export default [
  {
    title: 'Code',
    type: 'text',
    data: '',
    required: true,
    initialValue: '',
    dataIndex: 'code',
    note_text: 'A short code to quickly identify this tag, e.g. \'AK123\'.',
    additional: '',
    order: 1
  }, {
    title: 'Name',
    type: 'text',
    data: '',
    required: true,
    dataIndex: 'name',
    initialValue: '',
    note_text: 'A short summary of this tag\'s content, e.g. \'Liability clause\'.',
    additional: '',
    order: 2
  }, {
    title: 'Content',
    type: 'textarea',
    data: '',
    required: true,
    initialValue: '',
    note_text: '',
    dataIndex: 'content',
    additional: '',
    inputProps: {
      rows: 14,
      autoSize: { minRows: 14, maxRows: 21 }
    },
    order: 3
  }, {
    title: 'Sequence',
    type: 'text',
    data: '',
    initialValue: '',
    dataIndex: 'sequence',
    note_text: '(legacy; only for imports)',
    additional: '',
    order: 4
  }, {
    title: 'Show in csv',
    type: 'checkbox',
    data: '',
    dataIndex: 'show_in_csv',
    initialValue: false,
    note_text: '(prioritize in csv)',
    additional: '',
    order: 5
  }, {
    title: 'Show in pdf',
    type: 'checkbox',
    data: '',
    initialValue: false,
    dataIndex: 'show_in_pdf',
    note_text: '(prioritize in pdf)',
    additional: '',
    order: 6
  }, {
    title: 'Allow Override',
    type: 'checkbox',
    initialValue: false,
    dataIndex: 'allow_override',
    note_text: 'Can override in Tag Merchant',
    order: 6
  },
]
