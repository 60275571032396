export default [
  {
    "name": "username",
    "label": "Username",
    "data_type": "str",
    "ellipsis": true,
    "search": true,
    "sort": true
  },
  {
    "name": "first_name",
    "label": "First name",
    "data_type": "str",
    "search": true,
    "sort": true
  },
  {
    "name": "last_name",
    "label": "Last name",
    "data_type": "str",
    "ellipsis": true,
    "search": true,
    "sort": true
  },
  {
    "name": "is_active",
    "label": "Active",
    "data_type": "bool",
    "search": false,
    "sort": true
  },
  {
    "name": "is_staff",
    "label": "Staff status",
    "data_type": "bool",
    "search": false,
    "sort": true
  },
  {
    "name": "is_superuser",
    "label": "Superuser",
    "data_type": "bool",
    "search": false,
    "sort": true
  },
  {
    "name": "home_branch",
    "label": "Home branch",
    "data_type": null,
    "child_path": "name",
    "search": true,
    "sort": true
  },
]