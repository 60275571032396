import { MEASURE_TYPES, MEASURE_COUNT_SHAPES } from "App/Config/Constants";

export default [
  {
    title: 'Section',
    type: 'SelectQuery',
    dataIndex: 'section',
    data: 'sections',
    required: true,
    initialValue: '',
    note_text: '',
    additional: 'edit|add',
    order: 0,
    selectedKey: 'pk',
    selectedValue: 'name',
    disableChange: true,
  }, {
    title: 'Identifier',
    type: 'text',
    data: '',
    initialValue: '',
    dataIndex: 'identifier',
    note_text: 'Identifier string to replace current __unicode__ method that cause massive extra DB query.',
    additional: '',
    order: 1,
    // view: true,
    disabledAll:true,
  }, {
    title: 'Name',
    type: 'text',
    dataIndex: 'name',
    data: '',
    required: true,
    fieldKey: 'name',
    initialValue: '',
    note_text: 'In singular form, e.g. Door (not Doors)',
    additional: '',
    order: 2,
    view: true,
  }, {
    title: 'Plural name',
    type: 'text',
    data: '',
    required: true,
    dataIndex: 'plural_name',
    fieldKey: 'plural_name',
    editable: true,
    initialValue: '',
    note_text: 'The plural form of name, e.g. Doors (not Door)',
    additional: '',
    order: 3,
  }, {
    required: true,
    title: 'Order',
    type: 'number',
    dataIndex: 'order',
    data: '',
    initialValue: '0',
    note_text: '',
    additional: '',
    order: 4,
    fieldKey: 'order',
    view: true,
  }, {
    title: 'Show height',
    type: 'checkbox',
    dataIndex: 'show_height',
    data: '',
    initialValue: true,
    note_text: '',
    fieldKey: 'show_height',
    editable: true,
    additional: '',
    order: 5,
  }, {
    title: 'Height label',
    type: 'text',
    data: '',
    dataIndex: 'height_label',
    editable: true,
    fieldKey: 'height_label',
    initialValue: 'Height',
    note_text: '',
    additional: '',
    order: 6,
  }, {
    title: 'Height locked',
    type: 'checkbox',
    data: '',
    initialValue: false,
    dataIndex: 'height_locked',
    editable: true,
    fieldKey: 'height_locked',
    note_text: '',
    additional: '',
    order: 7,
  }, {
    title: 'Height limiter',
    type: 'checkbox',
    data: '',
    initialValue: false,
    fieldKey: 'height_limiter',
    editable: true,
    dataIndex: 'height_limiter',
    note_text: '',
    additional: '',
    order: 8,
  }, {
    title: 'Lower height',
    type: 'number',
    data: '',
    initialValue: null,
    dataIndex: 'lower_height',
    fieldKey: 'lower_height',
    editable: true,
    note_text: '',
    additional: '',
    order: 9,
  }, {
    title: 'Upper height',
    type: 'number',
    data: '',
    initialValue: null,
    dataIndex: 'upper_height',
    fieldKey: 'upper_height',
    editable: true,
    note_text: '',
    additional: '',
    order: 10,
  }, {
    title: 'Show Width',
    type: 'checkbox',
    data: '',
    initialValue: true,
    dataIndex: 'show_width',
    fieldKey: 'show_width',
    editable: true,
    note_text: '',
    additional: '',
    order: 11,
  }, {
    title: 'Width label',
    type: 'text',
    data: '',
    initialValue: 'Width',
    dataIndex: 'width_label',
    fieldKey: 'width_label',
    editable: true,
    note_text: '',
    additional: '',
    order: 12,
  }, {
    title: 'Width locked',
    type: 'checkbox',
    data: '',
    initialValue: false,
    dataIndex: 'width_locked',
    fieldKey: 'width_locked',
    editable: true,
    note_text: '',
    additional: '',
    order: 13,
  }, {
    title: 'Width limiter',
    type: 'checkbox',
    data: '',
    initialValue: false,
    dataIndex: 'width_limiter',
    fieldKey: 'width_limiter',
    editable: true,
    note_text: '',
    additional: '',
    order: 14,
  }, {
    title: 'Lower Width',
    type: 'number',
    data: '',
    initialValue: null,
    dataIndex: 'lower_width',
    fieldKey: 'lower_width',
    editable: true,
    note_text: '',
    additional: '',
    order: 15,
  }, {
    title: 'Upper Width',
    type: 'number',
    data: '',
    initialValue: null,
    note_text: '',
    dataIndex: 'upper_width',
    fieldKey: 'upper_width',
    editable: true,
    additional: '',
    order: 16,
  }, {
    title: 'Show Length',
    type: 'checkbox',
    data: '',
    initialValue: true,
    dataIndex: 'show_length',
    fieldKey: 'show_length',
    editable: true,
    note_text: '',
    additional: '',
    order: 17,
  }, {
    title: 'Length label',
    type: 'text',
    data: '',
    initialValue: 'Length',
    dataIndex: 'length_label',
    fieldKey: 'length_label',
    editable: true,
    note_text: '',
    additional: '',
    order: 18,
  }, {
    title: 'Length locked',
    type: 'checkbox',
    data: '',
    initialValue: false,
    dataIndex: 'length_locked',
    fieldKey: 'length_locked',
    editable: true,
    note_text: '',
    additional: '',
    order: 19,
  }, {
    title: 'Length limiter',
    type: 'checkbox',
    data: '',
    initialValue: false,
    dataIndex: 'length_limiter',
    fieldKey: 'length_limiter',
    editable: true,
    note_text: '',
    additional: '',
    order: 20,
  }, {
    title: 'Length Measure Type',
    type: 'select',
    data: MEASURE_TYPES,
    initialValue: '',
    dataIndex: 'length_measure_type',
    fieldKey: 'length_measure_type',
    note_text: 'Measurement tool type.',
    additional: '',
  }, {
    title: 'Length Measure Color',
    type: 'color',
    data: '',
    initialValue: '',
    dataIndex: 'length_measure_color',
    fieldKey: 'length_measure_color',
    note_text: 'Measurement tool color.',
    additional: '',
  }, {
    title: 'Length Measure Shape',
    type: 'select',
    data: MEASURE_COUNT_SHAPES,
    initialValue: '',
    dataIndex: 'length_measure_shape',
    fieldKey: 'length_measure_shape',
    note_text: 'Measurement COUNT tool shape.',
    additional: '',
  }, {
    title: 'Lower Length',
    type: 'number',
    data: '',
    initialValue: null,
    dataIndex: 'lower_length',
    fieldKey: 'lower_length',
    editable: true,
    note_text: '',
    additional: '',
    order: 21,
  }, {
    title: 'Upper Length',
    type: 'number',
    data: '',
    initialValue: null,
    dataIndex: 'upper_length',
    fieldKey: 'upper_length',
    editable: true,
    note_text: '',
    additional: '',
    order: 22,
  }, {
    title: 'Show quantity',
    type: 'checkbox',
    data: '',
    initialValue: false,
    dataIndex: 'show_quantity',
    fieldKey: 'show_quantity',
    editable: true,
    note_text: '',
    additional: '',
    order: 23,
  }, {
    title: 'Quantity Measure Type',
    type: 'select',
    data: MEASURE_TYPES,
    initialValue: '',
    dataIndex: 'quantity_measure_type',
    fieldKey: 'quantity_measure_type',
    note_text: 'Measurement tool type.',
    additional: '',
  }, {
    title: 'Quantity Measure Shape',
    type: 'select',
    data: MEASURE_COUNT_SHAPES,
    initialValue: '',
    dataIndex: 'quantity_measure_shape',
    fieldKey: 'quantity_measure_shape',
    note_text: 'Measurement COUNT tool shape.',
    additional: '',
  }, {
    title: 'Quantity Measure Color',
    type: 'color',
    data: '',
    initialValue: '',
    dataIndex: 'quantity_measure_color',
    fieldKey: 'quantity_measure_color',
    note_text: 'Measurement tool color.',
    editable: true,
    additional: '',
  }, {
    title: 'Deductible',
    type: 'checkbox',
    data: '',
    initialValue: false,
    dataIndex: 'deductible',
    fieldKey: 'deductible',
    editable: true,
    note_text: 'Can have deductions. e.g. walls.',
    additional: '',
    order: 24,
  }, {
    title: 'Is Opening',
    type: 'checkbox',
    data: '',
    initialValue: false,
    dataIndex: 'is_opening',
    fieldKey: 'is_opening',
    editable: true,
    note_text: 'Whether this is an opening, e.g. door, window.',
    additional: '',
    order: 25,
  }, {
    title: 'Is Door',
    type: 'checkbox',
    data: '',
    initialValue: false,
    dataIndex: 'is_door',
    fieldKey: 'is_door',
    editable: true,
    note_text: 'Whether this is a door.',
    additional: '',
    order: 26,
  }, {
    title: 'Is Bracing',
    type: 'checkbox',
    data: '',
    initialValue: false,
    fieldKey: 'is_bracing',
    editable: true,
    dataIndex: 'is_bracing',
    note_text: 'Whether this is a bracing.',
    additional: '',
    order: 27,
  }, {
    title: 'Is Wet Area',
    type: 'checkbox',
    data: '',
    initialValue: false,
    fieldKey: 'is_wet_area',
    editable: true,
    dataIndex: 'is_wet_area',
    note_text: 'Whether this is a wet area.',
    additional: '',
    order: 28,
  }, {
    title: 'Has Pitch Factor',
    type: 'checkbox',
    dataIndex: 'has_pitch_factor',
    data: '',
    initialValue: false,
    fieldKey: 'has_pitch_factor',
    editable: true,
    note_text: 'Can have pitch factor applied, e.g. roof',
    additional: '',
    order: 29,
  }, {
    title: 'Status',
    type: 'select',
    disableAllowClear: true,
    dataIndex: 'status',
    data: [
      {
        alias: 'active',
        value: 'Active - Anyone can use'
      }, {
        alias: 'inactive',
        value: 'Inactive - No one can use'
      }, {
        alias: 'unpublished',
        value: 'Unpublished - Only certain people can use'
      }
    ],
    initialValue: 'active',
    note_text: '',
    fieldKey: 'status',
    editable: true,
    additional: '',
    order: 30,
  }, {
    title: 'Dimension',
    type: 'number',
    dataIndex: 'dimension',
    fieldKey: 'dimension',
    editable: true,
    data: '',
    initialValue: null,
    note_text: '(legacy, only for import) bitmask (decimal of binary) for dimension and options, use dimension type instead.',
    additional: '',
    order: 31,
  }, {
    title: 'Locked',
    type: 'number',
    dataIndex: 'locked',
    fieldKey: 'locked',
    editable: true,
    data: '',
    initialValue: null,
    note_text: '',
    additional: '',
    order: 32,
  }, {
    title: 'Special notes',
    type: 'text',
    data: '',
    dataIndex: 'special_notes',
    initialValue: '',
    fieldKey: 'special_notes',
    editable: true,
    note_text: '',
    additional: '',
    order: 33,
  }, {
    title: 'Null dimension warning',
    type: 'checkbox',
    dataIndex: 'null_dimension_warning',
    fieldKey: 'null_dimension_warning',
    editable: true,
    data: '',
    initialValue: true,
    note_text: '',
    additional: '',
    order: 34,
  }
];
