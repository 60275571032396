import columnsIbes from '../User/columns'

const types = {
  ibes: 'IBES',
  tracker: 'Tracker',
}

export default [
  ...columnsIbes,
  {
    label: 'User Type',
    name: 'project_type',
    render: (val) => types[val],
  }
]