import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import { get } from 'lodash'
import Content from './Content'

class DescriptionsDialog extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      visible: false,
      modalProps: this.getModalProps(props.modalProps),
    }
  }

  getModalProps = (modalProps) => {
    return {
      centered: true,
      style: { top: 20 },
      width: 1024,
      footer: null,
      ...modalProps,
      onCancel: this.hide,
    }
  }

  setVisible = (visible, anotherState, callback = () => null) => {
    this.setState({ visible, ...anotherState }, callback)
  }

  show = (anotherState) => {
    this.setVisible(true, anotherState, () => {
      if (get(this._content, '_focusable')) {
        setTimeout(() => {
          this._content._focusable.focus()
        }, 150)
      }
    })
  }

  hide = () => this.setVisible(false)

  render() {
    const { title, urlKey, args, prev, next, contentProps, } = this.props
    const { visible, modalProps } = this.state

    return (
      <Modal
        visible={visible}
        title={title}
        {...modalProps}
      >
        <Content
          ref={ref => {this._content = ref}}
          urlKey={urlKey}
          args={args}
          prev={prev}
          next={next}
          {...contentProps}
        />
      </Modal>
    )
  }
}

DescriptionsDialog.propTypes = {
  urlKey: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  modalProps: PropTypes.object,
  args: PropTypes.array,
  fields: PropTypes.array,
  prev: PropTypes.func,
  next: PropTypes.func,
  contentProps: PropTypes.object,
}

DescriptionsDialog.defaultProps = {
  title: null,
  modalProps: null,
  args: [],
  fields: [],
  prev: null,
  next: null,
  contentProps: null,
}

export default DescriptionsDialog