import React from "react";
import { Button } from "antd";
import { BaseList, BaseDetail } from "App/Component/Bases";
import fields from "./fields";
import ComponentAutocomplete from "App/Component/Autocomplete";
import descriptor from "./descriptor";
import options from "./options";
import OrderManagement from "./OrderManagement"
import { forcePermissions } from "App/Data";

const title = "Override Sets";
const urlData = "overrideSets";
const bulkName = "name";
const availablePermissions = ['.view_', '.add_', '.change_'];
const children = {
    appName: 'estimation',
    modelName: 'overrideline',
    name: 'overrideSets',
    sub: 'overrideLines',
    dataIndex: 'override_lines',
    identifier: {
      name: 'override_set',
      is_array: false
    },
    bulk_request: true,
    urlSub: 'api:admin:estimation:overrideset:ref-override-lines-list',
    add: true,
    edit: true,
    data: false,
    filter_selected: { subsection: ['line_template'] },
    fieldOrder: [{ key: 'line_template' }], //unused
    permissions: {
      bulk_delete: true,
      bulk_url: 'overrideLines-read',
      initialrow: 1,
      payload: true,
      add: true
    },
    rules: {
      button: {
        type: {
          add: 'default',
          edit: 'default'
        },
        size: 'small'
      },
      input: {
        size: 'small'
      },
      select: {
        size: 'small'
      }
    },
  fields: ComponentAutocomplete['overrideLines']
  }

class List extends BaseList {
  constructor(props) {
    super(props)

    this.title = title;
    this.urlData = urlData;
    this.bulkName = bulkName;
    this.availablePermissions = availablePermissions;
    this.propsList = {
      descriptor,
      exportImport: options.exportImportOptions,
      getAnotherActions: () => (
        <Button icon='ordered-list' onClick={() => this.props.history.push('/admin/estimation/override-sets/order-management')}>
          Order Management
        </Button>
      )
    }
    this.state = {
      ...this.state,
      currentPermissionSet: this.isSuperuser ? this.filterPermissionItems(forcePermissions) : [],
    }
  }
}

class Detail extends BaseDetail {
  constructor(props) {
    super(props)

    this.title = title
    this.urlData = urlData
    this.bulkName = bulkName
    this.fields = fields
    this.children = children
    this.availablePermissions = availablePermissions
    this.propsDetail = {
      urlKeyHistory: 'api:admin:estimation:overrideset:detail-revision',
      keyLabel: 'name'
    }
    this.state = {
      ...this.state,
      currentPermissionSet: this.isSuperuser ? this.filterPermissionItems(forcePermissions) : [],
    }
  }
}

export default { List, Detail, OrderManagement };
