import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Table, Button, Input } from 'antd'
import { difference, get } from 'lodash'
import { Lib, Modules } from 'App/Utils'
import DrawerUsers from './DrawerUsers'

class TemplateList extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isShowDrawer: false,
      selectedMembers: [], // array of id user
    }
    this.userColumns = [
      {
        title: 'Username',
        dataIndex: 'username',
      }, {
        title: 'Full Name',
        dataIndex: 'full_name',
        render: (val, record) => {
          return (
            `${record.first_name} ${get(record, 'last_name', '')}`
          )
        }
      }, {
        title: 'Home Branch',
        dataIndex: 'home_branch.name',
      }, {
        title: 'Active Status',
        dataIndex: 'is_active',
        render: (val, record) => {
          return (
            Modules.renderStatusAIU(val)
          )
        }
      }, {
        title: 'Staff Status',
        dataIndex: 'is_staff',
        render: (val, record) => {
          return (
            Modules.renderStatusAIU(val)
          )
        }
      }, {
        title: 'Superuser Status',
        dataIndex: 'is_superuser',
        render: (val, record) => {
          return (
            Modules.renderStatusAIU(val)
          )
        }
      }, {
        title: 'Operation',
        dataIndex: 'operation',
        fixed: 'right',
        width: '125px',
        render: (val, record) => {
          return (
            <Row type='flex' gutter={[6, 6]}>
              <Col>
                <Button
                  icon='edit'
                  type='primary'
                  size='small'
                  title='View User'
                  onClick={(event) => {
                    event.stopPropagation()
                    props.handleHistoryAction(`/user-group/user/ibes/${record.pk}`)
                  }}
                />
              </Col>
              {!props.readOnly && (
                <Col>
                  <Button icon='user-delete' type='danger' size='small' title='Remove Member' onClick={(event) => {event.stopPropagation(); this.onDeleteMembers([record.pk])}} />
                </Col>
              )}
            </Row>
          )
        }
      }
    ]
  }

  onCloseDrawer = () => {
    this.onToggleUsers(false)
  }

  onToggleUsers = (isShowDrawer, callback = () => null) => {
    this.setState({ isShowDrawer }, callback)
  }

  onJoinUsers = (ids, callback) => {
    this.props.onJoinUsers(ids, (isSuccess) => {
      if (isSuccess) {
        this.onToggleUsers(false, () => {
          this.props.refresh('isSaving')
        })
      }
      callback(isSuccess)
    })
  }

  onSearch = (val, event) => {
    this.props.refresh('isSaving', { page: 1, search: val })
  }

  onPageChange = (page, pageSize) => {
    this.props.refresh('isSaving', { page, limit: pageSize })
  }

  onChangeSelection = (selectedRowKeys, selectedRows) => {
    this.setState({ selectedMembers: selectedRowKeys })
  }

  onClearSelected = () => {
    this.setState({ selectedMembers: [] })
  }

  onDeleteMembers = (idsMember) => {
    this.props.removeMembers(idsMember, isSuccess => {
      if (isSuccess) {
        const remainingSelectedMembers = difference(this.state.selectedMembers, idsMember)
        if (remainingSelectedMembers.length !== this.state.selectedMembers) {
          this.setState({ selectedMembers: remainingSelectedMembers })
        }
      }
    })
  }

  onDeleteSelectedMembers = () => {
    if (this.state.selectedMembers.length === 0) {
      return
    }
    this.props.removeMembers(this.state.selectedMembers, isSuccess => {
      if (isSuccess) {
        this.setState({ selectedMembers: [] })
      }
    })
  }

  onRowClick = (record, event) => {
    const { selectedMembers } = this.state
    if (selectedMembers.find(id => id === record.pk)) {
      this.setState({ selectedMembers: selectedMembers.filter(id => id !== record.pk) })
    } else {
      this.setState(prevState => ({ selectedMembers: [...prevState.selectedMembers, record.pk] }))
    }
  }

  render() {
    const { readOnly, dataSource, rawMembers, groupId, groupName, userReducer } = this.props
    const { isShowDrawer, selectedMembers } = this.state

    return (
      <div>
        <Row style={{ padding: '0 24px 24px 24px' }}>
          <Row className='mb-3' type='flex' justify='space-between' gutter={[12, 12]}>
            <Col style={{ fontSize: '16px', fontWeight: 500 }}>
              {!readOnly && (
              <Row type='flex' gutter={[12, 12]}>
                <Col>
                  <Button type='primary' icon='plus' onClick={() => this.onToggleUsers(true)}>
                    Add Member
                  </Button>
                </Col>
                {selectedMembers.length > 0 && (
                  <Col>
                    <Button.Group>
                      <Button onClick={this.onClearSelected}>
                        Clear selected
                      </Button>
                      <Button type='danger' icon='delete' onClick={this.onDeleteSelectedMembers}>
                        Remove Members
                      </Button>
                    </Button.Group>
                  </Col>
                )}
              </Row>
              )}
            </Col>
            <Col>
              <Row type='flex' gutter={[12, 12]}>
                <Col>
                  <Button icon='reload' onClick={() => this.props.refresh('isSaving')}>
                    Refresh
                  </Button>
                </Col>
                <Col>
                  <Input.Search
                    placeholder='Search members'
                    onSearch={this.onSearch}
                    style={{ width: '250px' }}
                  />
                </Col> 
              </Row>
            </Col>
          </Row>
          <Row>
            <Table
              bordered
              rowKey='pk'
              size='middle'
              scroll={{ x: true }}
              columns={this.userColumns}
              dataSource={dataSource}
              pagination={{
                pageSize: get(rawMembers, 'limit'),
                total: get(rawMembers, 'count'),
                showTotal: (total, range) => (
                  <span style={{ float: 'left' }}>{dataSource.length} of {total} members</span>
                ),
                onChange: this.onPageChange,
              }}
              rowSelection={readOnly ? null : {
                onChange: this.onChangeSelection,
                selectedRowKeys: selectedMembers,
              }}
              onRow={(record) => ({ onClick: (event) => this.onRowClick(record, event)})}
            />
          </Row>
        </Row>
        <DrawerUsers
          visible={isShowDrawer}
          groupId={groupId}
          groupName={groupName}
          userReducer={userReducer}
          members={dataSource}
          onClose={this.onCloseDrawer}
          onJoinUsers={this.onJoinUsers}
        />
      </div>
    )
  }
}

TemplateList.propTypes = {
  groupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  groupName: PropTypes.string.isRequired,
  userReducer: PropTypes.object.isRequired,
  handleHistoryAction: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  dataSource: PropTypes.array,
  onJoinUsers: PropTypes.func,
  refresh: PropTypes.func,
  removeMembers: PropTypes.func,
}

TemplateList.defaultProps = {
  readOnly: false,
  dataSource: [],
  onJoinUsers: () => null,
  refresh: () => null,
  removeMembers: () => null,
}

export default TemplateList