const module = 'admin'
const list = [
  {
    label: 'Dashboard',
    url: `/${module}/dashboard`,
    icon: 'dashboard',
  },
  {
    label: 'Estimation',
    menus: [
      {
        label: 'Export Import Sessions',
        url: `/${module}/estimation/export-import-sessions`
      },
      {
        label: 'Export Mapping Lines',
        url: `/${module}/estimation/export-mapping-lines`
      },
      {
        label: 'Export Mappings',
        url: `/${module}/estimation/export-mappings`
      },
      {
        label: 'Formulas',
        url: `/${module}/estimation/formulas`
      },
      {
        label: 'Line Templates',
        url: `/${module}/estimation/line-templates`
      },
      {
        label: 'Line Usages',
        url: `/${module}/estimation/line-usages`
      },
      {
        label: 'Override Lines',
        url: `/${module}/estimation/override-lines`
      },
      {
        label: 'Override Set Groups',
        url: `/${module}/estimation/override-set-groups`,
      },
      {
        label: 'Override Sets',
        url: `/${module}/estimation/override-sets`
      },
      {
        label: 'Pitch Factors',
        url: `/${module}/estimation/pitch-factors`
      },
      {
        label: 'PM Hardware Starting Ranges',
        url: `/${module}/estimation/pm-hardware-starting-ranges`
      },
      {
        label: 'PM Sort Codes',
        url: `/${module}/estimation/pm-sort-codes`
      },
      {
        label: 'PM Subhead Mappings',
        url: `/${module}/estimation/pm-subhead-mappings`
      },
      {
        label: 'PM Timber Starting Ranges',
        url: `/${module}/estimation/pm-timber-starting-ranges`
      },
      {
        label: 'Prefill Lines',
        url: `/${module}/estimation/prefill-lines`
      },
      {
        label: 'Prefill Set Groups',
        url: `/${module}/estimation/prefill-set-groups`,
      },
      {
        label: 'Prefill Sets',
        url: `/${module}/estimation/prefill-sets`
      },
      {
        label: 'Product Groups',
        url: `/${module}/estimation/product-groups`
      },
      {
        label: 'Products',
        url: `/${module}/estimation/products`
      },
      {
        label: 'Random Mappings',
        url: `/${module}/estimation/random-mappings`
      },
      {
        label: 'Sections',
        url: `/${module}/estimation/sections`
      },
      {
        label: 'Subhead Maps',
        url: `/${module}/estimation/subhead-maps`
      },
      {
        label: 'Subheadings',
        url: `/${module}/estimation/subheadings`
      },
      {
        label: 'Subsections',
        url: `/${module}/estimation/subsections`
      },
      {
        label: 'Template Sets',
        url: `/${module}/estimation/template-sets`
      },
      {
        label: 'Units',
        url: `/${module}/estimation/units`
      }
    ]
  },
  {
    label: 'Geo',
    menus: [
      {
        label: 'Countries',
        url: `/${module}/geo/countries`
      }
    ]
  },
  {
    label: 'Jobs',
    menus: [
      {
        label: 'Accounts',
        url: `/${module}/jobs/accounts`
      },
      {
        label: 'Building Types',
        url: `/${module}/jobs/building-types`
      },
      {
        label: 'Buildings',
        url: `/${module}/jobs/buildings`
      },
      {
        label: 'Extra Charge Jobs',
        url: `/${module}/jobs/extra-charge-jobs`
      },
      {
        label: 'Extra Charge Sizes',
        url: `/${module}/jobs/extra-charge-sizes`
      },
      {
        label: 'Extra Charges',
        url: `/${module}/jobs/extra-charges`
      },
      {
        label: 'Holidays',
        url: `/${module}/jobs/holidays`
      },
      // {
      //   label: 'Job Status Not Synceds',
      //   url: `/${module}/jobs/job-status-not-synceds`
      // },
      {
        label: 'Job Grades',
        url: `/${module}/jobs/job-grades`
      },
      {
        label: 'Job Status Histories',
        url: `/${module}/jobs/job-status-histories`
      },
      {
        label: 'Job Types',
        url: `/${module}/jobs/job-types`
      },
      {
        label: 'Merchant Groups',
        url: `/${module}/jobs/merchant-groups`
      },
      {
        label: 'Merchants',
        url: `/${module}/jobs/merchants`
      },
      {
        label: 'Operating Branches',
        url: `/${module}/jobs/operating-branches`
      },
      {
        label: 'Ranges',
        url: `/${module}/jobs/ranges`
      },
      {
        label: 'Rate Names',
        url: `/${module}/jobs/rate-names`
      },
      {
        label: 'Rate Types',
        url: `/${module}/jobs/rate-types`
      },
      {
        label: 'Tags',
        url: `/${module}/jobs/tags`
      },
      {
        label: 'Take Off Types',
        url: `/${module}/jobs/take-off-types`
      }
    ]
  },
  {
    label: 'Multisite',
    menus: [
      {
        label: 'Pending Monitor Reports',
        url: `/${module}/multisite/pending-monitor-reports`
      },
      {
        label: 'Servers',
        url: `/${module}/multisite/servers`
      }
    ]
  },
  {
    label: 'Notifications',
    menus: [
      {
        label: 'Notifications',
        url: `/${module}/notifications/notifications`
      }
    ]
  },
  {
    label: 'Reports',
    menus: [
      {
        label: 'Merchant Mapping Modes',
        url: `/${module}/reports/merchant-mapping-modes`
      },
      // {
      //   label: 'Merchant SKU Descriptions',
      //   url: `/${module}/reports/merchant-sku-descriptions`
      // },
      {
        label: 'MM Securities',
        url: `/${module}/reports/mm-securities`
      },
      {
        label: 'SKU Mappings',
        url: `/${module}/reports/sku-mappings`
      },
      {
        label: 'Special Messages',
        url: `/${module}/reports/special-messages`
      }
    ]
  },
  {
    label: 'Staff',
    menus: [
      {
        label: 'Staff Leaves',
        url: `/${module}/staff/leaves`
      },
      {
        label: 'Staff Positions',
        url: `/${module}/staff/positions`
      },
    ]
  }
]

const dummyFromApi = [
  {
    label: 'Estimation',
    menus: [
      // {
      //   label: 'Export Import Sessions (old)',
      //   url_name: 'api:admin:estimation:exportimportsession:list',
      //   url: '/admin/estimation/export-import-sessions-old',
      // },
      {
        label: 'Bluebeam Mappings',
        url_name: 'api:admin:estimation:bluebeammapping:list',
      },
      {
        label: 'Export Import Sessions',
        url_name: 'api:admin:exim:session:list',
      },
      {
        label: 'Export Mapping Lines',
        url_name: 'api:admin:estimation:exportmappingline:list',
      },
      {
        label: 'Export Mappings',
        url_name: 'api:admin:estimation:exportmapping:list',
      },
      {
        label: 'Formulas',
        url_name: 'api:admin:estimation:formula:list',
      },
      {
        label: 'Line Templates',
        url_name: 'api:admin:estimation:linetemplate:list',
      },
      {
        label: 'Line Usages',
        url_name: 'api:admin:estimation:lineusage:list',
      },
      {
        label: 'Override Lines',
        url_name: 'api:admin:estimation:overrideline:list',
      },
      {
        label: 'Override Groups',
        url_name: 'api:admin:estimation:overridesetgroup:list',
      },
      {
        label: 'Override Sets',
        url_name: 'api:admin:estimation:overrideset:list',
      },
      {
        label: 'Pitch Factors',
        url_name: 'api:admin:estimation:pitchfactor:list',
      },
      {
        label: 'PM Hardware Starting Ranges',
        url_name: 'api:admin:estimation:pmhardwarestartingrange:list',
      },
      {
        label: 'PM Sort Codes',
        url_name: 'api:admin:estimation:pmsortcode:list',
      },
      {
        label: 'PM Subhead Mappings',
        url_name: 'api:admin:estimation:pmsubheadmapping:list',
      },
      {
        label: 'PM Timber Starting Ranges',
        url_name: 'api:admin:estimation:pmtimberstartingrange:list',
      },
      {
        label: 'Prefill Lines',
        url_name: 'api:admin:estimation:prefillline:list',
      },
      {
        label: 'Prefill Groups',
        url_name: 'api:admin:estimation:prefillsetgroup:list',
      },
      {
        label: 'Prefill Sets',
        url_name: 'api:admin:estimation:prefillset:list',
      },
      {
        label: 'Product Groups',
        url_name: 'api:admin:estimation:productgroup:list',
      },
      {
        label: 'Products',
        url_name: 'api:admin:estimation:product:list',
      },
      {
        label: 'Random Mappings',
        url_name: 'api:admin:estimation:randommapping:list',
      },
      {
        label: 'Sections',
        url_name: 'api:admin:estimation:section:list',
      },
      {
        label: 'Subhead Maps',
        url_name: 'api:admin:estimation:subheadmap:list',
      },
      {
        label: 'Subheadings',
        url_name: 'api:admin:estimation:subheading:list',
      },
      {
        label: 'Subsections',
        url_name: 'api:admin:estimation:subsection:list',
      },
      {
        label: 'Template Sets',
        url_name: 'api:admin:estimation:templateset:list',
      },
      {
        label: 'Units',
        url_name: 'api:admin:estimation:unit:list',
      }
    ]
  },
  {
    label: 'Geo',
    menus: [
      {
        label: 'Countries',
        url_name: 'api:admin:geo:country:list',
      },
      {
        label: 'Provinces',
        url_name: 'api:admin:geo:province:list',
      },
      {
        label: 'Cities',
        url_name: 'api:admin:geo:city:list',
      },
    ]
  },
  {
    label: 'Jobs',
    menus: [
      {
        label: 'Accounts',
        url_name: 'api:admin:jobs:account:list',
      },
      {
        label: 'Building Types',
        url_name: 'api:admin:jobs:buildingtype:list',
      },
      {
        label: 'Buildings',
        url_name: 'api:admin:jobs:building:list',
      },
      // {
      //   label: 'Extra Charge Jobs',
      //   url_name: 'api:admin:jobs:extrachargejob:list',
      // },
      {
        label: 'Extra Charge Sizes',
        url_name: 'api:admin:jobs:extrachargesize:list',
      },
      {
        label: 'Extra Charges',
        url_name: 'api:admin:jobs:extracharge:list',
      },
      {
        label: 'Holidays',
        url_name: 'api:admin:jobs:holiday:list',
      },
      // {
      //   label: 'Job Status Not Synceds',
      //   url_name: 'api:admin:jobs:jobstatusnotsynced:list',
      // },
      {
        label: 'Job Grades',
        url_name: 'api:admin:jobs:jobgrade:list',
      },
      {
        label: 'Job Queues',
        url_name: 'api:admin:jobs:jobqueue:list',
      },
      // {
      //   label: 'Job Status Histories',
      //   url_name: 'api:admin:jobs:jobstatushistory:list',
      // },
      {
        label: 'Job Types',
        url_name: 'api:admin:jobs:jobtype:list',
      },
      {
        label: 'Merchant Groups',
        url_name: 'api:admin:jobs:merchantgroup:list',
      },
      {
        label: 'Merchants',
        url_name: 'api:admin:jobs:merchant:list',
      },
      {
        label: 'Operating Branches',
        url_name: 'api:admin:jobs:operatingbranch:list',
      },
      {
        label: 'Ranges',
        url_name: 'api:admin:jobs:ranges:list',
      },
      {
        label: 'Rate Names',
        url_name: 'api:admin:jobs:ratename:list',
      },
      {
        label: 'Rate Types',
        url_name: 'api:admin:jobs:ratetype:list',
      },
      {
        label: 'Tag Merchants',
        url_name: 'api:admin:jobs:tagmerchant:list',
      },
      {
        label: 'Tags',
        url_name: 'api:admin:jobs:tag:list',
      },
      {
        label: 'Take Off Types',
        url_name: 'api:admin:jobs:takeofftype:list',
      }
    ]
  },
  // {
  //   label: 'ERF',
  //   menus: [
  //     {
  //       label: 'ERF Types',
  //       url_name: 'api:admin:erf:erftype:list',
  //     },
  //     {
  //       label: 'ERF Mappings',
  //       url_name: 'api:admin:erf:erfmapping:list'
  //     },
  //   ]
  // },
  {
    label: 'Multisite',
    menus: [
      {
        label: 'Branches',
        url_name: 'api:admin:multisite:branch:list',
      },
      {
        label: 'Branch Ratings',
        url_name: 'api:admin:billing:branchrating:list',
      },
      // {
      //   label: 'Pending Monitor Reports',
      //   url_name: 'api:admin:multisite:pendingmonitorreport:list',
      // },
      // {
      //   label: 'Servers',
      //   url_name: 'api:admin:multisite:server:list',
      // }
    ]
  },
  {
    label: 'Notifications',
    menus: [
      {
        label: 'Broadcasts',
        url_name: 'api:admin:notifications:broadcast:list',
      },
    ]
  },
  {
    label: 'Reports',
    menus: [
      {
        label: 'Mapping Modes',
        url_name: 'api:admin:merchants:mappingmode:list',
        permissionCode: 'merchant.view_mappingmode', // for using permission code
      },
      // {
      //   label: 'MM Securities',
      //   url_name: 'api:admin:reports:mmsecurity:list',
      // },
      {
        label: 'SKU Mappings',
        url_name: 'api:admin:merchants:skumapping:list',
        permissionCode: 'merchant.view_skumapping', // for using permission code
      },
      {
        label: 'Special Messages',
        url_name: 'api:admin:reports:specialmessage:list',
      }
    ]
  },
  {
    label: 'User',
    menus: [
      {
        label: 'Staff Leaves',
        url_name: 'api:admin:users:staffleave:list',
      },
      {
        label: 'Staff Positions',
        url_name: 'api:admin:users:staffposition:list',
      },
    ]
  }
]

export default { key: module, list, dummyFromApi }
