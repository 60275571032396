import React from 'react'
import {Calendar, Badge, Col} from 'antd';
import ChartCard from "ant-design-pro/lib/Charts/ChartCard";
import moment from "moment";

class Calender extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      cutOff: []
    }
  }

  componentDidMount() {
    const {originData} = this.props
    this.setState({
			cutOff: originData
    })
  }

  getListData=(value)=> {
    const {cutOff} = this.state
    let listData = []
    let finddata = cutOff.findIndex(val => val.date_leave === value)

    if(finddata !== -1){
    	for (let name of cutOff[finddata].user_leave) {
				listData.push(
					{
						type: "warning",
						content: name
					})
			}
    }
    return listData || [];
  }

  dateCellRender=(value)=> {
    const listData = this.getListData(moment(value).format("YYYY-MM-DD"))
    return (
      <ul className="events">
        {listData.map(item => (
          <li key={item.content}>
            <Badge status={item.type} text={item.content} />
          </li>
        ))}
      </ul>
    );
  }

  getMonthData=(value)=> {
    const {cutOff} = this.state
    let totalLeave = 0
    for (let data of cutOff){
      if(moment(data.date_leave).format("YYYY-MM") === value) {
        totalLeave += 1
      }
    }
    return totalLeave
  }

  monthCellRender=(value)=> {
    const num = this.getMonthData(moment(value).format("YYYY-MM"));
    return num ? (
        <div className="notes-month">
          <span>{num} User is on leave</span>
        </div>
    ) : null;
  }
  render() {
    return(
      <Col span={24}>
      <ChartCard
        title={"User Calender"}
        style={{marginTop: "5px"}}
      >
      <Calendar
        dateCellRender={this.dateCellRender}
        monthCellRender={this.monthCellRender}
      />
      </ChartCard>
      </Col>
    )
  }
}
export default Calender
