import React from 'react'
import {ChartCard, Field, MiniBar} from "ant-design-pro/lib/Charts";
import {Badge, Tooltip, Row, Col, Icon} from "antd";
import numeral from 'numeral';
import PropTypes from 'prop-types';

class ChartMiniBar extends React.Component{

  render() {
    const {data, chartData} = this.props
    return(
      <Row>
        <Col span={16}>
          <ChartCard
            title={"Total modified"}
            style={{marginTop: "5px"}}
            action={
              <Tooltip title="modified">
                <Icon type="info-circle-o" />
              </Tooltip>
            }
            total={numeral(data.modified.total).format('0,0')}
            footer={<Field label="Modified today" value={numeral(39).format('0,0')} />}
            contentHeight={46}
          >
            <MiniBar height={76} data={chartData} />
          </ChartCard>
        </Col>
        <Col span={8}>
          <ChartCard
            title={"User usage for this data"}
            style={{marginTop: "5px", marginLeft: "5px"}}
            action={
            <Tooltip title={"User usage for this data"}>
              <Icon type="info-circle-o" />
            </Tooltip>
          }
            total={() => <span dangerouslySetInnerHTML={{ __html: data.usage.user }} />}
            footer={<Field label="Total data" value={numeral(data.usage.total_data).format('0,0')} />}
            contentHeight={46}
          >
            <span>
              Active <Badge status="success" text={data.usage.active} />
            </span>
            <span style={{ marginLeft: 16 }}>
              Inactive <Badge status="error" text={data.usage.inactive} />
            </span>
          </ChartCard>
        </Col>
      </Row>
    )
  }
}
ChartMiniBar.propTypes = {
  chartData: PropTypes.array,
  data: PropTypes.object
}
export default ChartMiniBar;