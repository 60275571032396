import React from 'react'
import PropTypes from 'prop-types'
import { Descriptions } from 'antd'
import { get } from 'lodash'
import { Lib } from 'App/Utils'

class CustomDescriptions extends React.PureComponent {
  constructor(props) {
    super(props)

    this.statesFromProps = ['descriptionsProps'] // key of state and props must be the same
    this.state = {
      descriptionsProps: this.getDescriptionsProps(props.descriptionsProps),
    }
  }

  getDescriptionsProps = (descriptionsProps) => {
    return {
      bordered: true,
      column: 1,
      size: 'middle',
      ...descriptionsProps,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const willState = {}
    for (const key of this.statesFromProps) {
      if (this.props[key] !== nextProps[key]) {
        if (key === 'descriptionsProps') {
          willState[key] = this.getDescriptionsProps(nextProps[key])
        } else {
          willState[key] = nextProps[key]
        }
      }
    }
    this.setState(willState)
  }

  render() {
    const { fields, record } = this.props
    const { descriptionsProps } = this.state

    return (
      <Descriptions {...descriptionsProps}>
        {fields.map((field, index) => (
          <Descriptions.Item key={field.key} label={field.label} span={get(field, 'span', 1)}>
            <div style={field.style}>
              {Lib.getValue(record, field, index)}
            </div>
          </Descriptions.Item>
        ))}
      </Descriptions>
    )
  }
}

CustomDescriptions.propTypes = {
  fields: PropTypes.array,
  record: PropTypes.object,
  descriptionsProps: PropTypes.object,
}

CustomDescriptions.defaultProps = {
  fields: [],
  record: null,
  descriptionsProps: null,
}

export default CustomDescriptions