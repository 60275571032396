export default {
  "notifications-read": "api/admin/notifications/notifications/",
  "notifications-create": "api/admin/notifications/notifications/",
  "notifications-delete": "api/admin/notifications/notifications/{}/",
  "notifications-update": "api/admin/notifications/notifications/{}/",
  "notifications-detail": "api/admin/notifications/notifications/{}/",
  "notifications-autocomplete": "api/admin/notifications/notifications/autocomplete/",

  "api:admin:notifications:broadcast:list": { url: "api/admin/notifications/broadcasts/", version: 3 },
  "api:admin:notifications:broadcast:detail": { url: "api/admin/notifications/broadcasts/{}/", version: 3 },
  "api:admin:notifications:broadcast:export": { url: "api/admin/notifications/broadcasts/export/", version: 3 },
  "broadcasts-detail": { url: "api/admin/notifications/broadcasts/{}/", version: 3 },
  "broadcasts-delete": { url: "api/admin/notifications/broadcasts/{}/", version: 3 },
  "sendBroadcast": { url: "api/admin/notifications/broadcasts/{}/send/", version: 3 },
};