import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Typography, Form, Input, Button, Icon, Modal, message } from 'antd'
import { get } from 'lodash'
import styled from 'styled-components'
import { FormContext, FormProvider } from 'App/Component/Context/ContextForm'
import { RequestV2 as Request } from 'App/Utils'

class EditProfile extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isSaving: false,
    }
  }

  onSave = () => {
    if (this.currentForm) {
      // Hati-hati ketika menggunakan isFieldsTouched(), karena jika setelah di setFields, isFieldsTouched kembali menjadil false
      this.currentForm.validateFieldsAndScroll({scroll: {offsetTop: 150}}, (err, values) => {
        if (!err) {
          const { profile } = this.props
          const withoutConfirm = values.username === get(profile, 'username') && values.email === get(profile, 'email')
          this.confirmPassword(anotherValues => {
            if (anotherValues) {
              this.save({ ...values, ...anotherValues })
            }
          }, withoutConfirm)
        }
      })
    } else {
      message.error('Cannot collect your input, please contact your admin!')
    }
  }

  confirmPassword = (callback = () => null, force) => {
    if (force) {
      callback(true)
    } else {
      let password = ''
      Modal.confirm({
        title: 'Password Confirmation',
        icon: <Icon type='exclamation-circle' style={{ color: '#1890ff' }} />, //#faad14
        content: (
          <Col>
            <Row style={{ marginBottom: '6px' }}>
              Please input your password to save your profile
            </Row>
            <Row style={{ marginBottom: '6px' }}>
              <Input.Password
                ref={ref => {setTimeout(() => {ref.input.focus()}, 250)}}
                placeholder='Confirm Password'
                onChange={(event) => {password = event.target.value}}
                onPressEnter={() => {
                  if (password) {
                    Modal.destroyAll()
                    callback({ password })
                  } else {
                    message.info('Please input Password')
                  }
                }}
              />
            </Row>
          </Col>
        ),
        okText: 'Save',
        okType: 'primary',
        onOk: () => new Promise((resolve, reject) => {
          if (password) {
            callback({ password })
            resolve()
          } else {
            message.info('Please input Password')
            reject()
          }
        }),
        onCancel: () => callback(),
      })
    }
  }

  save = (values) => {
    this.setState({ isSaving: true }, () => {
      Request(
        'put',
        'read-profile',
        {},
        values,
        [],
        this.saveSuccess,
        this.saveFailed,
        { values }
      )
    })
  }

  saveSuccess = (response, extra) => {
    message.success('Your profile has been saved')
    this.props.setProfile(response.data)
    this.setState({ isSaving: false }, () => {
      this.currentForm.setFieldsValue(extra.values)
    })
  }

  saveFailed = (error) => {
    const errors = get(error, ['response', 'data'])
    let errorMessage = 'Failed save Personal Information'
    if (errors && typeof errors === 'object') {
      if (errors.password) {
        errorMessage = errors.password[0]
      }

      const fieldsError = {}
      for (const key in errors) {
        fieldsError[key] = { value: this.currentForm.getFieldValue(key), errors: [new Error(errors[key][0])] }
      }
      this.currentForm.setFields(fieldsError)
    }
    message.error(errorMessage)
    this.setState({ isSaving: false })
  }

  render() {
    const { profile } = this.props
    const { isSaving } = this.state

    return (
      <Row type='flex' justify='center'>
        <ColForm>
          <Typography.Title level={2}>
            Personal Information
          </Typography.Title>
          <FormProvider>
            <FormContext.Consumer>
              {form => {
                this.currentForm = form
                return (
                  <Row>
                    <Col>
                      <Form.Item label='Username' style={{ marginBottom: '12px' }}>
                        {form.getFieldDecorator('username', {
                          rules: [{
                            required: true,
                            message: 'Cannot change the username to blank'
                          }],
                          initialValue: get(profile, 'username', ''),
                        })(
                          <Input onPressEnter={this.onSave} />
                        )}
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item label='First Name' style={{ marginBottom: '12px' }}>
                        {form.getFieldDecorator('first_name', {
                          rules: [{
                            required: true,
                            message: 'Please input First Name'
                          }],
                          initialValue: get(profile, 'first_name', ''),
                        })(
                          <Input
                            ref={ref => {ref.input.focus()}}
                            onPressEnter={this.onSave}
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item label='Last Name' style={{ marginBottom: '12px' }}>
                        {form.getFieldDecorator('last_name', {
                          initialValue: get(profile, 'last_name', '')
                        })(
                          <Input onPressEnter={this.onSave} />
                        )}
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item label='E-Mail Address' style={{ marginBottom: '12px' }}>
                        {form.getFieldDecorator('email', {
                          rules: [{
                            required: false,
                            message: 'Please input Email Address'
                          }],
                          initialValue: get(profile, 'email', ''),
                        })(
                          <Input type='email' onPressEnter={this.onSave} />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                )
              }}
            </FormContext.Consumer>
          </FormProvider>
          <Row>
            <Button block type='primary' loading={isSaving} onClick={this.onSave}>
              Save
            </Button>
          </Row>
          {/* <Button onClick={() => console.log(this.currentForm.isFieldsTouched())}>
            Is fields touched?
          </Button>
          <Button onClick={() => this.test = 'hello'}>
            set default hello
          </Button>
          <Button onClick={() => this.test = 'qwerty'}>
            set default qwerty
          </Button>
          <Button onClick={() => this.currentForm.setFieldsValue({ username: 'hy' })}>
            set to hy
          </Button>
          <Button onClick={() => this.currentForm.setFields({ username: {value: '123', errors: [new Error('error 123')]} })}>
            set fields 123
          </Button>
          <Button onClick={() => this.currentForm.resetFields()}>
            reset fields
          </Button>
          <Button onClick={() => this.setState({ dummy: true })}>
            Set state
          </Button> */}
        </ColForm>
      </Row>
    )
  }
}

const ColForm = styled(Col)`
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  width: 660px;
  max-width: 100%;
  padding: 24px;
`

EditProfile.propTypes = {
  userReducer: PropTypes.object.isRequired,
  profile: PropTypes.object,
  setProfile: PropTypes.func,
}

EditProfile.defaultProps = {
  profile: null,
  setProfile: () => null,
}

export default EditProfile