export default [
  {
    title: 'Section',
    required: true,
    type: 'SelectQuery',
    data: 'sections',
    initialValue: '',
    note_text: '',
    dataIndex: 'section',
    selectedKey: 'pk',
    selectedValue: 'name',
    dropdownClassName: 'width-auto',
    additional: 'edit|add',
    fieldKey: 'section',
    editable: true,
    width: 250,
    relations: ['subsection'],
    skip: true,
    order: 0,
  },
  {
    title: 'Subsection',
    type: 'SelectQuery',
    required: true,
    data: 'subsections',
    initialValue: '',
    note_text: '',
    selectedKey: 'pk',
    selectedValue: 'name',
    dropdownClassName: 'width-auto',
    additional: 'edit|add',
    dataIndex: 'subsection',
    fieldKey: 'subsection',
    foreignKey: 'section',
    relations: ['prefill_groups'],
    editable: true,
    order: 0,
  }, {
    title: 'Name',
    type: 'text',
    required: true,
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'name',
    fieldKey: 'name',
    editable: true,
    order: 1,
  }, {
    title: 'Status',
    type: 'select',
    disableAllowClear: true,
    data: [
      {
        alias: 'active',
        value: 'Active - Anyone can use'
      }, {
        alias: 'inactive',
        value: 'Inactive - No one can use'
      }, {
        alias: 'unpublished',
        value: 'Unpublished - Only certain people can use'
      }
    ],
    initialValue: 'active',
    note_text: '',
    additional: '',
    dataIndex: 'status',
    fieldKey: 'status',
    editable: true,
    order: 1,
  }, {
    title: 'Prefill groups',
    type: 'select_multiple',
    data: 'prefillSetGroups',
    initialValue: [],
    additional: 'add',
    dataIndex: 'prefill_groups',
    fieldKey: 'prefill_groups',
    selectedKey: 'pk',
    paramProps: { status: 'active' },
    foreignKey: 'subsection',
    // editable: true,
    selectedValue: 'name',
  }, {
    title: 'Order',
    type: 'number',
    dataIndex: 'order',
    fieldKey: 'order',
    initialValue: null, // set initialValue to null for allow null
    editable: true,
  }, {
    title: 'Indentation',
    type: 'number',
    dataIndex: 'indentation',
    fieldKey: 'indentation',
    initialValue: 0, // set initialValue to number (or dont pass) for cannot null
    editable: true,
  }, {
    title: 'Relation',
    type: 'text',
    dataIndex: 'relation',
    fieldKey: 'relation',
    initialValue: null, // set initialValue to null for allow null
    editable: true,
  }, {
    title: 'Import Session',
    type: 'text',
    dataIndex: 'import_session',
    fieldKey: 'import_session',
    initialValue: null,
    editable: true,
  },
];
