export default {
  "app_label": "estimation",
  "model_name": "overridesetgroup",
  "pagination": true,
  "columns": [
    {
      "name": "pk",
      "label": "ID",
      "help_text": null,
      "hidden": true,
      "identifier": true,
      "data_type": "int",
      "search": false,
      "sort": false
    },
    {
      "name": "name",
      "label": "Name",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "ellipsis": true,
      "search": true,
      "sort": true
    },
    {
      "name": "section",
      "label": "Section",
      "child_path": "name",
    },
    {
      "name": "subsection",
      "label": "Subsection",
      "child_path": "name",
    },
    {
      "name": "status",
      "label": "Status",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "search": true,
      "sort": true
    },
    {
      "name": "order",
      "label": "Order",
    },
    {
      "name": "modified",
      "label": "Modified",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "datetime",
      "search": false,
      "sort": false
    }
  ],
  "filter_by": [
    {
      "label": "Status",
      "name": "status",
      "type": "choice",
      "choices": [
        [
          "active",
          "Active - Anyone can use"
        ],
        [
          "inactive",
          "Inactive - No one can use"
        ],
        [
          "unpublished",
          "Unpublished - Only certain people can use"
        ]
      ]
    },
    {
      "label": "Section",
      "name": "section",
      "type": "api_reference",
      "api": {
        "url_name": "api:admin:estimation:section:autocomplete",
        "key": "pk",
        "display": "name",
        "pagination": true
      }
    },
    {
      "label": "Subsection",
      "name": "subsection",
      "type": "api_reference",
      relates: ['section'],
      "api": {
        "url_name": "api:admin:estimation:subsection:autocomplete",
        "key": "pk",
        "display": "name",
        "pagination": true
      }
    },
    // {
    //   "label": "Prefill Set",
    //   "name": "prefill_set",
    //   "type": "api_reference",
    //   relates: ['subsection'],
    //   "api": {
    //     "url_name": "api:admin:estimation:prefillset:autocomplete",
    //     "key": "pk",
    //     "display": "name",
    //     "pagination": true
    //   }
    // },
    {
      "label": "Override sets",
      "name": "override_sets",
      "type": "api_reference",
      relates: ['subsection', 'prefill_set'],
      "api": {
        "url_name": "api:admin:estimation:overrideset:autocomplete",
        "key": "pk",
        "display": "name",
        "pagination": true
      }
    }
  ],
  "export_import": null
}