import React, { PureComponent } from "react";
import styled from "styled-components";
import { Layout, Menu, Icon, Dropdown, Row, Modal, Col, Drawer } from "antd";
import { Link } from "react-router-dom";
import { get } from 'lodash';

import { Logout } from "App/Utils";
import UserProfile from "App/Component/User/UserProfile";
import UserSetting from "App/Component/User/UserSetting";
import { HeaderMenu, NavMenu } from "App/Config";
import hasNavMenu from "App/Screens/Dashboard/hasNavMenu";
import app from "App/Config/Sites";

const { Header } = Layout;

class AppHeader extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      current: "",
      visibleProfile: false,
      drawerEdit: false,
      visibleSetting: false
    };

    this.menuDropdown = this.menuDropdown.bind(this);
    this.handleVisibleProfile = this.handleVisibleProfile.bind(this);
    // this.filteredMenu = get(props.userReducer, ['user', 'is_superuser']) ? HeaderMenu : this.filterMenu(props)
    this.filteredMenu = this.filterMenu(props)
  }

  filterMenu = (props) => {
    const isSuperuser = get(props.userReducer, ['user', 'is_superuser'])
    const allowedModules = get(props.userReducer, ['user', 'allowed_modules'], [])
    const permissions = get(props.userReducer, ['user', 'permissions'])
    return HeaderMenu.filter(menu => {
      if (menu.forceHidden) return false
      if (isSuperuser) return true

      const isAllowedModule = allowedModules.find(codename => codename === menu.codename)
      return (isAllowedModule && this.getIsSetExist(menu, permissions))
    })
  }

  getIsSetExist = (headerMenu, permissions) => {
    const userPermissions = permissions || get(this.props, ['userReducer', 'user', 'permissions'], [])
    return hasNavMenu(headerMenu, userPermissions, NavMenu)
    switch(headerMenu.codename) {
      case 'admin': {
        const tempNav = get(NavMenu, headerMenu.codename, [])
        for (let i = 0; i < tempNav.length; i += 1) {
          const menus = get(tempNav[i], 'menus', [])
          for (let j = 0; j < menus.length; j += 1) {
            const group = get(menus[j], 'url_name', '').split(':')[2]
            const set = get(menus[j], 'url_name', '').split(':')[3]
            if (userPermissions.findIndex(perm => perm.includes(`${group}.view_${set}`)) !== -1) {
              return true
            }
          }
        }
        break
      }
      case 'branch': {
        const tempNav = get(NavMenu, 'branch-queue', [])
        for (let i = 0; i < tempNav.length; i += 1) {
          const regex = new RegExp('^' + get(tempNav[i], 'code', 'UNKNOWN').replace('{}', 'view') + '$')
          if (userPermissions.findIndex(perm => perm.search(regex) !== -1) !== -1) {
            return true
          }
        }
        break
      }
      case 'job': {
        const tempNav = get(NavMenu, 'job-management', [])
        for (let i = 0; i < tempNav.length; i += 1) {
          const regex = new RegExp('^' + get(tempNav[i], 'code', 'UNKNOWN').replace('{}', 'view') + '$')
          if (userPermissions.findIndex(perm => perm.search(regex) !== -1) !== -1) {
            return true
          }
        }
        break
      }
      case 'product': {
        const regex = new RegExp('estimation.view_product')
        if (userPermissions.findIndex(perm => perm.search(regex) !== -1) !== -1) {
          return true
        }
        break
      }
      case 'user_role': {
        const tempNav = get(NavMenu, 'user-group', [])
        for (let i = 0; i < tempNav.length; i += 1) {
          const regex = new RegExp(get(tempNav[i], 'code', 'UNKNOWN').replace('{}', 'view'))
          if (userPermissions.findIndex(perm => perm.search(regex) !== -1) !== -1) {
            return true
          }
        }
        break
      }
      default: {
        break
      }
    }
    return false
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    const next = get(nextProps.userReducer, ['user', 'allowed_modules'], [])
    const previous = get(this.props.userReducer, ['user', 'allowed_modules'], [])
    if(next.length !== previous.length){
      this.filteredMenu = get(nextProps.userReducer, ['user', 'is_superuser']) ? HeaderMenu : this.filterMenu(nextProps)
      this.setState({helloworld: Math.random()})
    }
  }

  handleClick = e => {
    this.setState({
      current: e.key
    });
  };

  getCurrentModule = () => {
    const pathname = get(this.props.location, 'pathname', '')
    return pathname.split('/').filter(val => val)[0]
  }

  handleVisibleProfile(is_Editprofile = false) {
    if (is_Editprofile) this.setState({ drawerEdit: !this.state.drawerEdit });
    else
      this.setState({
        visibleProfile: !this.state.visibleProfile,
        visibleSetting: false
      });
  }

  handleVisibleSetting = () => {
    this.setState({
      visibleSetting: !this.state.visibleSetting,
      drawerEdit: false,
      visibleProfile: false
    });
  };

  about() {
    Modal.info({
      title: 'Integrated Building Estimating System (IBES)',
      content: (
        <b style={{ color: 'coral' }}>
          Version v{get(app, 'app.version')}
        </b>
      ),
      onOk() {},
      onCancel() {},
    });
  }

  menuDropdown() {
    return (
      <Menu>
        <Menu.Item key="0" onClick={() => this.handleVisibleProfile()}>
          <Icon type="smile" theme="twoTone" />
          Profile
        </Menu.Item>
        {/* <Menu.Item key="1" onClick={() => this.handleVisibleSetting()}>
          <Icon type="setting" theme="twoTone" />
          Settings
        </Menu.Item> */}
        <Menu.Item key="1" onClick={()=>this.about()}>
          <Icon type="info-circle" theme="twoTone" twoToneColor="#00cecc" />
          About
        </Menu.Item>
        <Menu.Item key="2" onClick={() => Logout(this.props.history)}>
          <Icon type="lock" theme="twoTone" twoToneColor="#eb2f96" />
          Logout
        </Menu.Item>
      </Menu>
    );
  }

  render() {
    const { userReducer } = this.props;
    
    return (
      <StyledHeader collapsed={this.props.collapsed.toString()}>
        <Row>
          <Col xs={24} sm={18}>
            <Menu mode="horizontal" selectedKeys={[`/${this.getCurrentModule()}`]} style={{ lineHeight: '64px', marginTop: '-2px' }}>
              {
                this.filteredMenu.map(val => {
                  return (
                    <Menu.Item key={val.url}>
                      <Link to={val.url}>
                        <Icon type={val.icon} />
                        {val.label}
                      </Link>
                    </Menu.Item>
                  )
                })
              }
            </Menu>
          </Col>
          <Col xs={0} sm={6} className="text-right" style={{ overflow: 'hidden' }}>
            <StyledDropdown overlay={this.menuDropdown}>
              <SpanItemRight>
                <span style={{ color: "#1890ff" }}>
                  {get(userReducer, ['user', 'username'])} <Icon type="caret-down" />
                </span>
              </SpanItemRight>
            </StyledDropdown>
          </Col>
        </Row>
        <Drawer
          width={`calc(100% - ${this.props.collapsed ? '80px' : '300px'})`}
          // style={{ top:'64px' }}
          bodyStyle={{ padding: '0', marginTop: '0' }}
          placement="right"
          closable={false}
          onClose={() => this.handleVisibleProfile(false)}
          visible={this.state.visibleProfile}
        >
          <UserProfile
            toggle={this.handleVisibleProfile}
            userReducer={userReducer}
          />
        </Drawer>
        <UserSetting
          visible={this.state.visibleSetting}
          data={userReducer.user}
          toggle={this.handleVisibleSetting}
          userReducer={userReducer}
        />
      </StyledHeader>
    );
  }
}

export default AppHeader;

const StyledHeader = styled(Header)`
  background: #fff !important;
  padding: 0 !important;
  position: fixed;
  z-index: 3;
  // margin-left: 100px;
  width: ${props =>
    props.collapsed === "true" ? "calc(100% - 80px)" : "calc(100% - 300px)"};
  transition: width 0.2s;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
`;
const StyledDropdown = styled(Dropdown)`
  margin-right: 1rem;
`;
const SpanItemRight = styled.span`
  display: inline-block;
  height: 100%;
  cursor: pointer;
  transition: all 0.3s;
  padding: 0 4px 0 0;
  :hover {
    background: rgba(0, 0, 0, 0.025);
  }
`;
