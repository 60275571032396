import React from 'react'
import PropTypes from 'prop-types'
import { Prompt } from 'react-router-dom'
import { Card, Row, Col, Form, Button, message } from 'antd'
import { get } from 'lodash'
import { FormContext, FormProvider } from 'App/Component/Context/ContextForm'
import DynamicField from 'App/Component/DynamicField'
import fields from './fields'
import { RequestV2 as Request, Lib } from 'App/Utils'
import Lib2 from 'App/Component/Singlepage/Lib2'

const additionalField = {
  for: {
    title: 'For',
    type: 'select',
    disableAllowClear: true,
    dataIndex: 'for',
    data: [
      {
        alias: 'merchant',
        value: 'Merchant'
      }, {
        alias: 'group',
        value: 'Merchant Group'
      },
    ],
    initialValue: 'merchant',
  },
  merchant: {
    title: 'Merchant',
    type: 'SelectQuery',
    data: 'module-merchant-merchant',
    required: true,
    selectedKey: 'pk',
    selectedValue: 'name',
    dataIndex: 'merchant',
  },
  group: {
    title: 'Group',
    type: 'SelectQuery',
    data: 'module-merchant-group',
    required: true,
    selectedKey: 'pk',
    selectedValue: 'name',
    dataIndex: 'group',
  }
}

class PageAdd extends React.PureComponent {
  constructor(props) {
    super(props)

    this.fields = [...fields, additionalField.for, additionalField.merchant]
    this.state = {
      isFieldsTouched: false,
      isSaving: false,
    }
  }

  onCancel = () => {
    Lib.historyAction(this.props.history, '/merchant/sku-mapping').goBack()
  }

  onBlur = (value, key, type) => {
    const stateForce = {}
    if (key === 'for') {
      this.fields[this.fields.length - 1] = additionalField[value]
      stateForce.forceRender = !this.state.forceRender
    }
    this.setState({ isFieldsTouched: true, ...stateForce })
  }

  onRevert = () => {
    if (this.form) {
      this.form.resetFields()
      this.setState({ isFieldsTouched: false })
    } else {
      message.error('Error in code Add SKU Mapping, form not found. [function => onRevert]')
    }
  }

  onSave = () => {
    if (this.form) {
      this.form.validateFieldsAndScroll({scroll: {offsetTop: 150}}, (err, values) => {
        if (!err) {
          this.setState({ isSaving: true }, () => {
            const convertedValues = Lib2.getValuesWithFile(values)
            Request(
              'post',
              'module-merchant-skuMapping-create',
              {},
              convertedValues,
              [],
              this.saveSuccess,
              this.saveFailed,
            )
          })
        }
      })
    } else {
      message.error('Error in code Add SKU Mapping, form not found. [function => onSave]')
    }
  }

  saveSuccess = (response) => {
    message.success(`SKU Mapping with Product Code ${response.data.product_code} has been saved.`, 5)
    this.setState({ isFieldsTouched: false }, () => {
      Lib.historyAction(this.props.history, '/merchant/sku-mapping').goBack()
    })
  }

  saveFailed = (error) => {
    const errors = get(error, ['response', 'data'])
    if (errors && typeof errors === 'object') {
      this.setFieldsError(errors)
    } else {
      message.error('Failed to save SKU Mapping, please contact your admin! code: ' + get(error, ['response', 'status'], 'Unknown.'), 5)
    }
    this.setState({ isSaving: false })
  }

  setFieldsError = (objError) => {
    const obj = {}
    for (const key in objError) {
      message.error('Error in ' + key + ' : ' + objError[key][0], 5)
      const tempValue = this.form.getFieldValue(key)
      obj[key] = {
        value: tempValue,
        errors: [new Error(objError[key][0])]
      }
    }
    this.form.setFields(obj)
  }

  render() {
    const { userReducer } = this.props
    const { isFieldsTouched } = this.state

    return (
      <Card
        title='SKU Mapping'
        style={{ borderRadius: '12px', marginBottom: '12px' }}
      >
        <Prompt
          when={isFieldsTouched}
          message='You will lose any unsaved changes. Continue?'
        />
        <Row style={{ marginTop: '12px' }}>
          <FormProvider>
            <Form>
              {this.fields.map((elem) => {
                return (
                  <DynamicField
                    {...elem}
                    key={elem.dataIndex}
                    onBlur={this.onBlur}
                    userReducer={userReducer}
                  />
                )
              })}
              <FormContext.Consumer>
                {form => {this.form = form}}
              </FormContext.Consumer>
            </Form>
          </FormProvider>
        </Row>
        <Row type='flex' justify='space-between' align='middle' style={{ backgroundColor: '#fff', padding: '12px 0', borderTop: '1px solid #e8e8e8'}}>
          <Col>
            {/* <Button type='danger'>Button Danger</Button> */}
          </Col>
          <Col>
            <Row type='flex' gutter={[12, 12]}>
              <Col>
                <Button disabled={this.state.isSaving} onClick={this.onCancel}>
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button icon={this.state.isSaving ? 'loading' : 'save'} type='primary' onClick={this.onSave}>
                  Save
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    )
  }
}

PageAdd.propTypes = {
  userReducer: PropTypes.object.isRequired,
}

PageAdd.defaultProps = {
}

export default PageAdd