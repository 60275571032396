import React from 'react'
import {
  Row,
  Col,
  Button,
  Icon,
  Typography,
  Tooltip,
  Popover,
  Divider,
  Modal,
  message,
  Form,
  Card
} from 'antd'
import { FormContext, FormProvider } from 'App/Component/Context/ContextForm'
import DynamicFieldHalfScreen from 'App/Component/DynamicFieldHalfScreen'
import ComponentAutocomplete from 'App/Component/Autocomplete'
import ComponentHelper from 'App/Component/Singlepage/Lib2'
import { RequestV2 as Request } from 'App/Utils'
import moment from 'moment'
import PropTypes from 'prop-types'

import wordIcon from 'App/Assets/img/icon-word.jpg'
import excelIcon from 'App/Assets/img/icon-excel.jpg'
import pptIcon from 'App/Assets/img/icon-powerpoint.jpg'
import 'App/Component/Singlepage/SingleStyle.css'

const { Text } = Typography
const { confirm } = Modal
const fileTypeAccepted = '.doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.txt'

class PageDocuments extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isModalDialog: false,
      isLoading: false,
      isSaving: false,
      documentList: [],
      nextDocumentList: null,
      popVisible: [],
    }

    this.readSuccess = this.readSuccess.bind(this)
    this.readFailed = this.readFailed.bind(this)
    this.onSave = this.onSave.bind(this)
  }

  dataUpload() {
    return {
      beforeUpload: () => false,
      multiple: false,
      accept: fileTypeAccepted,
    }
  }

  handlePopVisibleChange(visible, index) {
    let popVisible = []
    popVisible[index] = visible
    this.setState({
      popVisible,
    })
  }

  moreContent = (document) => (
    <Row>
      <Button type='danger' block onClick={() => this.handleDelete(document)}>
        <Icon type='delete' /> Delete
      </Button>
    </Row>
  )

  read() {
    Request(
      'get',
      this.props.urlData + '-read',
      {},
      {},
      [this.props.parentId],
      this.readSuccess,
      this.readFailed,
    )
  }

  readSuccess(response) {
    this.setState({
      isLoading: false,
      documentList: response.data.results,
      nextDocumentList: response.data.next,
    })
  }

  readFailed(err) {
    this.setState({
      isLoading: false,
    }, () => this.failReqMsg(err))
  }

  failReqMsg(error) {
    if (error.response) {
      const errCode = error.response && error.response.status
      const errText = error.response && error.response.statusText
      let msg = `${errText} - code: ${errCode}`
      if (errCode === 400) {
        msg = `Your file is empty, please choose another document!`
      }
      message.error(msg)
    }
  }

  getSrcFile(type) {
    // write in the first index for using in srcFile
    const convType = [
      ['doc', 'docx',],
      ['ppt', 'pptx'],
      ['xls', 'xlsx'],
    ]

    const srcFile = {
      pdf: 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTz6Jq-N-CqJqPpndehQJS6FYyAozNU9vq7Flbu9r-Ilqk27NxM',
      doc: wordIcon,
      xls: excelIcon,
      ppt: pptIcon,
      csv: 'https://cdn2.iconfinder.com/data/icons/flat-file-types-1-1/300/icon_file-CSV_plano-512.png',
      default: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Text_document_with_red_question_mark.svg/768px-Text_document_with_red_question_mark.svg.png',
    }

    let tempType = type.replace('.', '')

    check:
    for (const cvTypeList of convType) {
      for (const cvType of cvTypeList) {
        if (cvType === tempType) {
          tempType = cvTypeList[0]
          break check
        }
      }
    }
    let src = srcFile[tempType]
    if (!src) {
      src = srcFile.default
    }
    return src
  }

  normFile = (e, limit) => {
    if (Array.isArray(e)) {
      return e;
    }

    if (!limit) {
      return e.fileList
    } else if (limit > 0) {
      limit *= -1
    }

    return e.fileList.slice(limit)
  }

  onSave() {
    const {validateFieldsAndScroll} = this.form;
    validateFieldsAndScroll({scroll: {offsetTop: 150}}, (err, values) => {
      if (!err) {
        // Do Save
        // if (window.confirm('Temporarily, the document will be saved, but with fake data')) {
          this.save(values)
        // }
      }
    })
  }

  save(values, resetFields) {
    const valuesConverted = ComponentHelper.getValuesWithFileV2(values, this.documentFields)

    this.setState({ isSaving: true }, () => {
      Request(
        'post',
        this.props.urlData + '-create',
        {},
        valuesConverted,
        [this.props.parentId],
        res => this.saveSuccess(res),
        err => {
          this.setState({ isSaving: false })
          this.failReqMsg(err)
        },
      )
    })
  }

  saveSuccess(response) {
    this.read()
    this.setState({
      isModalDialog: false,
      isSaving: false,
    }, () => message.success('Your document has been saved'))
  }

  handleDelete(document) {
    this.setState({
      popVisible: false,
    }, () => {
      this.showDeleteConfirm(document.file_name, confirmation => {
        if (confirmation) {
          this.delete(document)
        }
      })
    })
  }

  showDeleteConfirm(fileName, callback) {
    confirm({
      title: 'Delete file',
      content: `Are you sure want to delete ${fileName}`,
      okText: 'Yes',
      okType: 'danger',
      onOk: () => {
        callback(true)
      }
    })
  }

  delete(document) {
    Request(
      'delete',
      this.props.urlData + '-delete',
      {},
      {},
      [this.props.parentId, document.pk],
      res => { this.read(); message.success('delete success'); },
      err => message.error('error' + err.response),
    )
  }

  componentDidMount() {
    this.documentFields = ComponentAutocomplete.productDocuments
    this.read()
  }

  render() {
    const { readOnly } = this.props
    const { popVisible, isModalDialog } = this.state
    const { documentList, isSaving } = this.state
    return (
      <Card>
        <Row className='gridCenteredH' type='flex' justify='center'>
          <Col>
            <Row type='flex'>
              <Button
                disabled={readOnly}
                type='primary'
                onClick={() => this.setState({isModalDialog: true})}
              >
                <Col sm={24} xs={0} className='over-hide'>
                  <Icon type='plus' />
                  <span style={{marginLeft: '8px'}}>
                    {`Add Document`}
                  </span>
                </Col>
                <Col sm={0} xs={24} className='over-hide'>
                  <Icon type='plus' />
                </Col>
              </Button>
            </Row>
          </Col>
          <Divider className='row-item' type='horizontal' />
          {
            documentList.map((data, index, arr) => (
              <Col key={data.pk} style={{width: '250px', minHeight: '75px', border: '0px solid black'}}>
                <Row className='equal-container' style={{padding: '6px',}}>
                  <Row style={{ borderRadius: '4px', border: '1px solid #e8e8e8'}}>
                    <Col lg={8} md={8} sm={8} xs={24} style={{height: '63px', padding: '3px 8px'}}>
                      <a href={data.file} target='_blank' rel='noopener noreferrer'>
                        <Tooltip placement='top' title={`Click to open ${data.file_name}`}>
                          <img
                            className='equal-container'
                            style={{maxWidth: '80px',}}
                            alt='Type not supported'
                            src={this.getSrcFile(data.extension)}
                          />
                        </Tooltip>
                      </a>
                    </Col>
                    <Col lg={16} md={16} sm={16} xs={24} style={{minHeight: '63px'}}>
                      <Row>
                          <Text
                            className='black text-v'
                            strong
                            ellipsis
                            style={{display: 'block', margin: '9px 24px 0 0'}}
                          >
                            {data.file_name}
                          </Text>
                      </Row>
                      <Row>
                        <Text style={{fontSize: '11px', color: '#ababab'}}>
                          {moment(data.created).format('ll')}
                        </Text>
                      </Row>
                    </Col>
                    {!readOnly && (
                      <Popover
                        title='Choose action!'
                        placement='top'
                        trigger='click'
                        content={this.moreContent(data)}
                        visible={popVisible && popVisible[index]}
                        onVisibleChange={visible => this.handlePopVisibleChange(visible, index)}
                      >
                        <Col style={{
                          position: 'absolute',
                          right: 0,
                          top: 0,
                          cursor: 'pointer',
                          margin: '6px 6px 0 0'
                        }}>
                          <Icon type='more' style={{color: 'black'}} />
                        </Col>
                      </Popover>
                    )}
                  </Row>
                </Row>
              </Col>
            ))
          }
          {
            documentList.length === 0 &&
            (
              <Row className='equal-container' type='flex' justify='center'>
                <Text>
                  Document not found
                </Text>
              </Row>
            )
          }
          <Modal
            visible={isModalDialog}
            title={`Add Document`}
            className='sm-modal'
            onCancel={() => this.setState({isModalDialog: false})}
            footer={[
              <Button key="back" disabled={isSaving} onClick={() => this.setState({isModalDialog: false})}>
                Cancel
              </Button>,
              <Button key="submit" type="primary" loading={isSaving} onClick={this.onSave}>
                Save
              </Button>,
            ]}
          >
            <Row>
              <Col md={24} sm={24}>
                <Row span={24}>
                  <Col style={{
                    padding: '24px 12px',
                    overflow: 'auto',
                  }}>
                    <FormProvider>
                      <Form layout="horizontal">
                        {isModalDialog && this.documentFields && this.documentFields.map((elem, index) => {
                          return (
                            <DynamicFieldHalfScreen
                              {...elem}
                              key={index}
                              userReducer={this.props.userReducer}
                              normFile={this.normFile}
                              fileProps={this.dataUpload()}
                            />
                          )
                        })}
                        <FormContext.Consumer>
                          {(form) => {this.form = form}}
                        </FormContext.Consumer>
                      </Form>
                    </FormProvider>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Modal>
        </Row>
      </Card>
    )
  }
}

PageDocuments.propTypes = {
  userReducer: PropTypes.object.isRequired
}

PageDocuments.defaultProps = {
  userReducer: {}
}

export default PageDocuments
