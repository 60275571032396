import React from 'react'
import {
  Card,
  Col,
  Row,
  Button,
  Form,
  Divider,
  message,
  Tabs,
} from 'antd'
import DynamicField from 'App/Component/DynamicField'
import { FormContext, FormProvider } from 'App/Component/Context/ContextForm'
import { get, set, kebabCase, isEqual } from 'lodash'
import { RequestV2 as Request, Lib } from 'App/Utils'
import moment from 'moment'
import { isArray, isObject, isString } from 'util'
import Lib2 from 'App/Component/Singlepage/Lib2'
import History from "App/Screens/SubModules/Component/History";
import fields from '../../../../Branches/Branch/Management/fields'
const { TabPane } = Tabs;

class ProductDetail extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      activeKey: '1'
    }
    set(this.props.history.location, 'state.dataRecord', props.currentProduct)

    this.onSave = this.onSave.bind(this)
    this.onBlur = this.onBlur.bind(this)
  }

  goTo = (action, url, idData, additionalTitle, dataIdentifier) => {
    Lib2.pushFunc(this.props, this.state, action, url, idData, additionalTitle, dataIdentifier)
  }

  goToOld = (action, url, idData = null, additionTitle, dataidentifier=null) => {
    const { state } = this.props.history.location
    if (!url) {
      // const newState = {
      //   operation: action,
      //   insertColumns: this.props.history.location.state.insertColumns,
      //   title: this.props.history.location.state.title,
      //   url: this.props.history.location.state.url,
      //   urlData: this.props.history.location.state.urlData,
      // }
      // this.props.history.replace(
      //   this.props.history.location.pathname,
      //   newState
      // )
    } else {
      // action, url, idData = null, additionTitle, dataidentifier=null
      const master = kebabCase(url)
      // const app = kebabCase(this.getAppNameFromModel(master))
      const app = 'estimations'
      let from = []
      // if (this.props.history.location.state.from) {
      //   if (this.props.history.location.state.from.length > 0) {
      //     from = [...this.props.history.location.state.from]
      //   }
      // }
      from.push(this.props.history.location.pathname)
      const dataRecord = state && state.dataRecord
      const prevState = {
        insertColumns: this.props.fields,
        dataRecord,
      }
      const title = additionTitle ? additionTitle : url
      // const extraBack = this.props.history.location.state.extraBack
      let storeState = {
        operation: action,
        title: title,
        urlData: kebabCase(url),
        url: kebabCase(url),
        from,
        prevState,
        // extraBack,
      }

      if (action === 'edit') {
        let pk = Lib.checkValues({idData}).idData
        let urlTarget = '/' + app + '/' + master + '/' + pk
        storeState.idData = idData
        this.props.history.push(urlTarget, storeState)
      } else {
        let urlTarget = '/' + app + '/' + master + '/' + action
      //   if (dataidentifier && !dataidentifier.hasOwnProperty('pk')) {
      //     const tempDataRecord = this.props.history.location.state.dataRecord
      //     // ganti code dibawah ini
      //     // dengan mencari selectedValue berdasarkan selectedValue di columns
      //     const key = tempDataRecord.pk
      //     const selectedValue = tempDataRecord.label ? 'label' : 'name'
      //     const label = tempDataRecord[selectedValue]
      //     let fieldName = snakeCase(this.props.history.location.state.title)
      //     if (fieldName.slice(-1) === 's') {
      //       fieldName = fieldName.substring(0, fieldName.length - 1)
      //     }
      //     dataidentifier = {
      //       [fieldName]: {
      //         pk: key,
      //         [selectedValue]: label,
      //       }
      //     }
      //   }
      //   storeState.dataidentifier = dataidentifier
        this.props.history.push(urlTarget, storeState)
      }
    }
  }

  onSave(event) {
    if (!this.state.isLoading) {
      const {validateFieldsAndScroll, resetFields} = this.form
      validateFieldsAndScroll({scroll: {offset: 150}}, (err, values) => {
        if (!err) {
          const { currentProduct } = this.props
          if (currentProduct) {
            this.update(values, currentProduct.pk, resetFields)
          }
        }
      })
    }
  }

  update(values, pk, resetFields) {
    this.setState({ isLoading: true }, () => {
      const valuesConverted = Lib2.getValuesWithFile(values)
      Request(
        'put',
        'module-product-update',
        {},
        valuesConverted,
        [pk],
        res => this.updateSuccess(res),
        err => this.updateFailed(err),
      )
    })
  }

  updateSuccess(response) {
    this.setState({
      isLoading: false,
    }, () => {
      message.success('Update success')
      set(this.props.history.location, 'state.isChanged', false)
      this.props.refresh()
    })
  }

  updateFailed(error) {
    setTimeout(() => {
      this.setState({
        isLoading: false,
      }, () => {
        const errCode = error.response && error.response.status
        const errText = error.response && error.response.statusText
        message.error(`${errText} - code:${errCode}`)
      })
    }, 100)
  }

  getCurrentTab(pathname) {
    if (this.isCurrentTab) {
      return true
    } else {
      const urlTab = this.getUrlTab(pathname)
      if (urlTab === 'detail') {
        return true
      }
    }
    return false
  }

  listenHistory = (history) => {
    const urlTab = this.getUrlTab(history.pathname)
    if (urlTab === 'detail') {
      this.isCurrentTab = true
    } else {
      this.isCurrentTab = false
    }
  }

  getUrlTab(pathname) {
    const pathnameSplited = pathname.split('/').filter(word => word)
    return pathnameSplited[pathnameSplited.length - 1]
  }

  // cvCurrentProduct(currentProduct) {
  //   let cP = {...currentProduct}
  //   const { state } = this.props.history.location
  //   if (state) {
  //     const { dataRecord } = state
  //     if (dataRecord) {
  //       for (const i in dataRecord) {
  //         if (isArray(dataRecord[i])) {
  //           if (cP[i]) {
  //             cP[i] = dataRecord[i].concat(cP[i])
  //           }
  //         } else {
  //           cP[i] = dataRecord[i]
  //         }
  //       }
  //     }
  //   }
  //   this.setState({
  //     currentProductWithNext: cP,
  //     isLoading: false,
  //   })
  // }

  onBlurOld(e, title, type='text') {
    const stackKey = {
      text: ['target', 'value'],
      textarea: ['target', 'value'],
      number: ['target', 'value'],
      email: ['target', 'value'],
      password: ['target', 'value'],
      // checkbox: ['target', 'checked'],
      checkbox: [], // New Switch
      select: [],
      SelectQuery: [],
      select_multiple: [],
      date: [],
      datetime: [],
      // table, file, fileimg not yet
    }

    const format = {
      date: 'YYYY-MM-DD',
      datatime: 'YYYY-MM-DD HH:mm:ss'
    }

    if (!title) {
      return
    }
    if (isString(title)) {
      for (const tempType in stackKey) {
        if (tempType === type) {
          let tempValue = e
          if (isArray(e)) {
            e.map((obj, i) => tempValue[i] = {...obj})
          } else if (isObject(e)) {
            tempValue = {...e}
          }
          for (const keys of stackKey[type]) {
            tempValue = tempValue[keys]
          }

          // date, datetime, SelectQuery and select_multiple
          if (isObject(tempValue)) {
            if (moment.isMoment(tempValue)) {
              this.recordPage({
                title,
                value: moment(tempValue).format(format[type]),
              })
            } else {
              this.cvSQ(tempValue, title)
              this.recordPage({
                title,
                value: tempValue
              })
            }
          } else {
            this.recordPage({
              title,
              value: tempValue
            })
          }
          break
        }
      }
    }
    return
  }

  onBlur = (value, dataIndex, type='text', form) => {
    let valueConverted = value
    const force = type === 'checkbox'
    if (type === 'number' && !valueConverted) {
      const field = this.props.fields.find(field => field.dataIndex === dataIndex)
      form.setFieldsValue({ [dataIndex]: get(field, 'initialValue', 0) })
    }

    if (type === 'date') {
      valueConverted = moment(valueConverted).format('YYYY-MM-DD')
    } else if (type === 'datetime') {
      valueConverted = moment(valueConverted).format('YYYY-MM-DD HH:mm:ss')
    }

    this.recordPage({ title: dataIndex, value: valueConverted }, force)
  }

  isChange(dataRecord, values) {
    let willChange = true
    if (dataRecord && dataRecord[values.title]) {
      for (const keys in dataRecord) {
        if (keys === values.title) {
          if (dataRecord[keys] === values.value) {
            willChange = false
          }
          break // for single values
        }
      }
    } else if (!values.value) {
      willChange = false
    }
    return willChange
  }

  // send object for record like {title: dataIndex, value: 123}
  recordPage(values, force) {
    if (!this.isChange(get(this.props, 'history.location.state.dataRecord'), values)) {
      return
    }
    if (force === true || this.isChange(get(this.props, 'history.location.state.dataRecord'), values)) {
      let dataRecord = get(this.props, 'history.location.state.dataRecord')
      if (!dataRecord) {
        dataRecord = {
          [values.title]: values.value
        }
      } else {
        dataRecord = {
          ...dataRecord,
          [values.title]: values.value
        }
      }
      set(this.props.history.location, 'state.isChanged', true)
      // this.props.history.replace(
      //   this.props.history.location.pathname,
      //   {
      //     ...this.props.history.location.state,
      //     dataRecord,
      //   }
      // )
    }
  }

  // send objKey{selectedKey, selectedValue}
  // if you dont by reference
  cvSQ(objSelectQuery, dataIndex, objKey) {
    if (objKey) {
      // will return without change original variable
      objSelectQuery = {
        [objKey.selectedKey]: objSelectQuery.key,
        [objKey.selectedValue]: objSelectQuery.label,
      }
      return objSelectQuery
    } else {
      if (isArray(objSelectQuery)) {
        // will change original variable
        const keys = this.getSelectedObjectSQ(dataIndex, this.props.history.location.state.insertColumns)
        for (const index in objSelectQuery) {
          objSelectQuery[index][keys[0]] = objSelectQuery[index].key
          objSelectQuery[index][keys[1]] = objSelectQuery[index].label
          // Please dont delete keys of default {key, label}
        }
        return true
      } else {
        const keys = this.getSelectedObjectSQ(dataIndex, this.props.history.location.state.insertColumns)
        objSelectQuery[keys[0]] = objSelectQuery.key
        objSelectQuery[keys[1]] = objSelectQuery.label
        this.deleteKeys(objSelectQuery, keys)
        return true
      }
    }
  }

  deleteKeys(obj, excKeys = []) {
    let willRemove = []
    for (const tempKey in obj) {
      willRemove.push(tempKey)
      for (const value of excKeys) {
        if (value === tempKey) {
          willRemove.pop()
          break
        }
      }
    }
    for (const value of willRemove) {
      delete obj[value]
    }
  }

  getSelectedObjectSQ(dataIndex, columns=[]) {
    for (const index in columns) {
      if (columns[index].dataIndex === dataIndex) {
        const selectedKey = columns[index].selectedKey
        const selectedValue = columns[index].selectedValue
        return ([selectedKey, selectedValue])
      }
    }
    return []
  }

  componentDidMount() {
    const { state } = this.props.history.location
    if (!state) {
      this.props.history.location.state = {}
      this.props.history.location.state.dataRecord = {...this.props.currentProduct}
    } else {
      if (!state.dataRecord) {
        this.props.history.location.state.dataRecord = {...this.props.currentProduct}
      }
    }
    this.setState({ isLoading: false })
    this.unlisten = this.props.history.listen(this.listenHistory)
  }


  componentWillUnmount() {
    this.unlisten()
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { currentProduct } = this.props
    const nextProduct = nextProps.currentProduct
    if (currentProduct && nextProduct) {
      if (!isEqual(currentProduct, nextProduct)) {
        return true
      } else if (this.state.isLoading !== nextState.isLoading) {
        return true
      }
    }
    return false
  }

  render() {
    const dataRecord = get(this.props.history.location.state, 'dataRecord')
    const { readOnly, refresh } = this.props
    // let dataRecord
    // if (state) {
    //   dataRecord = state.dataRecord
    // }
    return (
      <Card>
        <Tabs 
          defaultActiveKey="1"
          tabPosition={"left"}
        >
          <TabPane tab="Edit" key="1">
            <Col span={24}>
              <Row>
                <FormProvider>
                  <Form layout="horizontal">
                    {/* {this.getCurrentTab(this.props.history.location.pathname) && this.props.fields.map((elem, index) => { */}
                    {this.props.fields.map((elem, index) => {
                      const dataField = elem && get(dataRecord, elem.dataIndex)
                      return (
                        <DynamicField
                          {...elem}
                          operation={readOnly ? 'view' : 'edit'}
                          key={index}
                          userReducer={this.props.userReducer}
                          dataRecord={dataField}
                          toggleDrawerSQ={this.goTo}
                          // normFile={this.normFile}
                          // fileProps={this.dataUpload()}
                          onBlur={this.onBlur}
                        />
                      )
                    })}
                    <FormContext.Consumer>
                      {(form) => {this.form = form}}
                    </FormContext.Consumer>
                  </Form>
                </FormProvider>
              </Row>
              {/* <Divider type='horizontal' style={{margin: '12px 0'}}/> */}
              <Row type='flex' justify='space-between' align='middle' style={{ backgroundColor: '#fff', padding: '12px 0', borderTop: '1px solid #e8e8e8'}}>
                <Col>
                  {/* <Button type='danger'>Button Danger</Button> */}
                </Col>
                <Col>
                  {!readOnly && (
                    <Button icon={this.state.isLoading ? 'loading' : 'save'} type='primary' onClick={this.onSave}>
                      Update
                    </Button>
                  )}
                </Col>
              </Row>
            </Col>
            </TabPane>
            <TabPane tab="History" key="2">
              <History 
                id={get(dataRecord, 'pk')} 
                url="module-product-history" 
                fields={this.props.fields}
                urlDetail="module-product-history-detail" 
                updateHeader={(data)=>this.props.history.push('/product/product')}
                title={get(dataRecord, 'name')} 
              />
            </TabPane>
        </Tabs>
      </Card>
    )
  }
}

export default ProductDetail
