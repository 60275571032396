import {createStore, combineReducers, applyMiddleware} from "redux";
import thunk from "redux-thunk"

import Reducer from "./Reducer.js";

export default createStore(
  combineReducers(Reducer),
  {},
  applyMiddleware(thunk)
);
