import { BaseList } from "App/Screens/SubModules/Component";
import descriptor from "App/Screens/Job/JobGrades/descriptor";

class JobGradeList extends BaseList {
  constructor(props) {
    super(props)

    this.appName = 'jobs'
    this.modelName = 'jobgrade'
    this.availablePermissions = ['.view_', '.add_', '.change_']
    this.propsList = {
      avatar: 'level',
      pageTitle: 'Job Grade',
      descriptor,
      masterKey: 'module-job-jobGrade',
    }
    this.state = {
      ...this.state,
      currentPermissionSet: this.isSuperuser ? this.availablePermissions : [],
    }
  }
}

export default JobGradeList