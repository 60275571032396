import React from "react";
import { Icon } from "antd";

import { ReactComponent as CircleIcon } from "App/Assets/img/circle.svg";
import { ReactComponent as SquareIcon } from "App/Assets/img/square.svg";
import { ReactComponent as TriangleIcon } from "App/Assets/img/triangle.svg";
import { ReactComponent as DiamonIcon } from "App/Assets/img/diamond.svg";

export const MEASURE_TYPES = [
  {
    alias: "area",
    value: "Area",
  },
  {
    alias: "length",
    value: "Length",
  },
  {
    alias: "polylength",
    value: "Polylength (Perimeter)",
  },
  {
    alias: "count",
    value: "Count",
  },
];

export const MEASURE_COUNT_SHAPES = [
  {
    alias: "checkmark",
    value: (
      <span>
        <Icon type="check" title="Checkmark" /> Checkmark
      </span>
    ),
  },
  {
    alias: "triangle",
    value: (
      <span>
        <Icon component={TriangleIcon} title="Triangle" /> Triangle
      </span>
    ),
  },
  {
    alias: "square",
    value: (
      <span>
        <Icon component={SquareIcon} title="Square" /> Square
      </span>
    ),
  },
  {
    alias: "circle",
    value: (
      <span>
        <Icon component={CircleIcon} title="Circle" /> Circle
      </span>
    ),
  },
  {
    alias: "diamond",
    value: (
      <span>
        <Icon component={DiamonIcon} title="Diamond" /> Diamond
      </span>
    ),
  },
];
