import React from 'react'
import PageImages from './PageImages'
import PropTypes from 'prop-types'
import LoadingSkleton from 'App/Component/LoadingSkleton'
import { message } from 'antd'
import { RequestV2 as Request } from 'App/Utils'
import { get, snakeCase, isEqual } from 'lodash'
import ComponentHelper from 'App/Component/Singlepage/Lib2'

class PageDocumentation extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      next: null,
      imagesRecord: [],
    }

    this.readSuccess = this.readSuccess.bind(this)
    this.readFailed = this.readFailed.bind(this)
    this.saveImage = this.saveImage.bind(this)
    this.preSaveSuccess = this.preSaveSuccess.bind(this)
    this.saveSuccess = this.saveSuccess.bind(this)
    this.preDeleteSuccess = this.preDeleteSuccess.bind(this)
    this.deleteSuccess = this.deleteSuccess.bind(this)
  }

  getPageImages() {
    if (this.state.isLoading) {
      return (
        <LoadingSkleton count={5} />
      )
    } else {
      return (
        <PageImages
          location={this.props.location}
          readOnly={this.props.readOnly}
          currentProduct={this.props.currentProduct}
          userReducer={this.props.userReducer}
          imagesRecord={this.state.imagesRecord}
          removeImage={(ids, cb) => this.removeImage(ids, cb)}
          saveImage={this.saveImage}
          next={this.state.next}
          loadMore={(url, callback) => this.loadMore(url, () => callback())}
          refresh={() => this.setState({ isLoading: true }, () => this.read())}
          ref={ref => this.props.setRef(ref)}
        />
      )
    }
  }

  removeImage(ids = [], cb = () => null) {
    // this.props.setLoading(true, () => {
    this.stackHasRemoved = 0
    this.detailBulk = []
    for (let i = 0; i < ids.length; i += 1) {
      this.detailBulk[i] = { pk: ids[i], deleted: false }
      const extra = { ids, id: ids[i], i, cb }
      Request(
        'delete',
        this.props.urlData + '-delete',
        {},
        {},
        [this.props.parentId, ids[i]],
        this.preDeleteSuccess,
        err => this.preDeleteFailed(err, extra),
        extra,
      ) 
    }
    // })
  }

  preDeleteSuccess(response, extra) {
    this.stackHasRemoved += 1
    const ids = get(extra, 'ids') || []
    this.detailBulk[extra.i].deleted = true
    if (this.stackHasRemoved === ids.length) {
      this.deleteSuccess(null, extra) // change null to stacks of response
    }
  }

  deleteSuccess(response, extra) {
    const { imagesRecord } = this.state
    const somePkDeleted = []
    for (const i in imagesRecord) {
      for (let j = 0; j < get(extra, 'ids', []).length; j += 1) {
        if (imagesRecord[i].pk === extra.ids[j]) {
          if (imagesRecord[i].is_thumbnail) {
            this.props.refresh()
            return
          }
          const detail = this.detailBulk.find(item => item.pk === imagesRecord[i].pk)
          if (detail && detail.deleted) {
            somePkDeleted.push(detail.pk)
          }
          break
        }
      }
    }

    this.refreshImage(somePkDeleted, extra.cb)
  }

  preDeleteFailed = (error, extra) => {
    this.stackHasRemoved += 1
    const ids = get(extra, 'ids') || []
    if (this.stackHasRemoved === ids.length) {
      this.deleteFailed(null, extra)
    }
    this.failReqMsg(error)
  }

  deleteFailed = (response, extra) => {
    if (this.detailBulk.filter(item => item).length > 0) {
      this.deleteSuccess(null, extra)
    }
  }

  refreshImage(somePkDeleted, cb = () => null) {
    const remaining = this.state.imagesRecord.filter(item => somePkDeleted.findIndex(pk => pk === item.pk) === -1)
    this.setState({
      imagesRecord: remaining
    }, () => cb(somePkDeleted))
  }

  saveImage(data, fields, cb = () => null) {
    const tempValues = ComponentHelper.getValuesWithFileV2(data, fields)
    const staticTitleLoop = 'image'
    const arrs = data[staticTitleLoop]
    const withLoading = tempValues.get('is_thumbnail') === 'true' ||
      !this.props.currentProduct.image_thumbnail
    this.props.setLoading(withLoading, () => {  
      const stackValues = []
      this.stackHasSaved = 0
      this.detailBulk = []
      for (let i = 0; i < arrs.length; i += 1) {
        stackValues[i] = ComponentHelper.getValuesWithFileV2(data, fields, [i])
        const extra = { withLoading, numStacks: arrs.length, cb }
        Request(
          'post',
          this.props.urlData + '-create',
          {},
          stackValues[i],
          [this.props.parentId],
          this.preSaveSuccess,
          err => this.preSaveFailed(err, extra),
          extra,
        )
      }
    })
  }

  preSaveSuccess(response, extra) {
    this.stackHasSaved += 1
    if (this.stackHasSaved === get(extra, 'numStacks')) {
      this.saveSuccess(null, extra) // change null to stacks of response
    }
  }

  saveSuccess(response, extra) {
    message.success('Save success')
    if (!get(extra, 'withLoading')) {
      extra.cb()
      this.read()
    } else {
      this.props.refresh(null, false)      
    }
  }

  preSaveFailed = (error, extra) => {
    this.stackHasSaved += 1
    if (this.stackHasSaved === get(extra, 'numStacks')) {
      setTimeout(() => {
        message.error('Something when wrong when saving images, please refresh list images')
      }, 500)
      extra.cb()
      this.read()
      if (get(extra, 'withLoading')) this.props.refresh(null)
    }
    this.failReqMsg(error)
  }

  failReqMsg(error) {
    if (error.response) {
      const errCode = error.response && error.response.status
      const errText = error.response && error.response.statusText
      if (errCode) {
        this.props.setLoading(false, () => {
          message.error(`${errText} - code:${errCode}`)
        })
      }
    }
  }

  convertDataIndex(data, fields) {
    let dataConverted = {}
    for (const field of fields) {
      const formKey = snakeCase(field.title)
      if (data.hasOwnProperty(formKey)) {
        dataConverted[field.dataIndex] = data[formKey]
      }
    }
    return dataConverted
  }

  read() {
    Request(
      'get',
      this.props.urlData + '-read',
      {},
      {},
      [this.props.parentId],
      this.readSuccess,
      this.readFailed,
    )
  }

  readSuccess(response) {
    if (response) {
      this.setResolution(
        response.data.results,
        () => {
          this.setState({
            isLoading: false,
            imagesRecord: response.data.results,
            next: response.data.next,
          })
        }
      )
    }
  }

  setResolution(images, callback) {
    let image = [], count = 0

    if (images.length === 0) {
      callback()
    } else {
      for (let i = 0; i < images.length; i++) {
        image[i] = new Image()
        image[i].src = images[i].file
        // this.imgOnLoad(image[i], images)
        image[i].onload = () => {
          images[i].width = image[i].width
          images[i].height = image[i].height
          images[i].a = 1
          count = count + 1
          if (count === images.length) {
            callback()
          }
        }
      }
    }
  }

  readFailed(error) {
    this.setState({
      isLoading: false,
      imagesRecord: [],
    })
  }

  loadMore(url, callback) {
    if (this.props.location.pathname.includes('images')) {
      Request(
        'get',
        this.props.urlData + '-read',
        {},
        this.getObjParams(this.state.next),
        [this.props.parentId],
        res => this.loadMoreSuccess(res, callback),
        err => { message.error('cannot get next image'); callback() },
      )
    }
  }

  loadMoreSuccess(response, callback) {
    let imagesRecord = this.state.imagesRecord
    imagesRecord = imagesRecord.concat(response.data.results)
    this.setState({
      imagesRecord,
      next: response.data.next,
    }, () => callback())
  }

  getObjParams(strUrl) {
    let objParams = {}
    if (strUrl) {
      const url = new URL(strUrl)
      const urlParams = new URLSearchParams(url.search)
      for (const val of urlParams.entries()) {
        objParams[val[0]] = val[1]
      }
    }
    return objParams
  }

  componentDidMount() {
    this.setState({ isLoading: true }, () => {
      this.read()
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { currentProduct } = this.props
    const nextProduct = nextProps.currentProduct
    if (currentProduct && nextProduct) {
      const key = 'image_thumbnail'
      if (currentProduct[key] && nextProduct[key]) {
        if (!isEqual(currentProduct[key], nextProduct[key])) {
          this.read()
        }
      }
    }
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   const { currentProduct } = this.props
  //   const nextProduct = nextProps.currentProduct
  //   if (currentProduct && nextProduct) {
  //     const key = 'image_thumbnail'
  //     if (currentProduct[key] && nextProduct[key]) {
  //       if (!isEqual(currentProduct[key], nextProduct[key])) {
  //         this.read()
  //       }
  //     }
  //   }
  //   return true
  // }

  render() {
    return (
      this.getPageImages()
    )
  }
}

PageDocumentation.propTypes = {
  userReducer: PropTypes.object
}

PageDocumentation.defaultProps = {
  userReducer: {
    token: null
  }
}

export default PageDocumentation
