import React from 'react'
import {capitalize, kebabCase, get} from 'lodash'
import {Avatar, Icon, Tabs, Row, Col, Tooltip} from 'antd';
import AdvancedTable from "./AdvancedTable";
import { forcePermissions } from "App/Data";

const { TabPane } = Tabs;

export default {

  tabReferenceTo(tab, goTo, tabindex, dataidentifier, urlData, data){
    let tabpane = tab && tab.map((elem, index) => {
      return (
        <TabPane
          tab={
            <Tooltip placement='left' title={capitalize(elem.label)}>
              <Row type='flex' style={{marginLeft: "6px"}}>
                <Col xl={0} lg={0} md={0}>
                  <Avatar style={{backgroundColor: parseInt(tabindex-1000) === index ? "#4BA7FF":"#585858"}} size={20}>
                    <small>{capitalize(elem.label.charAt(0))}</small>
                  </Avatar>
                </Col>
                <Col md={24} sm={0} xs={0} style={{overflow: 'hidden', marginLeft: '6px'}}>
                  <Icon type="api" style={{color: "#f2637b"}} /> {capitalize(elem.label)}
                </Col>
              </Row>
            </Tooltip>
          }
          key={index+1000}
        >
          <AdvancedTable
            {...data}
            urldata={kebabCase(urlData)}
            index={tabindex === -1 ? false : tab[tabindex-1000]}
            goTo ={goTo}
            reference={true}
            dataidentifier={dataidentifier}
            url_name={get(tab, `${[tabindex-1000]}.url_name`)}
            permissions={elem.permissions}
          />

        </TabPane>
      )
    })
    return tabpane
  },

  tabReferenceBy(tab, goTo, tabindex, dataidentifier, urlData, data) {
    let tabpane = tab && tab.map((elem, index) => {
      return (
        <TabPane
          tab={
            <Tooltip placement='left' title={capitalize(elem.label)}>
              <Row style={{marginLeft: "6px"}}>
								<Col xl={0} lg={0} md={0}>
                  <Avatar style={{backgroundColor: parseInt(tabindex) === index ? "#4BA7FF":"#585858"}} size={20}>
                    <small>{capitalize(elem.label.charAt(0))}</small>
                  </Avatar>
                </Col>
                <Col md={24} sm={0} xs={0} style={{overflow: 'hidden', marginLeft: '6px'}}>
                  <Icon type="share-alt" /> {capitalize(elem.label)}
                </Col>
              </Row>
            </Tooltip>
          }
          key={index}
        >
          <AdvancedTable
            {...data}
            urldata={kebabCase(urlData)}
            index={tabindex === -1 ? false : tab[tabindex]}
            goTo ={goTo}
            reference={true}
            dataidentifier={dataidentifier}
            url_name={get(tab, `${[tabindex]}.url_name`)}
            permissions={elem.permissions}
          />

        </TabPane>
      )
    })
    return tabpane
  }

}
