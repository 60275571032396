import React from 'react'
import { Card, Row, Col, Button, message, Modal } from 'antd'
import { get } from 'lodash'
import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import styled from 'styled-components'
import { RequestV2 as Request } from 'App/Utils'
import LoadingSkleton from 'App/Component/LoadingSkleton'
import UploadAdapter from './UploadAdapter'

import 'App/Component/Singlepage/SingleStyle.css'

const { confirm } = Modal
const configEditor = {
  // 0: "heading"
  // 1: "|"
  // 2: "bold"
  // 3: "italic"
  // 4: "link"
  // 5: "bulletedList"
  // 6: "numberedList"
  // 7: "|"
  // 8: "indent"
  // 9: "outdent"
  // 10: "|"
  // 11: "imageUpload"
  // 12: "blockQuote"
  // 13: "insertTable"
  // 14: "mediaEmbed"
  // 15: "undo"
  // 16: "redo"
  toolbar: ['heading', '|', 'bold', 'italic', 'link', '|', 'bulletedList', 'numberedList', '|', 'imageUpload', 'blockQuote', '|', 'undo', 'redo'],
  placeholder: 'Write notes here',
}

class PageNotes extends React.Component {
  feItem = []
  state = {
    isLoading: true,
    notes: [],
    next: null,
    editorIsBlank: true,
    isSaving: false,
    height: '100px',
    positionItem: [],
  }

  getNotesData = () => {
    if (this.state.isLoading && this.state.notes.length === 0) {
      return (
        <LoadingSkleton count={5} />
      )
    } else if (this.state.notes.length === 0) {
      return (
        <Row type='flex' justify='center'>
          No notes to display
        </Row>
      )
    } else {
      const colorEditor = [
        '#ccfc90',
        '#a7ffeb',
        '#fafafa',
        '#fefc8d',
      ]
      // let positionItem = this.state.positionItem
      // const containerHeight = this.containerHeight || 0
      return (
        <Row
          type='flex' align='top' gutter={[11, 11]}
          style={{
          //   // position: 'relative',
          //   // width: '100%',
          //   // height: containerHeight,
          }}
        >
          { this.state.notes.map((item, index) => {
            // if (!positionItem[index]) {
            //   positionItem[index] = {}
            // }
            return (
              <Col key={item.pk}
                xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}
                // style={{
                //   ...positionItem[index],
                // }}
              >
                <div className='equal-container absolute-anchor' ref={feItem => this.feItem[index] = feItem} />
                <Row className='ck-read-only' style={{
                  backgroundColor: colorEditor[(index % colorEditor.length)],
                  // height: index === 1 ? this.state.height : 'auto',
                }}>
                  <StyledCKEditor
                    editor={ClassicEditor}
                    config={{toolbar: []}}
                    data={item.note}
                    disabled={true}
                    onInit={editor => {
                      // this.setNotesLayout(index) // <-- Setting location of notes
                      const container = get(editor, ['ui', 'view', 'element'])
                      if (container) {
                        // Set anchor attribute to new tab
                        const anchorElements = container.getElementsByTagName('a')
                        for (let i = 0; i < anchorElements.length; i += 1) {
                          anchorElements[i].setAttribute('target', '_blank')
                          anchorElements[i].setAttribute('rel', 'noopener noreferrer')
                        }
                        // Hide image caption if don't have text information
                        const captionElements = container.getElementsByTagName('figcaption')
                        for (let i = 0; i < captionElements.length; i += 1) {
                          // Jika innerText hanya newLine, sembunyikan. Tapi masih ada bug
                          if (!captionElements[i].innerText.replace(/\r?\n|\r/g, '')) {
                            captionElements[i].style.display = 'none'
                          }
                        }
                      }
                    }}
                  />
                  {!this.props.readOnly && (
                    <div className='overlay abs-tr bg-transparent'>
                      <Button className='gutter-12' icon='delete' type='danger' onClick={e => this.handleDelete(e, item.pk)} />
                    </div>
                  )}
                </Row>
              </Col>
            )
          })}
        </Row>
      )
    }
  }

  setNotesLayout(index) {
    /** parent:
      xs={24} ==> <576
      sm={24} ==> >=576
      md={24} ==> >=768
      lg={12} ==> >=992
      xl={8} ==> >=1200
      xxl={6} ==> >=1600
    */
    const { notes } = this.state
    if (notes.length > 0) {
      if (index === notes.length - 1) {
        const innerWidth = window.innerWidth
        let cols = 1
        if (innerWidth >= 1600) {
          cols = 4
        } else if (innerWidth >= 1200) {
          cols = 3
        } else if (innerWidth >= 992) {
          cols = 2
        }
        let positionItem = []
        let topPosition = [...Array(cols)].map(w => 0)
        const width = this.feItem[0].offsetWidth
        for (const i in this.feItem) {
          let indexPosition = 0
          const minTop = Math.min(...topPosition)
          for (const j in topPosition) {
            if (topPosition[j] === minTop) {
              indexPosition = j
              break
            }
          }

          const translateX = width * indexPosition
          const translateY = topPosition[indexPosition]
          const transform = `translateX(${translateX}px) translateY(${translateY}px)`
          topPosition[indexPosition] += this.feItem[i].offsetHeight
          positionItem.push({
            position: 'absolute',
            top: 0, left: 0,
            transform,
          })
        }
        this.containerHeight = Math.max(...topPosition) + 'px'
        this.setState({ positionItem })
      }
    }
  }

  getThisNotes = () => {
    const note = this.trimElement(this.activeEditor.getData())
    return { note }
  }

  onSave = () => {
    this.setState({
      isSaving: true,
    }, () => {
      const values = this.getThisNotes()
      Request(
        'post',
        this.props.urlData + '-create',
        {},
        values,
        [this.props.parentId],
        res => this.saveSuccess(res),
        err => message.error('Save failed'),
      )
    })
  }

  saveSuccess(response) {
    // const remainingNotes = this.state.notes.filter(item => item.pk !== response.data.pk)
    this.setState(prevState => ({
      // isLoading: true,
      notes: [response.data, ...prevState.notes],
      isSaving: false,
    }), () => {
      // this.read()
    })
    // let notes = this.state.notes
    // const newNote = { ...response.data }
    // notes.splice(0, 0, newNote)
    // this.setState({
    //   notes,
    // }, () => {
    //   this.setState({
    //     isSaving: false,
    //   })
    this.activeEditor.setData('')
    message.success('Save success')
    // })
  }

  trimElement(element) {
    // Not yet
    return element
  }

  handleDelete(event, pk) {
    event.stopPropagation()
    event.preventDefault()

    const opts = {
      title: 'Delete Notes',
      content: 'Are you sure want to delete this notes?',
      okText: 'Yes',
      okType: 'danger',
      onOk: () => (
        new Promise((resolve, reject) => {
          this.deleteNote(pk, isSuccess => {
            if (isSuccess) {
              resolve()
            } else {
              reject()
              message.error('Delete failed, cannot connect to server')
            }
          })
        })
      )
    }

    this.confirmDialog(opts)
  }

  confirmDialog = (opts, callback = () => null) => {
    confirm({
      title: 'Please confirm',
      content: 'The next action requires confirmation',
      onOk: () => {
        callback(true)
      },
      onCancel: () => {
        callback(false)
      },
      ...opts,
    })
  }

  deleteNote(pk, callback = () => null) {
    Request(
      'delete',
      this.props.urlData + '-delete',
      {},
      {},
      [this.props.parentId, pk],
      (res, extra) => {
        callback(true)
        this.deleteSuccess(res, extra.pk)
      },
      () => callback(false),
      { pk, callback },
    )
  }

  deleteSuccess(response, pk) {
    const remainingNotes = this.state.notes.filter(item => item.pk !== pk)
    this.setState({
      notes: remainingNotes,
    }, () => {
      message.success('Notes has been deleted')
    })
  }

  read() {
    Request(
      'get',
      this.props.urlData + '-read',
      {},
      {},
      [this.props.parentId],
      res => this.readSuccess(res),
      err => this.readFailed(err),
    )
  }

  readSuccess(response) {
    if (response) {
      this.setState({
        isLoading: false,
        notes: response.data.results,
        next: response.data.next,
      })
    } else {
      this.setState({
        isLoading: false,
      })
    }
  }

  readFailed(err) {
    this.setState({
      isLoading: false,
    }, () => {
      this.failReqMsg(err)
    })
  }

  failReqMsg(error) {
    if (error.response) {
      if (error.response.status) {
        const errCode = error.response && error.response.status
        const errText = error.response && error.response.statusText
        message.error(`${errText} - code:${errCode}`)
      }
    }
  }

  componentDidMount() {
    this.read()
  }

  render() {
    const { readOnly } = this.props
    const { isLoading } = this.state

    return (
      <Card>
        <Row>
          <Col span={24}>
            <Row>
              <Col sm={12} xs={12}>
                <h2>Notes</h2>
              </Col>
              <Col sm={12} xs={12}>
                <Row type='flex' justify='end'>
                  <Button
                    loading={isLoading}
                    icon='reload'
                    onClick={() => this.setState({ isLoading: true }, () => this.read())}
                    style={{ marginRight: '12px' }}
                  >Refresh</Button>
                  <Button
                    type='primary'
                    icon={'save'}
                    loading={this.state.isSaving}
                    disabled={this.state.editorIsBlank || readOnly}
                    onClick={() => this.onSave()}
                  >Save</Button>
                </Row>
              </Col>
            </Row>
            <StyledRow>
              {!readOnly && (
                <CKEditor
                  editor={ClassicEditor}
                  config={configEditor}
                  onInit={editor => {
                    this.activeEditor = editor
                    editor.ui.view.editable.element.spellcheck = false
                    editor.editing.view.change( writer => {
                      writer.setAttribute( 'spellcheck', 'false', editor.editing.view.document.getRoot() );
                    } );
                    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
                      return new UploadAdapter({ loader, userReducer: this.props.userReducer, currentProductId: this.props.parentId });
                    }
                  }}
                  // onBlur={(event, editor) => this.note = editor.getData()}
                  onChange={(event, editor) => {
                    let isBlank = !editor.getData()
                    if (this.state.editorIsBlank !== isBlank) {
                      // const height = isBlank ? '100px' : '300px'
                      this.setState({
                        // height,
                        editorIsBlank: isBlank,
                      })
                    }
                  }}
                >
                </CKEditor>
              )}
            </StyledRow>
            { this.getNotesData() }
          </Col>
        </Row>
        {/* <TestLayout /> */}
      </Card>
    )
  }
}

export default PageNotes

// width: 500px !important;
const StyledCKEditor = styled(CKEditor)`
  width: 100% !important;
`

const StyledRow = styled(Row)`
  margin-bottom: 0.5em;
`
