import React from 'react'
import { BaseList } from 'App/Screens/SubModules/Component'
import descriptor from './descriptorOffline'
import ChartJobQueue from './Component/QueueSummary'

class JobQueueList extends BaseList {
  constructor(props) {
    super(props)

    this.appName = 'jobs'
    this.modelName = 'jobqueue'
    this.availablePermissions = ['.view_', '.change_'] // jika mengganti ini, juga perlu ganti di admin
    this.propsList = {
      avatar: 'name',
      avatarColorKey: 'branch.color',
      pageTitle: 'Job Queue',
      descriptor,
      masterKey: 'module-jobQueue',
      renderCountCard: this.chartBar,
      operation: false,
      groupByOptions: [
        {
          label: 'Branch',
          key: 'branch.name',
          dataIndex: 'branch.name'
        },
        {
          label: 'Role',
          key: 'role',
          dataIndex: 'role',
        },
      ]
    }
    this.state = {
      ...this.state,
      currentPermissionSet: this.isSuperuser ? this.availablePermissions : [],
    }
  }

  chartBar = (chart_data) => (
    <ChartJobQueue />
  )
}

export default JobQueueList