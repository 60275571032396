import React from "react";
import {kebabCase} from "lodash"
import AdvancedTable from "./Singlepage/AdvancedTable";
import styled from "styled-components";
import {Card} from "antd";

class TemplateTable extends React.Component{

  goTo = (action, url, idData = null, additionTitle, dataidentifier=null) => {
    let masterUrl = this.props.location.pathname
    if (masterUrl.substring(masterUrl.length - 1) === '/') {
      masterUrl = masterUrl.substring(0, masterUrl.length - 1)
    }
    const urlTarget = masterUrl + '/' + (action === 'add' ? action : idData.key)
    if (action === 'view') {
      this.props.history.push(urlTarget, {operation: action})
    } else {
      this.props.history.push(urlTarget)
    }
  };

  render() {
    const {urlData, title} = this.props
    const index = {label: kebabCase(urlData)}
    return (
      <RoundedCard title={title}>
        <AdvancedTable {...this.props} index={index} goTo={this.goTo}/>
      </RoundedCard>
    )
  }
}

const RoundedCard = styled(Card)`
  // border-radius: 12px !important;
  border: 0px !important;
`;

export default TemplateTable;
