import React from 'react'
import {
  Card,
  List,
  Col,
  Typography,
  Descriptions,
  Divider,
  Row, Badge
} from "antd";
import moment from "moment";
import {get} from "lodash"
import {Bar} from "ant-design-pro/lib/Charts";
import { RequestV2 as Request, Lib } from "App/Utils";
const { Title } = Typography;

class Stats extends React.Component {
  state = {
    dataComment: [],
    jobSummary: null,
    isLoading: true
  }

  read(url) {
    let senderId = this.props.match.params.id
    Request(
      'get',
      `${url}-read`,
      {},
      {},
      [senderId],
      res => url === 'jobComment' ? this.retrieveSuccess(res) : this.retrieveSummary(res),
      err => console.log('read failed', err),
    )
  }

  componentDidMount() {
    this.read('jobComment')
    this.read('jobSummary')
  }

  componentWillUnmount() {
    Lib.windowStop();
  }

  retrieveSummary(response){
    let data = response.data
    this.setState({
      jobSummary: data,
      isLoading: false
    })
  }

  retrieveSuccess(response) {
    let data = response.data
    data.splice(3)
    this.setState({
      dataComment: data,
    })
  }

  render() {
    const {jobdetail, history} = this.props
    const {dataComment, jobSummary, isLoading} = this.state
    let summary = []
    if (jobSummary)
      summary = [
        {
          x: `Worksheet`,
          y: jobSummary.saved_schedule
        },
        {
          x: `Saved schedule`,
          y: jobSummary.worksheet.worksheet
        },{
          x: `Worksheet line`,
          y: jobSummary.worksheet.line
        },{
          x: `Worksheet override`,
          y: jobSummary.worksheet.override
        },{
          x: `deduction`,
          y: jobSummary.worksheet.deduction
        },{
          x: `dimension`,
          y: jobSummary.worksheet.dimension
        },{
          x: `note`,
          y: jobSummary.worksheet.note
        },
      ]

    return (
      <>
      <Card
        title={"Job Detail"}
        extra={
          <small>
            Created by {jobdetail.created_by} at {moment(jobdetail.created).format('YYYY-MM-DD HH:mm:ss')}<br/>
            Last modified by {jobdetail.modified_by} at {moment(jobdetail.modified).format('YYYY-MM-DD HH:mm:ss')}
          </small>}
      >
        <Row>
          <Col md={1}>
            <Divider type="vertical" style={{ height: "19rem" }} />
          </Col>
          <Col md={11} xs={24}>
            <div>
              <Badge style={{backgroundColor: "#00d300", zIndex: "1"}} count={jobdetail.status}>
                <Card>
                  <Descriptions className="ant-desc-custom" column={1}>
                    <Descriptions.Item label={"Name"}>
                      {jobdetail.name}
                    </Descriptions.Item>
                    <Descriptions.Item label={"ID"}>
                      #{jobdetail.pk}
                    </Descriptions.Item>
                  </Descriptions>
                </Card>
              </Badge>

              <Title level={4} style={{marginTop: "10px"}} type={"secondary"}>Source</Title>
              <Descriptions className="ant-desc-custom" column={1}>
                <Descriptions.Item label={"Operating branch"}>
                  {get(jobdetail, "operating_branch.name", "-")}
                </Descriptions.Item>
                <Descriptions.Item label={"Branch of origin"}>
                  {jobdetail.origin_branch.name}
                </Descriptions.Item>
                <Descriptions.Item label={"Received"}>
                  {jobdetail.received} {jobdetail.received_time}
                </Descriptions.Item>
                <Descriptions.Item label={"Merchant"}>
                  {jobdetail.merchant.name}
                   </Descriptions.Item>
                <Descriptions.Item label={"Seed Attachment"}>
                  {get(jobdetail, 'seed_file') ? <a target="_blank" href={get(jobdetail, 'seed_file.file')}>{jobdetail.seed_file.file_name}</a> : "----"}
                </Descriptions.Item>
              </Descriptions>
            </div>
          </Col>
          <Col md={1}>
            <Divider type="vertical" style={{ height: "19rem" }} />
          </Col>
          <Col md={11}>
            <div style={{marginTop: "-10px"}}>
              <Title level={4} type={"secondary"}>Job Owner</Title>
              <Descriptions className="ant-desc-custom" column={1}>
                <Descriptions.Item label={"Last queued"}>
                  Central QA
                </Descriptions.Item>
                <Descriptions.Item label={"Person"}>
                  {jobdetail.job_queue.name}
                </Descriptions.Item>
              </Descriptions>
              <Title level={4} type={"secondary"} style={{marginTop: '10px'}}>Schedule and Billing</Title>
              <Descriptions className="ant-desc-custom" column={1}>
                <Descriptions.Item label={"Grade"}>
                  {jobdetail.name}
                </Descriptions.Item>
                <Descriptions.Item label={"Area"}>
                  {get(jobdetail, 'area', "-")}
                </Descriptions.Item>
                <Descriptions.Item label={"Tender"}>
                  {jobdetail.tender ? "Yes" : "No" }
                </Descriptions.Item>
                <Descriptions.Item label={"Commercial"}>
                  {jobdetail.commercial ? "Yes" : "No"}
                  {jobdetail.commercial && <span style={{marginLeft: "60px"}}>Ref : {jobdetail.merchant.ref}</span>}
                </Descriptions.Item>
                <Descriptions.Item label={"Actual start date"}>
                  {jobdetail.actual_start_date ? moment(jobdetail.actual_start_date).format('ll') : "----"}
                </Descriptions.Item>
                <Descriptions.Item label={"Actual finish date"}>
                  {jobdetail.actual_finish_date ? moment(jobdetail.actual_finish_date).format('ll') : "----"}
                </Descriptions.Item>
              </Descriptions>
            </div>

          </Col>
        </Row>
      </Card>
      <Row>
        <Col md={7}>
          <Card
            style={{marginTop: "5px", marginRight: "5px", marginBottom: "5px", height: "345px"}}
            title={"Recent history/comment"}
          >
            <List
              itemLayout="horizontal"
              dataSource={dataComment}
              renderItem={(item, i) => (
                <List.Item key={i}>
                  <List.Item.Meta
                    title={"Created at "+moment(item.created).format('ll')}
                    description={"ID : #" + item.pk}
                  />
                </List.Item>
              )}
            />
            { dataComment.length > 0 &&
              <span style={{ color: '#1890ff', cursor: 'pointer' }} onClick={() => history.push("job-history")}>
                See more...
              </span>
            }
          </Card>
        </Col>
        <Col md={17}>
          <Card
            style={{marginTop: "5px", height: "345px"}}
            title="Job Summary"
            loading={isLoading ? true : false}
          >
          <Bar
            height={239}
            data={summary}
            color={"#8a4bff"}
          />
          </Card>
            </Col>
          </Row>
        </>
    )
  }
}

export default Stats;
