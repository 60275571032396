import React from 'react'
import {
  Row,
  Col,
  Divider,
  Button,
  Typography,
  BackTop,
  Avatar,
  Tooltip,
  Modal,
  Radio,
  message,
  Form,
  Icon,
  Card,
 } from 'antd'
import Youtube from 'react-youtube'
import styled from 'styled-components'
import { get } from 'lodash'
import { RequestV2 as Request } from 'App/Utils'
import { FormContext, FormProvider } from 'App/Component/Context/ContextForm'
import ComponentAutocomplete from 'App/Component/Autocomplete'
import DynamicFieldHalfScreen from 'App/Component/DynamicFieldHalfScreen'
import ComponentHelper from 'App/Component/Singlepage/Lib2'
import LoadingSkleton from 'App/Component/LoadingSkleton'
import { Check } from '../Component'

import 'App/Component/Singlepage/SingleStyle.css'

const { Text, Title, Paragraph } = Typography
const modalConfirm = Modal.confirm

// Link youtube testing
// https://youtube.com/watch?v=1OhlpNVR6UU
// https://www.youtube.com/watch?v=Xtpmxb00hrQ

// https://youtube.com/embed/n6jWLstmetA

const linkPreviewYoutube = 'https://img.youtube.com/vi/{}/hqdefault.jpg'
const opts = {
  playerVars: {
    rel: 0,
    autoplay: 1,
    constrols: 1,
  }
}

class PageVideos extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      videoList: [],
      nextVideoList: null,
      // -------------
      videoId: null,
      player: null,
      currentVideo: null,
      isModalDialog: false,
      isLoading: true,
      isSaving: false,
      selectedIds: [],
    }

    this.onReady = this.onReady.bind(this)
    this.playVideo = this.playVideo.bind(this)
    this.pauseVideo = this.pauseVideo.bind(this)
    this.readSuccess = this.readSuccess.bind(this)
    this.readFailed = this.readFailed.bind(this)
  }

  setThisState = (obj, callback) => {
    for (const key in obj) {
      if (obj[key] !== this.state[key]) {
        this.setState(obj, callback)
        return
      }
    }
    callback()
  }

  onReady(event) {
    this.setState({
      player: event.target,
    }, () => this.playVideo())
  }

  playVideo() {
    if (this.state.player) {
      this.state.player.playVideo()
    }
  }

  pauseVideo() {
    if (this.state.player) {
      this.state.player.pauseVideo()
    }
  }

  setId(id) {
    if (this.state.videoId !== id) {
      this.setState({
        videoId: id
      }, () => {
        // window.scrollTo(0, 0)
        this.scrollUp.scrollToTop()
      })
    }
  }

  setCurrentVideo(currentVideo) {
    if (currentVideo && currentVideo.id) {
      if (currentVideo.id !== this.state.videoId) {
        this.setState({
          currentVideo,
          videoId: currentVideo.id,
        }, () => {
          window.scrollTo(window.scrollX, 450)
          // if (this.scrollUp) {
          //   this.scrollUp.scrollToTop()
          // }
        })
      }
    }
  }

  getIdFromUrl(url) {
    let id
    if (url) {
      if (url.split('/embed/').length > 1) {
        const idAndQuery = url.split('/embed/')[1]
        id = idAndQuery.split('?').filter(data => data)[0]
      } else if (url.includes('youtu.be')) {
        const temp = url.split('youtu.be/').filter(v => v)[1] || ''
        id = temp.split('/')[0]
      } else {
        const newUrl = new URL(url)
        id = newUrl.searchParams.get('v')
      }
    }
    return id
  }

  getAvatar() {
    const { currentVideo } = this.state

    if (currentVideo) {
      if (currentVideo.avatar) {
        return (
          <Avatar src={currentVideo.avatar} />
        )
      } else {
        const initialName = get(currentVideo, 'created_by.full_name', 'User').slice(0, 1)
        return (
          <Avatar className='initial-avatar'>
            {initialName.toUpperCase()}
          </Avatar>
        )
      }
    }
  }

  getAuthorName() {
    const { currentVideo } = this.state
    let author = 'User'
    if (currentVideo && currentVideo.create_by) {
      author = get(currentVideo, 'created_by.full_name', '')
      // if (!author) {
      //   author = get(currentVideo, 'created_by.username', '')
      // }
    }
    return author
  }

  showDialog() {
    this.setState({
      isModalDialog: true,
    }, () => this.pauseVideo())
  }

  getModalDialog() {
    const { isModalDialog } = this.state

    const exFields = ComponentAutocomplete.productVideos

    return (
      <Modal
        visible={isModalDialog}
        title='Add Youtube Media'
        centered
        onCancel={() => this.setState({isModalDialog: false})}
        onOk={() => this.onSave()}
        footer={[
          <Button key='cancel' disabled={this.state.isSaving} onClick={() => this.setState({isModalDialog: false})}>
            Cancel
          </Button>,
          <Button key='save' type='primary' loading={this.state.isSaving} onClick={() => this.onSave()}>
            Save
          </Button>
        ]}
      >
        {
          isModalDialog &&
          <Row>
            <Col span={24}>
              <Row className='row-item'>
                <Radio.Group value='youtube'>
                  <Radio value='youtube'>Youtube</Radio>
                </Radio.Group>
              </Row>
            </Col>
            <Col span={24}>
              <Row className='row-item'>
                <FormProvider>
                  <Form layout='horizontal'>
                    {
                      exFields.map((elem, index) => {
                        return (
                          <DynamicFieldHalfScreen
                            {...elem}
                            key={index}
                            userReducer={this.props.userReducer}
                          />
                        )
                      })
                    }
                    <FormContext.Consumer>
                      {(form) => {this.form = form}}
                    </FormContext.Consumer>
                  </Form>
                </FormProvider>
              </Row>
            </Col>
          </Row>
        }
      </Modal>
    )
  }

  onSave() {
    const {validateFieldsAndScroll, resetFields} = this.form;
    validateFieldsAndScroll({scroll: {offsetTop: 150}}, (err, values) => {
      if (!err) {
        const exFields = ComponentAutocomplete.productVideos
        const valuesConverted = ComponentHelper.getValuesWithFileV2(values, exFields)
        let cvValues = {}
        for (const keyValues in values) {
          for (const indexFields in exFields) {
            const titleForm = exFields[indexFields].title.replace(/ /g, '_').toLowerCase()
            const dataIndex = exFields[indexFields].dataIndex
            if (keyValues === titleForm) {
              let tempValues
              if (values[keyValues]) {
                if (values[keyValues].trim) {
                  tempValues = values[keyValues].trim()
                } else {
                  tempValues = values[keyValues]
                }
              }
              cvValues[dataIndex] = tempValues
              break
            }
          }
        }
        // Do Save
        this.save(valuesConverted, resetFields)
      }
    })
  }

  checkYoutube(videoList, values) {
    let errorMessage = null
    // Sementara regex of URL jika
    // data yang tidak memiliki slash '/'
    let id = '', url = values.get('link')
    if (url.split('/').length === 1) {
      id = url
      url = `https//youtube.com/embed/${id}`
    } else if (url.split('/').length > 1) {
      if (url.split('/embed/').length > 1) {
        const idAndQuery = url.split('/embed/')[1]
        id = idAndQuery.split('?').filter(data => data)[0]
      } else if (url.includes('youtu.be')) {
        return null
      } else {
        const newUrl = new URL(url)
        id = newUrl.searchParams.get('v')
      }
    }

    // let willSave = true
    if (id) {
      for (const index in videoList) {
        let tempId = ''
        if (videoList[index].link.split('/embed/').length > 1) {
          const idAndQuery = videoList[index].link.split('/embed/')[1]
          tempId = idAndQuery.split('?').filter(data => data)[0]
        } else {
          const newUrl = new URL(videoList[index].link)
          tempId = newUrl.searchParams.get('v')
        }

        if (id === tempId) {
          // willSave = false
          errorMessage = 'This video has already exist, please change URL video!'
          break
        }
      }
    } else {
      errorMessage = 'Please enter youtube video URL correctly!'
    }

    return errorMessage
  }

  save(values, resetFields) {
    const { videoList } = this.state

    let errorMessage = '', isYT = true

    if (isYT) errorMessage = this.checkYoutube(videoList, values)
    if (!errorMessage) {
      this.setState({ isSaving: true }, () => {
        Request(
          'post',
          this.props.urlData + '-create',
          {},
          values,
          [this.props.parentId],
          res => this.saveSuccess(res),
          err => this.failReqMsg(err),
        )
      })
    } else {
      message.error(errorMessage)
      this.form.setFields({
        url: {
          value: values.get('link'),
          errors: [new Error(errorMessage)]
        }
      })
    }
  }

  saveSuccess(response) {
    this.read()
    this.setState({
      isModalDialog: false, isSaving: false,
    }, () => {
      message.success('Video has been saved')
    })
  }

  failReqMsg(error) {
    this.setState({ isSaving: false })
    if (error.response) {
      const errCode = error.response && error.response.status
      const errText = error.response && error.response.statusText
      message.error(`${errText} - code:${errCode}`)
    }
  }

  listenHistory = (history) => {
    const relatedUrl = this.getEndUrl(history.pathname)
    if (relatedUrl !== 'videos') {
      this.pauseVideo()
    }
  }

  getEndUrl(pathname) {
    const pathnameSplited = pathname.split('/').filter(word => word)
    const relatedUrl = pathnameSplited[pathnameSplited.length - 1]
    return relatedUrl
  }

  handleDelete(e, pk) {
    e.stopPropagation()
    this.showDeleteConfirm(pk, confirmation => {
      if (confirmation) {
        this.delete(pk)
        return
      }
    })
  }

  showDeleteConfirm(id, callback) {
    const { currentVideo } = this.state
    let msg = 'Are you sure delete this video?'
    if (id === get(currentVideo, 'pk')) {
      this.pauseVideo()
      msg += ' This video is running.'
    }
    modalConfirm({
      title: 'Delete Video',
      content: msg,
      okText: 'Yes',
      okType: 'danger',
      onOk: () => {
        callback(true)
      },
    })
  }

  delete(pk) {
    Request(
      'delete',
      this.props.urlData + '-delete',
      {},
      {},
      [this.props.parentId, pk],
      (res, extra) => this.deleteSuccess(res, extra),
      err => this.deleteFailed(err),
      pk,
    )
  }

  deleteSuccess(response, extra) {
    const { currentVideo } = this.state
    if (extra === get(currentVideo, 'pk')) this.clearPlayback()
    message.success('Video has been deleted')
    this.read()
  }

  clearPlayback = () => {
    this.setState({ videoId: null, player: null, currentVideo: null })
  }

  deleteFailed(error) {
    const errCode = error.response && error.response.status
    const errText = error.response && error.response.statusText
    message.error(`${errText} - code:${errCode}`)
  }

  read() {
    this.setThisState({ isLoading: true }, () => {
      Request(
        'get',
        this.props.urlData + '-read',
        {},
        {},
        [this.props.parentId],
        this.readSuccess,
        err => this.readFailed(err),
      )
    })
  }

  readSuccess(response) {
    this.setState({
      videoList: response.data.results,
      nextVideoList: response.data.next,
      isLoading: false,
      videoId: null,
    })
  }

  readFailed(error) {
    message.error('Error code: ' + (error.response && error.response.status))
    this.setState({ isLoading: false })
  }

  getVideoList(list) {
    return list.length > 0 ? list.map((item, index, arr) => (
      <StyledColItem key={item.pk}
        onClick={() => this.setCurrentVideo(item)}
      >
        <ThumbnailRow>
          <img className='equal-container' src={linkPreviewYoutube.replace('{}', item.id)}
            alt='Nothing'
          />
          <div className='equal-container overlay absolute-anchor'>
            {/* <Check style={{ fontSize: '29px' }} onClick={e => e.stopPropagation()} /> */}
            {!this.props.readOnly && (
              <Button className='abs-tr gutter-12' type='danger' onClick={e => this.handleDelete(e, item.pk)}>
                <Icon type='delete' />
              </Button>
            )}
          </div>
          {/* <Check checked onClick={e => e.stopPropagation()} /> */}
        </ThumbnailRow>
        <ScrollingRow>
          <Row>
            <Text strong className='black'>
              {item.title}
            </Text>
          </Row>
          <Row>
            <Col>
              <Tooltip placement='topLeft' title={item.description}>
                <Text ellipsis className='equal-container'>
                  {item.description}
                </Text>
              </Tooltip>
            </Col>
          </Row>
        </ScrollingRow>

      </StyledColItem>
    )) :
    'No video to display'
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen(this.listenHistory)
    this.read()
  }

  componentWillUnmount() {
    this.unlisten()
  }

  render() {
    const { readOnly } = this.props
    const { isLoading, videoId, currentVideo } = this.state
    const { videoList } = this.state

    let cvData = videoList.map(item => ({
      ...item,
      id: this.getIdFromUrl(item.link),
    }))

    return (
      <Card>
        <Row>
          <BackTop className='no-opacity' ref={scrollUp => this.scrollUp = scrollUp}>
            <div className="ant-back-top-inner no-opacity">UP</div>
          </BackTop>
          <Col span={24}>
            <Row type='flex' justify='space-between'>
              <Col>
                <Row type='flex' gutter={[7, 7]}>
                  <Col>
                    <Button disabled={readOnly} type='primary' icon='plus' onClick={() => this.showDialog()}>
                      Add Video
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Button icon='reload' loading={isLoading} onClick={() => this.read()}>
                  Refresh
                </Button>
              </Col>
            </Row>
            <Divider className='row-item' type='horizontal' />
            {
              videoId && !isLoading &&
              <Row>
                <Row type='flex'>
                  <Col xl={16} lg={24} sm={24}>
                    <StyledYoutube
                      videoId={videoId}
                      onReady={this.onReady}
                      opts={opts}
                    />
                  </Col>
                  <Col xl={8} lg={24} sm={24}>
                    <Row className='yt-description push-left'>
                      <Col span={24}>
                        <Row type='flex' align='middle'>
                          <Col>
                            {this.getAvatar()}
                          </Col>
                          <Col className='push-left'>
                            <Title level={4} className='no-margin'>{this.getAuthorName()}</Title>
                          </Col>
                        </Row>
                        <Row className='row-item'>
                          <WordWrap strong className='black'>{currentVideo.title}</WordWrap>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Paragraph className='wrapped'>{currentVideo.description}</Paragraph>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {/* <Col span={24} className='row-item'>
                  <Row type='flex' align='middle' className='row-item'>
                    <Col>
                      {this.getAvatar()}
                    </Col>
                    <Col className='push-left'>
                      <Title level={4} className='no-margin'>{this.getAuthorName()}</Title>
                    </Col>
                  </Row>
                  <Row>
                    <Title level={4}>{currentVideo.title}</Title>
                  </Row>
                  <Row>
                    <Paragraph className='wrapped'>{currentVideo.description}</Paragraph>
                  </Row>
                </Col> */}
                <Divider className='row-item' type='horizontal' />
              </Row>
            }
            {
              !isLoading ? (
                <Row type='flex' justify='center'>
                  { this.getVideoList(cvData) }
                </Row>
              ) : (
                <LoadingSkleton count={4} />
              )
            }
          </Col>
          { this.getModalDialog() }
        </Row>
      </Card>
    )
  }
}

export default PageVideos

const StyledYoutube = styled(Youtube)`
  width: 100%;
  height: 55vh !important;
  min-height: 300px !important;
  margin-bottom: 12px;
`

const StyledColItem = styled(Col)`
  cursor: pointer;
  width: 20vw;
  height: 20vw;
  margin: 0 4px 24px 0;
  max-height: 250px;
  max-width: 250px;
  min-width: 160px;
  min-height: 160px;
`
// style={{margin: '0 4px 24px 0', maxHeight: '250px', maxWidth: '250px', minWidth: '160px', minHeight: '160px'}}
const ThumbnailRow = styled(Row)`
  height: 75%;
`

const ScrollingRow = styled(Row)`
  overflow: hidden;
  height: 25%;
`

const WordWrap = styled(Text)`
  word-wrap: break-word;
`
