import React from 'react'
import PropTypes from 'prop-types'
import { Row, Spin } from 'antd'

class LoadingRef extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: !props.defaultHide,
    }
  }

  setIsLoading = (isLoading, callback = () => null) => {
    this.setState({ isLoading }, () => callback())
  }

  render() {
    const { isLoading } = this.state
    return (
      <div
        style={{
          display: isLoading ? 'block' : 'none',
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
          zIndex: 1070,
        }}
        onClick={e => e.stopPropagation()}
      >
        <Row type="flex" justify="center" align="middle" style={{ width: '100%', height: '80%' }}>
          <Spin size="large" />
        </Row>
      </div>
    )
  }
}

LoadingRef.propTypes = {
  defaultHide: PropTypes.bool,
}

LoadingRef.defaultProps = {
  defaultHide: false,
}

export default LoadingRef