import { BaseList } from "App/Screens/SubModules/Component";
import descriptor from './descriptorOffline';

class TagMerchantList extends BaseList {
  constructor(props) {
    super(props)

    this.appName = 'jobs'
    this.modelName = 'tagmerchant'
    this.propsList = {
      avatar: 'merchant.name',
      pageTitle: 'Tag Merchant',
      descriptor,
      masterKey: 'module-merchant-tagMerchant',
    }
  }
}

export default TagMerchantList