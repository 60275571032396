import { BaseList } from "App/Screens/SubModules/Component";
import descriptor from "./descriptorOffline";

class ExtraCharge extends BaseList {
  constructor(props) {
    super(props)

    this.appName = 'jobs'
    this.modelName = 'extrachargesize'
    // this.availablePermissions = ['.view_']
    this.propsList = {
      avatar: 'name',
      pageTitle: 'Extra Charge Size',
      descriptor,
      masterKey: 'module-merchant-extraChargeSize',
    }
    
  }
}

export default ExtraCharge