export default [
  {
    label: 'Merchant',
    key: 'merchant.name',
    dataIndex: 'merchant.name',
  },
  {
    label: 'Subsection',
    key: 'subsection.identifier',
    dataIndex: 'subsection.identifier',
  },
]