import React from 'react';
import { Typography, Calendar, Input, Badge, Row, Select, DatePicker, Radio, Button, Avatar, Popover, Menu, Tag, Icon } from 'antd';
import moment from 'moment';
import { get, isEmpty, sumBy } from 'lodash';
import Sites from "App/Config/Sites";
import './Style.less';

const { SubMenu } = Menu;
const { MonthPicker } = DatePicker;
const { Option } = Select;
const { Search } = Input;
const { Text } = Typography;
const jobStatusMap = [
  {
    status: ['new'],
    icon: 'file-add',
  },
  {
    status: ['started', 'reopened'],
    icon: 'play-square',
  },
  {
    status: ['assigned'],
    icon: 'solution',
  },
  {
    status: ['QA1'],
    icon: 'user',
  },
  {
    status: ['QA2'],
    icon: 'team',
  },
  {
    status: ['pending'],
    icon: 'file-sync',
  },
  {
    status: ['finished'],
    icon: 'file-done',
  },
  {
    status: ['cancelled'],
    icon: 'exception',
  },
  {
    status: ['closed'],
    icon: 'file-excel',
  },
];

class CalendarMode extends React.Component {
  getStatusIcon = val => {
    const indexStatus = jobStatusMap.findIndex(item => item.status.includes(val));
    const found = jobStatusMap[indexStatus];
    return get(found, 'icon', 'user');
  };

  elipsisTitle = title => {
    if (title && title.length > 12) {
      let r = title.substring(0, 11);
      return r + '...';
    }
    return title;
  };

  getAvatar(tender, commercial, double_up) {
    const init = {
      tdc: 'DT&C',
      td: 'DT',
      tc: 'T&C',
      tender: 'Tender',
      commercial: 'CM',
      double_up: 'DU',
    };
    let avatar;
    if(tender && commercial && double_up)
      avatar = init.tdc
    else if (tender && commercial)
      avatar = init.tc
    else if (tender && double_up)
      avatar = init.td
    else if (tender) 
      avatar = init.tender;
    else if (commercial) 
      avatar = init.commercial;
    else if (double_up) 
      avatar = init.double_up;
    else avatar = 'Job';
    return avatar;
  }

  openQS(id) {
    const windowFeatures = `width=${window.innerWidth * 0.9},height=${window.innerHeight * 0.9}`
    const { userReducer } = this.props;
    const urlTarget = `${Sites.url.qs}/job/${id}?ItSHPShcIRDYnjKX=${btoa(`${Sites.oauth.id}:${Sites.oauth.secret}`)}&MwFMqLoCBGBDyZwj=${userReducer.token}&lmMgPPePCXurSXjI=${get(userReducer, "user.username")}`
    window.open(urlTarget, id, windowFeatures);
  }

  detailCell(d) {
    const [p] = [this.props];
    return (
      <Menu mode="vertical" selectedKeys="[]">
        {d.map((c, i) => (
          <SubMenu
            key={i}
            title={
              <span className="mr-3">
                <Avatar size="small" className="mr-2">
                  {c.jobs.length}
                </Avatar>
                {this.elipsisTitle(c.name)}
              </span>
            }
          >
            <Menu.ItemGroup title={c.name}>
              {c.jobs.map((val, index) => (
                <Menu.Item key={index} onClick={() => this.openQS(val.pk)}>
                  <Avatar
                    className="mr-2"
                    shape="square"
                    size="small"
                    style={{
                      backgroundColor: this.getRandomColor(
                        val.tender,
                        val.commercial,
                        val.double_up
                      ),
                    }}
                  >
                    {this.getAvatar(val.tender, val.commercial, val.double_up)}
                  </Avatar>
                  <Text type="secondary">#{val.pk}: </Text>
                  {val.name} ·{' '}
                  <Icon
                    title={val.name}
                    className={!val.owner && 'text-danger'}
                    type="user"
                    style={{ marginRight: '2px' }}
                  />
                  {val.owner ? val.owner : <font className="text-danger">Unassigned</font>} ·
                  <Tag className="ml-2" color="green">
                    {val.grade.level}
                  </Tag>
                  <Tag color="cyan">
                    <Icon type={this.getStatusIcon(val.status)} style={{ marginRight: '2px' }} />{' '}
                    {val.status}
                  </Tag>
                </Menu.Item>
              ))}
            </Menu.ItemGroup>
          </SubMenu>
        ))}
      </Menu>
    );
  }

  getListData(value, model, key = 'instructed_finish_date', data = 'job_queues') {
    const { jobs } = this.props;
    const date = moment(value).format('DD-MM-YYYY');
    const job = get(jobs, [model], []).find(e => moment(e[key]).format('DD-MM-YYYY') === date);
    if (job) return job[data];
    return [];
  }

  getRandomColor(tender, commercial, double_up, defaultColor = false) {
    let color = {
      tender: '#00bfff',
      dt: '#00bfff',
      dtc: '#FB8073',
      tc: '#FB8073',
      commercial: '#25D366',
      double_up: '#BA9F8C',
    };
    let bg = '#c1c1c1';
    if (tender && double_up && commercial) bg = color.dtc
    else if (tender && commercial) bg = color.tc
    else if (tender && double_up) bg = color.dt
    else if (tender) bg = color.tender;
    else if (commercial) bg = color.commercial;
    else if (double_up)
      bg = color.double_up
    else if (defaultColor) bg = defaultColor;
    return bg;
  }

  dateCellRender = value => {
    const listData = this.getListData(value, 'jobs');
    const __defaultDate = () => {
      return (
        <div className="ant-fullcalendar-date" style={{ borderRadius: '3px' }}>
          <div className="ant-fullcalendar-value">{moment(value).format('DD')}</div>
          <div className="ant-fullcalendar-content" style={{ overflowX: 'auto' }}>
            {this.renderStaff(value)}
            <div className='mt-1'>
              {listData && listData.map((item, i) => (
                <div key={i}>
                  <Typography.Text className="one-line" style={{ fontSize: '12px' }} type="secondary" key={i}>
                    <Avatar
                      size={13}
                      style={{
                        backgroundColor: '#0acd00',
                      }}
                    />{' '}
                    {`${item.name} `}
                    <Avatar
                      size={15}
                      style={{
                        fontSize: '10px',
                        backgroundColor: '#aa5fff'
                      }}
                    >{item.jobs.length}</Avatar>
                  </Typography.Text>
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    };
    const __filled = () => {
      return (
        <Popover
          placement="right"
          title={
            <div className="mb-1 py-1 sync-d-title">
              <Typography.Text type="secondary" className='mr-1' strong>Job due at {moment(value).format('DD MMM YYYY')}</Typography.Text>
            </div>
            // <Typography.Text type="secondary" strong>
            //   Job due at {moment(value).format('DD MMM YYYY')}
            // </Typography.Text>
          }
          content={this.detailCell(listData)}
        // trigger="click"
        >
          {__defaultDate()}
        </Popover>
      );
    };
    return isEmpty(listData) ? __defaultDate() : __filled();
  };

  getMonthdata = (value) => {
    const { yjobs } = this.props;
    const date = moment(value).format('MM-YYYY');
    const job = get(yjobs, 'jobs', []).find(e => moment(e.instructed_finish_month).format('MM-YYYY') === date);
    return job
  }

  renderStaff(value) {
    const w = this.getListData(value, 'sl', 'name', 'data')
    return (
      w.map((i, key) =>
        <div key={key}>
          <Typography.Text className="one-line" style={{ fontSize: '12px' }} type="secondary" key={key}>
            <Avatar
              shape="square"
              size={13}
              icon="user"
              style={{
                backgroundColor: '#ff6d39',
              }}
            /> {i.user.username}/{get(i, 'user.home_branch.name')} ({i.leave_type})
            </Typography.Text>
          </div>
      )
    )
  }

  monthRender = (e) => {
    const w = this.getMonthdata(e)
    if (get(w, 'job_queues')) {
      return (
        <>
          <div style={{
            borderLeft: '3px solid rgb(24, 144, 255)',
            backgroundColor: 'rgb(217, 236, 253)',
            padding: '8px',
            margin: '12px 0px',
          }}>Total job: {sumBy(w.job_queues, 'total_job')}</div>
          {w.job_queues.map((item, i) => (
            <div key={i}>
                <Typography.Text className="one-line" type="secondary">
                  <Avatar
                    size={10}
                    style={{
                      backgroundColor: '#0acd00'
                    }}
                  /> {item.name} · Total job : {' '}
                  <Avatar
                    size={17}
                    style={{
                      fontSize: '10px',
                      backgroundColor: '#aa5fff'
                    }}
                  >{item.total_job}</Avatar>
                </Typography.Text>
            </div>
          ))}
        </>
      )
    }
    return;
  }

  operation(view, date, onChange, next = true) {
    let r = date
    if (next)
      r = moment(date).add(1, 'M')
    else
      r = moment(date).subtract(1, 'M')
    // view(r, 'month')
    onChange(r)
    return r;
  }

  disabledDate() {
    return false;
  }

  renderPicker = (type, e) => {
    const [p] = [this.props]
    let content = (
      <>
        <Button
          type="default"
          style={{ borderTopRightRadius: '0px', borderBottomRightRadius: '0px' }}
          onClick={() => this.operation(p.onChangeview, e.value, e.onChange, false)}
        >
          <Icon type="left" />
        Prev
      </Button>
        <MonthPicker
          allowClear={false}
          format="MMM YYYY"
          className="mr-1 ml-1"
          value={moment(String(e.value))}
          disabledDate={() => this.disabledDate()}
          onChange={(selectedMonth, s) => {
            e.value = selectedMonth;
            // p.onChangeview(e.value)
            e.onChange(selectedMonth);
          }}
        />
        <Button
          className="mr-2"
          style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' }}
          type="default" 
          onClick={() => this.operation(p.onChangeview, e.value, e.onChange)}
        >
          Next
          <Icon type="right" />
        </Button>
      </>
    )
    if (type === 'year') {
      const w = e.value.year()
      const res = []
      for (let i = 1; i < 20; i++) {
        res.push(w - 11 + i)
      }
      content = (
        <Select
          defaultValue={w}
          style={{ width: '150px' }}
          dropdownClassName="same"
          onChange={(r) => {
            e.value = moment(`${r}-${moment(new Date()).format('MM')}-${moment(new Date()).format('DD')}`)
            // p.onChangeview(e.value, 'year')
            e.onChange(e.value)
          }}
          className="mr-2"
        >
          {res.map((i, key) =>
            <Option value={i} key={key}>{i}</Option>
          )}
        </Select>
      )
    }
    return content;
  }

  headerRender = (e) => {
    const [p] = [this.props]
    return (
      <Row type="flex" justify="end">
        {/* <Badge status='success' /> */}
        <Search
          placeholder="Search job"
          className="mr-2"
          style={{ width: '200px' }}
          loading={p.loading}
          onSearch={(r)=>p.onSearch(r, e.value)}
          onChange={p.changeS}
        />
        {this.renderPicker(p.vector, e)}
        <Radio.Group onChange={(r) => p.onChangeview(e.value, r.target.value)} value={p.vector} buttonStyle="solid">
          <Radio.Button value="month">Month</Radio.Button>
          <Radio.Button value="year">Year</Radio.Button>
        </Radio.Group>
      </Row>
    )
  }

  render() {
    const [p] = [this.props]
    const sp = {}
    if (p.vector === 'year')
      sp.onSelect = (e) => p.selectMonth(e)
    return (
      <Row>
        <Calendar
          {...sp}
          mode={p.vector}
          onPanelChange={(l, e) => p.onChangeview(l, e)}
          headerRender={this.headerRender}
          dateFullCellRender={this.dateCellRender}
          monthCellRender={this.monthRender}
        />
      </Row>
    );
  }
}

export default CalendarMode;

CalendarMode.defaultProps = {
  jobs: [],
  yjobs: [],
};
