import { BaseDetail } from 'App/Screens/SubModules/Component';
import fields from 'App/Screens/Job/ExtraChargeSizes/fields';
import { ExtraChargeMappingReference } from './References';

class PageDetail extends BaseDetail {
  constructor(props) {
    super(props)

    this.urlKey = 'module-merchant-extraChargeSize-detail'
    this.title = 'Extra Charge Size'
    this.urlList = '/merchant/extra-charge'
    this.headerDescriptions = [
      { key: 'name', label: 'Name' },
      { key: 'created', label: 'Created', type: 'datetime' },
      { key: 'created_by.username', label: 'Created By' },
      { key: 'modified', label: 'Modified', type: 'datetime' },
      { key: 'modified_by.username', label: 'Modified By' },
    ]
    this.fields = fields
    this.panes = [ExtraChargeMappingReference]
    this.history = {
      url: 'module-merchant-extraChargeSize-history',
      fields: fields,
      urlDetail: "module-merchant-extraChargeSize-history-detail",
      id: props.match.params.id,
      title: 'name'
    }
  }
}

export default PageDetail