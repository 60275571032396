import React, {
  Component,
  useRef,
  cloneElement,
  useCallback,
  message,
} from "react";
import { BaseList } from "../../../SubModules/Component";
import descriptor from "./descriptor";
import { Lib, RequestV2 as Request } from "App/Utils";
import General from "./form/general/general";
import { v4 as uuidv4 } from "uuid";
import { get, cloneDeep } from "lodash";
import { Radio } from "antd";

class Ruleslist extends BaseList {
  constructor(props) {
    super(props);
    this.appName = "expert_system";
    this.modelName = "rule";
    this.availablePermissions = [".view_", ".change_", ".delete_"];
    this._redsubAllist = null;
    this.propsList = {
      onDetailClick: this.onDetailClick,
      renderCountCard: this.renderCountCard,
      // onDeleteClick: this.onDeleteClick,
      disableAddButton: true,
      avatar: "key",
      pageTitle: "Rules",
      descriptor,
      masterKey: props.rulesType,
      onRef: (e) => {
        this._redsubAllist = e;
      },
    };
    this.state = {
      ...this.state,
      visible: false,
      dataRetrive: {
        is_root: true,
        identifier: uuidv4(),
        value_type: "bool",
        premises: [
          {
            id: "no Group",
            comparer: "",
            key: "",
            compare_value: "",
            is_deleted: false,
            group: "",
            identifier: uuidv4(),
            childObj: {
              premises: [],
              key: "",
              pk: "",
              parentObj: {
                key: "",
              },
            },
          },
        ],
      },
      // reloadTable: false,
      currentPermissionSet: !this.state.isCheckingPermission
        ? this.availablePermissions
        : [],
    };
  }
  componentWillReceiveProps(nextProps) {
    const data = {
      is_root: true,
      identifier: uuidv4(),
      value_type: "bool",
      premises: [
        {
          id: "no Group",
          comparer: "",
          key: "",
          compare_value: "",
          is_deleted: false,
          group: "",
          identifier: uuidv4(),
          childObj: {
            premises: [],
            key: "",
            pk: "",
            parentObj: {
              key: "",
            },
          },
        },
      ],
    }
    if (this.propsList.masterKey !== nextProps.rulesType) {
      this.setState({dataRetrive:data})
      this.propsList.masterKey = nextProps.rulesType;
      this._redsubAllist.descriptor({ data: this.propsList.descriptor });
    }
  }

  onDetailClick = (id, record) => {
    var url = "";
    if (record.fact_type) {
      url = "expert-system-retrive-fact";
      this.read(id, url);
    } else {
      url = "expert-system-retrive-rule";
      this.read(id, url);
    }
  };

  read = (id, url) => {
    this.setState({ isLoading: true }, () => {
      Request("get", url, {}, {}, [id], this.readSuccess, this.readFailed);
    });
  };

  readSuccess = (response) => {
    const data = response.data;
    if ("fact_type" in response.data) {
      this.setState({ visible: true, dataRetrive: response.data });
    } else {
      const map = this.mapping(response.data);
    }
  };

  readFailed = (error) => {
    message.error("there is an error in the system");
  };

  mapping = (array) => {
    const data = array;
    data.identifier = uuidv4();
    const group = [];
    for (let i = 0; i < data.premises.length; i++) {
      if (
        data.premises[i].comparer === "OR" ||
        data.premises[i].comparer === "AND"
      ) {
        group.push(data.premises[i]);
        data.premises[i].identifier = uuidv4();
        data.premises[i].childObj = {
          id: data.premises[i].id,
          parentObj: data,
          identifier: uuidv4(),
          key: `group_${data.premises[i].comparer} (${data.premises[i].id})`,
          premises: [],
        };
        this.insideGroup(data.premises[i], data);
      } else {
        data.premises[i].identifier = uuidv4();
        data.premises[i].childObj = {
          ...data.premises[i],
          premises: [],
          key: data.premises[i].child.key,
          parentObj: data,
          pk: data.premises[i].child.pk,
        };
      }
    }

    // for (let i = 0; i < group.length; i++) {

    // }
    this.deletedPremise(data.premises);
    const coba = [];
    coba.push(data)
    this.setState({ visible: true, dataRetrive: data });
  };
  insideGroup = (group, data) => {
    for (let i = 0; i < data.premises.length; i++) {
      if (data.premises[i].parent === group.id) {
        data.premises[i].childObj.parentObj = group.childObj
        group.childObj.premises.push({
          ...data.premises[i],
          // pk: data.premises[i].child.pk,
        });
      }
    }
  };
  deletedPremise = (premises) => {
    for (let i = premises.length - 1; i >= 0; i -= 1) {
      if (premises[i].parent != null) {
        premises.splice(i, 1);
      }
    }
  };
  update = () => {
    this._redsubAllist.read();
  };

  renderCountCard = () => {
    return (
      <>
        <General
          data={cloneDeep(this.state.dataRetrive)}
          list={true}
          onCloseDrawer={() => this.setState({ visible: false })}
          visible={this.state.visible}
          action="edit"
          force={true}
          tipeRule={
            this.propsList.masterKey === "module-expertSystem-fact"
              ? false
              : true
          }
          outputContent={null}
          updatedata={() => this.update()}
        />
      </>
    );
  };
}

export default Ruleslist;
