import React from "react";
import { Prompt } from "react-router"
// import get from "lodash/get";
import { get, cloneDeep, endsWith } from "lodash";
import { Layout, Row, Col, Icon, Spin, Button, message, Tabs, Drawer } from "antd";
import { RequestV2 as Request, CheckPermission, Lib } from "App/Utils";
import Header from './Header'
// import PageNotFound from "App/Component/PageNotFound"
import History from "App/Screens/SubModules/Component/History";
import PageError from 'App/Component/PageError'
import { PageMembers, PagePermissions, PageModules, PageReports, PageJobQueues } from './Pages'
import LoadingRef from 'App/Component/Singlepage/LoadingRef'

const { TabPane } = Tabs;

const ERROR_MESSAGE = 'Failed to fetch'

class GroupDetail extends React.PureComponent {
  constructor(props) {
    super(props)

    this.refKeys = [
      { key: '_header', keyBody: 'name', label: 'Group Name' },
      { key: '_module', keyBody: 'allowed_modules', label: 'Modules' },
      { key: '_permission', keyBody: 'permissions', label: 'Permissions' },
      { key: '_report', keyBody: 'allowed_reports', label: 'Reports' },
      { key: '_branch', keyBody: 'branch_queues', label: 'Job Queues' },
    ]

    this.groupNotes = [
      // {
      //   name: 'Override other user\'s Job',
      //   note: '*Note: User has full access to job when he/she own the job',
      // },
      // {
      //   name: 'Override other user\'s Schedule of Material',
      //   note: '*Note: User has full access to Schedule of Material when he/she own the job',
      // },
    ]

    const permissionSetUser = CheckPermission.getPermissionSet({ userReducer: props.userReducer, appName: 'account', modelName: 'user' })
    this.state = {
      isLoading: true,
      errorResponse: null,
      isSaving: false,
      activeTabKey: permissionSetUser.some(code => (code || '').includes('.view_')) ? 'members' : 'modules',
      group: null,
      groupHelper: null,
      filteredPermissions: [],
      isFieldsTouched: false,
      // Permission
      readOnly: !get(props, 'currentPermissionSet', []).some(code => (code || '').includes('.change_')),
      permissionSetUser,
      visible: false,
      // ----------
    }
  }

  requestHelper = (callback = () => null, callbackPageError = () => null) => {
    Request(
      'get',
      'module-helper-group',
      {},
      {},
      [],
      this.requestHelperSuccess,
      this.requestHelperFailed,
      { callback, callbackPageError },
    )
  }

  requestHelperSuccess = (response, extra) => {
    const permissionConverted = this.convertPermission(response.data.permission)
    this.setState({ groupHelper: { ...response.data, permission: permissionConverted } }, () => {
      extra.callback()
    })
  }

  convertPermission = (permission) => {
    const permissionConverted = cloneDeep(permission)
    for (const index in permissionConverted) {
      // Insert group note
      for (const j in permissionConverted[index].groups) {
        const groupNote = this.groupNotes.find(groupNote => groupNote.name === permissionConverted[index].groups[j].name)
        if (groupNote) {
          permissionConverted[index].groups[j].note = groupNote.note
        }
      }
      // Merge sets to groups
      if (permissionConverted[index].sets.length > 0) {
        permissionConverted[index].groups.push(
          { name: 'UNGROUPED', alias: `${permissionConverted[index].name}-UNGROUPED`, sets: permissionConverted[index].sets }
        )
      }
    }
    return permissionConverted
  }

  requestHelperFailed = (error, extra) => {
    this.setState({ isLoading: false, errorResponse: get(error, 'response') }, () => {
      extra.callbackPageError()
    })
  }

  retrieve = (keyLoading = 'isLoading', callback = () => null) => {
    const { id, detail } = get(this.props, 'match.params', {})
    let url = "module-groupUser-group-detail"
    const params = [id]
    if(parseInt(detail)){
      url = "module-groupUser-group-history-detail"
      params.push(detail)
    }
    this.setState({ [keyLoading]: true }, () => {
      this.requestHelper(() => {
        Request("GET", url, {}, {}, params,
          this.retrieveSuccess,
          this.retrieveFailed,
          { keyLoading, callback }
        );
      }, callback)
    })
  }

  retrieveSuccess = (response) => {
    const filteredPermissions = this.getFilteredPermissions(this.state.groupHelper.permission, response.data.allowed_modules)
    this.setState({ group: response.data, filteredPermissions, isLoading: false })
  }

  retrieveFailed = (error, extra) => {
    this.setState({ [extra.keyLoading]: false, errorResponse: get(error, 'response') }, () => {
      extra.callback()
    })
  }

  getFilteredPermissions = (displayPermissions, chosenModules) => {
    return displayPermissions.filter(perm => chosenModules.some(moduleId => moduleId === perm.pk))
  }

  setGroup = (updateGroup, callback = () => null) => {
    this.setState({ group: updateGroup }, callback)
  }

  setThisState = (obj, callback = () => null) => {
    if (typeof obj === 'object') {
      for (const key in obj) {
        if (obj[key] !== this.state[key]) {
          return this.setState(obj, callback)
        }
      }
    }
    callback()
  }

  onGoHome = () => {
    Lib.historyAction(this.props.history, '/user-group/group').goBack()
  }

  handleHistoryAction = (path) => {
    Lib.historyAction(this.props.history, path).push()
  }

  onSave = () => {
    const values = {}
    for (const refItem of this.refKeys) {
      if (get(this[refItem.key], 'validateFields')) {
        values[refItem.keyBody] = this[refItem.key].validateFields()
      }
    }

    this.setState({ isSaving: true }, () => {
      setTimeout(() => {
        this.save(values)
      })
    }, 250)
  }

  save = (values) => {
    const args = [get(this.state, 'group.pk')]

    const { detail } = get(this.props, 'match.params', {})
    let url = "module-groupUser-group-update"
    if(parseInt(detail)){
      url = "module-groupUser-group-history-detail"
      args.push(detail)
      this.setTouched(false)
    }
    Request(
      'put',
      url,
      {},
      values,
      args,
      this.saveSuccess,
      this.saveFailed,
    )
  }

  saveSuccess = (response) => {
    // message.success(response.data.name + ' has been saved.')
    // this.setState({ isSaving: false, group: response.data, isFieldsTouched: false })

    // Sementara sampai permission submodule tidak sama dengan permission di admin.
    this.setState({ group: response.data }, () => {
      const { history, match } = this.props
      if(match.params.detail){
        let urlTarget = `/user-group/group/${match.params.id}`
        history.push(urlTarget)
        message.success(response.data.name + ' has been reverted.')
      }else{
        message.success(response.data.name + ' has been saved.')
        setTimeout(() => {
          this.setState({ isSaving: false, isFieldsTouched: false })
        }, 150)
      }
    })
  }

  saveFailed = (error) => {
    const errors = get(error, ['response', 'data'])
    // console.log(error.response) // don't show "error.response" to console
    if (errors && typeof errors === 'object') {
      if (errors.detail) message.error(errors.detail)
      this.setFieldsError(errors)
    } else {
      message.error('Failed to save Group, please contact your admin!', 5)
    }
    this.setState({ isSaving: false })
  }

  setFieldsError = (objError) => {
    message.error('Failed to save Group')
    for (const key in objError) {
      const refKey = this.refKeys.find(ref => ref.keyBody === key)
      if (refKey && typeof get(this[refKey.key], 'setError') === 'function') {
        message.error('Error in ' + refKey.label + ' : ' + objError[key][0], 5)
        this[refKey.key].setError(objError[key][0])
      }
    }
  }

  // Children tidak boleh diberi fungsi ini
  setTouched = (isFieldsTouched, callback) => {
    this.setState({ isFieldsTouched }, callback)
  }

  // Set touched untuk children input
  onFieldTouched = (isFieldsTouched, callback = () => null) => {
    // Don't use isFieldsTouched param.
    this.setTouched(true, callback)
  }

  onTabChange = (activeTabKey, callback = () => null) => {
    this.setState({ activeTabKey }, callback)
  }

  // fungsi ini, when unselect module, akan menghapus permissions.nya juga
  // onModuleChange = (keys) => {
  //   this._loading.setIsLoading(true, () => {
  //     setTimeout(() => {
  //       const filteredPermissions = this.getFilteredPermissions(this.state.groupHelper.permission, keys)
  //       if (filteredPermissions.length < this.state.filteredPermissions.length) {
  //         const removedModules = this.state.filteredPermissions.filter(
  //           val => !filteredPermissions.some(
  //             val2 => val2.codename === val.codename
  //           )
  //         )
  //         for (const index in removedModules) {
  //           this._permission._permission.onSelectAllModule(false, removedModules[index])
  //         }
  //       }
  //       this.setState({ filteredPermissions }, () => {
  //         this._loading.setIsLoading(false)
  //       })
  //     }, 100)
  //   })
  // }

  onModuleChange = (keys) => {
    this._loading.setIsLoading(true, () => {
      setTimeout(() => {
        const filteredPermissions = this.getFilteredPermissions(this.state.groupHelper.permission, keys)
        this.setState({ filteredPermissions }, () => {
          this._loading.setIsLoading(false)
        })
      }, 100)
    })
  }

  setView=(record)=>{
    const { history, match } = this.props
    let urlTarget = `/user-group/group/${match.params.id}/history/${record.pk}`
    history.push(urlTarget)
  }

  componentDidMount() {
    this.retrieve()
  }

  render() {
    const { readOnly, activeTabKey, permissionSetUser, group, groupHelper, filteredPermissions, isLoading, errorResponse, isSaving, isFieldsTouched } = this.state;

    if (isLoading) {
      return (
        <div style={{ paddingTop: 100, textAlign: "center" }}>
          <Spin
            size="large"
            tip="Loading..."
            indicator={
              <Icon type="loading" style={{ fontSize: "4rem" }} spin />
            }
          />
        </div>
      )
    }

    return group ? (
      <Layout style={{ marginBottom: '16px' }}>
        <Prompt
          when={isFieldsTouched}
          message='You will lose any unsaved changes. Continue?'
        />
        <Layout>
          <Drawer 
            destroyOnClose 
            placement="right"
            closable={false}
            width={'50%'}
            visible={this.state.visible} 
            onClose={()=>this.setState({ visible: false })}
          >
            <History 
              id={group.pk} 
              url="module-groupUser-group-history" 
              urlDetail="module-groupUser-group-history-detail"
              title={group.name}
              setView={this.setView}
            />
          </Drawer>
          <Header
            ref={ref => {this._header = ref}}
            readOnly={readOnly}
            title={get(group, 'name', '')}
            onBack={this.onGoHome}
            params={get(this.props, 'match.params',{})}
            history={this.props.history}
            onFieldTouched={this.onFieldTouched}
            extra={!readOnly &&
              <Row type='flex'>
                <Button.Group>
                  {!get(this.props, 'match.params.detail') && <Button icon="history" onClick={()=>this.setState({ visible: true })}>History</Button>}
                  <Button type='primary' icon='save' loading={isSaving} onClick={this.onSave}>
                    Save
                  </Button>
                </Button.Group>
              </Row>
            }
          />
          <Row style={{ backgroundColor: '#fff' }}>
            <Tabs className='custom-margin-tab' tabBarGutter={0} activeKey={activeTabKey} onChange={this.onTabChange}>
              {permissionSetUser.some(perm => perm.includes('.view_')) && (
                <TabPane forceRender key='members' tab={<span><Icon type='team' />Members</span>}>
                  <PageMembers
                    // Tolong kalau bisa jangan kirim history,
                    // karena component akan selalu render jika perpindahan tab menggunakan path.
                    // ref={ref => {this._user = ref}}
                    handleHistoryAction={this.handleHistoryAction}
                    tabKey='members'
                    setActiveKey={this.onTabChange}
                    readOnly={readOnly}
                    userReducer={this.props.userReducer}
                    groupId={group.pk}
                    groupName={group.name}
                    permissionSetUser={permissionSetUser}
                  />
                </TabPane>
              )}
              <TabPane forceRender key='modules' tab={<span><Icon type='appstore' />Modules</span>}>
                <PageModules
                  ref={ref => {this._module = ref}}
                  tabKey='modules'
                  setActiveKey={this.onTabChange}
                  readOnly={readOnly}
                  userReducer={this.props.userReducer}
                  groupHelper={groupHelper}
                  onChange={this.onModuleChange}
                  group={group}
                  onFieldTouched={this.onFieldTouched}
                />
              </TabPane>
              <TabPane forceRender key='permissions' tab={<span><Icon type='safety-certificate' />Permissions</span>}>
                <PagePermissions
                  ref={ref => {this._permission = ref}}
                  tabKey='permissions'
                  setActiveKey={this.onTabChange}
                  readOnly={readOnly}
                  userReducer={this.props.userReducer}
                  // groupHelper={groupHelper}
                  displayPermissions={filteredPermissions}
                  group={group}
                  onFieldTouched={this.onFieldTouched}
                />
              </TabPane>
              <TabPane forceRender key='reports' tab={<span><Icon type='audit' />Reports</span>}>
                <PageReports
                  ref={ref => {this._report = ref}}
                  tabKey='reports'
                  setActiveKey={this.onTabChange}
                  readOnly={readOnly}
                  userReducer={this.props.userReducer}
                  groupHelper={groupHelper}
                  group={group}
                  onFieldTouched={this.onFieldTouched}
                />
              </TabPane>
              <TabPane forceRender key='job-queues' tab={<span><Icon type='branches' />Job Queues</span>}>
                <PageJobQueues
                  ref={ref => {this._branch = ref}}
                  tabKey='job-queues'
                  setActiveKey={this.onTabChange}
                  readOnly={readOnly}
                  userReducer={this.props.userReducer}
                  groupHelper={groupHelper}
                  group={group}
                  onFieldTouched={this.onFieldTouched}
                />
              </TabPane>
            </Tabs>
          </Row>
        </Layout>
        <LoadingRef defaultHide ref={ref => {this._loading = ref}} />
      </Layout>
    ) : (
      <PageError
        errorResponse={errorResponse}
        onGoBack={this.onGoHome}
        onReload={this.retrieve}
      />
    )
  }
}

export default GroupDetail