export default [
  {
    title: 'Export mapping',
    type: 'SelectQuery',
    required: true,
    data: 'exportMappings',
    dataIndex: 'export_mapping',
    initialValue: '',
    note_text: '',
    additional: 'edit|add',
    selectedKey: 'pk',
    selectedValue: 'label',
    dropdownClassName: 'width-auto2',
    order: 0,
  }, {
    title: 'Subheading',
    type: 'SelectQuery',
    data: 'subheadings',
    dataIndex: 'subheading',
    fieldKey: 'subheading',
    initialValue: '',
    note_text: '',
    additional: 'edit|add',
    selectedKey: 'pk',
    selectedValue: 'label',
    order: 1,
    view: true
  }, {
    title: 'Product',
    type: 'SelectQuery',
    data: 'products',
    dataIndex: 'product',
    fieldKey: 'product',
    view: true,
    initialValue: '',
    note_text: '',
    additional: 'edit|add',
    selectedKey: 'pk',
    selectedValue: 'name',
    order: 2,
  }, {
    title: 'Usage',
    type: 'SelectQuery',
    data: 'lineUsages',
    dataIndex: 'usage',
    fieldKey: 'usage',
    view: true,
    initialValue: '',
    note_text: '',
    additional: 'edit|add',
    selectedKey: 'pk',
    selectedValue: 'label',
    order: 3,
  }, {
    required: true,
    title: 'Multiplier',
    type: 'number',
    fieldKey: 'multiplier',
    view: true,
    data: '',
    dataIndex: 'multiplier',
    initialValue: '1',
    note_text: '',
    additional: '',
    order: 4,
    step: 0.001,
  }, {
    title: 'Import session',
    type: 'text',
    fieldKey: 'import_session',
    view: true,
    data: '',
    dataIndex: 'import_session',
    initialValue: '',
    note_text: '',
    additional: '',
    order: 5,
  },
]