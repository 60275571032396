import columns from './columns'

const descriptor = {
  pagination: true,
  filter_by: [
    {
      name: 'home_branch',
      label: 'Branch',
      api: {
        url_name: 'branches-autocomplete',
        key: 'pk',
        display: 'name',
      }
    },
    {
      name: 'staff_position',
      label: 'Staff Position',
      api: {
        url_name: 'staffPositions-autocomplete',
        key: 'pk',
        display: 'name',
      }
    },
    {
      name: 'is_superuser',
      label: 'Superuser status',
      singleChoices: [
        ['', 'All'],
        ['true', 'Yes'],
        ['false', 'No'],
      ],
    },
    {
      name: 'is_staff',
      label: 'Staff status',
      singleChoices: [
        ['', 'All'],
        ['true', 'Yes'],
        ['false', 'No'],
      ],
    },
    {
      name: 'is_active',
      label: 'Active',
      singleChoices: [
        ['', 'All'],
        ['true', 'Yes'],
        ['false', 'No'],
      ],
    },
  ],
  columns,
}

export default descriptor