import React from "react";
import {Col, Divider, Select, Button, Icon, Modal, Row, Table, message} from "antd";
import styled from "styled-components";
import Request from "App/Utils/Request";
import { Lib, RequestV2 } from "App/Utils";
import {get, isEmpty, camelCase, startCase} from "lodash"
import Modules from "../../../Utils/Modules";
import PropTypes from "prop-types";
import moment from "moment";
import PageError from "App/Component/PageError"
const { Option } = Select;
const {confirm} = Modal

export default class BulkAction extends React.Component{

  constructor() {
    super();

    this.tokenCache = null
    this.state ={
      action: {},
      loading: true,
      data: [],
      pagination: false,
      errorResponse: null,
    }
    this.page = 1
  }

  readSuccess=(r)=>{
    if(r){
      const {exclude_id} = this.props
      let data = r.data.results
      for(let i of exclude_id){
        let fi = data.findIndex(e => e.pk === i)
        if(fi >=0)
          data.splice(fi, 1)
      }
      this.setState({
        pagination: true,
        data: data,
        loading: false,
        count: r.data.count,
        count_row: get(r, 'data.results.length', '0'),
        current: r.data.current,
        limit: r.data.limit,
        errorResponse: null,
      })
    }
  }

  handleTableChange=(page)=>{
    this.page = page.current
    this.setState({loading: true},()=>this.read())
  }

  queryParams=()=>{
    return {...this.props.queryParam(), page: this.page}
  }

  read = () => {
    const p = this.props
    if (p.index) {
      let args = [p.refID]
      this.request = Request(
        "get",
        // camelCase(p.index) + "-read",
        p.url_name,
        {Authorization: `Bearer ${p.userReducer.token}`},
        this.queryParams(),
        args,
        this.readSuccess,
        (error) => {
          this.setState({ loading: false, errorResponse: get(error, 'response') })
        }
      );
    }
  };

  readCache = async () => {
    return new Promise((resolve) => {
      const queryParam = this.queryParams()
      delete queryParam.page
      delete queryParam.limit
      delete queryParam.ordering

      RequestV2(
        'post',
        'queryparam-cache',
        {},
        {},
        [],
        this.readCacheSuccess,
        this.readCacheFailed,
        { resolve },
        undefined,
        undefined,
        queryParam
      )
    })
  }

  readCacheSuccess = (response, extra) => {
    this.tokenCache = response.data.key
    extra.resolve(true)
  }

  readCacheFailed = (error, extra) => {
    extra.resolve(false)
    this.setState({ errorResponse: get(error, 'response') })
  }

  reload = () => {
    this.setState({ loading: true })
    this.read()
    this.readCache()
  }

  componentDidMount() {
    const p =this.props
    let r = {loading: false}
    if(p.selectAllContent) {
      r.loading = true
      this.reload()
    } else {
      r.loading = false
    }
    if(!isEmpty(p.bulkAction))
      r.action = p.bulkAction[0]
    this.setState(r)
  }

  onFailed=(error)=>{
    Lib.errorMessageHandler(get(error, 'response'))
    this.setState({loading: false})
  }

  onSuccess=(r)=>{
    const {allSelectedRowKeys, index, clearSelected, read, setPage} = this.props
    setPage(1, ()=>read())
    clearSelected()
    message.success(`successfully delete ${allSelectedRowKeys.length} ${startCase(index).toLowerCase()}`)
  }

  handleChange=(val, elem)=>{
    this.setState({ action: elem.props.obj })
  }

  renderOption = (objAction) => {
    return (
      <Option key={objAction.key} value={objAction.key} obj={objAction}>
        {objAction.label}
      </Option>
    )
  }

  renderAction=()=>{
    const {bulkAction} = this.props
    const defaultValue = get(this.props.bulkAction, [0, 'key'], '')
    return bulkAction.length > 0 ? (
      <>
        <b style={{ fontSize: '18px' }}>Action : </b>{' '}
        <Select size='small' className="mb-1" defaultValue={defaultValue} onChange={this.handleChange} style={{ width: 200 }}>
          {
            bulkAction.map(this.renderOption)
          }
        </Select>
      </>
    ) : null
  }

  handleBulk = () => {
    // to show dialog, please send "type"
    this.showConfirmV2(isConfirmed => {
      if (isConfirmed) {
        this.setState({ loading: true }, async () => {
          if (!this.tokenCache)
            await this.readCache()

          if (!this.tokenCache) {
            this.setState({ loading: false })
            return message.error('Action failed, please contact your admin')
          }

          const { selectAllContent, exclude_id, allSelectedRowKeys, url_name, refID } = this.props
          const { field, value } = this.state.action
          const body = { [field]: value }
          if (selectAllContent) {
            Object.assign(body, {
              excluded_ids: exclude_id,
              select_all: true,
            })
          } else {
            Object.assign(body, { ids: allSelectedRowKeys })
          }
          const urlKey = url_name
          const args = [refID]
          Request(
            'put',
            urlKey,
            {Authorization: `Bearer ${get(this.props.userReducer, 'token')}`},
            body,
            args,
            this.onBulkSuccess,
            this.onBulkFailed,
            null,
            false,
            'bulk-action/',
            { '_qp_cache': this.tokenCache }
          )
        })
      }
    }, !this.state.action.type)
  }

  showConfirmV2 = (callback = () => null, force) => {
    if (force) {
      callback(true)
    } else {
      confirm({
        title: `${this.state.action.label} ${this.props.selectAllContent ? this.props.select_count : this.props.allSelectedRowKeys.length} ${startCase(this.props.index)}`,
        content: 'This action cannot be undone, continue?',
        okType: this.state.action.type || 'primary',
        okText: this.state.action.label,
        onCancel: () => callback(false),
        onOk: () => callback(true),
      })
    }
  }

  onBulkSuccess = (response) => {
    const {index, clearSelected, read, setPage} = this.props
    message.success('Successfully ' + this.state.action.label + ' Selected ' + index)
    setPage(1, () => read())
    clearSelected()
  }

  onBulkFailed = (error) => {
    this.setState({ loading: false })
    Lib.errorMessageHandler(get(error, 'response'))
  }

  generateColumn=(cols)=>{
    let col = []
    for(const i of cols) {
      if(!i.hidden) {
        if (i.filters)
          delete i.filters
        if(i.data_type === 'datetime'){
          i.render = (text) => moment(text).format('lll')
        }
        col.push(i)
      }
    }
    return col
  }

  deleteRow=(id)=>{
    const rowfilter = (data, id) =>{
      if(data)
        return data.filter(e => e !== id)
      return []
    }

    const p =this.props
    this.setState({
      data: this.state.data.filter(e => e.pk !== id)
    }, ()=>{
      let validate = rowfilter(p.allSelectedRowKeys, id)
      this.props.setThisstate({
        allSelectedRowKeys: validate,
        visibleBulk: validate.length > 0,
        select_count: p.select_count -1,
        selectAllContent: validate.length > 0,
        visibleModalNew: validate.length > 0,
        currenctPageSelectedKeys: rowfilter(p.currenctPageSelectedKeys, id),
        exclude_id: p.exclude_id.concat(id),
      })
    })
  }

  render() {
    const p = this.props
    const {action, loading, data} = this.state
    let cols = this.generateColumn(p.bulkCols)
    if(p.selectAllContent){
      cols = cols.filter(e => e.name !== 'operation')
      cols.push({
        title: 'Operation',
        name: 'operation',
        width: '100px',
        render: (text, record) => (
          <Row>
            <Col>
              <Button
                type="primary"
                size="small"
                onClick={()=>this.deleteRow(text.pk)}
              >
                Unselect
                <Icon type="minus-circle"/>
              </Button>
            </Col>
          </Row>
        ),
      })
    }
    const s = this.state
    return(
      <Modal
        title={get(action, 'label', '') + " All Selected " + startCase(p.index)}
        visible={p.visibleModalNew}
        // onOk={(e) => {
        //   this.showConfirm()
        // }}
        maskClosable={false}
        onOk={this.handleBulk}
        okButtonProps={{ disabled: !get(p.bulkAction, 'length') }}
        okText={get(action, 'label', 'Proceed')}
        okType={get(action, 'type') || 'primary'}
        onCancel={() => {
          p.setThisstate({visibleModalNew: false})
        }}
        width={'80%'}
        maxHeight={'50%'}>
          {(this.state.errorResponse && !loading) ? (
            <PageError
              errorResponse={this.state.errorResponse}
              onReload={this.reload}
            />
          ) : (
            <>
        <Row>
          <Col xs={24}>
            <StyledSpanNoSelect>Selected : {p.selectAllContent ? p.select_count : p.allSelectedRowKeys.length}</StyledSpanNoSelect>
            <Divider type="vertical"/>
            <StyledFilter
              style={{height: '24px', fontSize: '14px'}}
              onClick={() => p.clearSelected()}>
              Click to clear selected
              <Icon type="close-circle" style={{marginLeft: '5px'}} theme="filled"/>
            </StyledFilter>
            <Divider type="vertical" />
              {this.renderAction()}
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <StyledTable
              dataSource={p.selectAllContent ? data : p.allSelectedRows}
              columns={cols}
              size="small"
              loading={loading}
              rowKey={data => data.pk}
              scroll={{x: true}}
              onChange={s.pagination ? this.handleTableChange : ()=>null}
              pagination={s.pagination ? {
                pageSizeOptions: ['25', '50', '75', '100'],
                showSizeChanger: true,
                showQuickJumper: true,
                total: s.count,
                current: s.current,
                pageSize: s.limit,
                hideOnSinglePage: false
              } : { pageSize: 25 }}
              footer={() => Modules.infoTotalRow(s.count_search, s.count_row, s.count)}
            />
          </Col>
        </Row>
        </>
        )}
      </Modal>
    )
  }
}

BulkAction.propTypes = {
  queryParam: PropTypes.func
}

BulkAction.defaultProps = {
  queryParam: () => null,
}

const StyledTable = styled(Table)`
  tr:hover td div .anticon-ellipsis{
    display: block;
  } 
`;
const StyledFilter = styled.span`
  background-color: #1690ff;
  padding: 2px 4px;
  margin: 2px;
  font-size: 10px;
  border-radius: 4px;
  color: #fff;
  display: inline-block;
  cursor: pointer;
`;
const StyledSpanNoSelect = styled.span`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
