import React from 'react'
import { Card, Row, Spin, Icon, Tabs } from 'antd'
import { get } from 'lodash'
import PageAdd from './Add'
import PageDetail from './Detail'
import { PermissionName } from 'App/Config'
import PageUnauthorized from 'App/Component/PageUnauthorized'
import { forcePermissions } from 'App/Data'
import { CheckPermission } from 'App/Utils'
import History from "App/Screens/SubModules/Component/History";
import fields from './fields'
const { TabPane } = Tabs

class SkuMappingManagement extends React.Component {
  constructor(props) {
    super(props)

    this.isSuperuser = get(props.userReducer, ['user', 'is_superuser'])
    this.state = {
      isCheckingPermission: !this.isSuperuser,
      isAccessAllowed: this.isSuperuser,
      currentPermissionSet: this.isSuperuser ? forcePermissions : [],
    }
  }

  setThisState = (obj, callback = () => null, force) => {
    if (force === true) {
      return this.setState(obj, callback)
    } else {
      for (const key in obj) {
        if (obj[key] !== this.state[key]) {
          return this.setState(obj, callback)
        }
      }
    }
    callback()
  }

  handleCheckPermission = (callback = () => null) => {
    this.setThisState({ isCheckingPermission: true }, () => {
      const currentPermissionSet = CheckPermission.getPermissionSet({
        userReducer: this.props.userReducer,
        appName: 'merchant',
        modelName: 'skumapping',
      })

      const codePart = this.props.match.params.id === 'add' ? PermissionName.CREATE.codePart : PermissionName.READ.codePart
      const isAccessAllowed = !!currentPermissionSet.find(val => val.search(codePart) !== -1)
      callback({ isAccessAllowed, currentPermissionSet })
    })
  }

  componentDidMount() {
    // Please use callbackAllowed when access is allowed,
    // Jangan menuliskan code is allowed di luar kondisi
    const callbackAllowed = () => { /* Your code here */ }
    if (!this.isSuperuser) {
      this.handleCheckPermission(res => {
        this.setState({
          isCheckingPermission: false,
          isAccessAllowed: get(res, 'isAccessAllowed'),
          currentPermissionSet: get(res, 'currentPermissionSet', [])
        }, () => {
          if (get(res, 'isAccessAllowed')) {
            callbackAllowed()
          }
        })
      })
    } else {
      callbackAllowed()
    }
  }
  
  render() {
    const { isCheckingPermission, isAccessAllowed, currentPermissionSet } = this.state
    if (isCheckingPermission) {
      return (
        <Row type='flex' justify='center' align='middle' style={{ marginTop: '30vh' }}>
          <Spin
            size='large'
            tip='Checking permission...'
            indicator={<Icon spin type='loading' style={{ fontSize: '4rem' }} />}
          />
        </Row>
      )
    }

    if (isAccessAllowed) {
      return (
        get(this.props.match, ['params', 'id'], 'add') === 'add' ? (
          <PageAdd {...this.props} />
        ) : (
          <div className="p-3 bg-white">
          <Tabs defaultActiveKey="1" tabPosition={"left"}>
            <TabPane tab="Edit" key="1">
              <PageDetail {...this.props} currentPermissionSet={currentPermissionSet} />
            </TabPane>
            <TabPane tab="History" key="2">
              <div className="bg-white p-4">
                <History 
                  url="module-merchant-skuMapping-history" 
                  urlDetail="module-merchant-skuMapping-history-detail"
                  fields={fields}
                  updateHeader={(e)=>{
                    this.props.history.push('/merchant/sku-mapping/')
                  }}
                  id={get(this.props.match, 'params.id')} 
                  title={`#${get(this.props.match, 'params.id')}`} 
                />
              </div>
          </TabPane>
          </Tabs>
          </div>
        )
      )
    }
    return (
      <PageUnauthorized />
    )
  }
}

export default SkuMappingManagement