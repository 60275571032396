import React from 'react'
import PropTypes from 'prop-types'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highcharts'
import ReactResizeDetector  from 'react-resize-detector'
import { get } from 'lodash'

const opts = {
  "chart": {
    "type": "line"
  },
  "title": {
    "text": "Product Statistics"
  },
  "xAxis": {
    "categories": []
  },
  "yAxis": {
    "title": {
      "text": "Usage / unit"
    }
  },
  "plotOptions": {
    "line": {
      "dataLabels": {
        "enabled": true
      },
      "enableMouseTracking": true
    },
    series: {
      events: {
        legendItemClick: function(event) {
          // event.stopPropagation()
          // or
          return false
        }
      }
    }
  },
  "series": [],
  "credits": {
    "enabled": false
  }
}

class ProductStat extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      options: this.getChartOptions(props.products),
    }
  }

  updateChartOptions = (products) => {
    if (typeof get(this._chart, ['chart', 'update']) !== 'function') return

    this._chart.chart.update(this.getChartOptions(products))

    this.setState({ options: this.getChartOptions(products) })
  }

  getChartOptions = (products) => {
    const categories = []
    const series = []

    for (let i = 0; i < products.length; i += 1) {
      // Initializing fields
      if (i === 0) {
        const { range } = products[i].usage_range
        for (const j in range) {
          categories.push(range[j].label)
        }
      }

      // Convert Data to chart
      series.push({
        name: products[i].name,
        id: products[i].id,
        data: products[i].usage_range.range.map(val => val.total)
      })
    }

    opts.xAxis.categories = categories
    opts.series = series

    return opts
  }

  updateActiveProduct = (selectedProduct) => {
    for (const i in this.state.options.series) {
      this.state.options.series[i].visible =
        this.state.options.series[i].id === selectedProduct ||
        !selectedProduct
    }

    this.setState({ options: { ...this.state.options } })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.products !== nextProps.products) {
      this.updateChartOptions(nextProps.products)
    }

    if (this.props.selectedProduct !== nextProps.selectedProduct) {
      this.updateActiveProduct(nextProps.selectedProduct)
    }
  }

  render() {
    return (
      <ReactResizeDetector handleWidth>
        {({ width }) => {
          if (
            typeof get(this._chart, ['chart', 'reflow']) === 'function' &&
            this.widthContainer !== width
          ) {
            clearTimeout(this._reflow)

            this._reflow = setTimeout(() => {
              try {
                this._chart.chart.reflow()
              } catch(e) {}
            }, 500)
          }

          this.widthContainer = width

          return (
            <div>
              {/* <h2>Product Statistics</h2> */}
              <HighchartsReact
                ref={ref => {this._chart = ref}}
                highcharts={Highcharts}
                options={this.state.options}
              />
            </div>
          )
        }}
      </ReactResizeDetector>
    )
  }
}

ProductStat.propTypes = {
  selectedProduct: PropTypes.number,
  products: PropTypes.array,
}

ProductStat.defaultProps = {
  selectedProduct: null,
  products: [],
}

export default ProductStat