import React from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import JSONView from "react-json-view";
import TurndownService from "turndown";
import moment from "moment";
import {
  Spin,
  Layout,
  Button,
  Row,
  Col,
  Icon,
  Form,
  Tabs,
  Input,
  Tag,
  message,
} from "antd";
import { find, every, noop, pick, omit } from "lodash";
import { isEmpty } from "validate.js";

import Base from "App/Component/Bases/Base";
import PageError from "App/Component/PageError";
import DynamicField from "App/Component/DynamicField";
import Lib2 from "App/Component/Singlepage/Lib2";
import { FormContext, FormProvider } from "App/Component/Context/ContextForm";
import { PageHeader } from "App/Screens/SubModules/Component/BaseDetail";
import { getValue, requestV3, Modules } from "App/Utils";
import getFeedbackItems from "../utils/getFeedbackItems";
import statusMapping from "../utils/statusMapping";
import UploadAdapter from "../utils/UploadAdapter";

const turndownPluginGfm = require("turndown-plugin-gfm");

const editorToolbarConfig = [
  "heading",
  "|",
  "bold",
  "italic",
  "link",
  "|",
  "bulletedList",
  "numberedList",
  "|",
  "imageUpload",
  "blockQuote",
  "|",
  "undo",
  "redo",
];

class ItemDetail extends Base.PureComponent {
  get isFact() {
    return this.type === "fact";
  }

  constructor(props) {
    super(props);
    this.initState({
      item: null,
      job: null,
      errResObj: null,
      currentIdx: -1,
      items: [],
    });
    this.jobId = +props.match.params.id;
    this.itemId = props.match.params.item_id;
    this.type = props.match.params.type;
    this.headerDescriptions = [
      { key: "job.full_name", label: "Job" },
      { key: "item.id", label: "ID" },
      { key: "item.type", label: "Type" },
      { key: "item.createdBy.full_name", label: "Created By" },
      { key: "item.created", label: "Created", type: "datetime" },
      { key: "item.modifiedBy.full_name", label: "Modified By" },
      { key: "item.modified", label: "Modified", type: "datetime" },
      {
        key: "item.status",
        label: "Status",
        render(item) {
          return item ? (
            <Tag color={statusMapping[item].color}>
              {statusMapping[item].label}
            </Tag>
          ) : (
            "-"
          );
        },
      },
    ];
    this.fields = [];
    this.factFields = [
      {
        title: "Plan",
        type: "custom",
        dataIndex: "extraObj",
        colProps: { span: 24, md: 24 },
        renderCustomField: ({ dataRecord }) => {
          return (
            <a
              target="_blank"
              /*href={getValue(dataRecord, "file")}*/ onClick={() =>
                this.planClickedHandler(dataRecord)
              }
            >
              {getValue(dataRecord, "cloud_plan_filename") ||
                getValue(dataRecord, "plan_file.file_name")}
            </a>
          );
        },
      },
      {
        title: "Descriptions",
        type: "custom",
        required: true,
        data: "",
        initialValue: "",
        note_text: "",
        dataIndex: "feedback",
        dataIndexReceive: "originalObj.user_feedback",
        fieldKey: "feedback",
        colProps: { span: 24, md: 24 },
        order: 5,
        renderCustomField: this.renderDescField,
      },
    ];
    this.validationFields = [
      {
        title: "Section",
        dataIndex: "section",
        dataIndexReceive: "originalObj.subsection.section",
        type: "SelectQuery",
        data: "sections",
        selectedKey: "pk",
        selectedValue: "name",
        dropdownClassName: "width-auto",
        // relations: ['subsection'],
        additional: "edit",
        skip: true,
        order: 0,
        inputProps: { disabled: true },
      },
      {
        title: "Subsection",
        type: "SelectQuery",
        data: "subsections",
        additional: "edit",
        initialValue: "",
        note_text: "",
        selectedKey: "pk",
        selectedValue: "name",
        dropdownClassName: "width-auto",
        dataIndex: "subsection",
        dataIndexReceive: "originalObj.subsection",
        fieldKey: "subsection",
        foreignKey: "section",
        order: 1,
        inputProps: { disabled: true },
      },
      {
        title: "Field",
        type: "text",
        data: "",
        initialValue: "",
        note_text: "",
        dataIndex: "originalObj.field",
        fieldKey: "field",
        operation: "view",
        order: 2,
      },
      {
        title: "Validation Type",
        type: "text",
        data: "",
        initialValue: "",
        note_text: "",
        dataIndex: "originalObj.validation_type",
        fieldKey: "validation_type",
        operation: "view",
        order: 2,
      },
      {
        title: "Action",
        type: "text",
        data: "",
        initialValue: "",
        note_text: "",
        dataIndex: "originalObj.action",
        fieldKey: "action",
        operation: "view",
        order: 2,
      },
      {
        title: "Suggested",
        type: "text",
        data: "",
        note_text:
          "Suggested from System (rule). If Validation Type = 'request', it means suggested by User same as 'Expected' field.\nIf the text is unclear to read, please see the original object in 'Extra' -> 'parsedRecord' field.",
        initialValue: "",
        dataIndex: "suggested",
        dataIndexReceive: "originalObj.source_object.record",
        fieldKey: "suggested",
        operation: "view",
        inputProps: { disabled: true },
      },
      {
        title: "Rule Key",
        type: "text",
        data: "",
        initialValue: "",
        note_text: "",
        dataIndex: "originalObj.source_object.rule_key",
        fieldKey: "rule_key",
        operation: "view",
        order: 3,
      },
      {
        title: "Rule ID",
        type: "text",
        data: "",
        initialValue: "",
        note_text: "",
        dataIndex: "originalObj.source_object.rule_id",
        fieldKey: "rule_id",
        operation: "view",
        order: 4,
      },
      {
        title: "Extra",
        type: "custom",
        dataIndex: "extra",
        dataIndexReceive: "originalObj.source_object",
        renderCustomField: ({ getFieldDecorator, dataIndex, dataRecord }) => {
          return getFieldDecorator(dataIndex, {
            initialValue: dataRecord,
            valuePropName: "src",
          })(
            <JSONView
              name={false}
              displayDataTypes={false}
              enableClipboard={false}
              collapsed={true}
              indentWidth={2}
              style={{ lineHeight: 1.5 }}
            />
          );
        },
      },
      {
        title: "Expected",
        type: "text",
        data: "",
        initialValue: "",
        note_text: "Expected (correct) result from User.",
        dataIndex: "expected",
        dataIndexReceive: "originalObj.source_object.feedback_object.expected",
        fieldKey: "expected",
        operation: "view",
        order: 4,
        inputProps: { disabled: true },
      },
      {
        title: "Descriptions",
        type: "custom",
        required: true,
        data: "",
        initialValue: "",
        note_text: "",
        dataIndex: "feedback",
        dataIndexReceive:
          "originalObj.source_object.feedback_object.description",
        fieldKey: "feedback",
        colProps: { span: 24, md: 24 },
        order: 5,
        renderCustomField: this.renderDescField,
      },
    ];
    this.bothFields = [
      {
        title: "Status",
        type: "select",
        disableAllowClear: true,
        data: [
          {
            alias: "new",
            value: "New",
          },
          {
            alias: "approve",
            value: "Approved",
          },
          {
            alias: "complete",
            value: "Completed",
          },
          {
            alias: "reject",
            value: "Rejected",
          },
        ],
        initialValue: "new",
        note_text: "",
        dataIndex: "status",
        fieldKey: "feedback_status",
        // renderCustomField: this.renderStatusField,
        colProps: { md: { span: 12, order: 10 }, sm: { span: 24, order: 10 } },
      },
      {
        title: "Is Added To Rule",
        type: "checkbox",
        required: false,
        data: "",
        initialValue: false,
        note_text: "",
        dataIndex: "isAdded",
        fieldKey: "feedback_added_to_rule",
        colProps: { md: { span: 12, order: 11 }, sm: { span: 24, order: 13 } },
      },
      {
        title: "Checked By",
        type: "custom",
        required: false,
        data: "",
        initialValue: "",
        note_text: "",
        dataIndex: "checkedBy",
        dataIndexReceive: "checkedBy.full_name",
        fieldKey: "feedback_checked_by",
        renderCustomField({ initial }) {
          return initial ? initial : "-";
        },
        colProps: { md: { span: 12, order: 12 }, sm: { span: 24, order: 11 } },
      },
      {
        title: "Checked",
        type: "custom",
        required: false,
        data: "",
        initialValue: null,
        note_text: "",
        dataIndex: "checked",
        fieldKey: "feedback_checked_time",
        renderCustomField({ initial }) {
          return initial
            ? moment(initial).format("ddd, DD MMM YYYY hh:mm:ss A")
            : "-";
        },
        colProps: { md: { span: 12, order: 14 }, sm: { span: 24, order: 12 } },
      },
      {
        title: "Added By",
        type: "custom",
        required: false,
        data: "",
        initialValue: "",
        note_text: "",
        dataIndex: "addedBy",
        dataIndexReceive: "addedBy.full_name",
        fieldKey: "feedback_added_to_rule_by",
        renderCustomField({ initial }) {
          return initial ? initial : "-";
        },
        colProps: { md: { span: 12, order: 13 }, sm: { span: 24, order: 14 } },
      },
      {
        title: "Added",
        type: "custom",
        required: false,
        data: "",
        initialValue: "",
        note_text: "",
        dataIndex: "added",
        dataIndexReceive: "added",
        fieldKey: "feedback_added_to_rule_time",
        renderCustomField({ initial }) {
          return initial
            ? moment(initial).format("ddd, DD MMM YYYY hh:mm:ss A")
            : "-";
        },
        colProps: { md: { span: 12, order: 15 }, sm: { span: 24, order: 15 } },
      },
    ];
    this.turndownService = new TurndownService();
    this.turndownService.use(turndownPluginGfm.gfm);
  }

  setFields = () => {
    this.fields = [
      ...(this.isFact ? this.factFields : this.validationFields).map((f) => ({
        ...f,
      })),
      ...this.bothFields,
    ];
  };

  resetFormFieldsValue = (values, fields = []) => {
    const resObj = {};

    for (const field of this.fields)
      if (!fields.length || fields.includes(field.fieldKey))
        resObj[field.dataIndex] = getValue(
          values,
          field.dataIndexReceive || field.dataIndex,
          field.initialValue
        );

    this._form.setFieldsValue(resObj);
  };

  hasPrevItem = () => {
    return !!this.state.items[this.state.currentIdx - 1];
  };

  hasNextItem = () => {
    return !!this.state.items[this.state.currentIdx + 1];
  };

  goBack = () => {
    this.props.history.goBack();
  };

  goPrev = () => {
    this.goOnItem(false);
  };

  goNext = () => {
    this.goOnItem();
  };

  goOnItem = (next = true) => {
    const nextItem =
      this.state.items[
        next ? this.state.currentIdx + 1 : this.state.currentIdx - 1
      ];

    this.props.history.replace(
      `/expert-system/feedbacks/${this.jobId}/${nextItem.type}/${nextItem.id}`
    );
    this.itemId = nextItem.id;
    this.type = nextItem.type;
    this.retrieve();
  };

  goTo = (action, url, idData, additionalTitle, dataIdentifier) => {
    Lib2.pushFunc(
      this.props,
      this.state,
      action,
      url,
      idData,
      additionalTitle,
      dataIdentifier,
      undefined,
      undefined,
      undefined,
      true
    );
  };

  showDescMarkdown = () => {
    const descValue = this._form.getFieldValue("feedback"),
      convertedValue = this.turndownService.turndown(descValue || "");

    this._form.setFieldsValue({ feedbackPreview: convertedValue });
  };

  retrieve = (callback = noop) => {
    this.setState({ loading: true }, () => {
      getFeedbackItems({
        jobId: this.jobId,
        itemId: +this.itemId,
        type: this.type,
      }).then((obj) => {
        let nextState = { loading: false };

        if (obj.errResObj) {
          nextState.errResObj = obj.errResObj;
          callback(false);
        } else {
          if (obj.item) {
            this.setFields();

            const statusField = find(this.fields, {
              fieldKey: "feedback_status",
            });

            if (!this.isFact) {
              const expectedField = find(this.fields, {
                  fieldKey: "expected",
                }),
                suggestedField = find(this.fields, {
                  fieldKey: "suggested",
                }),
                changedObjFields = [
                  {
                    type: "prefill_set",
                    field: {
                      data: "prefillSets",
                      type: "SelectQuery",
                      initialValue: "",
                      selectedKey: "pk",
                      selectedValue: "name",
                      paramProps: {
                        subsection: getValue(
                          obj.item,
                          "originalObj.subsection.pk"
                        ),
                      },
                      disableChange: true,
                      additional: "edit",
                      operation: null,
                    },
                  },
                  {
                    type: "override_set",
                    field: {
                      data: "overrideSets",
                      type: "SelectQuery",
                      initialValue: "",
                      selectedKey: "pk",
                      selectedValue: "name",
                      paramProps: {
                        subsection: getValue(
                          obj.item,
                          "originalObj.subsection.pk"
                        ),
                      },
                      disableChange: true,
                      additional: "edit",
                      operation: null,
                    },
                  },
                ],
                expectedObjFields = [
                  ...changedObjFields,
                  {
                    type: "product",
                    field: {
                      data: "products",
                      type: "SelectQuery",
                      initialValue: "",
                      selectedKey: "pk",
                      selectedValue: "name",
                      disableChange: true,
                      additional: "edit",
                      operation: null,
                    },
                  },
                ],
                suggestedObjFields = [
                  ...changedObjFields,
                  {
                    type: "tag",
                    field: {
                      data: "tags",
                      type: "SelectQuery",
                      initialValue: null,
                      selectedKey: "pk",
                      selectedValue: "name",
                      additional: "edit",
                      disableChange: true,
                      operation: null,
                    },
                  },
                ],
                matchedExpectedField = find(expectedObjFields, {
                  type: obj.item.originalObj.field,
                }),
                matchedSuggestedField = find(suggestedObjFields, {
                  type: obj.item.originalObj.field,
                });

              if (matchedExpectedField)
                for (const k in matchedExpectedField.field)
                  expectedField[k] = matchedExpectedField.field[k];

              if (matchedSuggestedField)
                for (const k in matchedSuggestedField.field)
                  suggestedField[k] = matchedSuggestedField.field[k];
            }

            // statusField.inputProps = { disabled: obj.item.isAdded };

            this.resetFormFieldsValue(obj.item, [
              "feedback",
              "feedback_status",
              "feedback_added_to_rule",
            ]);
            this.showDescMarkdown();

            console.log("obj", obj);

            nextState = {
              ...nextState,
              job: obj.job,
              item: obj.item,
              items: obj.items,
              currentIdx: obj.items.indexOf(obj.item),
              errResObj: null,
            };
            callback(true);
          } else {
            nextState.errResObj = { status: 404 };
            callback(false);
          }
        }

        this.setState(nextState);
      });
    });
  };

  save = (callback = noop) => {
    this.setState({ loading: true }, () => {
      const formValues = this._form.getFieldsValue(),
        { item } = this.state,
        userObj = pick(this.props.userReducer.user, [
          "pk",
          "username",
          "full_name",
        ]),
        isChecked = formValues.status !== "new",
        checkedObj =
          isChecked && item.status !== formValues.status
            ? {
                feedback_checked_by: userObj,
                feedback_checked_time: moment().utc().format(),
              }
            : !isChecked
            ? {
                feedback_checked_by: null,
                feedback_checked_time: null,
              }
            : null,
        addedObj =
          !item.isAdded && formValues.isAdded
            ? {
                feedback_added_to_rule_by: userObj,
                feedback_added_to_rule_time: moment().utc().format(),
              }
            : item.isAdded && !formValues.isAdded
            ? {
                feedback_added_to_rule_by: null,
                feedback_added_to_rule_time: null,
              }
            : null;

      if (this.isFact)
        requestV3({
          method: "put",
          urlKey: "module-expertSystem-feedback-job-plan-fact-detail",
          args: [item.id],
          data: {
            job: this.jobId,
            plan_file: getValue(item, "extraObj.plan_file.pk", null),
            cloud_plan_file: getValue(item, "extraObj.cloud_plan_file", null),
            fact_data: {
              ...item.originalObj,
              user_feedback: formValues.feedback,
              user_feedback_noted: isChecked,
              feedback_added_to_rule: !!formValues.isAdded,
              feedback_status: formValues.status,
              ...checkedObj,
              ...addedObj,
              modified: moment().utc().format(),
            },
          },
          onBoth: (success, err) => this.savedBoth(success, err, callback),
        }).then();
      else {
        const description = getValue(formValues, "feedback", null),
          expected = getValue(
            item,
            "originalObj.source_object.feedback_object.expected",
            null
          ),
          feedbackObj = !every([description, expected], isEmpty)
            ? {
                description,
                expected,
              }
            : null;

        requestV3({
          method: "patch",
          urlKey: "module-expertSystem-feedback-job-validation-detail",
          args: [this.jobId, item.id],
          data: {
            source_object: {
              ...omit(item.originalObj.source_object, ["parsedRecord"]),
              feedback_object: feedbackObj,
              feedback_checked: isChecked,
              feedback_added_to_rule: !!formValues.isAdded,
              feedback_status: formValues.status,
              ...checkedObj,
              ...addedObj,
            },
          },
          onBoth: (success, err) => this.savedBoth(success, err, callback),
        }).then();
      }
    });
  };

  savedBoth = (success, err, callback = noop) => {
    if (success) {
      message.success("Feedback saved!");
      this.retrieve(callback);
    } else {
      Modules.responseMessage("error", err.detail);
      this.setState({ loading: false }, callback);
    }
  };

  saveAndNext = () => {
    this.save(this.goNext);
  };

  planClickedHandler = (record) => {
    if (record.cloud_plan_file)
      requestV3({
        urlKey: "module-expertSystem-feedback-s3-file-sign",
        args: [record.cloud_plan_file],
        onSuccess({ url }) {
          window.open(url);
        },
        onFailed(err) {
          Modules.responseMessage("error", err, "Open Plan Failed!");
        },
      }).then();
    else window.open(getValue(record, "plan_file.file"));
  };

  descriptionChangedHandler = (_, editor) => {
    return editor.getData();
  };

  textEditorInitiatedHandler = (editor) => {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return new UploadAdapter(loader);
    };
  };

  descTabClickHandler = (key) => {
    if (key === "preview") this.showDescMarkdown();
  };

  pageReloadHandler = (_, callback) => {
    this.retrieve(callback);
  };

  renderDescField = ({ getFieldDecorator, dataIndex, initial }) => {
    return (
      <Tabs
        type="card"
        defaultActiveKey="write"
        onTabClick={this.descTabClickHandler}
      >
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="edit" />
              Write
            </span>
          }
          key="write"
        >
          {getFieldDecorator(dataIndex, {
            initialValue: initial,
            valuePropName: "data",
            getValueFromEvent: this.descriptionChangedHandler,
            validateTrigger: null,
          })(
            <CKEditor
              editor={ClassicEditor}
              config={{ toolbar: editorToolbarConfig }}
              onInit={this.textEditorInitiatedHandler}
            />
          )}
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="github" />
              Markdown
            </span>
          }
          key="preview"
        >
          {getFieldDecorator("feedbackPreview", {
            initialValue: null,
            validateTrigger: null,
            trigger: null,
          })(
            <Input.TextArea
              disabled
              autoSize={{ minRows: 5 }}
              className="es-feedback-md-textarea"
            />
          )}
        </Tabs.TabPane>
      </Tabs>
    );
  };

  renderField = (field, idx, fields) => {
    return (
      <DynamicField
        key={field.dataIndex}
        {...field}
        toggleDrawerSQ={this.goTo}
        dataRecord={getValue(
          this.state.item,
          field.dataIndexReceive || field.dataIndex
        )}
        allFields={fields}
        userReducer={this.props.userReducer}
      />
    );
  };

  didMount() {
    this.retrieve();
  }

  render() {
    const [s] = [this.state];

    return (
      <Spin spinning={s.loading}>
        {!s.errResObj ? (
          <Layout className="um-layout bg-white">
            <PageHeader
              title="Feedback"
              onBack={this.goBack}
              descriptions={this.headerDescriptions}
              data={{ job: s.job, item: s.item }}
            />
            <Layout.Content className="p-3">
              <Row>
                <Col>
                  <FormProvider>
                    <Form>
                      <Row type="flex">{this.fields.map(this.renderField)}</Row>
                      <FormContext.Consumer>
                        {this.refForm}
                      </FormContext.Consumer>
                    </Form>
                  </FormProvider>
                </Col>
              </Row>
              <Row type="flex" justify="space-between">
                <Col>
                  {this.hasPrevItem() && (
                    <Button type="primary" onClick={() => this.goPrev()}>
                      <Icon type="step-backward" />
                      Previous
                    </Button>
                  )}
                </Col>
                <Col>
                  <Button
                    type="primary"
                    icon="save"
                    onClick={() => this.save()}
                  >
                    Save
                  </Button>
                  {this.hasNextItem() && (
                    <>
                      <Button
                        type="primary"
                        className="mx-2"
                        icon="save"
                        onClick={() => this.saveAndNext()}
                      >
                        Save & Next
                      </Button>
                      <Button type="primary" onClick={() => this.goNext()}>
                        Next
                        <Icon type="step-forward" />
                      </Button>
                    </>
                  )}
                </Col>
              </Row>
            </Layout.Content>
          </Layout>
        ) : (
          <PageError
            errorResponse={s.errResObj}
            onGoBack={this.goBack}
            onReload={this.pageReloadHandler}
          />
        )}
      </Spin>
    );
  }
}

export default ItemDetail;
