import { BaseDetail } from 'App/Screens/SubModules/Component'
import fields from './fields'

class PageDetail extends BaseDetail {
  constructor(props) {
    super(props)

    this.urlKey = 'module-merchant-tagMerchant-detail'
    this.title = 'Tag Merchant'
    this.urlList = '/merchant/tag-merchant'
    this.headerDescriptions = [
      { key: 'tag.label', label: 'Tag' },
      { key: 'merchant.name', label: 'Merchant' },
    ]
    this.fields = fields
  }
}

export default PageDetail