import React from 'react'
import { BaseDetail } from 'App/Component/Bases'
import fields from './fields'

class SkuMappingManagement extends BaseDetail {
  title = 'SKU Mapping';
  urlData = 'module-merchant-skuMapping'
  urlName = 'module-merchant-skuMapping-delete'
  fields = fields;
  state = {
    isCheckingPermission: false,
    isAccessAllowed: true,
    currentPermissionSet: this.props.currentPermissionSet,
  }
}

class SkuMappingBridge extends React.Component {
  render() {
    return (
      <SkuMappingManagement
        urlName='?:?:reports:skumapping:listordetail'
        {...this.props}
      />
    )
  }
}

export default SkuMappingBridge