const module = 'job-management'
const list = [
  {
    label: 'Jobs',
    url: `/${module}/jobs`,
    icon: 'usb',
    code: 'jobs.{}_master_job',
  },
  {
    label: 'Consistency Notes',
    url: `/${module}/notes`,
    icon: 'snippets',
    code: 'note.{}_consistencynote',
  },
  {
    label: 'ERF',
    url: `/${module}/erf`,
    icon: 'layout',
    code: 'jobs.{}_erftype',
  },
  {
    label: 'Building Type',
    url: `/${module}/building-type`,
    icon: 'build',
    code: 'jobs.{}_buildingtype',
  },
  {
    label: 'Rate Type',
    url: `/${module}/rate-type`,
    icon: 'tag',
    code: 'jobs.{}_ratetype',
  },
  {
    label: 'Job Grade',
    url: `/${module}/job-grade`,
    icon: 'project',
    code: 'jobs.{}_jobgrade',
  },
  {
    label: 'Job Type',
    url: `/${module}/job-type`,
    icon: 'deployment-unit',
    code: 'jobs.{}_jobtype',
  },
  {
    label: 'Range',
    url: `/${module}/range`,
    icon: 'control',
    code: 'jobs.{}_ranges',
  },
  {
    label: 'Transitions',
    url: `/${module}/transition`,
    icon: 'pull-request',
    code: 'jobs.{}_jobstatustransition',
  },
]

export default { key: module, list }
