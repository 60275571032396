export default {
  // "branches-read": "api/admin/multisite/branches/",
  // "branches-create": "api/admin/multisite/branches/",
  // "branches-delete": "api/admin/multisite/branches/{}/",
  // "branches-update": "api/admin/multisite/branches/{}/",
  // "branches-detail": "api/admin/multisite/branches/{}/",
  // "branches-detail-revision-version": "api/admin/multisite/branches/{}/revision/{}/",
  // "branches-autocomplete": "api/admin/multisite/branches/autocomplete/",

  "branches-read": { url: "api/branch/multisite/branches/", version: 3 },
  "branches-create": { url: "api/branch/multisite/branches/", version: 3 },
  "branches-delete": { url: "api/branch/multisite/branches/{}/", version: 3 },
  "branches-update": { url: "api/branch/multisite/branches/{}/", version: 3 },
  "branches-detail": { url: "api/branch/multisite/branches/{}/", version: 3 },
  "branches-detail-revision-version": { url: "api/branch/multisite/branches/{}/revision/{}/", version: 3 },
  "branches-autocomplete": { url: "api/branch/multisite/branches/autocomplete/", version: 3 },

  "pendingMonitorReports-read": "api/admin/multisite/pending-monitor-reports/",
  "pendingMonitorReports-create": "api/admin/multisite/pending-monitor-reports/",
  "pendingMonitorReports-delete": "api/admin/multisite/pending-monitor-reports/{}/",
  "pendingMonitorReports-update": "api/admin/multisite/pending-monitor-reports/{}/",
  "pendingMonitorReports-detail": "api/admin/multisite/pending-monitor-reports/{}/",
  "pendingMonitorReports-autocomplete": "api/admin/multisite/pending-monitor-reports/autocomplete/",

  "branchManagement-read": { url: "api/branch/multisite/branches/", version: 3 },
  "branchManagement-create": { url: "api/branch/multisite/branches/", version: 3 },
  "branchManagement-delete": { url: "api/branch/multisite/branches/{}/", version: 3 },
  "branchManagement-update": { url: "api/branch/multisite/branches/{}/", version: 3 },
  "branchManagement-detail": { url: "api/branch/multisite/branches/{}/", version: 3 },
  "branchManagement-autocomplete": { url: "api/branch/multisite/branches/autocomplete/", version: 3 },

  "servers-read": "api/admin/multisite/servers/",
  "servers-create": "api/admin/multisite/servers/",
  "servers-detail": "api/admin/multisite/servers/{}/",
  "servers-update": "api/admin/multisite/servers/{}/",

  "branchRatings-read": "api/admin/billing/rates/",
  "branchRatings-detail": "api/admin/billing/rates/{}/",
  "branchRatings-update": "api/admin/billing/rates/{}/",
  "branchRatings-delete": "api/admin/billing/rates/{}/",
}
