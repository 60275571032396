import React from 'react'
import PropTypes from 'prop-types'
import { Button, Col, Icon, Layout, message, Row, Spin, Tabs } from 'antd'
import { get } from 'lodash'
import { Lib, RequestV2 as Request } from 'App/Utils'
import PageError from 'App/Component/PageError'
import { PageSetting, PageMerchants, PageReports } from './Pages'
// import { BasicPageSetting, ContentReference } from './internal'
import ChangePassword from '../internal/ChangePassword'
import fields from './fields'
import fieldsAdd from './fieldsAdd'

const { TabPane } = Tabs

class TrackerManagement extends React.PureComponent {
  constructor(props) {
    super(props)

    this.headerDescriptions = []
    this.title = 'Tracker User'
    this.addMode = props.match.params.id === 'add'
    this.urlKeyList = 'module-groupUser-userTracker-read'
    this.urlKeyDetail = 'module-groupUser-userTracker-detail'
    this.state = {
      activeTabKey: 'general',
      data: null,
      errorResponse: null,
      isLoading: true,
      isReloading: false,
      isSaving: false,
      userHelper: null,
    }
  }

  readHelper = () => {
    this.setState({ isLoading: true }, () => {
      Request(
        'get',
        'module-helper-user',
        {},
        {project_type: 'tracker'},
        [],
        this.readHelperSuccess,
        this.readHelperFailed,
      )
    })
  }

  readHelperSuccess = (response) => {
    this.setState({ userHelper: response.data, isLoading: !this.addMode, errorResponse: null }, () => {
      if (!this.addMode) {
        this.retrieve()
      }
    })
  }

  readHelperFailed = (error) => {
    // error sudah di tampilkan <PageError
    // Lib.errorMessageHandler(get(error, 'response'))
    this.setState({ isLoading: false, errorResponse: get(error, 'response') })
  }

  retrieve = () => {
    Request(
      'get',
      this.urlKeyDetail,
      {},
      {},
      [this.props.match.params.id],
      this.retrieveSuccess,
      this.retrieveFailed,
    )
  }

  retrieveSuccess = (response) => {
    this.setState({ isLoading: false, data: response.data, errorResponse: null })
  }

  retrieveFailed = (error) => {
    // error sudah di tampilkan <PageError
    // Lib.errorMessageHandler(get(error, 'response'))
    this.setState({ isLoading: false, errorResponse: get(error, 'response') })
  }

  getDescElems = () => {
    const { data } = this.state
    return (
      <Row className='ant-desc-custom' type='flex' gutter={[12, 12]} style={{ marginTop: '12px' }}>
        {
          this.headerDescriptions.map(item => (
            <Col key={item.key} xs={24} sm={12} lg={8}>
              <Row className='header-content-item'>
                <span className='ant-descriptions-item-label ant-descriptions-item-colon'>
                  {item.label}
                </span>
                <span className='ant-descriptions-item-content'>
                  {Lib.getValue(data, item)}
                </span>
              </Row>
            </Col>
          ))
        }
      </Row>
    )
  }

  setActiveKey = (activeTabKey, callback) => this.setState({ activeTabKey }, callback)

  onShowPassword = () => {
    if (this._modal && typeof this._modal.show === 'function') this._modal.show()
  }

  validateFields = (callback = () => null) => {
    this._general.validateFields((values) => {
      if (!values) return

      const user_merchant_access = this._merchant.validateFields()
      const user_allowed_reports = this._report.validateFields()
      
      callback({ ...values, user_merchant_access, user_allowed_reports })
    })
  }

  onSave = () => {
    this.validateFields((values) => {
      this.setState({ isSaving: true }, () => {
        this.save(values)
      })
    })
  }

  save = (values) => {
    Request(
      this.addMode ? 'post' : 'put',
      this.addMode ? this.urlKeyList : this.urlKeyDetail,
      {},
      values,
      [this.props.match.params.id],
      this.saveSuccess,
      this.saveFailed,
    )
  }

  saveSuccess = (response) => {
    message.success('Data has been saved')
    this.setState({ isSaving: false }, () => {
      this.props.history.replace(`/user-group/user/tracker/${response.data.pk}`)
    })
  }

  saveFailed = (error) => {
    Lib.errorMessageHandler(get(error, 'response'))
    this.setFieldsError(error)
    this.setState({ isSaving: false })
  }

  setFieldsError = (error) => {
    const objError = get(error, 'response.data')
    const general = ['username', 'firstname', 'lastname', 'email', 'is_active', 'password']
    
    if (typeof objError === 'object') {
      for (const key in objError) {
        if (general.includes(key)) {
          this._general.setError(key, objError[key])
        } else if (key === 'user_allowed_reports') {
          this._report.setError(objError[key])
        } else if (key === 'user_merchant_access') {
          this._merchant.setError(objError[key])
        }
      }
    }
  }

  onGoHome = () => {
    Lib.historyAction(this.props.history, '/user-group/user/tracker').goBack()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.match.params.id !== nextProps.match.params.id) {
      this.addMode = nextProps.match.params.id === 'add'
      this.readHelper()
    }
  }

  componentDidMount() {
    this.readHelper()
  }

  render() {
    const { activeTabKey, data, errorResponse, isLoading, isSaving, readOnly, userHelper } = this.state

    if (isLoading) {
      return (
        <div style={{ paddingTop: '100px', textAlign: 'center' }}>
          <Spin
            size='large'
            tip='Loading...'
            indicator={<Icon spin type='loading' style={{ fontSize: '4rem' }} />}
          />
        </div>
      )
    }

    return !errorResponse ? (
      <Layout>
        <div className='ant-page-header has-footer'>
          <Row type='flex' justify='space-between' gutter={[12, 12]} style={{ borderBottom: '1px solid #e8e8e8' }}>
            <Col>
              <Row type='flex' align='middle'>
                <Icon className='ant-page-header-back-button' type='arrow-left' style={{ marginRight: '16px' }} onClick={this.onGoHome} />
                <Col className='header-title text-ellipsis'>
                  Tracker User
                </Col>
                {readOnly && (
                  <Col style={{ fontSize: '12px', fontWeight: 500, backgroundColor: '#1890ff', color: '#fff', padding: '0 12px 2px 12px', borderRadius: '14px', marginLeft: '12px' }}>
                    Read Only
                  </Col>
                )}
              </Row>
            </Col>
            <Col>
              {typeof this.extra === 'function' ? this.extra() : this.extra}
              <Row type='flex' gutter={[6, 6]}>
                {!this.addMode && (
                  <Col>
                    <Button icon='key' onClick={this.onShowPassword}>
                      Change Password
                    </Button>
                    <ChangePassword
                      onRef={ref => {this._modal = ref}}
                      currentUser={data}
                    />
                  </Col>
                )}
                <Col>
                  <Button type='primary' icon='save' loading={isSaving} onClick={this.onSave}>
                    Save
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          {!this.addMode && (
            <div>
              {this.getDescElems()}
            </div>
          )}
          <div className='ant-page-header-footer'>
            <div style={{ height: '1px', backgroundColor: '#e8e8e8' }} />
          </div>
        </div>
        <div style={{ backgroundColor: '#fff', marginBottom: '24px' }}>
          <Tabs className='custom-margin-tab' tabBarGutter={0} activeKey={activeTabKey} onChange={this.setActiveKey}>
            {!readOnly && (
              <TabPane key='general' tab={<span><Icon type='setting' />General</span>}>
                <Row style={{ padding: '0 24px 24px 24px' }}>
                  <PageSetting
                    ref={ref => {this._general = ref}}
                    user={data}
                    tabKey='general'
                    setActiveKey={this.setActiveKey}
                    fields={this.addMode ? fieldsAdd : fields}
                  />
                </Row>
              </TabPane>
            )}
            <TabPane forceRender key='merchant' tab={<span><Icon type='contacts' />Merchants</span>}>
              <Row style={{ padding: '0 24px 24px 24px' }}>
                <PageMerchants
                  ref={ref => {this._merchant = ref}}
                  user={data}
                  userHelper={userHelper}
                  tabKey='merchant'
                  setActiveKey={this.setActiveKey}
                />
              </Row>
            </TabPane>
            <TabPane forceRender key='report' tab={<span><Icon type='audit' />Reports</span>}>
              <Row style={{ padding: '0 24px 24px 24px' }}>
                <PageReports
                  ref={ref => {this._report = ref}}
                  user={data}
                  userHelper={userHelper}
                  tabKey='report'
                  setActiveKey={this.setActiveKey}
                />
              </Row>
            </TabPane>
          </Tabs>
        </div>
      </Layout>
    ) : (
      <PageError
        errorResponse={errorResponse}
        onReload={this.readHelper}
      />
    )
  }
}

export default TrackerManagement