import React from 'react'
import { Row, Col, Select, Button, Spin, Empty, Card } from 'antd'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highcharts'
import { options as optsStackedColumn } from './StackedColumn'
import { RequestV2 } from 'App/Utils'
import {findIndex, lowerCase} from 'lodash'
import 'App/Component/Singlepage/SingleStyle.css'

const { Option } = Select
const statusColor = {
  New: '#74b9ff',
  Preqs: '#fed330',
  Assigned: '#ff8946',
  Started: '#1890ff',
  QA1: '#ffae56',
  QA2: '#8a4bff',
  Reopened: '#3867d6',
  Pending: '#e1b12c',
  Finished: '#36cc86',
  Cancelled: '#e86d37',
  Closed: '#ff3a29',
};

class ChartJobQueue extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      limitReceived: '7d',
      isLoading: true,
      // jobQueueSummary: [], // variable belum berguna
      currentOptions: null,
    }
  }

  setThisState = (obj, callback = () => null) => {
    for (const key in obj) {
      if (obj[key] !== this.state[key]) {
        return this.setState(obj, callback)
      }
    }
    callback()
  }

  getStatuscolor=(status)=>{
    // let status = lowerCase(val)
    let s = statusColor[status]
    if(!s)
      s = '#000000'
    return s
  }

  read = () => {
    this.setThisState({ isLoading: true }, () => {
      const { limitReceived } = this.state
      RequestV2(
        'get', // method
        'chart-job-queues', // urlKey
        {}, // headers
        { limit_received: limitReceived, status: true }, // data
        [], // args
        this.readSuccess,
        err => this.setState({ isLoading: false, currentOptions: null }),
      )
    })
  }

  readSuccess = response => {

    const colors = []

    const a = response.data
    const data = []
    const categories = []
    for (let s in a){
      categories.push(a[s].name)
      for(let r in a[s].job_summaries){
        for(let y in a[s].job_summaries[r].summaries){
          if(s === "0") {
            colors.push(this.getStatuscolor(a[s].job_summaries[r].summaries[y].label))
            data.push({
              name: a[s].job_summaries[r].summaries[y].label,
              stack: a[s].job_summaries[r].label,
              data: []
            })
          }
        }
      }
    }
    for(let r of a){
      for(let c of r.job_summaries){
        for(let o of c.summaries){
          let f = findIndex(data, {name: o.label, stack: c.label})
          if(f>=0){
            data[f].data.push(o.total)
          }
        }
      }
    }

    this.setState({
      isLoading: false,
      // jobQueueSummary,
      currentOptions: {
        ...optsStackedColumn,
        series: data,
        xAxis: {...optsStackedColumn.xAxis, categories},
        colors,
      }
    })
  }

  onDayChange = value => {
    this.setState({ isLoading: true, limitReceived: value }, () => {
      this.read()
    })
  }

  componentDidMount() {
    this.read()
  }

  render() {
    const { isLoading, limitReceived, currentOptions } = this.state

    return (
      <Col className="mb-2">
        <Card>
        <Row type='flex' justify='space-between'>
          <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
            <Row type='flex'>
              <Col span={8}>
                <Button disabled className='group-left' style={{ width: '100%', padding: '0 7px' }}>
                  Show:
                </Button>
              </Col>
              <Col span={16}>
                <Select
                  value={limitReceived}
                  className='group-right'
                  style={{ width: '100%', marginLeft: '-1px' }}
                  onChange={this.onDayChange}
                >
                  <Option value='7d'>7 Days</Option>
                  <Option value='30d'>30 Days</Option>
                </Select>
              </Col>
            </Row>
          </Col>
          <Col>
            <Button loading={isLoading} icon='reload' onClick={this.read}>Refresh</Button>
          </Col>
        </Row>
        <Row className='mt-2'>
          <Spin spinning={isLoading} tip='Loading...' size='large'>
            <div style={{ border: '1px solid #e8e8e8', borderRadius: '4px', padding: '1px' }}>
              {currentOptions ? (
                <HighchartsReact
                  highcharts={Highcharts}
                  constructorType='chart'
                  options={currentOptions}
                />
              ) : (
                <div>
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </div>
              )}
            </div>
          </Spin>
        </Row>
        </Card>
      </Col>
    )
  }
}

export default ChartJobQueue
