const stockData = [
  // [
  //   '2017-10-20T02:26:19.536130Z',
  //   169.98
  // ],
  // [
  //   '2017-10-21T02:26:19.536130Z',
  //   173.14
  // ],
  // [
  //   '2017-10-22T02:26:19.536130Z',
  //   174.96
  // ],
  // [
  //   1511533800000,
  //   174.97
  // ],
  // [
  //   1511793000000,
  //   174.09
  // ],
  // [
  //   1511879400000,
  //   173.07
  // ],
  // [
  //   1511965800000,
  //   169.48
  // ],
  // [
  //   1512052200000,
  //   171.85
  // ],
  // [
  //   1512138600000,
  //   171.05
  // ],
  // [
  //   1512397800000,
  //   169.8
  // ],
  // [
  //   1512484200000,
  //   169.64
  // ],
  // [
  //   1512570600000,
  //   169.01
  // ],
  // [
  //   1512657000000,
  //   169.32
  // ],
  // [
  //   1512743400000,
  //   169.37
  // ],
  // [
  //   1513002600000,
  //   172.67
  // ],
  // [
  //   1513089000000,
  //   171.7
  // ],
  // [
  //   1513175400000,
  //   172.27
  // ],
  // [
  //   1513261800000,
  //   172.22
  // ],
  // [
  //   1513348200000,
  //   173.97
  // ],
  // [
  //   1513607400000,
  //   176.42
  // ],
  // [
  //   1513693800000,
  //   174.54
  // ],
  // [
  //   1513780200000,
  //   174.35
  // ],
  // [
  //   1513866600000,
  //   175.01
  // ],
  // [
  //   1513953000000,
  //   175.01
  // ],
  // [
  //   1514298600000,
  //   170.57
  // ],
  // [
  //   1514385000000,
  //   170.6
  // ],
  // [
  //   1514471400000,
  //   171.08
  // ],
  // [
  //   1514557800000,
  //   169.23
  // ],
  // [
  //   1514903400000,
  //   172.26
  // ],
  // [
  //   1514989800000,
  //   172.23
  // ],
  // [
  //   1515076200000,
  //   173.03
  // ],
  // [
  //   1515162600000,
  //   175
  // ],
  // [
  //   1515421800000,
  //   174.35
  // ],
  // [
  //   1515508200000,
  //   174.33
  // ],
  // [
  //   1515594600000,
  //   174.29
  // ],
  // [
  //   1515681000000,
  //   175.28
  // ],
  // [
  //   1515767400000,
  //   177.09
  // ],
  // [
  //   1516113000000,
  //   176.19
  // ],
  // [
  //   1516199400000,
  //   179.1
  // ],
  // [
  //   1516285800000,
  //   179.26
  // ],
  // [
  //   1516372200000,
  //   178.46
  // ],
  // [
  //   1516631400000,
  //   177
  // ],
  // [
  //   1516717800000,
  //   177.04
  // ],
  // [
  //   1516804200000,
  //   174.22
  // ],
  // [
  //   1516890600000,
  //   171.11
  // ],
  // [
  //   1516977000000,
  //   171.51
  // ],
  // [
  //   1517236200000,
  //   167.96
  // ],
  // [
  //   1517322600000,
  //   166.97
  // ],
  // [
  //   1517409000000,
  //   167.43
  // ],
  // [
  //   1517495400000,
  //   167.78
  // ],
  // [
  //   1517581800000,
  //   160.5
  // ],
  // [
  //   1517841000000,
  //   156.49
  // ],
  // [
  //   1517927400000,
  //   163.03
  // ],
  // [
  //   1518013800000,
  //   159.54
  // ],
  // [
  //   1518100200000,
  //   155.15
  // ],
  // [
  //   1518186600000,
  //   156.41
  // ],
  // [
  //   1518445800000,
  //   162.71
  // ],
  // [
  //   1518532200000,
  //   164.34
  // ],
  // [
  //   1518618600000,
  //   167.37
  // ],
  // [
  //   1518705000000,
  //   172.99
  // ],
  // [
  //   1518791400000,
  //   172.43
  // ],
  // [
  //   1519137000000,
  //   171.85
  // ],
  // [
  //   1519223400000,
  //   171.07
  // ],
  // [
  //   1519309800000,
  //   172.5
  // ],
  // [
  //   1519396200000,
  //   175.5
  // ],
  // [
  //   1519655400000,
  //   178.97
  // ],
  // [
  //   1519741800000,
  //   178.39
  // ],
  // [
  //   1519828200000,
  //   178.12
  // ],
  // [
  //   1519914600000,
  //   175
  // ],
  // [
  //   1520001000000,
  //   176.21
  // ],
  // [
  //   1520260200000,
  //   176.82
  // ],
  // [
  //   1520346600000,
  //   176.67
  // ],
  // [
  //   1520433000000,
  //   175.03
  // ],
  // [
  //   1520519400000,
  //   176.94
  // ],
  // [
  //   1520605800000,
  //   179.98
  // ],
  // [
  //   1520861400000,
  //   181.72
  // ],
  // [
  //   1520947800000,
  //   179.97
  // ],
  // [
  //   1521034200000,
  //   178.44
  // ],
  // [
  //   1521120600000,
  //   178.65
  // ],
  // [
  //   1521207000000,
  //   178.02
  // ],
  // [
  //   1521466200000,
  //   175.3
  // ],
  // [
  //   1521552600000,
  //   175.24
  // ],
  // [
  //   1521639000000,
  //   171.27
  // ],
  // [
  //   1521725400000,
  //   168.85
  // ],
  // [
  //   1521811800000,
  //   164.94
  // ],
  // [
  //   1522071000000,
  //   172.77
  // ],
  // [
  //   1522157400000,
  //   168.34
  // ],
  // [
  //   1522243800000,
  //   166.48
  // ],
  // [
  //   1522330200000,
  //   167.78
  // ],
  // [
  //   1522675800000,
  //   166.68
  // ],
  // [
  //   1522762200000,
  //   168.39
  // ],
  // [
  //   1522848600000,
  //   171.61
  // ],
  // [
  //   1522935000000,
  //   172.8
  // ],
  // [
  //   1523021400000,
  //   168.38
  // ],
  // [
  //   1523280600000,
  //   170.05
  // ],
  // [
  //   1523367000000,
  //   173.25
  // ],
  // [
  //   1523453400000,
  //   172.44
  // ],
  // [
  //   1523539800000,
  //   174.14
  // ],
  // [
  //   1523626200000,
  //   174.73
  // ],
  // [
  //   1523885400000,
  //   175.82
  // ],
  // [
  //   1523971800000,
  //   178.24
  // ],
  // [
  //   1524058200000,
  //   177.84
  // ],
  // [
  //   1524144600000,
  //   172.8
  // ],
  // [
  //   1524231000000,
  //   165.72
  // ],
  // [
  //   1524490200000,
  //   165.24
  // ],
  // [
  //   1524576600000,
  //   162.94
  // ],
  // [
  //   1524663000000,
  //   163.65
  // ],
  // [
  //   1524749400000,
  //   164.22
  // ],
  // [
  //   1524835800000,
  //   162.32
  // ],
  // [
  //   1525095000000,
  //   165.26
  // ],
  // [
  //   1525181400000,
  //   169.1
  // ],
  // [
  //   1525267800000,
  //   176.57
  // ],
  // [
  //   1525354200000,
  //   176.89
  // ],
  // [
  //   1525440600000,
  //   183.83
  // ],
  // [
  //   1525699800000,
  //   185.16
  // ],
  // [
  //   1525786200000,
  //   186.05
  // ],
  // [
  //   1525872600000,
  //   187.36
  // ],
  // [
  //   1525959000000,
  //   190.04
  // ],
  // [
  //   1526045400000,
  //   188.59
  // ],
  // [
  //   1526304600000,
  //   188.15
  // ],
  // [
  //   1526391000000,
  //   186.44
  // ],
  // [
  //   1526477400000,
  //   188.18
  // ],
  // [
  //   1526563800000,
  //   186.99
  // ],
  // [
  //   1526650200000,
  //   186.31
  // ],
  // [
  //   1526909400000,
  //   187.63
  // ],
  // [
  //   1526995800000,
  //   187.16
  // ],
  // [
  //   1527082200000,
  //   188.36
  // ],
  // [
  //   1527168600000,
  //   188.15
  // ],
  // [
  //   1527255000000,
  //   188.58
  // ],
  // [
  //   1527600600000,
  //   187.9
  // ],
  // [
  //   1527687000000,
  //   187.5
  // ],
  // [
  //   1527773400000,
  //   186.87
  // ],
  // [
  //   1527859800000,
  //   190.24
  // ],
  // [
  //   1528119000000,
  //   191.83
  // ],
  // [
  //   1528205400000,
  //   193.31
  // ],
  // [
  //   1528291800000,
  //   193.98
  // ],
  // [
  //   1528378200000,
  //   193.46
  // ],
  // [
  //   1528464600000,
  //   191.7
  // ],
  // [
  //   1528723800000,
  //   191.23
  // ],
  // [
  //   1528810200000,
  //   192.28
  // ],
  // [
  //   1528896600000,
  //   190.7
  // ],
  // [
  //   1528983000000,
  //   190.8
  // ],
  // [
  //   1529069400000,
  //   188.84
  // ],
  // [
  //   1529328600000,
  //   188.74
  // ],
  // [
  //   1529415000000,
  //   185.69
  // ],
  // [
  //   1529501400000,
  //   186.5
  // ],
  // [
  //   1529587800000,
  //   185.46
  // ],
  // [
  //   1529674200000,
  //   184.92
  // ],
  // [
  //   1529933400000,
  //   182.17
  // ],
  // [
  //   1530019800000,
  //   184.43
  // ],
  // [
  //   1530106200000,
  //   184.16
  // ],
  // [
  //   1530192600000,
  //   185.5
  // ],
  // [
  //   1530279000000,
  //   185.11
  // ],
  // [
  //   1530538200000,
  //   187.18
  // ],
  // [
  //   1530624600000,
  //   183.92
  // ],
  // [
  //   1530797400000,
  //   185.4
  // ],
  // [
  //   1530883800000,
  //   187.97
  // ],
  // [
  //   1531143000000,
  //   190.58
  // ],
  // [
  //   1531229400000,
  //   190.35
  // ],
  // [
  //   1531315800000,
  //   187.88
  // ],
  // [
  //   1531402200000,
  //   191.03
  // ],
  // [
  //   1531488600000,
  //   191.33
  // ],
  // [
  //   1531747800000,
  //   190.91
  // ],
  // [
  //   1531834200000,
  //   191.45
  // ],
  // [
  //   1531920600000,
  //   190.4
  // ],
  // [
  //   1532007000000,
  //   191.88
  // ],
  // [
  //   1532093400000,
  //   191.44
  // ],
  // [
  //   1532352600000,
  //   191.61
  // ],
  // [
  //   1532439000000,
  //   193
  // ],
  // [
  //   1532525400000,
  //   194.82
  // ],
  // [
  //   1532611800000,
  //   194.21
  // ],
  // [
  //   1532698200000,
  //   190.98
  // ],
  // [
  //   1532957400000,
  //   189.91
  // ],
  // [
  //   1533043800000,
  //   190.29
  // ],
  // [
  //   1533130200000,
  //   201.5
  // ],
  // [
  //   1533216600000,
  //   207.39
  // ],
  // [
  //   1533303000000,
  //   207.99
  // ],
  // [
  //   1533562200000,
  //   209.07
  // ],
  // [
  //   1533648600000,
  //   207.11
  // ],
  // [
  //   1533735000000,
  //   207.25
  // ],
  // [
  //   1533821400000,
  //   208.88
  // ],
  // [
  //   1533907800000,
  //   207.53
  // ],
  // [
  //   1534167000000,
  //   208.87
  // ],
  // [
  //   1534253400000,
  //   209.75
  // ],
  // [
  //   1534339800000,
  //   210.24
  // ],
  // [
  //   1534426200000,
  //   213.32
  // ],
  // [
  //   1534512600000,
  //   217.58
  // ],
  // [
  //   1534771800000,
  //   215.46
  // ],
  // [
  //   1534858200000,
  //   215.04
  // ],
  // [
  //   1534944600000,
  //   215.05
  // ],
  // [
  //   1535031000000,
  //   215.49
  // ],
  // [
  //   1535117400000,
  //   216.16
  // ],
  // [
  //   1535376600000,
  //   217.94
  // ],
  // [
  //   1535463000000,
  //   219.7
  // ],
  // [
  //   1535549400000,
  //   222.98
  // ],
  // [
  //   1535635800000,
  //   225.03
  // ],
  // [
  //   1535722200000,
  //   227.63
  // ],
  // [
  //   1536067800000,
  //   228.36
  // ],
  // [
  //   1536154200000,
  //   226.87
  // ],
  // [
  //   1536240600000,
  //   223.1
  // ],
  // [
  //   1536327000000,
  //   221.3
  // ],
  // [
  //   1536586200000,
  //   218.33
  // ],
  // [
  //   1536672600000,
  //   223.85
  // ],
  // [
  //   1536759000000,
  //   221.07
  // ],
  // [
  //   1536845400000,
  //   226.41
  // ],
  // [
  //   1536931800000,
  //   223.84
  // ],
  // [
  //   1537191000000,
  //   217.88
  // ],
  // [
  //   1537277400000,
  //   218.24
  // ],
  // [
  //   1537363800000,
  //   218.37
  // ],
  // [
  //   1537450200000,
  //   220.03
  // ],
  // [
  //   1537536600000,
  //   217.66
  // ],
  // [
  //   1537795800000,
  //   220.79
  // ],
  // [
  //   1537882200000,
  //   222.19
  // ],
  // [
  //   1537968600000,
  //   220.42
  // ],
  // [
  //   1538055000000,
  //   224.95
  // ],
  // [
  //   1538141400000,
  //   225.74
  // ],
  // [
  //   1538400600000,
  //   227.26
  // ],
  // [
  //   1538487000000,
  //   229.28
  // ],
  // [
  //   1538573400000,
  //   232.07
  // ],
  // [
  //   1538659800000,
  //   227.99
  // ],
  // [
  //   1538746200000,
  //   224.29
  // ],
  // [
  //   1539005400000,
  //   223.77
  // ],
  // [
  //   1539091800000,
  //   226.87
  // ],
  // [
  //   1539178200000,
  //   216.36
  // ],
  // [
  //   1539264600000,
  //   214.45
  // ],
  // [
  //   1539351000000,
  //   222.11
  // ],
  // [
  //   1539610200000,
  //   217.36
  // ],
  // [
  //   1539696600000,
  //   222.15
  // ],
  // [
  //   1539783000000,
  //   221.19
  // ],
  // [
  //   1539869400000,
  //   216.02
  // ],
  // [
  //   1539955800000,
  //   219.31
  // ],
  // [
  //   1540215000000,
  //   220.65
  // ],
  // [
  //   1540301400000,
  //   222.73
  // ],
  // [
  //   1540387800000,
  //   215.09
  // ],
  // [
  //   1540474200000,
  //   219.8
  // ],
  // [
  //   1540560600000,
  //   216.3
  // ],
  // [
  //   1540819800000,
  //   212.24
  // ],
  // [
  //   1540906200000,
  //   213.3
  // ],
  // [
  //   1540992600000,
  //   218.86
  // ],
  // [
  //   1541079000000,
  //   222.22
  // ],
  // [
  //   1541165400000,
  //   207.48
  // ],
  // [
  //   1541428200000,
  //   201.59
  // ],
  // [
  //   1541514600000,
  //   203.77
  // ],
  // [
  //   1541601000000,
  //   209.95
  // ],
  // [
  //   1541687400000,
  //   208.49
  // ],
  // [
  //   1541773800000,
  //   204.47
  // ],
  // [
  //   1542033000000,
  //   194.17
  // ],
  // [
  //   1542119400000,
  //   192.23
  // ],
  // [
  //   1542205800000,
  //   186.8
  // ],
  // [
  //   1542292200000,
  //   191.41
  // ],
  // [
  //   1542378600000,
  //   193.53
  // ],
  // [
  //   1542637800000,
  //   185.86
  // ],
  // [
  //   1542724200000,
  //   176.98
  // ],
  // [
  //   1542810600000,
  //   176.78
  // ],
  // [
  //   1542983400000,
  //   172.29
  // ],
  // [
  //   1543242600000,
  //   174.62
  // ],
  // [
  //   1543329000000,
  //   174.24
  // ],
  // [
  //   1543415400000,
  //   180.94
  // ],
  // [
  //   1543501800000,
  //   179.55
  // ],
  // [
  //   1543588200000,
  //   178.58
  // ],
  // [
  //   1543847400000,
  //   184.82
  // ],
  // [
  //   1543933800000,
  //   176.69
  // ],
  // [
  //   1544106600000,
  //   174.72
  // ],
  // [
  //   1544193000000,
  //   168.49
  // ],
  // [
  //   1544452200000,
  //   169.6
  // ],
  // [
  //   1544538600000,
  //   168.63
  // ],
  // [
  //   1544625000000,
  //   169.1
  // ],
  // [
  //   1544711400000,
  //   170.95
  // ],
  // [
  //   1544797800000,
  //   165.48
  // ],
  // [
  //   1545057000000,
  //   163.94
  // ],
  // [
  //   1545143400000,
  //   166.07
  // ],
  // [
  //   1545229800000,
  //   160.89
  // ],
  // [
  //   1545316200000,
  //   156.83
  // ],
  // [
  //   1545402600000,
  //   150.73
  // ],
  // [
  //   1545661800000,
  //   146.83
  // ],
  // [
  //   1545834600000,
  //   157.17
  // ],
  // [
  //   1545921000000,
  //   156.15
  // ],
  // [
  //   1546007400000,
  //   156.23
  // ],
  // [
  //   1546266600000,
  //   157.74
  // ],
  // [
  //   1546439400000,
  //   157.92
  // ],
  // [
  //   1546525800000,
  //   142.19
  // ],
  // [
  //   1546612200000,
  //   148.26
  // ],
  // [
  //   1546871400000,
  //   147.93
  // ],
  // [
  //   1546957800000,
  //   150.75
  // ],
  // [
  //   1547044200000,
  //   153.31
  // ],
  // [
  //   1547130600000,
  //   153.8
  // ],
  // [
  //   1547217000000,
  //   152.29
  // ],
  // [
  //   1547476200000,
  //   150
  // ],
  // [
  //   1547562600000,
  //   153.07
  // ],
  // [
  //   1547649000000,
  //   154.94
  // ],
  // [
  //   1547735400000,
  //   155.86
  // ],
  // [
  //   1547821800000,
  //   156.82
  // ],
  // [
  //   1548167400000,
  //   153.3
  // ],
  // [
  //   1548253800000,
  //   153.92
  // ],
  // [
  //   1548340200000,
  //   152.7
  // ],
  // [
  //   1548426600000,
  //   157.76
  // ],
  // [
  //   1548685800000,
  //   156.3
  // ],
  // [
  //   1548772200000,
  //   154.68
  // ],
  // [
  //   1548858600000,
  //   165.25
  // ],
  // [
  //   1548945000000,
  //   166.44
  // ],
  // [
  //   1549031400000,
  //   166.52
  // ],
  // [
  //   1549290600000,
  //   171.25
  // ],
  // [
  //   1549377000000,
  //   174.18
  // ],
  // [
  //   1549463400000,
  //   174.24
  // ],
  // [
  //   1549549800000,
  //   170.94
  // ],
  // [
  //   1549636200000,
  //   170.41
  // ],
  // [
  //   1549895400000,
  //   169.43
  // ],
  // [
  //   1549981800000,
  //   170.89
  // ],
  // [
  //   1550068200000,
  //   170.18
  // ],
  // [
  //   1550154600000,
  //   170.8
  // ],
  // [
  //   1550241000000,
  //   170.42
  // ],
  // [
  //   1550586600000,
  //   170.93
  // ],
  // [
  //   1550673000000,
  //   172.03
  // ],
  // [
  //   1550759400000,
  //   171.06
  // ],
  // [
  //   1550845800000,
  //   172.97
  // ],
  // [
  //   1551105000000,
  //   174.23
  // ],
  // [
  //   1551191400000,
  //   174.33
  // ],
  // [
  //   1551277800000,
  //   174.87
  // ],
  // [
  //   1551364200000,
  //   173.15
  // ],
  // [
  //   1551450600000,
  //   174.97
  // ],
  // [
  //   1551709800000,
  //   175.85
  // ],
  // [
  //   1551796200000,
  //   175.53
  // ],
  // [
  //   1551882600000,
  //   174.52
  // ],
  // [
  //   1551969000000,
  //   172.5
  // ],
  // [
  //   1552055400000,
  //   172.91
  // ],
  // [
  //   1552311000000,
  //   178.9
  // ],
  // [
  //   1552397400000,
  //   180.91
  // ],
  // [
  //   1552483800000,
  //   181.71
  // ],
  // [
  //   1552570200000,
  //   183.73
  // ],
  // [
  //   1552656600000,
  //   186.12
  // ],
  // [
  //   1552915800000,
  //   188.02
  // ],
  // [
  //   1553002200000,
  //   186.53
  // ],
  // [
  //   1553088600000,
  //   188.16
  // ],
  // [
  //   1553175000000,
  //   195.09
  // ],
  // [
  //   1553261400000,
  //   191.05
  // ],
  // [
  //   1553520600000,
  //   188.74
  // ],
  // [
  //   1553607000000,
  //   186.79
  // ],
  // [
  //   1553693400000,
  //   188.47
  // ],
  // [
  //   1553779800000,
  //   188.72
  // ],
  // [
  //   1553866200000,
  //   189.95
  // ],
  // [
  //   1554125400000,
  //   191.24
  // ],
  // [
  //   1554211800000,
  //   194.02
  // ],
  // [
  //   1554298200000,
  //   195.35
  // ],
  // [
  //   1554384600000,
  //   195.69
  // ],
  // [
  //   1554471000000,
  //   197
  // ],
  // [
  //   1554730200000,
  //   200.1
  // ],
  // [
  //   1554816600000,
  //   199.5
  // ],
  // [
  //   1554903000000,
  //   200.62
  // ],
  // [
  //   1554989400000,
  //   198.95
  // ],
  // [
  //   1555075800000,
  //   198.87
  // ],
  // [
  //   1555335000000,
  //   199.23
  // ],
  // [
  //   1555421400000,
  //   199.25
  // ],
  // [
  //   1555507800000,
  //   203.13
  // ],
  // [
  //   1555594200000,
  //   203.86
  // ],
  // [
  //   1555939800000,
  //   204.53
  // ],
  // [
  //   1556026200000,
  //   207.48
  // ],
  // [
  //   1556112600000,
  //   207.16
  // ],
  // [
  //   1556199000000,
  //   205.28
  // ],
  // [
  //   1556285400000,
  //   204.3
  // ],
  // [
  //   1556544600000,
  //   204.61
  // ],
  // [
  //   1556631000000,
  //   200.67
  // ],
  // [
  //   1556717400000,
  //   210.52
  // ],
  // [
  //   1556803800000,
  //   209.15
  // ],
  // [
  //   1556890200000,
  //   211.75
  // ],
  // [
  //   1557149400000,
  //   208.48
  // ],
  // [
  //   1557235800000,
  //   202.86
  // ],
  // [
  //   1557322200000,
  //   202.9
  // ],
  // [
  //   1557408600000,
  //   200.72
  // ],
  // [
  //   1557495000000,
  //   197.18
  // ],
  // [
  //   1557754200000,
  //   185.72
  // ],
  // [
  //   1557840600000,
  //   188.66
  // ],
  // [
  //   1557927000000,
  //   190.92
  // ],
  // [
  //   1558013400000,
  //   190.08
  // ],
  // [
  //   1558099800000,
  //   189
  // ],
  // [
  //   1558359000000,
  //   183.09
  // ],
  // [
  //   1558445400000,
  //   186.6
  // ],
  // [
  //   1558531800000,
  //   182.78
  // ],
  // [
  //   1558618200000,
  //   179.66
  // ],
  // [
  //   1558704600000,
  //   178.97
  // ],
  // [
  //   1559050200000,
  //   178.23
  // ],
  // [
  //   1559136600000,
  //   177.38
  // ],
  // [
  //   1559223000000,
  //   178.3
  // ],
  // [
  //   1559309400000,
  //   175.07
  // ],
  // [
  //   1559568600000,
  //   173.3
  // ],
  // [
  //   1559655000000,
  //   179.64
  // ],
  // [
  //   1559741400000,
  //   182.54
  // ],
  // [
  //   1559827800000,
  //   185.22
  // ],
  // [
  //   1559914200000,
  //   190.15
  // ],
  // [
  //   1560173400000,
  //   192.58
  // ],
  // [
  //   1560259800000,
  //   194.81
  // ],
  // [
  //   1560346200000,
  //   194.19
  // ],
  // [
  //   1560432600000,
  //   194.15
  // ],
  // [
  //   1560519000000,
  //   192.74
  // ],
  // [
  //   1560778200000,
  //   193.89
  // ],
  // [
  //   1560864600000,
  //   198.45
  // ],
  // [
  //   1560951000000,
  //   197.87
  // ],
  // [
  //   1561037400000,
  //   199.46
  // ],
  // [
  //   1561123800000,
  //   198.78
  // ],
  // [
  //   1561383000000,
  //   198.58
  // ],
  // [
  //   1561469400000,
  //   195.57
  // ],
  // [
  //   1561555800000,
  //   199.8
  // ],
  // [
  //   1561642200000,
  //   199.74
  // ],
  // [
  //   1561728600000,
  //   197.92
  // ],
  // [
  //   1561987800000,
  //   201.55
  // ],
  // [
  //   1562074200000,
  //   202.73
  // ],
  // [
  //   1562160600000,
  //   204.41
  // ],
  // [
  //   1562333400000,
  //   204.23
  // ],
  // [
  //   1562592600000,
  //   200.02
  // ],
  // [
  //   1562679000000,
  //   201.24
  // ],
  // [
  //   1562765400000,
  //   203.23
  // ],
  // [
  //   1562851800000,
  //   201.75
  // ],
  // [
  //   1562938200000,
  //   203.3
  // ],
  // [
  //   1563197400000,
  //   205.21
  // ],
  // [
  //   1563283800000,
  //   204.5
  // ],
  // [
  //   1563370200000,
  //   203.35
  // ],
  // [
  //   1563456600000,
  //   205.66
  // ],
  // [
  //   1563543000000,
  //   202.59
  // ],
  // [
  //   1563802200000,
  //   207.22
  // ],
  // [
  //   1563888600000,
  //   208.84
  // ],
  // [
  //   1563975000000,
  //   208.67
  // ],
  // [
  //   1564061400000,
  //   207.02
  // ],
  // [
  //   1564147800000,
  //   207.74
  // ],
  // [
  //   1564407000000,
  //   209.68
  // ],
  // [
  //   1564493400000,
  //   208.78
  // ],
  // [
  //   1564579800000,
  //   213.04
  // ],
  // [
  //   1564666200000,
  //   208.43
  // ],
  // [
  //   1564752600000,
  //   204.02
  // ],
  // [
  //   1565011800000,
  //   193.34
  // ],
  // [
  //   1565098200000,
  //   197
  // ],
  // [
  //   1565184600000,
  //   199.04
  // ],
  // [
  //   1565271000000,
  //   203.43
  // ],
  // [
  //   1565357400000,
  //   200.99
  // ],
  // [
  //   1565616600000,
  //   200.48
  // ],
  // [
  //   1565703000000,
  //   208.97
  // ],
  // [
  //   1565789400000,
  //   202.75
  // ],
  // [
  //   1565875800000,
  //   201.74
  // ],
  // [
  //   1565962200000,
  //   206.5
  // ],
  // [
  //   1566221400000,
  //   210.35
  // ],
  // [
  //   1566307800000,
  //   210.36
  // ],
  // [
  //   1566394200000,
  //   212.64
  // ],
  // [
  //   1566480600000,
  //   212.46
  // ],
  // [
  //   1566567000000,
  //   202.64
  // ],
  // [
  //   1566826200000,
  //   206.49
  // ],
  // [
  //   1566912600000,
  //   204.16
  // ],
  // [
  //   1566999000000,
  //   205.53
  // ],
  // [
  //   1567085400000,
  //   209.01
  // ],
  // [
  //   1567171800000,
  //   208.74
  // ],
  // [
  //   1567517400000,
  //   205.7
  // ],
  // [
  //   1567603800000,
  //   209.19
  // ],
  // [
  //   1567690200000,
  //   213.28
  // ],
  // [
  //   1567776600000,
  //   213.26
  // ],
  // [
  //   1568035800000,
  //   214.17
  // ],
  // [
  //   1568122200000,
  //   216.7
  // ],
  // [
  //   1568208600000,
  //   223.59
  // ],
  // [
  //   1568295000000,
  //   223.09
  // ],
  // [
  //   1568381400000,
  //   218.75
  // ],
  // [
  //   1568640600000,
  //   219.9
  // ],
  // [
  //   1568727000000,
  //   220.7
  // ],
  // [
  //   1568813400000,
  //   222.77
  // ],
  // [
  //   1568899800000,
  //   220.96
  // ],
  // [
  //   1568986200000,
  //   217.73
  // ],
  // [
  //   1569245400000,
  //   218.72
  // ],
  // [
  //   1569331800000,
  //   217.68
  // ],
  // [
  //   1569418200000,
  //   221.03
  // ],
  // [
  //   1569504600000,
  //   219.89
  // ],
  // [
  //   1569591000000,
  //   218.82
  // ],
  // [
  //   1569850200000,
  //   223.97
  // ],
  // [
  //   1569936600000,
  //   224.59
  // ],
  // [
  //   1570023000000,
  //   218.96
  // ],
  // [
  //   1570109400000,
  //   220.82
  // ],
  // [
  //   1570195800000,
  //   227.01
  // ],
  // [
  //   1570455000000,
  //   227.06
  // ],
  // [
  //   1570541400000,
  //   224.4
  // ],
  // [
  //   1570627800000,
  //   227.03
  // ],
  // [
  //   1570714200000,
  //   230.09
  // ],
  // [
  //   1570800600000,
  //   236.21
  // ],
  // [
  //   1571059800000,
  //   235.87
  // ],
  // [
  //   1571146200000,
  //   235.32
  // ],
  // [
  //   1571232600000,
  //   234.37
  // ],
  // [
  //   1571319000000,
  //   235.28
  // ],
  // [
  //   1571405400000,
  //   236.41
  // ],
  // [
  //   1571664600000,
  //   240.51
  // ],
  // [
  //   1571751000000,
  //   239.96
  // ],
  // [
  //   1571837400000,
  //   243.18
  // ],
  [
    1571923800000,
    243.58
  ],
  [
    1572010200000,
    246.58
  ],
  [
    1572269400000,
    249.05
  ],
  [
    1572355800000,
    243.29
  ],
  [
    1572442200000,
    243.26
  ],
  [
    1572528600000,
    248.76
  ],
  [
    1572615000000,
    255.82
  ],
  [
    1572877800000,
    257.5
  ],
  [
    1572964200000,
    257.13
  ],
  [
    1573050600000,
    257.24
  ],
  [
    1573137000000,
    259.43
  ],
  [
    1573223400000,
    260.14
  ],
  [
    1573482600000,
    262.2
  ],
  [
    1573569000000,
    261.96
  ],
  [
    1573655400000,
    264.47
  ],
  [
    1573741800000,
    262.64
  ],
  [
    1573828200000,
    265.76
  ]
]

export { stockData }