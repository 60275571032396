import React from 'react'
import { BaseDetail } from 'App/Component/Bases'
import fields from './fields'

class FormAdd extends BaseDetail {
  title = 'Merchant';
  urlData = 'module-merchant-merchant';
  fields = fields;
  state = {
    isCheckingPermission: false,
    isAccessAllowed: true,
  }
}

export default FormAdd