import React from "react";
import PropTypes from "prop-types";
import { Row, Col, message } from "antd";
import { get } from "lodash";
import styled from "styled-components";

import Sites from "App/Config/Sites";
import SubAllList from "App/Component/SubAllList";
import descriptor from "App/Screens/SubModules/JobMg/descriptorOffline";

const windowFeatures = `width=${window.innerWidth *
  0.9},height=${window.innerHeight * 0.9}`;
const domainQs = Sites.url.qs;

class Jobs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  getQueryParams() {
    let params = { job_queue: this.props.match.params.id };
    return params;
  }

  handleTitleClick(e, item) {
    e.stopPropagation();

    const staticUrl = `/job-management/jobs/${item.pk}`;
    this.props.history.push(staticUrl);
  }

  getGroupByOptions() {
    return [
      {
        label: "Status",
        key: "status",
        dataIndex: "status",
        startcase: "true"
        // default: true,
      }
    ];
  }

  onAddClick = () => {
    const urlTarget = `${domainQs}/job/add`;
    window.open(urlTarget, urlTarget, windowFeatures);
  };

  onDetailClick = id => {
    if (this.state.hasQsAccess || true) {
      const { userReducer } = this.props;
      const urlTarget =
        domainQs +
        "/job/" +
        id +
        "?ItSHPShcIRDYnjKX=" +
        btoa(`${Sites.oauth.id}:${Sites.oauth.secret}`) +
        "&MwFMqLoCBGBDyZwj=" +
        userReducer.token +
        "&lmMgPPePCXurSXjI=" +
        get(userReducer, "user.username");
      window.open(urlTarget, urlTarget, windowFeatures);
    } else {
      message.info(`Sorry, you don't have access to QS`);
    }
  };

  // onDetailClick(item) {
  //   let pk = 'add'
  //   if (item) {
  //     pk = item
  //   }
  //   const urlTarget = `/job-management/jobs/${pk}`
  //   const from = [''] // just for goBack
  //   this.props.history.push(urlTarget, { from })
  // }

  render() {
    return (
      <SubAllList
        disableAddButton
        permissions={this.props.permissionItems}
        masterKey="jobs"
        pageTitle="Jobs"
        readParams={this.getQueryParams()}
        descriptor={descriptor}
        avatar="name"
        userReducer={this.props.userReducer}
        groupByOptions={this.getGroupByOptions()}
        onAddClick={() => this.onAddClick()}
        onDetailClick={item => this.onDetailClick(item)}
      />
    );
  }
}

Jobs.propTypes = {
  permissionItems: PropTypes.array
};

Jobs.defaultProps = {
  permissionItems: []
};

export default Jobs;

const FitRow = styled(Row)`
  width: fit-content;
`;
