import { BaseList, BaseDetail } from "App/Component/Bases";
import fields from "./fields";
import descriptor from "./descriptor";
import options from './options';

const title = "PM Sort Codes";
const urlData = "pmSortCodes";
const bulkName = "subheading.label";

class List extends BaseList {
  constructor(props) {
    super(props)

    this.title = title;
    this.urlData = urlData;
    this.bulkName = bulkName;
    this.propsList = {
      descriptor,
      exportImport: options.exportImportOptions,
    }
  }
}

class Detail extends BaseDetail {
  constructor(props) {
    super(props)

    this.title = title
    this.urlData = urlData
    this.bulkName = bulkName
    this.fields = fields
    this.propsDetail = {
      urlKeyHistory: 'api:admin:estimation:pmsortcode:detail-revision',
      keyLabel: 'description'
    }
  }
}

export default { List, Detail };