import React from "react";
import Trend from "ant-design-pro/lib/Trend";
import {
	ChartCard,
	Bar,
	TimelineChart,
	MiniArea
} from "ant-design-pro/lib/Charts";
import {
	Radio,
	Col,
	Row,
	Card,
	DatePicker,
	Tooltip,
	Icon,
	Timeline
} from "antd";

import { salesData, chartData } from "App/Data/dummy";

const { RangePicker, MonthPicker } = DatePicker;

class Stats extends React.PureComponent {
	render() {
		return (
			<Row gutter={[8, 8]} className="animated fadeIn">
				<Col lg={18} xl={18}>
					<Row gutter={[8, 8]}>
						<Col lg={12} xl={12}>
							<ChartCard
								title="Job on Time"
								action={
									<Tooltip title="this month">
										<Icon type="info-circle-o" />
									</Tooltip>
								}
								total={"25 jobs"}
								footer={
									<span>
										Changes
										<Trend
											flag="up"
											style={{ marginLeft: 8, color: "rgba(0,0,0,.85)" }}
										>
											12%
										</Trend>
									</span>
								}
							>
								<MiniArea line height={45} data={salesData} />
							</ChartCard>
						</Col>
						<Col lg={12} xl={12}>
							<ChartCard
								title="Average Jobs per Month"
								action={
									<Tooltip title="this month">
										<Icon type="info-circle-o" />
									</Tooltip>
								}
								total={"2.3 jobs / month"}
								footer={
									<span>
										Changes
										<Trend
											flag="down"
											style={{ marginLeft: 8, color: "rgba(0,0,0,.85)" }}
										>
											0.6%
										</Trend>
									</span>
								}
							>
								<MiniArea
									line
									height={45}
									data={salesData}
									borderColor="rgb(151, 95, 228)"
									color="rgb(151, 95, 228)"
								/>
							</ChartCard>
						</Col>
						<Col span={24}>
							<Card
								title="Completed Job"
								// className="m-2"
								extra={
									<>
										<Radio.Group defaultValue="a" className="mr-2">
											<Radio.Button value="a">This Week</Radio.Button>
											<Radio.Button value="b">This Month</Radio.Button>
											<Radio.Button value="c">This Year</Radio.Button>
										</Radio.Group>
										<RangePicker />
									</>
								}
							>
								<Bar height={200} autoLabel data={salesData} />
							</Card>
						</Col>
						<Col span={24}>
							<Card
								title="Login Activity"
								extra={<MonthPicker placeholder="Select month" />}
							>
								<TimelineChart
									height={200}
									data={chartData}
									titleMap={{ x: "Day", y1: "Login", y2: "Logout" }}
								/>
							</Card>
						</Col>
					</Row>
				</Col>
				<Col lg={6} xl={6}>
					<Row>
						<ChartCard title="History">
							<Timeline pending="Recording..." className="mt-2">
								<Timeline.Item>Create job Jembatan Sulpat 2015-09-01</Timeline.Item>
								<Timeline.Item>
									Assign job Jembatan Sulpat to Mahrus Kisanak 2015-09-01
								</Timeline.Item>
								<Timeline.Item>Update worksheet 2015-09-01</Timeline.Item>
							</Timeline>
						</ChartCard>
					</Row>
				</Col>
			</Row>
		);
	}
}

export default Stats;
