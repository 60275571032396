const module = 'admin'
const app = 'estimation'

export default {
  'api:admin:estimation:bluebeammapping:list': `/${module}/${app}/bluebeam-mappings`,
  'api:admin:estimation:bluebeammapping:detail': `/${module}/${app}/bluebeam-mappings/:id`,

  'api:admin:estimation:exportimportsession:list': `/${module}/${app}/export-import-sessions`,
  'api:admin:estimation:exportimportsession:detail': `/${module}/${app}/export-import-sessions/:id`,

  'api:admin:exim:session:list': `/${module}/${app}/export-import-sessions`,
  'api:admin:exim:session:detail': `/${module}/${app}/export-import-sessions/:id`,

  'api:admin:estimation:exportmappingline:list': `/${module}/${app}/export-mapping-lines`,
  'api:admin:estimation:exportmappingline:detail': `/${module}/${app}/export-mapping-lines/:id`,

  'api:admin:estimation:exportmapping:list': `/${module}/${app}/export-mappings`,
  'api:admin:estimation:exportmapping:detail': `/${module}/${app}/export-mappings/:id`,

  'api:admin:estimation:formula:list': `/${module}/${app}/formulas`,
  'api:admin:estimation:formula:detail': `/${module}/${app}/formulas/:id`,

  'api:admin:estimation:linetemplate:list': `/${module}/${app}/line-templates`,
  'api:admin:estimation:linetemplate:detail': `/${module}/${app}/line-templates/:id`,

  'api:admin:estimation:lineusage:list': `/${module}/${app}/line-usages`,
  'api:admin:estimation:lineusage:detail': `/${module}/${app}/line-usages/:id`,

  'api:admin:estimation:overrideline:list': `/${module}/${app}/override-lines`,
  'api:admin:estimation:overrideline:detail': `/${module}/${app}/override-lines/:id`,

  'api:admin:estimation:overridesetgroup:list': `/${module}/${app}/override-set-groups`,
  'api:admin:estimation:overridesetgroup:detail': `/${module}/${app}/override-set-groups/:id`,

  'api:admin:estimation:overrideset:list': `/${module}/${app}/override-sets`,
  'api:admin:estimation:overrideset:detail': `/${module}/${app}/override-sets/:id`,
  
  'api:admin:estimation:pitchfactor:list': `/${module}/${app}/pitch-factors`,
  'api:admin:estimation:pitchfactor:detail': `/${module}/${app}/pitch-factors/:id`,

  'api:admin:estimation:pmhardwarestartingrange:list': `/${module}/${app}/pm-hardware-starting-ranges`,
  'api:admin:estimation:pmhardwarestartingrange:detail': `/${module}/${app}/pm-hardware-starting-ranges/:id`,

  'api:admin:estimation:pmsortcode:list': `/${module}/${app}/pm-sort-codes`,
  'api:admin:estimation:pmsortcode:detail': `/${module}/${app}/pm-sort-codes/:id`,

  'api:admin:estimation:pmsubheadmapping:list': `/${module}/${app}/pm-subhead-mappings`,
  'api:admin:estimation:pmsubheadmapping:detail': `/${module}/${app}/pm-subhead-mappings/:id`,

  'api:admin:estimation:pmtimberstartingrange:list': `/${module}/${app}/pm-timber-starting-ranges`,
  'api:admin:estimation:pmtimberstartingrange:detail': `/${module}/${app}/pm-timber-starting-ranges/:id`,

  'api:admin:estimation:prefillline:list': `/${module}/${app}/prefill-lines`,
  'api:admin:estimation:prefillline:detail': `/${module}/${app}/prefill-lines/:id`,

  'api:admin:estimation:prefillsetgroup:list': `/${module}/${app}/prefill-set-groups`,
  'api:admin:estimation:prefillsetgroup:detail': `/${module}/${app}/prefill-set-groups/:id`,
  
  'api:admin:estimation:prefillset:list': `/${module}/${app}/prefill-sets`,
  'api:admin:estimation:prefillset:detail': `/${module}/${app}/prefill-sets/:id`,

  'api:admin:estimation:productgroup:list': `/${module}/${app}/product-groups`,
  'api:admin:estimation:productgroup:detail': `/${module}/${app}/product-groups/:id`,

  'api:admin:estimation:product:list': `/${module}/${app}/products`,
  'api:admin:estimation:product:detail': `/${module}/${app}/products/:id`,

  'api:admin:estimation:randommapping:list': `/${module}/${app}/random-mappings`,
  'api:admin:estimation:randommapping:detail': `/${module}/${app}/random-mappings/:id`,

  'api:admin:estimation:section:list': `/${module}/${app}/sections`,
  'api:admin:estimation:section:detail': `/${module}/${app}/sections/:id`,

  'api:admin:estimation:subheadmap:list': `/${module}/${app}/subhead-maps`,
  'api:admin:estimation:subheadmap:detail': `/${module}/${app}/subhead-maps/:id`,

  'api:admin:estimation:subheading:list': `/${module}/${app}/subheadings`,
  'api:admin:estimation:subheading:detail': `/${module}/${app}/subheadings/:id`,

  'api:admin:estimation:subsection:list': `/${module}/${app}/subsections`,
  'api:admin:estimation:subsection:detail': `/${module}/${app}/subsections/:id`,

  'api:admin:estimation:templateset:list': `/${module}/${app}/template-sets`,
  'api:admin:estimation:templateset:detail': `/${module}/${app}/template-sets/:id`,

  'api:admin:estimation:unit:list': `/${module}/${app}/units`,
  'api:admin:estimation:unit:detail': `/${module}/${app}/units/:id`,
}