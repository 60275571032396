import React from 'react'
import PropTypes from 'prop-types'
import { Row } from 'antd'
import { get } from 'lodash'
import CustomPermissionsTab from 'App/Screens/SubModules/UserGroup/User/Management/Component/CustomPermissionsTab'
import WarningLabel from '../WarningLabel'
import ErrorLabel from '../ErrorLabel'

class PagePermissions extends React.PureComponent {
  constructor(props) {
    super(props)

    this.emptyArray = []
    this.state = {
      errorMessage: '',
    }
  }

  // Jangan di hapus dong
  setThisState = (obj, callback = () => null, force) => {
    if (force) {
      return this.setState(obj, callback)
    } else {
      for (const key in obj) {
        if (obj[key] !== this.state[key]) {
          return this.setState(obj, callback)
        }
      }
    }
    return callback()
  }

  onFieldTouched = (isFieldsTouched) => {
    this.props.onFieldTouched(isFieldsTouched, () => {
      this.setState({ errorMessage: '' })
    })
  }

  getPermissionsBySets = (sets, selectedPermissions) => {
    const permissions = []
    for (let k = 0; k < sets.length; k += 1) {
      const matchPerms = sets[k].permissions.filter(perm => selectedPermissions.some(selected => `${selected}` === `${perm.permission_id}`))
      if (matchPerms.length > 0) {
        permissions.push(...matchPerms.map(perm => perm.permission_id))
      }
    }
    return permissions
  }

  filterPermissionsByModule = (permissions) => {
    const { displayPermissions } = this.props
    const filteredPermissions = []
    for (let i = 0; i < displayPermissions.length; i += 1) {
      const groups = displayPermissions[i].groups
      for (let j = 0; j < groups.length; j += 1) {
        filteredPermissions.push(...this.getPermissionsBySets(groups[j].sets, permissions))
      }
      filteredPermissions.push(...this.getPermissionsBySets(displayPermissions[i].sets, permissions))
    }
    return filteredPermissions
  }
  
  validateFields = () => {
    if (get(this._permission, 'validateFields')) {
      // const selectedPermissions = this._permission.validateFields()
      return this.filterPermissionsByModule(this._permission.validateFields())
    }
    return undefined
  }

  // validateFields = () => {
  //   if (get(this._permission, 'validateFields')) {
  //     return this._permission.validateFields()
  //   }
  //   return undefined
  // }

  setError = (errorMessage) => {
    this.props.setActiveKey(this.props.tabKey, () => {
      this.setState({ errorMessage })
    })
  }

  render() {
    const { readOnly, group, displayPermissions } = this.props
    const { errorMessage } = this.state

    return (
      <div>
        { errorMessage && <ErrorLabel errorMessage={errorMessage} /> }
        <WarningLabel tabName='Permissions' groupName={get(group, 'name', '')} />
        <div style={{ padding: '12px 24px' }}>
          <Row style={{ borderBottom: '1px solid #e8e8e8' }}>
            <div style={{ fontSize: '14px', fontWeight: 400, paddingBottom: '12px' }}>
              Manage allowed access/permission of the user in every modules. Permission read is the main permission to be able to access the menu.
              <div>
                <b>Attention:</b> For now, permission <b>Product</b> in Module Admin and permission <b>Product</b> in Module Product is the same. Also applies to <b>Branch</b> and <b>Job Queue</b>.
              </div>
              <div>Choose modules in tab "<b>Modules</b>" (between the Member tab and Permissions) to show the Allow Permissions</div>
            </div>
          </Row>
          <CustomPermissionsTab
            // Dilarang keras mengirim yang merusak cara kerja PureComponent
            ref={ref => {this._permission = ref}}
            readOnly={readOnly}
            dataSource={displayPermissions}
            initialValue={get(group, 'permissions', this.emptyArray)}
            setTouched={this.onFieldTouched}
          />
        </div>
      </div>
    )
  }
}

PagePermissions.propTypes = {
  readOnly: PropTypes.bool,
  tabKey: PropTypes.string,
  displayPermissions: PropTypes.array,
  group: PropTypes.object,
  onFieldTouched: PropTypes.func,
  setActiveKey: PropTypes.func,
}

PagePermissions.defaultProps = {
  readOnly: false,
  tabKey: null,
  displayPermissions: [],
  group: null,
  onFieldTouched: () => null,
  setActiveKey: (tabKey, callback = () => null) => callback(),
}

export default PagePermissions