import { BaseList } from "App/Screens/SubModules/Component";
import descriptor from "./descriptorOffline";

class MerchantGroupList extends BaseList {
  constructor(props) {
    super(props)

    this.appName = 'jobs'
    this.modelName = 'merchantgroup'
    this.propsList = {
      avatar: 'name',
      pageTitle: 'Merchant Group',
      descriptor,
      masterKey: 'module-merchant-group',
      groupByOptions: [
        {
          label: 'Mapping Mode',
          key: 'mapping_mode.name',
          dataIndex: 'mapping_mode.name',
        },
        {
          label: 'Show Seed',
          key: 'show_seed',
          dataIndex: 'show_seed',
        },
      ]
    }
  }
}

export default MerchantGroupList