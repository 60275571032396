export default [
  {
    title: 'Merchant',
    type: 'SelectQuery',
    data: 'merchants',
    required: true,
    initialValue: '',
    note_text: '',
    additional: 'edit|add',
    selectedKey: 'pk',
    selectedValue: 'name',
    dataIndex: 'merchant',
    order: 0,
  }, {
    title: 'Subheading',
    required: true,
    type: 'SelectQuery',
    data: 'subheadings',
    initialValue: '',
    note_text: '',
    additional: 'edit|add',
    selectedKey: 'pk',
    selectedValue: 'label',
    dataIndex: 'subheading',
    order: 1,
  }, {
    title: 'Start range',
    type: 'text',
    required: true,
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'start_range',
    order: 2,
  }, {
    title: 'End range',
    type: 'text',
    required: true,
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'end_range',
    order: 3,
  },
]