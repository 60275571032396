import BaseButton from './Base'

// Button to download app for linux
class ButtonLinux extends BaseButton {
  constructor(props) {
    super(props)

    this.ymlName = 'latest-linux.yml'
  }

  getButtonProps = () => ({
    icon: 'qq',
  })

  renderText() {
    return (
      `Download for Linux ${this.__version}`
    )
  }
}

export default ButtonLinux