export default {
  "app_label": "estimation",
  "model_name": "subheading",
  "pagination": true,
  "columns": [
    {
      "name": "pk",
      "label": "ID",
      "help_text": null,
      "hidden": true,
      "identifier": true,
      "data_type": "int",
      "search": false,
      "sort": false
    },
    {
      "name": "label",
      "label": "Label",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "ellipsis": true,
      "search": true,
      "sort": true
    },
    {
      "name": "status",
      "label": "Status",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "search": true,
      "sort": true
    },
    {
      "name": "code",
      "label": "Code",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "search": true,
      "sort": true
    },
    {
      "name": "bm_subhead",
      "label": "Bm subhead",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "ellipsis": true,
      "search": true,
      "sort": true
    },
    {
      "name": "pm_subhead",
      "label": "Pm subhead",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "ellipsis": true,
      "search": true,
      "sort": true
    },
    {
      "name": "prostix",
      "label": "Prostix",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "ellipsis": true,
      "search": true,
      "sort": true
    },
    {
      "name": "old_id",
      "label": "Old id",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "int",
      "search": true,
      "sort": true
    },
    {
      "name": "mfg_subhead",
      "label": "Mfg subhead",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "int",
      "search": true,
      "sort": true
    },
    {
      "name": "split_export",
      "label": "Split export",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "int",
      "search": true,
      "sort": true
    },
    {
      "name": "special",
      "label": "Special",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "int",
      "search": true,
      "sort": true
    },
    {
      "name": "umbraco",
      "label": "Umbraco",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "ellipsis": true,
      "search": true,
      "sort": true
    },
    {
      "name": "timber",
      "label": "Timber",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "bool",
      "search": false,
      "sort": true
    },
    {
      "name": "modified",
      "label": "Modified",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "datetime",
      "search": false,
      "sort": true
    }
  ],
  "filter_by": [
    {
      "label": "Status",
      "name": "status",
      "type": "choice",
      "choices": [
        [
          "active",
          "Active - Anyone can use"
        ],
        [
          "inactive",
          "Inactive - No one can use"
        ],
        [
          "unpublished",
          "Unpublished - Only certain people can use"
        ]
      ]
    },
    {
      "label": "Timber",
      "name": "timber",
      "type": "switch"
    },
    {
      "label": "Manufactured",
      "name": "manufactured",
      "type": "switch"
    },
    {
      "label": "Template set",
      "name": "template_set",
      "type": "api_reference",
      "api": {
        "url_name": "api:admin:estimation:templateset:autocomplete",
        "key": "pk",
        "display": "name",
        "pagination": true
      }
    }
  ],
  "export_import": null
}