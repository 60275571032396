import React from 'react'
import { BaseDetail } from 'App/Component/Bases'
import fields from './fields'

class JobQueueAdd extends BaseDetail {
  title = 'Job Queue';
  urlData = 'module-jobQueue';
  bulkName = 'name';
  fields = fields;
}

export default JobQueueAdd