import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import CustomTransfer from 'App/Screens/SubModules/UserGroup/User/Management/Component/CustomTransfer'
import WarningLabel from '../WarningLabel'
import ErrorLabel from '../ErrorLabel'

const TRANSFER_PROPS = { listStyle: {height: 'calc(100vh - 320px)'} }

class PageReports extends React.PureComponent {
  constructor(props) {
    super(props)

    this.reportTitles = ['Available Allowed Reports', 'Chosen Allowed Reports']
    this.state = {
      errorMessage: '',
    }
  }

  onFieldTouched = (isFieldsTouched) => {
    this.props.onFieldTouched(isFieldsTouched, () => {
      this.setState({ errorMessage: '' })
    })
  }

  validateFields = () => {
    if (get(this._report, 'validateFields')) {
      return this._report.validateFields()
    }
    return undefined
  }

  setError = (errorMessage) => {
    this.props.setActiveKey(this.props.tabKey, () => {
      this.setState({ errorMessage })
    })
  }

  render() {
    const { readOnly, groupHelper, group } = this.props
    const { errorMessage } = this.state

    return (
      <div>
        { errorMessage && <ErrorLabel errorMessage={errorMessage} /> }
        <WarningLabel tabName='Reports' groupName={get(group, 'name', '')} />
        <div style={{ padding: '12px 24px' }}>
          <CustomTransfer
            ref={ref => {this._report = ref}}
            readOnly={readOnly}
            titles={this.reportTitles}
            dataSource={groupHelper.report}
            initialValue={get(group, 'allowed_reports')}
            setTouched={this.onFieldTouched}
            transferProps={TRANSFER_PROPS}
          />
        </div>
      </div>
    )
  }
}

PageReports.propTypes = {
  readOnly: PropTypes.bool,
  tabKey: PropTypes.string,
  groupHelper: PropTypes.object,
  group: PropTypes.object,
  onFieldTouched: PropTypes.func,
  setActiveKey: PropTypes.func,
}

PageReports.defaultProps = {
  readOnly: false,
  tabKey: null,
  groupHelper: { report: [] },
  group: null,
  onFieldTouched: () => null,
  setActiveKey: (tabKey, callback = () => null) => callback(),
}

export default PageReports