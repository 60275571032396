import React from 'react'
import { Input } from 'antd'
import { BasicInput } from './'

class Number extends BasicInput {
  getInputComp = () => <Input type='number' {...this.getPropsComp()} />

  onInputRef = (ref) => {
    if (ref) {
      ref.input.focus()
      ref.input.select()
    }
  }
}

export default Number