import React, {Component} from 'react'
import styled from 'styled-components'
import {
  Row, Col, Button,
} from 'antd';
import bg500 from '../../../Assets/img/500.svg'

export default class Page500 extends Component {

  gotoDashboard() {
    this.props.history.push('/')
  }

  componentDidMount() {
    document.body.classList.add('body-fixed')
  }

  componentWillUnmount() {
    document.body.classList.remove('body-fixed')
  }


  render() {
    return (
      <StyledRow type={"flex"} justify={"center"}>
        <StyledCol span={12}>
          <StyledImg src={bg500}/>
        </StyledCol>
        <StyledCol span={12}>
          <StyledH1>500</StyledH1>
          <StyledTitle>Internal Server Error</StyledTitle>
          <StyledSubTitle>Sorry about that, but server is temporary down</StyledSubTitle>
          <Button type="primary" size="large" onClick={() => this.gotoDashboard()}>Go to Dashboard</Button>
        </StyledCol>
      </StyledRow>
    )
  }
}

const StyledRow = styled(Row)`
  background: #ebf2f6;
  height: 100vh;
`
const StyledCol = styled(Col)`
margin-top: 90px
  text-align:center; 
`

const StyledImg = styled.img`
  margin-top : 100px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
`
const StyledTitle = styled.h1`
  font-size: 2.8em 
`
const StyledH1 = styled.h1`
  font-size: 5.8em
`
const StyledSubTitle = styled.p`
  font-size: 22px
`
