import { BaseRedirectMenu } from 'App/Screens/SubModules/Component'
import V3Menu from './Menu'

class V3Redirect extends BaseRedirectMenu {
  constructor(props) {
    super(props)

    this.menu = V3Menu
  }
}

export default V3Redirect