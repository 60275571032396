import React from 'react'
import PropTypes from 'prop-types'
import { Button, Card, Col, Icon, Row, Tabs } from 'antd'
import { get } from 'lodash'
import { Lib, RequestV2 as Request } from 'App/Utils'
import Header from './Header'
import { PageGeneral, PageResult, PageHistory, PageQueues } from './Pages'
import LoadingSpin from 'App/Component/LoadingSpin'
import PageError from 'App/Component/PageError'

const { TabPane } = Tabs

class Detail extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isRetrieve: props.isRetrieve,
      exportImport: null,
      errorResponse: null,
    }
  }

  onGoBack = () => {
    this.props.history.push('/admin/estimation/export-import-sessions')
  }

  preRetrieve = () => {
    if (this.state.isRetrieve) {
      this.retrieve()
    }
  }

  reload = () => {
    const keyLoading = 'isRetrieve'
    this.setState({ [keyLoading]: true }, () => {
      this.retrieve(keyLoading)
    })
  }

  retrieve = (keyLoading = 'isRetrieve', callback = () => null) => {
    // this.setState({ [keyLoading]: true, }, () => {
      Request(
        'get',
        this.props.urlKeyDetail,
        {},
        {},
        [this.props.match.params.id],
        this.retrieveSuccess,
        this.retrieveFailed,
        { callback, keyLoading }
      )
    // })
  }

  retrieveSuccess = (response, extra) => {
    this.setState({ [extra.keyLoading]: false, onSaving: false, exportImport: response.data }, () => {
      extra.callback(true)
    })
  }

  retrieveFailed = (error, extra) => {
    this.setState({ [extra.keyLoading]: false, onSaving: false, exportImport: null, errorResponse: get(error, 'response') }, () => {
      extra.callback()
    })
  }

  onSaveContinue = (body, callback = () => null) => {
    this.onSave(body, (isSuccess, response) => {
      if (isSuccess) {
        const id = response.data.pk
        // karena setelah perubahan "add" ke "edit" sudah di handle request ulang, tidak perlu callback
        if (this.props.isAddMode) return this.props.history.push(`/admin/estimation/export-import-sessions/${id}`)
        else this.setState({ exportImport: response.data })
      }
      callback(isSuccess, response)
    })
  }

  // onSaveClear = () => {
  //   this.onSave(() => {
  //     if (!this.props.isAddMode) this.props.history.push('/admin/estimation/export-import-sessions/add')
  //     else this.setState({ isSaving: false })
  //   })
  // }

  onSave = (body, callback = () => null) => {
    Request(
      this.props.methodSave,
      this.props.urlKeySave,
      {},
      body,
      [this.props.match.params.id],
      this.onSaveSuccess,
      this.onSaveFailed,
      { callback },
    )
  }
  
  onSaveSuccess = (response, extra) => {
    extra.callback(true, response)
  }

  onSaveFailed = (error, extra) => {
    Lib.errorMessageHandler(get(error, 'response'))
    extra.callback(false, get(error, 'response'))
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.isRetrieve !== nextProps.isRetrieve) {
      this.setState({ isRetrieve: nextProps.isRetrieve, exportImport: null }, () => {
        this.preRetrieve()
      })
    }
  }

  componentDidMount() {
    this.preRetrieve()
  }
  
  render() {
    const { isAddMode, showResult, showHistory, showQueues, match } = this.props
    const { isRetrieve, exportImport, errorResponse } = this.state

    return (!isAddMode && !isRetrieve && !exportImport) ? (
      <PageError
        errorResponse={errorResponse}
        onGoBack={this.onGoBack}
        onReload={(key, callback) => this.retrieve(key, callback)}
      />
    ) : (
      <Card
        // title='Export Import Session'
        title={
          <Row type='flex' justify='space-between' align='middle'>
            <Col>
              Export Import Session
            </Col>
            {!isAddMode && (
              <Col>
                <Button icon='reload' onClick={this.reload}>
                  Refresh
                </Button>
              </Col>
            )}
          </Row>
        }
        style={{ marginBottom: '16px', borderRadius: '12px' }}
        bodyStyle={{ padding: '0 0 24px 0' }}
      >
        {isRetrieve ? (
          <LoadingSpin tip='Loading...' height='calc(100vh - 200px)' />
        ) : (
          <Row>
            {!this.props.isAddMode && <Header exportImport={exportImport} />}
            <Tabs className='custom-margin-tab' tabBarGutter={0}>
              <TabPane key='general' tab={<span><Icon type='setting' />General</span>}>
                <div style={{ padding: '0 24px' }}>
                  <PageGeneral
                    ref={ref => {this._general = ref}}
                    exportImport={exportImport}
                    onGoBack={this.onGoBack}
                    onSave={this.onSaveContinue}
                    path={match.url.replace(match.params.id, '')}
                    // onSaveClear={this.onSaveClear}
                    permissions={this.props.permissions}
                    isAddMode={isAddMode}
                  />
                </div>
              </TabPane>
              {showResult && (
                <TabPane key='result' tab={<span><Icon type='container' />Result</span>}>
                  <div style={{ padding: '0 24px' }}>
                    <PageResult exportImport={exportImport} />
                  </div>
                </TabPane>
              )}
              {showHistory && (
                <TabPane key='history' tab={<span><Icon type='history' />History</span>}>
                  <div style={{ padding: '0 24px' }}>
                    <PageHistory exportImportId={exportImport.pk} />
                  </div>
                </TabPane>
              )}
              {showQueues && (
                <TabPane key='queues' tab={<span><Icon type='pic-center' />Queues</span>}>
                  <div style={{ padding: '0 24px' }}>
                    <PageQueues exportImportId={exportImport.pk} />
                  </div>
                </TabPane>
              )}
            </Tabs>
          </Row>
        )}
      </Card>
    )
  }
}

Detail.propsTypes = {
  methodPost: PropTypes.string.isRequired,
}

Detail.defaultProps = {
  showResult: true,
  showHistory: true,
  showQueues: true,
}

export default Detail