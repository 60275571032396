import PageMenu from "App/Component/PageMenu";
import Branches from "./Branches";
import BranchRatings from './BranchRatings';
// import PendingMonitorReports from "./PendingMonitorReports/";
// import Servers from "./Servers/";

const module = "admin";
const app = "multisite";
const routes = [
  {
    name: "Multisite Menu",
    path: `/${module}/${app}`,
    component: PageMenu,
    exact: true
  },
  {
    // title: "Branches",
    routes: [
      {
        name: "Branches",
        component: Branches.List,
        props: { urlName: "api:admin:multisite:branch:list" },
      },
      {
        name: "Branches History",
        component: Branches.Detail,
        path: "/admin/multisite/branches/:id/history/:historyId",
      },
      {
        name: "Branches",
        component: Branches.Detail,
        exact: false,
        props: { urlName: "api:admin:multisite:branch:detail" },
      }
    ]
  },
  {
    // title: "Branch Ratings",
    routes: [
      {
        name: "Branch Ratings",
        component: BranchRatings.List,
        props: { urlName: "api:admin:billing:branchrating:list" },
      },
      {
        name: "Branches History",
        component: BranchRatings.Detail,
        path: "/admin/multisite/branches/:id/branch-ratings/:branchratingsId",
      },
      {
        name: "Branch Ratings",
        component: BranchRatings.Detail,
        exact: false,
        props: { urlName: "api:admin:billing:branchrating:detail" },
      }
    ]
  },
  // {
  //   // title: "Pending Monitor Reports",
  //   routes: [
  //     {
  //       name: "Pending Monitor Reports",
  //       component: PendingMonitorReports.List,
  //       props: { urlName: "api:admin:multisite:pendingmonitorreport:list" },
  //     },
  //     {
  //       name: "Pending Monitor Reports",
  //       component: PendingMonitorReports.Detail,
  //       exact: false,
  //       props: { urlName: "api:admin:multisite:pendingmonitorreport:detail" },
  //     }
  //   ]
  // },
  // {
  //   // title: "Servers",
  //   routes: [
  //     {
  //       name: "Servers",
  //       component: Servers.List,
  //       props: { urlName: "api:admin:multisite:server:list" },
  //     },
  //     {
  //       name: "Servers",
  //       component: Servers.Detail,
  //       exact: false,
  //       props: { urlName: "api:admin:multisite:server:detail" },
  //     }
  //   ]
  // }
];

export default { appName: "Multisites", routes };
