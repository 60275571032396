import descriptor from './descriptorOffline'

export default {
  key: 'tagmerchant',
  title: 'Tag Merchant',
  icon: 'tag',
  manualFilter: { key: 'tag', value: 'pk' },
  contentProps: {
    urlTarget: '/merchant/tag-merchant',
    appName: 'jobs',
    modelName: 'tag',
  },
  propsList: {
    avatar: 'merchant.name',
    masterKey: 'module-merchant-tagMerchant',
    descriptor,
  },
}