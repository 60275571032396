import React, {Component} from 'react';
import {Modal, Row, Col, Dropdown, Menu, Result, Switch, Form, Input, Upload, Button, Spin, Icon, message, Select} from "antd";
import { RequestV2 as Request } from "App/Utils";
import {startCase, get} from 'lodash';
import Lib2 from "./Singlepage/Lib2";
const {TextArea} = Input;
const {Option} = Select;

class Footer extends React.PureComponent {
  constructor(props) {
    super(props)

    this.saveOptions = [
      { key: '1', label: 'Save and Dry Run', body: { dry_run: true } },
      { key: '2', label: 'Save and Run', body: { dry_run: false } },
      { key: '3', label: 'Save' },
    ]
    this.state = {
      selectedSaveOption: this.saveOptions[2],
      isSaving: false,
    }
  }

  saveMenu = () => {
    return (
      <Menu onClick={this.onSaveOptionChange}>
        {this.saveOptions.map(val => (
           <Menu.Item key={val.key}>
             {val.label}
           </Menu.Item>   
        ))}
      </Menu>
    )
  }

  onSaveOptionChange = ({ key }) => {
    const selectedSaveOption = this.saveOptions.find(option => option.key === key)
    this.setState({ selectedSaveOption })
  }

  onSave = () => {
    this.setState({ isSaving: true }, () => {
      this.props.onSave(this.state.selectedSaveOption.body, () => {
        this.setState({ isSaving: false })
      })
    })
  }

  render() {
    const { isSaving, selectedSaveOption } = this.state

    return (
      <div style={{ borderTop: '1px solid #e8e8e8', padding: '10px 16px', backgroundColor: '#fff' }}>
        <Row type='flex' justify='space-between'>
          <Col />
          <Col>
            <Row type='flex' gutter={[12, 0]}>
              <Col>
                <Button onClick={this.props.onClose}>Close</Button>
              </Col>
              <Col>
                <Dropdown.Button type='primary' disabled={isSaving} placement='topRight' onClick={this.onSave} overlay={this.saveMenu}>
                  <Icon type={isSaving ? 'loading' : 'save'} /> {selectedSaveOption.label}
                </Dropdown.Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    )
  }
}

class ModalImport extends Component {
  state = {
    progress: 100,
    onprogress: false,
    datarecord: [],
    fileList: [],
    requireFile: false,
    allow_initial_data: false,
  }

  setLoading=(e)=>{
    this.setState({
      onprogress: e
    })
  }

  readSets=(label)=>{
    let args = []
    this.request = Request(
      "get",
      label + "-read",
      {},
      {},
      args,
      this.readSetsSuccess,
      this.onFailed
    );
  }

  readSetsSuccess=(r)=>{
    this.setState({
      allow_initial_data: true,
      datarecord: r.data.results
    })
    this.setLoading(false)
  }

  componentDidMount() {
    let {export_import} = this.props
    if(get(export_import, 'import.allow_initial_data'))
      this.readSets('templateSets')
  }

  importData=(anotherBody, callback = () => null)=>{
    const { allow_initial_data } = this.state
    const p = this.props
    const {validateFieldsAndScroll, resetFields} = this.props.form;
    this.setLoading(true)
    validateFieldsAndScroll({scroll: {offsetTop: 150}}, (err, values) => {
      if (err) {
        callback()
        this.setLoading(false)
        return null;
      }

      const originValue = new FormData()
      if (allow_initial_data) {
        originValue.append('overwrite_existing', true)
      }
      for (const key in values) {
        const value = key === 'file_source' ? get(values[key], ['file'], undefined) : values[key]
        if (value !== undefined) {
          originValue.append(key, value)
        }
      }
      originValue.append('app_label', get(p.metaList, ['app_label'], ''))
      originValue.append('model', get(p.metaList, ['model_name'], ''))
      for (const key in anotherBody) originValue.append(key, anotherBody[key])

      Request(
        "post",
        "api:admin:exim:session:import",
        {},
        originValue,
        [],
        this.importSuccess,
        this.onFailed,
        confirm => {
          callback()
          if (get(confirm, ['error'])) {
            const error = confirm.error
            if (typeof error === 'object') {
              for (const key in error) {
                const errorMessage = get(error, [key, '0'], 'Please contact your admin')
                this.props.form.setFields({ [key]: { errors: [new Error(errorMessage)] } })
                message.error(errorMessage)
              }
            }
          }
        }
      );
    })
  };

  importSuccess = (response, callback) => {
    const l = get(this.props, 'urlName', '').split(':')
    if (response) {
      if (response.status === 201) {
        message.destroy();
        message.success(`Import Data ${get(l, [3], '')} has been saved`);
        this.setLoading(false)
        // this.props.toggle()
        // this.props.changeStatusImport(true)
        this.props.toggle() // karena component modal akan unmount, tidak perlu setState
        // this.setState({ fileList: []})
        // callback(true);
      }
    }
  };

  handleChangeFile = info => {
    let fileList = [...info.fileList];

    // 1. Limit the number of uploaded files
    // Only to show two recent uploaded files, and old ones will be replaced by the new
    fileList = fileList.slice(-1);

    this.setState({ fileList });
  };

  onFailed = (error, callback = () => null) => {
    const errorData = get(error, ['response', 'data'])
    if (errorData) {
      callback({ error: errorData })
    }
    this.setLoading(false)
    message.error("Response from server :"+ error.response.status)
  };

  render() {
    const {getFieldDecorator} = this.props.form
    const {title, export_import} = this.props
    const {onprogress, datarecord, allow_initial_data, requireFile, fileList} = this.state
    return (
      <Modal
        visible={this.props.visible}
        title={startCase(title)}
        onOk={() => this.importData()}
        confirmDialog={onprogress}
        onCancel={this.props.toggle}
        width={'800px'}
        okText="Import"
        cancelText="Close"
        bodyStyle={{width:'800px'}}
        footer={<Footer onSave={this.importData} onClose={this.props.toggle} />}
      >
        <Spin spinning={onprogress} tip="Loading...">
          <Form>
            { allow_initial_data && requireFile &&
              <Form.Item
                // style={{width: "50%"}}
                extra='If template set is selected, then you need to upload a file.'
                label='Template set'
              >
                {getFieldDecorator('template_set', {
                  rules: [{
                    required: false,
                    message: `Please Input template sets`
                  }],
                  initialValue: '',
                })(
                  <Select placeholder='Template Set'>
                    {datarecord.map(function (item, i) {
                      return <Option key={i} value={item.pk}>{item.name}</Option>
                    })}
                  </Select>
                )}
              </Form.Item>
            }
            <Form.Item
              extra={'Accepts CSV file for export import sessions with header line, e.g. estimation_exportimportsession_20100101134502.csv'}
              label='CSV file to import'
            >
              {getFieldDecorator('file_source', {
                rules: [{
                  required: true,
                  message: `Please Input CSV, XLS or XLSX file to import!`
                }],
                initialValue: null,
              })(
                <Upload
                  fileList={fileList}
                  multiple={true}
                  accept='.csv,.xls,.xlsx'
                  onChange={this.handleChangeFile}
                  beforeUpload={() => false}
                >
                  <Button>
                    <Icon type="upload"/> Click to Upload
                  </Button>
                </Upload>
              )}
            </Form.Item>
            <Form.Item
              extra='(optional) a descriptive message to easily distinguish this import session, e.g. "New export import sessions after 28/Apr update"'
              label='Description'
            >
              {getFieldDecorator('description', {
                rules: [{
                  required: false,
                  message: `Please Input Description`
                }],
                initialValue: '',
              })(
                <TextArea row={4} />
              )}
            </Form.Item>
            { !allow_initial_data &&
              <Form.Item
                extra='If left unchecked, then existing export import sessions with the same ID will be left untouched, otherwise they will be overwritten with the info from the file.'
                label='Overwrite existing data with the same ID?'
              >
                {getFieldDecorator('overwrite_existing', {
                  rules: [{
                    required: false,
                    message: `Please Input Description`
                  }],
                  initialValue: false,
                  valuePropName: 'checked',
                })(
                  <Switch checkedChildren="Yes" unCheckedChildren="No" />
                )}
              </Form.Item>
            }
            { allow_initial_data &&
              <>
                <Form.Item
                  extra='If checked, then you need to upload a file.'
                  label='Initial data (File required)'>
                  {getFieldDecorator('initial_data', {
                    rules: [{
                      required: false,
                      message: `Please Input initial data`
                    }],
                    initialValue: false,
                    valuePropName: 'checked',
                  })(
                    <Switch checkedChildren="Yes" onChange={(e)=>this.setState({requireFile: e})} unCheckedChildren="No" />
                  )}
                </Form.Item>
                <span style={{ color: '#ff4d4f' }}>Example format: {get(export_import, 'import.example_format_string')}</span>
              </>
            }
          </Form>
        </Spin>
      </Modal>
    );
  }
}

const WrappedModalImport = Form.create({name: 'ModalImport'})(ModalImport);
export default WrappedModalImport;
