import qs from "querystring";
import { createBrowserHistory } from "history";
import { message } from "antd";

import { Sites } from "App/Config";
import { Store, Action } from "App/Redux";
import { RequestV2 } from "./";

const history = createBrowserHistory();

function onRefreshSuccess(response, { args }) {
  response.data.username = Store.getState().userReducer.username;
  Store.dispatch(Action.CreateUserSessionProperties(response.data));
  args[2].Authorization = `Bearer ${response.data.access_token}`;
  RequestV2(...args);
}

function onRefreshFailed(error) {
  message.error("Your session has ended!", 1.5);
  Store.dispatch(Action.RemoveUserSessionProperties());
  // history.replace("/login");
  window.location.replace("/login");
}

export default function RefreshToken(args) {
  RequestV2(
    "post",
    "user-auth",
    { "Content-Type": "application/x-www-form-urlencoded" },
    qs.stringify({
      client_id: Sites.oauth.id,
      client_secret: Sites.oauth.secret,
      grant_type: "refresh_token",
      refresh_token: Store.getState().userReducer.refreshToken
    }),
    [],
    onRefreshSuccess,
    onRefreshFailed,
    { args, isRefreshToken: true }
  );
}
