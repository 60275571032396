export default {
  bulkOptions: [
    // {
    //   key: 'delete_bulk',
    //   field: 'delete_bulk',
    //   value: 'true',
    //   label: 'Delete',
    //   type: 'danger',
    //   permissionCode: 'multisite.delete_branch',
    // },
  ],
  exportImportOptions: {
    export: {
      directly: true,
      url_name: 'api:admin:multisite:branch:export',
    },
  },
}