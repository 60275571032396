import React from 'react'
import { Row, Col, Select, Button, Spin, Empty, Card } from 'antd'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highcharts'
// import { options as optsStackedColumn } from './StackedColumn'
import { RequestV2 } from 'App/Utils'
import { findIndex } from 'lodash'
import 'App/Component/Singlepage/SingleStyle.css'

const { Option } = Select
const statusColor = {
  New: '#74b9ff',
  Preqs: '#fed330',
  Assigned: '#ff8946',
  Started: '#1890ff',
  QA1: '#ffae56',
  QA2: '#8a4bff',
  Reopened: '#3867d6',
  Pending: '#e1b12c',
  Finished: '#36cc86',
  Cancelled: '#e86d37',
  Closed: '#ff3a29',
}

const optsStackedColumn = {
  chart: {
    type: 'column',
    height: '450px',
  },
  title: {
    text: '.',
  },
  xAxis: {}, // don't delete this line
  yAxis: {
    min: 0,
    title: {
      text: 'Total Jobs',
    },
    stackLabels: {
      enabled: true,
      verticalAlign: 'top',
      crop: false,
      overflow: 'none',
      //y: 20,
      formatter: function() {
        return this.stack
          // +'<br/>'+ `${this.total}`
      },
      style: {
        color: '#666',
        fontSize: '0.6rem',
        fontWeight: '400',
        fontFamily: '"Open Sans", Arial, sans-serif'
      }
    }
  },
  legend: {
    align: 'right',
    x: -30,
    verticalAlign: 'top',
    y: 0,
    floating: true,
    backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || 'white',
    borderColor: '#ccc',
    borderWidth: 1,
    shadow: false,
    labelFormatter: function() {
      return this.name + ' (' + this.userOptions.stack + ')';
    }
  },
  tooltip: {
    headerFormat: '<b>{point.x}</b><br/>',
    pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
  },

  plotOptions: {
    column: {
      stacking: 'normal',
      dataLabels: {
        enabled: true,
      }
    }
  },
  credits: {
    enabled: false
  },
}

class ChartJobQueue extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      limitReceived: '7d',
      isLoading: true,
      // jobQueueSummary: [], // variable belum berguna
      currentOptions: null,
    }
  }

  setThisState = (obj, callback = () => null) => {
    for (const key in obj) {
      if (obj[key] !== this.state[key]) {
        return this.setState(obj, callback)
      }
    }
    callback()
  }

  getStatuscolor=(status)=>{
    let s = statusColor[status]
    if(!s)
      s = '#000000'
    return s
  }

  read = () => {
    this.setThisState({ isLoading: true }, () => {
      const { limitReceived } = this.state
      RequestV2(
        'get', // method
        'chart-job-queues', // urlKey
        {}, // headers
        { limit_received: limitReceived, status: true }, // data
        [], // args
        this.readSuccess,
        err => this.setState({ isLoading: false, currentOptions: null }),
      )
    })
  }

  readSuccess = response => {
    const colors = []
    const data = []
    const categories = []

    // append statuses by role (QS/QA)
    for (const i in response.data){
      categories.push(response.data[i].name)

      const { job_summaries } = response.data[i]
      for (let j in job_summaries){

        const { summaries } = job_summaries[j]
        for (let k in summaries){
          if (i === "0") { // for in, key is string
            colors.push(this.getStatuscolor(summaries[k].label))

            if (summaries[k].label !== 'Closed') {
              data.push({
                name: summaries[k].label,
                stack: job_summaries[j].label,
                data: []
              })
            }
          }
        }
      }
    }

    // append total jobs by branch on each statuses by role (QS/QA)
    for (let branch of response.data){
      for (let jobSummary of branch.job_summaries){
        for (let summary of jobSummary.summaries){
          const foundIndex = findIndex(data, {name: summary.label, stack: jobSummary.label})
          if (foundIndex >= 0){
            data[foundIndex].data.push(summary.total)
          }
        }
      }
    }

    console.log('data', data)
    console.log('categories', categories)
    console.log('colors', colors)

    this.setState({
      isLoading: false,
      // jobQueueSummary,
      currentOptions: {
        ...optsStackedColumn,
        series: data,
        xAxis: {...optsStackedColumn.xAxis, categories},
        colors,
      }
    })
  }

  onDayChange = value => {
    this.setState({ isLoading: true, limitReceived: value }, () => {
      this.read()
    })
  }

  componentDidMount() {
    this.read()
  }

  render() {
    const { isLoading, limitReceived, currentOptions } = this.state

    return (
      <Col className="mb-2">
        <Card title='Job Queue by Branch'>
        <Row type='flex' justify='space-between'>
          <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
            <Row type='flex'>
              <Col span={8}>
                <Button disabled className='group-left' style={{ width: '100%', padding: '0 7px' }}>
                  Show:
                </Button>
              </Col>
              <Col span={16}>
                <Select
                  value={limitReceived}
                  className='group-right'
                  style={{ width: '100%', marginLeft: '-1px' }}
                  onChange={this.onDayChange}
                >
                  <Option value='7d'>7 Days</Option>
                  <Option value='30d'>30 Days</Option>
                </Select>
              </Col>
            </Row>
          </Col>
          <Col>
            <Button loading={isLoading} icon='reload' onClick={this.read}>Refresh</Button>
          </Col>
        </Row>
        <Row className='mt-2'>
          <Spin spinning={isLoading} tip='Loading...' size='large'>
            <div style={{ border: '1px solid #e8e8e8', borderRadius: '4px', padding: '1px' }}>
              {currentOptions ? (
                <HighchartsReact
                  highcharts={Highcharts}
                  constructorType='chart'
                  options={currentOptions}
                />
              ) : (
                <div>
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </div>
              )}
            </div>
          </Spin>
        </Row>
        </Card>
      </Col>
    )
  }
}

export default ChartJobQueue
