import { BaseList, BaseDetail } from "App/Component/Bases";
import fields from "./fields";
import descriptor from "./descriptor";
import options from "./options";
import { forcePermissions } from "App/Data";

const isSubmodule = true;
const urlTargetSubmodule = "/products/{}";
const urlData = "products";
const title = "Product";
const availablePermissions = ['.view_', '.add_', '.change_'];

class List extends BaseList {
  constructor(props) {
    super(props)

    this.title = title;
    this.urlData = urlData;
    this.availablePermissions = availablePermissions;
    this.propsList = {
      descriptor,
      exportImport: options.exportImportOptions,
    }
    this.state = {
      ...this.state,
      currentPermissionSet: this.isSuperuser ? this.filterPermissionItems(forcePermissions) : [],
    }
  }
}

class Detail extends BaseDetail {
  constructor(props) {
    super(props)

    this.isSubmodule = isSubmodule
    this.urlTargetSubmodule = urlTargetSubmodule
    this.title = "Product"
    this.urlData = urlData
    this.bulkName = "name"
    this.fields = fields
    this.availablePermissions = availablePermissions
    this.propsDetail = {
      urlKeyHistory: 'api:admin:estimation:product:detail-revision',
      keyLabel: 'name'
    }
    this.state = {
      ...this.state,
      currentPermissionSet: this.isSuperuser ? this.filterPermissionItems(forcePermissions) : [],
    }
  }
}

export default { List, Detail };