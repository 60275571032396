import React from 'react'
import PropTypes from 'prop-types'
import { Icon, Row } from 'antd'
import { get } from 'lodash'
import { Lib, RequestV2 as Request } from 'App/Utils'
import { getInitialStatus } from 'App/Component/OrderManagement/Content/CardItem/statuses'

class Subsection extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      collapsed: true,
      isLoading: false,
      groups: [],
    }
  }

  onCollapse = (event) => {
    if (event) event.stopPropagation()

    if (get(this.state.groups, 'length') || !this.state.collapsed) {
      this.setCollapsed(!this.state.collapsed)
    } else {
      this.loadGroups((isSuccess) => {
        this.setCollapsed(!this.state.collapsed)
      })
    }
  }

  loadGroups = (callback = () => null) => {
    if (this.state.isLoading) return

    this.setState({ isLoading: true }, () => {
      Request(
        'get',
        this.props.urlKeyGroupList,
        {},
        { limit: 100, subsection: this.props.subsection.pk },
        [],
        this.loadGroupsSuccess,
        this.loadGroupsFailed,
        { callback }
      )
    })
  }

  loadGroupsSuccess = (response, extra) => {
    this.setState({ isLoading: false, groups: response.data.results }, () => {
      extra.callback(true)
    })
  }

  loadGroupsFailed = (error, extra) => {
    Lib.errorMessageHandler(get(error, 'response'))
    this.setState({ isLoading: false, groups: null }, () => {
      extra.callback(false)
    })
  }

  setCollapsed = (collapsed) => {
    this.setState({ collapsed })
  }

  getIconStatus = (status) => {
    if (status === 'active') return <Icon type='check-circle' style={{ color: 'rgb(39, 174, 96)', cursor: 'default' }} title='Active - Anyone can use' />
    if (status === 'unpublished') return <Icon type='exclamation-circle' style={{ color: 'orange', cursor: 'default' }} title='Unpublished - Only certain people can use' />
    return <Icon type='close-circle' style={{ color: 'rgb(127, 140, 141)', cursor: 'default' }} title='Inactive - No one can use' />
  }

  onClickGroup = (event, group) => {
    event.stopPropagation()

    this.props.onSelectGroup(group)
  }

  // on icon Add click
  onAddGroup = (event) => {
    event.stopPropagation()

    this.props.onAddGroup(this.props.subsection)
  }

  onShowDelete = (event, group) => {
    event.stopPropagation()
    event.preventDefault()

    this.props.onDeleteGroup(group, { subsection: this.props.subsection })
  }

  onConfirmDelete = (group, callback) => {
    if (group) {
      Request(
        'delete',
        'prefillSetGroups-delete',
        {},
        {},
        [group.pk],
        this.deleteSuccess,
        this.deleteFailed,
        { callback, groupId: group.pk }
      )
    } else {
      callback()
    }
  }

  deleteSuccess = (response, extra) => {
    extra.callback(true)

    this.setState(prevState => ({ groups: prevState.groups.filter(group => group.pk !== extra.groupId) }))
  }

  deleteFailed = (error, extra) => {
    Lib.errorMessageHandler(get(error, 'response'))
    extra.callback(false)
  }

  componentDidMount() {
    const { defaultExpandedKeys, subsection } = this.props
    if (`${get(defaultExpandedKeys, 'subsection')}` === `${subsection.pk}`) {
      // expand and read
      this.onCollapse()
    }
  }

  render() {
    const { subsection, selectedObj } = this.props
    const { collapsed, groups, isLoading } = this.state
    const iconType = isLoading ? 'loading' : (collapsed ? 'caret-right' : 'caret-down')

    return (
      <div className='ant-collapse-item section-panel' style={{ paddingLeft: '8px' }}>
        <Row type='flex' align='middle' className='ant-collapse-header container-hover' onClick={this.onCollapse}>
          <Icon type={iconType} className='ant-collapse-arrow text-danger' style={{ marginRight: '4px' }} />
          {/* <div style={{ width: 'calc(100% - 17px - 17px)' }}> */}
          <div style={{ width: 'calc(100% - 21px)' }}>
            {subsection.name}
          </div>
          <Icon
            className='child-hover'
            type='plus-circle'
            theme='filled'
            style={{ padding: '4px' }}
            title={`Add Group in Subsection "${subsection.name}"`}
            onClick={this.onAddGroup}
          />
        </Row>

        <div className={`ant-collapse-content ant-collapse-content-${collapsed ? 'inactive' : 'active'}`}>
          <div className='ant-collapse-content-box'>
            {groups ? (
              <ul style={{ paddingInlineStart: 0, listStyleType: 'none' }}>
                {groups.map(group => {
                  const isSelected = !!selectedObj[group.pk]

                  return (
                    <li
                      key={group.pk}
                      className={isSelected ? 'selected-subsection' : ''}
                      style={{ margin: '2px' }}
                      onClick={(event) => this.onClickGroup(event, group)}
                    >
                      <Row type='flex' align='middle' className='container-hover'>
                        <div style={{ width: '14px', marginRight: '4px' }}>
                          {isSelected && <Icon type='credit-card' />}
                        </div>
                        <div className='subsection-list-item' style={{ width: 'calc(100% - 18px - 22px)', display: 'flex' }}>
                          <div style={{ width: 'calc(100% - 17px)' }}>{group.name}</div>
                          <div style={{ margin: '0 2px 2px 2px'}} onClick={e => {e.stopPropagation()}}>
                            {getInitialStatus(group.status)}
                          </div>
                        </div>
                        <Icon
                          className='text-danger child-hover'
                          type='rest'
                          title={`Delete Group "${group.name}"`}
                          style={{ padding: '3px 4px' }}
                          onClick={(event) => this.onShowDelete(event, group)}
                        />
                      </Row>
                    </li>
                  )
                })}
              </ul>
            ) : (
              <div className='text-danger' style={{ fontStyle: 'italic', paddingLeft: '28px' }}>
                error...
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

Subsection.propTypes = {
  urlKeyGroupList: PropTypes.string.isRequired,
  onAddGroup: PropTypes.func,
  onSelectGroup: PropTypes.func,
  onDeleteGroup: PropTypes.func,
}

Subsection.defaultProps = {
  onAddGroup: () => null,
  onSelectGroup: () => null,
  onDeleteGroup: () => null,
}

export default Subsection