import startCase from "lodash/startCase";

import Dashboard from "./Dashboard";
import MainRedirect from "./MainRedirect";

const appName = "dashboard";
const routes = [
  {
    title: "Dashboard",
    routes: [
      {
        name: "Admin",
        path: "/",
        // redirect: "/admin",
        component: MainRedirect,
      },
      {
        name: "Admin",
        path: "/admin",
        redirect: "/admin/dashboard"
      },
      {
        path: "/admin/dashboard",
        codename: 'admin',
        name: "Dashboard",
        component: Dashboard
      }
    ]
  }
];

export default { appName: startCase(appName), routes };
