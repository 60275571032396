export default [
  {
    title: 'Sort',
    type: 'text',
    data: '',
    required: true,
    initialValue: '',
    dataIndex: 'sort',
    note_text: '',
    additional: '',
    order: 0,
  }, {
    title: 'Take off type',
    type: 'text',
    data: '',
    required: true,
    dataIndex: 'take_off_type',
    initialValue: '',
    note_text: '',
    additional: '',
    order: 1,
  }, {
    title: 'Status',
    type: 'select',
    dataIndex: 'status',
    data: [
      {
        alias: 'a',
        value: 'Active'
      }, {
        alias: 'i',
        value: 'Inactive'
      },
    ],
    initialValue: null,
    note_text: '',
    additional: '',
    order: 3,
  },
]