// old format, tapi masih digunakan. seperti "erfTypes-read"
// new format, seperti "api:admin:erf:erftype:list"

export default {
  "erfTypes-read": "api/admin/erf/erf-types/",
  // "erfTypes-create": "api/admin/erf/erf-types/",
  // "erfTypes-delete": "api/admin/erf/erf-types/{}/",
  "erfTypes-update": "api/admin/erf/erf-types/{}/",
  "erfTypes-detail": "api/admin/erf/erf-types/{}/",
  "erfTypes-autocomplete": "api/admin/erf/erf-types/autocomplete/",
  // "erfTypes-detail-revision-version": "api/admin/erf/erf-types/{}/revision/{}/",
  "api:admin:erf:erftype:list": "api/admin/erf/erf-types/",
  "api:admin:erf:erftype:detail": "api/admin/erf/erf-types/{}/",
  "api:admin:erf:erftype:detail-revision": "api/admin/erf/erf-types/{}/revision/",
  "api:admin:erf:erftype:detail-revision-version": "api/admin/erf/erf-types/{}/revision/{}/",
  "api:admin:erf:erftype:export": "api/admin/erf/erf-types/export/",

  "erfMappings-read": "api/admin/erf/erf-mappings/",
  "erfMappings-create": "api/admin/erf/erf-mappings/",
  "erfMappings-delete": "api/admin/erf/erf-mappings/{}/",
  "erfMappings-update": "api/admin/erf/erf-mappings/{}/",
  "erfMappings-detail": "api/admin/erf/erf-mappings/{}/",
  "erfMappings-autocomplete": "api/admin/erf/erf-mappings/autocomplete/",
  "erfMappings-detail-revision-version": "api/admin/erf/erf-mappings/{}/revision/{}/",
  "api:admin:erf:erfmapping:list": "api/admin/erf/erf-mappings/",
  "api:admin:erf:erfmapping:detail": "api/admin/erf/erf-mappings/{}/",
  "api:admin:erf:erfmapping:export": "api/admin/erf/erf-mappings/export/",

  "erfContentTypes-autocomplete": "api/admin/erf/content-types/autocomplete/",
  "erfContentTypes-refRecords-autocomplete": "api/admin/erf/content-types/{}/records/",
}