import React, {Fragment} from "react";
import {FormContext, FormProvider} from "../Context/ContextForm";
import {get, camelCase, capitalize, cloneDeep, isEmpty} from 'lodash'
import DynamicField from "../DynamicField";
import LoadingSkleton from "../LoadingSkleton";
import styled from "styled-components";
import {Row, Col, Button, Form, message, Modal, notification, Affix} from "antd";
import { isObject } from "util";
import DrawerSQ from "App/Component/DrawerSQ";
import { RequestV2 as Request, Lib, CheckPermission } from "App/Utils";
import Lib2 from "./Lib2";
import DynamicEditTable from "./DynamicEditTable";
const { confirm } = Modal;

class FieldsContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      paramProps: this.getInitialParamProps(),
      objArgs: this.getInitialObjArgs(),
    }
  }

  getInitialParamProps = () => {
    const paramProps = {}
    const { fields } = this.props
    for (const index in fields) {
      if (fields[index].foreignKey) {
        const initialValue = get(this.props.dataRecord, fields[index].foreignKey)
        paramProps[fields[index].dataIndex] = Lib.checkValues({ [fields[index].foreignKey]: initialValue })
      } else if (fields[index].configParamProps) {
        for (const j in fields[index].configParamProps) {
          const { key, sourceField, sourceKey } = fields[index].configParamProps[j]
          const initialValue = get(this.props.dataRecord, [sourceField, sourceKey])
          paramProps[fields[index].dataIndex] = {
            ...paramProps[fields[index].dataIndex],
            [key]: initialValue,
          }
        }
      }
    }
    return paramProps
  }

  // Mengatur URL param ke suatu field berdasar index di fields (multiple)
  setParamProps = (paramProps) => {
    this.setState(prevState => ({ paramProps: {...prevState.paramProps, ...paramProps} }))
  }

  getInitialObjArgs = () => {
    let objArgs = {}
    const { fields } = this.props
    for (const index in fields) {
      if (fields[index].referenceBy) {
        const dataIndexReceive = fields[index].dataIndexReceive || fields[index].dataIndex
        const currentValue = get(this.props.dataRecord, dataIndexReceive)
        objArgs = { ...objArgs, ...Lib.checkValues({ [fields[index].referenceBy]: currentValue }) }
      }
    }
    return objArgs
  }

  setArgs = (objArgs) => {
    this.setState(prevState => ({ objArgs: {...prevState.objArgs, ...objArgs} }))
  }

  render() {
    const p = this.props
    const { paramProps } = this.state
    return (
      (p.fields || []).map((elem, index) => {
        // key antara menerima dan mengirim dapat dibuat sama atau berbeda
        // jika ingin berbeda, buat "dataIndexReceive" untuk key yang diterima saat retrieve, dan "dataIndex" untuk key form, juga yang akan dikirim
        const dataIndexReceive = elem.dataIndexReceive || elem.dataIndex
        let datafield = dataIndexReceive && get(p.dataRecord, dataIndexReceive.split('.'))
        return (
          <DynamicField
            {...elem}
            recordId={get(p.dataRecord, 'pk')}
            paramProps={{...paramProps[elem.dataIndex], ...elem.paramProps}}
            setParamProps={this.setParamProps}
            args={get(this.state.objArgs, elem.dataIndex)}
            setArgs={this.setArgs}
            normFile={p.normFile}
            operation={p.operation}
            userReducer={p.userReducer}
            dataRecord={datafield}
            key={index}
            index={index}
            toggleDrawerSQ={p.toggleDrawerSQ}
            onBlur={p.onBlur}
            allFields={p.fields}
            field={elem}
            onPressEnter={this.props.onPressEnter}
            setFilter={p.setFilter}
          />
        );
      })
    )
  }
}

class FormData extends React.Component {
  constructor(props) {
    super(props)
    this.saveAndaddanother = false
    this.saveAndcontinueediting = false

    // Asumsinya: Jika user dapat mengakses ini, maka pasti sudah punya permission read
    // Karena sudah di handle di BaseDetail, sehingga hanya di tes apakah readOnly atau tidak
    // const code = props.operation === 'add' ? '.add_' : '.change_'
    this.readOnly = props.operation === 'edit' && !get(props, 'permissions', []).find(code => code.indexOf('.change_') !== -1)
    this.allowDelete = get(props, 'permissions', []).some(code => code.indexOf('.delete_') !== -1)
    this.state = {
      visibleDrawerSQ: false,
      operationSQ: '',
      historySubDrawer: [],
      indexing: -1,
    }
    this.formDetail = null
    this.fileList = []
  }

  // getInitialParamProps = () => {
  //   const paramProps = {}
  //   const { fields } = this.props
  //   for (const index in fields) {
  //     if (fields[index].foreignKey) {
  //       const initialValue = get(this.props.dataRecord, fields[index].foreignKey)
  //       console.log('initialValue', initialValue, this.props.dataRecord)
  //       paramProps[fields[index].dataIndex] = Lib.checkValues({ [fields[index].foreignKey]: initialValue })
  //     }
  //   }
  //   return paramProps
  // }

  setFilter=(value)=>{
    const [p] = [this.props]
    const select = get(this.props, 'children.filter_selected')
    if(select && get(value, 'data')){
      if(select[value.name]){
        const filterSelected = {}
        for(const i of select[value.name])
          filterSelected[i] = { [value.name]: value.data.key }
        p.setThisState({ filterSelected }, ()=>null, true)
      }
    }
  }

  hasErrors(form) {
    let hasError = false
    if (form) {
      const fieldsError = form.getFieldsError()
      hasError = Object.keys(fieldsError).some(field => fieldsError[field])
    }
    // Return Boolean
    return hasError
  }

  save = (values, form) => {
    const { urlData } = this.props
    const p = this.props
    if(p.subheaderData.length > 0 && !Lib2.haveNoErrorBulk(p.subheaderData, p.children)) {
      return null
    }

    p.messageCustom('loading', 'Save in progress..');
    let valuesConverted = Lib2.getValuesWithFile(values, urlData)
    this.request = Request(
      "post",
      this.props.getUrlData(urlData, 'save'),
      {},
      valuesConverted,
      [],
      this.saveSuccess,
      this.onFailed,
      form,
    );
  };

  saveSuccess = (response, extra) => {
    this.props.setTouched(false)
    this.props.location.state.isChanged = false
    const p = this.props
    // const subheader = Lib2.getSubheader(camelCase(p.urlData))

    if (response.status === 200 || response.status === 201) {
      let hasSubHeader = p.children && p.subheaderData.length > 0
      let hold = {saa: this.saveAndaddanother, sac: this.saveAndcontinueediting}
      if(hasSubHeader) {
        const subheaderData = cloneDeep(p.subheaderData)
        const formData = Lib2.arrayFormData(subheaderData, p.children, response, p.cloneSD)
        if(p.children.bulk_request) {
          if(formData.length > 0 && formData.find(e => e.touched))
            p.bulkSendData(p.children, response, hold, formData.filter(e => e.touched))
          else
            hasSubHeader = false
        } else {
          if(formData.length > 0 && formData.find(e => e.touched))
            p.multiSendData(p.children, response, hold, formData)
          else
            hasSubHeader = false
        }
      } else if (p.children && this.props.isFirstPage) {
        p.addSubheaderData()
      }
      if (this.saveAndaddanother) {
        this.saveAndaddanother = false
        message.destroy()
        message.success('Save Success')
        this.props.location.state.dataRecord = null
        this.props.location.state.isChanged = false
        p.setThisState({ originalDataRecord: null, loading: false }, () => {
          extra.resetFields()
        })
      } else if (this.saveAndcontinueediting) {
        this.saveAndcontinueediting = false
        this.props.location.state.dataRecord = response.data
        this.props.location.state.isChanged = false
        if (!hasSubHeader) {
          message.destroy()
          message.success('Save Success')
          this.props.history.replace(
            this.props.location.pathname.replace(
              this.props.match.params.id,
              response.data.pk
            ),
            this.props.location.state
          )
        }
        p.setThisState({ originalDataRecord: response.data, loading: false })
      } else {
        const {state} = p.location
        if (state) {
          message.destroy()
          message.success('Save Successfully')
          if (state.from && state.from.length > 0) {
            Lib2.storeState(response.data, p)
          }
          else if(!hasSubHeader){
            p.goBack(true)
          }
        }
      }
    }
  };

  onFailed = (error, form) => {
    let errorObj = error.response.data;
    const p = this.props
    message.destroy()
    p.messageCustom('error', 'There were some errors. Please check below for details.');

    if(error.response.status !== 500 && typeof errorObj === 'object')
      for (let key in errorObj) {
        if (errorObj.hasOwnProperty(key)) {
          form.setFields({
            [key]: {
              value: form.getFieldValue(key),
              errors: [new Error(errorObj[key])],
            },
          });
          notification.error({
            message: `Error on field ${key}`,
            description: errorObj[key]
          })
        }
      }
    else
      p.messageCustom('error', `Response status from server : ${error.response.status}`)
    p.setThisState({ loading: false })
  };

  editSuccess = (response, form) => {
    this.props.setTouched(false)
    const p = this.props
    // const subheader = Lib2.getSubheader(camelCase(p.urlData))

    if (response) {
      if (response.status === 200 || response.status === 201 || response.status === 204) {
        let dataRecord
        if (get(p.location.state, 'dataRecord')) {
          dataRecord = p.location.state.dataRecord
        } else {
          dataRecord = response.data
        }
        let bulkName;

        if (get(p.location.state, 'bulkName')) {
          bulkName = p.location.state.bulkName
          if (isObject(dataRecord[bulkName])) {
            bulkName = bulkName + '.' + Lib2.getBulkFromObject(dataRecord[bulkName])
          }
        } else {
          bulkName = Lib2.getBulkName(dataRecord, p.fields)
          if(isObject(dataRecord[bulkName])) {
            let secondKey = Object.keys(dataRecord[bulkName])
            bulkName = bulkName+ '.' + get(secondKey, '[1]', '[0]')
          }
        }
        let hasSubHeader = p.children && p.subheaderData.length > 0
        let hold = {saa: this.saveAndaddanother, title: Lib.bulkNameData(dataRecord, bulkName), sac: this.saveAndcontinueediting}
        if(hasSubHeader) {
          const subheaderData = cloneDeep(p.subheaderData)
          const formData = Lib2.arrayFormData(subheaderData, p.children, response, p.cloneSD)
          if(formData.length > 0 && formData.find(e => e.touched)){
            if(p.children.bulk_request)
              p.bulkSendData(p.children, response, hold, formData.filter(e => e.touched))
            else
              p.multiSendData(p.children, response, hold, formData)
          }
          else
            hasSubHeader = false
        } else if (p.children && this.props.isFirstPage) {
          p.addSubheaderData()
        }

        if (this.saveAndaddanother) {
          this.saveAndaddanother = false
          form.resetFields();
          if(!hasSubHeader) {
            message.destroy()
            message.success(`Edit "${Lib.bulkNameData(dataRecord, bulkName)}" was changed successfully.`)
            this.props.location.state.dataRecord = null
            this.props.location.state.isChanged = false
            p.setThisState({ originalDataRecord: null, loading: false })
          } else {
            p.setThisState({ originalDataRecord: null })
          }
          try { delete this.props.location.state.dataRecord } catch (e) {}
          this.props.history.replace(
            this.props.location.pathname.replace(
              this.props.match.params.id,
              'add'
            ),
            this.props.location.state
          )
        } else if (this.saveAndcontinueediting) {
          this.saveAndcontinueediting = false
          if (!hasSubHeader) {
            message.destroy()
            message.success(`Edit "${Lib.bulkNameData(dataRecord, bulkName)}" was changed successfully.`)
          }
          this.props.location.state.dataRecord = response.data
          this.props.location.state.isChanged = false
          p.setThisState({ originalDataRecord: response.data, loading: false })
        } else {
          const {state} = p.location
          if (state) {
            if (state.from && state.from.length > 0) {
              message.destroy()
              Lib2.storeState(response.data, this.props)
            } else if(!hasSubHeader){
              message.destroy()
              p.goBack(true)
            }
          }
          message.success(`Edit "${Lib.bulkNameData(dataRecord, bulkName)}" was changed successfully.`)
        }
      }
    }
  };

  edit = (values, form) => {
    const { urlData } = this.props
    const idData = get(this.props.location.state, 'dataRecord.pk')
    const p = this.props
    if (p.subheaderData.length > 0 && !Lib2.haveNoErrorBulk(p.subheaderData, p.children)) {
      return null
    }

    let result = Lib2.getValuesWithFile(values, urlData)
    p.messageCustom('loading', 'Edit in progress..');

    this.request = Request(
      "put",
      this.props.getUrlData(urlData, 'edit'),
      // urlData + "-update",
      {},
      result,
      this.props.getArgs([idData]),
      this.editSuccess,
      this.onFailed,
      form
    );
  };

  toggleDrawerSQ = (operationSQ, urlSQ = 'overrideLines', idData = null, keyform=null, callback = () => null) => {
    if(keyform)
      this.setState({keyform}, () => callback())
    if (operationSQ === 'back') {
      let popHistory = this.state.historySubDrawer, visibleDrawerSQ = true;
      popHistory.pop();

      if (popHistory.length < 1) {
        visibleDrawerSQ = false
      }

      this.setState((prevState) => ({
        historySubDrawer: popHistory,
        visibleDrawerSQ: visibleDrawerSQ,
        indexing: prevState.indexing - 1,
      }), () => callback());

    } else if (operationSQ === 'close') {
      this.setState((prevState) => ({
        historySubDrawer: [],
        visibleDrawerSQ: false,
        indexing: -1,
      }), () => callback());

    } else {
      let history = {
        idData: idData,
        url: urlSQ,
        historyOperation: operationSQ
      };

      this.setState((prevState) => ({
        visibleDrawerSQ: true,
        operationSQ,
        historySubDrawer: [...prevState.historySubDrawer, history],
        indexing: prevState.indexing + 1
      }), () => callback());
    }
  };

  onSave = (form) => {
    if (!this.props.loading) {
      const {validateFieldsAndScroll} = form;
      validateFieldsAndScroll({scroll: {offsetTop: 150}}, (err, values) => {
        if (!err) {
          let pk;
          if (get(this.props, 'location.state.dataRecord.pk')) {
            pk = this.props.location.state.dataRecord.pk
          }
          const skip = get(this.props, 'fields', []).find(e => e.skip)
          if(skip)
            delete values[skip.dataIndex]

          if (pk) {
            this.edit(values, form)
          } else {
            this.save(values, form)
          }
        } else {
          this.saveAndcontinueediting = false
          this.saveAndaddanother = false
        }
      })
    }
  }

  setForm=(val)=>{
    let obj = {form: this.formDetail}
    if (obj && typeof val === 'object') {
      let keyField = ''
      for (const key in val) { keyField = key; break; }
      const valField = { key: `${val[keyField].key}`, label: val[keyField].label }
      obj.form.setFieldsValue({ [keyField]: valField })
    }
  }

  refreshPage=(urlData, pk = undefined, form=false)=> {
    this.request = Request(
      "get",
      urlData + "-detail",
      {},
      {},
      pk ? [pk] : [],
      this.refreshCompleted,
      this.readFailed,
      form
    );
  }

  deleteFileSuccess = (response) => {
    if (response) {
      if (response.status === 204) {
        this.messageCustom('success', `Save successfully.`);
        this.refreshPage(this.props.location.state.urlData, this.props.match.params.id)
      }
    }
  }

  deleteFileFailed = (error) => {
    this.messageCustom('error', 'Action failed');
  }

  showDeleteConfirm=(callback)=> {
    const {userReducer, dataRecord, urlData} = this.props
    confirm({
      title: 'Are you sure delete this file?',
      content: 'your file will delete permanently',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk:()=> {
        this.messageCustom('loading', 'Delete in progress..');
        this.request = Request(
          "delete",
          urlData + "File-delete",
          {},
          {},
          [dataRecord.pk],
          this.deleteFileSuccess,
          this.deleteFileFailed,
        );
        callback(true)
      },
      onCancel:()=> {
        callback(false)
        this.messageCustom('info', 'canceled');
      },
    });
  }

  messageCustom = (type, msg) => {
    if (type !== 'loading') {
      message.destroy();
      this.setLoading(false);
    } else {
      this.setLoading(true);
    }
    return (
      message[type](msg)
    )
  };

  setLoading = (val) => {
    this.setState({loading: val})
  };



  subHeader = (e) =>{
    const [p,s] = [this.props,this.state]
    if(p.children) {
      const permissionItems = CheckPermission.getPermissionSet({ userReducer: this.props.userReducer, appName: p.children.appName, modelName: p.children.modelName })
      const readOnly = this.readOnly || !permissionItems.some(val => val.includes(p.children.bulk_request ? '.add_' : '.change_'))
      let x = !isEmpty(p.dataRecord)
      if (p.operation === 'add')
        x = true
      if (p.children[p.operation] && x)
        return <DynamicEditTable
          urlTab={p.children.sub}
          urlSub={p.children.urlSub}
          cloneSD={p.cloneSD}
          parentForm={this.formDetail}
          changeData={(key, val) => p.setThisState({[key]: val})}
          data={p.subheaderData}
          {...this.props}
          permissions={p.children}
          extraactions={false}
          readOnly={readOnly}
          filterSelected={p.filterSelected}
        />
    }
  }

  setThisform=(form)=>{
    if(!this.form)
      this.form = form
  }

  // Delete current item
  handleDelete = () => {
    Modal.confirm({
      // Don't passing maskClosable please
      title: `Delete ${this.props.title}`,
      content: `Are you sure you want to delete this ${this.props.title}? This cannot be undone.`,
      okType: 'danger',
      okText: 'Delete',
      onCancel: () => new Promise((resolve, reject) => {
        if (this.isDeleting) {
          reject('Deletion process in progress, cannot cancel it')
          message.info('Deletion process in progress, cannot cancel it')
        } else {
          resolve()
        }
      }),
      onOk: () => new Promise((resolve, reject) => {
        const id = get(this.props.dataRecord, 'pk', this.props.match.params.id)
        this.isDeleting = true
        Request(
          'delete',
          this.props.urlName,
          {},
          {},
          [id],
          this.onDeleteSuccess,
          this.onDeleteFailed,
          { resolve, reject }
        )
      })
    })
  }

  onDeleteSuccess = (response, extra) => {
    this.props.setTouched(false, () => {
      this.isDeleting = false
      message.success(`${this.props.title} has been deleted.`)
      extra.resolve()
      // Find URL list, dengan memotong akhir dari path, /admin/estimation/formula/123 => /admin/estimation/formula
      const paths = this.props.location.pathname.split('/').filter(v => v)
      paths.splice(-1)
      const urlTarget = '/' + paths.join('/')
      this.props.history.replace(urlTarget)
    })
  }

  onDeleteFailed = (error, extra) => {
    this.isDeleting = false
    message.error(`Failed to delete ${this.props.title}`)
    extra.reject('Delete failed')
  }

  onPressEnter = () => {
    this.onSave(this.formDetail)
  }

  // // Mengatur URL param ke suatu field berdasar index di fields (multiple)
  // setParamProps = (paramProps) => {
  //   this.setState(prevState => ({ paramProps: {...prevState.paramProps, ...paramProps} }))
  // }

  render() {
    const p = this.props
    return (
      <>
        <Row>
          <FormProvider>
            <Form layout="horizontal">
              {
                !p.showLoading ? (
                  <FieldsContainer
                    {...this.props}
                    normFile={this.showDeleteConfirm}
                    operation={this.readOnly ? 'view' : p.operation}
                    userReducer={p.userReducer}
                    // dataRecord={datafield}
                    // key={index}
                    // index={index}
                    setFilter={this.setFilter}
                    toggleDrawerSQ={p.subheaderData.length > 0 && p.isFirstPage ? this.toggleDrawerSQ : p.goTo}
                    onBlur={p.onBlur}
                    allFields={p.fields}
                    onPressEnter={this.onPressEnter}
                  />
                  // p.fields && p.fields.map((elem, index) => {
                  //   let datafield = (elem.dataIndex) && get(p.dataRecord, elem.dataIndex.split('.'))
                  //   return (
                  //     <DynamicField
                  //       paramProps={paramProps[elem.dataIndex]}
                  //       {...elem}
                  //       setParamProps={this.setParamProps}
                  //       normFile={this.showDeleteConfirm}
                  //       operation={this.readOnly ? 'view' : p.operation}
                  //       userReducer={p.userReducer}
                  //       dataRecord={datafield}
                  //       key={index}
                  //       index={index}
                  //       toggleDrawerSQ={p.subheaderData.length > 0 && p.isFirstPage ? this.toggleDrawerSQ : p.goTo}
                  //       onBlur={p.onBlur}
                  //       allFields={p.fields}
                  //     />
                  //   );
                  // })
                ) : <LoadingSkleton count={p.fields.length}/>
              }
              {p.isFirstPage && this.subHeader(p.urlData)}
              <FormContext.Consumer>
                {form => {this.formDetail = form}}
              </FormContext.Consumer>
            </Form>
          </FormProvider>
        </Row>
        <Affix offsetBottom={0}>
          <StyledFooter>
            <Row type='flex' justify='space-between'>
              <Col>
                {
                  (p.operation === 'edit' && this.allowDelete && !p.match.params.historyId) && (
                    <Button type='danger' icon='delete' onClick={this.handleDelete}>Delete</Button>
                  )
                }
              </Col>
              <Col>
                <Row type='flex' gutter={[12, 0]}>
                  <Col>
                    <Button type='danger' onClick={() => p.goBack()}>Close</Button>
                  </Col>
                  {
                    (!this.readOnly && p.isFirstPage && !p.match.params.historyId) && (
                      <>
                        <Col>
                          <Button
                            onClick={() => {this.saveAndaddanother = true; this.onSave(this.formDetail)}}
                            disabled={p.loading || this.hasErrors(this.formDetail)}
                          >
                            Save and add another
                          </Button>
                        </Col>
                        <Col>
                          <Button
                            onClick={() => {this.saveAndcontinueediting = true; this.onSave(this.formDetail)}}
                            disabled={p.loading || this.hasErrors(this.formDetail)}
                          >
                            Save and continue editing
                          </Button>
                        </Col>
                      </>
                    )
                  }
                  <Col>
                    {
                      !this.readOnly && (
                        <Button
                          type='primary'
                          onClick={() => this.onSave(this.formDetail)}
                          disabled={p.loading || this.hasErrors(this.formDetail)}
                        >
                          {p.operation === 'add' ? 'Save' : 'Update'}
                        </Button>
                      )
                    }
                  </Col>
                </Row>
              </Col>
            </Row>
          </StyledFooter>
        </Affix>
        <DrawerSQ
          {...this.props}
          visible={this.state.visibleDrawerSQ}
          toggleDrawerSQ={this.toggleDrawerSQ}
          // changeData={this.singleChangestate}
          getValuesWithFile={Lib2.getValuesWithFile}
          userReducer={this.props.userReducer}
          title={`${capitalize(this.state.operationSQ)} Record`}
          operationSQ={this.state.operationSQ}
          operation={this.state.operationSQ}
          historySubDrawer={this.state.historySubDrawer}
          indexing={this.state.indexing}
          reloadRead={()=>null}
          oneForm={this.setForm}
          isTable={true}
        />
      </>
    )
  }
}

export default FormData

const StyledFooter = styled.div`
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  background: #fff;
`;
