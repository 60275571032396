import React from 'react'
import PropTypes from 'prop-types'
import { Spin } from 'antd'
import MemberList from './List'
import ErrorLabel from '../ErrorLabel'

class PageMembers extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      members: [],
      rawResponse: null,
    }
  }

  // Jangan di hapus
  setThisState = (obj, callback = () => null, force) => {
    if (force) {
      return this.setState(obj, callback)
    } else {
      for (const key in obj) {
        if (obj[key] !== this.state[key]) {
          return this.setState(obj, callback)
        }
      }
    }
    return callback()
  }

  onRemoveMembers = (ids = [], callback = () => null) => {
    const remainingMembers = this.state.members.filter(member => ids.findIndex(id => member.pk === id) === -1)
    this.setState({ errorMessage: '', members: remainingMembers }, () => callback(true))
  }

  onJoinUsers = (ids, users, callback) => {
    this.setState(prevState => ({ errorMessage: '', members: [...prevState.members, ...users] }), () => {
      this.props.onFieldTouched(true)
      callback(true)
    })
  }

  validateFields = () => {
    return this.state.members.map(member => member.pk)
  }

  setError = (errorMessage) => {
    this.props.setActiveKey(this.props.tabKey, () => {
      this.setState({ errorMessage })
    })
  }

  render() {
    const { userReducer, history } = this.props
    const { members, errorMessage } = this.state

    return (
      <Spin spinning={false}>
        { errorMessage && <ErrorLabel errorMessage={errorMessage} /> }
        <MemberList
          ref={ref => {this._member = ref}}
          history={history}
          dataSource={members}
          userReducer={userReducer}
          onJoinUsers={this.onJoinUsers}
          removeMembers={this.onRemoveMembers}
        />
      </Spin>
    )
  }
}

PageMembers.propTypes = {
  userReducer: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  tabKey: PropTypes.string,
  setActiveKey: PropTypes.func,
}

PageMembers.defaultProps = {
  handleHistoryAction: () => null,
  tabKey: null,
  setActiveKey: (tabKey, callback = () => null) => callback(),
}

export default PageMembers