import ComponentAutocomplete from "App/Component/Autocomplete";
export default {
  name: 'extraCharges',
  sub: 'extraChargeMappings',
  bulk_request: false,
  identifier: {
    name: 'extra_charges',
    is_array: false
  },
  urlSub: 'api:admin:jobs:extracharge:ref-mappings-list',
  add: true,
  edit: true,
  permissions: {
    bulk_delete: false,
    initialrow: 1,
    add: true,
    duplicate: {
      name: 'size',
      label: 'Size',
      dataIndex: 'size.label',
      message: 'Size cannot duplicate'
    }
  },
  rules: {
    button: {
      type: {
        add: 'default',
        edit: 'default'
      },
      size: 'default'
    },
    input: {
      size: 'default'
    },
    select: {
      size: 'default'
    }
  },
  fields: ComponentAutocomplete['extraChargeMappings']
}