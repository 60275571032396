export default [
  {
    title: 'Template set',
    type: 'SelectQuery',
    required: true,
    data: 'templateSets',
    dataIndex: 'template_set',
    selectedKey: 'pk',
    selectedValue: 'name',
    initialValue: '',
    note_text: 'Which Template Set this section template belong to.',
    additional: 'edit|add',
    order: 0,
  }, {
    title: 'Name',
    required: true,
    type: 'text',
    dataIndex: 'name',
    data: '',
    initialValue: '',
    note_text: 'e.g. Ext Openings',
    additional: '',
    order: 1,
  }, {
    title: 'Order',
    required: true,
    type: 'number',
    dataIndex: 'order',
    data: '',
    initialValue: '0',
    note_text: '',
    additional: '',
    order: 2,
  }, {
    title: 'Status',
    type: 'select',
    disableAllowClear: true,
    dataIndex: 'status',
    data: [
      {
        alias: 'active',
        value: 'Active - Anyone can use'
      }, {
        alias: 'inactive',
        value: 'Inactive - No one can use'
      }, {
        alias: 'unpublished',
        value: 'Unpublished - Only certain people can use'
      }
    ],
    initialValue: 'active',
    note_text: '',
    additional: '',
    order: 3,
  },
  // {
  //   title: 'OVERRIDE LINES',
  //   type: 'table',
  //   data: [
  //     {
  //       title: 'NAME',
  //       type: 'text',
  //       data: '',
  //       additional: '',
  //       note_text : 'In singular form, e.g. Door (not Doors)',
  //       key : 'rowNAME',
  //     }, {
  //       title: 'PLURAL NAME',
  //       type: 'text',
  //       data: '',
  //       additional: '',
  //       note_text : 'The plural form of name, e.g. Doors (not Door)',
  //       key : 'rowPLURAL',
  //     }, {
  //       title: 'ORDER',
  //       type: 'text',
  //       data: '',
  //       additional: '',
  //       initialValue : '0',
  //       key : 'rowORDER',
  //     }, {
  //       title: 'SHOW HEIGHT',
  //       type: 'checkbox',
  //       data: '',
  //       additional: '',
  //       initialValue : true,
  //       key : 'rowSHOWHEIGHT',
  //     }, {
  //       title: 'HEIGHT LABEL',
  //       type: 'text',
  //       data: '',
  //       additional: '',
  //       initialValue : 'Height',
  //       key : 'rowHEIGHTLABEL',
  //     }, {
  //       title: 'HEIGHT LOCKED',
  //       type: 'checked',
  //       data: '',
  //       additional: '',
  //       key : 'rowHEIGHTLOCKED',
  //     }, {
  //       title: 'HEIGHT LIMITER',
  //       type: 'checked',
  //       data: '',
  //       additional: '',
  //       key : 'rowHEIGHTLIMITER',
  //     }, {
  //       title: 'LOWER HEIGHT',
  //       type: 'number',
  //       data: '',
  //       additional: '',
  //       note_text: '',
  //       key : 'rowLOWERHEIGHT',
  //     }, {
  //       title: 'UPPER HEIGHT',
  //       type: 'number',
  //       data: '',
  //       additional: '',
  //       note_text: '',
  //       key : 'rowUPPERHEIGHT',
  //     }, {
  //       title: 'SHOW WIDTH',
  //       type: 'checkbox',
  //       data: '',
  //       additional: '',
  //       note_text: '',
  //       initialValue: true,
  //       key : 'rowSHOWWIDTH',
  //     }, {
  //       title: 'WIDTH LABEL',
  //       type: 'text',
  //       data: '',
  //       additional: '',
  //       initialValue : 'Height',
  //       key : 'rowWIDTHLABEL',
  //     }, {
  //       title: 'WIDTH LOCKED',
  //       type: 'checkbox',
  //       data: '',
  //       additional: '',
  //       key : 'rowWIDTHLOCKED',
  //     }, {
  //       title: 'WIDTH LIMITER',
  //       type: 'checked',
  //       data: '',
  //       additional: '',
  //       key : 'rowWIDTHLIMITER',
  //     }, {
  //       title: 'LOWER WIDTH',
  //       type: 'number',
  //       data: '',
  //       additional: '',
  //       note_text: '',
  //       key : 'rowLOWERWIDTH',
  //     }, {
  //       title: 'UPPER WIDTH',
  //       type: 'number',
  //       data: '',
  //       additional: '',
  //       note_text: '',
  //       key : 'rowUPPERWIDTH',
  //     }, {
  //       title: 'SHOW LENGTH',
  //       type: 'checked',
  //       data: '',
  //       additional: '',
  //       note_text: '',
  //       initialValue: 'checked',
  //       key : 'rowSHOWLENGTH',
  //     }, {
  //       title: 'LENGTH LABEL',
  //       type: 'text',
  //       data: '',
  //       additional: '',
  //       initialValue : 'Height',
  //       key : 'rowLENGTHLABEL',
  //     }, {
  //       title: 'LENGTH LOCKED',
  //       type: 'checked',
  //       data: '',
  //       additional: '',
  //       key : 'rowLENGTHLOCKED',
  //     }, {
  //       title: 'LENGTH LIMITER',
  //       type: 'checked',
  //       data: '',
  //       additional: '',
  //       key : 'rowLENGTHLIMITER',
  //     }, {
  //       title: 'LOWER LENGTH',
  //       type: 'number',
  //       data: '',
  //       additional: '',
  //       note_text: '',
  //       key : 'rowLOWERLENGTH',
  //     }, {
  //       title: 'UPPER LENGTH',
  //       type: 'number',
  //       data: '',
  //       additional: '',
  //       note_text: '',
  //       key : 'rowUPPERLENGTH',
  //     }, {
  //       title: 'SHOW QUANTITY',
  //       type: 'checked',
  //       data: '',
  //       additional: '',
  //       note_text: '',
  //       key : 'rowSHOWQUANTITY',
  //     }, {
  //       title: 'DEDUCTIBLE',
  //       type: 'checked',
  //       data: '',
  //       additional: '',
  //       note_text: 'Can have deductions. e.g. walls.',
  //       key : 'rowDEDUCTIBLE',
  //     }, {
  //       title: 'IS OPENING',
  //       type: 'checked',
  //       data: '',
  //       additional: '',
  //       note_text: 'Whether this is an opening, e.g. door, window.',
  //       key : 'rowISOPENING',
  //     }, {
  //       title: 'IS DOOR',
  //       type: 'checked',
  //       data: '',
  //       additional: '',
  //       note_text: 'Whether this is a door.',
  //       key : 'rowISDOOR',
  //     }, {
  //       title: 'IS BRACING',
  //       type: 'checked',
  //       data: '',
  //       additional: '',
  //       note_text: 'Whether this is a bracing.',
  //       key : 'rowISBRACING',
  //     }, {
  //       title: 'IS WET AREA',
  //       type: 'checked',
  //       data: '',
  //       additional: '',
  //       note_text: 'Whether this is a wet area.',
  //       key : 'rowISWETAREA',
  //     }, {
  //       title: 'HAS PITCH FACTOR',
  //       type: 'checked',
  //       data: '',
  //       additional: '',
  //       note_text: 'Can have pitch factor applied, e.g. roof',
  //       key : 'rowHASPITCHFACTOR',
  //     },
  //     {
  //       title : 'STATUS',
  //       type : 'select',
  //       data : [
  //         {
  //           alias : 'active',
  //           value : 'Active - Anyone can use'
  //         },{
  //           alias : 'inactive',
  //           value : 'Inactive - No one can use'
  //         },{
  //           alias : 'unpublished',
  //           value : 'Unpublished - Only certain people can use'
  //         }
  //       ],
  //       initialValue : '1',
  //       additional: '',
  //       note_text : '',
  //       key : 'rowSTATUS',
  //     }, {
  //       title: 'DIMENSION',
  //       type: 'text',
  //       data: '',
  //       additional: '',
  //       note_text: '(legacy, only for import) bitmask (decimal of binary) for dimension and options, use dimension type instead.',
  //       key : 'rowDIMENSION',
  //     }, {
  //       title: 'LOCKED',
  //       type: 'text',
  //       data: '',
  //       additional: '',
  //       note_text: '',
  //       key : 'rowLOCKED',
  //     }, {
  //       title: 'SPECIAL NOTES',
  //       type: 'text',
  //       data: '',
  //       additional: '',
  //       note_text: '',
  //       key : 'rowSPECIALNOTES',
  //     }, {
  //       title: 'NULL DIMENSION WARNING',
  //       type: 'checked',
  //       data: '',
  //       additional: '',
  //       note_text: '',
  //       initialValue: 'checked',
  //       key : 'rowNULLDIMENSIONWARNING',
  //     },{
  //       title: 'delete ?',
  //       type: 'button',
  //       data: '',
  //       additional: '',
  //       key : 'rowDelete',
  //     }
  //   ],
  //   initialValue: '',
  //   note_text: '',
  //   additional: 'add'
  // },
]
