export default {
  bulkOptions: [
    {
      key: 'delete_bulk',
      field: 'delete_bulk',
      value: 'true',
      label: 'Delete',
      type: 'danger',
      permissionCode: 'estimation.delete_overrideline',
    },
  ],
  exportImportOptions: {
    export: {
      directly: true,
      url_name: 'api:admin:estimation:overrideline:export',
    },
  },
}