import React from 'react'
import moment from "moment";
import Lib from "../../Utils/Lib";
import {camelCase, isString, startCase, isArray, cloneDeep, isEmpty, capitalize, snakeCase, kebabCase, get, toLower, find, isObject } from 'lodash'
import {Icon, Tooltip, Typography} from 'antd';
import ChartMiniarea from "./Components/ChartMiniarea";
import Calender from "./Components/Calender";
import ChartPie from "./Components/ChartPie";
import { allMenu } from "App/Config/Menu"
import ChartRadar from "./Components/ChartRadar";
import TagCloud from "./Components/ChartTagCloud";
import ChartMiniBar from "./Components/ChartMiniBar";
import ChartTimeline from "./Components/ChartTimeline";
import CardStyle from "./Components/CardStyle";
import { Urls } from "App/Config";
import validate from "validate.js";
import ComponentAutocomplete from "App/Component/Autocomplete";

const { Text } = Typography;

function convertDataIndex(data, fields) {
  let dataConverted = {}
  for (const field of fields) {
    const formKey = snakeCase(field.title)
    if (data.hasOwnProperty(formKey)) {
      dataConverted[field.dataIndex] = data[formKey]
    } else if (data.hasOwnProperty(field.dataIndex)) {
      dataConverted[field.dataIndex] = data[field.dataIndex]
    }
  }
  return dataConverted
}

export default {

  getNexticon(previcon){
    switch (previcon) {
      case 'asscend':
        return 'descend'
      case 'descend':
        return 'no-sort'
      case 'no-sort':
        return 'asscend'
      default:
        break;
    }
  },

  getBulkName(dataRecord, fields) {
    let bulkName = 'name'
    if (dataRecord) {
      let tempBulkName = ''
      for (const values of fields) {
        if (values.required) {
          tempBulkName = values.dataIndex
          break
        }
      }
      if (isObject(dataRecord[tempBulkName])) {
        if (this.getBulkFromObject(dataRecord[tempBulkName])) {
          bulkName = tempBulkName + '.' + this.getBulkFromObject(dataRecord[tempBulkName])
        }
      } else {
        bulkName = tempBulkName
      }
      if (this.getBulkFromObject(dataRecord)) {
        bulkName = this.getBulkFromObject(dataRecord)
      }
    }
    return bulkName
  },

  getAppNameFromModel(model) {
    const modelTitle = capitalize(model).replace(/-/g, ' ')
    const excMenu = Object.keys(allMenu)[0]
    // or this
    // const excMenu = 'rootMenu'
    let indexApp = 0
    for (const app in allMenu) {
      if (app !== excMenu) {
        for (const index in allMenu[app]) {
          if (allMenu[app][index] === modelTitle) {
            return allMenu[excMenu][indexApp]
          }
        }
        indexApp++
      }
    }
    return undefined
  },

  // this function is broken, need repairs
  pushFunc(p, s, action, url, idData, additionTitle, dataIdentifier, referenceDataRequest, loadOtherAPIData, changeData, newTab = false){
    const {location, history} = p
    if (!url) {
      const newState = {
        operation: action,
        fields: p.fields,
        title: location.state.title,
        url: location.state.url,
        urlData: location.state.urlData,
      }
      
      if (newTab) window.open(location.pathname, "_blank");
      else history.replace(location.pathname, newState);
    } else {
      const master = kebabCase(url)
      const app = kebabCase(this.getAppNameFromModel(master))
      let from = []
      if (get(location.state, 'from', []).length > 0) {
        from = [...location.state.from]
      }
      from.push(location.pathname)
      const prevState = JSON.parse(JSON.stringify({ ...location.state, fields: p.fields }))
      const title = additionTitle ? additionTitle : url
      // const extraBack = get(location.state, 'extraBack')
      let storeState = {
        operation: action,
        title: title,
        urlData: kebabCase(url),
        url: kebabCase(url),
        from,
        prevState,
        // extraBack,
      }

      // plans
      // if(url==='plans') {
      //   let urlTarget
      //   let forPlan = location.pathname.split('/')
      //   if (action === 'edit') {
      //     changeData('AnimateEnd', false)
      //     loadOtherAPIData('plans', idData.key)
      //     urlTarget = `/jobs/buildings/${get(forPlan, '[3]')}/plans/${idData.key}/edit`
      //   }
      //   else if(action === 'add')
      //     urlTarget = `/jobs/buildings/${get(forPlan, '[3]')}/plans/add`
      //   else {
      //     history.goBack()
      //     if(Object.keys(this.state.referenceInfo).length === 0)
      //       referenceDataRequest('buildings')
      //     return null
      //   }
      //
      //   storeState.idData = idData
      //   history.push(urlTarget, storeState)
      //   return null
      // }
      // end plans

      if (action === 'edit') {
        let pk = Lib.checkValues({idData}).idData
        let urlTarget = (app ? '/admin/' : '') + app + '/' + master + '/' + pk
        storeState.idData = idData
        if (newTab) window.open(urlTarget, "_blank");
        else history.push(urlTarget, storeState);
      } else {
        let urlTarget = (app ? '/admin/' : '') + app + '/' + master + '/' + action
        if (dataIdentifier && !dataIdentifier.hasOwnProperty('pk')) {
          const tempDataRecord = get(location.state, 'dataRecord')
          const key = tempDataRecord.pk
          let selectedValue = tempDataRecord.label ? 'label' : 'name'
          let isSelectMultiple = false
          const fields = ComponentAutocomplete[camelCase(url)]
          let fieldName = snakeCase(p.title)
          if (fieldName.slice(-1) === 's') {
            fieldName = fieldName.substring(0, fieldName.length - 1)
          }
          if (fields) {
            const nextField = (p.urlName || '').split(':')[3] // field decorator
            const nextUrlData = p.urlData // url key camelCase, like "productGroups"
            const field = fields.find(val => val.data === nextUrlData) // field (object)
            if (field) {
              selectedValue = field.selectedValue
              // fieldName = field.dataIndex
              // di dynamic field menggunakan title di snake_case
              fieldName = snakeCase(field.title)
              if (field.type === 'select_multiple') isSelectMultiple = true
            }
          }
          const label = tempDataRecord[selectedValue]
          if (isSelectMultiple) {
            dataIdentifier = {
              [fieldName]: [{
                pk: key,
                [selectedValue]: label,
              }]
            }
          } else {
            dataIdentifier = {
              [fieldName]: {
                pk: key,
                [selectedValue]: label,
              }
            }
          }
        }
        storeState.dataIdentifier = dataIdentifier
        if (newTab) window.open(urlTarget, "_blank");
        else history.push(urlTarget, storeState);
      }
    }
  },

  generateMultipleSelectValues(r, toApi=false){
    let a = []
    if(toApi) {
      for (const i of r) {
        a.push(i.key)
      }
    }
    else {
      if (isArray(r))
        for (const i of r) {
          a.push({
            key: i.pk,
            label: i.name ? i.name : i.label
          })
        }
    }
    return a
  },

  setDataForm(form, data, os){
    if(!isArray(os)) return

    let s = {}
    for(const r in data){
      if(data[r] !== undefined) {
        if(data[r] !== null) {
          if (isObject(data[r]) && !isArray(data[r]) && r !== 'file') {
            s[r] = {key: data[r].pk, label: get(data[r], 'name', data[r].label)}
          } else {
            if(isArray(data[r]) && !isEmpty(data[r])) s[r] = this.generateMultipleSelectValues(data[r])
            else if(!isArray(data[r])) {
              const type = find(os, { type: "select_multiple_static" })
              if(type && data[r] && type.dataIndex === r) s[r] = data[r].split(',')
              else s[r] = data[r]
            }
          }
        }
      }
    }
    return form.setFieldsValue(s)
  },

  reFormatFile(data){
    let res = data
    if (res.file)
      res.file = [
        {
          uid:"rc-upload-1579000609312-3",
          lastModified:1575661908563,
          lastModifiedDate:res.modified,
          name:res.file_name,
          dummy: true,
          size:19929,
          type:"image/jpeg",
          percent:0,
          originFileObj:
            {
              name: res.file_name,
              uid:"rc-upload-1579000609312-3"
            }
        }
      ]
    return res
  },

  setFileImg(file, keymap, init=false){
    let fileList = file
    keymap = {
      uid: 'pk',
      name: 'file_name',
      status: 'status',
      url: 'file'
    }
    let s = []
    if(file) {
      if (!validate.isArray(file) && validate.isObject(file))
        fileList = [file]
      for (const f of fileList) {
        s.push(
          {
            uid: this.generateUID(f[keymap.uid]),
            name: f[keymap.name],
            status: f[keymap.status],
            url: f[keymap.url],
            initial: init
          }
        )
      }
    }
    return s
  },

  generateUID(key){
    let k = Math.floor(Math.random() * 100)
    if(key)
      k = key
    return k
  },

  getBulkFromObject(bulkNameObj) {
    const listPrimaryBulk = ['label', 'name', 'user']
    for (const value of listPrimaryBulk) {
      if (bulkNameObj[value]) {
        return value
      }
    }
    return undefined
  },

  overviewFirstContent(name, data, originData){
    switch (name) {
      case 'pie':
        return <ChartPie pieOriginData={originData} data={data} />
      case 'radar':
        return <ChartRadar radarOriginData={originData} data={data} />
      case 'timeline':
        return <ChartTimeline timelineOriginData={originData} data={data} />
      default:
        break;
    }
  },

  statisticFirstContent(name, data, tags, defaultprops){
    switch (name) {
      case 'tagcloud':
        return <TagCloud tags={tags} newdata={data} />
      case 'cardstyle':
        return <CardStyle tags={tags} data={data} defaultprops={defaultprops} />
      default:
        break;
    }
  },

  overviewLastContent(name, data, chartData){
    switch (name) {
      case 'miniarea':
        return <ChartMiniarea chartData={chartData} data={data} />
      case 'calender':
        return <Calender chartData={chartData} data={data} />
      default:
        break;
    }
  },

  statisticLastContent(name, data, chartData){
    switch (name) {
      case 'minibar':
        return <ChartMiniBar chartData={chartData} data={data} />
      case 'calender':
        return <Calender chartData={chartData} data={data} />
      default:
        break;
    }
  },

  masterUrls(url){
    let pUrls = Urls[camelCase(url)+"-read"]
    let appLabel = toLower(camelCase(pUrls.split('/')[2]))
    let model = toLower(camelCase(pUrls.split('/')[3].substring(0, pUrls.split('/')[3].length - 1)))
    return {app_label: appLabel, model: model}
  },

  getIcon(iconSorting, idx, sortChange, title){
    return(
      <span
        style={{overFlow: "hidden", whiteSpace: "nowrap"}}
      >
        <font
          style={{cursor: "pointer"}}
          onClick={()=>sortChange(iconSorting[idx].name)}>
          {startCase(title)}
        </font>
        <span
          className="column-sorter-x"
          onClick={()=>sortChange(iconSorting[idx].name)}
          style={{ cursor: "pointer"}}
        >
          <div
            title="Sort"
            className="sorter-inner-x"
          >
            <Icon
              type="caret-up"
              style={{color: iconSorting[idx].sorted=== "asscend" ? "#1890ff" : "#989898"}}
              className="anticon anticon-caret-up sorter-up-x"
            />
            <Icon
              type="caret-down"
              style={{color: iconSorting[idx].sorted=== "descend" ? "#1890ff" : "#989898"}}
              className="anticon anticon-caret-down sorter-down-x"
            />
          </div>
        </span>
        {iconSorting[idx].priority > 0 &&
            <Tooltip placement="right" title="Priority sorting">
              <Text
                style={{marginLeft: '10px', cursor: 'pointer'}}
                onClick={()=>sortChange(iconSorting[idx].name, true)}
                code
              >
                {iconSorting[idx].priority}
              </Text>

            </Tooltip>}
      </span>
    )
  },

  getValuesWithFile(values, urlData, fields = []) {
    let newValues = new FormData()
    urlData = camelCase(urlData)
    if (urlData === 'exportImportSessions') {
      const fileType = 'file'
      for (let index in values) {
        if (values[index]) {
          if (index === fileType) {
            if (values[index][0] && values[index][0].originFileObj) {
              newValues.append(index, values[index][0].originFileObj)
            }else {
              newValues.append(index, '')
            }
          }
        }
      }
      Lib.checkValues(values)
      for (let index in values) {
        if (values[index]) {
          if (index !== fileType) {
            newValues.append(index, values[index])
          }
        }
      }
    } else if (urlData === 'buildingTypes' || urlData === camelCase('module-job-buildingType') || urlData === camelCase('module-job-buildingType-detail')|| urlData === 'moduleJobBuildingTypeHistoryDetail') {
      const fileType = 'icon'
      for (let index in values) {
        if (values[index]) {
          if (index === fileType) {
            if (values[index][0] && values[index][0].originFileObj) {
              newValues.append(index, values[index][0].originFileObj)
            }
          }
        }
      }
      Lib.checkValues(values)
      for (let index in values) {
        if (values[index]) {
          if (index !== fileType) {
            newValues.append(index, values[index])
          }
        }
      }
    } else if (urlData === 'staffLeaves') {
      newValues = values
      for (let index in newValues) {
        if (moment.isMoment(newValues[index])) {
          newValues[index] = moment(newValues[index]).format("YYYY-MM-DD")
        }
      }
      Lib.checkValues(newValues)
    } else if (urlData === "buildingTypes") {
      const fileType = 'icon_file'
      for (let keys in values) {
        if (values[keys]) {
          if (keys === fileType) {
            newValues.append(keys, values[keys].file.originFileObj)
          }
        }
      }
      Lib.checkValues(values)
      for (let keys in values) {
        if (values[keys]) {
          if (keys !== fileType) {
            newValues.append(keys, values[keys])
          }
        }
      }
    }else {
      newValues = values
      if (urlData === 'merchants' || urlData === 'moduleMerchantMerchant' || urlData === 'moduleMerchantMerchantHistoryDetail') 
        newValues.email = (values.email || []).join(',')
      Lib.checkValues(newValues)

      // ubah empty value ke specific empty value
      for (const field of fields) {
        if (Object(newValues).hasOwnProperty(field.dataIndex)) {
          if (Object(field).hasOwnProperty('emptyValue')) {
            if (isEmpty(newValues[field.dataIndex])) {
              newValues[field.dataIndex] = field.emptyValue
            }
          }
        }
      }
    }

    return newValues
  },

  getValuesWithFileV2(values, fields, arrStack = [0]) {
    let formData = new FormData()
    let valConverted = values, stack = 0

    valConverted = convertDataIndex(values, fields)

    const fileTypes = ['file', 'fileImg']
    let excDataIndex = []

    for (const field of fields) {
      for (const fileType of fileTypes) {
        if (field.type === fileType) {
          if (valConverted.hasOwnProperty(field.dataIndex)) {
            const cvStack = arrStack[stack] || 0
            excDataIndex.push(field.dataIndex)
            // Jika tidak ada originFileObj, maka tidak perlu dimasukkan ke body
            if (get(valConverted[field.dataIndex], [cvStack, 'originFileObj'])) {
              formData.append(field.dataIndex, valConverted[field.dataIndex][cvStack].originFileObj)
              stack++
            }
            break
          }
        }
      }
    }
    Lib.checkValues(valConverted)
    for (const key in valConverted) {
      let willSave = true
      for (const exc of excDataIndex) {
        if (exc === key) {
          willSave = false
        }
      }
      if (willSave) {
        if (!(valConverted[key] === undefined || valConverted[key] === null)) {
          formData.append(key, valConverted[key])
        }
      }
    }

    return formData
  },

  deleteKeys(obj, excKeys = []) {
    let willRemove = []
    for (const tempKey in obj) {
      willRemove.push(tempKey)
      for (const value of excKeys) {
        if (value === tempKey) {
          willRemove.pop()
          break
        }
      }
    }
    for (const value of willRemove) {
      delete obj[value]
    }
  },

  getSelectedObjectSQ(dataIndex, columns=[]) {
    for (const index in columns) {
      if (columns[index].dataIndex === dataIndex) {
        const selectedKey = columns[index].selectedKey
        const selectedValue = columns[index].selectedValue
        return ([selectedKey, selectedValue])
      }
    }
    return []
  },

  getSubheader(e) {
    let masterHasSubheader = [
      {
        name: 'branches',
        sub: 'jobQueues',
        bulk_request: false,
        add: true,
        edit: true,
        permissions: {
          bulk_delete: false,
          bulk_url: '',
          initialrow: 2,
          add: false,
          rowfixed: true
        },
        rules: {
          button: {
            type: {
              add: 'default',
              edit: 'default'
            },
            size: 'medium'
          },
          input: {
            size: 'medium'
          }
        }
      }
    ]
    return find(masterHasSubheader, {name: e})
  },

  isExist(data, key, value){
   return  data.find(e => e[key] === value)
  },

  haveNoErrorBulk(data, r){
    let subheaderData = cloneDeep(data)
    let p;
    let s = []
    for (const ov of subheaderData){
      if(!this.isEmptyform(ov.form))
        ov.form.validateFields((err, val)=>{
          if(r && r.permissions.duplicate) {
            if(s.find(e => e === get(val, r.permissions.duplicate.dataIndex))) {
              // message.destroy()
              p = true
              ov.form.setFields({
                [r.permissions.duplicate.name]: {
                  value: get(val, r.permissions.duplicate.name),
                  errors: [new Error(r.permissions.duplicate.message)],
                },
              })
            }
          s.push(get(val, r.permissions.duplicate.dataIndex))
          }
          if (err)
            p = true;
        })
    }
    if(p)
      return false
    return true
  },

  cvResponseData(response) {
    const type = ['file', 'icon']
    for (let value of type) {
      if (response.data && response.data[value]) {
        let attachment = {
          uid: -1,
          name: response.data[value].file_name,
          status: 'done',
          url: response.data[value].file,
        }
        response.data[value] = [attachment]
      }
    }
  },

  clearEmptyArrayForm(data){
    let r = Object.assign(data)
    for(const c in r){
      if(this.isEmptyform(r[c].form)){
        r[c].is_empty = true
      }
    }
    return r
  },

  storeState(tempState, props) {
    let {state} = props.location
    const matchId = get(props.match, ['params', 'id'])
    if (state && state.from && state.from.length > 0) {
      let fieldName = snakeCase(state.title)
      // if (fieldName.slice(-1) === 's') {
      //   fieldName = fieldName.substring(0, fieldName.length - 1)
      // }
      const previousPath = state.from[state.from.length - 1]
      // Khusus ----------------------------
      if (!get(state, 'prevState.fields')) {
        props.history.push(previousPath)
        return
      }
      // -----------------------------------
      const keys = this.getSelectedObjectSQ(
        fieldName,
        state.prevState.fields,
      )
      let fieldValue = {
        [keys[0]]: tempState[keys[0]],
        [keys[1]]: tempState[keys[1]],
      }
      if (!fieldValue[keys[1]]) {
        fieldValue[keys[1]] = tempState[keys[0]]
      }
      let dataRecord = {
        [fieldName]: fieldValue,
      }

      const fieldType = this.getType(fieldName, state.prevState.fields)
      //const fieldType = this.getType(fieldName, state.prevState.insertColumns)

      // for select_multiple
      if (fieldType === 'select_multiple') {
        dataRecord = []
        if (state.prevState.dataRecord && state.prevState.dataRecord[fieldName]) {
          dataRecord = dataRecord.concat(state.prevState.dataRecord[fieldName])
          if (matchId === 'add') {
            dataRecord = dataRecord.concat([fieldValue])
          } else {
            for (let i = 0; i < dataRecord.length; i += 1) {
              if (dataRecord[i].pk === fieldValue.pk) {
                dataRecord[i] = fieldValue
                break
              }
            }
          }
          dataRecord = {
            [fieldName]: dataRecord,
          }
        } else {
          dataRecord = {
            [fieldName]: [fieldValue]
          }
        }
      }

      if (state.prevState && state.prevState.dataRecord) {
        for (const property in state.prevState.dataRecord) {
          if (property !== fieldName) {
            dataRecord[property] = state.prevState.dataRecord[property]
          }
        }
      }
      state.from.pop()
      // let extraBack = 1
      // if (state.extraBack) {
      //   extraBack += state.extraBack
      // }

      const storeState = {
        ...props.location.state.prevState,
        dataRecord,
        from: state.from,
        // extraBack,
      }
      props.history.replace(previousPath, storeState)
    }
  },

  // param url without domain: '/module/app/master/?query' except product and branches
  // just for admin module
  getMasterUrl(url = undefined, isExcept = false) {
    if (url) {
      let data = url.split('/').filter(word => word)
      let tempUrl = ''
      if (data.length === 1) {
        return '/' + data[0]
      } else if (data[0] === 'admin') {
        for (let i = 0; i < 3; i++) {
          if (data[i] !== 'add') {
            tempUrl += '/' + data[i]
            if (isExcept) return tempUrl // sorry :(
          }
        }
        return tempUrl
      } else {
        data.pop()
        return `/${data.join('/')}`
      }
    }
    return url
  },

  getMasterRelation(pathName, index = 0) {
    if (pathName) {
      let masterName = pathName.split('/').filter(word => word)
      if (masterName[index]) {
        return masterName[index]
      }
    }
    return undefined
  },

  getIndex(value, arr){
    let res = -1
    if(arr.reference_by)
      res = arr.reference_by.findIndex(e => e.label.toLowerCase() === value)
    if(res === -1) {
      if (arr.reference_to) {
        res = arr.reference_to.findIndex(e => e.label.toLowerCase() === value)
        if (res !== -1)
          return res + 1000
      }
    }
    return res
  },

  // parameter objValue must be object of title and value
  isChange(dataRecord, objValue) {
    let willChange = true
    if (dataRecord && dataRecord[objValue.title]) {
      for (const keys in dataRecord) {
        if (keys === objValue.title) {
          if (dataRecord[keys] === objValue.value) {
            willChange = false
          }
          break // for single value
        }
      }
    } else if (!objValue.value) {
      willChange = false
    }
    return willChange
  },

  setActivetab(e, changeData, p){
    let usertester = startCase(this.getMasterRelation(p.location.pathname, 4))
    if (e === -1) {
      e = -1
      let urltab = p.match.url
      p.history.replace(urltab, {
        ...p.location.state,
        idData: p.match.params.id
      });
    } else if (usertester) {
      let urltab = p.match.url
      p.history.replace(urltab+'/'+this.getMasterRelation(p.location.pathname, 4), {
        ...p.location.state,
        idData: p.match.params.id
      });
    }
    changeData({ tabindex: e })
  },

  getType(dataIndex, columns) {
    for (const index in columns) {
      if (columns[index].dataIndex === dataIndex) {
        return columns[index].type
      }
    }
    return []
  },

  // ex: pathName = '/app/master/id/table-related/addition
  // length = 3 ==> '/app/master/id
  convertUrl(pathName, length = 2) {
    if (pathName) {
      let splitPath = pathName.split('/').filter(word => word)
      const splitPathLength = splitPath.length + 1
      let newUrl = ''
      if (splitPathLength >= length) {
        let index = 0
        for (const value of splitPath) {
          if (index < length) {
            newUrl += '/' + value
            index++
          }
        }
        return newUrl
      }
    }
    return undefined
  },

  AarrayObjectToString(data, key){
    for(let i of data){
      for(let o in i.data){
        if(typeof i.data[o] === 'object')
          i.data[o] = i.data[o][key]
      }
    }
    return data
  },

  // send objKey{selectedKey, selectedValue}
  // if you dont by reference
  cvSQ(objSelectQuery, dataIndex, objKey, props) {
    if (objKey) {
      // will return without change original variable
      objSelectQuery = {
        [objKey.selectedKey]: objSelectQuery.key,
        [objKey.selectedValue]: objSelectQuery.label,
      }
      return objSelectQuery
    } else {
      if (isArray(objSelectQuery)) {
        // will change original variable
        const keys = this.getSelectedObjectSQ(dataIndex, props.location.state.fields)
        for (const index in objSelectQuery) {
          objSelectQuery[index][keys[0]] = objSelectQuery[index].key
          objSelectQuery[index][keys[1]] = objSelectQuery[index].label
          // Please dont delete keys of default {key, label}
        }
        return true
      } else {
        const keys = this.getSelectedObjectSQ(dataIndex, props.location.state.fields)
        objSelectQuery[keys[0]] = objSelectQuery.key
        objSelectQuery[keys[1]] = objSelectQuery.label
        this.deleteKeys(objSelectQuery, keys)
        return true
      }
    }
  },

  formRules(rules, data){
    if(rules.must_sent) {
      for (let hs of rules.must_sent) {
        if (!data[hs.name]){
          data[hs.name] = hs.default_value
        }
      }
    }
    return null
  },

  jsonRequire(subheaderData, url, r, cloneSD){
    let storage = []
    for(const x of subheaderData){
      let f = {}
      if(!this.isEmptyform(x.form)) {
        x.form.validateFields((err, s) => {
          Lib.checkValues(s)
          for (const o in s) {
            if(isArray(s[o])) {
              let st = this.generateMultipleSelectValues(s[o], true)
              f[o]= st
            }
            else if(s[o] && typeof s[o] === 'object') {
              if(!get(s[o], '[0].dummy')) {
                if(get(s[o], 'file.status') === 'removed')
                  f[o] = ''
                else
                  f[o] = s[o].key
              }
            }else if((s[o] !== undefined) && !isArray(s[o])){
              f[o] = s[o]
            }
          }
          let key = cloneSD.find(e => e.key === x.pk)
          if(key)
            f.pk = key.pk
          let identifier = [r.data.pk]
          if(!url.identifier.is_array)
            identifier = r.data.pk
          f[url.identifier.name] = identifier
        })
        const ESL = this.isExist(cloneSD, 'key', x.pk)
        let action = ESL ? "-update" : "-create"
        let method = ESL ? "put" : "post"
        if(url.form_rules)
          this.formRules(url.form_rules, f)
        storage.push({pk: x.pk, disk: f, action: action, touched: x.form.isFieldsTouched(), method: method, esl: ESL})
      }
    }
    return storage
  },

  arrayFormData(subheaderData, url, r, cloneSD){
    if(get(url, 'permissions.payload')){
      return this.jsonRequire(subheaderData, url, r, cloneSD)
    }
    let storage = []
    for(const x of subheaderData){
      let f = new FormData()
      if(!this.isEmptyform(x.form)) {
        x.form.validateFields((err, s) => {
          for (const o in s) {
            if(s[o] && s[o].file instanceof File ) {
              f.append(o, s[o].fileList[0].originFileObj)
            }
            else if(isArray(s[o])) {
              let st = this.generateMultipleSelectValues(s[o], true)
              f.append(o, st)
            }
             else if(s[o] && typeof s[o] === 'object') {
              if(!get(s[o], '[0].dummy')) {
                if(get(s[o], 'file.status') === 'removed')
                  f.append(o, '')
                else
                  f.append(o, s[o].key)
              }
            }else if(s[o] || s[o] === false){
              f.append(o, s[o])
            }else if(url.sub === 'plans' && o === 'link') {
              if(!s.link)
                f.append('link', '')
            }
          }
          let charSliced = -1
          if (url.name === 'branches') charSliced = -2
          f.append(snakeCase(url.name).slice(0, charSliced), r.data.pk)
        })
        const ESL = this.isExist(cloneSD, 'key', x.pk)
        let action = ESL ? "-update" : "-create"
        let method = ESL ? "put" : "post"
        storage.push({pk: x.pk, disk: f, action: action, touched: x.form.isFieldsTouched(), method: method, esl: ESL})
      }
    }
    return storage
  },

  decrementVal(async_field, form, exclude){
    if(async_field) {
      let eVal = form.getFieldValue(async_field)
      if(!isEmpty(eVal)) {
        let e = eVal.filter(i => i.key !== exclude.pk)
        form.setFieldsValue({[async_field]: e})
      }
    }
    return null
  },

  sessionMaster(value, name, p, onfilter, filter) {
    const { allowEmpty = true } = filter || {}
    let urlData = p.url_name
    if(p.reference)
      urlData = urlData +'-'+ snakeCase(get(p, 'index.label'))
    let res;
    let fp = localStorage.getItem(urlData)
    if(!fp)
      fp = []
    else
      fp = JSON.parse(fp)
    let checkData = fp.findIndex(item => item.name===name)
    if(checkData=== -1) {
      checkData = fp.push({name: name, data: value}) - 1
    }else{
      fp[checkData].data=value ? value : []
    }
    if (isEmpty(value) && !allowEmpty && fp.splice)
      fp.splice(checkData, 1)

    if(onfilter){
      Lib.windowStop();
      localStorage.setItem(urlData, JSON.stringify(fp))
      res = true
    }else {
      localStorage.setItem(urlData, JSON.stringify(fp))
    }
    return res
  },

  isEmptyform(form){
    if(!form)
      return true
    const value = form.getFieldsValue()
    for (const i in value){
      if(value[i]) {
        return false
      }
    }
    return true
  },

  forceOrder(name, arr){
    const data = []
    if(!isArray(arr) || !isString(name)) return new Error('arr must be Array and name must be a string')
    const named = name.split('.')[0]
    let isExists = false
    for(const i of cloneDeep(arr)){
      if(i.priority)
        i.priority = i.priority + 1
      if(i.name !== named)
        data.push(i)
      else
        isExists = true
    }
    if(isExists){
      data.unshift({ name: named, sorted: 'asscend', priority: 1 })
      return data
    }
    return arr
  }

}

