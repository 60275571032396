import React from 'react'
import { BasePreDetail } from 'App/Screens/SubModules/Component'
import PageDetail from './Detail'
import Add from './Add'

class ExtraChargeManagement extends BasePreDetail {
  constructor(props) {
    super(props)

    this.appName = 'jobs'
    this.modelName = 'extracharge'
  }

  getPageAdd = () => <Add {...this.props} />

  getPageDetail = () => <PageDetail {...this.props} currentPermissionSet={this.state.currentPermissionSet} />

}

export default ExtraChargeManagement