import React from 'react'
import { Button, Card, Col, Icon, message, Popover, Row } from 'antd'
import { cloneDeep, get } from 'lodash'
import GroupList from './List'
import GroupContents from './Contents'
import SectionSubsections from './Sections'
import ModalDelete from './ModalDelete'
import { Lib, RequestV2 as Request } from 'App/Utils'

const DEFAULT_FORM = {
  name: null,
  status: 'active',
  order: null,
  indentation: 0,
}
const WIDTH_SUBSECTION = '262px' // '235px' // '9rem'
const WIDTH_LIST = '0px' // '225px'

class SetsGroup extends React.Component {
  constructor(props) {
    super(props)

    this.title = 'Prefill Group'
    this.urlKeyList = 'prefillSetGroups-read'
    this.urlKeyDetail = 'prefillSetGroups-update'
    this.urlKeyChildList = 'prefillSets-autocomplete'
    this.labelChild = 'Prefill Sets'
    this.keyChild = 'prefill_sets'
    this.isOverrideSet = false
    this.state = {
      selectedGroups: {
        keys: [],
        obj: {}, // detail of selected keys
      }
    }

    this.setDefaultExpandedKeys(props.location.search)
    if (props.match.params.id === 'add') {
      const keyAdd = btoa(new Date().getTime())
      this.state.selectedGroups = {
        keys: [keyAdd],
        obj: {[keyAdd]: { ...DEFAULT_FORM, [this.keyChild]: [], key: keyAdd }}
      }
    } else if (props.match.params.id) {
      const { id } = props.match.params
      this.state.selectedGroups = {
        keys: [props.match.params.id],
        obj: {
          [id]: {
            pk: id,
            [this.keyChild]: [],
          }
        }
      }
    }
  }

  setDefaultExpandedKeys = (search) => {
    // search.substring(1) = remove "?" like in "?a=1&b=2"
    try {
      this.defaultExpandedKeys = JSON.parse('{"' + decodeURI(search.substring(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
    } catch (e) {
      this.defaultExpandedKeys = null
    }
  }

  infoContent = () => (
    <div>
      <ol style={{ paddingLeft: '20px', marginBottom: 0 }}>
        <li>
          <Row type='flex' align='middle' gutter={[6, 6]}>
            <Col>Card with color</Col>
            <Col><div style={{ height: '10px', width: '16px', backgroundColor: '#97cdff' }} /></Col>
            <Col>is New Unsaved {this.title}.</Col>
          </Row>
        </li>
        <li>
          <Row type='flex' align='middle' gutter={[6, 6]}>
            <Col>Card with color</Col>
            <Col><div style={{ height: '10px', width: '16px', backgroundColor: '#fff0e7' }} /></Col>
            <Col>has Unsaved Changes.</Col>
          </Row>
        </li>
        <li>
          <Row type='flex' align='middle' gutter={[6, 6]}>
            <Col>Card with color</Col>
            <Col><div style={{ height: '10px', width: '16px', backgroundColor: '#fafafa' }} /></Col>
            <Col>no Unsaved Changes.</Col>
          </Row>
        </li>
      </ol>
    </div>
  )

  getSubsections = () => (
    <Col style={{ width: WIDTH_SUBSECTION }}>
      <SectionSubsections
        ref={this.onRefSections}
        title={this.title}
        // onSelect={this.onSelectSubsection}
        urlKeyGroupList={this.urlKeyList}
        defaultExpandedKeys={this.defaultExpandedKeys}
        onSelectGroup={this.onSelectGroup}
        onAddGroup={this.onAddGroupInit}
        selectedObj={this.state.selectedGroups.obj} // merusak PureComponent :(
        onDeleteGroup={this.onShowDeleteGroup}
      />
    </Col>
  )

  onRefSections = (ref) => {
    this._sections = ref
  }

  onSelectSubsection = (subsectionId) => {
    if (this._list) this._list.reload({ subsection: subsectionId })
  }

  // when click add in specific subsection. Like onCreateForm
  onAddGroupInit = (subsection, section) => {
    const keyAdd = btoa(new Date().getTime())
    this.setState(
      prevState => ({
        selectedGroups: {
          keys: [keyAdd, ...prevState.selectedGroups.keys],
          obj: {
            [keyAdd]: {
              ...DEFAULT_FORM,
              [this.keyChild]: [],
              key: keyAdd,
              subsection,
              section,
            },
            ...prevState.selectedGroups.obj
          }
        }
      })
    )
  }

  getSetsGroupList = () => (
    <Col style={{ width: WIDTH_LIST }}>
      <GroupList
        ref={ref => {this._list = ref}}
        urlKey={this.urlKeyList}
        onSelect={this.onSelectGroup}
        selectedGroups={this.state.selectedGroups}
      />
    </Col>
  )

  onRefContents = (ref) => {
    this._contents = ref
  }

  getContents = () => (
    <Col style={{ width: `calc(100% - ${WIDTH_LIST} - ${WIDTH_SUBSECTION})` }}>
      <GroupContents
        ref={this.onRefContents}
        urlKeyList={this.urlKeyList}
        urlKeyDetail={this.urlKeyDetail}
        urlKeyChildList={this.urlKeyChildList}
        isOverrideSet={this.isOverrideSet}
        labelChild={this.labelChild}
        keyChild={this.keyChild}
        selectedGroups={this.state.selectedGroups}
        onCloseGroup={this.onDeselectGroup}
        onChangeGroup={this.onChangeGroup}
        onDeleteGroup={this.onShowDeleteGroup}
      />
    </Col>
  )

  onSelectGroup = (group) => {
    const { selectedGroups } = this.state
    const groupIsExist = selectedGroups.keys.find(key => `${key}` === `${group.pk}`)
    if (!groupIsExist) {
      this.setState({
        selectedGroups: {
          keys: [group.pk, ...selectedGroups.keys],
          obj: { ...selectedGroups.obj, [group.pk]: group }
        }
      })
    }
  }

  onDeselectGroup = (group) => {
    const { selectedGroups } = this.state
    const groupIndex = selectedGroups.keys.findIndex(key => `${key}` === `${group.pk}` || key === group.key)
    if (groupIndex !== -1) {
      this.setState({
        selectedGroups: {
          keys: selectedGroups.keys.filter((val, index) => index !== groupIndex),
          obj: { ...selectedGroups.obj, [selectedGroups.keys[groupIndex]]: null }, // untuk obj, tidak di hapus tidak masalah
        }
      })
    }
  }

  onChangeGroup = (group) => {
    const { selectedGroups } = this.state
    const groupIndex = selectedGroups.keys.findIndex(key => `${key}` === `${group.pk}`)
    if (groupIndex !== -1) {
      this.setState({
        selectedGroups: {
          keys: selectedGroups.keys,
          obj: { ...selectedGroups.obj, [group.pk]: group }
        }
      })
    }
  }

  onCreateForm = () => {
    const keyAdd = btoa(new Date().getTime())
    this.setState(
      prevState => ({
        selectedGroups: {
          keys: [keyAdd, ...prevState.selectedGroups.keys],
          obj: {[keyAdd]: { ...DEFAULT_FORM, [this.keyChild]: [], key: keyAdd }, ...prevState.selectedGroups.obj}
        }
      })
    )
  }

  onSave = () => {
    if (this._contents) {
      const values = this._contents.validateFields()
      if (!values) return

      this.countSaving = values.length
      for (let i = 0; i < values.length; i += 1) {
        this.setState({ isSaving: true }, () => {
          const method = values[i].pk ? 'put' : 'post'
          const urlKey = values[i].pk ? this.urlKeyDetail : this.urlKeyList
          const args = values[i].pk ? [values[i].pk] : []
          Request(
            method,
            urlKey,
            {},
            values[i],
            args,
            this.onSaveSuccess,
            this.onSaveFailed,
            { pk: values[i].pk, key: values[i].key, val: values[i] }
          )
        })
      }
    } else {
      message.error('Fatal Error! Failed to get unsaved changes.')
    }
  }

  onSaveSuccess = (response, extra) => {
    this.setState({ isSaving: !!--this.countSaving }, () => {
      message.success(<span>{this.title} "<b>{response.data.name}</b>" has been saved</span>)
      if (this._contents) {
        if (extra.key) {
          // Create success, close and open detail card
          const savedIndex = this.state.selectedGroups.keys.findIndex(val => val === extra.key)
          if (savedIndex !== -1) {
            this.state.selectedGroups.keys.splice(savedIndex, 1, response.data.pk)
            delete this.state.selectedGroups.obj[extra.key]
            this.setState({
              selectedGroups: {
                keys: [...this.state.selectedGroups.keys],
                obj: {...this.state.selectedGroups.obj, [response.data.pk]: response.data},
              }
            })
          }
        } else {
          const refKey = this._contents.getRefKey(extra.pk)
          if (this._contents[refKey]) {
            this._contents[refKey].setState({
              isFieldsTouched: false,
              prefillSetGroup: response.data,
              savedPrefillSetGroup: cloneDeep(response.data),
            })
          }
        }
      }
    })

    if (this._sections && typeof this._sections.getRefKey === 'function') {
      const sectionRef = this._sections[this._sections.getRefKey(response.data.section)]
      if (sectionRef && typeof sectionRef.getRefKey === 'function') {
        const subsectionRef = sectionRef[sectionRef.getRefKey(response.data.subsection)]
        if (subsectionRef && typeof subsectionRef.loadGroups === 'function') {
          subsectionRef.loadGroups()
        }
      }
    }

    // Jika subsection berubah, maka handle source/from subsection
    if (this.state.selectedGroups.obj[response.data.pk]) {
      const { section, subsection } = this.state.selectedGroups.obj[response.data.pk]
      if (`${get(response.data.subsection, 'pk')}` !== `${subsection.pk}`) {
        if (this._sections && typeof this._sections.getRefKey === 'function') {
          const sectionRef = this._sections[this._sections.getRefKey(section)]
          if (sectionRef && typeof sectionRef.getRefKey === 'function') {
            const subsectionRef = sectionRef[sectionRef.getRefKey(subsection)]
            if (subsectionRef && typeof subsectionRef.loadGroups === 'function') {
              subsectionRef.loadGroups()
            }
          }
        }
      }
    }
  }

  onSaveFailed = (error, extra) => {
    Lib.errorMessageHandler(get(error, 'response'))
    this.setState({ isSaving: !!--this.countSaving })

    // Set error
    const key = extra.pk || extra.key
    if (typeof get(this._contents, `_${key}.setError`) === 'function') this._contents[`_${key}`].setError(get(error, 'response.data'))
    // ---------
  }

  // dibutuhkan section, subsection untuk mencari ke ref children dan menghapus list.nya dan menutup card.nya
  // jika di card sudah ada section subsection. Tapi di list kiri, belum ada section, jadi menggunakan obj
  onShowDeleteGroup = (group, obj) => {
    this._modalDelete.show({ ...group, ...obj })
  }

  onDeleteGroup = (group, callback) => {
    Request(
      'delete',
      this.urlKeyDetail,
      {},
      {},
      [group.pk],
      this.deleteSuccess,
      this.deleteFailed,
      { callback, group }
    )
  }

  deleteSuccess = (res, extra) => {
    extra.callback(true)

    // Close the Card
    this.onDeselectGroup(extra.group)

    // Remove list
    if (this._sections && typeof this._sections.getRefKey === 'function') {
      const sectionRef = this._sections[this._sections.getRefKey(extra.group.section)]
      if (sectionRef && typeof sectionRef.getRefKey === 'function') {
        const subsectionRef = sectionRef[sectionRef.getRefKey(extra.group.subsection)]
        if (subsectionRef && subsectionRef.state.groups) {
          subsectionRef.setState(prevState => ({ groups: prevState.groups.filter(group => `${group.pk}` !== `${extra.group.pk}`) }))
        }
      }
    }
  }

  deleteFailed = (error, extra) => {
    Lib.errorMessageHandler(get(error, 'response'))
    extra.callback(false)
  }

  render() {
    const { isSaving } = this.state

    return (
      <Card
        title={
          <Row type='flex' justify='space-between' align='middle' gutter={[12, 12]}>
            <Col>
              <Row type='flex' align='middle' gutter={[12, 12]}>
                {/* <Col>{this.title}</Col> */}
                <Col>
                  <Button type='primary' icon='plus' onClick={this.onCreateForm}>
                    Add {this.title}
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row type='flex' align='middle' gutter={[12, 12]}>
                <Popover trigger='hover' content={this.infoContent()} placement='leftTop'>
                  <Col style={{ cursor: 'pointer', marginRight: '6px' }}>
                    <Icon
                      type='info-circle'
                      style={{ color: 'orange', fontSize: '16px' }}
                    />
                  </Col>
                </Popover>
                <Col>
                  <Button type='primary' icon='save' loading={isSaving} onClick={this.onSave}>
                    Save
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        }
        style={{ borderRadius: '12px', marginBottom: '12px' }}
        bodyStyle={{ paddingTop: '21px' }}
      >
        <Row type='flex' gutter={[12, 12]}>
          {this.getSubsections()}
          {/* {this.getSetsGroupList()} */}
          {this.getContents()}
        </Row>
        <ModalDelete
          ref={ref => {this._modalDelete = ref}}
          onConfirm={this.onDeleteGroup}
          title={this.title}
        />
      </Card>
    )
  }
}

export default SetsGroup