import React from "react";
import {Icon, Tabs, Card, Form, Button, message} from "antd"
import {isObject, isEmpty, cloneDeep} from 'lodash';
import { RequestV2 as Request } from "App/Utils";
import DynamicEditTable from "App/Component/Singlepage/DynamicEditTable";
import styled from "styled-components";
import {Lib} from "App/Utils"
import Lib2 from "App/Component/Singlepage/Lib2";
import ComponentAutocomplete from "App/Component/Autocomplete";
const { TabPane } = Tabs;

class JobQueues extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      field: [],
      loading: true,
      data: [],
      cloneSD: [],
      subheaderData: []
    }
    this.numberMSID = []
    this.children = {
      name: 'branches',
      sub: 'jobQueues',
      identifier: {
        name: 'branches',
        is_array: false
      },
      bulk_request: false,
      add: true,
      edit: true,
      permissions: {
        bulk_delete: false,
        bulk_url: '',
        add: false,
      },
      rules: {
        button: {
          type: {
            add: 'default',
            edit: 'default'
          },
          size: 'default'
        },
        input: {
          size: 'default'
        },
        select: {
          size: 'default'
        }
      },
      fields: ComponentAutocomplete['jobQueues']
    }
  }

  onFailed=(err)=>{
    alert("failed" , err)
  }

  messageCustom = (type, msg) => {
    if (type !== 'loading') {
      message.destroy();
      this.setLoading(false);
    } else {
      this.setLoading(true);
    }
    return (
      message[type](msg)
    )
  };

  setLoading = (val) => {
    this.setState({loading: val})
  };

  onSuccessCreate =(r, extra)=>{
    let cloneSD = [...this.state.cloneSD]
    this.numberMSID.push({hasError: false})
    let xs = cloneSD.findIndex(e => e.key === extra.key)
    if(xs === -1){
      r.data.key = extra.key
      cloneSD.push(r.data)
      this.setState({cloneSD})
    }
    if(extra.number === this.numberMSID.length){
      message.success('Job queue was updated')
      this.setLoading(false)
      this.numberMSID = []
    }
  }

  multiSendData = (setting, formData) => {
    for (const val of formData) {
      Request(
        val.method,
        `${setting.sub}${val.action}`,
        {},
        val.disk,
        [val.esl && val.esl.pk],
        this.onSuccessCreate,
        this.failedCreate,
        {key: val.pk, number: formData.length}
      )
    }
  }


  failedCreate =(err, extra)=>{
    this.numberMSID.push({hasError: false})
    if(extra.number === this.numberMSID.length){
      message.error('Failed to save')
      this.setLoading(false)
      this.numberMSID = []
    }
  }

  onSave() {
    this.setLoading(true)
    const {subheaderData, cloneSD} = this.state
    const data = cloneDeep(subheaderData)
    const subheader = Lib2.getSubheader('branches')
    const formData = Lib2.arrayFormData(data, subheader, {data: {pk: this.props.branch.pk}}, cloneSD)
    if(!isEmpty(formData)){
      this.multiSendData(subheader, formData)
    }else {
      this.setLoading(false)
    }
}

  onSuccess=(response)=>{
    this.setState({ data: response.data, loading: false })
  }

  retrieve=()=> {
    this.request = Request(
      "get",
      "module-branch-detail",
      {},
      {},
      [this.props.match.params.id],
      this.onSuccess,
      this.onFailed
    );
  }

  setThisState = (state, callback) => {
    this.setState(state, callback)
  }

  changeData=(state, data)=>{
    this.setState({[state]: data})
  }

  hasErrors(fieldsError) {
    // Return Boolean
    return Object.keys(fieldsError).some(field => fieldsError[field]);
  }

  componentDidMount() {
    this.setLoading(false)
    // this.setState({
    //   field: ComponentAutocomplete[camelCase("branches")]
    // })
    // this.retrieve()
  }

  componentWillUnmount() {
    Lib.windowStop();
  }

  render() {
    const {loading, cloneSD, subheaderData, field} = this.state
    const {readOnly, userReducer, branch} = this.props

    return (
      <Card title={"Job queue of branch "+branch.name}>
        <Tabs defaultActiveKey="1" tabPosition={"left"}>
          <TabPane tab="Edit" key="1">
          <DynamicEditTable
            readOnly={readOnly}
            allowDelete={false}
            urlTab='jobQueues'
            urlSub='module-branch-refJobQueues-read'
            userReducer={userReducer}
            changeData={this.changeData}
            setThisState={this.setThisState}
            data={subheaderData}
            dataRecord = {{pk: branch.pk}}
            model='jobQueues'
            permissions={this.children}
            urlData='branch'
            cloneSD={cloneSD}
            operation='edit'
          />
          {!readOnly && (
            <StyledButton
              onClick={() => this.onSave()}
              type="primary"
              loading={loading}
            >
              <Icon type="save"/>  Save Job Queues
            </StyledButton>
          )}
          </TabPane>
        </Tabs>
      </Card>
    )
  }
}

export default JobQueues;

const StyledButton = styled(Button)`

  margin-top: 10px;
`;
