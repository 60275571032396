import React, {PureComponent, Fragment} from 'react';
import PropTypes from 'prop-types'
import {Form, Input, InputNumber, Select, Switch, DatePicker, Button, Upload, Icon, Row, Col, Modal, Popover, Tooltip} from 'antd'
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CirclePicker } from 'react-color';
import {FormContext} from "./Context/ContextForm";
import Lib from 'App/Utils/Lib'
import Lib2 from "./Singlepage/Lib2";
import moment from 'moment'
import {get, isEmpty} from 'lodash'
import SelectQuery from 'App/Component/SelectQuery';
import Tableeditable from '../Screens/Job/Buildings/Planshelper/Tableeditable'
import ColorPicker from './ColorPicker'
import Transfer from './Transfer'
import Filter from './DynamicFieldFilter'
import UploadAdapter from '../Screens/SubModules/Products/Product/Management/Notes/UploadAdapter';
import FieldFileS3 from './Fields/FileS3';

const FormItem = Form.Item;
const {TextArea} = Input;
const {Option} = Select;

const imageAllowed = '.jpg, .jpeg, .png, .gif';

function TooltipContainer({ children, tooltip }) {
  return tooltip
    ? <Tooltip trigger="focus" {...tooltip}>{children}</Tooltip>
    : children
}

class DynamicField extends PureComponent {
  constructor(props) {
    super(props)

    this.activeColor = props.dataRecord === undefined ? props.initialValue : (props.dataRecord || '')
    this.state = {
      disabledAdditional: true,
      fileList: []
    };
  }

  changeSQ = (val, initial, form) => {
    const { relations, setFilter, dataIndex } = this.props
    if(setFilter)
      setFilter({ name: dataIndex, data : val })
    if (relations && form) {
      const obj = {}
      for (const index in relations) {
        const relationField = get(this.props, 'allFields', []).find(field => field.dataIndex === relations[index])
        if (relationField) {
          form.setFieldsValue({ [relations[index]]: relationField.initialValue })

          if (relationField.foreignKey)
            obj[relationField.dataIndex] = Lib.checkValues({ [relationField.foreignKey]: val })
          else if (relationField.configParamProps) {
            for (const j in relationField.configParamProps) {
              if (relationField.configParamProps[j].sourceField === dataIndex) {
                const { key, sourceKey } = relationField.configParamProps[j]
                obj[relationField.dataIndex] = { [key]: get(val, sourceKey) }
              }
            }
          }
        }
      }

      if (!isEmpty(obj)) {
        this.props.setParamProps(obj)
      }
    }

    // mengatur id reference to, like /api/table_referenceTo/{{ id }}/table_referenceBy/
    const { referenceBy } = this.props
    if (referenceBy && form && !isEmpty(val)) {
      // const obj = { [referenceBy]: Lib.checkValues({ [this.props.dataIndex]: val }) }
      const obj = Lib.checkValues({ [referenceBy]: val })
      this.props.setArgs(obj)

      form.setFieldsValue({ [referenceBy]: undefined })
    }
    // this.setState({
    //   disabledAdditional: !val
    // })

    this.props.onBlur(val, this.props.dataIndex, this.props.type, null, initial)
  };

  textValidator = (rule, value, callback) => {
    if (value) {
      const {maxLength} = this.props
      if (value.length > maxLength) {
        callback(true)
        return
      }
    }
    callback()
  }

  numberValidator = (rule, value, callback) => {
    if (value) {
      const {minValue, maxValue, isDecimal, decimalPlaces} = this.props
      if (minValue > value) {
        callback(true)
        return
      }
      if (maxValue && maxValue < value) {
        callback(true)
        return
      }
      if (isDecimal !== false) {
        if (decimalPlaces && decimalPlaces > 0) {
          let strValue = value.toString().split('.')
          if (strValue[1].length > decimalPlaces) {
            callback(true)
            return
          }
        }
      } else {
        let strValue = value.toString().split('.')
        if (strValue.length > 1) {
          callback(true)
          return
        }
      }
    }
    callback()
  }

  handleChange = (e) => {
    const {value} = e.target
    if (value) {
      this.hasStrip = true
      if (value.split('.').length > 1) {
        this.hasDot = true
      } else {
        this.hasDot = false
      }
    } else {
      this.hasDot = false
      this.hasStrip = false
    }
  }

  handleKeyDown = (e) => {
    this.onKeyDown(e)
    
    if (this.hasDot && e.key === '.') {
      e.preventDefault()
    } else if (this.hasStrip && e.key === '-') {
      e.preventDefault()
    }
    if (e.key === '.') {
      this.hasDot = true
    } else if (e.key === '-') {
      this.hasStrip = true
    }
  }

  onKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.props.onPressEnter()
    }
  }

  multipleValidator = (rule, value, callback) => {
    if (value && value.length > 0) {
      const { minSelected, maxSelected } = this.props
      if (minSelected && minSelected > value.length) {
        callback(true)
        return
      }
      if (maxSelected && maxSelected < value.length) {
        callback(true)
        return
      }
    }
    callback()
  }

  colorValidator = (rule, value, callback) => {
    if (value) {
      const colorRegex = /^#(?:[0-9a-fA-F]{3}){1,2}$/
      if (colorRegex.test(value)) {
        callback()
      } else {
        callback(true)
      }
    } else {
      callback()
    }
  }

  brightnessValidator = (rule, value, callback) => {
    if (value) {
      this.colorValidator(rule, value, res => {
        if (!res) {
          const count = 3 // r, g, b
          const tempHex = value.replace('#', '')
          const hex = tempHex.length === 3 ? (
            `${tempHex[0]}${tempHex[0]}${tempHex[1]}${tempHex[1]}${tempHex[2]}${tempHex[2]}`
           ) : tempHex
          const colorLength = hex.length / count
          let decimals = 0
          for (let i = 0; i < count; i += 1) {
            const startIndex = i * colorLength
            decimals += parseInt(hex.slice(startIndex, startIndex + colorLength), 16)
          }
          if (decimals > 600) { // rr + gg + bb = 765
            callback(true)
            return
          }
        }
        callback()
      })
    } else {
      callback()
    }
  }

  validatorRetype = (rule, value, callback, form) => {
    if (value) {
      const { matching } = this.props
      if (value !== form.getFieldValue(matching)) {
        return callback(true)
      }
    }
    callback()
  }

  checkOperationView = (additional, disabledAdditional, toggleDrawerSQ, getFieldValue, title, data) => {
    return <Fragment>
      {additional && (additional.split('|')[0] === 'edit') &&
      <Button type={'button'} disabled={disabledAdditional && getFieldValue(Lib.decoratorName(title)) === undefined}
              onClick={() => toggleDrawerSQ('edit', data, getFieldValue(Lib.decoratorName(title)), title)} icon='edit'
              style={{marginRight: '10px'}}/>}
      {additional && ((additional.split('|')[0] === 'add') || (additional && additional.split('|')[1] === 'add')) &&
      <Button type={'button'} onClick={() => toggleDrawerSQ('add', data, null, title)} icon='plus'/>}
    </Fragment>
  };

  checkSpecialOperation = (toggleSQ, index) => {
    return <Fragment>
      <Button
        type={'danger'}
        icon={'close'}
        onClick={() => toggleSQ('activate', index)}/>
    </Fragment>
  }

  setFileList = (e, limit, withalert=true) => {
    let fileList = this.state.fileList
    if (Array.isArray(e)) {
      return e;
    }
    if (limit > 0) {
      limit *= -1
    }
    if (e.file.status === 'removed') {
      fileList = []
    } else {
      fileList = e.fileList
    }
    fileList = fileList.slice(limit)
    if(isEmpty(e.fileList) && e.file.initial && withalert)
      this.props.normFile((isDeleted) => !isDeleted && this.setState({fileList: Lib2.setFileImg(this.props.dataRecord, {}, true)}))
    this.setState({fileList})
    this.props.onBlur(fileList)
    return e && fileList;
  }

  getInput = (getFieldDecorator, getFieldValue, form) => {
    const {disabledAdditional} = this.state;

    const {
      type,
      title,
      data,
      accept,
      dataIndex,
      required,
      note_text,
      dataRecord,
      initialValue,
      operation,
      custom_rule,
      selectedKey,
      selectedValue,
      userReducer,
      additional,
      toggleDrawerSQ,
      hideOnEdit,
      isTable,
      disableChange,
      disabledAll,
      isActive,
      disableAllowClear,
      inputProps,
      renderCustomField,
    } = this.props;

    const dataUpload = {
      // action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
      listType: 'picture',
      accept: imageAllowed,
      beforeUpload: () => false,
    };

    const formItemLayout = {
      labelCol: {span: 7},
      wrapperCol: {span: 15},
    };

    const operationView = operation === 'view';

    switch (type) {
      case 'label': {
        return (
          <Col md={12} sm={24} {...this.props.colProps}>
            {title}
          </Col>
        )
      }
      case 'SelectQuery': {
        const initial = Lib.setSelectValue(dataRecord, selectedKey, selectedValue)
        return (
          <Col md={12} sm={24} {...this.props.colProps}>
            <FormItem span={24} label={title} extra={note_text} {...formItemLayout}>
              <Row type='flex' gutter={[6, 6]} style={{ width: '100%' }}>
                {/* Filter ---------------------------------- */}
                {this.props.filters && (
                  <Col>
                    <Popover
                      trigger={['click']}
                      placement='rightTop'
                      overlayStyle={{ width: '250px' }}
                      title={<span><Icon type='filter' /> Filter</span>}
                      content={
                        <Filter
                          filters={this.props.filters}
                          paramProps={this.props.paramProps}
                          setParamProps={this.props.setParamProps}
                          parentDataIndex={dataIndex}
                          form={form}
                        />
                      }
                    >
                      <Button icon='filter' />
                    </Popover>
                  </Col>
                )}
                {/* Input ---------------------------------- */}
                <Col style={{ flex: 1, width: 'calc(100% - 38px)', minWidth: '170px' }}>
                  {getFieldDecorator(dataIndex, {
                    rules: [{
                      required: required,
                      message: `Please Input ${title}!`
                    }],
                    initialValue: initial,
                    valuePropName: 'value'
                  })(
                    <SelectQuery
                      // allowClear={!required}
                      urlKey={`${data}-autocomplete`}
                      paramProps={this.props.paramProps}
                      placeholder=""
                      userReducer={userReducer}
                      suffixIcon={<Icon type="caret-down"/>}
                      valKey={selectedKey}
                      onChange={(objValue, opt) => {
                        this.changeSQ(objValue && { ...objValue, ...get(opt, 'props.opt') }, initial, form)
                        if (this.props.specialFunction) this.props.specialFunction(objValue, form, operation)
                      }}
                      valLabel={selectedValue}
                      disabled={operationView || (isTable && disableChange) }
                      isActive={isActive}
                      // style={{maxWidth: '71%', marginRight: '10px'}}
                      dropdownClassName={this.props.dropdownClassName} // untuk styling dropdown by class
                      args={[this.props.args]}
                      {...inputProps}
                    />
                  )}
                </Col>
                {/* Addition Button ----------------------- */}
                <Col>
                  {!(operationView || (isTable && disableChange)) && this.checkOperationView(additional, disabledAdditional, toggleDrawerSQ, getFieldValue, title, data)}
                  {(isTable && disableChange) && this.checkSpecialOperation(toggleDrawerSQ, this.props.index)}
                </Col>
              </Row>
            </FormItem>
          </Col>
        );
      }
      case 'select_multiple': {
        const initial = Lib.setSelectValueMultiple(dataRecord, selectedKey, selectedValue)
        return (
          <Col md={12} sm={24} {...this.props.colProps}>
            <FormItem span={24} label={title} extra={note_text} {...formItemLayout}>
              <Row type='flex' gutter={[6, 6]} style={{ width: '100%' }}>
                {/* Filter ---------------------------------- */}
                {this.props.filters && (
                  <Col>
                    <Popover
                      trigger={['click']}
                      placement='rightTop'
                      overlayStyle={{ width: '250px' }}
                      title={<span><Icon type='filter' /> Filter</span>}
                      content={
                        <Filter
                          filters={this.props.filters}
                          paramProps={this.props.paramProps}
                          setParamProps={this.props.setParamProps}
                          parentDataIndex={dataIndex}
                          form={form}
                        />
                      }
                    >
                      <Button icon='filter' />
                    </Popover>
                  </Col>
                )}
                {/* Input ---------------------------------- */}
                <Col style={{ flex: 1, width: 'calc(100% - 38px)', minWidth: '170px' }}>
                  {getFieldDecorator(dataIndex, {
                    rules: [{
                      required: required,
                      message: `Please Input ${title}!`
                    },{
                      validator: this.multipleValidator,
                      message: this.multipleMessage
                    }],
                    initialValue: initial,
                  })(
                    <SelectQuery
                      urlKey={data + '-autocomplete'}
                      mode="multiple"
                      showSearch
                      paramProps={this.props.paramProps}
                      placeholder='Please type to search'
                      userReducer={userReducer}
                      suffixIcon={<Icon type="caret-down"/>}
                      onChange={(arrValue) => this.changeSQ(arrValue, initial, form)}
                      valKey={selectedKey}
                      valLabel={selectedValue}
                      disabled={operationView}
                      style={{marginRight: '10px'}}
                      {...inputProps}
                    />
                  )}
                </Col>
                {/* Addition Button ----------------------- */}
                <Col>
                  {!operationView && this.checkOperationView(additional, disabledAdditional, toggleDrawerSQ, getFieldValue, title, data)}
                </Col>
              </Row>
            </FormItem>
          </Col>
        );
      }
      case 'input_multiple': {
        let email = dataRecord ? dataRecord.split(',') : []
        return (
          <Col md={12} sm={24} {...this.props.colProps}>
            <FormItem span={24} label={title} extra={note_text} {...formItemLayout}>
              {getFieldDecorator(dataIndex, {
                rules: [{
                  required: required,
                  message: `Please Input ${title}!`,
                }],
                initialValue: email,
              })(
                <Select
                  className='text-center'
                  mode="tags"
                  tokenSeparators={[',']}
                  {...inputProps}
                  // dropdownStyle={{display: 'none'}}
                />
              )}
              {!operationView && this.checkOperationView(additional, disabledAdditional, toggleDrawerSQ, getFieldValue, title, data)}
            </FormItem>
          </Col>
        );
      }
      case 'number': {
        const initial = dataRecord === undefined ? (initialValue !== undefined ? initialValue : 0) : dataRecord
        return (
          <Col md={12} sm={24} {...this.props.colProps}>
            <FormItem span={24} label={title} extra={note_text} {...formItemLayout}>
              {getFieldDecorator(dataIndex, {
                rules: [{
                  required: required,
                  message: `Please Input ${title}!`
                }, {
                  validator: this.numberValidator,
                  message: this.numberMessage
                }],
                initialValue: initial,
              })(
                <Input
                  type="number"
                  disabled={operationView}
                  min={0}
                  onKeyDown={this.handleKeyDown}
                  onChange={this.handleChange}
                  step={this.props.step}
                  onBlur={e => {this.props.onBlur(e.target.value, dataIndex, type, form, initial)}}
                  {...inputProps}
                />)}
            </FormItem>
          </Col>
        );
      }
      case 'email': {
        const initial = dataRecord === undefined ? initialValue : dataRecord
        return (
          <Col md={12} sm={24} {...this.props.colProps}>
            <FormItem span={24} label={title} extra={note_text} {...formItemLayout}>
              {getFieldDecorator(dataIndex, {
                rules: [{
                  required: required,
                  message: `Please Input ${title}!`
                }, {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                }, {
                  ...custom_rule // https://ant.design/components/form/#Validation-Rules
                }],
                initialValue: initial,
              })(
                <Input
                  type="email"
                  disabled={operationView}
                  onBlur={e => this.props.onBlur(e.target.value, dataIndex, type, null, initial)}
                  onKeyDown={this.onKeyDown}
                  {...inputProps}
                />
              )}
            </FormItem>
          </Col>
        );
      }
      case 'textarea': {
        const initial = dataRecord === undefined ? initialValue : dataRecord
        return (
          <Col md={12} sm={24} {...this.props.colProps}>
            <FormItem span={24} label={title} extra={note_text} {...formItemLayout} {...this.props.formProps}>
              {getFieldDecorator(dataIndex, {
                rules: [{
                  required: required,
                  message: `Please Input ${title}!`
                }, {
                  ...custom_rule // https://ant.design/components/form/#Validation-Rules
                }],
                initialValue: initial,
              })(
                <TextArea
                  rows={4}
                  disabled={operationView}
                  onBlur={e => this.props.onBlur(e.target.value, dataIndex, type, null, initial)}
                  {...this.props.inputProps}
                />
              )}
            </FormItem>
          </Col>
        );
      }
      case 'checkbox': {
        const initial = dataRecord === undefined ? initialValue === true : dataRecord
        return (
          <Col md={12} sm={24} {...this.props.colProps}>
            <FormItem span={24} label={title} extra={note_text} {...formItemLayout}>
              {getFieldDecorator(dataIndex, {
                rules: [{
                  required: required,
                  message: `Please Input ${title}!`
                }],
                initialValue: initial,
                valuePropName: 'checked'
              })(
                <Switch
                  disabled={operationView}
                  onChange={e => this.props.onBlur(e, dataIndex, type, null, initial)}
                  {...inputProps}
                />
              )}
            </FormItem>
          </Col>
        );
      }
      case 'select': {
        let initial = dataRecord === undefined ? initialValue : dataRecord
        if (!data.find(item => `${item.alias}` === `${initial}`)) initial = undefined
        return (
          <Col md={12} sm={24} {...this.props.colProps}>
            <FormItem span={24} label={title} extra={note_text} {...formItemLayout}>
              {getFieldDecorator(dataIndex, {
                rules: [{
                  required: required,
                  message: `Please Input ${title}!`
                }],
                initialValue: initial,
                validateTrigger: ["onChange", "onBlur"],
              })(<Select allowClear={!disableAllowClear} onChange={e => this.props.onBlur(e, dataIndex, type, null, initial)} disabled={operationView} {...inputProps}>
                {data.map(function (item, i) {
                  return <Option key={i} value={item.alias}>{item.value}</Option>
                })}
              </Select>)}
            </FormItem>
          </Col>
        );
      }
      case 'select_multiple_static': {
        let initial = dataRecord === undefined ? initialValue : dataRecord
        if (initial && typeof initial === 'string') initial = initial.split(',')
        if (!initial) initial = []
        return (
          <Col md={12} sm={24} {...this.props.colProps}>
            <FormItem sm={24} label={title} extra={note_text} {...formItemLayout}>
              {getFieldDecorator(dataIndex, {
                rules: [{
                  required,
                  message: `Please Input ${title}!`
                }],
                initialValue: initial,
              })(
                <Select disabled={operationView} {...this.props.inputProps}>
                  {data.map(opt => <Option key={opt.alias} value={opt.alias}>{opt.value}</Option>)}
                </Select>
              )}
            </FormItem>
          </Col>
        )
      }
      case 'datetime': {
        const initial = dataRecord && moment(dataRecord)
        return (
          <Col md={12} sm={24} {...this.props.colProps}>
            <FormItem span={24} label={title} extra={note_text} {...formItemLayout}>
              {getFieldDecorator(dataIndex, {
                rules: [{
                  required: required,
                  message: `Please Input ${title}!`
                }],
                initialValue: initial,
              })(
                <DatePicker
                  showTime
                  disabled={operationView}
                  onChange={e => this.props.onBlur(e, dataIndex, type, null, initial)}
                  {...inputProps}
                />
              )}
            </FormItem>
          </Col>
        );
      }
      case 'date': {
        const initial = dataRecord && moment(dataRecord, 'YYYY-MM-DD')
        return (
          <Col md={12} sm={24} {...this.props.colProps}>
            <FormItem span={12} label={title} extra={note_text} {...formItemLayout}>
              {getFieldDecorator(dataIndex, {
                rules: [{
                  required: required,
                  message: `Please Input ${title}!`
                }],
                initialValue: initial,
              })(
                <DatePicker
                  disabled={operationView}
                  onChange={e => this.props.onBlur(e, dataIndex, type, null, initial)}
                  {...inputProps}
                />
              )}
            </FormItem>
          </Col>
        );
      }
      case 'file':
        return (
          <Col md={12} sm={24} {...this.props.colProps}>
            <FormItem span={24} label={title} extra={note_text} {...formItemLayout}>
              {getFieldDecorator(dataIndex, {
                rules: [{
                  required: required,
                  message: `Please Input ${title}!`
                }],
                getValueFromEvent: (e) => this.setFileList(e, 1, false),
                initialValue: this.state.fileList,
              })(
                <Upload fileList={this.state.fileList} accept={accept ? accept : ''} beforeUpload={() => false} disabled={operationView} {...inputProps}>
                  <Button>
                    <Icon type="upload"/> Click to Upload
                  </Button>
                </Upload>
              )}
            </FormItem>
          </Col>
        );
      case 'fileImg':
        return (
          <Col md={12} sm={24} {...this.props.colProps}>
            <FormItem span={24} label={title} extra={note_text} {...formItemLayout}>
              {getFieldDecorator(dataIndex, {
                rules: [{
                  required: required,
                  message: `Please Input ${title}!`
                }],
                getValueFromEvent: (e) => this.setFileList(e, 1)
              })(
                <Upload fileList={this.state.fileList} {...dataUpload} disabled={operationView} {...inputProps}>
                  <Button>
                    <Icon type="upload"/> Click to Upload
                  </Button>
                </Upload>
              )}
            </FormItem>
          </Col>
        );
      case 'file_s3':
        return (
          <Col md={12} sm={24} {...this.props.colProps}>
            <FieldFileS3
              form={form}
              formItemProps={{
                span: 24,
                extra: note_text,
                ...formItemLayout
              }}
              recordId={this.props.recordId}
              field={this.props.field}
              title={title}
              required={required}
              disabled={operationView}
              inputProps={inputProps}
              dataIndex={dataIndex}
              record={this.props.dataRecord}
            />
          </Col>
        );
      case "text": {
        const initial = dataRecord === undefined ? initialValue : dataRecord
        return (
          <Col md={12} sm={24} {...this.props.colProps}>
            <FormItem span={24} label={title} extra={note_text} {...formItemLayout}>
              <TooltipContainer tooltip={this.props.tooltip}>
              {getFieldDecorator(dataIndex, {
                rules: [{
                  required: required,
                  message: `Please Input ${title}!`
                }, {
                  validator: this.textValidator,
                  message: this.textMessage
                }],
                initialValue: initial,
              })(
                <Input
                  disabled={operationView || disabledAll === true}
                  onBlur={e => this.props.onBlur(e.target.value, dataIndex, type, null, initial)}
                  onKeyDown={this.onKeyDown}
                  {...inputProps}
                />
              )}
              </TooltipContainer>
            </FormItem>
          </Col>
        );
      }
      case "color": {
        const initial = dataRecord === undefined ? initialValue : dataRecord
        const styles = {}
        if (this.activeColor) {
          styles.color = this.activeColor
        }
        return (
          <Col md={12} sm={24} {...this.props.colProps}>
            <FormItem span={24} label={title} extra={note_text} {...formItemLayout}>
              {getFieldDecorator(dataIndex, {
                rules: [{
                  required,
                  message: `Please Input ${title}`,
                }, {
                  validator: this.colorValidator,
                  message: 'Color is not valid hex color value',
                }, {
                  validator: this.brightnessValidator,
                  message: 'The color chosen is too light'
                }],
                initialValue: initial,
              })(
                <Input
                  disabled={operationView || disabledAll === true}
                  style={{ minWidth: '100px', width: 'calc(100% - 32px - 10px)' }} // 32px is button width, 10px marginLeft
                  onChange={e => this.props.onBlur(e.target.value, dataIndex, type, null, initial)}
                  onKeyDown={this.onKeyDown}
                />
              )}
              {(operationView || disabledAll === true) ? (
                <Button
                  icon='setting'
                  disabled
                  style={{ marginLeft: '10px' }}
                />
              ) : (
                <Popover
                  placement='right'
                  trigger='hover'
                  content={
                    <ColorPicker.Sketch
                      color={dataRecord === undefined ? initialValue : (dataRecord || '')}
                      onChange={(color, event) => {
                        this.activeColor = color.hex
                        this.brightnessValidator(undefined, color.hex, isError => {
                          if (isError) {
                            form.setFields({
                              [Lib.decoratorName(title)]: {
                                value: color.hex,
                                errors: [new Error('The color chosen is too light')]
                              }
                            })
                          } else {
                            form.setFieldsValue({ [Lib.decoratorName(title)]: color.hex })
                          }
                        })
                      }}
                    />
                  }
                >
                  <Button
                    icon='setting'
                    style={{ marginLeft: '10px', ...styles }}
                  />
                </Popover>
              )}
            </FormItem>
          </Col>
        )
      }
      case "table":
        return <Tableeditable {...this.props} />
      case "password": {
        const initial = dataRecord === undefined ? initialValue : dataRecord
        return (
          <Col md={12} sm={24} {...this.props.colProps}>
            <FormItem span={24} style={{display: (operation !== 'add' && hideOnEdit) && 'none'}} label={title}
                      extra={note_text} {...formItemLayout}>
              {getFieldDecorator(dataIndex, {
                rules: [{
                  required: required,
                  message: `Please Input ${title}!`
                }],
                initialValue: initial,
              })(
                <Input.Password
                  autoComplete='new-password'
                  disabled={(operationView || (operation === 'edit' && hideOnEdit))}
                  onBlur={e => this.props.onBlur(e.target.value, dataIndex, type, initial)}/>)}
            </FormItem>
          </Col>
        );
      }
      case "retype-password": {
        const initial = dataRecord === undefined ? initialValue : dataRecord
        return (
          <Col md={12} sm={24} {...this.props.colProps}>
            <FormItem span={24} style={{display: (operation !== 'add' && hideOnEdit) && 'none'}} label={title}
                      extra={note_text} {...formItemLayout}>
              {getFieldDecorator(dataIndex, {
                rules: [{
                  required: required,
                  message: `Please Input ${title}!`
                }, {
                  validator: (rule, value, callback) => this.validatorRetype(rule, value, callback, form),
                  message: 'The passwords do not match'
                }],
                initialValue: initial,
              })(
                <Input.Password
                  // type="password"
                  disabled={(operationView || (operation === 'edit' && hideOnEdit))}
                  onBlur={e => this.props.onBlur(e.target.value, dataIndex, type, null, initial)}/>)}
            </FormItem>
          </Col>
        )
      }
      case "transfer": {
        const initial = dataRecord === undefined ? initialValue : dataRecord
        return (
          <Transfer {...this.props} form={form} initial={initial} />
        )
      }
      case "custom": {
        const initial = dataRecord === undefined ? initialValue : dataRecord
        return (
          <Col md={12} sm={24} {...this.props.colProps}>
            <FormItem
              span={24}
              label={title}
              extra={note_text}
              {...formItemLayout}
            >
              {renderCustomField({
                getFieldDecorator,
                initial,
                required,
                operationView,
                operation,
                hideOnEdit,
                dataIndex,
                dataRecord,
                disabled: operationView || (operation === "edit" && hideOnEdit),
              })}
            </FormItem>
          </Col>
        );
      }
      case 'markdown': {
        const initial = dataRecord === undefined ? initialValue : dataRecord

        return (
          <Col md={12} sm={24} {...this.props.colProps}>
            <FormItem span={24} label={title} extra={note_text} {...formItemLayout}>
              {getFieldDecorator(dataIndex, {
                rules: [{ required, message: `Please Input ${title}!` }],
                initialValue: initial,
                valuePropName: 'data',
                getValueFromEvent: (_, editor) => editor.getData(),
              })(
                <CKEditor
                  editor={ClassicEditor}
                  config={{
                    toolbar: ['heading', '|', 'bold', 'italic', 'link', '|', 'bulletedList', 'numberedList', '|', 'imageUpload', 'blockQuote', '|', 'undo', 'redo'],
                    placeholder: 'Write messages here...',
                  }}
                  onInit={editor => {
                    editor.ui.view.editable.element.spellcheck = false
                    editor.editing.view.change( writer => {
                      writer.setAttribute( 'spellcheck', 'false', editor.editing.view.document.getRoot() );
                    } );
                    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
                      return new UploadAdapter({ loader });
                    }
                  }}
                  {...inputProps}
                />
              )}
            </FormItem>
          </Col>
        )
      }
      default:
        return (
          <Col md={12} sm={24}>
            <FormItem span={24} label={title} extra={note_text} {...formItemLayout}>
              {getFieldDecorator(dataIndex, {
                rules: [{
                  required: required,
                  message: `Please Input ${title}!`
                }, {
                  ...custom_rule // https://ant.design/components/form/#Validation-Rules
                }],
                initialValue: dataRecord,
              })(<Input disabled={operationView}/>)}
            </FormItem>
          </Col>
        )
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.dataRecord) {
      if (nextProps && nextProps.type === 'SelectQuery') {
        this.disableEdit()
      }
    }
    // Menonaktifkan Button edit
    // setelah menekan tombol save and add another
    // Tetapi harus melalui save and continue editing
    if (nextProps.dataRecord !== this.props.dataRecord) {
      // console.log('Props:', this.props)
      // console.log('nextProps:', nextProps)
      if (nextProps.type === 'SelectQuery') {
        if (!nextProps.dataRecord) {
          this.disableEdit()
        }
      }
    }
    // if (!nextProps.dataRecord && !this.props.dataRecord) {
    //   if (nextProps.type === 'SelectQuery') {
    //     console.log('select', nextProps)
    //     console.log('state', this.state)
    //   }
    // }
  }

  disableEdit() {
    this.setState({
      disabledAdditional: true
    })
  }

  componentDidMount() {
    if (this.props.type === 'text') {
      if (this.props.maxLength > 0) {
        this.textMessage = `Cannot more than ${this.props.maxLength} characters`
      }
    } else if (this.props.type === 'number') {
      const minValue = (this.props.minValue || this.props.minValue === 0) ? `min integer value is ${this.props.minValue}, ` : ''
      const maxValue = this.props.maxValue && this.props.maxValue > 0 ? `max integer value is ${this.props.maxValue}, ` : ''
      let rangesValue = minValue + maxValue
      if (minValue && maxValue) {
        rangesValue = `Range of number is ${this.props.minValue} to ${this.props.maxValue}, `
      }
      const isDecimal = !this.props.isDecimal ? 'cannot decimal, ' : ''
      let decimalPlaces = ''
      if (this.props.isDecimal) {
        decimalPlaces = this.props.decimalPlaces && this.props.decimalPlaces > 0 ? `max decimal is ${this.props.decimalPlaces}, ` : ''
      }

      const tempMessage = (rangesValue + isDecimal + decimalPlaces).slice(0, -2)

      this.numberMessage = <span style={{fontSize: '12px'}}>
        Invalid Number
        {tempMessage ? ' or ' : ''}
        {tempMessage}
      </span>
    } else if (this.props.type === 'select_multiple') {
      const minSelected = this.props.minSelected && this.props.minSelected > 0 ? `minimun selected is ${this.props.minSelected}, ` : ''
      const maxSelected = this.props.maxSelected && this.props.maxSelected > 0 ? `maximum selected is ${this.props.maxSelected}, ` : ''
      let tempMessage = (minSelected + maxSelected).slice(0, -2)
      if (minSelected && maxSelected) {
        tempMessage = `Selected range ${this.props.minSelected} to ${this.props.maxSelected}`
      }
      this.multipleMessage = <span style={{fontSize: '12px'}}>
        {tempMessage}
      </span>
    }else if(this.props.type === 'fileImg' || this.props.type === 'file'){
      this.setState({
        fileList: Lib2.setFileImg(this.props.dataRecord, {}, true)
      })
    }
  }

  render() {
    return (
      <FormContext.Consumer>
        {form => {
          const {getFieldDecorator, getFieldValue} = form;
          return (this.getInput(getFieldDecorator, getFieldValue, form))
        }}
      </FormContext.Consumer>
    );
  }
}

export default DynamicField;

DynamicField.propTypes = {
  onBlur: PropTypes.func,
  dataIndex: PropTypes.string,
  onPressEnter: PropTypes.func,
}

DynamicField.defaultProps = {
  onBlur: () => undefined,
  dataIndex: 'name',
  onPressEnter: () => null,
  setArgs: () => null,
}
