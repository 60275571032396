import React from 'react'
import { Card } from 'antd'
import { BaseList } from "App/Screens/SubModules/Component";
import descriptor from "./descriptorOffline";
import ProductSummary from './ProductSummary'
import TopUsage from './TopUsage'

class ProductList extends BaseList {
  constructor(props) {
    super(props)

    this.appName = 'estimation'
    this.modelName = 'product'
    this.availablePermissions = ['.view_', '.add_', '.change_'] // jika mengganti ini, juga perlu ganti di admin
    this.propsList = {
      srcAvatar: 'image_thumbnail.file',
      avatar: 'name',
      pageTitle: 'Product',
      descriptor,
      masterKey: 'module-product',
      operation: false,
      numberCols: 3,
      renderCountCard: this.renderCountCard,
      groupByOptions: [
        {
          label: "Unit",
          key: "unit.name",
          dataIndex: "unit.name"
        },
        {
          label: "Status",
          key: "status",
          dataIndex: "status"
        },
      ]
    }
    this.state = {
      ...this.state,
      currentPermissionSet: this.isSuperuser ? this.availablePermissions : [],
    }
  }

  renderCountCard = () => {
    return (
      <div>
        {/* <Card className='mb-2' bodyStyle={{ padding: '12px' }}>
          <ProductSummary />
        </Card> */}
        <Card className='my-2' bodyStyle={{ padding: 0 }}>
          <TopUsage />
        </Card>
      </div>
    )
  }
}

export default ProductList