export default [
  {
    title: 'Queue',
    type: 'SelectQuery',
    data: 'dataBranch',
    required: true,
    initialValue: '',
    note_text: '',
    selectedKey: 'pk',
    selectedValue: 'name',
    additional: 'edit|add',
    dataIndex: 'queue',
    order: 0,
  }, {
    title: 'Version',
    type: 'text',
    data: '',
    initialValue: '0',
    note_text: '',
    additional: '',
    dataIndex: 'version',
    order: 1,
  }, {
    title: 'Server',
    type: 'SelectQuery',
    data: 'dataServer',
    required: true,
    initialValue: '',
    note_text: '',
    selectedKey: 'pk',
    selectedValue: 'name',
    additional: 'edit|add',
    dataIndex: 'server',
    order: 2,
  }, {
    title: 'Job',
    type: 'SelectQuery',
    data: '',
    required: true,
    initialValue: '',
    note_text: '',
    additional: '',
    selectedKey: 'pk',
    selectedValue: 'name',
    dataIndex: 'job',
    order: 3,
  }, {
    title: 'Created',
    type: 'datetime',
    data: '',
    required: true,
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'created',
    order: 4,
  }, {
    title: 'Modified',
    type: 'datetime',
    data: '',
    required: true,
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'modified',
    order: 5,
  },
]