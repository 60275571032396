export default [
  {
    title: 'Merchant',
    dataIndex: 'merchant',
    type: 'SelectQuery',
    data: 'merchants',
    selectedKey: 'pk',
    selectedValue: 'name',
  },
  {
    title: 'Subsection',
    dataIndex: 'subsection',
    type: 'SelectQuery',
    data: 'subsections',
    selectedKey: 'pk',
    selectedValue: 'identifier',
    dropdownClassName: 'width-auto2',
    filters: [
      {
        title: 'Section',
        dataIndex: 'section',
        urlKey: 'sections-autocomplete',
        // type: 'SelectQuery',
        selectedKey: 'pk',
        selectedValue: 'name',
        relations: ['subsection'], // for clear fields when onChange
      },
    ],
  },
  {
    title: 'Title',
    dataIndex: 'title',
    type: 'text',
    required: true,
    // colProps: { md: 24 },
  },
  {
    title: 'Note',
    dataIndex: 'note',
    type: 'markdown',
    required: true,
    // colProps: { md: 24 },
    // formItemProps: { },
  },
]