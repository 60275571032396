import React, { Component } from 'react'
import {Button, Empty} from 'antd'
import img from 'App/Assets/img/original-empty.png'

export default class AppEmpty extends Component {
  render() {
    return (
      <div style={{padding:'2rem'}}>
        <Empty
          image={img}
          description={<span>No Data</span>}
        >
          <Button onClick={this.props.click} type="primary">Create Now</Button>
        </Empty>
      </div>
    )
  }
}
