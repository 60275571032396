import { BaseRedirectMenu } from 'App/Screens/SubModules/Component'
import Menu from './Menu'

class ExpertSystemRedirect extends BaseRedirectMenu {
  constructor(props) {
    super(props)

    this.menu = Menu
  }
}

export default ExpertSystemRedirect