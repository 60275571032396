import React from "react";
import PropTypes from "prop-types";
import { Card } from "antd";
import { get } from "lodash";
import { Sites } from "App/Config";
import { Lib } from "App/Utils";
import SubAllList from "App/Component/SubAllList";
import descriptor from "./descriptorOffline";

// User List in a Branch
class UserList extends React.PureComponent {

  addUser=()=>{
    const { branch } = this.props
    const historyState = {
      dataIdentifier: {
        home_branch: { pk: branch.pk, name: branch.name }
      }
    }
    Lib.historyAction(this.props.history, '/user-group/user/add', historyState).push()
  }

  detailUser=(id)=>{
    Lib.historyAction(this.props.history, `/user-group/user/${id}`).push()
  }

  componentWillUnmount() {
    Lib.windowStop();
  }

  render() {
    const { match, permissionItems } = this.props
    return (
      <Card>
        {/* Harusnya request ke ref users dari branch */}
        {/* Tidak bisa, karena tidak ada descriptor -_- */}
        {/* Sepertinya harus membuat component sendiri */}
        {/* <TemplateTable
          history={this.props.history}
          location={this.props.location}
          match={this.props.match}
          userReducer={this.props.userReducer}
          runRedux={this.props.runRedux}

          dataRecord={this.props.branch}
          bulkName='label'
          url_name=''
        /> */}
        <SubAllList
          permissions={permissionItems}
          pageTitle="Users"
          descriptor={descriptor}
          avatar="username"
          masterKey="module-branch-refUsers"
          args={[get(match, 'params.id')]}
          extraCount={this.props.changeUserCount}
          notes="Manage users data, profile and roles."
          title={i => i.username}
          onAddClick={ this.addUser}
          onDetailClick={ this.detailUser }
          description={i => i.full_name}
          // readParams={{branch: this.props.branch.pk}}
          userReducer={this.props.userReducer}
          groupByOptions={[
            {
              label: "Home branch",
              key: "home_branch.name",
              dataIndex: "home_branch.name"
            }
          ]}
        />
      </Card>
    );
  }
}

UserList.propTypes = {
  permissionItems: PropTypes.array,
}

UserList.defaultProps = {
  permissionItems: [],
}

export default UserList;
