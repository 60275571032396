export default {
  "app_label": "multisite",
  "model_name": "branch",
  "pagination": true,
  "columns": [
    {
      "name": "pk",
      "label": "ID",
      "help_text": null,
      "hidden": true,
      "identifier": true,
      "data_type": "int",
      "search": false,
      "sort": false
    },
    {
      "name": "name",
      "label": "Name",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "ellipsis": true,
      "search": true,
      "sort": true
    },
    {
      name: 'group_name',
      label: 'Group Name',
      data_type: 'str',
      search: true,
      sort: true,
      render: (val) => val && String(val).toUpperCase(),
    },
    {
      "name": "color",
      "label": "Color",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "search": false,
      "sort": false
    },
    {
      "name": "status",
      "label": "Status",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "search": true,
      "sort": true
    },
    {
      "name": "modified",
      "label": "Modified",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "datetime",
      "search": false,
      "sort": true
    }
  ],
  "filter_by": [
    {
      "label": "Status",
      "name": "status",
      "type": "choice",
      "choices": [
        [
          true,
          "Active"
        ],
        [
          false,
          "Inactive"
        ]
      ]
    }
  ],
  "export_import": null
}