import { BaseDetail } from 'App/Screens/SubModules/Component'
import fields from 'App/Screens/Job/JobGrades/fields'
import { JobTypeReference } from './References'

class PageDetail extends BaseDetail {
  constructor(props) {
    super(props)

    this.urlKey = 'module-job-jobGrade-detail'
    this.title = 'Job Grade'
    this.urlList = '/job-management/job-grade'
    this.headerDescriptions = [
      { key: 'code', label: 'Code' },
      { key: 'level', label: 'Level' },
      { key: 'rate_type.type', label: 'Rate Type' },
      { key: 'created', label: 'Created', type: 'datetime' },
      { key: 'created_by.username', label: 'Created By' },
      { key: 'modified', label: 'Modified', type: 'datetime' },
      { key: 'modified_by.username', label: 'Modified By' },
    ]
    this.fields = fields
    this.panes = [JobTypeReference]
    this.history = {
      url: 'module-job-jobGrade-history',
      fields: fields,
      urlDetail: "module-job-jobGrade-history-detail",
      id: props.match.params.id,
      title: 'code'
    }
    this.refField = {
      name: 'grade',
      type: 'object_arr',
      valKey: 'pk',
      valLabel: 'level',
    }
  }
}

export default PageDetail