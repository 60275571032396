import { BaseDetail } from 'App/Screens/SubModules/Component'
import fields from './fields'

class PageDetail extends BaseDetail {
  constructor(props) {
    super(props)

    this.urlKey = 'module-job-erfMapping-detail'
    this.title = 'ERF Mapping'
    this.urlList = '/job-management/erf'
    this.headerDescriptions = [
      { key: 'erf_type.label', label: 'ERF Type' },
      { key: 'erf_field', label: 'Field' },
      { key: 'erf_value', label: 'Value' },
      { key: 'map_value', label: 'Map Value' },
      { key: 'map_content_type.label', label: 'Map Content Type' },
      { key: 'map_object.name', label: 'Map Data' },
    ]
    this.fields = fields
  }
}

export default PageDetail