import { message } from 'antd';
import { RequestV2 } from 'App/Utils';

class UploadAdapter {
  constructor({ loader, userReducer, currentProductId }) {
    this.loader = loader;
    this.userReducer = userReducer;
    this.currentProductId = currentProductId;
  }

  upload() {
    return this.loader.file.then(
      file => new Promise((resolve, reject) => {
        const data = new FormData();
        data.append('file', file);
        
        const options = {
          onUploadProgress: progressEvent => {
            this.loader.uploadTotal = progressEvent.total
            this.loader.uploaded = progressEvent.loaded
          }
        }

        RequestV2(
          'post',
          'source-files',
          {},
          data,
          [this.currentProductId],
          this.uploadSuccess,
          this.uploadFailed,
          { file, resolve, reject }, // extra
          null,
          options
        );
      })
    );
  }

  uploadSuccess = (response, extra) => {
    message.success(`Success upload file: ${response.data.file_name}`);
    extra.resolve({ default: response.data.file });
  }

  uploadFailed = (error, extra) => {
    extra.reject(`Couldn't upload file: ${extra.file.name}`);
  }

  abort() {
    // aborting...
  }
}

export default UploadAdapter;