import { get } from 'lodash'
import { Store } from 'App/Redux'
import { forcePermissions } from 'App/Data' // force: add, view, change, delete

// need object parameter with property: appName, modelName
// return array of permissions
const getPermissionSet = (props) => {
  const { userReducer } = Store.getState()
  const isSuperuser = get(userReducer, ['user', 'is_superuser'])
  if (isSuperuser) {
    return forcePermissions
  }

  const appName = props.appName || ''
  const regex = props.search || new RegExp('^' + appName + '\\..*_' + props.modelName + '$')
  const permissions = get(userReducer, ['user', 'permissions'], [])
  const permissionSet = permissions.filter(val => val && val.search(regex) !== -1)
  return permissionSet
}

// need object parameter with property: userReducer, code (permission code)
const checkAllowed = (props) => {
  const isSuperuser = get(props, ['userReducer', 'user', 'is_superuser'])
  if (isSuperuser) {
    return true
  }

  const permissions = get(props, ['userReducer', 'user', 'permissions'], [])
  return permissions.find(val => val === get(props, 'code'))
}

export default { getPermissionSet, checkAllowed }
