import React from 'react'
import SubAllScreen from 'App/Component/SubAllScreen'

class JobQueue extends React.Component {
  render() {
    const standardProps = {
      history: this.props.history,
      location: this.props.location,
      match: this.props.match,
      userReducer: this.props.userReducer,
    }

    return (
      <SubAllScreen {...standardProps} />
    )
  }
}

export default JobQueue