import React from 'react'
import PropTypes from 'prop-types'
import { Card, Checkbox, Col, Empty, Icon, Input, Spin, Row } from 'antd'
import { get, isEmpty } from 'lodash'
import { Lib, RequestV2 as Request } from 'App/Utils'
import ListItem from './ListItem'
import PageError from 'App/Component/PageError'

class ReceiverList extends React.PureComponent {
  constructor(props) {
    super(props)

    this.page = 1
    this.limit = 100
    this.paramKeys = ['page', 'limit']
    this.state = {
      isLoading: true,
      errorResponse: null,
      listData: null,
      objSelectedKeys: this.getObjKeys(props.parentData, props.fieldKey),
      searchText: '',
    }
  }

  getObjKeys = (parentData, fieldKey) => {
    const arr = get(parentData, fieldKey, [])
    const objKeys = {}
    for (let i = 0; i < arr.length; i += 1) objKeys[arr[i].pk] = true
    return objKeys
  }

  getObjParam = () => {
    const objParam = {}
    for (let i = 0; i < this.paramKeys.length; i += 1) {
      objParam[this.paramKeys[i]] = this[this.paramKeys[i]]
    }
    return { ...objParam, ...get(this.props.listItem, 'params') }
  }

  read = (isLoadMore, objState) => {
    if (!isLoadMore) this.page = 1
    this.setState({ isLoading: true, ...objState }, () => {
      Request(
        'get',
        this.props.urlKey,
        {},
        this.getObjParam(),
        [],
        this.readSuccess,
        this.readFailed,
        { isLoadMore }
      )
    })
  }

  readSuccess = (response, extra) => {
    const listData = response.data
    if (extra.isLoadMore) listData.results = [...this.state.listData.results, ...listData.results]
    this.setState({ isLoading: !!response.data.next, listData }, () => {
      if (response.data.next) {
        this.loadMore()
      }
    })
  }

  readFailed = (error) => {
    // Must read all list of pagination
    this.page = 1
    this.setState({ isLoading: false, listData: null, errorResponse: get(error, 'response') })
  }

  loadMore = () => {
    this.page += 1
    this.read(true)
  }

  renderListItem = (item) => {
    const { listItem } = this.props
    const { objSelectedKeys, searchText } = this.state
    const value = get(item, listItem.key, '-')
    const visible = new RegExp(searchText, 'i').test(value)

    return (
      <div key={item.pk} style={visible ? null : { display: 'none' }}>
        <ListItem
          id={item.pk}
          item={item}
          selected={!!objSelectedKeys[item.pk]}
          value={value}
          onSelect={this.onSelectItem}
        />
      </div>
    )
  }

  onSelectItem = (key) => {
    this.props.setTouched()
    this.setState(
      prevState => ({
        objSelectedKeys: {
          ...prevState.objSelectedKeys,
          [key]: !prevState.objSelectedKeys[key],
        }
      })
    )
  }

  onSearch = (searchText) => {
    this.setState({ searchText })
  }

  validateFields = () => {
    const { fieldKey } = this.props
    const { objSelectedKeys } = this.state
    const values = Object.keys(objSelectedKeys).filter(val => objSelectedKeys[val])

    return { [fieldKey]: values}
  }

  componentDidMount() {
    this.read()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.urlKey) {
      if (nextProps.urlKey !== this.props.urlKey) {
        this.read(undefined, { objSelectedKeys: {} })
      }
    }
  }

  render() {
    const { fieldKey, label } = this.props
    const { isLoading, errorResponse, listData, objSelectedKeys } = this.state
    const selectedCount = Object.keys(objSelectedKeys).filter(val => objSelectedKeys[val]).length

    if (isLoading) {
      return (
        <div className='my-3' style={{ width: '100%', textAlign: 'center' }}>
          <Spin
            indicator={<Icon type="loading" style={{ fontSize: "2.5rem" }} spin />}
          />
        </div>
      )
    }

    return listData ? (
      <Card
        title={
          <Row type='flex' justify='space-between' gutter={[12, 12]}>
            <Col>{`Available ${label || fieldKey} (${listData.results.length}) :`}</Col>
            {!!selectedCount && <Col>Selected: ({selectedCount})</Col>}
          </Row>
        }
        size='small'
        type='inner'
        bodyStyle={{ height: '300px', overflowY: 'auto', padding: '12px 0' }}
        style={{ width: '100%' }}
      >
        <div>
          {listData.results.length > 0 ? (
            <>
              <div className='px-2'>
                <Input.Search
                  autoFocus
                  size='small'
                  placeholder='Search...'
                  onChange={(event) => this.onSearch(event.target.value)}
                  onSearch={this.onSearch}
                />
              </div>
              {listData.results.map(this.renderListItem)}
            </>
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </div>
      </Card>
    ) : (
      <PageError
        errorResponse={errorResponse}
        onReload={() => this.read()}
      />
    )
  }
}

ReceiverList.propTypes = {
  urlKey: PropTypes.string.isRequired,
  fieldKey: PropTypes.string.isRequired,
  listItem: PropTypes.object,
  setTouched: PropTypes.func,
}

ReceiverList.defaultProps = {
  listItem: {},
  setTouched: () => null,
}

export default ReceiverList