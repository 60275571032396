import React from 'react'
import PropTypes from 'prop-types'
import { Card, Row, Col, Empty, Icon, Button } from 'antd'
import { xor } from 'lodash'

class CardGroup extends React.PureComponent {
  onSelectGroup = (groupId, isSelected, event) => {
    const { groups } = this.props
    const { selectedGroups, onChange } = this.props
    // Multiple selection, can no selection
    // const selectedIds = xor(selectedGroups, [groupId])
    // ------------------------------------

    // Single selection or all
    const selectedIds = (selectedGroups.length > 1 || isSelected) ?  [groupId] : groups.map(group => group.pk)
    // -----------------------
    onChange(selectedIds)
  }

  render() {
    const { groups, selectedGroups } = this.props

    return (
      <Card
        title={
          <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
            Followed Groups
            <span style={{ fontWeight: 400, marginLeft: '4px', color: '#8c8c8c' }}>
              (Select a group to show permission under the group, and select again to select all)
            </span>
          </div>
        }
        type='inner'
      >
        <Row type='flex' justify='center' gutter={[12, 12]}>
          {groups.length > 0 ? (
            groups.map((group) => {
              const isSelected = selectedGroups.find(groupId => groupId === group.pk)
              return (
                <Col key={group.pk}>
                  <Button type={isSelected ? 'primary' : 'default'} onClick={(event) => this.onSelectGroup(group.pk, !isSelected, event)}>
                    {isSelected ? <Icon type='check-square' /> : <Icon type='border' /> }
                    {group.name}
                  </Button>
                </Col>
              )
            })
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Not in any group" />
          )}
        </Row>
      </Card>
    )
  }
}

CardGroup.propTypes = {
  groups: PropTypes.array,
  selectedGroups: PropTypes.array,
  onChange: PropTypes.func,
}

CardGroup.defaultProps = {
  groups: [],
  selectedGroups: [],
  onChange: () => null,
}

export default CardGroup