import {ChartCard, Pie, Field} from 'ant-design-pro/lib/Charts';
import {Col, Icon, Row, Tooltip} from "antd";
import moment from "moment";
import Trend from "ant-design-pro/lib/Trend";
import React from "react";
import PropTypes from 'prop-types';
import Title from "antd/lib/typography/Title";

class ChartPie extends React.Component{

  render() {
    const {pieOriginData, data} = this.props
    let name = data.name
    if(data.label){
      name = data.label
    }
    const PieData = [];
    for (const data of pieOriginData){
      PieData.push({x: data.name, y: data.value})
    }
    let createdBy = data.created_by ? data.created_by.username : 'Unknow'
    return(
      <Row>
        <Col lg={15} xs={24}>
          <ChartCard style={{marginRight: "5px", marginBottom: "5px"}}>
            <Title level={4} style={{marginTop: "-28px"}} type={"secondary"}>{name}</Title>
            <Pie
              hasLegend={true}
              subTitle={"Top 7 last used"}
              total={() => (
                <span
                  dangerouslySetInnerHTML={{
                    __html: data.status ? data.status : "Active",
                  }}
                />
              )}
              data={PieData}
              valueFormat={val => <span dangerouslySetInnerHTML={{ __html: val }} />}
              height={252}
            />
          </ChartCard>
        </Col>
        <Col lg={9} xs={24}>
          <ChartCard
            title={"Created at"}
            action={
              <Tooltip title={name}>
                <Icon type="info-circle-o" />
              </Tooltip>
            }
            total={() => <span dangerouslySetInnerHTML={{ __html: moment(data.created).format('MM-DD-YYYY') }} />}
            footer={<Field label={"Created by : "} value={createdBy} />}
            contentHeight={46}
          >
              <span>
                edited
                <Trend flag="up" style={{ marginLeft: 8, color: 'rgba(0,0,0,.85)' }}>
                  12%
                </Trend>
              </span>
            <span style={{ marginLeft: 16 }}>
                edited
                <Trend flag="down" style={{ marginLeft: 8, color: 'rgba(0,0,0,.85)' }}>
                  11%
                </Trend>
              </span>
          </ChartCard>
        </Col>
        <Col lg={9} xs={24} >
          <ChartCard
            style={{marginTop: "5px"}}
            title={"Status"}
            avatar={
              <img
                style={{ width: 56, height: 56 }}
                src="https://gw.alipayobjects.com/zos/rmsportal/sfjbOqnsXXJgNCjCzDBL.png"
                alt="indicator"
              />
            }
            action={
              <Tooltip title={name}>
                <Icon type="info-circle-o" />
              </Tooltip>
            }
            total={() => <span dangerouslySetInnerHTML={{ __html: data.status ? data.status : "Active" }} />}
            footer={<Field label={"Desc. :"} value={(data.status === "active" || !data.status) ? "Anyone can use" : data.status === "inactive" ? "No one can use" : "Only certain people can use"} />}
          />
        </Col>
      </Row>
    )
  }
}
ChartPie.propTypes = {
  pieOriginData: PropTypes.array,
  data: PropTypes.object
}

export default ChartPie;
