export default [
  {
    title: "Account Code",
    type: "text",
    data: "",
    required: true,
    initialValue: "",
    note_text: "",
    additional: "",
    dataIndex: "account_code",
    order: 0
  },
  {
    title: "Account Name",
    type: "text",
    data: "",
    required: true,
    initialValue: "",
    note_text: "",
    additional: "",
    dataIndex: "account_name",
    order: 1
  }
];
