import React from "react";
import axios from "axios";
import { message, notification, Button } from "antd";
import queryString from "query-string";

import { Urls, Sites } from "App/Config";
import { Store } from "App/Redux";
import { RefreshToken } from "./";

const CancelToken = axios.CancelToken;
let api,
  btn,
  parentArgs,
  instance = {};

function Request(
  method,
  url_key,
  headers = {},
  data = {},
  args = [],
  onSuccess = () => {},
  onFailed = () => {},
  extra = undefined,
  is_Mock = false,
  mod_urls = false,
  params = '',
) {
  if (typeof headers !== "object") {
    throw Error("Invalid headers, headers must be an object");
  }

  headers = {
    Authorization: "Bearer " + Store.getState().userReducer.token,
    "Content-Type": "application/json",
    ...headers
  };


  // if (typeof data !== "object") {
  //   throw ("Invalid data, data must be an object")
  // }

  if (!Array.isArray(args)) {
    throw Error("Invalid arguments, data must be an array");
  }

  if (typeof onSuccess !== "function") {
    throw Error("Invalid onSuccess, onSuccess must be a function");
  }

  if (typeof onFailed !== "function") {
    throw Error("Invalid onFailed, onFailed must be a function");
  }

  if (method === "get" && data) {
    data = { params: data };
  }

  for (let header in headers) {
    axios.defaults.headers.common[header] = headers[header];
  }

  api = Urls[url_key];
  if (!api) {
    // throw Error("Invalid url key");
    onFailed("Invalid url key", extra);
    return
  }
  if(mod_urls)
    api = api + mod_urls

  args.map((value, index) => (api = api.replace("{}", value)));

  if (params) params = `?${queryString.stringify(params)}`

  if (typeof axios[method] !== "function") {
    throw Error("Invalid method");
  }

  btn = (
    <Button size="small" onClick={() => window.location.reload()}>
      Reload
    </Button>
  );

  parentArgs = arguments;
  let paramurldata = is_Mock ? 'https://mockapi.ibes.co.nz/' : (Sites.url.api.v2 + '/')
  axios[method](paramurldata + api + params, data, {
    cancelToken: new CancelToken(function executor(c) {
      instance.cancel = c;
    })
  })
    .then(function(response) {
      onSuccess(response, extra);
    })
    .catch(function(error) {
      if(error && error.response && error.response.status && error.response.status === 404) {
        // alert('Sorry, Response from API is 404 not found');
        onFailed(error, extra)
        return;
      }
      if(error && error.response && error.response.status && error.response.status === 500) {
        onFailed(error, extra)
        return;
      }

      if (typeof error !== "undefined") {
        if (typeof error.response !== "undefined") {
          /* refresh token */
          if (typeof error.response.statusText !== "undefined") {
            if (
              error.response.statusText === "Unauthorized" &&
              error.response.data.error !== "invalid_grant"
            ) {
              RefreshToken(parentArgs);
              return instance;
            }
          }
          /* === */
          if (typeof error.response.data !== "undefined") {
            if (typeof error.response.data === "object" || extra.isRefreshToken) {
              onFailed(error, extra);
            } else {
              message.error("wrong return from server!");
            }
          }
        } else if(error.toString() === "Cancel"){
          onFailed({
            response: {
              data: {
                detail: error.message
                  ? error.message
                  : "Operation canceled!"
              }
            }
          }, extra);
        } else {
          //Debug.isDevMode && console.log(error);
          notification.error({
            key: "netError",
            message: "Error!",
            description: "Network connection refused. Please check your connection!",
            duration: 15,
            btn
          });
          onFailed({
            response: { data: { detail: "Connection to Server Failed!" } }
          }, extra);
        }
      }

    });

  return instance;
}

export default Request;
