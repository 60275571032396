import { BaseList, BaseDetail } from "App/Component/Bases";
import fields from "./fields";
import ComponentAutocomplete from "App/Component/Autocomplete";
import descriptor from "./descriptor";
import options from "./options";

const title = "Product Groups";
const urlData = "productGroups";
const bulkName = "name";
const children = {
    appName: 'estimation',
    modelName: 'product',
    name: 'productGroups',
    sub: 'products',
    dataIndex: 'products',
    bulk_request: true,
    urlSub: 'api:admin:estimation:productgroup:ref-products-list',
    add: true,
    edit: true,
    data: false,
    identifier: {
      name: 'groups',
      is_array: true
    },
    permissions: {
      payload: true,
      bulk_delete: false,
      initialrow: 1,
      add:true
    },
    rules: {
      button: {
        type: {
          add: 'default',
          edit: 'default'
        },
        size: 'small'
      },
      input: {
        size: 'small'
      },
      select: {
        size: 'small'
      }
    },
  fields: ComponentAutocomplete['products']
  }

class List extends BaseList {
  constructor(props) {
    super(props)

    this.title = title;
    this.urlData = urlData;
    this.bulkName = bulkName;
    this.propsList = {
      descriptor,
      exportImport: options.exportImportOptions,
    }
  }
}

class Detail extends BaseDetail {
  constructor(props) {
    super(props)

    this.title = title
    this.urlData = urlData
    this.bulkName = bulkName
    this.fields = fields
    this.children = children
    this.propsDetail = {
      urlKeyHistory: 'api:admin:estimation:productgroup:detail-revision',
      keyLabel: 'name'
    }
  }
}

export default { List, Detail };
