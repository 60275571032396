import React from 'react'
import PropTypes from 'prop-types'
import { Prompt } from 'react-router-dom'
import { Modal, Row, Col, Form, Button, message } from 'antd'
import { get, cloneDeep } from 'lodash'
import { FormContext, FormProvider } from 'App/Component/Context/ContextForm'
import DynamicField from 'App/Component/DynamicField'
import fields from './fields'
import { RequestV2 as Request, Lib } from 'App/Utils'
import Lib2 from 'App/Component/Singlepage/Lib2'

class PageAdd extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isFieldsTouched: false,
      isSaving: false,
    }
  }

  onBlur = (value, key, type) => {
    this.setState({ isFieldsTouched: true })
  }

  onCancel = () => {
    Lib.historyAction(this.props.history, '/merchant/sku-mapping').goBack()
  }

  onSave = () => {
    if (this.form) {
      this.form.validateFieldsAndScroll({scroll: {offsetTop: 150}}, (err, values) => {
        if (!err) {
          this.setState({ isSaving: true }, () => {
            const merchantId = get(this.props.match, ['params', 'id'])
            const convertedValues = Lib2.getValuesWithFile(values)
            Request(
              'post',
              'module-merchant-merchant-refSkuMapping-create',
              {},
              { merchant: merchantId, ...convertedValues },
              [merchantId],
              this.saveSuccess,
              this.saveFailed,
            )
          })
        }
      })
    } else {
      message.error('Error in code Sku Mapping, form not found. [function => onSave]')
    }
  }

  saveSuccess = (response) => {
    message.success(`Sku Mapping with code: ${response.data.product_code} has been saved.`)
    this.setState({ isFieldsTouched: false }, () => {
      const merchantId = get(this.props.match, ['params', 'id'])
      Lib.historyAction(this.props.history, `/merchant/merchant/${merchantId}`).goBack()
    })
  }

  saveFailed = (error) => {
    const errors = get(error, ['response', 'data'])
    if (errors && typeof errors === 'object') {
      this.setFieldsError(errors)
    } else {
      message.error('Failed to save SKU Mapping, please contact your admin! code: ' + get(error, ['response', 'status'], 'Unknown.'), 5)
    }
    this.setState({ isSaving: false })
  }

  setFieldsError = (objError) => {
    const obj = {}
    for (const key in objError) {
      message.error('Error in ' + key + ' : ' + objError[key][0], 5)
      const tempValue = this.form.getFieldValue(key)
      obj[key] = {
        value: tempValue,
        errors: [new Error(objError[key][0])]
      }
    }
    this.form.setFields(obj)
  }

  render() {
    const { userReducer } = this.props
    const { isFieldsTouched, isSaving } = this.state

    return (
      <div>
        <Prompt
          when={isFieldsTouched}
          message='You will lose any unsaved changes. Continue?'
        />
        <Row style={{ marginTop: '12px' }}>
          <FormProvider>
            <Form>
              {fields.map((elem) => {
                return (
                  <DynamicField
                    {...elem}
                    key={elem.dataIndex}
                    onBlur={this.onBlur}
                    userReducer={userReducer}
                  />
                )
              })}
              <FormContext.Consumer>
                {form => {this.form = form}}
              </FormContext.Consumer>
            </Form>
          </FormProvider>
        </Row>
        <Row type='flex' justify='space-between' align='middle' style={{ backgroundColor: '#fff', padding: '12px 0', borderTop: '1px solid #e8e8e8'}}>
          <Col>
            {/* <Button type='danger'>Button Danger</Button> */}
          </Col>
          <Col>
            <Row type='flex' gutter={[12, 12]}>
              <Col>
                <Button disabled={isSaving} onClick={this.onCancel}>
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button icon='save' type='primary' loading={isSaving} onClick={this.onSave}>
                  Save
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    )
  }
}

PageAdd.propTypes = {
  userReducer: PropTypes.object.isRequired,
}

PageAdd.defaultProps = {
  
}

export default PageAdd