import { Store, Action } from "App/Redux";
import { Sites } from "App/Config";
import request from "./request.v3";

export default async function () {
  const newUserSessionObj = await request({
    method: "post",
    urlKey: "user-auth",
    data: {
      grant_type: "refresh_token",
      refresh_token: Store.getState().userReducer.refreshToken,
      client_id: Sites.oauth.id,
      client_secret: Sites.oauth.secret,
    },
  });

  if (newUserSessionObj) {
    newUserSessionObj.username = Store.getState().userReducer.username;
    Store.dispatch(Action.CreateUserSessionProperties(newUserSessionObj));
    return true;
  }

  return false;
}
