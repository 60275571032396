import React from 'react'
import { Button, Card, Col, message, Row } from 'antd'
import Lottie from 'react-lottie'
import { get } from 'lodash'
import { Lib, RequestV2 as Request } from 'App/Utils'
import Subsections from './Subsections'
import OrderContents from './Content'
import * as observationAnimation from 'App/Assets/lottie/observation'

class OrderManagement extends React.Component {
  constructor(props) {
    super(props)

    // override this ---
    this.title = 'Order Management'
    this.urlKeySave = ''
    this.propsCardContent = null // object
    this.colProps = undefined // object
    // -----------------
    this._content = null
    this.state = {
      isSaving: false,
      selectedSubsections: {
        keys: [],
        obj: {}, // detail of selected keys
      },
    }
  }

  getSubsectionList = () => (
    <Col style={{ width: '250px' }}>
      <Subsections
        ref={ref => {this._subsections = ref}}
        onSelect={this.onSelectSubsection}
        selectedSubsections={this.state.selectedSubsections}
      />
    </Col>
  )

  getContent = () => (
    <Col style={{ width: 'calc(100% - 250px)' }}>
      {this.state.selectedSubsections.keys.length > 0 ? (
        <OrderContents
          ref={ref => {this._content = ref}}
          selectedSubsections={this.state.selectedSubsections}
          onCloseCard={this.onCloseCard}
          propsCard={this.propsCardContent}
          colProps={this.colProps}
          urlKeySave={this.urlKeySave}
        />
      ) : (
        <div className='py-5'>
          <Lottie
            width={300}
            height={300}
            options={{
              loop: true,
              autoplay: true,
              animationData: observationAnimation.default,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              }
            }}
          />
          <div className='ant-result-title'>Select subsection on the left</div>
        </div>
      )}
    </Col>
  )

  onSelectSubsection = (subsection) => {
    const { selectedSubsections } = this.state
    const subsectionIsExist = selectedSubsections.keys.find(key => key === subsection.pk)
    if (!subsectionIsExist) {
      this.setState({
        selectedSubsections: {
          keys: [...selectedSubsections.keys, subsection.pk],
          obj: { ...selectedSubsections.obj, [subsection.pk]: subsection }
        }
      })
    } else {
      // Subsection card appears
    }
  }

  onCloseCard = (subsectionId) => {
    const { selectedSubsections: {keys, obj} } = this.state
    delete obj[subsectionId]
    delete this._content._cardItems[subsectionId]
    this.setState({
      selectedSubsections: {
        keys: keys.filter(key => key !== subsectionId),
        obj,
      }
    })
  }

  onSaveOrder = () => {
    const values = this._content ? this._content.validateFields() : []
    if (values.length) {
      this.setState({ isSaving: true }, () => {
        Request(
          'post',
          this.urlKeySave,
          {},
          values,
          [],
          this.saveSuccess,
          this.saveFailed,
        )
      })
    } else {
      message.info('No unsaved changes')
    }
  }

  saveSuccess = (response) => {
    message.success('Order has been saved')
    for (const key in this._content._cardItems) {
      if (get(this._content._cardItems[key], ['state', 'isTouched'])) {
        this._content._cardItems[key].clearTouched()
      }
    }
    this.setState({ isSaving: false })
  }

  saveFailed = (error) => {
    Lib.errorMessageHandler(get(error, 'response'))
    this.setState({ isSaving: false })
  }

  render() {
    const { isSaving } = this.state

    return (
      <Card
        title={this.title}
        extra={<Button type='primary' icon='save' loading={isSaving} onClick={this.onSaveOrder}>Save</Button>}
        style={{ borderRadius: '12px', marginBottom: '12px' }}
      >
        <Row type='flex' gutter={[12, 12]}>
          {this.getSubsectionList()}
          {this.getContent()}
        </Row>
      </Card>
    )
  }
}

export default OrderManagement