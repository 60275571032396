export default [
  {
    title: 'Username',
    type: 'text',
    data: '',
    required: true,
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'username',
    order: 0,
  }, {
    title: 'Password',
    type: 'password',
    data: '',
    required: true,
    hideOnEdit: true,
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'password',
    order: 1,
  }, {
    title: 'Re-type Password',
    type: 'password',
    data: '',
    hideOnEdit: true,
    initialValue: '',
    required: true,
    note_text: '',
    additional: '',
    dataIndex: '',
    order: 2,
  }, {
    title: 'First Name',
    type: 'text',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'first_name',
    order: 3,
  }, {
    title: 'Last Name',
    type: 'text',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'last_name',
    order: 4,
  }, {
    title: 'Full Name',
    type: 'text',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'full_name',
    order: 5,
  }, {
    title: 'E-Mail',
    type: 'email',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'email',
    order: 6,
  }, {
    title: 'Is Staff',
    type: 'checkbox',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'is_staff',
    order: 7,
  }, {
    title: 'Is Active',
    type: 'checkbox',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'is_active',
    order: 8,
  }, {
    title: 'Is Superuser',
    type: 'checkbox',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'is_superuser',
    order: 9,
  },
  
  
  // {
  //   title: 'Last Login',
  //   type: 'datetime',
  //   data: '',
  //   initialValue: '',
  //   note_text: '',
  //   additional: '',
  //   dataIndex: 'last_login',
  //   order: 10,
  // }, {
  //   title: 'User Permissions',
  //   type: 'select_multiple',
  //   data: 'permissions',
  //   initialValue: '',
  //   required: true,
  //   note_text: '',
  //   additional: '',
  //   selectedKey: 'pk',
  //   selectedValue: 'name',
  //   dataIndex: 'user_permissions',
  //   order: 11,
  // },{
  //   title: 'Groups',
  //   type: 'select_multiple',
  //   data: 'groups',
  //   initialValue: '',
  //   note_text: '',
  //   required: true,
  //   additional: '',
  //   selectedKey: 'pk',
  //   selectedValue: 'name',
  //   dataIndex: 'groups',
  //   isActive: true,
  //   order: 13,
  // },{
  //   title: 'Home Branch',
  //   type: 'SelectQuery',
  //   data: 'branches',
  //   initialValue: '',
  //   note_text: '',
  //   additional: 'edit|add',
  //   selectedKey: 'pk',
  //   selectedValue: 'name',
  //   dataIndex: 'home_branch',
  //   order: 14,
  // }, {
  //   title: 'Max Jobs',
  //   type: 'number',
  //   data: '',
  //   initialValue: '',
  //   note_text: '',
  //   additional: '',
  //   dataIndex: 'max_jobs',
  //   order: 15,
  // }, {
  //   title: 'Allowed Bill',
  //   type: 'checkbox',
  //   data: '',
  //   initialValue: '',
  //   note_text: '',
  //   additional: '',
  //   dataIndex: 'allowed_bill',
  //   order: 16,
  // }, {
  //   title: 'Allowed Analysis Report',
  //   type: 'checkbox',
  //   data: '',
  //   initialValue: '',
  //   note_text: '',
  //   additional: '',
  //   dataIndex: 'allowed_analysis_report',
  //   order: 17,
  // }, {
  //   title: 'Allowed Job Assignment',
  //   type: 'checkbox',
  //   data: '',
  //   initialValue: '',
  //   note_text: '',
  //   additional: '',
  //   dataIndex: 'allowed_job_assignment',
  //   order: 18,
  // }, {
  //   title: 'Bill Access',
  //   type: 'checkbox',
  //   data: '',
  //   initialValue: '',
  //   note_text: '',
  //   additional: '',
  //   dataIndex: 'bill_access',
  //   order: 19,
  // }, {
  //   title: 'Staff Position',
  //   type: 'SelectQuery',
  //   data: 'staffPositions',
  //   initialValue: '',
  //   note_text: '',
  //   additional: 'edit|add',
  //   selectedKey: 'pk',
  //   selectedValue: 'name',
  //   dataIndex: 'staff_position',
  //   order: 20,
  // },{
  //   title: 'Skill Level',
  //   type: 'text',
  //   data: '',
  //   initialValue: '',
  //   note_text: '',
  //   additional: '',
  //   dataIndex: 'skill_level',
  //   order: 21,
  // },{
  //   title: 'Notifications Count',
  //   type: 'text',
  //   data: '',
  //   initialValue: '',
  //   note_text: '',
  //   additional: '',
  //   dataIndex: 'notifications_count',
  //   order: 22,
  // },
]

// Backup original
// export default [
//   {
//     title: 'Username',
//     type: 'text',
//     data: '',
//     required: true,
//     initialValue: '',
//     selectedKey: 'pk',
//     selectedValue: 'full_name',
//     note_text: '',
//     additional: '',
//     dataIndex: 'username',
//     order: 0,
//   }, {
//     title: 'First Name',
//     type: 'text',
//     required: true,
//     data: '',
//     initialValue: '',
//     note_text: '',
//     additional: '',
//     dataIndex: 'first_name',
//     order: 1,
//   }, {
//     title: 'Last Name',
//     type: 'text',
//     required: true,
//     data: '',
//     initialValue: '',
//     note_text: '',
//     additional: '',
//     dataIndex: 'last_name',
//     order: 2,
//   }, {
//     title: 'Branch Full Name',
//     type: 'text',
//     required: true,
//     data: '',
//     initialValue: '',
//     note_text: '',
//     additional: '',
//     dataIndex: 'branch_full_name',
//     order: 3,
//   }, {
//     title: 'full_name',
//     type: 'text',
//     required: true,
//     data: '',
//     initialValue: '',
//     note_text: '',
//     additional: '',
//     dataIndex: 'Full Name',
//     order: 4,
//   }
// ];