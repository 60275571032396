export default [
  {
    title: 'Name',
    type: 'text',
    data: '',
    required: true,
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'name',
    order: 0,
  }, {
    title: 'Exporter name',
    type: 'textarea',
    data: '',
    initialValue: '',
    note_text: 'Internal system function name used for exporting jobs. Leave blank to use default.',
    additional: '',
    dataIndex: 'exporter_name',
    order: 1,
  }, {
    title: 'Old sequence',
    type: 'text',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'old_sequence',
    order: 2,
  }, {
    title: 'Show seed',
    type: 'checkbox',
    data: '',
    initialValue: '',
    note_text: 'Determines whether seed file needed or not.',
    additional: '',
    dataIndex: 'show_seed',
    order: 3,
  }, {
    title: 'Mapping Mode',
    type: 'SelectQuery',
    data: 'mappingModes',
    additional: 'edit|add',
    selectedKey: 'pk',
    selectedValue: 'name',
    dataIndex: 'mapping_mode',
  }
];