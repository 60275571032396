import React from 'react'
import { Card, Col, Divider, Row, Spin } from 'antd'
import { get } from 'lodash'
import { RequestV2 as Request } from 'App/Utils'
import PageError from 'App/Component/PageError'

class ProductSummary extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      isReloading: false,
      productSummary: null,
      errorResponse: null,
    }
  }

  read = (keyLoading = 'isLoading', callback = () => null) => {
    this.setState({ [keyLoading]: true }, () => {
      Request(
        'get',
        'module-product-summaries',
        {},
        {},
        [],
        this.readSuccess,
        this.readFailed,
        { keyLoading, callback }
      )
    })
  }

  readSuccess = (response, extra) => {
    this.setState({ [extra.keyLoading]: false, productSummary: response.data, errorResponse: null }, () => {
      extra.callback(true)
    })
  }

  readFailed = (error, extra) => {
    this.setState({ [extra.keyLoading]: false, errorResponse: get(error, 'response') }, () => {
      extra.callback(false)
    })
  }

  componentDidMount() {
    this.read()
  }

  render() {
    const { isLoading, productSummary, errorResponse } = this.state

    if (isLoading) {
      return (
        <Row className='p-3' type='flex' justify='center' align='middle'>
          <Col>
            <Spin spinning />
          </Col>
        </Row>
      )
    }

    return productSummary ? (
      <div>
        <Row>
          <Col className="text-center child-border-right" span={6}>
            <span className="text-muted font-lg mb-3">Total</span>
            <div>
              <span className="font-4xl">{productSummary.total}</span>
            </div>
          </Col>
          {productSummary.summaries.map(val => (
            <Col className="text-center child-border-right" span={6} key={val.label}>
              <span className="text-muted font-lg mb-3">{val.label}</span>
              <div>
                <span className="font-4xl">{val.total}</span>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    ) : (
      <PageError errorResponse={errorResponse} onReload={this.read} />
    )
  }
}

export default ProductSummary