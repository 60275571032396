import React from 'react'
import PropTypes from 'prop-types'
import { Prompt } from 'react-router-dom'
import { Row, Col, Form, Button, message } from 'antd'
import { get } from 'lodash'
import { FormContext, FormProvider } from 'App/Component/Context/ContextForm'
import DynamicField from 'App/Component/DynamicField'
import fields from './fields'
import { RequestV2 as Request } from 'App/Utils'
import Lib2 from 'App/Component/Singlepage/Lib2'

class PageSetting extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isFieldsTouched: false,
      isSaving: false,
    }
  }

  onBlur = (value, key, type) => {
    const { mappingMode } = this.props
    if (get(mappingMode, key) !== value) {
      this.setState({ isFieldsTouched: true })
    }
  }

  onRevert = () => {
    if (this.form) {
      // const { mappingMode } = this.props
      // const savedValue = {}
      // for (const index in fields) {
      //   const decoratorName = fields[index].dataIndex
      //   savedValue[decoratorName] = get(mappingMode, decoratorName)
      // }
      // this.form.setFieldsValue(savedValue)
      this.form.resetFields()
      this.setState({ isFieldsTouched: false })
    } else {
      message.error('Error in code PageSetting, form not found. [function => onRevert]')
    }
  }

  onSave = () => {
    if (this.form) {
      this.form.validateFieldsAndScroll({scroll: {offsetTop: 150}}, (err, values) => {
        if (!err) {
          this.setState({ isSaving: true }, () => {
            const { mappingMode } = this.props
            const convertedValues = Lib2.getValuesWithFile(values)
            Request(
              'put',
              'module-merchant-mappingMode-update',
              {},
              convertedValues,
              [mappingMode.pk],
              this.saveSuccess,
              this.saveFailed,
            )
          })
        }
      })
    } else {
      message.error('Error in code PageSetting, form not found. [function => onSave]')
    }
  }

  saveSuccess = (response) => {
    message.success(`${response.data.mode} has been saved.`)
    this.props.setMappingMode(response.data)
    this.setState({ isSaving: false, isFieldsTouched: false })
  }

  saveFailed = (error) => {
    const errors = get(error, ['response', 'data'])
    if (errors && typeof errors === 'object') {
      this.setFieldsError(errors)
    } else {
      message.error('Failed to save Group, please contact your admin!', 5)
    }
    this.setState({ isSaving: false })
  }

  setFieldsError = (objError) => {
    const obj = {}
    for (const key in objError) {
      message.error('Error in ' + key + ' : ' + objError[key][0], 5)
      const tempValue = this.form.getFieldValue(key)
      obj[key] = {
        value: tempValue,
        errors: [new Error(objError[key][0])]
      }
    }
    this.form.setFields(obj)
  }

  render() {
    const { userReducer, mappingMode } = this.props
    const { isFieldsTouched } = this.state

    return (
      <div>
        <Prompt
          when={isFieldsTouched}
          message='You will lose any unsaved changes. Continue?'
        />
        <Row style={{ marginTop: '12px' }}>
          <FormProvider>
            <Form>
              {fields.map((elem) => {
                return (
                  <DynamicField
                    {...elem}
                    key={elem.dataIndex}
                    onBlur={this.onBlur}
                    userReducer={userReducer}
                    dataRecord={get(mappingMode, elem.dataIndex)}
                  />
                )
              })}
              <FormContext.Consumer>
                {form => {this.form = form}}
              </FormContext.Consumer>
            </Form>
          </FormProvider>
        </Row>
        <Row type='flex' justify='space-between' align='middle' style={{ backgroundColor: '#fff', padding: '12px 0', borderTop: '1px solid #e8e8e8'}}>
          <Col>
            {/* <Button type='danger'>Button Danger</Button> */}
          </Col>
          <Col>
            <Row type='flex' gutter={[12, 12]}>
              {isFieldsTouched && (
                <Col>
                  <Button onClick={this.onRevert}>
                    Revert to Saved
                  </Button>
                </Col>
              )}
              <Col>
                <Button icon={this.state.isSaving ? 'loading' : 'save'} type='primary' onClick={this.onSave}>
                  Update
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    )
  }
}

PageSetting.propTypes = {
  userReducer: PropTypes.object.isRequired,
  mappingMode: PropTypes.object.isRequired,
  setMappingMode: PropTypes.func,
}

PageSetting.defaultProps = {
  setMappingMode: () => null,
}

export default PageSetting