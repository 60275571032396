import { BaseDetail } from 'App/Screens/SubModules/Component'
import fields from 'App/Screens/Job/RateTypes/fields'
import { JobGradeReference } from './References'

class PageDetail extends BaseDetail {
  constructor(props) {
    super(props)

    this.urlKey = 'module-job-rateType-detail'
    this.title = 'Rate Type'
    this.urlList = '/job-management/rate-type'
    this.headerDescriptions = [
      { key: 'type', label: 'Type' },
      { key: 'qty', label: 'Qty' },
      { key: 'created', label: 'Created', type: 'datetime' },
      { key: 'created_by.username', label: 'Created By' },
      { key: 'modified', label: 'Modified', type: 'datetime' },
      { key: 'modified_by.username', label: 'Modified By' },
    ]
    this.fields = fields
    this.panes = [JobGradeReference]
    this.refField = {
      name: 'rate_type',
      type: 'object',
      valKey: 'pk',
      valLabel: 'type',
    }
  }
}

export default PageDetail