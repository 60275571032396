import React from 'react'
import PropTypes from 'prop-types'
import { Button, Card, Col, Empty, Icon, Modal, Row, message } from 'antd'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import { cloneDeep, get } from 'lodash'
import { Lib, RequestV2 as Request } from 'App/Utils'
import RowItem from './RowItem'
import PageError from 'App/Component/PageError'
import LoadingSpin from 'App/Component/LoadingSpin'
import PromptContainer from 'App/Component/PromptContainer'
import SelectQuery from 'App/Component/SelectQuery'

const ORDER_ITERATION = 10

class CardItem extends React.PureComponent {
  constructor(props) {
    super(props)

    this.page = 1
    this.limit = 100
    this.paramKeys = ['page', 'limit']
    this.state = {
      isLoading: true,
      isLoadMore: false,
      isTouched: false,
      errorResponse: null,
      responseData: null, // please cloneDeep when copy results
      results: [],
      recordsHasChanged: 0,
      selectedObjs: {},
      visibleFilter: false,
    }
  }

  getObjParam = () => {
    const objParam = {}
    for (let i = 0; i < this.paramKeys.length; i += 1) {
      const key = this.paramKeys[i]
      objParam[key] = this[key]
    }
    return objParam
  }

  reload = () => {
    this.page = 1
    this.setState({ responseData: null, results: [], isTouched: false, recordsHasChanged: 0 }, () => {
      this.read()
    })
  }

  read = (keyLoading = 'isLoading', callback = () => null, isLoadMore) => {
    this.setState({ [keyLoading]: true }, () => {
      Request(
        'get',
        this.props.urlKeyListOrder,
        {},
        this.getObjParam(),
        [this.props.subsectionId],
        this.readSuccess,
        this.readFailed,
        { keyLoading, callback, isLoadMore }
      )
    })
  }

  readSuccess = (response, extra) => {
    const responseData = extra.isLoadMore ? {
      ...response.data,
      results: [...this.state.results, ...response.data.results]
    } : response.data

    this.setState({
      [extra.keyLoading]: false,
      responseData,
      results: cloneDeep(responseData.results),
      errorResponse: null
    }, () => {
      extra.callback(true, response.data)
    })
  }

  readFailed = (error, extra) => {
    Lib.errorMessageHandler(get(error, 'response'))
    this.setState({ [extra.keyLoading]: false, errorResponse: get(error, 'response') }, () => {
      extra.callback(false, error)
    })
  }

  loadMore = (callback = () => null) => {
    this.page += 1
    this.read('isLoadMore', (isSuccess, response) => {
      callback(isSuccess, response)
      if (!isSuccess) {
        this.page -= 1
      }
    }, true)
  }

  toggleFilter = () => {
    this.setState(prevState => ({ visibleFilter: !prevState.visibleFilter }))
  }

  onFilterChange = (value, filter) => {
    this[filter.key] = get(value, 'key', null)
    if (!this.paramKeys.find(paramKey => paramKey === filter.key)) this.paramKeys.push(filter.key)
    this.read()
  }

  revertToSaved = () => {
    this.confirm({title: 'Revert to saved'}, confirmed => {
      if (confirmed) {
        this.setState({ isTouched: false, recordsHasChanged: 0, results: cloneDeep(this.state.responseData.results) })
      }
    })
  }

  resetOrder = () => {
    this.confirm(
      {
        title: 'Remove all sets order in this subsection?',
        content: 'If you press the OK button, all orders on this Sets will be empty and ordering by default (Alphabhet Sets Name)',
      },
      (isConfirmed) => {
        if (isConfirmed) {
          return new Promise((resolve, reject) => {
            // 1. Load more jika ada.
            this.readAll((isReadAllSuccess, error) => {
              if (isReadAllSuccess) {
                // 2. Save bulk with order=null
                this.saveOrderNull((isSaveSuccess, resSave) => {
                  if (isSaveSuccess) {
                    resolve()
                    message.success('All sets order in this subsection has been removed')
                    // 3. Reload the Sets List
                    this.reload()
                  } else {
                    reject()
                    Lib.errorMessageHandler(get(resSave, 'response'))
                  }
                })
              } else {
                reject()
                Lib.errorMessageHandler(get(error, 'response'))
              }
            })
          })
        }
      }
    )
  }

  readAll = (callback = () => null) => {
    const { responseData } = this.state
    if (responseData.next) {
      this.loadMore((isSuccess, response) => {
        if (isSuccess) {
          this.readAll(callback)
        } else {
          callback(false, response)
        }
      })
    } else {
      callback(true)
    }
  }

  saveOrderNull = (callback = () => null) => {
    const { results } = this.state
    const values = []
    for (let i = 0; i < results.length; i += 1) {
      values.push({
        pk: results[i].pk,
        order: null
      })
    }
    Request(
      'post',
      this.props.urlKeySave,
      {},
      values,
      [],
      response => callback(true, response),
      error => callback(false, error),
    )
  }

  onClose = () => {
    this.confirm(null, confirmed => {
      if (confirmed) {
        this.setState({ isTouched: false }, () => {
          this.props.onClose(this.props.subsectionId)
        })
      }
    }, !this.state.isTouched)
  }

  confirm = (opts, callback, force) => {
    if (force) {
      callback(true)
    } else {
      Modal.confirm({
        title: 'Unsaved changes',
        content: `You will lose any unsaved changes in ${this.props.title}. Continue?`,
        okType: 'danger',
        onOk: () => callback(true),
        onCancel: () => callback(false),
        ...opts,
      })
    }
  }

  // order have been saved
  clearTouched = () => {
    const { responseData, results } = this.state
    this.setState({
      isTouched: false,
      recordsHasChanged: 0,
      responseData: {
        ...responseData,
        results: cloneDeep(results),
      }
    })
  }

  // onRowClick = (prefillSetId, event) => {
  //   event.persist()
  //   if (event.defaultPrevented) return

  //   if (event.button !== 0) return

  //   event.preventDefault()

  //   this.performAction(prefillSetId, event)

  //   window.getSelection().removeAllRanges()
  // }

  // wasToggleInSelectionGroupKeyUsed = (event) => {
  //   const isUsingWindows = navigator.platform.indexOf('Win') >= 0
  //   return isUsingWindows ? event.ctrlKey : event.metaKey
  // }

  // wasMultiSelectKeyUsed = (event) => event.shiftKey

  // performAction = (prefillSetId, event) => {
  //   if (this.wasToggleInSelectionGroupKeyUsed(event)) {
  //     this.toggleSelection(prefillSetId, true)
  //     return
  //   }

  //   if (this.wasMultiSelectKeyUsed(event)) {
  //     this.multiSelectTo(prefillSetId)
  //     return
  //   }
    
  //   this.toggleSelection(prefillSetId)
  // }

  // toggleSelection = (prefillSetId, withCtrl) => {
  //   this.lastKey = prefillSetId
  //   const { selectedObjs } = this.state
  //   if (withCtrl) {
  //     if (selectedObjs[prefillSetId]) {
  //       delete selectedObjs[prefillSetId]
  //     } else {
  //       selectedObjs[prefillSetId] = true
  //     }
  //     this.forceUpdate()
  //   } else {
  //     this.setState({ selectedObjs: { [prefillSetId]: true } })
  //   }
  // }

  // multiSelectTo = (prefillSetId) => {
  //   const { results, selectedObjs } = this.state
  //   if (this.lastKey) {
  //     let isStarted = false
  //     let isEnded = false
  //     for (let i = 0; i < results.length; i += 1) {
  //       if (results[i].pk === prefillSetId || `${results[i].pk}` === `${this.lastKey}`) {
  //         if (!isStarted) isStarted = true
  //         else isEnded = true
  //       }
  //       if (isStarted) selectedObjs[results[i].pk] = true
  //       if (isEnded) break
  //     }
  //     this.setState({ selectedObjs: {...selectedObjs} })
  //   } else {
  //     this.toggleSelection(prefillSetId)
  //   }
  //   this.lastKey = prefillSetId
  // }

  validateFields = () => {
    const values = []
    if (this.state.isTouched) {
      const { recordsHasChanged, results } = this.state
      const maxIteration = recordsHasChanged >= results.length ? results.length - 1 : recordsHasChanged
      // const objSubsection = !this.props.withoutSubsection && { subsection_id: this.props.subsectionId }
      for (let i = 0; i <= maxIteration; i += 1) {
        values.push({
          pk: this.state.results[i].pk,
          order: i * ORDER_ITERATION,
          // ...objSubsection,
        })
      }
    }
    return values
  }

  componentDidMount() {
    this._isMounted = true
    this.read()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    const { filters, subsectionId, title } = this.props
    const { isLoading, isLoadMore, isTouched, responseData, results, errorResponse, selectedObjs, visibleFilter } = this.state

    return (
      <Card
        size='small'
        title={
          <>
            <span>
              {isTouched && <Icon type='rollback' style={{ cursor: 'pointer', marginRight: '6px' }} title='Revert to saved' onClick={this.revertToSaved} />}
              {true && <Icon type='retweet' style={{ cursor: 'pointer', marginRight: '6px' }} title='Reset order' onClick={this.resetOrder} />}
              {filters.length > 0 && <Icon type='setting' style={{ cursor: 'pointer', marginRight: '6px' }} onClick={this.toggleFilter} />}
              {title}
            </span>
            <div style={{ display: visibleFilter ? 'block' : 'none' }}>
              {filters.map(filter => (
                <Row key={filter.key} type='flex' justify='space-between' style={{ marginRight: '6px', marginBottom: '2px' }}>
                  <Col style={{ maxWidth: '92px' }}>
                    {filter.label} :
                  </Col>
                  <Col style={{ width: 'calc(100% - 100px)'}}>
                    <SelectQuery
                      ref={ref => {if (filter.onRef) filter.onRef(ref)}}
                      className='w-100'
                      size='small'
                      urlKey={filter.urlKey}
                      paramProps={filter.getParamProps && filter.getParamProps(this)}
                      onChange={(val) => this.onFilterChange(val, filter)}
                    />
                  </Col>
                </Row>
              ))}
            </div>
          </>
        }
        headStyle={{ backgroundColor: isTouched ? '#ffebe6' : '#ebecf0' }}
        bodyStyle={{ backgroundColor: '#ebecf0' }}
        extra={<Icon type='close' onClick={this.onClose} />}
      >
        {isLoading ? (
          <LoadingSpin height='450px' />
        ) : !responseData ? (
          <PageError errorResponse={errorResponse} withErrorMessage={false} onReload={this.read} />          
        ) : (
          <div style={{ minHeight: '100px', maxHeight: 'calc(100vh - 350px)', overflowY: 'auto' }} onScroll={this.onListScroll}>
            <PromptContainer defaultTouched={isTouched} />
            <Droppable droppableId={`${subsectionId}`}>
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {results.length > 0 ? (
                    <>
                      {results.map((setItem, index) => (
                        <Draggable key={setItem.pk} index={index} draggableId={`${setItem.pk}`}>
                          {(provided) => (
                            <div
                              className='mb-2'
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                              // onClick={(event) => this.onRowClick(setItem.pk, event)}
                            >
                              <RowItem setItem={setItem} isSelected={selectedObjs[setItem.pk]} />
                            </div>
                          )}
                        </Draggable>
                      ))}
                    </>
                  ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  )}
                  {provided.placeholder}
                  {responseData.next && (
                    <Row className='w-100' type='flex' justify='center'>
                      <Col>
                        <Button icon='reload' loading={isLoadMore} onClick={() => this.loadMore()}>
                          Load more
                        </Button>
                      </Col>
                    </Row>
                  )}
                </div>
              )}
            </Droppable>
          </div>
        )}
      </Card>
    )
  }
}

CardItem.propTypes = {
  subsectionId: PropTypes.number.isRequired,
  urlKeyListOrder: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  filters: PropTypes.array,
  onClose: PropTypes.func,
}

CardItem.defaultProps = {
  filters: [],
  onClose: () => null,
}

export default CardItem