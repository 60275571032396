export default [
  {
  //   title: 'Mode',
  //   type: 'SelectQuery',
  //   data: 'module-merchant-mappingMode',
  //   initialValue: '',
  //   required: true,
  //   note_text: '',
  //   // additional: 'edit|add',
  //   selectedKey: 'pk',
  //   selectedValue: 'name',
  //   dataIndex: 'mode',
  //   order: 0,
  // }, {
    title: 'Product code',
    type: 'text',
    data: '',
    required: true,
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'product_code',
    order: 1,
  }, {
    title: 'Sku',
    type: 'text',
    data: '',
    required: true,
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'sku',
    order: 2,
  }, {
    title: 'Description',
    type: 'text',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'description',
  // }, {
  //   title: 'Group',
  //   type: 'SelectQuery',
  //   data: 'module-merchant-group',
  //   initialValue: '',
  //   required: true,
  //   note_text: '',
  //   // additional: 'edit|add',
  //   selectedKey: 'pk',
  //   selectedValue: 'name',
  //   dataIndex: 'group',
  },
]