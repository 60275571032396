import React from "react";
import Calender from "App/Component/Singlepage/Components/Calender";
import { RequestV2 as Request } from "App/Utils";
import {Spin} from "antd"
import {Lib} from "App/Utils/"

class Manage extends React.PureComponent {

  state = {
    userCalender: [],
    loading: true
  }

  onFailed=()=>{
    alert("failed")
  }

  componentWillUnmount() {
    Lib.windowStop();
  }

  onSuccess=(response)=>{
    this.setState({ userCalender: response.data, loading: false })
  }

  retrieve=()=> {
    this.request = Request(
      "get",
      "user-calender-read",
      {},
      {},
      [this.props.match.params.id],
      this.onSuccess,
      this.onFailed,
      undefined,
      true
    );
  }
  componentDidMount() {
    this.retrieve()
  }

  render() {
    const {userCalender, loading} = this.state

    return loading ?
      <div style={{ paddingTop: 100, paddingBottom: 100, textAlign: "center", backgroundColor: "#ffffff" }}>
        <Spin tip="Loading..." />
      </div> :
      <Calender originData={userCalender} />
  }
}

export default Manage;
