import React, {PureComponent} from 'react';
import {Button, Drawer, Form, message, notification} from "antd";
import DynamicField from "./DynamicField";
import {FormContext, FormProvider} from "./Context/ContextForm";
import {RequestV2 as Request, Lib} from "App/Utils";
import ComponentAutocomplete from 'App/Component/Autocomplete'
import LoadingSkleton from "./LoadingSkleton";
import styled from "styled-components";
import {get, set, snakeCase} from "lodash";
import moment from 'moment';
import Lib2 from './Singlepage/Lib2';

class DrawerSq extends PureComponent {
  state = {
    data: [],
    loading: false,
    historySubDrawer: [],
    indexing: -1,
    drawerAnimateEnd: false,
  };
  fieldsRecorded = {}
  fileList = []

  normFile = (e, limit, isAPI = true) => {
    const {data} = this.state

    if (Array.isArray(e)) {
      return e;
    }

    if (limit > 0) {
      limit *= -1
    }

    if (e.file.status === 'removed') {
      if (data && data.hasOwnProperty('pk') && data.pk && isAPI) {
        // Kode ini menghapus semua FILE/ICON/etc pada pk tersebut
        // Ganti jika ingin hapus per-file
        const isConfirm = window.confirm('Are you sure you want to delete this file?')
        if (isConfirm) {
          this.onDeleteFile(data.pk)
          this.fileList = this.fileList.filter(file => file.uid !== e.file.uid)
        } else {
          this.fileList.push(e.file)
          this.fileList[this.fileList.length-1].status = 'done'
          this.fileList = this.fileList.slice(limit)
        }
      } else {
        this.fileList = this.fileList.filter(file => file.uid !== e.file.uid)
      }
    } else {
      this.fileList = e.fileList
    }

    this.fileList = this.fileList.slice(limit)

    return e && this.fileList;
  }

  recordField = (value, dataIndex, type, dataRecord, form) => {
    const { historySubDrawer } = this.state
    // const value = this.getValueFromEvent(e, type)
    set(this, `fieldsRecorded.${historySubDrawer.url}.${dataIndex}`, value)
    if (type === 'number' && !value) {
      const findDefault = ComponentAutocomplete[get(historySubDrawer, 'url')]
      if (findDefault) {
        const field = findDefault.find(item => item.dataIndex === dataIndex)
        if (Object(field).hasOwnProperty('initialValue') && form) {
          form.setFieldsValue({[dataIndex]: field.initialValue || 0})
          set(this, `fieldsRecorded.${historySubDrawer.url}.${dataIndex}`, field.initialValue || 0)
        }
      }
    }
  }

  getValueFromEvent = (event, type) => {
    const stackKey = {
      text: ['target', 'value'],
      textarea: ['target', 'value'],
      number: ['target', 'value'],
      email: ['target', 'value'],
      password: ['target', 'value'],
      // checkbox: ['target', 'checked'],
      checkbox: [], // New Switch
      select: [],
      SelectQuery: [],
      select_multiple: [],
      date: [],
      datetime: [],
      // table, file, fileimg not yet
    }

    const format = {
      date: 'YYYY-MM-DD',
      datatime: 'YYYY-MM-DD HH:mm:ss'
    }

    let val = event
    if (format[type]) {
      val = moment(val).format(format[type])
    } else if (stackKey[type]) {
      for (const key of stackKey[type]) {
        val = val[key]
      }
    }
    return val
  }

  onDeleteFile(pk = -1) {
    const {userReducer} = this.props
    const {historySubDrawer} = this.state
    const urlData = historySubDrawer.url

    this.request = Request(
      "delete",
      urlData + "File-delete",
      {},
      {},
      [pk],
      this.deleteFileSuccess,
      this.deleteFileFailed,
    );
  }

  deleteFileSuccess = (response) => {
    if (response) {
      if (response.status === 204) {
        message.success('Delete Success')
      }
    }
  }

  deleteFileFailed = (error) => {
    let errorObj = error.response.data
    for (let key in errorObj) {
      if (errorObj.hasOwnProperty(key)) {
        message.error('' + errorObj[key])
      }
    }
  }

  onSave(form) {
    const {validateFieldsAndScroll, resetFields, validateFields} = form;
    const { historySubDrawer } = this.state
    const currentOperation = get(historySubDrawer, `historyOperation`)
    if(this.props.extraactions && this.state.historySubDrawer.url === 'prefillLines') {
      validateFields((err, values) => {
        if (!err) {
          const {operationSQ} = this.props;
          if (currentOperation === 'edit') {
            this.edit(values, form);

          } else if (currentOperation === 'add') {
            this.save(values, form);
          }
        }
      })
    }else {
      validateFieldsAndScroll({scroll: {offsetTop: 150}}, (err, values) => {
        if (!err) {
          const {operationSQ} = this.props;
          if (currentOperation === 'edit') {
            this.edit(values, form);

          } else if (currentOperation === 'add') {
            this.save(values, form);
          }
        }
      })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // compare indexing is more responsive more than array historySubDrawer.
    if (nextProps.indexing !== this.state.indexing) {
      this.setState({
        historySubDrawer: {
          url: nextProps.historySubDrawer[nextProps.indexing] && nextProps.historySubDrawer[nextProps.indexing].url,
          idData: nextProps.historySubDrawer[nextProps.indexing] && nextProps.historySubDrawer[nextProps.indexing].idData,
          historyOperation: nextProps.historySubDrawer[nextProps.indexing] && nextProps.historySubDrawer[nextProps.indexing].historyOperation,
        },
        indexing: nextProps.indexing,
      }, this.read);
    }
  }

  setLoading = (val) => {
    this.setState({loading: val})
  };

  messageCustom = (type, msg) => {
    if (type !== 'loading') {
      message.destroy();
      this.setLoading(false);
    } else {
      this.setLoading(true);
    }
    return (
      message[type](msg)
    )
  };

  edit = (values, form) => {
    const {data} = this.state;
    const {url} = this.state.historySubDrawer;
    const pk = (data) ? data['pk'] : undefined;
    const {extraactions, historySubDrawer, toggleDrawerSQ} = this.props
    // if(!extraactions && url !== 'preffilLines') {
    //   Lib.checkValues(values);
    // }
    if (url==='exportMappingLines') {
      values.export_mapping = data.export_mapping.pk
    }
    this.messageCustom('loading', 'Edit in progress..');
    if(this.props.extraactions){
      if(url==='prefillLines'){
        let backdata = values
        for (let index in values){
          if(typeof values[index] === 'object') {
              backdata[index] = {pk: values[index].key, label: values[index].label}
          }
        }
        if(backdata.product){
          backdata.product = {pk: backdata.product.pk, name: backdata.product.label}
        }
        backdata.key = historySubDrawer[0].idData.key
        backdata.pk = historySubDrawer[0].idData.key
        backdata.prefill_set = {pk: values.prefill_set.pk, name: this.props.headerName}
        extraactions(backdata, 'attachment', 'edit')
        this.messageCustom('success', 'Update Success');
        this.props.toggleDrawerSQ('back', this.state.url);
      }else if(url=== 'subsections') {
        let data = values
        data.section = data.section.label
        data.pk = historySubDrawer[0].idData.pk
        extraactions(data, 'attachment', 'edit')
        this.messageCustom('success', 'Update Success');
        toggleDrawerSQ('back', this.state.url);
      } else if (url === 'exportMappingLines') {
        let data = values
        data.key = this.props.historySubDrawer[0].idData.key
        extraactions(data, 'edit')
        this.messageCustom('success', 'Update Success');
        this.props.toggleDrawerSQ('back', this.state.url);
      }
    }else {
      let newValues = Lib2.getValuesWithFile(values, url)
      Lib.checkValues(newValues)
      try {
        this.request = Request(
            "put",
            url + "-update",
            {},
            newValues,
            [pk],
            this.editSuccess,
            this.onFailed,
            form,
        );
      } catch (err) {
        console.error('Invalid url key on update data')
      }
    }
  };

  editSuccess = (response, form) => {
    const p = this.props
    const s = this.state;
    const oldUrl = s.historySubDrawer.url
    if(s.historySubDrawer.historyOperation !== 'edit' && s.historySubDrawer.url !=='subsections'){
      p.updateDrawerData(response.data);
    }
    if (p.hasOwnProperty('reloadRead')){
      p.reloadRead();
    }
    let field = snakeCase(s.historySubDrawer.url).slice(0, -1)
    if (field === 'line_usage')
      field = 'usage'

    if (p.historySubDrawer.length === 1) {
      this.fieldsRecorded = {}
    } else {
      delete this.fieldsRecorded[oldUrl]
      this.setState({ drawerAnimateEnd: false }, () => {
        this.setState({ drawerAnimateEnd: true })
      })
    }

    if(p.oneForm){
      if (p.historySubDrawer.length === 1) {
        p.oneForm({
          [field] : {
            key: response.data.pk,
            label: response.data.label ? response.data.label : response.data.name
          }
        })
      }
    }else if(p.changeData){
      const label = response.data.label ? "label" : "name"
      const obj = {
        pk: response.data.pk,
        [label]: response.data[label]
      }
      p.changeData('dataRecord', {...p.dataRecord, [field]: obj})
      if (get(p, ['location', 'state'])) {
        if(p.location.state.dataRecord)
          p.location.state.dataRecord[field] = obj
        else
          p.location.state.dataRecord = {[field] : obj}
      }
    }
    this.messageCustom('success', 'Edit Success');
    p.toggleDrawerSQ('back', s.historySubDrawer.url, response.data, undefined, () => {
      const { historySubDrawer } = this.props;
      // after pop subdrawer on props in this callback
      if (historySubDrawer.length !== 0 || true) {
        form.setFieldsValue({
          [field]: {
            key: response.data.pk,
            label: response.data.label ? response.data.label : response.data.name
          }
        })
      }
    });
    // resetFields();
  };

  save = (values, form) => {
    const lockdata = values
    try {
      const {url} = this.state.historySubDrawer;
      const {extraactions, headerName} = this.props
      this.messageCustom('loading', 'Save in progress..');
      if(extraactions){
        let data = Object.assign({}, values)
        let rundkey = Math.floor(Math.random() * 1000000);
        if(url === 'subsections') {
          data.identifier = headerName + ' - ' + data.name
          data.section = headerName
          data.pk = rundkey
          data.key = rundkey
          extraactions(data, 'attachment')
          this.messageCustom('success', 'Save Success');
          this.props.toggleDrawerSQ('back', this.state.url);
        } else if(url === 'prefillLines') {
          let backdata = values
          backdata.key = rundkey
          backdata.pk = rundkey
          for (let index in lockdata){
            if(typeof lockdata[index] === 'object') {
                backdata[index] = {pk: lockdata[index].key, label: lockdata[index].label}
            }
          }
          if(backdata.product){
            backdata.product = {pk: backdata.product.pk, name: backdata.product.label}
          }
          backdata.prefill_set = {pk: rundkey, name: headerName}
          extraactions(backdata, "attachment")
          this.messageCustom('success', 'Save Success');
          this.props.toggleDrawerSQ('back', this.state.url);
        } else if (url === 'exportMappingLines') {
          extraactions(values)
          this.messageCustom('success', 'Save Success')
          this.props.toggleDrawerSQ('back', this.state.url)
        }
      } else {
        if (url === 'exportMappingLines') {
          values.export_mapping = this.props.historySubDrawer[0].idData.export_mapping
        }
        let newValues = Lib2.getValuesWithFile(values, url)
        Lib.checkValues(newValues);
        Request(
          "post",
          url + "-create",
          {},
          newValues,
          [],
          this.saveSuccess,
          this.onFailed,
          form,
        );
      }
    } catch (err) {
      console.error('error:', err)
    }
  };

  saveSuccess = (response, form) => {
    const p = this.props
    const s = this.state
    const oldUrl = s.historySubDrawer.url
    this.messageCustom('success', 'Save Success');
    let field = snakeCase(s.historySubDrawer.url).slice(0, -1)
    if (field === 'line_usage')
      field = 'usage'

    if (p.historySubDrawer.length === 1) {
      this.fieldsRecorded = {}
    } else {
      delete this.fieldsRecorded[oldUrl]
      this.setState({ drawerAnimateEnd: false }, () => {
        this.setState({ drawerAnimateEnd: true })
      })
    }

    if(p.oneForm){
      if (p.historySubDrawer.length === 1) {
        p.oneForm({
          [field] : {
            key: response.data.pk,
            label: response.data.label ? response.data.label : response.data.name
          }
        })
      }
    }else if(p.changeData){
      const label = response.data.label ? "label" : "name"
      const obj = {
        pk: response.data.pk,
        [label]: response.data[label]
      }
      p.changeData('dataRecord', {...p.dataRecord, [field]: obj})
      if(p.location.state.dataRecord)
        p.location.state.dataRecord[field] = obj
      else
        p.location.state.dataRecord = {[field] : obj}
    }
    if (p.hasOwnProperty('reloadRead') && !p.extraactions){
      p.reloadRead();
    }
    p.toggleDrawerSQ('back', this.state.url, undefined, undefined, () => {
      const { historySubDrawer } = this.props;
      if (historySubDrawer.length !== 0) {
        // after pop subdrawer on props in this callback
        form.setFieldsValue({
          [field]: {
            key: response.data.pk,
            label: response.data.label ? response.data.label : response.data.name
          }
        })
      }
    });
    // resetFields();
  };

  onFailed = (error) => {
    let errorObj = error.response.data;

    this.messageCustom('error', 'Action failed');
    let count = 0
    for (let key in errorObj) {
      count += 1
      if (count > 3) {
        break
      } else if (errorObj.hasOwnProperty(key)) {
        notification.error({
          message: `Error on field ${key}`,
          duration: 0,
          description: errorObj[key]
        })
      }
    }
    this.setState({ loading: false })
  };

  closeDrawer = (operation = 'back') => {
    const {url, idData} = this.state.historySubDrawer;
    const { toggleDrawerSQ } = this.props
    if (operation === 'close') {
      toggleDrawerSQ('close');
      this.fieldsRecorded = {}
    } else {
      delete this.fieldsRecorded[url]
      this.setState({ drawerAnimateEnd: false }, () => {
        toggleDrawerSQ('back', url, idData);
        this.setState({ drawerAnimateEnd: true })
      })
    }
    notification.destroy()
  };

  read = () => {
    try {
      const {url, idData, historyOperation } = this.state.historySubDrawer;
      if (idData && url && historyOperation !== 'add') {
        if (this.props.extraactions) {
          let rows = this.props.listHeaderdata
          let rowIndex = rows.findIndex((val) => (val.pk) ? val.pk === idData.pk : val.key === idData.key)
          this.setState({
            data: rows[rowIndex]
          })
        } else {
          Request(
              "get",
              url + "-detail",
              {},
              {},
              [idData.key],
              this.readSuccess,
              this.readFailed
          );
        }
      }else if(idData && historyOperation === 'add'){
        this.setState({
          data: idData,
        })
      }else {
        this.setState({
          data: [],
        })
      }
    } catch (err) {
      console.error('Invalid url key on read data')
    }

  };

  readSuccess = (response) => {
    message.destroy();

    /**
        Jika ada kondisi file memungkinkan Array.
        Ganti code di dalam if ini
      */
    const type = ['file', 'icon']

    for (let value of type) {
      if (response.data && response.data[value]) {
        const startIndex = response.data[value].lastIndexOf('/') + 1
        const nameFile = response.data[value].substring(startIndex)
        let attachment = {
          uid: -1,
          name: nameFile,
          status: 'done',
          url: response.data[value],
        }
        response.data[value] = [attachment]
      }
    }

    this.setState({
      data: response.data,
    });
  };

  readFailed = () => {
    message.destroy();
    message.error('Cant Connect To Server');
  };

  hasErrors(fieldsError) {
    // Return Boolean
    return Object.keys(fieldsError).some(field => fieldsError[field]);
  }

  preToggleDrawerSQ = (operationSQ, urlSQ, idData) => {
    const { toggleDrawerSQ } = this.props
    // This is for unmount DynamicField. Karena dengan decorator yang sama tanpa unmount, value akan berisi sama
    this.setState({ drawerAnimateEnd: false }, () => {
      toggleDrawerSQ(operationSQ, urlSQ, idData)
      this.setState({ drawerAnimateEnd: true })
    })
  }

  render() {
    const {userReducer, toggleDrawerSQ, indexing} = this.props;
    let isTable = false
    const {data, historySubDrawer, drawerAnimateEnd} = this.state;
    const titleDrawer = historySubDrawer && historySubDrawer.historyOperation ? Lib.CamelToSentence(historySubDrawer.historyOperation) : '';
    const configDrawer = {
      placement: "right",
      closable: true,
      maskClosable: false,
      width: 'calc(100% - 500px)',
      onClose: this.closeDrawer,
      visible: this.props.visible,
      title: `${titleDrawer} ${historySubDrawer.url ? Lib.CamelToSentence(historySubDrawer.url) : ''}`,
    };

    const totalCount = historySubDrawer.url && ComponentAutocomplete[historySubDrawer.url] && ComponentAutocomplete[historySubDrawer.url].length;
    const url = get(historySubDrawer, 'url') || ''

    return (
      <Drawer {...configDrawer} afterVisibleChange={(visible) => this.setState({drawerAnimateEnd: visible}) }>
        <FormProvider>
          <Form layout="horizontal">
            {drawerAnimateEnd ?
              historySubDrawer.url && ComponentAutocomplete[historySubDrawer.url] && ComponentAutocomplete[historySubDrawer.url].map((elem, index) => {
              return (
                <DynamicField
                  {...elem}
                  dataRecord={
                    elem.dataIndex && (
                      Object(get(this, `fieldsRecorded.${url}`)).hasOwnProperty(elem.dataIndex)
                      ? get(this, `fieldsRecorded.${url}.${elem.dataIndex}`)
                      : get(data, elem.dataIndex.split('.'))
                    )
                  }
                  toggleDrawerSQ={this.preToggleDrawerSQ}
                  operation={historySubDrawer.historyOperation}
                  isTable={isTable}
                  userReducer={userReducer}
                  key={index}
                  normFile={this.normFile}
                  onBlur={this.recordField}
                />
              );
            })
            :
              <LoadingSkleton count={totalCount}/>
            }
            <FormContext.Consumer>
              {(form) => {
                return (
                  <StyledFooter>
                    {indexing > 0 &&
                    <StyledButton type="danger" style={{float: 'left'}} onClick={(e) => this.closeDrawer('close')}>
                      Close All
                    </StyledButton>
                    }
                    <StyledButton type="danger" onClick={this.closeDrawer}>
                      Close
                    </StyledButton>
                    {(historySubDrawer.historyOperation === 'add') ?
                      <StyledButton onClick={() => this.onSave(form)} type="primary"
                                    disabled={this.hasErrors(form.getFieldsError())}>Save</StyledButton>
                      : (historySubDrawer.historyOperation === 'view') ?
                        <StyledButton type="primary" onClick={() => this.props.toggleDrawerSQ('edit', this.state.historySubDrawer.url, this.state.historySubDrawer.idData)}>
                          Edit
                        </StyledButton>
                        :
                      <StyledButton onClick={() => this.onSave(form)} type="primary"
                                    disabled={this.hasErrors(form.getFieldsError())}>Update</StyledButton>
                    }
                  </StyledFooter>
                )
              }}
            </FormContext.Consumer>
          </Form>
        </FormProvider>
      </Drawer>
    );
  }
}

export default DrawerSq;

const StyledFooter = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
`;

const StyledButton = styled(Button)`
  margin-right: 8px;
`;
