const dimOpt = [
  { alias: 'width', value: 'Width' },
  { alias: 'height', value: 'Height' },
  { alias: 'length', value: 'Length' },
  { alias: 'area', value: 'Area' },
  { alias: 'measurement', value: 'Measurement' },
  { alias: 'custom nogs', value: 'Nogs (Custom)' },
  { alias: 'custom height', value: 'Height (Custom)' },
  { alias: 'custom pitch', value: 'Pitch (Custom)' },
]

const prefillOpt = [
  { alias: 'custom type', value: 'Type (Custom)' },
  { alias: 'custom stud', value: 'Stud Centres (Custom)' },
  { alias: 'custom nogs', value: 'Nogs (Custom)' },
  { alias: 'custom top plate', value: 'Top Plate (Custom)' },
  { alias: 'custom pitch', value: 'Pitch (Custom)' },
  { alias: 'custom insulation', value: 'Insulation (Custom)' },
  { alias: 'custom notes', value: 'Notes (Custom)' },
  { alias: 'custom height', value: 'Height (Custom)' },
  { alias: 'custom type - iron', value: 'Type Iron (Custom)' },
  { alias: 'custom gauge', value: 'Gauge (Custom)' },
]

const colPropsLabel = { md: 4, style: {borderBottom: '1px solid #e8e8e8', fontSize: '21px', fontWeight: 500} }
const colPropsLeft = { md: 4 }
const colPropsLeftAdditional = { md: 4, xxl: 0 }
const colPropsRight = { md: 17, xxl: 10 }
const colPropsRightAdditional = { md: 0, xxl: 10 }

const staticBbField = 'Using bluebeam field or use static'

export default [
  {
    title: 'General',
    type: 'label',
    colProps: colPropsLabel,
  },
  {
    title: 'Subject',
    dataIndex: 'subject',
    required: true,
    type: 'text',
    colProps: colPropsRight,
  },
  {
    type: 'label',
    colProps: colPropsLeftAdditional,
  },
  {
    title: 'Section',
    dataIndex: 'section',
    type: 'SelectQuery',
    data: 'sections',
    selectedKey: 'pk',
    selectedValue: 'name',
    dropdownClassName: 'width-auto',
    relations: ['subsection'],
    skip: true,
    colProps: colPropsRight,
    inputProps: { style: {maxWidth: '100%'} },
  },
  {
    type: 'label',
    colProps: colPropsLeft,
  },
  {
    title: 'Order',
    dataIndex: 'order',
    type: 'number',
    initialValue: null,
    colProps: colPropsRight,
  },
  {
    type: 'label',
    colProps: colPropsLeft,
  },
  {
    title: 'Subsection',
    dataIndex: 'subsection',
    required: true,
    type: 'SelectQuery',
    data: 'subsections',
    selectedKey: 'pk',
    selectedValue: 'name',
    dropdownClassName: 'width-auto',
    relations: ['static_prefill_set'],
    foreignKey: 'section',
    colProps: colPropsRight,
    inputProps: { allowClear: false, style: {maxWidth: '100%'} },
  },
  {
    title: 'Sets',
    type: 'label',
    colProps: colPropsLabel,
  },
  {
    title: 'Prefill set Pattern',
    dataIndex: 'prefill_set_pattern',
    type: 'select_multiple_static',
    data: prefillOpt,
    initialValue: null,
    colProps: colPropsRight,
    inputProps: { mode: 'multiple', tokenSeparators: [','] },
  },
  {
    type: 'label',
    colProps: colPropsLeft,
  },
  {
    title: 'Use Static Prefill Set',
    dataIndex: 'use_static_prefill_set',
    type: 'checkbox',
    initialValue: false,
    colProps: colPropsRight,
  },
  {
    type: 'label',
    colProps: colPropsLeft,
  },
  {
    type: 'label',
    colProps: colPropsRightAdditional,
  },
  {
    title: 'Static Prefill Set',
    dataIndex: 'static_prefill_set',
    type: 'SelectQuery',
    data: 'prefillSets',
    selectedKey: 'pk',
    selectedValue: 'name',
    dropdownClassName: 'width-auto',
    foreignKey: 'subsection',
    note_text: 'Please toggle active "Use Static Prefill Set"',
    colProps: colPropsRight,
    inputProps: { style: {maxWidth: '100%'} },
  },

  {
    title: 'Dimensions',
    type: 'label',
    colProps: colPropsLabel,
  },
  {
    title: 'Width',
    dataIndex: 'dimension_width',
    type: 'select',
    initialValue: null,
    data: dimOpt,
    colProps: colPropsRight,
    note_text: staticBbField + ' width',
  },
  {
    type: 'label',
    colProps: colPropsLeft,
  },
  {
    title: 'Use Static Width',
    dataIndex: 'use_static_width',
    type: 'checkbox',
    initialValue: false,
    colProps: colPropsRight,
  },
  {
    type: 'label',
    colProps: colPropsLeft,
  },
  {
    type: 'label',
    colProps: colPropsRightAdditional,
  },
  {
    title: 'Static Width',
    dataIndex: 'static_width',
    type: 'number',
    initialValue: null,
    colProps: colPropsRight,
    note_text: 'Please toggle active "Use Static Width"',
  },
  {
    type: 'label',
    colProps: colPropsLeft,
  },
  {
    title: 'Height',
    dataIndex: 'dimension_height',
    type: 'select',
    initialValue: null,
    data: dimOpt,
    colProps: colPropsRight,
    note_text: staticBbField + ' height',
  },
  {
    type: 'label',
    colProps: colPropsLeft,
  },
  {
    title: 'Use Static Height',
    dataIndex: 'use_static_height',
    type: 'checkbox',
    initialValue: false,
    colProps: colPropsRight,
  },
  {
    type: 'label',
    colProps: colPropsLeft,
  },
  {
    type: 'label',
    colProps: colPropsRightAdditional,
  },
  {
    title: 'Static Height',
    dataIndex: 'static_height',
    type: 'number',
    initialValue: null,
    colProps: colPropsRight,
    note_text: 'Please toggle active "Use Static Height"',
  },
  {
    type: 'label',
    colProps: colPropsLeft,
  },
  {
    title: 'Length',
    dataIndex: 'dimension_length',
    type: 'select',
    initialValue: null,
    data: dimOpt,
    colProps: colPropsRight,
    note_text: staticBbField + ' length',
  },
  {
    type: 'label',
    colProps: colPropsLeft,
  },
  {
    title: 'Use Static Length',
    dataIndex: 'use_static_length',
    type: 'checkbox',
    initialValue: false,
    colProps: colPropsRight,
  },
  {
    type: 'label',
    colProps: colPropsLeft,
  },
  {
    type: 'label',
    colProps: colPropsRightAdditional,
  },
  {
    title: 'Static Length',
    dataIndex: 'static_length',
    type: 'number',
    initialValue: null,
    colProps: colPropsRight,
    note_text: 'Please toggle active "Use Static Length"',
  },
  {
    type: 'label',
    colProps: colPropsLeft,
  },
  {
    title: 'Quantity',
    dataIndex: 'dimension_quantity',
    type: 'select',
    initialValue: null,
    data: dimOpt,
    colProps: colPropsRight,
    note_text: staticBbField + ' quantity',
  },
  {
    type: 'label',
    colProps: colPropsLeft,
  },
  {
    title: 'Use Static Quantity',
    dataIndex: 'use_static_quantity',
    type: 'checkbox',
    initialValue: false,
    colProps: colPropsRight,
  },
  {
    type: 'label',
    colProps: colPropsLeft,
  },
  {
    type: 'label',
    colProps: colPropsRightAdditional,
  },
  {
    title: 'Static Quantity',
    dataIndex: 'static_quantity',
    type: 'number',
    initialValue: null,
    colProps: colPropsRight,
    note_text: 'Please toggle active "Use Static Quantity"',
  },
]