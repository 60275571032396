import React from "react";
import Lottie from "react-lottie";
import { Spin } from "antd";

import * as loadingAnim from "App/Assets/lottie/loading1";

function PageLoading({ isLoading, ...props }) {
	return (
		<Spin
			{...props}
			indicator={
				<div
					style={{ width: props.width, height: props.height }}
					className="page-loading-indicator"
				>
					<Lottie
						options={{
							loop: true,
							autoplay: true,
							animationData: loadingAnim.default,
							rendererSettings: {
								preserveAspectRatio: "xMidYMid slice"
							}
						}}
						width={props.width}
						height={props.height}
					/>
				</div>
			}
			spinning={isLoading}
		>
			{props.children}
		</Spin>
	);
}

PageLoading.defaultProps = {
	width: 300,
	height: 300,
	isLoading: true
};

export default React.memo(PageLoading);
