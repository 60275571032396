import { Sites } from 'App/Config'

const HeaderMenu = [
  { label: 'Admin', icon: 'dashboard', url: '/admin', codename: 'admin' },
  { label: 'User & Group', icon: 'robot', url: '/user-group', codename: 'user_role' },
  { label: 'Branch & Queue', icon: 'branches', url: '/branch-queue', codename: 'branch' },
  { label: 'Product', icon: 'credit-card', url: '/product', codename: 'product' },
  { label: 'Merchant', icon: 'shop', url: '/merchant', codename: 'merchant' },
  { label: 'Job', icon: 'usb', url: '/job-management', codename: 'job' },
  { label: 'Expert System', icon: 'file-search', url: '/expert-system', codename: 'expert_system' },
  { label: 'Download IBESv3', icon: 'appstore', url: '/v3', codename: 'v3', forceHidden: !Sites.url.ibesv3 },
]

export default HeaderMenu