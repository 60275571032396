import React from 'react'
import PropTypes from 'prop-types'
import { Col, Empty, Icon, Row, Spin } from 'antd'
import { get } from 'lodash'
import { RequestV2 as Request } from 'App/Utils'
import PageError from 'App/Component/PageError'
import SetItem from './SetItem'

class AvailableSets extends React.PureComponent {
  constructor(props) {
    super(props)

    this.page = 1
    this.limit = 100
    this.status = 'active'
    this.paramKeys = ['page', 'limit', 'status']
    this.state = {
      isLoading: !!get(props.params, 'subsection'),
      isReloading: false,
      errorResponse: null,
      listData: { results: [] },
      objSelectedKeys: this.getObjSelectedKeys(props.defaultSelectedKeysObj),
    }
  }

  getDefaultListData = (selectedKeysObj) => selectedKeysObj.length ? { results: selectedKeysObj } : null

  getObjSelectedKeys = (selectedKeysObj) => {
    const objSelectedKeys = {}
    for (let i = 0; i < selectedKeysObj.length; i += 1) {
      if (Number(get(selectedKeysObj[i], 'pk')) || typeof get(selectedKeysObj[i], 'pk') === 'string') {
        objSelectedKeys[get(selectedKeysObj[i], 'pk')] = true
      }
    }
    return objSelectedKeys
  }

  getObjParam = () => {
    const objParam = {}
    for (let i = 0; i < this.paramKeys.length; i += 1) {
      objParam[this.paramKeys[i]] = this[this.paramKeys[i]]
    }
    return { ...objParam, ...this.props.params }
  }

  read = (keyLoading = 'isLoading', isLoadMore, objState, callback = () => null) => {
    const objSelectedKeys = (this.props.autoClear && keyLoading === 'isLoading') ? {} : this.state.objSelectedKeys
    this.setState({ [keyLoading]: true, objSelectedKeys, ...objState }, () => {
      if (!isLoadMore) this.page = 1

      Request(
        'get',
        this.props.urlKey,
        {},
        this.getObjParam(),
        [],
        this.readSuccess,
        this.readFailed,
        { isLoadMore, keyLoading, callback }
      )
    })
  }

  readSuccess = (response, extra) => {
    const listData = response.data
    if (extra.isLoadMore) listData.results = [...this.state.listData.results, ...listData.results]
    this.setState({ [extra.keyLoading]: false, listData })
  }

  readFailed = (error, extra) => {
    this.page = 1
    if (extra.keyLoading === 'isReloading') extra.callback()
    this.setState({ [extra.keyLoading]: false, listData: null, errorResponse: get(error, 'response') })
  }

  loadMore = () => {
    this.page += 1
    this.read('isReloading', true)
  }

  onScrollList = (e) => {
    if (
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight &&
      get(this.state.listData, 'next') &&
      !this.state.isLoading
    ) {
      this.loadMore()
    }
  }

  onSelect = (setId, selected, set) => {
    this.props.onFieldTouched()
    this.setState(
      prevState => ({
        objSelectedKeys: {
          ...prevState.objSelectedKeys,
          [setId]: selected,
        }
      })
    )
  }

  validateFields = () => {
    const { objSelectedKeys } = this.state
    const selectedKeys = Object.keys(objSelectedKeys).filter(val => objSelectedKeys[val])
    return selectedKeys
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.defaultSelectedKeysObj !== nextProps.defaultSelectedKeysObj) {
      this.setState({
        objSelectedKeys: this.getObjSelectedKeys(nextProps.defaultSelectedKeysObj)
      })
    }
  }

  componentDidMount() {
    if (this.state.isLoading) this.read(undefined, undefined, { objSelectedKeys: this.state.objSelectedKeys })
  }

  render() {
    const { errorResponse, isLoading, isReloading, listData, objSelectedKeys } = this.state

    if (isLoading) {
      return (
        <div className='my-3' style={{ width: '100%', textAlign: 'center' }}>
          <Spin
            indicator={<Icon type="loading" style={{ fontSize: "2.5rem" }} spin />}
          />
        </div>
      )
    }

    return listData ? (
      <Spin spinning={isReloading}>
        <div
          className='custom-available-list default-scroll'
          onScroll={this.onScrollList}
          style={{ borderTop: '1px solid #e8e8e8' }}
        >
          {listData.results.length > 0 ? (
            listData.results.map(setItem => (
              <SetItem
                key={setItem.pk}
                selected={!!objSelectedKeys[setItem.pk]}
                setItem={setItem}
                onSelect={this.onSelect}
              />
            ))
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </div>
      </Spin>
    ) : (
      <PageError
        errorResponse={errorResponse}
        onReload={(keyLoading, callback) => this.read(keyLoading, undefined, undefined, callback)}
      />
    )
  }
}

AvailableSets.propTypes = {
  urlKey: PropTypes.string.isRequired,
  autoClear: PropTypes.bool, // auto clear selected
  defaultSelectedKeysObj: PropTypes.array,
  params: PropTypes.object,
  onFieldTouched: PropTypes.func,
}

AvailableSets.defaultProps = {
  autoClear: true,
  defaultSelectedKeysObj: [],
  params: null,
  onFieldTouched: () => null,
}

export default AvailableSets