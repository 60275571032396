const module = 'v3'
const list = [
  {
    label: 'Downloads',
    url: `/${module}/downloads`,
    icon: 'appstore',
    code: '',
  },
]

export default { key: module, list }
