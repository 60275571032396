export default [
  {
    title: 'Name',
    type: 'text',
    required: true,
    dataIndex: 'name',
    inputProps: { disabled: true, onKeyDown: e => {e.preventDefault()} },
    // fields ini tidak dapat memiliki SelectQuery add/edit, karena memiliki fungsi, yang mana tidak dapat disimpan dalam history state
  },
  {
    title: 'Label',
    type: 'text',
    required: true,
    dataIndex: 'label',
  },
  {
    title: 'Fields',
    type: 'textarea',
    dataIndex: 'fields',
    inputProps: {
      disabled: true,
      onKeyDown: e => {e.preventDefault()},
      rows: 6,
      autoSize: { minRows: 6, maxRows: 12 },
    },
  },
]