export default [
  {
    title: 'Name',
    type: 'text',
    required: true,
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex:'name',
    order: 0,
  }, {
    title: 'Description',
    type: 'text',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex:'description',
    order: 1,
  }
];