export default {
  "columns-read": "api/admin/column/{}/",
  "user-calender-read": "api/admin/branch/calender/{}/",
  "reference-read": "api/admin/tab/{}/",
  "overview-read": "api/admin/overview/{}/",
  "statistic-read": "api/admin/statistic/{}/",
  "manpower-read": "api/admin/manpower/",
  "services:descriptor": "services/descriptor/describe/",
  "services:name-descriptor": "services/descriptor/name/",
  "exportImportSessions-service": "services/descriptor/describe/?name=api:admin:estimation:exportimportsession:list",
  "exportMappings-service": "services/descriptor/describe/?name=api:admin:estimation:exportmapping:list",
  "exportMappingLines-service": "services/descriptor/describe/?name=api:admin:estimation:exportmappingline:list",
  "units-service": "services/descriptor/describe/?name=api:admin:estimation:unit:list",
  "templateSets-service": "services/descriptor/describe/?name=api:admin:estimation:templateset:list",
  "products-service": "services/descriptor/describe/?name=api:admin:estimation:product:list",
  "productGroups-service": "services/descriptor/describe/?name=api:admin:estimation:productgroup:list",
  "subheadings-service": "services/descriptor/describe/?name=api:admin:estimation:subheading:list",
  "formulas-service": "services/descriptor/describe/?name=api:admin:estimation:formula:list",
  "pitchFactors-service": "services/descriptor/describe/?name=api:admin:estimation:pitchfactor:list",
  "sections-service": "services/descriptor/describe/?name=api:admin:estimation:section:list",
  "subsections-service": "services/descriptor/describe/?name=api:admin:estimation:subsection:list",
  "lineUsages-service": "services/descriptor/describe/?name=api:admin:estimation:lineusage:list",
  "lineTemplates-service": "services/descriptor/describe/?name=api:admin:estimation:linetemplate:list",
  "prefillSetGroups-service": "services/descriptor/describe/?name=api:admin:estimation:prefillsetgroup:list",
  "prefillSets-service": "services/descriptor/describe/?name=api:admin:estimation:prefillset:list",
  "prefillLines-service": "services/descriptor/describe/?name=api:admin:estimation:prefillline:list",
  "overrideLines-service": "services/descriptor/describe/?name=api:admin:estimation:overrideline:list",
  "overrideSetGroups-service": "services/descriptor/describe/?name=api:admin:estimation:overridesetgroup:list",
  "overrideSets-service": "services/descriptor/describe/?name=api:admin:estimation:overrideset:list",
  "pmSortCodes-service": "services/descriptor/describe/?name=api:admin:estimation:pmsortcode:list",
  "pmSubheadMappings-service": "services/descriptor/describe/?name=api:admin:estimation:pmsubheadmapping:list",
  "pmHardwareStartingRanges-service": "services/descriptor/describe/?name=api:admin:estimation:pmhardwarestartingrange:list",
  "pmTimberStartingRanges-service": "services/descriptor/describe/?name=api:admin:estimation:pmtimberstartingrange:list",
  "subheadMaps-service": "services/descriptor/describe/?name=api:admin:estimation:subheadmap:list",
  "randomMappings-service": "services/descriptor/describe/?name=api:admin:estimation:randommapping:list",
  "countries-service": "services/descriptor/describe/?name=api:admin:geo:country:list",
  "accounts-service": "services/descriptor/describe/?name=api:admin:jobs:account:list",
  "buildingTypes-service": "services/descriptor/describe/?name=api:admin:jobs:buildingtype:list",
  "jobs-service": "services/descriptor/describe/?name=api:app:jobs:jobs",
  "jobQueues-service": "services/descriptor/describe/?name=api:admin:jobs:jobqueue:list",
  "holidays-service": "services/descriptor/describe/?name=api:admin:jobs:holiday:list",
  "extraCharges-service": "services/descriptor/describe/?name=api:admin:jobs:extracharge:list",
  "extraChargeSizes-service": "services/descriptor/describe/?name=api:admin:jobs:extrachargesize:list",
  "extraChargeMappings-service": "services/descriptor/describe/?name=api:admin:jobs:extrachargemapping:list",
  "extraChargeJobs-service": "services/descriptor/describe/?name=api:admin:jobs:extrachargejob:list",
  "buildings-service": "services/descriptor/describe/?name=api:admin:jobs:building:list",
  "takeOffTypes-service": "services/descriptor/describe/?name=api:admin:jobs:takeofftype:list",
  "rateTypes-service": "services/descriptor/describe/?name=api:admin:jobs:ratetype:list",
  "rateNames-service": "services/descriptor/describe/?name=api:admin:jobs:ratename:list",
  "ranges-service": "services/descriptor/describe/?name=api:admin:jobs:ranges:list",
  "jobTypes-service": "services/descriptor/describe/?name=api:admin:jobs:jobtype:list",
  "jobGrades-service": "services/descriptor/describe/?name=api:admin:jobs:jobgrade:list",
  "merchants-service": "services/descriptor/describe/?name=api:admin:jobs:merchant:list",
  "merchantGroups-service": "services/descriptor/describe/?name=api:admin:jobs:merchantgroup:list",
  "operatingBranches-service": "services/descriptor/describe/?name=api:admin:jobs:operatingbranch:list",
  "jobStatusHistories-service": "services/descriptor/describe/?name=api:admin:jobs:jobstatushistory:list",
  "tags-service": "services/descriptor/describe/?name=api:admin:jobs:tag:list",
  "branches-service": "services/descriptor/describe/?name=api:admin:multisite:branch:list",
  "pendingMonitorReports-service": "services/descriptor/describe/?name=api:admin:multisite:pendingmonitorreport:list",
  "branchManagement-service": "services/descriptor/describe/?name=api:admin:multisite:branch:list",
  "servers-service": "services/descriptor/describe/?name=api:admin:multisite:server:list",
  "notifications-service": "services/descriptor/describe/?name=api:admin:notifications:notification:list",
  "specialMessages-service": "services/descriptor/describe/?name=api:admin:reports:specialmessage:list",
  "mmSecurities-service": "services/descriptor/describe/?name=api:admin:reports:mmsecurity:list",
  "merchantMappingModes-service": "services/descriptor/describe/?name=api:admin:reports:merchantmappingmode:list",
  "skuMappings-service": "services/descriptor/describe/?name=api:admin:reports:skumapping:list",
  "merchantSkuDescriptions-service": "services/descriptor/describe/?name=api:admin:reports:merchantskudescription:list",
  "staffLeaves-service": "services/descriptor/describe/?name=api:admin:users:staffleave:list",
  "staffPositions-service": "services/descriptor/describe/?name=api:admin:users:staffposition:list",
  "users-service": "services/descriptor/describe/?name=api:admin:users:user:list",
  "user-service": "services/descriptor/describe/?name=api:admin:users:user:list",
  "groups-service": "services/descriptor/describe/?name=api:admin:users:group:list",


  "exportImportSessions-describeDetail": "services/descriptor/describe/?name=api:admin:estimation:exportimportsession:detail",

  "exportMappingLines-describeDetail": "services/descriptor/describe/?name=api:admin:estimation:exportmappingline:detail",

  "exportMappings-describeDetail": "services/descriptor/describe/?name=api:admin:estimation:exportmapping:detail",

  "formulas-describeDetail": "services/descriptor/describe/?name=api:admin:estimation:formula:detail",
  
  "lineTemplates-describeDetail": "services/descriptor/describe/?name=api:admin:estimation:linetemplate:detail",
  
  "lineUsages-describeDetail": "services/descriptor/describe/?name=api:admin:estimation:lineusage:detail",

  "overrideLines-describeDetail": "services/descriptor/describe/?name=api:admin:estimation:overrideline:detail",

  "overrideSetGroups-describeDetail": "services/descriptor/describe/?name=api:admin:estimation:overridesetgroup:detail",

  "overrideSets-describeDetail": "services/descriptor/describe/?name=api:admin:estimation:overrideset:detail",

  "pitchFactors-describeDetail": "services/descriptor/describe/?name=api:admin:estimation:pitchfactor:detail",
  
  "pmHardwareStartingRanges-describeDetail": "services/descriptor/describe/?name=api:admin:estimation:pmhardwarestartingrange:detail",

  "pmSortCodes-describeDetail": "services/descriptor/describe/?name=api:admin:estimation:pmsortcode:detail",
  
  "pmSubheadMappings-describeDetail": "services/descriptor/describe/?name=api:admin:estimation:pmsubheadmapping:detail",
  
  "pmTimberStartingRanges-describeDetail": "services/descriptor/describe/?name=api:admin:estimation:pmtimberstartingrange:detail",
  
  "prefillLines-describeDetail": "services/descriptor/describe/?name=api:admin:estimation:prefillline:detail",
  
  "prefillSetGroups-describeDetail": "services/descriptor/describe/?name=api:admin:estimation:prefillsetgroup:detail",
  
  "prefillSets-describeDetail": "services/descriptor/describe/?name=api:admin:estimation:prefillset:detail",
  
  "productGroups-describeDetail": "services/descriptor/describe/?name=api:admin:estimation:productgroup:detail",
  
  "products-describeDetail": "services/descriptor/describe/?name=api:admin:estimation:product:detail",
  
  "randomMappings-describeDetail": "services/descriptor/describe/?name=api:admin:estimation:randommapping:detail",
  
  "sections-describeDetail": "services/descriptor/describe/?name=api:admin:estimation:section:detail",
  
  "subheadMaps-describeDetail": "services/descriptor/describe/?name=api:admin:estimation:subheadmap:detail",
  
  "subheadings-describeDetail": "services/descriptor/describe/?name=api:admin:estimation:subheading:detail",
  
  "subsections-describeDetail": "services/descriptor/describe/?name=api:admin:estimation:subsection:detail",
  
  "templateSets-describeDetail": "services/descriptor/describe/?name=api:admin:estimation:templateset:detail",
  
  "units-describeDetail": "services/descriptor/describe/?name=api:admin:estimation:unit:detail",
  

  "countries-describeDetail": "services/descriptor/describe/?name=api:admin:geo:country:detail",
  "provinces-describeDetail": "services/descriptor/describe/?name=api:admin:geo:province:detail",
  "cities-describeDetail": "services/descriptor/describe/?name=api:admin:geo:city:detail",
  

  "accounts-describeDetail": "services/descriptor/describe/?name=api:admin:jobs:account:detail",

  "buildingTypes-describeDetail": "services/descriptor/describe/?name=api:admin:jobs:buildingtype:detail",
  
  "buildings-describeDetail": "services/descriptor/describe/?name=api:admin:jobs:building:detail",
  
  "extraChargeJobs-describeDetail": "services/descriptor/describe/?name=api:admin:jobs:extrachargejob:detail",
  
  "extraChargeSizes-describeDetail": "services/descriptor/describe/?name=api:admin:jobs:extrachargesize:detail",
  
  "extraCharges-describeDetail": "services/descriptor/describe/?name=api:admin:jobs:extracharge:detail",

  "holidays-describeDetail": "services/descriptor/describe/?name=api:admin:jobs:holiday:detail",
  
  "jobStatusNotSynceds-describeDetail": "services/descriptor/describe/?name=api:admin:jobs:jobstatusnotsynced:detail",
  
  "jobGrades-describeDetail": "services/descriptor/describe/?name=api:admin:jobs:jobgrade:detail",
  
  "jobStatusHistories-describeDetail": "services/descriptor/describe/?name=api:admin:jobs:jobstatushistory:detail",
  
  "jobTypes-describeDetail": "services/descriptor/describe/?name=api:admin:jobs:jobtype:detail",
  
  "merchantGroups-describeDetail": "services/descriptor/describe/?name=api:admin:jobs:merchantgroup:detail",
  
  "merchants-describeDetail": "services/descriptor/describe/?name=api:admin:jobs:merchant:detail",

  "operatingBranches-describeDetail": "services/descriptor/describe/?name=api:admin:jobs:operatingbranch:detail",
  
  "ranges-describeDetail": "services/descriptor/describe/?name=api:admin:jobs:ranges:detail",
  
  "rateNames-describeDetail": "services/descriptor/describe/?name=api:admin:jobs:ratename:detail",
  
  "rateTypes-describeDetail": "services/descriptor/describe/?name=api:admin:jobs:ratetype:detail",
  
  "tags-describeDetail": "services/descriptor/describe/?name=api:admin:jobs:tag:detail",
  
  "takeOffTypes-describeDetail": "services/descriptor/describe/?name=api:admin:jobs:takeofftype:detail",
  

  "branches-describeDetail": "services/descriptor/describe/?name=api:admin:multisite:branch:detail",

  "pendingMonitorReports-describeDetail": "services/descriptor/describe/?name=api:admin:multisite:pendingmonitorreport:detail",
  
  "servers-describeDetail": "services/descriptor/describe/?name=api:admin:multisite:server:detail",
  

  "notifications-describeDetail": "services/descriptor/describe/?name=api:admin:notifications:notification:detail",
  

  "merchantMappingModes-describeDetail": "services/descriptor/describe/?name=api:admin:reports:merchantmappingmode:detail",

  "mappingModes-describeDetail": "services/descriptor/describe/?name=api:admin:merchants:mappingmode:detail",
  
  "merchantSkuDescriptions-describeDetail": "services/descriptor/describe/?name=api:admin:reports:merchantskudescription:detail",
  
  "mmSecurities-describeDetail": "services/descriptor/describe/?name=api:admin:reports:mmsecurity:detail",
  
  "skuMappings-describeDetail": "services/descriptor/describe/?name=api:admin:merchants:skumapping:detail",

  "specialMessages-describeDetail": "services/descriptor/describe/?name=api:admin:reports:specialmessage:detail",

  "staffLeaves-describeDetail": "services/descriptor/describe/?name=api:admin:users:staffleave:detail",
  
  "staffPositions-describeDetail": "services/descriptor/describe/?name=api:admin:users:staffposition:detail",

  "users-describeDetail": "services/descriptor/describe/?name=api:admin:users:user:detail",
}
