import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Col, Row } from 'antd'

class ListItem extends React.PureComponent {
  render() {
    const { id, item, onSelect, selected, value } = this.props
    console.log('Rendered', value, this)

    return (
      <Row
        className='selection-row-item'
        type='flex'
        gutter={[6, 0]}
        style={{ margin: 0, paddingLeft: '6px' }}
        onClick={(event) => onSelect(id, item)}
      >
        <Col>
          <Checkbox checked={selected} />
        </Col>
        <Col>
          {value}
        </Col>
      </Row>
    )
  }
}

ListItem.propTypes = {
  item: PropTypes.object.isRequired,
  visible: PropTypes.bool,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
}

ListItem.defaultProps = {
  selected: false,
  onSelect: () => null,
}

export default ListItem