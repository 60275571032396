import React from "react";
import styled from "styled-components";
import { Layout, Modal } from "antd";

import { Action } from "App/Redux";
import { Sites } from "App/Config";
import { Lib, RequestV2 as Request } from "App/Utils";
import LoadingSpin from "App/Component/LoadingSpin";

import {
  AppNavigation,
  AppHeader,
  AppBreadcrumb
} from "App/Component/Template";

const { Content } = Layout;
const { confirm } = Modal;

class Main extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false
    };
    const urlParams = new URLSearchParams(props.location.search);
    this.waitingTransfer = !!urlParams.get("cvklsmvp");
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };

  setCollapse = collapsed => {
    if (this.state.collapsed !== collapsed) {
      this.setState({ collapsed });
    }
  };

  // componentWillUnmount() {
  // console.log('cwu main');
  // }

  requestMeSuccess = r => {
    const { runRedux, history, location } = this.props;
    runRedux(Action.UpdateUserInformation(r.data));
    history.replace("/");
    if(location.pathname.length > 16)
      history.replace(location.pathname)
  };

  requestMeFailed = e => {
    console.log(e);
  };

  showConfirm = () => {
    const { location, history, userReducer } = this.props;
    const urlParams = new URLSearchParams(location.search);
    const username = urlParams.get("cvklsmvp");
    if (!username) return;
    const source = urlParams.get("nsalpxz");
    const access_token = urlParams.get("8h3zo9");
    this.waitingTransfer = false
    if (userReducer.user && userReducer.user.username !== username) {
      confirm({
        cancelText: "NO",
        title: `Do you want to switch account to ${username}?`,
        // content: 'When clicked the OK button, user ',
        onOk: () => {
          this.transferToken(username, source, access_token);
        },
        onCancel() {
          history.replace(location.pathname);
          console.warn("Canceled");
        }
      });
    } else if (userReducer.user && userReducer.user.username === username) {
      history.replace(location.pathname);
    } else {
      this.transferToken(username, source, access_token);
    }
  };

  onTransferSuccess = r => {
    this.props.runRedux(Action.CreateUserSessionProperties(r.data));
    Request(
      "get",
      "read-me",
      {},
      {},
      [],
      this.requestMeSuccess,
      this.requestMeFailed
    );
  };

  onTransferFailed = r => {
    this.props.history.replace('/login')
    console.warn(r);
  };

  transferToken = async (username, source, access_token) => {
    if (source && access_token) {
      let params = {
        client_id: Sites.oauth.id,
        client_secret: Sites.oauth.secret,
        grant_type: "transfer_token",
        source,
        access_token
      };
      let data = new FormData();
      for (let i in params) {
        data.append(i, params[i]);
      }
      let res = await Request(
        "post",
        "user-auth",
        { "Content-Type": "application/x-www-form-urlencoded" },
        data,
        [],
        this.onTransferSuccess,
        this.onTransferFailed
      );
      return res;
    }
  };

  componentDidMount() {
    this.showConfirm();
  }

  render() {
    const p = this.props;

    return this.waitingTransfer ? (
      <LoadingSpin tip='Checking authentication...' />
    ) : (
      <StyledLayout collapsed={this.state.collapsed.toString()}>
        <AppNavigation
          collapsed={this.state.collapsed}
          onCollapse={this.toggle}
          setCollapse={this.setCollapse}
          history={p.history}
          location={p.location}
          match={p.match}
          userReducer={p.userReducer}
        />

        <Layout>
          <AppHeader
            location={p.location}
            history={p.history}
            userReducer={p.userReducer}
            collapsed={this.state.collapsed}
            toggle={this.toggle}
          />
          <StyledContent>
            <AppBreadcrumb />
            {p.routeElement}
          </StyledContent>
        </Layout>
      </StyledLayout>
    );
  }
}

export default Main;

const StyledLayout = styled(Layout)`
  min-height: 100vh !important;
  margin-left: ${props => (props.collapsed === "true" ? "80px" : "300px")};
  transition: all 0.2s;
`;
const StyledContent = styled(Content)`
  margin: 0 16px;
`;
