import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'antd'
import { Lib } from 'App/Utils'

class Header extends React.PureComponent {
  constructor(props) {
    super(props)

    this.headerDescriptions = [
      { key: 'session_type', label: 'Type' },
      { key: 'status', label: 'Status' },
      { key: 'content_type.label', label: 'Content Type' },
      { key: 'description', label: 'Description' },
      { key: 'overwrite_existing', label: 'Overwrite', type: 'bool' },
      { key: 'activate_imported_items', label: 'Activate Items', type: 'bool' },
      { key: 'file_source', label: 'File', type: 'file', display: 'file_name', link: 'file' },
      { key: 'modified', label: 'Modified', type: 'datetime' },
    ]
  }

  render() {
    const { exportImport } = this.props

    return (
      <div style={{ padding: '12px 24px', borderBottom: '1px solid #e8e8e8' }}>
        <Row className='ant-desc-custom' type='flex' gutter={[12, 12]}>
          {
            this.headerDescriptions.map(item => (
              <Col key={item.key} xs={24} sm={12} lg={8} xl={6}>
                <Row className='header-content-item'>
                  <span className='ant-descriptions-item-label ant-descriptions-item-colon'>
                    {item.label}
                  </span>
                  <span className='ant-descriptions-item-content text-truncate d-block'>
                    {Lib.getValue(exportImport, item)}
                  </span>
                </Row>
              </Col>
            ))
          }
        </Row>
      </div>
    )
  }
}

Header.propTypes = {
  exportImport: PropTypes.object.isRequired,
}

export default Header