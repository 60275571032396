export default [
  {
    title: 'Merchant',
    type: 'SelectQuery',
    required: true,
    data: 'merchants',
    initialValue: '',
    note_text: '',
    selectedKey: 'pk',
    selectedValue: 'name',
    additional: 'edit|add',
    dataIndex: 'merchant',
    order: 0,
    isActive: true, // for SelectQuery Autocomplete
  }, {
    title: 'Sku',
    type: 'text',
    required: true,
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'sku',
    order: 1,
  }, {
    title: 'Description',
    type: 'text',
    required: true,
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'description',
    order: 2,
  },
]