import React from 'react'
import { Button, Card, Col, Icon, Radio, Row, Spin } from 'antd'
import { get } from 'lodash'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highcharts'

import BaseRequest from './BaseRequest'
import options from './barOptions'

class WorkingSpeedStats extends BaseRequest {
  constructor(props) {
    super(props)

    this.title = 'Working Speed Statistics'
    this.urlKey = 'module-groupUser-user-workingSpeedStats'
  }

  convertResponseData = (responseData) => {
    const numberFormat = Intl.NumberFormat('de-DE')
    const workingSpeed = Math.round(responseData.avg_working_speed * 100) / 100
    const avg = `(${numberFormat.format(workingSpeed)}  m²/hours)`
    options.title.useHTML = true
    options.title.text = `<b>${this.title}</b> ${avg}`
    options.series = [
      {
        name: 'Grades',
        colorByPoint: true,
        data: responseData.grades.map(grade => ({
          key: grade.pk,
          name: grade.label,
          y: Number(get(grade.user_working_speed, 'working_speed', 0)),
          speed: numberFormat.format(get(grade.user_working_speed, 'working_speed', 0)),
          duration: get(grade.user_working_speed, 'total_duration.label', '-'),
        }))
      }
    ]

    if (typeof get(this._highcharts, 'chart.reflow') === 'function')
      this._highcharts.chart.reflow()

    if (typeof get(this._highcharts, 'chart.redraw') === 'function')
      this._highcharts.chart.redraw()

    return responseData
  }

  componentDidMount() {
    this.read()
    this._intervalRead = setInterval(() => {
      this.read()
    }, 1000 * 60 * 5)
  }

  componentWillUnmount() {
    clearInterval(this._intervalRead)
  }

  render() {
    return (
      <Spin spinning={this.state.isLoading}>
        <Card size='small' loading={!this.state.data && this.state.isLoading}>
          {!this.state.data ? this.getErrorComp() : (
            <div>
              {this.state.data && !this.state.isLoading && (
                <HighchartsReact
                  ref={ref => {this._highcharts = ref}}
                  highcharts={Highcharts}
                  constructorType='chart'
                  options={options}
                />
              )}
            </div>
          )}
        </Card>
      </Spin>
    )
  }
}

export default WorkingSpeedStats