import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Tabs, Divider, Button, Icon, Popover, Spin } from 'antd'

const { TabPane } = Tabs

const UNGROUPED_LABEL = 'UNGROUPED'

class PermissionItem extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isActive: props.permissionObj[props.permission.permission_id],
      visibleDialog: false,
    }
  }

  // Semua hal yang akan merubah state isActive,
  // tidak boleh menggunakan this.setState({ isActive: bool }),
  // harus memanggil fungsi ini "setIsActive"
  setIsActive = (isActive) => {
    this.setState({ isActive }, () => {
      this.props.setTouched(true)
    })
  }

  onItemClick = (isActive) => {
    this.setIsActive(isActive)
    this.props.permissionObj[this.props.permission.permission_id] = isActive // fix
    // Terjadi bug jika menekan Item, kemudian klik kanan Set
    // bug => this.props.setPermissionObj({ [this.props.permission.permission_id]: isActive })
  }

  handleVisibleDialog = (visibleDialog) => {
    if (!this.props.readOnly) {
      this.setState({ visibleDialog })
    }
  }

  onClickOpt1 = (event) => {
    event.stopPropagation()
    this.setState({ visibleDialog: false }, () => {
      setTimeout(() => {
        this.props.setPermInGroup(this.props.permission.code, !this.state.isActive)
      }, 25)
    })
  }

  onClickOpt2 = (event) => {
    event.stopPropagation()
    this.setState({ visibleDialog: false }, () => {
      setTimeout(() => {
        this.props.setPermInModule(this.props.permission.code, !this.state.isActive)
      }, 25)
    })
  }

  onClickOpt3 = (event) => {
    event.stopPropagation()
    this.setState({ visibleDialog: false }, () => {
      setTimeout(() => {
        this.props.setPermInAll(this.props.permission.code, !this.state.isActive)
      }, 25)
    }) 
  }

  getOptionContent = () => (
    <ul className='ant-dropdown-menu ant-dropdown-menu-light ant-dropdown-menu-root ant-dropdown-menu-vertical'>
      <li className='ant-dropdown-menu-item' onClick={this.onClickOpt1}>
        {this.state.isActive ? 'Remove' : 'Allow'} "<b>{this.props.permission.name}</b>" permission to this Group
      </li>
      <li className='ant-dropdown-menu-item' onClick={this.onClickOpt2}>
        {this.state.isActive ? 'Remove' : 'Allow'} "<b>{this.props.permission.name}</b>" permission to this Module
      </li>
      <li className='ant-dropdown-menu-item' onClick={this.onClickOpt3}>
        {this.state.isActive ? 'Remove' : 'Allow'} "<b>{this.props.permission.name}</b>" permission to All
      </li>
    </ul>
  )

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.isActive !== nextProps.permissionObj[nextProps.permission.permission_id]) {
      this.setIsActive(nextProps.permissionObj[nextProps.permission.permission_id])
      // this.setState({ isActive: nextProps.permissionObj[nextProps.permission.permission_id] })
    }
  }

  render() {
    const { permission, readOnly } = this.props
    const { isActive, visibleDialog } = this.state

    return (
      <Popover
        visible={visibleDialog}
        trigger='contextMenu'
        onVisibleChange={this.handleVisibleDialog}
        content={this.getOptionContent()}
        // overlayStyle={{ maxWidth: '450px' }}
        overlayClassName='custom-popover-content'
      >
        <Button disabled={readOnly} size='small' onClick={() => this.onItemClick(!isActive)} style={isActive ? {color: '#1890ff', borderColor: '#1890ff'} : {} }>
          { isActive ? <Icon type='check-square' theme='filled' style={{ color: '#1890ff' }} /> : <Icon type='border' /> }
          { permission.name }
        </Button>
      </Popover>
    )
  }
}

class Set extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      visibleDialog: false,
    }
  }

  handleVisibleDialog = (visibleDialog) => {
    if (!this.props.readOnly) {
      this.setState({ visibleDialog })
    }
  }

  getOptionContent = () => (
    <ul className='ant-dropdown-menu ant-dropdown-menu-light ant-dropdown-menu-root ant-dropdown-menu-vertical'>
      <li className='ant-dropdown-menu-item' onClick={(event) => this.onSelectAll(true, event)}>
        <b>Select</b> all permission in "<b>{this.props.set.name}</b>"
      </li>
      <li className='ant-dropdown-menu-item' onClick={(event) => this.onSelectAll(false, event)}>
      <b>Remove</b> all permission in "<b>{this.props.set.name}</b>"
      </li>
    </ul>
  )

  // select all permission in current set
  onSelectAll = (selected, event) => {
    event.stopPropagation()
    this.setState({ visibleDialog: false }, () => {
      this.props.setLoading(true, () => {
        setTimeout(() => {
          const newPermissionObj = {}
          const { set } = this.props
          for (const i in set.permissions) {
            newPermissionObj[set.permissions[i].permission_id] = selected
          }
          this.props.setPermissionObj(newPermissionObj)
          this.props.setLoading(false)
        }, 150)
      })
    })
  }

  render() {
    const { set, permissionObj, setPermissionObj, setPermInGroup, setPermInModule, setPermInAll, setTouched } = this.props
    const { visibleDialog } = this.state

    return (
      <Col xs={24} sm={24} lg={12} style={{ marginBottom: '12px' }}>
        <Popover
          visible={visibleDialog}
          trigger='contextMenu'
          onVisibleChange={this.handleVisibleDialog}
          content={this.getOptionContent()}
          overlayClassName='custom-popover-content'
        >
          <Divider style={{ margin: '6px 0' }}>
            {set.name}
          </Divider>
        </Popover>
        <Row type='flex' justify='center' gutter={[12, 12]} style={{ marginTop: 0, padding: '0 12px' }}>
          {
            set.permissions.map(permission => {
              return (
                <Col key={permission.permission_id}>
                  <PermissionItem
                    ref={ref => {this[`_ref-${permission.permission_id}`] = ref}}
                    readOnly={this.props.readOnly}
                    permission={permission}
                    permissionObj={permissionObj}
                    setPermissionObj={setPermissionObj}
                    setPermInGroup={setPermInGroup}
                    setPermInModule={setPermInModule}
                    setPermInAll={setPermInAll}
                    setTouched={setTouched}
                  />
                </Col>
              )
            })
          }
        </Row>
      </Col>
    )
  }
}

class Group extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      collapsed: false,
      visibleDialog: false,
    }
  }

  onCollapse = (collapsed) => {
    this.setState({ collapsed })
  }

  setPermInGroup = (code, selected = true) => {
    if (typeof code === 'string') {
      this.props.setLoading(true, () => {
        setTimeout(() => {
          const { group } = this.props
          const partCode = code.slice(code.indexOf('.'), code.indexOf('_') + 1)
          const newPermissionObj = {}
          for (const index in group.sets) {
            const permissionItems = group.sets[index].permissions.filter(permission => permission.code.includes(partCode))
            if (permissionItems.length > 0) {
              for (let j = 0; j < permissionItems.length; j += 1) {
                newPermissionObj[permissionItems[j].permission_id] = selected
              }
            }
          }
          this.props.setPermissionObj(newPermissionObj) // is synchronous
          this.props.setLoading(false)
        }, 150)
      })
    }
  }

  setPermInModule = (code, selected) => {
    this.props.setPermInModule(code, this.props.codeModule, selected)
  }

  handleVisibleDialog = (visibleDialog) => {
    if (!this.props.readOnly) {
      this.setState({ visibleDialog })
    }
  }

  getOptionContent = () => (
    <ul className='ant-dropdown-menu ant-dropdown-menu-light ant-dropdown-menu-root ant-dropdown-menu-vertical'>
      <li className='ant-dropdown-menu-item' onClick={(event) => this.onSelectAll(true, event)}>
        <b>Select</b> all permission in Group: <b>{this.props.group.name}</b>
      </li>
      <li className='ant-dropdown-menu-item' onClick={(event) => this.onSelectAll(false, event)}>
        <b>Remove</b> all permission in Group: <b>{this.props.group.name}</b>
      </li>
    </ul>
  )

  // select all permission in current group
  onSelectAll = (selected, event) => {
    event.stopPropagation()
    this.setState({ visibleDialog: false }, () => {
      this.props.setLoading(true, () => {
        setTimeout(() => {
          const newPermissionObj = {}
          const { group } = this.props
          for (const i in group.sets) {
            for (const j in group.sets[i].permissions) {
              newPermissionObj[group.sets[i].permissions[j].permission_id] = selected
            }
          }
          this.props.setPermissionObj(newPermissionObj)
          this.props.setLoading(false)
        }, 150)
      })
    })
  }
  
  render() {
    const { group, permissionObj, setPermissionObj, setPermInAll, setLoading, setTouched } = this.props
    const { collapsed, visibleDialog } = this.state

    return (
      <Col style={{ width: '100%' }}>
        <div
          style={{ borderLeft: '3px solid #1890ff', borderBottom: '1px solid #1890ff', backgroundColor: '#d9ecfd', padding: '8px', cursor: 'pointer' }}
          onClick={() => this.onCollapse(!collapsed)}
        >
          <Popover
            visible={visibleDialog}
            trigger='contextMenu'
            onVisibleChange={this.handleVisibleDialog}
            content={this.getOptionContent()}
            overlayClassName='custom-popover-content'
            placement='topLeft'
          >
            <Row type='flex' align='middle'>
              <Icon className='custom-icon-collapse' type='right' rotate={collapsed ? 0 : 90} />
              <b>{group.name}</b>
            </Row>
          </Popover>
        </div>
        <Row type='flex' gutter={[12, 12]} style={{ display: collapsed ? 'none' : 'flex', marginBottom: '24px' }}>
          {group.note && (
            <div style={{ width: '100%', textAlign: 'center', fontWeight: 500, margin: '12px 8px' }}>
              {group.note}
            </div>
          )}
          {
            group.sets.map(set => (
              <Set
                key={set.name}
                readOnly={this.props.readOnly}
                set={set}
                permissionObj={permissionObj}
                setPermissionObj={setPermissionObj}
                setPermInGroup={this.setPermInGroup}
                setPermInModule={this.setPermInModule}
                setPermInAll={setPermInAll}
                setLoading={setLoading}
                setTouched={setTouched}
              />
            ))
          }
        </Row>
      </Col>
    )
  }
}

class Permissions extends React.PureComponent {
  constructor(props) {
    super(props)

    this.refSets = {}
    this.permissionObj = this.getPermissionObj(props.initialValue)
    this.state = {
      isLoading: false,
      visibleDialogModule: {},
    }
  }

  getOptionContentModule = (currentModule) => (
    <ul className='ant-dropdown-menu ant-dropdown-menu-light ant-dropdown-menu-root ant-dropdown-menu-vertical'>
      <li className='ant-dropdown-menu-item' onClick={(event) => this.onSelectAllModule(true, currentModule, event)}>
        <b>Select</b> all permission in Module: <b>{currentModule.name}</b>
      </li>
      <li className='ant-dropdown-menu-item' onClick={(event) => this.onSelectAllModule(false, currentModule, event)}>
        <b>Remove</b> all permission in Module: <b>{currentModule.name}</b>
      </li>
    </ul>
  )

  handleVisibleDialogModule = (visible, codename) => {
    if (!this.props.readOnly) {
      this.setState(prevState => ({ visibleDialogModule: {[codename]: visible} }))
    }
  }

  // return object with: key = valueArray, value = true
  getPermissionObj = (permissions) => {
    const obj = {}
    if (Array.isArray(permissions)) {
      for (let i = 0; i < permissions.length; i += 1) {
        obj[permissions[i]] = true
      }
    }
    return obj
  }

  setPermissionObj = (permissionObj) => {
    this.permissionObj = { ...this.permissionObj, ...permissionObj }
    return this.permissionObj
  }

  setPermInModule = (code, codeModule, selected = true) => {
    if (typeof code === 'string') {
      const partCode = code.slice(code.indexOf('.'), code.indexOf('_') + 1)
      const module = this.props.dataSource.find(module => module.codename === codeModule)
      if (module) {
        this.setLoading(true, () => {
          setTimeout(() => {
            const newPermissionObj = {}
            for (let i = 0; i < module.groups.length; i += 1) {
              for (let j = 0; j < module.groups[i].sets.length; j += 1) {
                const permissionItem = module.groups[i].sets[j].permissions.find(permission => permission.code.includes(partCode))
                if (permissionItem) {
                  newPermissionObj[permissionItem.permission_id] = selected
                }
              }
            }
            this.setPermissionObj(newPermissionObj)
            this.setLoading(false)
          }, 150)
        })
      }
    }
  }

  setPermInAll = (code, selected) => {
    if (typeof code === 'string') {
      const partCode = code.slice(code.indexOf('.'), code.indexOf('_') + 1)
      const newPermissionObj = {}
      this.setLoading(true, () => {
        setTimeout(() => {
          for (let i = 0; i < this.props.dataSource.length; i += 1) {
            const module = this.props.dataSource[i]
            if (module) {
              for (let j = 0; j < module.groups.length; j += 1) {
                for (let k = 0; k < module.groups[j].sets.length; k += 1) {
                  const permissionItem = module.groups[j].sets[k].permissions.find(permission => permission.code.includes(partCode))
                  if (permissionItem) {
                    newPermissionObj[permissionItem.permission_id] = selected
                  }
                }
              }
            }
          }
          this.setPermissionObj(newPermissionObj)
          this.setLoading(false)
        }, 150)
      })
    }
  }

  // select all permission in current module
  onSelectAllModule = (selected, currentModule, event = {stopPropagation: () => null}) => {
    // jangan lupa stopPropagation
    event.stopPropagation()
    this.setState({ visibleDialogModule: {[currentModule.codename]: false}, isLoading: true}, () => {
      setTimeout(() => {
        const newPermissionObj = {}
        const groups = currentModule.groups.length > 0 ? currentModule.groups : [{ name: UNGROUPED_LABEL, alias: `${currentModule.name}-${UNGROUPED_LABEL}`, sets: currentModule.sets }]
        for (let j = 0; j < groups.length; j += 1) {
          for (let k = 0; k < groups[j].sets.length; k += 1) {
            const permissions = groups[j].sets[k].permissions
            for (const key in permissions) {
              newPermissionObj[permissions[key].permission_id] = selected
            }
          }
        }
        this.setPermissionObj(newPermissionObj)
        this.setLoading(false)
      }, 150)
    })
  }

  // select all permission
  onSelectAll = () => {
    // Tidak ada tempat
  }

  setLoading = (isLoading, callback) => {
    this.setState({ isLoading }, callback)
  }

  mappingTabPane = modules => {
    const { visibleDialogModule } = this.state
    return (
      modules.map(module => {
        const groups = module.groups.length > 0 ? module.groups : [{ name: UNGROUPED_LABEL, alias: `${module.name}-${UNGROUPED_LABEL}`, sets: module.sets }]
        return groups[0].sets.length > 0 ? (
          <TabPane
            forceRender
            key={module.codename}
            tab={
              <Popover
                visible={visibleDialogModule[module.codename]}
                trigger='contextMenu'
                onVisibleChange={(visible) => this.handleVisibleDialogModule(visible, module.codename)}
                content={this.getOptionContentModule(module)}
                overlayClassName='custom-popover-content'
                placement='right'
              >
                {module.name}
              </Popover>
            }
          >
            <Row type='flex' style={{ marginTop: '24px' }}>
              {this.mappingGroups(groups, module.codename)}
            </Row>
          </TabPane>
        ) : null
      })
    )
  }

  mappingGroups = (groups, codeModule) => {
    return groups.map((group) => (
      <Group
        key={group.name}
        readOnly={this.props.readOnly}
        codeModule={codeModule}
        group={group}
        permissionObj={this.permissionObj}
        setPermissionObj={this.setPermissionObj}
        setLoading={this.setLoading}
        setPermInModule={this.setPermInModule}
        setPermInAll={this.setPermInAll}
        setTouched={this.props.setTouched}
      />
    ))
  }

  validateFields = () => {
    const arrPermissions = []
    for (const key in this.permissionObj) {
      if (this.permissionObj[key]) {
        arrPermissions.push(key)
      }
    }
    return arrPermissions
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.initialValue.length !== nextProps.initialValue.length) {
      this.permissionObj = this.getPermissionObj(nextProps.initialValue)
    }
  }

  render() {
    const { dataSource } = this.props
    const { isLoading } = this.state
    return (
      <Spin spinning={isLoading}>
        <Tabs tabPosition="left">
          {/* {
            dataSource.map(module => (
              <Module
                key={module.codename}
                module={module}
                permissionObj={this.permissionObj}
                setPermissionObj={this.setPermissionObj}
                setLoading={this.setLoading}
                setPermInAll={this.setPermInAll}
              />
            ))
          } */}
          {this.mappingTabPane(dataSource)}
        </Tabs>
      </Spin>
    )
  }
}

PermissionItem.propTypes = {
  readOnly: PropTypes.bool,
  permission: PropTypes.object,
  permissionObj: PropTypes.object,
  setPermissionObj: PropTypes.func,
  setTouched: PropTypes.func,
}

PermissionItem.defaultProps = {
  readOnly: false,
  permission: {},
  permissionObj: {},
  setPermissionObj: () => null,
  setTouched: () => null,
}

Set.propTypes = {
  readOnly: PropTypes.bool,
  set: PropTypes.object,
  permissionObj: PropTypes.object,
  setPermissionObj: PropTypes.func,
  setTouched: PropTypes.func,
}

Set.defaultProps = {
  readOnly: false,
  set: { permissions: [] },
  permissionObj: {},
  setPermissionObj: () => null,
  setTouched: () => null,
}

Group.propTypes = {
  codeModule: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  group: PropTypes.object,
  setLoading: PropTypes.func,
  setPermissionObj: PropTypes.func,
  setPermInModule: PropTypes.func,
}

Group.defaultProps = {
  readOnly: false,
  group: { sets: [] },
  setLoading: () => null,
  setPermissionObj: () => null,
  setPermInModule: () => null,
}

Permissions.propTypes = {
  readOnly: PropTypes.bool,
  dataSource: PropTypes.array,
  initialValue: PropTypes.array,
  setTouched: PropTypes.func,
}

Permissions.defaultProps = {
  readOnly: false,
  dataSource: [],
  initialValue: [],
  setTouched: () => null,
}

export default Permissions