import React from 'react'
import PropTypes from 'prop-types'
import { Affix, Button, Col, Dropdown, Form, Icon, Menu, message, Row } from 'antd'
import { get, isEmpty } from 'lodash'
import { Lib } from 'App/Utils'
import fields from './fields'
import DynamicField from 'App/Component/DynamicField'
import { FormContext, FormProvider } from 'App/Component/Context/ContextForm'
import Lib2 from 'App/Component/Singlepage/Lib2'
import PromptContainer from 'App/Component/PromptContainer'

class Footer extends React.PureComponent {
  constructor(props) {
    super(props)

    this.saveOptions = [
      { key: '1', label: 'Save and Dry Run', body: { dry_run: true } },
      { key: '2', label: 'Save and Run', body: { dry_run: false } },
      { key: '3', label: 'Save' },
    ]
    this.state = {
      selectedSaveOption: this.saveOptions[2],
      isSaving: false,
    }
  }

  saveMenu = () => {
    return (
      <Menu onClick={this.onSaveOptionChange}>
        {this.saveOptions.map(val => (
           <Menu.Item key={val.key}>
             {val.label}
           </Menu.Item>   
        ))}
      </Menu>
    )
  }

  onSaveOptionChange = ({ key }) => {
    const selectedSaveOption = this.saveOptions.find(option => option.key === key)
    this.setState({ selectedSaveOption })
  }

  onSave = () => {
    this.setState({ isSaving: true }, () => {
      this.props.onSave(this.state.selectedSaveOption.body, () => {
        this.setState({ isSaving: false })
      })
    })
  }

  render() {
    const { showDelete, allowSave, operation } = this.props
    const { isSaving, selectedSaveOption } = this.state

    return (
      <div style={{ borderTop: '1px solid #e8e8e8', padding: '10px 16px', backgroundColor: '#fff' }}>
        <Row type='flex' justify='space-between'>
          <Col>
            {
              showDelete && false && ( // belum
                <Button type='danger' icon='delete' onClick={this.handleDelete}>Delete</Button>
              )
            }
          </Col>
          <Col>
            <Row type='flex' gutter={[12, 0]}>
              {!allowSave && (
                <Col>
                  <Row type='flex' align='middle' style={{ height: '100%' }}>
                    <Col style={{ fontWeight: 500, color: 'rgba(255, 0, 0, 0.6)' }}>
                      Can edit when status is New, Completed or Failed.
                      Click Refresh button to get current status
                    </Col>
                  </Row>
                </Col>
              )}
              <Col>
                <Button onClick={this.props.onClose}>Close</Button>
              </Col>
              <Col>
                <Dropdown.Button type='primary' disabled={isSaving || !allowSave || !isEmpty(operation)} placement='topRight' onClick={this.onSave} overlay={this.saveMenu}>
                  <Icon type={isSaving ? 'loading' : 'save'} /> {selectedSaveOption.label}
                </Dropdown.Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    )
  }
}

class PageGeneral extends React.PureComponent {
  constructor(props) {
    super(props)

    this.allowedStatusToSave = ['new', 'completed', 'failed']
    this.allowDelete = this.props.permissions.some(val => val.includes('.delete_'))
    this.allowEdit = this.props.permissions.some(val => val.includes('.change_'))
    this.state = {
      isSaving: false,
      isTouched: false,
      operation: !props.isAddMode && !this.allowEdit ? {operation: 'view'} : {}
    }
  }

  onFieldBlur = (value, dataIndex, type, form, previousValue) => {
    if (!value && !previousValue) return
    if (value !== previousValue) this.setTouched(true)
  }

  setTouched = isTouched => {
    if (this._prompt) {
      this._prompt.setTouched(isTouched)
    }
  }

  onSave = async (anotherBody = {}, callback = () => null) => {
    // callback harus di panggil, itu untuk mendisable loading
    const obj = await this.validateFields()
    if (!obj.error) {
      const values = Lib2.getValuesWithFileV2(obj.values, fields)
      for (const key in anotherBody) values.set(key, anotherBody[key])
      if (this.props.isAddMode && !values.get('file_source')) values.set('file_source', '')
      this.props.onSave(values, (isSuccess, response) => {
        if (isSuccess) {
          message.success('Export Import Session has been saved')
          this.setTouched(false)
        } else {
          Lib.errorMessageHandler(response)
          this.setFieldsError(response, obj.values)
        }
        callback()
      })
    } else {
      // field reject by FE form
      callback()
    }
  }

  setFieldsError = (errorResponse, valuesField) => {
    const errorData = get(errorResponse, 'data')
    if (typeof errorData === 'object') {
      const fieldsError = {}
      for (const key in errorData) {
        if (valuesField.hasOwnProperty(key)) {
          fieldsError[key] = {
            value: valuesField[key],
            errors: [new Error(errorData[key])],
          }
        }
      }
      if (!isEmpty(fieldsError)) this.form.setFields(fieldsError)
    }
  }

  validateFields = () => {
    return new Promise((resolve, reject) => {
      this.form.validateFieldsAndScroll((error, values) => {
        resolve({ values, error })
      })
    })
  }

  render() {
    const { isAddMode, onGoBack, exportImport } = this.props

    return (
      <div>
        <PromptContainer ref={ref => {this._prompt = ref}} startsWith={this.props.path} />
        <Row>
          <FormProvider>
            <Form layout="horizontal">
              {
                fields.map((elem) => {
                  const data = get(exportImport, elem.dataIndex, elem.initialValue)
                  return (
                    <DynamicField
                      {...elem}
                      key={elem.dataIndex}
                      {...this.state.operation}
                      dataRecord={data}
                      onBlur={this.onFieldBlur}
                    />
                  )
                })
              }
              <FormContext.Consumer>
                {form => {this.form = form}}
              </FormContext.Consumer>
            </Form>
          </FormProvider>
        </Row>
        <Affix offsetBottom={0}>
          <Footer
            allowSave={isAddMode || this.allowedStatusToSave.includes(get(exportImport, 'status'))}
            showDelete={!isAddMode && this.allowDelete}
            onSave={this.onSave}
            onClose={onGoBack}
            operation={this.state.operation}
          />
        </Affix>
      </div>
    )
  }
}

PageGeneral.propTypes = {
  // onSaveClear: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onGoBack: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  exportImport: PropTypes.object,
}

PageGeneral.defaultProps = {
  exportImport: null,
}

export default PageGeneral