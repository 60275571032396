export default [
  {
    title: 'Name',
    type: 'text',
    data: '',
    required: true,
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'name',
    order: 0,
  }, {
    title: 'Desc',
    type: 'textarea',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'desc',
    order: 1,
  }, {
    title: 'Status',
    type: 'select',
    disableAllowClear: true,
    data: [
      {
        alias: 'a',
        value: 'Active'
      }, {
        alias: 'i',
        value: 'Inactive'
      }
    ],
    initialValue: 'i',
    note_text: '',
    additional: '',
    dataIndex: 'status',
    order: 2,
  }
];