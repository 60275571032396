export default [
  {
    title: 'Title',
    type: 'text',
    required: true,
    dataIndex: 'title',
  }, {
    title: 'Is popup',
    type: 'checkbox',
    initialValue: false,
    dataIndex: 'force_popup',
  // }, {
  //   title: 'Is sent',
  //   type: 'checkbox',
  //   initialValue: false,
  //   dataIndex: 'is_sent',
  },
]