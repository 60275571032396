import columns from './columns'

const descriptor = {
  pagination: true,
  filter_by: [
    // {
    //   name: 'is_superuser',
    //   label: 'Superuser status',
    //   singleChoices: [
    //     ['', 'All'],
    //     ['true', 'Yes'],
    //     ['false', 'No'],
    //   ],
    // },
    // {
    //   name: 'is_staff',
    //   label: 'Staff status',
    //   singleChoices: [
    //     ['', 'All'],
    //     ['true', 'Yes'],
    //     ['false', 'No'],
    //   ],
    // },
    // {
    //   name: 'is_active',
    //   label: 'Active',
    //   singleChoices: [
    //     ['', 'All'],
    //     ['true', 'Yes'],
    //     ['false', 'No'],
    //   ],
    // },
  ],
  columns,
}

export default descriptor