import { BaseList, BaseDetail } from "App/Component/Bases";

import fields from "./fields"

const title = "Merchant SKU Descriptions";
const urlData = "merchantSkuDescriptions";
const bulkName = "sku";

class List extends BaseList {
  title = title;
  urlData = urlData;
  bulkName = bulkName;
}

class Detail extends BaseDetail {
  title = title;
  urlData = urlData;
  bulkName = bulkName;
  fields = fields;
}

export default { List, Detail };