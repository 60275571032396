import React from "react";
import PropTypes from "prop-types";
import { Row, Spin, Icon, message } from "antd";
import { get } from "lodash";
import { CheckPermission } from "App/Utils";
import PageUnauthorized from "App/Component/PageUnauthorized";
import { forcePermissions } from "App/Data";

class BaseRedirectMenu extends React.PureComponent {
  constructor(props) {
    super(props);

    this.menu = [{ list: [] }] // standard format menu
    const isSuperuser = get(props.userReducer, ['user', 'is_superuser'])
    this.state = {
      isCheckingPermission: !isSuperuser,
      isAccessAllowed: isSuperuser,
    }
  }

  setThisState = (obj, callback = () => null, force) => {
    if (force === true) {
      return this.setState(obj, callback)
    } else {
      for (const key in obj) {
        if (obj[key] !== this.state[key]) {
          return this.setState(obj, callback)
        }
      }
    }
    callback()
  }

  handleCheckPermission = (callback = () => null) => {
    this.setThisState({ isCheckingPermission: true }, () => {
      for (const index in this.menu.list) {
        if (this.menu.list[index].menus) {
          for (let j = 0; j < this.menu.list[index].menus.length; j += 1) {
            if (
              CheckPermission.checkAllowed({
                userReducer: this.props.userReducer,
                code: get(this.menu.list[index].menus[j], 'code',  '').replace('{}', 'view')
              })
            ) {
              callback({ isAccessAllowed: true, urlTarget: this.menu.list[index].menus[j].url })
              return
            }
          }
        } else {
          if (CheckPermission.checkAllowed({ userReducer: this.props.userReducer, code: get(this.menu.list[index], 'code', '').replace('{}', 'view') })) {
            callback({ isAccessAllowed: true, urlTarget: this.menu.list[index].url })
            return
          }
        }
      }
      callback({ isAccessAllowed: false })
    })
  }

  getConditionCheckPermission = () => this.state.isCheckingPermission

  checkRedirect = () => {
    // Please use callbackAllowed when access is allowed
    if (this.getConditionCheckPermission()) {
      this.handleCheckPermission(res => {
        if (get(res, 'isAccessAllowed')) {
          this.callbackAllowed(res.urlTarget)
        } else {
          this.setState({ isCheckingPermission: false, isAccessAllowed: get(res, 'isAccessAllowed') })
        }
      })
    } else {
      this.callbackAllowed(get(this.menu.list, [0, 'url']))
    }
  }

  callbackAllowed = (urlTarget) => {
    if (urlTarget) {
      this.props.history.replace(urlTarget)
    } else {
      message.error('Error when find menu in this module, please select navigation manually in left section.', 3)
    }
  }

  getComponentAllowed = () => <PageUnauthorized />

  getPageUnauthorized = () => <PageUnauthorized />

  componentDidMount() {
    this.checkRedirect()
  }

  render() {
    const { isCheckingPermission, isAccessAllowed } = this.state

    if (isCheckingPermission) {
      return (
        <Row type='flex' justify='center' align='middle' style={{ marginTop: '30vh' }}>
          <Spin
            size='large'
            tip='Checking permission...'
            indicator={<Icon spin type='loading' style={{ fontSize: '4rem' }} />}
          />
        </Row>
      )
    }

    return isAccessAllowed ? this.getComponentAllowed() : this.getPageUnauthorized()
  }
}

BaseRedirectMenu.propTypes = {
  userReducer: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default BaseRedirectMenu