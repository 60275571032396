import React from 'react'
import PropTypes from 'prop-types'
import { Prompt } from 'react-router-dom'
import { Modal, Row, Col, Form, Button, message, Tabs } from 'antd'
import { get, cloneDeep } from 'lodash'
import { FormContext, FormProvider } from 'App/Component/Context/ContextForm'
import DynamicField from 'App/Component/DynamicField'
import fields from 'App/Screens/SubModules/Merchant/Group/Management/fields'
import { RequestV2 as Request } from 'App/Utils'
import Lib2 from 'App/Component/Singlepage/Lib2'
import History from "App/Screens/SubModules/Component/History";
const { TabPane } = Tabs

class PageSetting extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isFieldsTouched: false,
      isSaving: false,
      activeKey: "1"
    }
  }

  onBlur = (value, key, type) => {
    const { merchantGroup } = this.props
    if (get(merchantGroup, key) !== value) {
      this.setState({ isFieldsTouched: true })
    }
  }

  onRevert = () => {
    if (this.form) {
      this.form.resetFields()
      this.setState({ isFieldsTouched: false })
    } else {
      message.error('Error in code PageSetting, form not found. [function => onRevert]')
    }
  }

  checkNeedCopy = (prevValues, nextValues, callback) => {
    const prevModeId = get(prevValues, ['mapping_mode', 'pk'])
    const nextModeId = get(nextValues, ['mapping_mode'])
    if (prevModeId && nextModeId) {
      if (prevModeId !== nextModeId) {
        Modal.confirm({
          title: 'Mapping Mode will change',
          content: 'Do you want to copy SKU Mapping from Mapping Mode before?',
          cancelText: 'Save without copy',
          okText: 'Copy',
          cancelButtonProps: {
            onClick: () => {
              callback()
              Modal.destroyAll()
            }
          },
          onCancel: () => {
            // When press escape button
            message.info('Save Merchant Group has been cancelled')
            this.setState({ isSaving: false })
          },
          onOk: () => {callback(true)},
        })
        return
      }
    }
    callback()
  }

  onSave = () => {
    if (this.form) {
      this.form.validateFieldsAndScroll({scroll: {offsetTop: 150}}, (err, values) => {
        if (!err) {
          this.setState({ isSaving: true }, () => {
            const { merchantGroup } = this.props
            const convertedValues = Lib2.getValuesWithFile(values)
            this.checkNeedCopy(merchantGroup, convertedValues, withCopy => {
              const additionalBody = withCopy ? { copy_sku_mapping: true } : {}
              Request(
                'put',
                'module-merchant-group-update',
                {},
                {...convertedValues, ...additionalBody},
                [merchantGroup.pk],
                this.saveSuccess,
                this.saveFailed,
              )
            })
          })
        }
      })
    } else {
      message.error('Error in code PageSetting, form not found. [function => onSave]')
    }
  }

  saveSuccess = (response) => {
    message.success(`${response.data.name} has been saved.`)
    this.props.setMerchantGroup(response.data)
    this.setState({ isSaving: false, isFieldsTouched: false })
  }

  saveFailed = (error) => {
    const errors = get(error, ['response', 'data'])
    if (errors && typeof errors === 'object') {
      this.setFieldsError(errors)
    } else {
      message.error('Failed to save Merchant, please contact your admin! code: ' + get(error, ['response', 'status'], 'Unknown.'), 5)
    }
    this.setState({ isSaving: false })
  }

  setFieldsError = (objError) => {
    const obj = {}
    for (const key in objError) {
      message.error('Error in ' + key + ' : ' + objError[key][0], 5)
      const tempValue = this.form.getFieldValue(key)
      obj[key] = {
        value: tempValue,
        errors: [new Error(objError[key][0])]
      }
    }
    this.form.setFields(obj)
  }

  render() {
    const { readOnly, userReducer, merchantGroup, setMerchantGroup } = this.props
    const { isFieldsTouched, activeKey } = this.state

    return (
      <div>
        <Prompt
          when={isFieldsTouched}
          message='You will lose any unsaved changes. Continue?'
        />
        <Tabs activeKey={activeKey} onChange={(activeKey)=>this.setState({ activeKey })} tabPosition={"left"}>
          <TabPane tab="Edit" key="1">
            <>
              <Row style={{ marginTop: '12px' }}>
                <FormProvider>
                  <Form>
                    {fields.map((elem) => {
                      return (
                        <DynamicField
                          {...elem}
                          operation={readOnly ? 'view' : 'edit'}
                          key={elem.dataIndex}
                          onBlur={this.onBlur}
                          userReducer={userReducer}
                          dataRecord={get(merchantGroup, elem.dataIndex)}
                        />
                      )
                    })}
                    <FormContext.Consumer>
                      {form => {this.form = form}}
                    </FormContext.Consumer>
                  </Form>
                </FormProvider>
              </Row>
              <Row type='flex' justify='space-between' align='middle' style={{ backgroundColor: '#fff', padding: '12px 0', borderTop: '1px solid #e8e8e8'}}>
                <Col>
                  {/* <Button type='danger'>Button Danger</Button> */}
                </Col>
                <Col>
                  <Row type='flex' gutter={[12, 12]}>
                    {isFieldsTouched && (
                      <Col>
                        <Button onClick={this.onRevert}>
                          Revert to Saved
                        </Button>
                      </Col>
                    )}
                    {!readOnly && (
                      <Col>
                        <Button icon={this.state.isSaving ? 'loading' : 'save'} type='primary' onClick={this.onSave}>
                          Update
                        </Button>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </>
          </TabPane>
          <TabPane tab="History" key="2">
            <History 
              url="module-merchant-group-history" 
              urlDetail="module-merchant-group-history-detail" 
              id={get(merchantGroup, 'pk')} 
              title={get(merchantGroup, 'name')} 
              fields={fields}
              updateHeader={(data)=>{
                setMerchantGroup(data)
                this.setState({ activeKey: "1" })
              }}
            />
          </TabPane>
        </Tabs>
      </div>
    )
  }
}

PageSetting.propTypes = {
  userReducer: PropTypes.object.isRequired,
  merchantGroup: PropTypes.object.isRequired,
  setMerchantGroup: PropTypes.func,
  readOnly: PropTypes.bool,
}

PageSetting.defaultProps = {
  setMerchantGroup: () => null,
  readOnly: false,
}

export default PageSetting