export default [
  {
    title: 'Extra charge',
    type: 'SelectQuery',
    required: true,
    data: 'extraCharges',
    initialValue: '',
    note_text: '',
    additional: '',
    selectedKey: 'pk',
    selectedValue: 'name',
    dataIndex: 'extra_charge',
    order: 0,
  }, {
    title: 'Quantity',
    type: 'text',
    data: '',
    initialValue: '0',
    note_text: '',
    additional: '',
    dataIndex: 'quantity',
    order: 2,
  }, {
    title: 'Answer',
    type: 'select',
    data: [
      {
        alias: 'print',
        value: 'Print'
      }, {
        alias: 'scan',
        value: 'Scan'
      }, {
        alias: 'both',
        value: 'Both'
      }
    ],
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'answer',
    order: 3,
  },
]
