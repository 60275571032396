import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Spin, Icon } from "antd";
import { get } from "lodash";

import SubAllScreen from "App/Component/SubAllScreen";
import { PermissionName } from "App/Config";
import PageUnauthorized from "App/Component/PageUnauthorized";
import { forcePermissions } from "App/Data";
import { CheckPermission } from "App/Utils";
import HistoryDetail from "App/Component/HistoryAction/Detail";

// const forcePermissions = []

export default class Detail extends Component {
  constructor(props) {
    super(props)

    this.isSuperuser = get(props.userReducer, ['user', 'is_superuser'])
    // Default
    this.title = "";
    this.urlData = "";
    this.bulkName = "name";
    this.fields = [];
    this.tabs = [];
    this.children = null;
    this.isSubmodule = false;
    this.availablePermissions = []; // blank array is all allowed permissions
    this.propsDetail = {}; // see props documentation on App/Component/SubAllScreen or App/Component/Singlepage/Tab or the childrens
    this.state = {
      isCheckingPermission: !this.isSuperuser,
      isAccessAllowed: this.isSuperuser, // add, read, edit (change)
      currentPermissionSet: this.isSuperuser ? forcePermissions : [],
    };
  }

  setThisState = (obj, callback = () => null, force) => {
    if (force) {
      this.setState(obj, callback)
      return
    } else {
      for (const key in obj) {
        if (obj[key] !== this.state[key]) {
          this.setState(obj, callback)
          return
        }
      }
    }
    callback()
  }
  
  // return first on pathname like "module" in "example.com/module/.../..."
  getCurrentModule = pathname => {
    return pathname.split('/').filter(val => val)[0]
  }

  getAppName = () => {
    const urlNameSplitted = this.props.urlName.split(':') // ex: api:admin:estimation:linetemplate:list
    return urlNameSplitted[2]
  }

  getModelName = () => {
    const urlNameSplitted = this.props.urlName.split(':') // ex: api:admin:estimation:linetemplate:list
    return urlNameSplitted[3]
  }

  filterPermissionItems = (permissionItems) => {
    if (this.availablePermissions.length) {
      const allowedPermissionItems = []
      for (let i = 0; i < this.availablePermissions.length; i += 1) {
        for (let j = 0; j < permissionItems.length; j += 1) {
          if (permissionItems[j].includes(this.availablePermissions[i])) {
            allowedPermissionItems.push(permissionItems[j])
            break
          }
        }
      }
      return allowedPermissionItems
    }
    return permissionItems
  }

  getCodePart = () => this.props.match.params.id === 'add' ? PermissionName.CREATE.codePart : PermissionName.READ.codePart

  handleCheckPermission = () => {
    this.setThisState({ isCheckingPermission: true }, () => {
      const { userReducer } = this.props
      const appName = this.getAppName()
      const modelName = this.getModelName()
      const permissionItems = CheckPermission.getPermissionSet({ userReducer, appName, modelName })
      const currentPermissionSet = this.filterPermissionItems(permissionItems)
      const codePart = this.getCodePart()
      const isAccessAllowed = !!currentPermissionSet.find(val => val.search(codePart) !== -1)

      // const codePart = this.props.match.params.id === 'add' ? PermissionName.CREATE.codePart : PermissionName.READ.codePart
      // const currentModule = this.getCurrentModule(this.props.location.pathname)
      // const allowedModules = get(this.props.userReducer, ['user', 'allowed_modules'], [])
      // const selectedHeader = HeaderMenu.find(val => val.url === `/${currentModule}` && allowedModules.find(module => val.codename === module.codename))
      // let currentPermissionSet = []
      // if (selectedHeader) {
      //   const modulePermission = allowedModules.find(val => val.codename === selectedHeader.codename)
      //   // permission code format: "appnamelowercase.permissioncode_modelname"
      //   const urlNameSplitted = this.props.urlName.split(':')
      //   const permissionCode = `${urlNameSplitted[2]}.${codePart}_${urlNameSplitted[3]}`
      //   // Mencari permission set dari group-group (app)
      //   // findPermission:
      //   // for (let i = 0; i < modulePermission.groups.length; i += 1) {
      //   //   for (let j = 0; j < modulePermission.groups[i].sets.length; j += 1) {
      //   //     if (modulePermission.groups[i].sets[j].permissions.find(permission => permission.code === permissionCode)) {
      //   //       currentPermissionSet = modulePermission.groups[i].sets[j].permissions
      //   //       break findPermission
      //   //     }
      //   //   }
      //   // }
      //   // ---------------------------------------------------------------
      //   // Mencari permission set langsung dari sets (digabungkan dari BE)
      //   if (modulePermission) {
      //     for (let i = 0; i < modulePermission.sets.length; i += 1) {
      //       if (modulePermission.sets[i].permissions.find(permission => permission.code === permissionCode)) {
      //         currentPermissionSet = modulePermission.sets[i].permissions
      //         break
      //       }
      //     }
      //   }
      // }
      this.setState({ isCheckingPermission: false, isAccessAllowed, currentPermissionSet })
    })
  }

  getPropsComp = () => {
    return {
      // isSubmodule: this.isSubmodule, // belum fix digunakan
      permissions: this.state.currentPermissionSet,
      userReducer: this.props.userReducer,
      runRedux: this.props.runRedux,
      match: this.props.match,
      location: this.props.location,
      history: this.props.history,
      urlName: this.props.urlName,
      title: this.title,
      urlData: this.urlData,
      bulkName: this.bulkName,
      fields: this.fields,
      children: this.children,
      ...this.propsDetail
    }
  }

  getAllowedComponent = () => {
    if (this.props.match.params.historyId) {
      return (
        <HistoryDetail {...this.getPropsComp()} />
      )
    }
    return (
      <SubAllScreen
        {...this.getPropsComp()}
      />
    )
  }

  componentDidMount() {
    if (this.state.isCheckingPermission) {
      setTimeout(() => {
        this.handleCheckPermission();
      }, 150)
    }
  }

  render() {
    const { isCheckingPermission, isAccessAllowed, currentPermissionSet } = this.state

    if (isCheckingPermission) {
      return (
        <Row type='flex' justify='center' align='middle' style={{ marginTop: '30vh' }}>
          <Spin
            size="large"
            tip="Checking permission..."
            indicator={<Icon type="loading" style={{ fontSize: "4rem" }} spin />}
          />
        </Row>
      )
    }

    return isAccessAllowed ? (
      this.getAllowedComponent()
    ) : (
      <PageUnauthorized history={this.props.history} />
    );
  }
}

Detail.propTypes = {
  userReducer: PropTypes.object.isRequired,
  runRedux: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  children: PropTypes.object,
  urlName: PropTypes.string, // format for admin only, like api:admin:estimation:linetemplate:detail
}

Detail.defaultProps = {
  urlName: '',
}