import React from 'react'
import { Tag } from 'antd'

export default [
  {
    key: 'queue.label',
    label: 'Queue',
  }, {
    key: 'import_type',
    label: 'Import Type',
    render: (val, record) => <Tag color='#1890ff'>{val}</Tag>,
  }, {
    key: 'saved',
    label: 'Saved Status',
    type: 'bool',
  }, {
    key: 'started_time',
    label: 'Started Time',
    type: 'datetime',
  }, {
    key: 'valid',
    label: 'Valid Status',
    type: 'bool'
  }, {
    key: 'finished_time',
    label: 'Finished Time',
    type: 'datetime',
  }, {
    key: 'diff',
    label: 'Difference',
    type: 'htmlRows',
    span: 2,
  }, {
    key: 'data',
    label: 'Data',
    type: 'json',
    span: 2,
    inputProps: { collapsed: true },
  }, {
    key: 'errors',
    label: 'Errors',
    type: 'textarea',
    span: 2,
  },
]