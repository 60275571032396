import React from 'react'
import PropTypes from 'prop-types'
import { Drawer, Row, Col, Card, Typography, Spin, Icon, Empty, Input, Button, Affix, message } from 'antd'
import { get } from 'lodash'
import { RequestV2 as Request } from 'App/Utils'
import PageNotFound from 'App/Component/PageNotFound'

// Class ini dibuat memang bukan untuk penggunaan umum,
class DrawerGroups extends React.PureComponent {
  constructor(props) {
    super(props)

    this.params = {}
    this.state = {
      isLoading: true,
      isLoadMore: false,
      isSaving: false,
      filteredGroups: [],
      next: null,
      selectedGroups: [],
    }
  }

  setThisState = (obj, callback = () => null, force) => {
    if (force) {
      return this.setState(obj, callback)
    } else {
      for (const key in obj) {
        if (obj[key] !== this.state[key]) {
          return this.setState(obj, callback)
        }
      }
    }
    return callback()
  }

  readGroups = (keyLoading = 'isLoading') => {
    this.setThisState({ [keyLoading]: true }, () => {
      Request(
        'get',
        'module-merchant-group-read',
        {},
        { ...this.params },
        [],
        this.readGroupsSuccess,
        this.readGroupsFailed,
        { keyLoading }
      )
    })
  }

  readGroupsSuccess = (response, extra) => {
    let mergeGroups = response.data.results
    if (extra.keyLoading === 'isLoadMore') {
      mergeGroups = [...this.state.filteredGroups, ...response.data.results]
    }
    const filteredGroups = this.filterGroups(mergeGroups)
    this.setState({ [extra.keyLoading]: false, filteredGroups, next: response.data.next })
  }

  readGroupsFailed = (error, extra) => {
    message.error('Failed when request merchant group list.')
    this.setState({ [extra.keyLoading]: false })
  }

  // return filtered except by groups and selected users
  filterGroups = (groups) => {
    const filteredGroups = groups.filter(group => !this.props.groups.find(pGroup => pGroup.pk === group.pk))
    const filteredGroupsBySelected = filteredGroups.filter(group => !this.state.selectedGroups.find(selected => selected.pk === group.pk))
    return filteredGroupsBySelected
  }

  onSearch = (val, event) => {
    this.params.search = val
    this.params.page = 1
    this.readGroups()
  }

  onSelectGroup = (newSelectedGroup) => {
    // Jangan langsung mengganti variable state, seperti push, unshift, dll.
    const { filteredGroups, selectedGroups } = this.state
    const remainingUsers = filteredGroups.filter((val) => val.pk !== get(newSelectedGroup, 'pk'))
    // selectedGroups.unshift(...filteredGroups.splice(0, 1)) // don't do this
    this.setState({ filteredGroups: remainingUsers, selectedGroups: [newSelectedGroup, ...selectedGroups] })
  }

  onDeselectGroup = (removedGroup) => {
    // Jangan langsung mengganti variable state, seperti push, unshift, dll.
    const { filteredGroups, selectedGroups } = this.state
    const remainingSelected = selectedGroups.filter((val) => val.pk !== get(removedGroup, 'pk'))
    // filteredGroups.unshift(...selectedGroups.splice(0, 1)) // don't do this
    this.setState({ filteredGroups: [removedGroup, ...filteredGroups], selectedGroups: remainingSelected })
  }

  onApply = () => {
    const { selectedGroups } = this.state
    if (selectedGroups.length > 0) {
      this.setState({ isSaving: true }, () => {
        setTimeout(() => {
          const ids = this.state.selectedGroups.map(val => val.pk)
          this.props.onJoinGroups(ids, this.state.selectedGroups, (isSuccess) => {
            if (isSuccess) {
              this.setState({ isSaving: false, selectedGroups: [] })
            } else {
              this.setState({ isSaving: false })
            }
          })
        }, 250)
      })
    } else {
      message.info('Please select at least 1 merchant group to join with Mapping Mode')
    }
  }

  onLoadMore = () => {
    const queryString = this.state.next.split('?')[1]
    const params = queryString.split('&')
    for (const key in params) {
      const tempParam = params[key].split('=')
      if (tempParam[0] === 'page') {
        this.params.page = tempParam[1]
        return this.readGroups('isLoadMore')
      }
    }
    return message.error('Cannot create request, no more data found.')
  }

  onRemoveAllSelected = () => {
    const { filteredGroups, selectedGroups } = this.state
    this.setState({ filteredGroups: [...selectedGroups, ...filteredGroups], selectedGroups: [] })
  }

  componentWillReceiveProps(nextProps) {
    const { filteredGroups } = this.state
    if (!this.props.visible && nextProps.visible && filteredGroups.length === 0) {
      this.readGroups()
    }
  }

  componentDidMount() {
    if (this.props.visible) {
      this.readGroups()
    }
  }

  render() {
    const { visible, onClose } = this.props
    const { isLoading, filteredGroups, selectedGroups, next, isLoadMore, isSaving } = this.state

    return (
      <Drawer
        closable={false}
        width='65%'
        bodyStyle={{ marginTop: 0, paddingTop: 0 }}
        visible={visible}
        onClose={onClose}
      >
        <Spin spinning={isLoading}>
          <Row>
            <Affix offsetTop={0} target={() => document.getElementsByClassName('ant-drawer-wrapper-body')[0]}>
              <div className='header-list-user' style={{ padding: '12px 0', backgroundColor: '#fff' }}>
                <div style={{ marginBotttom: '12px' }}>
                  <Row type='flex' justify='center' style={{ marginBottom: '12px' }}>
                    <Col style={{ fontSize: '24px', fontWeight: 500, lineHeight: 1.35, color: 'rgba(0, 0, 0, 0.85)', marginBottom: '12px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                      Add Group
                    </Col>
                  </Row>
                </div>
                <Row type='flex' justify='space-between' gutter={[12, 12]}>
                  <Col>
                    <Input.Search placeholder='Search...' onSearch={this.onSearch} />
                  </Col>
                  <Col>
                    <Button type='primary' icon='save' loading={isSaving} onClick={this.onApply}>
                      Apply
                    </Button>
                  </Col>
                </Row>
              </div>
            </Affix>
            <Row>
              <Col xs={24} sm={24} md={12}>
                <div style={{ padding: '12px', fontSize: '18px', fontWeight: 500, color: 'rgba(0, 0, 0, 0.85)', borderBottom: '1px solid #e8e8e8', borderRight: '1px solid #e8e8e8', textAlign: 'center', backgroundColor: '#f3f3f3' }}>
                  Available Group: {filteredGroups.length}
                </div>
                { filteredGroups.length === 0 && selectedGroups.length === 0 && !next && (
                  <Row>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='No user found' />
                    <Row type='flex' justify='center' style={{ marginTop: '12px' }}>
                      <Button onClick={() => this.readGroups()}>
                        Try again
                      </Button>
                    </Row>
                  </Row>
                )}
                { filteredGroups.map((group) => (
                  <div style={{ borderBottom: '1px solid #e8e8e8', borderRight: '1px solid #e8e8e8' }} key={group.pk}>
                    <div className='selection-row-item' onClick={() => this.onSelectGroup(group)}>
                      <div style={{ fontSize: '16px', fontWeight: 500, color: '#030303', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', marginBottom: '2px' }}>
                        {group.name}
                      </div>
                      <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                        {(group.exporter_name || '-')}
                      </div>
                    </div>
                  </div>
                ))}
                { next && (
                  <Row type='flex' justify='center' style={{ marginTop: '12px' }}>
                    <Button loading={isLoadMore} onClick={this.onLoadMore}>
                      Load more
                    </Button>
                  </Row>
                )}
              </Col>
              <Col xs={24} sm={24} md={12}>
                <div style={{ padding: '12px', fontSize: '18px', fontWeight: 500, color: 'rgba(0, 0, 0, 0.85)', borderBottom: '1px solid #e8e8e8', textAlign: 'center', backgroundColor: '#f3f3f3' }}>
                  Chosen Group: {selectedGroups.length}
                  { selectedGroups.length > 0 && (
                    <span title='Remove all selected' onClick={this.onRemoveAllSelected} style={{ float: 'right', color: '#ff4d4f', cursor: 'pointer' }}>
                      x
                    </span>
                  )}
                </div>
                { selectedGroups.length === 0 && (
                  <Row type='flex' justify='center'>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='No selected users' />
                  </Row>
                )}
                { selectedGroups.map((group) => (
                  <div style={{ borderBottom: '1px solid #e8e8e8' }} key={group.pk}>
                    <div className='selection-row-item' onClick={() => this.onDeselectGroup(group)}>
                      <div style={{ fontSize: '16px', fontWeight: 500, color: '#030303', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', marginBottom: '2px' }}>
                        {group.name}
                      </div>
                      <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                        {(group.exporter_name || '-')}
                      </div>
                    </div>
                  </div>
                ))}
              </Col>
            </Row>
          </Row>
        </Spin>
      </Drawer>
    )
  }
}

DrawerGroups.propTypes = {
  userReducer: PropTypes.object.isRequired,
  groups: PropTypes.array,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onJoinGroups: PropTypes.func,
}

DrawerGroups.defaultProps = {
  groups: [],
  visible: false,
  onClose: () => null,
  onJoinGroups: () => null,
}

export default DrawerGroups