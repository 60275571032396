import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Row, Col, Spin, Button } from 'antd'
import { get } from 'lodash'
import GroupList from './List'
import { Modules } from 'App/Utils'
import ErrorLabel from 'App/Screens/SubModules/UserGroup/Group/Management/Pages/ErrorLabel'

class PageAddGroup extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      groups: [],
      rawResponse: null,
    }
    this.groupColumns = [
      {
        title: 'Name',
        dataIndex: 'name',
      }, {
        title: 'Exporter Name',
        dataIndex: 'exporter_name',
        render: (item) => item || '-',
      }, {
        title: 'Show Seed',
        dataIndex: 'show_seed',
        render: (val, record) => {
          return (
            Modules.renderStatusAIU(val)
          )
        }
      }, {
        title: 'Operation',
        dataIndex: 'operation',
        fixed: 'right',
        width: '125px',
        render: (val, record) => {
          return (
            <Row type='flex' gutter={[6, 6]}>
              <Col>
                <Link to={`/merchant/group/${record.pk}`}>
                  <Button icon='edit' type='primary' size='small' title='View Merchant Group' />
                </Link>
              </Col>
              <Col>
                <Button icon='user-delete' type='danger' size='small' title='Remove Merchant Group' onClick={(event) => {event.stopPropagation(); this.onRemoveGroups([record.pk])}} />
              </Col>
            </Row>
          )
        }
      }
    ]
  }

  // Jangan di hapus
  setThisState = (obj, callback = () => null, force) => {
    if (force) {
      return this.setState(obj, callback)
    } else {
      for (const key in obj) {
        if (obj[key] !== this.state[key]) {
          return this.setState(obj, callback)
        }
      }
    }
    return callback()
  }

  onRemoveGroups = (ids = [], callback = () => null) => {
    const remainingGroups = this.state.groups.filter(group => ids.findIndex(id => group.pk === id) === -1)
    this.setState({ errorMessage: '', groups: remainingGroups }, () => callback(true))
  }

  onJoinGroups = (ids, users, callback) => {
    this.setState(prevState => ({ errorMessage: '', groups: [...prevState.groups, ...users] }), () => {
      this.props.onFieldTouched(true)
      callback(true)
    })
  }

  validateFields = () => {
    return this.state.groups.map(group => group.pk)
  }

  setError = (errorMessage) => {
    this.props.setActiveKey(this.props.tabKey, () => {
      this.setState({ errorMessage })
    })
  }

  render() {
    const { userReducer } = this.props
    const { groups, errorMessage } = this.state

    return (
      <Spin spinning={false}>
        { errorMessage && <ErrorLabel errorMessage={errorMessage} /> }
        <GroupList
          dataSource={groups}
          columns={this.groupColumns}
          userReducer={userReducer}
          onJoinGroups={this.onJoinGroups}
          removeGroups={this.onRemoveGroups}
        />
      </Spin>
    )
  }
}

PageAddGroup.propTypes = {
  userReducer: PropTypes.object.isRequired,
  tabKey: PropTypes.string,
  setActiveKey: PropTypes.func,
  onFieldTouched: PropTypes.func,
}

PageAddGroup.defaultProps = {
  handleHistoryAction: () => null,
  tabKey: null,
  setActiveKey: (tabKey, callback = () => null) => callback(),
  onFieldTouched: () => null,
}

export default PageAddGroup