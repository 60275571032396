import React from 'react'
import PropTypes from 'prop-types'
import { Transfer, message } from 'antd'
import { get, toLower } from 'lodash'

// Ini belum handle jika parent ingin mengubah targetKeys ini
class CustomTransfer extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      targetKeys: props.initialValue,
    }
  }

  filterOption = (inputValue, option) => {
    const { filterKeys } = this.props
    const inputValueLower = inputValue.toLowerCase()
    for (const val of filterKeys) {
      const optionValLower = toLower(get(option, val) || '')
      if (optionValLower.indexOf(inputValueLower) > -1) {
        return true
      }
    }
    return false
  }

  handleChange = (targetKeys) => {
    if (!this.props.readOnly) {
      this.props.setTouched(true)
      this.props.onChange(targetKeys)
      this.setState({ targetKeys })
    } else {
      message.info(`Can't make changes, because of Read only access.`, 3)
    }
  }

  validateFields = () => {
    return this.state.targetKeys
  }

  render() {
    const { titles, dataSource, render, rowKey, transferProps } = this.props
    const { targetKeys } = this.state

    return (
      <Transfer
        className='ant-transfer-list-50'
        lazy={false}
        showSearch
        filterOption={this.filterOption}
        targetKeys={targetKeys}
        onChange={this.handleChange}
        {...transferProps}
        titles={titles}
        dataSource={dataSource}
        render={render}
        rowKey={rowKey}
      />
    )
  }
}

CustomTransfer.propTypes = {
  // for input (Transfer)
  titles: PropTypes.array,
  dataSource: PropTypes.array,
  render: PropTypes.func,
  rowKey: PropTypes.func,
  initialValue: PropTypes.array,
  transferProps: PropTypes.object,
  // for another
  filterKeys: PropTypes.array,
  setTouched: PropTypes.func,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
}

CustomTransfer.defaultProps = {
  titles: ['Source', 'Target'],
  dataSource: [],
  render: (record) => get(record, 'name'),
  rowKey: (record) => get(record, 'pk'),
  initialValue: [],
  transferProps: {},
  filterKeys: ['name'],
  setTouched: () => null,
  onChange: () => null,
  readOnly: false,
}

export default CustomTransfer