export default [
  {
    title: 'Prefill set',
    type: 'SelectQuery',
    data: 'prefillSets',
    required: true,
    initialValue: undefined,
    note_text: '',
    dataIndex: 'prefill_set',
    fieldKey:'prefill_set',
    selectedKey: 'pk',
    selectedValue: 'name',
    additional: 'edit|add',
    relations: ['line_template'],
    order: 0,
    disableChange: true
  }, {
    title: 'Line template',
    type: 'SelectQuery',
    data: 'lineTemplates',
    required: true,
    dataIndex: 'line_template',
    fieldKey: 'line_template',
    initialValue: undefined,
    note_text: '',
    selectedKey: 'pk',
    selectedValue: 'label',
    additional: 'edit|add',
    order: 1,
    width: '300px',
    view: true,
    editable: true,
    // foreignKey: 'prefill_set',
    configParamProps: [
      { key: 'subsection', sourceField: 'prefill_set', sourceKey: 'subsection_id' },
    ],
  }, {
    title: 'Product',
    type: 'SelectQuery',
    data: 'products',
    initialValue: undefined,
    note_text: '',
    dataIndex: 'product',
    fieldKey: 'product',
    selectedKey: 'pk',
    selectedValue: 'name',
    additional: 'edit|add',
    width: '300px',
    editable: true,
    order: 2,
  }, {
    title: 'Usage',
    type: 'SelectQuery',
    data: 'lineUsages',
    initialValue: undefined,
    note_text: '',
    dataIndex: 'usage',
    fieldKey: 'usage',
    selectedKey: 'pk',
    selectedValue: 'label',
    width: '300px',
    additional: 'edit|add',
    editable: true,
    order: 3,
  }, {
    title: 'Subheading',
    type: 'SelectQuery',
    data: 'subheadings',
    initialValue: undefined,
    note_text: '',
    dataIndex: 'subheading',
    fieldKey: 'subheading',
    selectedKey: 'pk',
    selectedValue: 'label',
    additional: 'edit|add',
    width: '300px',
    editable: true,
    order: 4,
  }, {
    title: 'Extra1',
    type: 'text',
    data: '',
    initialValue: '',
    fieldKey: 'extra1',
    note_text: '',
    dataIndex: 'extra1',
    editable: true,
    additional: '',
    order: 5,
  }, {
    title: 'Extra2',
    type: 'text',
    data: '',
    initialValue: '',
    dataIndex: 'extra2',
    fieldKey: 'extra2',
    editable: true,
    note_text: '',
    additional: '',
    order: 6,
  }, {
    title: 'Waste pct',
    type: 'number',
    data: '',
    initialValue: null,
    dataIndex: 'waste_pct',
    fieldKey: 'waste_pct',
    editable: true,
    note_text: '',
    additional: '',
    order: 7,
    step: 0.01,
  }, {
    title: 'Extra3',
    type: 'text',
    data: '',
    initialValue: '',
    dataIndex: 'extra3',
    fieldKey: 'extra3',
    note_text: 'legacy, only used for import',
    editable: true,
    additional: '',
    order: 8,
  }, {
    title: 'Import session',
    type: 'text',
    data: '',
    initialValue: '',
    dataIndex: 'import_session',
    note_text: '',
    additional: '',
    order: 10,
  },
];
