import React from "react";
import PropTypes from "prop-types";
import { Card } from "antd";
import { Lib } from "App/Utils";
import SubAllList from "App/Component/SubAllList";
import descriptor from "./descriptorOffline";

class HolidayList extends React.PureComponent {
  onAddHoliday = () => {
    const { branch } = this.props
    const historyState = {
      dataIdentifier: {
        branch: { pk: branch.pk, name: branch.name }
      }
    }
    Lib.historyAction(this.props.history, '/branch-queue/holiday/add', historyState).push()
  }

  onDetailClick = (id) => {
    Lib.historyAction(this.props.history, `/branch-queue/holiday/${id}`).push()
  }

  componentWillUnmount() {
    Lib.windowStop();
  }

  render() {
    const { branch, permissionItems } = this.props

    return (
      <Card>
        <SubAllList
          permissions={permissionItems}
          pageTitle="Holiday"
          avatar="name"
          descriptor={descriptor}
          masterKey="module-branch-holiday"
          onAddClick={this.onAddHoliday}
          onDetailClick={this.onDetailClick}
          readParams={{ branch: branch.pk }}
        />
      </Card>
    );
  }
}

HolidayList.propTypes = {
  permissionItems: PropTypes.array,
}

HolidayList.defaultProps = {
  permissionItems: [],
}

export default HolidayList;
