import React from 'react'

import SelectQuery from 'App/Component/SelectQuery'

class Filter extends React.PureComponent {
  onChangeFilter = (filter, value) => {
    this.props.setParamProps({
      [this.props.parentDataIndex]: {
        ...this.props.paramProps,
        [filter.dataIndex]: value && value.key
      }
    })

    if (filter.relations) {
      for (const relation of filter.relations) {
        this.props.form.setFieldsValue({
          [relation]: undefined
        })
      }
    }
  }

  render() {
    const { filters, form } = this.props

    return (
      <div style={{ width: ''}}>
        {filters.map(filter => {
          const paramProps = {}
          for (const i in filter.foreignKeys) {
            if (this.props.paramProps[filter.foreignKeys[i]]) {
              paramProps[filter.foreignKeys[i]] = this.props.paramProps[filter.foreignKeys[i]]
            }
          }

          return (
            <div key={filter.dataIndex} style={{ marginBottom: '12px' }}>
              <div style={{ marginBottom: '6px', color: '#595959', fontWeight: 500 }}>
                {filter.title} :
              </div>
              <div>
                {form.getFieldDecorator(filter.dataIndex, {
                  rules: [],
                  valuePropName: 'value'
                })(
                  <SelectQuery
                    urlKey={filter.urlKey}
                    valKey={filter.selectedKey}
                    valLabel={filter.selectedValue}
                    paramProps={paramProps}
                    dropdownClassName='width-auto2'
                    placeholder={filter.title}
                    style={{ width: '100%' }}
                    onChange={(value) => this.onChangeFilter(filter, value)}
                  />
                )}
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}

export default Filter