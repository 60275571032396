import { BaseDetail } from 'App/Screens/SubModules/Component';
import fields from 'App/Screens/Job/ExtraCharges/fields';
import { Merchant } from './References';
import children from '../children';

class PageDetail extends BaseDetail {
  constructor(props) {
    super(props)

    this.urlKey = 'module-merchant-extraCharge-detail'
    this.title = 'Extra Charge'
    this.urlList = '/merchant/extra-charge'
    this.headerDescriptions = [
      { key: 'name', label: 'Name' },
      { key: 'created', label: 'Created', type: 'datetime' },
      { key: 'created_by.username', label: 'Created By' },
      { key: 'modified', label: 'Modified', type: 'datetime' },
      { key: 'modified_by.username', label: 'Modified By' },
    ]
    this.fields = fields
    this.panes = [Merchant]
    this.children = children
    this.history = {
      url: 'module-merchant-extraCharge-history',
      fields: fields,
      urlDetail: "module-merchant-extraCharge-history-detail",
      id: props.match.params.id,
      title: 'name'
    }
    this.refField = {
      name: 'extra_charge', // dataIndex in field
      type: 'object', // SelectQuery field, need valKey & valLabel (for object)
      valKey: 'pk',
      valLabel: 'name',
    }
  }
}

export default PageDetail