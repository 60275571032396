import { BaseList } from "App/Screens/SubModules/Component";
import descriptor from "./descriptorOffline";

class UserTrackerList extends BaseList {
  constructor(props) {
    super(props)

    this.appName = 'account'
    this.modelName = 'user'
    this.availablePermissions = ['.view_', '.add_', '.change_']
    this.propsList = {
      avatar: 'username',
      pageTitle: 'Tracker User',
      descriptor,
      masterKey: 'module-groupUser-userTracker',
      // importSetting: {
      //   app_label: 'account',
      //   model_name: 'user',
      // },
      // urlKeyExport: 'module-groupUser-user-export',
      operation: false,
    }
    this.state = {
      ...this.state,
      currentPermissionSet: !this.state.isCheckingPermission ? this.availablePermissions : [],
    }
  }
}

export default UserTrackerList