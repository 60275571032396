export default [
  {
    title: 'Name',
    dataIndex: 'name',
    type: 'text',
    required: true,
    initialValue: '',
  },
  {
    title: 'Keywords',
    dataIndex: 'keywords',
    required: true,
    type: 'select_multiple_static',
    initialValue: [],
    inputProps: { mode: 'tags', tokenSeparators: [','] },
    data: [],
  },
  {
    title: 'Skip Keyword',
    dataIndex: 'skip_keyword',
    type: 'text',
    initialValue: null,
    emptyValue: null, // key baru, jika value kosong maka API minta mengirim null, bukan string kosong
  },
  {
    title: 'Selector',
    dataIndex: 'selector',
    type: 'select_multiple_static',
    initialValue: [],
    inputProps: { mode: 'tags', tokenSeparators: [','] },
    data: [],
  },
  // ------ USING FILTER BUTTON -----------------------------------------
  {
    title: 'Subsections',
    dataIndex: 'subsections',
    required: true,
    type: 'select_multiple',
    data: 'subsections',
    selectedKey: 'pk',
    selectedValue: 'identifier',
    initialValue: [],
    dropdownClassName: 'width-auto',
    filters: [
      {
        title: 'Section',
        dataIndex: 'section',
        urlKey: 'sections-autocomplete',
        selectedKey: 'pk',
        selectedValue: 'name',
        relations: ['subsections'], // for clear fields when onChange
      },
    ],
  },
  // ------ USING 2 FIELDS ------------------------------------------------
  // {
  //   title: 'Section',
  //   dataIndex: 'section',
  //   required: true,
  //   type: 'SelectQuery',
  //   data: 'sections',
  //   selectedKey: 'pk',
  //   selectedValue: 'name',
  //   initialValue: [],
  //   dropdownClassName: 'width-auto',
  //   relations: ['subsections'],
  // },
  // {
  //   title: 'Subsections',
  //   dataIndex: 'subsections',
  //   required: true,
  //   type: 'select_multiple',
  //   data: 'subsections',
  //   selectedKey: 'pk',
  //   selectedValue: 'identifier',
  //   initialValue: [],
  //   dropdownClassName: 'width-auto',
  //   foreignKey: 'section',
  // },
  // -------------------------------------------------------------------
  {
    title: 'Groups',
    dataIndex: 'groups',
    type: 'select_multiple',
    data: 'module-expertSystem-keywordGroup',
    selectedKey: 'pk',
    selectedValue: 'name',
    initialValue: [],
  },
  {
    title: 'Target Pages',
    dataIndex: 'pages',
    type: 'select_multiple',
    data: 'module-expertSystem-keywordPage',
    selectedKey: 'pk',
    selectedValue: 'name',
    initialValue: [],
  },
  {
    title: 'Order',
    dataIndex: 'order',
    type: 'number',
    initialValue: null,
  },
  {
    title: 'Ignore Space',
    dataIndex: 'ignore_space',
    type: 'checkbox',
    initialValue: true,
  },
  {
    title: 'Fixed',
    dataIndex: 'is_fixed',
    type: 'checkbox',
    initialValue: false,
  },
  {
    title: 'Is Active',
    dataIndex: 'is_active',
    type: 'checkbox',
    initialValue: true,
  },
  {
    title: 'Is Important',
    dataIndex: 'is_important',
    type: 'checkbox',
    initialValue: false,
  },
]
