import React from 'react'
import PropTypes from 'prop-types'
import { Button, Col, message, Pagination, Row, Table } from 'antd'
import { get } from 'lodash'
import moment from 'moment'
import { Lib, RequestV2 as Request } from 'App/Utils'
import PageError from 'App/Component/PageError'
import LoadingSpin from 'App/Component/LoadingSpin'

class HistoryAction extends React.PureComponent {
  constructor(props) {
    super(props)

    this.page = '1'
    this.limit = '100'
    this.params = ['page', 'limit']
    this.columns = [
      {
        title: 'Date / Time',
        dataIndex: 'created',
        width: '195px',
        render: (item) => item ? moment(item).format('ddd, DD MMM YYYY hh:mm A') : '-',
      },
      {
        title: 'User',
        dataIndex: 'user',
        width: '130px',
        render: (user) => user ? `${user.username} (${user.full_name})` : '-',
      },
      {
        title: 'Action',
        dataIndex: 'comment',
      }
    ]

    this.state = {
      errorResponse: null,
      isLoading: true,
      isReloading: false,
      rawData: null,
    }
  }

  getThisParam = () => {
    const thisParam = {}
    for (let i = 0; i < this.params.length; i += 1) {
      const valParam = this[this.params[i]]
      if (valParam || valParam === 0) {
        thisParam[this.params[i]] = valParam
      }
    }
    return thisParam
  }

  read = (keyLoading = 'isLoading', callback = () => null) => {
    this.setState({ [keyLoading]: true }, () => {
      const bodyParam = this.getThisParam()
      Request(
        'get',
        this.props.urlKeyHistory,
        {},
        bodyParam,
        [this.props.id],
        this.readSuccess,
        this.readFailed,
        { keyLoading, callback },
      )
    })
  }

  readSuccess = (response, extra) => {
    this.setState({ [extra.keyLoading]: false, rawData: response.data, errorResponse: null }, () => {
      extra.callback(true, response)
    })
  }

  readFailed = (error, extra) => {
    this.setThisParam()
    if (!this.state.isLoading) Lib.errorMessageHandler(get(error, 'response'))
    this.setState({ [extra.keyLoading]: false, errorResponse: get(error, 'response') }, () => {
      extra.callback(false, error)
    })
  }

  getPageInformation = () => {
    const { rawData } = this.state
    const remaining = rawData.count - (rawData.limit * (rawData.current - 1))
    const totalCurrentPage = remaining > rawData.limit ? rawData.limit : remaining
    return (
      <div>
        Total <b>{totalCurrentPage}</b> from <b>{rawData.count}</b> entries
      </div>
    )
  }

  setThisParam = () => {
    const { rawData } = this.state
    if (rawData) {
      this.page = rawData.current
      this.limit = rawData.limit
    }
  }

  reload = (keyLoading = 'isReloading', callback = () => null) => {
    this.read(keyLoading, (isSuccess) => !isSuccess && callback(true))
  }

  onHeaderRow = () => ({ style: { background: '#ededed' } })

  onRow = (record, rowIndex) => ({
    className: rowIndex % 2 === 1 ? 'odd' : '',
    onClick: event => this.onRowClick(event, record, rowIndex),
  })

  onRowClick = (event, record, rowIndex) => {
    if (!window.getSelection().toString()) {
      const pathHistoryDetail = this.getPathHistoryDetail(record.pk)
      if (!pathHistoryDetail) return message.info('Cannot open the history, please contact your admin!')

      this.props.history.push(
        pathHistoryDetail.replace('{}', this.props.id).replace('{}', record.pk),
        { from: [this.props.history.location.pathname] }
      )
    }
  }

  getPathHistoryDetail = (historyId) => {
    // example props.pathHistoryDetail: /admin/estimation/formula/{}/history/{}
    if (this.props.pathHistoryDetail) return this.props.pathHistoryDetail
    
    const pathSplitted = this.props.history.location.pathname.split('/').filter(val => val)
    if (pathSplitted.length < 3) return null

    let pathHistoryDetail = ''
    for (let i = 0; i < 4; i += 1) if (pathSplitted[i]) pathHistoryDetail += `/${pathSplitted[i]}`
    pathHistoryDetail += `/history/${historyId}`
    return pathHistoryDetail
  }

  onPaginationChange = (page, pageSize) => {
    this.page = page
    this.reload()
  }

  onShowSizeChange = (current, size) => {
    this.page = current
    this.limit = size
    this.reload()
  }

  componentDidMount() {
    this.read()
  }

  render() {
    const { errorResponse, isLoading, isReloading, rawData } = this.state

    if (isLoading) {
      return (
        <LoadingSpin height='200px' tip='Loading...' />
      )
    }

    return rawData ? (
      <div>
        <Row className='mb-2' type='flex' justify='space-between' gutter={[12, 12]}>
          <Col className='text-truncate' style={{ maxWidth: 'calc(100% - 150px)' }}>
            <span style={{ fontSize: '20px', color: 'rgba(0, 0, 0, 0.65)' }}>
              Histories of {this.props.labelTable}: <b>{this.props.title}</b>
            </span>
          </Col>
          <Col>
            <Button icon='reload' loading={isReloading} onClick={() => this.reload()}>
              Refresh
            </Button>
          </Col>
        </Row>
        <Table
          bordered
          className='custom-table-small mb-2'
          columns={this.columns}
          dataSource={rawData.results}
          loading={isReloading}
          pagination={false}
          rowKey='pk'
          size='small'
          onHeaderRow={this.onHeaderRow}
          onRow={this.onRow}
        />
        <Row type='flex' justify='space-between' align='middle' gutter={[12, 12]}>
          <Col>
            {this.getPageInformation()}
          </Col>
          <Col>
            <Pagination
              showSizeChanger
              pageSizeOptions={['25', '50', '75', '100']}
              pageSize={rawData.limit}
              current={rawData.current}
              total={rawData.count}
              onChange={this.onPaginationChange}
              onShowSizeChange={this.onShowSizeChange}
            />
          </Col>
        </Row>
      </div>
    ) : (
      <PageError errorResponse={errorResponse} onReload={this.reload} />
    )
  }
}

HistoryAction.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  history: PropTypes.object.isRequired,
  urlKeyHistory: PropTypes.string.isRequired,
  pathHistoryDetail: PropTypes.string,
}

HistoryAction.defaultProps = {
  pathHistoryDetail: null,
}

export default HistoryAction