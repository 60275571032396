import dashboardApi from 'App/Screens/Dashboard/urlApi'
import estimationApi from 'App/Screens/Estimation/urlApi'
import geoApi from 'App/Screens/Geo/urlApi'
import jobApi from 'App/Screens/Job/urlApi'
import erfApi from 'App/Screens/ERF/urlApi'
import multisiteApi from 'App/Screens/Multisite/urlApi'
import notificationApi from 'App/Screens/Notification/urlApi'
import reportsApi from 'App/Screens/Report/urlApi'
import userApi from 'App/Screens/Users/urlApi'
import singlepageApi from 'App/Component/Singlepage/urlApi'
import urlV2 from 'App/Component/Singlepage/urlApiV2'
import submoduleApi from 'App/Screens/SubModules/urlApi'


export default {
  "user-auth": "oauth/token/",
  "user-revoke-auth": "oauth/revoke_token/",
  "source-files": "api/admin/source/files/",
  "read-me": "api/profile/master/",
  "read-profile": "api/profile/master/info/",
  "profile-password": "api/profile/master/password/",
  "queryparam-cache": "services/live-checker/caching/qp-cache/",
  "create-files": { url: "api/app/source/files/" },
  ...dashboardApi,
  ...estimationApi,
  ...geoApi,
  ...jobApi,
  ...erfApi,
  ...multisiteApi,
  ...notificationApi,
  ...reportsApi,
  ...userApi,
  ...singlepageApi,
  ...urlV2,
  ...submoduleApi,
}
