import React from 'react'
import { Spin } from 'antd'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highcharts'

import { RequestV2 as Request } from 'App/Utils'
import barOptions from './barOptions'
import PageError from 'App/Component/PageError'


class UsedLineTemplate extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
    }
  }

  read = (keyLoading, callback) => {
    this.setState({ isLoading: true }, () => {
      Request(
        'get',
        'module-product-detail-usedLineTemplate',
        {},
        {},
        [this.props.productId],
        this.readSuccess,
        this.readFailed,
        { callback }
      )
    })
  }

  readSuccess = (response) => {
    const data = []
    for (let i = 0; i < response.data.length; i += 1) {
      data.push({
        key: response.data[i].pk,
        name: response.data[i].line_template.label,
        subsection: response.data[i].line_template.subsection.identifier,
        y: Number(response.data[i].score) || 0,
      })
    }

    barOptions.series = [{ name: 'Line Template', colorByPoint: true, data }]

    this.setState({
      isLoading: false,
      errorResponse: null,
    })
  }

  readFailed = (error, extra) => {
    this.setState({ isLoading: false, errorResponse: error.response }, extra.callback)
  }

  componentDidMount() {
    this.read()
  }

  render() {
    if (this.state.errorResponse) {
      return (
        <PageError
          errorResponse={this.state.errorResponse}
          onReload={this.read}
        />
      )
    }

    return (
      <Spin spinning={this.state.isLoading}>
        <HighchartsReact
          ref={ref => {this._chart = ref}}
          highcharts={Highcharts}
          constructorType='chart'
          options={{ ...barOptions }}
        />
      </Spin>
    )
  }
}

export default UsedLineTemplate