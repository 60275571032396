import React from "react";
import {
  ChartCard,
  TimelineChart,
  MiniArea, Radar, WaterWave
} from "ant-design-pro/lib/Charts";
import { Col, Row, Card, DatePicker } from "antd";

import { chartData } from "App/Data/dummy";
import moment from "moment";
import {Lib} from "App/Utils"

const { MonthPicker } = DatePicker;

class Stats extends React.PureComponent {

  componentWillUnmount() {
    Lib.windowStop();
  }

  render() {
    const {branch} = this.props
    let data = branch || {}
    let radarOriginData = [
      {
        name: 'User active',
        bill_access: 10,
        allowed_analysis_report: 8,
        allowed_job_assignment: 4,
        allowed_bill: 5,
        is_staff: 7,
      },
      {
        name: 'User inactive',
        bill_access: 4,
        allowed_analysis_report: 1,
        allowed_job_assignment: 6,
        allowed_bill: 5,
        is_staff: 7,
      }
    ]
    let radarName = data.name

    if(data.label){
      radarName = data.label
    }
    const radarData = [];
    const radarTitleMap = {
      bill_access: 'Bill Access',
      allowed_analysis_report: 'Allowed Analysis Report',
      allowed_job_assignment: 'Allowed Job Assignment',
      allowed_bill: 'Allowed Bill',
      is_staff: 'Is Staff',
    };
    radarOriginData.forEach(item => {
      Object.keys(item).forEach(key => {
        if (key !== 'name') {
          radarData.push({
            name: item.name,
            label: radarTitleMap[key],
            value: item[key],
          });
        }
      });
    });
    const visitData = [];
    const beginDay = new Date().getTime();
    for (let i = 0; i < 20; i += 1) {
      visitData.push({
        x: moment(new Date(beginDay + 1000 * 60 * 60 * 24 * i)).format('YYYY-MM-DD'),
        y: Math.floor(Math.random() * 5),
      });
    }
    return (
      <Row>
        <Col xs={15}>
          <ChartCard title={"Lead time branch "+ radarName} style={{marginRight: "5px"}}>
            <Radar hasLegend height={286} data={radarData} />
          </ChartCard>
        </Col>
        <Col xs={9}>
          <ChartCard title={"Calculation"}>
            <div style={{ textAlign: 'center' }}>
              <WaterWave height={161} title="busy calculation" percent={34} />
            </div>
          </ChartCard>
          <ChartCard style={{marginTop: "5px"}} title={"User is on leave"}>
            <MiniArea line color="#cceafe" height={45} data={visitData} />
          </ChartCard>
        </Col>
        <Col span={24}>
          <Card title="Activity" style={{marginTop: "5px"}} extra={<MonthPicker placeholder="Select month" />}>
            <TimelineChart
              height={200}
              data={chartData}
              titleMap={{ x: "Day", y1: "Active", y2: "Leave" }}
            />
          </Card>
        </Col>
      </Row>
    );
  }
}

export default Stats;
