export default [
  {
    key: 'started_time',
    label: 'Started',
    type: 'datetime',
  }, {
    key: 'finished_time',
    label: 'Finished',
    type: 'datetime',
  }, {
    key: 'items_new',
    label: 'New',
  }, {
    key: 'items_update',
    label: 'Updated',
  }, {
    key: 'items_delete',
    label: 'Deleted',
  }, {
    key: 'items_skip',
    label: 'Skipped',
  }, {
    key: 'items_ignored',
    label: 'Ignored',
  }, {
    key: 'items_error',
    label: 'Error',
  }, {
    key: 'items_invalid',
    label: 'Invalid',
  }, {
    key: 'process_id',
    label: 'PID',
  }, {
    key: 'errors',
    label: 'Errors',
    style: { whiteSpace: 'pre-wrap' },
  },
]