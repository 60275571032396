export default {
  bulkOptions: [
    {
      key: 'delete_bulk',
      field: 'delete_bulk',
      value: 'true',
      label: 'Delete',
      type: 'danger',
      permissionCode: 'estimation.delete_exportmappingline',
    },
  ],
  exportImportOptions: {
    export: {
      directly: true,
      url_name: 'api:admin:estimation:exportmappingline:export',
      filter_by: [
        {
          name: 'limit',
          label: 'Limit',
          type: 'choices',
          choices: [
            ['all', 'All'],
            ['100', '100'],
            ['500', '500'],
          ],
        },
      ],
    },
  },
}