import React from 'react'
import PropTypes from 'prop-types'
import { Prompt } from 'react-router'

class PromptContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isTouched: props.defaultTouched,
    }
  }

  setTouched = (isTouched, callback = () => null) => {
    this.setState({ isTouched }, callback)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.defaultTouched !== nextProps.defaultTouched) {
      this.setState({ isTouched: nextProps.defaultTouched })
    }
  }

  render() {
    const { startsWith } = this.props
    const { isTouched } = this.state

    return (
      <Prompt
        when={isTouched}
        message={(location) => {
          if (location.pathname.startsWith(startsWith)) {
            return true
          }
          return 'You will lose any unsaved changes. Continue?'
        }}
      />
    )
  }
}

PromptContainer.propTypes = {
  defaultTouched: PropTypes.bool,
  startsWith: PropTypes.string,
}

PromptContainer.defaultProps = {
  defaultTouched: false,
  startsWith: null,
}

export default PromptContainer