import React from 'react'
import PropTypes from 'prop-types'
import { Button, Card, Col, Empty, Icon, Input, Row, Spin } from 'antd'
import { get } from 'lodash'
import { Lib, RequestV2 as Request } from 'App/Utils'
import SelectQuery from 'App/Component/SelectQuery'

class Subsection extends React.PureComponent {
  render() {
    return (
      <SubsectionList
        {...this.props}
        flatMode
        waitTime={0}
        urlKey='api:admin:estimation:subsection:list'
        order='section,name'
      />
    )
  }
}

class SubsectionList extends SelectQuery {
  constructor(props) {
    super(props)

    this.search = ''
    this.state = {
      ...this.state,
    }
  }

  onSearchFailed(error, extra) {
    Lib.errorMessageHandler(get(error, 'response'))
    if (this.page > 1) this.page -= 1
    this.setState({ isLoading: false, isLoadingLoadMore: false })
  }

  preRead = () => {
    this.onSearch(this.search)
  }

  componentDidMount() {
    // super.componentDidMount()
    this.preRead()
  }

  render() {
    const { onSelect, selectedSubsections } = this.props
    const { isLoading, data } = this.state

    return (
      <Card
        size='small'
        type='inner'
        bodyStyle={{ padding: '0 0 12px 0' }}
        title='Subsection List'
        extra={<Icon type={isLoading ? 'loading' : 'reload' } onClick={() => this.preRead()} />}
      >
        <div style={{ paddingBottom: '24px' }}>
          <div style={{ padding: '12px' }}>
            <Input.Search placeholder='Search' onSearch={(val) => {this.search = val; this.preRead()}} />
          </div>
          <div style={{ maxHeight: 'calc(100vh - 350px)', overflowY: 'auto', minHeight: '210px' }} onScroll={this.onPopupScroll}>
            {data.map(val => (
              <div key={val.pk} className={`custom-menu-item ${selectedSubsections.obj[val.pk] ? 'inactive' : ''}`} onClick={() => onSelect(val)} title={`${val.section.name} - ${val.name}`}>
                {val.section.name} - {val.name}
              </div>  
            ))}
            <div className="d-flex justify-content-center align-items-center my-2 w-100" style={{ position: 'absolute', bottom: 0 }}>
              <Spin size="small" spinning={this.state.isLoadingLoadMore} />
            </div>
          </div>
        </div>
      </Card>
    )
  }
}

SubsectionList.propTypes = {
  onSelect: PropTypes.func,
  selectedSubsections: PropTypes.object,
}

SubsectionList.defaultProps = {
  ...SelectQuery.defaultProps,
  onSelect: () => null,
  selectedSubsections: {keys: [], obj: {}},
}

export default Subsection