import { BaseList } from 'App/Screens/SubModules/Component';
import descriptor from './descriptorOffline';
import groupByOptions from './groupBy';

class ConsistencyNotesList extends BaseList {
  constructor(props) {
    super(props)

    this.appName = 'note'
    this.modelName = 'consistencynote'
    this.propsList = {
      avatar: 'title',
      pageTitle: 'Consistency Notes',
      descriptor,
      masterKey: 'module-job-consistencyNotes',
      groupByOptions,
    }
  }
}

export default ConsistencyNotesList