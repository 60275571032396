const module = 'product'
const list = [
  {
    label: 'Product Group',
    url: `/${module}/group`,
    icon: 'qrcode',
    code: 'estimation.{}_productgroup',
  },
  {
    label: 'Product',
    url: `/${module}/product`,
    icon: 'credit-card',
    code: 'estimation.{}_product',
  },
]

export default { key: module, list }
