import React from 'react'
import PropTypes from 'prop-types'
import SelectQuery from 'App/Component/SelectQuery'
import getError from './getError'

class ContainerSelectQuery extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      error: null,
    }
  }

  onRef = (ref) => {
    this._ref = ref
  }

  onChange = (val) => {
    this.setError(null)
    this.props.onChange(val)
  }

  setError = (errMessage) => {
    this.setState({ error: getError(errMessage) })
  }

  render() {
    const { error } = this.state

    return (
      <>
        <SelectQuery
          {...this.props}
          ref={this.onRef}
          onChange={this.onChange}
        />
        {!!error && <div style={{ color: '#f5222d' }}>{error.message}</div>}
      </>
    )
  }
}

ContainerSelectQuery.propTypes = {
  onChange: PropTypes.func,
}

ContainerSelectQuery.defaultProps = {
  onChange: () => null,
}

export default ContainerSelectQuery