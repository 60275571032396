export default {
  "app_label": "billing",
  "model_name": "rate",
  "pagination": true,
  "columns": [
    {
      "name": "pk",
      "label": "ID",
      "help_text": null,
      "hidden": true,
      "identifier": true,
      "data_type": "int",
      "search": false,
      "sort": false
    },
    {
      "name": "grade",
      "label": "Grade",
      "help_text": null,
      "hidden": false,
      "child_path": "label",
      "identifier": false,
      "data_type": "str",
      "ellipsis": true,
      "search": true,
      "sort": true
    },
    {
      "name": "start_area_range",
      "label": "Start Area",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "search": true,
      "sort": true
    },
    {
      "name": "end_area_range",
      "label": "End Area",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "search": true,
      "sort": true
    },
    {
      "name": "rate_type_display",
      "label": "Rate Type",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "search": false,
      "sort": true
    },
    
    {
      "name": "rate_1",
      "label": "Rate 1",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "search": true,
      "sort": true
    },
    {
      "name": "rate_2",
      "label": "Rate 2",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "search": true,
      "sort": true
    },
    {
      "name": "rate_3",
      "label": "Rate 3",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "search": true,
      "sort": true
    },
    {
      "name": "highlighted_charge",
      "label": "HI-Light",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "search": false,
      "sort": true
    },
    {
      "name": "modified",
      "label": "Modified",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "datetime",
      "search": false,
      "sort": true
    },
    
  ],
  "filter_by": [
    {
      "label": "Rate Type",
      "name": "rate_type",
      "type": "choice",
      "choices": [
        [
          'fix',
          "Fix"
        ],
        [
          'relative',
          "Relative"
        ]
      ]
    },
    {
      "label": "Grade",
      "name": "grade",
      "type": "api_reference",
      "api": {
        "url_name": "api:admin:jobs:jobgrade:autocomplete",
        "key": "pk",
        "display": "label",
        "pagination": true
      }
    },
  ],
  "export_import": null
}