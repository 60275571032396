import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Card, Tree, Spin, Divider, Typography, List, Icon, message } from 'antd'
import { get } from 'lodash'

const { TreeNode } = Tree
// const { Title } = Typography

class CustomTree extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      checkedKeys: props.initialValue,
      expandedKeys: props.autoExpand ? this.getParentKeys(props.dataSource) : [],
      // previouslyChosen: this.getPreviouslyChosen(props),
    }
  }

  // getPreviouslyChosen = (props) => {
  //   const { dataSource, initialValue, valKey, labelKey } = props
  //   const previouslyChosen = []
  //   for (let i = 0; i < initialValue.length; i += 1) {
  //     search:
  //     for (let j = 0; j < dataSource.length; j += 1) {
  //       for (let k = 0; k < dataSource[j].queues.length; k += 1) {
  //         if (dataSource[j].queues[k][valKey] === initialValue[i]) {
  //           previouslyChosen.push({ label: dataSource[j].queues[k][labelKey], order: (previouslyChosen.length + 1) })
  //           break search
  //         }
  //       }
  //     }
  //   }
  //   return previouslyChosen
  // }

  getParentKeys = (treeData = []) => {
    const { valKey, labelKey } = this.props
    return treeData.map(val => `${val[valKey]}-${val[labelKey]}-parent`)
  }

  // Tree tidak dapat menerima child dengan false!
  renderTreeNodes = (treeData, prevIndex) => {
    const { valKey, labelKey, childKeys, disableCheckbox } = this.props
    const childKey = typeof childKeys === 'object' ? get(childKeys, [prevIndex + 1], childKeys[0]) : childKeys
    const filteredTreeData = treeData.filter(item => !item[childKey] || item[childKey].length > 0)

    return (
      filteredTreeData.map(item => {
        if (item[childKey]) {
          return (
            <TreeNode disableCheckbox={disableCheckbox} key={`${item[valKey]}-${item[labelKey]}-parent`} title={item[labelKey]} selectable={false}>
              {this.renderTreeNodes(item[childKey], (prevIndex + 1))}
            </TreeNode>
          )
        }
        return <TreeNode disableCheckbox={disableCheckbox} key={`${item[valKey]}`} title={item[labelKey]} selectable={false} />
      })
    )
  }

  onCheck = (checkedKeys) => {
    if (!this.props.readOnly) {
      this.props.setTouched(true)
      this.setState({ checkedKeys })
    } else {
      message.info(`Can't make changes, because of Read only access.`, 3)
    }
  }

  validateFields = () => {
    const { childOnly } = this.props
    const { checkedKeys } = this.state
    if (!childOnly) {
      return checkedKeys.map((val) => `${val}`.split('-')[0])
    } else {
      // untuk umum (jika childKeys diisi)
      // return checkedKeys.filter((val) => `${val}`.indexOf('parent') === -1).map((val) => `${val}`.split('-')[0])
      // untuk khusus (jika childKeys default)
      return checkedKeys.filter(val => `${val}`.indexOf('parent') === -1)
    }
  }

  onExpand = (expandedKeys) => {
    this.setState({ expandedKeys })
  }

  onExpandAll = () => {
    this.setState({ expandedKeys: this.getParentKeys(this.props.dataSource) })
  }

  onCollapseAll = () => {
    this.setState({ expandedKeys: [] })
  }

  render() {
    const { dataSource, spinning, title, treeProps } = this.props
    const { checkedKeys, expandedKeys } = this.state
    // Menghitung jumlah selected (children only), seharusnya tidak boleh ada proses seperti ini di render
    const lblTotal = checkedKeys.filter(val => `${val}`.indexOf('parent') === -1).length

    return (
      <Card
        size='small'
        title={`${title} ${lblTotal ? `(${lblTotal})` : ''}`}
        type='inner'
        bodyStyle={{ height: 'calc(100vh - 370px)', minHeight: '300px', overflowY: 'auto' }}
        style={{ borderRadius: '4px', marginBottom: '12px' }}
        extra={
          <Icon
            type={expandedKeys.length > 0 ? 'minus-square' : 'plus-square'}
            onClick={() => expandedKeys.length > 0 ? this.onCollapseAll() : this.onExpandAll() }
          />
        }
      >
        <Spin spinning={spinning}>
          <Row>
            <Tree
              blockNode
              checkable
              onCheck={this.onCheck}
              checkedKeys={checkedKeys}
              onExpand={this.onExpand}
              expandedKeys={expandedKeys}
              {...treeProps}
            >
              {this.renderTreeNodes(dataSource, -1)}
            </Tree>
          </Row>
        </Spin>
      </Card>
    )
  }
}

CustomTree.propTypes = {
  // for card
  title: PropTypes.string,
  // for spin
  spinning: PropTypes.bool,
  // for input (Tree), direct
  treeProps: PropTypes.object,
  disableCheckbox: PropTypes.bool,
  // for another
  initialValue: PropTypes.array,
  dataSource: PropTypes.array,
  valKey: PropTypes.string,
  labelKey: PropTypes.string,
  childKeys: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  childOnly: PropTypes.bool,
  autoExpand: PropTypes.bool,
  setTouched: PropTypes.func,
  readOnly: PropTypes.bool,
}

CustomTree.defaultProps = {
  title: '',
  spinning: false,
  treeProps: {},
  disableCheckbox: false,
  initialValue: [],
  dataSource: [],
  valKey: 'pk',
  labelKey: 'name',
  childKeys: ['queues'],
  childOnly: true,
  autoExpand: false,
  setTouched: () => null,
  readOnly: false,
}

export default CustomTree