export default [
  {
    title: 'ERF Type',
    type: 'SelectQuery',
    required: true,
    dataIndex: 'erf_type',
    data: 'module-job-erfType',
    selectedKey: 'pk',
    selectedValue: 'label',
    inputProps: { disabled: true, onKeyDown: e => {e.preventDefault()} },
  },
  // {
  //   title: 'ERF Field',
  //   type: 'text',
  //   required: true,
  //   dataIndex: 'erf_field',
  // },
  {
    title: 'ERF Field',
    type: 'select',
    required: true,
    disableAllowClear: true,
    dataIndex: 'erf_field',
    data: [], // { alias, value }
  },
  {
    title: 'ERF Value',
    type: 'text',
    dataIndex: 'erf_value',
  },
  {
    title: 'Map Value',
    type: 'text',
    dataIndex: 'map_value',
  },
  {
    title: 'Map Content Type',
    type: 'SelectQuery',
    dataIndex: 'map_content_type',
    data: 'module-job-contentType',
    selectedKey: 'pk',
    selectedValue: 'label',
    referenceBy: 'map_object_id',
  },
  {
    title: 'Map Data',
    type: 'SelectQuery',
    dataIndex: 'map_object_id',
    dataIndexReceive: 'map_object',
    data: 'module-job-contentType-refRecord',
    selectedKey: 'pk',
    selectedValue: 'name',
    note_text: 'Select Map Content Type first',
  },
]