import React, { Component, useRef, cloneElement, useCallback } from "react";
import { Lib, RequestV2 as Request } from "App/Utils";
import {
  Card,
  Row,
  Col,
  Collapse,
  Layout,
  Icon,
  Empty,
  message,
  Button,
  Radio,
  notification
} from "antd";
import Section from "./panel";
import PrefillSets from "./prefillSet";
import Overide from "./overideside";
import Line from "./linetemplates";
import Tabss from "./form/tabs";
import General from "./form/general/general";
import { cloneDeep, get, isEqual } from "lodash";
import { v4 as uuidv4 } from "uuid";
import ListRule from "./listrule"

const { Panel } = Collapse;

class Rules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      section: [],
      prefil: [],
      button: true,
      rules: [],
      dataRules: [],
      visible: false,
      activeKey: "",
      initialRoot: 0,
      rulesID: {},
      tipeUi:"list",
      rulesType:"module-expertSystem-rule",
      rulesHold:null,
      activePane:false,
      checkTab:null,
      outputContent:null,
      // reloadTable:false,
      dataTab: [
        // [
        //   { title: "tab 1", key: 1 },
        //   { title: "tab 2", key: 2 },
        // ],
        // [
        //   { title: "Tab 3", key: "3" }
        // ],
      ],
    };
    this.handleClick = this.handleClick.bind(this);
  }
  getControltab(){
    const active = this.state.activeKey
    const data = this.state.dataTab
    const cariData = data[0].find(item => `${item.key}` === active)
    // console.log("tab active",cariData,active)
    this.setState({checkTab:{...cariData.sub,data:cariData.content}})
  }

  read = () => {
    this.setState({ isLoading: true }, () => {
      Request(
        "get",
        "sections-autocomplete",
        {},
        { limit: 100, status: "active" },
        [],
        this.readSuccess,
        this.readFailed
      );
    });
  };

  readSuccess = (response) => {
    this.setState({ section: response.data.results });
  };

  readFailed = (error) => {
    // console.log("hai", error);
    message.error('there is an error in the system');
  };

  rules = async (id, e) => {
    // return new Promise((resolve, reject) => {
    this.setState({ isLoading: true }, () => {
      Request(
        "get",
        "expert-system-rules",
        {},
        {},
        [id, e.pk],
        this.readrulesSuccess,
        this.readrulesFailed,
        {e,id}
      );
    });
    // });
  };

  notif(){
    notification.open({
      message: 'This content doesnt have rules yet',
      description:
        'To make a rule, please fill in the form on the side',
    });
  }

  readrulesSuccess = (response,extra) => {
    this.setState({ rules: response.data }, () => {
      if (response.data.length === 0) {
        var data
        if(extra.id === 18){
           data =  {outputContentType:{label: "Subsection",pk: 18 ,key:18}, outputObject: {key : extra.e.pk, label:get(extra,"e.label",extra.e.name)}}
        }
           else if(extra.id === 22){
           data=  {outputContentType:{label: "Prefill set",pk: 22,key:22}, outputObject: {key : extra.e.pk, label:get(extra,"e.label",extra.e.name)}}
           }
           else if(extra.id === 24){
            data = {outputContentType:{label: "Override set",pk: 24,key:24},outputObject: {key : extra.e.pk, label:get(extra,"e.label",extra.e.name)}}
           }
            else if(extra.id === 21){
           data = {outputContentType:{label: "Line template",pk: 21,key:21}, outputObject: {key : extra.e.pk, label:get(extra,"e.label",extra.e.name)}}
        }
        this.setState({ visible: true, outputContent: data})
        this.notif()
      } else {
        this.mapping();
      }
    });
  };

  readrulesFailed = (error) => {
    // console.log("rulesss", error);
    message.error('there is an error in the system');
    // extra.resolve();
  };

  getAvatar(item) {
      if (item || typeof item === 'string') {
        const nameSplited = item.split(/[\s_]+/).filter(w => w)
        let initial = ''
        for (const name of nameSplited) {
          // check a character is valid letter
          if (/[A-Za-z0-9]/.test(name.charAt(0))) {
            initial += name.charAt(0).toUpperCase()
            if (initial.length > 5) {
              break
            }
          }
        }
        return initial || '-'
      }
    }

  addTabs(e) {
    if (!this.state.dataTab[0]) {
      this.state.dataTab[0] = [];
    }
    const data = this.state.dataTab[0];

    if (this.state.dataTab[0].length > 0) {
      if (e.length >= 0) {
        for (let i = 0; i < e.length; i++) {
          let ada = data.find((element) => element.key === e[i].id);
          if (ada === undefined) {
            const title = this.getAvatar(e[i].key)
            this.state.dataTab[0].push({
              title: title,
              content: e[i],
              key: e[i].id,
              sub: this.state.rulesID,
            });
            // this.forceUpdate();
            this.setState({ activeKey: `${e[i].id}` },()=>
            this.getControltab());
          } else if(this.state.activePane === true) {
            let tab = this.state.dataTab
            let ada = data.findIndex((element) => element.key === e[i].id  );
            if(ada !== -1){
              const title = this.getAvatar(e[i].key)
              tab[0][ada] = {
                title: title,
                content: e[i],
                key: e[i].id,
                sub:data[ada].sub
              };
              this.setState({dataTab:tab , activePane : false})
                this.addtabchilldrenUpdate(this.state.dataTab,e[0])
              }
            // else{
            // this.setState({ activeKey: `${e[i].id}` });
            // }

            // }
            // else {
            //   console.log("ada === undef")
            //  this.addtabchilldrenUpdate(this.state.dataTab[0],e)
            // }
          }else if(this.state.activePane === false){
            this.setState({ activeKey: `${e[i].id}`},()=>
            this.getControltab());
          }
        }
      } else {
        let ada = data.find((element) => element.key === e.id);
        if (ada === undefined) {
          const title = this.getAvatar(e.key)
          this.state.dataTab[0].push({
            title: title,
            content: e,
            key: e.id,
            sub: this.state.rulesID,
          });
          // this.forceUpdate();
          this.setState({ activeKey: `${e.id}` },()=>
          this.getControltab());
        }
      }
    } else {
      for (let i = 0; i < e.length; i++) {
        const title = this.getAvatar(e[i].key)
        this.state.dataTab[0].push({
          title: title,
          content: e[i],
          key: e[i].id,
          sub: this.state.rulesID,
        });
        this.setState({ activeKey: `${e[i].id}` },()=>
        this.getControltab());
      }
    }
  }

  addtabchilldrenUpdate(tab, e) {
    // console.log("tab",tab, e)
    for (let i = 0; i < tab[0].length; i++) {
      for (let a = 0; a < e.premises.length; a++) {
        
        if(tab[0][i].key === e.premises[a].childObj.id){
          const title = this.getAvatar(e.premises[a].childObj.key)
            tab[0][i] = {
                  title:title,
                  content: e.premises[a].childObj,
                  key: e.premises[a].childObj.id,
                  sub: tab[0][i].sub
                };
                this.setState({dataTab : tab})
          }else  this.addtabchilldrenUpdate(tab,e.premises[a].childObj)
        }
      }
  }

  mapping() {
    // console.log("rules", this.state.rules);
    try {
      const data = this.state.rules;
      // const initialroot = this.state.initialRoot + 1;
      const array = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].is_root) {
          data[i].identifier = uuidv4();
          data[i].id = data[i].pk;
          array.push(data[i]);
        }
      }
      const treeRules = this.test(cloneDeep(array), cloneDeep(data));
      // for (let i = 0; i < array.length; i++) {
      //   this.mapRules(array[i], data);
      // }
      // this.setState({ dataRules: array }, () => {
      //   extra.resolve(array);
      // });
      console.log("array", treeRules);

      this.setState({ dataRules: treeRules }, () =>
        this.addTabs(this.state.dataRules)
      );

    } catch (e) {
      console.error(e);
    }
  }

  insertPremise(premises, premise) {  
    if (premise.parent) {
      for (const item of premises) {
        if (item  && premise.parent === item.id) {
          const foundIndex = premises.indexOf(item);
          if (!get(premises[foundIndex], ["childObj", "premises"])) {
            premises[foundIndex].identifier = uuidv4();
            premises[foundIndex].childObj = {
              premises: [],
              key: `group_${premises[foundIndex].comparer} (${premises[foundIndex].id})`,
            };
          }
          premises[foundIndex].childObj.premises.push(premise);
          return true;
        } else if (get(item, ["childObj", "premises"])) {
          if (this.insertPremise(item.childObj.premises, premise)) {
            return true;
          }
        }
      }
    }
  }

  deleteEmptyPremise(premises) {
    for (let i = premises.length - 1; i >= 0; i -= 1) {
      if (!premises[i]) {
        premises.splice(i, 1);
      }
    }
  }

  mapRule(rule, rules){
    for (const premise of rule.premises) {
      this.count = this.count + 1
      if(this.count >= 1000){
        if(this.count === 1000){
          message.error("an error occurred in the data",5)
        }
      return
      }
      if(premise){
        if (premise.child) {
          premise.identifier = uuidv4();
  
          premise.childObj = { ...rules.find((r2) => r2.pk === premise.child.pk)};
          // console.log("h",premise,premise.child)
          // premise.child = premise.child.pk
          this.mapRule(premise.childObj, rules);
        }
        if (this.insertPremise(rule.premises, premise)) {
          delete rule.premises[rule.premises.indexOf(premise)];
        }
      }
    }

    this.deleteEmptyPremise(rule.premises);
  }

  test(roots, rules) {
    const grouped = [];
    this.count = 0 
    for (const rule of roots) {
      grouped.push(rule);
      this.mapRule(rule, rules);
    }
    for (let i = 0; i < grouped.length; i++) {
      this.inputParentObj(grouped[i]);
    }
    // console.log("data",grouped)
    return grouped;
  }

  inputParentObj(data) {
    for (let i = 0; i < data.premises.length; i++) {
      if (data.premises[i].childObj && data.premises[i].parent === null) {
        data.premises[i].childObj.parentObj = data;
        data.premises[i].childObj.id = data.premises[i].id;
        this.inputParentObj(data.premises[i].childObj);
      } else if (data.key.includes("group")) {
        data.premises[i].childObj.parentObj = data;
        data.premises[i].childObj.id = data.premises[i].id;
        this.inputParentObj(data.premises[i].childObj);
      } else if(data.premises[i].childObj){
        data.premises[i].childObj.parentObj = data.childObj;
        data.premises[i].childObj.id = data.premises[i].id;
        this.inputParentObj(data.premises[i].childObj);
      }
    }
  }

  componentDidMount() {
    this.read();
  }

  async subsection(e) {
    this._prefilset.loadPrefillSets(e);
    this._overide.loadOveride(e);
    this._Line.loadLine(e);
    this.rules(18, e);
    this.setState({ rulesID: { ...e, idsub: 18 },rulesHold : e.pk });
  }

  async PrefillSets(e) {
    this.rules(22, e);
    this.setState({ rulesID: { ...e, idsub: 22 }});
  }

  Overide(e) {
    // console.log("line",e)
    this.rules(24, e);
    this.setState({ rulesID: { ...e, idsub: 24 } });
  }
  Line(e) {
    // console.log("line",e)
    this.rules(21, e);
    this.setState({ rulesID: { ...e, idsub: 21 } });
  }

  handleClick() {
    this.setState({
      button: !this.state.button,
    });
  }
  onCloseTab = (e, tabActive) => {
    const indexTab = this.state.dataTab;
    let data = indexTab[0];
    let index = data.findIndex((element) => `${element.key}` === e);
    let active = data.findIndex((element) => `${element.key}` === tabActive);
    if (data.length === 1) {
      indexTab.splice(0, 1);
      this.setState({ dataTab: indexTab });
    } else {
      if (e !== tabActive) {
        data.splice(index, 1);
        this.setState({ dataTab: indexTab, activeKey: tabActive },()=>
        this.getControltab());
      } else {
        if (e === tabActive && index > 0) {
          data.splice(index, 1);
          this.setState({
            dataTab: indexTab,
            activeKey: `${data[index - 1].key}`},
          ()=>
          this.getControltab());
        } else {
          data.splice(index, 1);
          this.setState({ dataTab: indexTab, activeKey: `${data[index].key}` },()=>
          this.getControltab());
        }
      }
    }
  };

  onChangeTab(e) {
    this.setState({ activeKey: e },()=>
      this.getControltab()
    );
   
  }

  updateData(data, tab) {
    // console.log("halo",data, tab)
    this.setState({activePane : true})
    this.rules(tab.sub.idsub, tab.sub);
  }

  listRule(){
    this._listRule.update()
  }

  onChange(data,index) {
    if (index === "tipeUi"){
      this.setState({tipeUi: data.target.value})
    }else if (index === "rulestipe"){
      this.setState({rulesType: data.target.value})
    }
  }
  render() {
    return (
      <Layout className="layout">
        <General
          onCloseDrawer={() => this.setState({ visible: false })}
          visible={this.state.visible}
          action="add"
          outputContent={this.state.outputContent}
          tipeRule={true}
          updatedata={()=>this.listRule()}
          list={false}
          
        />
        <div style={{ background: "#fff", minHeight: 280 }}>
          <Card
            className="heads modal-rule"
            title="Rules & Premise"
            extra={
              <Button
                style={{
                  fontWeight: 600,
                  fontSize: "14px",
                  fontFamily: "revert",
                }}
                type="primary"
                icon="plus"
                onClick={() => this.setState({ visible: true })}
              >
                Add Rules
              </Button>
            }
          >
            <Radio.Group
              onChange={(e) => this.onChange(e,"tipeUi")}
              defaultValue="list"
              buttonStyle="solid"
              style={{ marginBottom: "10px" }}
            >
              <Radio.Button value="list">Rule List</Radio.Button>
              <Radio.Button value="subsection">Output content</Radio.Button>
            </Radio.Group>
            
            {this.state.tipeUi !== "subsection" ? 
            <Radio.Group
              onChange={(e) => this.onChange(e,"rulestipe")}
              defaultValue={this.state.rulesType}
              buttonStyle="solid"
              style={{float:"right" , paddingRight: "20px"}}
            >
              <Radio.Button value="module-expertSystem-rule">Rule</Radio.Button>
              <Radio.Button value="module-expertSystem-fact">Fact</Radio.Button>
            </Radio.Group>:
            null }

            {this.state.tipeUi === "subsection" ? (
              <Row gutter={[8, 24]} type={"flex"}>
                <Col style={{ minWidth: "20%", maxWidth: "20%" }}>
                  <Card
                    type="inner"
                    title={
                      <>
                        <Icon type="profile" /> Subsection
                      </>
                    }
                    className="heads panelll custom listtt"
                    style={{ color: "forestgreen" }}
                    bodyStyle={{
                      padding: "0px",
                      height: "calc(100vh - 233px)",
                      overflowY: "scroll",
                    }}
                  >
                    <Collapse bordered={false}>
                      {this.state.section.map((item) => (
                        <Panel header={item.name} key={item.pk}>
                          <Section
                            subsection={item.pk}
                            hold = {this.state.rulesHold}
                            onSubsectionClick={(e) => this.subsection(e)}
                            onClick={this.handleClick}
                            classs={this.state.button}
                            checkTab={this.state.checkTab}
                          />
                        </Panel>
                      ))}
                    </Collapse>
                  </Card>
                </Col>
                <Col
                  style={{
                    minWidth: "15%",
                    maxWidth: "16%",
                    height: "calc(100vh - 162px)",
                  }}
                >
                  <Card
                    className="heads cardhead listtt"
                    title={
                      <>
                        <Icon type="book" /> Prefill Set
                      </>
                    }
                    type="inner"
                    style={{ height: "calc(50% - 5px)" }}
                    bodyStyle={{
                      padding: 0,
                      height: "calc(100% - 45px)",
                      overflowY: "scroll",
                    }}
                  >
                    <PrefillSets
                      onClick={this.handleClick}
                      ref={(n) => (this._prefilset = n)}
                      onPrefillSetsClick={(e) => this.PrefillSets(e)}
                      checkTab={this.state.checkTab}
                    />
                  </Card>
                  <Card
                    className="heads cardhead listtt"
                    title={
                      <>
                        <Icon type="book" /> Overide Set
                      </>
                    }
                    type="inner"
                    style={{ marginTop: 10, height: "calc(50% - 5px)" }}
                    bodyStyle={{
                      padding: 0,
                      height: "calc(100% - 45px)",
                      overflowY: "scroll",
                    }}
                  >
                    <Overide
                      ref={(n) => (this._overide = n)}
                      onOverideSetsClick={(e) => this.Overide(e)}
                      onClick={this.handleClick}
                      checkTab={this.state.checkTab}
                    />
                  </Card>
                </Col>
                <Col style={{ minWidth: "15%", maxWidth: "20%" }}>
                  <Card
                    title={
                      <>
                        <Icon type="swap" /> Line Templates
                      </>
                    }
                    type="inner"
                    bodyStyle={{
                      padding: 0,
                      height: "calc(100vh - 233px)",
                      overflowY: "scroll",
                    }}
                    className="heads cardhead listtt"
                  >
                    <Line
                      ref={(n) => (this._Line = n)}
                      onLineClick={(e) => this.Line(e)}
                      onClick={this.handleClick}
                      checkTab={this.state.checkTab}
                    />
                  </Card>
                </Col>

                <Col style={{ flex: "1" }}>
                  {this.state.dataTab.length !== 0 ? (
                    <Row type="flex">
                      {this.state.dataTab.map((tabs) => (
                        <Col
                          key="pk"
                          style={{
                            width: `${100 / this.state.dataTab.length}%`,
                          }}
                          // style={{width:"100%"}}
                        >
                          <Card
                            style={{ border: "none" }}
                            className="heads bodycar cardhead tabcard"
                            bodyStyle={{
                              height: "calc(100vh - 190px)",
                              overflowY: "scroll",
                            }}
                          >
                            <Tabss
                              datatab={(e) => this.addTabs(e)}
                              change={(e) => this.onChangeTab(e)}
                              tabAktif={this.state.activeKey}
                              close={(dataTab, tabActive) =>
                                this.onCloseTab(dataTab, tabActive)
                              }
                              updatedata3={(data, tab) =>
                                this.updateData(data, tab)
                              }
                              tabs={tabs}
                              // ref={(n) => (this._tabs = n)}
                            />
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  ) : (
                    <Row type="flex">
                      <Col style={{ flex: 1 }}>
                        <Card
                          style={{ border: "none" }}
                          className="heads bodycar cardhead"
                          bodyStyle={{
                            height: "calc(100vh - 190px)",
                            overflowY: "scroll",
                          }}
                        >
                          <Empty />
                        </Card>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            ) : (
              <ListRule
              userReducer={this.props.userReducer}
              rulesType={this.state.rulesType} 
              ref={(n) => (this._listRule = n)}
              // permission={ [".view_", ".change_",".delete_"]}
              />
            )}
          </Card>
        </div>
      </Layout>
    );
  }
}
export default Rules;
