/**
 * to override ENV var, create .env.local file on project root
 * var name must begin with REACT_APP_...
 */
 import get from "lodash/get";

const ENV = process.env;

export default {
  app: {
    version: ENV.REACT_APP_APP_VERSION
  },
  url: {
    api:{
      v2:get(ENV,"REACT_APP_URL_API","https://dev-api.ibes.co.nz"),
      v3:get(ENV,"REACT_APP_URL_API_V3","https://dev3-api.ibes.co.nz")
    },
    qs: ENV.REACT_APP_URL_QS,
    ibesv3: ENV.REACT_APP_URL_IBESV3,
  },
  oauth: {
    id: ENV.REACT_APP_OAUTH_ID,
    secret: ENV.REACT_APP_OAUTH_SECRET
  }
};
