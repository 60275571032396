import React from 'react'
import PropTypes from 'prop-types'
import { Button, Col, Radio, Row } from 'antd'
import { get } from 'lodash'
import ReceiverList from './List'
import availableReceiver from './availableReceiver'

class ReceiverField extends React.PureComponent {
  constructor(props) {
    super(props)

    this.availableFields = [
      { key: 'receiver_type', defaultValue: 'all' },
      { key: 'users', defaultValue: [] },
      { key: 'groups', defaultValue: [] },
      { key: 'branches', defaultValue: [] },
    ]
    
    this.state = {
      isError: false,
    }
    // for constructor only
    for (let i = 0; i < this.availableFields.length; i += 1) {
      const field = this.availableFields[i]
      this.state[field.key] = get(props.data, field.key, field.defaultValue)
    }
  }

  onRefReceiverList = (ref) => {
    this._receiverList = ref
  }

  onChangeReceiver = (event) => {
    this.setState({ receiver_type: event.target.value, isError: false }, () => {
      this.props.onChangeSizeReceiver(this.state.receiver_type)
    })
  }

  setTouched = () => {
    this.setState({ isError: false })
  }

  validateFields = () => {
    const list = this._receiverList ? this._receiverList.validateFields() : null
    const fieldKey = get(availableReceiver[this.state.receiver_type], 'fieldKey')
    if (this.state.receiver_type !== 'all' && !get(list, [fieldKey, 'length'])) {
      this.setState({ isError: true })
      return
    }

    return {
      ...(this._receiverList && this._receiverList.validateFields()),
      receiver_type: this.state.receiver_type,
    }
  }

  render() {
    const { data } = this.props
    const { isError, receiver_type } = this.state

    return (
      <div>
        <Row type='flex' align='middle' style={{ width: '100%' }}>
          <Col style={{ marginRight: '8px' }}>
            <span style={{ color: '#f5222d', display: 'inline-block', marginRight: '4px', fontFamily: 'SimSun, sans-serif' }}>*</span>
            Send to :
          </Col>
          <Col>
            <Radio.Group buttonStyle='solid' value={receiver_type} onChange={this.onChangeReceiver}>
              {Object.keys(availableReceiver).map(key => (
                <Radio.Button key={key} value={key}>{availableReceiver[key].label}</Radio.Button>
              ))}
            </Radio.Group>
          </Col>
          {isError && (
            <Col>
              <span className='ml-2' style={{ color: '#f5222d', display: 'inline-block' }}>
                Please select at least 1 receiver
              </span>
            </Col>
          )}
        </Row>
        {get(availableReceiver[receiver_type], 'urlKey') && (
          <Row className='mt-2' type='flex' style={{ width: '100%' }}>
            <ReceiverList
              ref={this.onRefReceiverList}
              {...availableReceiver[receiver_type]}
              parentData={data}
              setTouched={this.setTouched}
            />
          </Row>
        )}
      </div>
    )
  }
}

ReceiverField.propTypes = {
  
}

ReceiverField.defaultProps = {
  
}

export default ReceiverField