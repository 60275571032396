import React from 'react'
import PropTypes from 'prop-types'
import { CirclePicker, SliderPicker, SketchPicker } from 'react-color'
import { Row, Col } from 'antd'

const defaultColors = [
  '#001529', // header dark (ant deesign)
  '#1890ff', // blue (ant design)
  '#9c88ff', // periwinkle (british) like purple
  '#fbc531', // rise-n-shine (british) like yellow
  '#4cd137', // download progress (british) like green light
  '#16a085', // green sea (palette 1) like green
  '#34495e', // wet asphalt (palette 1) like dark
  '#f5222d', // red (ant design)
  '#2980b9', // belize hole (palette 1) like blue dark
  '#f0932b', // quince jelly (aussie) like orange
  '#ff7979', // pink glamour (aussie) like red light --> or use '#c0392b' pomegranate (palette 1) like red dark
]

class Circle extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      color: props.color,
    }
  }

  render() {
    const { onChange, colors } = this.props
    const { color } = this.state

    return (
      <CirclePicker
        color={color}
        colors={colors}
        onChangeComplete={(color, event) => {
          this.setState({ color: color.hex })
          onChange(color, event)
        }}
      />
    )
  }
}

Circle.propTypes = {
  color: PropTypes.string,
  colors: PropTypes.array,
  onChange: PropTypes.func,
}

Circle.defaultProps = {
  color: '',
  colors: defaultColors,
  onChange: () => null,
}

class Slider extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      color: props.color,
    }
  }

  render() {
    const { onChange } = this.props
    const { color } = this.state

    return (
      <SliderPicker
        styles={{ default: {hue: {width: '410px'}} }}
        color={color}
        onChangeComplete={(color, event) => {
          this.setState({ color: color.hex })
          onChange(color, event)
        }}
      />
    )
  }
}

class CirclenSlider extends React.PureComponent {
  render() {
    return (
      <Col>
        <Row>
          <Circle {...this.props} />
        </Row>
        <Row style={{ marginTop: '12px' }}>
          <Slider {...this.props} />
        </Row>
      </Col>
    )
  }
}

class Sketch extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      color: props.color,
    }
  }

  render() {
    const { colors, onChange } = this.props
    const { color } = this.state

    return (
      <SketchPicker
        disableAlpha
        color={color}
        presetColors={colors}
        onChangeComplete={(color, event) => {
          this.setState({ color: color.hex })
          onChange(color, event)
        }}
      />
    )
  }
}

Sketch.propTypes = {
  color: PropTypes.string,
  colors: PropTypes.array,
  onChange: PropTypes.func,
}

Sketch.defaultProps = {
  color: '#555555',
  colors: defaultColors,
  onChange: () => null,
}

export default { Circle, Slider, CirclenSlider, Sketch }