import React from 'react'
import { Layout, Row, Col, Button, Spin, Icon, message, Typography, Tabs } from 'antd'
import { get } from 'lodash'
import moment from 'moment'
import { RequestV2 as Request, Modules } from 'App/Utils'
import PageNotFound from 'App/Component/PageNotFound'
import PageError from 'App/Component/PageError'
import { PageProduct, PageSetting } from './Pages'

const { TabPane } = Tabs

class PageDetail extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      isReloading: false,
      errorResponse: null,
      productGroup: null,
      readOnly: !get(props, 'currentPermissionSet', []).some(code => (code || '').includes('.change_'))
    }

    this.headerDescriptions = [
      { key: 'name', label: 'Name' },
      { key: 'code', label: 'Code' },
      { key: 'template_set.name', label: 'Template Set' },
      { key: 'created', label: 'Created at', type: 'date' },
      { key: 'modified', label: 'Modified at', type: 'date' },
    ]
  }

  retrieve = (keyLoading = 'isLoading', callback = () => null) => {
    this.setState({ [keyLoading]: true }, () => {
      Request(
        'get',
        'module-product-productGroup-detail',
        {},
        {},
        [this.props.match.params.id],
        this.retrieveSuccess,
        this.retrieveFailed,
        { keyLoading, callback }
      )
    })
  }

  retrieveSuccess = (response, extra) => {
    this.setState({ [extra.keyLoading]: false, productGroup: response.data })
    // karena extra.callback ada di component yang akan unmount, maka jangan panggil extra.callback
  }

  retrieveFailed = (error, extra) => {
    this.setState({ [extra.keyLoading]: false, errorResponse: get(error, 'response') }, () => {
      extra.callback()
    })
  }

  getDescElems = () => {
    const { productGroup } = this.state
    return (
      <Row className='ant-desc-custom' type='flex' gutter={[12, 12]} style={{ marginTop: '12px' }}>
        {
          this.headerDescriptions.map(item => (
            <Col key={item.key} xs={24} sm={12} lg={8}>
              <Row className='header-content-item'>
                <span className='ant-descriptions-item-label ant-descriptions-item-colon'>
                  {item.label}
                </span>
                <span className='ant-descriptions-item-content'>
                  {this.getValue(productGroup, item.key, item.type)}
                </span>
              </Row>
            </Col>
          ))
        }
      </Row>
    )
  }

  getValue = (record, key, type) => {
    if (type === 'long_date') {
      return (
        <>
          {moment(record[key]).format('ddd, DD MMM YYYY hh:mm')}
          <Typography.Text type='secondary'> ({moment(record[key]).fromNow()})</Typography.Text>
        </>
      )
    } else if (type === 'date') {
      return moment(record[key]).format('ddd, DD MMM YYYY hh:mm')
    } else if (type === 'bool') {
      return Modules.renderStatusAIU(get(record, key))
    } else {
      return get(record, key, '-')
    }
  }

  onGoHome = () => {
    this.props.history.push('/product/group')
  }

  setProductGroup = (productGroup) => {
    this.setState({ productGroup })
  }

  componentDidMount() {
    this.retrieve()
  }

  render() {
    const { userReducer, history, location, match } = this.props
    const { isLoading, isReloading, errorResponse, productGroup, readOnly } = this.state

    if (isLoading) {
      return (
        <div style={{ paddingTop: 100, textAlign: "center" }}>
          <Spin
            size="large"
            tip="Loading..."
            indicator={<Icon type="loading" style={{ fontSize: "4rem" }} spin />}
          />
        </div>
      )
    }

    return productGroup ? (
      <Layout className='um-layout'>
        <div className='ant-page-header has-footer'>
          <Row type='flex' justify='space-between' gutter={[12, 12]} style={{ borderBottom: '1px solid #e8e8e8' }}>
            <Col>
              <Row type='flex' align='middle'>
                <Icon className='ant-page-header-back-button' type='arrow-left' style={{ marginRight: '16px' }} onClick={this.onGoHome} />
                <Col className='header-title text-ellipsis'>
                  Product Group
                </Col>
                {readOnly && (
                  <Col style={{ fontSize: '12px', fontWeight: 500, backgroundColor: '#1890ff', color: '#fff', padding: '0 12px 2px 12px', borderRadius: '14px', marginLeft: '12px' }}>
                    Read Only
                  </Col>
                )}
              </Row>
            </Col>
            <Col>
              <Row type='flex' gutter={[12, 12]}>
              </Row>
            </Col>
          </Row>
          <div>
            {this.getDescElems()}
          </div>
          <div className='ant-page-header-footer'>
            <div style={{ height: '1px', backgroundColor: '#e8e8e8' }} />
          </div>
        </div>
        <div style={{ backgroundColor: '#fff', marginBottom: '24px' }}>
          <Tabs className='custom-margin-tab' tabBarGutter={0}>
            <TabPane key='product' tab={<span><Icon type='credit-card' />Product</span>}>
              <Row style={{ paddingBottom: '24px'}}>
                <PageProduct userReducer={userReducer} history={history} location={location} match={match} productGroup={productGroup} />
              </Row>
            </TabPane>
            <TabPane key='setting' tab={<span><Icon type='setting' />Setting</span>}>
              <Row style={{ padding: '0 24px 24px 24px'}}>
                <PageSetting readOnly={readOnly} userReducer={userReducer} productGroup={productGroup} setProductGroup={this.setProductGroup} />
              </Row>
            </TabPane>
          </Tabs>
        </div>
      </Layout>
    ) : (
      <PageError
        errorResponse={errorResponse}
        onGoBack={this.onGoHome}
        onReload={this.retrieve}
      />
    )
  }
}

export default PageDetail