export default {
  "module-branch-read": { url: "api/branch/multisite/branches/", version: 3 },
  "module-branch-create": { url: "api/branch/multisite/branches/", version: 3 },
  "module-branch-autocomplete": "api/branch/multisite/branches/autocomplete/",
  "module-branch-delete": { url: "api/branch/multisite/branches/{}/", version: 3 },
  "module-branch-update": { url: "api/branch/multisite/branches/{}/", version: 3 },
  "module-branch-detail": { url: "api/branch/multisite/branches/{}/", version: 3 },
  "module-branch-service": "services/descriptor/describe/?name=api:admin:multisite:branch:list",
  "module-branch-refUsers-read": "api/branch/multisite/branches/{}/users/",
  "module-branch-refUsers-delete": "api/branch/multisite/branches/{}/users/{}/",
  "module-branch-refUsers-service": "services/descriptor/describe/?name=api:admin:users:user:list",
  "module-branch-refJobQueues-read": "api/branch/multisite/branches/{}/job_queues/",
  "module-branch-refMerchants-read": "api/branch/multisite/branches/{}/merchants/",
  "module-branch-refMerchants-delete": "api/branch/multisite/branches/{}/merchants/{}/",
  "module-branch-history": "api/branch/multisite/branches/{}/revision/",
  "module-branch-history-detail": "api/branch/multisite/branches/{}/revision/{}/",

  "module-branch-holiday-read": "api/branch/multisite/holidays/",
  "module-branch-holiday-create": "api/branch/multisite/holidays/",
  "module-branch-holiday-detail": "api/branch/multisite/holidays/{}/",
  "module-branch-holiday-delete": "api/branch/multisite/holidays/{}/",
  "module-branch-holiday-calendar": "api/branch/multisite/holidays/holiday-calendar/",
  "module-branch-holiday-history": "api/branch/multisite/holidays/{}/revision/",
  "module-branch-holiday-history-detail": "api/branch/multisite/holidays/{}/revision/{}/",

  "module-product-productGroup-read": "api/product/estimation/product-groups/",
  "module-product-productGroup-create": "api/product/estimation/product-groups/",
  "module-product-productGroup-delete": "api/product/estimation/product-groups/{}/",
  "module-product-productGroup-update": "api/product/estimation/product-groups/{}/",
  "module-product-productGroup-detail": "api/product/estimation/product-groups/{}/",
  "module-product-productGroup-refProduct-read": "api/product/estimation/product-groups/{}/products/",
  "module-product-productGroup-history": "api/product/estimation/product-groups/{}/revision/",
  "module-product-productGroup-history-detail": "api/product/estimation/product-groups/{}/revision/{}/",

  "module-product-read": "api/product/estimation/products/",
  "module-product-create": "api/product/estimation/products/",
  "module-product-delete": "api/product/estimation/products/{}/",
  "module-product-update": "api/product/estimation/products/{}/",
  "module-product-detail": "api/product/estimation/products/{}/",
  "module-product-summaries": "api/product/estimation/products/summaries/",
  "module-product-service": "services/descriptor/describe/?name=api:admin:estimation:product:list",
  "module-product-mostUsed": "api/product/estimation/products/most-used/",
  "module-product-detail-usedStat": "api/product/estimation/products/{}/used-stats/",
  "module-product-detail-usedLineTemplate": "api/product/estimation/products/{}/used-line-template/",
  "module-product-history": "api/product/estimation/products/{}/revision/",
  "module-product-history-detail": "api/product/estimation/products/{}/revision/{}/",

  "module-product-images-read": "api/product/estimation/products/{}/images/",
  "module-product-images-create": "api/product/estimation/products/{}/images/",
  "module-product-images-delete": "api/product/estimation/products/{}/images/{}/",
  "module-product-images-update": "api/product/estimation/products/{}/images/{}/",
  "module-product-images-detail": "api/product/estimation/products/{}/images/{}/",
  "module-product-images-asThumbnail": "api/product/estimation/products/{}/images/{}/as_thumbnail/",

  "module-product-videos-read": "api/product/estimation/products/{}/videos/",
  "module-product-videos-create": "api/product/estimation/products/{}/videos/",
  "module-product-videos-delete": "api/product/estimation/products/{}/videos/{}/",
  "module-product-videos-update": "api/product/estimation/products/{}/videos/{}/",
  "module-product-videos-detail": "api/product/estimation/products/{}/videos/{}/",

  "module-product-documents-read": "api/product/estimation/products/{}/documents/",
  "module-product-documents-create": "api/product/estimation/products/{}/documents/",
  "module-product-documents-delete": "api/product/estimation/products/{}/documents/{}/",
  "module-product-documents-update": "api/product/estimation/products/{}/documents/{}/",
  "module-product-documents-detail": "api/product/estimation/products/{}/documents/{}/",

  "module-product-notes-read": "api/product/estimation/products/{}/notes/",
  "module-product-notes-create": "api/product/estimation/products/{}/notes/",
  "module-product-notes-delete": "api/product/estimation/products/{}/notes/{}/",
  "module-product-notes-update": "api/product/estimation/products/{}/notes/{}/",
  "module-product-notes-detail": "api/product/estimation/products/{}/notes/{}/",

  "module-jobQueue-read": "api/branch/multisite/job-queues/",
  "module-jobQueue-create": "api/branch/multisite/job-queues/",
  "module-jobQueue-delete": "api/branch/multisite/job-queues/{}/",
  "module-jobQueue-update": "api/branch/multisite/job-queues/{}/",
  "module-jobQueue-detail": "api/branch/multisite/job-queues/{}/",
  "module-jobQueue-autocomplete": "api/branch/multisite/job-queues/autocomplete/",
  "module-jobQueue-service": "services/descriptor/describe/?name=api:admin:multisite:jobqueue:list",
  "chart-job-queues": "api/branch/multisite/job-queues/branch-queue-summaries/",
  "module-jobQueue-history": "api/branch/multisite/job-queues/{}/revision/",
  "module-jobQueue-history-detail": "api/branch/multisite/job-queues/{}/revision/{}/",

  "module-job-statusSummaries": "api/job/jobs/jobs/status-summaries/",
  "module-job-newJobSummaries": "api/job/jobs/jobs/new-job-summaries/",
  "module-job-gradeSummaries": "api/job/jobs/jobs/grade-summaries/",

  "module-job-consistencyNotes-read": { url: "api/job/note/consistency-notes/", version: 3 },
  "module-job-consistencyNotes-create": { url: "api/job/note/consistency-notes/", version: 3 },
  "module-job-consistencyNotes-update": { url: "api/job/note/consistency-notes/{}/", version: 3 },
  "module-job-consistencyNotes-detail": { url: "api/job/note/consistency-notes/{}/", version: 3 },
  "module-job-consistencyNotes-delete": { url: "api/job/note/consistency-notes/{}/", version: 3 },

  "module-job-erfType-read": "api/job/erf/erf-types/",
  "module-job-erfType-detail": "api/job/erf/erf-types/{}/",
  "module-job-erfType-history": "api/job/erf/erf-types/{}/revision/",
  "module-job-erfType-history-detail": "api/job/erf/erf-types/{}/revision/{}/",
  "module-job-erfType-autocomplete": "api/job/erf/erf-types/autocomplete/",

  "module-job-contentType-autocomplete": "api/job/erf/content-types/autocomplete/",
  "module-job-contentType-refRecord-autocomplete": "api/job/erf/content-types/{}/records/",

  "module-job-erfMapping-read": "api/job/erf/erf-mappings/",
  "module-job-erfMapping-detail": "api/job/erf/erf-mappings/{}/",
  "module-job-erfMapping-delete": "api/job/erf/erf-mappings/{}/",
  "module-job-erfMapping-history": "api/job/erf/erf-mappings/{}/revision/",
  "module-job-erfMapping-history-detail": "api/job/erf/erf-mappings/{}/revision/{}/",

  "module-job-buildingType-read": "api/job/jobs/building-types/",
  "module-job-buildingType-detail": "api/job/jobs/building-types/{}/",
  "module-job-buildingType-delete": "api/job/jobs/building-types/{}/",
  "module-job-buildingType-history": "api/job/jobs/building-types/{}/revision/",
  "module-job-buildingType-history-detail": "api/job/jobs/building-types/{}/revision/{}/",

  "module-job-rateType-read": "api/job/jobs/rate-types/",
  "module-job-rateType-detail": "api/job/jobs/rate-types/{}/",

  "module-job-jobGrade-read": "api/job/jobs/job-grades/",
  "module-job-jobGrade-detail": "api/job/jobs/job-grades/{}/",
  "module-job-jobGrade-history": "api/job/jobs/job-grades/{}/revision/",
  "module-job-jobGrade-history-detail": "api/job/jobs/job-grades/{}/revision/{}/",

  "module-job-jobType-read": "api/job/jobs/job-types/",
  "module-job-jobType-detail": "api/job/jobs/job-types/{}/",
  "module-job-jobType-delete": "api/job/jobs/job-types/{}/",
  "module-job-jobType-history": "api/job/jobs/job-types/{}/revision/",
  "module-job-jobType-history-detail": "api/job/jobs/job-types/{}/revision/{}/",

  "module-job-range-read": "api/job/jobs/ranges/",
  "module-job-range-detail": "api/job/jobs/ranges/{}/",
  "module-job-range-history": "api/job/jobs/ranges/{}/revision/",
  "module-job-range-history-detail": "api/job/jobs/ranges/{}/revision/{}/",

  "module-job-transition-read": "api/job/jobs/status-transitions/",
  "module-job-transition-detail": "api/job/jobs/status-transitions/{}/",

  "job-due-soon": "api/job/jobs/jobs/job-due-soon/",
  "calendar-jobs": "api/job/jobs/jobs/job-calendar/",

  "module-helper-group": "api/display/account/group/",
  "module-helper-user": "api/display/account/user/",

  "module-groupUser-group-read": "api/group-user/account/groups/",
  "module-groupUser-group-create": "api/group-user/account/groups/",
  "module-groupUser-group-autocomplete": "api/group-user/account/groups/autocomplete/",
  "module-groupUser-group-delete": "api/group-user/account/groups/{}/",
  "module-groupUser-group-update": "api/group-user/account/groups/{}/",
  "module-groupUser-group-detail": "api/group-user/account/groups/{}/",
  "module-groupUser-group-service": "services/descriptor/describe/?name=api:admin:users:group:list",
  "module-groupUser-group-manageUser": "api/group-user/account/groups/{}/manage-user/",
  "module-groupUser-group-users": "api/group-user/account/groups/{}/users/",
  "module-groupUser-group-history": "api/group-user/account/groups/{}/revision/",
  "module-groupUser-group-history-detail": "api/group-user/account/groups/{}/revision/{}/",

  // Start IBES User, key tidak di ganti, karena nanti butuh trace ke pemanggil
  "module-groupUser-user-read": { url: "api/group-user/account/ibes-users/", version: 3 },
  "module-groupUser-user-create": { url: "api/group-user/account/ibes-users/", version: 3 },
  "module-groupUser-user-autocomplete": "api/group-user/account/ibes-users/autocomplete/",
  "module-groupUser-user-delete": { url: "api/group-user/account/ibes-users/{}/", version: 3 },
  "module-groupUser-user-update": { url: "api/group-user/account/ibes-users/{}/", version: 3 },
  "module-groupUser-user-detail": { url: "api/group-user/account/ibes-users/{}/", version: 3 },
  "module-groupUser-user-password": "api/group-user/account/ibes-users/{}/password/",
  "module-groupUser-user-export": "api/group-user/account/ibes-users/export/",
  "module-groupUser-user-service": "services/descriptor/describe/?name=api:admin:users:user:list",
  // "me": "api/group-user/account/me/", // changed to "read-me"
  // "profile": "api/group-user/account/me/profile/",
  "profilePassword": "api/group-user/account/me/password/",
  // "forgotPassword": "api/group-user/account/forget-password/", // old
  // "resetPassword": "api/group-user/account/forget-password/{}/", // old
  "forgotPassword": "api/unauth/helper/forget-password/",
  "resetPassword": "api/unauth/helper/forget-password/{}/",
  "module-groupUser-user-history": "api/group-user/account/ibes-users/{}/revision/",
  "module-groupUser-user-history-detail": "api/group-user/account/ibes-users/{}/revision/{}/",
  "module-groupUser-user-activeJobStats": "api/group-user/account/ibes-users/{}/active-job-stats/",
  "module-groupUser-user-completedJobStats": "api/group-user/account/ibes-users/{}/completed-job-stats/",
  "module-groupUser-user-workingSpeedStats": "api/group-user/account/ibes-users/{}/working-speed-stats/",
  // End IBES User

  "module-groupUser-userAll-read": { url: "api/group-user/account/users/", version: 3 },

  "module-groupUser-userTracker-read": { url: "api/group-user/account/tracker-users/", version: 3 },
  // "module-groupUser-userTracker-create": "api/group-user/account/tracker-users/",
  "module-groupUser-userTracker-detail": { url: "api/group-user/account/tracker-users/{}/", version: 3 },
  "module-groupUser-userTracker-password": "api/group-user/account/tracker-users/{}/password/",

  "module-merchant-account-create": "api/merchant/merchant/accounts/",
  "module-merchant-account-read": "api/merchant/merchant/accounts/",
  "module-merchant-account-detail": "api/merchant/merchant/accounts/{}/",
  "module-merchant-account-delete": "api/merchant/merchant/accounts/{}/",
  "module-merchant-account-refMerchant-read": "api/merchant/merchant/merchants/", // manual filter
  "module-merchant-account-history": "api/merchant/merchant/accounts/{}/revision/",
  "module-merchant-account-history-detail": "api/merchant/merchant/accounts/{}/revision/{}/",

  "module-merchant-mappingMode-read": "api/merchant/merchant/mapping-modes/",
  "module-merchant-mappingMode-create": "api/merchant/merchant/mapping-modes/",
  "module-merchant-mappingMode-delete": "api/merchant/merchant/mapping-modes/{}/",
  "module-merchant-mappingMode-update": "api/merchant/merchant/mapping-modes/{}/",
  "module-merchant-mappingMode-detail": "api/merchant/merchant/mapping-modes/{}/",
  "module-merchant-mappingMode-autocomplete": "api/merchant/merchant/mapping-modes/autocomplete/",
  "module-merchant-mappingMode-refGroup-read": "api/merchant/merchant/mapping-modes/{}/merchant_groups/",
  "module-merchant-mappingMode-refGroup-delete": "api/merchant/merchant/mapping-modes/{}/merchant_groups/{}/",
  "module-merchant-mappingMode-refMerchant-read": "api/merchant/merchant/mapping-modes/{}/merchants/",
  "module-merchant-mappingMode-refMerchant-delete": "api/merchant/merchant/mapping-modes/{}/merchants/{}/",
  "module-merchant-mappingMode-history": "api/merchant/merchant/mapping-modes/{}/revision/",
  "module-merchant-mappingMode-history-detail": "api/merchant/merchant/mapping-modes/{}/revision/{}/",

  "module-merchant-group-read": "api/merchant/merchant/merchant-groups/",
  "module-merchant-group-create": "api/merchant/merchant/merchant-groups/",
  "module-merchant-group-delete": "api/merchant/merchant/merchant-groups/{}/",
  "module-merchant-group-update": "api/merchant/merchant/merchant-groups/{}/",
  "module-merchant-group-detail": "api/merchant/merchant/merchant-groups/{}/",
  "module-merchant-group-autocomplete": "api/merchant/merchant/merchant-groups/autocomplete/",
  "module-merchant-group-changeMode": "api/merchant/merchant/merchant-groups/{}/mode/",
  "module-merchant-group-refMerchant-read": "api/merchant/merchant/merchant-groups/{}/merchants/",
  "module-merchant-group-refMerchant-delete": "api/merchant/merchant/merchant-groups/{}/merchants/{}/",
  "module-merchant-group-refSkuMapping-read": "api/merchant/merchant/merchant-groups/{}/sku_mappings/",
  "module-merchant-group-refSkuMapping-create": "api/merchant/merchant/merchant-groups/{}/sku_mappings/",
  "module-merchant-group-refSkuMapping-delete": "api/merchant/merchant/merchant-groups/{}/sku_mappings/{}/",
  "module-merchant-group-refSkuMapping-update": "api/merchant/merchant/merchant-groups/{}/sku_mappings/{}/",
  "module-merchant-group-refSkuMapping-detail": "api/merchant/merchant/merchant-groups/{}/sku_mappings/{}/",
  "module-merchant-group-refSkuMapping-import": "api/merchant/merchant/merchant-groups/{}/sku_mappings/import/",
  "module-merchant-group-refSkuMapping-export": "api/merchant/merchant/merchant-groups/{}/sku_mappings/export/",
  "module-merchant-group-history": "api/merchant/merchant/merchant-groups/{}/revision/",
  "module-merchant-group-history-detail": "api/merchant/merchant/merchant-groups/{}/revision/{}/",

  "module-merchant-pricing-read": "api/merchant/merchant/merchant-pricings/",
  "module-merchant-pricing-detail": "api/merchant/merchant/merchant-pricings/{}/",

  "module-merchant-extraCharge-read": "api/merchant/merchant/extra-charges/",
  "module-merchant-extraCharge-detail": "api/merchant/merchant/extra-charges/{}/",
  "module-merchant-extraCharge-delete": "api/merchant/merchant/extra-charges/{}/",
  "module-merchant-extraCharge-refExtraChargeMapping-read": "api/merchant/merchant/extra-charge-mappings/",
  "module-merchant-extraCharge-history": "api/merchant/merchant/extra-charges/{}/revision/",
  "module-merchant-extraCharge-history-detail": "api/merchant/merchant/extra-charges/{}/revision/{}/",

  "module-merchant-extraChargeSize-read": "api/merchant/merchant/extra-charge-sizes/",
  "module-merchant-extraChargeSize-detail": "api/merchant/merchant/extra-charge-sizes/{}/",
  "module-merchant-extraChargeSize-delete": "api/merchant/merchant/extra-charge-sizes/{}/",
  "module-merchant-extraChargeSize-refExtraChargeMapping-read": "api/merchant/merchant/extra-charge-mappings/",
  "module-merchant-extraChargeSize-history": "api/merchant/merchant/extra-charge-sizes/{}/revision/",
  "module-merchant-extraChargeSize-history-detail": "api/merchant/merchant/extra-charge-sizes/{}/revision/{}/",

  "module-merchant-merchant-read": { url: "api/merchant/merchant/merchants/", version: 3 },
  "module-merchant-merchant-create": { url: "api/merchant/merchant/merchants/", version: 3 },
  "module-merchant-merchant-delete": { url: "api/merchant/merchant/merchants/{}/", version: 3},
  "module-merchant-merchant-update": { url: "api/merchant/merchant/merchants/{}/", version: 3},
  "module-merchant-merchant-detail": { url: "api/merchant/merchant/merchants/{}/", version: 3},
  "module-merchant-merchant-autocomplete": { url: "api/merchant/merchant/merchants/autocomplete/", version: 3 },
  "module-merchant-merchant-changeMode": "api/merchant/merchant/merchants/{}/mode/",
  "module-merchant-merchant-changeGroup": "api/merchant/merchant/merchants/{}/group/",
  "module-merchant-merchant-refExportMapping-read": "api/merchant/merchant/merchants/{}/export_mappings/",
  "module-merchant-merchant-history": "api/merchant/merchant/merchants/{}/revision/",
  "module-merchant-merchant-history-detail": "api/merchant/merchant/merchants/{}/revision/{}/",
  // "module-merchant-merchant-refExportMapping-delete": "api/merchant/merchant/merchants/{}/export_mappings/{}/",
  "module-merchant-merchant-refPmHardware-read": "api/merchant/merchant/merchants/{}/pm_hardware_starting_ranges/",
  "module-merchant-merchant-refPmTimber-read": "api/merchant/merchant/merchants/{}/pm_timber_starting_ranges/",
  "module-merchant-merchant-refSkuMapping-read": "api/merchant/merchant/merchants/{}/sku_mappings/",
  "module-merchant-merchant-refSkuMapping-create": "api/merchant/merchant/merchants/{}/sku_mappings/",
  "module-merchant-merchant-refSkuMapping-delete": "api/merchant/merchant/merchants/{}/sku_mappings/{}/",
  "module-merchant-merchant-refSkuMapping-update": "api/merchant/merchant/merchants/{}/sku_mappings/{}/",
  "module-merchant-merchant-refSkuMapping-detail": "api/merchant/merchant/merchants/{}/sku_mappings/{}/",
  "module-merchant-merchant-refSkuMapping-import": "api/merchant/merchant/merchants/{}/sku_mappings/import/",
  "module-merchant-merchant-refSkuMapping-export": "api/merchant/merchant/merchants/{}/sku_mappings/export/",

  "module-merchant-skuMapping-read": "api/merchant/merchant/sku-mappings/",
  "module-merchant-skuMapping-create": "api/merchant/merchant/sku-mappings/",
  "module-merchant-skuMapping-delete": "api/merchant/merchant/sku-mappings/{}/",
  "module-merchant-skuMapping-update": "api/merchant/merchant/sku-mappings/{}/",
  "module-merchant-skuMapping-detail": "api/merchant/merchant/sku-mappings/{}/",
  "module-merchant-skuMapping-history": "api/merchant/merchant/sku-mappings/{}/revision/",
  "module-merchant-skuMapping-history-detail": "api/merchant/merchant/sku-mappings/{}/revision/{}/",

  "module-merchant-tag-read": "api/merchant/merchant/tags/",
  "module-merchant-tag-create": "api/merchant/merchant/tags/",
  "module-merchant-tag-autocomplete": "api/merchant/merchant/tags/autocomplete/",
  "module-merchant-tag-delete": "api/merchant/merchant/tags/{}/",
  "module-merchant-tag-update": "api/merchant/merchant/tags/{}/",
  "module-merchant-tag-detail": "api/merchant/merchant/tags/{}/",
  "module-merchant-tag-history": "api/merchant/merchant/tags/{}/revision/",
  "module-merchant-tag-history-detail": "api/merchant/merchant/tags/{}/revision/{}/",

  "module-merchant-tagMerchant-read": "api/merchant/merchant/tag-merchants/",
  "module-merchant-tagMerchant-create": "api/merchant/merchant/tag-merchants/",
  "module-merchant-tagMerchant-delete": "api/merchant/merchant/tag-merchants/{}/",
  "module-merchant-tagMerchant-update": "api/merchant/merchant/tag-merchants/{}/",
  "module-merchant-tagMerchant-detail": "api/merchant/merchant/tag-merchants/{}/",

  "module-expertSystem-rule-read": {url :"api/expert-system/expert-system/rules/", version:3},
  "module-expertSystem-rule-delete": {url :"api/expert-system/expert-system/rules/{}/", version:3},
  "module-expertSystem-fact-read": {url :"api/expert-system/expert-system/facts/", version:3},
  "module-expertSystem-fact-delete": {url :"api/expert-system/expert-system/facts/{}/", version:3},

  "module-expertSystem-keyword-read": {url: "api/expert-system/expert-system/keywords/", version: 3},
  "module-expertSystem-keyword-create": {url: "api/expert-system/expert-system/keywords/", version: 3},
  "module-expertSystem-keyword-delete": {url: "api/expert-system/expert-system/keywords/{}/", version: 3},
  "module-expertSystem-keyword-update": {url: "api/expert-system/expert-system/keywords/{}/", version: 3},
  "module-expertSystem-keyword-detail": {url: "api/expert-system/expert-system/keywords/{}/", version: 3},

  "module-expertSystem-keywordGroup-read": {url: "api/expert-system/expert-system/keyword-groups/", version: 3},
  "module-expertSystem-keywordGroup-create": {url: "api/expert-system/expert-system/keyword-groups/", version: 3},
  "module-expertSystem-keywordGroup-autocomplete": {url: "api/expert-system/expert-system/keyword-groups/", version: 3},
  "module-expertSystem-keywordGroup-delete": {url: "api/expert-system/expert-system/keyword-groups/{}/", version: 3},
  "module-expertSystem-keywordGroup-update": {url: "api/expert-system/expert-system/keyword-groups/{}/", version: 3},
  "module-expertSystem-keywordGroup-detail": {url: "api/expert-system/expert-system/keyword-groups/{}/", version: 3},

  "module-expertSystem-keywordPage-read": {url: "api/expert-system/expert-system/keyword-pages/", version: 3},
  "module-expertSystem-keywordPage-create": {url: "api/expert-system/expert-system/keyword-pages/", version: 3},
  "module-expertSystem-keywordPage-autocomplete": {url: "api/expert-system/expert-system/keyword-pages/autocomplete/", version: 3},
  "module-expertSystem-keywordPage-delete": {url: "api/expert-system/expert-system/keyword-pages/{}/", version: 3},
  "module-expertSystem-keywordPage-update": {url: "api/expert-system/expert-system/keyword-pages/{}/", version: 3},
  "module-expertSystem-keywordPage-detail": {url: "api/expert-system/expert-system/keyword-pages/{}/", version: 3},

  "module-expertSystem-feedback-read": {url: "api/expert-system/jobs/job-feedbacks/", version: 3},
  "module-expertSystem-feedback-detail": {url: "api/expert-system/jobs/job-feedbacks/{}/", version: 3},
  "module-expertSystem-feedback-job-validation-read": {url: "api/app/job-es/{}/es-validations/", version: 3},
  "module-expertSystem-feedback-job-validation-detail": {url: "api/app/job-es/{}/es-validations/{}/", version: 3},
  "module-expertSystem-feedback-job-plan-fact-read": {url: "api/v3/detection/job-plan-facts/", version: 3},
  "module-expertSystem-feedback-job-plan-fact-detail": {url: "api/v3/detection/job-plan-facts/{}/", version: 3},
  "module-expertSystem-feedback-job-plan-fact-file": {url: "api/v3/detection/job-plan-facts/{}/open-fact/", version: 3},
  "module-expertSystem-feedback-s3-file-sign": { url: "api/app/storage-ep/files/{}/sign/", version: 3 },
}
