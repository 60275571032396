import { BaseDetail } from 'App/Screens/SubModules/Component'
import fields from 'App/Screens/Job/Holidays/fields'

class PageDetail extends BaseDetail {
  constructor(props) {
    super(props)

    this.urlKey = 'module-branch-holiday-detail'
    this.title = 'Holiday'
    this.urlList = '/branch-queue/holiday'
    this.historyUrl = "module-branch-holiday-history"
    this.historyDetailUrl = ""
    this.headerDescriptions = [
      { key: 'date', label: 'Date', type: 'datetime', format: 'DD MMMM YYYY' },
      { key: 'branch.name', label: 'Branch' },
      { key: 'name', label: 'Name' },
      { key: 'created', label: 'Created', type: 'datetime' },
      { key: 'modified', label: 'Modified', type: 'datetime' },
    ]
    this.fields = fields
    this.history = {
      url: 'module-branch-holiday-history',
      fields: fields,
      urlDetail: "module-branch-holiday-history-detail",
      id: props.match.params.id,
      title: 'name'
    }
  }
}

export default PageDetail