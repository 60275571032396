const module = 'merchant'
const list = [
  {
    label: 'Account',
    url: `/${module}/account`,
    icon: 'user',
    code: 'jobs.{}_account',
  },
  {
    label: 'Extra Charge',
    url: `/${module}/extra-charge`,
    icon: 'money-collect',
    code: 'jobs.{}_extracharge',
  },
  {
    label: 'Extra Charge Size',
    url: `/${module}/extra-charge-size`,
    icon: 'column-width',
    code: 'jobs.{}_extrachargesize',
  },
  {
    label: 'Merchant Group',
    url: `/${module}/group`,
    icon: 'team',
    code: 'jobs.{}_merchantgroup',
  },
  {
    label: 'Merchant',
    url: `/${module}/merchant`,
    icon: 'shop',
    code: 'jobs.{}_merchant',
  },
  {
    label: 'Merchant Pricing',
    url: `/${module}/pricing`,
    icon: 'dollar',
    code: 'jobs.{}_merchantpricing',
  },
  {
    label: 'Mapping Mode',
    url: `/${module}/mapping-mode`,
    icon: 'deployment-unit',
    code: 'merchant.{}_mappingmode',
  },
  {
    label: 'SKU Mapping',
    url: `/${module}/sku-mapping`,
    icon: 'deployment-unit',
    code: 'merchant.{}_skumapping',
  },
  {
    label: 'Tag',
    url: `/${module}/tag`,
    icon: 'tags',
    code: 'merchant.{}_tag',
  },
  {
    label: 'Tag Merchant',
    url: `/${module}/tag-merchant`,
    icon: 'tag',
    code: 'merchant.{}_tagmerchant',
  },
]

export default { key: module, list }
