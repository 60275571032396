import { BaseList } from "App/Screens/SubModules/Component";
import descriptor from "./descriptorOffline";

class UserList extends BaseList {
  constructor(props) {
    super(props)

    this.appName = 'account'
    this.modelName = 'user'
    this.availablePermissions = ['.view_', '.add_', '.change_']
    this.propsList = {
      avatar: 'username',
      pageTitle: 'IBES User',
      descriptor,
      masterKey: 'module-groupUser-user',
      importSetting: {
        app_label: 'account',
        model_name: 'user',
      },
      urlKeyExport: 'module-groupUser-user-export',
      operation: false,
      groupByOptions: [
        {
          label: 'Home Branch',
          key: 'home_branch.name',
          dataIndex: 'home_branch.name',
        },
      ],
    }
    this.state = {
      ...this.state,
      currentPermissionSet: !this.state.isCheckingPermission ? this.availablePermissions : [],
    }
  }
}

export default UserList