import { BaseList } from "App/Screens/SubModules/Component";
import descriptor from "./descriptorOffline";

class ProductGroupList extends BaseList {
  constructor(props) {
    super(props)

    this.appName = 'estimation'
    this.modelName = 'productgroup'
    this.propsList = {
      avatar: 'name',
      pageTitle: 'Product Group',
      descriptor,
      masterKey: 'module-product-productGroup',
      groupByOptions: [
        {
          label: 'Code',
          key: 'code',
          dataIndex: 'code',
        },
      ]
    }
  }
}

export default ProductGroupList