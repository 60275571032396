export default [
  // {
  //   title: 'Job',
  //   type: 'SelectQuery',
  //   data: 'jobs',
  //   initialValue: '',
  //   required: true,
  //   note_text: '',
  //   additional: '',
  //   dataIndex: 'job',
  //   selectedKey: 'pk',
  //   selectedValue: 'name',
  //   order: 0,
  // },
  {
    title: 'Queue',
    type: 'SelectQuery',
    data: 'branches',
    initialValue: '',
    required: true,
    note_text: '',
    additional: '',
    dataIndex: 'queue',
    selectedKey: 'pk',
    selectedValue: 'name',
    order: 1,
  }, {
    title: 'Version',
    type: 'text',
    data: '',
    initialValue: '0',
    note_text: '',
    additional: '',
    dataIndex: 'version',
    order: 2,
  }, {
    title: 'Status',
    type: 'text',
    required: true,
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'status',
    order: 3,
  },
]
