import React from 'react'
import {Col, Row} from "antd";
import {ChartCard, TimelineChart, MiniArea, WaterWave} from "ant-design-pro/lib/Charts";
import moment from "moment";
import PropTypes from 'prop-types';

class ChartTimeline extends React.Component{

  // shouldComponentUpdate(nextProps, nextState){
  //   console.log(nextProps, 'kkk')
  // }

  render() {
    const {data, timelineOriginData} = this.props
    let timeName = data.name
    if(data.label){
      timeName = data.label
    }
    const visitData = [];
    const beginDay = new Date().getTime();
    for (let i = 0; i < 20; i += 1) {
      visitData.push({
        x: moment(new Date(beginDay + 1000 * 60 * 60 * 24 * i)).format('YYYY-MM-DD'),
        y: Math.floor(Math.random() * 5),
      });
    }
    return(
      <Row>
        <Col xs={15}>
          <ChartCard title={"Lead time branch "+ timeName} style={{marginRight: "5px"}}>
            <TimelineChart height={250} data={timelineOriginData} titleMap={{ y1: 'Active', y2: 'Inactive' }} />
          </ChartCard>
        </Col>
        <Col xs={9}>
          <ChartCard title={"Calculation"}>
            <div style={{ textAlign: 'center' }}>
              <WaterWave disabled height={161} title="busy calculation" percent={34} />
            </div>
          </ChartCard>
          <ChartCard style={{marginTop: "5px"}} title={"User is on leave"}>
            <MiniArea line color="#cceafe" height={45} data={visitData} />
          </ChartCard>
        </Col>
      </Row>
    )
  }
}
ChartTimeline.propTypes = {
  data: PropTypes.object,
  timelineOriginData: PropTypes.array
}
export default ChartTimeline;