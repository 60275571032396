import React from "react";
import PropTypes from "prop-types";
import { Row, Spin, Icon } from "antd";
import { get } from "lodash";
import { Lib, CheckPermission } from "App/Utils";
import SubAllList from "App/Component/SubAllList";
import descriptor from "./descriptorOffline";

class PageMerchant extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isGettingPermissions: true,
      permissionItems: [],
    }
  }

  getPermissions = () => {
    const permissionItems = CheckPermission.getPermissionSet({
      userReducer: this.props.userReducer,
      appName: 'jobs',
      modelName: 'merchantgroup',
    }).filter(perm => !perm.includes('.delete_'))
    this.setState({ isGettingPermissions: false, permissionItems })
  }

  onAddMerchant = () => {
    const { merchantGroup } = this.props
    const historyState = {
      dataIdentifier: {
        merchant_group: { pk: merchantGroup.pk, name: merchantGroup.name }
      }
    }
    Lib.historyAction(this.props.history, '/merchant/merchant/add', historyState).push()
  }

  onDetailMerchant = (id) => {
    Lib.historyAction(this.props.history, `/merchant/merchant/${id}`).push()
  }

  componentDidMount() {
    this.getPermissions()
  }

  componentWillUnmount() {
    Lib.windowStop();
  }

  render() {
    const { userReducer, match } = this.props
    const { isGettingPermissions, permissionItems } = this.state

    return isGettingPermissions ? (
      <Row type='flex' justify='center' align='middle' style={{ margin: '30vh 0' }}>
        <Spin
          size='large'
          tip='Loading...'
          indicator={<Icon spin type='loading' style={{ fontSize: '4rem' }} />}
        />
      </Row>
    ) : (
      <SubAllList
        permissions={permissionItems}
        pageTitle="Merchant"
        avatar="name"
        descriptor={descriptor}
        masterKey="module-merchant-group-refMerchant"
        args={[get(match, 'params.id')]}
        onAddClick={this.onAddMerchant}
        onDetailClick={this.onDetailMerchant}
        userReducer={userReducer}
        groupByOptions={[
          {
            label: 'Mapping Mode',
            key: 'mapping_mode.name',
            dataIndex: 'mapping_mode.name',
          }, {
            label: 'Active Status',
            key: 'is_active',
            dataIndex: 'is_active',
          }
        ]}
      />
    );
  }
}

PageMerchant.propTypes = {
  userReducer: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  merchantGroup: PropTypes.object.isRequired,
}

PageMerchant.defaultProps = {

}

export default PageMerchant;
