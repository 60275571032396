import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Result, Button } from 'antd'

// const STATUSES = ['403', '404', '500', 'success', 'error', 'info', 'warning']
const STATUSES = ['403', '404', '500']

export default class PageNotFound extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      status: this.getStatus(props.status)
    }
  }

  getStatus = (strStatus) => {
    if (STATUSES.find(status => status === strStatus)) {
      return strStatus
    }
    return '404'
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.status !== nextProps.status) {
      this.setState({ status: this.getStatus(nextProps.status) })
    }
  }

  render() {
    const { title, subTitle, extra, urlTarget } = this.props
    const { status } = this.state

    return (
      <Result
        status={status}
        title={title}
        subTitle={subTitle}
        extra={
          extra || (
            <Link to={urlTarget}>
              <Button type='primary'>Back Home</Button>
            </Link>
          )
        }
      />
    )
  }
}

PageNotFound.propTypes = {
  status: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  urlTarget: PropTypes.string,
  extra: PropTypes.node,
}

PageNotFound.defaultProps = {
  status: '404',
  title: '404',
  subTitle: 'Sorry, the page you visited does not exist.',
  urlTarget: '/',
  extra: null,
}