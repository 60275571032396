import descriptor from './descriptorOffline'

export default {
  key: 'merchant',
  title: 'Merchant',
  icon: 'shop',
  manualFilter: { key: 'extra_charge', value: 'pk' },
  contentProps: {
    urlTarget: '/merchant/merchant',
    appName: 'jobs',
    modelName: 'merchant',
  },
  propsList: {
    avatar: 'name',
    avatarColorKey: 'branch.color',
    masterKey: 'module-merchant-account-refMerchant',
    descriptor,
  }
}