import React from 'react'
import PropTypes from 'prop-types'
import { Pie } from 'ant-design-pro/lib/Charts'
import { Row, Col, Spin, Empty, Radio, message } from 'antd'
import { get } from 'lodash'
import { Lib, RequestV2 } from 'App/Utils'

const NO_DATA = 'No Job Grade'
const labelReceived = { '7d': '7 Days', '30d': '30 Days' }

class GradeSummary extends React.PureComponent {
  constructor(props) {
    super(props)

    this.limitReceived = '7d'
    this.state = {
      isLoading: true,
      gradeSummary: [],
      total: 0,
      noDataDesc: NO_DATA, 
    }
  }

  setThisState = (obj, callback = () => null, force) => {
    if (force) {
      return this.setState(obj, callback)
    } else {
      for (const key in obj) {
        if (obj[key] !== this.state[key]) {
          return this.setState(obj, callback)
        }
      }
    }
    callback()
    return false
  }

  readSummary = () => {
    this.setThisState({ isLoading: true }, () => {
      RequestV2(
        'get',
        'module-job-gradeSummaries',
        {},
        {limit_received: this.limitReceived},
        [],
        this.readSummarySuccess,
        this.readSummaryFailed,
      )
    })
  }

  readSummarySuccess = response => {
    const keysConvert = { x: 'label', 'y': 'total' }
    let total = 0
    const gradeSummary = []
    for (const index in response.data) {
      total += get(response.data[index], 'total', 0)
      gradeSummary.push({ x: response.data[index][keysConvert.x], y: response.data[index][keysConvert.y] })
    }
    // this.setState({ isLoading: false, gradeSummary: data })
    this.setState({ isLoading: false, gradeSummary, total, noDataDesc: `${NO_DATA} in last ${labelReceived[this.limitReceived]}` })
  }

  readSummaryFailed = error => {
    Lib.errorMessageHandler(get(error, 'response'))
    this.setState({ isLoading: false, gradeSummary: [], total: 0, noDataDesc: 'Request failed' })
  }

  onLimitChange = event => {
    this.limitReceived = event.target.value
    this.setState({ isLoading: true }, () => {
      setTimeout(() => {
        this.readSummary()
      }, 100)
    })
  }

  componentDidMount() {
    this.readSummary()
  }

  render() {
    const { height, title } = this.props
    const { isLoading, gradeSummary, total, noDataDesc } = this.state

    return (
      <Spin spinning={isLoading}>
        <Col>
          <Row type='flex' justify='space-between' style={{ marginBottom: '12px' }}>
            <Col>
              <span style={{ fontSize: '16px', fontWeight: 700 }}>
                {title}:
                <span style={{ fontSize: '12px', marginLeft: '6px', border: '1px solid #c8c8c8', borderRadius: '4px', backgroundColor: '#f0f2f5', padding: '2px 6px' }}>
                  {total || 0}
                </span>
              </span>
            </Col>
            <Col>
              <Radio.Group defaultValue='7d' size='small' buttonStyle='solid' onChange={this.onLimitChange}>
                <Radio.Button value='7d'>7 Days</Radio.Button>
                <Radio.Button value='30d'>30 Days</Radio.Button>
              </Radio.Group>
            </Col>
          </Row>
          {
            // Pie harus di unmount untuk mengatasi bug #727
            (!isLoading && gradeSummary.length > 0) ? (
              <div className='custom-ant-pie'>
                <Pie  hasLegend height={height - 8} data={gradeSummary} />
              </div>
            ) : (
              <Row type='flex' justify='center' align='middle' style={{ height }}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={noDataDesc} />
              </Row>
            )
          }
        </Col>
      </Spin>
    )
  }
}

GradeSummary.propTypes = {
  userReducer: PropTypes.object.isRequired,
  title: PropTypes.string,
  height: PropTypes.number,
}

GradeSummary.defaultProps = {
  title: 'Received Job Grade',
  height: 200,
}

export default GradeSummary