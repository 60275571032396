import React from "react";
import {message, Row, Spin} from "antd";
import 'ant-design-pro/dist/ant-design-pro.css';
import ComponentHelper from "./Lib2";
import PropTypes from 'prop-types';
import {kebabCase} from "lodash";
import { Request } from "App/Utils";

class Overview extends React.Component{
  state = {
    isLoading: true,
    firstoriginData: {},
    lastoriginData: {}
  }

  componentDidMount() {
    this.read()
  }

  read=()=>{
    const {data, url, userReducer} = this.props
    this.request = Request(
      "get",
      "overview-read",
      {Authorization: `Bearer ${userReducer.token}`},
      {id: data.pk},
      [kebabCase(url)],
      this.successRequest,
      this.failedRequest,
      undefined,
      true
    );
  }

  successRequest=(response)=>{
    this.setState({
      firstoriginData: response.data.firstcontent,
      lastoriginData: response.data.lastcontent,
      isLoading: false
    })
  }

  failedRequest=(error)=>{
    message.error('overview Api still underconstruction');
  }

  render(){
    const {data} = this.props
    const {isLoading, firstoriginData, lastoriginData} = this.state
    return(
        !isLoading ?
          <Row>
            {ComponentHelper.overviewFirstContent(firstoriginData.type, data, firstoriginData.data)}
            {ComponentHelper.overviewLastContent(lastoriginData.type, data, lastoriginData.data)}
          </Row> :
          <div style={{ paddingTop: 100, textAlign: "center" }}>
            <Spin
              tip="Loading data..."
            />
          </div>
      )
    }
  }
Overview.propTypes = {
  data: PropTypes.object,
  url: PropTypes.string
};

Overview.defaultProps = {
  url: "products",
  data: {
    created_by: "onehub",
    modified_by: "admin"
  }
}
export default Overview;
