import { BaseList, BaseDetail } from "App/Component/Bases";
import fields from "./fields";
import descriptor from "./descriptor";
import options from "./options";

const title = "Holidays";
const urlData = "holidays";
const bulkName = "name";

class List extends BaseList {
  constructor(props) {
    super(props)

    this.title = title;
    this.urlData = urlData;
    this.bulkName = bulkName;
    this.propsList = {
      descriptor,
      exportImport: options.exportImportOptions,
    }
  }
}

class Detail extends BaseDetail {
  constructor(props) {
    super(props)

    this.title = title
    this.urlData = urlData
    this.bulkName = bulkName
    this.fields = fields
    this.propsDetail = {
      urlKeyHistory: 'api:admin:jobs:holiday:detail-revision',
      keyLabel: 'name'
    }
  }
}

export default { List, Detail };