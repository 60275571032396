import React from "react";
import { BaseList, BaseDetail } from "App/Component/Bases";
import SkuMappingManagement from "./Management";
// import descriptor from "App/Screens/SubModules/Merchant/SkuMapping/descriptorOffline";
import ModalImport from "./ModalImport";
import descriptor from "./descriptor";
import options from "./options";
// import fields from "./fields";

const title = "SKU Mappings";
const urlData = "skuMappings";
const bulkName = "product_code";
const appName = "merchant";
const modelName = "skumapping";

class List extends BaseList {
  constructor(props) {
    super(props)

    this.title = title;
    this.urlData = urlData;
    this.bulkName = bulkName;
    this.propsList = {
      descriptor,
      getModalImport: this.getModalImport,
      exportImport: options.exportImportOptions,
    };
  }

  getAppName = () => appName; // when appName in splitted urlName not same

  getModelName = () => modelName; // when modelName in splitted urlName not same

  getModalImport = (props) => {
    return (
      <ModalImport {...props} />
    )
  }
}

// class Detail extends BaseDetail {
//   title = title;
//   urlData = urlData;
//   bulkName = bulkName;
//   fields = fields;
// }

export default { List, Detail: SkuMappingManagement };