export default {
  bulkOptions: [
    {
      key: 'set_to_active',
      field: 'status',
      value: 'active',
      label: 'Set to Active',
      permissionCode: 'estimation.change_section',
    }, {
      key: 'set_to_inactive',
      field: 'status',
      value: 'inactive',
      label: 'Set to Inactive',
      permissionCode: 'estimation.change_section',
    }, {
      key: 'set_to_unpublished',
      field: 'status',
      value: 'unpublished',
      label: 'Set to Unpublished',
      permissionCode: 'estimation.change_section',
    }
  ],
  exportImportOptions: {
    export: {
      directly: true,
      url_name: 'api:admin:estimation:section:export',
    },
  },
}