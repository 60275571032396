import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Button, Steps, Progress, Descriptions, Icon, Badge, Typography, Upload, Input } from 'antd'
import { get } from 'lodash'
import moment from 'moment'
import { Lib, RequestV2 as Request } from 'App/Utils'

const { Step } = Steps
const { Title, Text } = Typography
const { TextArea } = Input

const STATUSES = [
  { key: 'new', label: 'New' },
  { key: 'inprogress', label: 'In Progress' },
  {
    key: 'finished',
    label: 'Finished',
    // another: {
    //   key: 'cancelled',
    //   label: 'Cancelled',
    // }
  },
]

class ImportInformation extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      statusCollapsed: true,
      isActivating: false,
    }
  }
  
  getStatus = () => {
    const { data } = this.props
    const { statusCollapsed } = this.state
    const status = get(data, 'status', 'Unknown')
    const objStatus = STATUSES.find(val => val.key === status)
    const current = STATUSES.findIndex(val => val.key === status)

    return (
      <div style={{ padding: '8px' }}>
        <Row type='flex' align='middle' style={{ cursor: 'pointer' }} onClick={this.onCollapseStatus}>
          <Icon className='custom-icon-collapse' type='right' rotate={statusCollapsed ? 0 : 90} />
          Status:
          {/* <Badge status='success' style={{ margin: '0 4px 0 8px' }} /> */}
          <b style={{ margin: '0 8px' }}>{get(objStatus, 'label', status)}</b>
          {get(data, 'status') === 'new' ? (
            '(in process queue, waiting for turn)'
          ) : (
            get(data, 'status') === 'inprogress' && get(data, 'running_process_found') && (
              <span style={{ color: '#f5222d' }}>
                (Warning: {get(data, 'exim_type')} process {get(data, 'process_id')} is NOT currently runing (quit unexpectedly), please check errors.)
              </span>
            )
          )}
        </Row>
        {!statusCollapsed && (
          <Steps progressDot current={current} direction='vertical' style={{ paddingLeft: '27px' }}>
            {
              STATUSES.map(val => (
                <Step key={val.key} title={val.label} />
              ))
            }
          </Steps>
        )}
      </div>
    )
  }

  onCollapseStatus = () => {
    this.setState(prevState => ({ statusCollapsed: !prevState.statusCollapsed }))
  }

  getPhase = () => {
    const { data } = this.props
    const progressPct = get(data, 'progress_pct', 0)
    const secondPhasePct = get(data, 'second_phace_progress_pct', 0)
    return (
      <div style={{ marginBottom: '24px' }}>
        <Row>
          Main Phase :
          <Progress percent={progressPct} format={percent => `${percent}%`} />
        </Row>
        {!!secondPhasePct && (
          <Row>
            Second Phase :
            <Progress percent={secondPhasePct} format={percent => `${percent}%`} />
          </Row>
        )}
      </div>
    )
  }

  onActivateItems = () => {
    // use exportImportSessions-activateItems
    this.setState({ isActivating: true }, () => {
      Request(
        'post',
        'exportImportSessions-activateItems',
        {},
        {},
        [get(this.props.data, 'pk')],
        this.onActivateSuccess,
        this.onActivateFailed,
      )
    })
  }

  onActivateSuccess = (response) => {
    this.setState({ isActivating: false })
    if (typeof this.props.onReload === 'function') {
      this.props.onReload()
    }
  }

  onActivateFailed = (error) => {
    Lib.errorMessageHandler(get(error, 'response'))
    this.setState({ isActivating: false })
  }

  render() {
    const { data } = this.props
    const { isActivating } = this.state

    return (
      <div>
        <div style={{ fontSize: '20px', fontWeight: 600 }}>
          Import: {get(data, ['description'], '-')}
        </div>
        {this.getStatus()}
        <div style={{ padding: '12px' }}>
          {this.getPhase()}
          <div style={{ marginBottom: '12px' }}>
            <Row type='flex' justify='space-around' gutter={[12, 12]}>
              <Col>
                <div style={{ textAlign: 'center' }}>
                  <Progress type='circle' width={80} percent={get(data, 'items_done')} format={item => `${item}`} />
                  <div style={{ marginTop: '12px', fontSize: '16px', fontWeight: 500 }}>
                    Items Done
                  </div>
                  <div style={{ fontSize: '16px', fontWeight: 500 }}>
                    ({get(data, 'done_pct', '-')}%)
                  </div>
                </div>
              </Col>
              {!!get(data, 'items_failed') && (
                <Col>
                  <div style={{ textAlign: 'center' }}>
                    <Progress type='circle' width={80} percent={get(data, 'items_failed')} status='exception' format={item => `${item}`} />
                    <div style={{ marginTop: '12px', fontSize: '16px', fontWeight: 500 }}>
                      Items Failed
                    </div>
                    <div style={{ fontSize: '16px', fontWeight: 500 }}>
                      ({get(data, 'failed_pct', '-')}%)
                    </div>
                  </div>
                </Col>
              )}
              <Col>
                <div style={{ textAlign: 'center' }}>
                  <Progress type='circle' width={80} percent={100} format={() => `${get(data, 'items_total', 0)}`} />
                  <div style={{ marginTop: '12px', fontSize: '16px', fontWeight: 500 }}>
                    Items Total
                  </div>
                </div>
              </Col>
              {!!get(data, 'duration') && (
                <Col>
                  <div style={{ textAlign: 'center' }}>
                    <Progress type='circle' width={80} percent={100} format={() => `${parseFloat(get(data, 'duration', '-')).toFixed(2)} s`} />
                    <div style={{ marginTop: '12px', fontSize: '16px', fontWeight: 500 }}>
                      Duration
                    </div>
                  </div>
                </Col>
              )}
            </Row>
          </div>
          <div style={{ marginBottom: '12px' }}>
            <Descriptions bordered size='small' column={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 2, xxl: 3 }}>
              <Descriptions.Item label={get(data, 'is_import') ? 'Source file' : 'Result File'}>
                {get(data, 'file') ? (
                  <a href={get(data, ['file', 'file'])} target='_blank' rel='noopener noreferrer'>
                    {get(data, ['file', 'file_name'])}
                  </a>
                ) : (
                  'An unexpected error has occured. File is missing.'
                )}
              </Descriptions.Item>
              <Descriptions.Item label='Submitted by'>
                {get(data, ['created_by', 'full_name'], 'Unknown')} at {moment(get(data, 'created')).format('DD MMM YYYY hh:mm:ss')}
              </Descriptions.Item>
              <Descriptions.Item label='Last Updated'>
                {moment(get(data, 'modified')).format('DD MMM YYYY hh:mm:ss')}
              </Descriptions.Item>
              <Descriptions.Item label='Session ID'>
                {get(data, 'pk', '-')}
              </Descriptions.Item>
            </Descriptions>
          </div>
          {get(data, 'is_import') && (
            <div style={{ marginBottom: '12px' }}>
              <Row>
                Options :
                <ul>
                  <li>
                    {get(data, 'overwrite_existing') ? 'Existing data is overwritten' : 'Existing data is left untouched'}
                  </li>
                  <li>
                    {get(data, 'activate_imported_items') ? (
                      'Imported items are activated straight away'
                    ) : (
                      <>
                        Imported items are only available to some privileged users to test first. When they're tested and ready:
                        <div>
                          <Button icon='setting' size='small' loading={isActivating} onClick={this.onActivateItems}>
                            Activate Items
                          </Button>
                        </div>
                      </>
                    )}
                  </li>
                </ul>
              </Row>
            </div>
          )}
          {get(data, 'errors') && (
            <div style={{ marginBottom: '12px' }}>
              <div style={{ fontWeight: 500, color: '#ff0000' }}>
                Errors :
              </div>
              <TextArea
                readOnly
                value={get(data, 'errors')}
                autoSize={{ minRows: 2, maxRows: 10 }}
                style={{ backgroundColor: '#ffe5e6', color: '#666' }}
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}

ImportInformation.propTypes = {
  data: PropTypes.object, // hasil retrieve export import session
}

ImportInformation.defaultProps = {

}

export default ImportInformation