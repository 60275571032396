import React from 'react'
import PropTypes from 'prop-types'
import { Card, Col, message, Row } from 'antd'
import { DragDropContext } from 'react-beautiful-dnd'
import CardItem from './CardItem'

class Content extends React.Component {
  constructor(props) {
    super(props)

    this._cardItems = {}
    this.state = {}
  }

  onDragEndOld = result => {
    const { source, destination } = result
    if (destination) {
      if (destination.droppableId !== source.droppableId) {
        message.info('Moving to other subsection is not supported')
      } else if (destination.index !== source.index) {
        const { results } = this._cardItems[destination.droppableId].state
        // masih di 1 subsection dan 1 prefill set
        const [sourcePrefillSet] = results.splice(source.index, 1)
        results.splice(destination.index, 0, sourcePrefillSet)
        const recordsHasChanged = (destination.index > source.index ? destination.index : source.index) + 1
        this._cardItems[destination.droppableId].setState(prevState => ({
          results: [...results],
          isTouched: true,
          recordsHasChanged: prevState.recordsHasChanged > recordsHasChanged ? prevState.recordsHasChanged : recordsHasChanged,
        }))
      }
    } 
  }

  onDragEnd = result => {
    const { source, destination } = result
    if (destination) {
      if (destination.droppableId !== source.droppableId) {
        message.info('Cannot moving to other subsection.')
        // const sourceResults = this._cardItems[source.droppableId].state.results
        // const destinationResults = this._cardItems[destination.droppableId].state.results
        // const [sourceItem] = sourceResults.splice(source.index, 1)
        // destinationResults.splice(destination.index, 0, sourceItem)
        // this._cardItems[source.droppableId].setState(prevState => ({
        //   results: [...sourceResults],
        //   isTouched: true,
        //   recordsHasChanged: prevState.recordsHasChanged > source.index ? prevState.recordsHasChanged : source.index,
        // }))
        // this._cardItems[destination.droppableId].setState(prevState => ({
        //   results: [...destinationResults],
        //   isTouched: true,
        //   recordsHasChanged: destinationResults.length - 1,
        // }))
      } else if (destination.index !== source.index) {
        const { results } = this._cardItems[destination.droppableId].state
        // masih di 1 prefill set, target saya bisa multiple select kemudian di drag
        const [sourcePrefillSet] = results.splice(source.index, 1)
        results.splice(destination.index, 0, sourcePrefillSet)
        const recordsHasChanged = (destination.index > source.index ? destination.index : source.index)
        this._cardItems[destination.droppableId].setState(prevState => ({
          results: [...results],
          isTouched: true,
          recordsHasChanged: prevState.recordsHasChanged > recordsHasChanged ? prevState.recordsHasChanged : recordsHasChanged,
        }))
      }
    } 
  }

  validateFields = () => {
    const values = []
    for (const key in this._cardItems) {
      if (this._cardItems[key]) {
        const tempValues = this._cardItems[key].validateFields()
        values.push(...tempValues)
      }
    }
    return values
  }

  render() {
    const { colProps, propsCard, selectedSubsections, onCloseCard, urlKeySave } = this.props

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Row type='flex' gutter={[12, 12]}>
          {selectedSubsections.keys.map(subsectionId => (
            <Col key={subsectionId} {...colProps}>
              <CardItem
                ref={ref => {this._cardItems[subsectionId] = ref}}
                title={`${selectedSubsections.obj[subsectionId].section.name} - ${selectedSubsections.obj[subsectionId].name}`}
                subsectionId={subsectionId}
                onClose={onCloseCard}
                urlKeySave={urlKeySave}
                {...propsCard}
              />
            </Col>
          ))}
        </Row>
      </DragDropContext>
    )
  }
}

Content.propTypes = {
  selectedSubsections: PropTypes.object.isRequired,
  onCloseCard: PropTypes.func,
  propsCard: PropTypes.object,
  colProps: PropTypes.object,
}

Content.defaultProps = {
  onCloseCard: () => null,
  propsCard: null,
  colProps: { xs: 24, sm: 24, lg: 12, xl: 12, xxl: 8 },
}

export default Content