import React from 'react'
import Carousel, { Modal as ModalReact, ModalGateway } from 'react-images'
import { Icon, Row, Col, Button, Modal as ModalAntd, Tooltip, Typography, message } from 'antd'
import styled from 'styled-components'
import LightboxModal from './LightboxModal'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import 'App/Component/Singlepage/SingleStyle.css'

const { confirm } = ModalAntd
const { Text } = Typography

class CustomImage extends React.Component {
  state = {
    viewerIsOpen: false,
    currentIndex: 0,
    showImageLightbox: false,
  }

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
  }

  handleClick(e, currentIndex) {
    this.setState({
      showImageLightbox: true,
      currentIndex,
    })
  }

  closeLightbox = () => {
    this.setState({
      viewerIsOpen: false,
      currentIndex: 0,
    })
  }

  handleDelete(e, image) {
    e.stopPropagation()
    const pk = image.pk
    this.showDeleteConfirm(pk)
  }

  showDeleteConfirm = (id, callback) => {
    confirm({
      title: 'Are you sure delete this file?',
      content: 'This action will delete on the database',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk:()=> {
        this.props.removeImage(id)
        this.setState({isEditing: false})
        callback && callback(true)
      },
      onCancel:()=> {
        callback && callback(false)
        this.setState({isEditing: this.state.isEditing}) // cuma buat biar ke render aja
      },
    })
  }

  render() {
    const { readOnly, index, left, top, photo, margin, images, imagesRecord } = this.props
    const { viewerIsOpen, currentIndex, showImageLightbox } = this.state

    const cont = {
      cursor: "zoom-in",
    }

    return (
      <div
        style={{
          position: 'absolute',
          margin,
          height: photo.height,
          width: photo.width,
          left: left,
          top: top,
          ...cont
        }}
      >
        <Row onClick={e => this.handleClick(e, index)}>
          <div>
            <div>
              <RoundedImage
                className='image-hover'
                alt={photo.title}
                src={photo.src}
                effect='blur'
                {...photo}
              />
            </div>
          </div>
          <div
            className='overlay'
            style={{
              borderRadius: '4px',
              position: 'absolute',
              // margin,
              height: photo.height,
              width: photo.width,
              left: 0,
              top: 0,
            }}
          >
            <Col
              style={{
                position: 'absolute',
                top: 0,
                padding: '12px 12px 12px 46px',
                width: photo.width,
              }}
            >
              <Row style={{display: 'flex', justifyContent: 'center', backgroundColor: 'rgba(0, 0, 0, 0.4)', borderRadius: '4px', padding: '0 6px',}}>
                <Tooltip placement='bottom' title={imagesRecord[index].title}>
                  <StyledText
                    strong
                    ellipsis
                    onClick={e => e.stopPropagation()}
                  >
                    {imagesRecord[index].title}
                  </StyledText>
                </Tooltip>
              </Row>
            </Col>
            {!readOnly && (
              <Button
                type='danger'
                onClick={e => this.handleDelete(e, imagesRecord[index])}
                style={{ position: 'absolute', right: 0, bottom: 0, margin: '7px' }}
                icon='delete'
              />
            )}
            {
              !this.props.selectedImage[index] &&
              <Icon type='check-square'
                theme='filled'
                style={{
                  fontSize: '29px',
                  color: 'rgba(0, 0, 0, 0.5)',
                  backgroundColor: 'rgba(175, 175, 175, 0.4)',
                  borderRadius: '1px',
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  margin: '11px 11px',
                  cursor: 'pointer',
                  boxShadow: '0 0px 8px 0 rgba(0, 0, 0, 0.2), 0 0px 14px 0 rgba(0, 0, 0, 0.19)',
                }}
                onClick={e => {
                  e.stopPropagation()
                  if (readOnly) {
                    message.info('Cannot select images when using the Read Only mode.')
                  } else {
                    this.props.selectImage(imagesRecord[index].pk, true)
                  }
                }}
              />
            }
          </div>
          {
            this.props.selectedImage[index] &&
            <Icon type='check-square'
              theme='filled'
              // twoToneColor='#1890ff'
              style={{
                fontSize: '28px',
                color: '#1890ff',
                backgroundColor: '#fff',
                borderRadius: '1px',
                position: 'absolute',
                left: 0,
                top: 0,
                margin: '11px 11px',
                cursor: 'pointer',
                boxShadow: '0 0px 8px 0 rgba(0, 0, 0, 0.2), 0 0px 14px 0 rgba(0, 0, 0, 0.19)',
              }}
              onClick={e => {
                e.stopPropagation()
                this.props.selectImage(imagesRecord[index].pk, false)
              }}
            />
          }
        </Row>
        <ModalGateway>
          {viewerIsOpen ? (
            <ModalReact onClose={this.closeLightbox}>
              <Carousel
                currentIndex={currentIndex}
                views={images.map(x => ({
                  ...x,
                  width: '30%',
                  srcset: x.srcSet,
                  caption: x.title
                }))}
              />
            </ModalReact>
          ) : null}
        </ModalGateway>
        {
          showImageLightbox &&
          <LightboxModal
            imagesRecord={imagesRecord}
            currentIndex={currentIndex}
            callback={obj => this.setState({[obj.key]: obj.value})}
          />
        }
      </div>
    )
  }
}

export default CustomImage

const RoundedImage = styled(LazyLoadImage)`
  border-radius: 4px;
`

const StyledText = styled(Text)`
  cursor: default;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.9) !important;
`
