import React from "react";
import {Icon, Tabs, Card, Form, Button, message} from "antd"
import {camelCase, get} from "lodash"
import { RequestV2 as Request } from "App/Utils";
import ComponentAutocomplete from 'App/Component/Autocomplete'
import {FormContext, FormProvider} from "App/Component/Context/ContextForm";
import DynamicField from "App/Component/DynamicField";
import LoadingSkleton from "App/Component/LoadingSkleton";
import styled from "styled-components";
import History from "App/Screens/SubModules/Component/History";
import {Lib} from "App/Utils"

const { TabPane } = Tabs;

class Settings extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      fields: [],
      loading: false,
      data: props.branch,
      isView: true,
      activeKey: '1'
    }
  }

  onFailed=(err)=>{
    alert("failed" , err)
  }

  messageCustom = (type, msg) => {
    if (type !== 'loading') {
      message.destroy();
      this.setLoading(false);
    } else {
      this.setLoading(true);
    }
    return (
      message[type](msg)
    )
  };

  setLoading = (val) => {
    this.setState({loading: val})
  };

  onSave(form) {
    this.messageCustom('loading', 'Saving data..');
    const {data} = this.state
    const {validateFieldsAndScroll, resetFields} = form;
    validateFieldsAndScroll({scroll: {offsetTop: 150}}, (err, values) => {
      this.request = Request(
        "put",
        "module-branch-update",
        {},
        values,
        [data.pk],
        this.editSuccess,
        this.editFailed,
        resetFields
      );
    })
  }

  editSuccess=(response)=>{
    this.setState({ data: response.data, isView: true, loading: false })
    this.props.changeBranch(response.data)
    this.messageCustom('success', 'Update successfully');
  }

  editFailed = (error) => {
    message.destroy()
    const errors = get(error.response, 'data')
    if (errors && typeof errors === 'object') {
      for (const key in errors) {
        message.error(`Error in ${key}: ${errors[key]}`)
      }
    } else {
      message.error('Save failed, code: ' + get(error.response, 'status') + ' ' + get(error.response, 'statusText'))
    }
    this.setState({ loading: false })
  }

  onSuccess=(response)=>{
    this.setState({ data: response.data, loading: false })
  }

  retrieve=()=> {
    this.request = Request(
      "get",
      "module-branch-detail",
      {},
      {},
      [this.props.match.params.id],
      this.onSuccess,
      this.onFailed
    );
  }

  hasErrors(fieldsError) {
    // Return Boolean
    return Object.keys(fieldsError).some(field => fieldsError[field]);
  }

  onCancel = (form) => {
    this.resetField(form, () => {
      this.setState({ isView: true })
    })
  }

  resetField = (form, callback = () => null) => {
    const { fields, data } = this.state
    const values = {}
    for (let i = 0; i < fields.length; i += 1) {
      const decoratorName = Lib.decoratorName(fields[i].title)
      if (fields[i].type === 'SelectQuery') {
        values[decoratorName] = Lib.setSelectValue(data[decoratorName], fields[i].selectedKey, fields[i].selectedLabel)
      } else {
        values[decoratorName] = data[decoratorName]
      }
    }
    form.setFieldsValue(values, callback)
  }

  componentDidMount() {
    this.setState({
      fields: ComponentAutocomplete[camelCase("branchManagement")]
    })
    // this.retrieve()
  }

  componentWillUnmount() {
    Lib.windowStop();
  }

  render() {
    const {loading, data, isView ,fields, activeKey} = this.state
    const {userReducer, changeBranch} = this.props
    let totalCount = fields.length
    return (
      <Card>
        {
          !loading ?
            <Tabs activeKey={activeKey} onChange={(activeKey)=>this.setState({ activeKey })} tabPosition={"left"}>
              <TabPane tab="Edit" key="1">
                <FormProvider>
                  <Form layout="horizontal" style={{height: "400px"}}>
                    { fields && fields.map((elem, index) => {
                      let datafield = (elem.dataIndex) && get(data, elem.dataIndex.split('.'))
                      return (
                        <DynamicField
                          {...elem}
                          onBlur={()=>null}
                          normFile={()=>null}
                          toggleDrawerSQ={()=>null}
                          operation={isView ? "view" : "edit"}
                          userReducer={userReducer}
                          dataRecord={datafield}
                          key={index}
                        />
                      );
                    })}
                    <FormContext.Consumer>
                      {(form) => (
                        <StyledFooter>
                          { isView ?
                          <StyledButton
                            onClick={() => {
                              this.setState({isView: false})
                            }} type="danger"
                            disabled={loading}
                          >
                          <Icon type="edit"/>  Edit branch
                          </StyledButton> :
                            <>
                            <StyledButton
                              type="danger"
                              disabled={loading}
                              onClick={() => {
                                this.onCancel(form)
                              }}
                            >  Cancel
                            </StyledButton>
                            <StyledButton
                            onClick={() => this.onSave(form)}
                            type="primary"
                            disabled={loading || this.hasErrors(form.getFieldsError())}
                            >
                            <Icon type="save" theme="filled" /> Save
                            </StyledButton>
                            </>
                          }
                        </StyledFooter>
                      )}
                    </FormContext.Consumer>
                  </Form>
                </FormProvider>
              </TabPane>
              <TabPane tab="History" key="2">
                <History
                  url="module-branch-history"
                  fields={fields}
                  userReducer={userReducer}
                  updateHeader={(data)=>{
                    changeBranch(data)
                    this.setState({ data, activeKey: '1' })
                  }}
                  urlDetail="module-branch-history-detail"
                  id={get(this.props, 'match.params.id')} 
                  title={get(data, 'name')} 
                />
              </TabPane>
            </Tabs>
            :
            <LoadingSkleton count={totalCount}/>
        }
      </Card>
    )
  }
}

export default Settings;

const StyledButton = styled(Button)`
  margin-right: 8px;
`;

const StyledFooter = styled.div`
  position: fixed;
  right: 8px;
  bottom: 10px;
  width: calc(100% - 300px);
  border-top: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
`;
