import descriptor from './descriptor'

export default {
  key: 'erfmapping',
  title: 'ERF Mapping',
  icon: 'deployment-unit',
  manualFilter: { key: 'erf_type', value: 'pk' },
  contentProps: {
    urlTarget: '/job-management/erf/{}/erf-mapping',
    appName: 'jobs',
    modelName: 'erfmapping',
  },
  propsList: {
    avatar: 'erf_field',
    masterKey: 'module-job-erfMapping',
    descriptor,
    availablePermissions: [],
  }
}