import React from 'react';
import { Table, Typography, Divider, Button, message } from 'antd';
import PropTypes from 'prop-types';
import RevertHistory from './RevertHistory';
import { get, isObject } from 'lodash';
import { RequestV2 as Request, Lib } from 'App/Utils';
import moment from 'moment';

class History extends React.Component {
  constructor(p){
    super(p)
    this.state = {
      selected: null,
      data: [],
      loading: true,
      pagination: { 
        current: 1, 
        total: 100, 
        pageSize: 25
      }
    }
    this.columns = [
      {
        title: 'Date/Time',
        dataIndex: 'created',
        editable: true,
        render: (e, obj)=>{
          return (
            <Button 
              type="link" 
              onClick={(e)=>{
                e.stopPropagation()
                if(p.setView)
                  p.setView(obj)
                else
                  this.setView(obj)
              }}
            >
              {moment(e).format('LLL')}
              </Button>
            )
        }
      },
      {
        title: 'User',
        dataIndex: 'user',
        editable: true,
        render: (e)=>{
          if(isObject(e))
            return <>{ e.username }({e.full_name})</>
          return 'Unknown'
        }
      },
      {
        title: 'Action',
        dataIndex: 'comment',
        // width: '50%',
        editable: true
      },
    ]
  }

  setView=(selected)=>{
    this.setState({ selected })
  }

  componentDidMount() {
    this.retrieve()
  }

  retrieveFailed=(data, callback)=>{
    message.error('Failed to read data')
    this.setState({ loading: false },()=>callback(false))
  }

  retrieveSuccess=(res, callback)=>{
    const { pagination } = this.state
    pagination.total = res.data.count
    pagination.current = res.data.current
    this.setState({
      pagination,
      data: res.data.results,
      loading: false
    },()=>callback(true))
  }

  retrieve=(callback=()=>null)=>{
    const [p,s] = [this.props,this.state]
    Request(
      'get',
      p.url,
      {},
      { limit: s.pagination.pageSize, page: s.pagination.current },
      [p.id],
      this.retrieveSuccess,
      this.retrieveFailed,
      callback
    )
  }

  dynamicFunction=(obj={}, func=false, ...args)=>{
    this.setState(obj)
    if(func)
      this[func].apply(this, args)
  }

  onCable=(param)=>{
    const { pagination } = this.state
    pagination.current = param.current
    this.setState({ pagination, loading: true },()=>this.retrieve())
  }

  renderView=()=>{
    const [p,s] = [this.props, this.state]
    if(s.selected)
      return(
        <RevertHistory 
          fields={p.fields} 
          detail={s.selected}
          idParent={p.id}
          urlDetail={p.urlDetail}
          updateHeader={p.updateHeader}
          title={p.title}
          dynamicFunction={this.dynamicFunction}
        />
      )
    return(
      <div>
        <Typography.Title level={4} style={{ fontStyle: 'italic', color: "#000000a6" }}>Change history: {p.title}</Typography.Title>
        <Divider style={{ margin: '5px 0px' }} orientation="center" dashed />
        <div 
          style={{
            borderLeft: '2px solid rgb(24, 144, 255)',
            backgroundColor: 'rgb(217, 236, 253)',
            padding: '8px',
            margin: '12px 0px',
          }}>
          <Typography.Text type="secondary">Choose a date from the list below to revert to a previous version of this object.</Typography.Text>
        </div>
        <Table
          bordered
          dataSource={s.data}
          rowKey={e => e.pk}
          loading={s.loading}
          onChange={this.onCable}
          onRow={(record, rowIndex) => {
            return {
              onClick: () => {
                if(p.setView)
                  p.setView(record)
                else
                  this.setView(record) 
              }
            }
          }}
          pagination={s.pagination}
          columns={this.columns}
          rowClassName="editable-row"
        />
      </div>
    )
  }
  render(){
    return this.renderView()
  }
}

export default History;

History.propTypes = {
  // id: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
  setView: PropTypes.func,
};

History.defaultProps = { title: "No title" };