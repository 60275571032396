import Main from "App/Screens";
import Dashboard from "App/Screens/Dashboard";
import Estimation from "App/Screens/Estimation/";
import Geo from "App/Screens/Geo/";
import Job from "App/Screens/Job";
// import ERF from "App/Screens/ERF";
import Multisite from "App/Screens/Multisite/";
import Notification from "App/Screens/Notification/";
import Report from "App/Screens/Report/";
import Users from "App/Screens/Users/";
import SubModules from "App/Screens/SubModules";
import { Login, Page404, ResetPassword } from "App/Screens/Pages";
import { Store } from "App/Redux";

const generateRoutes = routeConfig => {
  const routeObj = [],
    fillRoutes = item =>
      item.routes ? routeObj.push(...item.routes) : routeObj.push(item);
  for (const { routes } of routeConfig) routes.map(fillRoutes);
  return routeObj;
};

const appsRoutes = [
  Dashboard,
  Estimation,
  Geo,
  Job,
  // ERF,
  Multisite,
  Report,
  Users,
  Notification,
  SubModules.App
];

const loginRoutes = [SubModules.Login];

const auth = props => {
  return Store.getState().userReducer.isAuth;
};

export default [
  {
    /* login */
    auth: () => !auth(),
    path: "/login",
    to: "/",
    name: "login",
    component: Login
  },
  {
    auth: () => !auth(),
    path: "/login/reset",
    to: "/",
    name: "reset-password",
    component: ResetPassword
  },
  /* sub module */
  ...generateRoutes(loginRoutes),
  {
    /* main app */
    auth,
    path: "/",
    name: "Main",
    to: "/login",
    component: Main,
    exact: false,
    routes: [
      ...generateRoutes(appsRoutes),
      {
        path: "*",
        name: "404",
        component: Page404
      }
    ]
  },
  {
    path: "*",
    name: "404",
    component: Page404
  }
];
