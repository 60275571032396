export default [
  {
    title: 'User',
    type: 'SelectQuery',
    data: 'users',
    required: true,
    initialValue: '',
    selectedKey: 'pk',
    selectedValue: 'username',
    note_text: '',
    additional: '',
    dataIndex: 'user',
    paramProps: { is_staff: true },
    isActive: true,
    order: 0,
  }, {
    title: 'Start',
    type: 'date',
    required: true,
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'start',
    order: 1,
  }, {
    title: 'End',
    type: 'date',
    required: true,
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'end',
    order: 2,
  }, {
    title: 'Leave type',
    type: 'select',
    disableAllowClear: true,
    data: [
      {
        alias: 'holiday',
        value: 'Holiday Leave'
      }, {
        alias: 'sick',
        value: 'Sick Leave'
      }, {
        alias: 'pregnancy',
        value: 'Pregnancy Leave'
      }, {
        alias: 'bereavement',
        value: 'Bereavement Leave'
      }, {
        alias: 'gathering',
        value: 'Gathering Leave'
      }
    ],
    initialValue: 'holiday',
    note_text: '',
    additional: '',
    dataIndex: 'leave_type',
    order: 3,
  },
]