export default [
  {
    title: 'Template set',
    type: 'SelectQuery',
    required: true,
    data: 'templateSets',
    dataIndex: 'template_set',
    selectedKey: 'pk',
    selectedValue: 'name',
    initialValue: '',
    note_text: '',
    additional: 'edit|add',
    order: 0,
  }, {
    title: 'Name',
    type: 'text',
    required: true,
    dataIndex: 'name',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    order: 1,
  }, {
    title: 'Code',
    type: 'text',
    dataIndex: 'code',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    order: 2,
  }
];
