export default [
  {
    title: 'URL',
    type: 'url',
    // data: 'templateSets',
    dataIndex: 'link',
    initialValue: '',
    note_text: '',
    required: true,
  }, {
    title: 'Title',
    type: 'text',
    dataIndex: 'title',
    required: true,
  }, {
    title: 'Description',
    type: 'textarea',
    dataIndex: 'description',
  }
];
