import React from 'react'
import { Col, Row } from 'antd'

import { ButtonWindows, ButtonLinux, ButtonMac } from './Buttons'

class IBESv3Downloads extends React.PureComponent {
  render() {
    return (
      <div style={{ padding: '24px' }}>
        <div style={{ textAlign: 'center' }}>
          Download the
        </div>
        <h1 style={{ textAlign: 'center', fontSize: '28px' }}>
          IBESv3 App
        </h1>
        <Row type='flex' justify='center' gutter={[12, 12]}>
          <Col className='only-xs-block'>
            <ButtonWindows />
          </Col>
          <Col className='only-xs-block'>
            <ButtonLinux />
          </Col>
          <Col className='only-xs-block'>
            <ButtonMac />
          </Col>
        </Row>
      </div>
    )
  }
}

export default IBESv3Downloads