import React from 'react'
import { Result } from 'antd'
import { RequestV2 as Request } from 'App/Utils'
import { LoadingPage } from './LoadingPage'
import Detail from './JobDetail'

class JobManagement extends React.Component {
  constructor(props) {
    super(props)

    this.currentId = props.match.params.id
    this.state = {
      isLoading: this.currentId !== 'add',
      currentJob: null,
    }
  }

  retrieve() {
    let senderId = this.props.match.params.id
    Request(
      'get',
      'jobs-detail',
      {},
      {},
      [senderId],
      res => this.retrieveSuccess(res),
      err => this.setState({ isLoading: false }),
    )
  }

  retrieveSuccess(response) {
    this.setState({
      currentJob: response.data,
      isLoading: false,
    })
  }

  getJobDetail() {
    const standardProps = {
      history: this.props.history,
      location: this.props.location,
      match: this.props.match,
      userReducer: this.props.userReducer,
    }

    return <Detail {...standardProps} jobDetail={this.state.currentJob} />
  }

  componentDidMount() {
    if (this.currentId !== 'add') {
      this.retrieve()
    }
  }

  render() {
    const { isLoading, currentJob } = this.state
    if (!isLoading && currentJob) {
      return this.getJobDetail()
    } else if (!isLoading) {
      return (
        <Result
          status="404"
          title="Error"
          subTitle="Your request data is not found"
        />
      )
    } else {
      return <LoadingPage />
    }
  }
}

export default JobManagement
