import React from "react";
import {Badge, Card, Col, Icon, Row, Tooltip} from "antd";
import {ChartCard, MiniProgress, TagCloud} from "ant-design-pro/lib/Charts";
import PropTypes from 'prop-types';

class ChartTagCloud extends React.Component{

  render() {
    const {tags, newdata} = this.props
    return(
      <Row>
        <Col span={12}>
          <Card title={"Available data for edit"} style={{marginRight: "5px"}}>
            <TagCloud data={tags} height={198} />
          </Card>
        </Col>
        <Col span={12}>
          <ChartCard
            title={"Contributor models"}
            avatar={
              <img
                alt="indicator"
                style={{ width: 56, height: 56 }}
                src="https://gw.alipayobjects.com/zos/rmsportal/dURIMkkrRFpPgTuzkwnB.png"
              />
            }
            action={
              <Tooltip title="Contributor models">
                <Icon type="info-circle-o" />
              </Tooltip>
            }
            total={() => <span dangerouslySetInnerHTML={{ __html: newdata.contributor_models }} />}
          />
        </Col>
        <Col span={12}>
          <ChartCard
            style={{marginTop: "5px"}}
            title="Calculate data usage"
            action={
              <Tooltip title="percentage of data">
                <Icon type="info-circle-o" />
              </Tooltip>
            }
            total={newdata.calculate_data.used+"%"}
            footer={
              <div>
            <span>
              Inactive <Badge status="error" text={newdata.calculate_data.inactive+"%"} />
            </span>
                <span style={{ marginLeft: 16 }}>
              Active <Badge status="success" text={newdata.calculate_data.active+"%"} />
            </span>
                <span style={{ marginLeft: 16 }}>
                  Unpublished <Badge status="default" text={newdata.calculate_data.unpublished+"%"} />
                </span>
              </div>
            }
            contentHeight={56}
          >
            <MiniProgress percent={78} strokeWidth={8} target={100} />
          </ChartCard>
        </Col>
      </Row>
    )
  }
}
ChartTagCloud.propTypes = {
  newdata: PropTypes.object,
  tags: PropTypes.array
}
export default ChartTagCloud;