import React from 'react'
import PropTypes from 'prop-types'
import { Button, Col, Pagination, Row, Table } from 'antd'
import { get } from 'lodash'
import { Lib, RequestV2 as Request } from 'App/Utils'
import LoadingSpin from 'App/Component/LoadingSpin'
import PageError from 'App/Component/PageError'
import FilterImportType from './FilterImportType'
import columns from './columns'
import DescriptionsDialog from 'App/Screens/Estimation/ExportImportSessionNew/internal/DescriptionsDialog'
import descriptions from './descriptions'

class PageRows extends React.PureComponent {
  constructor(props) {
    super(props)

    this.page = 1
    this.limit = 100
    this.import_type = ''
    this.params = ['page', 'limit', 'import_type']
    this._dialogs = {}
    this.state = {
      isLoading: true, // for show loading only
      isReloading: false,
      rawData: null,
      rowsDialog: [],
    }
  }

  getThisParam = () => {
    const thisParam = {}
    for (let i = 0; i < this.params.length; i += 1) {
      const valParam = this[this.params[i]]
      if (valParam || valParam === 0) {
        thisParam[this.params[i]] = valParam
      }
    }
    return thisParam
  }

  read = (keyLoading = 'isLoading', callback = () => null) => {
    this.setState({ [keyLoading]: true }, () => {
      const bodyParam = this.getThisParam()
      Request(
        'get',
        'api:admin:exim:importqueue:ref-imported-datas-list',
        {},
        bodyParam,
        [this.props.queueId],
        this.readSuccess,
        this.readFailed,
        { keyLoading, callback },
      )
    })
  }

  readSuccess = (response, extra) => {
    const { results } = response.data
    const rowsDialog = []
    for (let i = 0; i < results.length; i += 1) {
      rowsDialog.push({
        ...results[i],
        key: results[i].label,
        setRef: (ref) => {this._dialogs[results[i].label] = ref},
        getRef: () => this._dialogs[results[i].label],
        args: [this.props.queueId, results[i].pk],
        contentProps: { descriptionsProps: { column: 2 }, fields: descriptions },
        prev: i > 0 ? () => {
          this._dialogs[results[i].label].hide()
          this._dialogs[results[i-1].label].show()
        } : null,
        next: (i+1) < results.length ? () => { // if ini masih salah
          this._dialogs[results[i].label].hide()
          this._dialogs[results[i+1].label].show()
        } : null,
      })
    }
    this.setState({ [extra.keyLoading]: false, rawData: response.data, rowsDialog }, () => {
      extra.callback(true, response)
    })
  }

  readFailed = (error, extra) => {
    this.setThisParam()
    if (!this.state.isLoading) Lib.errorMessageHandler(get(error, 'response'))
    this.setState({ [extra.keyLoading]: false, errorResponse: get(error, 'response') }, () => {
      extra.callback(false, error)
    })
  }

  getPageInformation = () => {
    const { rawData } = this.state
    const remaining = rawData.count - (rawData.limit * (rawData.current - 1))
    const totalCurrentPage = remaining > rawData.limit ? rawData.limit : remaining
    return (
      <div>
        Total <b>{totalCurrentPage}</b> from <b>{rawData.count}</b> entries
      </div>
    )
  }

  setThisParam = () => {
    const { rawData } = this.state
    if (rawData) {
      this.page = rawData.current
      this.limit = rawData.limit
    }
  }

  reload = (keyLoading = 'isReloading', callback = () => null) => {
    this.read(keyLoading, (isSuccess) => !isSuccess && callback(true)) // supaya tidak menampilkan pesan error di PageError jadi dikirim true
  }

  onPaginationChange = (page, pageSize) => {
    this.page = page
    this.reload()
  }

  onShowSizeChange = (current, size) => {
    this.page = current
    this.limit = size
    this.reload()
  }

  onRowClick = (event, record) => {
    let selectionLength = 0
    if (typeof get(window, 'getSelection') === 'function') {
      selectionLength = window.getSelection().toString().length
    }
    if (!selectionLength) {
      for (let i = 0; i < this.state.rowsDialog.length; i += 1) {
        if (record.label === this.state.rowsDialog[i].label) {
          const currentRef = this.state.rowsDialog[i].getRef()
          if (currentRef) {
            currentRef.show()
          }
        }
      }
      // this._dialog.show({ title: record.label, rowId: record.pk })
    }
  }

  onToggleFilter = () => {
    this._filter.toggle()
  }

  onConfirmFilter = (importType) => {
    this.import_type = importType
    this.page = 1
    this.reload()
  }

  componentDidMount() {
    this.read()
  }

  render() {
    const { isLoading, isReloading, rawData, errorResponse, rowsDialog } = this.state
    
    if (isLoading) {
      return (
        <LoadingSpin height='200px' tip='Loading...' />
      )
    }

    return rawData ? (
      <div>
        <Row className='mb-1' type='flex' justify='space-between' gutter={[12, 12]}>
          <Col>
            
          </Col>
          <Col>
            <Row type='flex' gutter={[6, 6]}>
              <Col>
                <Button icon='reload' loading={isReloading} onClick={() => this.reload()}>
                  Refresh
                </Button>
              </Col>
              <Col>
                <Button icon='setting' onClick={this.onToggleFilter}>
                  Filter Row
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <FilterImportType
          ref={ref => {this._filter = ref}}
          importType={this.import_type}
          onConfirm={this.onConfirmFilter}
        />
        <Table
          bordered
          className='custom-table-small mb-2'
          columns={columns}
          dataSource={rawData.results}
          loading={isReloading}
          pagination={false}
          rowKey='pk'
          size='small'
          scroll={{ y: 512 }}
          onHeaderRow={() => ({ style: { background: '#ededed' } })}
          onRow={(record, rowIndex) => {
            return {
              className: rowIndex % 2 === 1 ? 'odd' : '',
              style: { cursor: 'pointer' },
              onClick: (event) => this.onRowClick(event, record),
            }
          }}
        />
        <Row type='flex' justify='space-between' align='middle' gutter={[12, 12]}>
          <Col>
            {this.getPageInformation()}
          </Col>
          <Col>
            <Pagination
              showSizeChanger
              pageSizeOptions={['25', '50', '75', '100']}
              pageSize={rawData.limit}
              current={rawData.current}
              total={rawData.count}
              onChange={this.onPaginationChange}
              onShowSizeChange={this.onShowSizeChange}
            />
          </Col>
        </Row>
        {rowsDialog.map(val => (
          <DescriptionsDialog
            key={val.key}
            ref={ref => {val.setRef(ref)}}
            urlKey='api:admin:exim:importqueue:ref-imported-datas-detail'
            args={val.args}
            title={val.label}
            next={val.next}
            prev={val.prev}
            contentProps={val.contentProps}
          />
        ))}
      </div>
    ) : (
      <PageError errorResponse={errorResponse} onReload={this.reload} />
    )
  }
}

PageRows.propTypes = {
  queueId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
}

export default PageRows