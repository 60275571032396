import React from 'react'
import {message, Row, Spin} from 'antd';
import ComponentHelper from "./Lib2";
import moment from 'moment';
import PropTypes from "prop-types";
import {kebabCase} from "lodash";
import { Request } from "App/Utils";

class Statistic extends React.Component{

	state = {
		isLoading: true,
		firstoriginData: {},
		lastoriginData: {}
	}

	componentDidMount() {
		this.read()
	}

	read=()=>{
		const {data, url, userReducer} = this.props
		this.request = Request(
			"get",
			"statistic-read",
			{Authorization: `Bearer ${userReducer.token}`},
			{id: data.pk},
			[kebabCase(url)],
			this.successRequest,
			this.failedRequest,
			undefined,
			true
		);
	}

	successRequest=(response)=>{
		this.setState({
			firstoriginData: response.data.firstcontent,
			lastoriginData: response.data.lastcontent,
			isLoading: false
		})
	}

	failedRequest=(error)=>{
		message.error('statistic Api still underconstruction');
	}

  render() {
    const {data, list} = this.props
		const {firstoriginData, isLoading, lastoriginData} = this.state
    const visitData = [];
    const beginDay = new Date().getTime();
    for (let i = 0; i < 20; i += 1) {
      visitData.push({
        x: moment(new Date(beginDay + 1000 * 60 * 60 * 24 * i)).format('YYYY-MM-DD'),
        y: Math.floor(Math.random() * 100) + 10,
      });
    }
    const tags = [];
    for (let info of list) {
      tags.push({
        name: info.title,
        value: info.order,
      });
    }

    return(
    	!isLoading ?
      <Row>
        {ComponentHelper.statisticFirstContent(firstoriginData.type, firstoriginData.data, tags, data)}
        {ComponentHelper.statisticLastContent(lastoriginData.type, lastoriginData.data, visitData)}
      </Row> :
			<div style={{ paddingTop: 100, textAlign: "center" }}>
				<Spin
					tip="Loading data..."
				/>
			</div>
    )
  }
}
Statistic.propTypes = {
  data: PropTypes.object,
  list: PropTypes.array,
  url: PropTypes.string
};
Statistic.defaultProps = {
  url: "products",
  list: [{name: "product"}],
  data: {
    created_by: "onehub",
    modified_by: "admin"
  }
}
export default Statistic;
