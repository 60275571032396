export default [
  {
    title: 'Extra Charge',
    type: 'SelectQuery',
    dataIndex: 'extra_charge',
    data:'extraCharges',
    fieldKey: 'extra_charges',
    additional: 'edit|add',
    selectedKey: 'pk',
    selectedValue: 'name',
    order: 0,
    disableChange: true,
  },
  {
    title: 'Size',
    type: 'SelectQuery',
    dataIndex: 'size',
    data:'extraChargeSizes',
    fieldKey: 'size',
    additional: 'edit|add',
    initialValue: '',
    selectedKey: 'pk',
    selectedValue: 'name',
    order: 0,
    view: true,
    required: true
  },
  {
    title: 'Print Price',
    type: 'number',
    dataIndex: 'print_price',
    fieldKey: 'print_price',
    order: 1,
    view: true,
  },
  {
    title: 'Scan Price',
    type: 'number',
    dataIndex: 'scan_price',
    fieldKey: 'scan_price',
    order: 2,
    view: true,
  },
  {
    title: 'Both Price',
    type: 'number',
    dataIndex: 'both_price',
    fieldKey: 'both_price',
    order: 3,
    view: true,
  },

];
