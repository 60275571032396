function splitMulti(str, tokens) {
	var tempChar = tokens[0];
	for (var i = 1; i < tokens.length; i++) {
		str = str.split(tokens[i]).join(tempChar);
	}

	str = str.split(tempChar);
	return str;
}

function getSudomain() {
	let host = window.location.host;
	let split = splitMulti(host, [".", ":"]);
	return split[0];
}

const isLocalhost = Boolean(
	window.location.hostname === "localhost" ||
		// [::1] is the IPv6 localhost address.
		window.location.hostname === "[::1]" ||
		// 127.0.0.1/8 is considered localhost for IPv4.
		window.location.hostname.match(
			/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
		)
);

export default {
	isDevMode: process.env.NODE_ENV === "development",
	isOnLocal: isLocalhost,
	isOnDev: getSudomain() === "newibes-master",
	isOnDemo: getSudomain() === "newibes-master"
};