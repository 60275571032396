import getS3Url from './getS3Url';

export default async function open(fileSubId, s3Opts) {
  return new Promise((resolve, reject) => {
    getS3Url(fileSubId, s3Opts)
      .then(res => {
        window.open(res.url)
        resolve(res)
      })
      .catch(reject)
  })
}