import { BaseList, BaseDetail } from "App/Component/Bases";

import fields from "./fields";

const title = "Merchant Mapping Modes";
const urlData = "merchantMappingModes";
const bulkName = "mode";

class List extends BaseList {
  title = title;
  urlData = urlData;
  bulkName = bulkName;
}

class Detail extends BaseDetail {
  title = title;
  urlData = urlData;
  bulkName = bulkName;
  fields = fields;
}

export default { List, Detail };