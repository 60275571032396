import React, { Component } from "react";
import { Lib, RequestV2 as Request } from "App/Utils";
import { Collapse, List, Button, Icon, Avatar,Row, Spin,message } from "antd";

class LineTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Line: [],
      isLoading:false,
      dataId:0
    };
  }

  loadLine = (e) => {
    this.setState({ isLoading: true, dataId:e}, () => {
      Request(
        "get",
        "lineTemplates-read",
        {},
        { limit: 100, subsection: e.pk, status: "active" },
        [],
        this.loadLineSuccess,
        this.loadLineFailed
      );
    });
  };

  loadLineSuccess = (response) => {
    this.setState({ Line: response.data.results, isLoading:false,buttonReaload:false });
  };

  loadLineFailed = (error) => {
    console.log(error);
    this.setState({ isLoading:false, buttonReaload:true})
    message.error('there is an error in the system');
  };

  render() {
    return this.state.isLoading === true ? (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{ marginTop: 0, height: "100%" }}
      >
        <Spin
          size="large"
          tip="Loading..."
          style={{ width:"100%"}}
          indicator={<Icon spin type="loading" style={{ fontSize: "4rem"}} />}
        />
      </Row>
    ) : (
      this.state.buttonReaload === true ?(
        <Row
        type="flex"
        justify="center"
        align="middle"
        style={{ marginTop: 0, backgroundColor: "#fafafa", height: "200px" }}
      >
        <Button type="dashed" onClick={() => this.loadSubsection()}>
          Refresh
        </Button>
        </Row>
      ):(
      <List
        size="small"
        bordered
        style={{ borderRadius: "0px", minHeight:"100%" }}
        className="item avatarlist"
        dataSource={this.state.Line}
        rowKey="pk"
        renderItem={(item) => (
          <List.Item className="butn">
            <List.Item.Meta
              // avatar={<span class="dot"></span>}
              title={
                <div
                  id="divList"
                  onClick={() => this.props.onLineClick(item)}
                  // style={
                  //   this.props.hold === item.pk
                  //     ? { backgroundColor: "#E3E3E3" }
                  //     : null
                  // }
                >
                  <span style={{marginLeft:"13px"}} className="dot"></span>
                <Button
                  className={this.props.classs ? "buttonTrue" : "buttonFalse"}
                  type="link"
                  // onClick={() => this.props.onLineClick(item)}
                >
                  {item.label}
                </Button>
                {this.props.checkTab !== null &&
                 this.props.checkTab.data.output_content_type !== null &&
                  this.props.checkTab.label &&
                    this.props.checkTab.label === item.label && (
                      <h5 style={{float: "right",
                        fontSize: "10px",
                        marginRight: "12px",
                        color: "#1890ff",
                      }}>open</h5>
                    )}
                </div>
              }
            />
          </List.Item>
        )}
      />
      )
    );
  }
}
export default LineTemplate;
