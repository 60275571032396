export default {
  app_label: 'erf',
  model_name: 'erftype',
  pagination: true,
  columns: [
    {
      name: 'name',
      label: 'Name',
      data_type: 'str',
      ellipsis: true,
      search: true,
      sort: true,
    },
    {
      name: 'label',
      label: 'Label',
      data_type: 'str',
      ellipsis: true,
      search: true,
      sort: true,
    },
  ],
  filter_by: [],
  export_import: null,
}