export default [
  {
    title: 'Session Type',
    type: 'select',
    required: true,
    initialValue: 'import',
    disableAllowClear: true,
    data: [
      {
        alias: 'import',
        value: 'Import',
      },
      // {
      //   alias: 'export',
      //   value: 'Export',
      // },
    ],
    dataIndex: 'session_type',
  }, {
    title: 'Content Type',
    type: 'SelectQuery',
    required: true,
    data: 'contentTypes',
    dataIndex: 'content_type',
    initialValue: null,
    selectedKey: 'pk',
    selectedValue: 'label',
  }, {
    title: 'Overwrite existing',
    type: 'checkbox',
    data: '',
    dataIndex: 'overwrite_existing',
    initialValue: false,
  //   // note_text: 'Only for imports.',
  // }, {
  //   title: 'Activate items',
  //   type: 'checkbox',
  //   data: '',
  //   dataIndex: 'activate_imported_items',
  //   initialValue: false,
  //   // note_text: 'Whether to make imported items available to all users right away. The default is to make them available only to certain people with privileges.',
  }, {
    title: 'Description',
    type: 'text',
    data: '',
    dataIndex: 'description',
    initialValue: '',
    note_text: '',
    // maxLength: 15,
  }, {
    // required: true,
    title: 'File',
    type: 'file',
    dataIndex: 'file_source',
    accept: '.csv,.xls,.xlsx',
    initialValue: [],
  }
]
