const module = 'admin'
const app = 'geo'

export default {
  'api:admin:geo:country:list': `/${module}/${app}/countries`,
  'api:admin:geo:country:detail': `/${module}/${app}/countries/:id`,
  'api:admin:geo:province:list': `/${module}/${app}/provinces`,
  'api:admin:geo:province:detail': `/${module}/${app}/provinces/:id`,
  'api:admin:geo:city:list': `/${module}/${app}/cities`,
  'api:admin:geo:city:detail': `/${module}/${app}/cities/:id`,
}