const module = 'admin'
const app = 'multisite'

export default {
  'api:admin:multisite:branch:list': `/${module}/${app}/branches`,
  'api:admin:multisite:branch:detail': `/${module}/${app}/branches/:id`,

  'api:admin:billing:branchrating:list': `/${module}/${app}/branch-ratings`,
  'api:admin:billing:branchrating:detail': `/${module}/${app}/branch-ratings/:id`,

  'api:admin:multisite:pendingmonitorreport:list': `/${module}/${app}/pending-monitor-reports`,
  'api:admin:multisite:pendingmonitorreport:detail': `/${module}/${app}/pending-monitor-reports/:id`,

  'api:admin:multisite:server:list': `/${module}/${app}/servers`,
  'api:admin:multisite:server:detail': `/${module}/${app}/servers/:id`,
}