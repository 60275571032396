export default {
  "bluebeamMappings-read": "api/admin/estimation/bluebeam-mappings/",
  "bluebeamMappings-create": "api/admin/estimation/bluebeam-mappings/",
  "bluebeamMappings-delete": "api/admin/estimation/bluebeam-mappings/{}/",
  "bluebeamMappings-update": "api/admin/estimation/bluebeam-mappings/{}/",
  "bluebeamMappings-detail": "api/admin/estimation/bluebeam-mappings/{}/",
  
  "mappingVariants-read": "api/admin/estimation/mapping-variants/",
  "mappingVariants-create": "api/admin/estimation/mapping-variants/",
  "mappingVariants-delete": "api/admin/estimation/mapping-variants/{}/",
  "mappingVariants-update": "api/admin/estimation/mapping-variants/{}/",
  "mappingVariants-detail": "api/admin/estimation/mapping-variants/{}/",

  "exportImportSessions-read": "api/admin/estimation/export-import-sessions/",
  "exportImportSessions-create": "api/admin/estimation/export-import-sessions/",
  "exportImportSessions-delete": "api/admin/estimation/export-import-sessions/{}/",
  "exportImportSessions-update": "api/admin/estimation/export-import-sessions/{}/",
  "exportImportSessions-detail": "api/admin/estimation/export-import-sessions/{}/",
  "exportImportSessions-activateItems": "api/admin/estimation/export-import-sessions/{}/activate-items/",

  "exportImportSessionsFile-delete": "api/admin/estimation/export-import-sessions/{}/file/",

  "contentTypes-autocomplete": "api/admin/exim/content-types/autocomplete/",

  "exportMappings-read": "api/admin/estimation/export-mappings/",
  "exportMappings-create": "api/admin/estimation/export-mappings/",
  "exportMappings-delete": "api/admin/estimation/export-mappings/{}/",
  "exportMappings-update": "api/admin/estimation/export-mappings/{}/",
  "exportMappings-detail": "api/admin/estimation/export-mappings/{}/",
  "exportMappings-autocomplete": "api/admin/estimation/export-mappings/autocomplete/",
  "exportMappings-detail-revision-version": "api/admin/estimation/export-mappings/{}/revision/{}/",

  "exportMappingLines-read": "api/admin/estimation/export-mapping-lines/",
  "exportMappingLines-create": "api/admin/estimation/export-mapping-lines/",
  "exportMappingLines-create-bulk": "api/admin/estimation/export-mapping-lines/bulk/",
  "exportMappingLines-delete": "api/admin/estimation/export-mapping-lines/{}/",
  "exportMappingLines-update": "api/admin/estimation/export-mapping-lines/{}/",
  "exportMappingLines-detail": "api/admin/estimation/export-mapping-lines/{}/",
  "exportMappingLines-autocomplete": "api/admin/estimation/export-mapping-lines/autocomplete/",
  "exportMappingLines-detail-revision-version": "api/admin/estimation/export-mapping-lines/{}/revision/{}/",

  "units-read": "api/admin/estimation/units/",
  "units-create": "api/admin/estimation/units/",
  "units-delete": "api/admin/estimation/units/{}/",
  "units-update": "api/admin/estimation/units/{}/",
  "units-detail": "api/admin/estimation/units/{}/",
  "units-autocomplete": "api/admin/estimation/units/autocomplete/",
  "units-detail-revision-version": "api/admin/estimation/units/{}/revision/{}/",

  "templateSets-read": "api/admin/estimation/template-sets/",
  "templateSets-create": "api/admin/estimation/template-sets/",
  "templateSets-delete": "api/admin/estimation/template-sets/{}/",
  "templateSets-update": "api/admin/estimation/template-sets/{}/",
  "templateSets-detail": "api/admin/estimation/template-sets/{}/",
  "templateSets-autocomplete": "api/admin/estimation/template-sets/autocomplete/",
  "templateSets-detail-revision-version": "api/admin/estimation/template-sets/{}/revision/{}/",

  "products-read": "api/admin/estimation/products/",
  "products-create": "api/admin/estimation/products/",
  "products-create-bulk": "api/admin/estimation/products/bulk/",
  "products-delete": "api/admin/estimation/products/{}/",
  "products-update": "api/admin/estimation/products/{}/",
  "products-detail": "api/admin/estimation/products/{}/",
  "products-autocomplete": "api/admin/estimation/products/autocomplete/",
  "products-detail-revision-version": "api/admin/estimation/products/{}/revision/{}/",

  "productsImages-read": "api/admin/estimation/products/{}/images/",
  "productsImages-create": "api/admin/estimation/products/{}/images/",
  "productsImages-delete": "api/admin/estimation/products/{}/images/{}/",
  "productsImages-asThumbnail": "api/admin/estimation/products/{}/images/{}/as-thumbnail/",

  "productsVideos-read": "api/admin/estimation/products/{}/videos/",
  "productsVideos-create": "api/admin/estimation/products/{}/videos/",
  "productsVideos-delete": "api/admin/estimation/products/{}/videos/{}/",

  "productsDocuments-read": "api/admin/estimation/products/{}/documents/",
  "productsDocuments-create": "api/admin/estimation/products/{}/documents/",
  "productsDocuments-delete": "api/admin/estimation/products/{}/documents/{}/",

  "productsNotes-read": "api/admin/estimation/products/{}/notes/",
  "productsNotes-create": "api/admin/estimation/products/{}/notes/",
  "productsNotes-delete": "api/admin/estimation/products/{}/notes/{}/",

  "productGroups-read": "api/admin/estimation/product-groups/",
  "productGroups-create": "api/admin/estimation/product-groups/",
  "productGroups-delete": "api/admin/estimation/product-groups/{}/",
  "productGroups-update": "api/admin/estimation/product-groups/{}/",
  "productGroups-detail": "api/admin/estimation/product-groups/{}/",
  "productGroups-autocomplete": "api/admin/estimation/product-groups/autocomplete/",
  "productGroups-detail-revision-version": "api/admin/estimation/product-groups/{}/revision/{}/",

  "subheadings-read": "api/admin/estimation/subheadings/",
  "subheadings-create": "api/admin/estimation/subheadings/",
  "subheadings-delete": "api/admin/estimation/subheadings/{}/",
  "subheadings-update": "api/admin/estimation/subheadings/{}/",
  "subheadings-detail": "api/admin/estimation/subheadings/{}/",
  "subheadings-autocomplete": "api/admin/estimation/subheadings/autocomplete/",
  "subheadings-detail-revision-version": "api/admin/estimation/subheadings/{}/revision/{}/",

  "formulas-read": "api/admin/estimation/formulas/",
  "formulas-create": "api/admin/estimation/formulas/",
  "formulas-delete": "api/admin/estimation/formulas/{}/",
  "formulas-update": "api/admin/estimation/formulas/{}/",
  "formulas-detail": "api/admin/estimation/formulas/{}/",
  "formulas-autocomplete": "api/admin/estimation/formulas/autocomplete/",
  "estimation-admin:formulas-autocomplete": "api/admin/estimation/formulas/autocomplete/",
  "formulas-detail-revision-version": "api/admin/estimation/formulas/{}/revision/{}/",

  "pitchFactors-read": "api/admin/estimation/pitch-factors/",
  "pitchFactors-create": "api/admin/estimation/pitch-factors/",
  "pitchFactors-delete": "api/admin/estimation/pitch-factors/{}/",
  "pitchFactors-update": "api/admin/estimation/pitch-factors/{}/",
  "pitchFactors-detail": "api/admin/estimation/pitch-factors/{}/",
  "pitchFactors-autocomplete": "api/admin/estimation/pitch-factors/autocomplete/",
  "pitchFactors-detail-revision-version": "api/admin/estimation/pitch-factors/{}/revision/{}/",

  "sections-read": "api/admin/estimation/sections/",
  "sections-create": "api/admin/estimation/sections/",
  "sections-delete": "api/admin/estimation/sections/{}/",
  "sections-update": "api/admin/estimation/sections/{}/",
  "sections-detail": "api/admin/estimation/sections/{}/",
  "sections-autocomplete": "api/admin/estimation/sections/autocomplete/",
  "sections-detail-revision-version": "api/admin/estimation/sections/{}/revision/{}/",

  "subsections-read":{url :"api/admin/estimation/subsections/", version:3},
  "subsections-create":{url :"api/admin/estimation/subsections/", version:3},
  "subsections-create-bulk":{url :"api/admin/estimation/subsections/bulk/", version:3},
  "subsections-delete":{url :"api/admin/estimation/subsections/{}/", version:3},
  "subsections-update":{url :"api/admin/estimation/subsections/{}/", version:3},
  "subsections-detail":{url :"api/admin/estimation/subsections/{}/", version:3},
  "subsections-autocomplete": "api/admin/estimation/subsections/autocomplete/",
  "subsections-detail-revision-version": "api/admin/estimation/subsections/{}/revision/{}/",
  "expert-system-comparer":{url :"api/expert-system/expert-system/rules/comparer/", version:3},
  "expert-system-retrive-rule":{url :"api/expert-system/expert-system/rules/{}/", version:3},
  "expert-system-retrive-fact":{url :"api/expert-system/expert-system/facts/{}/", version:3},
  "expert-system-rules":{url :"api/expert-system/expert-system/rules/{}/{}/", version:3},
  "expert-system-premise-key":{url :"api/expert-system/expert-system/rules/autocomplete/", version:3},
  "expert-system-content-type-autocomplete":{url :"api/expert-system/expert-system/content-types/autocomplete/", version:3},
  "expert-system-content-object-autocomplete":{url :"api/expert-system/expert-system/content-types/{}/records/", version:3},
  "expert-system-premise-save":{url :"api/expert-system/expert-system/rules/", version:3},
  "expert-system-premise-edit":{url :"api/expert-system/expert-system/rules/{}/", version:3},
  "expert-system-add-fact":{url :"api/expert-system/expert-system/facts/", version:3},
  "expert-system-edit-fact":{url :"api/expert-system/expert-system/facts/{}/", version:3},
  "expert-system-delete-rule":{url :"api/expert-system/expert-system/rules/{}/", version:3},
  "expert-system-delete-fact":{url :"api/expert-system/expert-system/facts/{}/", version:3},
  // "expert-system-rules":"api/expert-system/expert-system/rules/{}/{}/",
  "lineUsages-read": "api/admin/estimation/line-usages/",
  "lineUsages-create": "api/admin/estimation/line-usages/",
  "lineUsages-delete": "api/admin/estimation/line-usages/{}/",
  "lineUsages-update": "api/admin/estimation/line-usages/{}/",
  "lineUsages-detail": "api/admin/estimation/line-usages/{}/",
  "lineUsages-autocomplete": "api/admin/estimation/line-usages/autocomplete/",
  "lineUsages-detail-revision-version": "api/admin/estimation/line-usages/{}/revision/{}/",

  "lineTemplates-read":{url :"api/admin/estimation/line-templates/", version:3},
  "lineTemplates-create":{url :"api/admin/estimation/line-templates/", version:3},
  "lineTemplates-delete":{url :"api/admin/estimation/line-templates/{}/", version:3},
  "lineTemplates-update":{url :"api/admin/estimation/line-templates/{}/", version:3},
  "lineTemplates-detail":{url :"api/admin/estimation/line-templates/{}/", version:3},
  "lineTemplates-autocomplete": "api/admin/estimation/line-templates/autocomplete/",
  "lineTemplates-detail-revision-version": "api/admin/estimation/line-templates/{}/revision/{}/",
  "dimensions-autocomplete": "api/admin/estimation/dimensions/",

  "prefillSets-read": "api/admin/estimation/prefill-sets/",
  "prefillSets-create": "api/admin/estimation/prefill-sets/",
  "prefillSets-create-bulk": "api/admin/estimation/prefill-sets/bulk/",
  "prefillSets-delete": "api/admin/estimation/prefill-sets/{}/",
  "prefillSets-update": "api/admin/estimation/prefill-sets/{}/",
  "prefillSets-detail": "api/admin/estimation/prefill-sets/{}/",
  "prefillSets-autocomplete": "api/admin/estimation/prefill-sets/autocomplete/",
  "prefillSets-detail-revision-version": "api/admin/estimation/prefill-sets/{}/revision/{}/",

  "prefillSetGroups-read": "api/admin/estimation/prefill-set-groups/",
  "prefillSetGroups-create": "api/admin/estimation/prefill-set-groups/",
  "prefillSetGroups-create-bulk": "api/admin/estimation/prefill-set-groups/bulk/",
  "prefillSetGroups-delete": "api/admin/estimation/prefill-set-groups/{}/",
  "prefillSetGroups-update": "api/admin/estimation/prefill-set-groups/{}/",
  "prefillSetGroups-detail": "api/admin/estimation/prefill-set-groups/{}/",
  "prefillSetGroups-autocomplete": "api/admin/estimation/prefill-set-groups/autocomplete/",
  "prefillSetGroups-detail-revision-version": "api/admin/estimation/prefill-set-groups/{}/revision/{}/",

  "prefillLines-read": "api/admin/estimation/prefill-lines/",
  "prefillLines-create": "api/admin/estimation/prefill-lines/",
  "prefillLines-create-bulk": "api/admin/estimation/prefill-lines/bulk/",
  "prefillLines-delete": "api/admin/estimation/prefill-lines/{}/",
  "prefillLines-update": "api/admin/estimation/prefill-lines/{}/",
  "prefillLines-detail": "api/admin/estimation/prefill-lines/{}/",
  "prefillLines-autocomplete": "api/admin/estimation/prefill-lines/autocomplete/",
  "prefillLines-detail-revision-version": "api/admin/estimation/prefill-lines/{}/revision/{}/",

  "overrideLines-read": "api/admin/estimation/override-lines/",
  "overrideLines-create": "api/admin/estimation/override-lines/",
  "overrideLines-create-bulk": "api/admin/estimation/override-lines/bulk/",
  "overrideLines-delete": "api/admin/estimation/override-lines/{}/",
  "overrideLines-update": "api/admin/estimation/override-lines/{}/",
  "overrideLines-detail": "api/admin/estimation/override-lines/{}/",
  "overrideLines-autocomplete": "api/admin/estimation/override-lines/autocomplete/",
  "overrideLines-detail-revision-version": "api/admin/estimation/override-lines/{}/revision/{}/",

  "overrideSetGroups-read": "api/admin/estimation/override-set-groups/",
  "overrideSetGroups-create": "api/admin/estimation/override-set-groups/",
  "overrideSetGroups-delete": "api/admin/estimation/override-set-groups/{}/",
  "overrideSetGroups-update": "api/admin/estimation/override-set-groups/{}/",
  "overrideSetGroups-detail": "api/admin/estimation/override-set-groups/{}/",
  "overrideSetGroups-autocomplete": "api/admin/estimation/override-set-groups/autocomplete/",
  "overrideSetGroups-detail-revision-version": "api/admin/estimation/override-set-groups/{}/revision/{}/",

  "overrideSets-read": "api/admin/estimation/override-sets/",
  "overrideSets-create": "api/admin/estimation/override-sets/",
  "overrideSets-create-bulk": "api/admin/estimation/override-sets/bulk/",
  "overrideSets-delete": "api/admin/estimation/override-sets/{}/",
  "overrideSets-update": "api/admin/estimation/override-sets/{}/",
  "overrideSets-detail": "api/admin/estimation/override-sets/{}/",
  "overrideSets-autocomplete": "api/admin/estimation/override-sets/autocomplete/",
  "overrideSets-detail-revision-version": "api/admin/estimation/override-sets/{}/revision/{}/",

  "pmSortCodes-read": "api/admin/estimation/pm-sort-codes/",
  "pmSortCodes-create": "api/admin/estimation/pm-sort-codes/",
  "pmSortCodes-delete": "api/admin/estimation/pm-sort-codes/{}/",
  "pmSortCodes-update": "api/admin/estimation/pm-sort-codes/{}/",
  "pmSortCodes-detail": "api/admin/estimation/pm-sort-codes/{}/",
  "pmSortCodes-autocomplete": "api/admin/estimation/pm-sort-codes/autocomplete/",
  "pmSortCodes-detail-revision-version": "api/admin/estimation/pm-sort-codes/{}/revision/{}/",

  "pmSubheadMappings-read": "api/admin/estimation/pm-subhead-mappings/",
  "pmSubheadMappings-create": "api/admin/estimation/pm-subhead-mappings/",
  "pmSubheadMappings-delete": "api/admin/estimation/pm-subhead-mappings/{}/",
  "pmSubheadMappings-update": "api/admin/estimation/pm-subhead-mappings/{}/",
  "pmSubheadMappings-detail": "api/admin/estimation/pm-subhead-mappings/{}/",
  "pmSubheadMappings-autocomplete": "api/admin/estimation/pm-subhead-mappings/autocomplete/",
  "pmSubheadMappings-detail-revision-version": "api/admin/estimation/pm-subhead-mappings/{}/revision/{}/",

  "pmHardwareStartingRanges-read": "api/admin/estimation/pm-hardware-starting-ranges/",
  "pmHardwareStartingRanges-create": "api/admin/estimation/pm-hardware-starting-ranges/",
  "pmHardwareStartingRanges-delete": "api/admin/estimation/pm-hardware-starting-ranges/{}/",
  "pmHardwareStartingRanges-update": "api/admin/estimation/pm-hardware-starting-ranges/{}/",
  "pmHardwareStartingRanges-detail": "api/admin/estimation/pm-hardware-starting-ranges/{}/",
  "pmHardwareStartingRanges-autocomplete": "api/admin/estimation/pm-hardware-starting-ranges/autocomplete/",
  "pmHardwareStartingRanges-detail-revision-version": "api/admin/estimation/pm-hardware-starting-ranges/{}/revision/{}/",

  "pmTimberStartingRanges-read": "api/admin/estimation/pm-timber-starting-ranges/",
  "pmTimberStartingRanges-create": "api/admin/estimation/pm-timber-starting-ranges/",
  "pmTimberStartingRanges-delete": "api/admin/estimation/pm-timber-starting-ranges/{}/",
  "pmTimberStartingRanges-update": "api/admin/estimation/pm-timber-starting-ranges/{}/",
  "pmTimberStartingRanges-detail": "api/admin/estimation/pm-timber-starting-ranges/{}/",
  "pmTimberStartingRanges-autocomplete": "api/admin/estimation/pm-timber-starting-ranges/autocomplete/",
  "pmTimberStartingRanges-detail-revision-version": "api/admin/estimation/pm-timber-starting-ranges/{}/revision/{}/",

  "subheadMaps-read": "api/admin/estimation/subhead-maps/",
  "subheadMaps-create": "api/admin/estimation/subhead-maps/",
  "subheadMaps-delete": "api/admin/estimation/subhead-maps/{}/",
  "subheadMaps-update": "api/admin/estimation/subhead-maps/{}/",
  "subheadMaps-detail": "api/admin/estimation/subhead-maps/{}/",
  "subheadMaps-autocomplete": "api/admin/estimation/subhead-maps/autocomplete/",
  "subheadMaps-detail-revision-version": "api/admin/estimation/subhead-maps/{}/revision/{}/",

  "randomMappings-read": "api/admin/estimation/random-mappings/",
  "randomMappings-create": "api/admin/estimation/random-mappings/",
  "randomMappings-delete": "api/admin/estimation/random-mappings/{}/",
  "randomMappings-update": "api/admin/estimation/random-mappings/{}/",
  "randomMappings-detail": "api/admin/estimation/random-mappings/{}/",
  "randomMappings-autocomplete": "api/admin/estimation/random-mappings/autocomplete/",
  "api:admin:estimation:exportimportsession:import": "api/admin/estimation/export-import-sessions/import/",
  "randomMappings-detail-revision-version": "api/admin/estimation/random-mappings/{}/revision/{}/",
  // "import-data": "api/admin/estimation/export-import-sessions/import/",






  "api:admin:estimation:bluebeammapping:list": "api/admin/estimation/bluebeam-mappings/",
  "api:admin:estimation:bluebeammapping:detail": "api/admin/estimation/bluebeam-mappings/{}/",
  "api:admin:estimation:bluebeammapping:export": "api/admin/estimation/bluebeam-mappings/export/",

  "api:admin:estimation:exportimportsession:list": "api/admin/estimation/export-import-sessions/",
  "api:admin:estimation:exportimportsession:detail": "api/admin/estimation/export-import-sessions/{}/",
  "api:admin:estimation:exportimportsession:autocomplete": "api/admin/estimation/export-import-sessions/autocomplete/",
  "api:admin:estimation:exportimportsession:export": "api/admin/estimation/export-import-sessions/export/",
  "api:admin:estimation:contenttype:autocomplete": "api/admin/exim/content-types/autocomplete/",

  "api:admin:exim:session:list": { url: "api/admin/exim/sessions/", version: 3 },
  "api:admin:exim:session:detail": { url: "api/admin/exim/sessions/{}/", version: 3 },
  "api:admin:exim:session:autocomplete": { url: "api/admin/exim/sessions/autocomplete/", version: 3 },
  "api:admin:exim:session:import": { url: "api/admin/exim/sessions/import/", version: 3 },
  "api:admin:exim:session:export": { url: "api/admin/exim/sessions/export/", version: 3 },
  "api:admin:exim:contenttype:autocomplete": { url: "api/admin/exim/content-types/autocomplete/", version: 3 },
  "api:admin:exim:session:ref-histories-list": { url: "api/admin/exim/sessions/{}/histories/", version: 3 },
  "api:admin:exim:session:ref-import-queues-list": { url: "api/admin/exim/sessions/{}/import_queues/", version: 3 },
  "registerQueue": { url: "api/admin/exim/sessions/{}/register_queue/", version: 3 },

  "api:admin:exim:importqueue:detail": { url: "api/admin/exim/import-queues/{}/", version: 3 },
  "api:admin:exim:importqueue:ref-histories-list": { url: "api/admin/exim/import-queues/{}/histories/", version: 3 },
  "api:admin:exim:importqueue:ref-errors-list": { url: "api/admin/exim/import-queues/{}/errors/", version: 3 },
  "api:admin:exim:importqueue:ref-errors-detail": { url: "api/admin/exim/import-queues/{}/errors/{}/", version: 3 },
  "api:admin:exim:importqueue:ref-imported-datas-list": { url: "api/admin/exim/import-queues/{}/imported_datas/", version: 3 },
  "api:admin:exim:importqueue:ref-imported-datas-detail": { url: "api/admin/exim/import-queues/{}/imported_datas/{}/", version: 3 },

  "api:admin:estimation:exportmappingline:list": "api/admin/estimation/export-mapping-lines/",
  "api:admin:estimation:exportmappingline:detail": "api/admin/estimation/export-mapping-lines/{}/",
  "api:admin:estimation:exportmappingline:detail-revision": "api/admin/estimation/export-mapping-lines/{}/revision/",
  "api:admin:estimation:exportmappingline:detail-revision-version": "api/admin/estimation/export-mapping-lines/{}/revision/{}/",
  "api:admin:estimation:exportmappingline:autocomplete": "api/admin/estimation/export-mapping-lines/autocomplete/",
  "api:admin:estimation:exportmappingline:export": "api/admin/estimation/export-mapping-lines/export/",

  "api:admin:estimation:exportmapping:list": "api/admin/estimation/export-mappings/",
  "api:admin:estimation:exportmapping:detail": "api/admin/estimation/export-mappings/{}/",
  "api:admin:estimation:exportmapping:detail-revision": "api/admin/estimation/export-mappings/{}/revision/",
  "api:admin:estimation:exportmapping:detail-revision-version": "api/admin/estimation/export-mappings/{}/revision/{}/",
  "api:admin:estimation:exportmapping:autocomplete": "api/admin/estimation/export-mappings/autocomplete/",
  "api:admin:estimation:exportmapping:export": "api/admin/estimation/export-mappings/export/",

  "api:admin:estimation:formula:list": "api/admin/estimation/formulas/",
  "api:admin:estimation:formula:detail": "api/admin/estimation/formulas/{}/",
  "api:admin:estimation:formula:detail-revision": "api/admin/estimation/formulas/{}/revision/",
  "api:admin:estimation:formula:history-detail": "api/admin/estimation/formulas/{}/revision/{}/",
  "api:admin:estimation:formula:autocomplete": "api/admin/estimation/formulas/autocomplete/",
  "api:admin:estimation:formula:export": "api/admin/estimation/formulas/export/",

  "api:admin:estimation:linetemplate:list":{url :"api/admin/estimation/line-templates/", version:3},
  "api:admin:estimation:test:list": "api/admin/estimation/line-templates/",
  "api:admin:estimation:linetemplate:detail":{url :"api/admin/estimation/line-templates/{}/", version:3},
  "api:admin:estimation:linetemplate:detail-revision": "api/admin/estimation/line-templates/{}/revision/",
  "api:admin:estimation:linetemplate:autocomplete": "api/admin/estimation/line-templates/autocomplete/",
  "api:admin:estimation:linetemplate:export": { url: "api/admin/estimation/line-templates/export/", version: 3 },

  "api:admin:estimation:lineusage:list": "api/admin/estimation/line-usages/",
  "api:admin:estimation:lineusage:detail": "api/admin/estimation/line-usages/{}/",
  "api:admin:estimation:lineusage:detail-revision": "api/admin/estimation/line-usages/{}/revision/",
  "api:admin:estimation:lineusage:autocomplete": "api/admin/estimation/line-usages/autocomplete/",
  "api:admin:estimation:lineusage:export": "api/admin/estimation/line-usages/export/",

  "api:admin:estimation:overrideline:list": "api/admin/estimation/override-lines/",
  "api:admin:estimation:overrideline:detail": "api/admin/estimation/override-lines/{}/",
  "api:admin:estimation:overrideline:detail-revision": "api/admin/estimation/override-lines/{}/revision/",
  "api:admin:estimation:overrideline:autocomplete": "api/admin/estimation/override-lines/autocomplete/",
  "api:admin:estimation:overrideline:export": "api/admin/estimation/override-lines/export/",

  "api:admin:estimation:overridesetgroup:list": "api/admin/estimation/override-set-groups/",
  "api:admin:estimation:overridesetgroup:detail": "api/admin/estimation/override-set-groups/{}/",
  "api:admin:estimation:overridesetgroup:detail-revision": "api/admin/estimation/override-set-groups/{}/revision/",
  "api:admin:estimation:overridesetgroup:autocomplete": "api/admin/estimation/override-set-groups/autocomplete/",
  "api:admin:estimation:overridesetgroup:export": "api/admin/estimation/override-set-groups/export/",
  "api:admin:estimation:overridesetgroup:manage-order": "api/admin/estimation/override-set-groups/manage-order/",

  "api:admin:estimation:overrideset:list": "api/admin/estimation/override-sets/",
  "api:admin:estimation:overrideset:detail": "api/admin/estimation/override-sets/{}/",
  "api:admin:estimation:overrideset:detail-revision": "api/admin/estimation/override-sets/{}/revision/",
  "api:admin:estimation:overrideset:autocomplete": "api/admin/estimation/override-sets/autocomplete/",
  "api:admin:estimation:overrideset:export": "api/admin/estimation/override-sets/export/",
  "api:admin:estimation:overrideset:manage-order": "api/admin/estimation/override-sets/manage-order/",

  "api:admin:estimation:pitchfactor:list": "api/admin/estimation/pitch-factors/",
  "api:admin:estimation:pitchfactor:detail": "api/admin/estimation/pitch-factors/{}/",
  "api:admin:estimation:pitchfactor:detail-revision": "api/admin/estimation/pitch-factors/{}/revision/",
  "api:admin:estimation:pitchfactor:autocomplete": "api/admin/estimation/pitch-factors/autocomplete/",
  "api:admin:estimation:pitchfactor:export": "api/admin/estimation/pitch-factors/export/",

  "api:admin:estimation:pmhardwarestartingrange:list": "api/admin/estimation/pm-hardware-starting-ranges/",
  "api:admin:estimation:pmhardwarestartingrange:detail": "api/admin/estimation/pm-hardware-starting-ranges/{}/",
  "api:admin:estimation:pmhardwarestartingrange:detail-revision": "api/admin/estimation/pm-hardware-starting-ranges/{}/revision/",
  "api:admin:estimation:pmhardwarestartingrange:autocomplete": "api/admin/estimation/pm-hardware-starting-ranges/autocomplete/",
  "api:admin:estimation:pmhardwarestartingrange:export": "api/admin/estimation/pm-hardware-starting-ranges/export/",

  "api:admin:estimation:pmsortcode:list": "api/admin/estimation/pm-sort-codes/",
  "api:admin:estimation:pmsortcode:detail": "api/admin/estimation/pm-sort-codes/{}/",
  "api:admin:estimation:pmsortcode:detail-revision": "api/admin/estimation/pm-sort-codes/{}/revision/",
  "api:admin:estimation:pmsortcode:autocomplete": "api/admin/estimation/pm-sort-codes/autocomplete/",
  "api:admin:estimation:pmsortcode:export": "api/admin/estimation/pm-sort-codes/export/",

  "api:admin:estimation:pmsubheadmapping:list": "api/admin/estimation/pm-subhead-mappings/",
  "api:admin:estimation:pmsubheadmapping:detail": "api/admin/estimation/pm-subhead-mappings/{}/",
  "api:admin:estimation:pmsubheadmapping:detail-revision": "api/admin/estimation/pm-subhead-mappings/{}/revision/",
  "api:admin:estimation:pmsubheadmapping:autocomplete": "api/admin/estimation/pm-subhead-mappings/autocomplete/",
  "api:admin:estimation:pmsubheadmapping:export": "api/admin/estimation/pm-subhead-mappings/export/",

  "api:admin:estimation:pmtimberstartingrange:list": "api/admin/estimation/pm-timber-starting-ranges/",
  "api:admin:estimation:pmtimberstartingrange:detail": "api/admin/estimation/pm-timber-starting-ranges/{}/",
  "api:admin:estimation:pmtimberstartingrange:detail-revision": "api/admin/estimation/pm-timber-starting-ranges/{}/revision/",
  "api:admin:estimation:pmtimberstartingrange:autocomplete": "api/admin/estimation/pm-timber-starting-ranges/autocomplete/",
  "api:admin:estimation:pmtimberstartingrange:export": "api/admin/estimation/pm-timber-starting-ranges/export/",

  "api:admin:estimation:prefillline:list": "api/admin/estimation/prefill-lines/",
  "api:admin:estimation:prefillline:detail": "api/admin/estimation/prefill-lines/{}/",
  "api:admin:estimation:prefillline:detail-revision": "api/admin/estimation/prefill-lines/{}/revision/",
  "api:admin:estimation:prefillline:autocomplete": "api/admin/estimation/prefill-lines/autocomplete/",
  "api:admin:estimation:prefillline:export": "api/admin/estimation/prefill-lines/export/",

  "api:admin:estimation:prefillsetgroup:list": "api/admin/estimation/prefill-set-groups/",
  "api:admin:estimation:prefillsetgroup:detail": "api/admin/estimation/prefill-set-groups/{}/",
  "api:admin:estimation:prefillsetgroup:detail-revision": "api/admin/estimation/prefill-set-groups/{}/revision/",
  "api:admin:estimation:prefillsetgroup:autocomplete": "api/admin/estimation/prefill-set-groups/autocomplete/",
  "api:admin:estimation:prefillsetgroup:export": "api/admin/estimation/prefill-set-groups/export/",
  "api:admin:estimation:prefillsetgroup:manage-order": "api/admin/estimation/prefill-set-groups/manage-order/",

  "api:admin:estimation:prefillset:list": "api/admin/estimation/prefill-sets/",
  "api:admin:estimation:prefillset:detail": "api/admin/estimation/prefill-sets/{}/",
  "api:admin:estimation:prefillset:detail-revision": "api/admin/estimation/prefill-sets/{}/revision/",
  "api:admin:estimation:prefillset:autocomplete": "api/admin/estimation/prefill-sets/autocomplete/",
  "api:admin:estimation:prefillset:export": "api/admin/estimation/prefill-sets/export/",
  "api:admin:estimation:prefillset:bulk-action": "api/admin/estimation/prefill-sets/bulk-action/",
  "api:admin:estimation:prefillset:manage-order": "api/admin/estimation/prefill-sets/manage-order/",

  "api:admin:estimation:productgroup:list": "api/admin/estimation/product-groups/",
  "api:admin:estimation:productgroup:detail": "api/admin/estimation/product-groups/{}/",
  "api:admin:estimation:productgroup:detail-revision": "api/admin/estimation/product-groups/{}/revision/",
  "api:admin:estimation:productgroup:autocomplete": "api/admin/estimation/product-groups/autocomplete/",
  "api:admin:estimation:productgroup:export": "api/admin/estimation/product-groups/export/",

  "api:admin:estimation:product:list": "api/admin/estimation/products/",
  "api:admin:estimation:product:detail": "api/admin/estimation/products/{}/",
  "api:admin:estimation:product:detail-revision": "api/admin/estimation/products/{}/revision/",
  "api:admin:estimation:product:autocomplete": "api/admin/estimation/products/autocomplete/",
  "api:admin:estimation:product:export": "api/admin/estimation/products/export/",

  "api:admin:estimation:randommapping:list": "api/admin/estimation/random-mappings/",
  "api:admin:estimation:randommapping:detail": "api/admin/estimation/random-mappings/{}/",
  "api:admin:estimation:randommapping:detail-revision": "api/admin/estimation/random-mappings/{}/revision/",
  "api:admin:estimation:randommapping:autocomplete": "api/admin/estimation/random-mappings/autocomplete/",
  "api:admin:estimation:randommapping:export": "api/admin/estimation/random-mappings/export/",

  "api:admin:estimation:section:list": "api/admin/estimation/sections/",
  "api:admin:estimation:section:detail": "api/admin/estimation/sections/{}/",
  "api:admin:estimation:section:detail-revision": "api/admin/estimation/sections/{}/revision/",
  "api:admin:estimation:section:autocomplete": "api/admin/estimation/sections/autocomplete/",
  "api:admin:estimation:section:export": "api/admin/estimation/sections/export/",

  "api:admin:estimation:subheadmap:list": "api/admin/estimation/subhead-maps/",
  "api:admin:estimation:subheadmap:detail": "api/admin/estimation/subhead-maps/{}/",
  "api:admin:estimation:subheadmap:detail-revision": "api/admin/estimation/subhead-maps/{}/revision/",
  "api:admin:estimation:subheadmap:autocomplete": "api/admin/estimation/subhead-maps/autocomplete/",
  "api:admin:estimation:subheadmap:map-name-autocomplete": "api/admin/estimation/subhead-maps/map-name-autocomplete/",
  "api:admin:estimation:subheadmap:export": "api/admin/estimation/subhead-maps/export/",

  "api:admin:estimation:subheading:list": "api/admin/estimation/subheadings/",
  "api:admin:estimation:subheading:detail": "api/admin/estimation/subheadings/{}/",
  "api:admin:estimation:subheading:detail-revision": "api/admin/estimation/subheadings/{}/revision/",
  "api:admin:estimation:subheading:autocomplete": "api/admin/estimation/subheadings/autocomplete/",
  "api:admin:estimation:subheading:export": "api/admin/estimation/subheadings/export/",

  "api:admin:estimation:subsection:list":{url :"api/admin/estimation/subsections/", version:3},
  "api:admin:estimation:subsection:detail":{url :"api/admin/estimation/subsections/{}/", version:3},
  "api:admin:estimation:subsection:detail-revision": "api/admin/estimation/subsections/{}/revision/",
  "api:admin:estimation:subsection:autocomplete": "api/admin/estimation/subsections/autocomplete/",
  "api:admin:estimation:subsection:export": { url: "api/admin/estimation/subsections/export/", version: 3 },

  "api:admin:estimation:templateset:list": "api/admin/estimation/template-sets/",
  "api:admin:estimation:templateset:detail": "api/admin/estimation/template-sets/{}/",
  "api:admin:estimation:templateset:detail-revision": "api/admin/estimation/template-sets/{}/revision/",
  "api:admin:estimation:templateset:autocomplete": "api/admin/estimation/template-sets/autocomplete/",
  "api:admin:estimation:templateset:export": "api/admin/estimation/template-sets/export/",

  "api:admin:estimation:unit:list": "api/admin/estimation/units/",
  "api:admin:estimation:unit:detail": "api/admin/estimation/units/{}/",
  "api:admin:estimation:unit:detail-revision": "api/admin/estimation/units/{}/revision/",
  "api:admin:estimation:unit:autocomplete": "api/admin/estimation/units/autocomplete/",
  "api:admin:estimation:unit:export": "api/admin/estimation/units/export/",

  // ganti reference dash to under_score
  "api:admin:estimation:exportmapping:ref-export-mapping-lines-list": "api/admin/estimation/export-mappings/{}/export_mapping_lines/",
  "api:admin:estimation:exportmapping:ref-export-mapping-lines-detail": "api/admin/estimation/export-mapping-lines/{}/",
  "api:admin:estimation:formula:ref-line-templates-list": "api/admin/estimation/formulas/{}/line_templates/",
  "api:admin:estimation:formula:ref-line-templates-detail": "api/admin/estimation/line-templates/{}/",
  "api:admin:estimation:linetemplate:ref-random-mappings-list": "api/admin/estimation/line-templates/{}/random_mappings/",
  "api:admin:estimation:linetemplate:ref-random-mappings-detail": "api/admin/estimation/random-mappings/{}/",
  "api:admin:estimation:linetemplate:ref-subhead-maps-list": "api/admin/estimation/line-templates/{}/subhead_maps/",
  "api:admin:estimation:linetemplate:ref-subhead-maps-detail": "api/admin/estimation/subhead-maps/{}/",
  "api:admin:estimation:linetemplate:ref-override-lines-list": "api/admin/estimation/line-templates/{}/override_lines/",
  "api:admin:estimation:linetemplate:ref-override-lines-detail": "api/admin/estimation/override-lines/{}/",
  "api:admin:estimation:linetemplate:ref-prefill-lines-list": "api/admin/estimation/line-templates/{}/prefill_lines/",
  "api:admin:estimation:linetemplate:ref-prefill-lines-detail": "api/admin/estimation/prefill-lines/{}/",
  "api:admin:estimation:lineusage:ref-export-mappings-list": "api/admin/estimation/line-usages/{}/export_mappings/",
  "api:admin:estimation:lineusage:ref-export-mappings-detail": "api/admin/estimation/export-mappings/{}/",
  "api:admin:estimation:lineusage:ref-export-mapping-lines-list": "api/admin/estimation/line-usages/{}/export_mapping_lines/",
  "api:admin:estimation:lineusage:ref-export-mapping-lines-detail": "api/admin/estimation/export-mapping-lines/{}/",
  "api:admin:estimation:lineusage:ref-line-templates-list": "api/admin/estimation/line-usages/{}/line_templates/",
  "api:admin:estimation:lineusage:ref-line-templates-detail": "api/admin/estimation/line-templates/{}/",
  "api:admin:estimation:lineusage:ref-pm-sort-codes-list": "api/admin/estimation/line-usages/{}/pm_sort_codes/",
  "api:admin:estimation:lineusage:ref-pm-sort-codes-detail": "api/admin/estimation/pm-sort-codes/{}/",
  "api:admin:estimation:lineusage:ref-override-lines-list": "api/admin/estimation/line-usages/{}/override_lines/",
  "api:admin:estimation:lineusage:ref-override-lines-detail": "api/admin/estimation/override-lines/{}/",
  "api:admin:estimation:lineusage:ref-prefill-lines-list": "api/admin/estimation/line-usages/{}/prefill_lines/",
  "api:admin:estimation:lineusage:ref-prefill-lines-detail": "api/admin/estimation/prefill-lines/{}/",
  "api:admin:estimation:overrideset:ref-override-lines-list": "api/admin/estimation/override-sets/{}/override_lines/",
  "api:admin:estimation:overrideset:ref-override-lines-detail": "api/admin/estimation/override-lines/{}/",
  "api:admin:estimation:overrideset:ref-groups-list": "api/admin/estimation/override-sets/{}/groups/",
  "api:admin:estimation:overrideset:ref-groups-detail": "api/admin/estimation/override-set-groups/{}/",
  "api:admin:estimation:overridesetgroup:ref-members-list": "api/admin/estimation/override-set-groups/{}/members/",
  "api:admin:estimation:overridesetgroup:ref-members-detail": "api/admin/estimation/override-sets/{}/",
  "api:admin:estimation:prefillset:ref-override-sets-list": "api/admin/estimation/prefill-sets/{}/override_sets/",
  "api:admin:estimation:prefillset:ref-override-sets-detail": "api/admin/estimation/override-sets/{}/",
  "api:admin:estimation:prefillset:ref-prefill-lines-list": "api/admin/estimation/prefill-sets/{}/prefill_lines/",
  "api:admin:estimation:prefillset:ref-prefill-lines-detail": "api/admin/estimation/prefill-lines/{}/",
  "api:admin:estimation:prefillset:ref-groups-list": "api/admin/estimation/prefill-sets/{}/groups/",
  "api:admin:estimation:prefillset:ref-groups-detail": "api/admin/estimation/prefill-set-groups/{}/",
  "api:admin:estimation:productgroup:ref-products-list": "api/admin/estimation/product-groups/{}/products/",
  "api:admin:estimation:productgroup:ref-products-detail": "api/admin/estimation/products/{}/",
  "api:admin:estimation:product:ref-groups-list": "api/admin/estimation/products/{}/groups/",
  "api:admin:estimation:product:ref-groups-detail": "api/admin/estimation/product-groups/{}/",
  // "api:admin:estimation:product:ref-notes-list": "api/admin/estimation/products/{}/notes/",
  // "api:admin:estimation:product:ref-images-list": "api/admin/estimation/products/{}/images/",
  // "api:admin:estimation:product:ref-videos-list": "api/admin/estimation/products/{}/videos/",
  // "api:admin:estimation:product:ref-documents-list": "api/admin/estimation/products/{}/documents/",
  "api:admin:estimation:product:ref-prefill-lines-list": "api/admin/estimation/products/{}/prefill_lines/",
  "api:admin:estimation:product:ref-prefill-lines-detail": "api/admin/estimation/prefill-lines/{}/",
  "api:admin:estimation:prefillsetgroup:ref-members-list": "api/admin/estimation/prefill-set-groups/{}/members/",
  "api:admin:estimation:prefillsetgroup:ref-members-detail": "api/admin/estimation/prefill-sets/{}/",
  "api:admin:estimation:product:ref-override-lines-list": "api/admin/estimation/products/{}/override_lines/",
  "api:admin:estimation:product:ref-override-lines-detail": "api/admin/estimation/override-lines/{}/",
  "api:admin:estimation:product:ref-export-mappings-list": "api/admin/estimation/products/{}/export_mappings/",
  "api:admin:estimation:product:ref-export-mappings-detail": "api/admin/estimation/export-mappings/{}/",
  "api:admin:estimation:product:ref-export-mapping-lines-list": "api/admin/estimation/products/{}/export_mapping_lines/",
  "api:admin:estimation:product:ref-export-mapping-lines-detail": "api/admin/estimation/export-mapping-lines/{}/",
  "api:admin:estimation:section:ref-subsections-list": "api/admin/estimation/sections/{}/subsections/",
  "api:admin:estimation:section:ref-subsections-detail": "api/admin/estimation/subsections/{}/",
  "api:admin:estimation:subheading:ref-export-mappings-list": "api/admin/estimation/subheadings/{}/export_mappings/",
  "api:admin:estimation:subheading:ref-export-mappings-detail": "api/admin/estimation/export-mappings/{}/",
  "api:admin:estimation:subheading:ref-export-mapping-lines-list": "api/admin/estimation/subheadings/{}/export_mapping_lines/",
  "api:admin:estimation:subheading:ref-export-mapping-lines-detail": "api/admin/estimation/export-mapping-lines/{}/",
  "api:admin:estimation:subheading:ref-line-templates-list": "api/admin/estimation/subheadings/{}/line_templates/",
  "api:admin:estimation:subheading:ref-line-templates-detail": "api/admin/estimation/line-templates/{}/",
  "api:admin:estimation:subheading:ref-random-mappings-list": "api/admin/estimation/subheadings/{}/random_mappings/",
  "api:admin:estimation:subheading:ref-random-mappings-detail": "api/admin/estimation/random-mappings/{}/",
  "api:admin:estimation:subheading:ref-pm-hardware-starting-ranges-list": "api/admin/estimation/subheadings/{}/pm_hardware_starting_ranges/",
  "api:admin:estimation:subheading:ref-pm-hardware-starting-ranges-detail": "api/admin/estimation/pm-hardware-starting-ranges/{}/",
  "api:admin:estimation:subheading:ref-pm-timber-starting-ranges-list": "api/admin/estimation/subheadings/{}/pm_timber_starting_ranges/",
  "api:admin:estimation:subheading:ref-pm-timber-starting-ranges-detail": "api/admin/estimation/pm-timber-starting-ranges/{}/",
  // "api:admin:estimation:subheading:ref-qs-subheads-list": "api/admin/estimation/subheadings/{}/pm_subhead_maps/", // yang mana? a
  "api:admin:estimation:subheading:ref-qs-subheads-list": "api/admin/estimation/subheadings/{}/qs_subheads/", // yang mana? b
  "api:admin:estimation:subheading:ref-qs-subheads-detail": "api/admin/estimation/pm-subhead-mappings/{}/",
  "api:admin:estimation:subheading:ref-pm-sort-codes-list": "api/admin/estimation/subheadings/{}/pm_sort_codes/",
  "api:admin:estimation:subheading:ref-pm-sort-codes-detail": "api/admin/estimation/pm-sort-codes/{}/",
  "api:admin:estimation:subheading:ref-subhead-maps-from-list": "api/admin/estimation/subheadings/{}/subhead_maps_from/",
  "api:admin:estimation:subheading:ref-subhead-maps-from-detail": "api/admin/estimation/subhead-maps/{}/",
  "api:admin:estimation:subheading:ref-subhead-maps-to-list": "api/admin/estimation/subheadings/{}/subhead_maps_to/",
  "api:admin:estimation:subheading:ref-subhead-maps-to-detail": "api/admin/estimation/subhead-maps/{}/",
  "api:admin:estimation:subheading:ref-override-lines-list": "api/admin/estimation/subheadings/{}/override_lines/",
  "api:admin:estimation:subheading:ref-override-lines-detail": "api/admin/estimation/override-lines/{}/",
  "api:admin:estimation:subheading:ref-prefill-lines-list": "api/admin/estimation/subheadings/{}/prefill_lines/",
  "api:admin:estimation:subheading:ref-prefill-lines-detail": "api/admin/estimation/prefill-lines/{}/",
  "api:admin:estimation:subsection:ref-line-templates-list": "api/admin/estimation/subsections/{}/line_templates/",
  "api:admin:estimation:subsection:ref-line-templates-detail": "api/admin/estimation/line-templates/{}/",
  "api:admin:estimation:subsection:ref-subhead-maps-list": "api/admin/estimation/subsections/{}/subhead_maps",
  "api:admin:estimation:subsection:ref-subhead-maps-detail": "api/admin/estimation/subhead-maps/{}/",
  "api:admin:estimation:subsection:ref-override-sets-list": "api/admin/estimation/subsections/{}/override_sets/",
  "api:admin:estimation:subsection:ref-override-sets-detail": "api/admin/estimation/override-sets/{}/",
  "api:admin:estimation:subsection:ref-prefill-sets-list": "api/admin/estimation/subsections/{}/prefill_sets/",
  "api:admin:estimation:subsection:ref-prefill-sets-detail": "api/admin/estimation/prefill-sets/{}/",
  "api:admin:estimation:templateset:ref-subheadings-list": "api/admin/estimation/template-sets/{}/subheadings/",
  "api:admin:estimation:templateset:ref-subheadings-detail": "api/admin/estimation/subheadings/{}/",
  "api:admin:estimation:templateset:ref-usages-list": "api/admin/estimation/template-sets/{}/usages/",
  "api:admin:estimation:templateset:ref-usages-detail": "api/admin/estimation/line-usages/{}/",
  "api:admin:estimation:templateset:ref-products-list": "api/admin/estimation/template-sets/{}/products/",
  "api:admin:estimation:templateset:ref-products-detail": "api/admin/estimation/products/{}/",
  "api:admin:estimation:templateset:ref-product-groups-list": "api/admin/estimation/template-sets/{}/product_groups/",
  "api:admin:estimation:templateset:ref-product-groups-detail": "api/admin/estimation/product-groups/{}/",
  "api:admin:estimation:unit:ref-line-templates-list": "api/admin/estimation/units/{}/line_templates/",
  "api:admin:estimation:unit:ref-line-templates-detail": "api/admin/estimation/units/{}/line_templates/{}/",
  "api:admin:estimation:unit:ref-products-list": "api/admin/estimation/units/{}/products/",
  "api:admin:estimation:unit:ref-products-detail": "api/admin/estimation/products/{}/",
  "api:admin:estimation:unit:ref-product-labours-list": "api/admin/estimation/units/{}/product_labours/",
  "api:admin:estimation:unit:ref-product-labours-detail": "api/admin/estimation/products/{}/",
  // ...

  //

  "api:admin:geo:country:list": "api/admin/geo/countries/",
  "api:admin:geo:country:detail": "api/admin/geo/countries/{}/",
  "api:admin:geo:country:detail-revision": "api/admin/geo/countries/{}/revision/",
  "api:admin:geo:country:autocomplete": "api/admin/geo/countries/autocomplete/",
  "api:admin:geo:country:export": "api/admin/geo/countries/export/",

  "api:admin:geo:country:ref-template-sets-list": "api/admin/geo/countries/{}/template_sets/",
  "api:admin:geo:country:ref-template-sets-detail": "api/admin/geo/countries/{}/template_sets/{}/",

  "api:admin:jobs:account:list": "api/admin/jobs/accounts/",
  "api:admin:jobs:account:detail": "api/admin/jobs/accounts/{}/",
  "api:admin:jobs:account:detail-revision": "api/admin/jobs/accounts/{}/revision/",
  "api:admin:jobs:account:autocomplete": "api/admin/jobs/accounts/autocomplete/",
  "api:admin:jobs:account:export": "api/admin/jobs/accounts/export/",

  "api:admin:jobs:buildingtype:list": "api/admin/jobs/building-types/",
  "api:admin:jobs:buildingtype:detail": "api/admin/jobs/building-types/{}/",
  "api:admin:jobs:buildingtype:detail-revision": "api/admin/jobs/building-types/{}/revision/",
  "api:admin:jobs:buildingtype:autocomplete": "api/admin/jobs/building-types/autocomplete/",
  "api:admin:jobs:buildingtype:export": "api/admin/jobs/building-types/export/",

  "api:admin:jobs:building:list": "api/admin/jobs/buildings/",
  "api:admin:jobs:building:detail": "api/admin/jobs/buildings/{}/",
  "api:admin:jobs:building:detail-revision": "api/admin/jobs/buildings/{}/revision/",
  "api:admin:jobs:building:autocomplete": "api/admin/jobs/buildings/autocomplete/",
  "api:admin:jobs:building:export": "api/admin/jobs/buildings/export/",

  "api:admin:jobs:extrachargejob:list": "api/admin/jobs/extra-charge-jobs/",
  "api:admin:jobs:extrachargejob:detail": "api/admin/jobs/extra-charge-jobs/{}/",
  "api:admin:jobs:extrachargejob:detail-revision": "api/admin/jobs/extra-charge-jobs/{}/revision/",
  "api:admin:jobs:extrachargejob:autocomplete": "api/admin/jobs/extra-charge-jobs/autocomplete/",
  "api:admin:jobs:extrachargejob:export": "api/admin/jobs/extra-charge-jobs/export/",

  "api:admin:jobs:extrachargesize:list": "api/admin/jobs/extra-charge-sizes/",
  "api:admin:jobs:extrachargesize:detail": "api/admin/jobs/extra-charge-sizes/{}/",
  "api:admin:jobs:extrachargesize:detail-revision": "api/admin/jobs/extra-charge-sizes/{}/revision/",
  "api:admin:jobs:extrachargesize:autocomplete": "api/admin/jobs/extra-charge-sizes/autocomplete/",
  "api:admin:jobs:extrachargesize:export": "api/admin/jobs/extra-charge-sizes/export/",

  "api:admin:jobs:extracharge:list": "api/admin/jobs/extra-charges/",
  "api:admin:jobs:extracharge:detail": "api/admin/jobs/extra-charges/{}/",
  "api:admin:jobs:extracharge:detail-revision": "api/admin/jobs/extra-charges/{}/revision/",
  "api:admin:jobs:extracharge:autocomplete": "api/admin/jobs/extra-charges/autocomplete/",
  "api:admin:jobs:extracharge:export": "api/admin/jobs/extra-charges/export/",

  "api:admin:jobs:holiday:list": "api/admin/jobs/holidays/",
  "api:admin:jobs:holiday:detail": "api/admin/jobs/holidays/{}/",
  "api:admin:jobs:holiday:detail-revision": "api/admin/jobs/holidays/{}/revision/",
  "api:admin:jobs:holiday:autocomplete": "api/admin/jobs/holidays/autocomplete/",
  "api:admin:jobs:holiday:export": "api/admin/jobs/holidays/export/",

  "api:admin:jobs:jobstatusnotsynced:list": "api/admin/jobs/job-status-not-synceds/",
  "api:admin:jobs:jobstatusnotsynced:detail": "api/admin/jobs/job-status-not-synceds/{}/",
  "api:admin:jobs:jobstatusnotsynced:autocomplete": "api/admin/jobs/job-status-not-synceds/autocomplete/",
  "api:admin:jobs:jobstatusnotsynced:export": "api/admin/jobs/job-status-not-synceds/export/",

  "api:admin:jobs:jobgrade:list": "api/admin/jobs/job-grades/",
  "api:admin:jobs:jobgrade:detail": "api/admin/jobs/job-grades/{}/",
  "api:admin:jobs:jobgrade:detail-revision": "api/admin/jobs/job-grades/{}/revision/",
  "api:admin:jobs:jobgrade:autocomplete": "api/admin/jobs/job-grades/autocomplete/",
  "api:admin:jobs:jobgrade:export": "api/admin/jobs/job-grades/export/",
  //
  "api:admin:jobs:jobgrade:ref-job-types-list": "api/admin/jobs/job-grades/{}/job_types/",
  //
  "api:admin:jobs:jobqueue:list": "api/admin/jobs/job-queues/",
  "api:admin:jobs:jobqueue:detail": "api/admin/jobs/job-queues/{}/",
  "api:admin:jobs:jobqueue:detail-revision": "api/admin/jobs/job-queues/{}/revision/",
  "api:admin:jobs:jobqueue:autocomplete": "api/admin/jobs/job-queues/autocomplete",
  "api:admin:jobs:jobqueue:export": "api/admin/jobs/job-queues/export/",

  "api:admin:jobs:jobstatushistory:list": "api/admin/jobs/job-status-histories/",
  "api:admin:jobs:jobstatushistory:detail": "api/admin/jobs/job-status-histories/{}/",
  "api:admin:jobs:jobstatushistory:detail-revision": "api/admin/jobs/job-status-histories/{}/revision/",
  "api:admin:jobs:jobstatushistory:autocomplete": "api/admin/jobs/job-status-histories/autocomplete/",
  "api:admin:jobs:jobstatushistory:export": "api/admin/jobs/job-status-histories/export/",

  "api:admin:jobs:jobtype:list": "api/admin/jobs/job-types/",
  "api:admin:jobs:jobtype:detail": "api/admin/jobs/job-types/{}/",
  "api:admin:jobs:jobtype:detail-revision": "api/admin/jobs/job-types/{}/revision/",
  "api:admin:jobs:jobtype:autocomplete": "api/admin/jobs/job-types/autocomplete/",
  "api:admin:jobs:jobtype:export": "api/admin/jobs/job-types/export/",

  "api:admin:jobs:merchantgroup:list": "api/admin/jobs/merchant-groups/",
  "api:admin:jobs:merchantgroup:detail": "api/admin/jobs/merchant-groups/{}/",
  "api:admin:jobs:merchantgroup:detail-revision": "api/admin/jobs/merchant-groups/{}/revision/",
  "api:admin:jobs:merchantgroup:autocomplete": "api/admin/jobs/merchant-groups/autocomplete/",
  "api:admin:jobs:merchantgroup:export": "api/admin/jobs/merchant-groups/export/",

  "api:admin:jobs:merchant:list": { url: "api/admin/jobs/merchants/", version: 3 },
  "api:admin:jobs:merchant:detail": { url: "api/admin/jobs/merchants/{}/", version: 3 },
  "api:admin:jobs:merchant:detail-revision": { url: "api/admin/jobs/merchants/{}/revision/", version: 3 },
  "api:admin:jobs:merchant:autocomplete": { url: "api/admin/jobs/merchants/autocomplete/", version: 3 },
  "api:admin:jobs:merchant:export": { url: "api/admin/jobs/merchants/export/", version: 3 },
  //
  "api:admin:jobs:merchant:ref-export-mappings-list": "api/admin/jobs/merchants/{}/export_mappings/",
  "api:admin:jobs:merchant:ref-pm-hardware-starting-ranges-list": "api/admin/jobs/merchants/{}/pm_hardware_starting_ranges/",
  "api:admin:jobs:merchant:ref-pm-timber-starting-ranges-list": "api/admin/jobs/merchants/{}/pm_timber_starting_ranges/",
  "api:admin:jobs:merchant:ref-mapping-modes-list": "api/admin/jobs/merchants/{}/mapping_modes/",
  "api:admin:jobs:merchant:ref-sku-descriptions-list": "api/admin/jobs/merchants/{}/sku-descriptions/",
  "api:admin:jobs:merchant:ref-skumappings-list": "api/admin/jobs/merchants/{}/skumappings/",
  //


  "api:admin:jobs:operatingbranch:list": "api/admin/jobs/operating-branches/",
  "api:admin:jobs:operatingbranch:detail": "api/admin/jobs/operating-branches/{}/",
  "api:admin:jobs:operatingbranch:detail-revision": "api/admin/jobs/operating-branches/{}/revision/",
  "api:admin:jobs:operatingbranch:autocomplete": "api/admin/jobs/operating-branches/autocomplete/",
  "api:admin:jobs:operatingbranch:ref-merchants-list": "api/admin/jobs/operating-branches/{}/merchants/",
  "api:admin:jobs:operatingbranch:export": "api/admin/jobs/operating-branches/export/",

  "api:admin:jobs:ranges:list": "api/admin/jobs/ranges/",
  "api:admin:jobs:ranges:detail": "api/admin/jobs/ranges/{}/",
  "api:admin:jobs:ranges:detail-revision": "api/admin/jobs/ranges/{}/revision/",
  "api:admin:jobs:ranges:autocomplete": "api/admin/jobs/ranges/autocomplete/",
  "api:admin:jobs:ranges:export": "api/admin/jobs/ranges/export/",

  "api:admin:jobs:ratename:list": "api/admin/jobs/rate-names/",
  "api:admin:jobs:ratename:detail": "api/admin/jobs/rate-names/{}/",
  "api:admin:jobs:ratename:detail-revision": "api/admin/jobs/rate-names/{}/revision/",
  "api:admin:jobs:ratename:autocomplete": "api/admin/jobs/rate-names/autocomplete/",
  "api:admin:jobs:ratename:export": "api/admin/jobs/rate-names/export/",

  "api:admin:jobs:ratetype:list": "api/admin/jobs/rate-types/",
  "api:admin:jobs:ratetype:detail": "api/admin/jobs/rate-types/{}/",
  "api:admin:jobs:ratetype:detail-revision": "api/admin/jobs/rate-types/{}/revision/",
  "api:admin:jobs:ratetype:autocomplete": "api/admin/jobs/rate-types/autocomplete/",
  "api:admin:jobs:ratetype:export": "api/admin/jobs/rate-types/export/",

  "api:admin:jobs:tagmerchant:list": "api/admin/jobs/tag-merchants/",
  "api:admin:jobs:tagmerchant:detail": "api/admin/jobs/tag-merchants/{}/",
  "api:admin:jobs:tagmerchant:export": "api/admin/jobs/tag-merchants/export/",

  "api:admin:jobs:tag:list": "api/admin/jobs/tags/",
  "api:admin:jobs:tag:detail": "api/admin/jobs/tags/{}/",
  "api:admin:jobs:tag:detail-revision": "api/admin/jobs/tags/{}/revision/",
  "api:admin:jobs:tag:autocomplete": "api/admin/jobs/tags/autocomplete/",
  "api:admin:jobs:tag:export": "api/admin/jobs/tags/export/",

  "api:admin:jobs:takeofftype:list": "api/admin/jobs/take-off-types/",
  "api:admin:jobs:takeofftype:detail": "api/admin/jobs/take-off-types/{}/",
  "api:admin:jobs:takeofftype:detail-revision": "api/admin/jobs/take-off-types/{}/revision/",
  "api:admin:jobs:takeofftype:autocomplete": "api/admin/jobs/take-off-types/autocomplete/",
  "api:admin:jobs:takeofftype:export": "api/admin/jobs/take-off-types/export/",


  "api:admin:multisite:branch:list": { url: "api/admin/multisite/branches/", version: 3 },
  "api:admin:multisite:branch:detail": { url: "api/admin/multisite/branches/{}/", version: 3 },
  "api:admin:multisite:branch:detail-revision": { url: "api/admin/multisite/branches/{}/revision/", version: 3 },
  "api:admin:multisite:branch:autocomplete": { url: "api/admin/multisite/branches/autocomplete/", version: 3 },
  "api:admin:multisite:branch:export": { url: "api/admin/multisite/branches/export/", version: 3 },
  
  "api:admin:billing:branchrating:list": "api/admin/billing/rates/",
  "api:admin:billing:branchrating:detail": "api/admin/billing/rates/{}/",
  "api:admin:billing:branchrating:detail-revision": "api/admin/billing/rates/{}/revision/",
  "api:admin:billing:branchrating:autocomplete": "api/admin/billing/rates/autocomplete/",
  "api:admin:billing:branchrating:export": "api/admin/billing/rates/export/",

  "api:admin:multisite:pendingmonitorreport:list": "api/admin/multisite/pending-monitor-reports/",
  "api:admin:multisite:pendingmonitorreport:detail": "api/admin/multisite/pending-monitor-reports/{}/",
  "api:admin:multisite:pendingmonitorreport:autocomplete": "api/admin/multisite/pending-monitor-reports/autocomplete/",
  "api:admin:multisite:pendingmonitorreport:export": "api/admin/multisite/pending-monitor-reports/export/",

  "api:admin:multisite:server:list": "api/admin/multisite/servers/",
  "api:admin:multisite:server:detail": "api/admin/multisite/servers/{}/",
  "api:admin:multisite:server:autocomplete": "api/admin/multisite/servers/autocomplete/",
  "api:admin:multisite:server:export": "api/admin/multisite/servers/export/",


  "api:admin:notifications:notification:list": "api/admin/notifications/notifications/",
  "api:admin:notifications:notification:detail": "api/admin/notifications/notifications/{}/",
  "api:admin:notifications:notification:detail-revision": "api/admin/notifications/notifications/{}/revision/",
  "api:admin:notifications:notification:autocomplete": "api/admin/notifications/notifications/autocomplete/",
  "api:admin:notifications:notification:export": "api/admin/notifications/notifications/export/",


  // "api:admin:reports:merchantmappingmode:list": "api/admin/reports/merchant-mapping-modes/",
  // "api:admin:reports:merchantmappingmode:detail": "api/admin/reports/merchant-mapping-modes/{}/",
  // "api:admin:reports:merchantmappingmode:autocomplete": "api/admin/reports/merchant-mapping-modes/autocomplete/",
  // "api:admin:reports:merchantmappingmode:export": "api/admin/reports/merchant-mapping-modes/export/",
  
  "api:admin:merchants:mappingmode:list": "api/admin/merchant/mapping-modes/",
  "api:admin:merchants:mappingmode:detail": "api/admin/merchant/mapping-modes/{}/",
  "api:admin:merchants:mappingmode:detail-revision": "api/admin/merchant/mapping-modes/{}/revision/",
  "api:admin:merchants:mappingmode:autocomplete": "api/admin/merchant/mapping-modes/autocomplete/",
  "api:admin:merchants:mappingmode:export": "api/admin/merchant/mapping-modes/export/",
  
  "api:admin:merchants:mappingmode:ref-sku-mappings-list": "api/admin/merchant/mapping-modes/{}/sku_mappings/",
  "api:admin:merchants:mappingmode:ref-sku-mappings-detail": "api/admin/merchant/mapping-modes/{}/sku_mappings/{}/",
  "api:admin:merchants:mappingmode:ref-merchants-list": "api/admin/merchant/mapping-modes/{}/merchants/",
  "api:admin:merchants:mappingmode:ref-merchants-detail": "api/admin/merchant/mapping-modes/{}/merchants/{}/",
  "api:admin:merchants:mappingmode:ref-merchant-groups-list": "api/admin/merchant/mapping-modes/{}/merchant_groups/",
  "api:admin:merchants:mappingmode:ref-merchant-groups-detail": "api/admin/merchant/mapping-modes/{}/merchant_groups/{}/",

  "api:admin:reports:merchantskudescription:list": "api/admin/reports/merchant-sku-descriptions/",
  "api:admin:reports:merchantskudescription:detail": "api/admin/reports/merchant-sku-descriptions/{}/",
  "api:admin:reports:merchantskudescription:autocomplete": "api/admin/reports/merchant-sku-descriptions/autocomplete/",
  "api:admin:reports:merchantskudescription:export": "api/admin/reports/merchant-sku-descriptions/export/",

  "api:admin:reports:mmsecurity:list": "api/admin/reports/mm-securities/",
  "api:admin:reports:mmsecurity:detail": "api/admin/reports/mm-securities/{}/",
  "api:admin:reports:mmsecurity:detail-revision": "api/admin/reports/mm-securities/{}/revision/",
  "api:admin:reports:mmsecurity:autocomplete": "api/admin/reports/mm-securities/autocomplete/",
  "api:admin:reports:mmsecurity:export": "api/admin/reports/mm-securities/export/",

  // "api:admin:reports:skumapping:list": "api/admin/reports/sku-mappings/",
  // "api:admin:reports:skumapping:detail": "api/admin/reports/sku-mappings/{}/",
  // "api:admin:reports:skumapping:autocomplete": "api/admin/reports/sku-mappings/autocomplete/",
  // "api:admin:reports:skumapping:export": "api/admin/reports/sku-mappings/export/",

  "api:admin:merchants:skumapping:list": "api/admin/merchant/sku-mappings/",
  "api:admin:merchants:skumapping:detail": "api/admin/merchant/sku-mappings/{}/",
  "api:admin:merchants:skumapping:detail-revision": "api/admin/merchant/sku-mappings/{}/revision/",
  "api:admin:merchants:skumapping:autocomplete": "api/admin/merchant/sku-mappings/autocomplete/",
  "api:admin:merchants:skumapping:export": "api/admin/merchant/sku-mappings/export/",

  "api:admin:reports:specialmessage:list": "api/admin/reports/special-messages/",
  "api:admin:reports:specialmessage:detail": "api/admin/reports/special-messages/{}/",
  "api:admin:reports:specialmessage:detail-revision": "api/admin/reports/special-messages/{}/revision/",
  "api:admin:reports:specialmessage:autocomplete": "api/admin/reports/special-messages/autocomplete/",
  "api:admin:reports:specialmessage:export": "api/admin/reports/special-messages/export/",

  "api:admin:users:staffleave:list": "api/admin/users/staff-leaves/",
  "api:admin:users:staffleave:detail": "api/admin/users/staff-leaves/{}/",
  "api:admin:users:staffleave:detail-revision": "api/admin/users/staff-leaves/{}/revision/",
  "api:admin:users:staffleave:autocomplete": "api/admin/users/staff-leaves/autocomplete/",
  "api:admin:users:staffleave:export": "api/admin/users/staff-leaves/export/",

  "api:admin:users:staffposition:list": "api/admin/users/staff-positions/",
  "api:admin:users:staffposition:detail": "api/admin/users/staff-positions/{}/",
  "api:admin:users:staffposition:detail-revision": "api/admin/users/staff-positions/{}/revision/",
  "api:admin:users:staffposition:autocomplete": "api/admin/users/staff-positions/autocomplete/",
  "api:admin:users:staffposition:export": "api/admin/users/staff-positions/export/",

  "api:admin:users:user:list": "api/admin/users/users/",
  "api:admin:users:user:detail": "api/admin/users/users/{}/",
  "api:admin:users:user:detail-revision": "api/admin/users/users/{}/revision/",
  "api:admin:users:user:autocomplete": "api/admin/users/users/autocomplete/",
  "api:admin:users:user:export": "api/admin/users/users/export/",
}
