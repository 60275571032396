export default {
  "countries-read": "api/admin/geo/countries/",
  "countries-create": "api/admin/geo/countries/",
  "countries-delete": "api/admin/geo/countries/{}/",
  "countries-update": "api/admin/geo/countries/{}/",
  "countries-detail": "api/admin/geo/countries/{}/",
  "countries-detail-revision-version": "api/admin/geo/countries/{}/revision/{}/",
  "countries-autocomplete": "api/admin/geo/countries/autocomplete/",


  "provinces-read": "api/admin/geo/provinces/",
  "provinces-create": "api/admin/geo/provinces/",
  "provinces-delete": "api/admin/geo/provinces/{}/",
  "provinces-update": "api/admin/geo/provinces/{}/",
  "provinces-detail": "api/admin/geo/provinces/{}/",
  "provinces-autocomplete": "api/admin/geo/provinces/autocomplete/",
  "provinces-detail-revision-version": "api/admin/geo/provinces/{}/revision/{}/",

  "api:admin:geo:province:list": "api/admin/geo/provinces/",
  "api:admin:geo:province:detail": "api/admin/geo/provinces/{}/",
  "api:admin:geo:province:autocomplete": "api/admin/geo/provinces/autocomplete/",
  "api:admin:geo:province:export": "api/admin/geo/provinces/export/",
  "api:admin:geo:province:detail-revision": "api/admin/geo/provinces/{}/revision/",
  // "api:admin:geo:province:history-detail": "api/admin/geo/provinces/{}/revision/{}/", // tidak digunakan


  "cities-read": "api/admin/geo/cities/",
  "cities-create": "api/admin/geo/cities/",
  "cities-delete": "api/admin/geo/cities/{}/",
  "cities-update": "api/admin/geo/cities/{}/",
  "cities-detail": "api/admin/geo/cities/{}/",
  "cities-autocomplete": "api/admin/geo/cities/autocomplete/",
  "cities-detail-revision-version": "api/admin/geo/cities/{}//revision/{}/",

  "api:admin:geo:city:list": "api/admin/geo/cities/",
  "api:admin:geo:city:detail": "api/admin/geo/cities/{}/",
  "api:admin:geo:city:autocomplete": "api/admin/geo/cities/autocomplete/",
  "api:admin:geo:city:export": "api/admin/geo/cities/export/",
  "api:admin:geo:city:detail-revision": "api/admin/geo/cities/{}/revision/",
  // "api:admin:geo:city:history-detail": "api/admin/geo/cities/{}/revision/{}/", // tidak digunakan
}
