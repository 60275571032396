import React from 'react';
import { Card, Row, Col, Avatar, Tooltip, Spin, message } from 'antd'
import { get, groupBy, isEmpty } from 'lodash'
import { BaseList } from 'App/Screens/SubModules/Component'
import descriptor from './descriptorOffline'
import StatusSummary from './StatusSummary'
import GradeSummary from './GradeSummary'
import groupByOptions from './groupBy'
import Sites from 'App/Config/Sites'
import CalendarMode from "./Management/CalendarMode";
import moment from 'moment';
import { RequestV2 as Request } from "App/Utils";

const windowFeatures = `width=${window.innerWidth * 0.9},height=${window.innerHeight * 0.9}`
const domainQs = Sites.url.qs

class JobList extends BaseList {
  constructor(props) {
    super(props)

    this.appName = 'jobs'
    this.modelName = 'master_job'
    this.availablePermissions = ['.view_']
    this.state = {
      ...this.state,
      hasQsAccess: this.state.isAccessAllowed,
      currentPermissionSet: !this.state.isCheckingPermission ? this.availablePermissions : [],

      //calendar
      month: [],
      year: [],
      spinning: false,
      vector: 'month',
      search: {}
    }
    this.propsList = {
      disableAddButton: true,
      getAvatar: this.getAvatar,
      pageTitle: 'Job',
      descriptor,
      masterKey: 'jobs',
      renderCountCard: this.renderCountCard,
      groupByOptions,
      //view
      panelView: [
        {
          value: 'calendar',
          label: 'Calendar',
          icon: 'calendar'
        },
        {
          value: 'list',
          label: 'List',
          icon: 'profile',
          active: true
        }],
      onClickView: ()=>{
        if(!isEmpty(this.state.search))
          this.setState({ search: {} }, ()=>this.retreive())
        else
          this.retreive()
      },
      viewComponent: this.viewComponent,
      onDetailClick: this.onDetailClick,
      onReload: this.onReloadList,
    }
    this.jobType = {
      tender: { label: "Tender", color: "#00bfff" },
      td: { label: "Tender and Double Up", alias: 'T&D', color: "#00bfff" },
      tc: { label: "Tender and Commercial", alias: 'T&C', color: "#ff776c" },
      tdc: { label: "Double up, Tender and Commercial", alias: 'DT&C', color: "#ff776c" },
      commercial: { label: "Commercial", alias: "CM", color: "#3cff00" },
      doubleUp: { label: "Double Up", alias: "DU", color: "#c1c1c1" },
      default: { label: "Job", color: "#c1c1c1" }
    }
  }

  callbackAllowed = () => {
    this.setState({ hasQsAccess: true })
  }

  renderCountCard = () => {
    const bodyStyle = { padding: "4px 12px" };
    return (
      <Row
        type="flex"
        justify="space-between"
        gutter={[12, 12]}
        style={{ marginBottom: "12px" }}
      >
        <Col xs={24} sm={24} lg={12}>
          <Card bodyStyle={bodyStyle}>
            <StatusSummary ref={ref => {this._statusSummary = ref}} userReducer={this.props.userReducer} />
          </Card>
        </Col>
        <Col xs={24} sm={24} lg={12}>
          <Card bodyStyle={bodyStyle}>
            <GradeSummary ref={ref => {this._gradeSummary = ref}} userReducer={this.props.userReducer} />
          </Card>
        </Col>
      </Row>
    )
  }

  // return object { label: string?, color: string? }
  getJobType = job => {
    if (job.tender && job.commercial && job.double_up) {
      return this.jobType.tdc;
    }
    if (job.tender && job.commercial) {
      return this.jobType.tc;
    }
    if (job.tender && job.double_up) {
      return this.jobType.td;
    }
    else if (job.tender) {
      return this.jobType.tender;
    }
    else if (job.commercial) {
      return this.jobType.commercial;
    }
    else if (job.double_up) {
      return this.jobType.doubleUp;
    }
    return this.jobType.default;
  }

  getAvatar = (item, index, record) => {
    if (item) {
      const jobType = this.getJobType(item);
      return (
        <Tooltip placement="left" title={jobType.label}>
          <Avatar
            shape="square"
            size="large"
            style={{ background: jobType.color }}
          >
            {jobType.alias || jobType.label}
          </Avatar>
        </Tooltip>
      );
    }
  }

  onChangeview=(e, s)=>{
    this.retreive(e, s)
  }

  selectMonth=(e)=>{
    this.setState({ vector: 'month'}, ()=>this.retreive(e, 'month'))
  }

  retreive=(date= new Date(), calendar_type='month')=>{
    this.setState({spinning: true, vector: calendar_type}, ()=> {
      const start_due_date = moment(date).startOf(calendar_type).toISOString()
      const end_due_date = moment(date).endOf(calendar_type).toISOString()
      const [p,s] = [this.props, this.state]
      Request(
        "get",
        "calendar-jobs",
        {},
        {start_due_date, end_due_date, calendar_type, ...s.search},
        [],
        (res, e) => {
          let r = {}
          if(calendar_type === 'month') {
            const sl = []
            const esl = groupBy(res.data.staff_leaves, 'leave_date')
            for(const i in esl){
              sl.push({ name: i, data: esl[i]})
            }
            r = {jobs:res.data.jobs, sl}
          }else {
            r = res.data
          }
          this.setState({[calendar_type]: r, spinning: false})
        },
        (res) => {
          this.setState({spinning: false})
          message.error('Something when wrong')
          console.warn(res)
        }
      );
    })
  }

  onSearch=(search, res)=>{
    const [s] = [this.state]
    let obj = { search: {}}
    if(search)
      obj.search = { search }
    this.setState(obj, ()=>this.retreive(res, s.vector))
  }

  changeS=(e)=>{
    const w = { search: {} }
    if(e.target.value)
      w.search = { search: e.target.value }
    this.setState(w)
  }

  viewComponent=()=>{
    const [s, p] = [this.state, this.props]
    return (
      <Spin spinning={s.spinning}>
        <CalendarMode
          jobs={s.month}
          loading={s.spinning}
          yjobs={s.year}
          setQuery={(obj)=>this.setState(obj)}
          changeS={this.changeS}
          onSearch={this.onSearch}
          userReducer={p.userReducer}
          selectMonth={this.selectMonth}
          onChangeview={this.onChangeview}
          vector={s.vector}
        />
      </Spin>
    )
  }

  onDetailClick = id => {
    if (this.state.hasQsAccess) {
      const { userReducer } = this.props;
      const urlTarget =
        domainQs +
        "/job/" +
        id +
        "?ItSHPShcIRDYnjKX=" +
        btoa(`${Sites.oauth.id}:${Sites.oauth.secret}`) +
        "&MwFMqLoCBGBDyZwj=" +
        userReducer.token +
        "&lmMgPPePCXurSXjI=" +
        get(userReducer, "user.username");
      window.open(urlTarget, urlTarget, windowFeatures);
    } else {
      message.info(`Sorry, you don't have access to QS`);
    }
  }

  onReloadList = () => {
    if (this._gradeSummary) this._gradeSummary.readSummary()
    if (this._statusSummary) this._statusSummary.readSummary()
  }
}

export default JobList
