import React from 'react'
import { Button, Icon, message, Modal } from 'antd'
import { get, startCase } from 'lodash'
import { Lib, RequestV2 as Request } from 'App/Utils'

const iconType = 'notification'
const sendStates = {
  new: 'Send',
  sending: 'Cannot send when status Sending...',
  sent: 'Resend',
}

const getAnotherOperations = (record, compTable) => (
  // Berbeda ukurannya jika menggunakan icon prop di Button dengan children <Icon />
  <Button
    disabled={record.state === 'sending'}
    type='primary'
    size='small'
    title={get(sendStates, record.state, record.state)}
    style={{ marginRight: '4px' }}
    onClick={(event) => onSend(event, record, compTable)}
  >
    <Icon type={iconType} />
  </Button>
)

const showConfirm = (record, callback, force) => {
  if (force === true) {
    callback(true)
  } else {
    const sendState = get(sendStates, record.state, record.state)
    const title = `${sendState} Confirmation`
    const description = ` will ${sendState} to ${startCase(record.receiver_type)}.`
    const additionalInformation = record.receiver_type === 'all' ? '' : 'Please open the Record to see more about Receiver.'
    // buat tampilan yang bagus
    // perhatikan state.nya juga, apakah dari new (berarti send) atau dari sent (berarti resend)
    // perhatikan receiver_type nya
    // berarti, kalau specific_user, group atau branch,
    // sebisa mungkin pesannya yang jelas
    Modal.confirm({
      title,
      icon: iconType,
      content: (
        <div>
          <b>{record.title}</b>
          {description}
          <br />
          {additionalInformation}
        </div>
      ),
      onOk: () => callback(true),
      onCancel: () => callback(false)
    })
  }
}

const onSend = (event, record, compTable) => {
  showConfirm(record, isConfirmation => {
    if (isConfirmation) {
      return new Promise((resolve, reject) => {
        Request(
          'post',
          'sendBroadcast',
          {},
          {},
          [record.pk],
          onSendSuccess,
          onSendFailed,
          { resolve, reject, compTable }
        )
      })
    }
  })
}

const onSendSuccess = (response, extra) => {
  message.success('Sending the message, please click Refresh button to get current Status', 6)
  extra.resolve()
  if (typeof get(extra.compTable, 'reloadPage') === 'function') extra.compTable.reloadPage()
}

const onSendFailed = (error, extra) => {
  extra.reject()
  Lib.errorMessageHandler(get(error, 'response'))
}

export default getAnotherOperations