export default {
  "app_label": "estimation",
  "model_name": "pmtimberstartingrange",
  "pagination": true,
  "columns": [
    {
      "name": "pk",
      "label": "ID",
      "help_text": null,
      "hidden": true,
      "identifier": true,
      "data_type": "int",
      "search": false,
      "sort": false
    },
    {
      "name": "merchant",
      "label": "Merchant",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": null,
      "child_path": "name",
      "search": true,
      "sort": true
    },
    {
      "name": "subheading",
      "label": "Subheading",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": null,
      "child_path": "label",
      "search": true,
      "sort": true
    },
    {
      "name": "start_range",
      "label": "Start range",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "ellipsis": true,
      "search": true,
      "sort": true
    },
    {
      "name": "end_range",
      "label": "End range",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "ellipsis": true,
      "search": true,
      "sort": true
    },
    {
      "name": "modified",
      "label": "Modified",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "datetime",
      "search": false,
      "sort": true
    }
  ],
  "filter_by": [
    {
      "label": "Subheading",
      "name": "subheading",
      "type": "api_reference",
      "api": {
        "url_name": "api:admin:estimation:subheading:autocomplete",
        "key": "pk",
        "display": "label",
        "pagination": true
      }
    },
    {
      "label": "Merchant",
      "name": "merchant",
      "type": "api_reference",
      "api": {
        "url_name": "api:admin:jobs:merchant:autocomplete",
        "key": "pk",
        "display": "name",
        "pagination": true
      }
    },
    {
      "label": "Start Range",
      "name": "start_range",
      "type": "text",
    },
    {
      "label": "End Range",
      "name": "end_range",
      "type": "text",
    },
  ],
  "export_import": null
}