export default [
  {
    title: 'Name',
    type: 'text',
    data: '',
    required: true,
    dataIndex: 'name',
    initialValue: '',
    note_text: '',
    additional: '',
    order: 0,
  },{
    title: 'Code',
    type: 'text',
    data: '',
    required: true,
    dataIndex: 'code',
    initialValue: '',
    note_text: 'Two-letter ISO 3166-1 alpha-2 country code, e.g. id for Indonesia, nz for New Zealand.',
    additional: '',
    order: 1,
  }
];