export default [
  {
    title: 'Title',
    type: 'text',
    data: '',
    required: true,
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'title',
    order: 0,
  }, {
    title: 'Message',
    type: 'textarea',
    data: '',
    required: true,
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'message',
    order: 1,
  }, {
    title: 'Is popup',
    type: 'checkbox',
    data: '',
    initialValue: '',
    note_text: 'Determines notification popup or not on User login.',
    additional: '',
    dataIndex: 'is_popup',
    order: 2,
  }, {
    title: 'Is sent',
    type: 'checkbox',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'is_sent',
    order: 3,
  },
]