import React from "react";
import SubAllList from "App/Component/SubAllList";
import {Typography, BackTop, Card, Icon} from "antd";
import { Lib } from "App/Utils";
import descriptor from "App/Screens/SubModules/UserGroup/User/descriptorOffline";

const { Text } = Typography;

class Index extends React.PureComponent {

  addUser=()=>{
    this.props.history.push('/users/users/add')
  }

  detailUser=(val)=>{
    this.props.history.push('/user-group/user/'+val)
  }

  componentWillUnmount() {
    Lib.windowStop();
  }

  render() {
    return (
      <Card>
        <SubAllList
          pageTitle={<Text type={"secondary"}><Icon type="solution" /> Operating Branch Capacity Planning</Text>}
          masterKey="users"
          descriptor={descriptor}
          disableAddButton={true}
          onAddClick={ this.addUser}
          onDetailClick={ this.detailUser }
          description={i => i.full_name}
          readParams={{branch: this.props.branch.pk}}
          userReducer={this.props.userReducer}
          groupByOptions={[
            {
              label: "Staff Position",
              key: "profile.staff_position.name",
              dataIndex: 'profile',
              default: true
            },
            {
              label: "Skill Level",
              key: "profile.skill_level",
              dataIndex: 'profile',
            }
          ]}
          avatar="username"

        />
        <BackTop />
      </Card>
    );
  }
}

export default Index;
