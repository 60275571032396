import React from 'react'
import { Prompt } from 'react-router-dom'
import { Layout, Row, Col, Button, Spin, Icon, message, Typography, Tabs } from 'antd'
import { get } from 'lodash'
import moment from 'moment'
import { RequestV2 as Request, Lib } from 'App/Utils'
import PageNotFound from 'App/Component/PageNotFound'
import Header from 'App/Screens/SubModules/UserGroup/Group/Management/Header'
import { PageMerchantGroup, PageAddMerchantGroup, PageMerchant, PageAddMerchant } from './Pages'

const { TabPane } = Tabs

class PageAdd extends React.PureComponent {
  constructor(props) {
    super(props)

    this.refKeys = [
      { key: '_header', keyBody: 'name', label: 'Mode' },
      { key: '_group', keyBody: 'merchant_group', label: 'Merchant Group' },
      { key: '_merchant', keyBody: 'merchant', label: 'Merchant' },
    ]

    this.state = {
      activeTabKey: 'groups',
      isSaving: false,
      isFieldsTouched: false,
      errorMessage: 'Failed to fetch',
    }
  }

  setTouched = (isFieldsTouched, callback) => {
    this.setState({ isFieldsTouched }, callback)
  }

  onFieldTouched = (isFieldsTouched, callback = () => null) => {
    this.setTouched(true, callback)
  }

  onTabChange = (activeTabKey, callback = () => null) => {
    this.setState({ activeTabKey }, callback)
  }

  onSave = () => {
    const values = {}
    for (const refItem of this.refKeys) {
      if (get(this[refItem.key], 'validateFields')) {
        values[refItem.keyBody] = this[refItem.key].validateFields()
      }
    }

    this.setState({ isSaving: true }, () => {
      Request(
        'post',
        'module-merchant-mappingMode-create',
        {},
        values,
        [],
        this.saveSuccess,
        this.saveFailed,
      )
    })
  }

  saveSuccess = (response) => {
    message.success(response.data.name + ' has been saved. will redirect to detail URL...')
    this.setState({ isFieldsTouched: false }, () => {
      setTimeout(() => {
        this.props.history.push(`/merchant/mapping-mode/${response.data.pk}`)
      }, 1000)
    })
  }

  saveFailed = (error) => {
    Lib.errorMessageHandler(error)
    const errors = get(error, ['response', 'data'])
    if (typeof get(error, ['response', 'data', 'detail']) === 'string') {
      message.error('Failed to save Mapping Mode, message: ' + error.response.data.detail, 5)
    } else if (errors && typeof errors === 'object') {
      this.setFieldsError(errors)
    } else {
      message.error('Failed to save Mapping Mode, please contact your admin!', 5)
    }
    this.setState({ isSaving: false })
  }

  setFieldsError = (objError) => {
    message.error('Failed to save Mapping Mode')
    for (const key in objError) {
      const refKey = this.refKeys.find(ref => ref.keyBody === key)
      message.error('Error in ' + get(refKey, 'label', key) + ' : ' + objError[key][0], 5)
      if (refKey && typeof get(this[refKey.key], 'setError') === 'function') {
        this[refKey.key].setError(objError[key][0])
      }
    }
  }

  onGoHome = () => {
    Lib.historyAction(this.props.history, '/merchant/mapping-mode').goBack()
  }

  render() {
    const { userReducer } = this.props
    const { activeTabKey, isSaving, isFieldsTouched } = this.state

    return (
      <Layout className='um-layout'>
        <Prompt
          when={isFieldsTouched}
          message='You will lose any unsaved changes. Continue?'
        />
        <Header
          ref={ref => {this._header = ref}}
          defaultEditing
          title='MODENAME'
          onBack={this.onGoHome}
          onFieldTouched={this.onFieldTouched}
          extra={
            <Row type='flex' gutter={[12, 12]}>
              <Col>
                <Button type='primary' icon='save' loading={isSaving} onClick={this.onSave}>
                  Save
                </Button>
              </Col>
            </Row>
          }
        />
        {/* <div style={{ backgroundColor: '#fff', marginBottom: '24px' }}>
          <Tabs className='custom-margin-tab' tabBarGutter={0} activeKey={activeTabKey} onChange={this.onTabChange}>
            <TabPane key='groups' tab={<span><Icon type='team' />Merchant Groups</span>}>
              <PageAddMerchantGroup
                ref={ref => {this._group = ref}}
                tabKey='groups'
                setActiveKey={this.onTabChange}
                userReducer={userReducer}
                onFieldTouched={this.onFieldTouched}
              />
            </TabPane>
            <TabPane key='merchants' tab={<span><Icon type='shop' />Merchants</span>}>
              <PageAddMerchant
                ref={ref => {this._merchant = ref}}
                tabKey='merchants'
                setActiveKey={this.onTabChange}
                userReducer={userReducer}
                onFieldTouched={this.onFieldTouched}
              />
            </TabPane>
          </Tabs>
        </div> */}
      </Layout>
    )
  }
}

export default PageAdd