export default {
  "app_label": "estimation",
  "model_name": "subheadmap",
  "pagination": true,
  "columns": [
    {
      "name": "pk",
      "label": "ID",
      "help_text": null,
      "hidden": true,
      "identifier": true,
      "data_type": "int",
      "search": false,
      "sort": false
    },
    {
      "name": "map_name",
      "label": "Map name",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "ellipsis": true,
      "search": true,
      "sort": true
    },
    {
      "name": "sequence",
      "label": "Sequence",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "int",
      "search": true,
      "sort": true
    },
    {
      "name": "modified",
      "label": "Modified",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "datetime",
      "search": false,
      "sort": true
    }
  ],
  "filter_by": [
    {
      "label": "Map Name",
      "name": "map_name",
      "type": "api_reference",
      "api": {
        "url_name": "api:admin:estimation:subheadmap:map-name-autocomplete",
        "key": "map_name",
        "display": "map_name",
        "pagination": true
      }
    },
    {
      "label": "Subheading from",
      "name": "subheading_from",
      "type": "api_reference",
      "api": {
        "url_name": "api:admin:estimation:subheading:autocomplete",
        "key": "pk",
        "display": "label",
        "pagination": true
      }
    },
    {
      "label": "Subheading to",
      "name": "subheading_to",
      "type": "api_reference",
      "api": {
        "url_name": "api:admin:estimation:subheading:autocomplete",
        "key": "pk",
        "display": "label",
        "pagination": true
      }
    },
    {
      "label": "Subsection",
      "name": "subsection",
      "type": "api_reference",
      "api": {
        "url_name": "api:admin:estimation:subsection:autocomplete",
        "key": "pk",
        "display": "identifier",
        "pagination": true
      }
    },
    {
      "label": "Line template",
      "name": "line_template",
      "type": "api_reference",
      relates: ['subsection'],
      "api": {
        "url_name": "api:admin:estimation:linetemplate:autocomplete",
        "key": "pk",
        "display": "label",
        "pagination": true
      }
    },
  ],
  "export_import": null
}