import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Spin, Icon, Button, message, Modal } from 'antd'
import { get, isEmpty } from 'lodash'
import { RequestV2 as Request } from 'App/Utils'
import PageNotFound from 'App/Component/PageNotFound'
import MemberList from './List'

class PageMembers extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true, // digunakan saat read members pertama kali
      isReloading: false, // digunakan untuk reload ketika error get members
      isSaving: false, // digunakan untuk aksi reload (table), add dan remove user
      members: null,
      rawResponse: null,
    }
  }

  setThisState = (obj, callback = () => null, force) => {
    if (force) {
      return this.setState(obj, callback)
    } else {
      for (const key in obj) {
        if (obj[key] !== this.state[key]) {
          return this.setState(obj, callback)
        }
      }
    }
    return callback()
  }

  read = (keyLoading = 'isLoading', objParam = {}) => {
    this.setThisState({ [keyLoading]: true }, () => {
      Request(
        'get',
        'module-groupUser-group-users',
        {},
        // { limit: 100 },
        objParam,
        [this.props.groupId],
        this.readSuccess,
        this.readFailed,
        { keyLoading },
      )
    })
  }

  readSuccess = (response, extra) => {
    this.setState({ [extra.keyLoading]: false, rawResponse: response.data, members: response.data.results })
  }

  readFailed = (error, extra) => {
    const errorData = get(error, 'response.data', [])
    if (!isEmpty(errorData) && typeof errorData === 'object') {
      // Warning: Akan blank jika tidak menggunakan if (typeof object), karena bisa jadi response string
      for (const key in errorData) {
        message.error('Failed to read Members, error in ' + key + ' : ' + errorData[key][0], 5)
      }
    } else {
      const codeError = get(error.response, 'status', '[Code]') + ' ' + get(error.response, 'statusText', 'Unknown')
      message.error('Failed to read Member(s), code: ' + codeError, 5)
    }
    this.setState({ [extra.keyLoading]: false })
  }

  onRemoveMembers = (ids = [], callback = () => null) => {
    Modal.confirm({
      title: `Remove ${ids.length} members`,
      content: 'Are you sure want to kick the users from this group? This action cannot be undone.',
      okType: 'danger',
      okText: 'Remove',
      onOk: () => new Promise((resolve, reject) => {
        setTimeout(() => {
          Request(
            'delete',
            'module-groupUser-group-manageUser',
            {},
            { data: {users: ids} },
            [this.props.groupId],
            this.onRemoveMembersSuccess,
            this.onRemoveMembersFailed,
            { resolve, reject, callback },
          )
        }, 150)
      })
    })
  }

  onRemoveMembersSuccess = (response, extra) => {
    message.success('Member has removed')
    extra.resolve()
    extra.callback(true)
    this.read('isSaving')
  }

  onRemoveMembersFailed = (error, extra) => {
    const errorData = get(error, 'response.data', [])
    if (!isEmpty(errorData) && typeof errorData === 'object') {
      // Warning: Akan blank jika tidak menggunakan if (typeof object), karena bisa jadi response string
      for (const key in errorData) {
        message.error('Error in ' + key + ' : ' + errorData[key][0], 5)
      }
    } else {
      const codeError = get(error.response, 'status', '[Code]') + ' ' + get(error.response, 'statusText', 'Unknown')
      message.error('Failed to remove Member(s), code: ' + codeError, 5)
    }
    extra.reject('Failed remove members')
    this.setState({ isSaving: false }) // untuk loading saat bulk remove
  }

  onJoinUsers = (ids, callback) => {
    Request(
      'post',
      'module-groupUser-group-manageUser',
      {},
      { users: ids },
      [this.props.groupId],
      this.onJoinSuccess,
      this.onJoinFailed,
      { callback }
    )
  }

  onJoinSuccess = (response, extra) => {
    message.success('Add member successfully.')
    // this.read('isSaving')
    extra.callback(response.data)
  }

  onJoinFailed = (error, extra) => {
    // console.error(error.response)
    const errorData = get(error, 'response.data', [])
    if (!isEmpty(errorData) && typeof errorData === 'object') {
      // Warning: Akan blank jika tidak menggunakan if (typeof object), karena bisa jadi response string
      for (const key in errorData) {
        message.error('Error in ' + key + ' : ' + errorData[key][0], 5)
      }
    } else {
      const codeError = get(error.response, 'status', '[Code]') + ' ' + get(error.response, 'statusText', 'Unknown')
      message.error('Failed to add Member(s), code: ' + codeError, 5)
    }
    // Jangan lupa callback
    extra.callback()
  }

  componentDidMount() {
    this.read()
  }

  render() {
    const { readOnly, handleHistoryAction, groupId, groupName, userReducer } = this.props
    const { isLoading, isReloading, isSaving, members, rawResponse } = this.state

    if (isLoading) {
      return (
        <Row type='flex' justify='center' align='middle' style={{ margin: '30vh 0' }}>
          <Spin
            size='large'
            tip='Loading...'
            indicator={<Icon spin type='loading' style={{ fontSize: '4rem' }} />}
          />
        </Row>
      )
    }

    return members ? (
      <Spin spinning={isSaving}>
        <MemberList
          readOnly={readOnly}
          handleHistoryAction={handleHistoryAction}
          dataSource={members}
          rawMembers={rawResponse}
          groupId={groupId}
          groupName={groupName}
          userReducer={userReducer}
          onJoinUsers={this.onJoinUsers}
          refresh={this.read}
          removeMembers={this.onRemoveMembers}
        />
      </Spin>
    ) : (
      <PageNotFound
        title={`Error when load Member in Group ID: ${this.props.groupId}`}
        subTitle='Error when connect to server. Please try again or contact your admin!'
        extra={
          <Row type='flex' justify='center' gutter={[12, 12]}>
            <Col>
              <Button onClick={() => handleHistoryAction('push', '/user-group/group')}>Back Home</Button>
            </Col>
            <Col>
              <Button type='primary' loading={isReloading} onClick={() => this.read('isReloading')}>Try again</Button>
            </Col>
          </Row>
        }
      />
    )
  }
}

PageMembers.propTypes = {
  groupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  groupName: PropTypes.string.isRequired,
  userReducer: PropTypes.object.isRequired,
  handleHistoryAction: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
}

PageMembers.defaultProps = {
  readOnly: false,
}

export default PageMembers