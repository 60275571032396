import React from 'react'
import PropTypes from 'prop-types'
import { Button, Col, Form, message, Row, Card, Tabs } from 'antd'
import { get, cloneDeep, isEmpty } from 'lodash'
import { FormContext, FormProvider } from 'App/Component/Context/ContextForm'
import { Lib, RequestV2 as Request } from 'App/Utils'
import PromptContainer from 'App/Component/PromptContainer'
import DynamicField from 'App/Component/DynamicField'
import Lib2 from 'App/Component/Singlepage/Lib2'
import DynamicEditTable from 'App/Component/Singlepage/DynamicEditTable'
import History from 'App/Screens/SubModules/Component/History'

class PageSetting extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {

    }
  }

  // callback(err, values)
  validateFields = (callback = () => null) => {
    this._form.validateFieldsAndScroll({scroll: {offsetTop: 150}}, (err, values) => {
      if (err) return this.props.setActiveKey(this.props.tabKey)

      callback(values)
    })
  }

  setError = (key, errorValue) => {
    this.props.setActiveKey(this.props.tabKey, () => {
      const value = this._form.getFieldValue(key)
      this._form.setFields({
        [key]: { value, errors: [new Error(errorValue)] },
      })
    })
  }

  render() {
    const { user, fields } = this.props

    return (
      <div>
        <Row style={{ marginTop: '12px' }}>
          <FormProvider>
            <Form>
              {fields.map(elem => (
                <DynamicField
                  key={elem.dataIndex}
                  {...elem}
                  onBlur={this.onBlur}
                  dataRecord={get(user, elem.dataIndex)}
                  onPressEnter={this.onSave}
                  normFile={() => {
                    message.info('Please remove file from Admin section')
                  }}
                />
              ))}
              <FormContext.Consumer>
                {form => {this._form = form}}
              </FormContext.Consumer>
            </Form>
          </FormProvider>
        </Row>
      </div>
    )
  }
}

export default PageSetting