export default {
  "app_label": "estimation",
  "model_name": "subsection",
  "pagination": true,
  "columns": [
    {
      "name": "pk",
      "label": "ID",
      "help_text": null,
      "hidden": true,
      "identifier": true,
      "data_type": "int",
      "search": false,
      "sort": false
    },
    {
      "name": "name",
      "label": "Name",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "ellipsis": true,
      "search": true,
      "sort": true
    },
    {
      "name": "section",
      "label": "Section",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": null,
      "child_path": "name",
      "search": true,
      "sort": true
    },
    {
      "name": "status",
      "label": "Status",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "search": true,
      "sort": true
    },
    {
      "name": "order",
      "label": "Order",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "int",
      "search": true,
      "sort": true
    },
    {
      "name": "modified",
      "label": "Modified",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "datetime",
      "search": false,
      "sort": true
    }
  ],
  "filter_by": [
    {
      "label": "Status",
      "name": "status",
      "type": "choice",
      "choices": [
        [
          "active",
          "Active - Anyone can use"
        ],
        [
          "inactive",
          "Inactive - No one can use"
        ],
        [
          "unpublished",
          "Unpublished - Only certain people can use"
        ]
      ]
    },
    {
      "label": "Section",
      "name": "section",
      "type": "api_reference",
      "api": {
        "url_name": "api:admin:estimation:section:autocomplete",
        "key": "pk",
        "display": "name",
        "pagination": true
      }
    },
    {
      "label": "Show Height",
      "name": "show_height",
      "type": "switch"
    },
    {
      "label": "Height Locked",
      "name": "height_locked",
      "type": "switch"
    },
    {
      "label": "Show Width",
      "name": "show_width",
      "type": "switch"
    },
    {
      "label": "Width Locked",
      "name": "width_locked",
      "type": "switch"
    },
    {
      "label": "Show Length",
      "name": "show_length",
      "type": "switch"
    },
    {
      "label": "Length Locked",
      "name": "length_locked",
      "type": "switch"
    },
    {
      "label": "Show Quantity",
      "name": "show_quantity",
      "type": "switch"
    },
    {
      "label": "Has Pitch Factor",
      "name": "has_pitch_factor",
      "type": "switch"
    },
    {
      "label": "Null Dimension Warning",
      "name": "null_dimension_warning",
      "type": "switch"
    },
    {
      "label": "Deductible",
      "name": "deductible",
      "type": "switch"
    },
    {
      "label": "Opening Status",
      "name": "is_opening",
      "type": "switch"
    },
  ],
  "export_import": null
}