import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'antd'
import classNames from 'classnames'

class MostUsedProduct extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      maxUsage: this.getMaxUsage(props.products),
    }
  }

  updateMaxUsage = (products) => {
    this.setState({ maxUsage: this.getMaxUsage(products) })
  }

  getMaxUsage = (products) => {
    let maxUsage = 1
    for (const i in products) {
      if (maxUsage < products[i].usage_range.total) {
        maxUsage = products[i].usage_range.total
      }
    }
    return maxUsage
  }

  getWidthPct = (totalUsage) => {
    return (
      (totalUsage / this.state.maxUsage) * 100
    )
  }

  renderListItem = (product) => {
    const className = classNames('bar-item-clickable', {active: product.id === this.props.selectedProduct})

    return (
      <div key={product.id} className={className} onClick={() => this.props.onSelectItem(product.id)}>
        <Row type='flex' justify='space-between' style={{ paddingTop: '3px', paddingBottom: '6px', lineHeight: '18px' }}>
          <Col className='text-truncate' style={{ maxWidth: 'calc(100% - 54px)' }} title={product.name}>
            {product.name}
          </Col>
          <Col>
            {product.usage_range.total}
          </Col>
        </Row>
        <div style={{ border: '1px solid #e8e8e8', borderRadius: '4px' }}>
          <Progress name={product.name} width={`${this.getWidthPct(product.usage_range.total)}%`} />
        </div>
      </div>
    )
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateMaxUsage(nextProps.products)
  }

  render() {
    const { products } = this.props

    return (
      <div>
        {products
          .sort((a, b) => b.usage_range.total - a.usage_range.total)
          .map(this.renderListItem)}
      </div>
    )
  }
}

class Progress extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      width: 0,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.width !== nextProps.width) {
      this.setState({ width: nextProps.width })
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ width: this.props.width })
    }, 100)
  }

  render() {
    return (
      <div
        className='bar-progress'
        style={{ width: this.state.width }}
      />
    )
  }
}

MostUsedProduct.propTypes = {
  products: PropTypes.array,
  selectedProduct: PropTypes.number,
  onSelectItem: PropTypes.func,
}

MostUsedProduct.defaultProps = {
  products: [],
  selectedProduct: null,
  onSelectItem: () => {},
}

export default MostUsedProduct