import request from 'App/Utils/request.v3';

async function getS3Url(fileSubId, opts) {
  return new Promise((resolve, reject) => {
    request({
      urlKey: 'module-expertSystem-feedback-s3-file-sign',
      args: [fileSubId],
      ...opts,
      onSuccess: resolve,
      onFailed: reject,
    })
  })
}

export default getS3Url