import BaseButton from './Base'

// Button to download app for mac
class ButtonMac extends BaseButton {
  constructor(props) {
    super(props)

    this.ymlName = 'latest-mac.yml'
  }

  getPath = (yml) => {
    const dmgFile = yml.files.find(file => file.url.includes('.dmg'))
    return dmgFile && dmgFile.url
  }

  getButtonProps = () => ({
    icon: 'apple',
  })

  renderText() {
    return (
      `Download for Mac ${this.__version}`
    )
  }
}

export default ButtonMac