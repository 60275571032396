import React from 'react'
import { Button, Row, Col, Card, Icon, Popover, Modal, Form, Radio, message } from 'antd'
import { get, debounce } from 'lodash'
// import { Request } from 'App/Utils'
import styled from 'styled-components'
import Gallery from 'react-photo-gallery'
import CustomImage from './CustomImage'
import Carousel, { Modal as ModalReact,  ModalGateway } from 'react-images'
import { FormContext, FormProvider } from 'App/Component/Context/ContextForm'
import DynamicFieldHalfScreen from 'App/Component/DynamicFieldHalfScreen'
import ComponentAutocomplete from 'App/Component/Autocomplete'
import { isArray } from 'util'
import ListItem from './ListItem'
import LightboxModal from './LightboxModal'
import PropTypes from 'prop-types'

import 'App/Component/Singlepage/SingleStyle.css'
import 'react-image-lightbox/style.css'

const { confirm } = Modal

class PageImages extends React.Component {
  // State ---------------------
  state = {
    modeView: 'grid',
    isLoading: false,
    viewerIsOpen: false,
    currentImage: 0,
    isEditing: false,
    isSaving: false,
    visiblePopover: [],
    checkedAdditional: false,
    currentIndex: 0,
    showImageLightbox: false,
    visibleBulk: false,
    selectedImage: [],
    showLoadMore: false,
  }

  // Variable in Class ---------
  fileList = []

  //Function -------------------
  constructor(props) {
    super(props)
    this.handleModeChange = this.handleModeChange.bind(this)
    this.onSave = this.onSave.bind(this)
    this.handleClickAdd = this.handleClickAdd.bind(this)
    this.selectImage = this.selectImage.bind(this)
    window.onscroll = debounce(() => {
      if (
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.scrollHeight - 100
      ) {
        if (this.props.next && !this.state.showLoadMore && this.props.location.pathname.includes('images')) {
          this.setState({
            showLoadMore: true,
          }, () => this.props.loadMore(this.props.next, () => this.setState({showLoadMore: false})))
        }
      }
    }, 100)
  }

  handleModeChange(e) {
    this.setState({
      modeView: e.target.value
    })
  }

  getImagesComponent() {
    const { modeView } = this.state
    if (modeView === 'grid') {
      return this.getImagesThumbnail()
    } else if (modeView === 'list') {
      return this.getImagesList()
    }
    return null
  }

  closeLightbox = () => {
    this.setState({
      viewerIsOpen: false,
      currentImage: 0,
    })
  }

  handleVisibleChange = (visiblePopover, index) => {
    if (index || index === 0) {
      let willState = []
      willState[index] = visiblePopover
      this.setState({
        visiblePopover: willState,
      })
    } else {
      let willState = [...this.state.visiblePopover]
      for (const index in willState) {
        if (willState[index]) {
          willState[index] = visiblePopover
        }
      }
      this.setState({
        visiblePopover: willState
      })
    }
  }

  handleMoreAction = (action) => {
    if (action === 'edit') {
      const { visiblePopover } = this.state
      let currentImage = -1
      for (const index in visiblePopover) {
        if (visiblePopover[index]) {
          currentImage = index
          this.imageRecord = this.props.imagesRecord[index]
          break
        }
      }
      this.setState({
        isEditing: true,
        currentImage,
      })
    } else if (action === 'delete') {
      const exampleId = 'qwe-23-s-4'
      this.showDeleteConfirm(exampleId)
    }
    this.handleVisibleChange(false)
  }

  getAnother() {
    const { imagesRecord } = this.props
    this.srcImages = []
    for (const index in imagesRecord) {
      this.srcImages[index] = {
        src: imagesRecord[index].url,
      }
    }

    let arrTag = []
    for (const index in imagesRecord) {
      if (imagesRecord[index].tag) {
        if (isArray(imagesRecord[index].tag)) {
          arrTag[index] = [...imagesRecord[index].tag]
        } else {
          // this.cvTag(imagesRecord[index])
          const splitTag = imagesRecord[index].tag.split(',').filter(item => item)
          arrTag[index] = splitTag
        }
      }
    }

    const moreContent = (
      <Row>
        <Button type='default' block onClick={() => this.handleMoreAction('edit')}>
          <Icon type='edit' />
        </Button>
        <Button type='danger' block onClick={() => this.handleMoreAction('delete')}>
          <Icon type='delete' />
        </Button>
      </Row>
    )

    return imagesRecord.map((item, index, arr) => (
      <Col xs={24} sm={12} md={8} lg={8} xl={6} key={item.pk}>
        <Row
          type='flex'
          justify='center'
          style={{
            marginBottom: '12px',
          }}>
            <RoundedCard
              loading={this.state.isLoading}
              bodyStyle={{
                padding: 0
              }}>
                <div style={{
                  position: 'absolute',
                  height: '32px',
                  width: '32px',
                  bottom: 0,
                  right: 0,
                  marginBottom: '44px',
                  zIndex: '1',
                }}>
                  <div style={{
                    position: 'relative',
                    height: '100%',
                    width: '100%',
                  }}>
                    <a
                      href={item.url}
                      download
                      rel='noopener noreferrer'
                      target='_blank'
                    >
                      <div
                        className='download-hover'
                        style={{
                          position: 'absolute',
                          bottom: '0',
                          right: '0',
                          width: '32px',
                          height: '32px',
                          borderRadius: '32px',
                          backgroundColor: '#1690ff', //rgb(22,144,255)
                          opacity: '0.7',
                        }}
                      >
                        <Icon
                          type='download'
                          style={{
                            position: 'absolute',
                            color: 'white',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                          }}
                        />
                      </div>
                    </a>
                  </div>
                </div>
                <Row
                  className='image-hover'
                  onClick={() => {
                    this.setState({
                      viewerIsOpen: true,
                      currentImage: index,
                    })
                  }}
                >
                  <Image
                      src={item.url}
                      alt='example'
                    />
                </Row>
                <Row style={{padding: '10px 0', width: '100%', minHeight: '44px'}}>
                  <StyledSpanNoSelect style={{position: 'absolute', left: 0, marginLeft: '10px', maxWidth: '120px', maxHeight: '25px', overflow: 'hidden'}}>
                    {arrTag[index] && arrTag[index].map((tagItem, i) => (
                      <StyledFilter key={i}>
                        {tagItem}
                      </StyledFilter>
                    ))}
                  </StyledSpanNoSelect>
                  <Popover
                      title='More action'
                      placement="top"
                      content={moreContent}
                      trigger="click"
                      visible={this.state.visiblePopover[index]}
                      onVisibleChange={visible => this.handleVisibleChange(visible, index)}>
                      <div
                        style={{
                          position: 'absolute',
                          // width: '100%',
                          right: 0,
                          cursor: 'pointer',
                          marginRight: '10px',
                        }}
                      >
                        {/* <Button
                          type="danger"
                          size="small"
                          onClick={(e) => this.showDeleteConfirm(1234)}
                        > */}
                          <Icon type="more" rotate='90'/>
                        {/* </Button> */}
                      </div>
                    </Popover>
                </Row>
            </RoundedCard>
        </Row>
      </Col>
    ))
  }

  getImagesThumbnail() {
    const { readOnly, imagesRecord, removeImage } = this.props
    let srcImages = []
    for (const index in imagesRecord) {
      let width = 2
      let height = 2

      if (imagesRecord[index].width) {
        width = imagesRecord[index].width
      }
      if (imagesRecord[index].height) {
        height = imagesRecord[index].height
      }

      srcImages[index] = {
        pk: imagesRecord[index].pk,
        src: imagesRecord[index].file,
        width: parseInt(width),
        height: parseInt(height),
      }
    }

    const imageRendered = ({ index, left, top, photo, margin }) => (
      <CustomImage
        readOnly={readOnly}
        index={index}
        left={left}
        top={top}
        margin={margin}
        photo={photo}
        images={srcImages}
        key={photo.pk}
        imagesRecord={imagesRecord}
        removeImage={id => { removeImage([id], ids => this.deselectByIndex(index)) }}
        //   this.refreshSelected([id], ids => removeImage(ids))
        // }}
        selectImage={this.selectImage}
        selectedImage={this.state.selectedImage}
      />
    )

    return (
      <Row>
        {
          (srcImages.length > 0) ?
          <Gallery
            photos={srcImages}
            direction='column'
            columns={this.columnsByContainer ? this.columnsByContainer : this.getColumnsByWidthDimensions(window.innerWidth)}
            margin={5}
            renderImage={imageRendered}
          /> :
          'No image to display'
        }
      </Row>
    )
  }

  getSelectButton() {
    const { readOnly, imagesRecord } = this.props
    const { selectedImage } = this.state

    let textButton, selectedAll = true
    for (const i in imagesRecord) {
      if (!selectedImage[i]) {
        selectedAll = false
      }
    }
    if (!selectedAll || imagesRecord.length === 0) {
      textButton = 'Select All'
    } else {
      textButton = 'Deselect All'
    }

    if (imagesRecord.length > 0 && !readOnly) {
      return (
        <Button className='push-left' onClick={() => this.selectAll(!selectedAll)}>
          {textButton}
        </Button>
      )
    }
    return ''
  }

  selectImage(id, condition) {
    const { imagesRecord } = this.props
    let visibleBulk = condition
    if (!condition) {
      for (const i in this.state.selectedImage) {
        let index = -1
        for (const j in this.props.imagesRecord) {
          if (this.props.imagesRecord[j].pk === id) {
            index = j
            break
          }
        }
        if (i !== index) {
          if (this.state.selectedImage[i]) {
            visibleBulk = true
            break
          }
        }
        visibleBulk = false
      }
    }
    for (const i in imagesRecord) {
      if (imagesRecord[i].pk === id) {
        let selectedImage = this.state.selectedImage
        selectedImage[i] = condition
        this.setState({
          selectedImage,
          visibleBulk,
        })
        break
      }
    }
  }

  selectAll(selected = true) {
    const { imagesRecord } = this.props
    let selectedImage = []
    for (const i in imagesRecord) {
      selectedImage[i] = selected
    }
    this.setState({
      selectedImage,
      visibleBulk: selected,
    })
  }

  deselect = (ids, index) => {
    
  }

  deselectByIndex = index => {
    const { selectedImage } = this.state
    const remainSelected = []
    for (let i = 0; i < selectedImage.length; i += 1) {
      if (i !== index) remainSelected.push(selectedImage[i])
    }
    this.setState(prevState => ({ selectedImage: remainSelected, visibleBulk: remainSelected.filter(item => item).length > 0 }))
  }

  deselectBySomeIndex = someIndex => {
    const { selectedImage } = this.state
    const remainSelected = []
    for (let i = 0; i < selectedImage.length; i += 1) {
      if (someIndex.filter(item => item === i).length === 0) remainSelected.push(selectedImage[i])
    }
    this.setState({ selectedImage: remainSelected, visibleBulk: remainSelected.filter(item => item).length > 0 })
  }

  refreshSelected(idsDeleted, callback) {
    const { imagesRecord } = this.props
    let selectedImage = this.state.selectedImage
    let deletedIndexOf = []
    for (const i in imagesRecord) {
      for (const j in idsDeleted) {
        if (idsDeleted[j] === imagesRecord[i].pk) {
          deletedIndexOf.push(i)
          // selectedImage.splice(i, 1)
          break
        }
      }
    }
    for (let i = deletedIndexOf.length - 1; i >= 0; i--) {
      selectedImage.splice(deletedIndexOf[i], 1)
    }
    let visibleBulk = false
    for (const isSelected of selectedImage) {
      if (isSelected) {
        visibleBulk = true
        break
      }
    }
    this.setState({
      selectedImage,
      visibleBulk,
    }, () => callback(idsDeleted))
  }

  handleDeleteSelected() {
    this.showDeleteConfirm(null, confirmation => {
      if (confirmation) {
        const { imagesRecord } = this.props
        const { selectedImage } = this.state
        const idsWillRemove = []
        const willRemove = []
        let index = 0
        const tempImagesRecord = []
        for (const i in selectedImage) { // will skip when item array is empty, there is not null/undefined/false
          tempImagesRecord[Number(i)] = imagesRecord[i]
          if (selectedImage[i]) {
            idsWillRemove[index] = imagesRecord[i].pk
            willRemove[index] = imagesRecord[i]
            index++
          }
        }
        this.props.removeImage(idsWillRemove, ids => {
          const indexDeleted = []
          for (const id of ids) {
            for (let i = 0; i < tempImagesRecord.length; i += 1) { // be carefull when using for in, because key/index is string
              if (tempImagesRecord[i]) {
                if (tempImagesRecord[i].pk === id) {
                  indexDeleted.push(i)
                }
              }
            }
          }
          this.deselectBySomeIndex(indexDeleted)
        })
        // this.refreshSelected(idsWillRemove, ids => this.props.removeImage(ids))
      }
    })
  }

  getImagesList() {
    const { readOnly, imagesRecord } = this.props
    return (
      imagesRecord.length > 0 ?
      imagesRecord.map((imageData, index, arr) => (
        <Col xxl={8} xl={12} lg={12} md={24} sm={24} xs={24} key={imageData.pk}
          // style={{maxWidth: '600px'}}
        >
          <ListItem
            readOnly={readOnly}
            // userReducer={this.props.userReducer}
            imageData={imageData}
            index={index}
            handleClick={(e, currentIndex, showImageLightbox) => this.setState({
              currentIndex,
              showImageLightbox,
            })}
            selectImage={this.selectImage}
            selectedImage={this.state.selectedImage}
            removeImage={pk => this.props.removeImage([pk], ids => this.deselectByIndex(index))}
            currentProduct={this.props.currentProduct}
          />
        </Col>
      )) :
      'No image to display'
    )
  }

  updateDimensions = () => {
    const windowWidth = window.innerWidth
    let changeColumns = false
    let tempColumns = this.getColumnsByWidthDimensions(windowWidth)

    if (tempColumns !== this.columnsByContainer) {
      changeColumns = true
    }

    if (changeColumns) {
      this.columnsByContainer = tempColumns
      this.setState({
        modeView: this.state.modeView, // just for call render()
      })
    }
  }

  getColumnsByWidthDimensions(windowWidth) {
    let tempColumns
    // if (windowWidth < 750) {
    //   tempColumns = 1
    // // } else if (windowWidth < 1000) {
    // //   tempColumns = 2
    // } else if (windowWidth < 1250) {
    //   tempColumns = 2
    // } else if (windowWidth < 1500) {
    //   tempColumns = 3
    // } else if (windowWidth < 1750) {
    //   tempColumns = 3
    // } else if (windowWidth < 2000) {
    //   tempColumns = 4
    // } else if (windowWidth < 2250) {
    //   tempColumns = 5
    // } else if (windowWidth < 2500) {
    //   tempColumns = 6
    // } else if (windowWidth < 2750 || windowWidth >= 2750) {
    //   tempColumns = 7
    // }

    // Without sidebar
    const minWidth = 500, iterator = 250, maxCols = 8
    const lenOfRightTab = 192
    if (windowWidth / (minWidth + lenOfRightTab) > 1) {
      tempColumns = Math.ceil((windowWidth - (iterator + lenOfRightTab)) / minWidth) + 1
      if (tempColumns > maxCols) {
        tempColumns = maxCols
      }
    } else {
      tempColumns = 1
    }
    return tempColumns
  }

  cvTag(response) {
    const splitByComma = ['tag']
    for (let keys of splitByComma) {
      if (response) {
        let tempValue = []
        if (response[keys]) {
          const splitValue = response[keys].split(',').filter(item => item)
          for (const index in splitValue) {
            tempValue[index] = splitValue[index]
          }
          response[keys] = tempValue
        } else {
          response[keys] = tempValue
        }
      }
    }
  }

  handleClickAdd(e) {
    this.setState({
      isEditing: true,
    })
  }

  onSave(event) {
    const {validateFieldsAndScroll} = this.form;
    validateFieldsAndScroll( (err, values) => {
      if (!err) {
        // Do Save
        this.setState({ isSaving: true }, () => {
          this.props.saveImage(values, this.imageFields, () => {
            this.setState({ isEditing: false, isSaving: false })
          })
        })
      } else {
        message.error('Cannot validate field' + err)
      }
    })
  }

  handleCheckAdditional = (checked) => {
    this.setState({
      checkedAdditional: !checked,
    })
  }

  normFile = (e, limit) => {
    // let dataRecord = this.props.location.state.dataRecord
    if (Array.isArray(e)) {
      return e;
    }
    if (!limit) {
      limit = 0
    } else if (limit > 0) {
      limit *= -1
    }
    this.fileList = e.fileList
    if (e.fileList.length === 0) {
      this.titleHasTouched = false
    } else if (e.file.status !== 'removed') {
      if (!this.titleHasTouched && !this.form.getFieldValue('title')) {
        // this validation (flow) is equal with (!(bool1 or bool2))
        // have bug when upload file, remove all file, upload file, clear title, upload file again.
        this.titleHasTouched = true // without declaration
        this.form.setFieldsValue({ title: e.file.name.split('.')[0] })
      }
    }
    this.fileList = this.fileList.slice(limit)
    return e && this.fileList;
  }

  showDeleteConfirm = (id, callback) => {
    confirm({
      title: 'Are you sure delete this file?',
      content: 'This action will delete on the database',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        callback && callback(true)
      },
      onCancel: () => {
        callback && callback(false)
        // this.setState({isEditing: this.state.isEditing}) // cuma buat biar ke render aja
      },
    })
  }

  componentDidMount() {
    this.imageFields = ComponentAutocomplete.productImages
    // this.dummyRead()
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  render() {
    const { viewerIsOpen, currentImage, isEditing, isSaving, visibleBulk } = this.state
    // Lightbox new
    const { currentIndex, showImageLightbox } = this.state

    const { imagesRecord, readOnly } = this.props

    return (
      <Card>
        <Row type='flex' justify='space-between' gutter={[12, 12]} style={{ marginBottom: '6px' }}>
          <Col>
            <Row type='flex' gutter={[12, 12]}>
              <Col>
                <Button.Group>
                  <Button disabled={readOnly} type='primary' onClick={this.handleClickAdd}>
                    <Icon type='plus' />
                    {`Add Image`}
                  </Button>
                </Button.Group>
              </Col>
              <Col>
                <Button.Group>
                  { this.getSelectButton() }
                  {
                    visibleBulk &&
                    <Button type='danger' onClick={e => this.handleDeleteSelected()}>
                      <Icon type='delete' />
                      {`Delete Selected Image`}
                    </Button>
                  }
                </Button.Group>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row className='right flex' gutter={[7, 7]}>
              <Col>
                <Button icon='reload' onClick={() => this.props.refresh()}>
                  Refresh
                </Button>
              </Col>
              <Col>
                <Radio.Group defaultValue={this.state.modeView} buttonStyle='solid' onChange={this.handleModeChange}>
                  <Radio.Button value='grid'>
                    <Icon type='appstore' />
                  </Radio.Button>
                  <Radio.Button value='list'>
                    <Icon type='unordered-list' />
                  </Radio.Button>
                </Radio.Group>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[4, 0]} style={{ marginLeft: '-6px', marginRight: '-6px' }}>
          { this.getImagesComponent() }
        </Row>
        {
          this.props.next && this.state.showLoadMore &&
          <Row type='flex' justify='center' style={{width: '100%', paddingTop: '12px'}}>
            <Button type='primary' shape='round' loading />
          </Row>
        }
        { isEditing &&
          <Modal
            visible={isEditing}
            title='Add Image'
            className='img-modal'
            onCancel={() => {this.setState({isEditing: false})}}
            footer={[
              <Button key="back" onClick={() => this.setState({isEditing: false})}>
                Cancel
              </Button>,
              <Button key="submit" type="primary" icon="save" loading={isSaving} onClick={this.onSave}>
                Save
              </Button>,
            ]}
            width={'80vw'}
            style={{
              top: '20px',
              height: '90v'
            }}
          >
            <Row>
              <Col md={24} sm={24}>
                <Row span={24}>
                  <Col style={{
                    padding: '24px 12px',
                    overflow: 'auto',
                  }}>
                    <FormProvider>
                      <Form layout="horizontal">
                        {isEditing && this.imageFields && this.imageFields.map((elem, index) => {
                          const dataField = (elem.dataIndex) && this.imageRecord && get(this.imageRecord, elem.dataIndex)
                          return (
                            <DynamicFieldHalfScreen
                              {...elem}
                              key={index}
                              userReducer={this.props.userReducer}
                              dataRecord={dataField}
                              handleCheckAdditional={this.handleCheckAdditional}
                              checkedAdditional={this.state.checkedAdditional}
                              normFile={this.normFile}
                            />
                          )
                        })}
                        <FormContext.Consumer>
                          {(form) => {this.form = form}}
                        </FormContext.Consumer>
                      </Form>
                    </FormProvider>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Modal>
        }

        <ModalGateway>
          {viewerIsOpen ? (
            <ModalReact
              onClose={this.closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={this.srcImages.map(x => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title
                }))}
              />
            </ModalReact>
          ) : null}
        </ModalGateway>
        {
          showImageLightbox &&
          <LightboxModal
            imagesRecord={imagesRecord}
            currentIndex={currentIndex}
            callback={obj => this.setState({[obj.key]: obj.value})}
          />
        }
      </Card>
    )
  }
}

PageImages.propTypes = {
  saveImage: PropTypes.func,
}

PageImages.defaultProps = {
  saveImage: () => null,
}

export default PageImages

const RoundedCard = styled(Card)`
  border-radius: 12px !important;
  width: 180px;
`

const Image = styled.img`
  border-radius: 12px 12px 0 0 !important;
  width: 180px;
  height: 180px;
`

const StyledFilter = styled.span`
  background-color: #1690ff;
  padding: 2px 4px;
  margin: 2px;
  font-size: 10px;
  border-radius: 4px;
  color: #fff;
  display: inline-block;
  
`;

const StyledSpanNoSelect = styled.span`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
