import React from 'react'
import { Icon, Tooltip } from 'antd'
import { get } from 'lodash'

const statuses = {
  'inactive': { // order ini harus di atas "active"
    initial: 'i',
    iconType: 'close-circle',
    color: '#7f8c8d',
  },
  'active': {
    initial: 'a',
    iconType: 'check-circle',
    color: '#27ae60',
  },
  'unpublished': {
    initial: 'u',
    iconType: 'exclamation-circle',
    color: 'orange',
  },
  'unknown': {
    initial: '?',
    iconType: 'question-circle',
    color: '#7f8c8d',
    label: 'Unknown',
  }
}

const getObjStatus = (strStatus) => {
  let objStatus = get(statuses[strStatus])
  if (typeof strStatus === 'string' && objStatus === undefined) {
    for (const key in statuses) {
      if (strStatus.toLowerCase().includes(key.toLowerCase())) {
        objStatus = statuses[key]
        break
      }
    }
    if (!objStatus) objStatus = statuses.unknown
  }

  return objStatus
}

const getInitialStatus = (strStatus) => {
  const objStatus = getObjStatus(strStatus)
  return (
    <Tooltip placement='left' title={objStatus.label || strStatus}>
      <Icon type={objStatus.iconType} style={{ color: objStatus.color, cursor: 'default' }} />
    </Tooltip>
  )
}

export { getInitialStatus, getObjStatus }