import React from 'react'

const ErrorLabel = React.memo(function WarningLabel(props) {
  return (
    <div style={{ borderLeft: '3px solid #f5222d', backgroundColor: '#fba7ab', padding: '8px', marginBottom: '12px', fontWeight: 500 }}>
      <b>Error : </b> {props.errorMessage || 'Unknown.'}
    </div>
  )
})

export default ErrorLabel