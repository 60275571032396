// import React from "react";
// import { Tabs, Icon } from "antd";
import { BaseList, BaseDetail } from "App/Component/Bases";
import descriptor from "./descriptor";
import fields from "./fields";
import childFields from "./BluebeamVariant/fields";
import options from "./options";
// import BluebeamVariant from './BluebeamVariant';

const title = "Bluebeam Mappings";
const urlData = "bluebeamMappings";

// const { TabPane } = Tabs;

const children = {
  appName: 'estimation',
  modelName: 'mappingvariants',
  name: urlData,
  sub: 'mappingVariants',
  dataIndex: 'mapping_variants',
  identifier: {
    name: 'mapping',
    is_array: false
  },
  bulk_request: false,
  urlSub: 'mappingVariants-read',
  paramProps: { mapping: 'pk' },
  add: true,
  edit: true,
  data: false,
  permissions: {
    bulk_delete: true,
    payload: true,
    // bulk_url: 'api:admin:estimation:prefillline:list',
    initialrow: 1,
    add: true
  },
  filter_selected: { subsection: ['static_prefill_set'] },
  rules: {
    button: {
      type: {
        add: 'default',
        edit: 'default'
      },
      size: 'default'
    },
    input: {
      size: 'default'
    },
    select: {
      size: 'default'
    }
  },
  fields: childFields,
}

class List extends BaseList {
  constructor(props) {
    super(props)

    this.title = title;
    this.urlData = urlData;
    this.propsList = {
      descriptor,
      exportImport: options.exportImportOptions,
    }
  }
}

class Detail extends BaseDetail {
  constructor(props) {
    super(props)

    this.title = title;
    this.urlData = urlData;
    this.fields = fields;
    this.children = children;
    // this.propsDetail = {
    //   getAnotherTabPanes: this.getVariantInformation,
    //   getAnotherTabPanesAdd: this.getVariantInformation,
      // urlKeyHistory: 'api:admin:estimation:formula:detail-revision',
      // keyLabel: 'name',
    // }
  }

  // getVariantInformation = () => {
  //   return (
  //     <TabPane
  //       key='information'
  //       tab={
  //         <span>
  //           <Icon type='pic-center' />
  //           Mapping variants
  //         </span>
  //       }
  //     >
  //       <BluebeamVariant {...this.props} /> 
  //     </TabPane>
  //   )
  // }
}

export default { List, Detail }