import { createBrowserHistory } from "history";

import { Store, Action } from "App/Redux";
import { Sites } from "App/Config";
import request from "./request.v3";

const browserHistory = createBrowserHistory();

async function revokeToken(
  token = Store.getState().userReducer.token,
  removeSession = true
) {
  await request({
    method: "post",
    urlKey: "user-revoke-aut",
    data: {
      token,
      client_id: Sites.oauth.id,
      client_secret: Sites.oauth.secret,
    },
    onBefore(options) {
      delete options.headers["Authorization"];
    },
  });

  if (removeSession) Store.dispatch(Action.RemoveUserSessionProperties());

  return null;
}

export default async function () {
  await revokeToken();
  browserHistory.replace("/login");
}
