import React from "react";
import { Prompt } from "react-router";
import { Layout, Row, Col, Icon, Spin, Button, message, Tabs } from "antd";
import { get, isEmpty } from "lodash";
import { RequestV2 as Request } from "App/Utils";
import Header from './Header'
import PageError from 'App/Component/PageError'
import { PageMembersAdd, PagePermissions, PageModules, PageReports, PageJobQueues } from './Pages'
import LoadingRef from 'App/Component/Singlepage/LoadingRef'

const { TabPane } = Tabs;

const ERROR_MESSAGE = 'Failed to fetch'

class GroupAdd extends React.PureComponent {
  constructor(props) {
    super(props)

    this.refKeys = [
      { key: '_member', keyBody: 'user_set', label: 'Members' },
      { key: '_header', keyBody: 'name', label: 'Group Name' },
      { key: '_module', keyBody: 'allowed_modules', label: 'Modules' },
      { key: '_permission', keyBody: 'permissions', label: 'Permissions' },
      { key: '_report', keyBody: 'allowed_reports', label: 'Reports' },
      { key: '_branch', keyBody: 'branch_queues', label: 'Job Queues' },
    ]

    this.state = {
      isLoading: true,
      errorResponse: null,
      isSaving: false,
      activeTabKey: 'members',
      groupHelper: null,
      filteredPermissions: [],
      errorMessage: ERROR_MESSAGE,
      isFieldsTouched: false,
    }
  }

  requestHelper = (keyLoading = 'isLoading', callback = () => null) => {
    this.setState({ [keyLoading]: true }, () => {
      Request(
        'get',
        'module-helper-group',
        {},
        {},
        [],
        this.requestHelperSuccess,
        this.requestHelperFailed,
        { keyLoading, callback }
      )
    })
  }

  requestHelperSuccess = (response, extra) => {
    this.setState({ groupHelper: response.data, [extra.keyLoading]: false })
  }

  requestHelperFailed = (error, extra) => {
    this.setState({ [extra.keyLoading]: false, errorResponse: get(error, 'response') }, () => {
      extra.callback()
    })
  }

  setThisState = (obj, callback = () => null) => {
    if (typeof obj === 'object') {
      for (const key in obj) {
        if (obj[key] !== this.state[key]) {
          return this.setState(obj, callback)
        }
      }
    }
    callback()
  }

  onGoHome = () => {
    this.props.history.push('/user-group/group')
  }

  onSave = () => {
    const values = {}
    for (const refItem of this.refKeys) {
      if (get(this[refItem.key], 'validateFields')) {
        values[refItem.keyBody] = this[refItem.key].validateFields()
      }
    }

    this.setState({ isSaving: true }, () => {
      setTimeout(() => {
        this.save(values)
      })
    }, 250)
  }

  save = (values) => {
    Request(
      'post',
      'module-groupUser-group-create',
      {},
      values,
      [],
      this.saveSuccess,
      this.saveFailed,
    )
  }

  saveSuccess = (response) => {
    message.success(response.data.name + ' has been saved. will redirect to detail URL...')
    this.setState({ isFieldsTouched: false }, () => {
      setTimeout(() => {
        this.props.history.push(`/user-group/group/${response.data.pk}`)
      }, 1000)
    })
    // this.setState({ isSaving: false, group: response.data, isFieldsTouched: false })
  }

  saveFailed = (error) => {
    const errors = get(error, ['response', 'data'])
    // console.log(error.response) // don't show "error.response" to console
    if (typeof errors === 'object' && !isEmpty(errors)) {
      if (errors.detail) message.error(errors.detail)
      this.setFieldsError(errors)
    } else {
      const codeError = get(error.response, 'status', '[Code]') + ' ' + get(error.response, 'statusText', 'Unknown')
      message.error('Failed to save Group, code: ' + codeError, 5)
    }
    this.setState({ isSaving: false })
  }

  setFieldsError = (objError) => {
    for (const key in objError) {
      const refKey = this.refKeys.find(ref => ref.keyBody === key)
      if (refKey && typeof get(this[refKey.key], 'setError') === 'function') {
        message.error('Error in ' + refKey.label + ' : ' + objError[key][0], 5)
        this[refKey.key].setError(objError[key][0])
      }
    }
  }

  // Children tidak boleh diberi fungsi ini
  setTouched = (isFieldsTouched, callback) => {
    this.setState({ isFieldsTouched }, callback)
  }

  // Set touched untuk children input
  onFieldTouched = (isFieldsTouched, callback = () => null) => {
    // Don't use isFieldsTouched param.
    this.setTouched(true, callback)
  }

  onTabChange = (activeTabKey, callback = () => null) => {
    this.setState({ activeTabKey }, callback)
  }

  getFilteredPermissions = (displayPermissions, chosenModules) => {
    return displayPermissions.filter(perm => chosenModules.some(moduleId => moduleId === perm.pk))
  }

  onModuleChange = (keys, callback = () => null) => {
    this._loading.setIsLoading(true, () => {
      setTimeout(() => {
        const filteredPermissions = this.getFilteredPermissions(this.state.groupHelper.permission, keys)
        this.setState({ filteredPermissions }, () => {
          this._loading.setIsLoading(false)
          callback()
          // Special statement
          this._permission.setThisState({ errorMessage: '' })
          // -----------------
        })
      }, 100)
    })
  }

  componentDidMount() {
    this.requestHelper('isLoading')
  }

  render() {
    const { activeTabKey, filteredPermissions, groupHelper, isLoading, errorResponse, isSaving, errorMessage, isFieldsTouched } = this.state;

    if (isLoading) {
      return (
        <div style={{ paddingTop: 100, textAlign: "center" }}>
          <Spin
            size="large"
            tip="Loading..."
            indicator={
              <Icon type="loading" style={{ fontSize: "4rem" }} spin />
            }
          />
        </div>
      )
    }

    return groupHelper ? (
      <Layout style={{ marginBottom: '16px' }}>
        <Prompt
          when={isFieldsTouched}
          message='You will lose any unsaved changes. Continue?'
        />
        <Layout>
          <Header
            ref={ref => {this._header = ref}}
            defaultEditing
            title='Group Name'
            onBack={this.onGoHome}
            onFieldTouched={this.onFieldTouched}
            extra={
              <Row type='flex'>
                <Button type='primary' icon='save' loading={isSaving} onClick={this.onSave}>
                  Save
                </Button>
              </Row>
            }
          />
          <Row style={{ backgroundColor: '#fff' }}>
            <Tabs className='custom-margin-tab' tabBarGutter={0} activeKey={activeTabKey} onChange={this.onTabChange}>
              <TabPane forceRender key='members' tab={<span><Icon type='team' />Members</span>}>
                <PageMembersAdd
                  // Tolong kalau bisa jangan kirim history,
                  // karena component akan selalu render jika perpindahan tab menggunakan path.
                  ref={ref => {this._member = ref}}
                  history={this.props.history}
                  tabKey='members'
                  setActiveKey={this.onTabChange}
                  userReducer={this.props.userReducer}
                  onFieldTouched={this.onFieldTouched}
                />
              </TabPane>
              <TabPane forceRender key='modules' tab={<span><Icon type='appstore' />Modules</span>}>
                <PageModules
                  ref={ref => {this._module = ref}}
                  tabKey='modules'
                  setActiveKey={this.onTabChange}
                  userReducer={this.props.userReducer}
                  groupHelper={groupHelper}
                  onFieldTouched={this.onFieldTouched}
                  onChange={this.onModuleChange}
                />
              </TabPane>
              <TabPane forceRender key='permissions' tab={<span><Icon type='safety-certificate' />Permissions</span>}>
                <PagePermissions
                  ref={ref => {this._permission = ref}}
                  tabKey='permissions'
                  setActiveKey={this.onTabChange}
                  userReducer={this.props.userReducer}
                  // groupHelper={groupHelper}
                  displayPermissions={filteredPermissions}
                  onFieldTouched={this.onFieldTouched}
                />
              </TabPane>
              <TabPane forceRender key='reports' tab={<span><Icon type='audit' />Reports</span>}>
                <PageReports
                  ref={ref => {this._report = ref}}
                  tabKey='reports'
                  setActiveKey={this.onTabChange}
                  userReducer={this.props.userReducer}
                  groupHelper={groupHelper}
                  onFieldTouched={this.onFieldTouched}
                />
              </TabPane>
              <TabPane forceRender key='job-queues' tab={<span><Icon type='branches' />Job Queues</span>}>
                <PageJobQueues
                  ref={ref => {this._branch = ref}}
                  tabKey='job-queues'
                  setActiveKey={this.onTabChange}
                  userReducer={this.props.userReducer}
                  groupHelper={groupHelper}
                  onFieldTouched={this.onFieldTouched}
                />
              </TabPane>
            </Tabs>
          </Row>
        </Layout>
        <LoadingRef defaultHide ref={ref => {this._loading = ref}} />
      </Layout>
    ) : (
      <PageError
        errorResponse={errorResponse}
        onGoBack={this.onGoHome}
        onReload={this.requestHelper}
      />
    )
  }
}

export default GroupAdd