import React from 'react'
import PropTypes from 'prop-types'
import { Card, Row, Col, Empty, Icon } from 'antd'

class CardItems extends React.PureComponent {
  render() {
    const { title, items, selectedGroups } = this.props

    return (
      <Card title={title} type='inner' style={{ height: '100%' }} bodyStyle={{ padding: '0' }}>
        {items.length > 0 ? (
          items.map((item) => {
            const isSelected = selectedGroups.findIndex(selected => item.__groups.findIndex(groupId => groupId === selected) !== -1) !== -1
            return (
              <div
                key={item.pk}
                style={{
                  borderLeft: isSelected ? '3px solid #1890ff' : '3px solid #8c8c8c',
                  backgroundColor: isSelected ? '#d9ecfd' : '#d8d8d8',
                  padding: '8px',
                  margin: '12px 0',
                }}
              >
                <Row type='flex' justify='space-between'>
                  <Col style={{ fontWeight: 500 }}>
                    {item.name}
                  </Col>
                  <Col>
                    <Icon
                      type='check-circle'
                      theme='filled'
                      style={{
                        color: isSelected ? '#1890ff' : '#afafaf',
                        marginRight: '6px'
                      }}
                    />
                  </Col>
                </Row>
              </div>
            )
          })
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Has no one access" />
        )}
      </Card>
    )
  }
}

CardItems.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.array, // array of object, object must have key "__groups" (array group ids)
  selectedGroups: PropTypes.array,
}

CardItems.defaultProps = {
  items: [],
  selectedGroups: [],
}

export default CardItems