export default [
  {
    title: 'Name',
    type: 'text',
    data: '',
    required: true,
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'name',
    order: 0,
  }, {
    title: 'Group Name',
    dataIndex: 'group_name',
    type: 'text',
    initialValue: '',
    inputProps: {
      style: {textTransform: 'uppercase'},
    },
  }, {
    title: 'Slug',
    type: 'text',
    data: '',
    required: true,
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'slug',
    order: 1,
  },{
    title: 'Rate',
    type: 'select',
    data: [
      {
        alias: "1",
        value: 'Rate 1'
      },{
        alias: "2",
        value: 'Rate 2'
      },{
        alias: "3",
        value: 'Rate 3'
      }
    ],
    initialValue: true,
    note_text: '',
    additional: '',
    dataIndex: 'rate',
    order: 2,
  }, {
    title: 'Color',
    type: 'color',
    dataIndex: 'color',
    data: '',
    required: true,
    initialValue: '',
    note_text: 'Color for Job Branch Summary in Dashboard',
  }, {
    title: 'Status',
    type: 'select',
    disableAllowClear: true,
    data: [
      {
        alias: true,
        value: 'Active'
      }, {
        alias: false,
        value: 'Inactive'
      }
    ],
    initialValue: true,
    note_text: '',
    additional: '',
    dataIndex: 'status',
    order: 2,
  },
];
