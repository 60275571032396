import { createMD5 } from 'hash-wasm';
import { get, noop } from 'lodash';

import request from 'App/Utils/request.v3';

class s3Manager {
  constructor(props) {
    this._preparedData = null
    this._onUploadProgress = noop
    this._onStepProgress = noop
    this.patchFields(props)
  }

  getSubId = () => get(this._preparedData, this._keySubId || 'pk')

  patchFields({ file, keySubId, prepareOpts, signOpts, confirmOpts, onUploadProgress, onStepProgress }) {
    if (file !== undefined) this._file = file
    if (keySubId !== undefined) this._keySubId = keySubId
    if (prepareOpts !== undefined) this._prepareOpts = prepareOpts
    if (signOpts !== undefined) this._signOpts = signOpts
    if (confirmOpts !== undefined) this._confirmOpts = confirmOpts
    if (onUploadProgress !== undefined) this._onUploadProgress = onUploadProgress
    if (onStepProgress !== undefined) this._onStepProgress = onStepProgress

    return this
  }

  async prepare() {
    this._onStepProgress('Preparing')
    console.log('preparing', this)
    return new Promise(async (resolve, reject) => {
      const chunkSize = 64 * 1024
      const hashFuncMD5 = await createMD5()
      hashFuncMD5.init()
  
      for (let i = 0; i < this._file.size; i += chunkSize) {
        const uint8Array = new Uint8Array(await this._file.slice(i, i + chunkSize).arrayBuffer())
        hashFuncMD5.update(uint8Array)
      }
  
      const md5Checksum = hashFuncMD5.digest('hex')
      request({
        method: 'post',
        ...this._prepareOpts,
        data: {
          md5_checksum: md5Checksum,
          size: this._file.size,
          filename: this._file.name,
          ...get(this._prepareOpts, 'data'),
        },
        onSuccess: res => {
          this._preparedData = res;
          resolve(res)
        },
        onFailed: reject,
      })
    })
  }

  async getBase64(file) {
    console.log('getBase64', this)
    return new Promise(resolve => {
      const reader = new FileReader()
      reader.onload = e => {
        resolve(e.target.result
          .replace('data:', '')
          .replace(/^.+,/, ''))
      }
      reader.readAsDataURL(file)
    })
  }
  
  async signUpload() {
    console.log('signUpload', this)
    this._onStepProgress('Sign in')
    return new Promise(async (resolve, reject) => {
      // 4KB file
      const headFileBase64 = await this.getBase64(this._file.slice(0, 4 * 1024))
      request({
        method: 'post',
        urlKey: 'module-expertSystem-feedback-s3-file-sign',
        ...this._signOpts,
        args: [this.getSubId()],
        data: { head: headFileBase64 },
        onSuccess: resolve,
        onFailed: reject,
      })
    })
  }

  async uploadS3(s3Data) {
    console.log('uploadS3', this)
    this._onStepProgress('Uploading')
    return new Promise((resolve, reject) => {
      request({
        method: 'post',
        url: s3Data.url,
        data: { ...s3Data.fields, file: this._file },
        requestDataType: 'formData',
        onBefore: (opt) => delete opt.headers,
        onUploadProgress: this._onUploadProgress,
        onSuccess: resolve,
        onFailed: reject,
      })
    })
  }

  async confirmUpload() {
    console.log('confirmUpload', this)
    if (!this._confirmOpts) return

    this._onStepProgress('Confirmation Upload')
    return new Promise((resolve, reject) => {
      request({
        method: 'post',
        ...this._confirmOpts,
        data: {
          ...get(this._confirmOpts, 'data'),
          file: this.getSubId(),
          ...get(this._confirmOpts, 'getData', window.noop)(this._preparedData),
        },
        // requestDataType: 'formData',
        onUploadProgress: (axiosProgressEvent) => console.log('confirmUpload onUploadProgress', axiosProgressEvent),
        onSuccess: resolve,
        onFailed: reject,
      })
    })
  }

  async upload() {
    return new Promise((resolve, reject) => {
      this.prepare()
        .then(() => this.signUpload())
        .then(s3Data => this.uploadS3(s3Data))
        .then(() => this.confirmUpload())
        .then(resolve)
        .catch(reject)
    })
  }
}

export default s3Manager