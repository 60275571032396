import React from 'react'
import { Layout, Row, Col, Spin, Icon, Typography, Tabs, Tooltip } from 'antd'
import { get } from 'lodash'
import moment from 'moment'
import { RequestV2 as Request, Modules, Lib } from 'App/Utils'
import PageError from 'App/Component/PageError'
import { PageStatistics, PageExportMapping, PagePmHardware, PagePmTimber, PageSkuMapping, PageSetting } from './Pages'

const { TabPane } = Tabs

class PageDetail extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      errorResponse: null,
      merchant: null,
      readOnly: !get(props, 'currentPermissionSet', []).some(code => (code || '').includes('.change_'))
    }

    this.headerDescriptions = [
      { key: 'name', label: 'Name' },
      { key: 'mapping_mode.name', label: 'Mode' },
      { key: 'merchant_group.name', label: 'Group' },
      { key: 'branch.name', label: 'Branch' },
      { key: 'email_schedule', label: 'Email Schedule', type: 'bool' },
      { key: 'modified', label: 'Modified at', type: 'date' },
    ]
  }

  retrieve = (keyLoading = 'isLoading', callback = () => null) => {
    this.setState({ [keyLoading]: true }, () => {
      Request(
        'get',
        'module-merchant-merchant-detail',
        {},
        {},
        [this.props.match.params.id],
        this.retrieveSuccess,
        this.retrieveFailed,
        { keyLoading, callback }
      )
    })
  }

  retrieveSuccess = (response, extra) => {
    this.setState({ [extra.keyLoading]: false, merchant: response.data })
  }

  retrieveFailed = (error, extra) => {
    this.setState({ [extra.keyLoading]: false, errorResponse: get(error, 'response') }, () => {
      extra.callback()
    })
  }

  getDescElems = () => {
    const { merchant } = this.state
    return (
      <Row className='ant-desc-custom' type='flex' gutter={[12, 12]} style={{ marginTop: '12px' }}>
        {
          this.headerDescriptions.map(item => (
            <Col key={item.key} xs={24} sm={12} lg={8}>
              <Row className='header-content-item'>
                <span className='ant-descriptions-item-label ant-descriptions-item-colon'>
                  {item.label}
                </span>
                <span className='ant-descriptions-item-content'>
                  {this.getValue(merchant, item.key, item.type)}
                </span>
              </Row>
            </Col>
          ))
        }
      </Row>
    )
  }

  getValue = (record, key, type) => {
    if (type === 'long_date') {
      return (
        <>
          {moment(record[key]).format('ddd, DD MMM YYYY hh:mm')}
          <Typography.Text type='secondary'> ({moment(record[key]).fromNow()})</Typography.Text>
        </>
      )
    } else if (type === 'date') {
      return moment(record[key]).format('ddd, DD MMM YYYY hh:mm')
    } else if (type === 'bool') {
      return Modules.renderStatusAIU(get(record, key))
    } else {
      return get(record, key, '-')
    }
  }

  onGoHome = () => {
    Lib.historyAction(this.props.history, '/merchant/merchant').goBack()
  }

  setMerchant = (merchant) => {
    this.setState({ merchant })
  }

  componentDidMount() {
    this.retrieve()
  }

  render() {
    const { userReducer, history, location, match } = this.props
    const { isLoading, errorResponse, merchant, readOnly } = this.state

    if (isLoading) {
      return (
        <div style={{ paddingTop: 100, textAlign: "center" }}>
          <Spin
            size="large"
            tip="Loading..."
            indicator={
              <Icon type="loading" style={{ fontSize: "4rem" }} spin />
            }
          />
        </div>
      )
    }

    return merchant ? (
      <Layout className='um-layout'>
        <div className='ant-page-header has-footer'>
          <Row type='flex' justify='space-between' gutter={[12, 12]} style={{ borderBottom: '1px solid #e8e8e8' }}>
            <Col>
              <Row type='flex' align='middle'>
                <Icon className='ant-page-header-back-button' type='arrow-left' style={{ marginRight: '16px' }} onClick={this.onGoHome} />
                <Col className='header-title text-ellipsis'>
                  Merchant
                </Col>
                {readOnly && (
                  <Col style={{ fontSize: '12px', fontWeight: 500, backgroundColor: '#1890ff', color: '#fff', padding: '0 12px 2px 12px', borderRadius: '14px', marginLeft: '12px' }}>
                    Read Only
                  </Col>
                )}
                {merchant.on_hold && (
                  <Col style={{ fontSize: '12px', fontWeight: 500, backgroundColor: '#FF0000', color: '#fff', padding: '0 12px 2px 12px', borderRadius: '14px', marginLeft: '12px' }}>
                    On Hold
                    <Tooltip placement='right' title='All new job booked is automatically status On Hold'>
                      <Icon type='info-circle' theme='filled' style={{ marginLeft: '6px', cursor: 'pointer' }} />
                    </Tooltip>
                  </Col>
                )}
              </Row>
            </Col>
            <Col>
              <Row type='flex' gutter={[12, 12]}>
              </Row>
            </Col>
          </Row>
          <div>
            {this.getDescElems()}
          </div>
          <div className='ant-page-header-footer'>
            <div style={{ height: '1px', backgroundColor: '#e8e8e8' }} />
          </div>
        </div>
        <div style={{ backgroundColor: '#fff', marginBottom: '24px' }}>
          <Tabs className='custom-margin-tab' tabBarGutter={0}>
            {/* <TabPane key='statistics' tab={<span><Icon type='pie-chart' />Statistics</span>}>
              <Row style={{ paddingBottom: '24px'}}>
                <PageStatistics />
              </Row>
            </TabPane> */}
            <TabPane key='exportMapping' tab={<span><Icon type='deployment-unit' />Export Mapping</span>}>
              <Row style={{ paddingBottom: '24px'}}>
                <PageExportMapping userReducer={userReducer} history={history} location={location} match={match}  merchant={merchant} />
              </Row>
            </TabPane>
            <TabPane key='pmHardware' tab={<span><Icon type='gold' />PM Hardware Starting Range</span>}>
              <Row style={{ paddingBottom: '24px'}}>
                <PagePmHardware userReducer={userReducer} history={history} location={location} match={match}  merchant={merchant} />
              </Row>
            </TabPane>
            <TabPane key='pmTimber' tab={<span><Icon type='gold' />PM Timber Starting Range</span>}>
              <Row style={{ paddingBottom: '24px'}}>
                <PagePmTimber userReducer={userReducer} history={history} location={location} match={match}  merchant={merchant} />
              </Row>
            </TabPane>
            <TabPane key='skuMapping' tab={<span><Icon type='deployment-unit' />SKU Mapping</span>}>
              <Row style={{ paddingBottom: '24px'}}>
                <PageSkuMapping userReducer={userReducer} history={history} location={location} match={match}  merchant={merchant} />
              </Row>
            </TabPane>
            <TabPane key='setting' tab={<span><Icon type='setting' />Setting</span>}>
              <Row style={{ padding: '0 24px 24px 24px'}}>
                <PageSetting readOnly={readOnly} userReducer={userReducer} merchant={merchant} setMerchant={this.setMerchant} />
              </Row>
            </TabPane>
          </Tabs>
        </div>
      </Layout>
    ) : (
      <PageError
        errorResponse={errorResponse}
        onGoBack={this.onGoHome}
        onReload={this.retrieve}
      />
    )
  }
}

export default PageDetail