import { BaseList, BaseDetail } from "App/Component/Bases";
import fields from "./fields";
import ComponentAutocomplete from "App/Component/Autocomplete";
import descriptor from "./descriptor";
import options from "./options";

const title = "Extra Charges";
const urlData = "extraCharges";
const bulkName = "name";
const children = {
    name: 'extraCharges',
    sub: 'extraChargeMappings',
    bulk_request: false,
    identifier: {
      name: 'extra_charges',
      is_array: false
    },
    urlSub: 'api:admin:jobs:extracharge:ref-mappings-list',
    add: true,
    edit: true,
    permissions: {
      bulk_delete: false,
      initialrow: 1,
      add: true,
      duplicate: {
        name: 'size',
        label: 'Size',
        dataIndex: 'size.label',
        message: 'Size cannot duplicate'
      }
    },
    rules: {
      button: {
        type: {
          add: 'default',
          edit: 'default'
        },
        size: 'default'
      },
      input: {
        size: 'default'
      },
      select: {
        size: 'default'
      }
    },
  fields: ComponentAutocomplete['extraChargeMappings']
  }

class List extends BaseList {
  constructor(props) {
    super(props)

    this.title = title;
    this.urlData = urlData;
    this.bulkName = bulkName;
    this.propsList = {
      descriptor,
      exportImport: options.exportImportOptions,
    }
  }
}

class Detail extends BaseDetail {
  constructor(props) {
    super(props)

    this.title = title
    this.urlData = urlData
    this.bulkName = bulkName
    this.fields = fields
    this.children = children
    this.propsDetail = {
      urlKeyHistory: 'api:admin:jobs:extracharge:detail-revision',
      keyLabel: 'name'
    }
  }
}

export default { List, Detail };
