import React, {Component} from 'react';
import styled from 'styled-components'
import {Card, Row, Col, Tabs, Button, Input, Icon} from "antd";
import {
  estimationMenu,
  geoMenu,
  jobMenu,
  multisiteMenu,
  notificationMenu,
  reportMenu,
  usersMenu,
} from "App/Config/Menu"
import Lib from 'App/Utils/Lib'

const {TabPane} = Tabs;

const ignoreAdd = [
  'Job Status Not Synceds',
  'Extra charge jobs',
  'Job status historys',
  'Pending monitor reports',
];

class PageMenu extends Component {

  state = {
    activeTabKey: 'estimation',
    search: null
  };

  checkIgnore = (menu, ignore) => {
    const index = ignore.findIndex(item => item === menu);
    return index >= 0
  };

  callback = (key) => {
    this.setState({
      activeTabKey: key,
      search: null
    })
  };

  goTo = (url) => {
    this.props.history.push(`/admin${url}`);
  };

  goToAdd = (url) => {
    this.props.history.push(`/admin${url}/add`);
  };

  componentDidMount() {
    const {path} = this.props.match;
    let tab_key = path.replace(/\/|admin/g, '');

    this.setState({
      activeTabKey: (tab_key === 'dashboard') ? 'estimation': tab_key
    })
  }

  handleSearch = (value) => {
    let val = (value === '') ? null : value.toLowerCase();
    this.setState({search: val})
  };

  renderMenuItem = (data, path) => {
    return (
      <Row>
        {data.map((item, key) => (
          <Col lg={8} md={12} sm={16} key={key} style={{
            backgroundColor: item.toLowerCase().includes(this.state.search) ? '#ebebeb' : '#ffffff',
            minHeight: '55px',
          }}>
            <MenuItem>
              <Col span={16}>
                <span style={{cursor:'pointer'}} onClick={() => this.goTo(Lib.generateURL(item, path))} >{item}</span>
              </Col>
              <Col span={8} style={{
                textAlign:'right',
                minWidth: '67px',
              }}>
                <Button.Group>
                  <Button type="primary" disabled={this.checkIgnore(item, ignoreAdd)} onClick={() => this.goToAdd(Lib.generateURL(item, path))} size="default" icon="plus"/>
                  <Button type="none" onClick={() => this.goTo(Lib.generateURL(item, path))} size="default" icon="menu"/>
                </Button.Group>
              </Col>
            </MenuItem>
          </Col>
        ))}
      </Row>
    )
  };

  render() {
    const {activeTabKey} = this.state;
    let renderSearch = <Input
          placeholder="Search menu"
          onChange={e => this.handleSearch(e.target.value)}
          prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
          style={{width: 200}}
          allowClear
          value={this.state.search}
        />


    return (
      <RoundedCard>
        <Tabs activeKey={activeTabKey} onChange={this.callback} tabBarExtraContent={renderSearch}>
          <TabPane tab="Estimations" key="estimation">
            {this.renderMenuItem(estimationMenu, 'estimation')}
          </TabPane>
          <TabPane tab="Geo" key="geo">
            {this.renderMenuItem(geoMenu, 'geo')}
          </TabPane>
          <TabPane tab="Jobs" key="jobs">
            {this.renderMenuItem(jobMenu, 'jobs')}
          </TabPane>
          <TabPane tab="Multisites" key="multisite">
            {this.renderMenuItem(multisiteMenu, 'multisite')}
          </TabPane>
          <TabPane tab="Notifications" key="notifications">
            {this.renderMenuItem(notificationMenu, 'notifications')}
          </TabPane>
          <TabPane tab="Reports" key="reports">
            {this.renderMenuItem(reportMenu, 'reports')}
          </TabPane>
          <TabPane tab="User" key="user">
            {this.renderMenuItem(usersMenu, 'user')}
          </TabPane>
        </Tabs>
      </RoundedCard>
    );
  }
}

export default PageMenu;

const RoundedCard = styled(Card)`
  border-radius: 12px !important;
`;

const MenuItem = styled.div`
    display: flex;
    justify-content: space-between;
    padding: .4rem;
    align-items: center;
    &:hover {
      background-color:#ebebeb;
    }
`
