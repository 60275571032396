export default [
  {
    title: 'Merchant name',
    type: 'text',
    data: '',
    required: true,
    initialValue: '',
    note_text: 'A merchant name, e.g. ITM kiapoi, This merchant name should be same as merchant module merchant name setup.',
    additional: '',
    dataIndex: 'merchant_name',
    order: 0,
  }, {
    title: 'Continued',
    type: 'checkbox',
    data: '',
    initialValue: true,
    note_text: '',
    additional: '',
    dataIndex: 'continued',
    order: 1,
  }, {
    title: 'Continue till',
    type: 'datetime',
    data: '',
    required: true,
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'continue_till',
    order: 2,
  },
]