import descriptor from './descriptorOffline'

export default {
  key: 'building',
  title: 'Building',
  icon: 'bank',
  manualFilter: { key: 'type', value: 'pk' },
  contentProps: {
    urlTarget: '/admin/jobs/buildings',
    appName: 'jobs',
    modelName: 'building',
    availablePermissions: ['.view_'], // jika array kosong, maka tidak ada filter. Jika tidak dikirim, default []
  },
  propsList: {
    avatar: 'name',
    masterKey: 'buildings',
    descriptor,
  }
}