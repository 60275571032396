export default {
  "app_label": "note",
  "model_name": "consistencynote",
  "pagination": true,
  "columns": [
    {
      name: "title",
      label: "Title",
    },
    {
      name: "merchant.name",
      label: "Merchant",
    },
    {
      name: "subsection.name",
      label: "Subsection",
    },
    {
      name: "modified",
      label: "Modified",
      data_type: "datetime",
    },
  ],
  filter_by: [
    {
      "label": "Merchant",
      "name": "merchant",
      "type": "api_reference",
      "separator": ",",
      "api": {
        "url_name": "api:admin:jobs:merchant:autocomplete",
        "key": "pk",
        "display": "name",
        "pagination": true
      }
    },
    {
      "label": "Section",
      "name": "section",
      "type": "api_reference",
      "separator": ",",
      "api": {
        "url_name": "api:admin:estimation:section:autocomplete",
        "key": "pk",
        "display": "name",
        "pagination": true
      }
    },
    {
      "label": "Subsection",
      "name": "subsection",
      "type": "api_reference",
      relates: ['section'],
      "separator": ",",
      "api": {
        "url_name": "api:admin:estimation:subsection:autocomplete",
        "key": "pk",
        "display": "name",
        "pagination": true
      }
    },
  ],
}