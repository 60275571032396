import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from "react-router-dom";
import { RequestV2 as Request, Modules, Lib } from "App/Utils";
import { Routes } from "App/Config";
import {
  Affix,
  Button,
  Row,
  Col,
  Layout,
  Menu,
  Icon,
  PageHeader,
  Statistic,
  Divider,
  Descriptions,
  Badge,
  Spin,
  message,
} from "antd";
import styled from "styled-components";
import moment from "moment";
import {Bar} from "ant-design-pro/lib/Charts";
import Jobs from './Stats'
import Setting from './Setting'

const { Content} = Layout;
const IbesLogo = require("App/Assets/img/IBES-LOGO.png");
const defaultMenu = "jobs";

class JobQueueEdit extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      dataJob: null,
      isLoading: true,
      chartDataAntd: [],
      // Permission
      readOnly: !props.currentPermissionSet.some(item => (item || '').includes('.change_')),
      allowReadJob: props.permissionSetJob.some(item => (item || '').includes('.view_')),
      // ----------
    }
  }

  read() {
    let senderId = this.props.match.params.id
    Request(
      'get',
      'module-jobQueue-detail',
      {},
      {},
      [senderId],
      res => this.retrieveSuccess(res),
      err => this.setState({ isLoading: false }, () => message.error('Cannot connect to server.')),
    )
  }

  componentDidMount() {
    const { match, location, history } = this.props
    if (location.pathname.split(match.url).filter(v => v).length === 0) {
      history.replace(`${match.path.replace(':id', match.params.id)}/${defaultMenu}`)
    }
    this.read()
  }

  retrieveSuccess = (response) => {
    const chartDataAntd = []
    const { summaries } = response.data.job_summary
    for (let i = 0; i < summaries.length; i += 1) {
      chartDataAntd.push({ x: summaries[i].label, y: summaries[i].total })
    }
    // data.job_summary = {total_job: data.job_summary.total_job,
    //   data: [
    //     {x: "New", y: data.job_summary.total_status_new},
    //     {x: "Started", y: data.job_summary.total_status_started},
    //     {x: "Assigned", y: data.job_summary.total_status_assigned},
    //     {x: "QA1", y: data.job_summary.total_status_QA1},
    //     {x: "QA2", y: data.job_summary.total_status_QA2},
    //     {x: "Pending", y: data.job_summary.total_status_pending},
    //     {x: "Cancelled", y: data.job_summary.total_status_cancelled},
    //     {x: "Reopened", y: data.job_summary.total_status_reopened},
    //     {x: "Pre-QS", y: data.job_summary.total_status_pre_qs},
    //     {x: "Closed", y: data.job_summary.total_status_closed},
    //   ]}
    this.setState({
      dataJob: response.data,
      isLoading: false,
      chartDataAntd,
    })
  }

  changeJobQueue=(e)=>{
      this.setState({
        jobCount: e
      })
  }

  render() {
    const { history, location, match, permissionSetJob } = this.props;
    const { readOnly, allowReadJob, dataJob, isLoading, chartDataAntd } = this.state
    const { Countdown } = Statistic;
    const deadline = Date.now() + 1000 * 60 * 60 * 24 * 2 + 1000 * 30; // Moment is also OK
    return !isLoading ? (
      <Layout className="um-layout">
        <Layout>
          <PageHeader
            style={{
              border: '1px solid rgb(235, 237, 240)',
              backgroundColor: "#ffffff"
            }}
            // onBack={this.goTo}
            avatar={{ src: IbesLogo}}
            title="Job Queue"
            onBack={() => history.push("/job-management/job-queues")}
            subTitle={readOnly &&
              <Col style={{ fontSize: '12px', fontWeight: 500, backgroundColor: '#1890ff', color: '#fff', padding: '0 12px 2px 12px', borderRadius: '14px' }}>
                Read Only
              </Col>
            }
          />
          <PageHeader
            // title={"Detail Job Queue : #" +dataJob.pk}
            ghost={false}
            footer={<Divider className="m-0" />}
          >
            <Row className="mb-3">
              <Col lg={6} xl={6} xs={16}>
                <Descriptions className="ant-desc-custom" column={1}>
                  <Descriptions.Item label="Name">
                    {dataJob.name}
                  </Descriptions.Item>
                  <Descriptions.Item label="Branch">
                    {dataJob.branch.name}
                  </Descriptions.Item>
                  <Descriptions.Item label="Status">
                    <Badge status="success" text="Active" />
                  </Descriptions.Item>
                  <Descriptions.Item label="Created at">
                    {moment(dataJob.created).format('ll')}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
              <Col lg={18} xl={18}>
                <Row>
                  <Col md={1}>
                    <Divider type="vertical" style={{ height: "6.1rem" }} />
                  </Col>
                  <Col md={5}>
                    <span className="text-muted font-md mb-3">Lead time</span>
                    <div>
                      <Icon
                        className="font-2xl mr-1"
                        type="idcard"
                        theme="twoTone"
                      />
                      <span className="font-2xl">
                        <Countdown value={deadline} format="DD:HH:mm:ss" />
                      </span>
                    </div>
                  </Col>
                  <Col md={1}>
                    <Divider type="vertical" style={{ height: "6.1rem" }} />
                  </Col>
                  <Col md={17} xs={24}>
                      Total job :
                      <Badge
                        count={dataJob.job_summary.total ? dataJob.job_summary.total : 'empty'}
                        overflowCount={999999999}
                        style={{ backgroundColor: dataJob.branch.color || '#ff8169', zIndex: "1", marginLeft: "10px", color: '#fff' }}
                      />
                      <Bar
                        height={120}
                        autoLabel={false}
                        data={chartDataAntd}
                        color={dataJob.branch.color || "#ff8169"}
                      />
                  </Col>
                </Row>
              </Col>
            </Row>
          </PageHeader>
          <Affix offsetTop={66}>
            <Menu
              mode="horizontal"
              className="px-2"
              // onClick={this.handlemenuClick}
              // theme={"dark"}
              selectedKeys={[
                Lib.getMenuUrl(location.pathname, undefined, defaultMenu)
              ]}
            >
              {allowReadJob && (
                <Menu.Item
                  key="jobs"
                  onClick={e => {
                    history.push(`${match.url}/jobs`);
                  }}
                >
                  <Icon type="book" />
                  Jobs
                </Menu.Item>
              )}
              {!readOnly && (
                <Menu.Item
                  key="settings"
                  onClick={e => {
                    history.push(`${match.url}/settings`);
                  }}
                >
                  <Icon type="setting" />
                  Settings
                </Menu.Item>
              )}
            </Menu>
          </Affix>
          <Layout>
            <StyledContent>
              <Switch>
                {allowReadJob && (
                  <Route path={`${this.props.match.path}/jobs`}>
                    <Jobs {...this.props} permissionItems={permissionSetJob} />
                  </Route>
                )}
                {!readOnly && (
                  <Route path={`${this.props.match.path}/settings`}>
                    <Setting changeJobQueue={this.changeJobQueue} {...this.props} />
                  </Route>
                )}
              </Switch>
            </StyledContent>
          </Layout>
        </Layout>
      </Layout>
    ) : (
      <div style={{ paddingTop: 100, textAlign: "center" }}>
        <Spin
          size="large"
          tip="making coffee..."
          indicator={<Icon type="loading" style={{ fontSize: "4rem" }} spin />}
        />
      </div>
    )
  }
}

JobQueueEdit.propTypes = {
  currentPermissionSet: PropTypes.array,
  permissionSetJob: PropTypes.array,
}

JobQueueEdit.defaultProps = {
  currentPermissionSet: [],
  permissionSetJob: [],
}

export default JobQueueEdit

const StyledContent = styled(Content)`
  margin-top: 5px;
`
