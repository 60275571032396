import { BaseDetail } from 'App/Component/Bases'
import fields from './fields'

class PageAdd extends BaseDetail {
  title = 'Keyword'
  urlData = 'module-expertSystem-keyword'
  fields = fields
  state = {
    isCheckingPermission: false,
    isAccessAllowed: true
  }
}

export default PageAdd