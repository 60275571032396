import React from "react";
import PropTypes from "prop-types";
import { Row, Spin, Icon } from "antd";
import { get } from "lodash";

import SubAllList from "App/Component/SubAllList";
import { CheckPermission, Lib } from "App/Utils";
import { PermissionName } from "App/Config";
import PageUnauthorized from "App/Component/PageUnauthorized";
import { forcePermissions } from "App/Data";

// Extends this class, and passing props like from route component
class BaseList extends React.Component {
  constructor(props) {
    super(props)

    // example permission: estimation.view_linetemplate
    // appName = "estimation" in example permission
    // modelName = "linetemplate" in example permission
    this.appName = null // string
    this.modelName = null // string
    this.propsList = {} // open api documentation in App/Component/SubAllList
    this.propsPageUnauthorized = {}
    this.isSuperuser = get(props.userReducer, ['user', 'is_superuser'])
    this.availablePermissions = []
    this.state = {
      // Permission
      isCheckingPermission: !this.isSuperuser,
      isAccessAllowed: this.isSuperuser,
      currentPermissionSet: this.isSuperuser ? forcePermissions : [],
      // ----------
    }
  }

  setThisState = (obj, callback = () => null, force) => {
    if (force === true) {
      return this.setState(obj, callback)
    } else {
      for (const key in obj) {
        if (obj[key] !== this.state[key]) {
          return this.setState(obj, callback)
        }
      }
    }
    callback()
  }

  filterPermissionItems = (permissionItems) => {
    if (this.availablePermissions.length) {
      const allowedPermissionItems = []
      for (let i = 0; i < this.availablePermissions.length; i += 1) {
        for (let j = 0; j < permissionItems.length; j += 1) {
          if (permissionItems[j].includes(this.availablePermissions[i])) {
            allowedPermissionItems.push(permissionItems[j])
            break
          }
        }
      }
      return allowedPermissionItems
    }
    return permissionItems
  }

  handleCheckPermission = (callback = () => null) => {
    this.setThisState({ isCheckingPermission: true }, () => {
      const permissionItems = CheckPermission.getPermissionSet({
        userReducer: this.props.userReducer,
        appName: this.appName,
        modelName: this.modelName,
      })
      const currentPermissionSet = this.filterPermissionItems(permissionItems)
      const codePart = PermissionName.READ.codePart
      const isAccessAllowed = !!currentPermissionSet.find(val => val.search(codePart) !== -1)
      callback({ isAccessAllowed, currentPermissionSet })
    })
  }

  getListComponent = () => (
    <SubAllList
      userReducer={this.props.userReducer}
      permissions={this.state.currentPermissionSet}
      // avatar="name"
      // pageTitle="Merchant"
      // descriptor={descriptor}
      // masterKey="module-merchant-merchant"
      // groupByOptionss={[{ label, key, dataIndex }]}
      // --- reference
      // args = array to replace parent id
      // onAddClick = function ()
      // onDetailClick = function (id)
      {...this.propsList}
    />
  )

  getPageUnauthorized = () => (
    <PageUnauthorized {...this.propsPageUnauthorized} />
  )

  callbackAllowed = () => {}

  componentDidMount() {
    if (this.state.isCheckingPermission) {
      this.handleCheckPermission(res => {
        this.setState({
          isCheckingPermission: false,
          isAccessAllowed: get(res, 'isAccessAllowed'),
          currentPermissionSet: get(res, 'currentPermissionSet', [])
        }, () => {
          this.callbackAllowed()
        })
      })
    } else {
      this.callbackAllowed()
    }
  }

  componentWillUnmount() {
    Lib.windowStop()
  }

  render() {
    const { isCheckingPermission, isAccessAllowed } = this.state
    if (isCheckingPermission) {
      return (
        <Row type='flex' justify='center' align='middle' style={{ marginTop: '30vh' }}>
          <Spin
            size='large'
            tip='Checking permission...'
            indicator={<Icon spin type='loading' style={{ fontSize: '4rem' }} />}
          />
        </Row>
      )
    }

    return isAccessAllowed ? this.getListComponent() : this.getPageUnauthorized()
  }
}

BaseList.propTypes = {
  userReducer: PropTypes.object.isRequired,
}

export default BaseList