import React from 'react'
import { Prompt } from 'react-router-dom'
import { Layout, Row, Col, Button, Spin, Drawer, Icon, message, Typography, Tabs } from 'antd'
import { get } from 'lodash'
import moment from 'moment'
import { RequestV2 as Request, Lib } from 'App/Utils'
import PageNotFound from 'App/Component/PageNotFound'
import PageError from 'App/Component/PageError'
import Header from 'App/Screens/SubModules/UserGroup/Group/Management/Header'
import { PageMerchantGroup, PageMerchant } from './Pages'
import History from "App/Screens/SubModules/Component/History";

const { TabPane } = Tabs

class PageDetail extends React.PureComponent {
  constructor(props) {
    super(props)

    this.refKeys = [
      { key: '_header', keyBody: 'name', label: 'Name' },
    ]

    this.state = {
      isLoading: true,
      errorResponse: null,
      isSaving: false,
      activeTabKey: 'groups',
      mappingMode: null,
      isFieldsTouched: false,
      visible: false,
      // Permission
      readOnly: !get(props, 'currentPermissionSet', []).some(code => (code || '').includes('.change_')),
      // ----------
    }
  }

  retrieve = (keyLoading = 'isLoading', callback = () => null) => {
    this.setState({ [keyLoading]: true }, () => {
      Request(
        'get',
        'module-merchant-mappingMode-detail',
        {},
        {},
        [this.props.match.params.id],
        this.retrieveSuccess,
        this.retrieveFailed,
        { keyLoading, callback }
      )
    })
  }

  retrieveSuccess = (response, extra) => {
    this.setState({ [extra.keyLoading]: false, mappingMode: response.data })
  }

  retrieveFailed = (error, extra) => {
    // message.error('Failed to receive Mapping Mode with ID: ' + this.props.match.params.id)
    this.setState({ [extra.keyLoading]: false, errorResponse: get(error, 'response') }, () => {
      extra.callback()
    })
  }

  onTabChange = (activeTabKey, callback = () => null) => {
    this.setState({ activeTabKey }, callback)
  }

  setTouched = (isFieldsTouched, callback) => {
    this.setState({ isFieldsTouched }, callback)
  }

  onFieldTouched = (isFieldsTouched, callback = () => null) => {
    this.setTouched(true, callback)
  }

  onRevert = () => {
    this._header.setTitle(this.state.mappingMode.name)
    this.setState({ isFieldsTouched: false })
  }

  onSave = () => {
    const values = {}
    for (const refItem of this.refKeys) {
      if (get(this[refItem.key], 'validateFields')) {
        values[refItem.keyBody] = this[refItem.key].validateFields()
      }
    }

    this.setState({ isSaving: true }, () => {
      const { mappingMode } = this.state
      const args = mappingMode ? [mappingMode.pk] : []
      Request(
        'put',
        'module-merchant-mappingMode-update',
        {},
        values,
        args,
        this.saveSuccess,
        this.saveFailed,
      )
    })
  }

  saveSuccess = (response) => {
    message.success(response.data.name + ' has been saved.')
    this.setState({ isSaving: false, isFieldsTouched: false, mappingMode: response.data })
  }

  saveFailed = (error) => {
    const errors = get(error, ['response', 'data'])
    if (typeof get(error, ['response', 'data', 'detail']) === 'string') {
      message.error('Failed to save Mapping Mode, message: ' + error.response.data.detail, 5)
    } else if (errors && typeof errors === 'object') {
      this.setFieldsError(errors)
    } else {
      message.error('Failed to save Mapping Mode, please contact your admin!', 5)
    }
    this.setState({ isSaving: false })
  }

  setFieldsError = (objError) => {
    message.error('Failed to save Mapping Mode')
    for (const key in objError) {
      const refKey = this.refKeys.find(ref => ref.keyBody === key)
      message.error('Error in ' + get(refKey, 'label', key) + ' : ' + objError[key][0], 5)
      if (refKey && typeof get(this[refKey.key], 'setError') === 'function') {
        this[refKey.key].setError(objError[key][0])
      }
    }
  }

  getDescElems = () => {
    const { mappingMode } = this.state
    return (
      <Row className='ant-desc-custom' type='flex' gutter={[12, 12]} style={{ marginTop: '12px' }}>
        {
          this.headerDescriptions.map(item => (
            <Col key={item.key} xs={24} sm={12} lg={8}>
              <Row className='header-content-item'>
                <span className='ant-descriptions-item-label ant-descriptions-item-colon'>
                  {item.label}
                </span>
                <span className='ant-descriptions-item-content'>
                  {this.getValue(mappingMode, item.key, item.type)}
                </span>
              </Row>
            </Col>
          ))
        }
      </Row>
    )
  }

  getValue = (record, key, type) => {
    if (type === 'long_date') {
      return (
        <>
          {moment(record[key]).format('ddd, DD MMM YYYY hh:mm')}
          <Typography.Text type='secondary'> ({moment(record[key]).fromNow()})</Typography.Text>
        </>
      )
    } else if (type === 'date') {
      return moment(record[key]).format('ddd, DD MMM YYYY hh:mm')
    } else {
      return get(record, key, '-')
    }
  }

  onGoHome = () => {
    Lib.historyAction(this.props.history, '/merchant/mapping-mode').goBack()
  }

  setMappingMode = (mappingMode) => {
    this.setState({ mappingMode })
  }

  componentDidMount() {
    this.retrieve()
  }

  render() {
    const { userReducer, history, location, match } = this.props
    const { activeTabKey, readOnly, isLoading, errorResponse, isSaving, mappingMode, isFieldsTouched } = this.state
    if (isLoading) {
      return (
        <div style={{ paddingTop: 100, textAlign: "center" }}>
          <Spin
            size="large"
            tip="Loading..."
            indicator={
              <Icon type="loading" style={{ fontSize: "4rem" }} spin />
            }
          />
        </div>
      )
    }

    return mappingMode ? (
      <Layout className='um-layout'>
        <Prompt
          when={isFieldsTouched}
          message='You will lose any unsaved changes. Continue?'
        />
        <Drawer 
          destroyOnClose 
          placement="right"
          closable={false}
          width={700}
          visible={this.state.visible} 
          onClose={()=>this.setState({ visible: false })}
        >
          <History 
            id={mappingMode.pk} 
            url="module-merchant-mappingMode-history" 
            urlDetail="module-merchant-mappingMode-history-detail" 
            title={mappingMode.name}
            updateHeader={(data)=>{
              this._header.setTitle(data.name)
              this.setState({ visible: false, mappingMode: data })
            }}
            fields={[
              {
                title: 'Name',
                type: 'text',
                data: '',
                initialValue: '',
                additional: '',
                dataIndex: 'name',
              }
            ]}
          />
        </Drawer>
        <Header
          ref={ref => {this._header = ref}}
          readOnly={readOnly}
          title={mappingMode.name}
          onBack={this.onGoHome}
          onFieldTouched={this.onFieldTouched}
          extra={
            <Row type='flex' gutter={[12, 12]}>
              {isFieldsTouched && (
                <Col>
                  <Button onClick={this.onRevert}>
                    Revert to Saved
                  </Button>
                </Col>
              )}
              <Col>
                <Button.Group>
                <Button onClick={()=>this.setState({ visible: true })}>History</Button>
                <Button disabled={!isFieldsTouched} type='primary' icon='save' loading={isSaving} onClick={this.onSave}>
                  Save
                </Button>
                </Button.Group>
              </Col>
            </Row>
          }
        />
        {/* <div className='ant-page-header has-footer'>
          <Row type='flex' justify='space-between' gutter={[12, 12]} style={{ borderBottom: '1px solid #e8e8e8' }}>
            <Col>
              <Row type='flex' align='middle'>
                <Icon className='ant-page-header-back-button' type='arrow-left' style={{ marginRight: '16px' }} onClick={() => this.props.history.push('/merchant/mapping-mode')} />
                <Col className='header-title text-ellipsis'>
                  Mapping Mode
                </Col>
              </Row>
            </Col>
          </Row>
          <div>
            {this.getDescElems()}
          </div>
          <div className='ant-page-header-footer'>
            <div style={{ height: '1px', backgroundColor: '#e8e8e8' }} />
          </div>
        </div> */}
        <div style={{ backgroundColor: '#fff', marginBottom: '24px' }}>
          <Tabs className='custom-margin-tab' tabBarGutter={0} activeKey={activeTabKey} onChange={this.onTabChange}>
            <TabPane key='groups' tab={<span><Icon type='team' />Merchant Groups</span>}>
              <Row>
                <PageMerchantGroup
                  tabKey='groups'
                  userReducer={userReducer}
                  history={history}
                  location={location}
                  match={match}
                  onGoHome={this.onGoHome}
                  mappingMode={mappingMode}
                />
              </Row>
            </TabPane>
            <TabPane key='merchants' tab={<span><Icon type='shop' />Merchants</span>}>
              <Row>
                <PageMerchant
                  tabKey='merchants'
                  userReducer={userReducer}
                  history={history}
                  location={location}
                  match={match}
                  onGoHome={this.onGoHome}
                  mappingMode={mappingMode}
                />
              </Row>
            </TabPane>
            {/* <TabPane key='setting' tab={<span><Icon type='setting' />Setting</span>}>
              <Row style={{ padding: '0 24px 24px 24px'}}>
                <PageSetting userReducer={userReducer} mappingMode={mappingMode} setMappingMode={this.setMappingMode} />
              </Row>
            </TabPane> */}
          </Tabs>
        </div>
      </Layout>
    ) : (
      <PageError
        errorResponse={errorResponse}
        onGoBack={this.onGoHome}
        onReload={this.retrieve}
      />
      // <PageNotFound
      //   title='Failed to fetch'
      //   subTitle='There was an error when connecting to the server.'
      //   extra={
      //     <Row type='flex' justify='center' gutter={[12, 12]}>
      //       <Col>
      //         <Button onClick={this.onGoHome}>Back Home</Button>
      //       </Col>
      //       <Col>
      //         <Button type='primary' loading={isReloading} onClick={() => this.retrieve('isReloading')}>Try again</Button>
      //       </Col>
      //     </Row>
      //   }
      // />
    )
  }
}

export default PageDetail