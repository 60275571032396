import { BaseList } from "App/Screens/SubModules/Component";
import descriptor from "./descriptorOffline";

class KeywordTargetList extends BaseList {
  constructor(props) {
    super(props)

    this.appName = 'expert_system'
    this.modelName = 'targetpage'
    this.propsList = {
      avatar: 'name',
      pageTitle: 'Keyword Page',
      descriptor,
      masterKey: 'module-expertSystem-keywordPage',
    }
  }
}

export default KeywordTargetList