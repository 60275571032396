import React from 'react'
import { Col, Form, Spin, Transfer } from 'antd'
import { get } from 'lodash'
import { Lib, RequestV2 as Request } from 'App/Utils'

class TransferField extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      list: [],
    }
  }

  read = () => {
    this.setState({ isLoading: true }, () => {
      Request(
        'get',
        this.props.urlKey,
        {},
        {...this.props.paramProps},
        [],
        this.readSuccess,
        this.readFailed,
      )
    })
  }

  readSuccess = (response) => {
    this.setState({ isLoading: false, list: response.data })
  }

  readFailed = (error) => {
    Lib.errorMessageHandler(get(error, 'response'))
    this.setState({ isLoading: false })
  }

  componentDidMount() {
    this.read()
  }

  render() {
    const { dataIndex, initial, inputProps, title, form, required, valLabel } = this.props
    const { isLoading, list } = this.state

    if (isLoading) {
      return (
        <div style={{ display: 'flex', alignItems: 'center', height: '300px' }}>
          <Spin spinning />
        </div>
      )
    }

    return (
      <Col span={24}>
        <Form.Item span={24} label={title}>
          {form.getFieldDecorator(dataIndex, {
            rules: [{
              required,
              message: `Please Input ${title}!`,
            }],
            valuePropName: 'targetKeys',
            initialValue: initial,
          })(
            <Transfer
              rowKey={record => record.pk}
              listStyle={{ width: 'calc(50% - 24px)' }}
              render={record => ({
                value: record[valLabel],
                label: record[valLabel],
              })}
              {...inputProps}
              dataSource={list}
            />
          )}
        </Form.Item>
      </Col>
    )
  }
}

export default TransferField