import PrefillSetGroupManagement from 'App/Screens/Estimation/PrefillSetGroups/Management'

class OverrideSetGroupManagement extends PrefillSetGroupManagement {
  constructor(props) {
    super(props)

    this.title = 'Override Group'
    this.urlKeyList = 'overrideSetGroups-read'
    this.urlKeyDetail = 'overrideSetGroups-detail'
    this.urlKeyChildList = 'overrideSets-autocomplete'
    this.isOverrideSet = true
    this.keyChild = 'override_sets'
    this.labelChild = 'Override Sets'
  }
}

export default OverrideSetGroupManagement