const descriptorOffline = {
  "app_label": "jobs",
  "model_name": "",
  "pagination": true,
  "columns": [
    {
      "name": "merchant",
      "label": "Merchant",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": null,
      "child_path": "name",
      "search": true,
      "sort": true
    },
    {
      "name": "is_pricing",
      "label": "Pricing",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "bool",
      "search": false,
      "sort": true
    },
    {
      "name": "modified",
      "label": "Modified",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "datetime",
      "search": false,
      "sort": false
    }
  ],
  "filter_by": [],
  "export_import": null
}

export default descriptorOffline