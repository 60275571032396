import { BaseList } from "App/Screens/SubModules/Component";
import descriptor from "./descriptorOffline";

class SkuMappingList extends BaseList {
  constructor(props) {
    super(props)

    this.appName = 'merchant'
    this.modelName = 'skumapping'
    this.propsList = {
      avatar: 'product_code',
      pageTitle: 'SKU Mapping',
      descriptor,
      masterKey: 'module-merchant-skuMapping',
    }
  }
}

export default SkuMappingList