import React from 'react'
import { BaseDetail } from 'App/Component/Bases'
import BaseOrderManagement from 'App/Component/OrderManagement'

class PermissionOrderManagement extends BaseDetail {
  getCodePart = () => '.change_'

  getAllowedComponent = () => (
    <OverrideSetOrderManagement {...this.props} />
  )
}

class OverrideSetOrderManagement extends BaseOrderManagement {
  constructor(props) {
    super(props)

    this.title = 'Override Sets Order Management'
    this.urlKeySave = 'api:admin:estimation:overrideset:manage-order'
    this.propsCardContent = {
      urlKeyListOrder: 'api:admin:estimation:subsection:ref-override-sets-list',
      // filters: [
      //   {
      //     key: 'prefill_set',
      //     label: 'Prefill Set',
      //     urlKey: 'api:admin:estimation:prefillset:autocomplete',
      //     getParamProps: (instance) => ({ subsection: instance.props.subsectionId })
      //   }
      // ]
    }
  }
}

export default PermissionOrderManagement