import { BaseList } from "App/Component/Bases";
import descriptor from "./descriptor";
import Detail from "./Management";
import options from "./options";
import getAnotherOperations from "./getAnotherOperations";

const title = "Broadcast";
const urlData = "broadcasts";
const bulkName = "title";

class List extends BaseList {
  constructor(props) {
    super(props)

    this.title = title;
    this.urlData = urlData;
    this.bulkName = bulkName;
    this.propsList = {
      descriptor,
      exportImport: options.exportImportOptions,
      widthOperation: '125px',
      getAnotherOperations,
    }
  }
}

export default { List, Detail };