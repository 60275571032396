import React, { Component } from "react";
import { Lib, RequestV2 as Request } from "App/Utils";
import {
  Typography,
  Row,
  Col,
  Card,
  Icon,
  Tag,
  Divider,
  Collapse,
  Tooltip,
  Button,
  Empty,
} from "antd";
import General from "./general";
import { get, cloneDeep, isEqual } from "lodash";
const { Text } = Typography;
const { Panel } = Collapse;

class CardRules extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      data: props.rules,
      comparer:[]
    };
  }

componentDidMount(){
  this.read()
}
  compareValue(data) {
    const tag = data.split(/[;,]+/);
    return tag;
  }

  updateData(data) {
    this.props.updatedata2(data);
  }

  read = () => {
    this.setState({ isLoading: true }, () => {
      Request(
        "get",
        "expert-system-comparer",
        {},
        {},
        [],
        this.readSuccess,
        this.readFailed
      );
    });
  };

  readSuccess = (response) => {
    this.setState({ comparer: response.data });
  };

  readFailed = (error) => {
    console.log("hai", error);
  };

  comparerOutput(data){
    const comparer = this.state.comparer
    for (let i = 0; i < comparer.length; i++) {
     if(comparer[i].name === data){
      return comparer[i].desc
     }
    }
  }

  rules(data) {
    if (!data) {
      console.log("data [0]", data);
    } else {
      return (
        <div className="rules">
          {!!data.premises.length && (
            <Collapse className="rules" style={{ border: "transparent" }}>
              {data.premises.map((e, index) => {
                  return (
                    <Panel
                      expandIcon={<Icon type="info-circle" />}
                      header={e.childObj.key}
                      key={index}
                    >
                      <Card
                        size="small"
                        style={{
                          width: "97%",
                          // border: "transparent",
                          margin: "8px",
                          borderRadius: 0,
                        }}
                        bodyStyle={{
                          padding: "10px",
                          borderBottom: "transparent",
                        }}
                      >
                        <Row>
                          {/* <Col span={24}>
                            {"pk" in e.childObj && (
                              <Tooltip placement="topLeft" title="More Details">
                                <Icon
                                  type="info-circle"
                                  onClick={() =>
                                    this.props.dataTabs(e.childObj)
                                  }
                                  style={{ float: "right" }}
                                />
                              </Tooltip>
                            )}
                          </Col> */}
                          {e.childObj.rule_type === "fact" ? (
                            <Col span={12}>
                              <p
                                style={{
                                  fontSize: "12px",
                                }}
                              >
                                Rule Type : {e.childObj.rule_type}{" "}
                              </p>
                            </Col>
                          ) : (
                            <>
                            <Col span={12}>
                              <p
                                style={{
                                  fontSize: "12px",
                                }}
                              >
                                Rule Type : {e.childObj.rule_type}{" "}
                              </p>
                            </Col>
                            <Col span={12}>
                              <p
                                style={{
                                  fontSize: "12px",
                                }}
                              >
                                Comparer : {this.comparerOutput(e.comparer)}{" "}
                              </p>
                            </Col>
                            </>
                          )}

                          {e.childObj.rule_type === "fact" ? (
                            <Col span={12}>
                              <p
                                style={{
                                  fontSize: "12px",
                                }}
                              >
                                Fact Type : {e.childObj.fact_type}{" "}
                              </p>
                            </Col>
                          ) : (
                            ("pk" in e.childObj) && (
                            <Col span={24}>
                              <p
                                style={{
                                  fontSize: "12px",
                                }}
                              >
                                Comparer value :{" "}
                                {e.compare_value === null ||
                                e.compare_value == ""
                                  ? " - "
                                  : this.compareValue(e.compare_value).map(
                                      (item) => (
                                        <Tag key="item" color="magenta">{item}</Tag>
                                      )
                                    )}
                              </p>
                            </Col>
                            )
                          )}
                          {("pk" in e.childObj) && (
                            <>
                          <Col span={24}>
                            <p
                              style={{
                                fontSize: "12px",
                              }}
                            >
                              Description :{" "}
                            </p>
                          </Col>
                          <Col span={24}>
                            <p
                              style={{
                                lineHeight: "17px",
                                margin: "0",
                                fontSize: "12px",
                              }}
                            >
                              {e.childObj.desc}
                            </p>
                          </Col>
                          </>
                          )}
                          <Col span={24}>
                          {"pk" in e.childObj && (
                            <Tooltip placement="topLeft" title="Open detail in new tab">
                            <Button style={{ width: "100%",marginTop: "13px" }} onClick={() =>
                              this.props.dataTabs(e.childObj)
                            } type="primary">
                              Open <Icon type="login" />
                            </Button>
                            </Tooltip>
                            )}
                          </Col>
                          <Col span={24}>
                          {!("pk" in e.childObj)
                            ? this.rules(e.childObj)
                            : null}
                          </Col>
                        </Row>
                      </Card>
                    </Panel>
                  );
              })}
            </Collapse>
          )}
        </div>
      );
    }
  }

  render() {
    const keyword = get(this.props, "rules.fact_keywords", []);
    const target = get(this.props, "rules.target_field");
    const name = get(this.props, "rules.name", "Rules name");
    const desc = get(this.props, "rules.desc", "");
    return (
      <Card bodyStyle={{ marginTop: "20px" }} className="collap">
        <General
          action="edit"
          list={false}
          onCloseDrawer={() => this.setState({ visible: false })}
          visible={this.state.visible}
          data={cloneDeep(this.props.rules)}
          outputContent={null}
          updatedata1={(newData) => this.updateData(newData)}
          tipeRule={
            get(this.props, "rules.rule_type", "rule") === "rule" ? true : false
          }
          
        />
        <Row gutter={[8, 16]}>
          <Col style={{ display: "flex", flexWrap: "nowrap" }}>
            <Col style={{ padding: "6px 4px" }}>
              <Icon type="credit-card" style={{ fontSize: "29px" }} />
            </Col>
            <Col style={{ minWidth: "69%", maxWidth: "75%" }}>
              <div>
                {name === null ? (
                  " - "
                ) : (
                  <Text strong>
                    {name}
                    {"  "}
                    <Tooltip
                      placement="topLeft"
                      title={desc === "" ? " doesnt have description " : desc}
                    >
                      <Icon type="info-circle" style={{ fontSize: "11px" }} />
                    </Tooltip>{" "}
                  </Text>
                )}
              </div>
              <div>
                <Text style={{ fontSize: "11px" }}>
                  {get(this.props, "rules.key", "Rules key")}
                </Text>
              </div>
            </Col>
            <Col style={{ minWidth: "10%" }}>
              {"pk" in this.props.rules && (
                <Icon
                  type="edit"
                  style={{ fontSize: "20px", float: "right" }}
                  onClick={() => this.setState({ visible: true })}
                />
              )}
            </Col>
            <Col style={{ minWidth: "15%" }}>
              <Tag color="#f50">
                {get(this.props, "rules.rule_type", null) === "rule"
                  ? "Rule"
                  : "Fact"}
              </Tag>
            </Col>
          </Col>
          <Col className="deviderbutton" span={24}>
            <Divider orientation="left">
              {get(this.props, "rules.fact_type", "") === null
                ? "  Premises"
                : "  Detail"}
            </Divider>
          </Col>
          <Col className="flexx">
            {get(this.props, "rules.fact_type", null) !== null && (
              <Col className="four">
                <Text>Type : </Text>
                <Text strong>
                  {" "}
                  {get(this.props, "rules.fact_type", "Fact")}
                </Text>
              </Col>
            )}
            {get(this.props, "rules.fact_type", null) !== null && (
              <Col className="four">
                <Text>Unit : </Text>
                <Text strong>{get(this.props, "rules.fact_unit", "Fact")}</Text>
              </Col>
            )}
          </Col>
          {get(this.props, "rules.fact_type", null) !== null && (
            <Col className="keyword">
             {this.props.rules.fact_type === "keyword" && ( 
               <>
              <Col span={3}>
                <Text>Keywords :</Text>
              </Col>
              {get(this.props, "rules.fact_type", null) !== null && (
                <Col span={21}>
                  {keyword.map((data) => (
                    <Tag color="red">
                      {data}
                    </Tag>
                  ))}
                </Col>
              )}
               </>
             )}
              {this.props.rules.fact_type === "job" && ( 
               <>
              <Col span={4}>
                <Text>Target Fields :</Text>
              </Col>
              {get(this.props, "rules.fact_type", null) !== null && (
                <Col span={20}>
                    <Tag color="red">
                      {target}
                    </Tag>
                </Col>
              )}
               </>
             )}
            </Col>
          )}
          {this.rules(this.props.rules)}
        </Row>
      </Card>
    );
  }
}

export default CardRules;
