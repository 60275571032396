export default {
  "app_label": "merchant",
  "model_name": "skumapping",
  "pagination": true,
  "columns": [
    {
      "name": "pk",
      "label": "ID",
      "help_text": null,
      "hidden": true,
      "identifier": true,
      "data_type": "int",
      "search": false,
      "sort": false
    },
    {
      "name": "product_code",
      "label": "Product code",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "search": true,
      "sort": true
    },
    {
      "name": "sku",
      "label": "Sku",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "search": true,
      "sort": true
    },
    {
      "name": "mode",
      "label": "Mode",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": null,
      "child_path": "name",
      "search": true,
      "sort": true
    },
    {
      "name": "description",
      "label": "Description",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "search": true,
      "sort": true
    },
    {
      "name": "merchant",
      "label": "Merchant",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": null,
      "child_path": "name",
      "search": true,
      "sort": true
    },
    {
      "name": "group",
      "label": "Group",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": null,
      "child_path": "name",
      "search": true,
      "sort": true
    },
    {
      "name": "modified",
      "label": "Modified",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "datetime",
      "search": false,
      "sort": true
    }
  ],
  "filter_by": [
    {
      name: 'mode',
      label: 'Mode',
      api: {
        url_name: 'module-merchant-mappingMode-autocomplete',
        key: 'pk',
        display: 'name',
      }
    },
    {
      name: 'group',
      label: 'Merchant Group',
      api: {
        url_name: 'module-merchant-group-autocomplete',
        key: 'pk',
        display: 'name',
      }
    },
    {
      name: 'merchant',
      label: 'Merchant',
      api: {
        url_name: 'module-merchant-merchant-autocomplete',
        key: 'pk',
        display: 'name',
      }
    },
    {
      name: 'product_code',
      label: 'Product Code',
      type: 'text',
    },
    {
      name: 'sku',
      label: 'SKU',
      type: 'text',
    },
  ],
  "export_import": null
}