import { BaseDetail } from 'App/Component/Bases'
import fields from 'App/Screens/Job/ExtraCharges/fields'

class PageAdd extends BaseDetail {
  title = 'Extra Charge'
  urlData = 'module-merchant-extraCharge'
  fields = fields
  state = {
    isCheckingPermission: false,
    isAccessAllowed: true
  }
}

export default PageAdd