import React, { PureComponent } from 'react';
import { Row, Col, Card, Timeline } from "antd";
import PageMenu from "App/Component/PageMenu";
// import {get} from 'lodash'
import styled from "styled-components";
import Lib from "App/Utils/Lib";
import JobQueueSummary from "./Component/JobQueueSummary";

class Dashboard extends PureComponent {
  state = {
    reverse: false
  };

  componentWillUnmount() {
    Lib.windowStop();
  }

  render() {
    return (
      <Row gutter={[12, 12]}>
        <Col span={24} style={{ marginBottom: '12px' }}>
          <RoundedCard title="Job Queue in All Branches">
            <JobQueueSummary />
          </RoundedCard>
        </Col>
        {/* <Col className="gutter-row" lg={19} md={24}>
          <PageMenu {...this.props} /> // Belum di fix menu.nya sesuai permission
        </Col>
        <Col className="gutter-row" lg={5} md={24}>
          <RoundedCard title="(Static) Recent Actions">
            <Timeline>
              <Timeline.Item>Create a services site 2015-09-01</Timeline.Item>
              <Timeline.Item>
                Solve initial network problems 2015-09-01
              </Timeline.Item>
              <Timeline.Item>Technical testing 2015-09-01</Timeline.Item>
              <Timeline.Item>
                Network problems being solved 2015-09-01
              </Timeline.Item>
            </Timeline>
          </RoundedCard>
        </Col> */}
      </Row>
    );
  }
}

export default Dashboard;

const RoundedCard = styled(Card)`
  // border-radius: 12px !important;
  border: 0px !important;
`;
