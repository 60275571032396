import { BaseDetail } from 'App/Component/Bases'
import fields from 'App/Screens/SubModules/Merchant/SkuMapping/Management/fields'

class SkuMappingManagement extends BaseDetail {
  constructor(props) {
    super(props)

    this.title = 'SKU Mapping';
    this.bulkName = 'product_code'; // for labelling in reference
    // urlData = 'module-merchant-skuMapping'
    // urlName = 'module-merchant-skuMapping-delete'
    this.urlData = 'skuMappings'
    // urlName = 'skuMappings-delete'
    this.fields = fields;
    this.propsDetail = {
      urlKeyHistory: 'api:admin:merchants:skumapping:detail-revision',
      keyLabel: 'sku'
    }
  }

  getAppName = () => 'merchant'

  getModelName = () => 'skumapping'
}

export default SkuMappingManagement