import { BaseList, BaseDetail } from "App/Component/Bases";
import fields from "./fields";
import options from "./options";

const title = "Job Status Histories";
const urlData = "jobStatusHistories";
const bulkName = "job.name";

class List extends BaseList {
  constructor(props) {
    super(props)

    this.title = title;
    this.urlData = urlData;
    this.bulkName = bulkName;
    this.propsList = {
      exportImport: options.exportImportOptions,
    }
  }
}

class Detail extends BaseDetail {
  title = title;
  urlData = urlData;
  bulkName = bulkName;
  fields = fields;
}

export default { List, Detail };