const rootMenu = [
  'Estimation',
  'Geo',
  'Jobs',
  'Multisite',
  'Notifications',
  'Reports',
  'User'
]

const estimationMenu = [
  'Export import sessions',
  'Export mapping lines',
  'Export mappings',
  'Formulas',
  'Line templates',
  'Line usages',
  'Override lines',
  'Override set groups',
  'Override sets',
  'Pitch factors',
  'Pm hardware starting ranges',
  'Pm sort codes',
  'Pm subhead mappings',
  'Pm timber starting ranges',
  'Prefill lines',
  'Prefill set groups',
  'Prefill sets',
  'Product groups',
  'Products',
  'Random mappings',
  'Sections',
  'Subhead maps',
  'Subheadings',
  'Subsections',
  'Template sets',
  'Units',
]

const geoMenu = [
  'Countries',
  'Provinces',
  'Cities',
];

const jobMenu = [
  'Accounts',
  'Building types',
  'Buildings',
  'Extra charge jobs',
  'Extra charge sizes',
  'Extra charges',
  'Holidays',
  'Job Status Not Synceds',
  'Job grades',
  'Job queues',
  'Job status histories',
  'Job types',
  'Merchant groups',
  'Merchants',
  'Operating branches',
  'Ranges',
  'Rate names',
  'Rate types',
  'Tags',
  'Take off types',
]

const multisiteMenu = [
  'Branches',
  'Pending monitor reports',
  'Servers',
]

const notificationMenu = [
  'Notifications',
]

const reportMenu = [
  'Mapping modes',
  'Merchant sku descriptions',
  'Mm securities',
  'Sku mappings',
  'Special messages',
]

const usersMenu = [
  'Staff leaves',
  'Staff positions',
  // 'Users',
  // 'Groups',
]

const userGroupMenu = [
	'Group',
	'Users',
]

const userGroupManageMenu = [
	'Day Off',
]

const userManagementMenu = [
	'Stats',
	'Profile',
	'Branch',
]
const jobDetailManagementMenu = [
	'Stats',
	'Job history'
]

const jobQManagementMenu = [
  'Stats',
  'Settings'
]

const branchManagementMenu = [
	'Stats',
	'Manage',
	'Users',
	'Manpower',
	'Settings'
]

const productMenu = [
  'Detail',
  'Images',
  'Videos',
  'Documents',
  'Notes',
]

// Index 0 for App Name
// Menu start on index 1
const allMenu = {
  rootMenu,
  estimationMenu,
  geoMenu,
  jobMenu,
  multisiteMenu,
  notificationMenu,
  reportMenu,
  usersMenu,
}

export {
  estimationMenu,
  geoMenu,
  jobMenu,
  multisiteMenu,
  notificationMenu,
  reportMenu,
  usersMenu,
	branchManagementMenu,
  jobQManagementMenu,
  jobDetailManagementMenu,
	userManagementMenu,
	userGroupMenu,
  userGroupManageMenu,
  productMenu,
  rootMenu,
  allMenu
}
