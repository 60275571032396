import React from 'react'
import PropTypes from 'prop-types'
import { Button, Col, Row } from 'antd'

const IMPORT_TYPES = [
  { key: 'new', label: 'New' },
  { key: 'update', label: 'Update' },
  { key: 'delete', label: 'Delete' },
  { key: 'skip', label: 'Skip' },
  { key: 'ignored', label: 'Ignored' },
  { key: 'error', label: 'Error' },
  { key: 'invalid', label: 'Invalid' },
]

class FilterImportType extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      visible: props.defaultVisible,
      objSelectedKeys: this.getObjFromStr(props.importType),
    }
  }

  show = (callback = () => null) => {
    this.setVisible(true, callback)
  }

  hide = (callback = () => null) => {
    this.setVisible(false, callback)
  }

  toggle = (callback = () => callback) => {
    this.setVisible(!this.state.visible, callback)
  }

  setVisible = (visible, callback) => {
    this.setState({ visible }, () => {
      if (typeof callback === 'function') {
        callback()
      }
    })
  }

  getObjFromStr = (importType) => {
    const params = importType.split('|').filter(val => val)
    const objParam = {}
    for (let i = 0; i < params.length; i += 1) {
      objParam[params[i]] = true
    }
    return objParam
  }

  getStrFromObj = (objParam) => {
    let strParam = ''
    let index = 0
    for (const key in objParam) {
      if (objParam[key] === true) {
        if (index > 0) {
          strParam += '|'
        }
        strParam += key
        index++
      }
    }
    return strParam
  }

  onParamChange = (val) => {
    this.setState(prevState => ({
      objSelectedKeys: {
        ...prevState.objSelectedKeys,
        [val.key]: !prevState.objSelectedKeys[val.key]
      }
    }))
  }

  onConfirmFilter = () => {
    const importType = this.getStrFromObj(this.state.objSelectedKeys)
    this.hide(() => {
      this.props.onConfirm(importType)
    })
  }

  render() {
    const { visible, objSelectedKeys } = this.state

    return (
      <Row className='mb-2' type='flex' justify='end' style={{ display: visible ? 'flex' : 'none' }}>
        <Col>
          <Row type='flex' align='middle' gutter={[12, 12]}>
            <Col style={{ fontSize: '14px', fontWeight: 'bold' }}>
              Import Type :
            </Col>
            <Col>
              <Button.Group>
                {IMPORT_TYPES.map(val => {
                  const propsSetting = objSelectedKeys[val.key] ? { type: 'primary', icon: 'check-square' } : { icon: 'border' }
                  return (
                    <Button key={val.key} size='small' {...propsSetting} onClick={() => this.onParamChange(val)}>
                      {val.label}
                    </Button>
                  )
                })}
              </Button.Group>
            </Col>
            <Col>
              <Button size='small' icon='check' onClick={this.onConfirmFilter}>
                OK
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
}

FilterImportType.propTypes = {
  importType: PropTypes.string,
  onConfirm: PropTypes.func,
}

FilterImportType.defaultProps = {
  importType: '',
  onConfirm: () => null,
}

export default FilterImportType