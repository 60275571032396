export default [
  {
    title: 'Name',
    type: 'text',
    data: '',
    required: true,
    initialValue: '',
    note_text: 'e.g. "House".',
    additional: '',
    dataIndex: 'name',
    order: 0,
  }, {
    title: 'Icon',
    type: 'fileImg',
    data: '',
    initialValue: '',
    note_text: 'Recommended image dimension: 60 x 60 pixels. File types: PNG, JPEG, or GIF *only*.',
    additional: '',
    dataIndex: 'icon',
    order: 1,
  }, {
    title: 'Sequence',
    type: 'text',
    data: '',
    required: true,
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'sequence',
    order: 2,
  }
];
