import React from 'react'
import { Card, Spin } from 'antd'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highcharts'
import ReactResizeDetector  from 'react-resize-detector'
import { get } from 'lodash'

import { RequestV2 as Request } from 'App/Utils'
import PageError from 'App/Component/PageError'

const opts = {
  "chart": {
    "type": "line"
  },
  "title": {
    "text": " "
  },
  "xAxis": {
    "categories": []
  },
  "yAxis": {
    "title": {
      "text": "Usage / unit"
    }
  },
  "plotOptions": {
    "line": {
      "dataLabels": {
        "enabled": true
      },
      "enableMouseTracking": true
    },
    series: {
      events: {
        legendItemClick: function(event) {
          // event.stopPropagation()
          // or
          return false
        }
      }
    }
  },
  "series": [],
  "credits": {
    "enabled": false
  }
}

class UsedStat extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
    }
  }

  read = (keyLoading, callback) => {
    this.setState({ isLoading: true }, () => {
      Request(
        'get',
        'module-product-detail-usedStat',
        {},
        { limit_days: this.props.limitDays },
        [this.props.productId],
        this.readSuccess,
        this.readFailed,
        { callback }
      )
    })
  }

  readSuccess = (response) => {
    if (typeof get(this._chart, ['chart', 'update']) === 'function')
      this._chart.chart.update(this.getChartOptions(response.data))

    this.props.setUsedStat(response.data)

    this.setState({ isLoading: false, options: this.getChartOptions(response.data), errorResponse: null })
  }

  readFailed = (error, extra) => {
    this.setState({ isLoading: false, errorResponse: error.response }, extra.callback)
  }

  getChartOptions = (product) => {
    const products = [product]

    const categories = []
    const series = []

    for (let i = 0; i < products.length; i += 1) {
      // Initializing fields
      if (i === 0) {
        const { range } = products[i].usage_range
        for (const j in range) {
          categories.push(range[j].label)
        }
      }

      // Convert Data to chart
      series.push({
        name: products[i].name,
        pk: products[i].pk,
        data: products[i].usage_range.range.map(val => val.total),
        showInLegend: false,
      })
    }

    // opts.title.text = `Used ${product.usage_range.total}`
    opts.xAxis.categories = categories
    opts.series = series

    return opts
  }

  componentDidMount() {
    this.read()
  }

  componentWillUnmount() {
    clearInterval(this._read)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.limitDays !== nextProps.limitDays) {
      this.read()
    }
  }

  render() {
    if (this.state.errorResponse) {
      return (
        <PageError
          errorResponse={this.state.errorResponse}
          onReload={this.read}
        />
      )
    }

    return (
      <Spin spinning={this.state.isLoading}>
          <ReactResizeDetector handleWidth>
            {({ width }) => {
              if (
                typeof get(this._chart, ['chart', 'reflow']) === 'function' &&
                this.widthContainer !== width
              ) {
                clearTimeout(this._reflow)

                this._reflow = setTimeout(() => {
                  try {
                    this._chart.chart.reflow()
                  } catch(e) {}
                }, 500)
              }

              this.widthContainer = width

              return (
                <div>
                  {/* <h2>Product Statistics</h2> */}
                  <HighchartsReact
                    ref={ref => {this._chart = ref}}
                    highcharts={Highcharts}
                    options={this.state.options}
                  />
                </div>
              )
            }}
          </ReactResizeDetector>
      </Spin>
    )
  }
}

export default UsedStat