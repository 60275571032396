import React from 'react'
import PropTypes from 'prop-types'
import CustomDescriptions from 'App/Screens/Estimation/ExportImportSessionNew/internal/CustomDescriptions'
import descriptions from './descriptions'

class PageResult extends React.PureComponent {
  render() {
    const { exportImport } = this.props

    return (
      <CustomDescriptions fields={descriptions} record={exportImport} />
    )
  }
}

PageResult.propTypes = {
  exportImport: PropTypes.object.isRequired,
}

export default PageResult