import { BaseList } from "App/Screens/SubModules/Component";
import descriptor from "./descriptorOffline";

class BranchList extends BaseList {
  constructor(props) {
    super(props)

    this.appName = 'multisite'
    this.modelName = 'branch'
    this.availablePermissions = ['.view_', '.change_'] // jika mengganti ini, juga perlu ganti di admin
    this.propsList = {
      avatar: 'name',
      pageTitle: 'Branch',
      descriptor,
      masterKey: 'module-branch',
      groupByOptions: [
        {
          label: "Status",
          key: "status",
          dataIndex: 'status'
        },
      ]
    }
    this.state = {
      ...this.state,
      currentPermissionSet: this.isSuperuser ? this.availablePermissions : [],
    }
  }
}

export default BranchList