export default [
  {
    title: 'Name',
    type: 'text',
    required: true,
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex:'name',
    order: 0,
  }, {
    title: 'Permissions',
    type: 'select_multiple',
    data: 'permissions',
    initialValue: '',
    note_text: '',
    required: true,
    additional: '',
    selectedKey: 'pk',
    selectedValue: 'name',
    dataIndex:'permissions',
    minSelected: 2,
    order: 1,
  },
]