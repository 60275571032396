import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {Drawer, Col, Row, Form, Switch, Typography, Avatar, Icon, Tabs, message, Select, Button, Tag} from 'antd'
const { Option } = Select;
const {TabPane} = Tabs

class UserSetting extends React.Component {
  constructor() {
    super()
    this.state = {
      drawerEdit: false,
      loading: false
    }
  }

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  render(){
    // const {userReducer} = this.props
    const {data, form, history} = this.props
    const {loading} = this.state
    return(
      <div>
        <Drawer
          width="50%"
          style={{top:'65px'}}
          placement="right"
          closable={false}
          onClose={this.props.toggle}
          visible={this.props.visible}
        >
            <Tag color="magenta">User Setting
            </Tag>
          <Tabs
            tabPosition="top"
            defaultActiveKey="1"
          >
            <TabPane
              tab={
                <span>
                  <Icon type="safety" /> Security
                </span>
              }
              key="1"
            >
              <Row gutter={15}>
                <Col sm={9} className="pl-5">
                  <div className="d-flex flex-column">
                    <center>
                      <Typography.Text strong={true} type="secondary">Two factor security</Typography.Text>
                      <Avatar
                        size={180}
                        icon="user"
                        src={''}
                        alt="Profile photo"
                        className="mb-4"
                      />
                    </center>
                    <Button type="primary">Generate Code</Button>
                  </div>
                </Col>
                <Col sm={15}>
                  <Form layout="vertical">
                    <Row gutter={15}>
                      <Col md={12}>
                        <Form.Item label="Two Factor Security">
                          {form.getFieldDecorator("t2f", {
                            rules: [
                              {
                                required: true
                              }
                            ]
                          })(<Switch defaultChecked />)}
                        </Form.Item>
                        <Form.Item
                          label="Notification login"
                          extra={<span className="font-xs text-muted">Notify by email if a new device login to this account</span>}
                        >
                          {form.getFieldDecorator("notify", {
                            rules: [
                              {
                                required: true
                              }
                            ]
                          })(<Switch defaultChecked />)}
                        </Form.Item>
                      </Col>
                      <Col md={12}>
                        <Form.Item label="Auto login">
                          {form.getFieldDecorator("autologin", {
                            rules: [
                              {
                                required: true
                              }
                            ]
                          })(<Switch defaultChecked />)}
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Col>
                <Col span={24} className="p-2 text-right">
                  <Button type="danger" onClick={(e) => this.props.toggle()}>
                    Cancel
                  </Button>
                  <Button style={{marginLeft: "5px"}} loading={loading} type="primary" onClick={() => message.info("API still underconstruction")}>
                    Save
                  </Button>
                </Col>
              </Row>
            </TabPane>
            <TabPane
              tab={
                <span>
                  <Icon type="setting" /> Setting
                </span>
              }
              key="2"
            >
              <Row className="mt-5">
                <Col sm={24}>
                  <Form layout="vertical">
                    <Row gutter={15}>
                      <Col md={12}>
                        <Form.Item label="Language">
                          {form.getFieldDecorator("language", {
                            initialValue: '🇺🇸 English',
                            rules: [
                              {
                                required: true
                              }
                            ]
                          })(<Select
                            mode="default"
                            style={{ width: '100%' }}
                            placeholder="select one country"
                            // defaultValue={['china']}
                            optionLabelProp="label"
                          >
                            <Option value="usa" label="USA">
                          <span role="img" aria-label="USA">
                            🇺🇸
                          </span>
                              USA (English)
                            </Option>
                            <Option value="china" label="China">
                            <span role="img" aria-label="China">
                              🇨🇳
                            </span>
                              China (中国)
                            </Option>
                            <Option value="japan" label="Japan">
                            <span role="img" aria-label="Japan">
                              🇯🇵
                            </span>
                              Japan (日本)
                            </Option>
                            <Option value="korea" label="Korea">
                            <span role="img" aria-label="Korea">
                              🇰🇷
                            </span>
                              Korea (韩国)
                            </Option>
                          </Select>)}
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Col>
                <Col span={24} className="p-2 text-right">
                  <Button
                    type="danger"
                    onClick={(e) => this.props.toggle()}
                  >
                    Cancel
                  </Button>
                  <Button style={{marginLeft: "5px"}} loading={loading} type="primary" onClick={() => message.info("API still underconstruction")}>
                    Save
                  </Button>
                </Col>
              </Row>
            </TabPane>
          </Tabs>
        </Drawer>
      </div>
    )
  }
}
UserSetting.propTypes = {
  visible: PropTypes.bool,
  toggle: PropTypes.func
};
export default Form.create()(UserSetting);

const StyledTitle = styled.p`
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 24px;
  display: block;
  margin-bottom: 24px;
`
const StyledSubTitle = styled.p`
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 24px;
  display: block;
  margin-bottom: 16px;
`
const StyledDesc = styled.div`
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 7px;
  color: rgba(0, 0, 0, 0.65);
`
const StyledDescTitle = styled.p`
  margin-right: 8px;
  display: inline-block;
  color: #000000;
  margin-bottom: 0;
`
