import React from 'react'
import PropTypes from 'prop-types'
import { Dropdown, Icon, Menu } from 'antd'

class MoreSettings extends React.Component {
  getMenu = () => {
    const { menus } = this.props
    return (
      <Menu onClick={this.onMenuClick}>
        {this.mappingMenu(menus)}
      </Menu>
    )
  }

  mappingMenu = (menus) => {
    return (
      menus.map(menu => menu.menus ? (
        <Menu.SubMenu
          key={menu.key}
          title={
            <span style={{ display: 'inline-block', paddingRight: '4px' }}>
              {menu.icon && <Icon type={menu.icon} />}
              {menu.label}
            </span>
          }
        >
          {this.mappingMenu(menu.menus)}
        </Menu.SubMenu>
      ) : (
        <Menu.Item key={menu.key} {...menu.props}>
          {menu.render ? (
            menu.render(menu.label, menu)
          ) : (
            <>
              {menu.icon && <Icon type={menu.icon} />}
              {menu.label}
            </>
          )}
        </Menu.Item>
      ))
    )
  }

  // options = { item, key, keyPath, domEvent }
  onMenuClick = (options) => {
    this.findMenuClick(this.props.menus, options.keyPath, options)
  }

  findMenuClick = (menus, keyPath, options) => {
    for (let i = 0; i < menus.length; i += 1) {
      if (menus[i].key === keyPath[keyPath.length - 1] && menus[i].onClick) {
        menus[i].onClick(options)
        break
      } else if (menus[i].menus) {
        this.findMenuClick(
          menus[i].menus,
          keyPath.slice(0, keyPath.length - 1),
          options
        )
      }
    }
  }

  render() {
    return (
      <Dropdown
        trigger={['click']}
        placement='bottomRight'
        overlay={this.getMenu()}
        overlayStyle={{ minWidth: '130px' }}
      >
        <Icon
          type='more'
          className='hovered-gray'
          style={{ borderRadius: '12px', padding: '4px 3px' }}
        />
      </Dropdown>
    )
  }
}

MoreSettings.propTypes = {
  menus: PropTypes.array,
}

MoreSettings.defaultProps = {
  menus: [],
}

export default MoreSettings