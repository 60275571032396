// Estimations
import exportImportSessions from 'App/Screens/Estimation/ExportImportSession/fields';
import exportMappingLines from 'App/Screens/Estimation/ExportMappingLines/fields';
import exportMappings from 'App/Screens/Estimation/ExportMappings/fields';
import formulas from 'App/Screens/Estimation/Formulas/fields';
import lineTemplates from 'App/Screens/Estimation/LineTemplates/fields';
import lineUsages from 'App/Screens/Estimation/LineUsages/fields';
import overrideLines from 'App/Screens/Estimation/OverrideLines/fields';
import overrideSetGroups from 'App/Screens/Estimation/OverrideSetGroups/fields';
import overrideSets from 'App/Screens/Estimation/OverrideSets/fields';
import pitchFactors from 'App/Screens/Estimation/PitchFactors/fields';
import pmHardwareStartingRanges from 'App/Screens/Estimation/PmHardwareStartingRanges/fields';
import pmSortCodes from 'App/Screens/Estimation/PmSortCodes/fields';
import pmSubheadMappings from 'App/Screens/Estimation/PmSubheadMappings/fields';
import pmTimberStartingRanges from 'App/Screens/Estimation/PmTimberStartingRanges/fields';
import prefillLines from 'App/Screens/Estimation/PrefillLines/fields';
import prefillSetGroups from 'App/Screens/Estimation/PrefillSetGroups/fields';
import prefillSets from 'App/Screens/Estimation/PrefillSets/fields';
import productGroups from 'App/Screens/Estimation/ProductGroups/fields';
import products from 'App/Screens/SubModules/Products/Product/fields';
import productImages from 'App/Screens/SubModules/Products/Product/Management/Images/fields'; // +
import productVideos from 'App/Screens/SubModules/Products/Product/Management/Videos/fields'; // +
import productDocuments from 'App/Screens/SubModules/Products/Product/Management/Documents/fields'; // +
import randomMappings from 'App/Screens/Estimation/RandomMappings/fields';
import sections from 'App/Screens/Estimation/Sections/fields';
import subheadMaps from 'App/Screens/Estimation/SubheadMaps/fields';
import subheadings from 'App/Screens/Estimation/Subheadings/fields';
import subsections from 'App/Screens/Estimation/Subsections/fields';
import templateSets from 'App/Screens/Estimation/TemplateSets/fields';
import units from 'App/Screens/Estimation/Units/fields';

// Geo
import countries from 'App/Screens/Geo/Countries/fields';

// Jobs
import accounts from 'App/Screens/Job/Accounts/fields'
import buildingTypes from 'App/Screens/Job/BuildingTypes/fields';
import buildings from 'App/Screens/Job/Buildings/fields'
import extraChargeJobs from 'App/Screens/Job/ExtraChargeJobs/fields'
import extraChargeMappings from './Jobs/extraChargeMappings';
import extraChargeSizes from 'App/Screens/Job/ExtraChargeSizes/fields';
import extraCharges from 'App/Screens/Job/ExtraCharges/fields';
import holidays from 'App/Screens/Job/Holidays/fields'
import jobs from './Jobs/jobs';
import jobStatusNotSynceds from 'App/Screens/Job/JobStatusNotSynceds/fields'
import jobGrades from 'App/Screens/Job/JobGrades/fields';
import jobQueues from 'App/Screens/Job/JobQueues/fields';
// import jobQueues from 'App/Screens/SubModules/JobMg/Management/JobQueue/fields';
import jobStatusHistories from 'App/Screens/Job/JobStatusHistories/fields'
import jobTypes from 'App/Screens/Job/JobTypes/fields'
import merchantGroups from 'App/Screens/Job/MerchantGroups/fields';
import merchants from 'App/Screens/Job/Merchants/fields';
import operatingBranches from 'App/Screens/Job/OperatingBranches/fields';
import ranges from 'App/Screens/Job/Ranges/fields'
import rateNames from 'App/Screens/Job/RateNames/fields'
import rateTypes from 'App/Screens/Job/RateTypes/fields';
import plans from "./Jobs/plans";
import tags from 'App/Screens/Job/Tags/fields'
import takeOffTypes from 'App/Screens/Job/TakeOffTypes/fields'

// Multisite
import branches from 'App/Screens/Multisite/Branches/fields';
import branchManagement from 'App/Screens/SubModules/Branches/Branch/Management/fields';
import pendingMonitorReports from 'App/Screens/Multisite/PendingMonitorReports/fields'
import servers from 'App/Screens/Multisite/Servers/fields'

// Notifications
import notifications from 'App/Screens/Notification/Notifications/fields'

// Reports
import merchantMappingModes from 'App/Screens/Report/MerchantMappingModes/fields';
import mappingModes from 'App/Screens/Report/MappingModes/fields';
import merchantSkuDescriptions from 'App/Screens/Report/MerchantSkuDescriptions/fields'
import mmSecurities from 'App/Screens/Report/MmSecurities/fields'
import skuMappings from 'App/Screens/Report/SkuMappings/fields'
import specialMessages from 'App/Screens/Report/SpecialMessages/fields'

// Users
import staffLeaves from 'App/Screens/Users/StaffLeaves/fields'
import staffPositions from 'App/Screens/Users/StaffPositions/fields';
import users from './Users/users';
import user from './Users/users';
import groups from './Users/groups'


export default {
  exportImportSessions,
  exportMappingLines,
  exportMappings,
  formulas,
  lineTemplates,
  lineUsages,
  overrideLines,
  overrideSetGroups,
  overrideSets,
  pitchFactors,
  pmHardwareStartingRanges,
  pmSortCodes,
  pmSubheadMappings,
  pmTimberStartingRanges,
  prefillLines,
  prefillSetGroups,
  prefillSets,
  productGroups,
  products,
  productImages, // +
  productVideos, // +
  productDocuments, // +
  randomMappings,
  sections,
  subheadMaps,
  subheadings,
  subsections,
  templateSets,
  units,

  countries,

  accounts,
  buildingTypes,
  buildings,
  extraChargeJobs,
  extraChargeMappings,
  extraChargeSizes,
  extraCharges,
  holidays,
  jobs,
  jobStatusNotSynceds,
  plans,
  jobGrades,
  jobQueues,
  jobStatusHistories,
  jobTypes,
  merchantGroups,
  merchants,
  operatingBranches,
  ranges,
  rateNames,
  rateTypes,
  tags,
  takeOffTypes,

  branches,
  branchManagement,
  pendingMonitorReports,
  servers,

  notifications,

  merchantMappingModes,
  mappingModes,
  merchantSkuDescriptions,
  mmSecurities,
  skuMappings,
  specialMessages,

  staffLeaves,
  staffPositions,
  users,
  user,
  groups,
}
