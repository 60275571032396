import React from 'react'
import { Row, Spin, Icon } from 'antd'
import { get } from 'lodash'
import ModeAdd from './Add'
import ModeEdit from './Detail'
import { PermissionName } from 'App/Config'
import { CheckPermission } from 'App/Utils'
import { forcePermissions } from 'App/Data'
import PageUnauthorized from 'App/Component/PageUnauthorized'

class JobQueue extends React.Component {
  constructor(props) {
    super(props)

    // Karena ModeAdd menggunakan BaseDetail dari Admin yang ada checkingpermission,
    // jadi disini membutuhkan isOperationAdd untuk isCheckingPermission dan isAccessAllowed
    this.isOperationAdd = get(props.match, ['params', 'id']) === 'add'
    this.isSuperuser = get(props.userReducer, ['user', 'is_superuser'])
    this.state = {
      // Permission
      isCheckingPermission: !this.isSuperuser && !this.isOperationAdd,
      isAccessAllowed: this.isSuperuser || this.isOperationAdd,
      currentPermissionSet: this.isSuperuser ? forcePermissions : [],
      permissionSetJob: ['.view_', '.add_'],
      // ----------
    }
  }

  setThisState = (obj, callback = () => null, force) => {
    if (force === true) {
      return this.setState(obj, callback)
    } else {
      for (const key in obj) {
        if (obj[key] !== this.state[key]) {
          return this.setState(obj, callback)
        }
      }
    }
    callback()
  }

  handleCheckPermission = (callback = () => null) => {
    this.setThisState({ isCheckingPermission: true }, () => {
      const currentPermissionSet = CheckPermission.getPermissionSet({
        userReducer: this.props.userReducer,
        appName: 'jobs',
        modelName: 'jobqueue',
      })
      const codePart = this.props.match.params.id === 'add' ? PermissionName.CREATE.codePart : PermissionName.READ.codePart
      const isAccessAllowed = !!currentPermissionSet.find(val => val.search(codePart) !== -1)
      callback({ isAccessAllowed, currentPermissionSet })
    })
  }

  componentDidMount() {
    if (this.state.isCheckingPermission) {
      this.handleCheckPermission(res => {
        const permissionSetJob = CheckPermission.getPermissionSet({
          userReducer: this.props.userReducer,
          appName: 'jobs',
          modelName: 'job',
        }).filter(item => (item || '').search(/\.view_|\.add_/) !== -1)
        this.setState({
          isCheckingPermission: false,
          isAccessAllowed: get(res, 'isAccessAllowed'),
          currentPermissionSet: get(res, 'currentPermissionSet', []),
          permissionSetJob,
        })
      })
    }
  }

  render() {
    const { isCheckingPermission, isAccessAllowed, currentPermissionSet, permissionSetJob } = this.state

    if (isCheckingPermission) {
      return (
        <Row type='flex' justify='center' align='middle' style={{ marginTop: '30vh' }}>
          <Spin
            size='large'
            tip='Checking permission...'
            indicator={<Icon spin type='loading' style={{ fontSize: '4rem' }} />}
          />
        </Row>
      )
    }

    if (isAccessAllowed) {
      return this.isOperationAdd ? (
        <ModeAdd {...this.props} urlName='api:admin:jobs:jobqueue:detail' />
      ) : (
        <ModeEdit {...this.props} currentPermissionSet={currentPermissionSet} permissionSetJob={permissionSetJob} />
      )
    }
    return <PageUnauthorized />
  }
}

export default JobQueue
