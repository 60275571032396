export default [
  {
    title: 'Name',
    type: 'text',
    data: '',
    required: true,
    initialValue: '',
    note_text: 'e.g. "Whiteman House", not "Whiteman House Stage 1".',
    additional: '',
    dataIndex: 'name',
    order: 0,
  }, {
    title: 'Type',
    type: 'SelectQuery',
    data: 'buildingTypes',
    required: true,
    initialValue: '',
    note_text: '',
    selectedKey: 'pk',
    selectedValue: 'name',
    additional: 'edit|add',
    dataIndex: 'type',
    order: 1,
  }, {
    title: 'Street1',
    type: 'text',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'street1',
    order: 2,
  }, {
    title: 'Street2',
    type: 'text',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'street2',
    order: 3,
  }, {
    title: 'Suburb',
    type: 'text',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'suburb',
    order: 4,
  }, {
    title: 'Post Code',
    type: 'text',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'post_code',
    order: 5,
  },
]