import { BaseList } from "App/Screens/SubModules/Component";
import descriptor from "App/Screens/Job/RateTypes/descriptor";

class RateTypeList extends BaseList {
  constructor(props) {
    super(props)

    this.appName = 'jobs'
    this.modelName = 'ratetype'
    this.availablePermissions = ['.view_']
    this.propsList = {
      avatar: 'type',
      pageTitle: 'Rate Type',
      descriptor,
      masterKey: 'module-job-rateType',
    }
    this.state = {
      ...this.state,
      currentPermissionSet: this.isSuperuser ? this.availablePermissions : [],
    }
  }
}

export default RateTypeList