import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Card, Descriptions, Avatar, Icon, Empty, Button, Spin, Typography } from 'antd'
import { get, uniqBy } from 'lodash'
import { Group, CardItems } from './Component'

class PermissionProfile extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: !props.profile.is_superuser,
      isChanging: false,
      modules: [],
      reports: [],
      queues: [],
      selectedGroups: [],
    }
  }

  setPermission = () => {
    const { profile } = this.props
    const modules = this.mergeModules(profile)
    const reports = this.mergeReports(profile)
    const queues = this.mergeQueues(profile)
    const selectedGroups = profile.groups.map(group => group.pk)
    
    this.setState({ isLoading: false, modules, reports, queues, selectedGroups })
  }

  mergeModules = (profile) => {
    const allModules = []
    const groups = get(profile, 'groups', [])
    for (const group of groups) {
      for (const allowedModule of group.allowed_modules) {
        if (!allModules.find(module => module.pk === allowedModule.pk)) {
          allModules.push({ ...allowedModule, __groups: [group.pk] })
        } else {
          const tempIndex = allModules.findIndex(module => module.pk === allowedModule.pk)
          if (get(allModules[tempIndex], '__groups')) allModules[tempIndex].__groups.push(group.pk)
        }
      }
    }
    return allModules
  }

  mergeReports = (profile) => {
    const allReports = []
    const groups = get(profile, 'groups', [])
    for (const group of groups) {
      for (const allowedReport of group.allowed_reports) {
        if (!allReports.find(report => report.pk === allowedReport.pk)) {
          allReports.push({ ...allowedReport, __groups: [group.pk] })
        } else {
          const tempIndex = allReports.findIndex(report => report.pk === allowedReport.pk)
          if (get(allReports[tempIndex], '__groups')) allReports[tempIndex].__groups.push(group.pk)
        }
      }
    }
    return allReports
  }

  mergeQueues = (profile) => {
    const allQueues = []
    const groups = get(profile, 'groups', [])
    for (const group of groups) {
      for (const branchQueue of group.branch_queues) {
        if (!allQueues.find(queue => queue.pk === branchQueue.pk)) {
          allQueues.push({ ...branchQueue, __groups: [group.pk] })
        } else {
          const tempIndex = allQueues.findIndex(queue => queue.pk === branchQueue.pk)
          if (get(allQueues[tempIndex], '__groups')) allQueues[tempIndex].__groups.push(group.pk)
        }
      }
    }
    return allQueues
  }

  onSelectedGroupChange = (selectedKeys) => {
    this.setState({ isChanging: true }, () => {
      setTimeout(() => {
        this.setState({ selectedGroups: selectedKeys }, () => {
          this.setState({ isChanging: false })
        })
      }, 500)
    })
  }

  componentDidMount() {
    if (!this.props.profile.is_superuser) {
      this.setPermission()
    }
  }

  render() {
    const { profile } = this.props
    const { isLoading, modules, reports, queues, selectedGroups, isChanging } = this.state

    if (profile.is_superuser) {
      return (
        <Row type='flex' justify='center' align='middle' style={{ height: 'calc(100vh - 112px)', textAlign: 'center', width: '100%' }}>
          <Col>
            <Typography.Title style={{ fontWeight: 'bold', fontSize: '53px', marginBottom: 0, textDecoration: 'underline' }}>
              YOU ARE <span style={{ color: '#1890ff' }}>SUPER USER</span>
            </Typography.Title>
            <Typography.Title style={{ fontWeight: 'bold', marginTop: '-7px' }}>
              ALL ACCESS GRANTED TO YOU
            </Typography.Title>
          </Col>
        </Row>
      )
    }

    return !isLoading ? (
      <Spin spinning={isChanging}>
        <Row type='flex' gutter={[24, 24]}>
          <Col span={24}>
            <Group groups={profile.groups} selectedGroups={selectedGroups} onChange={this.onSelectedGroupChange} />
          </Col>
          <Col xs={24} sm={24} md={8}>
            <CardItems title='Modules' items={modules} selectedGroups={selectedGroups} />
          </Col>
          <Col xs={24} sm={24} md={8}>
            <CardItems title='Reports' items={reports} selectedGroups={selectedGroups} />
          </Col>
          <Col xs={24} sm={24} md={8}>
            <CardItems title='Job Queues' items={queues} selectedGroups={selectedGroups} />
          </Col>
        </Row>
      </Spin>
    ) : (
      <Row type='flex' justify='center' align='middle' style={{ margin: '30vh 0' }}>
        <Col>
          <Spin
            size="large"
            tip="Preparing layout..."
            indicator={<Icon type="loading" style={{ fontSize: "4rem" }} spin />}
          />
        </Col>
      </Row>
    )
  }
}

PermissionProfile.propTypes = {
  profile: PropTypes.object.isRequired,
}

export default PermissionProfile