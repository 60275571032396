import React from 'react'
import PropTypes from 'prop-types'
import { Card, Affix, Row, Col, Form, Button, Input, InputNumber, Icon, message } from 'antd'
import { get } from 'lodash'
import { FormContext, FormProvider } from 'App/Component/Context/ContextForm'
import CustomTree from 'App/Screens/SubModules/UserGroup/User/Management/Component/CustomTree'
import SelectQuery from 'App/Component/SelectQuery'
import { RequestV2 as Request, Lib } from 'App/Utils'
import Lib2 from 'App/Component/Singlepage/Lib2'

class PageJobQueues extends React.Component {
  constructor(props) {
    super(props)

    this.merchantTitles = ['Available Allowed Merchants', 'Chosen Allowed Merchants']
    this.state = {
      isSaving: false,
    }
  }

  onSave = () => {
    if (get(this._branch, 'validateFields') && this.currentForm) {
      this.currentForm.validateFieldsAndScroll({scroll: {offsetTop:150}}, (error, values) => {
        if (!error) {
          const convertedValues = Lib2.getValuesWithFile(values)
          const userBranchQueues = this._branch.validateFields()
          this.setState({ isSaving: true }, () => {
            Request(
              'patch',
              'module-groupUser-user-update',
              {},
              { ...convertedValues, user_branch_queues: userBranchQueues },
              [get(this.props.user, 'pk')],
              this.saveSuccess,
              this.saveFailed,
            )
          })
        }
      })
    }
  }

  saveSuccess = (response) => {
    message.success('Access "Branch Queues" has been saved')
    this.setState({ isSaving: false })
  }

  saveFailed = (error) => {
    message.error('Failed to save "Branch Queues"')
    const errors = get(error, ['response', 'data'])
    if (errors && typeof errors === 'object') {
      const fieldsError = {}
      for (const key in errors) {
        fieldsError[key] = { value: this.currentForm.getFieldValue(key), errors: [new Error(errors[key][0])] }
      }
      this.currentForm.setFields(fieldsError)
    }
    this.setState({ isSaving: false })
  }

  render() {
    const { userHelper, user } = this.props
    const { isSaving } = this.state

    return (
      <div>
        <div style={{ borderLeft: '3px solid #1890ff', backgroundColor: '#d9ecfd', padding: '8px' }}>
          Don't forget to save your changes. Because data changes will be lost when moving pages.
          Tab active: <b>JOB QUEUES</b>
        </div>
        <Card>
          <Row>
            <FormProvider>
              <FormContext.Consumer>
                {form => {
                  this.currentForm = form
                  return (
                    <Row>
                      <Col>
                        <Form.Item label='Max jobs' style={{ marginBottom: '12px' }}>
                          {form.getFieldDecorator('max_jobs', {
                            // Tidak tau kenapa, harus mengirim rules dengan array minimal berisi 1 object rule
                            rules: [{
                              required: false,
                              message: 'Please input Max jobs'
                            }],
                            initialValue: Number(get(user, 'max_jobs', 1)),
                          })(
                            <Input
                              type='number'
                              // onPressEnter={this.onSave}
                            />
                          )}
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item label='Skill level' style={{ marginBottom: '12px' }}>
                          {form.getFieldDecorator('skill_level', {
                            rules: [{
                              required: false,
                              message: 'Please input Skill level'
                            }],
                            initialValue: Number(get(user, 'skill_level', 0)),
                          })(
                            <Input
                              type='number'
                              // onPressEnter={this.onSave}
                            />
                          )}
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item label='Home Branch' style={{ marginBottom: '12px' }}>
                          {form.getFieldDecorator('home_branch', {
                            rules: [{
                              required: true,
                              message: 'Home branch cannot be blank'
                            }],
                            initialValue: Lib.setSelectValue(get(user, 'home_branch'), 'pk', 'name'),
                          })(
                            <SelectQuery
                              urlKey='branches-autocomplete'
                              userReducer={this.props.userReducer}
                              suffixIcon={<Icon type="caret-down"/>}
                              valKey='pk'
                              valLabel='name'
                              style={{ width: '100%' }}
                            />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                  )
                }}
              </FormContext.Consumer>
            </FormProvider>
          </Row>
          {/* input number Max jobs */}
          <div>
            <CustomTree
              autoExpand
              ref={ref => {this._branch = ref}}
              title='Job Queues'
              dataSource={userHelper.branch}
              initialValue={user.user_branch_queues}
              // setTouched={this.setTouched}
            />
          </div>
          <Affix offsetBottom={0}>
            <div style={{ backgroundColor: '#fff', padding: '12px 0px' }}>
              <Button block type='primary' loading={isSaving} onClick={this.onSave}>
                Save
              </Button>
            </div>
          </Affix>
        </Card>
      </div>
    )
  }
}

export default PageJobQueues