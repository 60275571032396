import React from 'react'

const WarningLabel = React.memo(function WarningLabel(props) {
  return (
    <div style={{ borderLeft: '3px solid #1890ff', backgroundColor: '#d9ecfd', padding: '8px' }}>
      Any action taken in the <b>{props.tabName}</b> is not saved,
      please press the <b>Save</b> button before leaving {props.menu || 'Group'}: <b>{props.groupName.toUpperCase()}</b>
    </div>
  )
})

export default WarningLabel