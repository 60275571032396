export default {
  //estimation
  "api:admin:estimation:contenttype-autocomplete":"api/admin/estimation/content-types/autocomplete/",
  "api:admin:estimation:exportmapping-autocomplete":"api/admin/estimation/export-mappings/autocomplete/",
  "api:admin:estimation:exportmappingline-autocomplete":"api/admin/estimation/export-mapping-lines/autocomplete/",
  "api:admin:estimation:unit-autocomplete":"api/admin/estimation/units/autocomplete/",
  "api:admin:estimation:templateset-autocomplete":"api/admin/estimation/template-sets/autocomplete/",
  "api:admin:estimation:product-autocomplete":"api/admin/estimation/products/autocomplete/",
  "api:admin:estimation:productgroup-autocomplete":"api/admin/estimation/product-groups/autocomplete/",
  "api:admin:estimation:subheading-autocomplete":"api/admin/estimation/subheadings/autocomplete/",
  "api:admin:estimation:pitchfactor-autocomplete":"api/admin/estimation/pitch-factors/autocomplete/",
  "api:admin:estimation:section-autocomplete":"api/admin/estimation/sections/autocomplete/",
  "api:admin:estimation:subsection-autocomplete":"api/admin/estimation/subsections/autocomplete/",
  "api:admin:estimation:lineusage-autocomplete":"api/admin/estimation/line-usages/autocomplete/",
  "api:admin:estimation:linetemplate-autocomplete":"api/admin/estimation/line-templates/autocomplete/",
  "api:admin:estimation:prefillset-autocomplete":"api/admin/estimation/prefill-sets/autocomplete/",
  "api:admin:estimation:prefillline-autocomplete":"api/admin/estimation/prefill-lines/autocomplete/",
  "api:admin:estimation:overrideline-autocomplete":"api/admin/estimation/override-lines/autocomplete/",
  "api:admin:estimation:overrideset-autocomplete":"api/admin/estimation/override-sets/autocomplete/",
  "api:admin:estimation:pmsortcode-autocomplete":"api/admin/estimation/pm-sort-codes/autocomplete/",
  "api:admin:estimation:pmsubheadmapping-autocomplete":"api/admin/estimation/pm-subhead-mappings/autocomplete/",
  "api:admin:estimation:pmhardwarestartingrange-autocomplete":"api/admin/estimation/pm-hardware-starting-ranges/autocomplete/",
  "api:admin:estimation:pmtimberstartingrange-autocomplete":"api/admin/estimation/pm-timber-starting-ranges/autocomplete/",
  "api:admin:estimation:subheadmap-autocomplete":"api/admin/estimation/subhead-maps/autocomplete/",
  "api:admin:estimation:randommapping-autocomplete":"api/admin/estimation/random-mappings/autocomplete/",
  //geo
  "api:admin:geo:country-autocomplete":"api/admin/geo/countries/autocomplete/",
  //job
  "api:admin:jobs:account-autocomplete":"api/admin/jobs/accounts/autocomplete/",
  "api:admin:jobs:buildingtype-autocomplete":"api/admin/jobs/building-types/autocomplete/",
  "api:admin:jobs:job-autocomplete":"api/jobs/job/autocomplete/",
  "api:admin:jobs:jobqueue-autocomplete":"api/admin/jobs/job-queues/autocomplete/",
  "api:admin:jobs:holiday-autocomplete":"api/admin/jobs/holidays/autocomplete/",
  "api:admin:jobs:extracharge-autocomplete":"api/admin/jobs/extra-charges/autocomplete/",
  "api:admin:jobs:extrachargesize-autocomplete":"api/admin/jobs/extra-charge-sizes/autocomplete/",
  "api:admin:jobs:extrachargemapping-autocomplete":"api/admin/jobs/extra-charge-mappings/autocomplete/",
  "api:admin:jobs:building-autocomplete":"api/admin/jobs/buildings/autocomplete/",
  "api:admin:jobs:takeofftype-autocomplete":"api/admin/jobs/take-off-types/autocomplete/",
  "api:admin:jobs:ratetype-autocomplete":"api/admin/jobs/rate-types/autocomplete/",
  "api:admin:jobs:ratename-autocomplete":"api/admin/jobs/rate-names/autocomplete/",
  "api:admin:jobs:range-autocomplete":"api/admin/jobs/ranges/autocomplete/",
  "api:admin:jobs:jobtype-autocomplete":"api/admin/jobs/job-types/autocomplete/",
  "api:admin:jobs:jobgrade-autocomplete":"api/admin/jobs/job-grades/autocomplete/",
  "api:admin:jobs:merchant-autocomplete":"api/admin/jobs/merchants/autocomplete/",
  "api:admin:jobs:merchantgroup-autocomplete":"api/admin/jobs/merchant-groups/autocomplete/",
  "api:admin:jobs:operatingbranch-autocomplete":"api/admin/jobs/operating-branches/autocomplete/",
  //multisite
  "api:admin:multisite:pendingmonitorreport-autocomplete": "api/admin/multisite/pending-monitor-reports/autocomplete/",
  "api:admin:multisite:branch-autocomplete": "api/admin/multisite/branches/autocomplete/",
  //notification
  "api:admin:notifications:notification-autocomplete": "api/admin/notifications/notifications/autocomplete/",
  //Report
  "api:admin:reports:specialmessage-autocomplete":"api/admin/reports/special-messages/autocomplete/",
  "api:admin:reports:mmsecuritie-autocomplete":"api/admin/reports/mm-securities/autocomplete/",
  "api:admin:reports:merchantmappingmode-autocomplete":"api/admin/reports/merchant-mapping-modes/autocomplete/",
  "api:admin:reports:skumapping-autocomplete":"api/admin/reports/sku-mappings/autocomplete/",
  "api:admin:reports:merchantskudescription-autocomplete":"api/admin/reports/merchant-sku-descriptions/autocomplete/",

  //Bulk
  "bulk-action": "api/admin/estimation/{}/bulk/{}",
}
