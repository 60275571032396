import { BaseList } from "App/Screens/SubModules/Component";
import descriptor from "App/Screens/Job/Ranges/descriptor";

class RangeList extends BaseList {
  constructor(props) {
    super(props)

    this.appName = 'jobs'
    this.modelName = 'ranges'
    this.propsList = {
      avatar: 'name',
      pageTitle: 'Range',
      descriptor,
      masterKey: 'module-job-range',
    }
  }
}

export default RangeList