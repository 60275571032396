import React from 'react';
import PropTypes from 'prop-types';
import { differenceWith, get, isEqual } from 'lodash';
import debounce from 'lodash/debounce';
import { isEmpty, isArray } from 'validate.js';
import { Select, Spin, Icon } from 'antd';
import { Lib, RequestV2 as Request } from 'App/Utils';

export default class SelectQuery extends React.PureComponent {
  constructor(props) {
    super(props);
    this.initialState = {
      data: [],
      isLoading: false,
      isLoadingLoadMore: false,
    };
    this.state = Object.assign(
      {
        emptyText: 'Loading...',
        value: get(props, 'data-__meta.initialValue', { key: '', label: '' }),
      },
      this.initialState
    );
    this.onSearch = debounce(this.onSearch.bind(this), props.waitTime);
    this.onSearchSuccess = this.onSearchSuccess.bind(this);
    this.onSearchFailed = this.onSearchFailed.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onPopupScroll = this.onPopupScroll.bind(this);
    this.getDefaultValue = this.getDefaultValue.bind(this);
    this.reload = this.reload.bind(this);
    this.renderDropdown = this.renderDropdown.bind(this);
    this.renderOptions = this.renderOptions.bind(this);
    this.getLabel = this.getLabel.bind(this);

    this.nextState = {};
    this.inputValue = '';
    this.lastFetchId = 0;
    this.page = 1;
    this.next = false;
  }

  reload = () => this.onSearch('')

  onSearch(value, isLoadMore = false) {
    if (this.state.isLoadingLoadMore || this.state.isLoading) return;
    this.inputValue = value;
    this.lastFetchId += 1;
    let stateObj = {};

    if (!isLoadMore) {
      stateObj = {
        data: [],
        isLoading: true,
        emptyText: 'Not found!',
      };
      this.page = 1;
    } else {
      stateObj = {
        isLoadingLoadMore: true,
      };
      this.page += 1;
    }

    this.setState(stateObj, () =>
      Request(
        "get",
        this.props.urlKey,
        {},
        {
          ...this.props.paramProps,
          search: value,
          page: this.page,
          ordering: this.props.order,
          is_active: this.props.isActive
        },
        this.props.args,
        this.onSearchSuccess,
        this.onSearchFailed,
        { fetchId: this.lastFetchId, isLoadMore },
      )
    );
  }

  onSearchSuccess(response, { fetchId, isLoadMore }) {
    if (fetchId !== this.lastFetchId) return;
    this.nextState = this.onFilter(
      this.props.isPagination ? get(response.data, 'results', []) : response.data || []
    );
    this.next = response.data.next;
    this.setState(
      !isLoadMore
        ? {
            data: this.nextState,
            isLoading: false,
          }
        : {
            data: [...this.state.data, ...this.nextState],
            isLoadingLoadMore: false,
          }
    );
  }

  onSearchFailed(err) {
    this.setState({
      ...this.initialState,
      emptyText: `Error! ${get(err, 'response.detail', '')}`,
    });
  }

  onFilter(data) {
    return data.filter(this.props.onFilter);
  }

  onFocus() {
    const { alwaysRender } = this.props,
      forceRender = typeof alwaysRender === 'function' ? alwaysRender() : alwaysRender;

    if (isEmpty(this.state.data) || forceRender) this.onSearch('');
  }

  onPopupScroll(e) {
    e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight &&
      this.next &&
      this.onSearch(this.inputValue, true);
  }

  reload(value = '') {
    this.onSearch(value);
  }

  getDefaultValue() {
    return {
      key: this.state.data.find(val => val[this.props.defaultKey] === true),
    };
  }

  createItem(e) {
    e.preventDefault();
    const { data } = this.state;
    this.nextState = {
      [this.props.valKey]: Lib.uuid(),
      [this.props.valLabel]: this.inputValue,
    };
    data.push(this.nextState);
    this.setState({
      data,
      value: {
        key: this.nextState[this.props.valKey],
        label: this.nextState[this.props.valLabel],
      },
    });
  }

  renderNotFoundContent() {
    if (this.state.isLoading) {
      return (
        <div className="text-center">
          <Spin wrapperClassName="text-center" size="small" />
        </div>
      );
    } else {
      if (this.props.isCreatable && this.inputValue !== '') {
        return (
          <div>
            {this.state.emptyText}
            &nbsp;
            <a href="" onClick={e => this.createItem(e)}>
              Create this item.
            </a>
          </div>
        );
      } else {
        return this.state.emptyText;
      }
    }
  }

  renderLabel(val) {
    this.nextState = [];
    if (Array.isArray(this.props.valLabel)) {
      this.props.valLabel.map(label => this.nextState.push(val[label]));
      this.nextState = this.nextState.join(' ');
    } else {
      this.nextState = val[this.props.valLabel];
    }
    return this.nextState;
  }

  renderDropdown(opt) {
    return (
      <React.Fragment>
        {opt}
        <div className="d-flex justify-content-center align-items-center my-1">
          <Spin size="small" spinning={this.state.isLoadingLoadMore} />
        </div>
      </React.Fragment>
    );
  }

  renderOptions(val) {
    return (
      <Select.Option
        className="p-1 font-sm toggle-visible-hover"
        style={{ lineHeight: '1rem' }}
        // title={this.renderLabel(val)}
        title={val[this.props.valLabel]}
        key={val[this.props.valKey]}
        value={val[this.props.valKey]}
        opt={val}
      >
        {this.getLabel(val)}
        {/* {val[this.props.valLabel]} */}
      </Select.Option>
    );
  }

  getLabel(val) {
    if (Array.isArray(this.props.valLabel)) {
      const values = []
      for (let i = 0; i < this.props.valLabel.length; i += 1)
        values.push(get(val, this.props.valLabel[i], ''))

      return values.join(get(this.props, 'separator', ' - '))
    }

    return get(val, this.props.valLabel)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // console.log(differenceWith(this.props.paramProps, nextProps.paramProps))
    if (
      !isEqual(this.props.paramProps, nextProps.paramProps) ||
      !isEqual(this.props.args, nextProps.args)
    ) {
      this.setState({ data: [] })
    }
  }

  componentDidMount() {
    this._select.reload = s => this.reload(s);
    this.props.flatMode && this.onSearch('');
    this.props.onRef(this._select);
  }

  render() {
    return (
      <Select
        // showArrow={!this.props.disabled}
        allowClear
        {...this.props}
        showSearch
        labelInValue
        filterOption={false}
        // autoClearSearchValue={false}
        defaultActiveFirstOption={false}
        ref={s => (this._select = s)}
        // defaultValue={this.props.flatMode ? this.getDefaultValue() : undefined}
        // style={{ minWidth: '8.5rem', ...this.props.style }}
        dropdownRender={this.renderDropdown}
        onPopupScroll={this.onPopupScroll}
        onSearch={this.onSearch}
        onFocus={this.onFocus}
        notFoundContent={this.renderNotFoundContent()}
      >
        {this.props.emptyFirst && this.state.data.length > 0 && (
          <Select.Option className="p-1" key={this.props.emptyKey}>
            &nbsp;
          </Select.Option>
        )}
        {this.state.data.map(this.renderOptions)}
      </Select>
    );
  }
}

SelectQuery.propTypes = {
  urlKey: PropTypes.string.isRequired,
  valLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  alwaysRender: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
};

SelectQuery.defaultProps = {
  valLabel: 'name',
  valKey: 'pk',
  defaultKey: 'default',
  emptyKey: '-empty-key-',
  // order: ,
  className: 'min-w-3',
  waitTime: 600,
  paramProps: {},
  args: [],
  disabled: false,
  flatMode: false,
  isCreatable: false,
  emptyFirst: false,
  showInfo: false,
  allowClear: true,
  bordered: true,
  onRef: () => null,
  onInfoClick: () => null,
  onFilter: data => true,
  alwaysRender: false,
  isPagination: true,
};
