// old
// const forcePermissions = [
//   { code: '.add_' },
//   { code: '.view_' },
//   { code: '.change_' },
//   { code: '.delete_' },
// ]

const forcePermissions = ['.add_', '.view_', '.change_', '.delete_']

export default forcePermissions