export default [
  {
    title: 'Map name',
    type: 'text',
    data: '',
    required: true,
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'map_name',
    order: 0,
  }, {
    title: 'Subsection',
    type: 'SelectQuery',
    data: 'subsections',
    required: true,
    initialValue: '',
    note_text: '',
    additional: 'edit|add',
    selectedKey: 'pk',
    selectedValue: 'identifier',
    dataIndex: 'subsection',
    order: 1,
  }, {
    title: 'Line template',
    type: 'SelectQuery',
    data: 'lineTemplates',
    required: true,
    initialValue: '',
    note_text: '',
    additional: 'edit|add',
    selectedKey: 'pk',
    selectedValue: 'label',
    dataIndex: 'line_template',
    order: 2,
  }, {
    title: 'Subheading from',
    type: 'SelectQuery',
    data: 'subheadings',
    required: true,
    initialValue: '',
    note_text: '',
    additional: 'edit|add',
    selectedKey: 'pk',
    selectedValue: 'label',
    dataIndex: 'subheading_from',
    order: 3,
  }, {
    title: 'Subheading to',
    type: 'SelectQuery',
    data: 'subheadings',
    required: true,
    initialValue: '',
    note_text: '',
    additional: 'edit|add',
    selectedKey: 'pk',
    selectedValue: 'label',
    dataIndex: 'subheading_to',
    order: 4,
  }, {
    title: 'Sequence',
    type: 'text',
    data: '',
    initialValue: null,
    note_text: '',
    additional: '',
    dataIndex: 'sequence',
    order: 5,
  },
]
