import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import styled from 'styled-components'
// import moment from 'moment'
import {Drawer, Divider, Col, Row, Icon, Button, Form, Input, Upload, Avatar, message} from 'antd'
const UserAvatar = require("App/Assets/img/user-avatar.svg")

export class UserEditProfile extends Component {
  componentDidMount() {
    console.log('CDM', this)
    // message.info('read failed. Api still underconstruction')
  }

  render() {
    console.log('Rendered', this)
    const {userReducer} = this.props
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <Drawer
          width="50%"
          style={{top:'65px'}}
          placement="right"
          closable={false}
          onClose={this.props.toggle}
          visible={this.props.visible}
        >
          <Row>
            <Col md={1}>
              <Divider type="vertical" style={{ height: '710px'}} />
            </Col>
            <Col md={23}>
            <Form layout="horizontal" onSubmit={this.handleSubmit}>
              <Divider orientation="left"><Icon type="edit" /> Edit Profile</Divider>
              <Row gutter={10}>
                <Col md={8}>
                  <div className="text-center">
                    <span className="align-self-start">Avatar</span><br/>
                    <Avatar
                      size={205}
                      icon="user"
                      src={UserAvatar}
                      alt="Profile photo"
                      className="mb-4"
                    />
                    <Upload disabled>
                      <Button disabled>
                        <Icon type="upload" /> Change Photo
                      </Button>
                    </Upload>
                  </div>
                </Col>
                <Col md={16}>
                  <Form.Item label="Full Name">
                    {getFieldDecorator('name', {
                      rules: [
                        {
                          required: true, message: 'Please input your Name!',
                        }
                      ],
                      initialValue: userReducer.username
                    })(
                      <Input
                        onChange={(e)=>this.setState({name: e.target.value})}
                        placeholder="Name"
                        name="name"/>
                    )}
                  </Form.Item>
                  <Form.Item label="Email Address">
                    {getFieldDecorator('email', {
                      rules: [{
                        type: 'email', message: 'The input is not valid E-mail!',
                      }, {
                        required: true, message: 'Please input your E-mail!',
                      }],
                    })(
                      <Input
                        onChange={(e)=>this.setState({email: e.target.value})}
                        placeholder="E-mail"
                        name="email"/>
                    )}
                  </Form.Item>
                </Col>
                <Col md={16}>
                  <Form.Item label="Phone Number">
                    {getFieldDecorator('phone', {
                      rules: [
                        {
                          required: false
                        }
                      ],
                    })(
                      <Input
                        onChange={(e)=>this.setState({phone: e.target.value})}
                        placeholder="Phone Number"
                        name="phone"/>
                    )}
                  </Form.Item>
                </Col>
                <Col md={24}>
                  <Form.Item label="Address">
                    {getFieldDecorator('address', {
                      rules: [
                        {
                          required: false,
                        }
                      ],
                    })(
                      <Input
                        onChange={(e)=>this.setState({address: e.target.value})}
                        placeholder="Address"
                        name="address"/>
                    )}
                  </Form.Item>
                </Col>
              </Row>
                  <Divider orientation="left">Login Information</Divider>
              <Row gutter={10}>
                <Col md={13}>
                  <Form.Item label="Username">
                    {getFieldDecorator('username', {
                      rules: [
                        {
                          required: true, message: 'Please input your Username!',
                        }
                      ],
                      initialValue: userReducer.username
                    })(
                      <Input
                        onChange={(e)=>this.setState({username: e.target.value})}
                        placeholder="Username"
                        prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        name="username"/>
                    )}
                  </Form.Item>
                </Col>
                <Col md={13}>
                  <Form.Item label="Password">
                    {getFieldDecorator('password', {
                      rules: [
                        {
                          required: true, message: 'Please input your Password!',
                        }
                      ]
                    })(
                      <Input.Password
                        onChange={(e)=>this.setState({password: e.target.value})}
                        placeholder="Password"
                        prefix={<Icon type="key" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        name="password"/>
                    )}
                  </Form.Item>
                </Col>
              </Row>
                  <div className="text-right mb-4">
                    <Button
                      style={{
                        marginRight: 8,
                      }}
                      onClick={(e) => this.props.toggle()}
                    >
                      Close
                    </Button> <Button type="primary" onClick={()=> message.info('Api underconstruction')} htmlType="submit">
                      Save
                    </Button>
                  </div>
            </Form>
            </Col>
          </Row>
        </Drawer>
      </div>
  )
  }
  }

  UserEditProfile.propTypes = {
    visible: PropTypes.bool,
    toggle: PropTypes.func
  };

  const WrappedUserEditProfile = Form.create({ name: 'user_profile' })(UserEditProfile);
  export default WrappedUserEditProfile
