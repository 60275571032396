const descriptor = {
  pagination: true,
  filter_by: [],
  columns: [
    {
      "name": "name",
      "label": "Name",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "ellipsis": true,
      "search": true,
      "sort": true
    },
    {
      name: 'group_name',
      label: 'Group Name',
      data_type: 'str',
      search: true,
      sort: true,
      render: (val) => val && String(val).toUpperCase(),
    },
    {
      "name": "color",
      "label": "Color",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "search": false,
      "sort": false
    },
    {
      "name": "status",
      "label": "Status",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "search": true,
      "sort": true
    },
    {
      "name": "modified",
      "label": "Modified",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "datetime",
      "search": false,
      "sort": true
    }
  ]
}

export default descriptor