import React from 'react'
import { Dropdown, Menu, Spin, Typography } from 'antd'
import { ChartCard, MiniArea } from 'ant-design-pro/lib/Charts'
import NumberInfo from 'ant-design-pro/lib/NumberInfo'
import { get } from 'lodash'

import { RequestV2 as Request } from 'App/Utils'

class SummaryUsage extends React.PureComponent {
  constructor(props) {
    super(props)

    this.filters = [
      { key: '30d', label: '30 Days' },
      { key: '60d', label: '60 Days' },
      { key: '90d', label: '90 Days' },
    ]

    this.tempLimitDays = this.filters[0].key

    this.state = {
      isLoading: true,
      usedStat: null,
      limitDays: this.tempLimitDays,
      chartData: [],
    }
  }

  read = () => {
    this.setState({ isLoading: true }, () => {
      Request(
        'get',
        'module-product-detail-usedStat',
        {},
        { limit_days: this.tempLimitDays },
        [this.props.productId],
        this.readSuccess,
        this.readFailed,
      )
    })
  }

  readSuccess = (response) => {
    this.setState({
      isLoading: false,
      limitDays: this.tempLimitDays,
      usedStat: response.data,
      chartData: response.data.usage_range.range.map(item => ({ x: item.label, y: item.total })),
    })
  }

  readFailed = (error) => {
    this.setState({ isLoading: false })
  }

  getSelectedFilter = () => {
    const selectedFilter = this.filters.find(filter => filter.key === this.state.limitDays)
    return (
      <Dropdown placement='bottomRight' trigger={['click']} overlay={this.getFilterMenus()}>
        <span>
          Last {selectedFilter.label}
        </span>
      </Dropdown>
    )
  }

  getFilterMenus = () => {
    return (
      <Menu selectedKeys={[this.state.limitDays]} onClick={this.onFilterChange}>
        {this.filters.map(filter => (
          <Menu.Item key={filter.key}>
            {filter.label}
          </Menu.Item>
        ))}
      </Menu>
    )
  }

  onFilterChange = ({ key }) => {
    if (this.state.limitDays === key)
      return

    this.tempLimitDays = key
    this.read()
  }

  componentDidMount() {
    this.read()
  }

  render() {
    return (
      <Spin spinning={this.state.isLoading}>
        <ChartCard
          title={<Typography.Text ellipsis strong className='descriptions-item-label'>Total usage</Typography.Text>}
          contentHeight={87}
          action={this.getSelectedFilter()}
        >
          <NumberInfo total={get(this.state.usedStat, ['usage_range', 'total'], '-')} />
          <MiniArea line height={45} data={this.state.chartData} />
        </ChartCard>
      </Spin>
    )
  }
}

export default SummaryUsage