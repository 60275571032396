import PageMenu from "App/Component/PageMenu"

// ======= COMPONENT SCREEN
import MerchantMappingModes from "./MerchantMappingModes/";
import MappingModes from "./MappingModes";
import MerchantSkuDescriptions from "./MerchantSkuDescriptions/";
import MmSecurities from "./MmSecurities/";
import SkuMappings from "./SkuMappings/";
import SpecialMessages from "./SpecialMessages/";

const module = "admin";
const app = "reports";
const routes = [
  {
    name: "Reports Menu",
    path: `/${module}/${app}`,
    component: PageMenu,
    exact: true
  },
  {
    routes: [
      {
        name: "Mapping Modes",
        component: MappingModes.List,
        props: { urlName: "api:admin:merchants:mappingmode:list" },
      },
      {
        name: "Mapping Modes History",
        component: MappingModes.Detail,
        path: "/admin/reports/mapping-modes/:id/history/:historyId",
      },
      {
        name: "Mapping Modes",
        component: MappingModes.Detail,
        exact: false,
        props: { urlName: "api:admin:merchants:mappingmode:detail" },
      }
    ]
  },
  // {
  //   // title: "Merchant Mapping Modes",
  //   routes: [
  //     {
  //       name: "Merchant Mapping Modes",
  //       component: MerchantMappingModes.List,
  //       props: { urlName: "api:admin:reports:merchantmappingmode:list" },
  //     },
  //     {
  //       name: "Merchant Mapping Modes",
  //       component: MerchantMappingModes.Detail,
  //       exact: false,
  //       props: { urlName: "api:admin:reports:merchantmappingmode:detail" },
  //     }
  //   ]
  // },
  // {
  //   // title: "Merchant SKU Descriptions",
  //   routes: [
  //     {
  //       name: "Merchant SKU Descriptions",
  //       component: MerchantSkuDescriptions.List,
  //       props: { urlName: "api:admin:reports:merchantskudescription:list" },
  //     },
  //     {
  //       name: "Merchant SKU Descriptions",
  //       component: MerchantSkuDescriptions.Detail,
  //       exact: false,
  //       props: { urlName: "api:admin:reports:merchantskudescription:detail" },
  //     }
  //   ]
  // },
  // {
  //   // title: "MM Securities",
  //   routes: [
  //     {
  //       name: "MM Securities",
  //       component: MmSecurities.List,
  //       props: { urlName: "api:admin:reports:mmsecurity:list" },
  //     },
  //     {
  //       name: "MM Securities",
  //       component: MmSecurities.Detail,
  //       exact: false,
  //       props: { urlName: "api:admin:reports:mmsecurity:detail" },
  //     }
  //   ]
  // },
  {
    // title: "SKU Mappings",
    routes: [
      {
        name: "SKU Mappings",
        component: SkuMappings.List,
        props: { urlName: "api:admin:merchants:skumapping:list" },
      },
      {
        name: "SKU Mappings History",
        component: SkuMappings.Detail,
        path: "/admin/reports/sku-mappings/:id/history/:historyId",
      },
      {
        name: "SKU Mappings",
        component: SkuMappings.Detail,
        exact: false,
        props: { urlName: "api:admin:merchants:skumapping:detail" },
      }
    ]
  },
  {
    // title: "Special Messages",
    routes: [
      {
        name: "Special Messages",
        component: SpecialMessages.List,
        props: { urlName: "api:admin:reports:specialmessage:list" },
      },
      {
        name: "Special Messages History",
        component: SpecialMessages.Detail,
        path: "/admin/reports/special-messages/:id/history/:historyId",
      },
      {
        name: "Special Messages",
        component: SpecialMessages.Detail,
        exact: false,
        props: { urlName: "api:admin:reports:specialmessage:detail" },
      }
    ]
  }
]

export default { appName: "Reports", routes };