const descriptorOffline = {
  "app_label": "expert_system",
  "model_name": "targetpage",
  "pagination": true,
  "columns": [
    {
      "name": "name",
      "label": "Name",
      "data_type": "str",
      sort: true,
      default_sorting: "asscend",
      default_sorting_priority: 2,
    },
    {
      "name": "document_type",
      "label": "Document Type",
      "data_type": "str",
      sort: true,
      default_sorting: "asscend",
      default_sorting_priority: 1,
    },
    {
      "name": "is_active",
      "label": "Is Active",
      "data_type": "bool",
    },
    {
      "name": "modified",
      "label": "Modified",
      "data_type": "datetime",
      sort: true,
    }
  ],
  "filter_by": [
    {
      name: 'document_type',
      label: 'Document Type',
      singleChoices: [
        ['', 'All'],
        ['plan', 'Plan'],
        ['erf', 'ERF']
      ]
    },
    {
      "label": "Is Active",
      "name": "is_active",
      "type": "switch"
    },
  ],
  "export_import": null
}

export default descriptorOffline