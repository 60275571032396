import React from "react";
import PropTypes from "prop-types";
import { Card } from "antd";
import { get } from "lodash";
import { Sites } from "App/Config";
import { Lib } from "App/Utils";
import SubAllList from "App/Component/SubAllList";
import descriptor from "./descriptorOffline";

class MerchantList extends React.PureComponent {
  onAddMerchant = () => {
    const { branch } = this.props
    const historyState = {
      dataIdentifier: {
        branch: { pk: branch.pk, name: branch.name }
      }
    }
    Lib.historyAction(this.props.history, '/merchant/merchant/add', historyState).push()
  }

  onDetailMerchant = (id) => {
    Lib.historyAction(this.props.history, `/merchant/merchant/${id}`).push()
  }

  componentWillUnmount() {
    Lib.windowStop();
  }

  render() {
    const { match, permissionItems } = this.props

    return (
      <Card>
        <SubAllList
          permissions={permissionItems}
          pageTitle="Merchant"
          avatar="name"
          descriptor={descriptor}
          masterKey="module-branch-refMerchants"
          onAddClick={this.onAddMerchant}
          args={[get(match, 'params.id')]}
          onDetailClick={this.onDetailMerchant}
          userReducer={this.props.userReducer}
          groupByOptions={[
            {
              label: "Merchant Group",
              key: "merchant_group.name",
              dataIndex: "merchant_group.name"
            },
            // Ada bug saat di group by is_active / boolean type
            // {
            //   label: "Active Status",
            //   key: "is_active",
            //   dataIndex: "is_active",
            // }
          ]}
        />
      </Card>
    );
  }
}

MerchantList.propTypes = {
  permissionItems: PropTypes.array,
}

MerchantList.defaultProps = {
  permissionItems: [],
}

export default MerchantList;
