import { BaseDetail } from 'App/Component/Bases'
import fields from 'App/Screens/Job/JobQueues/fields'

class JobQueueAdd extends BaseDetail {
  title = 'Job Queue';
  urlData = 'module-jobQueue';
  bulkName = 'name';
  fields = fields;
}

export default JobQueueAdd