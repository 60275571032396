import React from 'react'
import PropTypes from 'prop-types'
import { Col, Icon, Layout, Row, Spin, Tabs } from 'antd'
import { get, noop } from 'lodash'
import { Lib, RequestV2 as Request } from 'App/Utils'
import PageError from 'App/Component/PageError'
import { BasicPageSetting, ContentReference } from './internal'

const { TabPane } = Tabs

export class PageHeader extends React.PureComponent {
  static defaultProps = {
    readOnly: false,
    title: null,
    extra: null,
    data: null,
    descriptions: [],
    onBack: noop,
  };

  getDescElems = () => {
    return (
      <Row
        className="ant-desc-custom"
        type="flex"
        gutter={[12, 12]}
        style={{ marginTop: "12px" }}
      >
        {this.props.descriptions.map((item) => (
          <Col key={item.key} xs={24} sm={12} lg={8}>
            <Row className="header-content-item">
              <span className="ant-descriptions-item-label ant-descriptions-item-colon">
                {item.label}
              </span>
              <span className="ant-descriptions-item-content">
                {Lib.getValue(this.props.data, item)}
              </span>
            </Row>
          </Col>
        ))}
      </Row>
    );
  };

  render() {
    const p = this.props;

    return (
      <div className="ant-page-header has-footer">
        <Row
          type="flex"
          justify="space-between"
          gutter={[12, 12]}
          style={{ borderBottom: "1px solid #e8e8e8" }}
        >
          <Col>
            <Row type="flex" align="middle">
              <Icon
                className="ant-page-header-back-button"
                type="arrow-left"
                style={{ marginRight: "16px" }}
                onClick={p.onBack}
              />
              <Col className="header-title text-ellipsis">{p.title}</Col>
              {p.readOnly && (
                <Col
                  style={{
                    fontSize: "12px",
                    fontWeight: 500,
                    backgroundColor: "#1890ff",
                    color: "#fff",
                    padding: "0 12px 2px 12px",
                    borderRadius: "14px",
                    marginLeft: "12px",
                  }}
                >
                  Read Only
                </Col>
              )}
            </Row>
          </Col>
          <Col>{typeof p.extra === "function" ? p.extra() : p.extra}</Col>
        </Row>
        <div>{this.getDescElems()}</div>
        <div className="ant-page-header-footer">
          <div style={{ height: "1px", backgroundColor: "#e8e8e8" }} />
        </div>
      </div>
    );
  }
}

class BaseDetailSubmodule extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      data: null, // hasil retrieve
      errorResponse: null,
      isLoading: true,
      isReloading: false,
      readOnly: !props.currentPermissionSet.some(code => (code || '').includes('.change_')),
    }
    this.headerDescriptions = [] // see Lib.getValue for detail.
    this.urlKey = null
    this.title = 'Title'
    this.urlList = '/' // Saat header back, jika tidak diketahui from-nya, maka menggunakan variable ini
    this.panes = []
    this.fields = []
    // When add click on reference, field on target page filled by this setting
    // example refField = { name: 'extra_charge', type: 'object', valKey: 'pk', valLabel: 'name' }
    // for detail, open function onAddClick in ./internal/ContentReference.js
    this.refField = null
  }

  getId = () => this.props.match.params.id

  retrieve = (keyLoading = 'isLoading', callback = () => null) => {
    this.setState({ [keyLoading]: true }, () => {
      Request(
        'get',
        this.urlKey,
        {},
        {},
        [this.getId()],
        this.readSuccess,
        this.readFailed,
        { keyLoading, callback }
      )
    })
  }

  readSuccess = (response, extra) => {
    this.setState({ [extra.keyLoading]: false, data: response.data }, extra.callback)
  }

  readFailed = (error, extra) => {
    this.setState({ [extra.keyLoading]: false, errorResponse: get(error, 'response') }, extra.callback)
  }

  getPaneTitle = (pane) => (
    <span>
      {pane.icon ? <Icon type={pane.icon} /> : ''}
      {pane.title}
    </span>
  )

  setData = (data) => this.setState({ data })

  onGoHome = () => {
    Lib.historyAction(this.props.history, this.urlList).goBack()
  }

  componentDidMount() {
    this.retrieve()
  }

  render() {
    const { data, errorResponse, isLoading, readOnly } = this.state
    if (isLoading) {
      return (
        <div style={{ paddingTop: '100px', textAlign: 'center' }}>
          <Spin
            size='large'
            tip='Loading...'
            indicator={<Icon spin type='loading' style={{ fontSize: '4rem' }} />}
          />
        </div>
      )
    }

    return data ? (
      <Layout className='um-layout'>
        <PageHeader
          title={this.title}
          onBack={this.onGoHome}
          extra={this.extra}
          descriptions={this.headerDescriptions}
          readOnly={readOnly}
          data={data}
        />
        <div style={{ backgroundColor: '#fff', marginBottom: '24px' }}>
          <Tabs className='custom-margin-tab' tabBarGutter={0}>
            {this.panes.map(pane => (
              <TabPane key={pane.key} tab={this.getPaneTitle(pane)} {...pane.paneProps}>
                <ContentReference
                  currentSetting={pane}
                  propsList={pane.propsList}
                  parentData={data}
                  history={this.props.history}
                  getId={this.getId}
                  refField={this.refField}
                  {...pane.contentProps}
                />
              </TabPane>
            ))}
            {!readOnly && (
              <TabPane key='setting' tab={<span><Icon type='setting' />Setting</span>}>
                <Row style={{ padding: '0 24px 24px 24px' }}>
                  <BasicPageSetting
                    data={data}
                    fields={this.fields}
                    setData={this.setData}
                    urlKey={this.urlKey}
                    children={this.children}
                    userReducer={this.props.userReducer}
                    transactionHistory={this.history}
                    reRetrieve={this.retrieve}
                    {...this.propsDetail}
                  />
                </Row>
              </TabPane>
            )}
          </Tabs>
        </div>
      </Layout>
    ) : (
      <PageError
        errorResponse={errorResponse}
        onGoBack={this.onGoBack} // don't declare in this Base
        onReload={this.retrieve}
      />
    )
  }
}

BaseDetailSubmodule.propTypes = {
  currentPermissionSet: PropTypes.array,
}

BaseDetailSubmodule.defaultProps = {
  currentPermissionSet: [],
}

export default BaseDetailSubmodule