export default [
  {
    title: 'Name',
    type: 'text',
    data: '',
    required: true,
    initialValue: 'name',
    dataIndex: 'name',
    note_text: 'Display name. e.g. \'Christchurch\'.',
    additional: '',
    order: 0,
  }, {
    title: 'Protocol',
    type: 'select',
    disableAllowClear: true,
    data: [
      {
        alias: 'http',
        value: 'HTTP'
      }, {
        alias: 'https',
        value: 'HTTPS'
      }
    ],
    initialValue: 'http',
    note_text: '',
    dataIndex: 'protocol',
    additional: '',
    order: 1,
  }, {
    title: 'Hostname',
    type: 'text',
    data: '',
    required: true,
    dataIndex: 'hostname',
    initialValue: '',
    note_text: 'Fully Qualified Host Name, e.g. \'jakarta.estimator.com\'.',
    additional: '',
    order: 2,
  }, {
    title: 'Port',
    type: 'number',
    data: '',
    dataIndex: 'port',
    initialValue: '',
    note_text: 'Leave blank to use default port numbers. (Recommended)',
    additional: '',
    order: 3,
  }, {
    title: 'Role',
    type: 'select',
    disableAllowClear: true,
    data: [
      {
        alias: 'central',
        value: 'Central'
      }, {
        alias: 'branch',
        value: 'Branch'
      }
    ],
    initialValue: 'branch',
    dataIndex: 'role',
    note_text: 'Leave blank to use default port numbers. (Recommended)',
    additional: '',
    order: 4,
  }, {
    title: 'Branch',
    type: 'SelectQuery',
    data: 'branches',
    selectedKey: 'pk',
    selectedValue: 'name',
    dataIndex: 'branch',
    initialValue: '',
    required: true,
    note_text: '',
    additional: 'edit|add',
    order: 5,
  }, {
    title: 'Status',
    type: 'select',
    disableAllowClear: true,
    dataIndex: 'status',
    data: [
      {
        alias: 'enabled',
        value: 'Enabled'
      }, {
        alias: 'disabled',
        value: 'Disabled'
      }
    ],
    initialValue: 'enabled',
    note_text: '',
    additional: '',
    order: 6,
  }, {
    title: 'Default for branch',
    type: 'checkbox',
    data: '',
    dataIndex: 'default_for_branch',
    initialValue: true,
    note_text: '',
    additional: '',
    order: 7,
  }, {
    title: 'Private key',
    type: 'textarea',
    data: '',
    initialValue: '',
    dataIndex: 'private_key',
    note_text: 'This is automatically generated. Do not change anything here. Please keep the content as a secret like a password for security reasons.',
    additional: '',
    order: 8,
  }, {
    title: 'Public Key',
    dataIndex: 'public_key',
    type: 'textarea',
    data: '',
    initialValue: '',
    note_text: 'This is automatically generated. Do not change anything here. Please keep the content as a secret like a password for security reasons.',
    additional: '',
    order: 9,
  },
]
