export default [
  {
    title: 'Name',
    type: 'text',
    data: '',
    required: true,
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'name',
    order: 0,
  }, {
    title: 'Grade',
    type: 'select_multiple',
    data: 'jobGrades',
    required: true,
    initialValue: '',
    note_text: '',
    additional: 'add',
    dataIndex: 'grade',
    selectedKey: 'pk',
    selectedValue: 'level', // arep di ganti label sama babang ganteng
    order: 1,
  },
]