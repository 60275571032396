import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from "react-router-dom";
import { Row, Col, Layout, Menu, Icon, PageHeader, Statistic, Divider, Descriptions, Badge, Spin, message, Button } from "antd";
import styled from "styled-components";
import moment from "moment";
import { get } from 'lodash'
import {Bar} from "ant-design-pro/lib/Charts";
import { RequestV2 as Request, Lib, Modules } from "App/Utils";
import { PageJobs, PageSetting } from './Pages'
import PageError from 'App/Component/PageError'

const { Content} = Layout;

class JobQueueDetail extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      dataJob: null,
      isLoading: true,
      errorResponse: null,
      chartDataAntd: [],
      // Permission
      readOnly: !props.currentPermissionSet.some(item => (item || '').includes('.change_')),
      allowReadJob: props.permissionSetJob.some(item => (item || '').includes('.view_')),
      // ----------
    }
    this.defaultMenu = this.state.allowReadJob ? 'jobs' : (this.state.readOnly ? '' : 'settings')
  }

  retrieve = (keyLoading = 'isLoading', callback = () => null) => {
    this.setState({ [keyLoading]: true }, () => {
      Request(
        'get',
        'module-jobQueue-detail',
        {},
        {},
        [this.props.match.params.id],
        this.retrieveSuccess,
        this.retrieveFailed,
        { keyLoading, callback },
      )
    })
  }

  retrieveSuccess = (response, extra) => {
    const chartDataAntd = []
    const { summaries } = response.data.job_summary
    for (let i = 0; i < summaries.length; i += 1) {
      chartDataAntd.push({ x: summaries[i].label, y: summaries[i].total })
    }
    this.setState({
      dataJob: response.data,
      [extra.keyLoading]: false,
      chartDataAntd,
    })
  }

  retrieveFailed = (error, extra) => {
    this.setState({ [extra.keyLoading]: false, errorResponse: get(error, 'response') }, () => {
      extra.callback()
    })
  }

  changeJobQueue = (jobQueue) => {
    this.setState({
      dataJob: jobQueue
    })
  }

  onGoHome = () => {
    Lib.historyAction(this.props.history, '/branch-queue/queue').goBack()
  }

  componentDidMount() {
    const { match, location, history } = this.props
    if (location.pathname.split(match.url).filter(v => v).length === 0) {
      history.replace(`${match.path.replace(':id', match.params.id)}/${this.defaultMenu}`)
    }
    this.retrieve()
  }

  render() {
    const { history, location, match, permissionSetJob } = this.props;
    const { readOnly, allowReadJob, dataJob, isLoading, errorResponse, chartDataAntd } = this.state
    const { Countdown } = Statistic;
    const deadline = Date.now() + 1000 * 60 * 60 * 24 * 2 + 1000 * 30; // Moment is also OK

    if (isLoading) {
      return (
        <div style={{ paddingTop: 100, textAlign: "center" }}>
          <Spin
            size="large"
            tip="making coffee..."
            indicator={<Icon type="loading" style={{ fontSize: "4rem" }} spin />}
          />
        </div>
      )
    }

    return dataJob ? (
      <Layout className="um-layout">
        <Layout>
          <PageHeader
            style={{
              border: '1px solid rgb(235, 237, 240)',
              backgroundColor: "#ffffff"
            }}
            // onBack={this.goTo}
            // avatar={{ src: IbesLogo}}
            title="Job Queue"
            onBack={this.onGoHome}
            subTitle={readOnly &&
              <Col style={{ fontSize: '12px', fontWeight: 500, backgroundColor: '#1890ff', color: '#fff', padding: '0 12px 2px 12px', borderRadius: '14px' }}>
                Read Only
              </Col>
            }
          />
          <PageHeader
            ghost={false}
            footer={<Divider className="m-0" />}
          >
            <Row type='flex' justify='space-between' style={{ width: '100%' }}>
              <Col lg={6} xl={6} xs={16}>
                <Descriptions className="ant-desc-custom" column={1}>
                  <Descriptions.Item label="Name">
                    {dataJob.name}
                  </Descriptions.Item>
                  <Descriptions.Item label="Branch">
                    {dataJob.branch.name}
                  </Descriptions.Item>
                  <Descriptions.Item label="Role">
                    {dataJob.role.toUpperCase()}
                  </Descriptions.Item>
                  <Descriptions.Item label="Default for Branch">
                    {Modules.renderStatusAIU(dataJob.default_for_branch)}
                  </Descriptions.Item>
                  <Descriptions.Item label="Created at">
                    {moment(dataJob.created).format('DD MMMM YYYY')}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
              <Col lg={18} xl={18}>
                <Row>
                  {/* <Col md={1}>
                    <Divider type="vertical" style={{ height: "6.1rem" }} />
                  </Col>
                  <Col md={5}>
                    <span className="text-muted font-md mb-3">Lead time</span>
                    <div>
                      <Icon
                        className="font-2xl mr-1"
                        type="idcard"
                        theme="twoTone"
                      />
                      <span className="font-2xl">
                        <Countdown value={deadline} format="DD:HH:mm:ss" />
                      </span>
                    </div>
                  </Col> */}
                  <Col md={1}>
                    <Divider type="vertical" style={{ height: "6.1rem" }} />
                  </Col>
                  <Col md={17} xs={24}>
                    Total job :
                    <Badge
                      count={dataJob.job_summary.total ? dataJob.job_summary.total : 'empty'}
                      overflowCount={999999999}
                      style={{ backgroundColor: dataJob.branch.color || '#ff8169', zIndex: "1", marginLeft: "10px", color: '#fff' }}
                    />
                    <Bar
                      height={120}
                      autoLabel={false}
                      data={chartDataAntd}
                      color={dataJob.branch.color || "#ff8169"}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </PageHeader>
          <Menu
            mode="horizontal"
            className="px-2"
            // onClick={this.handlemenuClick}
            // theme={"dark"}
            selectedKeys={[
              Lib.getMenuUrl(location.pathname, undefined, this.defaultMenu)
            ]}
          >
            {allowReadJob && (
              <Menu.Item
                key="jobs"
                onClick={e => {
                  history.push(`${match.url}/jobs`);
                }}
              >
                <Icon type="book" />
                Jobs
              </Menu.Item>
            )}
            {!readOnly && (
              <Menu.Item
                key="settings"
                onClick={e => {
                  history.push(`${match.url}/settings`);
                }}
              >
                <Icon type="setting" />
                Settings
              </Menu.Item>
            )}
          </Menu>
          <Layout>
            <Content style={{ marginTop: '5px' }}>
              <Switch>
                {allowReadJob && (
                  <Route path={`${this.props.match.path}/jobs`}>
                    <PageJobs {...this.props} permissionItems={permissionSetJob} />
                  </Route>
                )}
                {!readOnly && (
                  <Route path={`${this.props.match.path}/settings`}>
                    <PageSetting jobQueue={dataJob} changeJobQueue={this.changeJobQueue} {...this.props} />
                  </Route>
                )}
              </Switch>
            </Content>
          </Layout>
        </Layout>
      </Layout>
    ) : (
      <PageError
        errorResponse={errorResponse}
        onGoBack={this.onGoHome}
        onReload={this.retrieve}
      />
    )
  }
}

JobQueueDetail.propTypes = {
  currentPermissionSet: PropTypes.array,
  permissionSetJob: PropTypes.array,
}

JobQueueDetail.defaultProps = {
  currentPermissionSet: [],
  permissionSetJob: [],
}

export default JobQueueDetail

