import React from "react";
import { Card, Switch, Icon, Table, Transfer, Form, Button, Tag } from "antd";

import SelectQuery from "App/Component/SelectQuery";
import { RequestV2 as Request, Lib } from "App/Utils";
import PropTypes from "prop-types";

class Branch extends React.PureComponent {
	state = {
		permGroups: [],
		targetKeys: [],
		selectedKeys: [],
		operatingBranches: this.props.user.user_operating_branch.map(ob => ({
			...ob,
			is_home: false
		}))
	};
	columns = [
		{
			title: "Branch",
			dataIndex: "operating_branch.name",
			render: (item, record) =>
				this.props.form.getFieldDecorator(`visitingBranch[${record.pk}]`, {
					initialValue: Lib.setSelectValue(record.operating_branch, "pk")
				})(
					<SelectQuery
						userReducer={this.props.userReducer}
						urlKey="branches-autocomplete"
						className="min-w-5"
						valKey="pk"
					/>
				)
		},
		{
			title: "Permissions",
			children: [
				{
					title: (
						<span>
							Allowed Bill&nbsp;
							<Icon
								type="question-circle"
								title="Allow user to calculate the bills."
							/>
						</span>
					),
					key: "allowedBill",
					render: (i, r) => (
						<Switch size="small" defaultChecked={r.allowed_create} />
					)
				},
				{
					title: (
						<span>
							Allowed Analysis Report&nbsp;
							<Icon
								type="question-circle"
								title="Allow user to analize Reports."
							/>
						</span>
					),
					key: "allowedAnalysisReport",
					render: (i, r) => (
						<Switch size="small" defaultChecked={r.allowed_delete} />
					)
				},
				{
					title: (
						<span>
							Allowed Job Assigment&nbsp;
							<Icon
								type="question-circle"
								title="Allow user to assign Job to other Users."
							/>
						</span>
					),
					key: "allowedJobAssigment",
					render: (i, r) => (
						<Switch size="small" defaultChecked={r.allowed_read} />
					)
				},
				{
					title: (
						<span>
							Bill Access&nbsp;
							<Icon
								type="question-circle"
								title="Merchant tracker bill access."
							/>
						</span>
					),
					key: "billAccess",
					render: (i, r) => (
						<Switch size="small" defaultChecked={r.allowed_update} />
					)
				}
			]
		},
		{
			title: "Tags",
			key: "tags",
			className: "text-center",
			render: (i, r) => (r.is_home ? <Tag color="red">Home Branch</Tag> : "-")
		},
		{
			title: "Actions",
			key: "actions",
			render: (i, r) => (
				<div className="text-center">
					<Button
						icon="home"
						type="primary"
						title="Set as Home Branch"
						disabled={r.is_home}
						onClick={() => this.setHomeBranch(r)}
					/>
				</div>
			)
		}
	];

	setHomeBranch(record) {
		const { operatingBranches } = this.state;
		for (const ob of operatingBranches) ob.is_home = ob.pk === record.pk;

		this.setState({ operatingBranches: [...operatingBranches] });
	}

	async readBranches() {
		const res = await Request("GET", "groups-autocomplete");
		if (res)
			this.setState({
				permGroups: res.data.results
			});
	}

	componentDidMount() {
		this.readBranches();
	}

	render() {
		const {
			permGroups,
			targetKeys,
			selectedKeys,
			operatingBranches
		} = this.state;

		return (
			<div className="animated fadeIn">
				<Card type="inner" size="small" className="mb-3" title="Groups">
					<div className="py-2 mb-3 um-branch-groups-caption">
						The groups this user belongs to. A user will get all permissions
						granted to each of their groups.
					</div>
					<Transfer
						className="mb-2"
						titles={["Available groups", "Chosen groups"]}
						targetKeys={targetKeys}
						selectedKeys={selectedKeys}
						dataSource={permGroups}
						onChange={tK => this.setState({ targetKeys: tK })}
						onSelectChange={(sK, tK) =>
							this.setState({ selectedKeys: [...sK, ...tK] })
						}
						render={i => i.name}
						rowKey={i => i.pk}
					/>
				</Card>
				<Card type="inner" size="small" title="Branch" bordered={false}>
					<Table
						bordered
						size="small"
						rowKey="pk"
						className="um-branch-visiting-table"
						dataSource={operatingBranches}
						columns={this.columns}
						pagination={false}
						footer={() => (
							<a href="#">
								<Icon type="plus" /> Add Branch
							</a>
						)}
					/>
				</Card>
			</div>
		);
	}
}

export default Form.create()(Branch);

Branch.propTypes = {
	user: PropTypes.object
}
Branch.defaultProps = {
	user: {user_operating_branch: []}

}
