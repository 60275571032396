import React from "react";
import { Prompt } from "react-router";
import { get, isEmpty } from "lodash";
import { Layout, Row, Col, Icon, Spin, PageHeader, Alert, Button, Descriptions, Divider, message, Modal, Drawer, Input, Tabs } from "antd";
import { BaseUrl } from 'App/Component/Bases';
import { Lib, RequestV2 as Request } from "App/Utils";
import PageNotFound from "App/Component/PageNotFound"
import PageError from 'App/Component/PageError'
import { Stats, Statistics, Profile, UserPermission, Modules as PageModules, Reports as PageReports, PageMerchants, PageJobQueues } from "./Pages"
import History from "App/Screens/SubModules/Component/History";

const { TabPane } = Tabs;
const ProfilPhoto = require("App/Assets/img/user-avatar.svg");
const defaultMenu = "profile";
const ERROR_MESSAGE = 'Failed to fetch'
const fields = [
  { key: '_profile', key2: '_basic', tabKey: 'profile', tabKey2: 'general', keyBody: 'username', label: 'Username' },
  { key: '_profile', key2: '_basic', tabKey: 'profile', tabKey2: 'general', keyBody: 'first_name', label: 'First Name' },
  { key: '_profile', key2: '_basic', tabKey: 'profile', tabKey2: 'general', keyBody: 'last_name', label: 'Last Name' },
  { key: '_profile', key2: '_basic', tabKey: 'profile', tabKey2: 'general', keyBody: 'email', label: 'E-Mail Address' },
  { key: '_profile', key2: '_basic', tabKey: 'profile', tabKey2: 'general', keyBody: 'home_branch', label: 'Home Branch' },
  { key: '_profile', key2: '_basic', tabKey: 'profile', tabKey2: 'general', keyBody: 'staff_position', label: 'Staff Position' },
  { key: '_profile', key2: '_basic', tabKey: 'profile', tabKey2: 'general', keyBody: 'max_jobs', label: 'Max Jobs' },
  { key: '_profile', key2: '_basic', tabKey: 'profile', tabKey2: 'general', keyBody: 'skill_level', label: 'Skill Level' },
  { key: '_profile', key2: '_commonPermission', tabKey: 'profile', tabKey2: 'permission', keyBody: 'is_active', label: 'Active Status' },
  { key: '_profile', key2: '_commonPermission', tabKey: 'profile', tabKey2: 'permission', keyBody: 'is_staff', label: 'Staff Status' },
  { key: '_profile', key2: '_commonPermission', tabKey: 'profile', tabKey2: 'permission', keyBody: 'is_superuser', label: 'Superuser Status' },
  { key: '_profile', key2: '_commonPermission', tabKey: 'profile', tabKey2: 'permission', keyBody: 'groups', label: 'Chosen Groups' },
  { key: '_merchant', tabKey: 'merchants', keyBody: 'merchant_access', label: 'Merchant Access' },
]

export default class UserManagement extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      isReloading: false,
      errorResponse: null,
      activeTabKey: defaultMenu,
      user: null,
      userHelper: null,
      errorMessage: ERROR_MESSAGE,
      isSaving: false,
      isFieldsTouched: false,
      visible: false,
      // Permission
      readOnly: !get(props, 'currentPermissionSet', []).some(code => (code || '').includes('.change_')),
      // ----------
    }
  }

  requestHelper = (callback = () => null, callbackPageError = () => null) => {
    Request(
      'get',
      'module-helper-user',
      {},
      {},
      [],
      this.requestHelperSuccess,
      this.requestHelperFailed,
      { callback, callbackPageError },
    )
  }

  requestHelperSuccess = (response, extra) => {
    this.setState({ userHelper: response.data }, () => {
      extra.callback()
    })
  }

  requestHelperFailed = (error, extra) => {
    this.setState({ isLoading: false, isReloading: false, errorResponse: get(error, 'response') }, () => {
      extra.callbackPageError()
    })
  }

  retrieve = (keyLoading = 'isLoading', callback = () => null) => {
    const { id, detail } = get(this.props, 'match.params', {})
    let url = "module-groupUser-user-detail"
    const params = [id]
    if(parseInt(detail)){
      url = "module-groupUser-user-history-detail"
      params.push(detail)
    }
    this.setState({ [keyLoading]: true }, () => {
      this.requestHelper(() => {
        Request("GET", url, {}, {}, params,
          this.retrieveSuccess,
          this.retrieveFailed,
          { keyLoading, callback }
        );
      }, callback)
    })
  }

  retrieveSuccess = (response, extra) => {
    this.setState({ user: response.data, [extra.keyLoading]: false })
  }

  retrieveFailed = (error, extra) => {
    this.setState({ [extra.keyLoading]: false, errorResponse: get(error, 'response') }, () => {
      extra.callback()
    })
  }

  setUser = (updatedUser, callback = () => null) => {
    this.setState({ user: updatedUser }, callback)
  }

  setThisState = (obj, callback = () => null) => {
    if (typeof obj === 'object') {
      for (const key in obj) {
        if (obj[key] !== this.state[key]) {
          return this.setState(obj, callback)
        }
      }
    }
    callback()
  }

  onTabChange = (activeTabKey, callback = () => null) => {
    this.setState({ activeTabKey }, callback)
  }

  onGoHome = () => {
    Lib.historyAction(this.props.history, BaseUrl.userIbes).goBack()
  }

  onChangePassword = () => {
    let newPassword = ''
    let retypeNewPassword = ''
    Modal.confirm({
      title: 'Change Password',
      okText: 'Change Password',
      maskClosable: true,
      content: (
        <Col>
          <Row style={{ marginTop: '12px' }}>
            <Input.Password
              ref={ref => {ref && setTimeout(() => ref.input.focus(), 250)}}
              placeholder='New Password'
              onChange={(event) => {newPassword = event.target.value}}
            />
          </Row>
          <Row style={{ marginTop: '12px' }}>
            <Input.Password placeholder='Retype New Password' onChange={(event) => {retypeNewPassword = event.target.value}} />
          </Row>
        </Col>
      ),
      onOk: () => new Promise((resolve, reject) => {
        setTimeout(() => {
          if (!newPassword) {
            message.info('Please insert new password')
            reject('Fill new password')
          } else if (newPassword.length < 8) {
            message.info('Please insert new password at least 8 characters')
            reject('Password must at least 8 characters')
          } else if (newPassword === retypeNewPassword) {
            this.changePassword({ password: newPassword }, isSuccess => {
              if (isSuccess) {
                resolve()
              } else {
                reject()
              }
            })
          } else {
            message.info('Retype password not match, please check your input password')
            reject('Password not match')
          }
        }, 250)
      })
    })
  }

  changePassword = (values, callback = () => null) => {
    Request(
      'post',
      'module-groupUser-user-password',
      {},
      values,
      [this.state.user.pk],
      this.changePasswordSuccess,
      this.changePasswordFailed,
      { callback },
    )
  }

  changePasswordSuccess = (response, extra) => {
    message.success('Password has changed.')
    extra.callback(true)
  }

  changePasswordFailed = (error, extra) => {
    message.error('Failed to change the password, error_status: ' + get(error.response, 'status', 'Unknown'), 5)
    extra.callback()
  }

  onSave = () => {
    // Testing error
    // this.onSaveFailed({
    //   response: {
    //     data: {
    //       username: ['salah username', 'salah kedua', 'salah ketiga'],
    //       first_name: ['salah first_name'],
    //       last_name: ['salah last_name'],
    //       email: ['email tidak boleh sama dong'],
    //       home_branch: ['home_branch with ID: 123 is not exist'],
    //       staff_position: ['staff_posision erorr'],
    //       max_jobs: ['tidak boleh lebih dari 32786'],
    //       skill_level: ['skill_level error'],

    //       is_active: ['Is active error? waaaaaaaa'],
    //       is_staff: ['tidak boleh staff tanpa active'],
    //       is_superuser: ['tidak boleh superuser tanpa active dan staff'],
    //       groups: ['group with ID(s): 99999 is not exist.'],

    //       merchant_access: ['merchant_access with ID(s): 99999 is not exist.'],
    //     }
    //   }
    // })
    // return
    const merchant_access = this._merchant.validateFields()
    const args = [get(this.state.user, 'pk')]

    const { detail } = get(this.props, 'match.params', {})
    let url = "module-groupUser-user-update"
    if(parseInt(detail)){
      url = "module-groupUser-user-history-detail"
      args.push(detail)
      this.setTouched(false)
    }
    this._profile.validateFields().then(obj => {
      this.setState({ isSaving: true }, () => {
        Request(
          'put',
          url,
          {},
          { ...obj, merchant_access },
          args,
          this.onSaveSuccess,
          this.onSaveFailed,
          detail
        )
      })
    })
  }

  onSaveSuccess = (response, extra) => {
    message.success('Save success')
    if(extra){
      const [p] = [this.props]
      let urlTarget = `${BaseUrl.userIbes}/${p.match.params.id}`
      return p.history.push(urlTarget)
    }
    this.setState({ isSaving: false, user: response.data, isFieldsTouched: false })
  }

  // Cara penanganan error di Group dan User (ini) tidak sama.
  onSaveFailed = (error, extra) => {
    const errorData = get(error, 'response.data', [])
    if (typeof errorData === 'object') {
      message.error('Failed to save User')
      let index = 0
      // Warning: Akan blank jika tidak menggunakan if (typeof object), karena bisa jadi response string
      for (const key in errorData) {
        const refKey = fields.find(field => field.keyBody === key)
        if (refKey) {
          message.error('Error in ' + refKey.label + ' : ' + errorData[key][0], 5)
          if (refKey.key2) {
            this[refKey.key][refKey.key2].setError({ [key]: errorData[key] })
            // if (typeof get(this[refKey.key], [refKey.key2, 'setError']) === 'function') {
            //   this[refKey.key][refKey.key2].setError({ [key]: errorData[key] })
            // }
          } else if (typeof get(this[refKey.key], 'setError') === 'function') {
            this[refKey.key].setError({ [key]: errorData[key] })
          }
          // Berpindah posisi tab hanya sekali
          if (index === 0) {
            this.onTabChange(refKey.tabKey)
            if (refKey.key2) {
              this[refKey.key].onTabChange(refKey.tabKey2)
            }
          }
          // ---------------------------------
        }
        index += 1
      }
    } else {
      const codeError = get(error.response, 'status', '[Code]') + ' ' + get(error.response, 'statusText', 'Unknown')
      message.error('Save failed, code: ' + codeError, 5)
    }
    this.setState({ isSaving: false })
  }

  // Children tidak boleh diberi fungsi ini
  setTouched = (isFieldsTouched) => {
    this.setState({ isFieldsTouched })
  }

  // Set touched untuk children input
  onFieldTouched = () => (
    !this.state.readOnly && this.setTouched(true)
  )

  setView=(record)=>{
    const { history, match } = this.props
    let urlTarget = `${BaseUrl.userIbes}/${match.params.id}/history/${record.pk}`
    history.push(urlTarget)
  }

  componentDidMount() {
    this.retrieve();
  }

  onBack=(id, isHistory=false)=>{
    const [p] = [this.props]
    if(isHistory)
      p.history.push(`${BaseUrl.userIbes}/${id}`)
    else
      this.onGoHome()
  }

  render() {
    const { readOnly, activeTabKey, user, userHelper, isLoading, errorResponse, isSaving, isFieldsTouched } = this.state;

    if (isLoading) {
      return (
        <div style={{ paddingTop: 100, textAlign: "center" }}>
          <Spin
            size="large"
            tip="making coffee..."
            indicator={
              <Icon type="loading" style={{ fontSize: "4rem" }} spin />
            }
          />
        </div>
      )
    }

    return user ? (
      <Layout className="um-layout">
        <Prompt
          when={isFieldsTouched}
          message='You will lose any unsaved changes. Continue?'
        />
        <Layout>
          <Drawer 
            destroyOnClose width={window.innerWidth/1.5} 
            visible={this.state.visible} 
            closable={false}
            onClose={()=>this.setState({ visible: false })}
          >
            <History 
              id={user.pk} 
              url="module-groupUser-user-history" 
              urlDetail="module-groupUser-user-history-detail" 
              title={user.username} 
              setView={(record)=>this.setView(record)}
            />
          </Drawer>
          <PageHeader
            title={`${user.username}`}
            // title={user.first_name + ' ' + get(user, 'last_name', '')}
            subTitle={readOnly && (
              <Col style={{ fontSize: '10px', fontWeight: 500, backgroundColor: '#1890ff', color: '#fff', padding: '0 12px 2px 12px', borderRadius: '14px', lineHeight: '18px' }}>
                Read Only
              </Col>
            )}
            onBack={()=>this.onBack(user.pk, get(this.props, 'match.params.detail'))}
            avatar={{ src: ProfilPhoto }}
            ghost={false}
            extra={!readOnly &&
              <>
                {!get(this.props, 'match.params.detail') && <Button icon="history" onClick={()=>this.setState({ visible: true })}>History</Button>}
                <Button icon='key' onClick={this.onChangePassword}>
                  Change Password
                </Button>
                <Button type='primary' icon='save' loading={isSaving} onClick={this.onSave}>
                  Save
                </Button>
              </>
            }
            footer={<Divider className="m-0" />}
          >
            { get(this.props, 'match.params.detail') && 
              <Alert
                message={`Revert ${user.username}`}
                description="Press the save button to revert to this version of the object."
                type="warning"
                showIcon
              />
            }
            <Row className="mb-3">
              <Col lg={12} xl={12}>
                <Descriptions className="ant-desc-custom" column={1}>
                  <Descriptions.Item label="First Name">
                    {user.first_name}
                  </Descriptions.Item>
                  <Descriptions.Item label="Last Name">
                    {user.last_name || '-'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Email">
                    {user.email || '-'}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
              <Col lg={12} xl={12}>
                <Row>
                  <Col md={1} sm={0} xs={0}>
                    <Divider type="vertical" style={{ height: "5.5rem" }} />
                  </Col>
                  <Col md={11} sm={12}>
                    <span className="text-muted font-md mb-3">Position</span>
                    <div>
                      <Icon
                        className="font-2xl mr-1"
                        type="idcard"
                        theme="twoTone"
                      />
                      <span className="font-2xl">
                        {get(user, "staff_position.name", "-")}
                      </span>
                    </div>
                  </Col>
                  <Col md={1} sm={0} xs={0}>
                    <Divider type="vertical" style={{ height: "5.5rem" }} />
                  </Col>
                  <Col md={11} sm={12}>
                    <span className="text-muted font-md mb-3">Branch</span>
                    <div>
                      <Icon
                        className="font-2xl mr-1"
                        type="home"
                        theme="twoTone"
                        twoToneColor="#eb2f96"
                      />
                      <span className="font-2xl">
                        {get(user, "home_branch.name", "-")}
                      </span>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </PageHeader>
          <Row style={{ backgroundColor: '#fff' }}>
            <Tabs className='custom-margin-tab' tabBarGutter={0} activeKey={activeTabKey} onChange={this.onTabChange}>
              {/* <TabPane key='stats' tab={<span><Icon type='pie-chart' />Stats</span>}>
                <Stats {...this.props} />
              </TabPane> */}
              <TabPane key='statistics' tab={<span><Icon type='pie-chart' />Stats</span>}>
                <Statistics
                  match={this.props.match}
                />
              </TabPane>
              <TabPane forceRender key='profile' tab={<span><Icon type='smile' />Profile</span>}>
                <Profile
                  ref={ref => {this._profile = ref}}
                  tabKey='profile'
                  setActiveKey={this.onTabChange}
                  readOnly={readOnly}
                  userReducer={this.props.userReducer}
                  userHelper={userHelper}
                  user={user}
                  onFieldTouched={this.onFieldTouched}
                />
              </TabPane>
              <TabPane forceRender key='merchants' tab={<span><Icon type='contacts' />Merchants</span>}>
                <PageMerchants
                  ref={ref => {this._merchant = ref}}
                  readOnly={readOnly}
                  userReducer={this.props.userReducer}
                  userHelper={userHelper}
                  user={user}
                  onFieldTouched={this.onFieldTouched}
                />
              </TabPane>
            </Tabs>
          </Row>






          {/* <Menu
            defaultSelectedKeys={[defaultMenu]}
            selectedKeys={[
              Lib.getMenuUrl(location.pathname, undefined, defaultMenu)
            ]}
            className="px-2"
            mode="horizontal"
          >
            <Menu.Item key="stats">
              <Link to={`${match.url}/stats`}>
                <Icon type="pie-chart" />
                <span>Stats</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="profile">
              <Link to={`${match.url}/profile`}>
                <Icon type="smile" />
                <span>Profile</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="merchants">
              <Link to={`${match.url}/merchants`}>
                <Icon type="contacts" />
                <span>Merchants</span>
              </Link>
            </Menu.Item> */}
            {/* <Menu.Item key="permission">
              <Link to={`${match.url}/permission`}>
                <Icon type="safety-certificate" />
                <span>User Permission</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="modules">
              <Link to={`${match.url}/modules`}>
                <Icon type="appstore" />
                <span>Modules</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="reports">
              <Link to={`${match.url}/reports`}>
                <Icon type="audit" />
                <span>Reports</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="jobs">
              <Link to={`${match.url}/jobs`}>
                <Icon type="branches" />
                <span>Jobs</span>
              </Link>
            </Menu.Item> */}
          {/* </Menu> */}
          {/* <Content style={{ margin: '12px 0' }}>
            {routeElement}
            <Switch>
              <Route path={`${match.url}/stats`}>
                <Stats {...this.props} />
              </Route>
              <Route path={`${match.path}/profile`}>
                <Profile
                  userReducer={this.props.userReducer}
                  userHelper={userHelper}
                  user={user}
                  setUser={this.setUser}
                />
              </Route>
              <Route path={`${match.path}/merchants`}>
                <PageMerchants
                  userReducer={this.props.userReducer}
                  userHelper={userHelper}
                  user={user}
                  setUser={this.setUser}
                />
              </Route> */}
              {/* <Route path={`${match.path}/permission`}>
                <UserPermission
                  userReducer={this.props.userReducer}
                  userHelper={userHelper}
                  user={user}
                  // setUser={this.setUser}
                />
              </Route>
              <Route path={`${match.path}/modules`}>
                <PageModules
                  userReducer={this.props.userReducer}
                  userHelper={userHelper}
                  user={user}
                  // setUser={this.setUser}
                />
              </Route>
              <Route path={`${match.path}/reports`}>
                <PageReports
                  userReducer={this.props.userReducer}
                  userHelper={userHelper}
                  user={user}
                  // setUser={this.setUser}
                />
              </Route>
              <Route path={`${match.path}/jobs`}>
                <PageJobQueues
                  userReducer={this.props.userReducer}
                  userHelper={userHelper}
                  user={user}
                  // setUser={this.setUser}
                />
              </Route> */}
            {/* </Switch>
          </Content> */}
        </Layout>
      </Layout>
    ) : (
      <PageError
        errorResponse={errorResponse}
        onGoBack={this.onGoHome}
        onReload={this.retrieve}
      />
    )
  }
}
