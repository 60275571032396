import { BaseDetail } from 'App/Screens/SubModules/Component'
import fields from './fields'

class PageDetail extends BaseDetail {
  constructor(props) {
    super(props)

    this.urlKey = 'module-expertSystem-keywordGroup-detail'
    this.title = 'Keyword Group'
    this.urlList = '/expert-system/keyword-groups'
    this.headerDescriptions = [
      { key: 'name', label: 'Name' },
    ]
    this.fields = fields
  }
}

export default PageDetail