const descriptor = {
  "app_label": "reports",
  "model_name": "skumapping",
  "pagination": true,
  "columns": [
    {
      "name": "pk",
      "label": "Id",
      "help_text": null,
      "hidden": true,
      "identifier": false,
      "data_type": "str",
      "search": false,
      "sort": false
    },
    {
      "name": "product_code",
      "label": "Product code",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "search": true,
      "sort": true
    },
    {
      "name": "sku",
      "label": "Sku",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "search": true,
      "sort": true
    },
    {
      "name": "mode",
      "label": "Mode",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": null,
      "child_path": "name",
      "search": true,
      "sort": true
    },
    {
      "name": "description",
      "label": "Description",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "search": true,
      "sort": true
    },
    {
      "name": "merchant",
      "label": "Merchant",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": null,
      "child_path": "name",
      "search": true,
      "sort": true
    },
    {
      "name": "group",
      "label": "Group",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": null,
      "child_path": "name",
      "search": true,
      "sort": true
    },
    {
      "name": "modified",
      "label": "Modified",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "datetime",
      "search": false,
      "sort": true
    },
  ],
  "filter_by": [
    {
      name: 'mode',
      label: 'Mode',
      api: {
        url_name: 'module-merchant-mappingMode-autocomplete',
        key: 'pk',
        display: 'name',
      }
    },
    {
      name: 'group',
      label: 'Merchant Group',
      api: {
        url_name: 'module-merchant-group-autocomplete',
        key: 'pk',
        display: 'name',
      }
    },
    {
      name: 'merchant',
      label: 'Merchant',
      api: {
        url_name: 'module-merchant-merchant-autocomplete',
        key: 'pk',
        display: 'name',
      }
    },
  ],
  "export_import": {
    "export": {
      "directly": false,
      "url_name": "api:admin:reports:skumapping:export",
      "filter_by": [
        {
          "label": "Export Mode",
          "name": "export_mode",
          "type": "choices",
          "choices": [
            [
              "normal",
              "Normal Export"
            ],
            [
              "initial",
              "Initial Export"
            ]
          ]
        },
        {
          "label": "Export Limit",
          "name": "limit",
          "type": "choices",
          "choices": [
            [
              "all",
              "All Records"
            ],
            [
              "100",
              "100 Records"
            ],
            [
              "500",
              "500 Records"
            ]
          ]
        }
      ]
    },
    "import": {
      "url_name": "api:admin:estimation:exportimportsession:import",
      "allow_initial_data": true,
      "fields": [],
      "example_format_string": "seq,product_code,sku,mode"
    }
  }
}

export default descriptor