import React from "react";
import PropTypes from "prop-types";
import { message } from "antd";
import { get } from "lodash";

import Sites from "App/Config/Sites";
import SubAllList from "App/Component/SubAllList";
import descriptor from "./descriptorOffline";
import groupBy from "App/Screens/SubModules/JobMg/groupBy";

const windowFeatures = `width=${window.innerWidth *
  0.9},height=${window.innerHeight * 0.9}`;
const domainQs = Sites.url.qs;

class PageJobs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  getQueryParams() {
    let params = { job_queue: this.props.match.params.id };
    return params;
  }

  getGroupByOptions() {
    return [
      {
        label: "Status",
        key: "status",
        dataIndex: "status",
        startcase: "true"
        // default: true,
      }
    ];
  }

  onAddClick = () => {
    const urlTarget = `${domainQs}/job/add`;
    window.open(urlTarget, urlTarget, windowFeatures);
  };

  onDetailClick = id => {
    if (this.state.hasQsAccess || true) {
      const { userReducer } = this.props;
      const urlTarget =
        domainQs +
        "/job/" +
        id +
        "?ItSHPShcIRDYnjKX=" +
        btoa(`${Sites.oauth.id}:${Sites.oauth.secret}`) +
        "&MwFMqLoCBGBDyZwj=" +
        userReducer.token +
        "&lmMgPPePCXurSXjI=" +
        get(userReducer, "user.username");
      window.open(urlTarget, urlTarget, windowFeatures);
    } else {
      message.info(`Sorry, you don't have access to QS`);
    }
  };

  render() {
    return (
      <SubAllList
        disableAddButton
        permissions={this.props.permissionItems}
        masterKey="jobs"
        pageTitle="Jobs"
        readParams={this.getQueryParams()}
        descriptor={descriptor}
        avatar="name"
        userReducer={this.props.userReducer}
        groupByOptions={groupBy}
        onAddClick={() => this.onAddClick()}
        onDetailClick={item => this.onDetailClick(item)}
      />
    );
  }
}

PageJobs.propTypes = {
  permissionItems: PropTypes.array
};

PageJobs.defaultProps = {
  permissionItems: []
};

export default PageJobs;
