export default [
  {
    title: 'Section',
    required: true,
    type: 'SelectQuery',
    data: 'sections',
    initialValue: '',
    note_text: '',
    dataIndex: 'section',
    selectedKey: 'pk',
    selectedValue: 'name',
    dropdownClassName: 'width-auto',
    additional: 'edit|add',
    fieldKey: 'section',
    editable: true,
    width: 250,
    relations: ['subsection'],
    skip: true,
    order: 0,
  },
  {
    title: 'Subsection',
    required: true,
    type: 'SelectQuery',
    data: 'subsections',
    initialValue: '',
    note_text: '',
    dataIndex: 'subsection',
    selectedKey: 'pk',
    selectedValue: 'name',
    dropdownClassName: 'width-auto',
    additional: 'edit|add',
    fieldKey: 'subsection',
    editable: true,
    width: 250,
    relations: ['prefill_sets', 'override_groups'],
    foreignKey: 'section',
    order: 0,
  }, {
    title: 'Name',
    required: true,
    type: 'text',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'name',
    fieldKey: 'name',
    editable: true,
    order: 2,
  }, {
    title: 'Prefill sets',
    type: 'select_multiple',
    data: 'prefillSets',
    initialValue: [],
    note_text: 'This override set will only be applicable under these Prefill Sets. Leave blank to make applicable under any Prefill Set (or none) under the same Subsection.',
    additional: 'add',
    dataIndex: 'prefill_sets',
    paramProps: { status: 'active' },
    selectedKey: 'pk',
    selectedValue: 'name',
    // minSelected: 0,
    // maxSelected: 1,
    width: 200,
    fieldKey: 'prefill_sets',
    editable: true,
    foreignKey: 'subsection', // for default param only, example when open detail
    order: 3,
  }, {
    title: 'Status',
    type: 'select',
    disableAllowClear: true,
    data: [
      {
        alias: 'active',
        value: 'Active - Anyone can use'
      }, {
        alias: 'inactive',
        value: 'Inactive - No one can use'
      }, {
        alias: 'unpublished',
        value: 'Unpublished - Only certain people can use'
      }
    ],
    initialValue: 'active',
    note_text: '',
    additional: '',
    dataIndex: 'status',
    fieldKey: 'status',
    editable: true,
    order: 4,
  }, {
    title: 'Override groups',
    type: 'select_multiple',
    data: 'overrideSetGroups',
    initialValue: [],
    // note_text: 'This override set will only be applicable under these Prefill Sets. Leave blank to make applicable under any Prefill Set (or none) under the same Subsection.',
    additional: 'add',
    dataIndex: 'override_groups',
    selectedKey: 'pk',
    selectedValue: 'name',
    paramProps: { status: 'active' },
    foreignKey: 'subsection',
    // editable: true,
    fieldKey: 'override_groups'
  }, {
    title: 'Order',
    type: 'number',
    dataIndex: 'order',
    fieldKey: 'order',
    initialValue: null, // set initialValue to null for allow null
    editable: true,
  }, {
    title: 'Indentation',
    type: 'number',
    dataIndex: 'indentation',
    fieldKey: 'indentation',
    initialValue: 0, // set initialValue to number (or dont pass) for cannot null
    editable: true,
  },
];
