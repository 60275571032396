import { BaseDetail } from 'App/Component/Bases'
import fields from 'App/Screens/Job/Accounts/fields'

class PageAdd extends BaseDetail {
  title = 'Account'
  urlData = 'module-merchant-account'
  fields = fields
  state = {
    isCheckingPermission: false,
    isAccessAllowed: true
  }
}

export default PageAdd