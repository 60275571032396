import { BaseDetail } from 'App/Screens/SubModules/Component'
import fields from './fields'

class PageDetail extends BaseDetail {
  constructor(props) {
    super(props)

    this.urlKey = 'module-expertSystem-keywordPage-detail'
    this.title = 'Keyword Page'
    this.urlList = '/expert-system/keyword-pages'
    this.headerDescriptions = [
      { key: 'name', label: 'Name' },
      { key: 'document_type', label: 'Document Type' },
    ]
    this.fields = fields
  }
}

export default PageDetail