import React from 'react'
import { Row, Col, List, Typography, Tag, Tooltip, Icon, Button, Modal } from 'antd'
import styled from 'styled-components'
import moment from 'moment'

import 'App/Component/Singlepage/SingleStyle.css'

const { Text } = Typography
const { confirm } = Modal

class ListItem extends React.Component {

  showTag(tags) {
    if (tags || true) {
      const tags2 = ['dum', 'dummy']
      return tags2.map((tag) => (
        <StyledTag key={tag} color='#1890ff'>{tag}</StyledTag>
      ))
    }
  }

  selectImage(e, id, condition) {
    e.stopPropagation()
    this.props.selectImage(id, condition)
  }

  handleDelete(e, pk) {
    e.stopPropagation()
    this.showDeleteConfirm(pk)
  }

  showDeleteConfirm(id, callback) {
    confirm({
      title: 'Are you sure delete this file?',
      content: 'This action will delete on the database',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk:()=> {
        this.props.removeImage(id)
        callback && callback(true)
      },
      onCancel:()=> {
        callback && callback(false)
      },
    })
  }

  render() {
    const { readOnly, imageData, index, handleClick, currentProduct } = this.props

    return (
      <List.Item className='no-padding'>
        <Row type='flex' className='equal-container list-item bordered' style={{maxHeight: '100%'}}>
          <Col xs={24} sm={24} md={8}>
            <Row className='square-width'>
              <Row className='equal-container left-item'>
                <RoundedImage alt='Nothing' src={imageData.file} />
                {!readOnly && (
                  <div className='equal-container left-item'>
                    <Rounded className='overlay equal-container rounded'
                      onClick={(e) => handleClick(e, index, true)}
                    >
                      {
                        !this.props.selectedImage[index] &&
                        <IconSelect className='c-unselected' type='check-circle' theme='filled'
                          onClick={e => this.selectImage(e, imageData.pk, true)}
                        />
                      }
                      <Button
                        type='danger'
                        onClick={e => this.handleDelete(e, imageData.pk)}
                        className='abs-br'
                        style={{ margin: '0 19px 7px 0' }}
                        icon='delete'
                      />
                    </Rounded>
                  </div>
                )}
                {
                  this.props.selectedImage[index] &&
                  <IconSelect className='c-selected' type='check-circle' theme='filled'
                    onClick={e => this.selectImage(e, imageData.pk, false)}
                  />
                }
              </Row>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={16} style={{backgroundColor: 'rgba(255, 255, 255, 0.5'}}>
            <Row className='push-left2' style={{width: '100%', paddingTop: '50%'}}>
              <Row style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', overflow: 'auto'}}>
                <RowItem>
                  { imageData.is_thumbnail &&
                    <Tooltip placement='top' title='Selected thumbnail'>
                      <Col span={1}>
                        <DotGreen className='ant-badge-status-dot' />
                      </Col>
                    </Tooltip>
                  }
                  <Col span={16}>
                    <Tooltip placement='topLeft' title='Click to open this image'>
                      <a href={imageData.file} target='_blank' rel='noopener noreferrer'>
                        <TextClicked strong className='font-xl' ellipsis style={{width: '100%'}}>{imageData.title}</TextClicked>
                      </a>
                    </Tooltip>
                  </Col>
                  <Col sm={7} xs={0} className='over-hide'>
                    <Text className='right c-gray f-sm'>{moment(imageData.created).format('ll')}</Text>
                  </Col>
                </RowItem>
                {/* <RowItem>
                  {this.showTag(imageData.tag)}
                </RowItem> */}
                <RowItem>
                  <Text style={{fontSize: '0.8rem'}}>
                    Name: {imageData.file_name}
                  </Text>
                </RowItem>
                <RowItem>
                  <Text strong style={{fontSize: '1rem'}}>
                    Unit: {currentProduct.unit.name}
                  </Text>
                </RowItem>
                <RowItem>
                  <Text>
                    {imageData.description}
                  </Text>
                </RowItem>
              </Row>
            </Row>
          </Col>
        </Row>
      </List.Item>
    )
  }
}

export default ListItem

const RoundedImage = styled.img`
  border-radius: 4px;
  width: 100%;
  height: 100%;
`

const Rounded = styled.div`
  border-radius: 4px;
  cursor: zoom-in;
`

const RowItem = styled(Row)`
  margin-bottom: 5px;
`

const TextClicked = styled(Text)`
  cursor: pointer;
`

const StyledTag = styled(Tag)`
  margin-bottom: 4px !important;
`

const IconSelect = styled(Icon)`
  font-size: 1.5rem;
  background-color: #fff;
  border-radius: 1.5rem;
  position: absolute;
  left: 0;
  top: 0;
  margin: -5px;
  cursor: pointer;
`

// blue antd = #1890ff
// green antd = #52c41a
const DotGreen = styled.span`
  background: #1890ff;
  border-radius: 50%;
  margin-top: 8px;
`
