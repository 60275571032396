import React from 'react'
import {
	Button,
	Col,
	Form,
	Icon,
	Input,
	message,
	Spin,
	Tooltip,
	notification,
	Popconfirm,
	Table,
	Upload,
	Row,
	Card,
	Typography
} from "antd";
import {RequestV2 as Request, Lib} from "../../../../Utils";
import styled from "styled-components";
import PropTypes from "prop-types";
import {EditableFormRow, EditableContext} from "../../../../Component/Edit/EditableRow";
import EditableCell from "./Editablecell";
import {get} from "lodash";

const labelAttachment = "Attachment"
const labelLink = "Attachment Link"
let isURL = true
const FormItem = Form.Item;
const styledPlan = {
	marginLeft: '20%',
	marginRight: '20%'
}
const formItemLayout = {
	// labelCol: {span: 7},
	wrapperCol: {span: 24},
	style: styledPlan
};

class Tableeditable extends React.Component{
	tempPlans = []
	varIsLoading = false

	constructor(props){
		super(props)
		this.onPressEnter = this.onPressEnter.bind(this)
		this.state = {
			urlsource: '',
			isLoading: false,
			data: [],
			fileList: [], // {attachment: FILE, attachment_link: string}
			editingKey: '',
			isEditing: false
		}
	}

	setLoading = (val) => {
		this.setState({loading: val})
	};

	messageCustom = (type, msg) => {
		if (type !== 'loading') {
			message.destroy();
			this.setLoading(false);
		} else {
			this.setLoading(true);
		}
		return (
			message[type](msg)
		)
	};


	handleAdd = () => {
		if (!this.varIsLoading) {
			this.varIsLoading = true

			const fileList = this.props.form.getFieldValue(this.decoratorFile)
			const link = this.props.form.getFieldValue(this.decoratorLink)
			// Pakai ini jika ingin getValue {semua}
			// const arrValue = this.props.form.getFieldsValue([this.decoratorFile, this.decoratorLink])
			// end ---------------------------------
			if (this.props.dataRecord) {
				if (isURL) {
					if (fileList && fileList.length > 0) {
						for (const keys in fileList) {
							let values = new FormData()
							values.append(this.decoratorFile, fileList[keys].originFileObj)
							if (link) {
								values.append(this.decoratorLink, link)
							}
							this.onSave(values)
						}
					} else {
						if (link) {
							let values = new FormData()
							values.append(this.decoratorLink, link)
							this.onSave(values)
						}
					}
				} else {
					this.urlInput.focus()
				}
			} else {
				if (fileList && fileList.length > 0 && isURL) {
					let tempData = this.state.data
					let tempFileList = this.state.fileList
					let key = 0
					if (tempData.length > 0) {
						key = tempData[tempData.length-1].key + 1
					}
					for (const keys in fileList) {
						let temp1 = {}
						temp1.key = key
						temp1.file_name = fileList[keys].originFileObj.name
						if (link) {
							temp1.attachment_link = link
						}
						tempData.push(temp1)

						let temp2 = {...temp1}
						temp2.attachment = fileList[keys].originFileObj
						// store to state, then store to API (in index.js)
						tempFileList.push(temp2)

						key++
					}

					this.setState({
						data: tempData,
						fileList: tempFileList,
					})

					this.props.fileList(tempFileList)

					this.props.form.setFieldsValue({
						[this.decoratorFile]: undefined,
						[this.decoratorLink]: undefined
					})

				} else if (link && isURL) {
					let tempData = this.state.data
					let tempFileList = this.state.fileList
					let key = 0

					if (tempData.length > 0) {
						key = tempData[tempData.length-1].key + 1
					}

					tempData.push({
						key: key,
						file_name: '',
						attachment: '',
						attachment_link: link,
					})
					tempFileList.push({
						key: key,
						attachment: null,
						attachment_link: link,
					})

					this.setState({
						data: tempData,
						fileList: tempFileList
					}, () => {this.varIsLoading = false})

					// Set to index.js buildings
					this.props.fileList(tempFileList)

					this.props.form.setFieldsValue({
						[this.decoratorLink]: undefined
					})
				} else {
					this.urlInput.focus()
				}
				this.varIsLoading = false
			}
		}
	};

	clearTable = (withAttachment = true) => {
		this.setState({
			data: [],
			fileList: [],
		})

		if (withAttachment) {
			this.props.form.setFieldsValue({
				[this.decoratorFile]: undefined,
				[this.decoratorLink]: undefined
			})
		}
	}

	toggleDrawerSQ = (operationSQ, urlSQ, idData = null) => {
		if (operationSQ === 'back') {
			let popHistory = this.state.historySubDrawer, visibleDrawerSQ = true;
			popHistory.pop();

			if (popHistory.length < 1) {
				visibleDrawerSQ = false
			}

			this.setState((prevState) => ({
				historySubDrawer: popHistory,
				visibleDrawerSQ: visibleDrawerSQ,
				indexing: prevState.indexing - 1,
			}));

		} else if(operationSQ === 'close') {
			this.setState((prevState) => ({
				historySubDrawer: [],
				visibleDrawerSQ: false,
				indexing: -1,
			}));

		} else {
			let history = { idData: idData, url: urlSQ, historyOperation: operationSQ};

			this.setState((prevState) => ({
				visibleDrawerSQ: true,
				operationSQ,
				historySubDrawer: [...prevState.historySubDrawer, history],
				indexing: prevState.indexing + 1
			}));
		}
	};

	onSave = (values, isLast = true) => {
		values.append('building', this.props.dataRecord.pk)
		this.messageCustom('loading', 'Save in progress..');
		Request(
			"post",
			Lib.decoratorName(this.props.urlTab) + "-create",
			{},
			values,
			[],
			(response, extra) => this.saveSuccess(response, isLast),
			this.onFailed,
		);
	}

	saveSuccess = (response, isLast = true) => {
		if (response) {
			if (response.status === 200 || response.status === 201) {
				if (isLast) {
					// this.toggleDrawerSQ('close');
					this.reloadPage()
					this.messageCustom('success', `Save successfully.`);
					this.props.form.setFieldsValue({
						[this.decoratorFile]: undefined,
						[this.decoratorLink]: undefined
					})
					this.varIsLoading = false
				}
			}
		}
	};

	onFailed = (error) => {
		let errorObj = error.response.data;
		this.messageCustom('error', 'Action failed');
		this.varIsLoading = false
		const btn = (
			<Button type="primary" size="small" onClick={() => notification.destroy()}>
				Clear All
			</Button>
		);
		const closeAllNotifications = () => {notification.destroy()};
		if (Object.keys(errorObj).length > 1){
			notification.open({
				message: 'Clear All Message',
				duration: 0,
				btn,
				key:`open${Date.now()}`,
				onClose : closeAllNotifications
			});
		}
		for (let key in errorObj) {
			if (errorObj.hasOwnProperty(key)) {
				notification.error({
					message: `Error on field ${key}`,
					duration: 0,
					description: errorObj[key]
				})
			}
		}
	};

	onDelete = (e, data, url) => {
		if (this.props.dataRecord) {
			e.preventDefault()
			e.stopPropagation()
			this.setState({isLoading: true})
			this.request = Request(
				"delete",
				url,
				{},
				{},
				[data.pk],
				this.deleteSuccess,
				this.deleteFailed,
			)
		} else {
			let filteredData = this.state.data.filter(arr => arr.key !== data.key)
			let filteredFileList = this.state.fileList.filter(arr => arr.key !== data.key)
			this.setState({
				data: filteredData,
				fileList: filteredFileList,
			})
			this.props.fileList(filteredFileList)
		}
	}

	deleteSuccess = (response) => {
		this.messageCustom('loading', 'delete in progress..');
		if (response) {
			if (response.status === 204) {
				this.messageCustom('success', `Deleted.`);
				this.read()
			}
		}
	};


	deleteFailed = (error) => {
		let errorObj = error.response.data;
		for (let key in errorObj) {
			if (errorObj.hasOwnProperty(key)) {
				message.error('' + errorObj[key]);
			}
		}
	};

	stopEvent = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	validateLink = (rule, value, callback) => {
	  if (value !== '') {
			var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
				'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
				'((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
				'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
				'(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
				'(\\#[-a-z\\d_]*)?$','i'); // fragment locator
			if(!pattern.test(value)){
						isURL = false
				callback("The input is not valid url!")
			} else {
				isURL = true
				callback()
			}
		} else {
			isURL = true // skip URL
		}
	}

	queryParam = () => {
		return {
			ordering: this.ordering,
			search: this.state.searchTag.join(','),
			fields: this.state.filterTag.join(','),
			page: this.page,
			limit: this.limit_request,
		}
	};

	reloadPage = () => {
		Lib.windowStop();
		this.read();
	};

	read = (pk = null) => {
		this.setState({isLoading: true});
		this.request = Request(
			"get",
			"buildings-detail",
			{},
			{},
			pk ? [pk] : [this.props.dataRecord.pk],
			this.readSuccess,
			this.readFailed
		);
	}

	urlChange =(e)=>{
		this.setState({
			urlsource: e.target.value
		})
		if (this.props.operation === 'add') {
			this.props.newurl(e.target.value)
		}
	}


	readSuccess = (response) => {
		this.setState({
			data: response.data.plans,
			isLoading: false
		})
	};

	readFailed = () => {
		message.error('Cant Connect To Server');
		this.setState({
			isLoading: false,
		})
	};

	onSaveedit = (form, pk_new_record) => {
		this.setState({isLoading: true});
		this.messageCustom('loading', 'Save in progress..');
		form.validateFields((error, row) => {
			// if (error) {
			//     return;
			// }

			// const checkTemp = Lib.checkIsTemp(pk_new_record);
			this.setState({
				editingKey: ''
			})

			let dataEncrypt = new FormData()
			const attachment = get(row, 'attachment')
			let attachment_link = get(row, 'attachment_link')
			dataEncrypt.append('building', this.props.dataRecord.pk)
			if (attachment && attachment.length > 0) {
				// dataEncrypt.append('attachment', get(row, 'attachment[0].originFileObj'))
				dataEncrypt.append('attachment', attachment[0].originFileObj)
			}
			if (!attachment_link) {
				attachment_link = ''
			}
			dataEncrypt.append('attachment_link', attachment_link)

			this.request = Request(
				'put',
				`plans-update`,
				{},
				dataEncrypt,
				[pk_new_record],
				this.saveSuccess,
				this.onFailed,
				undefined
			);
		});
	};

	isEditing = record => {
		return record.pk === this.state.editingKey;
	};

	edit = (e, pk) => {
		e.stopPropagation()
		this.setState({
			editingKey: pk
		});
		this.setState({
			isEditing: true
		})
	};

	cancel = () => {
		this.setState({ editingKey: '' });
	};

	componentDidMount() {
		const {dataRecord} = this.props
		let tempData = []
		if (dataRecord) {
			if (dataRecord.plans && dataRecord.plans.length > 0) {
				tempData = dataRecord.plans
			}
		}
		this.setState({
			data: tempData
		})

		this.decoratorFile = Lib.decoratorName(labelAttachment)
		this.decoratorLink = Lib.decoratorName(labelLink)
	}

	componentDidUpdate(prevProps) {
		if (this.props.dataRecord !== prevProps.dataRecord) {
			if (this.props.dataRecord) {
				this.read(this.props.dataRecord.pk)
			} else {
				this.clearTable()
			}
		}
			// else {
			// 	if (!this.props.dataRecord) {
			// 		console.log('Record kosong dan sama dengan previous')
			// 		console.log('prev', prevProps.dataRecord)
			// 	}
			// }
		// }
	}

	static getDerivedStateFromProps(props, state) {
		if (props.dataRecord !== state.prevPropsDataRecord) {
			// if (props.dataRecord) {
			// 	// this.read(props.dataRecord.pk)
			// }
			return {
				prevPropsDataRecord: props.dataRecord
			}
		}
		return null
	}

	onPressEnter(e) {
		console.log('e', e)
		this.handleAdd()
		return
	}

	render() {
		const {editingKey}= this.state
		const disabled = editingKey !== '';
		const {getFieldDecorator} = this.props.form
		const {
			required,
			note_text
		} = this.props;
		const components = {
			body: {
				row: EditableFormRow,
				cell: EditableCell
			},
		};

		this.columns = [
			{
				width: '15%',
				title: 'Name',
				dataIndex: 'file_name',
				key: 'file_name',
				render: (text, record) => (
					(text)
					? (text.lastIndexOf('.') > 17)
						? <span>{text.substring(0, 13)}...{text.slice(-3)}</span>
						: <span>{text}</span>
					: '-'
				)
			},

			{
				width: '30%',
				title: 'Attachment',
				dataIndex: 'attachment',
				editable: true,
				key: 'attachment',
				render: (text, record) => (
					<span>
						{text ? <a href={text} rel='noopener noreferrer' target='_blank'> <Icon size="medium" type="file" />  https://...{text.substring(56, 80)}...</a> : ''}
						{text ?

							<Tooltip placement="right" title="Delete file">
								<Button
									type="danger"
									icon="delete"
									size="small"
									onClick={(e) => {
										window.confirm(`Are you sure want to delete this file? ${text}`) &&
											this.onDelete(e, record, 'plans-selected-delete')
									}}/>
							</Tooltip>

							: '-'}

					  </span>
				)
			},
			{
				width: '20%',
				title: 'Attachment Link',
				dataIndex: 'attachment_link',
				editable: true,
				key: 'attachment_link',
				render: (text) => (
					(text)
					? (text.length > 30)
						? <span>{text.substring(0, 33)}...</span>
						: <span>{text}</span>
					: '-'
				)
			},
			{
			  width: '10%',
				title: 'Action',
				key: 'operation',
				render: (data, record) => {
					const editable = this.isEditing(record);
					return editable ? (
						<Row>
							<Col>
								<EditableContext.Consumer>
								{form => (
									<Button
										style={{marginRight: '4px'}}
										type="primary"
										icon="save"
										onClick={()=>this.onSaveedit(form, data.pk)}
									/>
								)}
								</EditableContext.Consumer>
								<Button
									type="primary"
									icon="close-circle"
									onClick={() =>this.cancel(data.pk)}
								/>
							</Col>
						</Row>
					):(
						<Row>
							<Col>
							{
								(this.props.dataRecord) ?
								<Button
									style={{marginRight: '4px'}}
									type="primary"
									disabled={disabled}
									onClick={(e) => this.edit(e, data.pk)}
									icon="edit"
								/>
								: null
							}
								{/* {disabled ?
									<Button type="danger" icon="delete" disabled={disabled} />
									: */}
									{
									(this.props.dataRecord) ?
									<Popconfirm
										title="Sure to delete?"
										onCancel={(e) => this.stopEvent(e)}
										onConfirm={(e) => this.onDelete(e, data, 'plans-delete')}>
										<Button type="danger" icon="delete" disabled={disabled} />
									</Popconfirm>
									: <Button type="danger" icon="delete" disabled={disabled}
											onClick={(e) => this.onDelete(e, data, '')}/>
									}
								{/* } */}
							</Col>
						</Row>
					)
				}
			}
		]
		const columns = this.columns.map(col => {
			if (!col.editable) {
				return col;
			}
			return {
				...col,
				onCell: record => ({
					record,
					inputType: col.dataIndex === 'attachment' ? 'file' : 'text',
					dataIndex: col.dataIndex,
					title: col.title,
					editing: this.isEditing(record),
				}),
			};
		});

		return (

				<Col span={24}>
					{/* { operation==="edit" ? */}
					<RoundedCard size="small" title="PLANS" headStyle={{textAlign: 'center'}}>
						<FormItem extra={note_text} labelCol={{ span: 3 }} wrapperCol={{ span: 24 }}>
							<Spin spinning={this.state.isLoading}>
								<EditableContext.Provider value={this.props.form}>
								<Table
									bordered
									components={components}
									dataSource={this.state.data}
									columns={columns}
									scroll={{ x: 920 }}
									rowKey={data => data.pk ? data.pk : data.key}
									rowClassName="editable-row"
									// pagination={{
									// 	pageSizeOptions: ['25', '50', '75', '100'],
									// 	showSizeChanger: true,
									// 	onShowSizeChange: this.onShowSizeChange,
									// 	showQuickJumper: true,
									// 	pageSize: 10
									// }}
								/>
								</EditableContext.Provider>
							</Spin>
						</FormItem>
						{/* : ""} */}

						<Row>
							<Col style={styledPlan}>
								<Row>
									<Typography.Title level={3}>Add New Plan</Typography.Title>
								</Row>
							</Col>
						</Row>
						{/* <PageHeader title="Add new plan" /> */}
						<Form.Item /*label="Attachment file"*/ {...formItemLayout}>
							<div className="dropbox">
								{getFieldDecorator(Lib.decoratorName(labelAttachment), {
									valuePropName: 'fileList',
									getValueFromEvent: (e) => this.props.normFile(e, 0, false),
								})(
								<Upload.Dragger
									name="files"
									multiple={true}
									action="#"
									beforeUpload={() => false}>
									<p className="ant-upload-drag-icon">
										<Icon type="inbox" />
									</p>
									<p className="ant-upload-text">Click or drag file to this area to upload</p>
									<p className="ant-upload-hint">Support for a single or bulk upload.</p>
								</Upload.Dragger>
								)}
							</div>
						</Form.Item>

						<Form.Item /*label="Attachment link"*/ {...formItemLayout}>
							{ getFieldDecorator(Lib.decoratorName(labelLink), {
								rules: [{
									required: required,
									message: `Please Input valid link!`
								},{
									validator: this.validateLink,
								}],
								})(
									<Input
										name="attachmenturl"
										type="text"
										placeholder="Attachment Link"
										onPressEnter={this.onPressEnter}
										ref={(input) => this.urlInput = input} />)
							}
						</Form.Item>
						<Form.Item /*wrapperCol={{ span: 24 }}*/ {...formItemLayout}>
							<Button disabled={this.state.isLoading} type="primary" onClick={this.handleAdd} htmlType="submit">
								Submit
							</Button>
						</Form.Item>
						</RoundedCard>
				</Col>
		)
	}

}

Tableeditable.propTypes = {
	visible: PropTypes.bool,
	toggle: PropTypes.func
};

const WrappedDynamicRule = Form.create({ name: 'dynamic_rule' })(Tableeditable);
export default WrappedDynamicRule

const RoundedCard = styled(Card)`
	border-radius: 12px !important;
	font-size: 1rem;
	
`;
