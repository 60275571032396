import React, { Component } from "react";
import styled from "styled-components";
import { Layout } from "antd";
import {
  AppNavigation,
  AppHeader,
  AppBreadcrumb
} from "App/Component/Template";

const { Content } = Layout;

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false
    };
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };

  render() {
    const { userReducer } = this.props;

    // console.log('ma', this.props);

    return (
      <StyledLayout collapsed={this.state.collapsed.toString()}>
        <AppNavigation
          collapsed={this.state.collapsed}
          onCollapse={this.toggle}
        />

        <Layout>
          <AppHeader {...this.props} {...this.state} toggle={this.toggle} />
          <StyledContent>
            <AppBreadcrumb />
          </StyledContent>

          {/* <StyledFooter>
            Dax ©2018 Data Exchange
          </StyledFooter> */}
        </Layout>
      </StyledLayout>
    );
  }
}

export default {Main:123};

const StyledLayout = styled(Layout)`
  min-height: 100vh !important;
  margin-left: ${props => (props.collapsed === "true" ? "80px" : "300px")};
  transition: all 0.2s;
`;
const StyledContent = styled(Content)`
  margin: 0 16px;
`;
