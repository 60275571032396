import React from 'react'
import { get, startCase } from 'lodash';
import { Modules, Lib } from "App/Utils";

import {Row, Col, Descriptions, Tag, Upload, Affix, Button, Layout, Menu, Icon, PageHeader} from "antd";
import JobHistory from './JobHistory'

const { Content } = Layout;
const IbesLogo = require("App/Assets/img/IBES-LOGO.png");
const workLaptop = require('App/Assets/img/work-laptop.png');

class JobDetail extends React.Component {
  constructor(props) {
    super(props)

    this.statusColor = {
      'new': '#74b9ff',
      'pre-qs': '#fed330',
      'assigned': '#ff8946',
      'started': '#1890ff',
      'qa1': '#ffae56',
      'qa2': '#8a4bff',
      'reopened': '#3867d6',
      'pending': '#e1b12c',
      'finished': '#36cc86',
      'cancelled': '#e86d37',
      'closed': '#ff3a29',
      'unknown': '#fff',
    }
    this.iconStatuses = [
      {
        status: ['new'],
        icon: 'file-add',
      },
      {
        status: ['started', 'reopened'],
        icon: 'play-square',
      },
      {
        status: ['assigned'],
        icon: 'solution',
      },
      {
        status: ['QA1'],
        icon: 'user',
      },
      {
        status: ['QA2'],
        icon: 'team',
      },
      {
        status: ['pending'],
        icon: 'file-sync',
      },
      {
        status: ['finished'],
        icon: 'file-done',
      },
      {
        status: ['cancelled'],
        icon: 'exception',
      },
      {
        status: ['closed'],
        icon: 'file-excel',
      },
    ]
  }

  getStatusIcon = val => {
    const iconStatus = this.iconStatuses.find(iconStatus => iconStatus.status.includes(val))
    return get(iconStatus, 'icon', 'user')
  }

  handlemenuClick=(e)=>{
    this.setState({
      current: e.key
    })
  }

  renderSeedAttachment(seed) {
    if (seed) {
      for (let i = 0; i < seed.length; i++) {
        seed[i] = {
          uid: i,
          name: seed[i].file_name,
          url: seed[i].file,
          thumbUrl: seed[i].file,
          status: 'done',
        };
      }
      return <Upload fileList={seed} showUploadList={{ showRemoveIcon: false }} disabled />;
    } else {
      return '-';
    }
  }

  componentWillUnmount() {
    Lib.windowStop();
  }

  render() {
    const { history, location, jobDetail} = this.props;
    return (
      <Layout className="um-layout">
        <Affix offsetTop={0}>
          <>
            <PageHeader style={{ border: '1px solid rgb(235, 237, 240)', backgroundColor: "#ffffff" }}
              onBack={() => this.props.history.goBack()}
              avatar={{ src: IbesLogo }}
              title={`Job #${jobDetail.pk}`}
              extra={
                <>
                  <Button
                    type="primary"
                    icon="profile"
                    onClick={() => history.push("/job-management/job-queues")}
                  >
                    Job Queues
                  </Button>
                  <Button
                    type="primary"
                    icon="book"
                    onClick={() => history.push("/job-management/jobs")}
                  >
                    Jobs
                  </Button>
                  <Button
                    type="danger"
                    icon="logout"
                    onClick={() => this.onLogout()}
                  >
                    Logout
                  </Button>
                </>
              }
              subTitle={jobDetail.name}
            />
            {/* <Menu
              onClick={this.handlemenuClick}
              mode="horizontal"
              selectedKeys={[
                Lib.getMenuUrl(location.pathname, undefined, 'stats')
              ]}
            >
              <Menu.Item
                key="stats"
                onClick={() => history.push("stats")}
              >
                <Icon type="pie-chart" />
                <span>Detail</span>
              </Menu.Item>
              <Menu.Item
                key="job-history"
                onClick={() => history.push("job-history")}
              >
                <Icon type="history" />
                <span>Job History</span>
              </Menu.Item>
            </Menu> */}
          </>
        </Affix>
        <Row style={{ background: '#fff', borderBottom: '1px solid #ebedf0', padding: '12px 24px' }}>
          <Col md={12}>
            <Descriptions column={2} className="ant-desc-custom" layout="vertical">
              <Descriptions.Item label="Operating Branch">{get(jobDetail, 'operating_branch.name', '-')}</Descriptions.Item>
              <Descriptions.Item label="Branch of Origin">{get(jobDetail, 'origin_branch.name', '-')}</Descriptions.Item>
              <Descriptions.Item label="Received">{jobDetail.received}</Descriptions.Item>
              <Descriptions.Item label="Merchant">{get(jobDetail, 'merchant.name', '-')}</Descriptions.Item>
              <Descriptions.Item label="Builder">{Lib.fillEmpty(jobDetail.builder)}</Descriptions.Item>
              <Descriptions.Item label="Seed Attachment">
                {this.renderSeedAttachment(jobDetail.seed_file && [jobDetail.seed_file])}
              </Descriptions.Item>
            </Descriptions>
          </Col>
          <Col md={8}>
            <Row>
              <Col md={12}>
                <span className="text-muted font-md mb-3">
                  {jobDetail.status === 'closed' ? 'Last Queue at' : 'Branch Queue'}
                </span>
                <div>
                  <Icon className="font-2xl mr-1" type="home" theme="twoTone" />
                  <span className="font-xl">{get(jobDetail, 'job_queue.name', '-')}</span>
                </div>
              </Col>
              <Col md={12}>
                <span className="text-muted font-md mb-3">Person</span>
                <div>
                  {jobDetail.owner && (
                    <Icon
                      className="font-2xl mr-1"
                      type="smile"
                      theme="twoTone"
                      twoToneColor="#eb2f96"
                    />
                  )}
                  <span className="font-xl">{get(jobDetail, 'owner.full_name', '-')}</span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="mt-3" md={12}>
                <span className="text-muted font-md mb-3">Status</span>
                <div>
                  <Tag color={this.statusColor[get(jobDetail, 'status', 'unknown').toLowerCase()]}>
                    <b>
                      <Icon className="mr-1" type={this.getStatusIcon(jobDetail.status)} />{' '}
                      {startCase(jobDetail.status)}
                    </b>
                  </Tag>
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={4} className="text-center" xs={12}>
            <img src={workLaptop} alt="content" className="w-100" />
          </Col>
        </Row>
        <div style={{ padding: '12px' }}>
          <JobHistory {...this.props} />
        </div>
      </Layout>
    )
  }
}

export default JobDetail
