export default {
  "app_label": "jobs",
  "model_name": "tag",
  "pagination": true,
  "columns": [
    {
      "name": "code",
      "label": "Code",
      "search": true,
      "sort": true
    },
    {
      "name": "name",
      "label": "Name",
      "search": true,
      "sort": true
    },
    {
      "name": "excerpt",
      "label": "Excerpt",
      "search": false,
      "sort": false
    },
    {
      "name": "modified",
      "label": "Modified",
      "data_type": "datetime",
      "search": false,
      "sort": true
    }
  ],
  "filter_by": [
    {
      "label": "Show in csv",
      "name": "show_in_csv",
      "type": "switch"
    },
    {
      "label": "Show in pdf",
      "name": "show_in_pdf",
      "type": "switch"
    }
  ],
  "export_import": null
}