const module = 'admin'
const app = 'reports'

export default {
  'api:admin:merchants:mappingmode:list': `/${module}/${app}/mapping-modes`,
  'api:admin:merchants:mappingmode:detail': `/${module}/${app}/mapping-modes/:id`,

  'api:admin:reports:mmsecurity:list': `/${module}/${app}/mm-securities`,
  'api:admin:reports:mmsecurity:detail': `/${module}/${app}/mm-securities/:id`,

  'api:admin:merchants:skumapping:list': `/${module}/${app}/sku-mappings`,
  'api:admin:merchants:skumapping:detail': `/${module}/${app}/sku-mappings/:id`,

  'api:admin:reports:specialmessage:list': `/${module}/${app}/special-messages`,
  'api:admin:reports:specialmessage:detail': `/${module}/${app}/special-messages/:id`,
}