import React from "react";
import PropTypes from "prop-types";
import {
  groupBy,
  snakeCase,
  maxBy,
  startCase,
  isEmpty,
  isEqual,
  isBoolean,
  intersection,
  differenceWith,
  find,
  get,
  isString,
  cloneDeep,
  orderBy,
} from "lodash";
import { withRouter } from "react-router-dom";
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Icon,
  message,
  Popconfirm,
  Radio,
  Row,
  Select,
  Table,
  Tooltip,
  Typography,
} from "antd";
import FileDownload from "js-file-download";
import queryString from "query-string";
import styled from "styled-components";

import { RequestV2 as Request, Lib, Modules } from "App/Utils";
import Lib2 from "./Singlepage/Lib2";
import moment from "moment";
import Filter from "./Singlepage/AdvancedFilter";
import ModalImport from "./ModalImport";

const validate = require("validate.js");
const { Option } = Select;
const KEY_EXPORT = "EXPORT";

class SubAllList extends React.Component {
  constructor(props) {
    super(props);
    this.groupBy = null;
    this.page = 1;
    this.search = [];
    this.parsedQuery = queryString.parse(this.search);
    this.pageSizeOptions = this.props.pageSizeOptions;
    this.initialState = {
      total: 0,
      expanded: [],
      allExpander: [],
      onfilter: false,
      searchTag: [],
      iconSorting: [{ name: "start", sorted: "no-sort" }],
      count: 0,
      page: 0,
      count_row: 0,
      current: 0,
      pagination: true,
      limit: 25,
      selectedRowKeys: [],
      isLoading: false,
      viewAs:
        get(
          get(props, "panelView", []).find((val) => val.active),
          "value"
        ) || "list",
      records: [],
    };
    this.state = {
      ...this.initialState,
      // hanya stete yang saya tau saja yang saya pindah dari initial state
      filter: this.parsedQuery.fields ? this.parsedQuery.fields.split(",") : [],
      groupBy: this.groupBy,
      filter_by: [],
      // Permission
      allowAdd: props.permissions.find((code) => code.indexOf(".add_") !== -1),
      allowRead: props.permissions.find(
        (code) => code.indexOf(".view_") !== -1
      ),
      allowEdit: props.permissions.find(
        (code) => code.indexOf(".change_") !== -1
      ),
      allowDelete: props.permissions.find(
        (code) => code.indexOf(".delete_") !== -1
      ),
      // ----------
    };
  }

  onSearch = (val) => {
    this.page = 1;
    this.setState(
      {
        searchTag: val,
      },
      () => {
        this.read();
      }
    );
  };

  onSizeChange = (e, size) => {
    this.setState(
      {
        limit: size,
        current: e,
      },
      () => this.read()
    );
  };

  onGroupByChange = (val, s) => {
    this.groupBy =
      val.key !== "-"
        ? {
            no_data: s.props.no_data,
            dataIndex: s.props.dataindex,
            startcase: s.props.startcase,
            ...val,
          }
        : null;
    const obj = { selectedRowKeys: [], expanded: [] };
    if (val.key !== "-") {
      obj.iconSorting = Lib2.forceOrder(val.key, [...this.state.iconSorting]);
    }
    this.setState(obj, () => this.read());
  };

  bizList(jobs) {
    const [p] = [this.props];
    if (p.masterKey === "jobs") {
      for (const i of jobs) {
        if (i.commercial && i.tender) i.biztype = "Tender & Commercial";
        else if (i.commercial) i.biztype = "Commercial";
        else if (i.tender) i.biztype = "Tender";
        else if (i.double_up) i.biztype = "Double up";
        else i.biztype = "Job";
        if (i.received) {
          if (this.groupBy)
            i.received = moment(i.received).format("YYYY-MM-DD");
          else i.received = moment(i.received).format("lll");
        }
      }
    }
    return jobs;
  }

  getParams = () => {
    const p = this.props;
    const s = this.state;
    let ordering = [];
    let chars = orderBy(s.iconSorting, ["priority"], ["asc"]);
    if (chars.length > 0) {
      for (let orderData of chars) {
        if (orderData.priority > 0) {
          if (orderData.sorted === "asscend") {
            ordering.push(snakeCase(orderData.name));
          } else {
            ordering.push("-" + snakeCase(orderData.name));
          }
        }
      }
    }

    let filterParam = localStorage.getItem(p.masterKey);
    if (filterParam) {
      filterParam = Lib2.AarrayObjectToString(JSON.parse(filterParam), "key");
    } else filterParam = [];

    let params = {
      ...p.readParams,
      page: this.page,
      search: s.searchTag.join(","),
      ordering: ordering.join(",") ? ordering.join(",") : "-modified",
      fields: s.filter.join(","),
      limit: s.limit,
    };

    if (s.onfilter) {
      filterParam.forEach((value, index, arr) => {
        params[value.name] =
          isBoolean(value.data) || isString(value.data)
            ? value.data
            : value.data.join("|");
      });
    }
    return params;
  };

  read = () => {
    const p = this.props;
    const s = this.state;
    let ordering = [];
    let chars = orderBy(s.iconSorting, ["priority"], ["asc"]);
    if (chars.length > 0) {
      for (let orderData of chars) {
        if (orderData.priority > 0) {
          if (orderData.sorted === "asscend") {
            ordering.push(snakeCase(orderData.name));
          } else {
            ordering.push("-" + snakeCase(orderData.name));
          }
        }
      }
    }

    let filterParam = localStorage.getItem(p.masterKey);
    if (filterParam) {
      filterParam = Lib2.AarrayObjectToString(JSON.parse(filterParam), "key");
    } else filterParam = [];

    let params = {
      ...p.readParams,
      page: this.page,
      search: s.searchTag.join(","),
      ordering: ordering.join(",") ? ordering.join(",") : "-modified",
      fields: s.filter.join(","),
      limit: s.limit,
    };

    if (s.onfilter) {
      filterParam.forEach((value, index, arr) => {
        const currentFilter = s.filter_by.find(filter => filter.name === value.name)

        params[value.name] =
          isBoolean(value.data) || isString(value.data)
            ? value.data
            : value.data.join(get(currentFilter, 'separator', "|"));
      });
    }

    this.setState(
      {
        isLoading: true,
      },
      () =>
        Request(
          "GET",
          `${p.masterKey}-read`,
          {},
          params,
          [...p.args],
          (res, ex) => {
            if (this.props.extraCount) this.props.extraCount(res.data.count);
            let results = this.bizList(res.data.results);
            let groupByRes = {};
            let expanded = [];

            for (const key in results) {
              const a = results[key];
              a.avatar = p.getAvatar
                ? p.getAvatar(a, key, results)
                : this.getAvatar(
                    get(a, p.avatar) || "",
                    get(a, p.srcAvatar),
                    a
                  );
            }

            if (this.groupBy) {
              groupByRes = groupBy(res.data.results, get(this.groupBy, "key"));
              results = [];

              for (const k in groupByRes) {
                let key = k;
                switch (k) {
                  case "null":
                  case "undefined":
                    key = this.groupBy.no_data
                      ? this.groupBy.no_data
                      : `No ${this.groupBy.label}`;
                    break;
                  case "true":
                    key = "YES";
                    break;
                  case "false":
                    key = "NO";
                    break;
                  default:
                    break;
                }

                if (this.groupBy.startcase) key = startCase(k);
                results.push({
                  [this.groupBy.dataIndex]: key,
                  pk: k,
                  children: groupByRes[k],
                });
                expanded.push(k);
              }
            }

            this.setState({
              allExpander: expanded,
              expanded,
              count: res.data.count,
              count_row: results.length,
              records: results,
              total: res.data.count,
              current: res.data.current,
              limit: res.data.limit,
              groupBy: this.groupBy,
              isLoading: false,
            });
            p.onReadSuccess(res, ex);
          },
          (err, ex) => {
            Lib.errorMessageHandler(get(err, "response"));
            this.setState(this.initialState);
            // this.setState({isLoading: false})
            // p.onReadFailed(err, ex);
          },
          p.readExtra
        )
    );
  };

  delete(item) {
    const p = this.props;

    this.setState(
      {
        isLoading: true,
      },
      () => {
        Request(
          "DELETE",
          `${p.masterKey}-delete`,
          {},
          {},
          [...p.args, item],
          (res, ex) => {
            this.read();
            message.success("Item has been deleted.");
            p.onDeleteSuccess(res, ex);
          },
          (err, ex) => {
            this.setState({ isLoading: false });
            Lib.errorMessageHandler(get(err, "response"));
            p.onDeleteFailed(err, ex);
          },
          item
        );
      }
    );
  }

  renderCountCard() {
    const { totalOptions } = this.props;
    const el = [];

    if (validate.isEmpty(totalOptions)) return null;

    const ret = totalOptions(this.state);
    for (let i = 0; i < ret.length; i++) {
      el.push(
        <Col
          key={ret[i].title}
          xs={24}
          sm={Math.floor(22 / ret.length)}
          className="text-center"
        >
          <span className="text-muted font-lg mb-3">{ret[i].title}</span>
          <div>
            <span className="font-4xl">{ret[i].total}</span>
          </div>
        </Col>
      );
      if (i !== ret.length - 1) {
        el.push(
          <Col key={`div${i}`} xs={0} sm={1} className="text-center">
            <Divider type="vertical" style={{ height: "4.5rem" }} />
          </Col>
        );
      }
    }
    return (
      <Col>
        <Card className="mb-2">
          <Row>{el}</Row>
        </Card>
      </Col>
    );
  }

  componentDidMount() {
    const p = this.props;
    if (p.groupByOptions) {
      let g = find(p.groupByOptions, { default: true });
      if (g) this.groupBy = g;
    }
    if (p.descriptor) this.descriptor({ data: p.descriptor });
    else this.readColumns();
    if (p.onRef) {
      p.onRef(this);
    }
  }

  readColumns = () => {
    const { masterKey, userReducer } = this.props;
    if (masterKey) {
      this.request = Request(
        "get",
        masterKey + "-service",
        {},
        {},
        [],
        this.descriptor,
        this.readFailedcolumn
      );
    }
  };

  readFailedcolumn = (err) => {
    console.log('err', err)
    message.error(JSON.stringify(get(err.response.data, 'detail', err.response.data)))
    this.setState({
      loading: false,
    });
  };

  descriptor = (r) => {
    let columns = [];
    let operation = true;
    if (this.props.operation === false) operation = this.props.operation;
    let iconSorting = [];
    let identifier = "pk";
    if (r.data.columns) {
      columns = [...r.data.columns];
      for (let i of columns) {
        i.dataIndex = i.name;
        delete i.ellipsis;
        i.className = "col-ellipsis-x";
        if (i.child_path) i.dataIndex = `${i.name}.${i.child_path}`;
        i.title = i.label;
        if (i.search) {
          i.filters = [{ text: i.title, value: i.name }];
          i.filteredValue = [];
          i.filterDropdownVisible = false;
        }
        if (i.sort) {
          iconSorting.push({
            name: i.name,
            sorted: i.default_sorting || "no-sort",
            priority: i.default_sorting_priority || 0,
          });
        }
      }
      const z = r.data.columns.find((e) => e.identifier);
      if (z) identifier = z.name;
    }
    columns.push({
      className: "col-ellipsis-x",
      label: "Operation",
      title: "Operation",
      name: "operation",
      fixed: operation,
    });

    columns.unshift({
      label: "#",
      title: "#",
      hidden: false,
      name: "avatar",
      dataIndex: "avatar",
      isAvatar: true,
    });

    this.setState(
      {
        filter_by: r.data.filter_by,
        cols: columns,
        pagination: r.data.pagination,
        identifier,
        iconSorting,
        loading: true,
      },
      () => this.state.viewAs === "list" && this.read()
    );
  };

  setLoading = (val) => {
    this.setState({ loading: val });
  };

  filterIsEmpty = () => {
    this.setLoading(false);
  };

  sortChange = (columns, clearSorting = false) => {
    this.setLoading(true);
    let s = this.state.iconSorting;
    let checkData = s.findIndex((item) => item.name === columns);
    let prevPriority = maxBy(s, "priority");
    if (Lib2.getNexticon(s[checkData].sorted) === "no-sort" || clearSorting) {
      for (let data of s) {
        if (data.priority > s[checkData].priority) {
          data.priority -= 1;
        }
      }
      s[checkData].priority = 0;
    } else if (s[checkData].priority === 0) {
      s[checkData].priority = prevPriority.priority + 1;
    }
    if (clearSorting) {
      s[checkData].sorted = "no-sort";
    } else {
      s[checkData].sorted = Lib2.getNexticon(s[checkData].sorted);
    }
    this.setState(
      {
        s,
      },
      () => this.read()
    );
  };

  onDetailClick = (data) => {
    this.props.history.push(
      `${this.props.match.path}/${data[this.state.identifier]}`
    );
  };

  operationUI = (record, p, s) => {
    const delete_not_allowed = get(p, "delete_not_allowed", false);
    return (
      <Row>
        <Col>
          <Button
            type="primary"
            icon={s.allowEdit ? "control" : "read"}
            onClick={(e) => {
              e.stopPropagation();
              p.onDetailClick
                ? p.onDetailClick(record[s.identifier], record)
                : this.onDetailClick(record);
            }}
          />
          {s.allowDelete && (
            <Popconfirm
              trigger="click"
              title="Are you sure ?"
              disabled={delete_not_allowed}
              onConfirm={(event) => {
                event.stopPropagation();
                p.onDeleteClick
                  ? p.onDeleteClick(record[s.identifier])
                  : this.delete(record[s.identifier]);
              }}
              onCancel={(event) => event.stopPropagation()}
            >
              <Button
                type="danger"
                className="ml-1"
                disabled={delete_not_allowed}
                icon="delete"
                onClick={(event) => event.stopPropagation()}
              />
            </Popconfirm>
          )}
        </Col>
      </Row>
    );
  };

  renderUI = (x, i, s, p, r) => {
    let zeroCols = { props: { colSpan: 0 } };
    if (x[i].data_type === "bool") {
      if (!x[i].render || this.groupBy)
        x[i].render = (text, z) => {
          if (!z.children)
            return (
              <Row>
                <Col>
                  <span>{Modules.renderStatusAIU(text)}</span>
                </Col>
              </Row>
            );
          return zeroCols;
        };
    } else if (x[i].start_case) {
      if (!x[i].render || this.groupBy)
        x[i].render = (text, i) => startCase(text);
    } else if (x[i].data_type === "datetime") {
      if (!x[i].render || this.groupBy)
        x[i].render = (text, z) => {
          if (!z.children) {
            if (text) return moment(text).format("lll");
            return "-";
          }
          return zeroCols;
        };
    } else if (x[i].name === "operation") {
      if (this.props.operation !== false) {
        if (!this.groupBy && r > 6) x[i].fixed = "right";
        else x[i].fixed = false;
      }
      if (!x[i].render || this.groupBy)
        x[i].render = (e, z) => {
          if (!z.children) return this.operationUI(e, p, s);
          return zeroCols;
        };
    } else if (x[i].dataIndex && !x[i].isAvatar) {
      if (!x[i].render || this.groupBy)
        x[i].render = (text, z) => {
          if (!z.children) return { children: text, props: { colSpan: 1 } };
          return zeroCols;
        };
    }
  };

  generateColumns = (col, s) => {
    const p = this.props;
    let x = col.filter((e) => !e.hidden);
    for (let i in x) {
      if (x[i].search) {
        x[i].onFilterDropdownVisibleChange = () => {
          this.setState(
            {
              loading: true,
              filter: Lib.filterClick(x, s.filter, i, x[i].name),
            },
            () => (s.searchTag.length > 0 ? this.read() : this.filterIsEmpty())
          );
        };
      }
      const title = x[i].label;
      let idx = s.iconSorting.findIndex((val) => val.name === x[i].name);
      if (x[i].sort && idx !== -1) {
        x[i].title = Lib2.getIcon(s.iconSorting, idx, this.sortChange, title);
      }
      if (this.groupBy && x[i].dataIndex === this.groupBy.dataIndex) {
        x[i].render = (e, s, i) => {
          const obj = {
            children: e,
            props: { colSpan: 1 },
          };
          if (s.children) {
            obj.children = (
              <Typography.Text className="group-title">{e}</Typography.Text>
            );
            obj.props.colSpan = x.length - 1;
          }
          return obj;
        };
      } else {
        this.renderUI(x, i, s, p, x.length - 1);
      }
    }
    return x;
  };

  handleDeselect = (data) => {
    const { cols, filter, searchTag } = this.state;
    let tag = cloneDeep(searchTag);
    for (let i = 0; i < filter.length; i++) {
      if (filter[i] === data) {
        filter.splice(i, 1);
      }
    }
    for (let i of cols) {
      if (i.filteredValue && i.filteredValue[0] === data) {
        i.filteredValue = [];
      }
    }
    this.setState(
      (s) => ({
        filter,
        searchTag: s.searchTag.filter((val) => val !== data),
      }),
      () => {
        if (!isEmpty(tag)) {
          this.setLoading(true);
          this.read();
        }
      }
    );
  };

  handleTableChange = (pagination, filters, ordering) => {
    this.nextState = Lib.onTableChange(pagination, filters, ordering);
    const limit = pagination.pageSize;
    this.page = this.nextState.page;
    this.setState({ current: this.page, limit, loading: true }, () => {
      this.read();
    });
  };

  getAvatar(item, srcAvatar, record) {
    if (item || typeof item === "string") {
      const nameSplited = item.split(" ").filter((w) => w);
      let initial = "";
      const background =
        get(record, this.props.avatarColorKey || "color") ||
        Lib.getRandomColor();
      for (const name of nameSplited) {
        // check a character is valid letter
        if (/[A-Za-z0-9]/.test(name.charAt(0))) {
          initial += name.charAt(0).toUpperCase();
          if (initial.length > 2) {
            break;
          }
        }
      }

      return (
        <Tooltip placement="topLeft" title={item}>
          <Avatar
            src={srcAvatar}
            shape="square"
            size="large"
            style={{ background }}
          >
            {initial || "-"}
          </Avatar>
        </Tooltip>
      );
    }
  }

  renderFiltertag() {
    const { filter } = this.state;
    return filter.map((data, key) => (
      <StyledFilter
        style={{ background: "#28a745", color: "#fff" }}
        key={key}
        onClick={() => this.handleDeselect(data)}
      >
        {data.replace(/_/g, " ")}
        <Icon
          type="close-circle"
          style={{ marginLeft: "5px" }}
          theme="filled"
        />
      </StyledFilter>
    ));
  }

  onSelectChange = (selectedRowKeys, val) => {
    this.generateSelect(selectedRowKeys);
  };

  childrenConstructor = (data, inc, diff, record) => {
    let destroy, build, f;
    let arr = [];
    for (const y in record) {
      for (const x of record[y].children)
        if (x.pk === diff) {
          f = y;
          break;
        }
    }
    for (const i of record[f].children) {
      if (i.pk === diff) {
        if (inc) {
          let a = data.find((w) => w === record[f].pk);
          if (!a) build = record[f].pk;
        } else {
          let a = data.findIndex((w) => w === record[f].pk);
          if (a >= 0) destroy = a;
        }
      } else {
        arr.push(i.pk);
      }
    }

    const s = intersection(arr, data);
    if (s.length === 0) {
      if (inc && build !== undefined) data.push(build);
      else if (!inc && destroy !== undefined)
        data[destroy] = { isDeleted: true };
    }
  };

  generateSelect = (e) => {
    if (this.groupBy) {
      const { records, selectedRowKeys } = this.state;
      let inc = true;
      if (e.length < selectedRowKeys.length) inc = false;
      let diff = differenceWith(e, selectedRowKeys, isEqual);
      if (!inc) diff = differenceWith(selectedRowKeys, e, isEqual);
      if (!isEmpty(diff)) {
        let fData = find(records, { pk: diff[0] });
        if (fData) {
          if (inc) {
            for (let x of fData.children) {
              let s = e.find((r) => r === x.pk);
              if (!s) e.push(x.pk);
            }
          } else
            for (let x of fData.children) {
              const r = e.findIndex((s) => s === x.pk);
              if (r >= 0) e[r] = { isDeleted: true };
            }
        } else this.childrenConstructor(e, inc, diff[0], records);
        e = e.filter((i) => !i.isDeleted);
      }
    }
    this.setState({ selectedRowKeys: e });
  };

  filterSwitch = () => {
    this.page = 1;
    this.setState(
      {
        loading: true,
        onfilter: !this.state.onfilter,
      },
      () => this.read()
    );
  };

  setDrawer = (val) => {
    this.setState({
      visibledrawer: val,
    });
  };

  handleChange = (nextTargetKeys, name) => {
    this.page = 1;
    const p = { url_name: this.props.masterKey };
    const { onfilter } = this.state;
    let es = Lib2.sessionMaster(nextTargetKeys, name, p, onfilter);
    if (es) {
      this.setLoading(true);
      this.read();
    }
  };

  expander = () => {
    const s = this.state;
    this.setState({ expanded: isEmpty(s.expanded) ? s.allExpander : [] });
  };

  onExpandedRowsChange = (expanded) => {
    this.setState({
      expanded,
    });
  };

  setIcon = (r) => {
    if (get(r, "record.children")) {
      if (!r.expanded)
        return <Icon type="caret-right" width="4em" className="text-danger" />;
      return <Icon type="caret-down" width="4em" className="text-success" />;
    }
  };

  onPanel = (e) => {
    const [p] = [this.props];
    this.setState({ viewAs: e.target.value }, () => {
      if (e.target.value !== "list") {
        p.onClickView();
      } else {
        this.groupBy = null;
        this.read();
      }
    });
  };

  panelView = (os) => {
    const def = find(os, { active: true });
    return (
      <Radio.Group
        onChange={this.onPanel}
        size="large"
        defaultValue={get(def, "value", "")}
        buttonStyle="solid"
      >
        {os.map((i, key) => (
          <Radio.Button key={key} value={i.value}>
            {" "}
            <Icon type={i.icon} /> {i.label}
          </Radio.Button>
        ))}
      </Radio.Group>
    );
  };

  getRowProps = (record, index) => {
    return !record.children
      ? {
          className: index % 2 === 1 ? "odd" : "",
          onClick: () =>
            this.props.onDetailClick
              ? this.props.onDetailClick(record[this.state.identifier], record)
              : this.onDetailClick(record),
        }
      : {
          className: "row-group",
        };
  };

  onImportClick = () => {
    this.setState({ visibleImport: true });
  };

  onHideImport = () => {
    this.setState({ visibleImport: false });
  };

  onExportClick = () => {
    const params = { ...this.getParams() };
    delete params.page;
    delete params.limit;
    message.loading({
      content: `Downloading ${get(this.props, "pageTitle", "data")}...`,
      key: KEY_EXPORT,
      duration: 0,
    });
    this.isDownloading = true;

    Request(
      "get",
      this.props.urlKeyExport,
      {},
      params,
      [],
      this.exportSuccess,
      this.exportFailed
    );
  };

  exportSuccess = (response) => {
    const filename = Lib.getFilenameDisposition(
      response.headers,
      `Export ${this.props.pageTitle} ${moment().format("YYYY-MM-DD")}.csv`
    );
    FileDownload(response.data, filename);
    this.isDownloading = false;
    message.success({ key: KEY_EXPORT, content: "CSV file has been exported" });
  };

  exportFailed = () => {
    this.isDownloading = false;
    message.error({ key: KEY_EXPORT, content: "Failed to export CSV file" });
  };

  componentWillUnmount() {
    if (this.isDownloading) {
      message.destroy();
      message.info("Download was cancelled");
    }
    if (this.props.onRef) {
      this.props.onRef(undefined);
    }
    // window stop in Base List Submodule
  }

  render() {
    const s = this.state;
    const p = this.props;
    let cols = s.cols;
    if (cols) {
      cols = this.generateColumns(cols, s);
    }

    // const rowSelection = {
    //   selectedRowKeys: s.selectedRowKeys,
    //   onChange: this.onSelectChange,
    // };
    let url_name = p.masterKey;
    let expanded = {};
    if (s.groupBy)
      expanded = { expandedRowKeys: s.expanded, expandIcon: this.setIcon };
    return (
      <Row className="animated fadeIn mb-3">
        {p.renderCountCard ? p.renderCountCard() : this.renderCountCard()}
        <Col>
          <Card>
            <Row
              className="mb-4"
              type="flex"
              justify="space-between"
              gutter={[12, 12]}
            >
              <Col>
                <Button.Group>
                  <Button
                    type="dashed"
                    size="large"
                    style={{ fontWeight: "bold", cursor: "default" }}
                  >
                    {Lib.fillEmpty(p.pageTitle, startCase(p.masterKey))}
                  </Button>
                  {!p.disableAddButton && (
                    <Button
                      icon="plus"
                      type="primary"
                      size="large"
                      disabled={!s.allowAdd}
                      onClick={() =>
                        p.onAddClick
                          ? p.onAddClick()
                          : p.history.push(`${p.match.path}/add`)
                      }
                    >
                      Add {Lib.fillEmpty(p.pageTitle, startCase(p.masterKey))}
                    </Button>
                  )}
                </Button.Group>
              </Col>
              <Col>
                <Row type="flex" gutter={[12, 12]}>
                  <Col>{p.panelView && this.panelView(p.panelView)}</Col>
                  {p.importSetting && (
                    <Col>
                      <Button
                        icon="vertical-align-top"
                        size="large"
                        type="primary"
                        onClick={this.onImportClick}
                      >
                        Import
                      </Button>
                    </Col>
                  )}
                  {p.urlKeyExport && (
                    <Col>
                      <Button
                        icon="vertical-align-bottom"
                        size="large"
                        type="primary"
                        onClick={this.onExportClick}
                      >
                        Export
                      </Button>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
            <Divider className="my-3" />
            <Row></Row>
            {s.viewAs === "list" ? (
              <>
                <Row
                  className="mb-2"
                  type="flex"
                  justify="space-between"
                  gutter={[12, 12]}
                >
                  <Col>
                    {p.groupByOptions.length > 0 && (
                      <Row type="flex" align="middle" gutter={[6, 6]}>
                        <Col>
                          <span className="font-weight-bold">Group by :</span>
                        </Col>
                        <Col>
                          <Select
                            labelInValue
                            className="min-w-3"
                            size="default"
                            defaultValue={
                              p.groupByOptions.find((g) => g.default) || {
                                key: "-",
                              }
                            }
                            onChange={this.onGroupByChange}
                          >
                            <Option key="-" value="-">
                              No Grouping
                            </Option>
                            {p.groupByOptions.map((g) => (
                              <Option
                                key={g.dataIndex}
                                no_data={g.no_data}
                                startcase={g.startcase}
                                dataindex={g.dataIndex}
                                value={g.key}
                              >
                                {g.label}
                              </Option>
                            ))}
                          </Select>
                        </Col>
                        <Col>
                          {s.groupBy && (
                            <Button
                              icon={
                                !isEmpty(s.expanded)
                                  ? "caret-down"
                                  : "caret-right"
                              }
                              type="dashed"
                              onClick={() => this.expander()}
                            >
                              {!isEmpty(s.expanded)
                                ? "Collapse all"
                                : "Expand all"}
                            </Button>
                          )}
                        </Col>
                      </Row>
                    )}
                  </Col>
                  <Col>
                    <Row type="flex" justify="end" gutter={[12, 12]}>
                      <Col className="text-center">
                        <Button
                          block
                          icon={s.isLoading ? "loading" : "reload"}
                          disabled={s.isLoading}
                          type="dashed"
                          size="default"
                          // className="mb-2"
                          onClick={() => {
                            this.props.onReload();
                            this.read();
                          }}
                        >
                          Reload
                        </Button>
                      </Col>
                      {get(s, "filter_by", []).length > 0 && (
                        <Col className="text-center">
                          <Button.Group>
                            <Button
                              onClick={() => this.setDrawer(true)}
                              type={s.onfilter ? "primary" : "default"}
                            >
                              <Icon type="setting" /> Advance filter
                            </Button>
                            <Button
                              onClick={() => this.filterSwitch()}
                              type="dashed"
                            >
                              <Checkbox checked={s.onfilter} />
                            </Button>
                          </Button.Group>
                        </Col>
                      )}
                      <Col style={{ maxWidth: "242px" }}>
                        <Select
                          className="text-center"
                          mode="tags"
                          tokenSeparators={[","]}
                          onChange={(value) => this.onSearch(value)}
                          dropdownStyle={{ display: "none" }}
                          value={s.searchTag}
                          style={{
                            width: "230px",
                            borderTopRightRadius: "0px",
                          }}
                          placeholder="Search"
                          suffixIcon={<Icon type="setting" />}
                          onDeselect={(key) => this.handleDeselect(key)}
                        />
                        <br />
                        {!isEmpty(s.filter) && (
                          <StyledSpanNoSelect>
                            Search by field: {this.renderFiltertag()}
                          </StyledSpanNoSelect>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Table
                  {...expanded}
                  className="custom-table custom-table-sm"
                  columns={cols}
                  onExpandedRowsChange={this.onExpandedRowsChange}
                  expandRowByClick={true}
                  dataSource={s.records}
                  rowKey={(data) => data.pk}
                  scroll={{ x: true }}
                  // rowSelection={rowSelection}
                  onChange={this.handleTableChange}
                  loading={s.isLoading}
                  pagination={
                    s.pagination
                      ? {
                          pageSizeOptions: this.pageSizeOptions,
                          showSizeChanger: true,
                          // onShowSizeChange: this.onSizeChange,
                          showQuickJumper: true,
                          total: s.count,
                          current: s.current,
                          pageSize: s.limit,
                          hideOnSinglePage: false,
                        }
                      : false
                  }
                  onRow={this.getRowProps}
                  footer={() =>
                    Modules.infoTotalRow(
                      s.count_search,
                      s.count_row,
                      s.count,
                      this.groupBy,
                      s.records
                    )
                  }
                />
              </>
            ) : (
              p.viewComponent()
            )}
            <Filter
              visibledrawer={s.visibledrawer}
              onChildrenDrawerClose={() => this.setDrawer(false)}
              filter={s.filter_by}
              userReducer={p.userReducer}
              getFilterParams={() => localStorage.getItem(url_name)}
              onfilter={s.onfilter}
              handleChange={this.handleChange}
              setonfilter={this.filterSwitch}
            />
          </Card>
        </Col>
        {p.importSetting && (
          <ModalImport
            metaList={p.importSetting}
            visible={s.visibleImport}
            toggle={this.onHideImport}
          />
        )}
      </Row>
    );
  }
}

SubAllList.propTypes = {
  masterKey: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  totalOptions: PropTypes.func,
  extraCount: PropTypes.func,
  pageTitle: PropTypes.any,
  panelView: PropTypes.array,
  onAddClick: PropTypes.func,
  descriptor: PropTypes.object,
  disableAddButton: PropTypes.bool,
  pageSizeOptions: PropTypes.array,
  // userReducer: PropTypes.object.isRequired,
  permissions: PropTypes.array,
  args: PropTypes.array,
  onReload: PropTypes.func,
  onRef: PropTypes.func,
};

SubAllList.defaultProps = {
  readParams: {},
  readExtra: {},
  args: [],
  groupByOptions: [],
  onReadSuccess: () => null,
  extraCount: () => null,
  onReadFailed: () => null,
  onDeleteSuccess: () => null,
  onDeleteFailed: () => null,
  avatar: "name",
  pageSizeOptions: ["25", "50", "75", "100"],
  permissions: [],
  onReload: () => null,
  onRef: () => null,
};

export default withRouter(SubAllList);

const StyledFilter = styled.span`
  background-color: #1690ff;
  padding: 2px 4px;
  margin: 2px;
  font-size: 10px;
  border-radius: 4px;
  color: #fff;
  display: inline-block;
  cursor: pointer;
`;

const StyledSpanNoSelect = styled.span`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
