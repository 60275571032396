import React from 'react'
import PropTypes from 'prop-types'
import { Button, Calendar, DatePicker, Col, Icon, Radio, Row, Spin } from 'antd'
import { get } from 'lodash'
import moment from 'moment'
import { Lib, RequestV2 as Request } from 'App/Utils'
import PageError from 'App/Component/PageError'

const { MonthPicker } = DatePicker
const KEYS_MOMENT = {month: 'months', year: 'years'}

class HolidayCalendarView extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      currentDate: moment(),
      objData: null,
      errorResponse: null,
      isLoading: true,
      isReloading: false,
      mode: 'month',
    }
  }

  read = (keyLoading = 'isLoading', callback = () => null) => {
    this.setState({ [keyLoading]: true }, () => {
      Request(
        'get',
        'module-branch-holiday-read',
        {},
        {no_paging: true},
        [],
        this.readSuccess,
        this.readFailed,
        { keyLoading, callback }
      )
    })
  }

  readSuccess = (response, extra) => {
    const objData = this.getObjData(response.data)

    this.setState({ [extra.keyLoading]: false, objData, errorResponse: null }, extra.callback)
  }

  readFailed = (error, extra) => {
    if (extra.keyLoading === 'isReloading' && this.state.objData) Lib.errorMessageHandler(get(error, 'response'))
    this.setState({ [extra.keyLoading]: false, errorResponse: get(error, 'response') }, extra.callback)
  }

  onReload = () => {
    this.read('isReloading')
  }

  // responseData = [{ date, name }, ...]
  getObjData = (responseData) => {
    // will return object with grouped by month
    // example response:
    // response = {
    //   2020-10: {
    //     2020-10-01: { date, name },
    //     2020-10-02: { date, name },
    //     2020-10-19: { date, name },
    //     _getArr: () => [{2020-10-01: object}, {2020-10-02: object}, ...]
    //   },
    //   2020-12: ...
    // }

    const objData = {}
    for (let i = 0; i < responseData.length; i += 1) {
      const monthLabel = moment(responseData[i].date).format('YYYY-MM')
      const dateLabel = moment(responseData[i].date).format('YYYY-MM-DD')
      if (objData[monthLabel]) {
        if (!objData[monthLabel][dateLabel]) {
          objData[monthLabel][dateLabel] = [responseData[i]]
        } else {
          objData[monthLabel][dateLabel].push(responseData[i])
        }
      } else {
        objData[monthLabel] = {
          [dateLabel]: [responseData[i]]
        }
      }
    }
    return objData
  }

  // options = { value: moment, type: string, onChange: f(), onTypeChange: f() }
  headerRender = (options) => {
    const { currentDate, isReloading, mode } = this.state

    return (
      <div className='ant-fullcalendar-header'>
        <Row type='flex' justify='space-between' gutter={[12, 12]}>
          <Col>
            <div style={{ fontSize: '20px', fontWeight: 'bold', letterSpacing: '1px', paddingLeft: '16px' }}>
              {currentDate.format(mode === 'month' ? 'MMMM YYYY' : '[Year:] YYYY')}
            </div>
          </Col>
          <Col>
            <Row type='flex' gutter={[6, 6]}>
              <Col>
                <Button icon='reload' loading={isReloading} onClick={this.onReload}>Reload</Button>
              </Col>
              <Col>
                <Button className='group-left' icon='left' onClick={this.prevMonth}>Prev</Button>
                <MonthPicker className='calendar-group-center' allowClear={false} value={currentDate} onChange={this.onChangeDate} />
                <Button className='group-right' icon='right' onClick={this.nextMonth}>Next</Button>
              </Col>
              <Col>
                <Radio.Group buttonStyle='solid' value={mode} onChange={this.onChangeMode}>
                  <Radio.Button value='month'>Month</Radio.Button>
                  <Radio.Button value='year'>Year</Radio.Button>
                </Radio.Group>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    )
  }

  prevMonth = () => {
    // don't use var.subtract, example: currentDate.subtract(1, 'months')
    const currentDate = moment(this.state.currentDate).subtract(1, KEYS_MOMENT[this.state.mode])
    this.onChangeDate(currentDate)
  }

  nextMonth = () => {
    // don't use var.add, example: currentDate.add(1, 'months')
    const currentDate = moment(this.state.currentDate).add(1, KEYS_MOMENT[this.state.mode])
    this.onChangeDate(currentDate)
  }

  // onChangeDate from DatePicker or another function
  onChangeDate = (date) => {
    this.setState({ currentDate: date })
  }

  onChangeMode = (event) => {
    this.setState({ mode: event.target.value })
  }

  dateFullCellRender = (date) => {
    const currentHolidays = this.getHoliday(date)

    return (
      <div className={`ant-fullcalendar-date${currentHolidays ? ' active' : ''}`} style={{ borderRadius: '3px' }}>
        <div className='ant-fullcalendar-value'>{date.format('DD')}</div>
        <div className='ant-fullcalendar-content'>
          {currentHolidays && (
            <ul style={{ paddingLeft: '18px', marginBottom: 0 }}>
              {currentHolidays.map((currentHoliday) => (
                <li
                  key={currentHoliday.pk}
                  title={`Click to open ${this.getHolidayLabel(currentHoliday)}`}
                  onClick={() => this.onDetailClick(currentHoliday.pk)}
                >
                  {this.getHolidayLabel(currentHoliday)}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    )
  }

  getHolidayLabel = (currentHoliday) => {
    return (
      currentHoliday.name + (
        get(currentHoliday, 'branch.name') ? (
          ` (${currentHoliday.branch.name})`
        ) : ''
      )
    )
  }

  monthFullCellRender = (date) => {
    const objHolidays = this.getHolidays(date)
    const holidays = []
    for (const key in objHolidays) {
      for (let i = 0; i < objHolidays[key].length; i += 1) {
        holidays.push(objHolidays[key][i])
      }
    }

    return (
      <div className={`ant-fullcalendar-month${holidays.length > 0 ? ' active' : ''}`} style={{ borderRadius: '3px' }}>
        <div className='ant-fullcalendar-value'>{date.format('MMM')}</div>
        <div className='ant-fullcalendar-content' style={{ overflowY: 'hidden', marginTop: '-4px' }}>
          {holidays.length > 0 && (
            <ul>
              {
                holidays.filter((val, index) => index < 3).map(holiday => (
                  <li key={holiday.pk}>{holiday.name}</li>
                ))
              }
              {holidays.length > 3 && (
                <span>. . .</span>
              )}
            </ul>
          )}
        </div>
      </div>
    )
  }

  // get holidays record in the date, by send date: moment
  getHoliday = (date) => {
    return get(this.state.objData, [date.format('YYYY-MM'), date.format('YYYY-MM-DD')])
  }

  // get holidays in the month by send date: moment
  getHolidays = (date) => {
    return get(this.state.objData, [date.format('YYYY-MM')])
  }

  // onSelectDate from Calendar
  onSelectDate = (date) => {
    this.setState({ currentDate: date, mode: 'month' })
  }

  onDetailClick = (id) => this.props.onDetailClick(id)

  componentDidMount() {
    this.read()
  }

  render() {
    const { currentDate, errorResponse, isLoading, isReloading, mode, objData } = this.state

    if (isLoading) {
      return (
        <div style={{ paddingTop: '100px', textAlign: 'center' }}>
          <Spin
            size='large'
            tip='Loading...'
            indicator={<Icon spin type='loading' style={{ fontSize: '4rem' }} />}
          />
        </div>
      )
    }

    return objData ? (
      <Spin spinning={isReloading}>
        <Calendar
          className='custom-calendar-tomato custom-calendar-small'
          mode={mode}
          dateFullCellRender={this.dateFullCellRender}
          headerRender={this.headerRender}
          monthFullCellRender={this.monthFullCellRender}
          onSelect={this.onSelectDate}
          value={currentDate}
        />
      </Spin>
    ) : (
      <PageError errorResponse={errorResponse} onReload={this.read} />
    )
  }
}

HolidayCalendarView.propTypes = {
  onDetailClick: PropTypes.func,
}

HolidayCalendarView.defaultProps = {
  onDetailClick: () => null,
}

export default HolidayCalendarView