import React from 'react'
import PropTypes from 'prop-types'
import { Form, Col, Select, Switch, Input } from 'antd'
import { get } from 'lodash'
import { FormContext, FormProvider } from 'App/Component/Context/ContextForm'
import SelectQuery from 'App/Component/SelectQuery'

class ExportContent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  getInput = (elem, getFieldDecorator) => {
    switch (elem.type) {
      case 'choices': {
        return (
          getFieldDecorator(elem.name, {
            initialValue: get(elem, ['choices', '0', '0'])
          })(
            <Select>
              {elem.choices.map((item, index) => (
                <Select.Option key={item[0]} value={item[0]}>{item[1]}</Select.Option>
              ))}
            </Select>
          )
        )
      }
      case 'api_reference': {
        return (
          getFieldDecorator(elem.name, {
            // Nothing keys
          })(
            <SelectQuery
              urlKey={elem.api.url_name}
              valKey={elem.api.key}
              valLabel={elem.api.display}
              userReducer={this.props.userReducer}
              // paramProps={elem.name === 'subsection' ? {
              //   section: get(this.downloadForm.getFieldValue('section'), 'key', 0),
              // } : {}}
              // onChange={() => {
              //   // Sementara, jika mengganti section, subsection akan di hapus, dan request subsection dengan param section id.
              //   // Belum untuk yang lain
              //   if (elem.name === 'section') {
              //     this.downloadForm.setFieldsValue({ subsection: undefined })
              //   }
              // }}
            />
          )
        )
      }
      case 'switch': {
        return (
          getFieldDecorator(elem.name, {
            initialValue: elem.initialValue || false,
          })(<Switch />)
        )
      }
      case 'text': {
        return (
          getFieldDecorator(elem.name, {})(<Input />)
        )
      }
      case 'textarea': {
        return (
          getFieldDecorator(elem.name, {})(<Input.TextArea autoSize={{ minRows: 2, maxRows: 4 }} />)
        )
      }
      default: {
        return ''
      }
    }
  }

  render() {
    console.log('Rendered', this)
    const { filterBy, setForm } = this.props
    return (
      <FormProvider>
        <Form layout='horizontal'>
          {filterBy.map((elem) => (
            <FormContext.Consumer key={elem.name}>
              {form => {
                setForm(form)
                const { getFieldDecorator } = form
                const formItemLayout = { labelCol: {span: 7}, wrapperCol: {span: 15} }
                return (
                  <Col span={12}>
                    <Form.Item
                      colon={false}
                      {...formItemLayout}
                      label={
                        <div className='field-label'>
                          {elem.label}
                        </div>
                      }
                    >
                      {this.getInput(elem, getFieldDecorator)}
                    </Form.Item>
                  </Col>
                )
              }}
            </FormContext.Consumer>
          ))}
        </Form>
      </FormProvider>
    )
  }
}

ExportContent.propTypes = {
  filterBy: PropTypes.array,
  setForm: PropTypes.func,
}

ExportContent.defaultProps = {
  filterBy: [],
  setForm: () => null,
}

export default ExportContent