import { BaseList } from "App/Screens/SubModules/Component";
import descriptor from "./descriptorOffline";

class MerchantList extends BaseList {
  constructor(props) {
    super(props)

    this.appName = 'jobs'
    this.modelName = 'merchant'
    this.propsList = {
      avatar: 'name',
      avatarColorKey: 'branch.color',
      pageTitle: 'Merchant',
      descriptor,
      masterKey: 'module-merchant-merchant',
      groupByOptions: [
        {
          label: 'Mapping Mode',
          key: 'mapping_mode.name',
          dataIndex: 'mapping_mode.name',
        }, {
          label: 'Merchant Group',
          key: 'merchant_group.name',
          dataIndex: 'merchant_group.name',
        }, {
          label: 'Active Status',
          key: 'is_active',
          dataIndex: 'is_active',
        },
      ]
    }
  }
}

export default MerchantList