export default {
  "app_label": "jobs",
  "model_name": "merchant",
  "pagination": true,
  "columns": [
    {
      "name": "extra_charge",
      "label": "Extra Charge",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": null,
      "ellipsis": true,
      "child_path": "name",
      "search": true,
      "sort": true
    },
    {
      "name": "both_price",
      "label": "Both price",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "ellipsis": true,
      "search": true,
      "sort": true
    },
    {
      "name": "print_price",
      "label": "Print price",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": 'str',
      "search": true,
      "sort": true
    },
    {
      "name": "scan_price",
      "label": "Scan price",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": null,
      "search": true,
      "sort": true
    },
    {
      "name": "modified",
      "label": "Modified",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "datetime",
      "search": false,
      "sort": true
    }
  ],
  "filter_by": [
    // {
    //   name: 'merchant_group',
    //   label: 'Merchant Group',
    //   api: {
    //     url_name: 'module-merchant-group-autocomplete',
    //     key: 'pk',
    //     display: 'name',
    //   }
    // },
    // {
    //   name: 'mapping_mode',
    //   label: 'Mapping Mode',
    //   api: {
    //     url_name: 'module-merchant-mappingMode-autocomplete',
    //     key: 'pk',
    //     display: 'name',
    //   }
    // },
    // {
    //   name: 'branch',
    //   label: 'Branch',
    //   api: {
    //     url_name: 'branches-autocomplete',
    //     key: 'pk',
    //     display: 'name',
    //   }
    // },
    // {
    //   name: 'is_active',
    //   label: 'Active Status',
    //   singleChoices: [
    //     ['', 'All'],
    //     ['true', 'Active'],
    //     ['false', 'Inactive']
    //   ]
    // }
  ],
}