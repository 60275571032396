import React from "react";
import { Row, Col, Timeline, Icon, Card, Empty, Alert, Typography, Result, Spin, message } from "antd";
import moment from "moment";
import { get } from 'lodash'
import { RequestV2 as Request, Lib } from "App/Utils";

const { Text } = Typography

class JobHistory extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      systemComments: [], // job comment filtered by system_comment
      userComments: [], // job comment filtered by comment
    }
    this.colorClassName = {
      changed: 'text-info',
      removed: 'text-danger',
      added: 'text-success',
    }
  }

  read() {
    let senderId = this.props.match.params.id;
    Request(
      "get",
      "jobComment-read",
      {},
      {},
      [senderId],
      res => this.retrieveSuccess(res),
      err => this.retrieveFailed(err)
    );
  }

  componentDidMount() {
    this.read();
  }

  componentWillUnmount() {
    Lib.windowStop();
  }

  retrieveSuccess(response) {
    const splittedComments = this.splitComments(response.data)
    this.setState({
      systemComments: splittedComments.systemComment,
      userComments: splittedComments.comment,
      isLoading: false
    });
  }

  // Split by key "system_comment" and "comment"
  // return object {systemComments, userComments}
  splitComments = comments => {
    const splittedComments = { systemComment: [], comment: [] }
    for (let i = 0; i < comments.length; i += 1) {
      if (comments[i].system_comment) {
        if (comments[i].system_comment.length > 0) { // karena data lama ada yang array kosong
          splittedComments.systemComment.push(comments[i])
        }
      } else if (comments[i].comment) {
        splittedComments.comment.push(comments[i])
      }
    }
    return splittedComments
  }

  retrieveFailed = error => {
    message.error('Cannot connect to server')
    console.error(error)
    this.setState({ isLoading: false })
  }

  formatDate = value => {
    if (moment(value, moment.ISO_8601).isValid()) {
      return moment(value).format('DD MMM YYYY HH:mm:ss')
    }
    return value
  }

  getElemSystemComments = comments => {
    return (
      <Timeline>
        { comments.map(comment => (
          <Timeline.Item key={comment.pk} dot={<Icon type='robot' />}>
            <div className="arrow-left float-left" style={{ marginLeft: '-4px', marginTop: '4px' }} />
            <Card
              className='job-history-card'
              style={{ borderLeft: '2px solid #50a6f5', marginLeft: '10px' }}
              title={
                <React.Fragment>
                  <span className='font-weight-bold' style={{ color: '#f9915a' }}>
                    <Icon type='user' style={{ marginRight: '4px' }} />
                    {get(comment, 'created_by.full_name')}
                  </span>
                  <span className='text-muted font-sm float-right'>
                    {`${moment(comment.created).format('ddd, DD MMM YYYY')} (${moment(comment.created).fromNow()})`}
                  </span>
                </React.Fragment>
              }
            >
              <Alert
                showIcon
                type='info'
                message='Data Changes'
                style={{ margin: '8px 8px 20px 8px' }}
                description={
                  comment.system_comment.map((value, index) => (
                    <div key={index}>
                      <b><i>{value.field} </i></b>
                      {`${value.action} `}
                      <Text
                        className={`font-weight-bold ${this.colorClassName[value.action] || 'text-secondary'}`}
                        delete={value.action === 'removed' || value.action === 'changed'}
                      >
                        {value.action === 'added' ? this.formatDate(value.new_value) : this.formatDate(value.old_value)}
                      </Text>
                      {
                        value.action === 'changed' && (
                          <>
                            <Icon type='caret-right' className='text-secondary' style={{ margin: '0 4px' }} />
                            <Text type='warning' className='font-weight-bold'>{this.formatDate(value.new_value)}</Text>
                          </>
                        )
                      }
                    </div>
                  ))
                }
              />
            </Card>
          </Timeline.Item>
        )) }
      </Timeline>
    )
  }

  getElemUserComments = comments => {
    return (
      <Timeline>
        { comments.map(comment => (
          <Timeline.Item key={comment.pk} dot={<Icon type='smile' />}>
            <div className='arrow-left float-left' style={{ marginLeft: '-4px', marginTop: '4px' }} />
            <Card
              className='job-history-card'
              style={{ borderLeft: '2px solid #50a6f5', marginLeft: '10px' }}
              title={
                <React.Fragment>
                  <span className='font-weight-bold' style={{ color: '#f9915a' }}>
                    <Icon type='wechat' style={{ marginRight: '4px' }} />
                    {get(comment, 'created_by.full_name')}
                  </span>
                  <span className='text-muted font-sm float-right'>
                    {`${moment(comment.created).format('ddd, DD MMM YYYY')} (${moment(comment.created).fromNow()})`}
                  </span>
                </React.Fragment>
              }
            >
              <Row>
                <Col span={24} style={{ borderLeft: '1px solid #dcdcdc', marginLeft: '8px' }}>
                  {comment.comment.split(String.fromCharCode(10)).filter(val => val).map((value, index) => (
                    <div key={index} style={{ marginLeft: '10px' }}>
                      {value}
                    </div>
                  ))}
                </Col>
              </Row>
            </Card>
          </Timeline.Item>
        ))}
      </Timeline>
    )
  }

  render() {
    const { isLoading, systemComments, userComments } = this.state;
    return isLoading ? (
      <div style={{ paddingTop: 100, textAlign: "center" }}>
        <Spin
          size="large"
          tip="making coffee..."
          indicator={<Icon type="loading" style={{ fontSize: "4rem" }} spin />}
        />
      </div>
    ) : (
      <Row type='flex' gutter={[7, 7]}>
        <Col span={12}>
          <Card title={`History (${systemComments.length})`} style={{ height: '85vh', overflowY: 'auto' }}>
            {
              systemComments.length > 0 ? (
                this.getElemSystemComments(systemComments)
              ) : (
                <Empty description='System Comment not found' />
              )
            }
          </Card>
        </Col>
        <Col span={12}>
          <Card title={`Comment (${userComments.length})`} style={{ height: '85vh', overflowY: 'auto' }}>
            {
              userComments.length > 0 ? (
                this.getElemUserComments(userComments)
              ) : (
                <Empty description='User Comment not found' />
              )
            }
          </Card>
        </Col>
      </Row>
    );
  }
}

export default JobHistory;
