const descriptor = {
  pagination: true,
  columns:[
    {
      name:"pk",
      label:"ID",
      help_text:null,
      hidden:false,
      identifier:true,
      data_type:"int",
      search:false,
      sort:false
    },
    {
      name:"name",
      label:"Name",
      help_text:null,
      hidden:false,
      identifier:false,
      data_type:"str",
      ellipsis:true,
      search:true,
      sort:true
    },
    {
      name:"origin_branch",
      label:"origin_branch",
      help_text:null,
      hidden:false,
      identifier:false,
      data_type:'str',
      child_path:"name",
      search:true,
      sort:true
    },
    {
      name:"owner",
      label:"Owner",
      help_text:null,
      hidden:false,
      identifier:false,
      data_type:"str",
      search:true,
      sort:true
    },
    {
      name:"job_queue",
      label:"Job Queue",
      help_text:null,
      hidden:false,
      identifier:false,
      data_type:"str",
      child_path:"name",
      search:false,
      sort:true
    },
    {
      name:"received",
      label:"Received",
      help_text:null,
      hidden:false,
      identifier:false,
      data_type:"datetime",
      search:true,
      sort:true
    },
    {
      name:"status",
      label:"Status",
      help_text:null,
      hidden:false,
      identifier:false,
      data_type:"str",
      search:true,
      sort:true
    },
    {
      name:"grade",
      label:"Grade",
      help_text:null,
      hidden:false,
      identifier:false,
      data_type:"object",
      child_path: 'level',
      search:true,
      sort:true
    },
    {
      name:"instructed_finish_date",
      label:"Due date",
      help_text:null,
      hidden:false,
      identifier:false,
      data_type:"datetime",
      search:false,
      sort:true
    },
    {
      name:"last_qa1",
      label:"Last QA1",
      help_text:null,
      hidden:false,
      identifier:false,
      data_type:"object",
      child_path: "username",
      search:false,
    },
    {
      name:"last_qa2",
      label:"Last QA2",
      help_text:null,
      hidden:false,
      identifier:false,
      data_type:"object",
      child_path: "username",
      search:false,
    },
    {
      name:"last_qs",
      label:"Last QS",
      help_text:null,
      hidden:false,
      identifier:false,
      data_type:"object",
      child_path: "username",
      search:false,
    },
    {
      name:"biztype",
      label:"Biz Type",
      help_text:null,
      hidden:false,
      identifier:false,
      data_type:"str",
      search:false,
    }
  ],
  filter_by:[
    {
      "label":"Origin Branch",
      "name":"origin_branch",
      "type":"api_reference",
      "api":{
        "url_name":"api:admin:multisite:branch:autocomplete",
        "key":"pk",
        "display":"name",
        "pagination":true
      }
    },
    {
      name: 'job_queue',
      label: 'Job Queue',
      api: {
        url_name: 'module-jobQueue-autocomplete',
        key: 'pk',
        display: 'name',
      }
    },
    {
      name: 'grade',
      label: 'Job Grade',
      api: {
        url_name: 'jobGrades-autocomplete',
        key: 'pk',
        display: 'label',
      }
    },
    {
      "label": "Test / Training",
      "name": "is_training",
      "type": "switch"
    },
  ]
}

export default descriptor
