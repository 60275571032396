import React from 'react'
import PropTypes  from 'prop-types'
import { Col, Row } from 'antd'
import { getInitialStatus } from './statuses'

class RowItem extends React.PureComponent {
  render() {
    const { setItem, isSelected } = this.props
    const anotherClassName = isSelected ? 'selected' : ''

    return (
      <Row key={setItem.pk} type='flex' className={`custom-draggable-item ${anotherClassName}`} style={{ borderRadius: '2px', padding: '8px' }}>
        <Col className='text-truncate' style={{ width: 'calc(100% - 20px)' }}>
          {setItem.name}
        </Col>
        <Col style={{ width: '20px', paddingLeft: '6px' }}>
          {getInitialStatus(setItem.status)}
        </Col>
      </Row>
    )
  }
}

RowItem.propTypes = {
  setItem: PropTypes.object.isRequired,
  isSelected: PropTypes.bool,
}

RowItem.defaultProps = {
  isSelected: false,
}

export default RowItem