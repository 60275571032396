export default [
  {
    title: 'Type',
    type: 'text',
    data: '',
    required: true,
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'type',
    order: 0,
  }, {
    title: 'Qty',
    type: 'number',
    data: '',
    initialValue: null,
    note_text: 'qty of rate type',
    additional: '',
    dataIndex: 'qty',
    order: 1,
  }
];