import React from 'react'
import {
  Row, Col,
  Divider,
} from 'antd'
import StockHighcharts from 'highcharts/highstock'
import Highcharts from 'highcharts/highcharts'
import HighchartsReact from 'highcharts-react-official'
import { stockData } from './Data/StatisticData'
// import moment from 'moment'

const darkTheme = {
  colors: ['#2b908f', '#90ee7e', '#f45b5b', '#7798BF', '#aaeeee', '#ff0066',
    '#eeaaee', '#55BF3B', '#DF5353', '#7798BF', '#aaeeee'],
  chart: {
    backgroundColor: {
      linearGradient: { x1: 0, y1: 0, x2: 1, y2: 1 },
      stops: [
        [0, '#2a2a2b'],
        [1, '#3e3e40']
      ]
    },
    style: {
      fontFamily: '\'Unica One\', sans-serif'
    },
    plotBorderColor: '#606063'
  },
  subtitle: {
    style: {
      color: '#E0E0E3',
      textTransform: 'uppercase'
    }
  },
  xAxis: {
    gridLineColor: '#707073',
    labels: {
      style: {
        color: '#E0E0E3'
      }
    },
    lineColor: '#707073',
    minorGridLineColor: '#505053',
    tickColor: '#707073',
    title: {
      style: {
        color: '#A0A0A3'
      }
    }
  },
  yAxis: {
    gridLineColor: '#707073',
    labels: {
      style: {
        color: '#E0E0E3'
      }
    },
    lineColor: '#707073',
    minorGridLineColor: '#505053',
    tickColor: '#707073',
    tickWidth: 1,
    title: {
      style: {
        color: '#A0A0A3'
      }
    }
  },
  tooltip: {
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
    style: {
      color: '#F0F0F0'
    }
  },
  plotOptions: {
    series: {
      dataLabels: {
        color: '#F0F0F3',
        style: {
          fontSize: '13px'
        }
      },
      marker: {
        lineColor: '#333'
      }
    },
    boxplot: {
      fillColor: '#505053'
    },
    candlestick: {
      lineColor: 'white'
    },
    errorbar: {
      color: 'white'
    }
  },
  legend: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    itemStyle: {
      color: '#E0E0E3'
    },
    itemHoverStyle: {
      color: '#FFF'
    },
    itemHiddenStyle: {
      color: '#606063'
    },
    title: {
      style: {
        color: '#C0C0C0'
      }
    }
  },
  credits: {
    style: {
      color: '#666'
    }
  },
  labels: {
    style: {
      color: '#707073'
    }
  },
  drilldown: {
    activeAxisLabelStyle: {
      color: '#F0F0F3'
    },
    activeDataLabelStyle: {
      color: '#F0F0F3'
    }
  },
  navigation: {
    buttonOptions: {
      symbolStroke: '#DDDDDD',
      theme: {
        fill: '#505053'
      }
    }
  },
  // scroll charts
  rangeSelector: {
    buttonTheme: {
      fill: '#505053',
      stroke: '#000000',
      style: {
        color: '#CCC'
      },
      states: {
        hover: {
          fill: '#707073',
          stroke: '#000000',
          style: {
              color: 'white'
          }
        },
        select: {
          fill: '#000003',
          stroke: '#000000',
          style: {
              color: 'white'
          }
        }
      }
    },
    inputBoxBorderColor: '#505053',
    inputStyle: {
      backgroundColor: '#333',
      color: 'silver'
    },
    labelStyle: {
      color: 'silver'
    }
  },
  navigator: {
    handles: {
      backgroundColor: '#666',
      borderColor: '#AAA'
    },
    outlineColor: '#CCC',
    maskFill: 'rgba(255,255,255,0.1)',
    series: {
      color: '#7798BF',
      lineColor: '#A6C7ED'
    },
    xAxis: {
      gridLineColor: '#505053'
    }
  },
  scrollbar: {
    barBackgroundColor: '#808083',
    barBorderColor: '#808083',
    buttonArrowColor: '#CCC',
    buttonBackgroundColor: '#606063',
    buttonBorderColor: '#606063',
    rifleColor: '#FFF',
    trackBackgroundColor: '#404043',
    trackBorderColor: '#404043'
  }
}

class PageStatistics extends React.Component {

  getSimpleDate(paramDate) {
    const dd = paramDate.getDate()
    const mm = String(paramDate.getMonth() + 1).padStart(2, '0')
    const yyyy = paramDate.getFullYear()
    const simpleDate = yyyy + '/' + mm + '/' + dd
    return simpleDate
  }

  getHighchart() {
    let stockDataConverted = []
    let stockData2 = []
    let nextDay = new Date();

    [...Array(stockData.length)].map((x, i) => {
      nextDay.setDate(nextDay.getDate() + 1)

      stockData2.push([
        nextDay.getTime(),
        Math.floor(Math.random() * (300 - 150) + 150)
      ])

      stockDataConverted.push([
        nextDay.getTime(),
        stockData[i][1]
      ])
      return null
    })

    return (
      <HighchartsReact
        highcharts={StockHighcharts}
        constructorType={`stockChart`}
        options={{
          title: {
            text: `Stock Chart Line ${this.props.currentProduct.name} (with dummy data)`
          },
          series: [{
            name: 'Usage',
            data: stockDataConverted,
            type: 'areaspline',
            threshold: null,
            fillColor: {
              linearGradient: {x1: 0, y1: 0, x2: 0, y2: 1},
              stops: [
                [0, StockHighcharts.getOptions().colors[0]],
                [1, StockHighcharts.Color(StockHighcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
              ]
            }
          // }, {
          //   name: 'Alias (random)',
          //   data: stockData2,
          //   type: 'areaspline',
          //   threshold: null,
          //   fillColor: {
          //     linearGradient: {x1: 0, y1: 0, x2: 0, y2: 1},
          //     stops: [
          //       [0, StockHighcharts.getOptions().colors[0]],
          //       [1, StockHighcharts.Color(StockHighcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
          //     ]
          //   }
          }],
          rangeSelector: {
            selected: 1
          },
          tooltip: {
            valueDecimals: 0,
            valueSuffix: 'x'
          },
          credits: {
              enabled: false
          },
        }}
      />
    )
  }

  getHighchartDark() {
    return (
      <HighchartsReact
        highcharts={StockHighcharts}
        constructorType={`stockChart`}
        options={{
          series: [{
            name: 'Usage',
            data: stockData,
            type: 'area',
            threshold: null,
          }],
          rangeSelector: {
            selected: 1
          },
          tooltip: {
            valueDecimals: 0,
            valueSuffix: 'x'
          },
          ...darkTheme,
          credits: {
            enabled: false
          },
          title: {
            text: `Stock Chart (Dark Style - Area)`,
            style: {
              color: '#E0E0E3',
              textTransform: 'uppercase',
              fontSize: '20px'
            }
          },
        }}
      />
    )
  }

  getHighchartSpline() {
    let cat = [],
      johnData = [],
      michaelData = []

    let nextDay = new Date();

    [...Array(25)].map(x => {
      johnData.push(Math.floor(Math.random() * (9 - 2) + 2))
      michaelData.push(Math.floor(Math.random() * (9 - 2) + 2))
      nextDay.setDate(nextDay.getDate() + 1)
      cat.push(this.getSimpleDate(nextDay))
      return null
    })
    return (
      <HighchartsReact
        highcharts={Highcharts}
        constructorType='chart'
        options={{
          chart: {
            type: 'areaspline',
          },
          title: {
            text: `Spline Double Chart (with random ${johnData.length} data)`
          },
          series: [{
            name: 'John',
            data: johnData,
          }, {
            name: 'Michael',
            data: michaelData,
          }],
          xAxis: {
            categories: cat,
          },
          yAxis: {
            title: {
              text: `${this.props.currentProduct.name}`
            }
          },
          tooltip: {
            shared: true,
            valueSuffix: ' time of use'
          },
          credits: {
            enabled: false
          },
        }}
      />
    )
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { currentProduct } = this.props
    if (currentProduct && nextProps.currentProduct) {
      if (currentProduct.modified !== nextProps.currentProduct.modified) {
        return true
      }
    }
    return false
  }

  render() {
    for (const i in stockData) {
      if (Date.parse(stockData[i][0])) {
        stockData[i][0] = Date.parse(stockData[i][0])
      }
    }

    return (
      <Row>
        <Col span={24}>
          <Row>
            {
              this.getHighchart()
            }
          </Row>
          <Divider type='horizontal' />
          <Row>
            {
              this.getHighchartDark()
            }
          </Row>
          <Divider type='horizontal' />
          <Row>
            {
              this.getHighchartSpline()
            }
          </Row>
        </Col>
      </Row>
    )
  }
}

export default PageStatistics