import React from 'react'
import { Card, Col, Icon, Radio, Row, Spin, Tooltip } from 'antd'
import { get } from 'lodash'
import Lottie from 'react-lottie'

import BaseRequest from './BaseRequest'
import * as homeLottie from 'App/Assets/lottie/home'

class CompletedJobs extends BaseRequest {
  constructor(props) {
    super(props)

    this.filters = [
      { key: '30d', label: '30 days' },
      { key: '60d', label: '60 days' },
      { key: '90d', label: '90 days' },
    ]

    this.urlKey = 'module-groupUser-user-completedJobStats'
    this.limit_days = this.filters[0].key
    this.keysQueryParam.push(...['limit_days'])
  }

  onChangeFilter = (e) => {
    this.limit_days = e.target.value
    this.read()
  }

  getIconPercentage = () => {

  }

  renderChangePercentage = () => {
    const { data } = this.state
    if (!data.ontime_job.change_percentage) {
      return (
        <div style={{ color: '#00FF00' }}>
          Stable
        </div>
      )
    }

    return (
      <div>
        {data.ontime_job.change_percentage > 0 ? (
          <Icon type='arrow-up' style={{ color: '#1890ff' }} />
        ) : (
          <Icon type='arrow-down' style={{ color: '#ff4d4f' }} />
        )}
        <span style={{ fontSize: '14px' }}>
          {data.ontime_job.change_percentage} %
        </span>
      </div>
    )
  }

  render() {
    const { data } = this.state
    const filterLabel = get(this.filters.find(filter => filter.key === this.limit_days), 'label')

    return (
      <Spin spinning={this.state.isLoading}>
        <Card
          loading={!this.state.data && this.state.isLoading}
          size='small'
          title={`Completed Jobs (${filterLabel})`}
          extra={
            <Radio.Group buttonStyle='solid' size='small' value={this.limit_days} onChange={this.onChangeFilter}>
              {this.filters.map(filter => (
                <Radio.Button key={filter.key} value={filter.key}>
                  {filter.label}
                </Radio.Button>
              ))}
            </Radio.Group>
          }
        >
          {this.state.data ? (
            <Row type='flex' justify='space-between'>
              <Col>
                <Row type='flex' align='middle' style={{ height: '100%' }}>
                  <Col>
                    <Row type='flex' align='middle'>
                      <Col>
                        <div style={{ textAlign: 'center' }}>
                          <span style={{ fontSize: '48px' }}>{data.ontime_job.total}</span>
                          <span> of {data.total}</span>
                        </div>
                        <div>completed on time</div>
                      </Col>
                      <Tooltip title='Change percentage in the last month'>
                        <Col className='hoverable-65' style={{ fontSize: '18px', paddingLeft: '12px' }}>
                          {this.renderChangePercentage()}
                        </Col>
                      </Tooltip>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col>
                <div>
                  <Lottie
                    width={240}
                    height={160}
                    options={{
                      loop: false,
                      autoplay: true,
                      animationData: homeLottie.default,
                      rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice',
                      },
                    }}
                  />
                </div>
                <div style={{ fontSize: '28px', fontWeight: 500, textAlign: 'center' }} title='Area'>
                  {data.total_area} m<sup>2</sup>
                </div>
              </Col>
            </Row>
          ) : this.getErrorComp()}
        </Card>
      </Spin>
    )
  }
}

export default CompletedJobs