const module = 'admin'
const app = 'jobs'

export default {
  'api:admin:jobs:account:list': `/${module}/${app}/accounts`,
  'api:admin:jobs:account:detail': `/${module}/${app}/accounts/:id`,

  'api:admin:jobs:buildingtype:list': `/${module}/${app}/building-types`,
  'api:admin:jobs:buildingtype:detail': `/${module}/${app}/building-types/:id`,

  'api:admin:jobs:building:list': `/${module}/${app}/buildings`,
  'api:admin:jobs:building:detail': `/${module}/${app}/buildings/:id`,

  'api:admin:jobs:extrachargejob:list': `/${module}/${app}/extra-charge-jobs`,
  'api:admin:jobs:extrachargejob:detail': `/${module}/${app}/extra-charge-jobs/:id`,

  'api:admin:jobs:extrachargesize:list': `/${module}/${app}/extra-charge-sizes`,
  'api:admin:jobs:extrachargesize:detail': `/${module}/${app}/extra-charge-sizes/:id`,

  'api:admin:jobs:extracharge:list': `/${module}/${app}/extra-charges`,
  'api:admin:jobs:extracharge:detail': `/${module}/${app}/extra-charges/:id`,

  'api:admin:jobs:holiday:list': `/${module}/${app}/holidays`,
  'api:admin:jobs:holiday:detail': `/${module}/${app}/holidays/:id`,

  'api:admin:jobs:jobstatusnotsynced:list': `/${module}/${app}/job-status-not-synceds`,
  'api:admin:jobs:jobstatusnotsynced:detail': `/${module}/${app}/job-status-not-synceds/:id`,

  'api:admin:jobs:jobgrade:list': `/${module}/${app}/job-grades`,
  'api:admin:jobs:jobgrade:detail': `/${module}/${app}/job-grades/:id`,

  'api:admin:jobs:jobqueue:list': `/${module}/${app}/job-queues`,
  'api:admin:jobs:jobqueue:detail': `/${module}/${app}/job-queues/:id`,

  'api:admin:jobs:jobstatushistory:list': `/${module}/${app}/job-status-histories`,
  'api:admin:jobs:jobstatushistory:detail': `/${module}/${app}/job-status-histories/:id`,

  'api:admin:jobs:jobtype:list': `/${module}/${app}/job-types`,
  'api:admin:jobs:jobtype:detail': `/${module}/${app}/job-types/:id`,

  'api:admin:jobs:merchantgroup:list': `/${module}/${app}/merchant-groups`,
  'api:admin:jobs:merchantgroup:detail': `/${module}/${app}/merchant-groups/:id`,

  'api:admin:jobs:merchant:list': `/${module}/${app}/merchants`,
  'api:admin:jobs:merchant:detail': `/${module}/${app}/merchants/:id`,

  'api:admin:jobs:operatingbranch:list': `/${module}/${app}/operating-branches`,
  'api:admin:jobs:operatingbranch:detail': `/${module}/${app}/operating-branches/:id`,

  'api:admin:jobs:ranges:list': `/${module}/${app}/ranges`,
  'api:admin:jobs:ranges:detail': `/${module}/${app}/ranges/:id`,

  'api:admin:jobs:ratename:list': `/${module}/${app}/rate-names`,
  'api:admin:jobs:ratename:detail': `/${module}/${app}/rate-names/:id`,

  'api:admin:jobs:ratetype:list': `/${module}/${app}/rate-types`,
  'api:admin:jobs:ratetype:detail': `/${module}/${app}/rate-types/:id`,

  'api:admin:jobs:tagmerchant:list': `/${module}/${app}/tag-merchants`,
  'api:admin:jobs:tagmerchant:detail': `/${module}/${app}/tag-merchants/:id`,

  'api:admin:jobs:tag:list': `/${module}/${app}/tags`,
  'api:admin:jobs:tag:detail': `/${module}/${app}/tags/:id`,

  'api:admin:jobs:takeofftype:list': `/${module}/${app}/take-off-types`,
  'api:admin:jobs:takeofftype:detail': `/${module}/${app}/take-off-types/:id`,
}
