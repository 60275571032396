import React from 'react'
import PropTypes from 'prop-types'
import { Prompt } from 'react-router-dom'
import { Tabs, Row, Col, Form, Button, message } from 'antd'
import { get } from 'lodash'
import { FormContext, FormProvider } from 'App/Component/Context/ContextForm'
import DynamicField from 'App/Component/DynamicField'
import fields from 'App/Screens/SubModules/Products/ProductGroup/Management/fields'
import { RequestV2 as Request } from 'App/Utils'
import Lib2 from 'App/Component/Singlepage/Lib2'
import History from "App/Screens/SubModules/Component/History";
const { TabPane } = Tabs;

class PageSetting extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isFieldsTouched: false,
      isSaving: false,
      activeKey: '1'
    }
  }

  onBlur = (value, key, type) => {
    const { productGroup } = this.props
    if (get(productGroup, key) !== value) {
      this.setState({ isFieldsTouched: true })
    }
  }

  onRevert = () => {
    if (this.form) {
      this.form.resetFields()
      this.setState({ isFieldsTouched: false })
    } else {
      message.error('Error in code PageSetting, form not found. [function => onRevert]')
    }
  }

  onSave = () => {
    if (this.form) {
      this.form.validateFieldsAndScroll({scroll: {offsetTop: 150}}, (err, values) => {
        if (!err) {
          this.setState({ isSaving: true }, () => {
            const { productGroup } = this.props
            const convertedValues = Lib2.getValuesWithFile(values)
            Request(
              'put',
              'module-product-productGroup-update',
              {},
              convertedValues,
              [productGroup.pk],
              this.saveSuccess,
              this.saveFailed,
            )
          })
        }
      })
    } else {
      message.error('Error in code PageSetting, form not found. [function => onSave]')
    }
  }

  saveSuccess = (response) => {
    message.success(`${response.data.name} has been saved.`)
    this.props.setProductGroup(response.data)
    this.setState({ isSaving: false, isFieldsTouched: false })
  }

  saveFailed = (error) => {
    const errors = get(error, ['response', 'data'])
    if (errors && typeof errors === 'object') {
      this.setFieldsError(errors)
    } else {
      message.error('Failed to save Product Group, please contact your admin! code: ' + get(error, ['response', 'status'], 'Unknown.'), 5)
    }
    this.setState({ isSaving: false })
  }

  setFieldsError = (objError) => {
    const obj = {}
    for (const key in objError) {
      message.error('Error in ' + key + ' : ' + objError[key][0], 5)
      const tempValue = this.form.getFieldValue(key)
      obj[key] = {
        value: tempValue,
        errors: [new Error(objError[key][0])]
      }
    }
    this.form.setFields(obj)
  }

  render() {
    const { readOnly, userReducer, productGroup, setProductGroup } = this.props
    const { isFieldsTouched, activeKey } = this.state

    return (
      <div>
        <Prompt
          when={isFieldsTouched}
          message='You will lose any unsaved changes. Continue?'
        />
        <Tabs activeKey={activeKey} onChange={(activeKey)=>this.setState({ activeKey })} tabPosition={"left"}>
          <TabPane tab="Edit" key="1">
            <>
              <Row style={{ marginTop: '12px' }}>
                <FormProvider>
                  <Form>
                    {fields.map((elem) => {
                      return (
                        <DynamicField
                          {...elem}
                          operation={readOnly ? 'view' : 'edit'}
                          key={elem.dataIndex}
                          onBlur={this.onBlur}
                          userReducer={userReducer}
                          dataRecord={get(productGroup, elem.dataIndex)}
                        />
                      )
                    })}
                    <FormContext.Consumer>
                      {form => {this.form = form}}
                    </FormContext.Consumer>
                  </Form>
                </FormProvider>
              </Row>
              <Row type='flex' justify='space-between' align='middle' style={{ backgroundColor: '#fff', padding: '12px 0', borderTop: '1px solid #e8e8e8'}}>
                <Col>
                  {/* <Button type='danger'>Button Danger</Button> */}
                </Col>
                <Col>
                  <Row type='flex' gutter={[12, 12]}>
                    {isFieldsTouched && (
                      <Col>
                        <Button onClick={this.onRevert}>
                          Revert to Saved
                        </Button>
                      </Col>
                    )}
                    {!readOnly && (
                      <Col>
                        <Button icon={this.state.isSaving ? 'loading' : 'save'} type='primary' onClick={this.onSave}>
                          Update
                        </Button>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </>
          </TabPane>
          <TabPane tab="History" key="2">
            <History 
              url="module-product-productGroup-history" 
              id={get(this.props, 'productGroup.pk')}
              fields={fields}
              updateHeader={(productGroup)=>{
                setProductGroup(productGroup)
                this.setState({ productGroup, activeKey: "1" })
              }}
              title={productGroup.name} 
              urlDetail="module-product-productGroup-history-detail"
            />
          </TabPane>
        </Tabs>
      </div>
    )
  }
}

PageSetting.propTypes = {
  userReducer: PropTypes.object.isRequired,
  productGroup: PropTypes.object.isRequired,
  setProductGroup: PropTypes.func,
  readOnly: PropTypes.bool,
}

PageSetting.defaultProps = {
  setProductGroup: () => null,
  readOnly: false,
}

export default PageSetting