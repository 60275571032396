import request from "App/Utils/request.v3";

export default class UploadAdapter {
  constructor(loader) {
    this.loader = loader;
  }

  upload() {
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          const data = new FormData();
          data.append("file", file);

          request({
            method: "post",
            urlKey: "create-files",
            data,
            onSuccess: (res) => resolve({ default: res.file }),
            onFailed: () =>
              reject(new Error(`Couldn't upload file: ${file.name}`)),
            onUploadProgress: (e) => {
              this.loader.uploadTotal = e.total;
              this.loader.uploaded = e.loaded;
            },
          }).then();
        })
    );
  }
}
