export default {
  app_label: 'jobs',
  model_name: 'erfmapping',
  pagination: true,
  columns: [
    {
      name: 'erf_field',
      label: 'ERF Field',
      data_type: 'str',
      search: true,
      sort: true,
    },
    {
      name: 'erf_value',
      label: 'ERF Value',
      data_type: 'str',
      search: true,
      sort: true,
    },
    {
      name: 'map_value',
      label: 'Map Value',
      data_type: 'str',
      search: true,
      sort: true,
    },
    {
      name: 'map_content_type',
      label: 'Map Content',
      data_type: null,
      child_path: 'label',
      search: true,
      sort: true,
    },
    {
      name: 'map_object',
      label: 'Map Object',
      data_type: null,
      child_path: 'name',
      search: true,
      sort: true,
    },
  ],
  filter_by: [],
  export_import: null,
}