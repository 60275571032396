import { BaseDetail } from 'App/Screens/SubModules/Component'
import fields from './fields'
import { TagMerchantReference } from './References'

class PageDetail extends BaseDetail {
  constructor(props) {
    super(props)

    this.urlKey = 'module-merchant-tag-detail'
    this.title = 'Tag'
    this.urlList = '/merchant/tag'
    this.headerDescriptions = [
      { key: 'code', label: 'Code' },
      { key: 'name', label: 'Name' },
      { key: 'sequence', label: 'Sequence' },
      { key: 'show_in_csv', label: 'Show in CSV', type: 'bool' },
      { key: 'show_in_pdf', label: 'Show in PDF', type: 'bool' },
      { key: 'allow_override', label: 'Allow Override', type: 'bool' },
    ]
    this.fields = fields
    this.panes = [TagMerchantReference]
    this.refField = {
      name: 'tag',
      type: 'object',
      valKey: 'pk',
      valLabel: 'label',
    }
    this.history = {
      url: 'module-merchant-tag-history',
      fields: fields,
      urlDetail: "module-merchant-tag-history-detail",
      id: props.match.params.id,
      title: 'name',
      destroyOnSave: true
    }
  }

  componentDidMount() {
    this.retrieve(undefined, () => {
      if (this.state.data) {
        this.state.data.label = `${this.state.data.code} - ${this.state.data.name}`
      }
    })
  }
}

export default PageDetail