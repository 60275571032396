/**
 |--------------------------------------------------
 | Please use field key for object autocomplete
 | example : line_template: {'pk' : 1, 'name': 'danan'}

 | √ correct => fieldKey: line_template
 | x wrong => fieldKey: line_template.name
 |
 |--------------------------------------------------
 */

export default [
  {
    title: 'Override set',
    required: true,
    type: 'SelectQuery',
    data: 'overrideSets',
    initialValue: undefined,
    note_text: '',
    dataIndex: 'override_set',
    fieldKey:'override_set',
    selectedKey: 'pk',
    selectedValue: 'name',
    additional: 'edit|add',
    order: 0,
    relations: ['line_template'],
    disableChange: true
  }, {
    title: 'Line template',
    required: true,
    type: 'SelectQuery',
    data: 'lineTemplates',
    initialValue: undefined,
    note_text: '',
    dataIndex: 'line_template',
    fieldKey:'line_template',
    selectedKey: 'pk',
    selectedValue: 'label',
    additional: 'edit|add',
    param_name:'line_template',
    sorter: true,
    view: true,
    editable: true,
    width: '300px',
    order: 1,
    configParamProps: [
      { key: 'subsection', sourceField: 'override_set', sourceKey: 'subsection_id' },
    ],
  }, {
    title: 'Product',
    type: 'SelectQuery',
    data: 'products',
    initialValue: undefined,
    note_text: '',
    dataIndex: 'product',
    fieldKey:'product',
    selectedKey: 'pk',
    selectedValue: 'name',
    additional: 'edit|add',
    param_name:'product',
    sorter: true,
    view: true,
    editable: true,
    width: '300px',
    order: 2,
  }, {
    title: 'Extra1',
    type: 'text',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'extra1',
    fieldKey:'extra1',
    param_name:'extra1',
    sorter: true,
    view: true,
    editable: true,
    width: '200px',
    order: 3,
  }, {
    title: 'Extra2',
    type: 'text',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'extra2',
    fieldKey:'extra2',
    param_name:'extra2',
    sorter: true,
    view: true,
    editable: true,
    width: '200px',
    order: 4,
  }, {
    title: 'Usage',
    type: 'SelectQuery',
    data: 'lineUsages',
    initialValue: '',
    note_text: '',
    additional: 'edit|add',
    selectedKey: 'pk',
    selectedValue: 'label',
    dataIndex: 'usage',
    fieldKey:'usage',
    param_name:'usage',
    sorter: true,
    view: true,
    editable: true,
    width: '300px',
    order: 5,
  }, {
    title: 'Waste pct',
    type: 'number',
    data: '',
    initialValue: null,
    note_text: '',
    additional: '',
    dataIndex: 'waste_pct',
    fieldKey:'waste_pct',
    param_name:'waste_pct',
    sorter: true,
    view: true,
    editable: true,
    width: '200px',
    order: 6,
    step: 0.01,
  }, {
    title: 'Subheading',
    type: 'SelectQuery',
    data: 'subheadings',
    initialValue: '',
    note_text: '',
    additional: 'edit|add',
    selectedKey: 'pk',
    selectedValue: 'label',
    dataIndex: 'subheading',
    fieldKey:'subheading',
    param_name:'subheading',
    sorter: true,
    view: true,
    editable: true,
    width: '300px',
    order: 7,
  }, {
    title: 'Relation',
    type: 'text',
    data: '',
    initialValue: '',
    note_text: 'link back to the prefill set',
    additional: '',
    dataIndex: 'relation',
    fieldKey:'relation',
    param_name:'relation',
    sorter: true,
    view: true,
    editable: true,
    width: '100px',
    order: 8,
  },
];
