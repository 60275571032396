import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import CustomTransfer from 'App/Screens/SubModules/UserGroup/User/Management/Component/CustomTransfer'
// import WarningLabel from '../WarningLabel'
import ErrorLabel from 'App/Screens/SubModules/UserGroup/Group/Management/Pages/ErrorLabel'

const TRANSFER_PROPS = { listStyle: {height: 'calc(100vh - 320px)', minHeight: '265px'} }

class PageMerchants extends React.PureComponent {
  constructor(props) {
    super(props)

    this.transferProps = { listStyle: {height: `calc(100vh - ${props.user ? '430px' : '320px'})`, minHeight: '265px'} }
    this.reportTitles = ['Available Merchant Access', 'Chosen Merchant Access']
    this.state = {
      errorMessage: '',
    }
  }

  onFieldTouched = (isFieldsTouched) => {
    this.props.onFieldTouched(isFieldsTouched, () => {
      this.setState({ errorMessage: '' })
    })
  }

  validateFields = () => {
    if (get(this._merchant, 'validateFields')) {
      return this._merchant.validateFields()
    }
    return undefined
  }

  setError = (errorMessage) => {
    if (!errorMessage) return

    this.props.setActiveKey(this.props.tabKey, () => {
      this.setState({ errorMessage: new Error(errorMessage).message })
    })
  }

  render() {
    const { readOnly, userHelper, user } = this.props
    const { errorMessage } = this.state

    return (
      <div>
        { errorMessage && <ErrorLabel errorMessage={errorMessage} /> }
        {/* <WarningLabel tabName='Reports' groupName={get(user, 'name', '')} /> */}
        <div style={{ padding: '12px 24px' }}>
          <CustomTransfer
            ref={ref => {this._merchant = ref}}
            readOnly={readOnly}
            titles={this.reportTitles}
            dataSource={userHelper.merchant}
            initialValue={get(user, 'user_merchant_access')}
            setTouched={this.onFieldTouched}
            transferProps={this.transferProps}
          />
        </div>
      </div>
    )
  }
}

PageMerchants.propTypes = {
  readOnly: PropTypes.bool,
  tabKey: PropTypes.string,
  userHelper: PropTypes.object,
  user: PropTypes.object,
  onFieldTouched: PropTypes.func,
  setActiveKey: PropTypes.func,
}

PageMerchants.defaultProps = {
  readOnly: false,
  tabKey: null,
  userHelper: { merchant: [] },
  user: null,
  onFieldTouched: (is, callback) => callback(),
  setActiveKey: (tabKey, callback = () => null) => callback(),
}

export default PageMerchants