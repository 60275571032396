import { BaseList } from "App/Screens/SubModules/Component";
import descriptor from "App/Screens/Job/BuildingTypes/descriptor";

class BuildingTypeList extends BaseList {
  constructor(props) {
    super(props)

    this.appName = 'jobs'
    this.modelName = 'buildingtype'
    this.propsList = {
      avatar: 'name',
      pageTitle: 'Building Type',
      descriptor,
      masterKey: 'module-job-buildingType',
    }
  }
}

export default BuildingTypeList