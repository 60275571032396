import Auth from "App/Utils/Auth";

export default {
  userReducer(state = Auth.GetCredential(), action) {
    switch (action.type) {
      case "CreateUserSession":
        state = {
          ...state, // jangan replace user (dari request me: UpdateUserInformation)
          username: action.payload.username,
          token: action.payload.access_token,
          refreshToken: action.payload.refresh_token,
          isAuth: true,
          inAuth: false
          // profile: { ...state.profile },
          // access: { ...state.access }
        };
        Auth.WriteCredential(state);
        break;

      case "RemoveUserSession":
        state = {
          ...state,
          username: null,
          token: null,
          refreshToken: null,
          isAuth: false,
          inAuth: false
        };
        Auth.RemoveCredential();
        break;

      case "UpdateUserSession":
        const currentUser = Auth.GetCredential();
        state = {
          username: currentUser.username,
          token: currentUser.token,
          refreshToken: currentUser.refreshToken,
          isAuth: true,
          inAuth: false,
          props: {
            email: currentUser.email,
            firstName: action.payload.first_name,
            lastName: action.payload.last_name,
            isSuperAdministrator: action.payload.isSuperAdministrator
          }
        };
        Auth.WriteCredential(state);
        break;

      case "StoreUserInformation":
        state = {
          ...state,
          profile: {
            ...state.profile,
            alias: action.payload.profile.alias,
            firstName: action.payload.profile.first_name,
            lastName: action.payload.profile.last_name,
            originSupplier: action.payload.profile.origin_supplier,
            phone: action.payload.profile.phone,
            email: action.payload.profile.email,
            address: action.payload.profile.address,
            address2: action.payload.profile.address2,
            city: action.payload.profile.city,
            postal: action.payload.profile.postal,
            isActive: action.payload.profile.is_active
          },
          access: {
            ...state.access,
            accessCode: action.payload.access.access_code,
            activeRole: action.payload.access.active_role,
            activeSupplier: !action.payload.is_superadmin
              ? action.payload.access.active_supplier
              : { name: "ESLNZ" },
            isAdministrator: action.payload.access.is_administrator,
            isSuperAdministrator: action.payload.is_superadmin,
            permissions: action.payload.access.permissions
          }
        };
        Auth.WriteCredential(state);
        break;

      case "UpdateUserInformation":
        state = {
          ...state,
          user: action.payload,
        };
        Auth.WriteCredential(state);
        break;

      case "AuthToggle":
        state = {
          ...state,
          inAuth: action.payload
        };
        break;

      default:
        break;
    }
    return state;
  }
};
