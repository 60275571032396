import { BaseRedirectMenu } from 'App/Screens/SubModules/Component'
import ProductMenu from './Menu'

class ProductRedirect extends BaseRedirectMenu {
  constructor(props) {
    super(props)

    this.menu = ProductMenu
  }
}

export default ProductRedirect