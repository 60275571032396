import {
  isString,
  get,
  find,
  orderBy,
  isObject,
  matchesProperty,
  unset,
} from "lodash";

import { requestV3, Lib, getValue } from "App/Utils";
import statusMapping from "./statusMapping";

const itemObj = {
  type: "validation",
  id: null,
  extraObj: null,
  originalObj: null,
};

function orderByStatus(item) {
  return statusMapping[item.status].order;
}

function resolve(fn, obj, { id, type }) {
  fn({
    ...obj,
    items: orderBy(
      obj.items,
      ["type", orderByStatus, "id"]
      // ["asc", "desc", "asc"]
    ),
    item: find(obj.items, { id, type }),
  });
}

function setProperties(item) {
  Object.defineProperties(item, {
    isFact: {
      get() {
        return item.type === "fact";
      },
    },
    isChecked: {
      get() {
        return getValue(
          item.originalObj,
          item.isFact
            ? "user_feedback_noted"
            : "source_object.feedback_checked",
          false
        );
      },
    },
    checkedBy: {
      get() {
        return getValue(
          item.originalObj,
          item.isFact
            ? "feedback_checked_by"
            : "source_object.feedback_checked_by",
          null
        );
      },
    },
    checked: {
      get() {
        return getValue(
          item.originalObj,
          item.isFact
            ? "feedback_checked_time"
            : "source_object.feedback_checked_time",
          null
        );
      },
    },
    isAdded: {
      get() {
        return getValue(
          item.originalObj,
          item.isFact
            ? "feedback_added_to_rule"
            : "source_object.feedback_added_to_rule",
          false
        );
      },
    },
    addedBy: {
      get() {
        return getValue(
          item.originalObj,
          item.isFact
            ? "feedback_added_to_rule_by"
            : "source_object.feedback_added_to_rule_by",
          null
        );
      },
    },
    added: {
      get() {
        return getValue(
          item.originalObj,
          item.isFact
            ? "feedback_added_to_rule_time"
            : "source_object.feedback_added_to_rule_time",
          null
        );
      },
    },
    createdBy: {
      get() {
        return getValue(
          item.isFact ? item.extraObj : item.originalObj,
          "created_by"
        );
      },
    },
    created: {
      get() {
        return getValue(
          item.isFact ? item.extraObj : item.originalObj,
          "created"
        );
      },
    },
    modifiedBy: {
      get() {
        return getValue(
          item.isFact ? item.extraObj : item.originalObj,
          "modified_by"
        );
      },
    },
    modified: {
      get() {
        return getValue(
          item.isFact ? item.extraObj : item.originalObj,
          "modified"
        );
      },
    },
    status: {
      get() {
        const oriStat = getValue(
          item.originalObj,
          item.isFact ? "feedback_status" : "source_object.feedback_status",
          null
        );

        return oriStat ? oriStat : item.isChecked ? "approve" : "new";
      },
    },
  });

  return item;
}

export default function (options) {
  options = {
    type: null, // 'validation' | 'fact'
    jobId: null,
    itemId: null,
    ...options,
  };

  const items = [];
  let resObj = null;

  return new Promise((resolve) => {
    requestV3
      .bulk(
        [
          {
            key: "job",
            urlKey: "module-expertSystem-feedback-detail",
            args: [options.jobId],
          },
          {
            key: "validation",
            urlKey: "module-expertSystem-feedback-job-validation-read",
            args: [options.jobId],
          },
          {
            key: "fact",
            urlKey: "module-expertSystem-feedback-job-plan-fact-read",
            params: { job: options.jobId, paginate: false },
          },
        ],
        {
          returnResultObject: true,
          async onSuccess({ fact, validation, job }) {
            resObj = {
              job: {
                ...job.result,
                full_name: `#${job.result.pk}: ${job.result.name}`,
              },
              item: null,
              items,
            };

            const fValidations = validation.result.filter(
              (v) => v.source_object.feedback_object
            );

            for (const val of fValidations) {
              const decFeedbackObj = isString(val.source_object.feedback_object)
                ? await Lib.decodeEnc(val.source_object.feedback_object)
                : val.source_object.feedback_object;
              let parsedRecord = val.source_object.record;

              if (val.field === "tag" && isString(val.source_object.record))
                parsedRecord = await Lib.decodeEnc(val.source_object.record);

              if (isObject(decFeedbackObj.expected))
                decFeedbackObj.expected = {
                  ...decFeedbackObj.expected,
                  pk: +decFeedbackObj.expected.key,
                  name: decFeedbackObj.expected.label,
                };

              const obj = {
                ...itemObj,
                id: val.pk,
                originalObj: {
                  ...val,
                  source_object: {
                    ...val.source_object,
                    ...get(val, "source_object.source"),
                    ...get(val, "source_object.source.extra"),
                    feedback_object: decFeedbackObj,
                    parsedRecord,
                  },
                },
              };

              unset(obj, "originalObj.source_object.source");
              unset(obj, "originalObj.source_object.extra");

              items.push(setProperties(obj));
            }
            // }

            // if (withFact)
            requestV3
              .bulk(
                fact.result.map((f) => ({
                  urlKey: "module-expertSystem-feedback-job-plan-fact-file",
                  args: [f.pk],
                  extra: f,
                })),
                {
                  returnResultObject: true,
                  resultType: "array",
                  onSuccess(res) {
                    const fFacts = res.filter((f) => f.result.user_feedback);

                    for (const fFact of fFacts)
                      items.push(
                        setProperties({
                          ...itemObj,
                          type: "fact",
                          id: fFact.extra.pk,
                          extraObj: fFact.extra,
                          originalObj: fFact.result,
                        })
                      );

                    resolve({
                      ...resObj,
                      items: orderBy(resObj.items, [
                        "type",
                        orderByStatus,
                        "id",
                      ]),
                      item: find(resObj.items, {
                        id: options.itemId,
                        type: options.type,
                      }),
                    });
                  },
                  onFailed(err) {
                    resolve({
                      error: true,
                      errResObj: find(err, { success: false }).result,
                    });
                  },
                }
              )
              .then();
            // else resolve(r, resObj, options.itemId);
          },
          onFailed(err) {
            resolve({
              error: true,
              errResObj: find(
                Object.entries(err),
                matchesProperty("1.success", false)
              )[1].result,
            });
          },
        }
      )
      .then();
  });
}
