export default {
  bulkOptions: [
    {
      key: 'delete_bulk',
      field: 'delete_bulk',
      value: 'true',
      label: 'Delete',
      type: 'danger',
      permissionCode: 'estimation.delete_exportmapping',
    },
    {
      key: 'set_to_active',
      field: 'status',
      value: 'active',
      label: 'Set to Active',
      permissionCode: 'estimation.change_exportmapping',
    }, {
      key: 'set_to_inactive',
      field: 'status',
      value: 'inactive',
      label: 'Set to Inactive',
      permissionCode: 'estimation.change_exportmapping',
    }, {
      key: 'set_to_unpublished',
      field: 'status',
      value: 'unpublished',
      label: 'Set to Unpublished',
      permissionCode: 'estimation.change_exportmapping',
    }
  ],
  exportImportOptions: {
    export: {
      directly: true,
      url_name: 'api:admin:estimation:exportmapping:export',
      filter_by: [
        {
          name: 'limit',
          label: 'Limit',
          type: 'choices',
          choices: [
            ['all', 'All'],
            ['100', '100'],
            ['500', '500'],
          ],
        },
        {
          name: 'status',
          label: 'Status',
          type: 'choices',
          choices: [
            ['', 'All'],
            ['active', 'Active'],
            ['inactive', 'Inactive'],
            ['unpublished', 'Unpublished'],
          ],
        },
        {
          name: 'merchant',
          label: 'Merchant',
          type: 'api_reference',
          api: {
            url_name: 'api:admin:jobs:merchant:autocomplete',
            key: 'pk',
            display: 'name',
          },
        },
        {
          name: 'subheading',
          label: 'Subheading',
          type: 'api_reference',
          api: {
            url_name: 'api:admin:estimation:subheading:autocomplete',
            key: 'pk',
            display: 'label',
          },
        },
        {
          name: 'usage',
          label: 'Usage',
          type: 'api_reference',
          api: {
            url_name: 'api:admin:estimation:lineusage:autocomplete',
            key: 'pk',
            display: 'label',
          },
        },
        {
          name: 'is_random_mapping',
          label: 'Is random mapping',
          type: 'switch',
        },
        {
          name: 'is_random_mapping_roof',
          label: 'Is random mapping roof',
          type: 'switch',
        },
        {
          name: 'is_random_mapping_frame',
          label: 'Is random mapping frame',
          type: 'switch',
        },
        {
          name: 'product_code_start',
          label: 'Product code start',
          type: 'text',
        },
        {
          name: 'product_code_end',
          label: 'Product code end',
          type: 'textarea',
        },
      ],
    },
  },
}