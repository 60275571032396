export default [
  {
    title: 'Template set',
    type: 'SelectQuery',
    required: true,
    data: 'templateSets',
    dataIndex: 'template_set',
    selectedKey: 'pk',
    selectedValue: 'name',
    initialValue: '',
    note_text: '',
    additional: 'edit|add',
    order: 0,
  }, {
    title: 'Label',
    required: true,
    dataIndex: 'label',
    type: 'text',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    order: 1,
  }, {
    title: 'Slug',
    type: 'text',
    data: '',
    dataIndex: 'slug',
    initialValue: '',
    note_text: '',
    additional: '',
    order: 2,
  }, {
    title: 'Code',
    type: 'text',
    data: '',
    dataIndex: 'code',
    initialValue: null,
    note_text: '',
    additional: '',
    order: 3,
  }, {
    title: 'Bm usage',
    type: 'text',
    data: '',
    initialValue: '',
    dataIndex: 'bm_usage',
    note_text: '',
    additional: '',
    order: 4,
  }, {
    title: 'Old id',
    type: 'text',
    data: '',
    initialValue: null,
    dataIndex: 'old_id',
    note_text: '',
    additional: '',
    order: 5,
  }, {
    title: 'Mfg subgroup',
    type: 'text',
    data: '',
    initialValue: null,
    note_text: '',
    additional: '',
    dataIndex: 'mfg_subgroup',
    order: 6,
  }
];
