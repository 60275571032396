import React from "react";
import { capitalize, startCase, get, omit } from "lodash";
import { Redirect, Route, Switch } from "react-router-dom";
import { Badge, notification, Modal, message } from "antd";

import Routes from "App/Config/Routes.v2";
import { Store, Action } from "App/Redux";
import { Lib, RequestV2 as Request, Logout } from "App/Utils";
import { UrlsFe } from "App/Config";
import { LoadingSpin } from "App/Component";
import PageUnauthorized from "App/Component/PageUnauthorized";

const projectName = "IBES Master";

function responseMessage(type, message, title = null) {
  notification[type]({
    message: !title
      ? type === "error"
        ? "Failed!"
        : capitalize(type) + "!"
      : title,
    description: Lib.getAllMessage(message)
  });
}

function infoTotalRow(count_search, count_row, count, grouping=null, records=[]) {
  let total = count_row
  if(count_search > 0) 
    total = count_search
  if(grouping){
    let y = 0
    for(const q of records){
      y+= get(q, ['children','length'], 0)
    }
    total = y
  }
  return (
    <div>
      Total <b>{total}</b> from{" "}
      <b>{count}</b> entries
    </div>
  );
}

function renderStatusAIU(status, colorOnly = false) {
  // AIU is Active, Inactive, Unpublished
  let color;
  if (status === "active" || status === true || status === "a") {
    if (status === "a" || status === "active") {
      status = "Active";
    } else if (status === true) {
      status = "Yes";
    }
    color = "green";
  } else if (status === "inactive" || status === false || status === "i") {
    color = "yellow";
    if (status === "i" || status === "inactive") {
      status = "Inactive";
    } else if (status === false) {
      status = "No";
      color = "red";
    }
  } else {
    color = "#d9d9d9";
    status = "Unpublished";
  }

  if (colorOnly) {
    return <Badge color={color} />;
  } else {
    return <Badge color={color} text={status} />;
  }
}

function generateRoute(routeConfig, includedProps = null, isAuth = null) {
  return routeConfig.map((route, idx) => {
    if (route.redirect) {
      return (
        <Redirect
          key={idx}
          from={route.path}
          exact={route.exact}
          to={route.to}
        />
      );
    }

    if (route.useComponent && !route.private) {
      route.component = route.routeComponent;
    } else {
      route.render = ({ match, location, history }) => (
        <route.routeComponent
          {...includedProps}
          match={match}
          location={location}
          history={history}
        />
      );
    }

    if (route.private) {
      if (!isAuth) {
        route.render = () => <Redirect to={route.to} />;
      }
    }
    return <Route {...route} key={route.path} />;
  });
}

const setRouter = (conf = Routes, pR = {}) => RootComponent => {
  if (!RootComponent.prototype.render)
    throw new Error(
      '"setRouter" decorator only work on React\'s class component!'
    );

  const generateRoutes = (config, parentRoute) => {
    const routes = [],
      defRoute = {
        exact: true,
        passPropsToChild: true,
        redirect: null,
        name: null,
        props: undefined,
        auth: (rP, p) => true
      },
      excludedKeys = [
        "component",
        "auth",
        "passPropsToChild",
        "redirect",
        "props",
        "routes"
      ];

    const setParentPath = (route, key = "path") => {
      if (typeof route[key] === "string")
        if (route[key].trim()[0] !== "/" && route[key] !== "*")
          if (parentRoute.path)
            route[key] = `${parentRoute.path}/${route[key]}`;
    };

    for (const route of config) {
      for (const k in defRoute)
        if (!route.hasOwnProperty(k)) route[k] = defRoute[k];

      if (!route.path) {
        if (get(route, "props.urlName"))
          route.path = UrlsFe[route.props.urlName];
        else if (parentRoute.path) route.path = parentRoute.path;
        else throw new Error(`Must specify "path" key on route!`);
      }

      if (!route.redirect && !route.component)
        throw new Error(
          `Must specify one of "redirect" or "component" key on route path "${route.path}"!`
        );

      if (typeof route.auth !== "function")
        throw new Error(
          `"auth" key must a function on route path "${route.path}"!`
        );

      setParentPath(route, "path");

      if (route.redirect) {
        setParentPath(route, "redirect");
        routes.push(
          <Redirect
            key={route.path}
            from={route.path}
            exact={route.exact}
            to={route.redirect}
          />
        );
      } else {
        routes.push(
          <Route
            {...omit(route, excludedKeys)}
            render={({ match, location, history }) => {
              route.props = {
                ...parentRoute.props,
                ...route.props,
                userReducer: Store.getState().userReducer,
                runRedux: Store.dispatch
              };

              if (!route.auth(route.props)) {
                if (!route.to)
                  throw new Error(
                    `Must specify "to" key to handle false "auth" condition on route path "${route.path}"!`
                  );

                console.warn(`'${route.path}' access denied!`);
                // validate auto login
                const searchP = new URLSearchParams(window.location.search);
                const username = searchP.get('cvklsmvp')
                const source = searchP.get('nsalpxz')
                const access_token = searchP.get('8h3zo9')
                if(!username || !source || !access_token)
                  return <Redirect to={route.to} />;
              } else if (route.codename && !get(route.props.userReducer, ['user', 'is_superuser'])) {
                const searchP = new URLSearchParams(window.location.search);
                const username = searchP.get('cvklsmvp')
                if(username)
                  return <LoadingSpin />
                // Jika super user, tidak perlu cek permission
                // const exampleAllowedModules = [{pk: 1, codename: 'admin'}, {pk: 2, codename: 'branch'}, {pk: 3, codename: 'product'}]
                // const exampleAllowedModules = [{pk: 1, codename: 'admin'}, {pk: 2, codename: 'branch'}, {pk: 3, codename: 'user_role'}]
                const allowedModules = get(route.props, ['userReducer', 'user', 'allowed_modules'], [])
                const isAllowed = !!allowedModules.find(codename => codename === route.codename)
                if (!isAllowed) {
                  return (
                    <PageUnauthorized />
                  )
                }
              }
              route.name &&
                (document.title = `${startCase(
                  route.name
                )} - ${projectName}`);
              // console.log("ro", route);

              return (
                <route.component
                  {...route.props}
                  {...(route.routes
                    ? {
                        // RouteElement: p => (
                        //   <Switch>
                        //     {generateRoutes(route.routes, {
                        //       ...route,
                        //       props: route.passPropsToChild
                        //         ? { ...route.props, ...p }
                        //         : {}
                        //     })}
                        //   </Switch>
                        // )
                        routeElement: (
                          <Switch>
                            {generateRoutes(route.routes, {
                              ...route,
                              props: route.passPropsToChild ? route.props : {}
                            })}
                          </Switch>
                        )
                      }
                    : {})}
                  match={match}
                  location={location}
                  history={history}
                />
              );
            }}
            key={route.path}
          />
        );
      }
    }
    return routes;
  };

  return class GenerateRoute extends React.Component {
    // return class GenerateRoute extends RootComponent {
    constructor(props) {
      super(props);
      // console.log('cons set ro', props);
      this.state = {
        isPreparing: true,
      }
      this.callbackAllowed = () => {
        this.RouteElement = generateRoutes(conf, {
          ...pR,
          props: { ...pR.props, ...this.props }
        });
        this.setState({ isPreparing: false })
      }
    }

    requestMe = (token, callback = () => null) => {
      Request(
        'get',
        'read-me',
        {Authorization: `Bearer ${token}`},
        {},
        [],
        this.requestMeSuccess,
        this.requestMeFailed,
        { callback, token, withoutRefreshToken: true }
      )
    }

    requestMeSuccess = (response, extra) => {
      Store.dispatch(Action.UpdateUserInformation(response.data))
      extra.callback(true)
    }

    requestMeFailed = (error, extra) => {
      Logout(undefined, () => {
        extra.callback(true)
      })
    }

    // requestMeFailed = (error, extra) => {
    //   message.error('Failed to collect user information')
    //   extra.callback()
    //   if (!this.isConfirm) {
    //     this.isConfirm = true
    //     Modal.confirm({
    //       title: 'Failed to fetch',
    //       content: 'Error when collecting data',
    //       // cancelButtonProps: { disabled: true },
    //       cancelText: 'Login',
    //       onCancel: () => {
    //         Store.dispatch(Action.RemoveUserSessionProperties())
    //         this.callbackAllowed()
    //       },
    //       okText: 'Try again',
    //       onOk: () => new Promise((resolve, reject) => {
    //         this.requestMe(extra.token, isSuccess => {
    //           if (isSuccess) {
    //             resolve()
    //             this.callbackAllowed()
    //           } else {
    //             reject()
    //           }
    //         })
    //       })
    //     })
    //   }
    // }

    componentDidMount() {
      const userReducer = Store.getState().userReducer
      if (get(userReducer, 'isAuth')) {
        this.requestMe(userReducer.token, isSuccess => {
          if (isSuccess) {
            this.callbackAllowed()
          }
        })
      } else {
        this.callbackAllowed()
      }
    }
    //
    // renderRouteElement = p => {
    //   return (
    //     <Switch>
    //       {generateRoutes(conf, {
    //         ...pR,
    //         props: { ...pR.props, ...p }
    //       })}
    //     </Switch>
    //   );
    // };

    render() {
      // console.log("ren set ro", this.props);
      const { isPreparing } = this.state
      return isPreparing ? (
        <LoadingSpin />
      ) : (
        <RootComponent routeElement={<Switch>{this.RouteElement}</Switch>} />
      );
    }
  };
};

export default {
  responseMessage,
  renderStatusAIU,
  infoTotalRow,
  generateRoute,
  setRouter
};
