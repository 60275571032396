export default [
  {
    title: 'Name',
    type: 'text',
    data: '',
    required: true,
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'name',
    order: 0,
  }, {
    title: 'Type1 m2 from',
    type: 'text',
    data: '',
    initialValue: null,
    note_text: '',
    additional: '',
    dataIndex: 'type1_m2_from',
    order: 1,
  }, {
    title: 'Type1 m2 to',
    type: 'text',
    data: '',
    initialValue: null,
    note_text: '',
    additional: '',
    dataIndex: 'type1_m2_to',
    order: 2,
  }, {
    title: 'Type1 hour',
    type: 'text',
    data: '',
    initialValue: null,
    note_text: '',
    additional: '',
    dataIndex: 'type1_hours',
    order: 3,
  }, {
    title: 'Type2 m2 from',
    type: 'text',
    data: '',
    initialValue: null,
    note_text: '',
    additional: '',
    dataIndex: 'type2_m2_from',
    order: 4,
  }, {
    title: 'Type2 m2 to',
    type: 'text',
    data: '',
    initialValue: null,
    note_text: '',
    additional: '',
    dataIndex: 'type2_m2_to',
    order: 5,
  }, {
    title: 'Type2 hour',
    type: 'text',
    data: '',
    initialValue: null,
    note_text: '',
    additional: '',
    dataIndex: 'type2_hours',
    order: 6,
  },
]
