import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Avatar, Typography, Divider } from 'antd'
import { get } from 'lodash'
import styled from 'styled-components'
import Card from './Card'

const UserAvatar = require('App/Assets/img/user-avatar.svg')
const iconPersonalization = require('App/Assets/img/icon-personalization.png')
const iconSecurity = require('App/Assets/img/icon-security.png')
const iconStorage = require('App/Assets/img/icon-storage.png')

class GeneralProfile extends React.PureComponent {
  render() {
    const { profile, setSelectedMenu } = this.props

    return (
      <Col>
        <Row type='flex' style={{ borderBottom: '1px solid #e8e8e8' }}>
          <Col style={{ width: '200px', padding: '12px' }}>
            <Row>
              <Row type='flex' justify='center' style={{ marginBottom: '4px' }}>
                <Avatar
                  size={120}
                  icon="user"
                  src={profile.image_thumbnail || UserAvatar}
                  alt="Profile photo"
                />
              </Row>
              <Row type='flex' justify='center' style={{ marginBottom: '4px' }}>
                <Col style={{ fontSize: '24px', fontWeight: 500, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {profile.username}
                </Col>
              </Row>
              <Row type='flex' justify='center' style={{ marginBottom: '4px' }}>
                {profile.is_superuser && (
                  <Col style={{ fontSize: '12px', fontWeight: 500, backgroundColor: '#1890ff', color: '#fff', padding: '0 12px 2px 12px', borderRadius: '14px' }}>
                    Super User
                  </Col>
                )}
              </Row>
            </Row>
          </Col>
          <Col style={{ width: 'calc(100% - 200px)', padding: '12px' }}>
            <Typography.Title level={3} type='secondary' style={{ padding: '0 6px'}}>
              Information
            </Typography.Title>
            <Row type='flex'>
              <Col xs={24} sm={24} md={9}>
                <div style={{ padding: '6px' }}>
                  <Row style={{ marginBottom: '8px' }}>
                    <span style={{ fontWeight: 500, color: '#8c8c8c' }}>Full Name: </span>
                    <span style={{ fontWeight: 'bold', marginLeft: '4px' }}>{profile.full_name}</span>
                  </Row>
                  <Row style={{ marginBottom: '8px' }}>
                    <span style={{ fontWeight: 500, color: '#8c8c8c' }}>Position: </span>
                    <span style={{ fontWeight: 'bold', marginLeft: '4px' }}>{get(profile.staff_position, 'name', '-')}</span>
                  </Row>
                  <Row style={{ marginBottom: '8px' }}>
                    <span style={{ fontWeight: 500, color: '#8c8c8c' }}>Email: </span>
                    <span style={{ fontWeight: 'bold', marginLeft: '4px' }}>{profile.email || '-'}</span>
                  </Row>
                </div>
              </Col>
              <Col xs={24} sm={24} md={15}>
                <Row type='flex'>
                  <Col xs={24} sm={24} md={8}>
                    <Row style={{ marginBottom: '6px', color: '#8c8c8c', fontWeight: 500 }}>
                      Home Branch:
                    </Row>
                    <div style={{ fontSize: '26px', fontWeight: 500, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                      {profile.home_branch.name}
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Row style={{ marginBottom: '6px', color: '#8c8c8c', fontWeight: 500 }}>
                      Max Jobs:
                    </Row>
                    <div style={{ fontSize: '26px', fontWeight: 500, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                      {profile.max_jobs}
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Row style={{ marginBottom: '6px', color: '#8c8c8c', fontWeight: 500 }}>
                      Skill Level
                    </Row>
                    <div style={{ fontSize: '26px', fontWeight: 500, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                      {profile.skill_level}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* <div className="text-center">
          <Row type='flex' justify='center'>
            <Col style={{ position: 'relative' }}>
              <Avatar
                size={180}
                icon="user"
                src={profile.image_thumbnail || UserAvatar}
                alt="Profile photo"
                className="mb-4"
              />
            </Col>
          </Row>
          <Typography.Title level={2}>
            Welcome, {profile.full_name}
            <span style={{ fontWeight: 300 }}>
              &nbsp;({get(profile.staff_position, 'name')})
            </span>
          </Typography.Title>
          <H4>See your personal info and the access granted to you</H4>
        </div> */}
        <div style={{ marginTop: '24px' }}>
          <Row type='flex' gutter={[24, 24]}>
            <Col xs={24} sm={24} md={12} xxl={8} style={{ display: 'flex' }}>
              <Card
                keyMenu='edit-profile'
                title='Personal information'
                content='See the data in your profile and change your personal data.'
                imgSrc={iconPersonalization}
                txtFooter='Manage my profile'
                onClickItem={setSelectedMenu}
              />
            </Col>
            <Col xs={24} sm={24} md={12} xxl={8} style={{ display: 'flex' }}>
              <Card
                keyMenu='change-password'
                title='Security'
                content='Protect your account now by set unique password.'
                imgSrc={iconSecurity}
                txtFooter='Change my password'
                onClickItem={setSelectedMenu}
              />
            </Col>
            <Col xs={24} sm={24} md={12} xxl={8} style={{ display: 'flex' }}>
              <Card
                keyMenu='permission-profile'
                title='Permission'
                content='Do you already know what you can do here? Try opening what permissions you have by tapping the "See details..." button below.'
                imgSrc={iconStorage}
                txtFooter='See details...'
                onClickItem={setSelectedMenu}
              />
            </Col>
          </Row>
        </div>
      </Col>
    )
  }
}

GeneralProfile.propTypes = {
  profile: PropTypes.object.isRequired,
  setSelectedMenu: PropTypes.func,
}

GeneralProfile.defaultProps = {
  setSelectedMenu: () => null,
}

export default GeneralProfile

const H4 = styled.div`
  font-family: Roboto,Arial,sans-serif;
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  margin-top: 16px;
`