export default {
  "app_label": "jobs",
  "model_name": "tagmerchant",
  "pagination": true,
  "columns": [
    {
      "name": "merchant",
      "label": "Merchant",
      "child_path": "name",
      "search": true,
      "sort": true
    },
    {
      "name": "excerpt",
      "label": "Excerpt",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": null,
      "search": false,
      "sort": false,
    },
    {
      "name": "modified",
      "label": "Modified",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "datetime",
      "search": false,
      "sort": true
    }
  ],
  "filter_by": [
    {
      "label": "Tag",
      "name": "tag",
      "type": "api_reference",
      "api": {
        "url_name": "api:admin:jobs:tag:autocomplete",
        "key": "pk",
        "display": "label",
        "pagination": true
      },
      paramProps: { allow_override: true },
    },
    {
      "label": "Merchant",
      "name": "merchant",
      "type": "api_reference",
      "api": {
        "url_name": "api:admin:jobs:merchant:autocomplete",
        "key": "pk",
        "display": "name",
        "pagination": true
      }
    },
  ],
  "export_import": null
}