export default [
  {
    title: 'Template set',
    type: 'SelectQuery',
    required: true,
    data: 'templateSets',
    dataIndex: 'template_set',
    selectedKey: 'pk',
    selectedValue: 'name',
    initialValue: '',
    note_text: '',
    additional: 'edit|add',
    order: 0,
  }, {
    title: 'Code',
    type: 'text',
    data: '',
    required: true,
    dataIndex: 'code',
    initialValue: '',
    note_text: '',
    additional: '',
    // maxLength: '4',
    order: 1,
  }, {
    title: 'Name',
    type: 'text',
    data: '',
    required: true,
    dataIndex: 'name',
    initialValue: '',
    note_text: '',
    additional: '',
    order: 2,
  }, {
    title: 'Status',
    type: 'select',
    disableAllowClear: true,
    data: [
      {
        alias: 'active',
        value: 'Active - Anyone can use'
      }, {
        alias: 'inactive',
        value: 'Inactive - No one can use'
      }, {
        alias: 'unpublished',
        value: 'Unpublished - Only certain people can use'
      }
    ],
    initialValue: 'active',
    note_text: '',
    dataIndex: 'status',
    additional: '',
    fieldKey: 'status',
    editable: true,
    order: 3,
  }, {
    title: 'Groups',
    type: 'select_multiple',
    data: 'productGroups',
    dataIndex: 'groups',
    initialValue: '',
    note_text: '',
    additional: 'add',
    // minSelected: 2,
    // maxSelected: 3,
    order: 4,
    selectedKey: 'pk',
    selectedValue: 'name',
  }, {
    title: 'Unit',
    type: 'SelectQuery',
    data: 'units',
    required: true,
    dataIndex: 'unit',
    selectedKey: 'pk',
    selectedValue: 'symbol',
    initialValue: '',
    note_text: 'Unit of measurement for this product.',
    additional: 'edit|add',
    order: 5,
  }, {
    title: 'For each',
    type: 'number',
    data: '',
    dataIndex: 'for_each',
    initialValue: null,
    note_text: '',
    additional: '',
    // minValue: 5,
    // maxValue: 15,
    // isDecimal: true,
    // decimalPlaces: 4,
    order: 6,
  }, {
    title: 'Horizontal',
    type: 'number',
    dataIndex: 'horizontal',
    data: '',
    initialValue: null,
    note_text: '',
    additional: '',
    step: 0.1,
    // maxValue: 9,
    // isDecimal: true,
    order: 7,
  }, {
    title: 'Vertical',
    type: 'number',
    data: '',
    dataIndex: 'vertical',
    initialValue: null,
    note_text: '',
    additional: '',
    // maxDecimal: 3,
    // isDecimal: true,
    // decimalPlaces: 3,
    order: 8,
  }, {
    title: 'M2',
    type: 'number',
    data: '',
    dataIndex: 'm2',
    initialValue: null,
    note_text: '',
    additional: '',
    step: 0.001,
    // minValue: 10,
    order: 9,
  }, {
    title: 'M3',
    type: 'number',
    data: '',
    dataIndex: 'm3',
    initialValue: null,
    note_text: '',
    additional: '',
    step: 0.001,
    // minValue: 0,
    // maxValue: 1,
    // decimalPlaces: 10,
    order: 10,
  }, {
    title: 'Weight',
    type: 'number',
    data: '',
    dataIndex: 'weight',
    initialValue: null,
    note_text: '',
    additional: '',
    order: 11,
  }, {
    title: 'Timber M3',
    type: 'number',
    data: '',
    dataIndex: 'timber_m3',
    initialValue: null,
    note_text: '',
    additional: '',
    order: 12,
    step: 0.0001,
  }, {
    title: 'Paint',
    type: 'number',
    dataIndex: 'paint',
    data: '',
    initialValue: null,
    note_text: '',
    additional: '',
    order: 13,
    step: 0.001,
  }, {
    title: 'Old Code',
    type: 'number',
    data: '',
    dataIndex: 'old_code',
    initialValue: null,
    note_text: '',
    additional: '',
    order: 14,
  }, {
    title: 'Fixings',
    type: 'number',
    data: '',
    dataIndex: 'fixings',
    initialValue: null,
    note_text: '',
    additional: '',
    order: 15,
    step: 0.001,
  }, {
    title: 'Factor1',
    type: 'number',
    data: '',
    dataIndex: 'factor1',
    initialValue: null,
    note_text: '',
    additional: '',
    order: 16,
    step: 0.0001,
  }, {
    title: 'Factor2',
    type: 'number',
    data: '',
    dataIndex: 'factor2',
    initialValue: null,
    note_text: '',
    additional: '',
    order: 17,
    step: 0.0001,
  }, {
    title: 'Factor3',
    type: 'number',
    data: '',
    dataIndex: 'factor3',
    initialValue: null,
    note_text: '',
    additional: '',
    order: 18,
    step: 0.0001,
  }, {
    title: 'Factor4',
    type: 'number',
    data: '',
    dataIndex: 'factor4',
    initialValue: null,
    note_text: '',
    additional: '',
    order: 19,
    step: 0.0001,
  }, {
    title: 'Factor5',
    type: 'number',
    data: '',
    dataIndex: 'factor5',
    initialValue: null,
    note_text: '',
    additional: '',
    order: 20,
    step: 0.0001,
  }, {
    title: 'Each',
    type: 'number',
    data: '',
    dataIndex: 'each',
    initialValue: null,
    note_text: '',
    additional: '',
    order: 21,
  }, {
    title: 'Group No',
    type: 'text',
    data: '',
    dataIndex: 'groupno',
    initialValue: '',
    note_text: '',
    additional: '',
    order: 22,
  }, {
    title: 'Group Code',
    type: 'text',
    data: '',
    dataIndex: 'gcode',
    initialValue: '',
    note_text: '',
    additional: '',
    order: 23,
  }, {
    title: 'Labour',
    type: 'number',
    data: '',
    dataIndex: 'labour',
    initialValue: null,
    note_text: '',
    additional: '',
    order: 24,
  }, {
    title: 'Labour Unit',
    type: 'SelectQuery',
    data: 'units',
    initialValue: '',
    dataIndex: 'labour_unit',
    selectedKey: 'pk',
    selectedValue: 'symbol',
    note_text: 'Unit of measurement for labour.',
    additional: 'edit|add',
    order: 25,
  }, {
    title: 'Supplier',
    type: 'text',
    data: '',
    dataIndex: 'supplier',
    initialValue: '',
    note_text: '',
    additional: '',
    order: 26,
  }, {
    title: 'Import session',
    type: 'text',
    data: '',
    dataIndex: 'import_session',
    initialValue: '',
    note_text: '',
    additional: '',
    order: 27,
  }, {
    title: 'Timber product',
    type: 'checkbox',
    data: '',
    dataIndex: 'timber_product',
    initialValue: '',
    note_text: '',
    additional: '',
    order: 28,
  }, {
    title: 'Special notes',
    type: 'text',
    data: '',
    dataIndex: 'special_notes',
    initialValue: '',
    note_text: '',
    additional: '',
    order: 29,
  },
  {
    title: 'Expensive',
    type: 'checkbox',
    dataIndex: 'is_expensive',
    initialValue: false,
  },
];
