export default [
  {
    title: 'Code',
    type: 'text',
    dataIndex: 'code',
    data: '',
    required: true,
    initialValue: '',
    note_text: 'Internal code for the symbol. Mostly for typing.',
    additional: '',
    order: 0,
  }, {
    title: 'Name',
    type: 'text',
    dataIndex: 'name',
    data: '',
    required: true,
    initialValue: '',
    note_text: 'Description of the unit.',
    additional: '',
    order: 1,
  }, {
    title: 'Symbol',
    type: 'text',
    dataIndex: 'symbol',
    data: '',
    required: true,
    initialValue: '',
    note_text: 'Short symbol of the unit. Mostly for printing.',
    additional: '',
    order: 2,
  }, {
    required: true,
    title: 'Decimals',
    type: 'number',
    dataIndex: 'decimals',
    data: '',
    initialValue: '0',
    note_text: '',
    additional: '',
    order: 3,
  }, {
    title: 'Pinepac',
    type: 'text',
    dataIndex: 'pinepac',
    data: '',
    required: true,
    initialValue: '',
    note_text: 'Prostix non timber unit info',
    additional: '',
    order: 4,
  }
];