import React from 'react'
import PropTypes from 'prop-types'

class Card extends React.PureComponent {
  render() {
    const { keyMenu, title, content, imgSrc, txtFooter, onClickItem } = this.props

    return (
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <article style={{ display: 'flex', flexGrow: 1, flexShrink: 1 }}>
          <div style={{ border: '1px solid #e8e8e8', borderRadius: '8px', boxSizing: 'border-box', overflow: 'hidden', width: '100%' }}>
            <div style={{ display: 'flex', WebkitBoxOrient: 'vertical', flexDirection: 'column', width: '100%', height: '100%' }}>
              <div>
                <div>
                  <div style={{ padding: '24px 24px 16px 24px', height: '100%' }}>
                    <header style={{ display: 'flex' }}>
                      <div style={{ display: 'flex', WebkitBoxOrient: 'vertical', flexDirection: 'column', flexGrow: 1, flexShrink: 1, flexBasis: 0 }}>
                        <h2 style={{ lineHeight: '1.75rem', fontSize: '1.375rem' }}>
                          {title}
                        </h2>
                        <div style={{ flexGrow: 1, flexShrink: 1, fontFamily: 'Roboto,Arial,sans-serif', fontSize: '.875rem', fontWeight: 400, lineHeight: '1.25rem', wordBreak: 'break-word', color: '#5f6368', paddingTop: '8px' }}>
                          <div>{content}</div>
                        </div>
                      </div>
                      <div style={{ WebkitBoxAlign: 'center', alignItems: 'center', display: 'flex', WebkitBoxPack: 'center', justifyContent: 'center', flexGrow: 0, flexShrink: 0, marginLeft: '16px' }}>
                        <div>
                          {/* <style nonce="">{".HJOYVi12 {width: 96px; height: 96px;}"}</style> */}
                          <figure
                            // aria-hidden="true"
                            style={{ width: '96px', height: '96px', WebkitBoxAlign: 'center', WebkitBoxPack: 'center', alignItems: 'center', display: 'flex', justifyContent: 'center', overflow: 'hidden', cursor: 'pointer' }}
                            onClick={() => onClickItem(keyMenu)}
                          >
                            <img
                              src={imgSrc}
                              alt="no"
                              data-atf="false"
                              data-iml="4009.4999999855645"
                              style={{ height: '100%', width: 'auto' }}
                            />
                          </figure>
                        </div>
                      </div>
                    </header>
                  </div>
                </div>
              </div>
              <div style={{ marginTop: 'auto' }}>
                <div style={{ transition: 'padding-left 15ms ease-in-out' }}>
                  <div style={{ borderTop: '1px solid #e8e8e8' }} />
                </div>
                <style>{".VfPpkd-ksKsZd-XxIAqe:hover{background-color: #f5f5f5; cursor: pointer;}"}</style>
                <div
                  className="VfPpkd-ksKsZd-XxIAqe"
                  // jsaction="click:cOuCgd; mousedown:UX7yZ; mouseup:lbsD7e; mouseenter:tfO1Yc; mouseleave:JywGue; touchstart:p6p2H; touchmove:FwuNnf; touchend:yfqBxc; touchcancel:JMtRjd; focus:AHmuwe; blur:O22p3e; contextmenu:mg9Pef"
                  onClick={() => onClickItem(keyMenu)}
                >
                  <div
                    data-rid="10004"
                    data-nav-type="5"
                    style={{ padding: '17px 24px 18px 24px', height: '100%', display: 'block', textDecoration: 'none' }}
                  >
                    <div style={{ letterSpacing: '.01785714em', fontFamily: 'Roboto,Arial,sans-serif', fontSize: '.875rem', fontWeight: 500, lineHeight: '1.25rem', wordBreak: 'break-word', color: '#1890ff' }}>
                      {txtFooter}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
    )
  }
}

Card.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  imgSrc: PropTypes.string,
  txtFooter: PropTypes.string,
  onClickItem: PropTypes.func,
}

Card.defaultProps = {
  title: 'Title',
  content: '-',
  imgSrc: '',
  txtFooter: 'See details...',
  onClickItem: () => null,
}

export default Card