const prefillOpt = [
  { alias: 'custom type', value: 'Type (Custom)' },
  { alias: 'custom stud', value: 'Stud Centres (Custom)' },
  { alias: 'custom nogs', value: 'Nogs (Custom)' },
  { alias: 'custom top plate', value: 'Top Plate (Custom)' },
  { alias: 'custom pitch', value: 'Pitch (Custom)' },
  { alias: 'custom insulation', value: 'Insulation (Custom)' },
  { alias: 'custom notes', value: 'Notes (Custom)' },
  { alias: 'custom height', value: 'Height (Custom)' },
]

export default [
  {
    title: 'Name',
    type: 'text',
    dataIndex: 'name',
    fieldKey: 'name',
    order: 0,
    required: true,
    view: true,
  },
  {
    title: 'Prefill set Pattern',
    dataIndex: 'prefill_set_pattern',
    type: 'select_multiple_static',
    fieldKey: 'prefill_set_pattern',
    static_data: prefillOpt,
    order:1,
    inputProps: { mode: 'multiple', tokenSeparators: [','] },
    view: true,
  },
  {
    title: 'Use Static Prefill Set',
    type: 'checkbox',
    data: '',
    initialValue: false,
    fieldKey: 'use_static_prefill_set',
    // note_text: 'Default for specified role in branch? If not specified, the queues will be picked randomly.',
    additional: '',
    dataIndex: 'use_static_prefill_set',
    order: 3,
    view: true,
  },
  {
    title: 'Static Prefill Set',
    type: 'SelectQuery',
    dataIndex: 'static_prefill_set',
    data:'prefillSets',
    fieldKey: 'static_prefill_set',
    additional: 'edit|add',
    initialValue: '',
    selectedKey: 'pk',
    selectedValue: 'name',
    order: 2,
    view: true,
  },

];
