import descriptor from './descriptorOffline'

export default {
  key: 'jobtype',
  title: 'Job Type',
  icon: 'deployment-unit',
  manualFilter: { key: 'grade', value: 'pk' },
  contentProps: {
    urlTarget: '/job-management/job-type',
    appName: 'jobs',
    modelName: 'jobtype',
  },
  propsList: {
    avatar: 'name',
    masterKey: 'module-job-jobType',
    descriptor,
    availablePermissions: [], // jika array kosong, maka tidak ada filter. Jika tidak dikirim, default []
    // onAddClick: () => null, // can override or open urlTarget + /add
    // onDetailClick: () => null, // can override or open urlTarget + /:id
  }
}