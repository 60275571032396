import qs from "query-string";
import React from "react";
import PropTypes from "prop-types";
import { get, isEqual, has } from "lodash";
import { isEmpty } from "validate.js";
// import { Prompt } from "umi";

import { Lib } from "App/Utils";

const Base = Object.assign({}, React);

function getValue(obj, path, def) {
  return Lib.fillEmpty(get(obj, path, def), def);
}

function BaseFoo(react) {
  return class extends react {
    #isMounted = false;

    _timeout = null;
    _lazyDelay = null;
    _form = null;
    _loadingQueue = 0;
    _debug = false;

    initialValueMap = {
      // foo: {
      //  enum: ['m', 'w'] | default: 'm',
      //  path: 'state.foo',
      //  type: 'number' | 'array'
      // },
    };

    initialValues = {};

    storageKey = window.location.pathname;

    state = {
      loading: false,
      dummy: false,
      mountLazy: false,
    };

    static propTypes = {
      onRef: PropTypes.func,
    };

    static defaultProps = {
      onRef: window.noop,
      // onPressEnter: (e) => null,
    };

    get _isMounted() {
      return this.#isMounted;
    }

    get _isProductionMode() {
      return process.env.NODE_ENV === "production";
    }

    constructor(props) {
      super(props);
      this.showConsole("constructor");
      this.setLoading = this.setLoading.bind(this);
    }

    _setLoading(loading, callback = window.noop) {
      if (loading) {
        this._loadingQueue += 1;

        if (!this.state.loading) {
          this.setState({ loading }, callback);
          return;
        }
      } else {
        this._loadingQueue -= 1;

        if (this._loadingQueue < 1) {
          this.setState({ loading }, callback);
          return;
        }
      }

      callback();
    }

    _setStorage(obj) {
      // componentStorage.save({
      //   [this.storageKey]: { ...this.getStorage(), ...obj },
      // });
    }

    _getStorage() {
      // return getValue(componentStorage.get(), this.storageKey, null);
    }

    setStorage = (obj) => {
      this._setStorage(obj);
    };

    getStorage = () => {
      return this._getStorage();
    };

    _setMountLazy() {
      if (!this.state.mountLazy) this.setState({ mountLazy: true });
    }

    _reRender(callback) {
      if (this._isMounted)
        this.setState({ dummy: !this.state.dummy }, callback);
      else callback();
    }

    showConsole(str) {
      // this._debug &&
      //   konsole.log(
      //     str + " %c%s:",
      //     "color: #c41d7f;",
      //     this.constructor.name,
      //     this
      //   );
    }

    setMountedState(state, callback = undefined) {
      this._isMounted && this.setState(state, callback);
    }

    initState(state) {
      this.state = { ...this.state, ...state };
    }

    initInitialValues() {
      this.initialValues = this.generateFromURLParams();
    }

    setLoading(loading, callback = window.noop) {
      this._setLoading(loading, callback);
    }

    setMountLazy = () => {
      this._setMountLazy();
    };

    setEventListener(fnName) {}

    changeURLParams() {
      const urlSearchObj = qs.parse(window.location.search),
        validObj = {};
      let queryParams;

      for (const field in this.initialValueMap) {
        const varPath = getValue(this.initialValueMap, `${field}.path`, null);

        validObj[field] = getValue(
          this,
          varPath ? varPath : `state.${field}`,
          null
        );
      }

      queryParams = qs.stringify({ ...urlSearchObj, ...validObj });

      window.history.replaceState(
        null,
        "",
        `${window.location.pathname}${queryParams ? `?${queryParams}` : ""}`
      );
    }

    generateFromURLParams() {
      const urlSearchObj = qs.parse(window.location.search),
        validObj = {};
      for (const field in this.initialValueMap) {
        if (has(urlSearchObj, field))
          if (!isEmpty(this.initialValueMap[field].enum)) {
            if (this.initialValueMap[field].enum.includes(urlSearchObj[field]))
              validObj[field] = urlSearchObj[field];
            else validObj[field] = this.initialValueMap[field].enum[0];
          } else if (!isEmpty(urlSearchObj[field]))
            validObj[field] = urlSearchObj[field];
          else
            validObj[field] = getValue(
              this.initialValueMap,
              `${field}.default`,
              null
            );
        else
          validObj[field] = getValue(
            this.initialValueMap,
            `${field}.default`,
            null
          );
      }
      return validObj;
    }

    transformToNumber = (n) => {
      return +n;
    };

    reRender = (callback = window.noop) => {
      this._reRender(callback);
    };

    refForm = (node) => {
      this._form = node;
    };

    sortAscOrder = (a, b) => {
      return a.order - b.order;
    };

    sortAscModified = (a, b) => {
      return new Date(a.modified) - new Date(b.modified);
    };

    sortAscCreated = (a, b) => {
      return new Date(a.created) - new Date(b.created);
    };

    shouldFieldUpdate = (prevValues, curValues) => {
      return !isEqual(prevValues, curValues);
    };

    pressEnterHandler = (e) => {
      if (e.keyCode === 13) this.props.onPressEnter(e);
    };

    sleep = async (ms = 0) => {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    };

    didCatch(error, errorInfo) {}

    didUpdate(pP, pS) {}

    didMount() {}

    willUnmount() {}

    componentDidCatch(error, errorInfo) {
      this.didCatch(...arguments);
    }

    componentDidUpdate(pP, pS) {
      if (!isEmpty(this.initialValueMap)) this.changeURLParams();
      this.didUpdate(pP, pS);
    }

    componentDidMount() {
      this.showConsole("componentDidMount");
      this.setEventListener("addEventListener");
      this.#isMounted = true;

      if (this._lazyDelay && !this.state.mountLazy)
        this._timeout = setTimeout(this.setMountLazy, this._lazyDelay);

      if (this.props.onRef) this.props.onRef(this);

      if (!isEmpty(this.initialValueMap)) this.changeURLParams();

      this.didMount();
    }

    componentWillUnmount() {
      this.showConsole("componentWillUnmount");
      this.setEventListener("removeEventListener");
      this.#isMounted = false;

      if (this.props.onRef) this.props.onRef(undefined);

      this.willUnmount();
      clearTimeout(this._timeout);
    }

    isFieldsTouched = () => this._form && this._form.isFieldsTouched();

    // Documentation : https://v5.reactrouter.com/core/api/Prompt
    // renderPrompt = (props) => (
    //   <Prompt
    //     when={this.isFieldsTouched()}
    //     message={(window.location, action) =>
    //       get(props, "beforeMessage", window.noop)(window.location, action)
    //         ? true
    //         : "You will lose any unsaved changes. Continue?"
    //     }
    //     {...props}
    //   />
    // );

    render() {
      this.showConsole("render");

      return (
        <div className="text-center">
          Default rendered content from <span className="c-red">Base</span>{" "}
          component
        </div>
      );
    }
  };
}

// class extends BaseFoo(Base.Component) {}
// class BasePureComponent extends BaseFoo(Base.PureComponent) {}

Base.Component = class extends BaseFoo(Base.Component) {
  constructor(props) {
    super(props);
  }
};
Base.PureComponent = class extends BaseFoo(Base.PureComponent) {
  constructor(props) {
    super(props);
  }
};

export default Base;
