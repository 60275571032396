import { MEASURE_TYPES, MEASURE_COUNT_SHAPES } from "App/Config/Constants";

export default [
  {
    title: 'Section',
    required: true,
    type: 'SelectQuery',
    data: 'sections',
    initialValue: '',
    note_text: '',
    dataIndex: 'section',
    selectedKey: 'pk',
    selectedValue: 'name',
    dropdownClassName: 'width-auto',
    additional: 'edit|add',
    fieldKey: 'section',
    editable: true,
    width: 250,
    relations: ['subsection'],
    skip: true,
    order: 0,
  },
  {
    title: 'Subsection',
    required: true,
    type: 'SelectQuery',
    data: 'subsections',
    initialValue: '',
    selectedKey: 'pk',
    selectedValue: 'name',
    dropdownClassName: 'width-auto',
    note_text: '',
    dataIndex: 'subsection',
    additional: 'edit|add',
    relations: ['parents'],
    foreignKey: 'section',
    order: 1,
  }, {
    required: true,
    title: 'Order',
    type: 'number',
    data: '',
    initialValue: 0,
    note_text: '',
    additional: '',
    dataIndex: 'order',
    order: 2
  }, {
    title: 'Label',
    required: true,
    type: 'text',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'label',
    order: 3,
  }, {
    title: 'Status',
    type: 'select',
    disableAllowClear: true,
    data: [
      {
        alias: 'active',
        value: 'Active - Anyone can use'
      }, {
        alias: 'inactive',
        value: 'Inactive - No one can use'
      }, {
        alias: 'unpublished',
        value: 'Unpublished - Only certain people can use'
      }
    ],
    initialValue: 'active',
    note_text: '',
    additional: '',
    dataIndex: 'status',
    order: 4,
  }, {
    title: 'Is highlighted',
    type: 'checkbox',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'is_highlighted',
    order: 4,
  }, {
    title: 'Extra1 label',
    type: 'text',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'extra1_label',
    order: 5,
  }, {
    title: 'Extra1 Widget',
    type: 'select',
    data: [
      {
        alias: 'numeric',
        value: 'Numeric'
      }, {
        alias: 'text',
        value: 'Text'
      }, {
        alias: 'choices',
        value: 'Choices'
      }
    ],
    initialValue: null,
    note_text: '',
    additional: '',
    dataIndex: 'extra1_widget',
    order: 6,
  }, {
    title: 'Extra1 choices',
    type: 'textarea',
    data: '',
    initialValue: '',
    note_text: 'One line per choice. Format per line: "value|label".',
    additional: '',
    dataIndex: 'extra1_choices',
    order: 7,
  }, {
    title: 'Extra2 label',
    type: 'text',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'extra2_label',
    order: 8,
  }, {
    title: 'Extra2 Widget',
    type: 'select',
    data: [
      {
        alias: 'numeric',
        value: 'Numeric'
      }, {
        alias: 'text',
        value: 'Text'
      }, {
        alias: 'choices',
        value: 'Choices'
      }
    ],
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'extra2_widget',
    order: 9,
  }, {
    title: 'Extra2 choices',
    type: 'textarea',
    data: '',
    initialValue: '',
    note_text: 'One line per choice. Format per line: "value|label".',
    additional: '',
    dataIndex: 'extra2_choices',
    order: 10,
  }, {
    title: 'Extra2 Measure Type',
    type: 'select',
    data: MEASURE_TYPES,
    initialValue: '',
    note_text: 'Measurement tool type.',
    additional: '',
    dataIndex: 'extra2_measure_type',
  }, {
    title: 'Extra2 Measure Color',
    type: 'color',
    data: '',
    initialValue: '',
    dataIndex: 'extra2_measure_color',
    note_text: 'Measurement tool color.',
    additional: '',
  }, {
    title: 'Extra2 Measure Shape',
    type: 'select',
    data: MEASURE_COUNT_SHAPES,
    initialValue: '',
    dataIndex: 'extra2_measure_shape',
    note_text: 'Measurement COUNT tool shape.',
    additional: '',
  }, {
    title: 'Default usage',
    type: 'SelectQuery',
    data: 'lineUsages',
    initialValue: '',
    note_text: '',
    selectedKey: 'pk',
    selectedValue: 'label',
    additional: 'edit|add',
    dataIndex: 'default_usage',
    order: 11,
  }, {
    title: 'Default waste pct',
    type: 'number',
    data: '',
    initialValue: null,
    note_text: '',
    additional: '',
    dataIndex: 'default_waste_pct',
    order: 12,
    step: 0.01,
  }, {
    title: 'Default subheading',
    type: 'SelectQuery',
    data: 'subheadings',
    initialValue: '',
    selectedKey: 'pk',
    selectedValue: 'label',
    note_text: '',
    additional: 'add|edit',
    dataIndex: 'default_subheading',
    order: 13,
  }, {
    title: 'Formula',
    type: 'SelectQuery',
    data: 'formulas',
    initialValue: '',
    selectedKey: 'pk',
    selectedValue: 'name',
    note_text: '',
    additional: 'edit|add',
    dataIndex: 'formula',
    order: 14,
  }, {
    title: 'Formula Name',
    type: 'text',
    data: '',
    initialValue: '',
    note_text: 'only used for import',
    additional: '',
    dataIndex: 'formula_name',
    order: 15,
  }, {
    title: 'Parents',
    type: 'select_multiple',
    data: 'lineTemplates',
    initialValue: [],
    note_text: '',
    selectedKey: 'pk',
    selectedValue: 'label',
    additional: '',
    dataIndex: 'parents',
    foreignKey: 'subsection', // untuk initial paramProps
    order: 16,
  }, {
    title: 'Parents str',
    type: 'text',
    data: '',
    initialValue: '',
    note_text: 'only used for import',
    additional: '',
    dataIndex: 'parents_str',
    order: 17,
  }, {
    title: 'Dimension',
    /* data: [
      {
        alias: 1,
        value: 'For each'
      }, {
        alias: 2,
        value: 'Horizontal'
      }, {
        alias: 3,
        value: 'Vertical'
      }, {
        alias: 4,
        value: 'M2'
      }, {
        alias: 5,
        value: 'M3'
      }, {
        alias: 6,
        value: 'Weight'
      }, {
        alias: 7,
        value: 'Timber M3'
      }, {
        alias: 8,
        value: 'Paint'
      }, {
        alias: 9,
        value: 'Fixings'
      }, {
        alias: 10,
        value: 'Factor1'
      }, {
        alias: 11,
        value: 'Factor2'
      }, {
        alias: 12,
        value: 'Factor3'
      }, {
        alias: 13,
        value: 'Factor4'
      }, {
        alias: 14,
        value: 'Factor5'
      }, {
        alias: 15,
        value: 'Each'
      }
    ], */
    initialValue: null,
    note_text: '(legacy, only for import) bitmask (decimal of binary) for dimension',
    dataIndex: 'dimension',
    type: 'SelectQuery',
    data: 'dimensions',
    selectedKey: 'value',
    selectedValue: 'name',
    inputProps: {
      isPagination: false,
    },
  }, {
    title: 'Unit',
    type: 'SelectQuery',
    data: 'units',
    initialValue: '',
    note_text: '',
    selectedKey: 'pk',
    selectedValue: 'name',
    additional: 'edit|add',
    dataIndex: 'unit',
    order: 19,
  }, {
    required: true,
    title: 'Decimals',
    type: 'number',
    data: '',
    initialValue: 2,
    note_text: 'How many decimal places for Quantity (default=2).',
    additional: '',
    dataIndex: 'decimals',
    order: 20,
  }, {
    title: 'Has selected lengths',
    type: 'checkbox',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'has_selected_lengths',
    order: 21,
  }, {
    title: 'Has labour',
    type: 'checkbox',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'has_labour',
    order: 22,
  }, {
    title: 'Automatic',
    type: 'checkbox',
    data: '',
    initialValue: false,
    note_text: '',
    additional: '',
    dataIndex: 'automatic',
    order: 22,
  }, {
    title: 'Usage2',
    type: 'number',
    data: '',
    initialValue: null,
    note_text: '',
    additional: '',
    dataIndex: 'usage2',
    order: 23,
  }, {
    required: true,
    title: 'Default rounding multiplier',
    type: 'number',
    data: '',
    initialValue: '0.3',
    note_text: 'Products in this Line are sold only in multiples of this number. 1 means whole items, 0 means as is (no rounding up). E.g. 0.3 for lintels.',
    additional: '',
    dataIndex: 'default_rounding_multiplier',
    order: 24,
    step: 0.01,
  }, {
    title: 'Hide extra info',
    type: 'checkbox',
    data: '',
    initialValue: '',
    note_text: 'Hide extra info in qa summary screen',
    additional: '',
    dataIndex: 'hide_extra_info',
    order: 25,
  }, {
    title: 'Zero negative warning',
    type: 'checkbox',
    data: '',
    initialValue: true,
    note_text: '',
    additional: '',
    dataIndex: 'zero_negative_warning',
    order: 26,
  }

];
