export default [
  {
    title: 'Date',
    type: 'date',
    data: '',
    required: true,
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'date',
    order: 0,
  }, {
    title: 'Name',
    type: 'text',
    data: '',
    required: true,
    initialValue: '',
    note_text: 'e.g. "Christmas".',
    additional: '',
    dataIndex: 'name',
    order: 1,
  }, {
    title: 'Branch',
    type: 'SelectQuery',
    data: 'branches',
    initialValue: '',
    note_text: 'The holiday applies only to this branch. Leave blank to apply to all branches.',
    additional: '',
    dataIndex: 'branch',
    selectedKey: 'pk',
    selectedValue: 'name',
    isActive: true,
    order: 2,
  },
]
