import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Row, Col, Button, Spin, Icon, message } from 'antd'
import { get } from 'lodash'
import { RequestV2 as Request } from 'App/Utils'
import ResetElement from './ResetPassword'
import PageNotFound from 'App/Component/PageNotFound' // cannot import { PageNotFound } from App/Component

class ResetPassword extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      token: this.getToken(get(props.location, ['search'])),
      // params: this.getParams(get(props.location, ['search'])),
      isCheckingToken: true,
      isAllowReset: false,
      currentUser: null,
      isResetting: false,
    }
  }

  getToken = strSearch => {
    let token = null
    if (strSearch) {
      const params = strSearch.replace(/\?/g, '').split('&')
      for (let i = 0; i < params.length; i += 1) {
        const paramPack = params[i].split('=') // index 0 = key, 1 = value
        if (paramPack[0] === 'token') {
          token = paramPack[1]
          break
        }
      }
    }
    return token
  }

  // return object URL Params
  getParams = strSearch => {
    const urlParams = {}
    if (strSearch) {
      const params = strSearch.replace(/\?/g, '').split('&')
      for (let i = 0; i < params.length; i += 1) {
        const paramPack = params[i].split('=') // index 0 = key, 1 = value
        urlParams[paramPack[0]] = paramPack[1]
      }
    }
    return urlParams
  }

  setThisState = (obj, callback = () => null, force) => {
    if (force) {
      return this.setState(obj, callback)
    } else {
      if (obj && typeof obj === 'object') {
        for (const key in obj) {
          if (obj[key] !== this.state[key]) {
            return this.setState(obj, callback)
          }
        }
      }
    }
    return callback()
  }

  checkToken = (keyLoading = 'isCheckingToken') => {
    this.setThisState({ [keyLoading]: true }, () => {
      const { token } = this.state
      Request(
        'get',
        'resetPassword',
        {},
        {},
        [token],
        this.checkTokenSuccess,
        this.checkTokenFailed,
        { keyLoading }
      )   
    })
  }

  checkTokenSuccess = (response, extra) => {
    this.setState({ [extra.keyLoading]: false, isAllowReset: true, currentUser: response.data })
  }

  checkTokenFailed = (error, extra) => {
    message.error(`Cannot reset password`)
    this.setState({ [extra.keyLoading]: false, isAllowReset: false })
  }

  onGoLogin = () => {
    this.props.history.push('/login')
  }

  componentDidMount() {
    const { token } = this.state
    if (token) {
      this.checkToken()
    } else {
      this.props.history.replace('/login')
    }
  }

  render() {
    const { isCheckingToken, isAllowReset, token, currentUser, isReloading } = this.state

    if (isCheckingToken) {
      return (
        <Row type='flex' justify='center' align='middle' style={{ marginTop: '30vh'}}>
          <Spin
            size="large"
            tip="Validation..."
            indicator={<Icon type="loading" style={{ fontSize: "4rem" }} spin />}
          />
        </Row>
      )
    }

    return isAllowReset ? (
      <ResetElement currentUser={currentUser} token={token} history={this.props.history} />
    ) : (
      <PageNotFound
        title='Error'
        subTitle='Maybe the wrong token, please contact your admin!'
        extra={
          <Row type='flex' justify='center' gutter={[12, 12]}>
            <Col>
              <Link to='/login'>
                <Button icon='arrow-left'>Login</Button>
              </Link>
            </Col>
            <Col>
              <Button
                type='primary'
                loading={isReloading}
                onClick={() => this.checkToken('isReloading')}
              >
                Try again
              </Button>
            </Col>
          </Row>
        }
      />
    )
  }
}

ResetPassword.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

export default ResetPassword