import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Row, Col, Spin, Button } from 'antd'
import MerchantList from './List'
import { Modules } from 'App/Utils'
import ErrorLabel from 'App/Screens/SubModules/UserGroup/Group/Management/Pages/ErrorLabel'

class PageAddMerchant extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      merchants: [],
      rawResponse: null,
    }
    this.merchantColumns = [
      {
        title: 'Name',
        dataIndex: 'name',
      }, {
        title: 'Merchant Group',
        dataIndex: 'merchant_group.name',
        render: (item) => item || '-',
      }, {
        title: 'Branch',
        dataIndex: 'branch.name',
        render: (item) => item || '-',
      }, {
        title: 'Code',
        dataIndex: 'code',
        render: (item) => item || '-',
      }, {
        title: 'Active Status',
        dataIndex: 'is_active',
        render: (val, record) => {
          return (
            Modules.renderStatusAIU(val)
          )
        }
      }, {
        title: 'Operation',
        dataIndex: 'operation',
        fixed: 'right',
        width: '125px',
        render: (val, record) => {
          return (
            <Row type='flex' gutter={[6, 6]}>
              <Col>
                <Link to={`/merchant/merchant/${record.pk}`}>
                  <Button icon='edit' type='primary' size='small' title='View Merchant' />
                </Link>
              </Col>
              <Col>
                <Button icon='user-delete' type='danger' size='small' title='Remove Merchant' onClick={(event) => {event.stopPropagation(); this.onRemoveMerchants([record.pk])}} />
              </Col>
            </Row>
          )
        }
      }
    ]
  }

  onRemoveMerchants = (ids = [], callback = () => null) => {
    const remainingMerchants = this.state.merchants.filter(merchant => ids.findIndex(id => merchant.pk === id) === -1)
    this.setState({ errorMessage: '', merchants: remainingMerchants }, () => callback(true))
  }

  onJoinMerchants = (ids, users, callback) => {
    this.setState(prevState => ({ errorMessage: '', merchants: [...prevState.merchants, ...users] }), () => {
      this.props.onFieldTouched(true)
      callback(true)
    })
  }

  validateFields = () => {
    return this.state.merchants.map(merchant => merchant.pk)
  }

  setError = (errorMessage) => {
    this.props.setActiveKey(this.props.tabKey, () => {
      this.setState({ errorMessage })
    })
  }

  render() {
    const { userReducer } = this.props
    const { merchants, errorMessage } = this.state

    return (
      <Spin spinning={false}>
        { errorMessage && <ErrorLabel errorMessage={errorMessage} /> }
        <MerchantList
          dataSource={merchants}
          columns={this.merchantColumns}
          userReducer={userReducer}
          onJoinMerchants={this.onJoinMerchants}
          removeMerchants={this.onRemoveMerchants}
        />
      </Spin>
    )
  }
}

PageAddMerchant.propTypes = {
  userReducer: PropTypes.object.isRequired,
  tabKey: PropTypes.string,
  setActiveKey: PropTypes.func,
  onFieldTouched: PropTypes.func,
}

PageAddMerchant.defaultProps = {
  handleHistoryAction: () => null,
  tabKey: null,
  setActiveKey: (tabKey, callback = () => null) => callback(),
  onFieldTouched: () => null,
}

export default PageAddMerchant