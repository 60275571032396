export default {
	CreateUserSessionProperties(payload) {
		return {
			type: "CreateUserSession",
			payload: payload
		};
	},
	RemoveUserSessionProperties() {
		return {
			type: "RemoveUserSession",
			payload: {}
		};
	},
	UpdateUserSessionProperties(payload) {
		return {
			type: "UpdateUserSession",
			payload: payload
		};
	},
	ToggleAuthSessionProperties(in_auth) {
		return {
			type: "AuthToggle",
			payload: in_auth
		};
  },
  // digunakan
	UpdateUserInformation(payload) {
		return {
			type: "UpdateUserInformation",
			payload: payload
		};
  },
  // ---------
	StoreUserInformation(payload) {
		return {
			type: "StoreUserInformation",
			payload: payload
		};
  },
	StoreFromStorage(payload) {
		return {
			type: "StoreFromStorage",
			payload: payload
		};
	},
	Exec(model_event, payload) {
		return {
			type: model_event,
			payload: payload
		};
	}
};
