import React from "react";
import { Result, Button, Icon } from "antd";

export default React.memo(props => (
	<Result
		status="404"
		title={
			<span>
				still looking for the idea..&nbsp;
				<Icon type="smile" spin />
			</span>
		}
		extra={
			<Button
				type="primary"
				key="console"
				onClick={() => props.history.goBack()}
			>
				Go Back
			</Button>
		}
	/>
));
