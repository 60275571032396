import { BaseList, BaseDetail } from "App/Component/Bases";
import fields from "./fields"
import ComponentAutocomplete from "App/Component/Autocomplete";
import descriptor from "./descriptor";
import options from "./options";
import { forcePermissions } from "App/Data";

const title = "Sections";
const urlData = "sections";
const bulkName = "name";
const availablePermissions = ['.view_', '.add_', '.change_'];
const children = {
    appName: 'estimation',
    modelName: 'subsection',
    name: 'sections',
    sub: 'subsections',
    identifier: {
      name: 'section',
      is_array: false
    },
    dataIndex: 'all_subsections',
    bulk_request: true,
    urlSub: 'api:admin:estimation:section:ref-subsections-list',
    add: true,
    edit: true,
    data: false,
    permissions: {
      bulk_delete: true,
      bulk_url: 'subsections-read',
      initialrow: 1,
      payload: true,
      add: true
    },
    rules: {
      button: {
        type: {
          add: 'default',
          edit: 'default'
        },
        size: 'default'
      },
      input: {
        size: 'default'
      },
      select: {
        size: 'default'
      }
    },
  fields: ComponentAutocomplete['subsections']
  }

class List extends BaseList {
  constructor(props) {
    super(props)

    this.title = title;
    this.urlData = urlData;
    this.bulkName = bulkName;
    this.availablePermissions = availablePermissions;
    this.propsList = {
      descriptor,
      exportImport: options.exportImportOptions,
    }
    this.state = {
      ...this.state,
      currentPermissionSet: this.isSuperuser ? this.filterPermissionItems(forcePermissions) : [],
    }
  }
}

class Detail extends BaseDetail {
  constructor(props) {
    super(props)

    this.title = title
    this.urlData = urlData
    this.bulkName = bulkName
    this.fields = fields
    this.children = children
    this.availablePermissions = availablePermissions
    this.propsDetail = {
      urlKeyHistory: 'api:admin:estimation:section:detail-revision',
      keyLabel: 'name'
    }
    this.state = {
      ...this.state,
      currentPermissionSet: this.isSuperuser ? this.filterPermissionItems(forcePermissions) : [],
    }
  }
}

export default { List, Detail };
