import React from "react";

import { BaseList } from "App/Screens/SubModules/Component";
import descriptor from "./descriptorOffline";

export default class UserFeedbackList extends BaseList {
  constructor(props) {
    super(props);

    this.appName = "expert_system";
    this.modelName = "feedback";
    this.availablePermissions = [".view_"];
    this.propsList = {
      descriptor,
      avatar: "name",
      pageTitle: "Feedback",
      masterKey: "module-expertSystem-feedback",
      disableAddButton: true,
    };
    this.state = {
      ...this.state,
      currentPermissionSet: !this.state.isCheckingPermission
        ? this.availablePermissions
        : [],
    };
  }
}
