import React from "react";
import qs from "querystring";
import { Switch, Route } from "react-router-dom";
import { Layout, Row, Col, Menu, Badge, Icon, Spin, PageHeader, Button, Descriptions, Divider, message } from "antd";
import { Bar } from 'ant-design-pro/lib/Charts';
import moment from 'moment';
import { get } from "lodash";
// import HighchartsReact from 'highcharts-react-official'
// import Highcharts from 'highcharts/highcharts'
import { RequestV2 as Request, Lib, CheckPermission } from "App/Utils";
import { PermissionName } from "App/Config";
import { BaseDetail } from 'App/Component/Bases';
import fields from './fields'
import Stats from './Pages/Stats';
import Users from './Pages/Users';
import Manpower from './Pages/Manpower';
import Manage from './Pages/Manage';
import Settings from './Pages/Settings';
import JobQueues from "./Pages/JobQueues";
import PageMerchants from "./Pages/Merchants";
import PageHolidays from "./Pages/Holiday";
// import PageError from "App/Component/Singlepage/PageError";
import PageError from 'App/Component/PageError'
import PageUnauthorized from "App/Component/PageUnauthorized";
import { forcePermissions } from "App/Data";
// import { options as optsStackedColumn } from 'App/Screens/Dashboard/Component/ChartOptions/StackedColumn'

const defaultMenu = "users";
const { Content } = Layout;
const urlData = 'module-branch'

class ModeAdd extends BaseDetail {
  title = 'Branches';
  urlData = urlData;
  bulkName = 'name';
  fields = fields;
}

export default class BranchManagement extends React.PureComponent {
  constructor(props) {
    super(props)

    this.isSuperuser = get(props.userReducer, ['user', 'is_superuser'])
    this.matchUrl = props.match.url
    this.state = {
      // Permission
      isCheckingPermission: !this.isSuperuser && this.props.match.params.id !== 'add',
      isAccessAllowed: this.isSuperuser || this.props.match.params.id === 'add', // read, edit (change)
      currentPermissionSet: this.isSuperuser ? forcePermissions : [],
      readOnly: false,
      allowReadUser: true,
      permissionSetUser: forcePermissions,
      allowReadJobQueue: true,
      permissionSetJobQueue: forcePermissions,
      allowReadMerchant: true,
      permissionSetMerchant: forcePermissions,
      allowReadHoliday: true,
      permissionSetHoliday: forcePermissions,
      // ----------
      isLoading: true,
      errorResponse: null,
      collapsed: false,
      numberUser: '-',
      branch: null,

      chartDataAntd: [],
    };
  }

  setThisState = (obj, callback = () => null) => {
    for (const key in obj) {
      if (obj[key] !== this.state[key]) {
        return this.setState(obj, callback)
      }
    }
    callback()
  }

  handleCheckPermission = (callback = () => null) => {
    this.setThisState({ isCheckingPermission: true }, () => {
      const currentPermissionSet = CheckPermission.getPermissionSet({
        userReducer: this.props.userReducer,
        appName: 'multisite',
        modelName: 'branch',
      })
      const codePart = this.props.match.params.id === 'add' ? PermissionName.CREATE.codePart : PermissionName.READ.codePart
      const isAccessAllowed = !!currentPermissionSet.find(val => val.search(codePart) !== -1)
      callback({ isAccessAllowed, currentPermissionSet })
    })
  }

  retrieve = (keyLoading = 'isLoading', callback = () => null) => {
    this.setThisState({ [keyLoading]: true }, () => {
      Request("GET", `${urlData}-detail`, {}, {}, [this.props.match.params.id],
        res => {
          const chartDataAntd = []
          const { summaries } = res.data.job_summary
          for (let i = 0; i < summaries.length; i += 1) {
            chartDataAntd.push({ x: summaries[i].label, y: summaries[i].total })
          }
          this.setState({ [keyLoading]: false, branch: res.data, chartDataAntd }, () => {
            const paths = this.props.location.pathname.split('/').filter(v => v)
            if (paths.length === 3) {
              this.props.history.replace(`/${paths[0]}/${paths[1]}/${paths[2]}/${defaultMenu}`)
            }
          })
        },
        error => {
          this.setState({ [keyLoading]: false, chartDataAntd: [], errorResponse: get(error, 'response') }, () => {
            callback()
          })
        }
      );
    })
  }

  onGoHome = () => {
    Lib.historyAction(this.props.history, '/branch-queue/branch').goBack()
  };

  changeBranch=(e)=>{
    let oldbranch = this.state.branch
    e.job_summary = oldbranch.job_summary
    this.setState({
      branch: e
    })
  }

  changeUserCount=(e)=>(
    this.setState({
      numberUser: e
    })
  )

  componentDidMount() {
    const callbackAllowed = () => {
      if (this.props.match.params.id !== 'add') {
        // this.props.history.replace(`${this.matchUrl}/users`)
        this.retrieve();
      }
    }
    if (this.state.isCheckingPermission) {
      this.handleCheckPermission(res => {
        const { userReducer } = this.props
        const readOnly = !get(res, 'currentPermissionSet', []).some(item => (item || '').includes('.change_'))
        const permissionSetUser = CheckPermission.getPermissionSet({ userReducer, appName: 'account', modelName: 'user' })
        const permissionSetJobQueue = CheckPermission.getPermissionSet({ userReducer, appName: 'jobs', modelName: 'jobqueue' })
        const permissionSetMerchant = CheckPermission.getPermissionSet({ userReducer, appName: 'jobs', modelName: 'merchant' })
        const permissionSetHoliday = CheckPermission.getPermissionSet({ appName: 'jobs', modelName: 'holiday' })
        const allowReadUser = permissionSetUser.some(item => (item || '').includes('.view_'))
        const allowReadJobQueue = permissionSetJobQueue.some(item => (item || '').includes('.view_'))
        const allowReadMerchant = permissionSetMerchant.some(item => (item || '').includes('.view_'))
        const allowReadHoliday = permissionSetHoliday.some(item => (item || '').includes('.view_'))

        this.setState({
          isCheckingPermission: false,
          isAccessAllowed: get(res, 'isAccessAllowed'),
          currentPermissionSet: get(res, 'currentPermissionSet', []),
          readOnly,
          allowReadUser,
          allowReadJobQueue,
          allowReadMerchant,
          allowReadHoliday,
          permissionSetUser,
          permissionSetJobQueue,
          permissionSetMerchant,
          permissionSetHoliday,
        }, () => {
          if (get(res, 'isAccessAllowed')) {
            callbackAllowed()
          }
        })
      })
    } else {
      callbackAllowed()
    }
  }

  componentWillUnmount() {
    Lib.windowStop();
  }

  render() {
    const { readOnly, allowReadUser, allowReadJobQueue, allowReadMerchant, allowReadHoliday, permissionSetUser, permissionSetJobQueue, permissionSetMerchant, permissionSetHoliday, isCheckingPermission, isAccessAllowed } = this.state
    const { isLoading, errorResponse, branch, numberUser, chartDataAntd } = this.state;
    const p = this.props;

    if (isCheckingPermission) {
      return (
        <Row type='flex' justify='center' align='middle' style={{ marginTop: '30vh' }}>
          <Spin
            size='large'
            tip='Checking permission...'
            indicator={<Icon spin type='loading' style={{ fontSize: '4rem' }} />}
          />
        </Row>
      )
    }

    if (!isAccessAllowed) {
      return (
        <PageUnauthorized />
      )
    }

    if (p.match.params.id === 'add') {
      return <ModeAdd {...p} urlName='api:admin:multisite:branch:detail' />
    } else if (isLoading) {
      return (
        <div style={{ paddingTop: 100, textAlign: "center" }}>
          <Spin
            size="large"
            tip="making coffee..."
            indicator={<Icon type="loading" style={{ fontSize: "4rem" }} spin />}
          />
        </div>
      )
    } else {
      return branch ? (
        <Layout className="um-layout">
          <PageHeader
            style={{
              border: '1px solid rgb(235, 237, 240)',
              backgroundColor: "#ffffff"
            }}
            onBack={this.onGoHome}
            title="Branch"
            subTitle={readOnly &&
              <Col style={{ fontSize: '12px', fontWeight: 500, backgroundColor: '#1890ff', color: '#fff', padding: '0 12px 2px 12px', borderRadius: '14px' }}>
                Read Only
              </Col>
            }
          />
          

          <PageHeader
            title="Branch detail"
            ghost={false}
            footer={<Divider className="m-0" />}
          >
            <Row className="mb-3">
              <Col lg={6} xl={6} xs={16}>
                <Descriptions className="ant-desc-custom" column={1}>
                  <Descriptions.Item label="Name">
                    {branch.name}
                  </Descriptions.Item>
                  <Descriptions.Item label="Group Name">
                    {branch.group_name || '-'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Slug">
                    {branch.slug}
                  </Descriptions.Item>
                  <Descriptions.Item label="Status">
                    {branch.status ? <Badge status="success" text="Active" /> : <Badge status="error" text="Inactive" /> }
                  </Descriptions.Item>
                  <Descriptions.Item label="Created at">
                    {moment(branch.created).format('MMM DD YYYY HH:mm:ss')}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
              <Col lg={18} xl={18}>
                <Row>
                  <Col md={1}>
                    <Divider type="vertical" style={{ height: "6.1rem" }} />
                  </Col>
                  <Col md={17} xs={24}>
                    <div>
                      Total job :
                      <Badge
                        count={branch.job_summary.total !== 0 ? branch.job_summary.total : 'empty'}
                        overflowCount={999999999}
                        style={{ backgroundColor: branch.color, zIndex: "1", marginLeft: "10px", color: '#fff' }}
                      />
                      <Bar
                        height={120}
                        autoLabel={false}
                        data={chartDataAntd}
                        // color={"#ff5b00"}
                        color={branch.color || ''}
                      />
                    </div>
                  </Col>
                  <Col md={1}>
                    <Divider type="vertical" style={{ height: "6.1rem" }} />
                  </Col>
                  <Col md={5}>
                    <span className="text-muted font-md mb-3">Total user</span>
                    <div>
                      <Icon
                        className="font-2xl mr-1"
                        type="idcard"
                        theme="twoTone"
                      />
                      <span className="font-2xl">
                        {numberUser}
                      </span>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </PageHeader>
          {/* <Affix offsetTop={65}> */}
            <Menu
              mode="horizontal"
              className="px-2"
              // theme={"dark"}
              selectedKeys={[
                Lib.getMenuUrl(p.location.pathname, undefined, defaultMenu)
              ]}
            >
              {/* <Menu.Item
                key="stats"
                onClick={e => {
                  p.history.push(`${this.matchUrl}/stats`);
                }}
              >
                <Icon type="pie-chart" />
                Stats
              </Menu.Item> */}
              {/* {allowReadUser && ( */}
                <Menu.Item
                  key="users"
                  onClick={e => {
                    Lib.historyAction(p.history, `${this.matchUrl}/users`).replace()
                  }}
                >
                  <Icon type="user" />
                  User
                </Menu.Item>
              {/* )} */}
              {/* <Menu.Item
                key="manpower"
                onClick={e => {
                  p.history.push(`${this.matchUrl}/manpower`);
                }}
              >
                <Icon type="thunderbolt" />
                Manpower Resources
              </Menu.Item> */}
              {/* <Menu.Item
                key="manage"
                onClick={e => {
                  p.history.push(`${this.matchUrl}/manage`);
                }}
              >
                <Icon type="calendar" />
                Day Off Management
              </Menu.Item> */}
              {allowReadJobQueue && (
                <Menu.Item
                  key="job-queues"
                  onClick={e => {
                    Lib.historyAction(p.history, `${this.matchUrl}/job-queues`).replace()
                  }}
                >
                  <Icon type="unordered-list" />
                  Job Queues
                </Menu.Item>
              )}
              {allowReadMerchant && (
                <Menu.Item
                  key="merchants"
                  onClick={() => {
                    Lib.historyAction(p.history, `${this.matchUrl}/merchants`).replace()
                  }}
                >
                  <Icon type='shop' />
                  Merchant
                </Menu.Item>
              )}
              {allowReadHoliday && (
                <Menu.Item
                  key="holidays"
                  onClick={() => {
                    Lib.historyAction(p.history, `${this.matchUrl}/holidays`).replace()
                  }}
                >
                  <Icon type='calendar' />
                  Holiday
                </Menu.Item>
              )}
              {!readOnly && (
                <Menu.Item
                  key="settings"
                  onClick={e => {
                    Lib.historyAction(p.history, `${this.matchUrl}/settings`).replace()
                  }}
                >
                  <Icon type="setting" />
                  Settings
                </Menu.Item>
              )}
            </Menu>
          {/* </Affix> */}
            <Content className="mt-2 mb-2">
              <Switch>
                {/* <Route path={`${p.match.path}/stats`}>
                  <Stats {...p} branch={branch} />
                </Route> */}
                {allowReadUser && (
                  <Route path={`${p.match.path}/users`}>
                    <Users {...p} changeUserCount={this.changeUserCount} branch={branch} permissionItems={permissionSetUser} />
                  </Route>
                )}
                {/* <Route path={`${p.match.path}/manpower`}>
                  <Manpower {...p} branch={branch} />
                </Route> */}
                {/* <Route path={`${p.match.path}/manage`}>
                  <Manage {...p} branch={branch} />
                </Route> */}
                {allowReadJobQueue && (
                  <Route path={`${p.match.path}/job-queues`}>
                    <JobQueues {...p} branch={branch} readOnly={readOnly} />
                  </Route>
                )}
                {allowReadMerchant && (
                  <Route path={`${p.match.path}/merchants`}>
                    <PageMerchants {...p} permissionItems={permissionSetMerchant} branch={branch} />
                  </Route>
                )}
                {allowReadHoliday && (
                  <Route path={`${p.match.path}/holidays`}>
                    <PageHolidays {...p} permissionItems={permissionSetHoliday} branch={branch} />
                  </Route>
                )}
                {!readOnly && (
                  <Route path={`${p.match.path}/settings`}>
                    <Settings {...p} changeBranch={this.changeBranch} branch={branch} />
                  </Route>
                )}
              </Switch>
            </Content>
        </Layout>
      ) : (
        <PageError
          errorResponse={errorResponse}
          onGoBack={this.onGoHome}
          onReload={this.retrieve}
        />
      );
    }
  }
}

