export default {
  bulkOptions: [
    {
      key: 'set_to_active',
      field: 'status',
      value: 'active',
      label: 'Set to Active',
      permissionCode: 'estimation.change_linetemplate',
    }, {
      key: 'set_to_inactive',
      field: 'status',
      value: 'inactive',
      label: 'Set to Inactive',
      permissionCode: 'estimation.change_linetemplate',
    }, {
      key: 'set_to_unpublished',
      field: 'status',
      value: 'unpublished',
      label: 'Set to Unpublished',
      permissionCode: 'estimation.change_linetemplate',
    }
  ],
  exportImportOptions: {
    export: {
      directly: true,
      url_name: 'api:admin:estimation:linetemplate:export',
      filter_by: [
        {
          name: 'limit',
          label: 'Limit',
          type: 'choices',
          choices: [
            ['all', 'All'],
            ['100', '100'],
            ['500', '500'],
          ],
        },
        {
          name: 'status',
          label: 'Status',
          type: 'choices',
          choices: [
            ['', 'All'],
            ['active', 'Active'],
            ['inactive', 'Inactive'],
            ['unpublished', 'Unpublished'],
          ],
        },
        {
          name: 'section',
          label: 'Section',
          type: 'api_reference',
          api: {
            url_name: 'api:admin:estimation:section:autocomplete',
            key: 'pk',
            display: 'name',
          },
        },
        {
          name: 'subsection',
          label: 'Subsection',
          type: 'api_reference',
          api: {
            url_name: 'api:admin:estimation:subsection:autocomplete',
            key: 'pk',
            display: 'identifier',
          },
        },
      ],
    },
  },
}