import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Form, Icon, Popconfirm, Progress, Row, Spin, Upload } from 'antd';
import { get, noop } from 'lodash';

import { Lib, Modules, s3 } from 'App/Utils';

class FieldFileS3 extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      error: null,
      loading: false,
      file: props.record,
    }
  }

  openFile = () => {
    this.setState({ loading: true }, () => {
      s3.open(this.state.file.subid)
        // .then(this.openFileSuccess)
        .catch(error => Modules.responseMessage('error', get(error, 'detail', error)))
        .finally(() => this.setState({ loading: false }))
    })
  }

  onRemoveFile = () => {
    this.props.form.setFieldsValue({ [this.props.dataIndex]: null })
    this.setState({ file: null, error: null })
  }

  retryUpload = () => this.uploadFile(this._tempFile)

  uploadFile = (file) => {
    if (!file) return

    this._tempFile = file
    this.manager = new s3.manager({
      file,
      keySubId: 'subid',
      prepareOpts: get(this.props.field, 'getPrepareOpts', noop)(this.props.recordId),
    })
    this.setState({ loading: true }, () => {
      this.manager.upload()
        .then((a, b, c) => {
          console.log('manager upload then', {a, b, c}, this.manager)
          this.setState({ error: null, file: {...file, filename: file.name, subid: this.manager._preparedData.subid} })
          this.props.form.setFieldsValue({ [this.props.dataIndex]: this.manager._preparedData.subid })
        })
        .catch((error) => this.setState({ error }))
        .finally(() => this.setState({ loading: false }))
    })
  }

  getValueFromEvent = ({ file }) => {
    this.uploadFile(file)
  }

  renderMenu() {
    return (
      <Row type="flex" className="overlay-action" gutter={[6, 6]} onClick={e => e.stopPropagation()}>
        {/* <Col>
          <div
            title="Download"
            rel='noopener noreferrer'
            onClick={this.downloadFile}
          >
            <Button size="small" icon={<VerticalAlignBottomOutlined />} />
          </div>
        </Col> */}
        {/* {this.props.allowEdit && (
          <Col>
            <Upload
              {...this.props.inputProps}
              maxCount={1}
              fileList={[]}
              beforeUpload={() => false}
              onChange={({ file }) => this.props.onEditFile(this.props.file, file)}
            >
              <Button
                size="small"
                type="primary"
                icon={<EditOutlined />}
                title="Change File"
                onClick={e => e.preventDefault()}
              />
            </Upload>
          </Col>
        )} */}
        {/* {this.props.allowDelete && ( */}
          <Col>
            {/* <Popconfirm
              placement="topRight"
              title="Are you sure to delete this file?"
              okText="Yes"
              okButtonProps={{ danger: true }}
              // onConfirm={() => {this.onDeleteFile()}} // karena akan loading jika return async
            > */}
              <Button
                type="danger"
                size="small"
                // type="primary"
                title="Delete File"
                // icon={<DeleteOutlined />}
                icon="close"
                onClick={this.onRemoveFile}
              />
            {/* </Popconfirm> */}
          </Col>
        {/* )} */}
      </Row>
    )
  }

  renderErrorOverlay() {
    return this.state.error && (
      <div
        style={{
          position: 'absolute',
          inset: 0,
          background: 'rgba(255, 255, 255, 0.8)',
          zIndex: 1,
        }}
        onClick={e => {
          e.stopPropagation() // click parent div
          e.preventDefault() // click anchor
        }}
      >
        <Row
          type="flex"
          gutter={[6, 6]}
          justify="center"
          style={{
            position: 'absolute',
            top: '50%',
            left: 6,
            right: 6,
            transform: 'translate(0%, -50%)'
          }}
        >
          <Col>
            <Button type="primary" size="small" onClick={this.retryUpload}>
              Try Again
            </Button>
          </Col>
          <Col>
            <Button type="danger" size="small" onClick={this.onRemoveFile}>
              Cancel
            </Button>
          </Col>
        </Row>
      </div>
    )
  }

  renderFile() {
    return (
      <div className="h-100 wrapper-height-spin" onClick={() => console.log('onClickItem')}>
        {/* <Spin spinning={this.state.loading}> */}
          <div
            title={this.state.file.filename}
            className="h-100"
            onClick={this.openFile}
          >
            <Card hoverable className="plan-file h-100" bodyStyle={{ padding: '12px 12px 6px 12px', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {this.renderMenu()}
              {/* {this.renderErrorOverlay()} */}
              <div style={{ width: '100%' }}>
                <div className="mb-2 text-center">
                  {Lib.renderFileIcon(
                    this.state.file.filename,
                    { style: {fontSize: '36px'} }
                  )}
                </div>
                <div style={{ fontSize: '12px' }}>
                  <div className="text-center font-weight-semibold text-truncate" title={this.state.file.filename}>
                    {this.state.file.filename}
                  </div>
                  <div className="mt-2 text-center" style={{ color: '#a9a9a9', fontSize: '11px', lineHeight: '12px' }}>
                    {Lib.formatFileSize(this.state.file.size)}
                  </div>
                </div>
                {/* {this.state.loading && (
                  <div style={{ lineHeight: '12px' }}>
                    <Progress
                      showInfo={false}
                      size="small"
                      status='active'
                      percent={(this.state.loaded / this.state.total * 100)}
                      strokeColor={{
                        from: '#108ee9',
                        to: '#87d068',
                      }}
                      style={{ lineHeight: '4px' }}
                    />
                  </div>
                )} */}
              </div>
            </Card>
          </div>
        {/* </Spin> */}
      </div>
    )
  }

  render() {
    console.log('Rendered FileS3', this)
    return (
      <div className="h-100 wrapper-height-spin" onClick={() => console.log('onClickItem')}>
        <Spin spinning={this.state.loading}>
          <div className="h-100" style={{ position: 'relative' }}>
            {this.renderErrorOverlay()}
            <Form.Item label={this.props.title} {...this.props.formItemProps}>
              {this.props.form.getFieldDecorator(this.props.dataIndex, {
                rules: [{
                  required: this.props.required,
                  message: `Please Input ${this.props.title}!`
                }],
                getValueFromEvent: this.getValueFromEvent,
                initialValue: get(this.state.file, 'subid', null),
              })(
                this.state.file ? this.renderFile() : (
                  <Upload.Dragger
                    fileList={[]}
                    // accept={accept || ''}
                    beforeUpload={() => false}
                    disabled={this.props.disabled}
                    {...this.props.inputProps}
                  >
                    <div className="h-100">
                      <div className="px-3">
                        <Icon type="plus" style={{ fontSize: '20px' }} />
                        <div className="mt-2" style={{ fontSize: '12px' }}>
                          Upload Here
                        </div>
                      </div>
                    </div>
                  </Upload.Dragger>
                )
              )}
            </Form.Item>
          </div>
        </Spin>
      </div>
    )
  }
}

export default FieldFileS3