import React from 'react'
import PropTypes from 'prop-types'
import { Button, Col, Form, message, Row, Card, Tabs } from 'antd'
import { get, cloneDeep, isEmpty } from 'lodash'
import { FormContext, FormProvider } from 'App/Component/Context/ContextForm'
import { Lib, RequestV2 as Request } from 'App/Utils'
import PromptContainer from 'App/Component/PromptContainer'
import DynamicField from 'App/Component/DynamicField'
import Lib2 from 'App/Component/Singlepage/Lib2'
import DynamicEditTable from "App/Component/Singlepage/DynamicEditTable";
import History from "App/Screens/SubModules/Component/History";
const { TabPane } = Tabs;

class BasicPageSetting extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isFieldsTouched: false,
      isSaving: false,
      subheaderData: [],
      cloneSD: [],
      activeKey: '1',
      objArgs: this.getInitialObjArgs(),
      paramProps: {},
    }
  }

  onRefPrompt = (ref) => {
    this._prompt = ref
  }

  getIsFieldsTouched = () => get(this._prompt, 'isTouched')

  setTouched = (isTouched, callback) => {
    if (this._prompt) {
      this._prompt.setTouched(isTouched, callback)
    }
  }

  onBlur = (value, key, type) => {
    if (get(this.props.data, key) !== value) {
      this.setTouched(true)
    }
  }

  onRevert = () => {
    if (this._form) {
      this._form.resetFields()
      this.setTouched(false)
    } else {
      message.error('Error when collecting data, please contact your admin!')
    }
  }

  onSave = () => {
    if (!this.state.isSaving) {
      this._form.validateFieldsAndScroll({scroll: {offsetTop: 150}}, (err, values) => {
        if (!err) {
          this.setState({ isSaving: true }, () => {
            let convertedValues = {}
            if (this.props.convertValues) {
              convertedValues = this.props.convertValues(values)
            } else {
              convertedValues = Lib2.getValuesWithFile(values, this.props.urlKey, this.props.fields)
            }
            Request(
              'put',
              this.props.urlKey,
              {},
              convertedValues,
              [get(this.props.data, 'pk')],
              this.saveSuccess,
              this.saveFailed,
            )
          })
        }
      })
    }
  }

  saveSuccess = (response) => {
    message.success('Data has been saved')
    this.props.setData(response.data)
    const [p,s] = [this.props,this.state]
    let hasSubHeader = p.children && !isEmpty(s.subheaderData)
    if(hasSubHeader){
      let esl = cloneDeep(s.subheaderData)
      const formData = Lib2.arrayFormData(esl, p.children, response, s.cloneSD)
      if(!isEmpty(formData) && formData.find(e => e.touched))
        this.loopSendData(p.children, formData)
    }

    this.setTouched(false, () => {
      this.setState({ isSaving: false })
    })
  }

  loopSendData=(setting, formData)=>{
    for (const val of formData) {
      // let l = formData.filter(e => e.touched)
      if(val.touched)
        Request(
          val.method,
          `${setting.sub}${val.action}`,
          {},
          val.disk,
          [val.esl && val.esl.pk],
          this.successFunc,
          (e)=>Lib.errorMessageHandler(e.response),
          val.pk
        )
    }
  }

  successFunc=(val, key)=>{
    let cloneSD = [...this.state.cloneSD]
    let xs = cloneSD.findIndex(e => e.key === key)
    if(xs === -1){
      val.data.key = key
      cloneSD.push(val.data)
      this.setState({ cloneSD })
  }
  // message.success("Update successfully")
}

  saveFailed = (error) => {
    Lib.errorMessageHandler(get(error, 'response'))
    this.setState({ isSaving: false })

    const errorObj = get(error, 'response.data')
    for (let key in errorObj) {
      if (errorObj.hasOwnProperty(key)) {
        this._form.setFields({
          [key]: {
            value: this._form.getFieldValue(key),
            errors: [new Error(errorObj[key])],
          },
        });
      }
    }
  }

  setFieldsError = (objError) => {
    const obj = {}
    for (const key in objError) {
      const tempValue = this._form.getFieldValue(key)
      obj[key] = {
        value: tempValue,
        errors: [new Error(objError[key])]
      }
    }
    this._form.setFields(obj)
  }

  onUpdateHandler=(data)=>{
    const [p] = [this.props]
    p.setData(data)
    this.setState({ activeKey: '1' })
    if(p.transactionHistory.destroyOnSave){
      p.reRetrieve()
    }
  }

  renderView=()=>{
    const [p,s] = [this.props,this.state]
    if(p.transactionHistory){
      return (
        <Card>
          <Tabs activeKey={s.activeKey} onChange={(activeKey)=>this.setState({ activeKey })} tabPosition={"left"}>
            <TabPane tab="Edit" key="1">
              {this.baseForm()}
            </TabPane>
            <TabPane tab="History" key="2">
              <History
                {...p.transactionHistory}
                updateHeader={this.onUpdateHandler}
                title={get(p.data, `${p.transactionHistory.title}`)} 
              />
            </TabPane>
          </Tabs>
        </Card>
      )
    }
    return this.baseForm()
  }

  getInitialObjArgs = () => {
    let objArgs = {}
    const { fields } = this.props
    for (const index in fields) {
      if (fields[index].referenceBy) {
        const dataIndexReceive = fields[index].dataIndexReceive || fields[index].dataIndex
        const currentValue = get(this.props.data, dataIndexReceive)
        objArgs = { ...objArgs, ...Lib.checkValues({ [fields[index].referenceBy]: currentValue }) }
      }
    }
    return objArgs
  }

  setArgs = (objArgs) => {
    this.setState(prevState => ({ objArgs: {...prevState.objArgs, ...objArgs} }))
  }

  setParamProps = (paramProps) => {
    this.setState(prevState => ({ paramProps: {...prevState.paramProps, ...paramProps} }))
  }

  baseForm=()=>{
    const { data, fields, readOnly } = this.props
    const { isSaving } = this.state
    const [p,s] = [this.props,this.state]
    return(
      <div>
        <PromptContainer ref={this.onRefPrompt} />
        <Row style={{ marginTop: '12px' }}>
          <FormProvider>
            <Form>
              {fields.map(elem => {
                const dataIndexReceive = elem.dataIndexReceive || elem.dataIndex

                return (
                  <DynamicField
                    key={elem.dataIndex}
                    {...elem}
                    paramProps={{...this.state.paramProps[elem.dataIndex], ...elem.paramProps}}
                    setParamProps={this.setParamProps}
                    allFields={fields}
                    // -------------------------------
                    onBlur={this.onBlur}
                    dataRecord={this.props.convertDataRecord(data, dataIndexReceive)}
                    onPressEnter={this.onSave}
                    normFile={() => {
                      message.info('Please remove file from Admin section')
                    }}
                    args={get(this.state.objArgs, elem.dataIndex)}
                    setArgs={this.setArgs}
                  />
                )
              })}
              <FormContext.Consumer>
                {form => {this._form = form}}
              </FormContext.Consumer>
            </Form>
          </FormProvider>
        </Row>
        { p.children &&
        <Row>
          <Col>
            <DynamicEditTable
              userReducer={p.userReducer}
              dataRecord={p.data}
              urlTab={p.children.sub}
              urlSub={p.children.urlSub}
              cloneSD={s.cloneSD}
              parentForm={this.formDetail}
              changeData={(key, val) => this.setState({[key]: val})}
              operation="edit"
              data={s.subheaderData}
              permissions={p.children}
              extraactions={false}
              readOnly={readOnly}
              setThisState={(obj,callback=()=>null) => this.setState(obj,()=>callback(true))}
              filterSelected={p.filterSelected}
            />
          </Col>
        </Row> }
        <Row type='flex' justify='space-between' align='middle' style={{ backgroundColor: '#fff', padding: '12px 0', borderTop: '1px solid #e8e8e8' }}>
          <Col />
          <Col>
            <Row type='flex' gutter={[12, 12]}>
              {this.getIsFieldsTouched() && (
                <Col>
                  <Button onClick={this.onRevert}>
                    Revert to Saved
                  </Button>
                </Col>
              )}
              {!readOnly && (
                <Col>
                  <Button icon='save' loading={isSaving} type='primary' onClick={this.onSave}>
                    Update
                  </Button>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </div>
    )
  }

  render() {
    return this.renderView()
  }
}

BasicPageSetting.propTypes = {
  data: PropTypes.object.isRequired,
  urlKey: PropTypes.string.isRequired,
  fields: PropTypes.array,
  readOnly: PropTypes.bool,
  setData: PropTypes.func,
  reRetrieve: PropTypes.func
}

BasicPageSetting.defaultProps = {
  fields: [],
  readOnly: false,
  setData: () => null,
  reRetrieve: () => null,
  convertDataRecord: (data, dataIndexReceive) => get(data, dataIndexReceive),
}

export default BasicPageSetting