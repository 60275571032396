import React from 'react'
import { BasePreDetail } from 'App/Screens/SubModules/Component'
import PageDetail from './Detail'

class MerchantPricingManagement extends BasePreDetail {
  constructor(props) {
    super(props)

    this.appName = 'jobs'
    this.modelName = 'merchantpricing'
    this.availablePermissions = ['.view_']
    const isAccessAllowed = this.availablePermissions.find(val => val.search(this.codePart) !== -1)
    this.state = {
      ...this.state,
      isAccessAllowed,
      currentPermissionSet: this.isSuperuser ? this.availablePermissions : [],
    }
  }

  getPageDetail = () => <PageDetail {...this.props} currentPermissionSet={this.state.currentPermissionSet} />
}

export default MerchantPricingManagement