import qs from "querystring";
import { createBrowserHistory } from "history";

import { RequestV2 as Request, Modules } from "App/Utils";
import { Sites } from "App/Config";
import { Action, Store } from "App/Redux";

const browserHistory = createBrowserHistory();

export default function Logout(history = browserHistory, callback = () => null) {
  Request(
    "post",
    "user-revoke-auth",
    { "Content-Type": "application/x-www-form-urlencoded" },
    qs.stringify({
      token: Store.getState().userReducer.token,
      client_id: Sites.oauth.id,
      client_secret: Sites.oauth.secret
    }),
    [],
    onLogoutSuccess,
    onLogoutFailed,
    { history, callback, withoutRefreshToken: true }
  );
}

const onLogoutSuccess = (response, ex) => {
  Store.dispatch(Action.RemoveUserSessionProperties());
  ex.history.replace("/login");
  ex.callback(true)
};

const onLogoutFailed = (error, ex) => {
  Store.dispatch(Action.RemoveUserSessionProperties());
  ex.history.replace("/login");
  Modules.responseMessage("error", error.response.data);
  ex.callback()
};
