import React from 'react'
import { Button, Icon, message, Modal } from 'antd'
import { get } from 'lodash'
import { Lib, RequestV2 as Request } from 'App/Utils'
import { BaseList } from 'App/Component/Bases'
import Detail from './Management'
import descriptorOffline from './descriptor'

const title = "Export Import Sessions"
const urlData = "exportImportSessions"
const bulkName = "content_type"

class List extends BaseList {
  constructor(props) {
    super(props)

    const allowedStatusToSave = ['new', 'completed', 'failed']
    this.title = title
    this.urlData = urlData
    this.bulkName = bulkName
    this.propsList = {
      enableImport: false,
      enableExport: false,
      widthOperation: '160px',
      descriptor: descriptorOffline,
      getAnotherOperations: (record, compTable) => {
        return (
          <>
            <Button
              type='primary'
              size='small'
              title='Run'
              disabled={!allowedStatusToSave.includes(record.status) || !get(compTable, ['props', 'permissions'], []).find(val => val.includes('.change_'))}
              style={{ marginRight: '4px' }}
              onClick={(event) => this.onRun(event, record, compTable)}
            >
              <Icon type='play-circle' />
            </Button>
            <Button
              type='primary'
              size='small'
              title='Dry Run'
              disabled={!allowedStatusToSave.includes(record.status) || !get(compTable, ['props', 'permissions'], []).find(val => val.includes('.change_'))}
              style={{ marginRight: '4px' }}
              onClick={(event) => this.onDryRun(event, record, compTable)}
            >
              <Icon type='experiment' />
            </Button>
          </>
        )
      }
    }
  }

  onRun = (event, record, compTable) => {
    this.registerQueue(record.pk, { dry_run: false }, record, compTable)
  }

  onDryRun = (event, record, compTable) => {
    this.registerQueue(record.pk, { dry_run: true }, record, compTable)
  }

  registerConfirm = (record, isDryRun, callback, force) => {
    if (force === true) {
      callback(true)
    } else {
      const title = `Register Queue (${isDryRun ? 'Dry Run' : 'Run'})`
      const description = isDryRun
        ? 'Dry Run is a trial session only, the results of the export import will not be saved.'
        : 'If you confirm this dialog, the export import will be queued for execution.'
      Modal.confirm({
        title,
        icon: isDryRun ? 'experiment' : 'play-circle',
        content: (
          <div>
            Description in record: <b>{record.description || '(No Description)'}</b>
            <div style={{ marginTop: '12px', fontSize: '13px' }}>
              <b style={{ textDecoration: 'underline' }}>Note:</b> {description}
            </div>
          </div>
        ),
        onOk: () => callback(true),
        onCancel: () => callback(false),
      })
    }
  }

  registerQueue = (id, body, record, compTable) => {
    this.registerConfirm(record, body.dry_run, isConfirmation => {
      if (isConfirmation) {
        return new Promise((resolve, reject) => {
          Request(
            'post',
            'registerQueue',
            {},
            body,
            [id],
            this.registerQueueSuccess,
            this.registerQueueFailed,
            { resolve, reject, compTable },
          )
        })
      }
    })
  }

  registerQueueSuccess = (response, extra) => {
    message.success('Register Queue is successful', 3)
    extra.resolve()
    if (typeof get(extra.compTable, 'reloadPage') === 'function') extra.compTable.reloadPage()
  }

  registerQueueFailed = (error, extra) => {
    extra.reject()
    Lib.errorMessageHandler(get(error, 'response'))
  }
}

export default { List, Detail }