// KETERANGAN PENTING...!!! ---------------------------------------------------------
// codename adalah codename yang ada pada allowed_modules, See on "User & Group" menu
// Path children mengikuti module-nya, seperti "/branches/:id" masuk ke module branch
// Jadi isikan mengikuti codename pada module
// ----------------------------------------------------------------------------------
import startCase from "lodash/startCase";

import { BaseUrl } from "App/Component/Bases";
/* user group */
import UserGroup from "./UserGroup";
import Group from "./UserGroup/Group";
import GroupManagement from "./UserGroup/Group/Management";
// import GroupForm from "./UserGroup/Group/Form";
import User from "./UserGroup/User";
import UserAdd from "./UserGroup/User/Management/Add";
import UserManagement from "./UserGroup/User/Management";
import UserAllList from "App/Screens/SubModules/UserGroup/UserAll";
import UserTrackerList from "App/Screens/SubModules/UserGroup/UserTracker";
import UserTrackerManagement from "App/Screens/SubModules/UserGroup/UserTracker/Management";
import {
  Stats,
  Profile,
  Branch as UserBranch,
} from "./UserGroup/User/Management/Pages";
import { DayOff } from "./UserGroup/Manage";

/* product */
import ProductRedirect from "App/Screens/SubModules/Products";
import ProductGroupList from "App/Screens/SubModules/Products/ProductGroup";
import ProductGroupManagement from "App/Screens/SubModules/Products/ProductGroup/Management";
import Products from "./Products/Product";
import ProductManagement from "./Products/Product/Management";

/* branch */
import BranchQueue from "./Branches"; // Branch & Queue
import BranchList from "./Branches/Branch"; // Branch List
import BranchManagement from "./Branches/Branch/Management"; // Branch Add/Detail
import JobQueueList from "./Branches/Queue"; // Job Queue List
import JobQueueManagement from "./Branches/Queue/Management"; // Job Queue Add/Detail
import HolidayList from "App/Screens/SubModules/Branches/Holiday";
import HolidayManagement from "App/Screens/SubModules/Branches/Holiday/Management";

/* job */
import MainJob from "./JobMg/MainRedirect";
import JobMg from "./JobMg";
import ConsistencyNotesList from "App/Screens/SubModules/JobMg/ConsistencyNote";
import ConsistencyNotesManagement from "App/Screens/SubModules/JobMg/ConsistencyNote/Management";
import ErfTypeList from "App/Screens/SubModules/JobMg/ErfType";
import ErfTypeManagement from "App/Screens/SubModules/JobMg/ErfType/Management";
import ErfMappingManagement from "App/Screens/SubModules/JobMg/ErfMapping/Management";
import BuildingTypeList from "App/Screens/SubModules/JobMg/BuildingType";
import BuildingTypeManagement from "App/Screens/SubModules/JobMg/BuildingType/Management";
import RateTypeList from "App/Screens/SubModules/JobMg/RateType";
import RateTypeManagement from "App/Screens/SubModules/JobMg/RateType/Management";
import JobGradeList from "App/Screens/SubModules/JobMg/JobGrade";
import JobGradeManagement from "App/Screens/SubModules/JobMg/JobGrade/Management";
import JobTypeList from "App/Screens/SubModules/JobMg/JobType";
import JobTypeManagement from "App/Screens/SubModules/JobMg/JobType/Management";
import RangeList from "App/Screens/SubModules/JobMg/Range";
import RangeManagement from "App/Screens/SubModules/JobMg/Range/Management";
import TransitionList from "App/Screens/SubModules/JobMg/Transition";
import TransitionManagement from "App/Screens/SubModules/JobMg/Transition/Management";
// import JobManagement from "./JobMg/Management/JobManagement";
// import JobQueue from "./JobMg/Management/Queue";
import JobQ from "./JobMg/Management/JobQueue";
// import {
//   JobManagement,
//   // JobQueueList,
//   // JobQueue, // lawas
//   // JobQ, // don"t do this, i don"t know kenapa error
// } from "./JobMg/Management";

import { Store } from "App/Redux";
import MerchantRedirect from "App/Screens/SubModules/Merchant";
import AccountList from "App/Screens/SubModules/Merchant/Account";
import AccountManagement from "App/Screens/SubModules/Merchant/Account/Management";
import MerchantMappingModeList from "App/Screens/SubModules/Merchant/MappingMode";
import MerchantMappingModeManagement from "App/Screens/SubModules/Merchant/MappingMode/Management";
import MerchantGroupList from "App/Screens/SubModules/Merchant/Group";
import MerchantGroupManagement from "App/Screens/SubModules/Merchant/Group/Management";
import MerchantGroupRefSkuMapping from "App/Screens/SubModules/Merchant/Group/Management/SkuMapping/Management";
import MerchantPricingList from "App/Screens/SubModules/Merchant/Pricing";
import MerchantPricingManagement from "App/Screens/SubModules/Merchant/Pricing/Management";
import MerchantList from "App/Screens/SubModules/Merchant/Merchant";
import MerchantManagement from "App/Screens/SubModules/Merchant/Merchant/Management";
import MerchantRefSkuMapping from "App/Screens/SubModules/Merchant/Merchant/Management/SkuMapping/Management";
import SkuMappingList from "App/Screens/SubModules/Merchant/SkuMapping";
import SkuMappingManagement from "App/Screens/SubModules/Merchant/SkuMapping/Management";
import ExtraChargeList from "App/Screens/SubModules/Merchant/ExtraCharge";
import ExtraChargeManagement from "App/Screens/SubModules/Merchant/ExtraCharge/Management";
import ExtraChargeSizeList from "App/Screens/SubModules/Merchant/ExtraChargeSize";
import ExtraChargeSizeManagement from "App/Screens/SubModules/Merchant/ExtraChargeSize/Management";
import TagList from "App/Screens/SubModules/Merchant/Tag";
import TagManagement from "App/Screens/SubModules/Merchant/Tag/Management";
import TagMerchantList from "App/Screens/SubModules/Merchant/TagMerchant";
import TagMerchantManagement from "App/Screens/SubModules/Merchant/TagMerchant/Management";

// expert system
import ExpertSystemRedirect from "App/Screens/SubModules/ExpertSystem";
import RulesList from "App/Screens/SubModules/ExpertSystem/Rules";
import KeywordList from "App/Screens/SubModules/ExpertSystem/Keyword";
import KeywordManagement from "App/Screens/SubModules/ExpertSystem/Keyword/Management";
import KeywordGroupList from "App/Screens/SubModules/ExpertSystem/KeywordGroup";
import KeywordGroupManagement from "App/Screens/SubModules/ExpertSystem/KeywordGroup/Management";
import KeywordPageList from "App/Screens/SubModules/ExpertSystem/KeywordPage";
import KeywordPageManagement from "App/Screens/SubModules/ExpertSystem/KeywordPage/Management";
import FeedbackList from "./ExpertSystem/Feedback";
import FeedbackManagement from "./ExpertSystem/Feedback/Management";
import FeedbackItemDetail from "./ExpertSystem/Feedback/Management/Detail";

// ibesv3
import IBESv3Redirect from "App/Screens/SubModules/v3";
import IBESv3Downloads from "App/Screens/SubModules/v3/Downloads";

const auth = (route) => {
  return Store.getState().userReducer.isAuth;
};

const appName = "sub-modules";

const loginRoutes = [
  {
    title: "Job Management",
    routes: [
      // {
      //   path: "/job-management/jobs/:id",
      //   exact: true,
      //   redirect: "/job-management/jobs/:id/stats",
      // },
      // {
      //   auth,
      //   path: "/job-management/jobs/:id",
      //   name: "Job Management Detail",
      //   component: JobManagement,
      //   to: "/login",
      //   // exact: false, // aktifkan ini jika di dalam detail ada sub url
      // },
      // {
      //   path: "/job-management/job-queues/:id",
      //   exact: true,
      //   redirect: "/job-management/job-queues/:id/stats",
      // },
      // {
      //   auth,
      //   path: "/job-management/job-queues/:id",
      //   codename: "job",
      //   name: "Job Queue",
      //   component: JobQ,
      //   to: "/login",
      //   exact: false,
      // },
    ],
  },
];

const appRoutes = [
  {
    title: "User Group",
    icon: "robot",
    routes: [
      {
        auth,
        path: `${BaseUrl.userIbes}/:id`,
        codename: "user_role",
        to: "/login",
        name: "User Management",
        component: UserManagement,
        // exact: false,
      },
      {
        auth,
        path: `${BaseUrl.group}/:id/history/:detail`,
        codename: "user_role",
        to: "/login",
        name: "History Group",
        component: GroupManagement,
        exact: false,
      },
      {
        auth,
        path: `${BaseUrl.userIbes}/:id/history/:detail`,
        codename: "user_role",
        to: "/login",
        name: "History User",
        component: UserManagement,
        exact: false,
      },
      {
        auth,
        path: `${BaseUrl.group}/:id`,
        codename: "user_role",
        to: "/login",
        name: "Group",
        component: GroupManagement,
        exact: false,
      },
      {
        auth,
        path: `${BaseUrl.userTracker}/:id`,
        codename: "user_role",
        to: "/login",
        name: "User Tracker",
        component: UserTrackerManagement,
      },
      {
        auth,
        name: "User / Group",
        path: "/user-group",
        codename: "user_role",
        to: "/login",
        component: UserGroup,
        exact: false,
        routes: [
          // {
          //   redirect: "group"
          // },
          {
            path: "group",
            name: "Group",
            component: Group,
          },
          {
            path: "user/all",
            name: "User",
            component: UserAllList,
          },
          {
            path: "user/ibes",
            name: "User",
            component: User,
          },
          {
            path: "user/tracker",
            name: "User",
            component: UserTrackerList,
          },
          {
            path: "manage",
            redirect: "manage/day-off",
          },
          {
            path: "manage/day-off",
            name: "Day Off",
            component: DayOff,
          },
        ],
      },
    ],
  },
  {
    title: "Branches & Queue",
    routes: [
      // {
      //   path: "/branches/:id",
      //   redirect: "/branches/:id/stats"
      // },
      {
        auth,
        path: "/branch-queue",
        codename: "branch",
        name: "Branch & Queue",
        component: BranchQueue,
        to: "/login",
      },
      {
        auth,
        path: "/branch-queue/branch",
        codename: "branch",
        name: "Branch",
        component: BranchList,
        to: "/login",
      },
      {
        auth,
        path: "/branch-queue/branch/:id",
        codename: "branch",
        name: "Branch Detail",
        component: BranchManagement,
        to: "/login",
        exact: false,
      },
      {
        auth,
        path: "/branch-queue/queue",
        codename: "branch",
        name: "Job Queue",
        component: JobQueueList,
        to: "/login",
      },
      {
        auth,
        path: "/branch-queue/queue/:id",
        codename: "branch",
        name: "Job Queue Detail",
        component: JobQueueManagement,
        to: "/login",
        exact: false,
      },
      {
        auth,
        path: "/branch-queue/holiday",
        codename: "branch",
        name: "Holiday",
        component: HolidayList,
        to: "/login",
      },
      {
        auth,
        path: "/branch-queue/holiday/:id",
        codename: "branch",
        name: "Holiday",
        component: HolidayManagement,
        to: "/login",
      },
    ],
  },
  {
    title: "Branches",
    icon: "branches",
    routes: [
      // {
      //   auth,
      //   path: "/branches",
      //   codename: "branch",
      //   name: "Branch Management",
      //   component: Branch,
      //   to: "/",
      // }
    ],
  },
  {
    routes: [
      {
        auth,
        path: "/product",
        codename: "product",
        name: "Product",
        component: ProductRedirect,
        to: "/login",
      },
      {
        auth,
        path: "/product/group",
        codename: "product",
        name: "Product Group",
        component: ProductGroupList,
        to: "/login",
      },
      {
        auth,
        path: "/product/group/:id",
        codename: "product",
        name: "Product Group",
        component: ProductGroupManagement,
        to: "/login",
      },
      {
        auth,
        path: "/product/product",
        codename: "product",
        name: "Product",
        component: Products,
        to: "/login",
      },
      {
        auth,
        path: "/product/product/:id",
        codename: "product",
        name: "Product",
        component: ProductManagement,
        to: "/login",
        exact: false,
      },
    ],
  },
  {
    title: "Download IBESv3",
    // icon: 'appstore',
    routes: [
      {
        path: "/v3",
        codename: "v3",
        name: "Download IBESv3",
        component: IBESv3Redirect,
        to: "/login",
      },
      {
        path: "/v3/downloads",
        codename: "v3",
        name: "Download IBESv3",
        component: IBESv3Downloads,
        to: "/login",
      },
    ],
  },
  {
    title: "Job Management",
    icon: "usb",
    routes: [
      {
        path: "/job-management",
        codename: "job",
        name: "Job Management",
        // redirect: "/job-management/jobs"
        component: MainJob,
      },
      // {
      //   auth,
      //   path: "/job-management/job-queues/:id",
      //   name: "Job Queue",
      //   component: JobQueue,
      //   to: "/login"
      // },
      {
        auth,
        path: "/job-management/jobs",
        codename: "job",
        name: "Job Management",
        component: JobMg,
        to: "/login",
      },
      {
        auth,
        path: "/job-management/notes",
        name: "Consistency Notes",
        component: ConsistencyNotesList,
        to: "/login",
      },
      {
        auth,
        path: "/job-management/notes/:id",
        name: "Consistency Notes",
        component: ConsistencyNotesManagement,
        to: "/login",
      },
      {
        auth,
        path: "/job-management/erf",
        name: "ERF",
        component: ErfTypeList,
        to: "/login",
      },
      {
        auth,
        path: "/job-management/erf/:id",
        name: "ERF",
        component: ErfTypeManagement,
        to: "/login",
      },
      {
        auth,
        path: "/job-management/erf/:erftype_id/erf-mapping/:id",
        name: "ERF Mapping",
        component: ErfMappingManagement,
        to: "/login",
      },
      {
        auth,
        to: "/login",
        path: "/job-management/building-type",
        name: "Building Type",
        component: BuildingTypeList,
      },
      {
        auth,
        to: "/login",
        path: "/job-management/building-type/:id",
        name: "Building Type",
        component: BuildingTypeManagement,
      },
      {
        auth,
        to: "/login",
        path: "/job-management/rate-type",
        name: "Rate Type",
        component: RateTypeList,
      },
      {
        auth,
        to: "/login",
        path: "/job-management/rate-type/:id",
        name: "Rate Type",
        component: RateTypeManagement,
      },
      {
        auth,
        to: "/login",
        path: "/job-management/job-grade",
        name: "Job Grade",
        component: JobGradeList,
      },
      {
        auth,
        to: "/login",
        path: "/job-management/job-grade/:id",
        name: "Job Grade",
        component: JobGradeManagement,
      },
      {
        auth,
        to: "/login",
        path: "/job-management/job-type",
        name: "Job Type",
        component: JobTypeList,
      },
      {
        auth,
        to: "/login",
        path: "/job-management/job-type/:id",
        name: "Job Type",
        component: JobTypeManagement,
      },
      {
        auth,
        to: "/login",
        path: "/job-management/range",
        name: "Range",
        component: RangeList,
      },
      {
        auth,
        to: "/login",
        path: "/job-management/range/:id",
        name: "Range",
        component: RangeManagement,
      },
      {
        auth,
        to: "/login",
        path: "/job-management/transition",
        name: "Transition",
        component: TransitionList,
      },
      {
        auth,
        to: "/login",
        path: "/job-management/transition/:id",
        name: "Transition",
        component: TransitionManagement,
      },
      // {
      //   auth,
      //   path: "/job-management/job-queues",
      //   codename: "job",
      //   test: 'asdf',
      //   name: "Job Queue",
      //   component: JobQueueList,
      //   to: "/login"
      // },
      // {
      //   path: "/job-management/job-queues/:id",
      //   exact: true,
      //   redirect: "/job-management/job-queues/:id/stats",
      // },
      // {
      //   auth,
      //   path: "/job-management/job-queues/:id",
      //   codename: "job",
      //   name: "Job Queue",
      //   component: JobQ,
      //   to: "/login",
      //   exact: false,
      // },
    ],
  },
  {
    routes: [
      {
        auth,
        path: "/expert-system",
        codename: "expert_system",
        name: "Expert System",
        component: ExpertSystemRedirect,
        to: "/login",
      },
      {
        auth,
        path: "/expert-system/rules",
        codename: "expert_system",
        name: "rules list",
        component: RulesList,
        to: "/login",
      },
      {
        auth,
        path: "/expert-system/keywords",
        codename: "expert_system",
        name: "Keyword",
        component: KeywordList,
        to: "/login",
      },
      {
        auth,
        path: "/expert-system/keywords/:id",
        codename: "expert_system",
        name: "Keyword",
        component: KeywordManagement,
        to: "/login",
      },
      {
        auth,
        path: "/expert-system/keyword-groups",
        codename: "expert_system",
        name: "Keyword Group",
        component: KeywordGroupList,
        to: "/login",
      },
      {
        auth,
        path: "/expert-system/keyword-groups/:id",
        codename: "expert_system",
        name: "Keyword Group",
        component: KeywordGroupManagement,
        to: "/login",
      },
      {
        auth,
        path: "/expert-system/keyword-pages",
        codename: "expert_system",
        name: "Keyword Page",
        component: KeywordPageList,
        to: "/login",
      },
      {
        auth,
        path: "/expert-system/keyword-pages/:id",
        codename: "expert_system",
        name: "Keyword Page",
        component: KeywordPageManagement,
        to: "/login",
      },
      {
        auth,
        path: "/expert-system/feedbacks",
        codename: "expert_system",
        name: "Feedback",
        component: FeedbackList,
        to: "/login",
      },
      {
        auth,
        path: "/expert-system/feedbacks/:id(\\d+)",
        codename: "expert_system",
        name: "Feedback",
        component: FeedbackManagement,
        to: "/login",
      },
      {
        auth,
        path: "/expert-system/feedbacks/:id(\\d+)/:type(fact|validation)/:item_id(\\d+)",
        codename: "expert_system",
        name: "Feedback",
        component: FeedbackItemDetail,
        to: "/login",
      },
    ],
  },
  {
    routes: [
      {
        auth,
        to: "/login",
        name: "Merchant",
        path: "/merchant",
        codename: "merchant",
        component: MerchantRedirect,
      },
      {
        auth,
        to: "/login",
        path: "/merchant/account",
        name: "Account",
        component: AccountList,
      },
      {
        auth,
        to: "/login",
        path: "/merchant/account/:id",
        name: "Account",
        component: AccountManagement,
      },
      {
        auth,
        to: "/login",
        path: "/merchant/mapping-mode",
        name: "Mapping Mode",
        component: MerchantMappingModeList,
      },
      {
        auth,
        to: "/login",
        path: "/merchant/mapping-mode/:id",
        name: "Mapping Mode",
        component: MerchantMappingModeManagement,
      },
      {
        auth,
        to: "/login",
        path: "/merchant/group",
        name: "Merchant Group",
        component: MerchantGroupList,
      },
      {
        auth,
        to: "/login",
        path: "/merchant/group/:id",
        name: "Merchant Group",
        component: MerchantGroupManagement,
      },
      {
        auth,
        to: "/login",
        path: "/merchant/group/:id/sku-mapping/:refId",
        name: "SKU Mapping",
        component: MerchantGroupRefSkuMapping,
      },
      {
        auth,
        to: "/login",
        path: "/merchant/pricing",
        name: "Merchant Pricing",
        component: MerchantPricingList,
      },
      {
        auth,
        to: "/login",
        path: "/merchant/pricing/:id",
        name: "Merchant Pricing",
        component: MerchantPricingManagement,
      },
      {
        auth,
        to: "/login",
        path: "/merchant/merchant",
        name: "Merchant",
        component: MerchantList,
      },
      {
        auth,
        to: "/login",
        path: "/merchant/merchant/:id",
        name: "Merchant",
        component: MerchantManagement,
      },
      {
        auth,
        to: "/login",
        path: "/merchant/merchant/:id/sku-mapping/:refId",
        name: "SKU Mapping",
        component: MerchantRefSkuMapping,
      },
      {
        auth,
        to: "/login",
        path: "/merchant/sku-mapping",
        name: "SKU Mapping",
        component: SkuMappingList,
      },
      {
        auth,
        to: "/login",
        path: "/merchant/sku-mapping/:id",
        name: "SKU Mapping",
        component: SkuMappingManagement,
      },
      {
        auth,
        to: "/login",
        path: "/merchant/extra-charge",
        name: "Extra Charge",
        component: ExtraChargeList,
      },
      {
        auth,
        to: "/login",
        path: "/merchant/extra-charge/:id",
        name: "Extra Charge",
        component: ExtraChargeManagement,
      },
      {
        auth,
        to: "/login",
        path: "/merchant/extra-charge-size",
        name: "Extra Charge Size",
        component: ExtraChargeSizeList,
      },
      {
        auth,
        to: "/login",
        path: "/merchant/extra-charge-size/:id",
        name: "Extra Charge Size",
        component: ExtraChargeSizeManagement,
      },
      {
        auth,
        to: "/login",
        path: "/merchant/tag",
        name: "Tag",
        component: TagList,
      },
      {
        auth,
        to: "/login",
        path: "/merchant/tag/:id",
        name: "Tag",
        component: TagManagement,
      },
      {
        auth,
        to: "/login",
        path: "/merchant/tag-merchant",
        name: "Tag Merchant",
        component: TagMerchantList,
      },
      {
        auth,
        to: "/login",
        path: "/merchant/tag-merchant/:id",
        name: "Tag Merchant",
        component: TagMerchantManagement,
      },
    ],
  },
];

export default {
  App: { appName: startCase(appName), routes: appRoutes },
  Login: { appName: startCase(appName), routes: loginRoutes },
};
