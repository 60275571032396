import React from 'react'
import { Button, Card, Col, Row, Spin } from 'antd'
import { Pie } from 'ant-design-pro/lib/Charts'
import Lottie from 'react-lottie'

import BaseRequest from './BaseRequest'
import * as homeLottie from 'App/Assets/lottie/home'

class ActiveJobs extends BaseRequest {
  constructor(props) {
    super(props)

    this.urlKey = 'module-groupUser-user-activeJobStats'
  }

  render() {
    const { data } = this.state

    return (
      <Spin spinning={!!this.state.isLoading}>
        <Card
          loading={!this.state.data && this.state.isLoading}
          size='small'
          title='Active Jobs (30 days)'
          extra={
            <Button size='small' loading={this.state.isLoading} icon='reload' onClick={this.read}>
              Refresh
            </Button>
          }
        >
          {!this.state.data ? this.getErrorComp() : (
            <div>
              <Row type='flex' justify='space-between'>
                <Col xs={24} sm={24} md={12} lg={24} xl={12}>
                  <Pie
                    width={240}
                    height={180}
                    percent={(data.total_worked_on - data.total_owned) / (data.total_worked_on || 1) * 100}
                    subTitle={
                      <div>
                        <span style={{ fontSize: '32px' }}>{data.total_owned}</span>
                        /{data.total_worked_on} jobs
                      </div>
                    }
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={24} xl={12}>
                  <div>
                    <Lottie
                      width={240}
                      height={160}
                      options={{
                        loop: false,
                        autoplay: true,
                        animationData: homeLottie.default,
                        rendererSettings: {
                          preserveAspectRatio: 'xMidYMid slice',
                        }
                      }}
                    />
                  </div>
                  <div style={{ fontSize: '28px', fontWeight: 500, textAlign: 'center' }} title='Area'>
                    {data.total_area} m<sup>2</sup>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </Card>
      </Spin>
    )
  }
}

export default ActiveJobs