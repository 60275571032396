import React from 'react'
import PropTypes from 'prop-types'
import { Button, Col, Empty, Row } from 'antd'
import { get } from 'lodash'
import { Lib, RequestV2 as Request } from 'App/Utils'
import LoadingSpin from 'App/Component/LoadingSpin'
import PageError from 'App/Component/PageError'
import Content from './Content'

class PageQueues extends React.PureComponent {
  constructor(props) {
    super(props)

    this.page = 1
    this.limit = 25
    this.params = ['page', 'limit']
    this.state = {
      isLoading: true, // for show loading only
      isReloading: false,
      isLoadMore: false,
      rawData: null,
      selectedQueue: null,
      hasOpened: {},
    }
  }

  getThisParam = () => {
    const thisParam = {}
    for (let i = 0; i < this.params.length; i += 1) {
      const valParam = this[this.params[i]]
      if (valParam || valParam === 0) {
        thisParam[this.params[i]] = valParam
      }
    }
    return thisParam
  }

  read = (keyLoading = 'isLoading', callback = () => null) => {
    this.setState({ [keyLoading]: true }, () => {
      Request(
        'get',
        'api:admin:exim:session:ref-import-queues-list',
        {},
        this.getThisParam(),
        [this.props.exportImportId],
        this.readSuccess,
        this.readFailed,
        { keyLoading, callback },
      )
    })
  }

  readSuccess = (response, extra) => {
    const anotherStates = {}
    if (!this.state.selectedQueue) {
      if (response.data.results.length) {
        anotherStates.selectedQueue = response.data.results[0].pk
        anotherStates.hasOpened = { ...this.state.hasOpened }
        anotherStates.hasOpened[response.data.results[0].pk] = true
      }
    }
    if (extra.keyLoading !== 'isLoadMore') {
      this.setState({ [extra.keyLoading]: false, rawData: response.data, ...anotherStates }, () => {
        extra.callback(true, response)
      })
    } else {
      extra.callback(true, response)
    }
  }

  readFailed = (error, extra) => {
    if (!this.state.isLoading) Lib.errorMessageHandler(get(error, 'response'))
    this.setState({ [extra.keyLoading]: false, errorResponse: get(error, 'response') }, () => {
      extra.callback(false, error)
    })
  }

  reload = (keyLoading = 'isReloading', callback = () => null) => {
    this.read(keyLoading, (isSuccess) => !isSuccess && callback(true)) // supaya tidak menampilkan pesan error di PageError jadi dikirim true
  }

  loadMore = () => {
    this.page += 1
    this.read('isLoadMore', (isSuccess, response) => {
      if (isSuccess) {
        const rawData = {
          ...response.data,
          results: [
            ...this.state.rawData.results,
            ...response.data.results
          ],
        }
        this.setState({ isLoadMore: false, rawData })
      } else {
        this.page -= 1
      }
    })
  }

  onSelectQueue = (queueId) => {
    const hasOpened = { ...this.state.hasOpened, [queueId]: true }
    this.setState({ selectedQueue: queueId, hasOpened })
  }

  componentDidMount() {
    this.read()
  }

  render() {
    // ilmu baru: const { variabelA, ...anotherA } = this.props
    const { isLoading, isReloading, isLoadMore, rawData, errorResponse, selectedQueue } = this.state

    if (isLoading) {
      return (
        <LoadingSpin height='200px' tip='Loading...' />
      )
    }

    return rawData ? (
      <div style={{ margin: '0 -24px' }}>
        <div style={{ position: 'relative', minHeight: '100px' }}>
          <div style={{ position: 'absolute', top: 0, bottom: 0, width: '13rem', borderRight: '2px solid #e8e8e8', overflowY: 'auto' }}>
            <div>
              {rawData.results.length > 0 ? (
                rawData.results.map((val, index) => (
                  <Row key={val.pk} style={{ padding: '6px 12px', cursor: 'pointer', background: selectedQueue === val.pk ? '#1890ff' : '', fontWeight: selectedQueue === val.pk ? 'bold' : 'normal', color: selectedQueue === val.pk ? '#fff' : '', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} onClick={() => this.onSelectQueue(val.pk, val)}>
                    <Col>
                      {val.label}
                    </Col>
                  </Row>
                ))
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
              {rawData.next && (
                <Row type='flex' justify='center' style={{ width: '100%', padding: '6px 12px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  <Col>
                    <Button icon='caret-down' loading={isLoadMore} onClick={this.loadMore}>
                      Load More
                    </Button>
                  </Col>
                </Row>
              )}
            </div>
          </div>
          <div style={{ position: 'relative', marginLeft: '13rem' }}>
            <div style={{ padding: '0 12px' }}>
              {rawData.results.map((val, index) => (
                this.state.hasOpened[val.pk] && (
                  <Content
                    key={val.pk}
                    visible={selectedQueue === val.pk}
                    queueId={val.pk}
                  />
                )
              ))}
            </div>
          </div>
        </div>
        {/* <Row type='flex'>
          <Col style={{ width: '250px', overflowY: 'auto', borderRight: '1px solid #e8e8e8' }}>
            <div style={{ padding: '12px' }}>
              {rawData.results.map(val => (
                <Row key={val.pk} type='flex'>
                  <Col>
                    {val.label}
                  </Col>
                </Row>
              ))}
            </div>
          </Col>
          <Col style={{ width: 'calc(100%-250px)' }}>
            Right
          </Col>
        </Row> */}
      </div>
    ) : (
      <PageError errorResponse={errorResponse} onReload={this.reload} />
    )
  }
}

PageQueues.propTypes = {
  exportImportId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

export default PageQueues