export default [
  {
    title: 'Name',
    dataIndex: 'name',
    type: 'text',
    required: true,
    initialValue: '',
  },
  {
    title: 'Order',
    dataIndex: 'order',
    type: 'number',
    initialValue: null,
  },
  {
    title: 'Is Important',
    dataIndex: 'is_important',
    type: 'checkbox',
    initialValue: false,
  },
  {
    title: 'Is Active',
    dataIndex: 'is_active',
    type: 'checkbox',
    initialValue: true,
  },
]
