import React from 'react'
import { BaseList } from 'App/Screens/SubModules/Component'
import descriptor from './descriptorOffline'
import CalendarView from './CalendarView'

const urlList = '/branch-queue/holiday'

class HolidayList extends BaseList {
  constructor(props) {
    super(props)

    this.appName = 'jobs'
    this.modelName = 'holiday'
    this.propsList = {
      avatar: 'name',
      avatarColorKey: 'branch.color',
      pageTitle: 'Holiday',
      masterKey: 'module-branch-holiday',
      descriptor,
      panelView: [
        { value: 'calendar', label: 'Calendar', icon: 'calendar', active: true },
        { value: 'list', label: 'List', icon: 'profile' },
      ],
      onClickView: this.onClickCalendar,
      viewComponent: this.getCalendarView,
      onDetailClick: this.onDetailClick,
    }
  }

  onClickCalendar = () => {
    console.log('onClickCalendar')
  }

  getCalendarView = () => <CalendarView onDetailClick={this.onDetailClick} />

  onDetailClick = (id, record) => {
    this.props.history.push(`${urlList}/${id}`)
  }
}

export default HolidayList