import React from 'react'
import PropTypes from 'prop-types'
import descriptions from './descriptions'
import CustomDescriptions from 'App/Screens/Estimation/ExportImportSessionNew/internal/CustomDescriptions'

class PageGeneralQueue extends React.PureComponent {
  render() {
    const { queue } = this.props

    return (
      <div>
        <CustomDescriptions fields={descriptions} record={queue} />
      </div>
    )
  }
}

PageGeneralQueue.propTypes = {
  queue: PropTypes.object.isRequired,
}

export default PageGeneralQueue