import { BaseRedirectMenu } from 'App/Screens/SubModules/Component'
import JobMenu from './Menu'

class JobRedirect extends BaseRedirectMenu {
  constructor(props) {
    super(props)

    this.menu = JobMenu
  }
}

export default JobRedirect