import { BaseDetail } from 'App/Screens/SubModules/Component'

class PageDetail extends BaseDetail {
  constructor(props) {
    super(props)

    this.urlKey = 'module-merchant-pricing-detail'
    this.title = 'Merchant Pricing'
    this.urlList = '/merchant/pricing'
    this.headerDescriptions = [
      { key: 'merchant.name', label: 'Merchant' },
      { key: 'is_pricing', label: 'Pricing', type: 'bool' },
      { key: 'created', label: 'Created', type: 'datetime' },
      { key: 'created_by.username', label: 'Created By' },
      { key: 'modified', label: 'Modified', type: 'datetime' },
      { key: 'modified_by.username', label: 'Modified By' },
    ]
    // this.history = {
    //   url: 'module-merchant-extraChargeSize-history',
    //   fields: fields,
    //   userReducer: props.userReducer,
    //   urlDetail: "module-merchant-extraChargeSize-history-detail",
    //   id: props.match.params.id
    // }
  }
}

export default PageDetail