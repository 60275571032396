import React from 'react'
import { BaseDetail } from 'App/Component/Bases'
import fields from './fields'

class FormAdd extends BaseDetail {
  title = 'Merchant Group';
  urlData = 'module-merchant-group';
  fields = fields;
  state = {
    isCheckingPermission: false,
    isAccessAllowed: true,
  }
}

class PageAdd extends React.Component {
  render() {
    return (
      <FormAdd {...this.props} />
    )
  }
}

export default PageAdd