import React from 'react'
import { Row, Spin, Icon } from 'antd'
import { get } from 'lodash'
import UserAdd from './Add'
import UserDetail from './Detail'
import { PermissionName } from 'App/Config'
import PageUnauthorized from 'App/Component/PageUnauthorized'
import { forcePermissions } from 'App/Data'
import { CheckPermission } from 'App/Utils'

class UserManagement extends React.Component {
  constructor(props) {
    super(props)

    this.isSuperuser = get(props.userReducer, ['user', 'is_superuser'])
    this.state = {
      isCheckingPermission: !this.isSuperuser,
      isAccessAllowed: this.isSuperuser,
      currentPermissionSet: this.isSuperuser ? forcePermissions : [],
    }
  }

  setThisState = (obj, callback = () => null, force) => {
    if (force === true) {
      return this.setState(obj, callback)
    } else {
      for (const key in obj) {
        if (obj[key] !== this.state[key]) {
          return this.setState(obj, callback)
        }
      }
    }
    callback()
  }

  handleCheckPermission = (callback = () => null) => {
    this.setThisState({ isCheckingPermission: true }, () => {
      const currentPermissionSet = CheckPermission.getPermissionSet({
        userReducer: this.props.userReducer,
        appName: 'account',
        modelName: 'user',
      })

      const codePart = this.props.match.params.id === 'add' ? PermissionName.CREATE.codePart : PermissionName.READ.codePart
      // const codePart = PermissionName.READ.codePart // sementara
      const isAccessAllowed = !!currentPermissionSet.find(val => val.search(codePart) !== -1)
      // const allowedModules = get(this.props.userReducer, ['user', 'allowed_modules'], [])
      // const selectedHeader = HeaderMenu.find(val => val.url === '/user-group')
      // let currentPermissionSet = []
      // if (selectedHeader) {
      //   const modulePermission = allowedModules.find(val => val.codename === selectedHeader.codename)
      //   const permissionCode = `account.${codePart}_user`
      //   if (modulePermission) {
      //     for (let i = 0; i < modulePermission.sets.length; i += 1) {
      //       if (modulePermission.sets[i].permissions.find(permission => permission.code === permissionCode)) {
      //         currentPermissionSet = modulePermission.sets[i].permissions
      //         break
      //       }
      //     }
      //   }
      // }
      callback({ isAccessAllowed, currentPermissionSet })
    })
  }

  componentDidMount() {
    // Please use callbackAllowed when access is allowed,
    // Jangan menuliskan code is allowed di luar kondisi
    const callbackAllowed = () => { /* Your code here */ }
    if (!this.isSuperuser) {
      this.handleCheckPermission(res => {
        this.setState({
          isCheckingPermission: false,
          isAccessAllowed: get(res, 'isAccessAllowed'),
          currentPermissionSet: get(res, 'currentPermissionSet', [])
        }, () => {
          if (get(res, 'isAccessAllowed')) {
            callbackAllowed()
          }
        })
      })
    } else {
      callbackAllowed()
    }
  }
  
  render() {
    const { isCheckingPermission, isAccessAllowed, currentPermissionSet } = this.state

    if (isCheckingPermission) {
      return (
        <Row type='flex' justify='center' align='middle' style={{ marginTop: '30vh' }}>
          <Spin
            size='large'
            tip='Checking permission...'
            indicator={<Icon spin type='loading' style={{ fontSize: '4rem' }} />}
          />
        </Row>
      )
    }

    if (isAccessAllowed) {
      return get(this.props.match, ['params', 'id'], 'add') === 'add' ? (
        <UserAdd {...this.props} />
      ) : (
        <UserDetail {...this.props} currentPermissionSet={currentPermissionSet} />
      )
    }
    return <PageUnauthorized />
  }
}

export default UserManagement