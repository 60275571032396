import React, { Component } from "react";
import { Lib, RequestV2 as Request } from "App/Utils";
import {
  Form,
  Input,
  Drawer,
  Select,
  Button,
  Radio,
  Row,
  Col,
  Icon,
  Divider,
  InputNumber,
  message,
  notification,
  Popover
} from "antd";
import DropPremise from "./tabledrop";
import { isEmpty,isString } from "validate.js";
import SelectQuery from "App/Component/SelectQuery";
import { get,cloneDeep,hasIn } from "lodash";
import { v4 as uuidv4 } from "uuid";
const { Option, OptGroup } = Select;

const dummy = {
  is_root: true,
  identifier: uuidv4(),
  value_type: "bool",
  premises: [
    {
      id: "no Group",
      comparer: "",
      key: "",
      compare_value: "",
      is_deleted: false,
      group: "",
      identifier: uuidv4(),
      childObj: {
        premises: [],
        key: "",
        pk: "",
        parentObj: {
          key: "",
        },
      },
    },
  ],
};
class GeneralForm extends React.Component {
  constructor(props) {
    super(props);
    this.data2 = { ...get(props, "data", dummy) };
    this.cloneDummy = cloneDeep(dummy);
    this.state = {
      confirmDirty: false,
      typeForm: "",
      factType: "",
      dataPremises: [],
      datakirim : {indikator : true , group :""},
      group: [
        {
          identifier: "",
          childObj: {
            key: "out of group",
            identifier: "",
          },
        },
      ],
      initialGroupKey: 0,
      final: [],
      content: null,
      section: null,
      subsection: null,
      prefilset: null,
    };
    this.onChange = this.onChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  change(e, bagian) {
    if (bagian === "content") {
      this.setState({ content: get(e, "key", null) });
    } else if (bagian === "section") {
      this.setState({ section: get(e, "key", null) });
    } else if (bagian === "subsection") {
      this.setState({ subsection: get(e, "key", null) });
    } else if (bagian === "prefilset") {
      this.setState({ prefilset: get(e, "key", null) });
    }
  }

  filter() {
    const content = this.state.content;
    return (
      <Row>
        <Col style={{ marginTop: "5px" }}>
          <h5>Section</h5>
          <SelectQuery
            style={{ width: "100px" }}
            size="default"
            urlKey="sections-autocomplete"
            valKey={"pk"}
            onChange={(e) => this.change(e, "section")}
            valLabel={"name"}
            dropdownClassName="width-auto2"
          />
        </Col>
        {content !== 18 && (
          <Col style={{ marginTop: "5px" }}>
            <h5>Subsection</h5>
            <SelectQuery
              style={{ width: "100px" }}
              size="default"
              urlKey="subsections-autocomplete"
              onChange={(e) => this.change(e, "subsection")}
              valKey={"pk"}
              paramProps={{ section: this.state.section }}
              valLabel={"name"}
              dropdownClassName="width-auto2"
            />
          </Col>
        )}
        {content === 24 && (
          <Col style={{ marginTop: "5px" }}>
            <h5>Prefil set</h5>
            <SelectQuery
              style={{ width: "100px" }}
              size="default"
              urlKey="prefillSets-autocomplete"
              onChange={(e) => this.change(e, "prefilset")}
              valKey={"pk"}
              paramProps={{ subsection: this.state.subsection }}
              valLabel={"name"}
              dropdownClassName="width-auto2"
            />
          </Col>
        )}
      </Row>
    );
  }
  cekGroupPremises(data) {
    // console.log("dataaa",this.datakirim,data)
    let datakirim = this.state.datakirim
    let jumblah = 0;
    for (let i = 0; i < data.premises.length; i++) {
      // console.log("premise",data.premises[i])
      if (!("pk" in data.premises[i].childObj) && datakirim.indikator !== false) {
        if ("is_deleted" in data.premises[i]) {
          datakirim = {indikator : true , group :""}
        }
        for (let a = 0; a < data.premises[i].childObj.premises.length; a++) {
          if (hasIn(data.premises[i].childObj.premises[a], "is_deleted")) {
            // console.log("masuk1");
            jumblah = +1;
          }
        }
        // console.log("datuuaa",this.datakirim,data.premises[i].childObj.premises.length,jumblah,)
        if (data.premises[i].childObj.premises.length < 2 && jumblah === 0 && !("is_deleted" in data.premises[i])) {
          // console.log("masuk3", data.premises[i].childObj, jumblah);
          datakirim ={indikator : false , group :data.premises[i].childObj.key };
          this.datakirim = datakirim
        } else if (
          data.premises[i].childObj.premises.length + jumblah < 2 &&
          jumblah !== 0 && !("is_deleted" in data.premises[i])
        ) {
          // console.log("masuk2", data.premises[i].childObj, jumblah);
           datakirim ={indikator : false , group :data.premises[i].childObj.key };
           this.datakirim = datakirim
        }else{
          this.cekGroupPremises(data.premises[i].childObj);
        }
        
      // }else if(datakirim.indikator === false) {
      //   console.log("datakirim",datakirim)
      //   this.datakirim = datakirim
      }
    }
  }
  dataFact(data) {
    // console.log("data", data);
    const dataJadi = [];
    data.form.validateFields((err, values) => {
      if (!err) {
        dataJadi.push(values);
        this.sendtoFactApi(dataJadi[0]);
      } else {
        message.error("please input valid data");
        console.log("errr", err);
        // this.data2.form.resetFields();
      }
    });
  }
  sendtoFactApi(data) {
    data.default = null;
    if (this.props.action === "add") {
      this.submitDataFact(data);
    } else {
      // console.log("data2", data);
      this.submitDataFactEdit(
        data,
        this.props.list === true ? this.props.data.pk : this.data2.pk
      );
    }
  }
  submitDataFact(data) {
    Request(
      "post",
      "expert-system-add-fact",
      {},
      data,
      [],
      this.readfactSuccess,
      this.readfactFailed
    );
  }

  submitDataFactEdit(data, id) {
    // console.log("data3", data);
    Request(
      "put",
      "expert-system-edit-fact",
      {},
      data,
      [id],
      this.readfactSuccess,
      this.readfactFailed
      // { resolve, reject }
    );
  }
  deleteApiRule(id) {
    Request(
      "delete",
      "expert-system-delete-rule",
      {},
      {},
      [id],
      this.deleteApiRuleSucsess,
      this.readfactFailed
      // { resolve, reject }
    );
  }
  deleteApiFact(id) {
    // console.log(id);
    Request(
      "delete",
      "expert-system-delete-fact",
      {},
      {},
      [id],
      this.deleteApiRuleSucsess,
      this.readfactFailed
      // { resolve, reject }
    );
  }
  deleteApiRuleSucsess = (response) => {
    // console.log("berhasil", response);
    this.props.onCloseDrawer();
    message.success("rules have been deleted");
  };

  readfactFailed = (error) => {
    // if (this.props.list === true) {
    //   console.log("masuk")
    //   this.data2.form.resetFields();
    // }
    this.setFields(this.data2, error.response.data, "fact");
    message.error("failed");
  };

  readfactSuccess = (response) => {
    // console.log("massssss");
    if (this.props.action === "edit" && this.props.list === false) {
      // console.log("massss");
      this.props.updatedata1(this.data2);
    } else if (this.props.list === true) {
      this.data2.form.resetFields();
      this.props.updatedata();
    } else if (this.props.action === "add") {
      this.setState({ typeForm: "rule" });
      this.data2.form.resetFields();
      this.data2 = {
        is_root: true,
        identifier: uuidv4(),
        value_type: "bool",
        premises: [
          {
            id: "no Group",
            comparer: "",
            key: "",
            compare_value: "",
            is_deleted: false,
            group: "",
            identifier: uuidv4(),
            childObj: {
              premises: [],
              key: "",
              pk: "",
              parentObj: {
                key: "",
              },
            },
          },
        ],
      };
    }
    this.onclose();
    message.success("successfully");
  };

  Delete = (e) => {
    if (this.state.typeForm === "fact") {
      this.deleteApiFact(this.data2.pk);
    } else {
      this.deleteApiRule(this.data2.pk);
    }
  };
  setFields(final, err, data) {
    if (data === "rule") {
      const finalData = final;
      if (err.key) {
        var formItem = finalData[0].form.getFieldsValue();
        finalData[0].form.setFields({
          key: {
            value: formItem.key,
            errors: [new Error(err.key)],
          },
        });
      }
      if (err.premises) {
        //  if(err.premises.length === 1 && isString(err.premises[0]))
        for (let e = 0; e < err.premises.length; e++) {
          if (!isEmpty(err.premises[e])) {
            var formItem = finalData[e + 1].form.getFieldsValue();
            finalData[e + 1].form.setFields({
              premise_key: {
                value: formItem.premise_key,
                errors:
                  err.premises[e].child !== undefined
                    ? [new Error(err.premises[e].child)]
                    : [new Error("")],
              },
              comparer: {
                value: formItem.comparer,
                errors:
                  err.premises[e].comparer !== undefined
                    ? [new Error(err.premises[e].comparer)]
                    : [new Error("")],
              },
            });
          }
        }
      }
      this.forceUpdate();
    } else {
      if (err.key) {
        var formItem = final.form.getFieldsValue();
        final.form.setFields({
          key: {
            value: formItem.key,
            errors: [new Error(err.key)],
          },
        });
      }
      if (err.comparer) {
        var formItem = final.form.getFieldsValue();
        final.form.setFields({
          comp: {
            value: formItem.comp,
            errors: [new Error(err.comparer)],
          },
        });
      }
    }
  }

  handleSubmit = (e) => {
    if (this.state.typeForm === "fact") {
      this.data2.form = this.props.form;
      this.dataFact(this.data2);
    } else {
      const DataTransfer = [];
      e.preventDefault();
      this.cekGroupPremises(this.data2)
      const group = this.datakirim
      // console.log("group",group)
      if (group.indikator === false) {
        notification.open({
          message: `error in ${group.group}`,
          description: "Group Premise must more than one",
        });
        this.datakirim = {indikator:true, group:""}
      } else {
        const finalData = [];
        this.data2.form = this.props.form;
        finalData.push(this.data2);
        this.setFinalData(finalData, this.data2);
        // console.log("final", finalData);

        this.dataPremiseForm(finalData);
        for (let i = 0; i < finalData.length; i++) {
          if (
            !("is_deleted" in finalData[i]) ||
            finalData[i].is_deleted === false
          ) {
            DataTransfer.push(finalData[i]);
          }
        }
        this.sendData(DataTransfer);
        // console.log("dataaaa",DataTransfer)
        this.setState({ final: DataTransfer });
      }
    }
  };

  submitData(data) {
    // console.log(data);
    // return new Promise((resolve, reject) => {
    Request(
      "post",
      "expert-system-premise-save",
      {},
      data,
      [],
      this.readrulesSuccess,
      this.readrulesFailed
      // { resolve, reject }
    );

    // });
  }

  submitDataEdit(data, id) {
    // console.log("edit", data, id);
    Request(
      "put",
      "expert-system-premise-edit",
      {},
      data,
      [id],
      this.readrulesSuccess,
      this.readrulesFailed
      // { resolve, reject }
    );
  }

  readrulesSuccess = (response) => {
    if (this.props.action === "edit" && this.props.list === false) {
      this.props.updatedata1(this.data2);
    } else if (this.props.list === true) {
      this.data2.form.resetFields();
    } else if (this.props.action === "add") {
      this.data2.premises.splice(0, this.data2.premises.length);
      this.data2.premises.push({
        id: "no Group",
        comparer: "",
        key: "",
        compare_value: "",
        is_deleted: false,
        group: "",
        identifier: uuidv4(),
        childObj: {
          premises: [],
          key: "",
          pk: "",
          parentObj: {
            key: "",
          },
        },
      });
      this.data2.form.resetFields();
      this.data2.HasilValidate.key = "";
      this.data2.HasilValidate.name = "";
      // this.data2.premises = this.cloneDummy.premises
    }
    this.props.onCloseDrawer();
    message.success("successfully");
  };

  readrulesFailed = (error) => {
    // if (this.props.list === true) {
    //   this.data2.form.resetFields();
    // }
    notification.open({
      message: "Error",
      description: "Please check the data again",
    });

    // console.error("gagal", error.response);
    this.setFields(this.state.final, error.response.data, "rule");
    // message.success(err);
    // this.props.onCloseDrawer();
  };

  sendData(data) {
    const dataKirim = [];
    // console.log("submit", data);
    for (let i = 0; i < data.length; i++) {
      if (!("HasilValidate" in data[i])) {
      } else {
        if (data[i] === data[0]) {
          if (data[i].HasilValidate.object_id.key === 0) {
            data[i].HasilValidate.object_id.key = null;
          }
          if (data[i].HasilValidate.Output_content.key === 0) {
            data[i].HasilValidate.Output_content.key = null;
          }
          dataKirim.push({
            description: data[i].HasilValidate.Description,
            output_content_type: data[i].HasilValidate.Output_content.key,
            // output_content_type: null,
            status: data[i].HasilValidate.status,
            key: data[i].HasilValidate.key,
            name:
              data[i].HasilValidate.name === ""
                ? null
                : data[i].HasilValidate.name,
            output_object_id: data[i].HasilValidate.object_id.key,
            identifier: data[i].identifier,
            value_type: data[i].value_type,
            premises: [],
          });
        } else if (!("pk" in data[i].childObj) || data[i].id === "group") {
          let parent = null;
          if (data[i].HasilValidate.group !== undefined) {
            parent = data[i].HasilValidate.group.identifier;
          } else if (data[i].id === "group") {
            // console.log("masuk", data[i]);
            parent = data[i].childObj.identifier;
          }
          dataKirim.push({
            comparer: data[i].comp === undefined ? null : data[i].comp.name,
            input_identifier: data[i].identifier,
            input_parent: parent,
          });
        } else {
          let parent = null;
          let compare_value = data[i].HasilValidate.compare_value;
          if (data[i].HasilValidate.group !== undefined) {
            parent = data[i].HasilValidate.group.identifier;
          } else if (data[i].id === "no Group") {
            parent = data[i].childObj.identifier;
          }
          if (
            data[i].HasilValidate.compare_value === undefined ||
            data[i].HasilValidate.compare_value[0] === undefined
          ) {
            compare_value = null;
          }
          dataKirim.push({
            comparer: data[i].comp ? data[i].comp.name : null,
            input_identifier: data[i].identifier,
            input_comparer_value: compare_value,
            // premise_key: data[i].HasilValidate.premise_key.key,
            input_parent: parent,
            child: get(data[i].HasilValidate, "premise_key.key", null),
          });
        }
      }
    }

    // console.log("sendData", dataKirim);
    this.sendtoApi(dataKirim);
  }
  sendtoApi(data) {
    // console.log("sendtoApi", data);
    for (let i = 0; i < data.length; i++) {
      for (let e = 1; e < data.length; e++) {
        if ("key" in data[i]) {
          data[i].premises.push(data[e]);
        }
      }
    }
    // console.log("send to api", data[0]);
    if (this.props.action === "add") {
      this.submitData(data[0]);
    } else {
      // console.log("data2", this.data2);
      this.submitDataEdit(data[0], this.data2.pk);
    }
  }

  setFinalData(finalData, data) {
    const premises = data.premises || get(data, "childObj.premises", []) || [];
    for (let i = 0; i < premises.length; i++) {
      if (!("pk" in premises[i].childObj)) {
        finalData.push(premises[i]);
        this.setFinalData(finalData, premises[i]);
      } else finalData.push(premises[i]);
    }
  }

  editDummy() {
    dummy.premises[0].childObj.parentObj = dummy;
  }

  // componentWillReceiveProps(nextProps){

  //   let dataTipe = nextProps.tipeRule === true ? "rule" : "fact"
  //   console.log("props",nextProps.tipeRule,dataTipe )
  //   if(this.state.typeForm !== dataTipe){
  //     this.setState({typeForm:dataTipe});
  //   }
  // }

  componentDidMount() {
    var fact_type = get(this.props, "data.fact_type", "keyword");
    this.setState({
      typeForm: this.props.tipeRule === true ? "rule" : "fact",
      factType: fact_type,
    });
    this.editDummy();
    if (this.props.tipeRule === true) {
      this.optionGroup(this.data2);
    }
    if (this.props.list === true) {
      this.forceUpdate();
    }
  }

  componentWillReceiveProps(nextProps) {
    // console.log("aku",nextProps)
    let dataTipe = "rule";
    if (nextProps.tipeRule === false) {
      dataTipe = "fact";
    }
    if (
      nextProps.data !== undefined &&
      this.state.factType !== nextProps.tipeRule
    ) {
      this.data2 = nextProps.data;
      this.optionGroup(nextProps.data);
      this.setState({ typeForm: dataTipe, factType: nextProps.data.fact_type });
    }
  }

  dataPremiseForm(data) {
    const valuess = [];
    const simpan = [];
    for (let i = 0; i < data.length; i++) {
      data[i].form.validateFields((err, values) => {
        // if (!err) {
        valuess.push(values);
        return (data[i].HasilValidate = values);
        // } else {
        // if(err.compare_value){
        // valuess.push(values);
        // return (data[i].HasilValidate = values);
        // }
        // message.error("terjadi kesalahan data")
        // console.log("errr", err);
        // }
      });
    }
    // console.log("validate", valuess);
  }

  handleChange(value) {
    this.setState({ factType: value });
  }
  onChange(e) {
    this.setState({ typeForm: e.target.value });
  }

  // OnChange = (value) => {
  //   console.log("val", value);
  // };
  GroupTable(value, dataIndex, record, data) {
    for (let i = 0; i < data.premises.length; i++) {
      if (data.premises[i].identifier === value.key) {
        let dataBaru = data.premises[i].childObj;
        let a = record.childObj.parentObj.premises.findIndex(
          (item) => item.id === record.id
        );
        data.premises[i].childObj.premises.push(record);
        record.childObj.parentObj.premises.splice(a, 1);
        record.childObj.parentObj = dataBaru;
      } else {
        // console.log("masukkkk");
        this.GroupTable(value, dataIndex, record, data.premises[i].childObj);
      }
    }
  }

  addPremise() {
    // const data = this.data2;
    this.data2.premises.push({
      identifier: uuidv4(),
      id: "no Group",
      premise_key: "",
      comparer: "",
      compare_value: "",
      group: "",
      premises: [],
      childObj: {
        premises: [],
        pk: "",
        parentObj: {
          ...this.data2,
        },
      },
    });
    this.forceUpdate();
  }

  addGroup() {
    const dataOption = this.state.group;
    const initialGroupKey = this.state.initialGroupKey + 1;
    const dataGroup = {
      id: "group",
      premise_key: "",
      comparer: "",
      identifier: uuidv4(),
      compare_value: "",
      group: "",
      childObj: {
        key: `new group ${initialGroupKey}`,
        premises: [],
        parentObj: {
          ...this.data2,
        },
      },
    };
    this.data2.premises.push(dataGroup);
    dataOption.push(dataGroup);
    this.setState({ initialGroupKey: initialGroupKey, group: dataOption });
    this.forceUpdate();
  }

  addTableRowChildGroup(record, data) {
    const dataOption = this.state.group;
    const initialGroupKey = this.state.initialGroupKey + 1;
    const dataGroup = {
      id: "group",
      identifier: uuidv4(),
      premise_key: "",
      comparer: "",
      compare_value: "",
      group: "",
      childObj: {
        premises: [],
        key: `new group ${initialGroupKey}`,
        parentObj: { ...record.childObj },
        identifier: record.identifier,
      },
    };
    for (let i = 0; i < data.premises.length; i++) {
      if (data.premises[i].identifier === record.identifier) {
        data.premises[i].childObj.premises.push(dataGroup);
        dataOption.push(dataGroup);
        this.setState({ initialGroupKey: initialGroupKey, group: dataOption });
        // this._Table.onChangeExpanded(e);
      } else this.addTableRowChildGroup(record, data.premises[i].childObj);
    }
    this.forceUpdate();
  }

  addTableRowChildPremises(record, data) {
    const premises = {
      id: "no Group",
      premise_key: "",
      comparer: "",
      identifier: uuidv4(),
      compare_value: "",
      group: "",
      scroll: {},
      childObj: {
        premises: [],
        parentObj: { ...record.childObj },
        identifier: record.identifier,
        pk: "",
      },
    };
    for (let i = 0; i < data.premises.length; i++) {
      if (data.premises[i].identifier === record.identifier) {
        data.premises[i].childObj.premises.push(premises);
      } else this.addTableRowChildPremises(record, data.premises[i].childObj);
    }
    this.forceUpdate();
  }

  optionGroup(data){
    const option = this.state.group
    if (!data.premises) data.premises = []
    for (let i = 0; i < data.premises.length; i++) {
      if (!("pk" in data.premises[i].childObj)) {
        if((option.find(item => item.childObj.key === data.premises[i].childObj.key))!== undefined){
          this.optionGroup(data.premises[i].childObj);
        }else{
          option.push({ ...data.premises[i] });
          this.optionGroup(data.premises[i].childObj);
        }
      }
    }

    this.setState({ group: option });
    this.forceUpdate();
  }

  // option(data,option) {
  //   console.log("option1", data);
  //   for (let i = 0; i < data.premises.length; i++) {
  //     if (!("pk" in data.premises[i].childObj)) {
  //       option.push({ ...data.premises[i] });
  //       this.optionGroup(data.premises[i].childObj,option);
  //     }
  //   }
  // }
  dataTag() {
    const children = [];
    for (let i = 0; i < 0; i++) {
      children.push(
        <Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>
      );
    }
    return children;
  }
  onclose() {
 
    if (get(this.data2, "form", null) !== null) {

      this.data2.form.resetFields();
    } else if (get(this.props, "form", null) !== null) {
      this.props.form.resetFields();
    }
    this.props.onCloseDrawer();
  }

  render() {

    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    return (
      <Drawer
        className="drawer-Rule"
        title={
          <Row>
            {this.props.action === "edit" ? (
              <Col span={12}>Edit Rules</Col>
            ) : (
              <Col span={12}>Add Rules</Col>
            )}
            <Col span={12}>
              <Radio.Group
                style={{ float: "right" }}
                defaultValue={this.props.tipeRule === true ? "rule" : "fact"}
                buttonStyle="solid"
                onChange={this.onChange}
              >
                <Radio.Button
                  value="rule"
                  disabled={this.props.action === "edit" ? true : false}
                >
                  Rule
                </Radio.Button>
                <Radio.Button
                  value="fact"
                  disabled={this.props.action === "edit" ? true : false}
                >
                  Fact
                </Radio.Button>
              </Radio.Group>
            </Col>
          </Row>
        }
        destroyOnClose={true}
        width={"90%"}
        onClose={() => this.onclose()}
        visible={this.props.visible}
        closable={false}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Form
          id="myForm"
          {...formItemLayout}
          className="labell"
          layout="inline"
          // onSubmit={this.handleSubmit}
          style={{ paddingLeft: "20px" }}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                label="Status"
                hasFeedback
                style={{ margin: 0, width: "100%" }}
              >
                {getFieldDecorator("status", {
                  initialValue: get(this.props, "data.status", "active"),
                  rules: [
                    {
                      required: true,
                      message: "This field is required.",
                    },
                  ],
                })(
                  <Select defaultActiveFirstOption>
                    <Option value="active">Active - Anyone can use</Option>
                    <Option value="inactive">Inactive - No one can use</Option>
                    <Option value="unpublished">
                      Unpublished - Only certain people can use
                    </Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Col span={12}>
                <Form.Item
                  label="Key"
                  hasFeedback
                  style={{ margin: 0, width: "100%" }}
                >
                  {getFieldDecorator("key", {
                    initialValue: get(this.props, "data.key", ""),
                    rules: [
                      {
                        required: true,
                        message: "This field is required.",
                      },
                    ],
                  })(
                    <Input style={{ width: "96%" }} placeholder="Rules key" />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Name"
                  hasFeedback
                  style={{ margin: 0, width: "100%" }}
                >
                  {getFieldDecorator("name", {
                    initialValue: get(this.props, "data.name", ""),
                    rules: [
                      {
                        required: true,
                        message: "This field is required.",
                      },
                    ],
                  })(
                    <Input style={{ width: "96%" }} placeholder="Rules Name" />
                  )}
                </Form.Item>
              </Col>
            </Col>
            <Col span={24}>
              <Form.Item
                label={"Description"}
                style={{ margin: 0, width: "100%" }}
              >
                {getFieldDecorator("desc", {
                  initialValue: get(this.props, "data.desc", ""),
                  rules: [{ required: false }],
                })(<Input.TextArea style={{ width: "100%" }} rows={4} />)}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Col span={8}>
                {this.state.typeForm === "fact" && (
                  <Form.Item
                    label="Fact type"
                    hasFeedback
                    style={{ margin: 0, width: "100%" }}
                  >
                    {getFieldDecorator("fact_type", {
                      initialValue: get(
                        this.props,
                        "data.fact_type",
                        "keyword"
                      ),
                      // initialValue: "keyword",
                      rules: [
                        {
                          required: true,
                          message: "This field is required.",
                        },
                      ],
                    })(
                      <Select
                        style={{ width: "92%" }}
                        onChange={this.handleChange}
                      >
                        <Option value="keyword">Keyword</Option>
                        <Option value="job">Job</Option>
                        <Option value="object">Object</Option>
                      </Select>
                    )}
                  </Form.Item>
                )}
              </Col>
              <Col span={8}>
                {this.state.typeForm === "fact" && (
                  <Form.Item
                    label="Fact unit"
                    hasFeedback
                    style={{ margin: 0, width: "100%" }}
                  >
                    {getFieldDecorator("fact_unit", {
                      initialValue: get(
                        this.props,
                        "data.fact_unit",
                        "availability"
                      ),
                      // initialValue: "availability",
                      rules: [
                        {
                          required: true,
                          message: "This field is required.",
                        },
                      ],
                    })(
                      <Select
                        style={{ width: "92%" }}
                        // defaultValue={{ key: 'availability' }}
                        // onChange={this.handleChange}
                      >
                        <Option value="availability">Availability</Option>
                        <Option value="count">Count</Option>
                        <Option value="total">Total</Option>
                        <Option value="value">Value</Option>
                      </Select>
                    )}
                  </Form.Item>
                )}
              </Col>
              <Col span={8}>
                {this.state.typeForm === "fact" &&
                  this.state.factType === "keyword" && (
                    <Form.Item
                      label="Fact keywords:"
                      hasFeedback
                      style={{ margin: 0, width: "100%" }}
                    >
                      {getFieldDecorator("fact_keywords", {
                        initialValue: get(this.props, "data.fact_keywords", []),
                        rules: [
                          {
                            required: true,
                            message: "This field is required.",
                          },
                        ],
                      })(
                        <Select
                          mode="tags"
                          style={{ width: "92%" }}
                          tokenSeparators={[","]}
                          placeholder="Keywords"
                        >
                          {this.dataTag()}
                        </Select>
                      )}
                    </Form.Item>
                  )}
              </Col>
              <Col span={8}>
                {this.state.typeForm === "fact" &&
                  this.state.factType === "job" && (
                    <Form.Item
                      label="Fact job field:"
                      hasFeedback
                      style={{ margin: 0, width: "100%" }}
                    >
                      {getFieldDecorator("target_field", {
                        initialValue: get(this.props, "data.target_field", ""),
                        rules: [
                          {
                            required: true,
                            message: "This field is required.",
                          },
                        ],
                      })(
                        <Input
                          placeholder="Target Field"
                          style={{ width: "92%" }}
                        />
                      )}
                    </Form.Item>
                  )}
              </Col>
            </Col>
            <Col>
              <Col span={12}>
                {this.state.typeForm === "rule" && (
                  <Form.Item
                    label="Output content type"
                    hasFeedback
                    style={{ margin: 0, width: "100%" }}
                  >
                    {getFieldDecorator("Output_content", {
                      initialValue:
                        this.props.outputContent !== null
                          ? get(
                              this.props,
                              "outputContent.outputContentType",
                              null
                            )
                          : this.props.data !== undefined &&
                            get(
                              this.props,
                              "data.output_content_type",
                              null
                            ) !== null
                          ? {
                              key: this.props.data.output_content_type.pk,
                              label: this.props.data.output_content_type.label,
                            }
                          : { key: 0, label: "" },
                      rules: [
                        {
                          required: false,
                          message: "This field is required",
                        },
                      ],
                    })(
                      // <Select
                      //   dropdownClassName="width-auto2"
                      //   labelInValue={true}
                      // >
                      //   <Option value={0} key={0}>
                      //     {" "}
                      //   </Option>
                      //   <Option value={18} key={18}>
                      //     Subsection
                      //   </Option>
                      //   <Option value={22} key={22}>
                      //     Prefill sets
                      //   </Option>
                      //   <Option value={24} key={24}>
                      //     Overide side
                      //   </Option>
                      //   <Option value={21} key={21}>
                      //     Line templates
                      //   </Option>
                      // </Select>
                      <SelectQuery
                        size="default"
                        onChange={(e) => this.change(e, "content")}
                        urlKey="expert-system-content-type-autocomplete"
                        valKey={"pk"}
                        valLabel={"label"}
                        dropdownClassName="width-auto2"
                      />
                    )}
                  </Form.Item>
                )}
              </Col>
              <Col span={12}>
                {this.state.typeForm === "rule" && (
                  <Form.Item
                    label="Output object"
                    hasFeedback
                    style={{ margin: 0, width: "100%" }}
                    extra="fill in the Output content type and filter to make it easier"
                  >
                    <Popover content={this.filter()} title="Filter">
                      <Button icon="filter" style={{ width: "5%" }} />
                    </Popover>
                    {getFieldDecorator("object_id", {
                      initialValue:
                        this.props.outputContent !== null
                          ? get(this.props, "outputContent.outputObject", null)
                          : this.props.data !== undefined &&
                            get(
                              this.props,
                              "data.output_content_object",
                              null
                            ) !== null
                          ? {
                              key: this.props.data.output_content_object.pk,
                              label: this.props.data.output_content_object.name,
                            }
                          : { key: 0, label: "" },
                      rules: [
                        {
                          required: false,
                          message: "This field is required.",
                        },
                      ],
                    })(
                      <SelectQuery
                        // className="fromFilter"
                        style={{ marginLeft: "5px", width: "90%" }}
                        size="default"
                        urlKey="expert-system-content-object-autocomplete"
                        valKey={"pk"}
                        args={[this.state.content]}
                        paramProps={{
                          subsection: this.state.subsection,
                          section: this.state.section,
                          prefill_sets: this.state.prefilset,
                        }}
                        valLabel={"label"}
                        dropdownClassName="width-auto2"
                      />
                    )}
                  </Form.Item>
                )}
              </Col>
            </Col>
            {/* <Form.Item {...tailFormItemLayout} hasFeedback>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item> */}
          </Row>
          <Divider dashed />
        </Form>
        {this.state.typeForm === "rule" && (
          <>
            <Row>
              <Col span={24} style={{ paddingBottom: "10px" }}>
                <Button
                  icon="plus"
                  type="primary"
                  onClick={(e) => this.addPremise(e)}
                >
                  Add Premise
                </Button>
                <Button
                  icon="usergroup-add"
                  style={{ marginLeft: "2px" }}
                  onClick={(e) => this.addGroup(e)}
                >
                  Add Group
                </Button>
              </Col>

              <Col span={24}>
                <DropPremise
                  onChangeGroupTable={(value, dataIndex, record) =>
                    this.GroupTable(value, dataIndex, record, this.data2)
                  }
                  addrowgroup={(record) =>
                    this.addTableRowChildGroup(record, this.data2)
                  }
                  addrowPremises={(record) =>
                    this.addTableRowChildPremises(record, this.data2)
                  }
                  action={this.props.action}
                  data={this.props.list === true ? this.props.data : this.data2}
                  group={this.state.group}
                  ref={(n) => (this._Table = n)}
                  root={true}
                />{" "}
              </Col>
            </Row>
          </>
        )}
        <div
          style={{
            position: "absolute",
            right: 0,
            bottom: 0,
            width: "100%",
            borderTop: "1px solid #e9e9e9",
            padding: "10px 16px",
            background: "#fff",
            textAlign: "right",
          }}
        >
          {this.props.action === "edit" && (
            <Button
              onClick={(e) => this.Delete(e)}
              style={{ marginRight: 8 }}
              type="danger"
            >
              Delete
            </Button>
          )}

          <Button onClick={() => this.onclose()} style={{ marginRight: 8 }}>
            Cancel
          </Button>
          <Button
            onClick={(e) => this.handleSubmit(e)}
            form="myForm"
            key="submit"
            htmlType="submit"
            type="primary"
          >
            {" "}
            {this.props.action === "edit" ? "Update" : "Submit"}
          </Button>
        </div>
      </Drawer>
    );
  }
}

const WrappedGeneralForm = Form.create({ name: "General" })(GeneralForm);
export default WrappedGeneralForm;
