import { BaseList } from "App/Screens/SubModules/Component";
import descriptor from "App/Screens/Job/JobTypes/descriptor";

class JobTypeList extends BaseList {
  constructor(props) {
    super(props)

    this.appName = 'jobs'
    this.modelName = 'jobtype'
    this.propsList = {
      avatar: 'name',
      pageTitle: 'Job Type',
      descriptor,
      masterKey: 'module-job-jobType',
    }
  }
}

export default JobTypeList