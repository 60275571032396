import { get } from 'lodash'

// headerMenu is single header menu (object)
// permissions is array of permission code
// NavMenu is object imported from NavMenu 'App/Config/MenuLeft'
const hasNavMenu = (headerMenu, permissions, NavMenu) => {
  // console.log('hasNavMenu', headerMenu.codename, { headerMenu, permissions, NavMenu})
  switch(headerMenu.codename) {
    case 'admin': {
      const tempNav = get(NavMenu, headerMenu.codename, [])
      for (let i = 0; i < tempNav.length; i += 1) {
        const menus = get(tempNav[i], 'menus', [])
        for (let j = 0; j < menus.length; j += 1) {
          const group = get(menus[j], 'url_name', '').split(':')[2]
          const set = get(menus[j], 'url_name', '').split(':')[3]
          if (permissions.findIndex(perm => perm.includes(`${group}.view_${set}`)) !== -1) {
            return true
          }
        }
      }
      break
    }
    case 'branch': {
      const tempNav = get(NavMenu, 'branch-queue', [])
      for (let i = 0; i < tempNav.length; i += 1) {
        const regex = new RegExp('^' + get(tempNav[i], 'code', 'UNKNOWN').replace('{}', 'view') + '$')
        if (permissions.findIndex(perm => perm.search(regex) !== -1) !== -1) {
          return true
        }
      }
      break
    }
    case 'job': {
      const tempNav = get(NavMenu, 'job-management', [])
      for (let i = 0; i < tempNav.length; i += 1) {
        const regex = new RegExp('^' + get(tempNav[i], 'code', 'UNKNOWN').replace('{}', 'view') + '$')
        if (permissions.findIndex(perm => perm.search(regex) !== -1) !== -1) {
          return true
        }
      }
      break
    }
    case 'product': {
      const regex = new RegExp('estimation.view_product')
      if (permissions.findIndex(perm => perm.search(regex) !== -1) !== -1) {
        return true
      }
      break
    }
    case 'user_role': {
      const tempNav = get(NavMenu, 'user-group', [])
      for (let i = 0; i < tempNav.length; i += 1) {
        if (tempNav[i].menus) {
          for (let j = 0; j < tempNav[i].menus.length; j += 1) {
            const regex = new RegExp(get(tempNav[i].menus[j], 'code', 'UNKNOWN').replace('{}', 'view'))
            if (permissions.findIndex(perm => perm.search(regex) !== -1) !== -1) {
              return true
            }
          }
        } else {
          const regex = new RegExp(get(tempNav[i], 'code', 'UNKNOWN').replace('{}', 'view'))
          if (permissions.findIndex(perm => perm.search(regex) !== -1) !== -1) {
            return true
          }
        }
      }
      break
    }
    case 'merchant': {
      const tempNav = get(NavMenu, 'merchant', [])
      for (let i = 0; i < tempNav.length; i += 1) {
        const regex = new RegExp(get(tempNav[i], 'code', 'UNKNOWN').replace('{}', 'view'))
        if (permissions.findIndex(perm => perm.search(regex) !== -1) !== -1) {
          return true
        }
      }
      break
    }
    case 'expert_system': {
      const tempNav = get(NavMenu, 'expert-system', [])
      for (let i = 0; i < tempNav.length; i += 1) {
        const regex = new RegExp(get(tempNav[i], 'code', 'UNKNOWN').replace('{}', 'view'))
        if (permissions.findIndex(perm => perm.search(regex) !== -1) !== -1) {
          return true
        }
      }
      break
    }
    default: {
      break
    }
  }
  return false
}

export default hasNavMenu