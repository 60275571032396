import React, { Component } from "react";
import Components from "App/Component";
import { Lib, RequestV2 as Request } from "App/Utils";
import { Collapse, List, Avatar, Button, Icon ,Row, Spin,message} from "antd";

class Subsection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subsection: [],
      isLoading:false,
      buttonReaload:false
    };
  }

  componentDidMount() {
    this.loadSubsection();
  }

  loadSubsection = () => {
    this.setState({ isLoading: true }, () => {
      Request(
        "get",
        "subsections-autocomplete",
        {},
        { limit: 100, section: this.props.subsection, status: "active" },
        [],
        this.loadSubsectionSuccess,
        this.loadSubsectionFailed
      );
    });
  };

  loadSubsectionSuccess = (response) => {
    this.setState({ isLoading:false, subsection: response.data.results,buttonReaload:false });
  };

  loadSubsectionFailed = (error) => {
    console.log(error);
    this.setState({ isLoading:false, buttonReaload:true})
    message.error('there is an error in the system');
  };

  render() {
    return this.state.isLoading === true ? (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{ marginTop: 0, backgroundColor: "#fafafa", height: "200px" }}
      >
        <Spin
          size="large"
          tip="Loading..."
          style={{ width: "100%" }}
          indicator={<Icon spin type="loading" style={{ fontSize: "4rem" }} />}
        />
      </Row>
    ) : (
      this.state.buttonReaload === true ?(
        <Row
        type="flex"
        justify="center"
        align="middle"
        style={{ marginTop: 0, backgroundColor: "#fafafa", height: "200px" }}
      >
        <Button type="dashed" onClick={() => this.loadSubsection()}>
          Refresh
        </Button>
        </Row>
      ):(
      <List
        size="small"
        bordered
        style={{ borderRadius: "0px", height: "100%", paddingLeft: "14px" }}
        dataSource={this.state.subsection}
        className="avatarlist"
        rowKey="pk"
        renderItem={(item) => (
          <List.Item className="butn">
            <List.Item.Meta
              // key={item.pk} 
              // avatar={
              //   this.props.hold === item.pk ? (
              //     <Avatar
              //       size="small"
              //       style={{ color:"#0000FF" }}
              //       // className="avatarList"
              //       icon="trademark"
              //     />
              //   ) : (
              //     <span class="dot"></span>
              //   )
              // }
              title={
                <div
                  id="divList"
                  onClick={() => this.props.onSubsectionClick(item)}
                  style={
                    this.props.hold === item.pk
                      ? { backgroundColor: "#E3E3E3" }
                      : null
                  }
                >
                  {this.props.hold === item.pk ? (
                    <Avatar
                      size="small"
                      style={{ marginLeft: "17px" }}
                      // className="listRead"
                      icon="trademark"
                    />
                  ) : (
                    <span style={{ marginLeft: "22px" }} className="dot"></span>
                  )}
                  <Button
                    style={
                      this.props.hold === item.pk
                        ? { color: "#1890ff" }
                        : { color: "black" }
                    }
                    type="link"
                    // onClick={() => this.props.onSubsectionClick(item)}
                  >
                    {item.name}
                  </Button>
                  {this.props.checkTab !== null &&
                    this.props.checkTab.data.output_content_type !== null &&
                    this.props.checkTab.identifier &&
                    this.props.checkTab.name === item.name && (
                      <h5
                        style={{
                          float: "right",
                          fontSize: "10px",
                          marginRight: "12px",
                          color: "#1890ff",
                        }}
                      >
                        open
                      </h5>
                    )}
                </div>
              }
            />
          </List.Item>
        )}
      />
      )
    );
    // ))
  }
}
export default Subsection;