export default {
  "app_label": "exim",
  "model_name": "session",
  "pagination": true,
  "columns": [
    {
      "name": "pk",
      "label": "ID",
      "help_text": null,
      "identifier": true,
      "data_type": "str",
      "search": false,
      "sort": false,
      width: '65px',
      render: (val, record, index) => `#${val}`,
    },
    {
      name: 'created_by',
      label: 'User',
      sort: true,
      render: (val) => val
        ? [val.first_name, val.last_name].filter(v => v).join(' ')
        : '-'
    },
    {
      "name": "description",
      "label": "Description",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "ellipsis": true,
      "search": true,
      "sort": true
    },
    {
      "name": "session_type",
      "label": "Type",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": null,
      "search": true,
      "sort": true
    },
    {
      "name": "status",
      "label": "Status",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": null,
      "search": true,
      "sort": true
    },
    {
      "name": "duration",
      "label": "Duration",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "timedelta",
      "search": false,
      "sort": true
    },
    {
      "name": "items_saved",
      "label": "Saved",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "int",
      "search": true,
      "sort": true
    },
    {
      "name": "items_skipped",
      "label": "Skipped",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "int",
      "search": true,
      "sort": true
    },
    {
      "name": "items_failed",
      "label": "Failed",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "int",
      "search": true,
      "sort": true
    },
    {
      "name": "items_total",
      "label": "Total",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "int",
      "search": true,
      "sort": true
    },
    {
      "name": "modified",
      "label": "Modified",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "datetime",
      "search": false,
      "sort": true
    }
  ],
  "filter_by": [
    {
      "label": "Status",
      "name": "status",
      "type": "choice",
      "choices": [
        [
          "new",
          "New"
        ],
        [
          "queueing",
          "Queueing"
        ],
        [
          "processing",
          "Processing"
        ],
        [
          "dry_run",
          "Dry Run"
        ],
        [
          "completed",
          "Completed"
        ],
        [
          "failed",
          "Failed"
        ]
      ]
    },
    {
      "label": "Session Type",
      "name": "session_type",
      "type": "choice",
      "choices": [
        [
          "export",
          "Export"
        ],
        [
          "import",
          "Import"
        ]
      ]
    },
    {
      "label": "Overwrite existing",
      "name": "overwrite_existing",
      "type": "switch"
    },
    {
      "label": "Activate imported items",
      "name": "activate_imported_items",
      "type": "switch"
    },
    {
      "label": "Content type",
      "name": "content_type",
      "type": "api_reference",
      "api": {
        "url_name": "api:admin:exim:contenttype:autocomplete",
        "key": "pk",
        "display": "label",
        "pagination": true
      }
    },
    {
      label: 'User',
      name: 'created_by',
      type: 'api_reference',
      api: {
        url_name: 'api:admin:users:user:autocomplete',
        key: 'pk',
        display: 'full_name',
        pagination: true,
      }
    },
  ],
  "export_import": null
}