import { BaseRedirectMenu } from 'App/Screens/SubModules/Component'
import MerchantMenu from "./Menu";

class MerchantRedirect extends BaseRedirectMenu {
  constructor(props) {
    super(props)

    this.menu = MerchantMenu
  }
}

export default MerchantRedirect