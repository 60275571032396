import React from 'react';
import {
  Layout,
  Avatar,
  Row,
  Col,
  Icon,
  Spin,
  PageHeader,
  Button,
  Descriptions,
  Divider,
  Typography,
  Tooltip,
  Tabs,
  Empty,
  Card,
  Modal,
  message,
  Drawer,
} from 'antd';
import { ChartCard, MiniArea } from 'ant-design-pro/lib/Charts'
import NumberInfo from 'ant-design-pro/lib/NumberInfo'
import { get, kebabCase, startCase } from 'lodash'
import moment from 'moment'
import { PermissionName } from 'App/Config';
import { forcePermissions } from 'App/Data'
import PageUnauthorized from 'App/Component/PageUnauthorized'
import { RequestV2 as Request, Modules, Lib, CheckPermission } from 'App/Utils'
import ProductDetail from './Detail/ProductDetail'
import PageDocumentation from './Images/PageDocumentation'
import PageVideos from './Videos/PageVideos'
import PageDocuments from './Documents/PageDocuments'
import PageNotes from './Notes/PageNotes'
import PageStatistics from './Statistics/PageStatistics'
import PageUsage from './Usage'
// import UsedStat from './UsedStat'
import LightboxModal from './Images/LightboxModal'
import NewListData from 'App/Component/NewListData'
import AdvancedTable from 'App/Component/Singlepage/AdvancedTable'
import styled from 'styled-components'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { BaseDetail } from 'App/Component/Bases'
import fields from 'App/Screens/Estimation/Products/fields.js'
import Page404 from 'App/Component/Singlepage/Page404'
import PageError from 'App/Component/PageError'
import SummaryUsage from './SummaryUsage'

import 'react-lazy-load-image-component/src/effects/blur.css'
import 'App/Component/Singlepage/SingleStyle.css'

const { Content } = Layout;
const { Title, Text } = Typography;
const { TabPane } = Tabs
const { confirm } = Modal
const IconLink = ({ type, text, onClick }) => (
  <a className='d-flex align-items-center mr-4' href='open' onClick={onClick}>
    <Icon type={type} className='font-xl mr-1' />
    {text}
  </a>
);

const tabs = [
  {
    pk: 'tab-1',
    title: 'Images',
    urlData: 'module-product-images',
  }, {
    pk: 'tab-2',
    title: 'Videos',
    urlData: 'module-product-videos',
  }, {
    pk: 'tab-3',
    title: 'Documents',
    urlData: 'module-product-documents',
  }, {
    pk: 'tab-4',
    title: 'Notes',
    urlData: 'module-product-notes',
  },
  {
    pk: 'usage',
    title: 'Usage',
  },
  // {
  //   pk: 'another-tab-1',
  //   title: 'Statistics',
  //   urlData: 'test',
  // }, {
  //   pk: 'another-tab-2',
  //   title: 'Overview',
  //   urlData: 'test',
  // }
]

const urlData = 'module-product'

class ModeAdd extends BaseDetail {
  title = 'Products';
  urlData = urlData;
  bulkName = 'name';
  fields = fields;
}

export default class ProductManagement extends React.PureComponent {
  imageUrlData = 'module-product-images'

  constructor(props) {
    super(props)

    this.action = props.match.params.id === 'add' ? 'add' : 'edit'
    this.isSuperuser = get(props.userReducer, ['user', 'is_superuser'])
    this.state = {
      // Permission
      isCheckingPermission: !this.isSuperuser && this.action !== 'add',
      isAccessAllowed: this.isSuperuser || this.action === 'add',
      currentPermissionSet: this.isSuperuser ? forcePermissions : [],
      readOnly: false,
      // ----------
      isLoading: this.action !== 'add',
      errorResponse: null,
      currentProduct: null,
      selectedProductPhoto: null,
      isSelectPhoto: false,
      selectedImage: [],
      showImageLightbox: false,
      visibleDrawer: false,
    }

    this.handleTabClick = this.handleTabClick.bind(this)
  }

  getOptionalChart(currentProduct) {
    const visitData = []
    const beginDay = new Date().getTime()
    let totalUsage = 0

    for (let i = 0; i < 20; i += 1) {
      const y = Math.floor(Math.random() * 100) + 10
      totalUsage += y
      visitData.push({
        x: moment(new Date(beginDay + 1000 * 60 * 60 * 24 * i)).format('YYYY-MM-DD'),
        y,
      })
    }
    return (
      <ChartCard
        title={<Text ellipsis strong className='descriptions-item-label'>Total usage (still random)</Text>}
        contentHeight={87}
        action={
          this.getAverage(totalUsage, 45)
        }
      >
        <NumberInfo
          // subTitle={<span>Total Usage</span>}
          total={totalUsage}
          // status="average"
          // subTotal={this.getAverage(totalUsage, 20)}
        />
        <MiniArea line height={45} data={visitData} />
      </ChartCard>
    )
  }

  getAverage(total, count) {
    const average = Math.floor(total / count)
    return (
      <Tooltip placement='top' title={`Average: ${average}`}>
        <Icon type='info-circle-o' />
      </Tooltip>
    )
  }

  // getTabIcon(title) {
  //   const tabIcon = {
  //     Images: 'file-image',
  //     Videos: 'video-camera',
  //     Documents: 'file',
  //     Notes: 'file-text',
  //   }
  //   return <Icon type={tabIcon[title]} />
  // }

  backConfirm = (callback, withoutConfirm) => {
    if (get(this.props.location.state, 'isChanged')) {
      if (withoutConfirm !== true) {
        confirm({
          title: 'Are you sure want to leave this page??',
          content: 'Any changes will be lost',
          onOk: () => callback(true),
          onCancel: () => callback(false),
        })
      } else {
        callback(true)
      }
    } else {
      callback(true)
    }
  }

  onGoBack = () => {
    this.props.history.push('/product/product')
  }

  goToMasterProduct = () => {
    this.backConfirm(confirm => {
      if (confirm) {
        this.props.history.push('/product/product/')
      }
    }, !get(this.props, 'location.state.isChanged'))
  }

  getSelectedTab(pathname, indexLocation) {
    const pathSplited = pathname.split('/').filter(w => w)
    if (pathSplited.length > 0) {
      const selectedIndex = indexLocation ? indexLocation : pathSplited.length - 1
      const another = [
        { title: 'Detail' }
      ]
      const tabsWithAnother = tabs.concat(another)
      for (const index in tabsWithAnother) {
        if (tabsWithAnother[index].title.toLowerCase() === pathSplited[selectedIndex]) {
          return pathSplited[selectedIndex]
        }
      }
    }
    return null
  }

  getTabTitle(title, staticIcon, position = 'top') {
    const tabIcon = {
      Detail: 'edit',
      Images: 'file-image',
      Videos: 'video-camera',
      Documents: 'file',
      Notes: 'file-text',
      Usage: 'line-chart',
      // Statistics: 'pie-chart',
      Statistics: 'area-chart',
      Overview: 'dashboard',
    }

    const selectedIcon = staticIcon || tabIcon[title]
    const placement = position

    return (
      <Row type='flex' justify='center' align='middle' style={{margin: '0'}}>
        <Col xl={24} lg={24} md={24} sm={24} xs={0} className='over-hide'>
          <Icon type={selectedIcon} />{startCase(title)}
        </Col>
        <Tooltip placement={placement} title={startCase(title)}>
          <Col xl={0} lg={0} md={0} sm={0} className='over-hide'>
            <Icon type={selectedIcon} />
            <style>
              {
                `.ant-tabs-nav .ant-tabs-tab {
                  margin: 0 2px 0 0;
                }`
              }
            </style>
          </Col>
        </Tooltip>
      </Row>
    )
  }

  getTabContent(title, urlData) {
    const { userReducer, history, location, match } = this.props
    const { readOnly, currentProduct } = this.state
    const parentId = currentProduct.pk

    const standardProps = { readOnly, urlData, userReducer, parentId, currentProduct, history, location, match }

    const tabContent = {
      Detail: 'Temporary Component',
      Images: <PageDocumentation
        {...standardProps}
        setLoading={(is, callback) => this.setLoading(is, callback)}
        refresh={(id, optBool) => this.retrieve(id, optBool)}
        setRef={refImages => this.refImages = refImages}
      />,
      Videos: <PageVideos {...standardProps} />,
      Documents: <PageDocuments {...standardProps} />,
      Notes: <PageNotes {...standardProps} />,
      Statistics: <PageStatistics currentProduct={this.state.currentProduct}/>,
      Usage: <PageUsage productId={parentId} />,
    }
    let content = tabContent[title]
    if (content) {
      if (title === 'Detail') {
        return (
          <ProductDetail
            readOnly={readOnly}
            currentProduct={this.state.currentProduct}
            fields={fields}
            userReducer={this.props.userReducer}
            history={this.props.history}
            location={this.props.location}
            refresh={() => this.retrieve(null, false)}
          />
        )
      }
      return content
    }
    return <Empty description={false} />
  }

  handleTabClick(key, event) {
    const url = `/product/product/${this.props.match.params.id}/${kebabCase(key).replace('/', '')}`
    this.props.history.replace(url, this.props.location.state)
  }

  getActiveKey() {
    const urlSplited = this.props.history.location.pathname.split('/').filter(w => w)
    return urlSplited[urlSplited.length - 1].toLowerCase()
  }

  setLoading(isLoading, callback) {
    this.setState({
      isLoading,
    }, () => callback && callback())
  }

  preRetrieve = (keyLoading, callback = () => null) => {
    this.retrieve(undefined, false, callback)
  }

  retrieve = (id, allowLoading = true, callback = () => null) => {
    allowLoading && this.setState({ isLoading: true })
    let senderId = this.props.match.params.id
    if (id) {
      senderId = id
    }
    Request(
      'get',
      `${urlData}-detail`,
      {},
      {},
      [senderId],
      this.retrieveSuccess,
      this.retrieveFailed,
      { callback }
    );
  }

  retrieveSuccess = (response, extra) => {
    if (!this.getSelectedTab(this.props.history.location.pathname, 3)) {
      this.props.history.replace('/product/product/' + this.props.match.params.id + '/usage')
    }


    this.setState({
      // isLoading: false,
      currentProduct: response.data,
      visibleDrawer: false,
    }, () => {
      this.readImages(() => {
        this.setState({isLoading: false})
      })
    })
  }

  retrieveFailed = (error, extra) => {
    this.setState({ isLoading: false, errorResponse: get(error, 'response') }, () => {
      extra.callback()
      // message.error(`Please try again later, error code: ${error.response.status}`)
    })
  }

  getThumbnail() {
    const { readOnly, currentProduct: {image_thumbnail} } = this.state
    let child, cursor = '', btnIcon = 'edit'
    if (image_thumbnail) {
      cursor = 'z-in'
      child = ( <StyledImg src={image_thumbnail.file} alt='Not found' effect='blur' /> )
    } else {
      // btnIcon = 'plus'
      child = (
        <StyledNoImage
          type='flex' justify='center' align='middle' >
          <Title level={3} ellipsis className='c-white'style={{opacity: '75%'}}>
            No Image
          </Title>
        </StyledNoImage>
      )
    }

    return (
      <Row>
        { child }
        {!readOnly && (
          <StyledOverlay
            className={`overlay equal-container absolute-anchor ${cursor}`}
            onClick={() => this.showLightbox()} >
            <Button
              icon={btnIcon}
              type='primary'
              onClick={(e) => this.editProductPhoto(e, btnIcon)}
              className='abs-tr'
            />
          </StyledOverlay>
        )}
      </Row>
    )
    // return url
  }

  showLightbox() {
    if (this.state.currentProduct.image_thumbnail) {
      this.setState({showImageLightbox: true})
    }
  }

  getCurrentProductTab() {
    return (
      <Tabs
        animated={false}
        onTabClick={this.handleTabClick}
        activeKey={this.getActiveKey()}
      >
        <TabPane tab={this.getTabTitle('Detail')} key={'detail'} style={{marginRight: '3px !important'}}>
          { this.getTabContent('Detail') }
        </TabPane>
        {
          tabs.map(item => (
            <TabPane tab={this.getTabTitle(item.title)} key={item.title.toLowerCase()}>
              { this.getTabContent(item.title, item.urlData) }
            </TabPane>
          ))
        }
      </Tabs>
    )
  }

  editProductPhoto(e, action) {
    e.stopPropagation()

    if (!this.refImages) {
      const urlImages = '/product/product/' + this.props.match.params.id + '/images'
      this.props.history.replace(urlImages)
    }

    const { currentProduct } = this.state
    if (currentProduct) {
      this.readImages(() => {
        this.setState({
          isSelectPhoto: true,
        })
      })
    }
  }

  handleClickAdd(e) {
    if (this.refImages) {
      this.refImages.handleClickAdd(e)
    }
  }

  readImages(callback) {
    if (this.state.currentProduct.listImages) {
      callback()
    } else {
      Request(
        'get',
        `${this.imageUrlData}-read`,
        {},
        {},
        [this.state.currentProduct.pk],
        (res, cb) => this.readImagesSuccess(res, () => callback()),
        err => this.readImagesFailed(err, () => callback()),
      )
    }
  }

  readImagesSuccess(response, callback) {
    let currentProduct = this.state.currentProduct
    currentProduct.listImages = response.data.results
    this.setState({ currentProduct }, () => {
      this.refreshSelectedImage(() => callback())
    })
  }

  readImagesFailed(error, callback) {
    callback()
  }

  selectPhoto() {
    const { listImages } = this.state.currentProduct
    return (
      <Modal
        visible={this.state.isSelectPhoto}
        title='Please select photo for master this product'
        onCancel={() => this.refreshSelectedImage(() => this.setState({isSelectPhoto: false}))}
        onOk={() => this.changeSelectedPhoto()}
        width={'80vw'}
        style={{ top: '20px' }}
      >
        { this.state.isSelectPhoto &&
          <Row>
            <Row className='row'>
              <Button icon='plus' type='primary' className='row' onClick={e => this.handleClickAdd(e)}>Add</Button>
            </Row>
            <StyledDividerH type='horizontal' />
            <Row type='flex' justify='center' gutter={[15, 15]} style={{maxHeight: '63vh', minHeight: '175px', overflow: 'auto', margin: '0'}}>
              { listImages && listImages.map((item, index) => (
                  <Col key={item.pk} style={{width: '175px', height: '175px'}}>
                    <Row className='equal-container'>
                      <img
                        src={item.file}
                        alt='Not found'
                        className='equal-container radius'
                        style={{
                          border: '1px solid #73818f',
                        }}
                      />
                      <div
                        className='overlay equal-container absolute-anchor radius'
                        style={{
                          borderRadius: '4px',
                          cursor: 'pointer',
                        }}
                        onClick={() => this.selectImage(index)}
                      />
                      {
                        this.state.selectedImage[index] &&
                        <Icon type='check-circle' theme='filled'
                          className='abs-tr'
                          style={{
                            fontSize: '1.5rem',
                            color: '#1890ff',
                            backgroundColor: '#fff',
                            borderRadius: '1.5rem',
                            // position: 'absolute',
                            // right: 0,
                            // top: 0,
                            margin: '-0.5rem',
                            cursor: 'pointer',
                          }}
                        />
                      }
                    </Row>
                  </Col>
                ))
              }
            </Row>
          </Row>
        }
      </Modal>
    )
  }

  selectImage(index) {
    const selectedImage = []
    selectedImage[index] = true
    if (!this.state.selectedImage[index]) {
      this.setState({
        selectedImage,
      })
    }
  }

  refreshSelectedImage(callback) {
    const { listImages } = this.state.currentProduct
    let selectedImage = []
    if (listImages) {
      for (const i in listImages) {
        if (listImages[i].is_thumbnail) {
          selectedImage[i] = true
          this.setSelectedImage(selectedImage, () => callback())
          return
        }
      }
    }
    this.setSelectedImage(selectedImage, () => callback())
  }

  setSelectedImage(selectedImageArr, callback) {
    const { selectedImage } = this.state
    let isDiff = true
    for (const i in selectedImage) {
      isDiff = false
      if (selectedImage[i] !== selectedImageArr[i]) {
        isDiff = true
        break
      }
    }
    if (isDiff) {
      this.setState({
        selectedImage: selectedImageArr },
        () => callback && callback()
      )
    } else {
      callback && callback()
    }
  }

  changeSelectedPhoto() {
    const { selectedImage, currentProduct } = this.state
    const { image_thumbnail } = currentProduct

    for (const index in selectedImage) {
      if (selectedImage[index]) {
        // const pkImage = currentProduct.listImages[index].pk
        // const pkThumbnail = image_thumbnail && image_thumbnail.pk
        const nameImage = currentProduct.listImages[index].file_name
        const nameThumbnail = image_thumbnail && image_thumbnail.file_name
        if (nameImage === nameThumbnail) {
          // message.info(`${image_thumbnail.file_name} has already selected, please select another photo`)
          // message.info('No changes')
          this.setState({ isSelectPhoto: false })
        } else {
          const pkSelectedImage = currentProduct.listImages[index].pk
          Request(
            'post',
            this.imageUrlData + '-asThumbnail',
            {},
            {},
            [currentProduct.pk, pkSelectedImage],
            res => this.changeSuccess(res),
            err => message.error('Change photo failed' + err.response.status),
          )
        }
        return
      }
    }
    message.info('Please select an image')
  }

  changeSuccess(response) {
    let currentProduct = {
      ...this.state.currentProduct,
      image_thumbnail: response.data,
      // image_thumbnail: {
      //   file: response.data.file
      // }
    }
    this.setState({
      currentProduct,
      isSelectPhoto: false,
    }, () => {
      message.success('Photo has been changed')
    })
  }

  getLightboxImage() {
    const { image_thumbnail } = this.state.currentProduct
    if (image_thumbnail) {
      return ([{ file: image_thumbnail.file }])
    }
    return undefined
  }

  getDrawer() {
    return (
      <Drawer
        title={`Select Product`}
        placement={`right`}
        onClose={() => this.setState({visibleDrawer: false})}
        visible={this.state.visibleDrawer}
        width={'80%'}
      >
        <NewListData
          masterKey={urlData}
          pageTitle='Products'
          headercols={[{name: 'name', sorter: true}]}
          totalOptions={items => this.totalOptions(items)}
          onAddClick={() => message.error('You cannot Add Product from here')}
          onDetailClick={item => this.onDetailClick(item)}
          avatar={item => this.getAvatar(item)}
          title={item => item.code}
          description={item => this.getDescription(item)}
          content={item => this.getContent(item)}
        />
      </Drawer>
    )
  }

  totalOptions(items) {
    return [
      {
        title: 'Total',
        total: items.total,
      }
    ]
  }

  onDetailClick(item) {
    const url = '/product/product/' + item.pk
    this.props.history.push(
      url
    )
    this.retrieve(item.pk)
  }

  getAvatar(item) {
    let srcImg = null, strInitial = ''
    const customProps = {}
    if (item.image_thumbnail) {
      srcImg = item.image_thumbnail.file
      if (item.image_thumbnail.image_x48) {
        srcImg = item.image_thumbnail.image_x48
      }
    } else {
      customProps.style = { backgroundColor: Lib.getRandomColor() }
      const nameSplited = item.name.split(' ').filter(w => w)
      strInitial = nameSplited[0].charAt(0).toUpperCase() + (nameSplited.length > 1 ? nameSplited[1].charAt(0).toUpperCase() : '')
    }
    return (
      <Avatar
        shape="square"
        size="large"
        {...customProps}
        src={srcImg}
      >
        {strInitial}
      </Avatar>
    )
  }

  getDescription(item) {
    let nameEllipsis = item.name
    const maxLenght = 17
    if (item.name.length > maxLenght) {
      nameEllipsis = nameEllipsis.slice(0, maxLenght - 3) + '...'
    }
    return (
      <Tooltip placement='top' title={item.name}>
        { nameEllipsis }
      </Tooltip>
    )
  }

  getContent(item) {
    return (
      <Row gutter={[64, 4]} className="mr-5" type='flex' justify='end'>
        <Col xs={0} sm={0} md={12} lg={8} xl={8} xxl={8} style={{ width: "150px", overflow: 'hidden' }}>
          <span className="text-muted mb-2">Unit</span>
          <div>
            <span>
              {item.unit.name}
            </span>
          </div>
        </Col>
        <Col xs={0} sm={0} md={0} lg={8} xl={8} xxl={8} span={8} style={{ width: "200px", overflow: 'hidden' }}>
          <span className="text-muted mb-2">Status</span>
          <div>
            <span>{Modules.renderStatusAIU(item.status)}</span>
          </div>
        </Col>
        <Col xs={0} sm={24} md={12} lg={8} xl={8} xxl={8} span={8} className="w-auto" style={{ overflow: 'hidden' }}>
          <span className="text-muted mb-2">Timber</span>
          <div>
            <span>
              {Modules.renderStatusAIU(item.timber_product)}
            </span>
          </div>
        </Col>
      </Row>
    )
  }

  getPageAdd() {
    const standardProps = {
      history: this.props.history,
      location: this.props.location,
      match: this.props.match,
      userReducer: this.props.userReducer,
    }

    // return (
    //   <SubAllScreen {...standardProps} />
    // )
    return <ModeAdd {...this.props} urlName='api:admin:estimation:product:list' />
  }

  getPage404() {
    return (
      <Row>
        <Row style={{backgroundColor: '#f0f2f5', height: '20vh'}}>
          {/* Header */}
        </Row>
        <Page404 history={this.props.history} strLink='Product' urlLink='/product/product' />
      </Row>
    )
  }

  setThisState = (obj, callback = () => null, force) => {
    if (force === true) {
      return this.setState(obj, callback)
    } else {
      for (const key in obj) {
        if (obj[key] !== this.state[key]) {
          return this.setState(obj, callback)
        }
      }
    }
    callback()
  }

  handleCheckPermission = (callback = () => null) => {
    this.setThisState({ isCheckingPermission: true }, () => {
      const currentPermissionSet = CheckPermission.getPermissionSet({
        userReducer: this.props.userReducer,
        appName: 'estimation',
        modelName: 'product',
      })
      const codePart = this.props.match.params.id === 'add' ? PermissionName.CREATE.codePart : PermissionName.READ.codePart
      const isAccessAllowed = !!currentPermissionSet.find(val => val.search(codePart) !== -1)
      callback({ isAccessAllowed, currentPermissionSet})
    })
  }

  componentDidMount() {
    const callbackAllowed = () => {
      if (this.action !== 'add') {
        this.retrieve()
      }
    }
    if (!this.isSuperuser) {
      this.handleCheckPermission(res => {
        const readOnly = !get(res, 'currentPermissionSet', []).some(item => (item || '').includes(PermissionName.UPDATE.codePart))
        this.setState({
          isCheckingPermission: false,
          isAccessAllowed: get(res, 'isAccessAllowed'),
          currentPermissionSet: get(res, 'currentPermissionSet', []),
          readOnly,
        }, () => {
          if (get(res, 'isAccessAllowed')) {
            callbackAllowed()   
          }
        })
      })
    } else {
      callbackAllowed()
    }
  }

  render() {
    const { readOnly, isLoading, errorResponse, currentProduct, showImageLightbox } = this.state;
    const { isCheckingPermission, isAccessAllowed, currentPermissionSet } = this.state

    if (isCheckingPermission) {
      return (
        <Row type='flex' justify='center' align='middle' style={{ marginTop: '30vh' }}>
          <Spin
            size='large'
            tip='Checking permission...'
            indicator={<Icon spin type='loading' style={{ fontSize: '4rem' }} />}
          />
        </Row>
      )
    }

    if (!isAccessAllowed) {
      return <PageUnauthorized />
    }

    return (!isLoading && currentProduct) ? (
      <Layout className='um-layout'>
        <Layout>
          <PageHeader
            title='Product'
            subTitle={readOnly &&
              <Col style={{ fontSize: '12px', fontWeight: 500, backgroundColor: '#1890ff', color: '#fff', padding: '0 12px 2px 12px', borderRadius: '14px' }}>
                Read Only
              </Col>
            }
            style={{
              border: '1px solid rgb(235, 237, 240)'
            }}
            onBack={() => this.goToMasterProduct()}
            ghost={false}
            // avatar={{ src: currentProduct.selected_image }}
          >
            <Row className='mb-3' type='flex' justify='space-between'>
              <Col xs={24} sm={24} md={24} lg={14}>
                <Row type='flex' justify='space-between'>
                  <Col style={{ width: '250px' }}>
                    { this.getThumbnail() }
                  </Col>
                  <Col style={{ width: 'calc(100% - 250px - 12px)' }}>
                    <Row>
                      <Col>
                        <Title level={4} ellipsis style={{marginBottom: '3px'}}>{currentProduct.name}</Title>
                        <Row>
                          <Col style={{width: '45%', minWidth: '210px'}}>
                            <Divider type='horizontal' style={{margin: '0'}} />
                          </Col>
                        </Row>
                      </Col>
                      <Descriptions className='ant-desc-custom' column={1}>
                        <Descriptions.Item label='Status'>
                          {Modules.renderStatusAIU(currentProduct.status)}
                        </Descriptions.Item>
                        <Descriptions.Item label='Code'>
                          {currentProduct.code}
                        </Descriptions.Item>
                        <Descriptions.Item label='Template Set'>
                          {currentProduct.template_set.name}
                        </Descriptions.Item>
                        <Descriptions.Item label='Created'>
                          {moment(currentProduct.created).format('llll')}
                        </Descriptions.Item>
                        <Descriptions.Item label='Timber Product'>
                          {Modules.renderStatusAIU(currentProduct.timber_product)}
                        </Descriptions.Item>
                      </Descriptions>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col xs={0} sm={0} md={0} lg={10}>
                {/* { this.getOptionalChart(currentProduct) } */}
                <SummaryUsage productId={currentProduct.pk} />
                {/* <UsedStat productId={currentProduct.pk} /> */}
              </Col>
            </Row>
            <Row type='flex'>
              <IconLink
                type='info-circle'
                text='Another Product'
                onClick={e => {
                  e.preventDefault();
                  this.setState({visibleDrawer: true})
                }}
              />
            </Row>
          </PageHeader>
          <Content style={{ margin: '0.5rem 0' }}>
            <Card>
              { this.getCurrentProductTab() }
            </Card>
          </Content>
        </Layout>
        { this.selectPhoto() }
        { showImageLightbox &&
          <LightboxModal
            imagesRecord={this.getLightboxImage()}
            currentIndex={0}
            callback={obj => this.setState({[obj.key]: obj.value})}
          />
        }
        { this.getDrawer() }
      </Layout>
    ) : (this.action === 'add') ? (
      this.getPageAdd()
    ) : (!isLoading) ? (
      <PageError
        errorResponse={errorResponse}
        onGoBack={this.onGoBack}
        onReload={this.preRetrieve}
      />
      // this.getPage404()
    ) : (
      <div style={{ paddingTop: 100, textAlign: 'center' }}>
        <Spin
          size='large'
          tip='making coffee...'
          indicator={<Icon type='loading' style={{ fontSize: '4rem' }} spin />}
        />
      </div>
    );
  }
}

const StyledImg = styled(LazyLoadImage)`
  border-radius: 4px;
  width: 100%;
  height: auto;
  max-width: 250px;
  max-height: 275px;
  min-height: 135px;
`

const StyledOverlay = styled.div`
  border-radius: 4px;
`

const StyledNoImage = styled(Row)`
  width: 100%;
  height: 180px;
  background-color: #1890ff;
  border-radius: 4px;
  padding: 4px;
`

const StyledDividerH = styled(Divider)`
  margin: 12px 0 !important;
`
