export default [
  {
    title: 'Name',
    type: 'text',
    data: '',
    required: true,
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'name',
    fieldKey: 'name',
    editable: true,
    order: 0,
  }, {
    title: 'Merchant group',
    type: 'SelectQuery',
    data: 'module-merchant-group',
    required: true,
    initialValue: '',
    note_text: '',
    // additional: 'edit|add',
    selectedKey: 'pk',
    selectedValue: 'name',
    dataIndex: 'merchant_group',
    order: 1,
  }, {
    title: 'Mapping Mode',
    type: 'SelectQuery',
    data: 'module-merchant-mappingMode',
    initialValue: '',
    note_text: '',
    // additional: 'edit|add',
    selectedKey: 'pk',
    selectedValue: 'name',
    dataIndex: 'mapping_mode',
    order: 1,
  }, {
    title: 'Branch',
    type: 'SelectQuery',
    // required: true,
    data: 'branches',
    initialValue: null,
    note_text: '',
    // additional: 'edit|add',
    selectedKey: 'pk',
    selectedValue: 'name',
    dataIndex: 'branch',
    fieldKey: 'branch',
    width: '150px',
    editable: true,
    order: 2,
  }, {
    title: 'Code',
    type: 'text',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'code',
    fieldKey: 'code',
    editable: true,
    order: 3,
    tooltip: {
      title: "Attention please that code is used for connection between IBES and MM, don't change it carelessly.",
    },
  }, {
    title: 'City',
    type: 'SelectQuery',
    data: 'cities',
    initialValue: undefined,
    selectedKey: 'pk',
    selectedValue: 'name',
    dataIndex: 'city',
  }, {
    title: 'Tax',
    type: 'number',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'tax',
    fieldKey: 'tax',
    editable: true,
    order: 4,
  }, {
    title: 'Discount',
    type: 'number',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'discount',
    fieldKey: 'discount',
    editable: true,
    order: 5,
  }, {
    title: 'Rate',
    type: 'select',
    disableAllowClear: true,
    data: [
      {
        alias: '1',
        value: 'Rate 1'
      }, {
        alias: '2',
        value: 'Rate 2'
      }, {
        alias: '3',
        value: 'Rate 3'
      }, {
        alias: '4',
        value: 'Rate 4'
      }, {
        alias: '5',
        value: 'Rate 5'
      }, {
        alias: '6',
        value: 'Rate 6'
      }, {
        alias: '7',
        value: 'Rate 7'
      }, {
        alias: '8',
        value: 'Rate 8'
      }, {
        alias: '9',
        value: 'Rate 9'
      }, {
        alias: '10',
        value: 'Rate 10'
      },
    ],
    initialValue: '1',
    note_text: '',
    additional: '',
    dataIndex: 'rate',
    fieldKey: 'rate',
    editable: true,
    order: 6,
  }, {
    title: 'Range Type',
    type: 'select',
    required: true,
    disableAllowClear: true,
    data: [
      {
        alias: '1',
        value: 'Range Type 1'
      }, {
        alias: '2',
        value: 'Range Type 2'
      }
    ],
    initialValue: '1',
    note_text: '',
    additional: '',
    dataIndex: 'range_type',
    fieldKey: 'range_type',
    editable: true,
    order: 7,
  }, {
    title: 'Minimum charge',
    type: 'number',
    data: '',
    initialValue: '',
    note_text: 'Minimum charge for billing.',
    additional: '',
    dataIndex: 'minimum_charge',
    fieldKey: 'minimum_charge',
    editable: true,
    order: 8,
  }, {
    title: 'Account code',
    type: 'text',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'account_code',
    fieldKey: 'account_code',
    editable: true,
    order: 9,
  }, {
    title: 'Default finish day',
    type: 'number',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'default_finish_day',
    fieldKey: 'default_finish_day',
    editable: true,
    order: 10,
  }, {
    title: 'Custom SKU',
    type: 'checkbox',
    data: '',
    initialValue: '',
    note_text: 'Merchant option to use their sku description',
    additional: '',
    dataIndex: 'custom_sku',
    fieldKey: 'custom_sku',
    editable: true,
    order: 11,
  }, {
    title: 'Xero Name',
    type: 'text',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'xero_name',
    fieldKey: 'xero_name',
    editable: true,
    order: 12,
  }, {
    title: 'E-Mail',
    type: 'input_multiple',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'email',
    fieldKey: 'email',
    editable: true,
    order: 13,
  }, {
    title: 'E-Mail schedule',
    type: 'checkbox',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'email_schedule',
    fieldKey: 'email_schedule',
    editable: true,
    order: 14,
  }, {
    title: 'Send Export Schedule',
    type: 'checkbox',
    initialValue: true,
    dataIndex: 'send_export_schedule',
  }, {
    title: 'Service fee',
    type: 'checkbox',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'service_fee',
    fieldKey: 'service_fee',
    editable: true,
    order: 15,
  }, {
    title: 'Is Active',
    type: 'checkbox',
    data: '',
    initialValue: true,
    note_text: '',
    additional: '',
    dataIndex: 'is_active',
    fieldKey: 'is_active',
    editable: true,
    order: 16,
  }, {
    title: 'On Hold',
    type: 'checkbox',
    data: '',
    initialValue: false,
    note_text: '',
    additional: '',
    dataIndex: 'on_hold',
  }, {
    title: 'Operating Branch',
    type: 'SelectQuery',
    data: 'operatingBranches',
    initialValue: null,
    // required: true,
    note_text: '',
    // additional: 'edit|add',
    selectedKey: 'pk',
    selectedValue: 'name',
    dataIndex: 'operating_branch',
    fieldKey: 'operating_branch',
    editable: true,
    order: 17,
  }, {
    title: 'Extras rating',
    type: 'number',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'extras_rating',
    fieldKey: 'extras_rating',
    editable: true,
    order: 18,
  }, {
    title: 'Extra Charge',
    type: 'SelectQuery',
    data: 'extraCharges',
    // required: true,
    initialValue: null,
    note_text: '',
    // additional: 'edit|add',
    selectedKey: 'pk',
    selectedValue: 'name',
    dataIndex: 'extra_charge',
    fieldKey: 'extra_charge',
    editable: true,
    width: '150px',
    order: 19,
  }, {
    title: 'Bill Invoice Type',
    dataIndex: 'bill_invoice_type',
    type: 'select',
    inputProps: { allowClear: false },
    initialValue: 'individual',
    data: [
      { alias: 'individual', value: 'Individual' },
      { alias: 'group', value: 'Group' },
    ],
  }, {
    title: 'Export Preference',
    dataIndex: 'schedule_preference',
    type: 'select',
    allowClear: true,
    initialValue: null,
    data: [
      { alias: 'email', value: 'Email' },
      { alias: 'drive', value: 'Drive' },
      { alias: 'mmonline', value: 'Push to MM' },
    ],
  },
  {
    title: 'Special Doc',
    dataIndex: 'special_doc',
    type: 'file_s3',
    // initialValue: null,
    getPrepareOpts: (recordId) => ({
      urlKey: 'merchants-special-docs-prepare',
      data: { merchant: recordId || null },
    }),
  },
];
