import React from 'react';
import PropTypes from 'prop-types';
import {FormContext, FormProvider} from "App/Component/Context/ContextForm";
import DynamicField from "App/Component/DynamicField";
import styled from "styled-components";
import { Form, Icon, Button, Spin, Result, Typography, Divider, message } from 'antd';
import { get, isEmpty } from 'lodash';
import { RequestV2 as Request } from 'App/Utils';
import Lib2 from 'App/Component/Singlepage/Lib2'
import { Lib } from 'App/Utils';

class RevertHistory extends React.PureComponent {
  constructor(p){
    super(p)
    this.state = {
      loading: true,
      data: {},
      fail: false
    }
  }

  componentDidMount() {
    this.retrieve()
  }

  retrieveFailed=(data)=>{
    Lib.errorMessageHandler(data.response)
    this.setState({ fail: true, loading: false })
  }

  retrieveSuccess=(res)=>{
    this.setState({ loading: false, data: res.data })
  }

  retrieve=()=>{
    const [p] = [this.props]
    this.setState({ fail: false })
    Request(
      'get',
      p.urlDetail,
      {},
      {},
      // [p.data.pk],
      [p.idParent, p.detail.pk],
      this.retrieveSuccess,
      this.retrieveFailed,
    )
  }

  onSave=(form)=>{
    this.setState({ loading: true })
    const [p] = [this.props]
    const {validateFieldsAndScroll, resetFields} = form;
    validateFieldsAndScroll({scroll: {offsetTop: 150}}, (err, values) => {
      const value  = Lib2.getValuesWithFile(values, p.urlDetail)
      this.request = Request(
        "put",
        p.urlDetail,
        {},
        value,
        [p.idParent, p.detail.pk],
        this.editSuccess,
        this.editFailed,
        resetFields
      );
    })
  }

  editSuccess=(response)=>{
    const [p] = [this.props]
    this.setState({ loading: false })
    const art = [{ selected: null }, 'retrieve']
    if(p.updateHeader)
      art.push(((ok)=>{
          if(ok)
            p.updateHeader(response.data)
        }
      ))
    p.dynamicFunction(...art)
    message.success('Revert data successfully');
  }

  editFailed = (error) => {
    console.warn(error)
    message.destroy()
    const errors = get(error.response, 'data')
    if (errors && typeof errors === 'object') {
      for (const key in errors) {
        message.error(`Error in ${key}: ${errors[key]}`)
      }
    } else {
      message.error('Save failed, code: ' + get(error.response, 'status') + ' ' + get(error.response, 'statusText'))
    }
    this.setState({ loading: false })
  }

  hasErrors(fieldsError) {
    let hasError = false
    if (fieldsError) {
      hasError = Object.keys(fieldsError).some(field => fieldsError[field])
    }
    // Return Boolean
    return hasError
  }

  renderField=()=>{
    const { readOnly, fields, dynamicFunction } = this.props
    const [s] = [this.state]
    if(s.loading)
      return null
    return(
      <FormProvider>
          <Form layout="horizontal">
            { fields.map((elem, index) => {
              let datafield = (elem.dataIndex) && get(s.data, elem.dataIndex.split('.'))
              return (
                <DynamicField
                  {...elem}
                  onBlur={()=>null}
                  normFile={()=>null}
                  toggleDrawerSQ={()=>null}
                  operation={"edit"}
                  dataRecord={datafield}
                  key={index}
                />
              );
            })}
            <FormContext.Consumer>
              {(form) => (
                <StyledFooter>
                  <Divider />
                  { readOnly ?
                    <StyledButton
                      
                      type="danger"
                      disabled={s.loading}
                    >
                      <Icon type="edit"/>  Edit
                    </StyledButton> :
                    <>
                      <StyledButton type="danger" loading={s.loading} onClick={() => dynamicFunction({ selected: null })}>
                        Cancel
                      </StyledButton>
                      <StyledButton
                        onClick={() => this.onSave(form)}
                        type="primary"
                        disabled={s.loading || this.hasErrors(form.getFieldsError())}
                      >
                        <Icon type="save" theme="filled" /> Save
                      </StyledButton>
                    </>
                  }
                </StyledFooter>
              )}
            </FormContext.Consumer>
          </Form>
        </FormProvider>
    )
  }
  
  render(){
    const [s,p] = [this.state,this.props]
    return(
      <Spin spinning={s.loading}>
        <Typography.Title level={4} style={{ fontStyle: 'italic' }} type="secondary">Revert {p.title}</Typography.Title>
        <Typography.Text className="mb-5">Press the save button below to revert to this version of the object.</Typography.Text>
        <Divider orientation="center" dashed />
        { s.fail ?
          <Result status="404"extra={<Button onClick={()=>this.setState({ loading: true },()=>this.retrieve())} type="primary">Retry</Button>} />
        :
          this.renderField()
        }
      </Spin>
    )
  }
}
export default RevertHistory;

RevertHistory.propTypes = {
  url: PropTypes.string,
  fields: PropTypes.array
};

RevertHistory.defaultProps = {
  url: '',
  fields: []
};

const StyledFooter = styled.div`
  // position: fixed;
  // right: 16px;
  // bottom: 10px;
  // width: 230px;
  // border: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
`;
const StyledButton = styled(Button)`
  margin-right: 8px;
`;