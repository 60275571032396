export default [
  {
    title: 'Title',
    type: 'text',
    dataIndex: 'title',
    required: true,
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
  },
  {
    title: 'Image',
    type: 'fileImg',
    dataIndex: 'file',
    required: true,
    multipleUpload: true,
    // limit: 5,
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
  },
  {
    title: 'Description',
    type: 'textarea',
    dataIndex: 'description',
  }, {
    title: 'Set to Default Image',
    type: 'checkbox',
    dataIndex: 'is_thumbnail',
  },
];
