export default [
  {
    title: 'Central queue',
    type: 'SelectQuery',
    data: 'jobQueues',
    required: true,
    initialValue: '',
    note_text: '',
    additional: 'edit|add',
    order: 0,
  }, {
    title: 'Central Version',
    type: 'text',
    data: '',
    initialValue: '',
    note_text: 'e.g. "Christmas".',
    additional: '',
    order: 1,
  }, {
    title: 'Central modified',
    type: 'datetime',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    order: 2,
  }, {
    title: 'Central status',
    type: 'datetime',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    order: 3,
  }, {
    title: 'Tauranga queue',
    type: 'SelectQuery',
    data: 'jobQueues',
    initialValue: '',
    note_text: '',
    additional: 'edit|add',
    order: 4,
  }, {
    title: 'Tauranga Version',
    type: 'text',
    data: '',
    initialValue: '',
    note_text: 'e.g. "Christmas".',
    additional: '',
    order: 5,
  }, {
    title: 'Tauranga modified',
    type: 'datetime',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    order: 6,
  }, {
    title: 'Tauranga status',
    type: 'datetime',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    order: 7,
  }, {
    title: 'Jakarta queue',
    type: 'SelectQuery',
    data: 'jobQueues',
    initialValue: '',
    note_text: '',
    additional: 'edit|add',
    order: 8,
  }, {
    title: 'Jakarta Version',
    type: 'text',
    data: '',
    initialValue: '',
    note_text: 'e.g. "Christmas".',
    additional: '',
    order: 9,
  }, {
    title: 'Jakarta modified',
    type: 'datetime',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    order: 10,
  }, {
    title: 'Jakarta status',
    type: 'datetime',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    order: 11,
  }, {
    title: 'Freelance queue',
    type: 'SelectQuery',
    data: 'jobQueues',
    initialValue: '',
    note_text: '',
    additional: 'edit|add',
    order: 12,
  }, {
    title: 'Freelance Version',
    type: 'text',
    data: '',
    initialValue: '',
    note_text: 'e.g. "Christmas".',
    additional: '',
    order: 13,
  }, {
    title: 'Freelance modified',
    type: 'datetime',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    order: 14,
  }, {
    title: 'Freelance status',
    type: 'datetime',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    order: 15,
  }, {
    title: 'Solo queue',
    type: 'SelectQuery',
    data: 'jobQueues',
    initialValue: '',
    note_text: '',
    additional: 'edit|add',
    order: 16,
  }, {
    title: 'Solo Version',
    type: 'text',
    data: '',
    initialValue: '',
    note_text: 'e.g. "Christmas".',
    additional: '',
    order: 17,
  }, {
    title: 'Solo modified',
    type: 'datetime',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    order: 18,
  }, {
    title: 'Solo status',
    type: 'datetime',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    order: 19,
  }, {
    title: 'Central2 queue',
    type: 'SelectQuery',
    data: 'jobQueues',
    initialValue: '',
    note_text: '',
    additional: 'edit|add',
    order: 20,
  }, {
    title: 'Central2 Version',
    type: 'text',
    data: '',
    initialValue: '',
    note_text: 'e.g. "Christmas".',
    additional: '',
    order: 21,
  }, {
    title: 'Central2 modified',
    type: 'datetime',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    order: 22,
  }, {
    title: 'Central2 status',
    type: 'datetime',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    order: 23,
  },
]