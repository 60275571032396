import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'antd'
import { get } from 'lodash'
import { Lib, RequestV2 as Request } from 'App/Utils'
import LoadingSpin from 'App/Component/LoadingSpin'
import PageError from 'App/Component/PageError'
import CustomDescriptions from '../CustomDescriptions'

class DialogContent extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true, // show loading only
      errorResponse: null,
      data: null,
    }
  }

  read = (keyLoading = 'isLoading', callback = () => null) => {
    this.setState({ [keyLoading]: true }, () => {
      Request(
        'get',
        // 'api:admin:exim:importqueue:ref-errors-detail',
        this.props.urlKey,
        {},
        {},
        // [this.props.queueId, this.props.errorId],
        this.props.args,
        this.readSuccess,
        this.readFailed,
        { keyLoading, callback }
      )
    })
  }

  readSuccess = (response, extra) => {
    this.setState({ [extra.keyLoading]: false, data: response.data }, () => {
      extra.callback(true, response)
    })
  }

  readFailed = (error, extra) => {
    if (!this.state.isLoading) Lib.errorMessageHandler(get(error, 'response'))
    this.setState({ [extra.keyLoading]: false, errorResponse: get(error, 'response') }, () => {
      extra.callback(false, error)
    })
  }

  reload = (keyLoading, callback) => {
    this.read(keyLoading, (isSuccess) => !isSuccess && callback(true))
  }

  prev = () => this.props.prev && this.props.prev()

  next = () => this.props.next && this.props.next()

  componentDidMount() {
    this.read()
  }

  render() {
    const { descriptionsProps, fields } = this.props
    const { isLoading, data, errorResponse } = this.state

    if (isLoading) {
      return (
        <LoadingSpin height='200px' tip='Loading...' />
      )
    }

    return data ? (
      <div
        ref={ref => {
          this._focusable = ref
          if (ref) ref.focus()
        }}
        // className='focus-no-outline'
        className='focus-outline-gray'
        tabIndex={0}
        onKeyDown={e => {
          if (e.keyCode === 37) {
            this.prev()
          } else if (e.keyCode === 39) {
            this.next()
          }
        }}
      >
        {this.props.prev && (
          <div style={{ position: 'absolute', top: '45%', left: '-50px' }}>
            <Icon className='white-hover' type='caret-left' style={{ fontSize: '48px', cursor: 'pointer' }} onClick={this.prev} />
          </div>
        )}
        <CustomDescriptions fields={fields} record={data} descriptionsProps={descriptionsProps} />
        {this.props.next && (
          <div style={{ position: 'absolute', top: '45%', right: '-50px' }}>
            <Icon className='white-hover' type='caret-right' style={{ fontSize: '48px', cursor: 'pointer' }} onClick={this.next} />
          </div>
        )}
      </div>
    ) : (
      <PageError errorResponse={errorResponse} onReload={this.reload} />
    )
  }
}

DialogContent.propTypes = {
  urlKey: PropTypes.string.isRequired,
  args: PropTypes.array,
  fields: PropTypes.array,
  descriptionsProps: PropTypes.object,
}

DialogContent.defaultProps = {
  args: [],
  fields: [],
  descriptionsProps: null,
}

export default DialogContent