import React from "react";
import PropTypes from "prop-types";
import { Row, Spin, Icon } from "antd";
import { get } from "lodash";
import { Lib, CheckPermission } from "App/Utils";
import SubAllList from "App/Component/SubAllList";
import descriptor from "./descriptorOffline";

class PagePmTimberStartingRange extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isGettingPermissions: true,
      permissionItems: [],
    }
  }

  getPermissions = () => {
    const permissionItems = CheckPermission.getPermissionSet({
      userReducer: this.props.userReducer,
      appName: 'estimation',
      modelName: 'pmtimberstartingrange',
    }).filter(perm => !perm.includes('.delete_'))
    this.setState({ isGettingPermissions: false, permissionItems })
  }

  onAddPmTimber = () => {
    const { merchant } = this.props
    const historyState = {
      dataIdentifier: {
        merchant: { pk: merchant.pk, name: merchant.name }
      }
    }
    Lib.historyAction(this.props.history, '/admin/estimation/pm-timber-starting-ranges/add', historyState).push()
  }

  onDetailPmTimber = (id) => {
    Lib.historyAction(this.props.history, `/admin/estimation/pm-timber-starting-ranges/${id}`).push()
  }

  componentDidMount() {
    this.getPermissions()
  }

  componentWillUnmount() {
    Lib.windowStop();
  }

  render() {
    const { userReducer, match } = this.props
    const { isGettingPermissions, permissionItems } = this.state

    return isGettingPermissions ? (
      <Row type='flex' justify='center' align='middle' style={{ margin: '30vh 0' }}>
        <Spin
          size='large'
          tip='Loading...'
          indicator={<Icon spin type='loading' style={{ fontSize: '4rem' }} />}
        />
      </Row>
    ) : (
      <SubAllList
        permissions={permissionItems}
        pageTitle="PM Timber Starting Range"
        avatar="subheading.label"
        descriptor={descriptor}
        masterKey="module-merchant-merchant-refPmTimber"
        args={[get(match, 'params.id')]}
        onAddClick={this.onAddPmTimber}
        onDetailClick={this.onDetailPmTimber}
        userReducer={userReducer}
      />
    );
  }
}

PagePmTimberStartingRange.propTypes = {
  userReducer: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  merchant: PropTypes.object.isRequired,
}

PagePmTimberStartingRange.defaultProps = {
  permissionItems: [],
}

export default PagePmTimberStartingRange;
