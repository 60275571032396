import React from 'react'
import Lightbox from 'react-image-lightbox'
import PropTypes from 'prop-types'

const LightboxModal = ({imagesRecord, currentIndex, callback}) => {
  return (
    imagesRecord ?
    <Lightbox
      imageTitle={imagesRecord[currentIndex].title}
      imageCaption={imagesRecord[currentIndex].description}
      mainSrc={imagesRecord[currentIndex].file}
      nextSrc={imagesRecord[(currentIndex + 1) % imagesRecord.length].file}
      prevSrc={imagesRecord[(currentIndex + imagesRecord.length - 1) % imagesRecord.length].file}
      onCloseRequest={() => callback({
        key: 'showImageLightbox',
        value: false,
      })}
      onMovePrevRequest={() => callback({
        key: 'currentIndex',
        value: ((currentIndex + imagesRecord.length - 1) % imagesRecord.length)
      })}
      onMoveNextRequest={() => callback({
        key: 'currentIndex',
        value: (((currentIndex + 1) % imagesRecord.length))
      })}
    />
    : null
  )
}

LightboxModal.propTypes = {
  imagesRecord: PropTypes.array.isRequired
}

LightboxModal.defaultProps = {
  // imagesRecord: [{ { file: null } }]
}

export default LightboxModal

