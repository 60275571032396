export default [
  {
    title: 'Username',
    type: 'text',
    required: true,
    dataIndex: 'username',
    order: 0,
  }, {
    title: 'First Name',
    type: 'text',
    required: true,
    dataIndex: 'first_name',
    initialValue: '',
  }, {
    title: 'Last Name',
    type: 'text',
    dataIndex: 'last_name',
    initialValue: '',
  }, {
    title: 'Email',
    type: 'email',
    dataIndex: 'email',
    initialValue: '',
  }, {
    title: 'Is Active',
    type: 'checkbox',
    dataIndex: 'is_active',
    initialValue: true,
  },
]