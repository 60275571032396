import { Lib } from 'App/Utils'

export default [
  {
    title: 'Status',
    dataIndex: 'status',
  }, {
    title: 'Created Time',
    dataIndex: 'created_time',
    render: (value, record, index) => Lib.getValue(value, { type: 'datetime' }),
  },
]