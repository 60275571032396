import { BaseDetail } from 'App/Component/Bases'
import fields from 'App/Screens/Job/Holidays/fields'

class PageAdd extends BaseDetail {
  constructor(props) {
    super(props)

    this.title = 'Holiday'
    this.urlData = 'module-branch-holiday'
    this.fields = fields
    this.state = {
      isCheckingPermission: false,
      isAccessAllowed: true,
    }
  }
}

export default PageAdd