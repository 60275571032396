import React from 'react'
import PropTypes from 'prop-types'
import { Button, Col, Icon, Modal, Row } from 'antd'
import { get } from 'lodash'

class ModalDelete extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isDeleting: false,
      selectedGroup: null,
      visible: false,
    }
  }

  show = (selectedGroup) => this.setVisible(true, { selectedGroup })

  hide = () => !this.state.isDeleting && this.setVisible(false)

  setVisible = (visible, state) => this.setState({ ...state, visible })

  getFooter = () => (
    <Row type='flex' justify='end' gutter={[8, 8]}>
      <Col>
        <Button
          ref={ref => ref && ref.buttonNode.focus()}
          type='primary'
          onClick={this.hide}
          style={{ width: '65px'}}
        >
          No
        </Button>
      </Col>
      <Col>
        <Button type='danger' loading={this.state.isDeleting} onClick={this.onDelete}>
          Yes
        </Button>
      </Col>
    </Row>
  )

  onDelete = (event) => {
    this.setState({ isDeleting: true }, () => {
      this.props.onConfirm(this.state.selectedGroup, (success) => {
        this.setState({ isDeleting: false, })
        if (success) this.hide()
      })
    })
  }

  render() {
    console.log('Rendered', this)
    const { selectedGroup, visible } = this.state
    const title = this.props.title ? `Delete ${this.props.title}` : null

    return (
      <Modal
        ref={this.onRef}
        title={title}
        visible={visible}
        onCancel={this.hide}
        footer={this.getFooter()}
      >
        <div>
          <Row type='flex' justify='center'>
            <Icon
              type='warning'
              theme='twoTone'
              twoToneColor='#ff4d4f'
              style={{ fontSize: '8rem' }}
            />
          </Row>
          <div style={{ width: '100%', marginTop: '24px', textAlign: 'center' }}>
            "<span className='font-weight-bold'>{get(selectedGroup, 'name')}</span>" will be deleted, are you sure?
          </div>
        </div>
      </Modal>
    )
  }
}

ModalDelete.propTypes = {
  title: PropTypes.any,
  onConfirm: PropTypes.func,
}

ModalDelete.defaultProps = {
  title: null,
  onConfirm: (agree, callback) => {callback()},
}

export default ModalDelete