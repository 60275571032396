import React from 'react'
import { Redirect } from 'react-router-dom'
import { Row, Spin, Icon } from 'antd'
import { get } from 'lodash'
import { HeaderMenu, NavMenu } from 'App/Config'
import hasNavMenu from './hasNavMenu'
import PageUnauthorized from 'App/Component/PageUnauthorized'

class MainRedirect extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isCheckingPermission: true,
      // isAccessAllowed: false,
      urlTarget: '',
    }
  }

  componentDidMount() {
    if (get(this.props, ['userReducer', 'user', 'is_superuser'])) {
      return this.setState({ isCheckingPermission: false, urlTarget: '/admin' })
    } else {
      const allowedModules = get(this.props, ['userReducer', 'user', 'allowed_modules'], [])
      for (let i = 0; i < HeaderMenu.length; i += 1) {
        if (allowedModules.find(codename => codename === HeaderMenu[i].codename)) {
          if (
            hasNavMenu(
              HeaderMenu[i],
              get(this.props, ['userReducer', 'user', 'permissions'], []),
              NavMenu
            )
          ) {
            return this.setState({ isCheckingPermission: false, urlTarget: HeaderMenu[i].url })
          }
        }
      }
    }
    return this.setState({ isCheckingPermission: false })
  }

  render() {
    const { isCheckingPermission, urlTarget } = this.state

    if (isCheckingPermission) {
      return (
        <Row type='flex' justify='center' align='middle' style={{ marginTop: '30vh'}}>
          <Spin
            size="large"
            tip="Checking permission..."
            indicator={<Icon type="loading" style={{ fontSize: "4rem" }} spin />}
          />
        </Row>
      )
    }

    return urlTarget ? (
      <Redirect
        from={this.props.location.pathname}
        to={urlTarget}
      />
    ) : (
      <PageUnauthorized subTitle='This page is only for administrators.' extra={<span></span>} />
    )
  }
}

export default MainRedirect