const descriptor = {
  "app_label": "jobs",
  "model_name": "merchant",
  "pagination": true,
  "columns": [
    {
      "name": "pk",
      "label": "ID",
      "help_text": null,
      "hidden": true,
      "identifier": true,
      "data_type": "int",
      "search": false,
      "sort": false
    },
    {
      "name": "name",
      "label": "Name",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "ellipsis": true,
      "search": true,
      "sort": true
    },
    {
      "name": "merchant_group",
      "label": "Merchant group",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": null,
      "child_path": "name",
      "search": true,
      "sort": true
    },
    {
      "name": "code",
      "label": "Code",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "search": true,
      "sort": true
    },
    {
      "name": "is_active",
      "label": "Is active",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "bool",
      "search": false,
      "sort": true
    },
    {
      "name": "modified",
      "label": "Modified",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "datetime",
      "search": false,
      "sort": true
    }
  ],
  "filter_by": [],
  "export_import": {
    "export": {
      "directly": false,
      "url_name": "api:admin:jobs:merchant:export",
      "filter_by": [
        {
          "label": "Export Mode",
          "name": "export_mode",
          "type": "choices",
          "choices": [
            [
              "normal",
              "Normal Export"
            ],
            [
              "initial",
              "Initial Export"
            ]
          ]
        },
        {
          "label": "Export Limit",
          "name": "limit",
          "type": "choices",
          "choices": [
            [
              "all",
              "All Records"
            ],
            [
              "100",
              "100 Records"
            ],
            [
              "500",
              "500 Records"
            ]
          ]
        }
      ]
    },
    "import": {
      "url_name": "api:admin:estimation:exportimportsession:import",
      "allow_initial_data": true,
      "fields": [],
      "example_format_string": "seq,branch,name,merchant_group,code,tax,discount,rate,range_type,minimum_charge,account_code,default_finish_day,custom_sku,xero_name,email,service_fee,template_form,is_active"
    }
  }
}

export default descriptor