const roles = {
  job: 'Shared Job Queue',
  qa: 'Shared QA Queue',
}

export default {
  "app_label": "jobs",
  "model_name": "jobqueue",
  "pagination": true,
  "columns": [
    {
      "name": "pk",
      "label": "ID",
      "help_text": null,
      "hidden": true,
      "identifier": true,
      "data_type": "int",
      "search": false,
      "sort": false
    },
    {
      "name": "name",
      "label": "Name",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "ellipsis": true,
      "search": true,
      "sort": true
    },
    {
      "name": "branch",
      "label": "Branch",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": null,
      "child_path": "name",
      "search": true,
      "sort": true
    },
    {
      "name": "role",
      "label": "Role",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "search": true,
      "sort": true,
      render: val => roles[val] || val,
    },
    {
      "name": "default_for_branch",
      "label": "Default for branch",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "bool",
      "search": false,
      "sort": true
    },
    {
      "name": "total_job",
      "label": "Total job",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "int",
      "search": true,
      "sort": true
    },
    {
      "name": "modified",
      "label": "Modified",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "datetime",
      "search": false,
      "sort": true
    }
  ],
  "filter_by": [
    {
      "label": "Role",
      "name": "role",
      "type": "choice",
      "choices": [
        [
          "job",
          "Shared Job Queue"
        ],
        [
          "qa",
          "Shared QA Queue"
        ]
      ]
    },
    {
      "label": "Default for branch",
      "name": "default_for_branch",
      "type": "switch"
    },
    {
      "label": "Is commercial",
      "name": "is_commercial",
      "type": "switch"
    },
    {
      "label": "Branch",
      "name": "branch",
      "type": "api_reference",
      "api": {
        "url_name": "api:admin:multisite:branch:autocomplete",
        "key": "pk",
        "display": "name",
        "pagination": true
      }
    }
  ],
  "export_import": null
}