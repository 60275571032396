import {Radar, ChartCard, WaterWave} from 'ant-design-pro/lib/Charts';
import {Col, Row} from "antd";
import React from "react";
import { MiniArea } from 'ant-design-pro/lib/Charts';
import moment from 'moment';
import PropTypes from 'prop-types';

class ChartRadar extends React.Component{
  render() {
    const {radarOriginData, data} = this.props
    let radarName = data.name
    if(data.label){
      radarName = data.label
    }
    const radarData = [];
    const radarTitleMap = {
      bill_access: 'Bill Access',
      allowed_analysis_report: 'Allowed Analysis Report',
      allowed_job_assignment: 'Allowed Job Assignment',
      allowed_bill: 'Allowed Bill',
      is_staff: 'Is Staff',
    };
    radarOriginData.forEach(item => {
      Object.keys(item).forEach(key => {
        if (key !== 'name') {
          radarData.push({
            name: item.name,
            label: radarTitleMap[key],
            value: item[key],
          });
        }
      });
    });
    const visitData = [];
    const beginDay = new Date().getTime();
    for (let i = 0; i < 20; i += 1) {
      visitData.push({
        x: moment(new Date(beginDay + 1000 * 60 * 60 * 24 * i)).format('YYYY-MM-DD'),
        y: Math.floor(Math.random() * 5),
      });
    }
    return(
      <Row>
        <Col xs={15}>
          <ChartCard title={"Lead time branch "+ radarName} style={{marginRight: "5px"}}>
            <Radar hasLegend height={286} data={radarData} />
          </ChartCard>
        </Col>
        <Col xs={9}>
          <ChartCard title={"Calculation"}>
            <div style={{ textAlign: 'center' }}>
              <WaterWave height={161} title="busy calculation" percent={34} />
            </div>
          </ChartCard>
          <ChartCard style={{marginTop: "5px"}} title={"User is on leave"}>
            <MiniArea line color="#cceafe" height={45} data={visitData} />
          </ChartCard>
        </Col>
      </Row>
    )
  }
}

ChartRadar.propTypes = {
  radarOriginData: PropTypes.array,
  data: PropTypes.object
}

export default ChartRadar;