import React, {PureComponent, Fragment} from 'react';
import PropTypes from 'prop-types'
import {Form, Input, Select, Switch, DatePicker, Button, Upload, Icon, Col} from 'antd'
import {FormContext} from "./Context/ContextForm";
import Lib from 'App/Utils/Lib'
import moment from 'moment'
import SelectQuery from 'App/Component/SelectQuery';
import Tableeditable from '../Screens/Job/Buildings/Planshelper/Tableeditable'

const FormItem = Form.Item;
const {TextArea} = Input;
const {Option} = Select;

const imageAllowed = '.jpg, .jpeg, .png, .gif';

class DynamicFieldHalfScreen extends PureComponent {
  state = {
    disabledAdditional: true,
  };

  changeSQ = (e) => {
    this.setState({
      disabledAdditional: !e
    })
    if (this.props.onBlur) {
      this.props.onBlur(e, this.props.dataIndex, this.props.type)
    }
  };

  textValidator = (rule, value, callback) => {
    if (value) {
      const {maxLength} = this.props
      if (value.length > maxLength) {
        callback(true)
        return
      }
    }
    callback()
  }

  numberValidator = (rule, value, callback) => {
    if (value) {
      const {minValue, maxValue, isDecimal, decimalPlaces} = this.props
      if (minValue > value) {
        callback(true)
        return
      }
      if (maxValue && maxValue < value) {
        callback(true)
        return
      }
      if (isDecimal !== false) {
        if (decimalPlaces && decimalPlaces > 0) {
          let strValue = value.toString().split('.')
          if (strValue[1].length > decimalPlaces) {
            callback(true)
            return
          }
        }
      } else {
        let strValue = value.toString().split('.')
        if (strValue.length > 1) {
          callback(true)
          return
        }
      }
    }
    callback()
  }

  fileValidator = (rule, value, callback) => {
    let fileTypeAccepted = this.props.fileProps && this.props.fileProps.accept
    let showMessage = undefined // value must undefined
    if (fileTypeAccepted) {
      // ex: '.doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx'
      fileTypeAccepted = fileTypeAccepted.replace(/\./g, '')
      if (value.length > 0) {
        for (const i in value) {
          const fileName = value[i].name
          const fileType = this.getFileType(fileName)
          const acceptSplited = fileTypeAccepted.split(',').filter(data => data)
          let typeAccepted = false
          for (const j in acceptSplited) {
            if (fileType === acceptSplited[j]) {
              typeAccepted = true
              break
            }
          }
          if (!typeAccepted) {
            showMessage = true
          }
        }
      }
    }
    callback(showMessage)
  }

  getFileType(fileName) {
    const fileNameSplited = fileName.split('.').filter(data => data)
    let fileType = fileNameSplited[fileNameSplited.length - 1]
    if (fileNameSplited.length === 1) {
      fileType = 'unknown'
    }
    return fileType
  }

  handleChange = (e) => {
    const {value} = e.target
    if (value) {
      this.hasStrip = true
      if (value.split('.').length > 1) {
        this.hasDot = true
      } else {
        this.hasDot = false
      }
    } else {
      this.hasDot = false
      this.hasStrip = false
    }
  }

  handleKeyDown = (e) => {
    if (this.hasDot && e.key === '.') {
      e.preventDefault()
    } else if (this.hasStrip && e.key === '-') {
      e.preventDefault()
    }
    if (e.key === '.') {
      this.hasDot = true
    } else if (e.key === '-') {
      this.hasStrip = true
    }
  }

  multipleValidator = (rule, value, callback) => {
    if (value && value.length > 0) {
      const { minSelected, maxSelected } = this.props
      if (minSelected && minSelected > value.length) {
        callback(true)
        return
      }
      if (maxSelected && maxSelected < value.length) {
        callback(true)
        return
      }
    }
    callback()
  }

  checkOperationView = (additional, disabledAdditional, toggleDrawerSQ, getFieldValue, title, data) => {
    return <Fragment>
      {additional && (additional.split('|')[0] === 'edit') &&
      <Button type={'button'} disabled={disabledAdditional && getFieldValue(Lib.decoratorName(title)) === undefined}
              onClick={() => toggleDrawerSQ('edit', data, getFieldValue(Lib.decoratorName(title)), title)} icon='edit'
              style={{marginRight: '10px'}}/>}
      {additional && ((additional.split('|')[0] === 'add') || (additional && additional.split('|')[1] === 'add')) &&
      <Button type={'button'} onClick={() => toggleDrawerSQ('add', data, null, title)} icon='plus'/>}
    </Fragment>
  };

  checkSpecialOperation = (toggleSQ) => {
    return <Fragment>
      <Button
        type={'danger'}
        icon={'close'}
        onClick={() => toggleSQ('add')}/>
    </Fragment>
  }

  getInput = (getFieldDecorator, getFieldValue) => {
    const {disabledAdditional} = this.state;

    const {
      type,
      title,
      data,
      dataIndex,
      required,
      note_text,
      dataRecord,
      operation,
      custom_rule,
      selectedKey,
      selectedValue,
      userReducer,
      additional,
      toggleDrawerSQ,
      hideOnEdit,
      isActive,
      isDisabled,
      multipleUpload,
      additionalChoices,
      additionalTitle,
      checkedAdditional,
      limit, // Upload
    } = this.props;

    const dataUpload = {
      // action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
      listType: 'picture',
      accept: imageAllowed,
      beforeUpload: () => false,
      multiple: multipleUpload,
    };

    const formItemLayout = {
      labelCol: {span: 7},
      wrapperCol: {span: 15},
    };

    const operationView = operation === 'view';

    switch (type) {
      case 'SelectQuery':
        return (
          <Col sm={24}>
            <FormItem span={24} label={title} extra={note_text} {...formItemLayout}>
              {getFieldDecorator(Lib.decoratorName(title), {
                rules: [{
                  required: required,
                  message: `Please Input ${title}!`
                }],
                initialValue: Lib.setSelectValue(dataRecord, selectedKey, selectedValue),
                valuePropName: 'value'
              })(
                <SelectQuery
                  urlKey={`${data}-autocomplete`}
                  placeholder=""
                  userReducer={userReducer}
                  suffixIcon={<Icon type="caret-down"/>}
                  valKey={selectedKey}
                  onChange={(e) => this.changeSQ(e)}
                  valLabel={selectedValue}
                  disabled={operationView || isDisabled}
                  isActive={isActive}
                />
              )}
            </FormItem>
          </Col>
        );
      case 'select_multiple':
        return (
          <Col sm={24}>
            <FormItem span={24} label={title} extra={note_text} {...formItemLayout}>
              {getFieldDecorator(Lib.decoratorName(title), {
                rules: [{
                  required: required,
                  message: `Please Input ${title}!`
                },{
                  validator: this.multipleValidator,
                  message: this.multipleMessage
                }],
                initialValue: Lib.setSelectValueMultiple(dataRecord, selectedKey, selectedValue),
              })(
                <SelectQuery
                  urlKey={data + '-autocomplete'}
                  mode="multiple"
                  showSearch
                  placeholder='Please type to search'
                  userReducer={userReducer}
                  suffixIcon={<Icon type="caret-down"/>}
                  onChange={this.changeSQ}
                  valKey={selectedKey}
                  valLabel={selectedValue}
                  disabled={operationView}
                  style={{marginRight: '10px'}}
                />
              )}
              {!operationView && this.checkOperationView(additional, disabledAdditional, toggleDrawerSQ, getFieldValue, title, data)}
            </FormItem>
          </Col>
        );
      case 'tag':
        return (
          <Col sm={24}>
            <FormItem span={24} label={title} extra={note_text} {...formItemLayout}>
              {getFieldDecorator(Lib.decoratorName(title), {
                rules: [{
                  required: required,
                  message: `Please Input ${title}!`
                }],
                initialValue: dataRecord,
              })(
                <Select
                  mode="tags"
                  style={{width: '100%', marginBottom: '10px'}}
                  tokenSeparators={[',']}
                  // onChange={value => this.handleSearch(value)}
                  dropdownStyle={{display: 'none'}}
                  placeholder={title}
                  // onDeselect={key => this.handleDeselect(key)}
                />
              )}
              {!operationView && this.checkOperationView(additional, disabledAdditional, toggleDrawerSQ, getFieldValue, title, data)}
            </FormItem>
          </Col>
        );
      case 'number':
        return (
          <Col sm={24}>
            <FormItem span={24} label={title} extra={note_text} {...formItemLayout}>
              {getFieldDecorator(Lib.decoratorName(title), {
                rules: [{
                  required: required,
                  message: `Please Input ${title}!`
                },
                {
                  validator: this.numberValidator,
                  message: this.numberMessage
                }
              ],
                initialValue: dataRecord,
              })(
                <Input
                  type="number"
                  disabled={operationView}
                  onKeyDown={this.handleKeyDown}
                  onChange={this.handleChange}
                  onBlur={e => {this.props.onBlur(e, dataIndex, type)}}/>)}
            </FormItem>
          </Col>
        );
      case 'email':
        return (
          <Col sm={24}>
            <FormItem span={24} label={title} extra={note_text} {...formItemLayout}>
              {getFieldDecorator(Lib.decoratorName(title), {
                rules: [{
                  required: required,
                  message: `Please Input ${title}!`
                }, {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                }, {
                  ...custom_rule // https://ant.design/components/form/#Validation-Rules
                }],
                initialValue: dataRecord,
              })(
                <Input
                  type="email"
                  disabled={operationView}
                  onBlur={e => this.props.onBlur(e, dataIndex, type)}/>)}
            </FormItem>
          </Col>
        );
      case 'url':
        return (
          <Col sm={24}>
            <FormItem span={24} label={title} extra={note_text} {...formItemLayout}>
              {getFieldDecorator(Lib.decoratorName(title), {
                rules: [{
                  required: required,
                  message: `Please Input ${title}!`
                }, {
                  type: 'url',
                  message: 'The input is not valid URL!',
                }, {
                  ...custom_rule // https://ant.design/components/form/#Validation-Rules
                }],
                initialValue: dataRecord,
              })(
                <Input
                  disabled={operationView}
                  onBlur={e => this.props.onBlur(e, dataIndex, type)}/>)}
            </FormItem>
          </Col>
        );
      case 'textarea':
        return (
          <Col sm={24}>
            <FormItem span={24} label={title} extra={note_text} {...formItemLayout}>
              {getFieldDecorator(Lib.decoratorName(title), {
                rules: [{
                  required: required,
                  message: `Please Input ${title}!`
                }, {
                  ...custom_rule // https://ant.design/components/form/#Validation-Rules
                }],
                initialValue: dataRecord,
              })(
                <TextArea
                  rows={4}
                  disabled={operationView}
                  onBlur={e => this.props.onBlur(e, dataIndex, type)}/>)}
            </FormItem>
          </Col>
        );
      case 'checkbox':
        return (
          <Col sm={24}>
            <FormItem span={24} label={title} extra={note_text} {...formItemLayout}>
              {getFieldDecorator(Lib.decoratorName(title), {
                rules: [{
                  required: required,
                  message: `Please Input ${title}!`
                }],
                initialValue: dataRecord === undefined ? this.props.initialValue === true : dataRecord,
                valuePropName: 'checked'
              })(
                <Switch
                  disabled={operationView}
                  onChange={e => this.props.onBlur(e, dataIndex, type)}/>)}
                {/* <Checkbox
                  disabled={operationView}
                  onChange={e => this.props.onBlur(e, dataIndex, type)}/>)} */}
            </FormItem>
          </Col>
        );
      case 'select':
        return (
          <Col sm={24}>
            <FormItem span={24} label={title} extra={note_text} {...formItemLayout}>
              {getFieldDecorator(Lib.decoratorName(title), {
                rules: [{
                  required: required,
                  message: `Please Input ${title}!`
                }],
                initialValue: dataRecord,
                validateTrigger: ["onChange", "onBlur"],
              })(<Select onChange={e => this.props.onBlur(e, dataIndex, type)} disabled={operationView}>
                {data.map(function (item, i) {
                  return <Option key={i} value={item.alias}>{item.value}</Option>
                })}
              </Select>)}
            </FormItem>
          </Col>
        );
      case 'datetime':
        return (
          <Col sm={24}>
            <FormItem span={24} label={title} extra={note_text} {...formItemLayout}>
              {getFieldDecorator(Lib.decoratorName(title), {
                rules: [{
                  required: required,
                  message: `Please Input ${title}!`
                }],
                initialValue: dataRecord && moment(dataRecord, 'YYYY-MM-DD HH:mm:ss'),
              })(
                <DatePicker
                  showTime
                  disabled={operationView || isDisabled}
                  onChange={e => this.props.onBlur(e, dataIndex, type)}/>)}
            </FormItem>
          </Col>
        );
      case 'date':
        return (
          <Col sm={24}>
            <FormItem span={12} label={title} extra={note_text} {...formItemLayout}>
              {getFieldDecorator(Lib.decoratorName(title), {
                rules: [{
                  required: required,
                  message: `Please Input ${title}!`
                }],
                initialValue: dataRecord && moment(dataRecord, 'YYYY-MM-DD'),
              })(
                <DatePicker
                  disabled={operationView}
                  onChange={e => this.props.onBlur(e, dataIndex, type)}/>)}
            </FormItem>
          </Col>
        );
      case 'file':
        return (
          <Col span={24}>
            <FormItem span={24} label={title} extra={note_text} {...formItemLayout}>
              {getFieldDecorator(Lib.decoratorName(title), {
                rules: [{
                  required: required,
                  message: `Please Input ${title}!`
                }, {
                  validator: this.fileValidator,
                  message: `Cannot save this file, please choose another file!`
                }],
                valuePropName: 'fileList',
                getValueFromEvent: (e) => this.props.normFile(e, limit),
                initialValue: dataRecord,
              })(
                <Upload {...this.props.fileProps} beforeUpload={() => false} disabled={operationView}>
                  <Button>
                    <Icon type="upload"/> Click to Upload
                  </Button>
                </Upload>
              )}
            </FormItem>
          </Col>
        );
      case 'fileImg':
        return (
          <Col sm={24}>
            {
              additionalChoices && checkedAdditional &&
              <FormItem span={24} label={additionalTitle} {...formItemLayout}>
                <Switch defaultChecked={false} onChange={this.props.handleCheckAdditional}/>
              </FormItem>
            }
            {
              (checkedAdditional || !additionalChoices) &&
              <FormItem span={24} label={title} extra={note_text} {...formItemLayout}>
                {getFieldDecorator(Lib.decoratorName(title), {
                  rules: [{
                    required: required,
                    message: `Please Input ${title}!`
                  }],
                  valuePropName: 'fileList',
                  getValueFromEvent: (e) => this.props.normFile(e, limit),
                  initialValue: dataRecord,
                })(
                  <Upload {...dataUpload} disabled={operationView || isDisabled}>
                    {
                      !(operationView || isDisabled) &&
                      <Button>
                        <Icon type="upload"/> Click to Upload
                      </Button>
                    }
                  </Upload>
                )}
              </FormItem>
            }
          </Col>
        );
      case "text":
        return (
          <Col sm={24}>
            {
              additionalChoices && !checkedAdditional &&
              <FormItem span={24} label={additionalTitle} {...formItemLayout}>
                <Switch defaultChecked onChange={this.props.handleCheckAdditional}/>
              </FormItem>
            }
            {
              (!checkedAdditional || !additionalChoices) &&
              <FormItem span={24} label={title} extra={note_text} {...formItemLayout}>
                {getFieldDecorator(Lib.decoratorName(title), {
                  rules: [{
                    required: required,
                    message: `Please Input ${title}!`
                  }, {
                    validator: this.textValidator,
                    message: this.textMessage
                  }],
                  initialValue: dataRecord,
                })(
                  <Input
                    disabled={operationView || isDisabled}
                    onBlur={e => this.props.onBlur(e, dataIndex, type)}/>
                )}
              </FormItem>
            }
          </Col>
        );
      case "table":
        return <Tableeditable {...this.props} />
      case "password":
        return (
          <Col sm={24}>
            <FormItem span={24} style={{display: (operation !== 'add' && hideOnEdit) && 'none'}} label={title}
                      extra={note_text} {...formItemLayout}>
              {getFieldDecorator(Lib.decoratorName(title), {
                rules: [{
                  required: required,
                  message: `Please Input ${title}!`
                }],
                initialValue: dataRecord,
              })(
                <Input
                  type="password"
                  disabled={(operationView || (operation === 'edit' && hideOnEdit))}
                  onBlur={e => this.props.onBlur(e, dataIndex, type)}/>)}
            </FormItem>
          </Col>
        );
      default:
        return (
          <Col sm={24}>
            <FormItem span={24} label={title} extra={note_text} {...formItemLayout}>
              {getFieldDecorator(Lib.decoratorName(title), {
                rules: [{
                  required: required,
                  message: `Please Input ${title}!`
                }, {
                  ...custom_rule // https://ant.design/components/form/#Validation-Rules
                }],
                initialValue: dataRecord,
              })(<Input disabled={operationView}/>)}
            </FormItem>
          </Col>
        )
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.dataRecord) {
      if (nextProps && nextProps.type === 'SelectQuery') {
        this.disableEdit()
      }
    }

    if (nextProps.dataRecord !== this.props.dataRecord) {
      if (nextProps.type === 'SelectQuery') {
        if (!nextProps.dataRecord) {
          this.disableEdit()
        }
      }
    }
  }

  disableEdit() {
    this.setState({
      disabledAdditional: true
    })
  }

  componentDidMount() {
    if (this.props.type === 'text') {
      if (this.props.maxLength > 0) {
        this.textMessage = `Cannot more than ${this.props.maxLength} characters`
      }
    } else if (this.props.type === 'number') {
      const minValue = (this.props.minValue || this.props.minValue === 0) ? `min integer value is ${this.props.minValue}, ` : ''
      const maxValue = this.props.maxValue && this.props.maxValue > 0 ? `max integer value is ${this.props.maxValue}, ` : ''
      let rangesValue = minValue + maxValue
      if (minValue && maxValue) {
        rangesValue = `Range of number is ${this.props.minValue} to ${this.props.maxValue}, `
      }
      const isDecimal = !this.props.isDecimal ? 'cannot decimal, ' : ''
      let decimalPlaces = ''
      if (this.props.isDecimal) {
        decimalPlaces = this.props.decimalPlaces && this.props.decimalPlaces > 0 ? `max decimal is ${this.props.decimalPlaces}, ` : ''
      }

      const tempMessage = (rangesValue + isDecimal + decimalPlaces).slice(0, -2)

      this.numberMessage = <span style={{fontSize: '12px'}}>
        Invalid Number
        {tempMessage ? ' or ' : ''}
        {tempMessage}
      </span>
    } else if (this.props.type === 'select_multiple') {
      const minSelected = this.props.minSelected && this.props.minSelected > 0 ? `minimun selected is ${this.props.minSelected}, ` : ''
      const maxSelected = this.props.maxSelected && this.props.maxSelected > 0 ? `maximum selected is ${this.props.maxSelected}, ` : ''
      let tempMessage = (minSelected + maxSelected).slice(0, -2)
      if (minSelected && maxSelected) {
        tempMessage = `Selected range ${this.props.minSelected} to ${this.props.maxSelected}`
      }
      this.multipleMessage = <span style={{fontSize: '12px'}}>
        {tempMessage}
      </span>
    }
  }

  render() {
    return (
      <FormContext.Consumer>
        {form => {
          const {getFieldDecorator, getFieldValue} = form;
          return (this.getInput(getFieldDecorator, getFieldValue))
        }}
      </FormContext.Consumer>
    );
  }
}

export default DynamicFieldHalfScreen

DynamicFieldHalfScreen.propTypes = {
  onBlur: PropTypes.func,
  dataIndex: PropTypes.string.isRequired,
  normFile: PropTypes.func,
}

DynamicFieldHalfScreen.defaultProps = {
  onBlur: () => null,
  dataIndex: 'name',
  normFile: () => null,
}
