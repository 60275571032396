export default [
  {
    title: 'Mode',
    type: 'SelectQuery',
    data: 'merchantMappingModes',
    initialValue: '',
    required: true,
    note_text: '',
    additional: 'edit|add',
    selectedKey: 'pk',
    selectedValue: 'mode',
    dataIndex: 'mode',
    order: 0,
  }, {
    title: 'Product code',
    type: 'text',
    data: '',
    required: true,
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'product_code',
    order: 1,
  }, {
    title: 'Sku',
    type: 'text',
    data: '',
    required: true,
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'sku',
    order: 2,
  },
]