import React from "react";
import NumberInfo from "ant-design-pro/lib/NumberInfo";
import moment from "moment";
import {ChartCard, MiniArea} from "ant-design-pro/lib/Charts";
import {Col} from "antd";
import PropTypes from 'prop-types';

class ChartMiniarea extends React.Component{
  render() {
    const {data, chartData} = this.props
    return(
      <Col span={24}>
      <ChartCard title={"Last modified by"} total={data.modified_by ? data.modified_by.username : 'Unknow'} contentHeight={134}>
        <NumberInfo
          subTitle={<span>{"Last modified at"}</span>}
          total={moment(data.modified).format('MM-DD-YYYY') }
          status="up"
          subTotal={17.1}
        />
        <MiniArea line height={45} data={chartData} />
      </ChartCard>
      </Col>
    )
  }
}
ChartMiniarea.propTypes = {
  data: PropTypes.object,
  chartData: PropTypes.array
}
export default ChartMiniarea;
