const groupBy = [
  // {
  //   label: 'Branch',
  //   key: 'origin_branch.name',
  //   dataIndex: 'origin_branch.name',
  // },
  // {
  //   label: 'Job Queue',
  //   key: 'job_queue.name',
  //   dataIndex: 'job_queue.name',
  // },
  {
    label: 'Assigned user',
    key: 'owner',
    dataIndex: 'owner',
    no_data: 'Unassigned'
  },
  {
    label: 'Due Date',
    key: 'instructed_finish_date',
    dataIndex: 'instructed_finish_date',
  },
  {
    label: 'Received date',
    key: 'received',
    dataIndex: 'received',
  },
  {
    label: 'Status',
    key: 'status',
    dataIndex: 'status',
    startcase: "true",
    // default: true,
  },
  {
    label: 'Grade',
    key: 'grade.level',
    dataIndex: 'grade.level',
  },
  {
    label: 'Last QA1',
    key: 'last_qa1.username',
    dataIndex: 'last_qa1.username',
  },
  {
    label: 'Last QA2',
    key: 'last_qa2.username',
    dataIndex: 'last_qa2.username',
  },
  {
    label: 'Last QS',
    key: 'last_qs.username',
    dataIndex: 'last_qs.username',
  },
  {
    label: 'Biztype',
    key: 'biztype',
    dataIndex: 'biztype',
    no_data: 'Job'
  },
]

export default groupBy
