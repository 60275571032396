export default [
  {
    title: 'Username',
    type: 'text',
    required: true,
    dataIndex: 'username',
    order: 0,
  }, {
    title: 'First Name',
    type: 'text',
    required: true,
    dataIndex: 'first_name',
    initialValue: '',
  }, {
    title: 'Last Name',
    type: 'text',
    dataIndex: 'last_name',
    initialValue: '',
  }, {
    title: 'Email',
    type: 'email',
    dataIndex: 'email',
    initialValue: '',
  }, {
    title: 'Home Branch',
    type: 'SelectQuery',
    required: true,
    dataIndex: 'home_branch',
    data: 'branches',
    selectedKey: 'pk',
    selectedValue: 'name',
  }, {
    title: 'Skill Level',
    type: 'number',
    dataIndex: 'skill_level',
    initialValue: 0,
  }, {
    title: 'Is Superuser',
    type: 'checkbox',
    dataIndex: 'is_superuser',
    initialValue: false,
    note_text: 'Designates that this user has all permissions.',
  }, {
    title: 'Is Staff',
    type: 'checkbox',
    dataIndex: 'is_staff',
    initialValue: false,
    note_text: 'Designates whether the user can log into IBES.',
  }, {
    title: 'Is Active',
    type: 'checkbox',
    dataIndex: 'is_active',
    initialValue: true,
  }, {
    title: 'Staff Position',
    type: 'SelectQuery',
    dataIndex: 'staff_position',
    data: 'staffPositions',
    selectedKey: 'pk',
    selectedValue: 'name',
  }, {
    title: 'Password',
    type: 'password',
    required: true,
    initialValue: '',
    dataIndex: 'password',
  }, {
    title: 'Re-type Password',
    type: 'retype-password',
    matching: 'password', // karena decorator di ambil dari title, gunakan title, atau langsung dari hasil Lib.decoratorName(title)
    initialValue: '',
    required: true,
  }, {
    title: 'Max Jobs',
    type: 'number',
    dataIndex: 'max_jobs',
    initialValue: 1,
  },
]