export default {
  "chart": {
    "type": "column"
  },
  "title": {
    "text": "Used in Line Template"
  },
  "accessibility": {
    "announceNewData": {
      "enabled": true
    }
  },
  "xAxis": {
    "type": "category"
  },
  "yAxis": {
    "title": {
      "text": "Total Used in Line Template"
    }
  },
  "legend": {
    "enabled": false
  },
  "plotOptions": {
    "series": {
      "borderWidth": 0,
      "dataLabels": {
        "enabled": true,
        "format": "{point.y}"
      }
    }
  },
  "tooltip": {
    "headerFormat": "",
    "pointFormat": "<span style=\"color:{point.color}\">Subsection: </span><b>{point.subsection}</b><br/><span style=\"color:{point.color}\">Line Template: </span><b>{point.name}</b><br/><span style=\"color:{point.color}\">Usage: </span> <b>{point.y}</b><br/>"
  },
  credits: {
    enabled: false
  },
}