const descriptorOffline = {
  "app_label": "jobs",
  "model_name": "jobstatustransition",
  "pagination": true,
  "columns": [
    {
      "name": "action_name",
      "label": "Action Name",
      "data_type": "str",
      "ellipsis": true,
      "search": true,
      "sort": true
    },
    {
      "name": "from_status",
      "label": "From Status",
      "data_type": "str",
      "ellipsis": true,
      "search": true,
      "sort": true
    },
    {
      "name": "to_status",
      "label": "To Status",
      "data_type": "str",
      "ellipsis": true,
      "search": true,
      "sort": true
    },
    {
      "name": "need_assign_user",
      "label": "Need Assign User",
      "data_type": "bool",
      "sort": true,
    },
  ],
  "filter_by": [],
  "export_import": null
}

export default descriptorOffline