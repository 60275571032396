const salesData = [];
for (let i = 0; i < 12; i += 1) {
	salesData.push({
		x: `${i + 1}`,
		y: Math.floor(Math.random() * 10) + 20
	});
}

const chartData = [];
for (let i = 0; i <= 30; i++) {
	chartData.push({
		x: new Date().getTime() + 1000 * 60 * 30 * i,
		y1: 0 + Math.random() * (12 - 0) | 0,
		y2: 13 + Math.random() * (23 - 13) | 0
	});
}

export { salesData, chartData };
