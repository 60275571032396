import React from 'react'
import PropTypes from 'prop-types'
import { Card, Affix, Button, message } from 'antd'
import { get } from 'lodash'
import CustomTransfer from 'App/Screens/SubModules/UserGroup/User/Management/Component/CustomTransfer'
import { RequestV2 as Request } from 'App/Utils'
import WarningLabel from "App/Screens/SubModules/UserGroup/Group/Management/Pages/WarningLabel"
import ErrorLabel from 'App/Screens/SubModules/UserGroup/Group/Management/Pages/ErrorLabel'

class PageMerchants extends React.PureComponent {
  constructor(props) {
    super(props)

    this.merchantTitles = ['Available Allowed Merchants', 'Chosen Allowed Merchants']
    this.state = {
      isSaving: false,
      errorMessage: '',
    }
  }

  onFieldTouched = (isFieldsTouched) => {
    this.setState({ errorMessage: '' }, () => {
      this.props.onFieldTouched(isFieldsTouched)
    })
  }

  onSave = () => {
    if (get(this._merchant, 'validateFields')) {
      const merchantAccess = this._merchant.validateFields()
      this.setState({ isSaving: true }, () => {
        Request(
          'patch',
          'module-groupUser-user-update',
          {},
          { merchant_access: merchantAccess },
          [get(this.props.user, 'pk')],
          this.saveSuccess,
          this.saveFailed,
        )
      }) 
    }
  }

  saveSuccess = (response) => {
    message.success('Access "Merchants" has been saved')
    this.setState({ isSaving: false }, () => {
      this.props.setUser(response.data)
    })
  }

  saveFailed = () => {
    message.error('Failed to save "Merchants"')
    this.setState({ isSaving: false })
  }

  validateFields = () => {
    return this._merchant.validateFields()
  }

  setError = (objError) => {
    if (typeof objError === 'object') {
      for (const key in objError) {
        if (key === 'merchant_access') {
          this.setState({ errorMessage: objError[key] })
        }
      }
    }
  }

  render() {
    const { readOnly, userHelper, user } = this.props
    const { errorMessage } = this.state

    return (
      <div>
        {errorMessage && <ErrorLabel errorMessage={errorMessage} />}
        <WarningLabel tabName='Merchants' menu='User' groupName={get(user, 'username', 'this page')} />
        <Card>
          <div>
            <CustomTransfer
              ref={ref => {this._merchant = ref}}
              readOnly={readOnly}
              titles={this.merchantTitles}
              dataSource={get(userHelper, 'merchant', [])}
              initialValue={get(user, 'merchant_access', [])}
              setTouched={this.onFieldTouched}
            />
          </div>
        </Card>
      </div>
    )
  }
}

PageMerchants.propTypes = {
  readOnly: PropTypes.bool,
  userHelper: PropTypes.object,
  user: PropTypes.object,
  onFieldTouched: PropTypes.func,
}

PageMerchants.defaultProps = {
  readOnly: false,
  userHelper: null,
  user: null,
  onFieldTouched: () => null,
}

export default PageMerchants