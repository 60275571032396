export default [
  {
    title: 'Merchant',
    // required: true,
    type: 'SelectQuery',
    data: 'merchants',
    dataIndex: 'merchant',
    initialValue: '',
    note_text: '',
    additional: 'edit|add',
    selectedKey: 'pk',
    selectedValue: 'name'
  }, {
    title: 'Subheading',
    type: 'SelectQuery',
    data: 'subheadings',
    dataIndex: 'subheading',
    initialValue: '',
    note_text: '',
    additional: 'edit|add',
    selectedKey: 'pk',
    selectedValue: 'label',
  }, {
    title: 'Product',
    type: 'SelectQuery',
    data: 'products',
    dataIndex: 'product',
    initialValue: '',
    note_text: '',
    additional: 'edit|add',
    selectedKey: 'pk',
    selectedValue: 'name',
  }, {
    title: 'Usage',
    type: 'SelectQuery',
    data: 'lineUsages',
    dataIndex: 'usage',
    initialValue: '',
    note_text: '',
    additional: 'edit|add',
    selectedKey: 'pk',
    selectedValue: 'label',
  }, {
    title: 'Name',
    type: 'text',
    data: '',
    dataIndex: 'name',
    initialValue: '',
    note_text: '',
    additional: ''
  }, {
    title: 'Drop',
    type: 'checkbox',
    data: '',
    dataIndex: 'drop',
    initialValue: '',
    note_text: '',
    additional: ''
  }, {
    title: 'Old sequence',
    type: 'text',
    data: '',
    dataIndex: 'old_sequence',
    initialValue: '',
    note_text: 'legacy, only used for import',
    additional: ''
  }, {
    title: 'Status',
    type: 'select',
    disableAllowClear: true,
    data: [
      {
        alias: 'active',
        value: 'Active - Anyone can use'
      }, {
        alias: 'inactive',
        value: 'Inactive - No one can use'
      }, {
        alias: 'unpublished',
        value: 'Unpublished - Only certain people can use'
      }
    ],
    dataIndex: 'status',
    initialValue: 'active',
    note_text: '',
    additional: ''
  }, {
    title: 'Is random mapping',
    type: 'checkbox',
    data: '',
    dataIndex: 'is_random_mapping',
    initialValue: '',
    note_text: 'whether this is for random or export mapping',
    additional: ''
  }, {
    title: 'Is random mapping roof',
    type: 'checkbox',
    data: '',
    dataIndex: 'is_random_mapping_roof',
    initialValue: '',
    note_text: '',
    additional: ''
  }, {
    title: 'Is random mapping frame',
    type: 'checkbox',
    data: '',
    dataIndex: 'is_random_mapping_frame',
    initialValue: '',
    note_text: '',
    additional: ''
  }, {
    title: 'Is carters random mapping',
    type: 'checkbox',
    data: '',
    dataIndex: 'is_carters_random_mapping',
    initialValue: '',
    note_text: '',
    additional: ''
  }, {
    title: 'Is bulk mapping',
    type: 'checkbox',
    data: '',
    dataIndex: 'is_bulk_mapping',
    initialValue: '',
    note_text: '',
    additional: ''
  }, {
    title: 'Import session',
    type: 'text',
    data: '',
    dataIndex: 'import_session',
    initialValue: '',
    note_text: '',
    additional: ''
  },
]
