import React, {Component} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  Row, Col, Button,
} from 'antd';
import bg404 from 'App/Assets/img/404.svg'
import { startCase } from 'lodash'

export default class Page404 extends Component {

  constructor(props) {
    super(props)
    this.gotoMaster = this.gotoMaster.bind(this)
  }

  gotoMaster() {
    const { urlLink } = this.props
    const masterUrl = urlLink ? urlLink : this.getUrl(window.location.pathname, 2)
    // dont use window to push/replace/pop
    this.props.history.replace(masterUrl)
  }

  getMasterName(path) {
    // 0 = app, 1 = master, 2 = id
    const arrPath = path.split('/').filter(name => name)
    return arrPath[1]
  }

  // stackUrl: 1 = domain/app, 2 = domain/app/master, 3 = domain/app/master/id
  getUrl(path, stackUrl = 1) {
    const arrPath = path.split('/').filter(name => name)
    if (stackUrl <= arrPath.length) {
      let url = ''
      for (let i = 0; i < stackUrl; i++) {
        url += '/' + arrPath[i]
      }
      return url
    }
    return undefined
  }

  // componentDidMount() {
  //   document.body.classList.add('body-fixed')
  // }

  // componentWillUnmount() {
  //   document.body.classList.remove('body-fixed')
  // }

  render() {
    const { strLink } = this.props
    let masterName = startCase(this.getMasterName(window.location.pathname))
    if (strLink) {
      masterName = strLink
    }
    return (
      <StyledRow type={"flex"} justify={"center"}>
        <StyledCol span={12}>
          <StyledImg src={bg404}/>
        </StyledCol>
        <StyledCol span={12}>
          <StyledTitle style={{'marginTop': '30px'}}>Sorry :(</StyledTitle>
          <StyledSubTitle>Your data request is not found</StyledSubTitle>
          <Button type="primary" size="large" onClick={this.gotoMaster}>Go to {masterName}</Button>
        </StyledCol>
      </StyledRow>
    )
  }
}

Page404.propTypes = {
  history: PropTypes.object.isRequired,
  urlLink: PropTypes.string,
  masterName: PropTypes.string,
}

const StyledRow = styled(Row)`
  background: #f0f2f5;
  height: 80vh;
`
const StyledCol = styled(Col)`
  margin-top: 100px
  text-align:center;
`
const StyledImg = styled.img`
  height: 310px;
  margin-bottom:-100px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
`
const StyledTitle = styled.h1`
  font-size: 3.8em
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.3em;
  width: 70%;
`
const StyledSubTitle = styled.p`
  font-size: 22px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
`