import React from "react";
import { Form } from "antd";

const FormContext = React.createContext();

const FormRow = ({ form, index, ...props}) => {
  return(
    <FormContext.Provider value={form}>
      <span {...props} />
    </FormContext.Provider>
  )
};
const FormProvider = Form.create()(FormRow);
export {FormContext, FormProvider}