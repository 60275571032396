import SelectQuery from "./SelectQuery";
import AppPagination from "./Pagination";
import Empty from "./Empty";
import TemplateTable from "./TemplateTable";
import PageLoading from "./PageLoading";
import PageNotFound from "./PageNotFound";
import SubAllScreen from "./SubAllScreen";
import LoadingSpin from "./LoadingSpin";

export {
  SelectQuery,
  AppPagination,
  Empty,
  TemplateTable,
  PageLoading,
  PageNotFound,
  SubAllScreen,
  LoadingSpin,
};
