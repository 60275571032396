import React from "react";
import { Button } from "antd";
import { BaseList, BaseDetail } from "App/Component/Bases";
import ComponentAutocomplete from 'App/Component/Autocomplete'
import fields from "./fields";
import descriptor from "./descriptor";
import options from "./options";
import OrderManagement from 'App/Component/OrderManagement'
import { forcePermissions } from "App/Data";

const title = "Prefill Sets";
const urlData = "prefillSets";
const bulkName = "name";
const availablePermissions = ['.view_', '.add_', '.change_'];
const children = {
  appName: 'estimation',
  modelName: 'prefillline',
  name: 'prefillSets',
  sub: 'prefillLines',
  dataIndex: 'prefill_lines',
  identifier: {
    name: 'prefill_set',
    is_array: false
  },
  bulk_request: true,
  urlSub: 'api:admin:estimation:prefillset:ref-prefill-lines-list',
  add: true,
  edit: true,
  data: false,
  permissions: {
    bulk_delete: true,
    payload: true,
    bulk_url: 'api:admin:estimation:prefillline:list',
    initialrow: 1,
    add: true
  },
  filter_selected: { subsection: ['line_template'] },
  fieldOrder: [{ key: 'line_template', order: 'modified' }], //unused
  rules: {
    button: {
      type: {
        add: 'default',
        edit: 'default'
      },
      size: 'default'
    },
    input: {
      size: 'default'
    },
    select: {
      size: 'default'
    }
  },
  fields: ComponentAutocomplete['prefillLines']
};

class List extends BaseList {
  constructor(props) {
    super(props)

    this.title = title;
    this.urlData = urlData;
    this.bulkName = bulkName;
    this.availablePermissions = availablePermissions;
    this.propsList = {
      descriptor,
      exportImport: options.exportImportOptions,
      getAnotherActions: () => (
        <Button icon='ordered-list' onClick={() => this.props.history.push('/admin/estimation/prefill-sets/order-management')}>
          Order Management
        </Button>
      )
    }
    this.state = {
      ...this.state,
      currentPermissionSet: this.isSuperuser ? this.filterPermissionItems(forcePermissions) : [],
    }
  }


}

class Detail extends BaseDetail {
  constructor(props) {
    super(props)

    this.title = title
    this.urlData = urlData
    this.bulkName = bulkName
    this.fields = fields
    this.children = children
    this.availablePermissions = availablePermissions
    this.propsDetail = {
      urlKeyHistory: 'api:admin:estimation:prefillset:detail-revision',
      keyLabel: 'name'
    }
    this.state = {
      ...this.state,
      currentPermissionSet: this.isSuperuser ? this.filterPermissionItems(forcePermissions) : [],
    }
  }
}

class PermissionOrderManagement extends BaseDetail {
  getCodePart = () => '.change_'

  getAllowedComponent = () => (
    <PrefillSetOrderManagement {...this.props} />
  )
}

class PrefillSetOrderManagement extends OrderManagement {
  constructor(props) {
    super(props)

    this.title = 'Prefill Sets Order Management'
    this.urlKeySave = 'api:admin:estimation:prefillset:manage-order'
    this.propsCardContent = {
      urlKeyListOrder: 'api:admin:estimation:subsection:ref-prefill-sets-list',
    }
  }
}

export default { List, Detail, OrderManagement: PermissionOrderManagement };
