import React, { PureComponent } from "react";
import qs from "querystring";
import { Row, Col, Form, Icon, Input, Button, Checkbox, Modal, Result, message } from "antd";
import { get } from "lodash";
import styled from "styled-components";
import bg from "../../../Assets/img/bg-p.png";

import { Sites } from "App/Config";
import { Action } from "App/Redux";
import { Request } from "App/Utils";
import IBESLogo from "../../../Assets/img/NewIBES-Logo-Dark.png";

class Login extends PureComponent {
  constructor(props) {
    super(props);
    // this.checkSession(props);
    this.state = {
      validateStatus: undefined,
      isLoading: false,
      isForgotPassword: false
    };
    this.onLoginSuccess = this.onLoginSuccess.bind(this);
    this.onLoginFailed = this.onLoginFailed.bind(this);
  }

  checkSession(props) {
    const { userReducer, history } = props;
    if (userReducer.isAuth) {
      history.replace("/");
    }
    return;
  }

  changeStatus(validateStatus, isLoading) {
    this.setState({ validateStatus, isLoading });
  }

  onLogin(e) {
    e.preventDefault();
    const { form, runRedux } = this.props;
    form.validateFields((err, values) => {
      if (!err) {
        this.changeStatus("validating", true);
        const payload = {
          client_id: Sites.oauth.id,
          client_secret: Sites.oauth.secret,
          grant_type: "password",
          username: values.username,
          password: values.password
        };
        runRedux(Action.ToggleAuthSessionProperties(true));
        Request(
          "post",
          "user-auth",
          { "Content-Type": "application/x-www-form-urlencoded" },
          qs.stringify(payload),
          [],
          this.onLoginSuccess,
          this.onLoginFailed,
          payload
        );
      }
    });
  }

  onLoginSuccess(response, extra) {
    response.data.username = extra.username;
    this.changeStatus("validating", false);
    this.props.runRedux(Action.CreateUserSessionProperties(response.data));
    this.requestMe(response.data);
    // this.props.history.replace("/admin/dashboard", {after_login: true});
  }

  requestMe = (dataLoginSuccess, callback = () => null) => {
    Request(
      "get",
      "read-me",
      { Authorization: `Bearer ${get(dataLoginSuccess, "access_token")}` },
      {},
      [],
      this.requestMeSuccess,
      this.requestMeFailed,
      { callback, data: dataLoginSuccess }
    );
  };

  requestMeSuccess = (response, extra) => {
    extra.callback(true);
    this.props.runRedux(Action.UpdateUserInformation(response.data));
    this.props.history.replace("/");
  };

  requestMeFailed = (error, extra) => {
    message.error("Failed to collect user information");
    extra.callback();
    if (!this.isConfirm) {
      this.isConfirm = true;
      Modal.confirm({
        title: "Failed to fetch",
        content: "Error when collecting data",
        cancelButtonProps: { disabled: true },
        okText: "Try again",
        onOk: () =>
          new Promise((resolve, reject) => {
            this.requestMe(extra.data, isSuccess => {
              if (isSuccess) {
                resolve();
              } else {
                reject();
              }
            });
          })
      });
    }
  };

  onLoginFailed(err) {
    const { runRedux } = this.props;
    this.changeStatus("error", false);
    if (err.response.data.error === "invalid_grant") {
      if (err.response.data.error_description === "Invalid credentials given.") {
        message.error("Wrong Username or Password!", 3);
      } else {
        message.error(err.response.data.error_description, 3);
        if (err.response.data.error_description === 'You do not have authority to access this application.') {
          this.setState({ visibleDrawer: true })
        }
      }
    } else if (err.response.data.error) {
      message.error('Error message: ' + err.response.data.error)
    } else {
      message.error('Error message: ' + err.response.data.detail)
    }
    runRedux(Action.ToggleAuthSessionProperties(false));
  }

  onCloseDrawer = () => {
    this.setVisibleDrawer(false)
  }

  setVisibleDrawer = (visibleDrawer, callback = () => null) => {
    this.setState({ visibleDrawer }, callback)
  }

  onForgot(e) {
    e.preventDefault();
    this.setState({ isForgotPassword: true });
  }

  onBackToLogin = () => {
    this.setState({ isForgotPassword: false });
  };

  onSubmitForgot = e => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFieldsAndScroll(
      { scroll: { offsetTop: 150 } },
      (error, values) => {
        if (!error) {
          this.setState({ isLoading: true }, () => {
            Request(
              "post",
              "forgotPassword",
              {},
              {
                ...values,
                client_id: Sites.oauth.id,
                client_secret: Sites.oauth.secret,
              },
              [],
              this.onForgotSuccess,
              this.onForgotFailed
            );
          });
        }
      }
    );
  };

  onForgotSuccess = () => {
    message.success("An email have been sent to the email address, please check the inbox/spam.", 7);
    this.setState({ isLoading: false, isForgotPassword: false });
  };

  onForgotFailed = error => {
    message.error("Failed when connecting to server");
    const userErrors = get(error, ["response", "data", "user"]);
    if (userErrors) {
      const { form } = this.props;
      const userValue = form.getFieldValue("user");
      form.setFields({
        user: {
          value: userValue,
          errors: [new Error(userErrors[0])]
        }
      });
    }
    this.setState({ isLoading: false });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { visibleDrawer } = this.state

    return (
      <Container>
        <StyledRow type={"flex"} align={"middle"} justify={"center"}>
          <Col lg={18} xl={14} xxl={12}>
            <LoginPanel type="flex" justify="space-around" align="middle">
              <StyledColLogin xs={22} sm={24} md={24} lg={12}>
                {this.state.isForgotPassword ? (
                  <StyledForm onSubmit={this.onSubmitForgot}>
                    <StyledTitle>
                      <Icon
                        type="caret-left"
                        theme="filled"
                        title="Back to login"
                        style={{
                          fontSize: "24px",
                          color: "#1990ff",
                          marginRight: "8px",
                          cursor: "pointer"
                        }}
                        onClick={this.onBackToLogin}
                      />
                      Forgot Password
                    </StyledTitle>
                    <StyledSubTitle>
                      Enter an username or email address where we can contact
                      you later
                    </StyledSubTitle>
                    <Form.Item>
                      {getFieldDecorator("user", {
                        rules: [
                          {
                            required: true,
                            message: "Please input username or email!"
                          }
                        ]
                      })(
                        <Input
                          ref={ref => {
                            ref.input.focus();
                          }}
                          prefix={
                            <Icon
                              type="user"
                              style={{ color: "rgba(0,0,0,.25)" }}
                            />
                          }
                          placeholder="Username or Email"
                        />
                      )}
                    </Form.Item>
                    <Form.Item>
                      <StyledButton
                        type="primary"
                        htmlType="submit"
                        loading={this.state.isLoading}
                      >
                        Next
                      </StyledButton>
                    </Form.Item>
                  </StyledForm>
                ) : (
                  <StyledForm onSubmit={e => this.onLogin(e)}>
                    <StyledTitle>Login to IBES</StyledTitle>
                    <StyledSubTitle>
                      Integrated Building Estimating System
                    </StyledSubTitle>
                    <Form.Item>
                      {getFieldDecorator("username", {
                        rules: [
                          {
                            required: true,
                            message: "Please input your username!"
                          }
                        ]
                      })(
                        <Input
                          ref={ref => {
                            ref.input.focus();
                          }}
                          prefix={
                            <Icon
                              type="user"
                              style={{ color: "rgba(0,0,0,.25)" }}
                            />
                          }
                          placeholder="Username"
                        />
                      )}
                    </Form.Item>
                    <Form.Item>
                      {getFieldDecorator("password", {
                        rules: [
                          {
                            required: true,
                            message: "Please input your Password!"
                          }
                        ]
                      })(
                        <Input.Password
                          prefix={
                            <Icon
                              type="lock"
                              style={{ color: "rgba(0,0,0,.25)" }}
                            />
                          }
                          type="password"
                          placeholder="Password"
                        />
                      )}
                    </Form.Item>
                    <Form.Item>
                      {getFieldDecorator("remember", {
                        valuePropName: "checked",
                        initialValue: true
                      })(<Checkbox>Remember me</Checkbox>)}
                      <StyledForgot href="#" onClick={e => this.onForgot(e)}>
                        Forgot password
                      </StyledForgot>
                      <StyledButton
                        type="primary"
                        htmlType="submit"
                        loading={this.state.isLoading}
                      >
                        Log in
                      </StyledButton>
                    </Form.Item>
                  </StyledForm>
                )}
              </StyledColLogin>
              <StyledColCenter xs={0} sm={0} md={0} lg={12}>
                <img className="text-center" style={{width: '50%'}} src={IBESLogo} />
                <Welcome>Hi, welcome to IBES Master</Welcome>
                <p>Please login to your account.</p>
              </StyledColCenter>
            </LoginPanel>
          </Col>
          <Modal visible={visibleDrawer} footer={null} onCancel={this.onCloseDrawer}>
            <Result
              status="warning"
              title="You do not have authority to access this application."
              extra={
                <Button
                  ref={ref => {console.log('ref', ref)}}
                  type="primary"
                  onClick={this.onCloseDrawer}
                >
                  OK
                </Button>
              }
            />
          </Modal>
        </StyledRow>
      </Container>
    );
  }
}

export default Form.create()(Login);

const Container = styled.div`
  background: #1c293d;
`;
const StyledForm = styled(Form)`
  max-width: 400px;
`;
const StyledTitle = styled.h3`
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 0;
  color: #1990ff;
`;
const StyledSubTitle = styled.p`
  margin-bottom: 2rem;
`;
const StyledRow = styled(Row)`
  height: 100vh;
`;
const LoginPanel = styled(Row)`
  background-image: url(${bg});
  border-radius: 4px;

  @media (max-width: 700px) {
    background: none;
  }
`;
const StyledColLogin = styled(Col)`
  background: #ffffff;
  padding: 3.4rem;
  border-radius: 4px;
`;
const StyledColCenter = styled(Col)`
  text-align: center;
`;
const Welcome = styled.h3`
  font-size: 2em;
  color: #1990ff;
  font-weight: bold;
  margin: 0;
`;
const StyledForgot = styled.a`
  float: right;
`;
const StyledButton = styled(Button)`
  width: 100%;
`;
