const all = {
  label: 'All',
}

const specific_user = {
  fieldKey: 'users',
  label: 'Users',
  urlKey: 'module-groupUser-user-autocomplete',
  listItem: {
    key: 'username',
    params: { is_active: true, is_staff: true },
  }
}

const group = {
  fieldKey: 'groups',
  label: 'Groups',
  urlKey: 'module-groupUser-group-autocomplete',
  listItem: {
    key: 'name',
  }
}

const branch = {
  fieldKey: 'branches',
  label: 'Branches',
  urlKey: 'module-branch-autocomplete',
  listItem: {
    key: 'name',
  }
}

export default {
  all,
  specific_user,
  group,
  branch,
}