import estimationUrl from 'App/Screens/Estimation/urlFe'
import geoUrl from 'App/Screens/Geo/urlFe'
import jobUrl from 'App/Screens/Job/urlFe'
import erfUrl from 'App/Screens/ERF/urlFe'
import multisiteUrl from 'App/Screens/Multisite/urlFe'
import notificationUrl from 'App/Screens/Notification/urlFe'
import reportUrl from 'App/Screens/Report/urlFe'
import userUrl from 'App/Screens/Users/urlFe'

export default {
  ...estimationUrl,
  ...geoUrl,
  ...jobUrl,
  ...erfUrl,
  ...multisiteUrl,
  ...notificationUrl,
  ...reportUrl,
  ...userUrl,
}