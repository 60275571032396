export default [
  {
    title: 'Name',
    required: true,
    type: 'text',
    dataIndex: 'name',
    data: '',
    initialValue: '',
    note_text: 'A semi-descriptive name, e.g. QuantityE2, E2Number, Cladding.',
    additional: '',
    order: 0,
  }, {
    title: 'Abbreviated',
    type: 'text',
    required: true,
    data: '',
    dataIndex: 'abbreviated',
    initialValue: '',
    note_text: 'Abbr. version of the formula, e.g. 2xHxE2/WxF. Please follow Format symbol in Abbreviated Information.',
    additional: '',
    order: 1,
  }, {
    title: 'Description',
    type: 'text',
    data: '',
    dataIndex: 'description',
    initialValue: '',
    note_text: '',
    additional: '',
    order: 2,
  }, {
    title: 'Method name',
    required: true,
    dataIndex: 'method_name',
    type: 'textarea',
    data: '',
    initialValue: '',
    note_text: 'Corresponding Python script/method name to be invoked, relative to estimator.estimation.formulas.',
    additional: '',
    order: 3,
  },
]
