import React from 'react'
import PropTypes from 'prop-types'
import { Input } from 'antd'
import { get } from 'lodash'
import getError from './getError'

const ERR_STYLE = { border: '1px solid #f5222d', borderRadius: '4px', color: '#f5222d' }

class BasicInput extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      error: null,
      isEditing: props.autoFocus,
      value: props.value,
    }
  }

  getPropsComp = () => ({
    ref: this.onInputRef,
    defaultValue: this.props.value,
    onBlur: this.onBlur,
    onKeyDown: this.onKeyDown,
    onChange: this.onChange,
    size: 'small',
    ...this.props.inputProps
  })

  getInputComp = () => <Input {...this.getPropsComp()} />

  onInputRef = (ref) => {
    this._ref = ref
    if (ref) {
      ref.input.focus()
    }
  }

  onContainerClick = () => {
    if (!this.state.isEditing) {
      this.setState({ isEditing: true })
    }
  }

  onCancel = () => {
    this.props.onCancel(() => {
      this.setState({ isEditing: false, value: this.props.value })
    })
  }

  onBlur = () => {
    this.onPressEnter(this.state.value)
  }

  onChange = (e) => {
    this.setState({ value: e.target.value })
  }

  onKeyDown = (e) => {
    switch (e.keyCode) {
      case 13: {
        // enter
        this.onPressEnter(e.target.value)
        break
      }
      case 27: {
        // escape
        this.onCancel()
        break
      }
      default: break;
    }
  }

  onPressEnter = (value) => {
    this.setState({ error: null }, () => {
      if (`${this.state.value}` === `${this.props.value}`) this.onCancel()
      else this.props.onPressEnter(value, this.onCancel)
    })
  }

  // errMessage: string | string array
  // parent access with ref
  setError = (errMessage) => {
    this.setState({ error: getError(errMessage) })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({ value: nextProps.value })
    }
  }

  render() {
    const { containerProps } = this.props
    const { error, isEditing, value } = this.state
    const style = error ? ERR_STYLE : null

    return (
      <>
        <div
          onClick={this.onContainerClick}
          title={value}
          {...containerProps}
          style={{ ...get(containerProps, 'style'), ...style }}
        >
          {isEditing ? (
            this.getInputComp()
          ) : (
            (value === null || value === undefined)
            ? <span style={{ fontSize: '12px', fontStyle: 'italic', fontWeight: 400 }}>{get(this.props, 'blankField')}</span>
            : value
          )}
        </div>
        {!!error && <div style={{ color: '#f5222d' }}>{error.message}</div>}
      </>
    )
  }
}

BasicInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  autoFocus: PropTypes.bool,
  containerProps: PropTypes.object,
  blankField: PropTypes.string,
  onPressEnter: PropTypes.func,
  onCancel: PropTypes.func,
}

BasicInput.defaultProps = {
  autoFocus: false,
  blankField: '-',
  onPressEnter: (value, callback) => callback(),
  onCancel: (callback) => callback(),
}

export default BasicInput