import React from 'react';

import { BaseDetail } from 'App/Screens/SubModules/Component';
import fields from './fields';

class PageDetail extends BaseDetail {
  constructor(props) {
    super(props)

    this.urlKey = 'module-job-consistencyNotes-detail'
    this.title = 'Consistency Notes'
    this.urlList = '/job-management/notes'
    this.headerDescriptions = [
      { key: 'merchant.name', label: 'Merchant' },
      { key: 'subsection.name', label: 'Subsection' },
      { key: 'title', label: 'Title' },
      { key: 'note', label: 'Note', render: (value) => <span className="webkit-box two-lines custom-markdown-sm" dangerouslySetInnerHTML={{ __html: value }} /> },
    ]

    this.fields = fields
  }
}

export default PageDetail