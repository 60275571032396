import React from "react";
import PropTypes from "prop-types";
import { Card, Tabs } from "antd";
import { get } from 'lodash'
import Basic from "./Basic";
import CommonPermission from "./CommonPermission";
import WarningLabel from "App/Screens/SubModules/UserGroup/Group/Management/Pages/WarningLabel"
import ErrorLabel from "App/Screens/SubModules/UserGroup/Group/Management/Pages/ErrorLabel";

const { TabPane } = Tabs;

class Profile extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      activeTabKey: 'general',
      errorMessage: '',
    }
  }

  onTabChange = (activeTabKey) => {
    this.setState({ activeTabKey })
  }

  validateFields = async () => {
    const basicData = await this._basic.validateFields()
    const common = this._commonPermission.validateFields()
    return { ...basicData, ...common }
  }

  validateError = (error) => {
    this.props.setActiveKey(this.props.tabKey, () => {
      // Karena validate error hanya akan di tab general, maka:
      this.onTabChange('general')
      // ------------------------------------------------------
    })
  }

  // For parent with ref access
  setErrors = (fieldsError) => {
    if (fieldsError && typeof fieldsError === 'object') {
      // belum
    }
  }

  render() {
    const p = this.props
    const { activeTabKey, errorMessage } = this.state

    return (
      <>
        {errorMessage && <ErrorLabel errorMessage={errorMessage} />}
        <WarningLabel
          tabName='Profile'
          menu='User'
          groupName={p.user.username}
        />
        <Card className="animated fadeIn">
          <Tabs tabPosition="left" className="um-profile-tabs" activeKey={activeTabKey} onChange={this.onTabChange}>
            <TabPane forceRender key="general" tab="General">
              <Basic {...p} ref={ref => {this._basic = ref}} onFieldTouched={p.onFieldTouched} hasErrors={this.validateError} />
            </TabPane>
            <TabPane forceRender key="permission" tab="Permission">
              <CommonPermission {...p} ref={ref => {this._commonPermission = ref}} onFieldTouched={p.onFieldTouched} />
            </TabPane>
          </Tabs>
        </Card>
      </>
    )
  }
}

Profile.propTypes = {
  tabKey: PropTypes.string,
  onFieldTouched: PropTypes.func,
  setActiveKey: PropTypes.func,
}

Profile.defaultProps = {
  tabKey: null,
  onFieldTouched: () => null,
  setActiveKey: (tabKey, callback = () => null) => callback(),
}

export default Profile