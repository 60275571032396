export default {
  "app_label": "users",
  "model_name": "staffleave",
  "pagination": true,
  "columns": [
    {
      "name": "pk",
      "label": "ID",
      "help_text": null,
      "hidden": true,
      "identifier": false,
      "data_type": "int",
      "search": false,
      "sort": false
    },
    {
      "name": "user",
      "label": "User",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": null,
      "child_path": "username",
      "search": false,
      "sort": false
    },
    {
      "name": "start",
      "label": "Start",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "date",
      "search": false,
      "sort": true
    },
    {
      "name": "end",
      "label": "End",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "date",
      "search": false,
      "sort": true
    },
    {
      "name": "leave_type",
      "label": "Leave type",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": null,
      "search": true,
      "sort": true
    },
    {
      "name": "modified",
      "label": "Modified",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "datetime",
      "search": false,
      "sort": true
    }
  ],
  "filter_by": [
    {
      "label": "User",
      "name": "user",
      "type": "api_reference",
      "api": {
        "url_name": "api:admin:users:user:autocomplete",
        "key": "pk",
        "display": "username",
        "pagination": true
      }
    },
    {
      "label": "Leave Type",
      "name": "leave_type",
      "type": "choice",
      "choices": [
        [
          "holiday",
          "Holiday Leave"
        ],
        [
          "sick",
          "Sick Leave"
        ],
        [
          "pregnancy",
          "Pregnancy Leave"
        ],
        [
          "bereavement",
          "Bereavement Leave"
        ],
        [
          "gathering",
          "Gathering Leave"
        ]
      ]
    }
  ],
  "export_import": null
}