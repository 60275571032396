import React from "react";
import Lib2 from "./Lib2";
import lodash, {camelCase, get, differenceWith, snakeCase, isEqual, maxBy, startCase, cloneDeep, isEmpty, orderBy} from "lodash";
import styled from "styled-components";
import FileDownload from "js-file-download";
import Modules from "../../Utils/Modules";
// import Request from "../../Utils/Request";
import { Lib, RequestV2 as Request } from "App/Utils";
import Filter from "./AdvancedFilter"
import BulkAction from "./Components/BulkAction";
import Options from "App/Config/Options";

import {
  Icon,
  Table, message,
  Row, Col, Button, Tag,
  Popconfirm, Select, Divider,
  Tooltip, Switch, Modal, Form,
  Radio,
} from "antd";
import queryString from "query-string";
import PropTypes from "prop-types";
import moment from "moment";
import ModalImport from "../ModalImport";
import { FormContext, FormProvider } from "App/Component/Context/ContextForm";
import SelectQuery from "App/Component/SelectQuery";
import ExportContent from "./ExportContent";

const ButtonGroup = Button.Group;
const KEY_DOWNLOAD = 'downloading'

class AdvancedTable extends React.Component {
  constructor(props) {
    super(props)

    // this.hasPermissionSearchOperator = get(props.userReducer, ['user', 'permissions'], []).includes('estimation.search_operator')

    this.search = []
    this.parsedQuery = queryString.parse(this.search);
    this.nextState = {};
    this.page = (this.parsedQuery.page) ? this.parsedQuery.page : 1;
    this.limit_request = (this.parsedQuery.limit) ? this.parsedQuery.limit : 25;
    this.state = {
      // Permissions
      allowAdd: !!props.permissions.find(code => code.indexOf('.add_') !== -1),
      allowRead: !!props.permissions.find(code => code.indexOf('.view_') !== -1),
      allowEdit: !!props.permissions.find(code => code.indexOf('.change_') !== -1),
      // issue feature #192 menyebutkan tidak boleh ada delete yang memiliki field "status", tapi untuk reference by, kesulitan untuk hide tombol "delete" untuk spesifik table
      allowDelete: !!props.permissions.find(code => code.indexOf('.delete_') !== -1),
      allowImport: get(props.userReducer, ['user', 'permissions'], []).some(code => code === 'exim.add_session'),
      // Table
      filter: (this.parsedQuery.fields) ? this.parsedQuery.fields.split(',') : [],
      searchSeparator: ',', // this.hasPermissionSearchOperator ? '|' : ',',
      limit : 0,
      count_search : 0,
      filter_by: [],
      onfilter: false,
      filterParam: [],
      count_row : 0,
      visibledrawer: false,
      count : 0,
      page: 0,
      loading: true,
      index: false,
      pagination: true,
      identifier: 'pk',
      current: 0,
      searchTag: (this.parsedQuery.search) ? this.parsedQuery.search.split(',') : [],
      dataRecord: [],
      cols: [],
      // export_import: null,
      selectedRows: [],
      metaList: null,

      //sorting
      iconSorting: [{name: 'start', sorted: 'no-sort'}],
      // Bulk Properties
      visibleBulk: false,
      bulkCols: [],
      copyKey: [],
      next: null,
      select_count: 0,
      exclude_id: [],
      bulkAction: [],
      blank_page: [],
      selectAllContent: false,
      selectAll: false,
      allSelectedRows: [],      // List of selected objects
      allSelectedRowKeys: [],   // List of selected keys
      currenctPageSelectedKeys: [],
      currentPageKeys: [],
      sortedInfo: {
        order: 'descend',
        columnKey: 'created',
      },
      filteredInfo: [],
    }
  }

  setQueryParamToUrl = () => {
    const q = queryString.stringify(this.queryParam());
    this.search= q
  };

  setLoading = (val) => {
    this.setState({loading: val})
  };

  queryParam = () => {
    const {url_name, index, reference} = this.props
    const { onfilter, iconSorting}= this.state
    let url_n = url_name
    if(reference)
      url_n = url_name +'-'+ snakeCase(get(index, 'label'))
    let filterParam = localStorage.getItem(url_n)
    if(filterParam) {
      filterParam = Lib2.AarrayObjectToString(JSON.parse(filterParam), 'key')
    }
    else
      filterParam = []
    let ordering = []
    let chars = orderBy(iconSorting, ['priority'],['asc']);
    if(chars.length > 0 && chars[0].name !== 'start') {
      for (let orderData of chars) {
        if(orderData.priority > 0) {
          const orderFieldName = orderData.alias_order || orderData.name
          if(orderData.sorted === 'asscend') {
            ordering.push(orderFieldName)
          }else{
            ordering.push('-' + orderFieldName)
          }
        }
      }
    }
    let objurl = {
      ordering: ordering.join(',') ? ordering.join(',') : '-modified',
      search: this.state.searchTag.join(this.state.searchSeparator),
      fields: this.state.filter.join(','),
      page: this.page,
      limit: this.limit_request
    }

    if(onfilter)
      filterParam.forEach((value, index, arr) => {
        const filter = this.state.filter_by.find(filter => filter.name === value.name)
        const delimiter = get(filter, 'delimiter', '|')
        let a = value.data
        if(typeof value.data === 'object')
          a = value.data.join(delimiter)
        objurl[value.name] = a
      })

    return objurl
  };

  clearSelected=()=> {
    this.setState({
      allSelectedRowKeys: [],
      allSelectedRows: [],
      selectedCurrentPageKeys: [],
      visibleBulk: false,
      visibleModalNew: false,
      currenctPageSelectedKeys: [],
      selectAll: false,
      exclude_id: [],
      selectAllContent: false
    })
  }

  onShowSizeChange = (current, pageSize) => {
    this.limit_request = pageSize;
  };

  read = () => {
    const p = this.props
    if (p.index) {
      this.setLoading(true)
      let args = [get(p, 'dataRecord.pk')]
      this.request = Request(
        "get",
        p.url_name,
        // camelCase(p.index.label) + "-read",
        {},
        this.queryParam(),
        args,
        this.readSuccess,
        this.readFailed
      );
    }
    this.setQueryParamToUrl();
  };

  handleTableChange = (pagination, filters, ordering) => {
    this.nextState = Lib.onTableChange(pagination, filters, ordering);
    this.ordering = this.nextState.order ? this.nextState.order : '-modified';
    this.page = this.nextState.page;
    this.setLoading(true)
    this.read();
  };

  readColumns =()=>{
    const {index, url_name} = this.props
    if (this.props.descriptor) {
      this.onSuccess({ data: this.props.descriptor })
    } else if (index) {
      this.request = Request(
        "get",
        // camelCase(index.label)+"-service",
        'services:descriptor',
        {},
        {name: url_name},
        [],
        this.onSuccess,
        this.readFailedcolumn
      );
    }
  }

  onSuccess=(response)=>{
    const { columns } = response.data
    const p = this.props
    const s = this.state
    let iconSorting = []
    let cols=[], bulkCols=[];
    let identifier = 'pk'
    if(columns) {
      for (let i of columns) {
        i.dataIndex = i.name
        delete i.ellipsis
        i.className = 'col-ellipsis-x'
        if(i.child_path)
          i.dataIndex = `${i.name}.${i.child_path}`
        i.title = i.label
        if (i.search) {
          i.filters = [{text: i.title, value: i.name}]
          i.filteredValue = []
          i.filterDropdownVisible = false
        }
        if(i.sort){
          iconSorting.push({name: i.name, sorted: 'no-sort', priority: 0, alias_order: i.alias_order})
        }
      }
      const r = columns.find(e => e.identifier)
      if(r)
        identifier = r.name
      cols = cloneDeep(columns)
      bulkCols = cloneDeep(columns)
      cols.push({
        title: 'Operation',
        name: 'operation',
        fixed: 'right',
        width: this.props.widthOperation || '85px',
        render: (text, record) => (
          <Row type='flex' justify='center' onClick={e => e.stopPropagation()}>
            <Col>
              {this.props.getAnotherOperations(record, this)}
              <Button
                style={{marginRight: '4px'}}
                type="primary"
                size="small"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation()
                  this.props.goTo('edit', camelCase(get(p, 'index.label', 'dashboard')), {key: record.pk, label: undefined}, get(p, 'index.label', 'dashboard'))
                }}
              >
                <Icon type={s.allowEdit ? 'edit' : 'read'} theme="filled"/>
              </Button>
              { !p.reference && s.allowDelete &&
                <Popconfirm
                  title="Sure to delete?"
                  placement="topRight"
                  onCancel={(e) => this.stopEvent(e)}
                  onConfirm={(e) => this.handleDelete(e, record.pk)}>
                  <Button
                    type="danger"
                    size="small"
                    onClick={(e) => this.stopEvent(e)}
                  >
                    <Icon type="delete"/>
                  </Button>
                </Popconfirm>
              }
            </Col>
          </Row>
        ),
      })
      bulkCols.push({
        title: 'Operation',
        name: 'operation',
        width: '100px',
        render: (text, record) => (
          <Row>
            <Col>
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  this.delRowSelection(record.pk)
                }}>
                Unselect
                <Icon type="minus-circle"/>
              </Button>
            </Col>
          </Row>
        ),
      })

    }
    const bulkActions = []
    const keyOptions = camelCase(get(this.props.index, 'label'))
    const bulkOptions = get(Options, [keyOptions, 'bulkOptions'], [])
    const isSuperuser = get(this.props.userReducer, ['user', 'is_superuser'])
    if (isSuperuser) {
      bulkActions.push(...bulkOptions)
    } else {
      for (let i = 0; i < bulkOptions.length; i += 1) {
        if (this.props.permissions.includes(bulkOptions[i].permissionCode)) {
          bulkActions.push(bulkOptions[i])
        }
      }
    }

    const metaList = { ...response.data }
    if (this.props.exportImport) metaList.export_import = this.props.exportImport

    this.setState({
      metaList,
      filter_by: response.data.filter_by,
      cols,
      bulkCols,
      bulkAction: bulkActions,
      pagination: response.data.pagination,
      identifier: identifier,
      iconSorting,
      loading: true
    }, ()=> this.read())
  }

  readSuccess = (response) => {
    let data = response.data.results
    const {
      allSelectedRowKeys, exclude_id, page, blank_page, selectAllContent,
    } = this.state
    let currentPageKeys = data.map(
      (value, index, arr) => {return value.pk}
    )
    let currenctPageSelectedKeys = currentPageKeys.filter(
      (value, index, arr) => {return allSelectedRowKeys.indexOf(value) !== -1}
    )
    let copyKey = []
    for (const originData of data) {
      copyKey.push(originData.pk)
      for (const o in originData) {
       if(originData[o] === undefined && originData[o] === null || originData[o]===''){
          originData[o] = "-"
        }
      }
    }
    let e = {}
    if(selectAllContent && !isEmpty(copyKey)) {
      let blank;
      if(blank_page.find(e => e === this.page)) {
        blank = true
      }
      if(!allSelectedRowKeys.find(e => e === copyKey[0]) && !blank) {
        e.allSelectedRowKeys = allSelectedRowKeys.concat([...copyKey])
        if(!isEmpty(exclude_id) && !isEmpty(copyKey)){
            let clone = [...copyKey]
            for(const i of exclude_id){
              let a = clone.findIndex(e => e === i)
              if(a>=0)
                clone.splice(a, 1)
            }
          e.currenctPageSelectedKeys = clone
          }else {
            e.currenctPageSelectedKeys = [...copyKey]
        }
      }
    }
    this.setState({
      dataRecord: data,
      copyKey: copyKey,
      next: response.data.next,
      count: response.data.count,
      count_row: get(response, 'data.results.length', '0'),
      loading: false,
      current: response.data.current,
      limit: response.data.limit,

      // Bulk properties
      currentPageKeys: currentPageKeys,
      currenctPageSelectedKeys: currenctPageSelectedKeys, ...e
    })
  };

  readFailed = (error) => {
    Lib.errorMessageHandler(get(error, 'response'))
    this.setState({
      loading: false,
    })
  };

  readFailedcolumn = (error) => {
    Lib.errorMessageHandler(get(error, 'response'))
    this.setState({
      loading: false,
    })
  };

  componentDidMount() {
    this.readColumns()
  }

  // componentWillUnmount() {
  //   if (!this.isDownloading) {
  //     message.destroy()
  //     Lib.windowStop();
  //   }
  // };

  componentWillUnmount() {
    if (this.isDownloading) {
      message.destroy()
      message.info('Download was cancelled')
    }
    Lib.windowStop()
  }

  renderFiltertag() {
    const {filter} = this.state;
    return filter.map((data, key) => (
      <StyledFilter style={{background: '#28a745', color: '#fff'}} key={key} onClick={() => this.handleDeselect(data)}>
        {data.replace(/_/g, ' ')}
        <Icon type="close-circle" style={{marginLeft: '5px'}} theme="filled"/>
      </StyledFilter>
    ))
  }

  handleSearch = (value) => {
    this.page = 1;
    this.setState({ loading: true, searchTag: value }, () => {
      this.clearSelected()
      this.read()
    })
  };

  stopEvent = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleDeselect = (data) => {
    const {cols, filter, searchTag} = this.state
    let tag = cloneDeep(searchTag)
    for (let i = 0; i < filter.length; i++) {
      if (filter[i] === (data)) {
        filter.splice(i, 1);
      }
    }
    for (let i of cols){
      if(i.filteredValue && i.filteredValue[0]=== data){
        i.filteredValue=[]
      }
    }
    this.setState(s => ({
      filter,
      searchTag: s.searchTag.filter(val => val !== data)
    }), ()=>{
      if(!isEmpty(tag)) {
        this.setLoading(true);
        this.read()
      }
    });
  };

  messageCustom = (type, msg) => {
    if (type !== 'loading') {
      message.destroy();
      this.setLoading(false);
    } else {
      this.setLoading(true);
    }
    return (
      message[type](msg)
    )
  };

  handleDelete = (e, key) => {
    e.preventDefault();
    e.stopPropagation();
    const {index, url_name} = this.props
    if(index) {
      this.setLoading(true)
      this.messageCustom('loading', 'Delete in progress..');
      // Belum bisa mengimplementasikan menggunakan url ref,
      // Karena tidak mendapatkan url_name detail (untuk delete ini)
      this.request = Request(
        "delete",
        camelCase(index.label) + "-delete",
        // (url_name || '').replace('list', 'detail'), // sudah bisa, tapi URL key di FE belum siap
        {},
        {},
        [key],
        this.deleteSuccess,
        this.deleteFailed,
        key
      );
    }
  };

  deleteSuccess = (response, key) => {
    const s = this.state
    if (response) {
      if (response.status === 204) {
        const obj = {
          allSelectedRowKeys: s.allSelectedRowKeys.filter(e => e !== key),
          allSelectedRows: s.allSelectedRows.filter(e => e.pk !== key),
          currenctPageSelectedKeys: s.currenctPageSelectedKeys.filter(e => e !== key)
        }
        this.setState(obj)
        message.destroy();
        message.success('Delete Success');
        this.read()
      }
    }
  };

  deleteFailed = (error) => {
    message.destroy()
    Lib.errorMessageHandler(get(error, 'response'))
    this.setLoading(false)
    // this.messageCustom('error', "Error, status: " + errorObj)
  };

  filterIsEmpty=()=>{
    this.setLoading(false)
  }

  reloadPage = () => {
    this.setLoading(true)
    // Lib.windowStop();
    this.readColumns()
    // this.read();
  };

  delRowSelection = (pk) => {
    const s = this.state
    let r = {
      allSelectedRowKeys: this.state.allSelectedRowKeys,
      allSelectedRows: this.state.allSelectedRows,
    }
    for (let i = 0; i < r.allSelectedRowKeys.length; i++) {
      if (r.allSelectedRowKeys[i] === pk) {
        r.allSelectedRowKeys.splice(i, 1)
        r.allSelectedRows.splice(i, 1)
        break
      }
    }
    r.visibleBulk = r.allSelectedRowKeys.length > 0
    r.visibleModalNew = s.visibleModalNew && r.visibleBulk
    if(!r.visibleModalNew)
      r.currenctPageSelectedKeys = []
    else
      r.currenctPageSelectedKeys = r.allSelectedRowKeys
    if(s.selectAllContent) {
      r.exclude_id = s.exclude_id.concat(pk)
      r.select_count = s.select_count -= 1
    }
    if(r.allSelectedRowKeys.length === 0)
      r.selectAll = false
    this.setState(r)
  };

  setDrawer=(val)=>{
    this.setState({
      visibledrawer: val
    })
  }
  handleChange = (nextTargetKeys, name, filter) => {
    this.page = 1
    const p = this.props
    const {onfilter} = this.state
    let es = Lib2.sessionMaster(nextTargetKeys, name, p, onfilter, filter)
    if(es){
      this.setLoading(true)
      this.read()
    }
  };

  filterSwitch=(val)=>{
    this.page = 1
    this.setState({
      loading: true,
      onfilter: val
    },()=>{
      this.clearSelected()
      this.read()
    })
  }

  sortChange=(columns, clearSorting=false)=>{
    this.setLoading(true)
    let s = this.state.iconSorting
    let checkData = s.findIndex(item => item.name===columns)
    let prevPriority = maxBy(s, 'priority')
    if(Lib2.getNexticon(s[checkData].sorted) === "no-sort" || clearSorting) {
      for (let data of s) {
          if(data.priority > s[checkData].priority){
            data.priority-=1
          }
      }
      s[checkData].priority = 0
    }else if(s[checkData].priority===0){
      s[checkData].priority = prevPriority.priority+1
    }
    if(clearSorting){
      s[checkData].sorted = 'no-sort'
    }else {
      s[checkData].sorted = Lib2.getNexticon(s[checkData].sorted)
    }
    this.setState({
      iconSorting: s
    },()=> this.read())
  }

  generateColumns=(col, s)=>{
    let cols = col.filter(e => !e.hidden)
    for (let i in cols) {
      if (cols[i].search) {
        cols[i].onFilterDropdownVisibleChange = () => {
          this.setState({
            loading: true,
            filter: Lib.filterClick(cols, s.filter, i, cols[i].name)
          },()=>s.searchTag.length > 0 ? this.read() : this.filterIsEmpty())
        }
      }
      const title = cols[i].label
      let idx = s.iconSorting.findIndex(val => val.name === cols[i].name)
      if(cols[i].sort && idx !== -1){
        cols[i].title = Lib2.getIcon(s.iconSorting, idx, this.sortChange, title)
      }
      if(cols[i].data_type === 'bool'){
        cols[i].render = (text) => (
          <Row>
            <Col>
              <span>{Modules.renderStatusAIU(text)}</span>
            </Col>
          </Row>
        )
        }else if(cols[i].data_type === 'datetime'){
          cols[i].render = (text) => {
            if(text)
              return moment(text).format('lll')
            return '-'
          }
        }
    }
    return cols
  }

  selectRowChange=(selectedRowKeys, selectedRows)=>{
    const s = this.state
    let selectedCurrentPageKeys = s.currentPageKeys.filter(
      (value, index, arr) => {
        return (s.allSelectedRowKeys.indexOf(value) !== -1)
      }
    )

    let newSelectedObjects = [], newSelectedKeys = []
    newSelectedKeys = selectedRowKeys.filter(
      (value, index, arr) => {
        let check = selectedCurrentPageKeys.indexOf(value) === -1
        if (check) {
          for (const i in selectedRows) {
            if (selectedRows[i].pk === value) {
              newSelectedObjects.push(selectedRows[i])
            }
          }
        }
        return check
      }
    )
    let removeSelectedKeys = []
    removeSelectedKeys = selectedCurrentPageKeys.filter(
      (value, index, arr) => {
        return (selectedRowKeys.indexOf(value) === -1)
      }
    )

    let willState = {
      currenctPageSelectedKeys: selectedRowKeys,
    }
    if (newSelectedKeys.length > 0) {
      willState.allSelectedRowKeys = s.allSelectedRowKeys.concat(newSelectedKeys)
      willState.allSelectedRows = s.allSelectedRows.concat(newSelectedObjects)
      if(s.selectAllContent) {
        willState.exclude_id = differenceWith(s.exclude_id, newSelectedKeys, isEqual)
        willState.select_count = s.select_count += newSelectedKeys.length
      }
    } else {
      removeSelectedKeys.forEach(
        (value, index, arr) => {
          this.delRowSelection(value)
        }
      )
    }
    willState.visibleBulk = s.allSelectedRowKeys.length > 0 || newSelectedKeys.length > 0
    if(s.selectAllContent) {
      if(s.select_count > 0)
        willState.visibleBulk = true
      else if(s.select_count===0)
       willState.selectAllContent = false
      if(selectedRowKeys.length === 0)
        willState.blank_page = s.blank_page.concat(this.page)
    }
    this.setState(willState)
  }

  setPage=(e, callback)=>{
    this.page = e
    callback()
  }
  setThisstate=(val, callback = ()=>null)=>{
    this.setState(val,()=>callback())
  }

  selectAll=(e)=>{
    const s = this.state
    let obj = {selectAll: e}
    if(s.selectAllContent && e === false) {
      obj.exclude_id = [...s.exclude_id.concat(s.copyKey)]
      if(!s.blank_page.find(e => e === this.page))
        obj.blank_page = s.blank_page.concat(this.page)
    }else if(!s.selectAllContent && e===false){

      this.clearSelected()
    }else {
      if(this.page === 1 && !s.next) {
          obj.exclude_id = []
          obj.currenctPageSelectedKeys = [...s.copyKey]
          obj.allSelectedRowKeys = [...s.copyKey]
          obj.select_count = s.count
          obj.selectAllContent = true
      }
      obj.blank_page = s.blank_page.filter(e => e !== this.page)
    }
    this.setState(obj)
  }

  selectionControl =(index)=>{
    const s = this.state
    let content;
    if(s.selectAll || !isEmpty(s.allSelectedRowKeys) || s.selectAllContent) {
      if (s.selectAllContent)
      content = <Tag onClick={() => {
        this.setThisstate({
          selectAllContent: false,
        })
        this.clearSelected()
      }}
           className='select-all ant-btn-danger'>
        Clear selection
      </Tag>
      else
        content = <Tag onClick={() => this.setThisstate({
          selectAllContent: true,
          currenctPageSelectedKeys: [...s.copyKey],
          allSelectedRowKeys: [...s.copyKey],
          selectAll: true,
          exclude_id: [],
          select_count: s.count
        })} className='select-all ant-btn-primary'>
          select all {s.count} {startCase(index).toLowerCase()}
        </Tag>
    }
    return content
  }

  getModalImport = (title) => {
    if (this.state.visibleModalImport) {
      return this.props.getModalImport ? (
        this.props.getModalImport({
          ...this.props,
          visible: this.state.visibleModalImport,
          model: this.props.location.pathname.split('/')[2],
          export_import: get(this.state.metaList, 'export_import'),
          urlName: this.props.urlName ? this.props.urlName : this.props.url_name,
          toggle: () => {
            this.setState({visibleModalImport: !this.state.visibleModalImport})
          },
          title: `Import ${title}`,
          url: title,
          metaList: this.state.metaList,
        })
      ) : (
        <ModalImport
          {...this.props}
          model={this.props.location.pathname.split('/')[2]}
          visible={this.state.visibleModalImport}
          export_import={get(this.state.metaList, 'export_import')}
          urlName={this.props.urlName ? this.props.urlName : this.props.url_name}
          toggle={() => {
            this.setState({visibleModalImport: !this.state.visibleModalImport})
          }}
          title={`Import ${title}`}
          url={title}
          metaList={this.state.metaList}
        />
      )
    }
    return null
  }

  onExportClick = event => {
    const { metaList } = this.state
    if (!get(metaList, 'export_import')) {
      message.info('Data is not ready, please reload your tab')
    } else {
      const keyMessage = KEY_DOWNLOAD + metaList.export_import.export.url_name
      if (get(metaList, ['export_import', 'export', 'directly'])) {
        // Download now, directly
        this.handleDownload(
          metaList.export_import.export.url_name,
          undefined,
          isSuccess => {
            this.isDownloading = false
            if (isSuccess) {
              message.success({ key: keyMessage, content: `Download ${this.props.title} Success`})
            } else if (isSuccess === undefined) {
              message.error({ key: keyMessage, content: `Download ${this.props.title} Failed` })
            }
          }
        )
      } else {
        // Jangan hapus comment code di dalam Modal.confirm
        Modal.confirm({
          title: 'Export Options',
          // content: this.getContent(get(metaList.export_import.export, 'filter_by', [])),
          content: (
            <ExportContent
              filterBy={get(metaList.export_import.export, 'filter_by', [])}
              setForm={form => {this.downloadForm = form}}
            />
          ),
          width: 932,
          icon: <Icon type='control' />,
          maskClosable: true,
          // onCancel: () => new Promise((resolve, reject) => {
          //   if (this.isDownloading) {
          //     reject('Download is still working, cannot close dialog')
          //     message.info('Cannot cancel download, please wait a moment.')
          //   } else {
          //     resolve()
          //   }
          // }),
          onCancel: () => {
            if (this.isDownloading) {
              message.info('The download is still running in the background')
            }
          },
          onOk: () => new Promise((resolve, reject) => {
            this.handleDownload(
              metaList.export_import.export.url_name,
              this.downloadForm,
              isSuccess => {
                this.isDownloading = false
                if (isSuccess) {
                  message.success({ content: `Download ${this.props.title} Success`, key: keyMessage })
                  resolve()
                } else if (isSuccess !== undefined) {
                  reject(`Download ${this.props.title} cancelled`)
                } else {
                  message.error({ content: `Download ${this.props.title} Failed`, key: keyMessage })
                  reject('Failed to export data')
                }
              }
            )
          }),
        })
      }
    }
  }

  getContent = (fieldsApi) => {
    return (
      <FormProvider>
        <Form layout='horizontal'>
          {fieldsApi.map((elem, index) => (
            this.getElem(elem)
          ))}
        </Form>
      </FormProvider>
    )
  }

  getElem = (elem) => {
    return (
      <FormContext.Consumer key={elem.name}>
        {form => {
          const { getFieldDecorator } = form
          this.downloadForm = form
          const formItemLayout = { labelCol: {span: 7}, wrapperCol: {span: 15} }
          return (
            <Col span={12}>
              <Form.Item label={elem.label} {...formItemLayout}>
                {this.getInput(elem, getFieldDecorator)}
              </Form.Item>
            </Col>
          )
        }}
      </FormContext.Consumer>
    )
  }

  getInput = (elem, getFieldDecorator) => {
    switch (elem.type) {
      case 'choices': {
        return (
          getFieldDecorator(elem.name, {
            initialValue: get(elem, ['choices', '0', '0'])
          })(
            <Select>
              {elem.choices.map((item, index) => (
                <Select.Option key={item[0]} value={item[0]}>{item[1]}</Select.Option>
              ))}
            </Select>
          )
        )
      }
      case 'api_reference': {
        return (
          getFieldDecorator(elem.name, {
            // Nothing keys
          })(
            <SelectQuery
              urlKey={elem.api.url_name}
              valKey={elem.api.key}
              valLabel={elem.api.display}
              userReducer={this.props.userReducer}
              paramProps={elem.name === 'subsection' ? {
                section: get(this.downloadForm.getFieldValue('section'), 'key', 0),
              } : {}}
              onChange={() => {
                // Sementara, jika mengganti section, subsection akan di hapus, dan request subsection dengan param section id.
                // Belum untuk yang lain
                if (elem.name === 'section') {
                  this.downloadForm.setFieldsValue({ subsection: undefined })
                }
              }}
            />
          )
        )
      }
      case 'switch': {
        return (
          getFieldDecorator(elem.name, {})(<Switch />)
        )
      }
      default: {
        return ''
      }
    }
  }

  handleDownload = (urlName, form, callback = () => null) => {
    const queryParam = this.queryParam()
    delete queryParam.limit
    delete queryParam.page

    let convertedValues = {}
    if (form) {
      form.validateFields((err, values) => {
        if (err) {
          message.error('Something when wrong')
          callback()
          return
        }
        // please don't passing FormData, need object
        convertedValues = Lib2.getValuesWithFile(values)
      })
    }

    // const warningMessage = 'Too much data, will require a very long time. Continue? Maybe the export results will be sent to the email.'
    const warningMessage = 'Too much data, will require a very long time. Continue?'
    this.handleConfirm(warningMessage, (isApproved) => {
      if (isApproved) {
        message.loading({ content: `Downloading ${this.props.title}...`, key: KEY_DOWNLOAD + urlName, duration: 0 })
        this.isDownloading = true
        Request(
          'get',
          urlName,
          {},
          { ...queryParam, ...convertedValues },
          [],
          this.downloadSuccess,
          this.downloadFailed,
          callback,
        )
      } else {
        callback(false)
      }
    }, !((convertedValues.limit === 'all' || typeof convertedValues.limit === 'undefined') && this.state.count > 1000))
  }

  handleConfirm = (warningMessage, callback = () => null, force) => {
    if (!force) {
      Modal.confirm({
        title: 'Confirmation',
        content: warningMessage,
        okText: 'Continue',
        onOk: () => callback(true),
        onCancel: () => callback(),
      })
    } else {
      callback(true)
    }
  }

  filterFields = (values) => {
    const obj = {}
    for (const key in values) {
      if (values[key] !== undefined) {
        obj[key] = values[key]
      }
    }
    return obj
  }

  downloadSuccess = (response, callback) => {
    const filename = Lib.getFilenameDisposition(response.headers, `Export ${this.props.title} ${moment().format('YYYY-MM-DD')}.csv`)
    FileDownload(response.data, filename)
    callback(true)
  }

  downloadFailed = (error, callback) => {
    callback()
  }

  onImportClick = () => {
    this.setState({ visibleModalImport: true })
  }

  render() {
    const s = this.state
    const p = this.props
    const {location} = this.props
    let index = s.index
    if(p.index){
      index = p.index.label
    }
    let url_name = p.url_name
    if(p.reference)
      url_name = url_name +'-'+ snakeCase(index)
    const rowSelection = {
      selectedRowKeys: s.currenctPageSelectedKeys,
      onChange: this.selectRowChange,
      onSelectAll: this.selectAll,
    }
      return (
        <Row>
          <Col sm={13} md={13} xs={24}>
            {/* <Affix offsetTop={16}> */}
              <Col>
                <Row>
                  <Col>
                    <ButtonGroup>
                      {
                        s.visibleBulk &&
                        <Button
                          type="primary"
                          icon='appstore'
                          onClick={() => {this.setState({visibleModalNew: true})}}
                        >
                          Bulk Actions
                        </Button>
                      }
                      <Button
                        type="primary"
                        icon="plus"
                        disabled={!s.allowAdd}
                        onClick={() => p.goTo('add', index, null, index, p.dataidentifier)}
                      >
                        {`Add ${index ? startCase(index) : ''}`}
                      </Button>
                    </ButtonGroup>
                    {!s.visibleBulk && (
                      <>
                        <Divider type="vertical"/>
                        <ButtonGroup>
                          <Button type="primary" disabled={s.loading} icon={s.loading ? 'loading' : 'reload'} onClick={()=>this.reloadPage()}/>
                          <Button
                            // disabled={!s.allowEdit}
                            disabled={p.reference || !this.props.enableImport || !s.allowImport}
                            title={!p.reference ? 'Import Data' : 'Import in reference table is not supported'}
                            type="primary"
                            icon="vertical-align-top"
                            onClick={this.props.onImportClick || this.onImportClick}
                          />
                          <Button
                            disabled={!s.allowAdd || p.reference || !this.props.enableExport}
                            title={!p.reference ? 'Export Data' : 'Export in reference table is not supported'}
                            type="primary"
                            icon="vertical-align-bottom"
                            onClick={this.props.onExportClick || this.onExportClick}
                          />
                        </ButtonGroup>
                      </>
                    )}
                    {this.props.getAnotherActions && (
                      <>
                        <Divider type="vertical"/>
                        {this.props.getAnotherActions()}
                      </>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md={24}>
                  {
                    s.visibleBulk &&
                    <StyledFilter
                      onClick={() => this.clearSelected()}>
                      Selected : {s.selectAllContent ? s.select_count : s.allSelectedRowKeys.length}
                      <Icon type="close-circle" style={{marginLeft: '5px'}} theme="filled"/>
                    </StyledFilter>
                  } {
                    this.selectionControl(index)
                  }
                  </Col>
                </Row>
              </Col>
            {/* </Affix> */}
          </Col>

          <Col xs={24} sm={9} md={7}>
            <StyledSpanNoSelect>
              Search by field: {this.renderFiltertag()}
            </StyledSpanNoSelect>
            {/* <br/> */}
            <div style={{ position: 'relative', marginBottom: '12px' }}>
              <Select
                allowClear
                autoFocus
                mode="tags"
                className="custom-select-with-operator w-100"
                tokenSeparators={[',', ' ']}
                onChange={this.handleSearch}
                dropdownStyle={{ display: 'none' }}
                value={s.searchTag}
                placeholder="Search"
              />
              {/* {this.hasPermissionSearchOperator && ( */}
                <div style={{ position: 'absolute', right: '6px', bottom: '9px', zIndex: 1 }}>
                  <Radio.Group
                    size="small"
                    buttonStyle="solid"
                    value={this.state.searchSeparator}
                    onChange={e => {
                      this.setState({ searchSeparator: e.target.value }, () => {
                        if ((this.state.searchTag || []).length > 1)
                          this.read()
                      })
                    }}
                  >
                    <Radio.Button value=",">
                      AND
                    </Radio.Button>
                    <Radio.Button value="|">
                      OR
                    </Radio.Button>
                  </Radio.Group>
                </div>
              {/* )} */}
            </div>
          </Col>
          <Col sm={24} md={4} xs={24}>
            <Tag className="ml-3" style={{width: '90%', marginBottom: '10px'}}>
            <Row>
              <Col md={12}>
              <Button
              icon="setting"
              className="mt-1 spin-filter"
              onClick={()=>this.setDrawer(true)}
              shape="circle"
              type="danger"
            />
              <Switch
                unCheckedChildren="Off"
                checkedChildren="On"
                size={"small"}
                className="ml-2 mb-2"
                checked={s.onfilter}
                style={{marginTop: '5px'}}
                onChange={this.filterSwitch}
              />
            <br/>
              <b style={{color: "#8c8a8a"}}>Advance filter</b>
              </Col>
              <Col md={12}>
                {/*{ !location.state &&*/}
                {/*<Button*/}
                {/*  icon="control"*/}
                {/*  size={"large"}*/}
                {/*  className="mt-2 ml-4"*/}
                {/*  disabled={true}*/}
                {/*/> }*/}
              </Col>
            </Row>
            </Tag>
          </Col>
          <Col span={24}>
              <StyledTable
                dataSource={s.dataRecord}
                loading={s.loading}
                size="small"
                columns={this.generateColumns(s.cols, s)}
                onChange={this.handleTableChange}
                rowKey={data => data.pk}
                rowSelection={get(s.bulkAction, 'length') ? rowSelection : null}
                scroll={{x: true}}
                onRow={(record, rowIndex) => {
                  return {
                    className: rowIndex % 2 === 1 ? 'odd' : '',
                    onClick: event => {
                      if (this.props.onDetailClick) this.props.onDetailClick(record)
                      else this.props.goTo('edit', camelCase(index), {key: record[s.identifier], label: record.name}, index)
                    },
                  }
                }}
                pagination={s.pagination ? {
                  pageSizeOptions: ['25', '50', '75', '100'],
                  showSizeChanger: true,
                  onShowSizeChange: this.onShowSizeChange,
                  showQuickJumper: true,
                  total: s.count,
                  current: s.current,
                  pageSize: s.limit,
                  hideOnSinglePage: false
                } : false}
                footer={() => Modules.infoTotalRow(s.count_search, s.count_row, s.count)}
              />
          </Col>
          <Filter
            visibledrawer={s.visibledrawer}
            onChildrenDrawerClose={()=>this.setDrawer(false)}
            filter={s.filter_by}
            userReducer={p.userReducer}
            getFilterParams={() => localStorage.getItem(url_name)}
            onfilter={s.onfilter}
            handleChange={this.handleChange}
            setonfilter={this.filterSwitch}
          />
          {this.getModalImport(index)}
          { s.visibleModalNew &&
          <BulkAction
            {...s}
            generateColumns={this.generateColumns}
            setThisstate={this.setThisstate}
            read={this.read}
            userReducer={p.userReducer}
            index={index}
            setPage={this.setPage}
            reference={p.reference}
            queryParam={this.queryParam}
            refID={get(p, 'dataRecord.pk')}
            url_name={p.url_name}
            clearSelected={this.clearSelected}
          />
          }

        </Row>

      )
  }
}

AdvancedTable.propTypes = {
  goTo: PropTypes.func,
  dataidentifier: PropTypes.object,
  permissions: PropTypes.array,
  getModalImport: PropTypes.func,
  descriptor: PropTypes.object,
  getAnotherOperations: PropTypes.func,
  enableImport: PropTypes.bool,
  enableExport: PropTypes.bool,
};

AdvancedTable.defaultProps = {
  goTo: () => null,
  dataidentifier: {},
  permissions: [],
  getModalImport: null,
  getAnotherOperations: () => null,
  enableImport: true,
  enableExport: true,
}

export default AdvancedTable

const StyledTable = styled(Table)`
  tr:hover td div .anticon-ellipsis{
    display: block;
  } 
`;
const StyledFilter = styled.span`
  background-color: #1690ff;
  padding: 2px 4px;
  margin: 2px;
  font-size: 10px;
  border-radius: 4px;
  color: #fff;
  display: inline-block;
  cursor: pointer;
`;
const StyledSpanNoSelect = styled.span`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
