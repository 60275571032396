import React from 'react'
import PropTypes from 'prop-types'
import { Col, Icon, Row } from 'antd'
import { get } from 'lodash'
import { Lib, RequestV2 as Request } from 'App/Utils'
import Subsection from './Subsection'

class Section extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      collapsed: true,
      isLoading: false,
      subsections: [], // default is empty, when not array, it's error
    }
  }

  onRefSubsection = (ref, subsection) => {
    this[this.getRefKey(subsection)] = ref
  }

  getRefKey = (subsection) => {
    return `_subsection_${get(subsection, 'pk')}`
  }

  onCollapse = () => {
    if (get(this.state.subsections, 'length') || !this.state.collapsed) {
      this.setCollapsed(!this.state.collapsed)
    } else {
      this.loadSubsection((isSuccess) => {
        // if (isSuccess) this.setCollapsed(!this.state.collapsed)
        this.setCollapsed(!this.state.collapsed)
      })
    }
  }

  setCollapsed = (collapsed) => {
    this.setState({ collapsed })
  }

  loadSubsection = (callback = () => null) => {
    if (!this.state.isLoading) {
      this.setState({ isLoading: true }, () => {
        Request(
          'get',
          'subsections-autocomplete',
          {},
          { limit: 100, section: this.props.section.pk, status: 'active' },
          [],
          this.loadSubsectionSuccess,
          this.loadSubsectionFailed,
          { callback }
        )
      })
    }
  }

  loadSubsectionSuccess = (response, extra) => {
    this.setState({ isLoading: false, subsections: response.data.results }, () => {
      extra.callback(true, response)
    })
  }

  loadSubsectionFailed = (error, extra) => {
    Lib.errorMessageHandler(get(error, 'response'))
    this.setState({ isLoading: false, subsections: null }, () => {
      extra.callback(false, error)
    })
  }

  onClickSubsection = (event, subsection) => {
    event.stopPropagation()
    this.props.onSelectSubsection(subsection, this.props.section)
  }

  onAddGroup = (subsection) => {
    this.props.onAddGroup(subsection, this.props.section)
  }

  onDeleteGroup = (group, obj) => {
    this.props.onDeleteGroup(group, Object.assign({ section: this.props.section }, obj))
  }

  componentDidMount() {
    const { defaultExpandedKeys, section } = this.props
    if (`${get(defaultExpandedKeys, 'section')}` === `${section.pk}`) {
      // expand and read
      this.onCollapse()
    }
  }

  render() {
    const { section, selectedSubsection } = this.props
    const { collapsed, isLoading, subsections } = this.state
    const iconType = isLoading ? 'loading' : (collapsed ? 'caret-right' : 'caret-down')

    return (
      <div className='ant-collapse-item section-panel'>
        <div className='ant-collapse-header' onClick={this.onCollapse}>
          <Icon type={iconType} className='ant-collapse-arrow text-success' />
          <div>{section.name}</div>
        </div>
        <div className={`ant-collapse-content ant-collapse-content-${collapsed ? 'inactive' : 'active'}`}>
          <div className='ant-collapse-content-box'>
            {subsections ? (
              <div className='ant-collapse ant-collapse-borderless'>
                {subsections.map(subsection => {
                  const isSelected = selectedSubsection === subsection.pk

                  return (
                    <Subsection
                      ref={ref => {this.onRefSubsection(ref, subsection)}}
                      key={subsection.pk}
                      urlKeyGroupList={this.props.urlKeyGroupList}
                      subsection={subsection}
                      isSelected={isSelected}
                      defaultExpandedKeys={this.props.defaultExpandedKeys}
                      onAddGroup={this.onAddGroup}
                      onSelectGroup={this.props.onSelectGroup}
                      selectedObj={this.props.selectedObj}
                      onDeleteGroup={this.onDeleteGroup}
                    />
                  )
                })}
              </div>
            ) : (
              <div className='text-danger' style={{ fontStyle: 'italic', paddingLeft: '28px' }}>
                error...
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

Section.propTypes = {
  urlKeyGroupList: PropTypes.string.isRequired,
  section: PropTypes.object.isRequired,
  selectedObj: PropTypes.object,
  defaultExpandedKeys: PropTypes.object,
  onSelectSubsection: PropTypes.func,
  onAddGroup: PropTypes.func,
  onSelectGroup: PropTypes.func,
  onDeleteGroup: PropTypes.func,
}

Section.defaultProps = {
  defaultExpandedKeys: null,
  onSelectSubsection: () => null,
  onAddGroup: () => null,
  onSelectGroup: () => null,
  onDeleteGroup: () => null,
}

export default Section