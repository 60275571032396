import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Table, Button, Input } from 'antd'
import { get } from 'lodash'
import DrawerMerchants from './DrawerMerchants'

class TemplateList extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isShowDrawer: false,
      selectedMerchants: [], // array of id merchant
    }
  }

  onCloseDrawer = () => {
    this.onToggleMerchants(false)
  }

  onToggleMerchants = (isShowDrawer, callback = () => null) => {
    this.setState({ isShowDrawer }, callback)
  }

  onJoinMerchants = (ids, merchants, callback) => {
    this.props.onJoinMerchants(ids, merchants, (isSuccess) => {
      if (isSuccess) {
        this.onToggleMerchants(false)
      }
      callback(isSuccess)
    })
  }

  onSearch = (val, event) => {
    this.props.refresh('isSaving', { page: 1, search: val })
  }

  onPageChange = (page, pageSize) => {
    this.props.refresh('isSaving', { page, limit: pageSize })
  }

  onChangeSelection = (selectedRowKeys, selectedRows) => {
    this.setState({ selectedMerchants: selectedRowKeys })
  }

  onClearSelected = () => {
    this.setState({ selectedMerchants: [] })
  }

  onRemoveSelectedMerchants = () => {
    if (this.state.selectedMerchants.length === 0) {
      return
    }
    this.props.removeMerchants(this.state.selectedMerchants, isSuccess => {
      if (isSuccess) {
        this.setState({ selectedMerchants: [] })
      }
    })
  }

  onRowClick = (record, event) => {
    const { selectedMerchants } = this.state
    if (selectedMerchants.find(id => id === record.pk)) {
      this.setState({ selectedMerchants: selectedMerchants.filter(id => id !== record.pk) })
    } else {
      this.setState(prevState => ({ selectedMerchants: [...prevState.selectedMerchants, record.pk] }))
    }
  }

  render() {
    const { columns, dataSource, userReducer } = this.props
    const { isShowDrawer, selectedMerchants } = this.state

    return (
      <div>
        <Row style={{ padding: '0 24px 24px 24px' }}>
          <Row className='mb-3' type='flex' justify='space-between' gutter={[12, 12]}>
            <Col style={{ fontSize: '16px', fontWeight: 500 }}>
              <Row type='flex' gutter={[12, 12]}>
                <Col>
                  <Button type='primary' icon='plus' onClick={() => this.onToggleMerchants(true)}>
                    Select Merchant
                  </Button>
                </Col>
                {selectedMerchants.length > 0 && (
                  <Col>
                    <Button.Group>
                      <Button onClick={this.onClearSelected}>
                        Clear selected
                      </Button>
                      <Button type='danger' icon='delete' onClick={this.onRemoveSelectedMerchants}>
                        Remove Merchants
                      </Button>
                    </Button.Group>
                  </Col>
                )}
              </Row>
            </Col>
            <Col>
              <Row type='flex' gutter={[12, 12]}>
                <Col>
                  {/* <Button icon='reload' onClick={() => this.props.refresh('isSaving')}>
                    Refresh
                  </Button> */}
                </Col>
                <Col>
                  {/* <Input.Search
                    placeholder='Search merchants'
                    onSearch={this.onSearch}
                    style={{ width: '250px' }}
                  /> */}
                </Col> 
              </Row>
            </Col>
          </Row>
          <Row>
            <Table
              bordered
              rowKey='pk'
              size='middle'
              scroll={{ x: true }}
              columns={columns}
              dataSource={dataSource}
              pagination={false}
              // pagination={{
              //   pageSize: get(rowMerchants, 'limit'),
              //   total: get(rowMerchants, 'count'),
              //   showTotal: (total, range) => (
              //     <span style={{ float: 'left' }}>{dataSource.length} of {total} merchants</span>
              //   ),
              //   onChange: this.onPageChange,
              // }}
              rowSelection={{
                onChange: this.onChangeSelection,
                selectedRowKeys: selectedMerchants,
              }}
              onRow={(record) => ({ onClick: (event) => this.onRowClick(record, event)})}
            />
          </Row>
        </Row>
        <DrawerMerchants
          visible={isShowDrawer}
          userReducer={userReducer}
          merchants={dataSource}
          onClose={this.onCloseDrawer}
          onJoinMerchants={this.onJoinMerchants}
        />
      </div>
    )
  }
}

TemplateList.propTypes = {
  userReducer: PropTypes.object.isRequired,
  columns: PropTypes.array,
  dataSource: PropTypes.array,
  onJoinMerchants: PropTypes.func,
  removeMerchants: PropTypes.func,
}

TemplateList.defaultProps = {
  columns: [],
  dataSource: [],
  onJoinMerchants: () => null,
  removeMerchants: () => null,
}

export default TemplateList