import React from "react";
import {
  Avatar,
  Alert,
  Popconfirm,
  Menu,
  DatePicker,
  Icon,
  Tag,
  Typography,
  Spin,
  Calendar,
  Popover,
  Badge,
  Row,
  Col,
  Button, message
} from "antd";
import { groupBy, isEmpty, get, startCase } from "lodash";
import moment from "moment";
import validate from "validate.js";

import Request from "App/Utils/Request.v2";
import Sites from "App/Config/Sites";
const { SubMenu } = Menu;

const windowFeatures = `width=${window.innerWidth *
0.9},height=${window.innerHeight * 0.9}`;
const domainQs = Sites.url.qs;

const { MonthPicker } = DatePicker;
const { Text } = Typography;
const jobStatusMap = [
  {
    status: ["new"],
    icon: "file-add"
  },
  {
    status: ["started", "reopened"],
    icon: "play-square"
  },
  {
    status: ["assigned"],
    icon: "solution"
  },
  {
    status: ["QA1"],
    icon: "user"
  },
  {
    status: ["QA2"],
    icon: "team"
  },
  {
    status: ["pending"],
    icon: "file-sync"
  },
  {
    status: ["finished"],
    icon: "file-done"
  },
  {
    status: ["cancelled"],
    icon: "exception"
  },
  {
    status: ["closed"],
    icon: "file-excel"
  }
];

class CalendarJDS extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      jobs: [],
      tip: "Loading...",
      failed: false,
      hasQsAccess: false
    };
  }

  getRandomColor(tender, commercial, defaultColor = false) {
    let color = {
      tender: "#00bfff",
      commercial: "#3cff00",
      double_up: "#c1c1c1"
    };
    let bg = color.double_up;
    if (tender) bg = color.tender;
    else if (commercial) bg = color.commercial;
    else if (defaultColor) bg = defaultColor;
    return bg;
  }

  getAvatar(tender, commercial, double_up) {
    const init = {
      tender: "Tender",
      commercial: "CM",
      double_up: "DU"
    };
    let avatar;
    if (tender) avatar = init.tender;
    else if (commercial) avatar = init.commercial;
    else if (double_up) avatar = init.double_up;
    else avatar = "Job";
    return avatar;
  }

  elipsisTitle = title => {
    if (title && title.length > 12) {
      let r = title.substring(0, 11);
      return r + "...";
    }
    return title;
  };

  getStatusIcon = val => {
    const indexStatus = jobStatusMap.findIndex(item =>
      item.status.includes(val)
    );
    const found = jobStatusMap[indexStatus];
    return get(found, "icon", "user");
  };

  onRightClick = e => {
    let sel = window.getSelection();
    sel.removeAllRanges();
    return false;
  };

  confirmYES = val => {
    const { userReducer } = this.props;
    let instructed;
    if (!isEmpty(val)) {
      instructed = val[0].instructed_finish_date;
    }
    window.open(
      Sites.url.qs +
        "/report/due-soon/?start_instructed=" +
        instructed +
        "&end_instructed=" +
        instructed +
        "&ItSHPShcIRDYnjKX=" +
        btoa(`${Sites.oauth.id}:${Sites.oauth.secret}`) +
        "&MwFMqLoCBGBDyZwj=" +
        userReducer.token +
        "&lmMgPPePCXurSXjI=" +
        get(userReducer, "user.username"),
      "_blank"
    );
  };

  onDetailClick = id => {
    if (this.state.hasQsAccess) {
      const { userReducer } = this.props;
      const urlTarget =
        domainQs +
        "/job/" +
        id +
        "?ItSHPShcIRDYnjKX=" +
        btoa(`${Sites.oauth.id}:${Sites.oauth.secret}`) +
        "&MwFMqLoCBGBDyZwj=" +
        userReducer.token +
        "&lmMgPPePCXurSXjI=" +
        get(userReducer, "user.username");
      window.open(urlTarget, urlTarget, windowFeatures);
    } else {
      message.info(`Sorry, you don't have access to QS`);
    }
  };

  generateContent = data => {
    const jData = groupBy(data, "job_queue.name");
    return (
      <Menu mode="vertical" style={{ width: "180px" }} selectedKeys={[]}>
        {Object.keys(jData).map((c, i) => (
          <SubMenu
            key={c}
            title={
              <>
                <Avatar size="small" className="mr-2">
                  {jData[c].length}
                </Avatar>
                {this.elipsisTitle(c)}
              </>
            }
          >
            <Menu.ItemGroup title={c}>
              {jData[c].map((val, index) => (
                <Menu.Item
                  key={val.pk}
                  onClick={() =>
                    this.onDetailClick(val.pk)
                  }
                >
                  <Avatar
                    className="mr-2"
                    size="small"
                    style={{
                      backgroundColor: this.getRandomColor(
                        val.tender,
                        val.commercial
                      )
                    }}
                  >
                    {this.getAvatar(val.tender, val.commercial, val.double_up)}
                  </Avatar>
                  <Text type="secondary">#{val.pk}:</Text>
                  {val.name}
                  <Tag className="ml-2" color="#8a4bff">
                    <Icon type={this.getStatusIcon(val.status)} />
                    {startCase(val.status)}
                  </Tag>
                </Menu.Item>
              ))}
            </Menu.ItemGroup>
          </SubMenu>
        ))}
      </Menu>
    );
  };

  onSuccess = res => {
    this.setState({
      jobs: res.data,
      loading: false,
      failed: false
    });
  };

  read() {
    this.setState({ failed: false });
    Request("get", "job-due-soon", {}, {is_training: false}, [], this.onSuccess, e =>
      this.setState({ failed: true })
    );
  }

  componentDidMount() {
    const {userReducer} = this.props
    if(get(userReducer, 'user.permissions', []).includes('jobs.view_job'))
      this.setState({hasQsAccess: true})
    this.read();
  }

  generateDate = (e, s) => {
    const { jobs } = this.state;
    let color = "#6d6d6d";
    let res = moment(e).format("DD");
    if (moment(s).format("MM") !== moment(e).format("MM")) color = "#d3d3d3";
    if (jobs && validate.isArray(jobs)) {
      let findJobs = jobs.filter(
        r =>
          r.instructed_finish_date ===
          moment(e)
            .format("YYYY-MM-DD")
            .toString()
      );
      let colr = '#FFA351FF'
      if(moment(e).format('YYYY-MM-DD') <= moment(new Date()).format('YYYY-MM-DD'))
        colr = '#f34841'
      if (!isEmpty(findJobs)) {
        res = (
          <Popconfirm
            placement="topRight"
            trigger={["contextMenu"]}
            title={"Report at " + get(findJobs[0], "instructed_finish_date")}
            onConfirm={() => this.confirmYES(findJobs)}
            okText="Open"
            icon={<Icon type="file-text" className="text-primary" />}
            onContextMenu={e => {
              e.preventDefault();
              this.onRightClick(e);
            }}
            cancelText="Close"
          >
            <Popover
              placement="right"
              content={this.generateContent(findJobs)}
              title={
                <Typography.Text type="secondary" strong>
                  Job due at {moment(e).format("MMM DD YYYY")}
                </Typography.Text>
              }
            >
              <Badge count={findJobs.length} style={{backgroundColor: colr}}>
                <Avatar
                  style={{
                    backgroundColor: "#ffffff",
                    color: colr,
                    border: "1px solid "+colr
                  }}
                >
                  {moment(e).format("DD")}
                </Avatar>
              </Badge>
            </Popover>
          </Popconfirm>
        );
      } else if (
        moment(e).format("DD MM YY") === moment(new Date()).format("DD MM YY")
      ) {
        res = (
          <Avatar style={{ backgroundColor: "#b1bec7" }}>
            {moment(e).format("DD")}
          </Avatar>
        );
      }
    }

    return (
      <span style={{ fontSize: "13px", color: color, cursor: "pointer" }}>
        <center>{res}</center>
      </span>
    );
  };

  decreseMonths(date, months, onChange) {
    let d = date.getDate();
    date.setMonth(date.getMonth() - +months);
    if (date.getDate() !== d) {
      date.setDate(0);
    }
    onChange(moment(date));
    return date;
  }

  addMonths(date, months, onChange) {
    let d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if (date.getDate() !== d) {
      date.setDate(0);
    }
    onChange(moment(date));
    return date;
  }

  disabledDate() {
    return false;
  }

  render() {
    let { loading, failed, tip } = this.state;
    let i = {};
    if (failed)
      i = {
        indicator: (
          <Icon
            type="api"
            size="large"
            className="text-danger"
            theme="filled"
          />
        )
      };
    if (failed)
      tip = (
        <Button onClick={() => this.read()} type="dashed">
          Try again
        </Button>
      );
    return (
      <div className="site-calendar-demo-card bg-white pb-5">
        {failed && <Alert banner message="Failed to get data" type="error" />}
        <Spin tip={tip} {...i} spinning={loading}>
          <Calendar
            dateFullCellRender={(e, s) => this.generateDate(e, s)}
            fullscreen={false}
            onSelect={e => null}
            headerRender={({ value, type, onChange, onTypeChange }) => {
              return (
                <div style={{ padding: 8 }}>
                  <div style={{ height: "15px" }} className="mt-2 text-center">
                    <Text strong type="secondary">
                      Job due soon
                    </Text>
                  </div>
                  <Row className="mt-2">
                    <Col md={4} className="text-left">
                      <Button
                        onClick={() =>
                          this.decreseMonths(new Date(value), 1, onChange)
                        }
                        size="small"
                        icon="left"
                        shape="circle"
                        className="mt-1"
                        // type="danger"
                      />
                    </Col>
                    <Col md={16} className="text-center">
                      <MonthPicker
                        allowClear={false}
                        value={moment(String(value))}
                        disabledDate={this.disabledDate}
                        onChange={selectedMonth => {
                          value = selectedMonth;
                          onChange(selectedMonth);
                        }}
                      />
                    </Col>
                    <Col md={4} className="text-right">
                      <Button
                        size="small"
                        // type="danger"
                        className="mt-1"
                        icon="right"
                        shape="circle"
                        onClick={() =>
                          this.addMonths(new Date(value), 1, onChange)
                        }
                      />
                    </Col>
                  </Row>
                </div>
              );
            }}
          />
        </Spin>
      </div>
    );
  }
}
export default CalendarJDS;
