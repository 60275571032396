export default [
  {
    title: 'Mode',
    type: 'text',
    data: '',
    required: true,
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'mode',
  }, {
    title: 'Merchant group',
    type: 'SelectQuery',
    data: 'merchantGroups',
    required: true,
    initialValue: '',
    note_text: '',
    selectedKey: 'pk',
    selectedValue: 'name',
    // additional: 'edit|add',
    dataIndex: 'merchant_group',
  }, {
    title: 'Merchant',
    type: 'SelectQuery',
    // required: true,
    data: 'merchants',
    initialValue: '',
    note_text: '',
    selectedKey: 'pk',
    selectedValue: 'name',
    // additional: 'edit|add',
    dataIndex: 'merchant',
    isActive: true, // for SelectQuery Autocomplete
  },
];