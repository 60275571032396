export default [
  {
    title: 'Line',
    dataIndex: 'imported_data.label',
  }, {
    title: 'Label',
    dataIndex: 'label',
  }, {
    title: 'Wrapper Class',
    dataIndex: 'wrapper_class',
  },
]