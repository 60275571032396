import { BaseDetail } from 'App/Component/Bases';
import fields from 'App/Screens/SubModules/JobMg/ConsistencyNote/Management/fields';

class PageAdd extends BaseDetail {
  constructor(props) {
    super(props)

    this.title = 'Consistency Notes'
    this.urlData = 'module-job-consistencyNotes'
    this.fields = fields
    this.state = {
      isCheckingPermission: false,
      isAccessAllowed: true,
    }
  }
}

export default PageAdd