export default {
  "app_label": "jobs",
  "model_name": "merchant",
  "pagination": true,
  "columns": [
    {
      "name": "pk",
      "label": "ID",
      "help_text": null,
      "hidden": true,
      "identifier": true,
      "data_type": "int",
      "search": false,
      "sort": false
    },
    {
      "name": "name",
      "label": "Name",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "ellipsis": true,
      "search": true,
      "sort": true
    },
    {
      "name": "merchant_group",
      "label": "Merchant group",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": null,
      "child_path": "name",
      "search": true,
      "sort": true
    },
    {
      "name": "branch",
      "label": "Branch",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": null,
      "child_path": "name",
      "search": true,
      "sort": true
    },
    {
      "name": "code",
      "label": "Code",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "search": true,
      "sort": true
    },
    {
      "name": "is_active",
      "label": "Is active",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "bool",
      "search": false,
      "sort": true
    },
    {
      "name": "modified",
      "label": "Modified",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "datetime",
      "search": false,
      "sort": true
    }
  ],
  "filter_by": [
    {
      "label": "Custom sku",
      "name": "custom_sku",
      "type": "switch"
    },
    {
      "label": "Email schedule",
      "name": "email_schedule",
      "type": "switch"
    },
    {
      "label": "Service fee",
      "name": "service_fee",
      "type": "switch"
    },
    {
      "label": "Is active",
      "name": "is_active",
      "type": "switch"
    },
    {
      "label": "On hold",
      "name": "on_hold",
      "type": "switch"
    },
    {
      "label": "Branch",
      "name": "branch",
      "type": "api_reference",
      "api": {
        "url_name": "api:admin:multisite:branch:autocomplete",
        "key": "pk",
        "display": "name",
        "pagination": true
      }
    },
    {
      "label": "Operating branch",
      "name": "operating_branch",
      "type": "api_reference",
      "api": {
        "url_name": "api:admin:jobs:operatingbranch:autocomplete",
        "key": "pk",
        "display": "name",
        "pagination": true
      }
    },
    {
      "label": "Merchant group",
      "name": "merchant_group",
      "type": "api_reference",
      "api": {
        "url_name": "api:admin:jobs:merchantgroup:autocomplete",
        "key": "pk",
        "display": "name",
        "pagination": true
      }
    },
    {
      "label": "Extra charge",
      "name": "extra_charge",
      "type": "api_reference",
      "api": {
        "url_name": "api:admin:jobs:extracharge:autocomplete",
        "key": "pk",
        "display": "name",
        "pagination": true
      }
    },
    {
      "label": "Mapping mode",
      "name": "mapping_mode",
      "type": "api_reference",
      "api": {
        "url_name": "api:admin:merchants:mappingmode:autocomplete",
        "key": "pk",
        "display": "name",
        "pagination": true
      }
    }
  ],
  "export_import": null
}