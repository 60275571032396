import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { Row, Col, Icon, Input, Alert } from 'antd'

class EditableHeader extends React.PureComponent {
  constructor(props) {
    super(props)

    this.tempTitle = props.title
    this.state = {
      isEditingTitle: false,
      title: props.title,
      errorMessage: '',
    }
  }

  setEditing = (isEditingTitle, titleParam) => {
    if (this.props.readOnly) return
    const { onSaveTitle } = this.props
    const title = (titleParam || '').trim() || this.state.title
    if (title !== this.state.title) {
      // message.info('Group ' + title  + ' has not been saved, press the save button to save it')
      this.props.onFieldTouched(true)
      // onSaveTitle(title) // belum siap, perhatikan handle errornya.
    }
    this.tempTitle = title
    this.setState({ isEditingTitle, title })
  }

  onTitleChange = (title) => {
    this.setState({ errorMessage: '' })
    this.tempTitle = title
  }

  onTitleKeyDown = (event) => {
    event.persist()
    if (event.keyCode === 27) {
      this.setEditing(false)
    }
  }

  // For parent with ref controller
  setTitle = (title) => {
    this.setState({ title, errorMessage: '' })
  }

  validateFields = () => {
    return this.state.title
  }

  setError = (errorMessage) => {
    this.setState({ errorMessage, isEditingTitle: true })
  }

  componentDidMount() {
    if (this.props.defaultEditing && !this.props.readOnly) {
      setTimeout(() => {
        this.setEditing(true)
      }, 250)
    }
  }

  onBack=(id, isHistory=false)=>{
    const [p] = [this.props]
    if(isHistory)
      p.history.push(`/user-group/group/${id}`)
    else
      p.onBack()
  }

  render() {
    const { readOnly, extra, onBack, params } = this.props
    const { isEditingTitle, title, errorMessage } = this.state
    return (
      <div className='ant-page-header has-footer'>
        <Row type='flex' justify='space-between' gutter={[12, 12]}>
          {/* 300px for extra (right buttons) */}
          <Col style={{ minWidth: '300px', width: 'calc(100% - 300px)' }}>
            <Row type='flex' align='middle'>
              { onBack && <Icon className='ant-page-header-back-button' type='arrow-left' style={{ marginRight: '16px' }} onClick={()=>this.onBack(get(params, 'id'), get(params, 'detail'))} /> }
              {isEditingTitle ? (
                // 30px is width of icon back (14px width, 16px margin)
                <>
                  <Col style={{ width: 'calc(100% - 30px)', maxWidth: '300px', minWidth: '250px' }}>
                    <Input
                      ref={ref => {ref && ref.input.focus()}}
                      className={errorMessage && 'input-has-error'}
                      defaultValue={title}
                      onChange={event => this.onTitleChange(event.target.value)}
                      onBlur={() => this.setEditing(false, this.tempTitle)}
                      onPressEnter={() => this.setEditing(false, this.tempTitle)}
                      onKeyDown={this.onTitleKeyDown}
                      // style={errorMessage ? { borderColor: '#f5222d', boxShadow: '0 0 0 2px rgba(245, 34, 45, 0.2)' } : {}}
                    />
                  </Col>
                  {/* <Col style={{ marginLeft: '12px' }}>
                    <Icon
                      // className='ant-page-header-back-button icon-need-hover'
                      title='Save to temporary'
                      type='check'
                      style={{ fontSize: '16px', color: '#1890ff' }}
                      onClick={() => this.setEditing(false, this.tempTitle)}
                    />
                  </Col>
                  <Col style={{ marginLeft: '12px' }}>
                    <Icon
                      // className='ant-page-header-back-button icon-need-hover'
                      title='Cancel edit Group Name'
                      type='close'
                      style={{ fontSize: '16px', color: '#ff4d4f' }}
                      onClick={() => this.setEditing(false)}
                    />
                  </Col> */}
                </>
              ) : (
                <>
                  {/* 30px is width of icon back, 30px is icon edit */}
                  <Col
                    title={title}
                    style={{
                      fontSize: '20px',
                      fontWeight: 600,
                      marginRight: '12px',
                      maxWidth: 'calc(100% - 30px - 30px)',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      cursor: 'pointer',
                    }}
                    onClick={() => this.setEditing(true)}
                  >
                    {title}
                  </Col>
                  <Col>
                    { readOnly ? (
                      <Col style={{ fontSize: '12px', fontWeight: 500, backgroundColor: '#1890ff', color: '#fff', padding: '0 12px 2px 12px', borderRadius: '14px' }}>
                        Read Only
                      </Col>
                    ) : (
                      <Icon
                        // className='ant-page-header-back-button icon-need-hover'
                        type='edit'
                        style={{ fontSize: '16px', color: '#1890ff' }}
                        onClick={() => this.setEditing(true)}
                      />
                    )}
                  </Col>
                </>
              )}
            </Row>
          </Col>
          <Col>{extra}</Col>
        </Row>
        {errorMessage && (
          <div className='label-error-message' style={{ marginLeft: '30px' }}>
            {errorMessage}
          </div>
        )}
        <div className='ant-page-header-footer'>
          <div style={{ height: '1px', backgroundColor: '#e8e8e8' }} />
        </div>
        { get(params, 'detail') && 
          <Alert
            message={`Revert ${title}`}
            description="Press the save button to revert to this version of the object."
            type="warning"
            showIcon
          />
        }
      </div>
      
    )
  }
}

EditableHeader.propTypes = {
  readOnly: PropTypes.bool,
  defaultEditing: PropTypes.bool,
  title: PropTypes.string,
  onBack: PropTypes.func,
  extra: PropTypes.node,
  onSaveTitle: PropTypes.func,
  onFieldTouched: PropTypes.func,
}

EditableHeader.defaultProps = {
  readOnly: false,
  defaultEditing: false,
  title: 'Group Name',
  onBack: null,
  extra: <span></span>,
  onSaveTitle: () => null,
  onFieldTouched: () => null,
}

export default EditableHeader