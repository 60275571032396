const module = 'branch-queue'
const list = [
  {
    label: 'Branch',
    url: `/${module}/branch`,
    icon: 'branches',
    code: 'multisite.{}_branch',
  },
  {
    label: 'Job Queue',
    url: `/${module}/queue`,
    icon: 'pic-center',
    code: 'jobs.{}_jobqueue',
  },
  {
    label: 'Holiday',
    url: `/${module}/holiday`,
    icon: 'calendar',
    code: 'jobs.{}_holiday',
  },
]

export default { key: module, list }