import React from "react";
import { get, set, compact, isEmpty, startCase, endsWith, cloneDeep, toLower, snakeCase, find } from "lodash";
// import FormSubsection from "../Screens/Estimation/Subsections/FormSubsection";
import { Card, Modal, Row, Col, Button, message } from "antd";
import styled from "styled-components";
// import ComponentAutocomplete from './Autocomplete'
import moment from "moment";
import { RequestV2 as Request, Lib, CheckPermission } from "App/Utils"; // changed to RequestV2 at 24/07/2020
import { PermissionName } from "App/Config"
// import Page404 from "./Singlepage/Page404";
// import PageError from "./Singlepage/PageError";
import PageError from 'App/Component/PageError'
import Lib2 from "./Singlepage/Lib2";
// import { isObject, isString, isArray } from "util";
import PropTypes from "prop-types"
import CoreComponent from "./Singlepage/Tab"
import { forcePermissions } from "App/Data"
import PromptContainer from "./PromptContainer"
// import windowStop from "../Utils/windowStop";

// import DrawerSQ from './DrawerSQ';

const { confirm } = Modal;

class SubAllScreenV2 extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showLoading: props.match.params.id !== 'add', // for fields
      loading: props.match.params.id !== 'add', // for buttons
      tabindex: -1,
      originalDataRecord: null,
      referenceInfo: {},
      // for sub master (table) ~ like table subsection in section
      subheaderData: [],
      cloneSD: [],
      filterSelected: {},
    }

    this.title = props.title
    this.operation = props.match.params.id === 'add' ? 'add' : 'edit'
    this.isFirstPage = get(props, 'location.state.from', []).length === 0
    this.fileList = []
    this.numberMSID = []
    if (!props.location.state) {
      props.location.state = {} // must be
    }
  }

  messageCustom = (type, msg) => {
    if (type !== 'loading') {
      message.destroy()
      this.setThisState({ loading: false })
    } else {
      this.setThisState({ loading: true })
    }

    message[type](msg)
  }

  setThisState = (state, callback = () => null, force) => {
    if (state) {
      if (force === true) {
        this.setState(state, callback)
        return
      } else {
        for (const key in state) {
          if (state[key] !== this.state[key]) {
            this.setState(state, callback)
            return
          }
        }
      }
    }
    callback()
  }

  goTo = (action, url, idData, additionalTitle, dataIdentifier) => {
    if (action === 'activate') { // tidak digunakan lagi
      const index = url // for me, i don't know again :(
      this.props.fields[index].disableChange = false
      // order 1
      set(this.props.location.state, 'dataRecord', this.props.location.state.dataIdentifier)
      // order 2
      this.props.location.state.dataIdentifier = null

      this.props.history.replace(this.props.location.pathname, this.props.location.state)
    } else {
      Lib2.pushFunc(this.props, this.state, action, url, idData, additionalTitle, dataIdentifier, this.referenceDataRequest, this.loadOtherAPIData, this.singleChangeState)
    }
  }

  // toggleDrawerSQ = (operationSQ, urlSQ, idData, keyForm, callback = () => null) => {
  //   const { indexing, historySubDrawer } = this.state
  //   if (operationSQ === 'add' || operationSQ === 'edit') {
  //     this.setState({
  //       visibleDrawerSQ: true,
  //       indexing: indexing + 1,
  //       historySubDrawer: [...historySubDrawer, { historyOperation: operationSQ, url: urlSQ, idData }],
  //     }, () => callback())
  //   } else if (operationSQ === 'back') {
  //     const remainingHistorySubDrawer = historySubDrawer.slice(0, historySubDrawer.length - 1)
  //     this.setState({
  //       visibleDrawerSQ: remainingHistorySubDrawer.length > 0,
  //       indexing: indexing - 1,
  //       historySubDrawer: remainingHistorySubDrawer,
  //     }, () => callback())
  //   } else if (operationSQ === 'close') {
  //     this.setState({ visibleDrawerSQ: false, indexing: -1, historySubDrawer: [] }, () => callback())
  //   }
  // }

  goBack = (withoutConfirm) => {
    if (get(this.props.location.state, 'from', []).length > 0) {
      const previousUrl = this.props.location.state.from.pop()
      const previousState = this.props.location.state.prevState
      this.props.history.push(previousUrl, previousState)
    } else {
      const isOnePath = this.props.location.pathname.slice(0, '/branches/'.length) === '/branches/' || this.props.location.pathname.slice(0, '/products/'.length) === '/products/'
      let masterUrl = Lib2.getMasterUrl(this.props.location.pathname, isOnePath)
      this.props.history.push(masterUrl)
    }
  }

  reload = (keyLoading = 'loading', callback = () => null) => {
    this.setState({ [keyLoading]: true }, () => {
      this.retrieve(this.props.urlData, this.props.match.params.id, isSuccess => {
        if (!isSuccess) callback()
        if (isSuccess && (this.isFirstPage || true)) {
          this.referenceDataRequest(this.props.urlData)
        }
      })
    })
  }

  preRetrieve = (keyLoading, callback = () => null) => {
    this.retrieve(this.props.urlData, this.props.match.params.id, (isSuccess) => {
      if (!isSuccess) {
        callback()
      }
    })
  }

  getUrlData = (urlData, action) => {
    const keys = {
      retrieve: 'detail',
      edit: 'update',
      save: 'read'
    }

    return (
      `${urlData}-${keys[action]}`
    )
  }

  getArgs = (defaultArgs) => defaultArgs

  retrieve = (urlData, id, callback = () => null) => {
    Request(
      'get',
      this.getUrlData(urlData, 'retrieve'),
      {},
      {},
      this.getArgs([id]),
      this.retrieveSuccess,
      this.retrieveFailed,
      { callback },
    )
  }

  retrieveSuccess = (response, extra) => {
    set(this.props.location, 'state.dataRecord', response.data)
    extra.callback(true)
    const children = get(this.props, 'children.filter_selected')
    if(children){
      const filterSelected = {}
      for(const r in children){
        for(const i of children[r]){
          if(response.data[r])
            filterSelected[i] = { [r]: response.data[r].pk}
        }
      }
      this.setState({ filterSelected })
    }
    if (this.popStack()) {
      this.setThisState({ showLoading: false, loading: false, originalDataRecord: response.data, isError: false })
    } else {
      this.setThisState({ originalDataRecord: response.data, isError: false })
    }
  }

  retrieveFailed = (error, extra) => {
    this.setState({ isError: true, errorResponse: get(error, 'response'), showLoading: false, loading: false }, () => {
      extra.callback()
    })
  }

  referenceDataRequest = (urlData, callback = () => null) => {
    Request(
      'get',
      // 'services:descriptor',
      `${this.props.urlData}-describeDetail`,
      {},
      {},
      [],
      this.referenceSuccess,
      this.referenceFailed,
      callback,
      // true
    )
  }

  referenceSuccess = (response, extra) => {
    if (typeof extra === 'function') {
      extra()
    }

    // Filter reference menu -----------
    const isSuperuser = get(this.props.userReducer, ['user', 'is_superuser'])
    const referenceBy = get(response.data, ['reference_by'], [])
    const referenceTo = get(response.data, ['reference_to'], [])
    let referenceByFiltered = this.getReference(referenceBy, this.props.userReducer, isSuperuser)
    let referenceToFiltered = this.getReference(referenceTo, this.props.userReducer, isSuperuser)
    // Replace response for reference menu
    response.data.reference_to = referenceToFiltered
    response.data.reference_by = referenceByFiltered
    // End filter ---------- -----------

    if (this.popStack()) {
      this.setThisState({ showLoading: false, loading: false, referenceInfo: response.data, isError: false })
    } else {
      this.setThisState({ referenceInfo: response.data, isError: false })
    }

    const masterTab = startCase(Lib2.getMasterRelation(this.props.location.pathname, 4))
    if (masterTab && response.data) {
      let activeKey = toLower(masterTab)
      if (get(this.props, 'location.state.urlData') === 'buildings') {
        activeKey = -1
      }
      Lib2.setActivetab(Lib2.getIndex(activeKey, response.data), this.setThisState, this.props)
    }
  }

  // Just for this component, get reference (to/by) filtered by permissions
  // referenceMenus is array of reference by/to
  getReference = (referenceMenus, userReducer, isSuperuser) => {
    const referenceFiltered = []
    for (let i = 0; i < referenceMenus.length; i += 1) {
      if (isSuperuser) {
        this.setReferenceItem(referenceFiltered, referenceMenus[i], forcePermissions)
      } else if (userReducer) {
        const currentPermissionSet = CheckPermission.getPermissionSet({
          userReducer,
          appName: referenceMenus[i].app_label,
          modelName: referenceMenus[i].model_name,
        })
        // filter reference by permission is disabled
        if (true || currentPermissionSet.find(val => val.search(PermissionName.READ.code) !== -1)) {
          this.setReferenceItem(referenceFiltered, referenceMenus[i], currentPermissionSet)
        }
      }
    }
    return referenceFiltered
  }

  // reference item akan dimasukkan dalam paramenter variable "reference"
  setReferenceItem = (reference = [], referenceItem, permissions = []) => {
    if (referenceItem && typeof referenceItem === 'object') {
      reference.push({
        label: referenceItem.label,
        url_name: referenceItem.url_name,
        permissions
      })
    }
  }

  referenceFailed = error => {
    if (this.popStack()) {
      this.setThisState({ showLoading: false, loading: false })
    }
  }

  // Static function, not for public use
  popStack = () => {
    return (this.tempStackRequest -= 1) <= 0
  }

  setTouched = (isTouched, callback = () => null) => {
    if (this._prompt) {
      this._prompt.setTouched(isTouched, callback)
    }
  }

  onBlur = (value, dataIndex, type='text', form, previousValue) => {
    let valueConverted = value
    const force = type === 'checkbox'
    if (type === 'number' && !valueConverted && valueConverted !== 0 && form) {
      const field = this.props.fields.find(field => field.dataIndex === dataIndex)
      form.setFieldsValue({ [dataIndex]: null })
      // form.setFieldsValue({ [dataIndex]: get(field, 'initialValue', 0) })
    }

    if (type === 'date') {
      valueConverted = moment(valueConverted).format('YYYY-MM-DD')
    } else if (type === 'datetime') {
      valueConverted = moment(valueConverted).format('YYYY-MM-DD HH:mm:ss')
    }

    // Set touched
    if (!value && !previousValue) return
    if (value !== previousValue) {
      this.setTouched(true)
    }
    // -----------

    this.recordPage({ title: dataIndex, value: valueConverted }, force)
  }

  recordPage = (objValue, force) => {
    const dataRecord = get(this.props.location.state, 'dataRecord')
    if (force === true || Lib2.isChange(dataRecord, objValue)) {
      set(this.props.location, `state.dataRecord.${objValue.title}`, objValue.value)
      // Jangan di hapus
      // this.props.history.replace(
      //   this.props.location.pathname,
      //   this.props.location.state
      // )
    }
  }

  bulkSendData = (setting, res, hold, formData) => {
    let data = []
    for(const i of formData) {
      data.push(i.disk)
    }
    // const subheaderData = cloneDeep(this.state.subheaderData)
    // const {cloneSD} = this.state
    // for (const index in subheaderData) {
    //   const obj = subheaderData[index]
    //   if(obj.form.isFieldsTouched()) {
    //     if (!Lib2.isEmptyform(obj.form)) {
    //       if (endsWith(setting.name, 'es')) {
    //         obj[snakeCase(setting.name.slice(0, -2))] = res.data.pk
    //       } else if (endsWith(setting.name, 's')) {
    //         obj[snakeCase(setting.name.slice(0, -1))] = res.data.pk
    //       } else {
    //         obj[snakeCase(setting.name)] = res.data.pk
    //       }
    //       obj.form.validateFields((err, values) => {
    //         for (let key in values) {
    //           if (typeof values[key] === 'object') {
    //             obj[key] = values[key].key
    //           } else {
    //             obj[key] = values[key]
    //           }
    //         }
    //       })
    //       let f = cloneSD.find(e => e.key === obj.pk)
    //       if(f)
    //         obj.pk = f.pk
    //       else
    //         delete obj.pk
    //       delete obj.form
    //     }
    //   }else {
    //     delete subheaderData[index]
    //   }
    // }
    if (setting.sub) {
      Request(
        'post',
        `${setting.sub}-create-bulk`,
        {},
        data,
        [],
        this.overrideBSID,
        this.failedBSID,
        {...hold, data: formData}
      )
    }
  }

  failedBSID=(e, r)=>{
    this.setTouched(true)
    const s = this.state
    this.setState({loading: false})
    message.destroy()
    let res = get(e, 'response.data', [])
    if(!isEmpty(r.data)){
      for(const i in res){
        if(!isEmpty(res[i]) && r.data[i]){
          let key = r.data[i].pk
          if(key){
            let ds = s.subheaderData.find(es => es.pk === key) 
            if(ds){
              for(const n in res[i]){
                let value = ds.form.getFieldValue(n)
                ds.form.setFields({
                  [n]: {
                    value,
                    touched: true,
                    errors: [new Error(res[i][n])]
                  }
                })
              }
            }
            
          }
        }
      }
    }
    message.error(`Failed to save data rows in table, please check your data.`)
  }

  overrideBSID = (val, hold) => {
    const { subheaderData } = this.state
    let data = Lib2.clearEmptyArrayForm(subheaderData).filter(item => !item.is_empty)
    if (data.length >= 0) {
      for (const j in val.data) {
        if (data[j]) {
          val.data[j].key = data[j].pk
          if (val.data[j].relation || val.data[j].relation !== null) {
            data[j].form.setFieldsValue({
              relation: val.data[j].relation
            })
          }
        }
      }
      data.push({pk: Lib.uuid()})
      let alert = 'Edit data was changed successfully.'
      let cloneSD = val.data
      if(hold.saa) {
        this.props.location.state = {
          ...this.props.location.state,
          dataRecord: undefined
        }
        alert = `Save successfully.`
        data = []
        cloneSD = []
      }
      if(!hold.sac && !hold.saa)
        this.goBack(true)
      else
        this.setThisState({ subheaderData: data, cloneSD })
      this.messageCustom('success', alert)
    }
  }

  multiSendData = (setting, res, hold, formData) => {
    for (const val of formData) {
      let l = formData.filter(e => e.touched)
      if(val.touched)
        Request(
          val.method,
          `${setting.sub}${val.action}`,
          {},
          val.disk,
          [val.esl && val.esl.pk],
          this.overrideMSID,
          this.failedMSID,
          {key: val.pk, hold, number: l.length, set: setting}
        )
    }
  }

  overrideMSID = (val, extra) => {
    this.numberMSID.push({hasError: false})
    let cloneSD = [...this.state.cloneSD]
    let SD = this.state.subheaderData
    let xs = cloneSD.findIndex(e => e.key === extra.key)
    if(xs === -1){
      val.data.key = extra.key
      cloneSD.push(val.data)
      this.setState({cloneSD})
    }else if(xs >= 0 && val.status===204){
      let xm = SD.findIndex(e=>e.pk === extra.key)
      if(xm >=0) {
        delete cloneSD[xs]
        delete SD[xm]
        this.setState({ subheaderData: compact(SD), cloneSD: compact(cloneSD) })
      }
    }
    const sData = find(SD, {pk: extra.key})
    if(sData && val.data.relation){
      sData.form.setFieldsValue({
        relation: val.data.relation
      })
    }
    let data = Lib2.clearEmptyArrayForm(SD).filter(e => !e.is_empty)
    if (!(this.props.urlData === 'branches' && data.length >= 2)) {
      data.push({pk: Lib.uuid()})
    }

    if(extra.number === this.numberMSID.length){
      const hasError = this.numberMSID.find(e=> e.hasError)
      this.numberMSID = []
      let alert = 'Save data successfully.'
      if(extra.hold.title)
        alert = `Edit ${extra.hold.title} was changed successfully.`
      if(hasError) {
        this.messageCustom('error', hasError.message)
        return null
      }
      if(extra.hold.saa) {
        alert = 'Save data successfully.'
        data = []
        cloneSD = []
        let permission = Lib2.getSubheader(get(extra, 'set.name'))
        if(permission && permission.permissions.rowfixed){
          for(let i = 1; i <= permission.permissions.initialrow; i++ ){
            data.push({pk: Lib.uuid()})
          }
        }
        this.props.location.state.dataRecord = null
      }
      if(!extra.hold.saa && !extra.hold.sac) {
        this.goBack(true)
      }else
        this.setState({ subheaderData: data, cloneSD })
      this.messageCustom('success', alert)
    }
  }

  failedMSID = (error, extra) => {
    let errMsg = 'Something went wrong'
    if (get(error, 'response.data.detail') === 'Connection to Server Failed!') {
      errMsg = error.response.data.detail
    } else if (get(error, 'response.data')) {
      const key = Object.keys(error.response.data)
      errMsg = error.response.data[key] && error.response.data[key][0]
    }
    this.numberMSID.push({ hasError: true, message: errMsg })
    message.destroy()
    Lib.errorMessageHandler(error.response)
    if (extra.number === this.numberMSID.length) {
      this.numberMSID = []
      this.setThisState({ loading: false })
    }
  }

  addSubheaderData = () => {
    const { subHeaderData } = this.state
    if (!(this.props.urlData === 'branches' && subHeaderData.length >= 2)) {
      this.setState({ subheaderData: [{pk: Lib.uuid()}] })
    }
  }

  setThisTitle = (title, subtitle) => {
    if (title) {
      this.title = (
        <>
          {startCase(title)}
          <span style={{ fontSize: '14px', fontStyle: 'italic', color: 'rgba(0, 0, 0, 0.45)' }}>
            {subtitle}
          </span>
        </>
      )
    }
  }

  // Cannot set value of key in object props and state
  // like props.abc = 7 (cannot), but props.abc.key = 7 (is can) jika key "abc" sudah ada sebelumnya
  static getDerivedStateFromProps(props, state) {
    const dataIdentifier = get(props.location.state, 'dataIdentifier')
    if (dataIdentifier) {
      // const keys = Object.keys(dataIdentifier)
      for (const key in dataIdentifier) {
        for (const index in props.fields) {
          if (props.fields[index].dataIndex === key) {
            // Set the field to disabled
            // props.fields[index].disableChange = true
            // props.fields[index].isTable = true
            set(props.location, `state.dataRecord.${key}`, dataIdentifier[key])
            // Set Title
            // const subtitle = ` - for ${startCase(keys[0])} "${dataIdentifier[keys[0]].name || dataIdentifier[keys[0]].label}"`
            // gak bisa :(, karena tidak bisa akses "this"
            // this.setThisTitle(props.title, subtitle)
            break
          }
        }
      }
    }
    return null
  }

  componentDidMount() {
    if (this.operation === 'edit') {
      this.tempStackRequest = 1
      const dataRecord = get(this.props.location.state, 'dataRecord')
      if (dataRecord) {
        const callback = () => {
          if (this.popStack()) {
            this.setThisState({ showLoading: false, loading: false })
          }
        }
        if ((this.isFirstPage || true) && !this.props.match.params.historyId) {
          this.referenceDataRequest(this.props.urlData, () => {
            callback()
          })
        } else {
          callback()
        }
      } else {
        if ((this.isFirstPage || true) && !this.props.match.params.historyId) {
          this.tempStackRequest = 2
          this.reload()
        } else {
          this.retrieve(this.props.urlData, this.props.match.params.id)
        }
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    // Set title in reference
    this.title = nextProps.title
    if (this.isFirstPage || true) {
      if (this.operation === 'edit') {
        if (parseInt(nextState.tabindex) !== -1 && !isNaN(parseInt(nextState.tabindex))) {
          let titleReference = ''
          if(parseInt(nextState.tabindex) >= 1000) {
              titleReference = nextState.referenceInfo.reference_to[nextState.tabindex-1000].label
          }else{
              titleReference = nextState.referenceInfo.reference_by[nextState.tabindex].label
          }
          const subtitle = ` - for ${nextProps.title} "${get(nextProps.location.state, `dataRecord.${nextProps.bulkName}`)}"`
          this.setThisTitle(titleReference, subtitle)
        }
      }
    }
    return true
  }

  render() {
    const { isError, loading, filterSelected, errorResponse, tabindex, cloneSD, subheaderData, showLoading, referenceInfo } = this.state
    return (isError) ? (
      <PageError
        errorResponse={errorResponse}
        onGoBack={this.goBack}
        onReload={this.reload}
      />
      // <PageError
      //   history={this.props.history}
      //   buttonComponent={
      //     <Row type='flex' justify='center' gutter={[7, 7]}>
      //       <Col>
      //         <Button icon='arrow-left' type='primary' size='large' onClick={this.goBack}>Go Back</Button>
      //       </Col>
      //       <Col>
      //         <Button loading={showLoading} icon='reload' type='primary' size='large' onClick={this.reload}>Reload</Button>
      //       </Col>
      //     </Row>
      //   }
      // />
    ) : (
      <RoundedCard title={this.title}>
        <PromptContainer ref={ref => {this._prompt = ref}} startsWith={this.props.match.url} />
        <CoreComponent
          {...this.props}
          showLoading={showLoading} // for fields
          loading={loading} // for buttons
          tabindex={tabindex}
          operation={this.props.match.params.id === 'add' ? 'add' : 'edit'}
          subheaderData={subheaderData}
          goTo={this.goTo}
          goBack={this.goBack}
          isFirstPage={this.isFirstPage}
          dataRecord={get(this.props.location.state, 'dataRecord')}
          referenceInfo={referenceInfo}
          setThisState={this.setThisState}
          messageCustom={this.messageCustom}
          onBlur={this.onBlur}
          dataidentifier={{}}
          setTouched={this.setTouched}
          onReload={this.reload}
          getUrlData={this.getUrlData}
          getArgs={this.getArgs}
          // for sub master (table)
          cloneSD={cloneSD}
          filterSelected={filterSelected}
          bulkSendData={this.bulkSendData}
          multiSendData={this.multiSendData}
          addSubheaderData={this.addSubheaderData}
        />
      </RoundedCard>
    )
  }
}

SubAllScreenV2.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  userReducer: PropTypes.object.isRequired,
}

export default SubAllScreenV2;

const RoundedCard = styled(Card)`
  // border-radius: 12px !important;
  border: 0px !important;
  margin-bottom: 12px !important;
`;
