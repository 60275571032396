import React from "react";
import { Icon, Tag } from "antd";

class SorterTag extends React.PureComponent {
	state = {
		isChecked: !!this.props.default,
		isAscend: !this.props.default
	};

	onClick = () => {
		this.setState(
			{
				isChecked: this.props.isToggleable ? !this.state.isChecked : true,
				isAscend: !this.state.isAscend
			},
			() =>
				this.props.onChange(
					this.props.value,
					this.state.isChecked,
					this.state.isAscend
				)
		);
	};

	onClose = e => {
		e.stopPropagation();
		this.setState(
			{
				isChecked: false
			},
			() =>
				this.props.onChange(
					this.props.value,
					this.state.isChecked,
					this.state.isAscend
				)
		);
	};

	render() {
		const p = this.props;
		const s = this.state;

		return (
			<Tag
				onClick={() => this.onClick()}
				color={s.isChecked && "#108ee9"}
				className="mr-0"
			>
				{p.label}&nbsp;
				{!p.isToggleable && s.isChecked && (
					<>
						<Icon type={s.isAscend ? "caret-down" : "caret-up"} />
						<Icon type="close" onClick={e => this.onClose(e)} />
					</>
				)}
			</Tag>
		);
	}
}

SorterTag.defaultProps = {
	onChange: () => null,
	isToggleable: false
};

export default SorterTag;
