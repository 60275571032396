import { message } from 'antd'
import SubAllScreen from 'App/Component/SubAllScreen'

class HistoryDetail extends SubAllScreen {
  goTo = () => {
    message.info('Cannot open in history mode')
  }

  getUrlData = (urlData, action) => `${urlData}-detail-revision-version`

  getArgs = () => [this.props.match.params.id, this.props.match.params.historyId]
}

export default HistoryDetail