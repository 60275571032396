import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Spin, Icon } from 'antd'

class LoadingSpin extends React.PureComponent {
  render() {
    const { height, tip } = this.props
    return (
      <Row type='flex' justify='center' align='middle' style={{ height }}>
        <Col>
          <Spin
            size='large'
            tip={tip}
            indicator={<Icon type='loading' style={{ fontSize: '4rem' }} spin />}
          />
        </Col>
      </Row>
    )
  }
}

LoadingSpin.propTypes = {
  height: PropTypes.string,
  tip: PropTypes.string,
}

LoadingSpin.defaultProps = {
  height: '100vh',
  tip: 'Preparing...',
}

export default LoadingSpin