import React from "react";
import { Tabs, Icon } from "antd";
import { BaseList, BaseDetail } from "App/Component/Bases";
import fields from "./fields"
import AbbreviatedInformation from "./AbbreviatedInformation";
import descriptor from "./descriptor";
import options from "./options";

const { TabPane } = Tabs;

const title = "Formulas";
const urlData = "formulas";
const bulkName = "name";

class List extends BaseList {
  constructor(props) {
    super(props)

    this.title = title;
    this.urlData = urlData;
    this.bulkName = bulkName;
    this.propsList = {
      descriptor,
      exportImport: options.exportImportOptions,
    }
  }
}

class Detail extends BaseDetail {
  constructor(props) {
    super(props)

    this.title = title;
    this.urlData = urlData;
    this.bulkName = bulkName;
    this.fields = fields;
    this.propsDetail = {
      getAnotherTabPanes: this.getFormulaInformation,
      getAnotherTabPanesAdd: this.getFormulaInformation,
      urlKeyHistory: 'api:admin:estimation:formula:detail-revision',
      keyLabel: 'name',
    }
  }

  getFormulaInformation = () => {
    return (
      <TabPane
        key='information'
        tab={
          <span>
            <Icon type='circle-information' />
            Abbreviated Information
          </span>
        }
      >
        <AbbreviatedInformation /> 
      </TabPane>
    )
  }
}

class HistoryDetail extends Detail {
  constructor(props) {
    super(props)

    this.propsDetail = null
  }
}

export default { List, Detail, HistoryDetail };