import PageMenu from "App/Component/PageMenu";

// ======= COMPONENT SCREEN
import Countries from "./Countries/";
import Provinces from "./Provinces";
import Cities from "./Cities";

const module = "admin";
const app = "geo";
const routes = [
  {
    name: "Geo Menu",
    path: `/${module}/${app}`,
    component: PageMenu,
    exact: true
  },
  {
    // title: "Countries",
    routes: [
      {
        name: "Countries",
        component: Countries.List,
        props: { urlName: "api:admin:geo:country:list" },
      },
      {
        name: "Countries History",
        component: Countries.Detail,
        path: "/admin/geo/countries/:id/history/:historyId",
      },
      {
        name: "Countries",
        component: Countries.Detail,
        exact: false,
        props: { urlName: "api:admin:geo:country:detail" },
      },
      {
        name: "Provinces",
        component: Provinces.List,
        props: { urlName: "api:admin:geo:province:list" },
      },
      {
        name: "Provinces",
        component: Provinces.Detail,
        path: "/admin/geo/provinces/:id/history/:historyId",
      },
      {
        name: "Provinces",
        component: Provinces.Detail,
        exact: false,
        props: { urlName: "api:admin:geo:province:detail" },
      },
      {
        name: "Cities",
        component: Cities.List,
        props: { urlName: "api:admin:geo:city:list" },
      },
      {
        name: "Cities",
        component: Cities.Detail,
        path: "/admin/geo/cities/:id/history/:historyId",
      },
      {
        name: "Cities",
        component: Cities.Detail,
        exact: false,
        props: { urlName: "api:admin:geo:city:detail" },
      },
    ]
  }
]

export default { appName: "Geo", routes };