export default {
  "app_label": "estimation",
  "model_name": "prefillline",
  "pagination": true,
  "columns": [
    {
      "name": "pk",
      "label": "ID",
      "help_text": null,
      "hidden": true,
      "identifier": true,
      "data_type": "int",
      "search": false,
      "sort": false
    },
    {
      "name": "prefill_set",
      "label": "Prefill set",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": null,
      "child_path": "name",
      "search": true,
      "sort": true
    },
    {
      "name": "line_template",
      "label": "Line template",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": null,
      "child_path": "label",
      "search": true,
      "sort": true
    },
    {
      "name": "product",
      "label": "Product",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": null,
      "child_path": "name",
      "search": true,
      "sort": true
    },
    {
      "name": "relation",
      "label": "Relation",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "int",
      "search": true,
      "sort": true
    },
    {
      "name": "modified",
      "label": "Modified",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "datetime",
      "search": false,
      "sort": true
    }
  ],
  "filter_by": [
    {
      "label": "Section",
      "name": "section",
      "type": "api_reference",
      "api": {
        "url_name": "api:admin:estimation:section:autocomplete",
        "key": "pk",
        "display": "name",
        "pagination": true
      }
    },
    {
      "label": "Subsection",
      "name": "subsection",
      "type": "api_reference",
      relates: ['section'],
      "api": {
        "url_name": "api:admin:estimation:subsection:autocomplete",
        "key": "pk",
        "display": "identifier",
        "pagination": true
      }
    },
    {
      "label": "Product",
      "name": "product",
      "type": "api_reference",
      "api": {
        "url_name": "api:admin:estimation:product:autocomplete",
        "key": "pk",
        "display": "name",
        "pagination": true
      }
    },
    {
      "label": "Product Status",
      "name": "product_status",
      "allowEmpty": false,
      "delimiter": ",",
      "type": "choice",
      "choices": [
        ["active", "Active"],
        ["inactive", "Inactive"],
        ["unpublished", "Unpublished"],
      ],
    },
    {
      "label": "Usage",
      "name": "usage",
      "type": "api_reference",
      "api": {
        "url_name": "api:admin:estimation:lineusage:autocomplete",
        "key": "pk",
        "display": "label",
        "pagination": true
      }
    },
    {
      "label": "Subheading",
      "name": "subheading",
      "type": "api_reference",
      "api": {
        "url_name": "api:admin:estimation:subheading:autocomplete",
        "key": "pk",
        "display": "label",
        "pagination": true
      }
    },
    {
      "label": "Line template",
      "name": "line_template",
      "type": "api_reference",
      relates: ['subsection'],
      "api": {
        "url_name": "api:admin:estimation:linetemplate:autocomplete",
        "key": "pk",
        "display": "label",
        "pagination": true
      }
    },
    {
      "label": "Prefill set",
      "name": "prefill_set",
      "type": "api_reference",
      relates: ['subsection'],
      "api": {
        "url_name": "api:admin:estimation:prefillset:autocomplete",
        "key": "pk",
        "display": "name",
        "pagination": true
      }
    }
  ],
  "export_import": null
}