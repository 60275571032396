const roles = {
  job: 'Shared Job Queue',
  qa: 'Shared QA Queue',
}

const descriptor = {
  pagination: true,
  filter_by: [
    {
      name: 'role',
      label: 'Role',
      singleChoices: [
        ['', 'All'],
        ['job', 'Shared Job Queue'],
        ['qa', 'Shared QA Queue']
      ]
    },
    {
      name: 'branch',
      label: 'Branch',
      api: {
        url_name: 'branches-autocomplete',
        key: 'pk',
        display: 'name',
      }
    },
  ],
  columns: [
    {
      "name": "name",
      "label": "Name",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "ellipsis": true,
      "search": true,
      "sort": true
    },
    {
      "name": "branch",
      "label": "Branch",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": null,
      "child_path": "name",
      "search": true,
      "sort": true
    },
    {
      "name": "role",
      "label": "Role",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "search": true,
      "sort": true,
      render: (val) => roles[val] || val,
    },
    {
      "name": "default_for_branch",
      "label": "Default for branch",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "bool",
      "search": false,
      "sort": true
    },
    {
      "name": "total_job",
      "label": "Total job",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "int",
      "search": true,
      "sort": true
    },
    {
      "name": "modified",
      "label": "Modified",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "datetime",
      "search": false,
      "sort": true
    }
  ]
}

export default descriptor