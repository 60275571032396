import React from 'react'
import { Button, Card, Col, Row, Select } from 'antd'
import UsedLineTemplate from './UsedLineTemplate'
import UsedStat from './UsedStat'

const { Option } = Select

class ProductUsage extends React.PureComponent {
  constructor(props) {
    super(props)

    this.filters = [
      { key: '30d', label: '30 Days' },
      { key: '60d', label: '60 Days' },
      { key: '90d', label: '90 Days' },
    ]

    this.state = {
      limitDays: this.filters[0].key,
    }
  }

  onDayChange = (limitDays) => {
    this.setState({ limitDays })
  }

  setUsedStat = (usedStat) => {
    this.setState({ totalUsage: usedStat.all_times_usage })
  }

  render() {
    return (
      <div>
        <Row type='flex' justify='space-between' style={{ marginBottom: '12px' }}>
          <Col style={{ fontSize: '21px' }}>
            <span style={{ fontWeight: 300, display: 'inline-block', marginRight: '4px' }}>
              Total Usage All Time:
            </span>
            <b>{this.state.totalUsage}</b>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
            <Row type='flex'>
              <Col span={8}>
                <Button disabled className='group-left' style={{ width: '100%', padding: '0 7px' }}>
                  Show:
                </Button>
              </Col>
              <Col span={16}>
                <Select
                  value={this.state.limitDays}
                  className='group-right'
                  style={{ width: '100%', marginLeft: '-1px' }}
                  onChange={this.onDayChange}
                >
                  {this.filters.map(filter => (
                    <Option key={filter.key} value={filter.key}>
                      {filter.label}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </Col>
          {/* <Col>
            <Button loading={isLoading} icon='reload' onClick={this.read}>Refresh</Button>
          </Col> */}
        </Row>
        <Row type='flex' gutter={[12, 12]}>
          <Col xs={24} sm={24} xxl={10} style={{ height: 'auto' }}>
            <Card bodyStyle={{ padding: '12px' }}>
              <UsedStat
                productId={this.props.productId}
                limitDays={this.state.limitDays}
                setUsedStat={this.setUsedStat}
              />
            </Card>
          </Col>
          <Col xs={24} sm={24} xxl={14} style={{ height: 'auto' }}>
            <Card bodyStyle={{ padding: '12px' }}>
              <UsedLineTemplate
                productId={this.props.productId}
                // limitDays={this.state.limitDays}
              />
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

export default ProductUsage