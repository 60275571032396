import React from "react";
import PropTypes from "prop-types";
import { Col, Icon, List, Popover, Row, Switch, Spin } from "antd";
import { get } from 'lodash'
import CustomTransfer from 'App/Screens/SubModules/UserGroup/User/Management/Component/CustomTransfer'
import ErrorLabel from 'App/Screens/SubModules/UserGroup/Group/Management/Pages/ErrorLabel'

class CommonPermission extends React.PureComponent {
  constructor(props) {
    super(props)

    this.groupTitles = ['Available Groups', 'Chosen Groups']
    this.checkboxValues = {
      is_active: props.user.is_active,
      is_staff: props.user.is_staff,
      is_superuser: props.user.is_superuser,
    }
    this.state = {
      errors: {}
    }
  }

  renderGroupItem = (record) => {
    return (
      <Popover
        trigger='contextMenu'
        content={this.getContextMenuGroup(record)}
        overlayClassName='custom-popover-content'
        placement='topLeft'
      >
        <div style={{ display: 'inline-block', width: 'calc(100% - 28px' }}>
          {record.name}
        </div>
      </Popover>
    )
  }

  getContextMenuGroup = (record) => (
    <ul className='ant-dropdown-menu ant-dropdown-menu-light ant-dropdown-menu-root ant-dropdown-menu-vertical'>
      <li
        className='ant-dropdown-menu-item'
        onClick={(event) => {
          event.stopPropagation()
          window.open(`/user-group/group/${record.pk}`)
        }}
      >
        Open Group: <b>{record.name}</b>
      </li>
    </ul>
  )

  // untuk parent dengan ref, jangan dihapus
  validateFields = () => {
    const groups = this._group.validateFields()
    return { ...this.checkboxValues, groups }
  }

  onChangeStatus = (values) => {
    this.props.onFieldTouched(true)
    this.checkboxValues = { ...this.checkboxValues, ...values }
    for (const key in values) {
      if (this.state.errors[key]) {
        // once setState
        return this.setState(prevState => ({ errors: { ...prevState.errors, [key]: '' } }))
      }
    }
  }

   // Set error to single field
   setError = (objError) => {
    if (typeof objError === 'object') {
      for (const key in objError) {
        this.setState(prevState => ({ errors: { ...prevState.errors, [key]: objError[key][0] } }))
      }
    }
  }

  onGroupsTouched = (isFieldsTouched) => {
    this.props.onFieldTouched(isFieldsTouched)
    if (this.state.errors.groups) {
      this.setState(prevState => ({ errors: {...prevState.errors, groups: ''} }))
    }
  }

  render() {
    const { readOnly, user, userHelper } = this.props;
    const { errors } = this.state
    
    return (
      <Spin spinning={false}>
        <h2 className="mb-3">Common Permission</h2>
        {errors.is_active && <ErrorLabel errorMessage={errors.is_active} />}
        {errors.is_staff && <ErrorLabel errorMessage={errors.is_staff} />}
        {errors.is_superuser && <ErrorLabel errorMessage={errors.is_superuser} />}
        <Row className="mb-3">
          <Col>
            <List
              itemLayout="horizontal"
              dataSource={[
                {
                  title: "Active",
                  desc:
                    "Designates whether this user should be treated as active. Unselect this instead of deleting accounts.",
                  status: user.is_active,
                  key: 'is_active',
                },
                {
                  title: "Staff",
                  desc:
                    "Designates whether the user can log into IBES.",
                  status: user.is_staff,
                  key: 'is_staff',
                },
                {
                  title: "Superuser",
                  desc:
                    "Designates that this user has all permissions without explicitly assigning them.",
                  status: user.is_superuser,
                  key: 'is_superuser',
                }
              ]}
              renderItem={item => (
                <List.Item
                  actions={[
                    <Switch
                      disabled={readOnly}
                      checkedChildren={<Icon type="check" />}
                      unCheckedChildren={<Icon type="close" />}
                      defaultChecked={item.status}
                      onChange={(checked) => this.onChangeStatus({ [item.key]: checked })}
                    />
                  ]}
                >
                  <List.Item.Meta title={item.title} description={item.desc} />
                </List.Item>
              )}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: '24px' }}>
          {errors.groups && <ErrorLabel errorMessage={'(Chosen Groups) ' + errors.groups} />}
          <div style={{ fontSize: '16px', fontWeight: 500, marginBottom: '12px' }}>
            Groups <span style={{ color: '#eb2f96', fontSize: '11px' }}>*right click on a Group to open in new tab</span>
          </div>
          <CustomTransfer
            readOnly={readOnly}
            ref={ref => {this._group = ref}}
            titles={this.groupTitles}
            dataSource={get(userHelper, 'group', [])}
            initialValue={get(user, 'groups', [])}
            setTouched={this.onGroupsTouched}
            render={this.renderGroupItem}
          />
        </Row>
      </Spin>
    );
  }
}

CommonPermission.propTypes = {
  readOnly: PropTypes.bool,
  user: PropTypes.object,
  onFieldTouched: PropTypes.func,
}

CommonPermission.defaultProps = {
  readOnly: false,
  user: {},
  onFieldTouched: () => null,
}

export default CommonPermission;
