import React, { Children } from "react";
import { Lib, RequestV2 as Request } from "App/Utils";
import {
  Table,
  Input,
  Row,
  Col,
  Button,
  Form,
  Select,
  Tooltip,
  Icon,
} from "antd";
import { get, cloneDeep } from "lodash";
import {
  EditableFormRow,
  EditableContext,
} from "App/Component/Edit/EditableRowV2";
import SelectQuery from "App/Component/SelectQuery";
import { v4 as uuidv4 } from "uuid";

const { Option } = Select;
const FormItem = Form.Item;

class EditableCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      record: {},
      compareValueLength:[]
    };
  }
  handleChange = (value, dataIndex, record) => {
    // console.log("val", value, record);
    if (dataIndex === "group" || dataIndex === "comparer") {
      this.props.onChangeTable(value, dataIndex, record);
      if (dataIndex === "comparer") {
        record.form.setFieldsValue({ compare_value: [undefined] });
      }
    } else {
      this.forceUpdate();
    }
  };

  onChange(value) {
    this.forceUpdate();
  }

  comparerAdd(record, comparer, dataIndex,coba) {
    if (dataIndex === "comparer") {
      for (let i = 0; i < comparer.length; i++) {
        if (record.comparer === comparer[i].comparer.name) {
          record.comp = {
            ...comparer[i].comparer,
            key: comparer[i].comparer.desc,
            label: comparer[i].comparer.label,
            datakeI: comparer[i].comparer.min_comparer_length
          };
        }
      }
    } else if (dataIndex === "group") {
      for (let i = 0; i < comparer.length; i++) {
        if (record.childObj.parentObj.key === comparer[i].childObj.key) {
          record.groupVal = {
            ...comparer[i],
            key: comparer[i].identifier,
            label: comparer[i].childObj.key,
          };
        }
      }
    }
  }

  comparerValue(getFieldDecorator, record) {
    // console.log("comparerValue")
    const { dataIndex, title, colKey, tipe, comparer, requiredFields } =
    this.props;
    // console.log("comparerValue", record);
    let comapreValue = [];
    if (record.compare_value !== "" && record.compare_value !== null) {
      comapreValue = record.compare_value.split(/[;,]+/);
    }
    record.comapareValueLength = []
    const coba = [];
    const comp = get(record, "comp", { min_comparer_length: 2 });
    let datakeI = 0;
    if (comapreValue.length > comp.datakeI) {
      datakeI = comapreValue.length;
    } else datakeI = comp.datakeI;
    // if (comp.max_comparer_length !== null) {
    //   datakeI = comp.max_comparer_length;
    // }
    if (comp.multi_comparer === true) {
      for (let i = 0; i < datakeI; i++) {
        coba.push(
          <div key={`compare_value[${i}]`}>
            <Row>
              <Col span={20}>
                <FormItem span={24}>
                  {getFieldDecorator(`compare_value[${i}]`, {
                    rules: [
                      {
                        required: requiredFields,
                        message: "This field may not be blank",
                      },
                    ],
                    initialValue: comapreValue[i],
                  })(<Input onChange={(e) => this.onChange(e)} />)}
                </FormItem>
              </Col>
              {datakeI > comp.min_comparer_length && (
                <Col span={4}>
                    <Button
                      size="small"
                      icon="delete"
                      type="danger"
                      style={{marginTop: "8px", marginLeft: "2px"}}
                      onClick={() => this.props.onDeletecompareValue(record,i)}
                    />
                </Col>
              )}
            </Row>
          </div>
        );
      }
      // record.comapareValueLength = coba
    } else {
      for (let i = 0; i < 1; i++) {
        coba.push(
          <div key="identifier">
             <Col span={20}>
            <FormItem span={24}>
              {getFieldDecorator(`compare_value[${i}]`, {
                rules: [
                  {
                    required: comp.need_comparer_value === false ? false : true,
                    message: "This field may not be blank.",
                  },
                ],
                initialValue: comapreValue[i],
              })(
                <Input
                  disabled={comp.need_comparer_value === false ? true : false}
                  onChange={(e) => this.onChange(e)}
                  // value={comp.need_comparer_value === false ? "" : [undefined]}
                />
              )}
            </FormItem>
            </Col>
          </div>
        );
      }
      // record.comapareValueLength = coba
    }

    // if (comp.need_comparer_value === false) {
    //   return null;
    // } else
    // record.comapareValueLength = coba
    return (
      <div>
        {coba}
        {comp.need_comparer_value === true &&
          comp.multi_comparer === true &&
          comp.max_comparer_length === null && (
            <Tooltip placement="topLeft" title="Add compare value">
              <Button
                size="small"
                icon="plus"
                onClick={() => this.props.onAddcompareValue(record,coba)}
              />
            </Tooltip>
          )}
      </div>
    );
    // record.comapareValueLength = coba
  }

  itemForm(form) {  
    const {
      dataIndex,
      title,
      record,
      colKey,
      tipe,
      comparer,
      requiredFields,
      mode,
    } = this.props;
    // console.log("comparer",comparer)
    if (
      // (dataIndex === "comparer" || dataIndex === "group") &&
      !("editableComparerValue" in record)
    ) {
      this.comparerAdd(record, comparer, dataIndex);
    }
    if (tipe === "SelectQuery") {
      return (
        <FormItem span={24}>
          <Col>
            {form.getFieldDecorator(colKey, {
              initialValue: record.childObj.key
                ? {
                    key: record.childObj.pk,
                    label: record.childObj.key,
                  }
                : undefined,
              rules: [
                {
                  required: requiredFields,
                  message: `This field may not be blank.`,
                },
              ],
            })(
              <SelectQuery
                size="default"
                onChange={(e) => this.handleChange(e, dataIndex, record)}
                urlKey="expert-system-premise-key"
                valKey={"pk"}
                disabled={!("pk" in record.childObj) ? true : false}
                style={{ width: "250px" }}
                valLabel={"key"}
                dropdownClassName="width-auto2"
              />
            )}
          </Col>
        </FormItem>
      );
    } else if (tipe === "Text") {
      return (
        "pk" in record.childObj &&
        this.comparerValue(form.getFieldDecorator, record)
      );
    } else if (tipe === "Select") {
      return !("pk" in record.childObj) && dataIndex !== "comparer" ? null : (
        <FormItem>
          <Col>
            {form.getFieldDecorator(dataIndex, {
              initialValue: get(record, colKey),
              rules: [
                {
                  required: requiredFields,
                  message: "This field may not be blank.",
                },
              ],
            })(
              <Select
                style={{ minWidth: "250px", maxwidth: "250px" }}
                optionFilterProp="comparer"
                dropdownClassName="width-auto2"
                onChange={(e, option) => {
                  let v = null;
                  if (mode) {
                    v = { ...e, ...option.props.opt };
                  } else {
                    v = e;
                  }
                  this.handleChange(v, dataIndex, record, option);
                }}
                labelInValue={mode}
              >
                {comparer.map((item, index) => (
                  <Option
                    key={
                      dataIndex === "group"
                        ? item.childObj.key
                        : item.comparer.name
                    }
                    value={
                      dataIndex === "group"
                        ? item.identifier
                        : item.comparer.desc
                    }
                    opt={item.comparer}
                  >
                    {dataIndex === "group"
                      ? item.childObj.key
                      : item.comparer.desc}
                  </Option>
                ))}
              </Select>
            )}
          </Col>
        </FormItem>
      );
    }
  }
  renderCell = (contex) => {
    const { editing, children, record, colKey, ...restProps } = this.props;
    return <td className="formTable" style={{padding:0}}>{editing ? this.itemForm(contex) : children}</td>;
  };

  render() {
    // console.log("table"(this))
    return (
      <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
    );
  }
}

const groupDummy = [
  {
    identifier: "",
    childObj: {
      key: "out of group",
      identifier: "",
      parentObj: {
        key: "",
      },
    },
  },
];
class EditableTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      editingKey: "",
      rowChildern: false,
      comparer: [],
      addGroup: false,
      root: false,
      optionGroup: [],
      expanded: [],
      dataAsli: {},
    };
    this.columns = [
      {
        title: "Premise key",
        dataIndex: "childObj.key",
        width: "23%",
        url: "expert-system-premise-key",
        key: "premise_key",
        editable: true,
        tipe: "SelectQuery",
        required: true,
      },
      {
        title: "Group",
        dataIndex: "group",
        key: "groupVal",
        width: "22%",
        editable: true,
        tipe: "Select",
        mode: true,
        required: false,
      },
      {
        title: "Comparer",
        dataIndex: "comparer",
        key: "comp",
        width: "22%",
        editable: true,
        url: "expert-system-comparer",
        tipe: "Select",
        mode: true,
        required: true,
      },
      {
        title: "Compare value",
        key: "compare_value",
        dataIndex: "compare_value",
        width: "22%",
        editable: true,
        tipe: "Text",
        required: true,
      },
      {
        title: "",
        width: "20%",
        dataIndex: "operation",
        render: (text, record) => {
          return get(record, "childObj.key", "")
            .toLowerCase()
            .includes("group") || record.id === "group" ? (
            <>
              <Tooltip placement="topLeft" title="Add Premise">
                <Button
                  size="small"
                  icon="plus"
                  type="primary"
                  onClick={() => this.addTableRowChildPremises(record)}
                  style={{ marginLeft: "10px" }}
                />
              </Tooltip>
              <Tooltip placement="topLeft" title="Add Group">
                <Button
                  size="small"
                  icon="usergroup-add"
                  onClick={() => this.addTableRowChildGroup(record)}
                  style={{ marginLeft: "10px" }}
                />
              </Tooltip>
              <Tooltip placement="topLeft" title="Delete">
                <Button
                  size="small"
                  icon="delete"
                  type="danger"
                  onClick={() => this.delete(record)}
                  style={{ marginLeft: "10px" }}
                />
              </Tooltip>
            </>
          ) : (
            <Tooltip placement="topLeft" title="Delete">
              <Button
                size="small"
                icon="delete"
                type="danger"
                onClick={() => this.delete(record)}
                style={{ marginLeft: "10px" }}
              />
            </Tooltip>
          );
        },
      },
    ];

    // this.addPremise = this.addPremise.bind(this);
    // this.addGroup = this.addGroup.bind(this);
  }

  // optionGroup(data) {
  //   console.log("group",data)
  //   const option = [];
  //   for (let i = 0; i < data.length; i++) {
  //       option.push(data.premises[i]);
  //       this.optionGroup(data.premises[i].childObj);

  //   }
  //   return option
  // }

  delete(record) {
    const { data } = this.state;
    const dataPush = record;
    // console.log("recoerd", dataPush);
    dataPush.is_deleted = true;
    dataPush.childObj.premises = [];
    // for (let i = 0; i < dataPush.childObj.premises.length; i++) {
    //   // if(!("pk" in dataPush)){
    //   //   dataPush.childObj.premises[i].is_deleted = true;

    //   // }
    //   dataPush.childObj.premises[i].is_deleted = true;
    // }
    this.forceUpdate();
    this.setState({ data: data });
    // this.filter()
  }
  read = () => {
    this.setState({ isLoading: true }, () => {
      Request(
        "get",
        "expert-system-comparer",
        {},
        {},
        [],
        this.readSuccess,
        this.readFailed
      );
    });
  };

  readSuccess = (response) => {
    let data = [];
    for (let i = 0; i < response.data.length; i++) {
      data.push({ comparer: response.data[i] });
    }
    // console.log("read",data)
    this.setState({ comparer: data });
  };

  readFailed = (error) => {
    console.log("hai", error);
  };

  addTableRowChildGroup(record) {
    this.props.addrowgroup(record);
  }

  addTableRowChildPremises(record) {
    this.props.addrowPremises(record);
  }

  expandrows(data) {
    const dataexpand = [];
    for (let i = 0; i < data.length; i++) {
      if (!("pk" in data[i].childObj)) {
        dataexpand.push(data[i].identifier);
      }
    }
    return dataexpand;
  }

  // componentWillReceiveProps(nextProps){
  //   let newState = {};
  //   const data = { ...nextProps.data};
  //   if(this.state.data !== data){
  //     newState.root = nextProps.root;
  //     newState.scroll = { x: "max-content" };
  //     newState.data = data;
  //     newState.expanded = this.expandrows(data.premises);
  //     this.setState((newState));
  //   }
  // }
  componentDidMount() {
    const data = { ...this.props.data };
    // const dataAsli = { ...this.props.data};
    let newState = {};
    // this.setState({ root: true, scroll: { x: "max-content" } });
    newState.root = this.props.root;
    newState.scroll = { x: "max-content" };

    newState.data = data;
    // newState.dataAsli = dataAsli;
    // newState.optionGroup = this.optionGroup(this.props.group)
    newState.expanded = this.expandrows(data.premises);
    this.read();
    this.setState((newState));
  }

  // formOverrideData = (val, rowInstance) => {
  //   const data = this.state.data.premises;
  //   let r = data.find(
  //     (e) => e.identifier === rowInstance.props["data-row-key"]
  //   );
  //   if (r) {
  //     if (!r.form) {
  //       r.form = val;
  //     } else {
  //       rowInstance.contexValue.form = r.form;
  //     }
  //     // changeData('subheaderData', data)
  //   }
  //   return null;
  // };

  formOverrideData = (val,key) => {
    const data = this.state.data.premises;
    let r = data.findIndex((e) => !e.form);
    let a = data.find((e) => e.identifier == key.row_key);
    a.form = val
    // if (r >= 0) {
    //   data[r].form = val;
    // }
    return null;
  };
  rowOverride = (p) => {
    return <EditableFormRow {...p} overrideform={this.formOverrideData} />;
  };

  changeTable(value, dataIndex, record, data) {
    // console.log("change table", value, dataIndex, record, data, data);
    if (dataIndex === "group") {
      if (value.label === "out of group") {
        if (this.props.parentData !== undefined) {
          for (let i = 0; i < this.props.parentData.premises.length; i++) {
            if (
              this.props.parentData.premises[i].identifier === record.identifier
            ) {
              // console.log("masuk");
              record.groupVal = { value };
            } else if (!("pk" in record.childObj.parentObj)) {
              // console.log("dattt");
              let a = record.childObj.parentObj.premises.findIndex(
                (item) => item.identifier === record.identifier
              );
              record.childObj.parentObj.premises.splice(a, 1);
              record.childObj.parentObj = data.parentObj;
              record.groupVal = {};
              data.parentObj.premises.push(record);
              // record.form.setFieldsValue({comparer_value : ""})
            }
          }
        }
      } else {
        this.props.onChangeGroupTable(value, dataIndex, record);
      }
    } else {
      for (let i = 0; i < data.premises.length; i++) {
        if (data.premises[i].identifier === record.identifier) {
          data.premises[i].comp = {
            ...value,
            datakeI: value.min_comparer_length,
          };
          data.premises[i].comparer = value.desc;
        }
      }
    }

    this.setState({ data: data }, () => {
      if (this.props.updateParentTable) {
        this.props.updateParentTable();
      }
    });
  }

  renderCallTable = (record) => {
    if (record.id !== "no Group") {
      if (!record.childObj.rule_type || record.id === "group") {
        return (
          <>
            <EditableTable
              onChangeGroupTable={(value, dataIndex, record) =>
                this.changeTable(value, dataIndex, record, this.state.data)
              }
              addrowgroup={(record) => this.addTableRowChildGroup(record)}
              addrowPremises={(record) => this.addTableRowChildPremises(record)}
              action={this.props.action}
              data={record.childObj}
              parentData={this.state.data}
              group={get(this.props, "group", groupDummy)}
              updateParentTable={() => this.forceUpdate()}
              root={false}
            />
          </>
        );
      }
    }
  };

  // cell(record){
  //  const data = get(record,"form",null)
  //  if(data !== null)
  //   console.log("record",data.getFieldsValue())
  // }
  onChangeExpanded(data) {
    // console.log("datarowexpand", data);
    this.setState({ expanded: data });
  }
  EditableCell = (p) => {
    return (
      <EditableCell
        {...p}
        onChangeTable={(value, dataIndex, record) =>
          this.changeTable(value, dataIndex, record, this.state.data)
        }
        onAddcompareValue={(record, coba) =>
          this.onAddcompareValue(record, coba)
        }
        onDeletecompareValue={(record, index) =>
          this.onDeletecompareValue(record, index)
        }
      />
    );
  };

  onDeletecompareValue(record, index) {
    const data = this.state.data;
    let form = {};
    for (let i = 0; i < data.premises.length; i++) {
      if (data.premises[i].identifier === record.identifier) {
        // data.premises[i].comapareValueLength.splice(index, 1);
        form = data.premises[i].form.getFieldsValue();
        for (let e = index; e < form.compare_value.length; e++) {
          form.compare_value[e] = form.compare_value[e + 1];
          form.compare_value.splice(form.compare_value.length - 1,1)
        }
        // const comparerundef = form.compare_value.findIndex(
        //   (item) => item === undefined
        // );
        // if (comparerundef !== -1) {
        //   form.compare_value.splice(comparerundef, 1);
        // }
        data.premises[i].form.setFieldsValue(form);
        data.premises[i].compare_value = form.compare_value.toString();
        data.premises[i].comp.datakeI =  data.premises[i].comp.datakeI - 1
      }
    }
    this.setState({ data: data });
  }

  onAddcompareValue(record, coba) {
    const data = this.state.data;

    for (let i = 0; i < data.premises.length; i++) {
      if (data.premises[i].identifier === record.identifier) {
        let plus = record.comp.datakeI + 1;
        data.premises[i].editableComparerValue = true;
        data.premises[i].comp.datakeI = plus;
        // console.log(data.premises[i], plus);
      }
    }
    this.setState({ data: data });
  }

  expandIcon(props) {
    if (!("pk" in props.record.childObj)) {
      if (props.expanded) {
        return (
          <a
            onClick={(e) => {
              props.onExpand(props.record, e);
            }}
          >
            <Icon type="minus-square" />
          </a>
        );
      } else {
        return (
          <a
            onClick={(e) => {
              props.onExpand(props.record, e);
            }}
          >
            <Icon type="plus-square" />
          </a>
        );
      }
    } else return null;
  }

  table(data) {
    // console.log("data", data);
    let is_deleted = [];
    let not_deleted = [];
    if (data.premises !== undefined) {
      // console.log("datssa", data);
      data.premises.map((e) => {
        if (e.is_deleted === true) {
          is_deleted.push(e);
        } else {
          not_deleted.push(e);
        }
      });
    }
    const components = {
      body: {
        row: this.rowOverride,
        cell: this.EditableCell,
        // cell: EditableCell,
      },
    };
    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          tipe: col.tipe,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: true,
          url: col.url,
          comparer:
            col.dataIndex === "group"
              ? get(this.props, "group", groupDummy)
              : this.state.comparer,
          colKey: col.key,
          optiondata: this.state.premise_key,
          action: this.props.action,
          requiredFields: col.required,
          mode: col.mode,
        }),
      };
    });
    return (
      <Row>
        <Col>
          <Table
            components={components}
            rowClassName="editable-row"
            size="small"
            className="tablell expantable"
            dataSource={not_deleted}
            columns={columns}
            rowKey={"identifier"}
            pagination={false}
            scroll={this.state.scroll}
            expandedRowKeys={this.state.expanded}
            showHeader={this.state.root}
            onExpandedRowsChange={(e) => this.onChangeExpanded(e)}
            expandedRowRender={this.renderCallTable}
            expandIcon={(e) => this.expandIcon(e)}
          />
        </Col>
      </Row>
    );
  }

  render() {
    return <>{this.table(this.state.data)}</>;
  }
}

const EditableFormTable = EditableTable;
export default EditableTable;
