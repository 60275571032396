import React from 'react'
import PropTypes from 'prop-types'
import { Card, Affix, Button, message } from 'antd'
import { get } from 'lodash'
import CustomTransfer from 'App/Screens/SubModules/UserGroup/User/Management/Component/CustomTransfer'
import { RequestV2 as Request } from 'App/Utils'

class PageModules extends React.PureComponent {
  constructor(props) {
    super(props)

    this.moduleTitles = ['Available Allowed Modules', 'Chosen Allowed Modules']
    this.state = {
      isSaving: false,
    }
  }

  onSave = () => {
    if (get(this._module, 'validateFields')) {
      const userAllowedModules = this._module.validateFields()
      this.setState({ isSaving: true }, () => {
        Request(
          'patch',
          'module-groupUser-user-update',
          {},
          { user_allowed_modules: userAllowedModules },
          [get(this.props.user, 'pk')],
          this.saveSuccess,
          this.saveFailed,
        )
      }) 
    }
  }

  saveSuccess = () => {
    message.success('Access modules has been saved')
    this.setState({ isSaving: false })
  }

  saveFailed = () => {
    message.error('Failed to save modules')
    this.setState({ isSaving: false })
  }

  render() {
    const { userHelper, user } = this.props
    const { isSaving } = this.state

    return (
      <div style={{ backgroundColor: '#fff' }}>
        <div style={{ borderLeft: '3px solid #1890ff', backgroundColor: '#d9ecfd', padding: '8px' }}>
          Don't forget to save your changes. Because data changes will be lost when moving pages.
          Tab active: <b>MODULES</b>
        </div>
        <Card>
          <div>
            <CustomTransfer
              ref={ref => {this._module = ref}}
              titles={this.moduleTitles}
              dataSource={userHelper.module}
              initialValue={user.user_allowed_modules}
              // setTouched={}
            />
          </div>
          <Affix offsetBottom={0}>
            <div style={{ backgroundColor: '#fff', padding: '12px 0px' }}>
              <Button block type='primary' loading={isSaving} onClick={this.onSave}>
                Save
              </Button>
            </div>
          </Affix>
        </Card>
      </div>
    )
  }
}

export default PageModules