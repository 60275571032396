import { startCase } from 'lodash'

export default {
  "app_label": "notifications",
  "model_name": "broadcast",
  "pagination": true,
  "columns": [
    {
      "name": "title",
      "label": "Title",
      "data_type": "str",
      "search": true,
      "sort": true
    },
    {
      "name": "state",
      "label": "Status",
      "data_type": "str",
      "sort": true,
      render: (val) => startCase(val)
    },
    {
      "name": "receiver_type",
      "label": "Send to",
      "data_type": "str",
      "sort": true,
      render: (val) => startCase(val)
    },
    {
      "name": "modified",
      "label": "Modified",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "datetime",
      "search": false,
      "sort": true
    }
  ],
  "filter_by": [
    {
      "label": "Status",
      "name": "state",
      "type": "choice",
      "choices": [
        ["new", "New"],
        ["sending", "Sending"],
        ["sent", "Sent"]
      ]
    },
    {
      "label": "Send to",
      "name": "receiver_type",
      "type": "choice",
      "choices": [
        ["all", "All"],
        ["specific_user", "Specific Users"],
        ["group", "Groups"],
        ["branch", "Branches"],
      ]
    },
    {
      "label": "Popup",
      "name": "force_popup",
      "type": "switch",
    },
  ],
  "export_import": null
}