const descriptorOffline = {
  app_label: "expertsystem",
  model_name: "feedback",
  pagination: true,
  columns: [
    {
      name: "pk",
      label: "Job ID",
      data_type: "int",
      help_text: null,
      hidden: false,
      identifier: true,
      search: false,
      sort: false,
    },
    {
      name: "name",
      label: "Job Name",
      data_type: "str",
      help_text: null,
      hidden: false,
      identifier: false,
      ellipsis: true,
      search: true,
      sort: true,
    },
    {
      name: "has_fact_feedback",
      label: "Has Fact Feedback",
      data_type: "bool",
    },
    {
      name: "has_esvalidation_feedback",
      label: "Has Validation Feedback",
      data_type: "bool",
    },
    {
      name: "all_feedback_checked",
      label: "All Checked",
      data_type: "bool",
    },
    {
      name: "all_added_rule",
      label: "All Added To Rule",
      data_type: "bool",
    },
  ],
  filter_by: [
    {
      name: "has_fact_feedback",
      label: "Has Fact Feedback",
      singleChoices: [
        ["", "All"],
        ["true", "Yes"],
        ["false", "No"],
      ],
    },
    {
      name: "has_es_feedback",
      label: "Has Validation Feedback",
      singleChoices: [
        ["", "All"],
        ["true", "Yes"],
        ["false", "No"],
      ],
    },
    {
      name: "all_feedback_checked",
      label: "All Checked",
      singleChoices: [
        ["", "All"],
        ["true", "Yes"],
        ["false", "No"],
      ],
    },
    {
      name: "all_added_rule",
      label: "All Added To Rule",
      singleChoices: [
        ["", "All"],
        ["true", "Yes"],
        ["false", "No"],
      ],
    },
    {
      name: "feedback_status",
      label: "Status",
      singleChoices: [
        ["", "All"],
        ["new", "New"],
        ["approve", "Approved"],
        ["reject", "Rejected"],
        ["complete", "All Completed"],
      ],
    },
  ],
  export_import: null,
};

export default descriptorOffline;
