import React from "react";
import {
  Spin,
  Row,
  Col,
  Layout,
  Table,
  Divider,
  Button,
  Icon,
  Checkbox,
  Tag,
} from "antd";
import { startCase, noop, filter, overEvery } from "lodash";

import Base from "App/Component/Bases/Base";
import PageError from "App/Component/PageError";
import Filter from "App/Component/Singlepage/AdvancedFilter";
import { PageHeader } from "App/Screens/SubModules/Component/BaseDetail";
import { Lib, Modules, getValue } from "App/Utils";
import getFeedbackItems from "../utils/getFeedbackItems";
import statusMapping from "../utils/statusMapping";

class JobDetail extends Base.PureComponent {
  constructor(props) {
    super(props);
    this.initState({
      items: [],
      filters: [],
      filterVisible: false,
      filtered: false,
      job: null,
      errResObj: null,
    });
    this.jobId = props.match.params.id;
    this.columns = [
      {
        title: "ID",
        dataIndex: ["id"],
      },
      {
        title: "Type",
        dataIndex: ["type"],
        render: startCase,
      },
      {
        title: "Status",
        dataIndex: ["status"],
        render(item) {
          return (
            <Tag color={statusMapping[item].color}>
              {statusMapping[item].label}
            </Tag>
          );
        },
      },
      {
        title: "Is Checked",
        className: "text-center",
        dataIndex: ["isChecked"],
        render(item) {
          return Modules.renderStatusAIU(item || false);
        },
      },
      {
        title: "Is Added To Rule",
        className: "text-center",
        dataIndex: ["isAdded"],
        render(item) {
          return Modules.renderStatusAIU(item || false);
        },
      },
      {
        title: "Fact",
        className: "text-center",
        children: [
          {
            title: "Plan",
            dataIndex: ["extraObj", "plan_file", "file_name"],
            render(item, record) {
              return getValue(record, "extraObj.cloud_plan_filename") || item;
            },
          },
        ],
      },
      {
        title: "Validation",
        className: "text-center",
        children: [
          {
            title: "Rule",
            dataIndex: ["originalObj", "source_object", "rule_key"],
          },
          {
            title: "Field",
            dataIndex: ["originalObj", "field"],
          },
        ],
      },
      {
        key: "operation",
        title: "Operation",
        fixed: "right",
        className: "text-center",
        width: 100,
        render: this.renderOperation,
      },
    ];
    this.filters = [
      {
        name: "type",
        label: "Feedback Type",
        singleChoices: [
          ["", "All"],
          ["fact", "Fact"],
          ["validation", "Validation"],
        ],
      },
      {
        name: "status",
        label: "Feedback Status",
        singleChoices: [
          ["", "All"],
          ["new", "New"],
          ["approve", "Approved"],
          ["reject", "Rejected"],
          ["complete", "Completed"],
        ],
      },
      {
        label: "Is Checked",
        name: "isChecked",
        type: "switch",
      },
      {
        label: "Is Added To Rule",
        name: "isAdded",
        type: "switch",
      },
      {
        name: "validationField",
        label: "Validation Field",
        type: "choices",
        choices: [
          ["prefill_set", "Prefill Set"],
          ["override_set", "Override Set"],
        ],
      },
      {
        name: "validationType",
        label: "Validation Type",
        type: "choices",
        choices: [
          ["request", "Request"],
          ["suggestion", "Suggestion"],
        ],
      },
      {
        name: "validationAction",
        label: "Validation Action",
        type: "choices",
        choices: [["rejected", "Rejected"]],
      },
    ];
    this.headerDescriptions = [
      { key: "full_name", label: "Job" },
      { key: "has_fact_feedback", label: "Has Fact Feedback", type: "bool" },
      {
        key: "has_esvalidation_feedback",
        label: "Has Validation Feedback",
        type: "bool",
      },
    ];
  }

  retrieve = (callback = noop) => {
    this.setState({ loading: true }, () => {
      getFeedbackItems({ jobId: this.jobId }).then((obj) => {
        let nextState = { loading: false };

        if (obj.errResObj) {
          nextState.errResObj = obj.errResObj;
          callback(false);
        } else {
          nextState = {
            ...nextState,
            job: obj.job,
            items: obj.items,
            errResObj: null,
          };
          callback(true);
        }

        this.setState(nextState);
      });
    });
  };

  goBase = () => {
    Lib.historyAction(this.props.history, "/expert-system/feedbacks").goBack();
  };

  parseBooleanString(inputString) {
    const lowercaseInput = inputString.toLowerCase();

    if (lowercaseInput === "true") return true;
    else if (lowercaseInput === "false") return false;

    return inputString;
  }

  getRowKey = (r) => {
    return r.type + "-" + r.id;
  };

  setFilter = () => {
    this.setState({ filtered: !this.state.filtered });
  };

  filterChangeHandler = (value, name) => {
    const { filters } = this.state;

    if (name === "validationField") {
      if (!!value.length)
        filters[1] = function (val) {
          return (
            val.type === "fact" ||
            value.includes(getValue(val, "originalObj.field"))
          );
        };
      else filters[1] = null;
    } else if (name === "validationType") {
      if (!!value.length)
        filters[2] = function (val) {
          return (
            val.type === "fact" ||
            value.includes(getValue(val, "originalObj.validation_type"))
          );
        };
      else filters[2] = null;
    } else if (name === "validationAction") {
      if (!!value.length)
        filters[3] = function (val) {
          return (
            val.type === "fact" ||
            value.includes(getValue(val, "originalObj.action"))
          );
        };
      else filters[3] = null;
    } else {
      if (value) {
        filters[0] = {
          ...filters[0],
          [name]: this.parseBooleanString(value),
        };
      } else delete filters[0][name];
    }

    if (this.state.filtered) this.setState({ filters: [...filters] });
  };

  rowHandler = (record) => {
    return {
      onClick: () => {
        this.props.history.push(
          `${this.props.match.url}/${record.type}/${record.id}`
        );
      },
    };
  };

  pageReloadHandler = (_, callback) => {
    this.retrieve(callback);
  };

  renderOperation = (_, record) => {
    return (
      <>
        <Button className="mr-1" type="primary" icon="control" />
        <Button
          type="primary"
          icon="export"
          title="Open in new Tab."
          onClick={(e) => {
            e.stopPropagation();
            window.open(
              `${this.props.match.url}/${record.type}/${record.id}`,
              "_blank"
            );
          }}
        />
      </>
    );
  };

  didMount() {
    this.retrieve();
  }

  render() {
    const [p, s] = [this.props, this.state];

    return (
      <Spin spinning={s.loading}>
        {!s.errResObj ? (
          <Layout className="um-layout bg-white">
            <PageHeader
              readOnly
              title="Feedback"
              onBack={this.goBase}
              descriptions={this.headerDescriptions}
              data={s.job}
            />
            <Layout.Content className="pb-3 px-3">
              <Row gutter={[16, 16]}>
                <Col>
                  <Divider className="my-1" />
                </Col>
                <Col>
                  <Row type="flex" justify="end">
                    <Col className="text-center">
                      <Button.Group>
                        <Button
                          onClick={() => this.setState({ filterVisible: true })}
                          type={s.filtered ? "primary" : "default"}
                        >
                          <Icon type="setting" /> Advance filter
                        </Button>
                        <Button onClick={this.setFilter} type="dashed">
                          <Checkbox checked={s.filtered} />
                        </Button>
                      </Button.Group>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Table
                    bordered
                    rowKey={this.getRowKey}
                    size="middle"
                    columns={this.columns}
                    dataSource={filter(
                      s.items,
                      s.filtered ? overEvery(s.filters) : {}
                    )}
                    loading={s.loading}
                    scroll={{ x: true }}
                    pagination={false}
                    onRow={this.rowHandler}
                  />
                </Col>
              </Row>
            </Layout.Content>
            <Filter
              visibledrawer={s.filterVisible}
              onChildrenDrawerClose={() =>
                this.setState({ filterVisible: false })
              }
              filter={this.filters}
              userReducer={p.userReducer}
              getFilterParams={noop}
              onfilter={s.filtered}
              handleChange={this.filterChangeHandler}
              setonfilter={this.setFilter}
            />
          </Layout>
        ) : (
          <PageError
            errorResponse={s.errResObj}
            onGoBack={this.goBase}
            onReload={this.pageReloadHandler}
          />
        )}
      </Spin>
    );
  }
}

export default JobDetail;
