import React from 'react'
import { message } from 'antd'
import { BaseDetail } from 'App/Component/Bases'
import BaseOrderManagement from 'App/Component/OrderManagement'

class PermissionOrderManagement extends BaseDetail {
  getCodePart = () => '.change_'

  getAllowedComponent = () => (
    <PrefillSetGroupOrderManagement {...this.props} />
  )
}

class PrefillSetGroupOrderManagement extends BaseOrderManagement {
  constructor(props) {
    super(props)

    this.title = 'Prefill Set Groups Order Management'
    this.urlKeySave = 'api:admin:estimation:prefillsetgroup:manage-order'
    this.propsCardContent = {
      urlKeyListOrder: 'api:admin:estimation:prefillsetgroup:list',
      withoutSubsection: true,
    }
    this.colProps = { span: 24 }
    this.state = {
      ...this.state,
      selectedSubsections: {
        keys: [99999],
        obj: {
          99999: {
            pk: 99999,
            name: 'Prefill Set Groups',
            section: { name: 'List' },
          }
        }
      }
    }
  }

  getSubsectionList = () => null

  onCloseCard = () => message.info('Cannot close card')
}

export default PermissionOrderManagement