import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Row, Col, Button } from 'antd'
import { get } from 'lodash'

class ActionFooter extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      isCanceling: false,
    }
  }

  // Dapat digunakan untuk parent (dengan memberikan ref)
  setThisState = (obj, callback = () => null) => {
    if (obj) {
      for (const key in obj) {
        if (obj[key] !== this.state[key]) {
          this.setState(obj, callback)
          return
        }
      }
    }
    callback()
  }

  hasErrors = (fieldsError) => {
    // Return Boolean
    return fieldsError && Object.keys(fieldsError).some(field => fieldsError[field])
  }

  onSave = (form) => {
    const { onSave } = this.props
    this.setState({ isLoading: true }, () => {
      setTimeout(() => {
        onSave(form, () => {
          this.setState({ isLoading: false })
        })
      }, 250)
    })
  }

  onCancel = (event) => {
    const { onCancel } = this.props
    this.setState({ isCanceling: true }, () => {
      setTimeout(() => {
        onCancel(event, () => {
          this.setState({ isCanceling: false })
        })
      }, 250)
    })
  }

  render() {
    const { form } = this.props
    const { isLoading, isCanceling } = this.state

    return (
      <StyledFooter style={{ width: `calc(${get(document.getElementsByTagName('main'), ['0', 'offsetWidth'], '100% - 332')}px + 32px)` }}>
        <Row type='flex' justify='end' gutter={[12, 0]}>
          <Col>
            <Button type='danger' loading={isCanceling} onClick={this.onCancel}>Cancel</Button>
          </Col>
          <Col>
            <Button
              type='primary'
              loading={isLoading}
              icon='save'
              disabled={this.hasErrors(form && form.getFieldsError())}
              onClick={() => this.onSave(form)}
            >
              Save
            </Button>
          </Col>
        </Row>
      </StyledFooter>
    )
  }
}

const StyledFooter = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  border-top: 1px solid #e8e8e8;
  z-index: 9;
  padding: 8px 16px;
  text-align: right;
  background: #fff;
`;

ActionFooter.propTypes = {
  form: PropTypes.object,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
}

ActionFooter.defaultProps = {
  form: null,
  onCancel: (event, callback) => callback(),
  onSave: (form, callback) => callback(),
}

export default ActionFooter