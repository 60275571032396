import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'antd'

class Check extends React.PureComponent {
  constructor(props) {
    super(props)

    this.defaultStyle = {
      position: 'absolute',
      top: 0,
      left: 0,
      margin: '11px',
      cursor: 'pointer',
      fontSize: '28px',
      color: props.checked ? '#1890ff' : 'rgba(0, 0, 0, 0.5)',
      backgroundColor: props.checked ? '#ffffff' : 'rgba(175, 175, 175, 0.4)',
      borderRadius: '1px',
      boxShadow: '0 0px 8px 0 rgba(0, 0, 0, 0.2), 0 0px 14px 0 rgba(0, 0, 0, 0.19)',
    }
  }

  render() {
    const { className, style, onClick } = this.props
    return (
      <Icon
        type='check-square'
        theme='filled'
        className={className}
        style={{ ...this.defaultStyle, ...style }}
        onClick={onClick}
      />
    )
  }
}

Check.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
}

Check.defaultProps = {
  checked: false,
  className: '',
  style: {},
  onClick: () => null,
}

export default Check