import React, {Component} from 'react'
import styled from 'styled-components'
import {
  Row, Col, Button,
} from 'antd';
import bg404 from '../../../Assets/img/404.svg'

export default class Page404 extends Component {

  gotoDashboard() {
    this.props.history.push('/')
  }

  componentDidMount() {
    document.body.classList.add('body-fixed')
  }

  componentWillUnmount() {
    document.body.classList.remove('body-fixed')
  }

  render() {
    return (
      <StyledRow type={"flex"} justify={"center"}>
        <StyledCol span={12}>
          <StyledImg src={bg404}/>
        </StyledCol>
        <StyledCol span={12}>
          <StyledTitle style={{'marginTop': '30px'}}>404</StyledTitle>
          <StyledSubTitle>Sorry about that, but the page you looking for is unavailable</StyledSubTitle>
          <Button type="primary" size="large" onClick={() => this.gotoDashboard()}>Go to Dashboard</Button>
        </StyledCol>
      </StyledRow>
    )
  }
}

const StyledRow = styled(Row)`
  background: #f0f2f5;
  height: 100vh;
`
const StyledCol = styled(Col)`
  margin-top: 150px
  text-align:center;
`
const StyledImg = styled.img`
  height: 310px;
  margin-bottom:-100px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
`
const StyledTitle = styled.h1`
  font-size: 3.8em
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
`
const StyledSubTitle = styled.p`
  font-size: 22px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
`
