import React from 'react'
import PropTypes from 'prop-types'
import { Row, Card, Affix, Button, message } from 'antd'
import { get } from 'lodash'
import CustomPermissionsTab from 'App/Screens/SubModules/UserGroup/User/Management/Component/CustomPermissionsTab'
import { RequestV2 as Request } from 'App/Utils'

class UserPermission extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isSaving: false,
    }
  }

  onSave = () => {
    if (get(this._permission, 'validateFields')) {
      const userPermissions = this._permission.validateFields()
      this.setState({ isSaving: true }, () => {
        Request(
          'patch',
          'module-groupUser-user-update',
          {},
          { user_permissions: userPermissions },
          [get(this.props.user, 'pk')],
          this.saveSuccess,
          this.saveFailed,
        )
      }) 
    }
  }

  saveSuccess = () => {
    message.success('Permission has been saved')
    this.setState({ isSaving: false })
  }

  saveFailed = () => {
    message.error('Failed to save permission')
    this.setState({ isSaving: false })
  }

  render() {
    const { user, userHelper } = this.props
    const { isSaving } = this.state

    return (
      <>
        <div style={{ borderLeft: '3px solid #1890ff', backgroundColor: '#d9ecfd', padding: '8px' }}>
          Don't forget to save your changes. Because data changes will be lost when moving pages.
          Tab active: <b>USER PERMISSION</b>
        </div>
        <Card className="animated fadeIn">
          <Row style={{ borderBottom: '1px solid #e8e8e8' }}>
            <div style={{ fontSize: '14px', fontWeight: 400, paddingBottom: '12px' }} onClick={() => this.setState({ dummy: Math.random() })}>
              Manage allowed access/permission of the user in every modules. Permission read is the main permission to be able to access the menu.
            </div>
          </Row>
          <CustomPermissionsTab
            ref={ref => {this._permission = ref}}
            dataSource={userHelper.permission}
            initialValue={user.user_permissions}
          />
          <Affix offsetBottom={0}>
            <div style={{ backgroundColor: '#fff', padding: '12px 0px' }}>
              <Button block type='primary' loading={isSaving} onClick={this.onSave}>
                Save
              </Button>
            </div>
          </Affix>
        </Card>
      </>
    )
  }
}

UserPermission.propTypes = {
  userReducer: PropTypes.object.isRequired,
  userHelper: PropTypes.object,
}

UserPermission.defaultProps = {
  userHelper: null,
}

export default UserPermission