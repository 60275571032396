import React from 'react'
import PropTypes from 'prop-types'
import { Col, message, Row } from 'antd'
import Lottie from 'react-lottie'
import { get, isEmpty } from 'lodash'
import GroupItem from './Item'
import * as observationAnimation from 'App/Assets/lottie/observation'

// must React.Component
class Contents extends React.Component {
  getRefKey = (key) => {
    return `_${key}`
  }

  onRef = (ref) => {
    const key = get(ref, 'props.currentGroup.pk') || get(ref, 'props.currentGroup.key')
    const refKey = this.getRefKey(key)
    this[refKey] = ref
  }

  validateFields = () => {
    const { selectedGroups: {keys} } = this.props
    const values = []
    for (let i = 0; i < keys.length; i += 1) {
      const refKey = this.getRefKey(keys[i])
      if (this[refKey]) {
        const record = this[refKey].validateFields()

        if (record) values.push(record)
      }
    }

    if (isEmpty(values)) {
      message.info('No unsaved changes.')
      return null
    }

    return values
  }

  render() {
    const { isOverrideSet, keyChild, labelChild, selectedGroups, urlKeyDetail, urlKeyChildList } = this.props

    return (
      <div style={{ height: 'calc(100vh - 241px)', overflowY: 'auto' }}>
        {selectedGroups.keys.length ? (
          <div style={{ padding: '0 6px' }}>
            <Row type='flex' gutter={[12, 12]}>
              {selectedGroups.keys.map(groupId => {
                return (
                  <Col key={groupId || get(selectedGroups.obj[groupId], 'key')} xs={24} sm={24} xl={12} xxl={8}>
                    <GroupItem
                      ref={this.onRef}
                      urlKeyDetail={urlKeyDetail}
                      urlKeyChildList={urlKeyChildList}
                      isOverrideSet={isOverrideSet}
                      labelChild={labelChild}
                      keyChild={keyChild}
                      currentGroup={selectedGroups.obj[groupId]}
                      onClose={this.props.onCloseGroup}
                      onChange={this.props.onChangeGroup}
                      onDeleteGroup={this.props.onDeleteGroup}
                    />
                  </Col>
                )
              })}
            </Row>
          </div>
        ) : (
          <div className='py-5'>
            <Lottie
              width={300}
              height={300}
              options={{
                loop: true,
                autoplay: true,
                animationData: observationAnimation.default,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                }
              }}
            />
            <div className='ant-result-title'>Select Subsection and Group on the left</div>
          </div>
        )}
      </div>
    )
  }
}

Contents.propTypes = {
  // urlKeyList: PropTypes.string.isRequired,
  urlKeyDetail: PropTypes.string.isRequired,
  urlKeyChildList: PropTypes.string.isRequired,
  onCloseGroup: PropTypes.func,
  onChangeGroup: PropTypes.func,
  onDeleteGroup: PropTypes.func,
}

Contents.defaultProps = {
  onCloseGroup: () => null,
  onChangeGroup: () => null,
  onDeleteGroup: () => null,
}

export default Contents