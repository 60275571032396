import React from 'react'
import { Spin, Icon } from 'antd'

export const LoadingPage = (props) => {
  return (
    <div style={{ paddingTop: 100, textAlign: 'center' }}>
      <Spin
        size='large'
        tip='making coffee...'
        indicator={<Icon type='loading' style={{ fontSize: '4rem' }} spin />}
      />
    </div>
  )
}