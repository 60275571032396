import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Form, Row, Col, Card, Input, Button, Icon, message } from 'antd'
import { get } from 'lodash'
import { RequestV2 as Request } from 'App/Utils'
import { FormContext, FormProvider } from 'App/Component/Context/ContextForm'
import bgImage from 'App/Assets/img/bg-p.png'
import IbesLogo from 'App/Assets/img/NewIBES-Logo-Dark.png'

import './ResetPassword.less'

class ResetPasswordElement extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isResetting: false,
    }
  }

  matchValidator = (rule, retypePassword, callback) => {
    const password = this.form.getFieldValue('password')
    if (retypePassword) {
      if (retypePassword !== password) {
        this.retypeRef.input.focus()
        return callback(true)
      }
    }
    callback()
  }

  onResetPassword = () => {
    if (this.form) {
      this.form.validateFieldsAndScroll({scroll: {offsetTop: 150}}, (err, values) => {
        if (!err) {
          this.resetPassword(values.password)
        }
      })
    }
  }

  resetPassword = (newPassword) => {
    this.setState({ isResetting: true }, () => {
      const { token } = this.props
      Request(
        'put',
        'resetPassword',
        {},
        { new_password: newPassword },
        [token],
        this.resetPasswordSuccess,
        this.resetPasswordFailed,
      )
    })
  }

  resetPasswordSuccess = (response) => {
    message.success('The password has been saved, create a login page...')
    setTimeout(() => {
      this.props.history.replace('/login')
    }, 1000)
  }

  resetPasswordFailed = (error) => {
    message.error('Failed to reset password')
    const passwordErrors = get(error, ['response', 'data', 'new_password'])
    if (passwordErrors) {
      const passwordValue = this.form.getFieldValue('password')
      this.form.setFields({ password: {
        value: passwordValue,
        errors: [new Error(passwordErrors[0])],
      }})
      this.newPasswordRef.input.focus()
    }
    this.setState({ isResetting: false })
  }

  render() {
    const { currentUser } = this.props
    const { isResetting } = this.state

    return (
      <Row
        type='flex'
        justify='center'
        align='middle'
        style={{ height: '100vh', overflowY: 'auto', backgroundColor: '#001529' }}
      >
        <Col className='reset-card'>
          <Row type='flex'>
            <Col className='section-card section-card-left'>
              <Row type='flex' justify='center'>
                <Icon type='unlock' style={{ fontSize: '64px', color: '#001529' }} />
              </Row>
              <Row type='flex' justify='center'>
                <Col style={{ fontSize: '24px', fontWeight: 500, color: '#001529' }}>
                  Reset Password
                </Col>
              </Row>
              <div style={{ textAlign: 'center' }}>User: <b>{get(currentUser, 'full_name', get(currentUser, 'username'))}</b></div>
              <FormProvider>
                <FormContext.Consumer>
                  {form => {
                    this.form = form
                    return (
                      <Row style={{ margin: '12px 0' }}>
                        <Col>
                          <Form.Item style={{ marginBottom: '12px' }}>
                            {form.getFieldDecorator('password', {
                              rules: [{
                                required: true,
                                message: 'Please input new password'
                              }]
                            })(
                              <Input.Password
                                ref={ref => {this.newPasswordRef = ref; setTimeout(() => ref.input.focus(), 3000)}}
                                autoComplete='new-password'
                                placeholder='New password'
                                onPressEnter={this.onResetPassword}
                              />
                            )}
                          </Form.Item>
                        </Col>
                        <Col>
                          <Form.Item style={{ marginBottom: '12px' }}>
                            {form.getFieldDecorator('retype-password', {
                              rules: [{
                                required: true,
                                message: 'Please input retype password'
                              }, {
                                validator: this.matchValidator,
                                message: 'The passwords do not match'
                              }]
                            })(
                              <Input.Password ref={ref => {this.retypeRef = ref}} placeholder='Retype new password' autoComplete='new-password' onPressEnter={this.onResetPassword} />
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                    )
                  }}
                </FormContext.Consumer>
              </FormProvider>
              <Row type='flex' gutter={[12, 12]}>
                <Col span={8}>
                  <Link to='/login'>
                    <Button block disabled={isResetting}>
                      Login
                    </Button>
                  </Link>
                </Col>
                <Col span={16}>
                  <Button block type='primary' loading={isResetting} onClick={this.onResetPassword}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col className='section-card section-card-right' style={{ backgroundImage: `url(${bgImage})` }}>
              <Row type='flex' justify='center' align='middle' style={{ height: '100%' }}>
                <Col>
                  <Row type='flex' justify='center'>
                    <img style={{width: '50%'}} src={IbesLogo} alt='error' />
                  </Row>
                  {/* <div style={{ textAlign: 'center', fontSize: '36px', fontWeight: 500, color: '#1890ff' }}>
                    Reset Password
                  </div> */}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
}

ResetPasswordElement.propTypes = {
  history: PropTypes.object.isRequired,
}

export default ResetPasswordElement
