const descriptor = {
  pagination: true,
  filter_by: [
    {
      name: 'status',
      label: 'Status',
      singleChoices: [
        ['', 'All'],
        ['active', 'Active'],
        ['inactive', 'Inactive'],
        ['unpublished', 'Unpublished']
      ],
    },
  ],
  columns: [
    {
      "name": "name",
      "label": "Name",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "ellipsis": true,
      "search": true,
      "sort": true
    },
    {
      "name": "code",
      "label": "Code",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "search": true,
      "sort": true
    },
    {
      "name": "status",
      "label": "Status",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": null,
      "search": true,
      "sort": true
    },
    {
      "name": "unit",
      "label": "Unit",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": null,
      "child_path": "name",
      "search": true,
      "sort": true
    },
    {
      "name": "timber_product",
      "label": "Timber product",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "bool",
      "search": false,
      "sort": false
    },
    {
      "name": "modified",
      "label": "Modified",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "datetime",
      "search": false,
      "sort": true
    }
  ]
}

export default descriptor