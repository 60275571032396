import PageMenu from "App/Component/PageMenu";

// ======= COMPONENT SCREEN
import StaffLeaves from "./StaffLeaves/";
import StaffPositions from "./StaffPositions/";
// import Users from "./Users/";
// import Groups from "./Groups/";

const module = "admin";
const app = "user";
const routes = [
  {
    path: `/${module}/${app}`,
    component: PageMenu,
    exact: true
  },
  {
    // title: "Staff Leaves",
    routes: [
      {
        name: "Staff Leave",
        component: StaffLeaves.List,
        props: { urlName: "api:admin:users:staffleave:list" },
      },
      {
        name: "Staff Leave History",
        component: StaffLeaves.Detail,
        path: `/${module}/${app}/staff-leaves/:id/history/:historyId`,
      },
      {
        name: "Staff Leave",
        component: StaffLeaves.Detail,
        exact: false,
        props: { urlName: "api:admin:users:staffleave:detail" },
      }
    ]
  },
  {
    // title: "Staff Positions",
    routes: [
      {
        name: "Staff Position",
        component: StaffPositions.List,
        props: { urlName: "api:admin:users:staffposition:list" },
      },
      {
        name: "Staff Position History",
        component: StaffPositions.Detail,
        path: `/${module}/${app}/staff-positions/:id/history/:historyId`,
      },
      {
        name: "Staff Position",
        component: StaffPositions.Detail,
        exact: false,
        props: { urlName: "api:admin:users:staffposition:detail" },
      }
    ]
  }
]

export default { appName: "User", routes };
