import React from 'react'
import { get } from 'lodash'

import { RequestV2 as Request } from 'App/Utils'
import PageError from 'App/Component/PageError'

class BaseRequest extends React.PureComponent {
  constructor(props) {
    super(props)

    this.urlKey = ''
    this.keysQueryParam = []

    this.state = {
      isLoading: true,
      errorResponse: null,
      data: null,
    }
  }

  getParams = () => {
    const params = {}
    for (const key of this.keysQueryParam) {
      if (this[key] !== undefined) {
        params[key] = this[key]
      }
    }

    return params
  }

  getArgs = () => [this.props.userId]

  read = (keyLoading, callback) => {
    if (typeof keyLoading !== 'string')
      keyLoading = 'isLoading'

    this.setState({ [keyLoading]: true }, () => {
      Request(
        'get',
        this.urlKey,
        {},
        this.getParams(),
        this.getArgs(),
        this.readSuccess,
        this.readFailed,
        { callback, keyLoading }
      )
    })
  }

  convertResponseData = (responseData) => responseData

  readSuccess = (response, extra) => {
    this.setState({ [extra.keyLoading]: false, data: this.convertResponseData(response.data), errorResponse: null })
  }

  readFailed = (error, extra) => {
    this.setState({ [extra.keyLoading]: false, errorResponse: error.response }, extra.callback)
  }

  getErrorComp = () => (
    <PageError
      errorResponse={this.state.errorResponse}
      onReload={this.read}
    />
  )

  componentDidMount() {
    if (this.state.isLoading) {
      this.read()
    }
  }
}

export default BaseRequest