const descriptor = {
  pagination: true,
  filter_by: [],
  columns: [
    {
      "name": "username",
      "label": "Username",
      "help_text": "Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.",
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "ellipsis": true,
      "search": true,
      "sort": true
    },
    {
      "name": "first_name",
      "label": "First name",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "search": true,
      "sort": true
    },
    {
      "name": "last_name",
      "label": "Last name",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "ellipsis": true,
      "search": true,
      "sort": true
    },
    {
      "name": "is_active",
      "label": "Active",
      "help_text": "Designates whether this user should be treated as active. Unselect this instead of deleting accounts.",
      "hidden": false,
      "identifier": false,
      "data_type": "bool",
      "search": false,
      "sort": true
    },
    {
      "name": "is_staff",
      "label": "Staff status",
      "help_text": "Designates whether the user can log into this admin site.",
      "hidden": false,
      "identifier": false,
      "data_type": "bool",
      "search": false,
      "sort": true
    },
    {
      "name": "is_superuser",
      "label": "Superuser",
      "help_text": "Designates that this user has all permissions without explicitly assigning them.",
      "hidden": false,
      "identifier": false,
      "data_type": "bool",
      "search": false,
      "sort": true
    },
  ]
}

export default descriptor