export default {
  "app_label": "jobs",
  "model_name": "merchant",
  "pagination": true,
  "columns": [
    {
      "name": "name",
      "label": "Name",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "ellipsis": true,
      "search": true,
      "sort": true
    },
    {
      "name": "merchant_group",
      "label": "Merchant group",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": null,
      "child_path": "name",
      "search": true,
      "sort": true
    },
    {
      "name": "mapping_mode",
      "label": "Mapping Mode",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": null,
      "child_path": "name",
      "search": true,
      "sort": true
    },
    {
      "name": "branch",
      "label": "Branch",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": null,
      "child_path": "name",
      "search": true,
      "sort": true
    },
    {
      "name": "code",
      "label": "Code",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "search": true,
      "sort": true
    },
    {
      "name": "is_active",
      "label": "Is active",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "bool",
      "search": false,
      "sort": true
    },
    {
      "name": "modified",
      "label": "Modified",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "datetime",
      "search": false,
      "sort": true
    }
  ],
  "filter_by": [
    {
      name: 'merchant_group',
      label: 'Merchant Group',
      api: {
        url_name: 'module-merchant-group-autocomplete',
        key: 'pk',
        display: 'name',
      }
    },
    {
      name: 'mapping_mode',
      label: 'Mapping Mode',
      api: {
        url_name: 'module-merchant-mappingMode-autocomplete',
        key: 'pk',
        display: 'name',
      }
    },
    {
      name: 'branch',
      label: 'Branch',
      api: {
        url_name: 'branches-autocomplete',
        key: 'pk',
        display: 'name',
      }
    },
    {
      name: 'is_active',
      label: 'Active Status',
      singleChoices: [
        ['', 'All'],
        ['true', 'Active'],
        ['false', 'Inactive']
      ]
    }
  ],
}