import AdminMenu from 'App/Admin/Menu'
import BranchMenu from 'App/Screens/SubModules/Branches/Menu'
import JobMenu from 'App/Screens/SubModules/JobMg/Menu'
import ProductMenu from 'App/Screens/SubModules/Products/Menu'
import MerchantMenu from 'App/Screens/SubModules/Merchant/Menu'
import UserMenu from 'App/Screens/SubModules/UserGroup/Menu'
import ExpertSystemMenu from 'App/Screens/SubModules/ExpertSystem/Menu'
import V3Menu from 'App/Screens/SubModules/v3/Menu'

// the key must be the same as the first url
// example URL: "/admin/.../..", AdminMenu.key must: "admin", and others.
export default {
  [AdminMenu.key]: AdminMenu.dummyFromApi,
  [BranchMenu.key]: BranchMenu.list,
  [MerchantMenu.key]: MerchantMenu.list,
  [JobMenu.key]: JobMenu.list,
  [ProductMenu.key]: ProductMenu.list,
  [UserMenu.key]: UserMenu.list,
  [ExpertSystemMenu.key]: ExpertSystemMenu.list,
  [V3Menu.key]: V3Menu.list,
}