export default {
  "app_label": "estimation",
  "model_name": "product",
  "pagination": true,
  "columns": [
    {
      "name": "pk",
      "label": "ID",
      "help_text": null,
      "hidden": true,
      "identifier": true,
      "data_type": "int",
      "search": false,
      "sort": false
    },
    {
      "name": "name",
      "label": "Name",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "ellipsis": true,
      "search": true,
      "sort": true
    },
    {
      "name": "code",
      "label": "Code",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "search": true,
      "sort": true
    },
    {
      "name": "status",
      "label": "Status",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": null,
      "search": true,
      "sort": true
    },
    {
      "name": "unit",
      "label": "Unit",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": null,
      "child_path": "name",
      "search": true,
      "sort": true
    },
    {
      "name": "timber_product",
      "label": "Timber product",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "bool",
      "search": false,
      "sort": false
    },
    {
      "name": "modified",
      "label": "Modified",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "datetime",
      "search": false,
      "sort": true
    }
  ],
  "filter_by": [
    {
      "label": "Status",
      "name": "status",
      "type": "choice",
      "choices": [
        [
          "active",
          "Active"
        ],
        [
          "inactive",
          "Inactive"
        ],
        [
          "unpublished",
          "Unpublished"
        ]
      ]
    },
    {
      "label": "Timber product",
      "name": "timber_product",
      "type": "switch"
    },
    {
      "label": "Expensive",
      "name": "is_expensive",
      "type": "switch"
    },
    {
      "label": "Groups",
      "name": "groups",
      "type": "api_reference",
      "api": {
        "url_name": "api:admin:estimation:productgroup:autocomplete",
        "key": "pk",
        "display": "name",
        "pagination": true
      }
    },
    {
      "label": "Unit",
      "name": "unit",
      "type": "api_reference",
      "api": {
        "url_name": "api:admin:estimation:unit:autocomplete",
        "key": "pk",
        "display": "name",
        "pagination": true
      }
    },
    {
      "label": "Labour unit",
      "name": "labour_unit",
      "type": "api_reference",
      "api": {
        "url_name": "api:admin:estimation:unit:autocomplete",
        "key": "pk",
        "display": "name",
        "pagination": true
      }
    },
    {
      "label": "Template set",
      "name": "template_set",
      "type": "api_reference",
      "api": {
        "url_name": "api:admin:estimation:templateset:autocomplete",
        "key": "pk",
        "display": "name",
        "pagination": true
      }
    },
    {
      name: 'code',
      label: 'Code',
      type: 'text',
    },
    {
      name: 'start_code',
      label: 'Start Code',
      type: 'text',
    },
    {
      name: 'end_code',
      label: 'End Code',
      type: 'text',
    },
  ],
  "export_import": null
}