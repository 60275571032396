import React from 'react'
import PropTypes from 'prop-types'
import { Card, Icon, Input, Spin } from 'antd'
import { get } from 'lodash'
import { Lib } from 'App/Utils'
import SelectQuery from 'App/Component/SelectQuery'

const DEFAULT_PARAM_PROPS = { status: 'active' }

class SetsGroupListContainer extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      paramProps: DEFAULT_PARAM_PROPS,
    }
  }

  onRef = (ref) => {
    this._ref = ref
  }

  reload = (paramProps) => {
    if (this._ref) {
      this.setState({ paramProps: {...DEFAULT_PARAM_PROPS, ...paramProps} }, () => {
        this._ref.onSearch('')
      })
    }
  }

  render() {
    const { paramProps } = this.state

    return (
      <SetsGroupList
        ref={this.onRef}
        waitTime={0}
        urlKey={this.props.urlKey}
        paramProps={paramProps}
        {...this.props}
      />
    )
  }
}

class SetsGroupList extends SelectQuery {
  constructor(props) {
    super(props)

    this.search = ''
    this.state = {
      ...this.state,
    }
  }

  onSearchFailed(error, extra) {
    Lib.errorMessageHandler(get(error, 'response'))
    if (this.page > 1) this.page -= 1
    this.setState({ isLoading: false, isLoadingLoadMore: false })
  }

  preRead = () => {
    this.onSearch(this.search)
  }

  componentDidMount() {
    this.preRead()
  }

  render() {
    const { onSelect, selectedGroups } = this.props
    const { isLoading, isLoadingLoadMore, data } = this.state

    return (
      <Card
        size='small'
        type='inner'
        bodyStyle={{ padding: '0 0 12px 0' }}
        title='Group List'
        extra={<Icon type={isLoading ? 'loading' : 'reload'} onClick={this.preRead} />}
      >
        <div style={{ paddingBottom: '24px' }}>
          <div style={{ paddingBottom: '12px' }}>
            {/* <Input.Search placeholder='Search' onSearch={(val) => {this.search = val; this.preRead()} } /> */}
          </div>
          <div style={{ height: 'calc(100vh - 375px)', overflowY: 'auto' }} onScroll={this.onPopupScroll}>
            {data.map(val => (
              <div
                key={val.pk}
                className={`custom-menu-item ${selectedGroups.obj[val.pk] ? 'inactive' : ''}`}
                onClick={() => onSelect(val)}
                title={val.name}
              >
                {val.name}
              </div>
            ))}
          </div>
          <div className='d-flex justify-content-center align-items-center my-2 w-100' style={{ position: 'absolute', bottom: 0 }}>
            <Spin size='small' spinning={isLoadingLoadMore} />
          </div>
        </div>
      </Card>
    )
  }
}

SetsGroupListContainer.propTypes = {
  urlKey: PropTypes.string.isRequired,
  onSelect: PropTypes.func,
}

SetsGroupListContainer.defaultProps = {
  onSelect: () => null,
}

export default SetsGroupListContainer