export default [
  {
    title: 'Name',
    type: 'text',
    required: true,
    dataIndex: 'name',
    initialValue: '',
    order: 0,
  }, {
    title: 'Code',
    type: 'text',
    dataIndex: 'code',
    initialValue: '',
  }, {
    title: 'Country',
    required: true,
    type: 'SelectQuery',
    data: 'countries',
    initialValue: undefined,
    selectedKey: 'pk',
    selectedValue: 'name',
    dataIndex: 'country',
    additional: 'edit|add',
  },
];