import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Table, Button, Input } from 'antd'
import { get } from 'lodash'
import DrawerGroups from './DrawerGroups'

class TemplateList extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isShowDrawer: false,
      selectedGroups: [], // array of id merchant group
    }
  }

  onCloseDrawer = () => {
    this.onToggleGroups(false)
  }

  onToggleGroups = (isShowDrawer, callback = () => null) => {
    this.setState({ isShowDrawer }, callback)
  }

  onJoinGroups = (ids, groups, callback) => {
    this.props.onJoinGroups(ids, groups, (isSuccess) => {
      if (isSuccess) {
        this.onToggleGroups(false)
      }
      callback(isSuccess)
    })
  }

  onSearch = (val, event) => {
    this.props.refresh('isSaving', { page: 1, search: val })
  }

  onPageChange = (page, pageSize) => {
    this.props.refresh('isSaving', { page, limit: pageSize })
  }

  onChangeSelection = (selectedRowKeys, selectedRows) => {
    this.setState({ selectedGroups: selectedRowKeys })
  }

  onClearSelected = () => {
    this.setState({ selectedGroups: [] })
  }

  onRemoveSelectedGroups = () => {
    if (this.state.selectedGroups.length === 0) {
      return
    }
    this.props.removeGroups(this.state.selectedGroups, isSuccess => {
      if (isSuccess) {
        this.setState({ selectedGroups: [] })
      }
    })
  }

  onRowClick = (record, event) => {
    const { selectedGroups } = this.state
    if (selectedGroups.find(id => id === record.pk)) {
      this.setState({ selectedGroups: selectedGroups.filter(id => id !== record.pk) })
    } else {
      this.setState(prevState => ({ selectedGroups: [...prevState.selectedGroups, record.pk] }))
    }
  }

  render() {
    const { columns, dataSource, userReducer } = this.props
    const { isShowDrawer, selectedGroups } = this.state

    return (
      <div>
        <Row style={{ padding: '0 24px 24px 24px' }}>
          <Row className='mb-3' type='flex' justify='space-between' gutter={[12, 12]}>
            <Col style={{ fontSize: '16px', fontWeight: 500 }}>
              <Row type='flex' gutter={[12, 12]}>
                <Col>
                  <Button type='primary' icon='plus' onClick={() => this.onToggleGroups(true)}>
                    Select Group
                  </Button>
                </Col>
                {selectedGroups.length > 0 && (
                  <Col>
                    <Button.Group>
                      <Button onClick={this.onClearSelected}>
                        Clear selected
                      </Button>
                      <Button type='danger' icon='delete' onClick={this.onRemoveSelectedGroups}>
                        Remove Groups
                      </Button>
                    </Button.Group>
                  </Col>
                )}
              </Row>
            </Col>
            <Col>
              <Row type='flex' gutter={[12, 12]}>
                <Col>
                  {/* <Button icon='reload' onClick={() => this.props.refresh('isSaving')}>
                    Refresh
                  </Button> */}
                </Col>
                <Col>
                  {/* <Input.Search
                    placeholder='Search members'
                    onSearch={this.onSearch}
                    style={{ width: '250px' }}
                  /> */}
                </Col> 
              </Row>
            </Col>
          </Row>
          <Row>
            <Table
              bordered
              rowKey='pk'
              size='middle'
              scroll={{ x: true }}
              columns={columns}
              dataSource={dataSource}
              pagination={false}
              // pagination={{
              //   pageSize: get(rawMembers, 'limit'),
              //   total: get(rawMembers, 'count'),
              //   showTotal: (total, range) => (
              //     <span style={{ float: 'left' }}>{dataSource.length} of {total} members</span>
              //   ),
              //   onChange: this.onPageChange,
              // }}
              rowSelection={{
                onChange: this.onChangeSelection,
                selectedRowKeys: selectedGroups,
              }}
              onRow={(record) => ({ onClick: (event) => this.onRowClick(record, event)})}
            />
          </Row>
        </Row>
        <DrawerGroups
          visible={isShowDrawer}
          userReducer={userReducer}
          groups={dataSource}
          onClose={this.onCloseDrawer}
          onJoinGroups={this.onJoinGroups}
        />
      </div>
    )
  }
}

TemplateList.propTypes = {
  userReducer: PropTypes.object.isRequired,
  columns: PropTypes.array,
  dataSource: PropTypes.array,
  onJoinGroups: PropTypes.func,
  removeGroups: PropTypes.func,
}

TemplateList.defaultProps = {
  columns: [],
  dataSource: [],
  onJoinGroups: () => null,
  removeGroups: () => null,
}

export default TemplateList