import { Modules } from 'App/Utils'

export default [
  {
    title: 'Label',
    dataIndex: 'label',
    width: '150px',
  }, {
    title: 'Import Type',
    dataIndex: 'import_type',
    width: '120px',
  }, {
    title: 'Content Type',
    dataIndex: 'content_type.label',
    width: '140px',
  }, {
    title: 'Row Number',
    dataIndex: 'row_number',
    width: '100px',
  }, {
    title: 'Saved',
    dataIndex: 'saved',
    width: '90px',
    render: (value) => Modules.renderStatusAIU(value),
  }, {
    title: 'Valid',
    dataIndex: 'valid',
    width: '90px',
    render: (value) => Modules.renderStatusAIU(value),
  },
]