export default [
  {
    title: 'File',
    type: 'file',
    data: '',
    initialValue: '',
    note_text: '',
    fieldKey: 'file',
    view: true,
    editable: true,
    additional: 'edit|add',
    dataIndex: 'file',
    order: 1,
  }, {
    title: 'Link',
    type: 'text',
    data: '',
    initialValue: '',
    note_text: '',
    fieldKey: 'link',
    view: true,
    editable: true,
    additional: 'edit|add',
    dataIndex: 'link',
    order: 2,
  }
];
