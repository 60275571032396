const module = 'expert-system'
const list = [
  {
    label: 'Keywords',
    url: `/${module}/keywords`,
    icon: 'funnel-plot',
    code: 'expert_system.{}_keyword',
  },
  {
    label: 'Keyword Groups',
    url: `/${module}/keyword-groups`,
    icon: 'deployment-unit',
    code: 'expert_system.{}_keywordgroup',
  },
  {
    label: 'Keyword Pages',
    url: `/${module}/keyword-pages`,
    icon: 'security-scan',
    code: 'expert_system.{}_targetpage',
  },
  {
    label: 'Rules',
    url: `/${module}/rules`,
    icon: 'apartment',
    code: 'expert_system.{}_rule',
  },
  {
    label: 'Feedbacks',
    url: `/${module}/feedbacks`,
    icon: 'message',
    code: 'expert_system.{}_feedback',
  },
]

export default { key: module, list }
