import PageMenu from "App/Component/PageMenu"

// ======= COMPONENT SCREEN
import Notifications from "./Notifications/";
import Broadcast from "./Broadcast";

const module = "admin";
const app = "notifications";
const routes = [
  {
    name: "Notifications Menu",
    path: `/${module}/${app}`,
    component: PageMenu,
    exact: true
  },
  {
    // title: "Notifications",
    routes: [
      {
        name: "Notifications",
        component: Notifications.List,
        props: { urlName: "api:admin:notifications:notification:list" },
      },
      {
        name: "Notifications",
        component: Notifications.Detail,
        exact: false,
        props: { urlName: "api:admin:notifications:notification:detail" },
      }
    ]
  },
  {
    routes: [
      {
        name: "Broadcasts",
        component: Broadcast.List,
        props: { urlName: "api:admin:notifications:broadcast:list" },
      },
      {
        name: "Broadcasts",
        component: Broadcast.Detail,
        exact: false,
        props: { urlName: "api:admin:notifications:broadcast:detail" },
      }
    ]
  },
]

export default { appName: "Notifications", routes };