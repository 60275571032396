import { BaseDetail } from 'App/Screens/SubModules/Component'
import fields from 'App/Screens/Job/JobTypes/fields'

class PageDetail extends BaseDetail {
  constructor(props) {
    super(props)

    this.urlKey = 'module-job-jobType-detail'
    this.title = 'Job Type'
    this.urlList = '/job-management/job-type'
    this.headerDescriptions = [
      { key: 'name', label: 'Name' },
      { key: 'created', label: 'Created', type: 'datetime' },
      { key: 'created_by.username', label: 'Created By' },
      { key: 'modified', label: 'Modified', type: 'datetime' },
      { key: 'modified_by.username', label: 'Modified By' },
    ]
    this.fields = fields
    this.history = {
      url: 'module-job-jobType-history',
      fields: fields,
      urlDetail: "module-job-jobType-history-detail",
      id: props.match.params.id,
      title: 'name'
    }
  }
}

export default PageDetail