import { get } from 'lodash'

import { BaseDetail } from 'App/Screens/SubModules/Component'
import fields from 'App/Screens/ERF/ErfTypes/fields'
import fieldsErfMapping from 'App/Screens/SubModules/JobMg/ErfMapping/Management/fields'
import { ErfMappingReference } from './References'

class PageDetail extends BaseDetail {
  constructor(props) {
    super(props)

    this.urlKey = 'module-job-erfType-detail'
    this.title = 'ERF'
    this.urlList = '/job-management/erf'
    this.headerDescriptions = [
      { key: 'name', label: 'Name' },
      { key: 'label', label: 'Label' },
      { key: 'fields', label: 'Allowed Fields', type: 'arrayString' },
    ]
    this.fields = fields
    this.panes = [ErfMappingReference]
    this.refField = {
      name: 'erf_type',
      type: 'object',
      valKey: 'pk',
      valLabel: 'label',
    }
    this.propsDetail = {
      convertDataRecord: (data, dataIndex) => {
        if (dataIndex === 'fields') {
          return (get(data, dataIndex) || []).reduce((acc, val) => acc + `${val}\n`, '')
        }
        return get(data, dataIndex)
      },
    }
  }

  readSuccess = (response, extra) => {
    const erfFieldIndex = fieldsErfMapping.findIndex(field => field.dataIndex === 'erf_field')
    if (erfFieldIndex !== -1 && Array.isArray(response.data.fields)) {
      fieldsErfMapping[erfFieldIndex].data = response.data.fields.map(lbl => ({ alias: lbl, value: lbl }))
    }

    this.setState({ [extra.keyLoading]: false, data: response.data }, extra.callback)
  }
}

export default PageDetail