import PageMenu from "App/Component/PageMenu";

// ======= COMPONENT SCREEN
import Accounts from "./Accounts";
import BuildingTypes from "./BuildingTypes/";
import Buildings from "./Buildings/";
import ExtraChargeJobs from "./ExtraChargeJobs/";
import ExtraChargeSizes from "./ExtraChargeSizes/";
import ExtraCharges from "./ExtraCharges/";
import Holidays from "./Holidays/";
import JobStatusNotSynceds from "./JobStatusNotSynceds/";
import JobGrades from "./JobGrades/";
import JobQueues from "./JobQueues/";
import JobStatusHistories from "./JobStatusHistories/";
import JobTypes from "./JobTypes/";
import MerchantGroups from "./MerchantGroups/";
import Merchants from "./Merchants/";
import OperatingBranches from "./OperatingBranches/";
import Ranges from "./Ranges/";
import RateNames from "./RateNames/";
import RateTypes from "./RateTypes/";
import TagMerchants from "./TagMerchants";
import Tags from "./Tags/";
import TakeOffTypes from "./TakeOffTypes/";

const module = "admin";
const app = "jobs";
const routes = [
  {
    name: 'Jobs Menu',
    path: `/${module}/${app}`,
    component: PageMenu,
    exact: true
  },
  {
    // title: "Accounts",
    routes: [
      {
        name: 'Accounts',
        component: Accounts.List,
        props: { urlName: "api:admin:jobs:account:list" },
      },
      {
        name: 'Accounts History',
        component: Accounts.Detail,
        path: "/admin/jobs/accounts/:id/history/:historyId",
      },
      {
        name: 'Accounts',
        component: Accounts.Detail,
        exact: false,
        props: { urlName: "api:admin:jobs:account:detail" },
      }
    ]
  },
  {
    // title: "Building Types",
    routes: [
      {
        name: 'Building Types',
        component: BuildingTypes.List,
        props: { urlName: "api:admin:jobs:buildingtype:list" },
      },
      {
        name: 'Building Types',
        component: BuildingTypes.Detail,
        exact: false,
        props: { urlName: "api:admin:jobs:buildingtype:detail" },
      }
    ],
  },
  {
    // title: "Buildings",
    routes: [
      {
        name: "Buildings",
        component: Buildings.List,
        props: { urlName: "api:admin:jobs:building:list" },
      },
      {
        name: "Buildings History",
        component: Buildings.Detail,
        path: "/admin/jobs/buildings/:id/history/:historyId",
      },
      {
        name: "Buildings",
        component: Buildings.Detail,
        exact: false,
        props: { urlName: "api:admin:jobs:building:detail" },
      }
    ]
  },
  // {
  //   // title: "Extra Charge Jobs",
  //   routes: [
  //     {
  //       name: "Extra Charge Jobs",
  //       component: ExtraChargeJobs.List,
  //       props: { urlName: "api:admin:jobs:extrachargejob:list" },
  //     },
  //     {
  //       name: "Extra Charge Jobs",
  //       component: ExtraChargeJobs.Detail,
  //       exact: false,
  //       props: { urlName: "api:admin:jobs:extrachargejob:detail" },
  //     }
  //   ]
  // },
  {
    // title: "Extra Charge Sizes",
    routes: [
      {
        name: "Extra Charge Sizes",
        component: ExtraChargeSizes.List,
        props: { urlName: "api:admin:jobs:extrachargesize:list" },
      },
      {
        name: "Extra Charge Sizes History",
        component: ExtraChargeSizes.Detail,
        path: "/admin/jobs/extra-charge-sizes/:id/history/:historyId",
      },
      {
        name: "Extra Charge Sizes",
        component: ExtraChargeSizes.Detail,
        exact: false,
        props: { urlName: "api:admin:jobs:extrachargesize:detail" },
      }
    ]
  },
  {
    // title: "Extra Charges",
    routes: [
      {
        name: "Extra Charges",
        component: ExtraCharges.List,
        props: { urlName: "api:admin:jobs:extracharge:list" },
      },
      {
        name: "Extra Charges History",
        component: ExtraCharges.Detail,
        path: "/admin/jobs/extra-charges/:id/history/:historyId",
      },
      {
        name: "Extra Charges",
        component: ExtraCharges.Detail,
        exact: false,
        props: { urlName: "api:admin:jobs:extracharge:detail" },
      }
    ]
  },
  {
    // title: "Holidays",
    routes: [
      {
        name: "Holidays",
        component: Holidays.List,
        props: { urlName: "api:admin:jobs:holiday:list" },
      },
      {
        name: "Holidays History",
        component: Holidays.Detail,
        path: "/admin/jobs/holidays/:id/history/:historyId",
      },
      {
        name: "Holidays",
        component: Holidays.Detail,
        exact: false,
        props: { urlName: "api:admin:jobs:holiday:detail" },
      }
    ]
  },
  // {
  //   title: "Job Status Not Synceds",
  //   routes: [
  //     {
  //       name: "Job Status Not Synceds",
  //       component: JobStatusNotSynceds.List,
  //       props: { urlName: "api:admin:jobs:jobstatusnotsynced:list" },
  //     },
  //     {
  //       name: "Job Status Not Synceds",
  //       component: JobStatusNotSynceds.Detail,
  //       exact: false,
  //       props: { urlName: "api:admin:jobs:jobstatusnotsynced:detail" },
  //     }
  //   ]
  // },
  {
    // title: "Job Grades",
    routes: [
      {
        name: "Job Grades",
        component: JobGrades.List,
        props: { urlName: "api:admin:jobs:jobgrade:list" },
      },
      {
        name: "Job Grades History",
        component: JobGrades.Detail,
        path: "/admin/jobs/job-grades/:id/history/:historyId",
      },
      {
        name: "Job Grades",
        component: JobGrades.Detail,
        exact: false,
        props: { urlName: "api:admin:jobs:jobgrade:detail" },
      }
    ]
  },
  {
    // title: "Job Queues",
    routes: [
      {
        name: "Job Queues",
        component: JobQueues.List,
        props: { urlName: "api:admin:jobs:jobqueue:list" },
      },
      {
        name: "Job Queues History",
        component: JobQueues.Detail,
        path: "/admin/jobs/job-queues/:id/history/:historyId",
      },
      {
        name: "Job Queues",
        component: JobQueues.Detail,
        exact: false,
        props: { urlName: "api:admin:jobs:jobqueue:detail" },
      }
    ]
  },
  // {
  //   // title: "Job Status Histories",
  //   routes: [
  //     {
  //       name: "Job Status Histories",
  //       component: JobStatusHistories.List,
  //       props: { urlName: "api:admin:jobs:jobstatushistory:list" },
  //     },
  //     {
  //       name: "Job Status Histories",
  //       component: JobStatusHistories.Detail,
  //       exact: false,
  //       props: { urlName: "api:admin:jobs:jobstatushistory:detail" },
  //     }
  //   ]
  // },
  {
    // title: "Job Types",
    routes: [
      {
        name: "Job Types",
        component: JobTypes.List,
        props: { urlName: "api:admin:jobs:jobtype:list" },
      },
      {
        name: "Job Types History",
        component: JobTypes.Detail,
        path: "/admin/jobs/job-types/:id/history/:historyId",
      },
      {
        name: "Job Types",
        component: JobTypes.Detail,
        exact: false,
        props: { urlName: "api:admin:jobs:jobtype:detail" },
      }
    ]
  },
  {
    // title: "Merchant Groups",
    routes: [
      {
        name: "Merchant Groups",
        component: MerchantGroups.List,
        props: { urlName: "api:admin:jobs:merchantgroup:list" },
      },
      {
        name: "Merchant Groups History",
        component: MerchantGroups.Detail,
        path: "/admin/jobs/merchant-groups/:id/history/:historyId",
      },
      {
        name: "Merchant Groups",
        component: MerchantGroups.Detail,
        exact: false,
        props: { urlName: "api:admin:jobs:merchantgroup:detail" },
      }
    ]
  },
  {
    // title: "Merchants",
    routes: [
      {
        name: "Merchants",
        component: Merchants.List,
        props: { urlName: "api:admin:jobs:merchant:list" },
      },
      {
        name: "Merchants History",
        component: Merchants.Detail,
        path: "/admin/jobs/merchants/:id/history/:historyId",
      },
      {
        name: "Merchants",
        component: Merchants.Detail,
        exact: false,
        props: { urlName: "api:admin:jobs:merchant:detail" },
      }
    ]
  },
  {
    // title: "Operating Branches",
    routes: [
      {
        name: "Operating Branches",
        component: OperatingBranches.List,
        props: { urlName: "api:admin:jobs:operatingbranch:list" },
      },
      {
        name: "Operating Branches",
        component: OperatingBranches.Detail,
        exact: false,
        props: { urlName: "api:admin:jobs:operatingbranch:detail" },
      }
    ]
  },
  {
    // title: "Ranges",
    routes: [
      {
        name: "Ranges",
        component: Ranges.List,
        props: { urlName: "api:admin:jobs:ranges:list" },
      },
      {
        name: "Ranges History",
        component: Ranges.Detail,
        path: "/admin/jobs/ranges/:id/history/:historyId",
      },
      {
        name: "Ranges",
        component: Ranges.Detail,
        exact: false,
        props: { urlName: "api:admin:jobs:ranges:detail" },
      }
    ]
  },
  {
    // title: "Rate Names",
    routes: [
      {
        name: "Rate Names",
        component: RateNames.List,
        props: { urlName: "api:admin:jobs:ratename:list" },
      },
      {
        name: "Rate Names",
        component: RateNames.Detail,
        exact: false,
        props: { urlName: "api:admin:jobs:ratename:detail" },
      }
    ]
  },
  {
    // title: "Rate Types",
    routes: [
      {
        name: "Rate Types",
        component: RateTypes.List,
        props: { urlName: "api:admin:jobs:ratetype:list" },
      },
      {
        name: "Rate Types History",
        component: RateTypes.Detail,
        path: "/admin/jobs/rate-types/:id/history/:historyId",
      },
      {
        name: "Rate Types",
        component: RateTypes.Detail,
        exact: false,
        props: { urlName: "api:admin:jobs:ratetype:detail" },
      }
    ]
  },
  {
    // title: "Tags",
    routes: [
      {
        name: "Tag Merchants",
        component: TagMerchants.List,
        props: { urlName: "api:admin:jobs:tagmerchant:list" },
      },
      {
        name: "Tag Merchants History",
        component: TagMerchants.Detail,
        path: "/admin/jobs/tag-merchants/:id/history/:historyId",
      },
      {
        name: "Tag Merchants",
        component: TagMerchants.Detail,
        exact: false,
        props: { urlName: "api:admin:jobs:tagmerchant:detail" },
      }
    ]
  },
  {
    // title: "Tags",
    routes: [
      {
        name: "Tags",
        component: Tags.List,
        props: { urlName: "api:admin:jobs:tag:list" },
      },
      {
        name: "Tags History",
        component: Tags.Detail,
        path: "/admin/jobs/tags/:id/history/:historyId",
      },
      {
        name: "Tags",
        component: Tags.Detail,
        exact: false,
        props: { urlName: "api:admin:jobs:tag:detail" },
      }
    ]
  },
  {
    // title: "Take Off Types",
    routes: [
      {
        name: "Take Off Types",
        component: TakeOffTypes.List,
        props: { urlName: "api:admin:jobs:takeofftype:list" },
      },
      {
        name: "Take Off Types History",
        component: TakeOffTypes.Detail,
        path: "/admin/jobs/take-off-types/:id/history/:historyId",
      },
      {
        name: "Take Off Types",
        component: TakeOffTypes.Detail,
        exact: false,
        props: { urlName: "api:admin:jobs:takeofftype:detail" },
      }
    ]
  }
];

export default { appName: "Jobs", routes };
