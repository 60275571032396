import React from 'react'
import PropTypes from 'prop-types'
import {Col, Row, Menu, Divider, Button, Affix, Timeline, Typography, Descriptions, Avatar, Tooltip, Tag, Spin, Icon, message} from 'antd'
import moment from 'moment'
import { get } from 'lodash'
import { RequestV2 as Request } from 'App/Utils'
import UserEditProfile from 'App/Component/User/UserEditProfile'
import { General, EditProfile, ChangePassword, Permission } from './Component'
import { PagePermission } from './Pages'
import { PageNotFound } from 'App/Component'

const KEY_GENERAL = 'general'
const KEY_CHANGE_PROFILE = 'edit-profile'
const KEY_CHANGE_PASSWORD = 'change-password'
const KEY_PERMISSION = 'permission-profile'

class UserProfile extends React.PureComponent {
  constructor(props) {
    super(props)

    const defaultSelectedMenu = KEY_GENERAL
    this.state = {
      isLoadProfile: true,
      isReloading: false,
      myProfile: null,
      selectedMenu: defaultSelectedMenu,
      keysHasOpened: { [defaultSelectedMenu]: true }, // keys yang pernah di buka (meniru cara kerja Tab)
    }
  }

  setThisState = (obj, callback = () => null, force) => {
    if (force) {
      return this.setState(obj, callback)
    } else {
      for (const key in obj) {
        if (obj[key] !== this.state[key]) {
          return this.setState(obj, callback)
        }
      }
    }
    return callback()
  }

  readProfile = (keyLoading = 'isLoadProfile') => {
    this.setThisState({ [keyLoading]: true }, () => {
      Request(
        'get',
        'read-profile',
        {},
        {},
        [],
        this.readProfileSuccess,
        this.readProfileFailed,
        { keyLoading },
      )  
    })
  }

  readProfileSuccess = (response, extra) => {
    this.setState({ [extra.keyLoading]: false, myProfile: response.data })
  }

  readProfileFailed = (error, extra) => {
    message.error('Failed load profile')
    this.setState({ [extra.keyLoading]: false })
  }

  onSelectMenu = (props) => {
    this.setState(prevState => ({ selectedMenu: props.key, keysHasOpened: {...prevState.keysHasOpened, [props.key]: true } }))
  }

  setSelectedMenu = (selectedMenu) => {
    this.setState(prevState => ({ selectedMenu, keysHasOpened: {...prevState.keysHasOpened, [selectedMenu]: true} }))
  }

  setProfile = (newProfile, callback = () => null) => {
    this.setState({ myProfile: newProfile }, callback)
  }

  backToGeneral = () => {
    this.setState({ selectedMenu: KEY_GENERAL })
  }

  componentDidMount() {
    setTimeout(() => {
      this.readProfile()
    }, 250)
  }

  render() {
    const { toggle } = this.props
    const { isLoadProfile, isReloading, myProfile, selectedMenu, keysHasOpened } = this.state

    if (isLoadProfile) {
      return (
        <Row type='flex' justify='center' align='middle' style={{ height: '100vh' }}>
          <Col>
            <Spin
              size="large"
              tip="Loading..."
              indicator={<Icon type="loading" style={{ fontSize: "4rem" }} spin />}
            />
          </Col>
        </Row>
      )
    }

    return myProfile ? (
      <div style={{ height: '100vh', overflowY: 'auto' }} ref={ref => {this._container = ref}}>
        <Affix offsetTop={0} target={() => this._container}>
          <div style={{ height: '64px' }}>
            <Row type='flex'>
              <Col style={{ width: 'calc(100% - 64px)' }}>
                <Menu mode='horizontal' selectedKeys={[selectedMenu]} onSelect={this.onSelectMenu} style={{ lineHeight: '64px', marginTop: '-2px' }}>
                  <Menu.Item key={KEY_GENERAL}>
                    General
                  </Menu.Item>
                  <Menu.Item key={KEY_CHANGE_PROFILE}>
                    Edit Profile
                  </Menu.Item>
                  <Menu.Item key={KEY_CHANGE_PASSWORD}>
                    Change password
                  </Menu.Item>
                  <Menu.Item key={KEY_PERMISSION}>
                    Permission
                  </Menu.Item>
                </Menu>
              </Col>
              <Col style={{ width: '64px', borderBottom: '1px solid #e8e8e8', background: '#fff' }}>
                <Row className='hover-danger' type='flex' justify='center' align='middle' style={{ height: '100%' }} onClick={() => toggle()}>
                  <Col>
                    <Icon type='close' />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Affix>
        <Row>
          {/* Content */}
          <Col style={{ padding: '24px 12px' }}>
            {/* Content Item General */}
            <div style={{ display: selectedMenu === KEY_GENERAL ? 'block' : 'none' }}>
              {keysHasOpened[KEY_GENERAL] && (
                <General profile={myProfile} setSelectedMenu={this.setSelectedMenu} />
              )}
            </div>
            {/* Content Item Edit Profile */}
            <div style={{ display: selectedMenu === KEY_CHANGE_PROFILE ? 'block' : 'none' }}>
              {selectedMenu === KEY_CHANGE_PROFILE && (
                <EditProfile userReducer={this.props.userReducer} profile={myProfile} setProfile={this.setProfile} />
              )}
            </div>
            {/* Content Item Change Password */}
            <div style={{ display: selectedMenu === KEY_CHANGE_PASSWORD ? 'block' : 'none' }}>
              {selectedMenu === KEY_CHANGE_PASSWORD && (
                <ChangePassword userReducer={this.props.userReducer} backToGeneral={this.backToGeneral} />
              )}
            </div>
            {/* Content Item Permission */}
            <div style={{ display: selectedMenu === KEY_PERMISSION ? 'block' : 'none' }}>
              {keysHasOpened[KEY_PERMISSION] && (
                <PagePermission profile={myProfile} />
              )}
            </div>
          </Col>
        </Row>
      </div>
    ) : (
      <PageNotFound
        title='Error'
        subTitle='Failed to collect your profile data'
        extra={
          <Button type='primary' loading={isReloading} onClick={() => this.readProfile('isReloading')}>
            Show my profile
          </Button>
        }
      />
    )
  }
}

UserProfile.propTypes = {
  userReducer: PropTypes.object.isRequired,
  toggle: PropTypes.func
}

UserProfile.defaultProps = {
  toggle: () => null,
}

export default UserProfile