import { BaseDetail } from 'App/Screens/SubModules/Component'

class PageDetail extends BaseDetail {
  constructor(props) {
    super(props)

    this.urlKey = 'module-job-transition-detail'
    this.title = 'Transition'
    this.urlList = '/job-management/transition'
    this.headerDescriptions = [
      { key: 'action_name', label: 'Action Name' },
      { key: 'from_status', label: 'From Status' },
      { key: 'to_status', label: 'To Status' },
      { key: 'need_assign_user', label: 'Need Assign User', type: 'bool' },
      { key: 'created', label: 'Created', type: 'datetime' },
      { key: 'created_by.username', label: 'Created By' },
      { key: 'modified', label: 'Modified', type: 'datetime' },
      { key: 'modified_by.username', label: 'Modified By' },
    ]
  }
}

export default PageDetail