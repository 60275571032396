import descriptor from './descriptorOffline'

export default {
  key: 'extraChargeMapping',
  title: 'Extra Charge Mappings',
  icon: 'shop',
  manualFilter: { key: 'extra_charge_size', value: 'pk' },
  contentProps: {
    availablePermissions: ['.view_'],
    appName: 'jobs',
    modelName: 'extrachargemapping',
  },
  propsList: {
    avatar: 'scan_price',
    masterKey: 'module-merchant-extraCharge-refExtraChargeMapping',
    descriptor,
    availablePermissions: [], // jika array kosong, maka tidak ada filter. Jika tidak dikirim, default []
    onAddClick: () => null, // can override or open urlTarget + /add
    onDetailClick: () => null, // can override or open urlTarget + /:id
  }
}