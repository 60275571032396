import React from 'react'
import { Tag } from 'antd'

const descriptorOffline = {
  "app_label": "expertsystem",
  "model_name": "keyword",
  "pagination": true,
  "columns": [
    {
      "name": "name",
      "label": "Name",
      "data_type": "str",
      sort: true,
    },
    {
      "name": "subsections",
      "label": "Subsections",
      ellipsis: true,
      render: items => items && items.map((item) => <Tag key={item.pk} color='#2db7f5'>{item.identifier}</Tag>)
    },
    {
      "name": "groups",
      "label": "Groups",
      render: items => items && items.map((item) => <Tag key={item.pk} color='#2db7f5'>{item.name}</Tag>)
    },
    {
      "name": "pages",
      "label": "Pages",
      render: items => items && items.map((item) => <Tag key={item.pk} color='#2db7f5'>{item.name}</Tag>)
    },
    {
      "name": "is_important",
      "label": "Is Important",
      "data_type": "bool",
      sort: true,
    },
    {
      "name": "is_active",
      "label": "Is Active",
      "data_type": "bool",
    },
  ],
  "filter_by": [
    {
      "label": "Is Active",
      "name": "is_active",
      "type": "switch",
    },
    {
      "label": "Is Important",
      "name": "is_important",
      "type": "switch",
    },
    {
      "label": "Has Group",
      "name": "has_group",
      "type": "switch"
    },
    {
      "label": "Has Page",
      "name": "has_page",
      "type": "switch"
    },
    {
      "label": "Has Subsection",
      "name": "has_subsection",
      "type": "switch"
    },
  ],
  "export_import": null
}

export default descriptorOffline