import { BaseDetail } from 'App/Screens/SubModules/Component'
import fields from 'App/Screens/Job/BuildingTypes/fields'
import { BuildingReference } from './References'

class PageDetail extends BaseDetail {
  constructor(props) {
    super(props)

    this.urlKey = 'module-job-buildingType-detail'
    this.title = 'Building Type'
    this.urlList = '/job-management/building-type'
    this.headerDescriptions = [
      { key: 'name', label: 'Name' },
      { key: 'sequence', label: 'Sequence' },
      { key: 'icon', label: 'Icon', type: 'file', link: 'file', display: 'file_name' },
      { key: 'created', label: 'Created', type: 'datetime' },
      { key: 'created_by.username', label: 'Created By' },
      { key: 'modified', label: 'Modified', type: 'datetime' },
      { key: 'modified_by.username', label: 'Modified By' },
    ]
    this.fields = fields
    this.history = {
      url: 'module-job-buildingType-history',
      fields: fields,
      urlDetail: "module-job-buildingType-history-detail",
      id: props.match.params.id,
      title: 'name',
      destroyOnSave: true
    }
    this.panes = [BuildingReference]
  }
}

export default PageDetail