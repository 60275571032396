import React from 'react'
import { Tag } from 'antd'

const descriptorOffline = {
  "app_label": "expertsystem",
  "model_name": "keywordgroup",
  "pagination": true,
  "columns": [
    {
      "name": "name",
      "label": "Name",
      "data_type": "str",
      sort: true,
      default_sorting: "asscend", // typo, lihat di src/App/Component/Singlepage/Lib2.js "getNexticon" function
      default_sorting_priority: 2,
    },
    {
      "name": "order",
      "label": "Order",
      "data_type": "int",
      "sort": true,
      default_sorting: "asscend",
      default_sorting_priority: 1,
    },
    {
      "name": "is_important",
      "label": "Is Important",
      "data_type": "bool",
      "sort": true,
    },
    {
      "name": "is_active",
      "label": "Is Active",
      "data_type": "bool",
    },
    {
      "name": "modified",
      "label": "Modified",
      "data_type": "datetime",
      sort: true,
    },
  ],
  "filter_by": [
    {
      "label": "Is Important",
      "name": "is_important",
      "type": "switch"
    },
    {
      "label": "Is Active",
      "name": "is_active",
      "type": "switch"
    },
  ],
  "export_import": null
}

export default descriptorOffline