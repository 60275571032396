export default {
  "staffLeaves-read": "api/admin/users/staff-leaves/",
  "staffLeaves-create": "api/admin/users/staff-leaves/",
  "staffLeaves-delete": "api/admin/users/staff-leaves/{}/",
  "staffLeaves-update": "api/admin/users/staff-leaves/{}/",
  "staffLeaves-detail": "api/admin/users/staff-leaves/{}/",
  "staffLeaves-detail-revision-version": "api/admin/users/staff-leaves/{}/revision/{}/",
  "staffLeaves-autocomplete": "api/admin/users/staff-leaves/autocomplete/",

  "staffPositions-read": "api/admin/users/staff-positions/",
  "staffPositions-create": "api/admin/users/staff-positions/",
  "staffPositions-delete": "api/admin/users/staff-positions/{}/",
  "staffPositions-update": "api/admin/users/staff-positions/{}/",
  "staffPositions-detail": "api/admin/users/staff-positions/{}/",
  "staffPositions-detail-revision-version": "api/admin/users/staff-positions/{}/revision/{}/",
  "staffPositions-autocomplete": "api/admin/users/staff-positions/autocomplete/",

  "users-read": "api/admin/users/users/",
  "users-create": "api/admin/users/users/",
  "users-delete": "api/admin/users/users/{}/",
  "users-update": "api/admin/users/users/{}/",
  "users-detail": "api/admin/users/users/{}/",
  "users-autocomplete": "api/admin/users/users/autocomplete/",

  "user-read": "api/admin/users/users/",
  "user-create": "api/admin/users/users/",
  "user-delete": "api/admin/users/users/{}/",
  "user-update": "api/admin/users/users/{}/",
  "user-detail": "api/admin/users/users/{}/",
  "user-autocomplete": "api/admin/users/users/autocomplete/",

  "permissions-autocomplete": "api/admin/users/permissions/autocomplete/",

  "groups-read": "api/admin/users/groups/",
  "groups-create": "api/admin/users/groups/",
  "groups-delete": "api/admin/users/groups/{}/",
  "groups-update": "api/admin/users/groups/{}/",
  "groups-detail": "api/admin/users/groups/{}/",
  "groups-autocomplete": "api/admin/users/groups/autocomplete/",
}
