import React from 'react';
import pako from 'pako';
import { Icon, message } from "antd";
import { get, kebabCase, camelCase } from "lodash";
import browser from "browser-detect";
import moment from "moment";
import { getValue } from "./internal"

const validate = require("validate.js");

function toCamelCase(str) {
  return str
    .split(" ")
    .map(function(word, index) {
      // If it is not the first word only upper case the first char and lowercase the rest.
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join("");
}

function buildFormData(formData, data, parentKey) {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach(key => {
      if (Array.isArray(data)){
          buildFormData(formData, data[key], parentKey ? `${parentKey}` : key);
      } else {
        buildFormData(formData, data[key], parentKey ? `${parentKey}.${key}` : key);
      }
    });
  } else {
    const value = data == null ? '' : data;

    formData.append(parentKey, value);
  }
}

function jsonToFormData(data) {
  const formData = new FormData();

  buildFormData(formData, data);

  return formData;
}

// import { FileExcelOutlined, FileImageOutlined, FileOutlined, FilePdfOutlined, FileTextOutlined, FileWordOutlined, FileZipOutlined } from '@ant-design/icons';

function getFileExtension(filename) {
  return (filename || '').split('.').pop()
}

function renderFileIcon(filename, opt = {}) {
  let { type, ...iconProps } = opt
  let iconType = type || 'file'

  switch(getFileExtension(filename)) {
    case 'pdf': {iconType = 'file-pdf'; break}
    case 'txt': {iconType = 'file-text'; break}
    case 'doc':
    case 'docx': {iconType = 'file-word'; break}
    case 'bmp':
    case 'gif':
    case 'ico':
    case 'jpg':
    case 'png': {iconType = 'file-image'; break}
    case 'zip':
    case 'rar': {iconType = 'file-zip'; break}
    case 'xls':
    case 'xlsx': {iconType = 'file-excel'; break}
    default: {}
  }

  return <Icon type={iconType} style={{ fontSize: '64px' }} {...iconProps} />
}

function formatFileSize(fileSize) {
  if (typeof fileSize !== 'number') return fileSize

  let size = fileSize, type = 'Byte'
  if (size > 1000) {
    size = size / 1024
    type = 'KB'
  }

  if (size > 1000) {
    size = size / 1024
    type = 'MB'
  }

  if (size > 1000) {
    size = size / 1024
    type = 'GB'
  }

  return `${Number((size).toFixed(0)).toLocaleString()} ${type}`
}

export default {
  uuid() {
    return "ruid-xxxxxxxx-xxxx-4xxx-yxxx".replace(/[xy]/g, function(c) {
      var r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  },
  checkIsTemp(id) {
    const parseString = id.toString();
    const a = parseString.split("-");
    return a[0] === "ruid";
  },
  getAllMessage(obj) {
    let str = null;
    if (!obj) {
      if (obj instanceof Array) {
        str = [];
        for (let i = 0; i < obj.length; i++) {
          str.push(this.getAllMessage(obj[i]));
        }
        str = str.join("\n");
      } else if (obj instanceof Object) {
        str = [];
        for (let key in obj) {
          str.push(`${key}: \n${this.getAllMessage(obj[key])}`);
        }
        str = str.join("\n");
      } else {
        str = obj;
      }
      return str;
    } else {
      return "";
    }
  },
  /**
   |--------------------------------------------------
   | this function for update & save row direct to state
   |--------------------------------------------------
   */
  updateRow(response, dataState, key, identifier = "alias") {
    const newData = [...dataState];
    const index = newData.findIndex(item => item[identifier] === key);
    let dataFromServer = response.data.data;

    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
        ...dataFromServer
      });
    } else {
      newData.push(dataFromServer);
    }
    return newData;
  },
  /**
   |--------------------------------------------------
   | this function for delete row direct to state
   |--------------------------------------------------
   */
  deleteRow(rowData, key, identifier = "alias") {
    return [...rowData].filter(item => item[identifier] !== key);
  },
  onTableChange(pagination, filters, ordering) {
    let filter = [];
    let order = "";
    let page = 1;

    for (let key in filters) {
      filter = filter.concat(filters[key]);
    }
    filter = filter.join(",");

    if (Object.keys(ordering).length > 1) {
      if (ordering.column.sorter) {
        if (ordering.order === "ascend") {
          order = ordering.column.param_name;
        } else {
          order = "-" + ordering.column.param_name;
        }
      }
    }

    page = pagination.current;
    return { filter, order, page };
  },
  setSelectValue(data = null, valKey = "alias", valLabel = "name") {
    if (data) {
      if (data.length === 0) return [];

      if (data[valKey] === undefined) {
        return {
          key: get(data, "key", data),
          label: get(data, "label", data)
        };
      } else {
        return {
          key: data[valKey],
          label: this.getLabel(data, valLabel),
        };
      }
    } else {
      return undefined;
    }
  },
  getLabel(data, valLabel) {
    if (Array.isArray(valLabel)) {
      const values = []
      for (let i = 0; i < valLabel.length; i += 1)
        values.push(get(data, valLabel[i], ''))

      return values.join(' - ')
    }

    return get(data, valLabel)
  },
  setSelectValueMultiple(data = null, valKey = "alias", valLabel = "name") {
    const dataMultiple = []
    if (data) {
      for (let i in data) {
        dataMultiple.push(this.setSelectValue(data[i], valKey, valLabel))
      }
    }
    return dataMultiple
  },
  filterClick(column, filters, index, val) {
    let flag = column[index].filteredValue;
    if (flag.length > 0) {
      column[index].filteredValue = [];
      for (let i = 0; i < filters.length; i++) {
        if (filters[i] === val) {
          filters.splice(i, 1);
        }
      }
    } else {
      // active icon
      column[index].filteredValue = [val];
      filters = [...filters, val];
    }
    return [...new Set(filters.map(a => a))];
  },
  decoratorName(text) {
    let a = text.toLowerCase();
    a = a.replace(/-/g, "");
    return a.replace(/ /g, "_");
  },
  CamelToSentence(text) {
    return text
      .replace(/([A-Z])/g, match => ` ${match}`)
      .replace(/^./, match => match.toUpperCase())
      .trim();
  },
  windowStop() {
    if (browser().name === "safari") return null;
    window.stop();
  },
  OpenDetailUrl(url, ID, history) {
    history.push(url + "?id=" + ID);
  },
  bulkNameData(elem, bulkName) {
    if (bulkName.includes("-")) {
      if (bulkName.split("-")) {
        return (
          get(elem, bulkName.split("-")[0].split(".")) +
          " - " +
          get(elem, bulkName.split("-")[1].split("."))
        );
      }
    } else {
      return get(elem, bulkName.split("."));
    }
  },
  checkValues(values) {
    console.log('checkValues', values)
    for (let i in values) {
      if (values[i] === undefined) {
        values[i] = null
      } else {
        if (i === "date") {
          values[i] = moment(values[i]).format("YYYY-MM-DD");
        } else if ((
          i === "startdate" ||
          i === "enddate" ||
          i === "continue_till"
        ) && values[i]) {
          values[i] = moment(values[i]).format();
        } else if (typeof values[i] === "object" && values[i] !== null) {
          if (Array.isArray(values[i])) {
            let select_multiple = [];
            for (let j = 0; j < values[i].length; j += 1) {
              const item = values[i][j]
              if (typeof item === 'object') {
                if (item.hasOwnProperty("key") && item.key !== undefined) {
                  select_multiple.push(parseInt(item.key));
                } else if (item.hasOwnProperty("pk")) {
                  select_multiple.push(parseInt(item.pk))
                }
              } else {
                select_multiple = values[i].join(',')
                if (i === 'selector' || i === 'keywords') select_multiple = values[i]
                break
              }
            }
            // terpaksa, perlu perbaikan fungsi
            if (i === 'prefill_set_pattern')
              if (values[i])
                select_multiple = values[i].join(',')

            // ada multiple yang mengirim array, ada yang string dengan pemisah koma, ini harus di fix
            // Contoh di product, groups di isi array, tidak boleh null
            // di merchant untuk email, itu string dipisah koma, jika kosong maka null, bukan array kosong
            // values[i] = !validate.isEmpty(select_multiple) ? select_multiple : null;
            values[i] = select_multiple;
          } else {
            if (values[i].hasOwnProperty("key")) {
              values[i] = values[i].key; // for select Query data
            } else if (values[i].hasOwnProperty("pk")) {
              values[i] = values[i].pk;
            } else {
              values[i] = [];
            }
          }
        }
      }
    }
    return values;
  },
  getSelectValue(data, objMaps = { name: "label", alias: "key" }) {
    for (let key in objMaps) {
      data[key] = data[objMaps[key]];
    }
    return data;
  },
  incrementCount(count) {
    return count + 1;
  },
  incrementCountRow(count_row, limit) {
    let count = 0;
    if (count_row < limit) {
      count = count_row + 1;
    } else {
      count = count_row;
    }
    return count;
  },
  decrementCount(count) {
    return count - 1;
  },
  decrementCountRow(count_row, limit) {
    let count = 0;
    if (count_row < limit) {
      count = count_row - 1;
    } else {
      count = count_row;
    }
    return count;
  },
  generateURL(text, main_path) {
    // be careful, kebabCase of "abcDef/text2" -> "abc-def-text2", slash changed to "-"
    const mainPathUrl = (main_path || '').indexOf('/') > -1 ? main_path : kebabCase(main_path)
    return `/${mainPathUrl}/${kebabCase(text)}`;
  },
  generateComponentName(text) {
    return camelCase(text);
  },
  setFileList(file, keyMap) {
    // eslint-disable-next-line no-param-reassign
    keyMap = {
      name: "file_name",
      url: "attachment",
      thumbUrl: "attachment",
      uid: "pk",
      ...keyMap
    };
    if (!validate.isEmpty(file)) {
      if (validate.isArray(file)) {
        file = Object.assign([], file);
      } else {
        file = [Object.assign({}, file)];
      }
      for (let i = 0; i < file.length; i++) {
        file[i] = {
          uid: file[i][keyMap.uid],
          name: this.fillEmpty(
            file[i][keyMap.name],
            file[i][keyMap.url].substring(
              file[i][keyMap.url].lastIndexOf("/") + 1
            )
          ),
          url: file[i][keyMap.url],
          thumbUrl: this.fillEmpty(
            file[i][keyMap.thumbUrl],
            file[i][keyMap.url]
          ),
          status: "done"
        };
      }
      return file;
    } else {
      return [];
    }
  },
  ucFirst(word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  },
  responseCode(code) {
    const response_code = {
      "100": "Continue",
      "101": "Switching Protocols",
      "102": "Processing ",
      "103": "Early Hints",
      "200": "OK",
      "201": "Created",
      "202": "Accepted",
      "203": "Non-Authoritative Information",
      "204": "No Content",
      "205": "Reset Content",
      "206": "Partial Content",
      "207": "Multi-Status",
      "208": "Already Reported",
      "226": "IM Used",
      "300": "Multiple Choices",
      "301": "Moved Permanently",
      "302": "Found (Previously Moved temporarily)",
      "303": "See Other ",
      "304": "Not Modified",
      "305": "Use Proxy",
      "306": "Switch Proxy",
      "307": "Temporary Redirect",
      "308": "Permanent Redirect",
      "400": "Bad Request",
      "401": "Unauthorized",
      "402": "Payment Required",
      "403": "Forbidden",
      "404": "Not Found",
      "405": "Method Not Allowed",
      "406": "Not Acceptable",
      "407": "Proxy Authentication Required",
      "408": "Request Timeout",
      "409": "Conflict",
      "410": "Gone",
      "411": "Length Required",
      "412": "Precondition Failed",
      "413": "Payload Too Large",
      "414": "URI Too Long",
      "415": "Unsupported Media Type",
      "416": "Range Not Satisfiable",
      "417": "Expectation Failed",
      "418": "I'm a teapot",
      "421": "Misdirected Request ",
      "422": "Unprocessable Entity",
      "423": "Locked",
      "424": "Failed Dependency",
      "425": "Too Early",
      "426": "Upgrade Required",
      "428": "Precondition Required",
      "429": "Too Many Requests",
      "431": "Request Header Fields Too Large",
      "451": "Unavailable For Legal Reasons",
      "500": "Internal Server Error",
      "501": "Not Implemented",
      "502": "Bad Gateway",
      "503": "Service Unavailable",
      "504": "Gateway Timeout",
      "505": "HTTP Version Not Supported",
      "506": "Variant Also Negotiates",
      "507": "Insufficient Storage",
      "508": "Loop Detected",
      "510": "Not Extended",
      "511": "Network Authentication Required"
    };
    return response_code[code];
  },
  getMenuUrl(url = undefined, idx = undefined, defaultMenu) {
    if (url) {
      let data = url.split("/").filter(w => w);
      let choosedIdx = isNaN(parseFloat(idx)) ? data.length - 1 : idx;
      return data[choosedIdx] ? data[choosedIdx] : defaultMenu;
    }
    return defaultMenu;
  },
  getRandomColor() {
    let letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  },
  fillEmpty(val, replace = "-") {
    return validate.isEmpty(val) ? replace : val;
  },
  validURL(str) {
    // membutuhkan waktu yang lama jika terdapat beberapa tanda "-" (strip)
    // var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    //   '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    //   '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    //   '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    //   '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    //   '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    // --------------------------------------------------------------------
    const pattern = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/
    return pattern.test(str);
  },
  jsonTourl(data){
    let r = {}
    for(const i in data){
      if(typeof data[i] !== 'object')
        r[i] = data[i]
    }
    let url = new URLSearchParams(r).toString();
    return url
  },
  errorMessageHandler(errorResponse, defaultStatus = '404') {
    // errorResponse is error.response from request failed
    // const objReturn = { status: STATUSES[0], title: 'Error', subTitle: UNKNOWN_MESSAGE }
    const objReturn = { status: defaultStatus }
    let errorStatus = `${get(errorResponse, ['status'], '')}` // default must be string kosong
    if (errorStatus) {
      objReturn.title = errorStatus
      objReturn.status = errorStatus
    }
    const data = get(errorResponse, ['data'])
    if (typeof data === 'object') {
      if (!validate.isEmpty(data)) {
        if (data.detail) {
          if (data.detail === 'Connection to Server Failed!') {
            objReturn.title = data.detail
            objReturn.subTitle = 'Network connection refused. Please check your connection!'
          } else {
            objReturn.subTitle = data.detail
          }
        } else {
          // error another detail, maybe fields error,
          // if will supporting form setFields error, use in here and parameter of form in third of param
          // ---------------------------------
          for (const key in data) {
            objReturn.subTitle = new Error(data[key]).toString()
            // sample error with first key of object
            break
          }
        }
      }
    } else {
      if (errorStatus) {
        switch (errorStatus) {
          case '404': {
            objReturn.subTitle = `Invalid URL Request, maybe the URL API doesn't exist or has been moved.`
            break
          }
          case '500': {
            objReturn.subTitle = 'Internal server error, please try again later.'
            break
          }
          default: break
        }
      }
    }
    message.error({
      key: 'errorMessageHandler',
      content: `Error with status ${objReturn.status}, detail: ${objReturn.subTitle}`,
      duration: 5,
    })
    return objReturn
  },
  historyAction(history, path, historyState = {}) {
    // tujuan utama hanya untuk record "asal" URL khusus di submodule
    return {
      push: () => this.historyPush(history, path, historyState),
      goBack: () => this.goBack(history, path, historyState),
      replace: () => this.historyReplace(history, path, historyState)
    }
  },
  historyPush(history, urlTarget, historyState = {}) {
    if (history) {
      if (urlTarget) {
        const fromPaths = get(history, ['location', 'state', 'from'], [])
        fromPaths.push(history.location.pathname)
        history.push(urlTarget, { from: fromPaths, ...historyState, })
      } else {
        // URL Target not found
        message.error('URL Target not found')
      }
    } else {
      // history tidak boleh kosong
      message.error('Unknown history browser')
    }
  },
  goBack(history, defaultPath, historyState = {}) {
    if (history) {
      const fromPaths = get(history, ['location', 'state', 'from'])
      if (!validate.isEmpty(fromPaths)) {
        const urlTarget = fromPaths.pop()
        history.push(urlTarget, { from: fromPaths, ...historyState, })
      } else if (defaultPath) {
        history.push(defaultPath)
      } else {
        // URL Target not found
        message.error('URL Target not found')
      }
    } else {
      // history tidak boleh kosong
      message.error('Unknown history browser')
    }
  },
  historyReplace(history, urlTarget, historyState = {}) {
    if (history) {
      if (urlTarget) {
        const previousState = get(history, ['location', 'state'])
        history.replace(urlTarget, previousState)
      } else {
        // URL Target not found
        message.error('URL Target not found')
      }
    } else {
      // history tidak boleh kosong
      message.error('Unknown history browser')
    }
  },
  getFilenameDisposition(headers, defaultFilename) {
    let filename = defaultFilename
    const disposition = get(headers, 'content-disposition')
    if (typeof disposition === 'string' && disposition.indexOf('filename') !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);
      if (matches !== null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }
    return filename
  },
  getValue,
  getActionValue(historyAction, history) {
    const actions = {
      create: { label: 'Created' },
      update: { label: 'Change', effectedFields: true },
    }
    let action = get(actions, `${historyAction}.label`, historyAction)
    const exceptionFields = ['_object_version', 'modified']

    let strEffectedFields = ''
    if (get(actions, `${historyAction}.effectedFields`)) {
      const effectedFields = history.effected_fields.filter(field => !exceptionFields.some(excField => excField === field))
      for (let i = 0; i < effectedFields.length; i += 1) {
        if (strEffectedFields) strEffectedFields += ', '
        strEffectedFields += effectedFields[i]
      }
      if (!strEffectedFields) {
        strEffectedFields = 'No fields changed.'
        action = ''
      }
    }

    return (
      <>
        <b>{action}</b> {strEffectedFields}
      </>
    )
  },
  decodeEnc(data) {
    return new Promise((resolve) => {
      try {
        let job = JSON.parse(pako.inflate(atob(data), { to: 'string' }));
        return resolve(job);
      } catch (e) {
        return resolve(null);
      }
    });
  },
  jsonToFormData,
  renderFileIcon,
  formatFileSize,
};
