export default {
  "specialMessages-read": "api/admin/reports/special-messages/",
  "specialMessages-create": "api/admin/reports/special-messages/",
  "specialMessages-delete": "api/admin/reports/special-messages/{}/",
  "specialMessages-update": "api/admin/reports/special-messages/{}/",
  "specialMessages-detail": "api/admin/reports/special-messages/{}/",
  "specialMessages-detail-revision-version": "api/admin/reports/special-messages/{}/revision/{}/",
  "specialMessages-autocomplete": "api/admin/reports/special-messages/autocomplete/",

  "mmSecurities-read": "api/admin/reports/mm-securities/",
  "mmSecurities-create": "api/admin/reports/mm-securities/",
  "mmSecurities-delete": "api/admin/reports/mm-securities/{}/",
  "mmSecurities-update": "api/admin/reports/mm-securities/{}/",
  "mmSecurities-detail": "api/admin/reports/mm-securities/{}/",
  "mmSecurities-autocomplete": "api/admin/reports/mm-securities/autocomplete/",

  "mappingModes-read": "api/admin/merchant/mapping-modes/",
  "mappingModes-create": "api/admin/merchant/mapping-modes/",
  "mappingModes-delete": "api/admin/merchant/mapping-modes/{}/",
  "mappingModes-update": "api/admin/merchant/mapping-modes/{}/",
  "mappingModes-detail": "api/admin/merchant/mapping-modes/{}/",
  "mappingModes-detail-revision-version": "api/admin/merchant/mapping-modes/{}/revision/{}/",
  "mappingModes-autocomplete": "api/admin/merchant/mapping-modes/autocomplete/",

  "skuMappings-read": "api/admin/merchant/sku-mappings/",
  "skuMappings-create": "api/admin/merchant/sku-mappings/",
  "skuMappings-delete": "api/admin/merchant/sku-mappings/{}/",
  "skuMappings-update": "api/admin/merchant/sku-mappings/{}/",
  "skuMappings-detail": "api/admin/merchant/sku-mappings/{}/",
  "skuMappings-detail-revision-version": "api/admin/merchant/sku-mappings/{}/revision/{}/",
  "skuMappings-autocomplete": "api/admin/merchant/sku-mappings/autocomplete/",

  "merchantSkuDescriptions-read": "api/admin/reports/merchant-sku-descriptions/",
  "merchantSkuDescriptions-create": "api/admin/reports/merchant-sku-descriptions/",
  "merchantSkuDescriptions-delete": "api/admin/reports/merchant-sku-descriptions/{}/",
  "merchantSkuDescriptions-update": "api/admin/reports/merchant-sku-descriptions/{}/",
  "merchantSkuDescriptions-detail": "api/admin/reports/merchant-sku-descriptions/{}/",
  "merchantSkuDescriptions-autocomplete": "api/admin/reports/merchant-sku-descriptions/autocomplete/",
}
