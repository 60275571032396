export default {
  "app_label": "estimation",
  "model_name": "product",
  "pagination": true,
  "columns": [
    {
      "name": "pk",
      "label": "ID",
      "help_text": null,
      "hidden": true,
      "identifier": true,
      "data_type": "int",
      "search": false,
      "sort": false
    },
    {
      "name": "name",
      "label": "Name",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "ellipsis": true,
      "search": true,
      "sort": true
    },
    {
      "name": "code",
      "label": "Code",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "search": true,
      "sort": true
    },
    {
      "name": "status",
      "label": "Status",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": null,
      "search": true,
      "sort": true
    },
    {
      "name": "unit",
      "label": "Unit",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": null,
      "child_path": "name",
      "search": true,
      "sort": true
    },
    {
      "name": "timber_product",
      "label": "Timber product",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "bool",
      "search": false,
      "sort": false
    },
    {
      "name": "modified",
      "label": "Modified",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "datetime",
      "search": false,
      "sort": true
    }
  ],
  "filter_by": [],
  "export_import": {
    "export": {
      "directly": false,
      "url_name": null,
      "filter_by": [
        {
          "label": "Export Mode",
          "name": "export_mode",
          "type": "choices",
          "choices": [
            [
              "normal",
              "Normal Export"
            ],
            [
              "initial",
              "Initial Export"
            ]
          ]
        },
        {
          "label": "Export Limit",
          "name": "limit",
          "type": "choices",
          "choices": [
            [
              "all",
              "All Records"
            ],
            [
              "100",
              "100 Records"
            ],
            [
              "500",
              "500 Records"
            ]
          ]
        }
      ]
    },
    "import": {
      "url_name": "api:admin:estimation:exportimportsession:import",
      "allow_initial_data": true,
      "fields": [],
      "example_format_string": "seq,code,name,unit,for_each,horizontal,vertical,m2,m3,weight,timber_m3,paint,old_code,fixings,factor1,factor2,factor3,factor4,factor5,each,groupno,gcode,labour,labour_unit,supplier"
    }
  }
}