import Sites from "App/Config/Sites";

const SecureLS = require("secure-ls");
const ls = new SecureLS({
  encodingType: "aes",
  encryptionSecret: Sites.oauth.secret.substr(0, 32),
  isCompression: false
});
const KEY = "UserToken";

export default {
  WriteCredential(data) {
    ls.set(KEY, data);
  },

  GetCredential() {
    let credential = null;

    try {
      credential = ls.get(KEY);
    } catch (error) {}

    if (!credential) {
      return {
        isAuth: false,
        inAuth: false,
        username: null,
        token: null,
        refreshToken: null,
        profile: {},
        access: {}
      };
    }
    return credential;
  },
  RemoveCredential() {
    ls.remove(KEY);
  }
};
