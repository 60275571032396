import React from 'react'
import PropTypes from 'prop-types'
import { Prompt } from 'react-router-dom'
import { Modal, Row, Col, Spin, Icon, Form, Button, message } from 'antd'
import { get, cloneDeep } from 'lodash'
import { FormContext, FormProvider } from 'App/Component/Context/ContextForm'
import DynamicField from 'App/Component/DynamicField'
import fields from './fields'
import { RequestV2 as Request, Lib } from 'App/Utils'
import Lib2 from 'App/Component/Singlepage/Lib2'
import PageNotFound from 'App/Component/PageNotFound'

class PageDetail extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      isReloading: false,
      skuMapping: null,
      isFieldsTouched: false,
      isSaving: false,
      allowEdit: props.currentPermissionSet.find(code => code.includes('.change_')),
      allowDelete: props.currentPermissionSet.find(code => code.includes('.delete_')),
    }
  }

  retrieve = (keyLoading = 'isLoading') => {
    this.setState({ [keyLoading]: true }, () => {
      const merchantId = get(this.props.match, ['params', 'id'])
      const skuMappingId = get(this.props.match, ['params', 'refId'])
      Request(
        'get',
        'module-merchant-merchant-refSkuMapping-detail',
        {},
        {},
        [merchantId, skuMappingId],
        this.retrieveSuccess,
        this.retrieveFailed,
        { keyLoading }
      )
    })
  }

  retrieveSuccess = (response, extra) => {
    this.setState({ [extra.keyLoading]: false, skuMapping: response.data })
  }

  retrieveFailed = (error, extra) => {
    this.setState({ [extra.keyLoading]: false })
  }

  onBlur = (value, key, type) => {
    this.setState({ isFieldsTouched: true })
  }

  onCancel = () => {
    Lib.historyAction(this.props.history, '/merchant/sku-mapping').goBack()
  }

  onSave = () => {
    if (this.form) {
      this.form.validateFieldsAndScroll({scroll: {offsetTop: 150}}, (err, values) => {
        if (!err) {
          this.setState({ isSaving: true }, () => {
            const merchantId = get(this.props.match, ['params', 'id'])
            const skuMappingId = get(this.props.match, ['params', 'refId'])
            const convertedValues = Lib2.getValuesWithFile(values)
            Request(
              'put',
              'module-merchant-merchant-refSkuMapping-update',
              {},
              { merchant: merchantId, ...convertedValues },
              [merchantId, skuMappingId],
              this.saveSuccess,
              this.saveFailed,
            )
          })
        }
      })
    } else {
      message.error('Error in code Sku Mapping, form not found. [function => onSave]')
    }
  }

  saveSuccess = (response) => {
    message.success(`Sku Mapping with code: ${response.data.product_code} has been saved.`)
    this.setState({ isFieldsTouched: false }, () => {
      const merchantId = get(this.props.match, ['params', 'id'])
      Lib.historyAction(this.props.history, `/merchant/merchant/${merchantId}`).goBack()
    })
  }

  saveFailed = (error) => {
    const errors = get(error, ['response', 'data'])
    if (errors && typeof errors === 'object') {
      this.setFieldsError(errors)
    } else {
      message.error('Failed to save SKU Mapping, please contact your admin! code: ' + get(error, ['response', 'status'], 'Unknown.'), 5)
    }
    this.setState({ isSaving: false })
  }

  setFieldsError = (objError) => {
    const obj = {}
    for (const key in objError) {
      message.error('Error in ' + key + ' : ' + objError[key][0], 5)
      const tempValue = this.form.getFieldValue(key)
      obj[key] = {
        value: tempValue,
        errors: [new Error(objError[key][0])]
      }
    }
    this.form.setFields(obj)
  }

  // Delete current item
  handleDelete = () => {
    Modal.confirm({
      // Don't passing maskClosable please
      title: `Delete SKU Mapping with Product Code: ${this.state.skuMapping.product_code}`,
      content: `Are you sure you want to delete this SKU Mapping? This cannot be undone.`,
      okType: 'danger',
      okText: 'Delete',
      onCancel: () => new Promise((resolve, reject) => {
        if (this.isDeleting) {
          reject('Deletion process in progress, cannot cancel it')
          message.info('Deletion process in progress, cannot cancel it')
        } else {
          resolve()
        }
      }),
      onOk: () => new Promise((resolve, reject) => {
        const merchantId = get(this.props.match, ['params', 'id'])
        const skuMappingId = get(this.props.match, ['params', 'refId'])    
        this.isDeleting = true
        Request(
          'delete',
          'module-merchant-merchant-refSkuMapping-delete',
          {},
          {},
          [merchantId, skuMappingId],
          this.onDeleteSuccess,
          this.onDeleteFailed,
          { resolve, reject }
        )
      })
    })
  }

  onDeleteSuccess = (response, extra) => {
    this.setState({ isFieldsTouched: false }, () => {
      const merchantId = get(this.props.match, ['params', 'id'])
      message.success(`SKU Mapping with Product Code ${this.state.skuMapping.product_code} has been deleted.`)
      extra.resolve()
      this.props.history.push(`/merchant/merchant/${merchantId}`)
    })
  }

  onDeleteFailed = (error, extra) => {
    this.isDeleting = false
    extra.reject('Delete failed')
    const errors = get(error, ['response', 'data'])
    if (errors && typeof errors === 'object') {
      this.setFieldsError(errors)
    } else {
      message.error('Failed to delete SKU Mapping, please contact your admin! code: ' + get(error, ['response', 'status'], 'Unknown.'), 5)
    }
  }

  componentDidMount() {
    this.retrieve()
  }

  render() {
    const { userReducer } = this.props
    const { isLoading, isReloading, skuMapping, isFieldsTouched, isSaving } = this.state
    const { allowEdit, allowDelete } = this.state

    if (isLoading) {
      return (
        <Row type='flex' justify='center' align='middle' style={{ marginTop: '30vh' }}>
          <Spin
            size='large'
            tip='Loading...'
            indicator={<Icon spin type='loading' style={{ fontSize: '4rem' }} />}
          />
        </Row>
      )
    }

    return skuMapping ? (
      <div>
        <Prompt
          when={isFieldsTouched}
          message='You will lose any unsaved changes. Continue?'
        />
        <Row style={{ marginTop: '12px' }}>
          <FormProvider>
            <Form>
              {fields.map((elem) => {
                return (
                  <DynamicField
                    {...elem}
                    operation={allowEdit ? 'edit' : 'view'}
                    key={elem.dataIndex}
                    onBlur={this.onBlur}
                    userReducer={userReducer}
                    dataRecord={get(skuMapping, elem.dataIndex)}
                  />
                )
              })}
              <FormContext.Consumer>
                {form => {this.form = form}}
              </FormContext.Consumer>
            </Form>
          </FormProvider>
        </Row>
        <Row type='flex' justify='space-between' align='middle' style={{ backgroundColor: '#fff', padding: '12px 0', borderTop: '1px solid #e8e8e8'}}>
          <Col>
            {allowDelete && <Button type='danger' icon='delete' onClick={this.handleDelete}>Delete</Button>}
          </Col>
          <Col>
            <Row type='flex' gutter={[12, 12]}>
              <Col>
                <Button disabled={isSaving} onClick={this.onCancel}>
                  Cancel
                </Button>
              </Col>
              {allowEdit && (
                <Col>
                  <Button icon='save' type='primary' loading={isSaving} onClick={this.onSave}>
                    Update
                  </Button>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </div>
    ) : (
      <PageNotFound
        title={`Failed to find SKU Mapping with ID: ${get(this.props.match, ['params', 'refId'], '[Unknown]')}.`}
        subTitle=''
        extra={
          <Row type='flex' justify='center' gutter={[12, 12]}>
            <Col>
              <Button onClick={this.onCancel}>
                Go Back
              </Button>
            </Col>
            <Col>
              <Button loading={isReloading} type='primary' onClick={() => this.retrieve('isReloading')}>
                Try Again
              </Button>
            </Col>
          </Row>
        }
      />
    )
  }
}

PageDetail.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  userReducer: PropTypes.object.isRequired,
  currentPermissionSet: PropTypes.array,
}

PageDetail.defaultProps = {
  currentPermissionSet: [],
}

export default PageDetail