import React, { Component, useRef, cloneElement, useCallback } from "react";
import { Button } from "antd";
const descriptor = {
  "app_label": "expert_system",
  "model_name": "rule",
  pagination: true,
  columns: [
    {
      name: "pk",
      label: "ID",
      help_text: null,
      hidden: false,
      identifier: false,
      data_type: "int",
      ellipsis: true,
      search: false,
      sort: false,
    },
    {
      name: "key",
      label: "Rules Key",
      help_text: null,
      hidden: false,
      identifier: false,
      data_type: "int",
      ellipsis: true,
      search: true,
      sort: true,
    },
    {
      render:(a,b,c)=>{
        if(b.fact_type){
          return "Fact"
        }else{
          return "Rule"
        }
      },
      name: "rule_type",
      label: "Rule Type",
      help_text: null,
      hidden: false,
      identifier: false,
      data_type: "int",
      ellipsis: true,
      search: false,
      sort: false,
    },
    {
      render:(value,record,index)=>{
        if(record.fact_type){
          return record.fact_type
        }else{
          return "-"
        }
      },
      name: "fact_type",
      label: "Fact Type",
      help_text: null,
      hidden: false,
      identifier: false,
      data_type: "int",
      ellipsis: true,
      search: false,
      sort: false,
    },
    {
      // render: (value, record, index) => {
      //   //  console.log(value,record,index)
      //   if (value === 18) {
      //     return "Subsection";
      //   } else if (value === 22) {
      //     return "PrefillSet";
      //   } else if (value === 24) {
      //     return "Override Side";
      //   } else if (value === 44) {
      //     return "Tag";
      //   } else {
      //     return " - ";
      //   }
      // },
      name: "output_content_type.label",
      label: "Output Content",
      help_text: null,
      hidden: null,
      identifier: false,
      data_type: "str",
      ellipsis: true,
      search: false,
      sort: false,
    },
    {
      // render: (value, record, index) => {
      //   // console.log(value, record, index);
      //   if (record.output_content_type === 18) {
      //     return (
      //       <Button
      //         type="link"
      //         onClick={() =>
      //           window.open(`/admin/estimation/subsections/${value}`)
      //         }
      //       >
      //         {value}
      //       </Button>
      //     );
      //   } else if (record.output_content_type === 22) {
      //     return (
      //       <Button
      //         type="link"
      //         onClick={() =>
      //           window.open(`/admin/estimation/prefill-sets/${value}`)
      //         }
      //       >
      //         {value}
      //       </Button>
      //     );
      //   } else if (record.output_content_type === 24) {
      //     return (
      //       <Button
      //         type="link"
      //         onClick={() =>
      //           window.open(`/admin/estimation/override-sets/${value}`)
      //         }
      //       >
      //         {value}
      //       </Button>
      //     );
      //   } else if (record.output_content_type === 21) {
      //     return (
      //       <Button
      //         type="link"
      //         onClick={() =>
      //           window.open(`/admin/estimation/line-templates/${value}`)
      //         }
      //       >
      //         {value}
      //       </Button>
      //     );
      //   } else if (record.output_content_type === 44) {
      //     return (
      //       <Button
      //         type="link"
      //         onClick={() => window.open(`/admin/jobs/tags/${value}`)}
      //       >
      //         {value}
      //       </Button>
      //     );
      //   }else{
      //     return " - "
      //   }
      // },
      name: "output_content_object.name",
      label: "Output Object",
      help_text: null,
      hidden: null,
      identifier: false,
      data_type: "int",
      ellipsis: true,
      search: false,
      sort: false,
    },
  ],
  filter_by: [
    // {
    //   label: "Rule Type",
    //   name: "rule_type",
    //   type: "choice",
    //   choices: [
    //     [18, "Rule"],
    //     [22, "fact"],
    //   ],
    // },
    {
      label: "Output Content Type",
      name: "output_content_type",
      type: "choice",
      choices: [
        [18, "Subsection"],
        [22, "PrefillSet"],
        [24, "OverrideSet"],
        [44, "Tag"],
      ],
    },
    {
      label: "Fact Type",
      name: "fact_type",
      type: "choice",
      choices: [
        [ "keyword", "Keyword"],
        ["job", "Job"],
        ["object", "Object"],
      ],
    },

    // {
    //   name: 'keyword',
    //   label: 'keyword',
    //   api: {
    //     url_name: 'jobGrades-autocomplete',
    //     key: 'pk',
    //     display: 'label',
    //   }
    // },
    // {
    //   name: 'Output_Object',
    //   label: 'Output Object',
    //   api: {
    //     url_name: 'jobGrades-autocomplete',
    //     key: 'pk',
    //     display: 'label',
    //   }
    // },
  ],
};

export default descriptor;
