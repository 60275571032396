import React from "react";
import { Col, Row, Calendar, Card, Badge } from "antd";

class DayOff extends React.PureComponent {
	getListData(value) {
		let listData;
		switch (value.date()) {
			case 8:
				listData = [
					{ type: "warning", content: "Bambang" },
					{ type: "success", content: "Rudi" }
				];
				break;
			case 10:
				listData = [
					{ type: "warning", content: "Andrew" },
					{ type: "success", content: "Andrew" },
					{ type: "error", content: "Bambang" }
				];
				break;
			case 15:
				listData = [
					{ type: "warning", content: "Andrew" },
					{ type: "success", content: "Andrew" },
					{ type: "error", content: "Bambang" },
					{ type: "error", content: "Bambang" },
					{ type: "error", content: "Bambang" },
					{ type: "error", content: "Bambang" }
				];
				break;
			default:
		}
		return listData || [];
	}

	dateCellRender = value => {
		const listData = this.getListData(value);
		return (
			<ul className="events">
				{listData.map((item, idx) => (
					<li key={idx}>
						<Badge status={item.type} text={item.content} />
					</li>
				))}
			</ul>
		);
	};

	getMonthData(value) {
		if (value.month() === 8) {
			return 1394;
		}
	}

	monthCellRender = value => {
		const num = this.getMonthData(value);
		return num ? (
			<div className="notes-month">
				<section>{num}</section>
				<span>Backlog number</span>
			</div>
		) : null;
	};

	render() {
		return (
			<Row className="animated fadeIn">
				<Col>
					<Card title="Day Off Management">
						<Calendar
							dateCellRender={this.dateCellRender}
							monthCellRender={this.monthCellRender}
						/>
					</Card>
				</Col>
			</Row>
		);
	}
}

export default DayOff;
