import React from 'react'
import PropTypes from 'prop-types'
import { Button, Card, Col, Icon, message, Modal, Row, Spin } from 'antd'
import { cloneDeep, get, isEqual } from 'lodash'
import { Lib, RequestV2 as Request } from 'App/Utils'
import { ContainerSQ, Number, Text } from '../internal'
import AvailableSets from './AvailableSets'
import MoreSettings from './MoreSettings'
import PageError from 'App/Component/PageError'

const CONTAINER_NAME_PROPS = { className: 'text-truncate' }
const INPUT_NAME_PROPS = { placeholder: 'Name' }
const CONTAINER_ORDER_PROPS = { title: 'Order', className: 'text-truncate' }
const INPUT_ORDER_PROPS = { style: {fontSize: '12px', padding: 0, textAlign: 'right'} }

class GroupItem extends React.PureComponent {
  constructor(props) {
    super(props)

    this.addMode = !get(props.currentGroup, 'pk')
    this.state = {
      errorResponse: null,
      isLoading: !this.addMode,
      isReloading: false,
      isFieldsTouched: false,
      prefillSetGroup: this.addMode ? props.currentGroup : null,

      contextVisible: false,
    }
    this.state.savedPrefillSetGroup = cloneDeep(this.state.prefillSetGroup)
  }

  onRefName = (ref) => {
    this._name = ref
  }

  onRefOrder = (ref) => {
    this._order = ref
  }

  getContextContent = () => (
    <Row type='flex' gutter={[12, 12]}>
      {!this.addMode && (
        <Col>
          <Button
            type='danger'
            icon='delete'
            onClick={this.onDelete}
          >
            Delete
          </Button>
        </Col>
      )}
      <Col>
        <Button
          icon='close'
          onClick={() => {
            this.onContextVisibleChange(false)
            this.closeCard()
          }}
        >
          Close
        </Button>
      </Col>
    </Row>
  )

  closeCard = () => {
    this.confirmDiscardChanges(agree => {
      if (agree) {
        this.props.onClose(this.props.currentGroup)
      }
    }, !this.state.isFieldsTouched && !this.addMode)
  }

  onContextVisibleChange = (contextVisible) => {
    this.setState({ contextVisible })
  }

  getHeaderStyle = () => {
    if (!get(this.state.prefillSetGroup, 'pk')) return {background: '#97cdff'}

    return this.state.isFieldsTouched ? {background: '#fff0e7'} : {background: '#fafafa'}
  }

  retrieve = (keyLoading = 'isLoading', callback) => {
    this.setState({ [keyLoading]: true }, () => {
      Request(
        'get',
        this.props.urlKeyDetail,
        {},
        {},
        [this.props.currentGroup.pk],
        this.retrieveSuccess,
        this.retrieveFailed,
        { keyLoading, callback }
      )
    })
  }

  retrieveSuccess = (response, extra) => {
    this.setState({ [extra.keyLoading]: false, prefillSetGroup: response.data, savedPrefillSetGroup: cloneDeep(response.data) })

    this.props.onChange(cloneDeep(response.data))
  }

  retrieveFailed = (error, extra) => {
    this.setState({ [extra.keyLoading]: false, errorResponse: get(error, 'response') }, () => {
      if (extra.keyLoading === 'isReloading') {
        extra.callback()
      }
    })
  }

  getMenuSetting = () => {
    const menus = [
      { key: 'close', icon: 'close', label: 'Close', onClick: this.closeCard },
      {
        key: 'change_status',
        icon: 'select',
        label: 'Change Status',
        onClick: this.onChangeStatus,
        menus: [
          { key: 'active', label: 'Active - Anyone can use', render: this.renderStatus },
          { key: 'inactive', label: 'Inactive - No one can use', render: this.renderStatus },
          { key: 'unpublished', label: 'Unpublished - Only certain people can use', render: this.renderStatus },
        ]
      },
    ]

    // if (!this.addMode) {
    //   menus.push(...[
    //     {
    //       key: 'delete',
    //       icon: 'delete',
    //       label: 'Delete',
    //       props: {style: {color: '#ff4d4f'}},
    //       onClick: () => this.props.onDeleteGroup(this.state.savedPrefillSetGroup)
    //     },
    //   ])
    // }

    return menus
  }

  renderStatus = (label, obj) => {
    return (
      <Row type='flex' align='middle'>
        <div style={{ display: 'flex', width: '14px', height: '14px', marginRight: '9px' }}>
          {this.state.prefillSetGroup.status === obj.key && (
            <Icon
              type='check-circle'
              theme='filled'
              style={{ color: 'rgb(39, 174, 96)' }}
            />
          )}
        </div>
        {label}
      </Row>
    )
  }

  onChangeStatus = (options) => {
    this.onChangeField({ status: options.key })
  }

  revertToSaved = () => {
    this.confirmDiscardChanges(agree => {
      if (agree) {
        const { savedPrefillSetGroup } = this.state
        this.setState({ prefillSetGroup: cloneDeep(savedPrefillSetGroup), isFieldsTouched: false }, () => {
          if (this._list) {
            const { keyChild } = this.props
            this._list.read(
              undefined,
              undefined,
              { objSelectedKeys: this._list.getObjSelectedKeys(get(savedPrefillSetGroup, keyChild)) },
            )
          }
        })
      }
    })
  }

  confirmDiscardChanges = (callback, force) => {
    if (force === true) {
      callback(true)
    } else {
      Modal.confirm({
        title: 'Discard changes',
        content: 'You will lose unsaved changes. Continue?',
        okType: 'danger',
        onOk: () => callback(true),
        onCancel: () => callback(false),
      })
    }
  }

  onChangeName = (name, callback) => {
    if (!name) return callback()

    this.onChangeField({ name }, callback)
  }

  onChangeOrder = (order, callback) => {
    if (order === '') order = null

    this.onChangeField({ order }, callback)
  }

  onChangeSection = (section) => {
    let selectedSection = undefined
    if (section) selectedSection = { ...section, pk: section.key, name: section.label }
    this.onChangeField({ section: selectedSection, subsection: undefined }, () => {
      // if (typeof get(this._subsection, '_ref.reload') === 'function') {
      //   this._subsection._ref.reload()
      // }
    })
  }

  onChangeSubsection = (subsection) => {
    let selectedSubsection = undefined // default must undefined, not null
    if (subsection) selectedSubsection = { ...subsection, pk: subsection.key, name: subsection.label }
    this.onChangeField({ subsection: selectedSubsection, prefill_set: undefined }, () => {
      if (this._list) {
        this._list.read()
      }
    })
  }

  onChangePrefillSet = (prefillSet) => {
    let selectedPrefillSet = undefined // default must undefined, not null
    if (prefillSet) selectedPrefillSet = { ...prefillSet, pk: prefillSet.key, name: prefillSet.label }
    this.onChangeField({ prefill_set: selectedPrefillSet }, () => {
      if (this._list) {
        this._list.read()
      }
    })
  }

  onChangeField = (obj, callback = () => null) => {
    // this.setState({ ...obj, isFieldsTouched: true }, callback)
    this.setState(
      prevState => ({
        prefillSetGroup: { ...prevState.prefillSetGroup, ...obj },
        isFieldsTouched: true
      }),
      callback
    )
  }

  onRefList = (ref) => {
    this._list = ref
  }

  onFieldTouched = () => this.setTouched(true)

  setTouched = (isFieldsTouched) => {
    this.setState({ isFieldsTouched })
  }

  onDelete = () => {
    this.onContextVisibleChange(false)
    this.confirmDiscardChanges(agree => {
      Modal.confirm({
        title: 'Confirm Delete',
        content: (
          <span>
            Are you sure want to delete <b>{this.state.prefillSetGroup.name}</b>?
          </span>
        ),
        okType: 'danger',
        okText: 'Delete',
        width: 440,
        onOk: () => new Promise((resolve, reject) => {
          this.delete((success) => {
            resolve()
          })
        }),
      })
    }, !this.state.isFieldsTouched || !this.state.prefillSetGroup.pk)
  }

  delete = (callback = () => null) => {
    Request(
      'delete',
      this.props.urlKeyDetail,
      {},
      {},
      [this.state.prefillSetGroup.pk],
      this.deleteSuccess,
      this.deleteFailed,
      { callback }
    )
  }

  deleteSuccess = (response, extra) => {
    message.success(`Delete ${this.state.prefillSetGroup.name} success`)
    this.props.onClose(this.props.currentGroup)
    extra.callback(true)
  }

  deleteFailed = (error, extra) => {
    Lib.errorMessageHandler(get(error, 'response'))
    extra.callback(false)
  }

  validateFields = () => {
    if (!this.state.isFieldsTouched && !this.addMode) return

    const { keyChild } = this.props
    const sets = this._list.validateFields()
    return {
      ...this.state.prefillSetGroup,
      section: get(this.state.prefillSetGroup, 'section.pk'),
      subsection: get(this.state.prefillSetGroup, 'subsection.pk'),
      [keyChild]: sets,
    }
  }

  setError = (objError) => {
    // belum set error sets
    for (let key in objError) {
      if (typeof get(this[`_${key}`], 'setError') === 'function') {
        if (key === 'order') objError[key] = ['']
        this[`_${key}`].setError(objError[key])
      }
    }
  }

  componentDidMount() {
    if (this.state.isLoading) this.retrieve()
  }

  render() {
    const { keyChild, labelChild } = this.props
    const { errorResponse, isFieldsTouched, isLoading, prefillSetGroup } = this.state
    // For props ref PureComponent
    const tempParams = { subsection: get(prefillSetGroup, 'subsection.pk') } //prefill_set: get(prefillSetGroup, 'prefill_set.pk') } // prefill_set for Override Group
    if (!isEqual(tempParams, this.listParams)) this.listParams = tempParams

    if (isLoading) {
      return (
        <div className='my-3' style={{ width: '100%', textAlign: 'center' }}>
          <Spin
            indicator={<Icon type="loading" style={{ fontSize: "2.5rem" }} spin />}
          />
        </div>
      )
    }

    return (prefillSetGroup) ? (
      <Card
        size='small'
        headStyle={{ padding: '0 0.5rem', ...this.getHeaderStyle() }}
        bodyStyle={{ padding: '12px 0' }}
        title={
          <Row type='flex' justify='space-between' gutter={[12, 12]}>
            {isFieldsTouched && prefillSetGroup.pk && (
              <Col style={{ paddingRight: '0' }}>
                <Icon type='rollback' style={{ cursor: 'pointer' }} title='Revert to saved' onClick={this.revertToSaved} />
              </Col>
            )}
            <Col style={{ width: 'calc(100% - 70px - 20px)' }}>
              <Text
                ref={this.onRefName}
                autoFocus={!prefillSetGroup.name}
                containerProps={CONTAINER_NAME_PROPS}
                inputProps={INPUT_NAME_PROPS}
                onPressEnter={this.onChangeName}
                value={prefillSetGroup.name}
                blankField='(Input Name)'
              />
            </Col>
            <Col style={{ width: '70px', textAlign: 'right' }}>
              <Row type='flex' align='middle'>
                <Col style={{ width: 'calc(100% - 24px)' }}>
                  <Number
                    ref={this.onRefOrder}
                    containerProps={CONTAINER_ORDER_PROPS}
                    inputProps={INPUT_ORDER_PROPS}
                    onPressEnter={this.onChangeOrder}
                    value={prefillSetGroup.order}
                    blankField='Order'
                  />
                </Col>
                <Col style={{ marginLeft: '4px' }}>
                  <MoreSettings
                    menus={this.getMenuSetting()}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        }
      >
        <div className='px-2'>
          <Row type='flex' gutter={[6, 6]}>
            <Col xs={24} sm={24} xxl={8}>
              <ContainerSQ
                ref={ref => {this._section = ref}} // ini digunakan
                // flatMode
                allowClear={false}
                className=''
                urlKey='sections-autocomplete'
                style={{ width: '100%' }}
                placeholder='Section...'
                onChange={this.onChangeSection}
                size='small'
                value={Lib.setSelectValue(get(prefillSetGroup, 'section'), 'pk', 'name')}
                paramProps={{ status: 'active' }}
              />
            </Col>
            <Col xs={24} sm={24} xxl={16}>
              <ContainerSQ
                ref={ref => {this._subsection = ref}} // ini digunakan
                allowClear={false}
                urlKey='subsections-autocomplete'
                style={{ width: '100%' }}
                placeholder='Subsection...'
                onChange={this.onChangeSubsection}
                size='small'
                value={Lib.setSelectValue(get(prefillSetGroup, 'subsection'), 'pk', 'name')}
                paramProps={{ status: 'active', section: get(this.state.prefillSetGroup, 'section.pk') }}
              />
            </Col>
            {/* {this.props.isOverrideSet && (
              <Col span={24}>
                <SelectQuery
                  ref={ref => {this._prefillSet = ref}}
                  urlKey='prefillSets-autocomplete'
                  style={{ width: '100%' }}
                  placeholder='Prefill Set...'
                  onChange={this.onChangePrefillSet}
                  size='small'
                  value={get(prefillSetGroup, 'prefill_set')}
                  paramProps={{ status: 'active', subsection: get(this.state.prefillSetGroup, 'subsection.pk') }}
                />
              </Col>
            )} */}
          </Row>
        </div>
        <div className='mt-2'>
          <div className='px-2' style={{ fontWeight: 'bold' }}>
            Available {labelChild} :
          </div>
          <AvailableSets
            ref={this.onRefList}
            urlKey={this.props.urlKeyChildList}
            params={this.listParams}
            defaultSelectedKeysObj={prefillSetGroup[keyChild]}
            onFieldTouched={this.onFieldTouched}
          />
        </div>
      </Card>
    ) : (
      <div style={{ border: '1px solid #e8e8e8', borderRadius: '4px' }}>
        <PageError
          errorResponse={errorResponse}
          onReload={this.retrieve}
        />
      </div>
    )
  }
}

GroupItem.propTypes = {
  urlKeyDetail: PropTypes.string.isRequired,
  urlKeyChildList: PropTypes.string.isRequired,
  isOverrideSet: PropTypes.bool,
  currentGroup: PropTypes.object,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  onDeleteGroup: PropTypes.func,
}

GroupItem.defaultProps = {
  isOverrideSet: false,
  currentGroup: null,
  onClose: () => null,
  onChange: () => null,
  onDeleteGroup: () => null,
}

export default GroupItem