import React from 'react'
import PropTypes from 'prop-types'
import { Button, Col, Form, Input, message, Modal, Row } from 'antd'
import { get } from 'lodash'
import { Lib, RequestV2 as Request } from 'App/Utils'

class ModalChangePassword extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      visible: false,
      isSaving: false,
    }
  }

  show = () => this.setVisible(true)

  hide = () => this.setVisible(false)

  setVisible = (visible) => this.setState({ visible })

  getFooter = () => (
    <Row type='flex' justify='end' gutter={[8, 8]}>
      <Col>
        <Button onClick={this.hide}>Cancel</Button>
      </Col>
      <Col>
        <Button type='primary' loading={this.state.isSaving} onClick={this.changePassword}>
          Change Password
        </Button>
      </Col>
    </Row>
  )

  onKeyDown = (e) => {
    if (e.keyCode === 13) {
      // on press enter
      this.changePassword()
    }
  }

  changePassword = () => {
    if (this.state.isSaving) return

    this.props.form.validateFields((err, values) => {
      if (err) return
      if (values.password !== values.retype_password) {
        this.props.form.setFields({
          password: { value: values.password, errors: [new Error('Password not match')] },
          retype_password: { value: values.retype_password, errors: [new Error('Password not match')] },
        })
        return
      }

      this.setState({ isSaving: true }, () => {
        Request(
          'post',
          'module-groupUser-userTracker-password',
          {},
          { password: values.password },
          [get(this.props.currentUser, 'pk')],
          this.changePasswordSuccess,
          this.changePasswordFailed,
        )
      })
    })
  }

  changePasswordSuccess = (response) => {
    const username = get(this.props.currentUser, 'username')
    message.success(<span>The user password of "<b>{username}</b>" has been saved</span>)
    this.setState({ isSaving: false, visible: false })
  }

  changePasswordFailed = (error) => {
    Lib.errorMessageHandler(get(error, 'response'))
    this.setState({ isSaving: false })

    const errObj = get(error, 'response.data')
    if (typeof errObj === 'object') {
      const { form } = this.props
      for (const key in errObj) {
        form.setFields({
          [key]: {
            value: form.getFieldValue(key),
            errors: [new Error(errObj[key])],
          }
        })
      }
    }
  }

  componentDidMount() {
    this.props.onRef(this)
  }

  render() {
    const { form } = this.props
    const { visible } = this.state

    return (
      <Modal
        visible={visible}
        title='Change Password'
        destroyOnClose
        maskClosable={false}
        footer={this.getFooter()}
        onCancel={this.hide}
      >
        <Row>
          <Form>
            <Form.Item>
              {form.getFieldDecorator('password', {
                rules: [{ required: true, message: 'Please input New Password!' }],
              })(
                <Input.Password
                  autoFocus
                  placeholder='New Password'
                  autoComplete='new-password'
                  onKeyDown={this.onKeyDown}
                />
              )}
            </Form.Item>
            <Form.Item>
              {form.getFieldDecorator('retype_password', {
                rules: [{ required: true, message: 'Please input Retype New Password!' }],
              })(
                <Input.Password
                  placeholder='Retype New Password'
                  autoComplete='new-password'
                  onKeyDown={this.onKeyDown}
                />
              )}
            </Form.Item>
          </Form>
        </Row>
      </Modal>
    )
  }
}

ModalChangePassword.propTypes = {
  onRef: PropTypes.func,
}

ModalChangePassword.defaultProps = {
  onRef: () => null,
}

export default Form.create()(ModalChangePassword)