import { BaseList, BaseDetail } from "App/Component/Bases";
import fields from "./fields";
import descriptor from "./descriptor";
import options from "./options";

const title = "Branch Ratings";
const urlData = "branchRatings";
const availablePermissions = ['.view_', '.change_', '.add_', '.delete_'] // jika mengganti ini, juga perlu ganti di submodule

class List extends BaseList {
  constructor(props) {
    super(props)

    this.title = title;
    this.urlData = urlData;
    this.availablePermissions = availablePermissions;
    this.propsList = {
      descriptor,
      exportImport: options.exportImportOptions,
    }
    this.state = {
      ...this.state,
      currentPermissionSet: this.isSuperuser ? availablePermissions : [],
    }
  }
  getAppName=()=> `billing`

}

class Detail extends BaseDetail {
  constructor(props) {
    super(props)

    this.title = title;
    this.urlData = urlData;
    this.bulkName = "name";
    this.fields = fields;
    this.availablePermissions = availablePermissions;
    this.propsDetail = {
      urlKeyHistory: 'api:admin:billing:branchrating:detail-revision',
      keyLabel: 'name'
    }
    this.state = {
      ...this.state,
      currentPermissionSet: this.isSuperuser ? availablePermissions : [],
    }
  }
}

export default { List, Detail };
