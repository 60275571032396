export default [
  {
    title: 'Is Import',
    type: 'checkbox',
    data: '',
    dataIndex: 'is_import',
    initialValue: true,
    note_text: '',
    order: 0,
  }, {
    title: 'Content Type',
    type: 'SelectQuery',
    required: true,
    data: 'contentTypes',
    dataIndex: 'content_type',
    initialValue: '',
    note_text: '',
    selectedKey: 'pk',
    selectedValue: 'label',
    order: 1,
  }, {
    title: 'Template set',
    type: 'SelectQuery',
    data: 'templateSets',
    dataIndex: 'template_set',
    initialValue: '',
    note_text: '',
    additional: 'edit|add',
    selectedKey: 'pk',
    selectedValue: 'name',
    order: 2,
  }, {
    title: 'Overwrite existing',
    type: 'checkbox',
    data: '',
    dataIndex: 'overwrite_existing',
    initialValue: '',
    note_text: 'Only for imports.',
    order: 3,
  }, {
    title: 'Activate imported items',
    type: 'checkbox',
    data: '',
    dataIndex: 'activate_imported_items',
    initialValue: true,
    note_text: 'Whether to make imported items available to all users right away. The default is to make them available only to certain people with privileges.',
    order: 4,
  }, {
    title: 'Description',
    type: 'text',
    data: '',
    dataIndex: 'description',
    initialValue: '',
    note_text: '',
    // maxLength: 15,
    order: 5,
  }, {
    title: 'File',
    type: 'file',
    data: '',
    dataIndex: 'file',
    accept: '.csv',
    initialValue: '',
    note_text: '',
    order: 6,
  }, {
    title: 'Errors',
    type: 'textarea',
    data: '',
    dataIndex: 'errors',
    initialValue: '',
    note_text: '',
    order: 5,
  },{
    title: 'Initial data',
    type: 'checkbox',
    data: '',
    dataIndex: 'initial_data',
    initialValue: '',
    note_text: '',
    order: 7,
  }
]
