import React from "react";
import { Tabs, Icon } from "antd";
import { BaseList, BaseDetail } from "App/Component/Bases";
import fields from "./fields";
import ImportInformation from "./ImportInformation";
import options from "./options";

const { TabPane } = Tabs;

const title = "Export Import Sessions";
const urlData = "exportImportSessions";
const bulkName = "template_set";

class List extends BaseList {
  constructor(props) {
    super(props)

    this.title = title;
    this.urlData = urlData;
    this.bulkName = bulkName;
    this.propsList = {
      exportImport: options.exportImportOptions,
    }
  }
  
}

class Detail extends BaseDetail {
  constructor(props) {
    super(props)

    this.title = title;
    this.urlData = urlData;
    this.bulkName = bulkName;
    this.fields = fields;
    this.propsDetail = {
      getAnotherTabPanes: this.getImportInformation,
    }
  }

  getImportInformation = (detail, onReload) => {
    return (
      <TabPane
        key='exim'
        tab={
          <span>
            <Icon type='info-circle' />
            Import Information
          </span>
        }
      >
        <ImportInformation data={detail} onReload={onReload} />
      </TabPane>
    )
  }

}

export default { List, Detail };