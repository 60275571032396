import React from 'react'
import { BaseDetail } from 'App/Component/Bases'
import Detail from './Detail'

const staticProps = {
  addMode: {
    showResult: false,
    showHistory: false,
    showQueues: false,
    methodSave: 'post',
    urlKeySave: 'api:admin:notifications:broadcast:list',
    isRetrieve: false,
  },
  editMode: {
    methodSave: 'put',
    urlKeySave: 'api:admin:notifications:broadcast:detail',
    isRetrieve: true,
  }
}

class BroadcastManagement extends BaseDetail {
  getAllowedComponent = () => {
    const isAddMode = this.props.match.params.id === 'add'
    const keyProps = isAddMode ? 'addMode' : 'editMode'
    return (
      <Detail
        {...this.props}
        {...staticProps[keyProps]}
        urlKeyDetail='api:admin:notifications:broadcast:detail'
        isAddMode={isAddMode}
        permissions={this.state.currentPermissionSet}
      />
    )
  }
}

export default BroadcastManagement