import React, { Component } from "react";
import { Breadcrumb } from "antd";
import { Link, withRouter } from "react-router-dom";
import { capitalize, startCase } from "lodash";

class AppBreadcrumb extends Component {
  render() {
    const { location } = this.props;
    const pathSnippets = location.pathname.split("/").filter((i) => i);
    const extraBreadcrumbItems = pathSnippets.map((val, index) => {
      const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
      return (
        <Breadcrumb.Item key={index}>
          {index > 2 ? (
            startCase(capitalize(val.replace(/-/g, " ")))
          ) : (
            <Link to={url}>
              {startCase(capitalize(val.replace(/-/g, " ")))}
            </Link>
          )}
        </Breadcrumb.Item>
      );
    });

    // const breadcrumbItems = [(
    //   <Breadcrumb.Item key="dashboard">
    //     <Link to="/dashboard">Dashboard</Link>
    //   </Breadcrumb.Item>
    // )].concat(extraBreadcrumbItems);

    return (
      <div style={{ margin: location.pathname === "/logs" ? "72px" : "" }}>
        <span
          style={{ display: location.pathname === "/logs" ? "none" : "block" }}
        >
          <Breadcrumb style={{ margin: "16px 0", marginTop: "80px" }}>
            {extraBreadcrumbItems}
          </Breadcrumb>
        </span>
      </div>
    );
  }
}
export default withRouter(AppBreadcrumb);
