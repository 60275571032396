import React from 'react'
import { Button, message } from 'antd'
import yaml from 'yaml'

import { RequestV2 } from 'App/Utils'
import { Sites } from 'App/Config'

class BaseButton extends React.PureComponent {
  constructor(props) {
    super(props)

    this.ymlName = '' // required

    const self = this
    Object.defineProperties(this, {
      __version: {
        get() {
          return self.yml ? `v${self.yml.version}` : ''
        }
      },
      __path: {
        get() {
          return self.getPath(self.yml)
        }
      },
    })

    this.state = {
      isLoading: false,
    }

    this.renderText = this.renderText.bind(this)
  }

  getButtonProps = () => null
  getPath = (yml) => yml.path

  readYaml = () => {
    this.setState({ isLoading: true }, () => {
      RequestV2(
        'get',
        `${Sites.url.ibesv3}/${this.ymlName}`,
        null,
        {},
        [],
        this.readYamlSuccess,
        this.readYamlFailed,

        // clearHeaders: true,
      )
    })
  }

  readYamlSuccess = (response) => {
    this.setYml(yaml.parse(response.data))
    this.setState({ isLoading: false })
  }

  setYml = (yml) => {
    this.yml = yml
  }

  readYamlFailed = (error) => {
    this.setState({ isLoading: false }, () => {
      // setTimeout(this.readYaml, 5000)
    })
  }

  beforeDownload = () => {
    if (!this.__version)
      this.readYaml()
    else
      this.onDownload()
  }

  onDownload = () => {
    if (this.__path)
      window.open(`${Sites.url.ibesv3}/${encodeURIComponent(this.__path)}`)
    else
      message.info('Cannot download, please ask the application to management')
  }

  componentDidMount() {
    this.readYaml()
  }

  // override this function
  renderText() {}

  render() {
    return (
      <Button
        block
        type='primary'
        loading={this.state.isLoading}
        onClick={this.beforeDownload}
        style={{ minWidth: '48px' }}
        {...this.getButtonProps()}
      >
        {this.__version
          ? this.renderText()
          : 'Preparing error, click to reload...'}
      </Button>
    )
  }
}

export default BaseButton