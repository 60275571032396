import React from 'react'
import { Row, Spin, Icon } from 'antd'
import { get } from 'lodash'
import { PermissionName } from 'App/Config'
import PageUnauthorized from 'App/Component/PageUnauthorized'
import { CheckPermission } from 'App/Utils'
import { forcePermissions } from 'App/Data'

// Base Pre Add/Detail
class BasePreDetail extends React.Component {
  constructor(props) {
    super(props)

    this.appName = null // string like 'jobs'
    this.modelName = null // string like 'buildingtype'
    this.isSuperuser = get(props.userReducer, ['user', 'is_superuser'])
    // follow rules availablePermissions, example: ['.view_']
    // don't forget insert dot and underscore
    this.availablePermissions = forcePermissions
    this.codePart = props.match.params.id === 'add' ? PermissionName.CREATE.codePart : PermissionName.READ.codePart
    const isAccessAllowed = this.availablePermissions.find(val => val.search(this.codePart) !== -1)
    this.state = {
      isCheckingPermission: !this.isSuperuser,
      isAccessAllowed,
      currentPermissionSet: this.isSuperuser ? this.availablePermissions : [],
    }
  }

  setThisState = (obj, callback = () => null, force) => {
    if (force === true) {
      return this.setState(obj, callback)
    } else {
      for (const key in obj) {
        if (obj[key] !== this.state[key]) {
          return this.setState(obj, callback)
        }
      }
    }
    callback()
  }

  handleCheckPermission = (callback = () => null) => {
    this.setThisState({ isCheckingPermission: true }, () => {
      const currentPermissionSet = CheckPermission.getPermissionSet({
        appName: this.appName,
        modelName: this.modelName,
      }).filter(val => this.availablePermissions.find(available => val.includes(available)))

      const isAccessAllowed = !!currentPermissionSet.find(val => val.search(this.codePart) !== -1)
      callback({ isAccessAllowed, currentPermissionSet })
    })
  }

  getPageAdd = () => null

  getPageDetail = () => null

  componentDidMount() {
    // Please use callbackAllowed when access is allowed,
    // Jangan menuliskan code is allowed di luar kondisi
    const callbackAllowed = () => { /* Your code here */ }
    if (this.state.isCheckingPermission) {
      this.handleCheckPermission(res => {
        this.setState({
          isCheckingPermission: false,
          isAccessAllowed: get(res, 'isAccessAllowed'),
          currentPermissionSet: get(res, 'currentPermissionSet', [])
        }, () => {
          if (get(res, 'isAccessAllowed')) {
            callbackAllowed()
          }
        })
      })
    } else {
      callbackAllowed()
    }
  }
  
  render() {
    const { isCheckingPermission, isAccessAllowed } = this.state

    if (isCheckingPermission) {
      return (
        <Row type='flex' justify='center' align='middle' style={{ marginTop: '30vh' }}>
          <Spin
            size='large'
            tip='Checking permission...'
            indicator={<Icon spin type='loading' style={{ fontSize: '4rem' }} />}
          />
        </Row>
      )
    }

    if (isAccessAllowed) {
      return get(this.props.match, ['params', 'id'], 'add') === 'add' ? (
        this.getPageAdd()
      ) : (
        this.getPageDetail()
      )
    }
    return (
      <PageUnauthorized />
    )
  }
}

export default BasePreDetail