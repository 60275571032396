import React from "react";
import {Icon, Tabs, Card, Form, Button, message} from "antd"
import {camelCase, get} from "lodash"
import ComponentAutocomplete from 'App/Component/Autocomplete'
import {FormContext, FormProvider} from "App/Component/Context/ContextForm";
import DynamicField from "App/Component/DynamicField";
import LoadingSkleton from "App/Component/LoadingSkleton";
import styled from "styled-components";
import fields from "../fields";
import { Lib, RequestV2 as Request } from "App/Utils";
const { TabPane } = Tabs;

class Settings extends React.PureComponent {

  state = {
    field: [],
    loading: true,
    data: [],
    readOnly: true
  }

  onFailed=(err, l)=>{
    message.error('Something error on server')
    this.setState({loading: false})
  }

  messageCustom = (type, msg) => {
    if (type !== 'loading') {
      message.destroy();
      this.setLoading(false);
    } else {
      this.setLoading(true);
    }
    return (
      message[type](msg)
    )
  };

  setLoading = (val) => {
    this.setState({loading: val})
  };

  onSave(form) {
    this.messageCustom('loading', 'Saving data..');
    const {data} = this.state
    const {validateFieldsAndScroll, resetFields} = form;
    validateFieldsAndScroll({scroll: {offsetTop: 150}}, (err, values) => {
      values.branch = values.branch.key
      this.request = Request(
        "put",
        "jobQueues-update",
        {},
        values,
        [data.pk],
        this.editSuccess,
        this.onFailed,
        resetFields
      );
    })
  }

  editSuccess=(response)=>{
    this.setState({ data: response.data, readOnly: true, loading: false })
    this.props.changeJobQueue(response.data)
    this.messageCustom('success', 'Update successfully');
  }

  onSuccess=(response)=>{
    this.setState({ data: response.data, loading: false })
  }

  retrieve=()=> {
    this.request = Request(
      "get",
      "jobQueues-detail",
      {},
      {},
      [this.props.match.params.id],
      this.onSuccess,
      this.onFailed
    );
  }

  hasErrors(fieldsError) {
    // Return Boolean
    return Object.keys(fieldsError).some(field => fieldsError[field]);
  }

  onCancel = (form) => {
    this.resetFields(form, () => {
      this.setState({ readOnly: true })
    })
  }

  resetFields = (form, callback = () => null) => {
    const { field, data } = this.state
    const values = {}
    for (let i = 0; i < field.length; i += 1) {
      const decoratorName = Lib.decoratorName(field[i].title)
      if (field[i].type === 'SelectQuery') {
        values[decoratorName] = Lib.setSelectValue(data[decoratorName], field[i].selectedKey, field[i].selectedLabel)
      } else {
        values[decoratorName] = data[decoratorName]
      }
    }
    form.setFieldsValue(values, callback)
  }

  componentDidMount() {
    let customField = ComponentAutocomplete[camelCase("jobQueues")]
    customField[2].additional = undefined
    this.setState({
      field: customField
    })
    this.retrieve()
  }

  render() {
    console.log('Rendered', this)
    const {loading, data, readOnly ,field} = this.state
    const {userReducer} = this.props
    let totalCount = field.length

    return (
      <Card title={"Edit Job queue "+get(data, 'name', '-')}>
        {
          !loading ?
            <Tabs defaultActiveKey="1" tabPosition={"left"}>
              <TabPane tab="Edit" key="1">
                <FormProvider>
                  <Form layout="horizontal" style={{height: "400px"}}>
                    { field && field.map((elem, index) => {
                      let datafield = (elem.dataIndex) && get(data, elem.dataIndex.split('.'))
                      return (
                        <DynamicField
                          {...elem}
                          onBlur={()=>null}
                          normFile={()=>null}
                          toggleDrawerSQ={()=>null}
                          operation={readOnly ? "view" : "edit"}
                          userReducer={userReducer}
                          dataRecord={datafield}
                          key={index}
                        />
                      );
                    })}
                    <FormContext.Consumer>
                      {(form) => (
                        <StyledFooter>
                          { readOnly ?
                            <StyledButton
                              onClick={() => {
                                this.setState({readOnly: false})
                              }} type="danger"
                              disabled={loading}
                            >
                              <Icon type="edit"/>  Edit
                            </StyledButton> :
                            <>
                              <StyledButton type="danger" loading={loading} onClick={() => this.onCancel(form)}>
                                Cancel
                              </StyledButton>
                              <StyledButton
                                onClick={() => this.onSave(form)}
                                type="primary"
                                disabled={loading || this.hasErrors(form.getFieldsError())}
                              >
                                <Icon type="save" theme="filled" /> Save
                              </StyledButton>
                            </>
                          }
                        </StyledFooter>
                      )}
                    </FormContext.Consumer>
                  </Form>
                </FormProvider>
              </TabPane>
            </Tabs>
            :
            <LoadingSkleton count={totalCount}/>
        }
      </Card>
    )
  }
}

export default Settings;

const StyledButton = styled(Button)`
  margin-right: 8px;
`;

const StyledFooter = styled.div`
  position: fixed;
  right: 8px;
  bottom: 10px;
  width: calc(100% - 300px);
  border-top: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
`;
