export default [
  {
    title: 'Name',
    required: true,
    type: 'text',
    dataIndex: 'name',
    initialValue: '',
    note_text: '',
  }, {
    title: 'Status',
    type: 'select',
    disableAllowClear: true,
    data: [
      {
        alias: 'active',
        value: 'Active - Anyone can use'
      }, {
        alias: 'inactive',
        value: 'Inactive - No one can use'
      }, {
        alias: 'unpublished',
        value: 'Unpublished - Only certain people can use'
      }
    ],
    initialValue: 'active',
    note_text: '',
    additional: '',
    dataIndex: 'status',
  }, {
    title: 'Prefill sets',
    type: 'select_multiple',
    data: 'prefillSets',
    initialValue: '',
    additional: 'add',
    dataIndex: 'prefill_sets',
    selectedKey: 'pk',
    selectedValue: 'name',
    paramProps: { status: 'active' },
  }, {
    title: 'Order',
    type: 'number',
    dataIndex: 'order',
    initialValue: null, // set initialValue to null for allow null
  },
]