import { BaseList } from "App/Screens/SubModules/Component";
import descriptor from "./descriptorOffline";

class GroupList extends BaseList {
  constructor(props) {
    super(props)
    this.appName = 'account'
    this.modelName = 'group'
    this.availablePermissions = ['.view_', '.add_', '.change_']
    this.propsList = {
      avatar: 'name',
      pageTitle: 'Group',
      descriptor,
      masterKey: 'module-groupUser-group',
    }
    this.state = {
      ...this.state,
      currentPermissionSet: !this.state.isCheckingPermission ? this.availablePermissions : [],
    }
  }
}

export default GroupList