import React from 'react'
import PropTypes from 'prop-types'
import {Col, Icon, Row, Tabs, Typography} from "antd";
import {capitalize, startCase, kebabCase, isEmpty, get} from 'lodash';
import Overview from "./Overview";
import Statistic from "./Statistic";
import DynamicForm from './Formdata'
import styled from "styled-components";
import Reference from "./Reference";
import HistoryAction from "../HistoryAction";

const { TabPane } = Tabs;
const { Text } = Typography;

export default class Tab extends React.Component{

  tabchange=(e)=>{
    const {match, referenceInfo} = this.props
    const p = this.props
    let tab = referenceInfo.reference_by
    if(e > 999 && e!=="-1"){
      tab = referenceInfo.reference_to
    }

    let tabname = ''
    if(e < 1000 && e !== '-1'){
      tabname = tab[e].label
    }
    else if(e >= 1000){
      tabname = tab[e-1000].label
    }

    const matchUrl = match.url.slice(-1) === '/' ? match.url.slice(0, -1) : match.url
    let urltab = matchUrl + '/'+ kebabCase(tabname)
    p.history.replace(urltab, {
      ...p.location.state,
      idData: p.match.params.id
    });
    p.setThisState({ tabindex: e })
  }

  render() {
    const p = this.props
    let referenceTo, referenceBy;
    if(p.referenceInfo) {
      referenceTo = Reference.tabReferenceTo(p.referenceInfo.reference_to, p.goTo, p.tabindex, p.dataidentifier, p.urlData, p)
      referenceBy = Reference.tabReferenceBy(p.referenceInfo.reference_by, p.goTo, p.tabindex, p.dataidentifier, p.urlData, p)
    }
    return(
      <Row>
        <Col xs={24}>
          <Tabs
            activeKey={String(p.tabindex)}
            type="card"
            tabPosition={ p.operation === "add"?"top":"right"}
            onChange={(e)=>this.tabchange(e)}
          >
            <TabPane
              tab={
                <Row type='flex'>
                  <Col md={24} sm={0} xs={0} style={{overflow: 'hidden'}}>
                    <Icon type="edit"/>{capitalize(p.operation) + " " + startCase(p.title)}
                  </Col>
                  <Col md={0} sm={24} xs={24} style={{overflow: 'hidden'}}>
                    <Icon type="edit"/>
                  </Col>
                </Row>
              }
              key="-1"
            >
              {p.operation === 'add' &&
                <DynamicForm
                  {...p}
                />
              }

              {p.operation !== 'add' && (
                <Tabs tabPosition="top" onChange={p.handleTabDetailChange} tabBarGutter={0}>
                  {p.operation !== 'add' &&
                  <TabPane
                    key="1"
                    tab={
                      <span>
                          <Icon type="edit" />
                        {capitalize(p.operation) + " " + startCase(p.title)}
                        </span>
                    }>
                    <Row>
                      <DynamicForm
                        {...p}
                      />
                    </Row>
                  </TabPane>
                  }
                  { p.getAnotherTabPanes(p.dataRecord, p.onReload) }
                  {p.operation !== 'add' && p.isFirstPage && p.urlKeyHistory && !p.match.params.historyId &&
                    <TabPane
                      key="history"
                      tab={
                        <span>
                          <Icon type="history" />
                          History
                        </span>
                      }
                    >
                      <HistoryAction
                        id={p.match.params.id}
                        history={p.history}
                        urlKeyHistory={p.urlKeyHistory}
                        pathHistoryDetail={p.pathHistoryDetail}
                        labelTable={p.title}
                        title={get(p.dataRecord, p.keyLabel, `#${p.match.params.id}`)}
                      />
                    </TabPane>
                  }
                  {(p.operation === 'edit' || p.operation === 'view') && p.referenceInfo && p.referenceInfo.overview &&
                  <TabPane
                    key="2"
                    tab={
                      <span>
                      <Icon type="dashboard" />
                      Overview
                    </span>
                    }>
                    <Overview
                      list={p.fields}
                      userReducer={p.userReducer}
                      url={kebabCase(p.urlData)}
                      data={p.dataRecord}
                    />
                  </TabPane>
                  }
                  {(p.operation === 'edit' || p.operation === 'view') && p.referenceInfo && p.referenceInfo.statistic &&
                  <TabPane
                    key="3"
                    tab={
                      <span>
                      <Icon type="pie-chart" />
                      Statistic
                    </span>
                    }>
                    <Statistic data={p.dataRecord} url={kebabCase(p.urlData)} userReducer={p.userReducer} list={p.fields}/>
                  </TabPane>
                  }
                  { p.tabs }
                </Tabs>
              )}
            </TabPane>
            {p.operation === 'add' && p.getAnotherTabPanesAdd()}
            {(p.operation === 'edit' || p.operation === 'view') && !isEmpty(referenceBy) &&
            <Styledtabpane
              disabled={true}
              tab={
                <Row type='flex'>
                  <Col md={24} sm={0} xs={0} style={{overflow: 'hidden'}}>
                    <Text strong>
                      Referenced by
                    </Text>
                  </Col>
                  <Col md={0} sm={24} xs={24} style={{overflow: 'hidden'}}>
                    <Text>
                      by
                    </Text>
                  </Col>
                </Row>
              }
              key="868828"
            >
            </Styledtabpane>
            }

            {(p.operation === 'edit' || p.operation === 'view') && referenceBy &&
            referenceBy }

            {(p.operation === 'edit' || p.operation === 'view') && !isEmpty(referenceTo) &&
            <Styledtabpane
              disabled={true}
              tab={
                <Row type='flex'>
                  <Col md={24} sm={0} xs={0} style={{overflow: 'hidden'}}>
                    <Text strong>
                      Reference to
                    </Text>
                  </Col>
                  <Col md={0} sm={24} xs={24} style={{overflow: 'hidden'}}>
                    <Text>
                      to
                    </Text>
                  </Col>
                </Row>
              }
              key="868829"
            >
            </Styledtabpane> }
            {(p.operation === 'edit' || p.operation === 'view') &&
            referenceTo }
          </Tabs>

        </Col>
      </Row>
    )
  }
}

Tab.propTypes = {
  tabs: PropTypes.array,
  getAnotherTabPanes: PropTypes.func,
  getAnotherTabPanesAdd: PropTypes.func,
}

Tab.defaultProps = {
  tabs: [],
  getAnotherTabPanes: () => null,
  getAnotherTabPanesAdd: () => null,
}

const Styledtabpane = styled(TabPane)`
  border: 0px solid #ffff;
  background: #000;
`;
