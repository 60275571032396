export default [
  {
    title: 'Name',
    dataIndex: 'name',
    type: 'text',
    required: true,
    initialValue: '',
  },
  {
    title: 'Document Type',
    dataIndex: 'document_type',
    required: true,
    type: 'select',
    disableAllowClear: true,
    initialValue: 'plan',
    data: [
      {
        alias: 'plan',
        value: 'Plan',
      },
      {
        alias: 'erf',
        value: 'ERF',
      },
    ]
  },
  {
    title: 'Is Active',
    dataIndex: 'is_active',
    type: 'checkbox',
    initialValue: true,
  },
]
