import { BaseList, BaseDetail } from "App/Component/Bases";
import fields from "./fields";
import descriptor from "./descriptor";
import options from "./options";
import { forcePermissions } from "App/Data";

const title = "Grades";
const urlData = "jobGrades";
const bulkName = "code";
const availablePermissions = ['.view_', '.add_', '.change_'];

class List extends BaseList {
  constructor(props) {
    super(props)

    this.title = title;
    this.urlData = urlData;
    this.bulkName = bulkName;
    this.availablePermissions = availablePermissions;
    this.propsList = {
      descriptor,
      exportImport: options.exportImportOptions,
    }
    this.state = {
      ...this.state,
      currentPermissionSet: this.isSuperuser ? this.filterPermissionItems(forcePermissions) : [],
    }
  }
}

class Detail extends BaseDetail {
  constructor(props) {
    super(props)

    this.title = title
    this.urlData = urlData
    this.bulkName = bulkName
    this.fields = fields
    this.availablePermissions = availablePermissions
    this.propsDetail = {
      urlKeyHistory: 'api:admin:jobs:jobgrade:detail-revision',
      keyLabel: 'level'
    }
    this.state = {
      ...this.state,
      currentPermissionSet: this.isSuperuser ? this.filterPermissionItems(forcePermissions) : [],
    }
  }
}

export default { List, Detail };