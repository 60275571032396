import { get } from 'lodash'
import { BaseRedirectMenu } from 'App/Screens/SubModules/Component'
import UserGroupMenu from './Menu'

class UserGroupRedirect extends BaseRedirectMenu {
  constructor(props) {
    super(props)

    this.menu = UserGroupMenu
    const isSuperuser = get(props.userReducer, ['user', 'is_superuser'])
    this.withoutCheckPermission = this.props.history.location.pathname.split('/').filter(v => v).length !== 1
    this.state = {
      isCheckingPermission: !isSuperuser && !this.withoutCheckPermission,
      isAccessAllowed: isSuperuser || this.withoutCheckPermission,
    }
  }

  callbackAllowed = (urlTarget) => {
    this.setState({ isCheckingPermission: false, isAccessAllowed: true }, () => {
      this.props.history.replace(urlTarget)
    })
  }

  getComponentAllowed = () => this.props.routeElement

  UNSAFE_componentWillReceiveProps(nextProps) {
    const withoutCheckPermission = nextProps.history.location.pathname.split('/').filter(v => v).length !== 1
    if (!withoutCheckPermission) {
      this.setState({ isCheckingPermission: true }, () => {
        this.checkRedirect(nextProps.history)
      })
    }
  }

  componentDidMount() {
    if (!this.withoutCheckPermission) {
      this.checkRedirect()
    }
  }
}

export default UserGroupRedirect