import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Spin, Icon, message } from "antd";
import { get } from "lodash";

import TemplateTable from "App/Component/TemplateTable";
import Lib from "App/Utils/Lib";
import { Request, CheckPermission } from "App/Utils";
// import PageError from "App/Component/Singlepage/PageError";
import { PermissionName } from "App/Config";
// import NavMenus from "App/Config/MenuLeft";
import PageUnauthorized from "App/Component/PageUnauthorized";
import { forcePermissions } from "App/Data";

// Only for admin module
class List extends Component {
  constructor(props) {
    super(props);

    this.isSuperuser = get(props.userReducer, ['user', 'is_superuser'])
    this.title = "";
    this.urlData = "";
    this.bulkName = "";
    this.isSubmodule = false;
    this.availablePermissions = []; // blank array is all allowed permissions
    this.propsList = {}; // see props documentation in App/Component/Singlepage/AdvancedTable
    this.state = {
      isCheckingPermission: !this.isSuperuser,
      isAccessAllowed: this.isSuperuser, // view (read)
      currentPermissionSet: this.isSuperuser ? forcePermissions : [],
    };
  }

  setThisState(obj, callback = () => null, force) {
    if (force) {
      this.setState(obj, callback)
    } else {
      for (const key in obj) {
        // object with object is not equal
        if (obj[key] !== this.state[key]) {
          this.setState(obj, callback)
          return
        }
      }
      callback()
    }
  }

  // return first on pathname like "module" in "example.com/module/.../..."
  getCurrentModule = pathname => {
    return pathname.split('/').filter(val => val)[0]
  }

  getAppName = () => {
    const urlNameSplitted = this.props.urlName.split(':') // ex: api:admin:estimation:linetemplate:list
    return urlNameSplitted[2]
  }

  getModelName = () => {
    const urlNameSplitted = this.props.urlName.split(':') // ex: api:admin:estimation:linetemplate:list
    return urlNameSplitted[3]
  }

  filterPermissionItems = (permissionItems) => {
    if (this.availablePermissions.length) {
      const allowedPermissionItems = []
      for (let i = 0; i < this.availablePermissions.length; i += 1) {
        for (let j = 0; j < permissionItems.length; j += 1) {
          if (permissionItems[j].includes(this.availablePermissions[i])) {
            allowedPermissionItems.push(permissionItems[j])
            break
          }
        }
      }
      return allowedPermissionItems
    }
    return permissionItems
  }

  handleCheckPermission = () => {
    this.setThisState({ isCheckingPermission: true }, () => {
      const { userReducer } = this.props
      const appName = this.getAppName()
      const modelName = this.getModelName()
      const permissionItems = CheckPermission.getPermissionSet({ userReducer, appName, modelName })
      const currentPermissionSet = this.filterPermissionItems(permissionItems)
      const codePartRead = PermissionName.READ.codePart
      const isAccessAllowed = !!currentPermissionSet.find(val => val.search(codePartRead) !== -1)
      // const currentModule = this.getCurrentModule(this.props.location.pathname)
      // const allowedModules = get(this.props.userReducer, ['user', 'allowed_modules'], [])
      // const selectedHeader = HeaderMenu.find(val => val.url === `/${currentModule}` && allowedModules.find(module => val.codename === module.codename))
      // let currentPermissionSet = []
      // if (selectedHeader) { // is allowed module
      //   const modulePermission = allowedModules.find(val => val.codename === selectedHeader.codename)
      //   // permission code format: "appnamelowercase.permissioncode_modelname"
      //   const urlNameSplitted = this.props.urlName.split(':') // ex: api:admin:estimation:linetemplate:list
      //   const permissionCode = `${urlNameSplitted[2]}.${codePartRead}_${urlNameSplitted[3]}`
      //   // Mencari permission set dari group-group (app)
      //   // findPermission:
      //   // for (let i = 0; i < modulePermission.groups.length; i += 1) {
      //   //   for (let j = 0; j < modulePermission.groups[i].sets.length; j += 1) {
      //   //     if (modulePermission.groups[i].sets[j].permissions.find(permission => permission.code === permissionCode)) {
      //   //       currentPermissionSet = modulePermission.groups[i].sets[j].permissions
      //   //       break findPermission
      //   //     }
      //   //   }
      //   // }
      //   // ---------------------------------------------------------------
      //   // Mencari permission set langsung dari sets (digabungkan dari BE)
      //   if (modulePermission) {
      //     for (let i = 0; i < modulePermission.sets.length; i += 1) {
      //       if (modulePermission.sets[i].permissions.find(permission => permission.code === permissionCode)) {
      //         currentPermissionSet = modulePermission.sets[i].permissions
      //         break
      //       }
      //     }
      //   }
      // }
      this.setState({ isCheckingPermission: false, isAccessAllowed, currentPermissionSet })
    })
  }

  componentDidMount() {
    if (this.state.isCheckingPermission) {
      this.handleCheckPermission();
    }
  }

  componentWillUnmount() {
    // Lib.windowStop(); // don't activate this line
  };

  render() {
    const { isCheckingPermission, isAccessAllowed, currentPermissionSet } = this.state
    const { urlName } = this.props

    if (isCheckingPermission) {
      return (
        <Row type='flex' justify='center' align='middle' style={{ marginTop: '30vh'}}>
          <Spin
            size="large"
            tip="Checking permission..."
            indicator={<Icon type="loading" style={{ fontSize: "4rem" }} spin />}
          />
        </Row>
      )
    }

    return isAccessAllowed ? (
      <TemplateTable
        {...this.props}
        title={this.title}
        urlData={this.urlData}
        bulkName={this.bulkName}
        // meta={meta}
        url_name={urlName}
        permissions={currentPermissionSet}
        isSubmodule={this.isSubmodule}
        {...this.propsList}
      />
    ) : (
      <PageUnauthorized history={this.props.history} />
    )
  }
}

List.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  userReducer: PropTypes.object,
  urlName: PropTypes.string,
}

List.defaultProps = {
  userReducer: null,
  urlName: '',
}

export default List;
