import React from "react";
import PropTypes from "prop-types";
import {groupBy, snakeCase, maxBy, startCase, orderBy} from "lodash";
import get from "lodash/get";
import { withRouter } from "react-router-dom";
import {
  Col,
  Row,
  Button,
  Card,
  Divider,
  List,
  Popconfirm,
  Typography,
  Icon,
  Collapse,
  Select
} from "antd";

import { RequestV2 as Request, Lib, Modules } from "App/Utils";
import SorterTag from "./SorterTag";
import componentHelper from "./Singlepage/Lib2";

const validate = require("validate.js");
const { Option } = Select;
const { Panel } = Collapse;
const {Text} = Typography

class NewListData extends React.PureComponent {
  groupBy = null;
  page = 1;
  search = "";
  searchBy = "";
  sortBy = "";
  initialState = {
    groupBy: this.groupBy,
    records: ["Branch name","Slug","Modified","Status"],
    total: 0,
    searchTag: [],
    current: 0,
    iconSorting: null,
    limit: 0,
    isLoading: false
  };
  state = this.initialState;

  onSearch = val => {
    this.page = 1;
    this.setState({
      searchTag: val
    }, ()=> {this.read()})
  };

  onSizeChange=(e, size)=>{
    this.setState({
      limit: size,
      current: e
    },()=>this.read())
  };

  onPageChange = page => {
    this.page = page;
    this.read();
  };

  onSearchByChange = (val, isChecked) => {
    this.searchBy = this.searchBy.split(",");
    this.searchBy = this.searchBy.filter(a => a);

    isChecked
      ? this.searchBy.push(val)
      : (this.searchBy = this.searchBy.filter(a => a !== val));

    this.searchBy = this.searchBy.join();
    this.read();
  };

  onGroupByChange = val => {
    this.groupBy = val.key !== "-" ? val : null;
    this.read();
  };

  read() {
    const p = this.props;
    const s = this.state
    let ordering = []
    let chars = orderBy(s.iconSorting, ['priority'],['asc']);
    if(chars.length > 0){
      for (let orderData of chars) {
        if(orderData.priority > 0) {
          if(orderData.sorted === 'asscend') {
            ordering.push(snakeCase(orderData.name))
          }else{
            ordering.push('-'+snakeCase(orderData.name))
          }
        }
      }
    }


    this.setState(
      {
        isLoading: true
      },
      () =>
        Request(
          "GET",
          `${p.masterKey}-read`,
          {},
          {
            ...p.readParams,
            page: this.page,
            search: s.searchTag.join(','),
            ordering: ordering.join(',') ? ordering.join(',') : '-modified',
            fields: this.searchBy,
            limit: s.limit
          },
          [],
          (res, ex) => {
            if(this.props.extraCount)
              this.props.extraCount(res.data.count)
            let { results } = res.data;
            let groupByRes = {};

            if (this.groupBy) {
              groupByRes = groupBy(res.data.results, i =>
                get(i, this.groupBy.key)
              );
              results = [];

              for (const k in groupByRes)
                results.push({ title: k, records: groupByRes[k] });
            }

            this.setState({
              records: results,
              total: res.data.count,
              current: res.data.current,
              limit: res.data.limit,
              groupBy: this.groupBy,
              isLoading: false
            });
            p.onReadSuccess(res, ex);
          },
          (err, ex) => {
            Modules.responseMessage("error", err.response.data.detail);
            this.setState(this.initialState);
            p.onReadFailed(err, ex);
          },
          p.readExtra
        )
    );
  }

  delete(item) {
    const p = this.props;

    this.setState(
      {
        isLoading: true
      },
      () => {
        Request(
          "DELETE",
          `${p.masterKey}-delete`,
          {},
          {},
          [item.pk],
          (res, ex) => {
            this.read();
            Modules.responseMessage("success", "Item deleted!");
            p.onDeleteSuccess(res, ex);
          },
          (err, ex) => {
            this.setState({ isLoading: false });
            Modules.responseMessage("error", err.response.data.detail);
            p.onDeleteFailed(err, ex);
          },
          item
        );
      }
    );
  }

  initSorter() {
    const p = this.props;
    this.sortBy = p.sortByOptions
      .filter(g => g.default)
      .map(g => g.key)
      .join();
    let col = []
    for (let i of p.headercols){
      if(i.sorter) {
        col.push({name: i.name, sorted: 'no-sort', priority: 0})
      }
    }
    this.setState({
      iconSorting: col
    })

    this.searchBy = p.searchByOptions
      .filter(g => g.default)
      .map(g => g.key)
      .join();

    this.groupBy = p.groupByOptions.find(g => g.default) || null;
  }

  renderCountCard() {
    const { totalOptions } = this.props;
    const el = [];

    if (validate.isEmpty(totalOptions)) return null;

    const ret = totalOptions(this.state);
    for (let i = 0; i < ret.length; i++) {
      el.push(
        <Col
          key={ret[i].title}
          xs={24}
          sm={Math.floor(22 / ret.length)}
          className="text-center"
        >
          <span className="text-muted font-lg mb-3">{ret[i].title}</span>
          <div>
            <span className="font-4xl">{ret[i].total}</span>
          </div>
        </Col>
      );
      if (i !== ret.length - 1) {
        el.push(
          <Col key={`div${i}`} xs={0} sm={1} className="text-center">
            <Divider type="vertical" style={{ height: "4.5rem" }} />
          </Col>
        );
      }
    }
    return (
      <Col>
        <Card className="mb-2">
          <Row>{el}</Row>
        </Card>
      </Col>
    );
  }

  renderListItem(i, p) {
    return (
      <List.Item
        className='overlay-item'
        key={i.pk}
        actions={[
          <Button
            type="primary"
            icon="control"
            onClick={() =>
              p.onDetailClick
                ? p.onDetailClick(i)
                : p.history.push(`${p.match.path}/${i.pk}`)
            }
          />,
          <Popconfirm
            title="Are you sure ?"
            onConfirm={() =>
              p.onDeleteClick ? p.onDeleteClick(i) : this.delete(i)
            }
          >
            <Button type="danger" icon="delete" />
          </Popconfirm>
        ].concat(p.extraActions(i))}
      >
        <List.Item.Meta
          avatar={p.avatar(i)}
          title={<span className="font-weight-bold">{p.title(i)}</span>}
          description={p.description(i)}
        />
        {p.content(i)}
      </List.Item>
    );
  }

  renderItem(i, p, s) {
    if (s.groupBy) {
      return (
        <Collapse key={i.title} bordered={false} defaultActiveKey={[i.title]}>
          <Panel
            key={i.title}
            header={`${s.groupBy.label} - ${i.title} (${i.records.length})`}
          >
            {i.records.map(x => this.renderListItem(x, p))}
          </Panel>
        </Collapse>
      );
    } else {
      return this.renderListItem(i, p);
    }
  }

  componentDidMount() {
    this.initSorter();
    this.read();
  }

  sortChange=(columns, clearSorting=false)=>{

    let iconSorting = this.state.iconSorting
    let checkData = iconSorting.findIndex(item => item.name===columns)
    let prevPriority = maxBy(iconSorting, 'priority')
    if(componentHelper.getNexticon(iconSorting[checkData].sorted) === "no-sort" || clearSorting) {
      for (let data of iconSorting) {
        if(data.priority > iconSorting[checkData].priority){
          data.priority-=1
        }
      }
      iconSorting[checkData].priority = 0
    }else if(iconSorting[checkData].priority===0){
      iconSorting[checkData].priority = prevPriority.priority+1
    }
    if(clearSorting){
      iconSorting[checkData].sorted = 'no-sort'
    }else {
      iconSorting[checkData].sorted = componentHelper.getNexticon(iconSorting[checkData].sorted)
    }
    this.setState({
      iconSorting,
      isLoading: true
    },()=> this.read())
  }

  renderHeaderThreeCols=()=>{
    const {headercols} = this.props
    const p = this.props
    const {iconSorting} = this.state
    let cols = headercols
    if(!headercols)
      cols = []
    const col = cols.map((value, i)=>{
      let idx = iconSorting.findIndex(val => val.name === value.name)
      return(
        i > 0 && p.numberCols === 3 ?
        <Col key={i} span={8} style={{width: i === 1 ? "32%" : i===2 ? "54%" : "10%"}}>
          <span className="text-muted mb-2">
            {value.sorter ?
            componentHelper.getIcon(iconSorting, idx, this.sortChange, value.name, true):
            value.name}
          </span>
        </Col> :
          i > 0 && p.numberCols === 2 &&
        <Col key={i} span={8} style={{width: i === 1 ? "30%" : i===2 && "30%", marginLeft: i===1 ? "182px" : "0px"}}>
          <span className="text-muted mb-2">
            {value.sorter ?
              componentHelper.getIcon(iconSorting, idx, this.sortChange, value.name, true):
              value.name}
          </span>
        </Col>
      )
    })
    return col
  }

  renderHeaderFourCols=()=>{
    const {headercols} = this.props
    const {iconSorting} = this.state
    let cols = headercols
    if(!headercols)
      cols = []
    const col = cols.map((value, i)=>{
      let idx = iconSorting.findIndex(val => val.name === value.name)
      return(
        i > 0 &&
          <Col key={i} span={6} className="w-auto">
            <span className="text-muted mb-2">
              {value.sorter ?
                componentHelper.getIcon(iconSorting, idx, this.sortChange, value.name, true):
                value.name}
            </span>
          </Col>
      )
    })
    return col
  }

  handleDeselect=(value)=>{
    this.page = 1;
    let tag = this.state.searchTag
    let search = tag.filter(val => val !== value)
    this.setState({
      searchTag: search
    }, ()=>this.read())
  }

  render() {
    const s = this.state;
    const p = this.props;
    let idx = undefined
    if(s.iconSorting)
      idx = s.iconSorting.findIndex(val => val.name === p.headercols[0].name)
    const data = [
      {
        title: '',
      }]
    return (
      <Row className="animated fadeIn">
        {this.renderCountCard()}
        <Col>
          <Card
            title={Lib.fillEmpty(p.pageTitle, startCase(p.masterKey))}
            extra={
              <Row>
                <Col xs={24} sm={24}>
                  <Select
                    mode="tags"
                    tokenSeparators={[',']}
                    onChange={value => this.onSearch(value)}
                    dropdownStyle={{display: 'none'}}
                    value={s.searchTag}
                    className="min-w-4"
                    style={{width: "100%"}}
                    placeholder="Search"
                    onDeselect={(key) => this.handleDeselect(key)}
                  />
                </Col>
                <Col sm={24}>
              <Row style={{marginTop: "5px"}}>
              <Col sm={6} xs={24}>
                <span className="font-weight-bold" style={{whiteSpace: "nowrap"}}>Search by : </span>
              </Col>
              <Col sm={18} xs={24}>
              {!validate.isEmpty(p.searchByOptions)
                ? p.searchByOptions.map((g, i) => (
                  <React.Fragment key={g.key}>
                    <SorterTag
                      isToggleable
                      {...g}
                      value={g.key}
                      onChange={this.onSearchByChange}
                    />
                    {i !== p.searchByOptions.length - 1 && (
                      <Divider type="vertical" />
                    )}
                  </React.Fragment>
                  ))
                : <span style={{marginLeft: "11px"}}>--</span>}
                </Col>
              </Row>
                </Col>
              </Row>
            }
          >
            {!validate.isEmpty(p.notes) && (
              <div className="py-2 mb-3 um-branch-groups-caption">
                {p.notes}
              </div>
            )}
            { !p.disableAddButton &&
            <Button
              block
              icon="plus"
              type="dashed"
              className="mb-2"
              onClick={() =>
                p.onAddClick
                  ? p.onAddClick()
                  : p.history.push(`${p.match.path}/add`)
              }
            >
              Add {startCase(p.masterKey)}
            </Button>
            }
            <Row>
              <Row>
                <Col sm={4} md={2} xs={4}>
                  <span className="font-weight-bold">Group by :</span>
                </Col>
                <Col sm={6} xs={6} md={3}>
                  <Select
                    labelInValue
                    className="min-w-3"
                    size="small"
                    defaultValue={
                      p.groupByOptions.find(g => g.default) || { key: "-" }
                    }
                    onChange={this.onGroupByChange}
                  >
                    <Option key="-" value="-">
                      No Grouping
                    </Option>
                    {p.groupByOptions.map(g => (
                      <Option key={g.key} value={g.key}>
                        {g.label}
                      </Option>
                    ))}
                  </Select>
                </Col>
              </Row>
            </Row>

            <List
              style={{marginBottom: "5px", marginTop: "10px"}}
              bordered={true}
              rowKey="pk"
              dataSource={data}
              renderItem={(item, i) => (
                <List.Item
                  actions={["Operation"]}
                  key={i}
                >
                  { p.numberCols ?
                    <Row style={{display: "flex", flex: "1 1"}}>
                      <Col span={24}>
                        <Text style={{lineHeight: "None", marginTop: "9px"}}>{
                          s.iconSorting && p.headercols[0].sorter ?
                            componentHelper.getIcon(s.iconSorting, idx, this.sortChange, p.headercols[0].name, true)
                            :
                            p.headercols[0].name }
                        </Text>
                      </Col>
                    </Row> :
                  <List.Item.Meta
                    title={
                      <Text style={{lineHeight: "None", marginTop: "9px"}}>
                        { s.iconSorting && p.headercols[0].sorter ?
                      componentHelper.getIcon(s.iconSorting, idx, this.sortChange, p.headercols[0].name, true)
                        :
                      p.headercols[0].name }
                      </Text>
                    }
                  />}
                  { p.numberCols ?
                  <Row style={{width: "465px"}} className="mr-5">
                    {s.iconSorting && this.renderHeaderThreeCols()}
                  </Row>
                  :
                  <Row gutter={[64, 4]} className="mr-5">
                    {s.iconSorting && this.renderHeaderFourCols()}
                  </Row>
                  }
                </List.Item>
              )}
            />

            <List
              itemLayout="horizontal"
              rowKey="pk"
              dataSource={s.records}
              loading={{
                indicator: (
                  <Icon type="loading" style={{ fontSize: "4rem" }} spin />
                ),
                spinning: s.isLoading
              }}
              pagination={{
                total: s.total,
                current: s.current,
                pageSize: s.limit,
                showSizeChanger: true,
                showQuickJumper: true,
                onShowSizeChange: this.onSizeChange,
                onChange: this.onPageChange,
                pageSizeOptions: p.pageSizeOptions,
              }}
              renderItem={i => this.renderItem(i, p, s)}
            />
          </Card>
        </Col>
      </Row>
    );
  }
}

const keyLabelPropTypes = {
  key: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  default: PropTypes.bool
};

NewListData.propTypes = {
  masterKey: PropTypes.string.isRequired,
  groupByOptions: PropTypes.arrayOf(PropTypes.exact(keyLabelPropTypes)),
  sortByOptions: PropTypes.arrayOf(PropTypes.exact(keyLabelPropTypes)),
  searchByOptions: PropTypes.arrayOf(PropTypes.exact(keyLabelPropTypes)),
  totalOptions: PropTypes.func,
  pageTitle: PropTypes.any,
  onAddClick: PropTypes.func,
  disableAddButton: PropTypes.bool,
  pageSizeOptions: PropTypes.array,
};

NewListData.defaultProps = {
  readParams: {},
  readExtra: {},
  deleteParams: {},
  groupByOptions: [],
  sortByOptions: [],
  searchByOptions: [],
  onReadSuccess: () => null,
  onReadFailed: () => null,
  onDeleteSuccess: () => null,
  onDeleteFailed: () => null,
  avatar: () => null,
  title: () => null,
  content: () => null,
  extraActions: () => [],
  description: () => "-",
  pageSizeOptions: ['25', '50', '75', '100'],
};

export default withRouter(NewListData);
