import { BaseRedirectMenu } from 'App/Screens/SubModules/Component'
import BranchMenu from "./Menu";

class BranchRedirect extends BaseRedirectMenu {
  constructor(props) {
    super(props)

    this.menu = BranchMenu
  }
}

export default BranchRedirect