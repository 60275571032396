import React from 'react'
import { Button, message, Modal } from 'antd'
import { get } from 'lodash'
import { BaseList } from "App/Screens/SubModules/Component";
import descriptor from "./descriptorOffline";

class UserAllList extends BaseList {
  constructor(props) {
    super(props)

    this.appName = 'account'
    this.modelName = 'user'
    this.availablePermissions = ['.view_', '.add_', '.change_']
    this.propsList = {
      avatar: 'username',
      pageTitle: 'User',
      descriptor,
      masterKey: 'module-groupUser-userAll',
      groupByOptions: [
        {
          label: 'Home Branch',
          key: 'home_branch.name',
          dataIndex: 'home_branch.name',
        },
      ],
      onAddClick: this.onAddClick,
      onDetailClick: this.onDetailClick,
    }
    this.state = {
      ...this.state,
      currentPermissionSet: !this.state.isCheckingPermission ? this.availablePermissions : [],
    }
  }

  onAddClick = () => {
    const currentModal = Modal.confirm({
      title: 'Select User Type',
      content: 'Press Esc to cancel',
      okText: 'IBES User',
      onOk: () => this.onDetailClick('add', { project_type: 'ibes' }),
      cancelText: 'Tracker User',
      cancelButtonProps: {
        onClick: () => {
          this.onDetailClick('add', { project_type: 'tracker' })
          currentModal.destroy()
        }
      },
    })
  }

  onDetailClick = (id, record) => {
    const projectType = get(record, 'project_type')
    switch(projectType) {
      case 'ibes': {
        this.props.history.push(`/user-group/user/ibes/${id}`)
        break
      }
      case 'tracker': {
        this.props.history.push(`/user-group/user/tracker/${id}`)
        break
      }
      default: {
        message.info('Cannot find type of user record, please open it from IBES / Tracker menu')
      }
    }
  }
}

export default UserAllList