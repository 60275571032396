import React, { Component } from "react";
import styled from "styled-components";
import { Link, withRouter } from "react-router-dom";
import { Layout, Menu, Row, Popover, Icon, Avatar } from "antd";
import { get } from 'lodash';
import {CalendarJDS} from 'App/Screens/SubModules/JobMg/Management/'
import Lib from "App/Utils/Lib";
import { UrlsFe, HeaderMenu } from "App/Config";
// import {
// 	estimationMenu,
// 	geoMenu,
// 	jobMenu,
// 	multisiteMenu,
// 	notificationMenu,
// 	reportMenu,
// 	usersMenu
// } from "App/Config/Menu";

import NavMenus from "App/Config/Menu.v2";
import NavMenusV2 from "App/Config/MenuLeft";
import { PermissionName } from "App/Config";

const { Sider } = Layout;
const IbesLogo = require("App/Assets/img/IBES-LOGO.png");

class Navigation extends Component {
  state = {
    menus: [],
    additionalCalendar: false,
  };

  getMasterUrl(url = undefined) {
    if (url) {
      let data = url.split("/").filter(word => word);
      let tempUrl = "";
      const anotherModule = ["user-group", "job-management"];
      for (const val of anotherModule) {
        if (val === data[0]) {
          return Lib.generateURL("", val);
        }
      }
      if (data.length <= 1) {
        return Lib.generateURL("", data[0]);
      } else {
        for (let i = 0; i < 2; i++) {
          tempUrl += "/" + data[i];
        }
        return tempUrl;
      }
    }
    return url;
  }

  getSelectedMenu = () => {
    const pathname = get(this.props.location, 'pathname', '')
    const { menus } = this.state
    if (pathname.split('/').filter(v => v)[0] === 'admin') {
      return this.getSelectedMenu_Old()
    } else {
      for (let i = 0; i < menus.length; i += 1) {
        // Jika nanti ada sub-sub-menu harus membuat fungsi rekursif
        if (menus[i].menus) {
          for (let j = 0; j < menus[i].menus.length; j += 1) {
            const currentUrl = this.getCurrentUrlMenu(pathname, menus[i].menus[j])
            if (currentUrl) return currentUrl
          }
        } else {
          const currentUrl = this.getCurrentUrlMenu(pathname, menus[i])
          if (currentUrl) return currentUrl
        }
      }
    }
    return null
  }

  getCurrentUrlMenu = (pathname, currentMenu) => {
    const paths = pathname.split('/').filter(v => v)
    const menuPaths = get(currentMenu, 'url', '').split('/').filter(v => v)
    // belum di handle jika menus[i].menus
    if (menuPaths.length) {
      let isEqual = true
      for (let j = 0; j < menuPaths.length; j += 1) {
        if (menuPaths[j] !== paths[j]) isEqual = false
      }
      if (isEqual) return currentMenu.url
    }
    return null
  }

  getSelectedMenu_Old = (maxLength = 3) => {
    const paths = get(this.props.location, 'pathname', '').split('/').filter(val => val)
    let path = ''
    for (let i = 0; i < paths.length; i += 1) {
      if (i < maxLength) {
        path += `/${paths[i]}`
      }
    }
    return path
  }

  // module must in first on pathname like "domain.com/module/app/model"
  getCurrentModule = () => {
    const pathname = get(this.props.location, 'pathname', '')
    return pathname.split('/').filter(val => val)[0]
  }

  getModuleName = pathname => {
    return pathname.split('/').filter(val => val)[0]
  }

  getCurrentMenu = (menus) => {
    if (menus) {
      return menus.map(val => (
        val.menus ? (
          <Menu.SubMenu
            key={val.label}
            title={(
              <span>
                <Icon type='plus-square' />
                <span>{val.label}</span>
              </span>
            )}
          >
            {val.menus.map(menu => menu.menus ? this.getCurrentMenu([menu]) : this.getMenuItem(menu))}
          </Menu.SubMenu>
        ) : (
          this.getMenuItem(val)
        )
      ))
    }
    return []
  }

  getMenuItem = (menuSetting) => (
    <Menu.Item key={menuSetting.url ? menuSetting.url : UrlsFe[menuSetting.url_name]}>
      <Link to={menuSetting.url ? menuSetting.url : (UrlsFe[menuSetting.url_name] || '/')}>
        {menuSetting.icon && <Icon type={menuSetting.icon} />}
        <span>{menuSetting.label}</span>
      </Link>
    </Menu.Item>
  )

  setThisState = (obj, callback = () => null) => {
    for (const key in obj) {
      if (obj[key] !== this.state[key]) {
        this.setState(obj, callback)
        return
      }
    }
    callback()
  }

  setMenu = currentModule => {
    let additionalCalendar = false
    const isSuperuser = get(this.props.userReducer, ['user', 'is_superuser'])
    const allowedModules = get(this.props.userReducer, ['user', 'allowed_modules'], [])
    const selectedHeader = HeaderMenu.find(val => (
      val.url === `/${currentModule}` &&
      (allowedModules.find(codename => codename === val.codename) || isSuperuser)
    ))
    if (selectedHeader) {
      // const modulePermission = allowedModules.find(codename => codename === selectedHeader.codename)
      const partPermissionCode = PermissionName.READ.codePart
      const permissions = get(this.props.userReducer, ['user', 'permissions'])
      if (currentModule === 'admin') {
        // Please request to API for menu admin, sementara di local dummy
        const tempNav = NavMenusV2[currentModule]
        const response = isSuperuser ? tempNav : []
        // Menggabungkan permission sets dari group-group (app) ~ yang mengandung "view" saja
        // for (let i = 0; i < modulePermission.groups.length; i += 1) {
        //   for (let j = 0; j < modulePermission.groups[i].sets.length; j += 1) {
        //     permissions.push(...modulePermission.groups[i].sets[j].permissions.filter(val => val.code.indexOf(partPermissionCode) !== -1))
        //   }
        // }
        if (!isSuperuser) {
          // Jika bukan superuser, mapping navigation with available permission Read
          // if (modulePermission) {
          //   for (let i = 0; i < modulePermission.sets.length; i += 1) {
          //     permissions.push(...modulePermission.sets[i].permissions.filter(val => val.code.indexOf(partPermissionCode) !== -1))
          //   }
          // }

          // permission code format: "appnamelowercase.permissioncode_modelname"
          for (let i = 0; i < tempNav.length; i += 1) {
            let appExist = false
            for (let j = 0; j < tempNav[i].menus.length; j += 1) {
              if (tempNav[i].menus[j].menus) {
                // Belum ada check permission
                if (!appExist) {
                  appExist = true
                  response.push({ label: tempNav[i].label, menus: [] })
                }
                response[response.length - 1].menus.push(tempNav[i].menus[j])
                continue
                // -------------------------
              }
              // modelname kami ambil dari splitted url_name by titik dua (:) index 3
              const urlNames = tempNav[i].menus[j].url_name.split(':')
              const appName = urlNames[2]
              const modelName = urlNames[3]
              const permissionCode = tempNav[i].menus[j].permissionCode || `${appName}.${partPermissionCode}_${modelName}`
              if (permissions.find(code => code === permissionCode)) {
                if (!appExist) {
                  appExist = true
                  // Insert app menu
                  response.push({ label: tempNav[i].label, menus: [] })
                }
                // Insert model menu
                response[response.length - 1].menus.push(tempNav[i].menus[j])
              }
            }
          }
        }

        this.setState({
          additionalCalendar,
          menus: response.length > 0 && [
            {
              label: 'Dashboard',
              url: `/${currentModule}/dashboard`,
              icon: 'dashboard',
            },
            ...response
          ]
        })
      } else {
        // get menu submodule from local
        const tempNav = NavMenusV2[currentModule]
        const menus = isSuperuser ? (tempNav || []) : []
        if (!isSuperuser && tempNav) {
          // Untuk menu submodule, harus menuliskan key "code" dengan value: "app.{}_model"
          for (let i = 0; i < tempNav.length; i += 1) {
            if (tempNav[i].code) {
              if (permissions.find(code => code === tempNav[i].code.replace('{}', partPermissionCode))) {
                menus.push(tempNav[i])
              }
            } else if (tempNav[i].menus) {
              let isGroupPushed = false
              for (const j in tempNav[i].menus) {
                if (tempNav[i].menus[j].code) {
                  if (permissions.find(code => code === tempNav[i].menus[j].code.replace('{}', partPermissionCode))) {
                    if (!isGroupPushed) {
                      menus.push({ ...tempNav[i], menus: [] })
                      isGroupPushed = true
                    }
                    menus[menus.length - 1].menus.push(tempNav[i].menus[j])
                  }
                }
              }
            }
            // for (let j = 0; j < modulePermission.sets.length; j += 1) {
            //   if (tempNav[i].code) {
            //     if (modulePermission.sets[j].permissions.find(permission => permission.code === tempNav[i].code.replace('{}', partPermissionCode))) {
            //       menus.push(tempNav[i])
            //       break
            //     }
            //   } else if (tempNav[i].menus) {
            //     let isGroupPushed = false
            //     for (let k = 0; k < tempNav[i].menus.length; k += 1) {
            //       if (tempNav[i].menus[k].code) {
            //         if (modulePermission.sets[j].permissions.find(permission => permission.code === tempNav[i].menus[k].code.replace('{}', partPermissionCode))) {
            //           if (!isGroupPushed) {
            //             menus.push({ ...tempNav[i], menus: [] })
            //             isGroupPushed = true
            //           }
            //           menus[menus.length - 1].menus.push(tempNav[i].menus[k])
            //         }
            //       }
            //     }
            //   }
            // }
          }
        }
        // Check visibility job due soon
        if (currentModule === 'job-management') {
          if (menus.findIndex(menu => menu.code === 'jobs.{}_master_job') !== -1) {
            additionalCalendar = true
          }
        }
        this.setState({ menus, additionalCalendar })
      }
    } else {
      this.setState({ menus: [], additionalCalendar })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const prevModule = this.getModuleName(this.props.location.pathname)
    const nextModule = this.getModuleName(nextProps.location.pathname)
    if (prevModule !== nextModule) {
      this.setMenu(nextModule)
    }
  }

  componentDidMount() {
    this.setMenu(this.getCurrentModule())
  }

  calendarShow=(props)=>{
    let content = <CalendarJDS userReducer={props.userReducer} />
    if(props.collapsed)
      content = <Popover content={content} placement="right">
            <center><Icon type="calendar" className="text-white hover-calendar"/></center>
          </Popover>
    return content
  }

  render() {
    return (
      <FixedSider
        width={300}
        breakpoint="lg"
        collapsible
        collapsed={this.props.collapsed}
        onCollapse={this.props.onCollapse}
      >
        <Row type='flex' justify='center' align='middle' className='app-logo'>
          <Link to='/'>
            <Avatar src={IbesLogo} shape="square" style={{width: '49px'}} size="large" />
          </Link>
          {!this.props.collapsed && <StyledTitle>Administrator</StyledTitle>}
        </Row>
        <StyledMenu
          theme="dark"
          mode="inline"
          // defaultSelectedKeys={["/admin"]}
          selectedKeys={[this.getSelectedMenu()]}
        >
          { this.getCurrentMenu(this.state.menus) }
        </StyledMenu>
        { this.state.additionalCalendar && this.calendarShow(this.props)}
      </FixedSider>
    );
  }
}

export default Navigation;

const FixedSider = styled(Sider)`
  overflow: auto;
  height: 100vh;
  position: fixed !important;
  left: 0;
  z-index: 10;
`;

const StyledMenu = styled(Menu)`
  padding-bottom: 44px !important;
`;

const StyledTitle = styled.span`
  color: rgba(255, 255, 255, 0.75);
  font-size: 24px;
  margin-left: 12px;
  font-weight: 500;
`;
