export default [
  {
    title: 'Template set',
    type: 'SelectQuery',
    required: true,
    data: 'templateSets',
    dataIndex: 'template_set',
    selectedKey: 'pk',
    selectedValue: 'name',
    initialValue: '',
    note_text: '',
    additional: 'edit|add',
    order: 0,
  }, {
    title: 'Label',
    type: 'text',
    required: true,
    dataIndex: 'label',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    order: 1,
  }, {
    title: 'Status',
    type: 'select',
    disableAllowClear: true,
    data: [
      {
        alias: 'active',
        value: 'Active - Anyone can use'
      }, {
        alias: 'inactive',
        value: 'Inactive - No one can use'
      }, {
        alias: 'unpublished',
        value: 'Unpublished - Only certain people can use'
      }
    ],
    dataIndex: 'status',
    initialValue: 'active',
    note_text: '',
    additional: '',
    order: 2,
  }, {
    title: 'Code',
    type: 'text',
    data: '',
    dataIndex: 'code',
    initialValue: '',
    note_text: '',
    additional: '',
    order: 3,
  }, {
    title: 'BM Subhead',
    type: 'text',
    data: '',
    dataIndex: 'bm_subhead',
    initialValue: '',
    note_text: '',
    additional: '',
    order: 4,
  }, {
    title: 'Prostix',
    type: 'text',
    data: '',
    dataIndex: 'prostix',
    initialValue: '',
    note_text: '',
    additional: '',
    order: 5,
  }, {
    title: 'PM Subhead',
    type: 'text',
    data: '',
    dataIndex: 'pm_subhead',
    initialValue: '',
    note_text: '',
    additional: '',
    order: 6,
  }, {
    title: 'Old Id',
    type: 'text',
    data: '',
    dataIndex: 'old_id',
    initialValue: null,
    note_text: '',
    additional: '',
    order: 7,
  }, {
    title: 'Sequence',
    type: 'text',
    data: '',
    dataIndex: 'sequence',
    initialValue: null,
    note_text: '',
    additional: '',
    order: 8,
  }, {
    title: 'MFG Subhead',
    type: 'text',
    data: '',
    dataIndex: 'mfg_subhead',
    initialValue: null,
    note_text: '',
    additional: '',
    order: 9,
  }, {
    title: 'Split Export',
    type: 'text',
    data: '',
    dataIndex: 'split_export',
    initialValue: null,
    note_text: '',
    additional: '',
    order: 10,
  }, {
    title: 'Special',
    type: 'text',
    data: '',
    dataIndex: 'special',
    initialValue: null,
    note_text: '',
    additional: '',
    order: 11,
  }, {
    title: 'Umbraco',
    type: 'text',
    data: '',
    dataIndex: 'umbraco',
    initialValue: '',
    note_text: '',
    additional: '',
    order: 12,
  }, {
    title: 'timber',
    type: 'checkbox',
    data: '',
    dataIndex: 'timber',
    initialValue: '',
    note_text: '',
    additional: '',
    order: 13,
  }, {
    title: 'Manufactured',
    type: 'checkbox',
    data: '',
    dataIndex: 'manufactured',
    initialValue: true,
    note_text: '',
    additional: '',
    order: 14,
  }
];
