import { BaseList, BaseDetail } from "App/Component/Bases";
import descriptor from "./descriptor";
import options from "./options";
import fields from "./fields";

const title = "Provinces";
const urlData = "provinces";
const bulkName = "name";

class List extends BaseList {
  constructor(props) {
    super(props)

    this.title = title;
    this.urlData = urlData;
    this.bulkName = bulkName;
    this.propsList = {
      descriptor,
      exportImport: options.exportImportOptions,
    }
  }
}

class Detail extends BaseDetail {
  constructor(props) {
    super(props)

    this.title = title
    this.urlData = urlData
    this.bulkName = bulkName
    this.fields = fields
    this.propsDetail = {
      urlKeyHistory: 'api:admin:geo:province:detail-revision',
      keyLabel: 'name',
    }
  }
}

export default { List, Detail };