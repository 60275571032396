import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Col, Row } from 'antd'

class SetItem extends React.PureComponent {
  onClick = () => {
    const { setItem, selected } = this.props
    this.props.onSelect(setItem.pk, !selected, setItem)
  }

  render() {
    const { setItem, selected } = this.props

    return (
      <div
        className='selection-row-item'
        onClick={this.onClick}
        style={{ borderBottom: '1px solid #e8e8e8', padding: '6px 0.5rem' }}
        title={setItem.name}
      >
        <Row type='flex' align='middle' style={{ width: '100%' }}>
          <Col style={{ width: '16px' }}>
            <Checkbox checked={selected} />
          </Col>
          <Col className='text-truncate' style={{ marginLeft: '8px', width: 'calc(100% - 16px - 8px)', whiteSpace: 'pre' }}>
            {setItem.name}
          </Col>
        </Row>
      </div>
    )
  }
}

SetItem.propTypes = {
  setItem: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  selected: PropTypes.bool,
}

SetItem.defaultProps = {
  onSelect: () => null,
  selected: false,
}

export default SetItem