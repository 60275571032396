import React from 'react'
import { ChartCard, MiniProgress, Field } from 'ant-design-pro/lib/Charts';
import Trend from 'ant-design-pro/lib/Trend';
import {Row, Col, Icon, Tooltip, Badge} from 'antd';
import moment from "moment";
import PropTypes from 'prop-types';

class CardStyle extends React.Component{
  render() {
    const {data, defaultprops} = this.props
    return(
      <Row>
        <Col span={12}>
          <ChartCard
            title={"Created at"}
            total={() => <span dangerouslySetInnerHTML={{ __html: moment(defaultprops.created).format('MM-DD-YYYY') }} />}
            footer={<Field label={"Created by : "} value={defaultprops.created_by.username} />}
            contentHeight={46}
          >
              <span>
                edited
                <Trend flag="up" style={{ marginLeft: 8, color: 'rgba(0,0,0,.85)' }}>
                  12%
                </Trend>
              </span>
              <span style={{ marginLeft: 16 }}>
                edited
                <Trend flag="down" style={{ marginLeft: 8, color: 'rgba(0,0,0,.85)' }}>
                  11%
                </Trend>
              </span>
          </ChartCard>
        </Col>
        <Col span={12}>
          <ChartCard
            style={{marginLeft: "5px"}}
            title="Calculate data used"
            action={
              <Tooltip title="percentage of data">
                <Icon type="info-circle-o" />
              </Tooltip>
            }
            total={data.calculate_data.used+"%"}
            footer={
              <div>
                <span>
                  Inactive <Badge status="error" text={data.calculate_data.inactive+"%"} />
                </span>
                <span style={{ marginLeft: 16 }}>
                  Active <Badge status="success" text={data.calculate_data.active+"%"} />
                </span>
              </div>
            }
            contentHeight={47}
          >
            <MiniProgress percent={78} strokeWidth={8} target={100} />
          </ChartCard>
        </Col>
      </Row>
    )
  }
}
CardStyle.propTypes = {
  data: PropTypes.object,
  name: PropTypes.string,
  defaultprops: PropTypes.object
}
export default CardStyle
