import React from 'react'
import PropTypes from 'prop-types'
import { Icon, Tabs } from 'antd'
import { get } from 'lodash'
import { Lib, RequestV2 as Request } from 'App/Utils'
import LoadingSpin from 'App/Component/LoadingSpin'
import PageError from 'App/Component/PageError'
import { PageGeneral, PageResult, PageHistory, PageErrors, PageRows } from './Pages'

const { TabPane } = Tabs

class SessionQueue extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true, // show loading only
      isReloading: false,
      queue: null,
    }
  }

  read = (keyLoading = 'isLoading', callback = () => null) => {
    this.setState({ [keyLoading]: true }, () => {
      Request(
        'get',
        'api:admin:exim:importqueue:detail',
        {},
        {},
        [this.props.queueId],
        this.readSuccess,
        this.readFailed,
        { keyLoading, callback }
      )
    })
  }

  readSuccess = (response, extra) => {
    this.setState({ [extra.keyLoading]: false, queue: response.data }, () => {
      extra.callback(true, response)
    })
  }

  readFailed = (error, extra) => {
    this.setState({ [extra.keyLoading]: false, errorResponse: get(error, 'response') }, () => {
      extra.callback(false, error)
    })
  }

  reload = (keyLoading = 'isReloading', callback = () => null) => {
    this.read(keyLoading, () => callback(true)) // supaya tidak menampilkan pesan error di PageError jadi dikirim true
  }

  componentDidMount() {
    this.read()
  }

  render() {
    const { visible } = this.props
    const { isLoading, errorResponse, queue } = this.state

    if (isLoading) {
      return <LoadingSpin height='200px' tip='Loading...' />
    }

    return queue ? (
      <div style={{ display: visible ? 'block' : 'none' }}>
        <Tabs className='custom-margin-tab' tabBarGutter={0}>
          <TabPane key='general' tab={<span><Icon type='read' />General</span>}>
            <PageGeneral queue={queue} />
          </TabPane>
          <TabPane key='result' tab={<span><Icon type='container' />Result</span>}>
            <PageResult queue={queue} />
          </TabPane>
          <TabPane key='history' tab={<span><Icon type='history' />History</span>}>
            <PageHistory queueId={queue.pk} />
          </TabPane>
          <TabPane key='errors' tab={<span><Icon type='exception' />Errors</span>}>
            <PageErrors queueId={queue.pk} />
          </TabPane>
          <TabPane key='rows' tab={<span><Icon type='table' />Rows</span>}>
            <PageRows queueId={queue.pk} />
          </TabPane>
        </Tabs>
      </div>
    ) : (
      <PageError errorResponse={errorResponse} onReload={this.reload} />
    )
  }
}

SessionQueue.propTypes = {
  queueId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  visible: PropTypes.bool,
}

SessionQueue.defaultProps = {
  visible: false,
}

export default SessionQueue