export default [
  {
    key: 'exc_class',
    label: 'Exc Class',
  }, {
    key: 'wrapper_class',
    label: 'Wrapper Class',
  }, {
    key: 'message',
    label: 'Message',
    type: 'textarea',
  }, {
    key: 'data',
    label: 'Data',
    type: 'json',
  }, {
    key: 'error_trace',
    label: 'Error Trace',
    type: 'textarea',
    maxWidth: '100%',
  },
]