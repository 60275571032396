
import React from "react";
import { Form } from "antd";

const EditableContext = React.createContext();

class EditableRowV2 extends React.Component{
  constructor(props){
    super(props);
    // this.state={
    //   dummy:false
    // }
    // this.form = props.form
    // this.reRender = this.reRender.bind(this)
    // this.contexValue = {form : props.form}
  }
  // reRender(){
  //   this.setState({dummy:!this.state.dummy})
  // }
  componentDidMount() {
    const {overrideform, form} = this.props
    overrideform(form,{row_key:this.props["data-row-key"]})
  }

  render() {
    const { form, index, ...props } = this.props
      return (
        <EditableContext.Provider value={form}>
          <tr {...props} overrideform={0} />
        </EditableContext.Provider>
      )
  }

}

const EditableFormRow = Form.create()(EditableRowV2);

export {EditableContext, EditableFormRow}
