import { BaseList } from "App/Screens/SubModules/Component";
import descriptor from "./descriptorOffline";

class KeywordList extends BaseList {
  constructor(props) {
    super(props)

    this.appName = 'expert_system'
    this.modelName = 'keyword'
    this.propsList = {
      avatar: 'subsections[0].identifier',
      pageTitle: 'Keyword',
      descriptor,
      masterKey: 'module-expertSystem-keyword',
    }
  }
}

export default KeywordList