const descriptorOffline = {
  "app_label": "reports",
  "model_name": "merchantmappingmode",
  "pagination": true,
  "columns": [
    {
      "name": "pk",
      "label": "ID",
      "help_text": null,
      "hidden": true,
      "identifier": false,
      "data_type": "int",
      "search": false,
      "sort": false
    },
    {
      "name": "name",
      "label": "Name",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "ellipsis": true,
      "search": true,
      "sort": true
    },
    {
      "name": "modified",
      "label": "Modified",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "datetime",
      "search": false,
      "sort": true
    }
  ],
  "filter_by": [],
  "export_import": {
    "export": {
      "directly": true,
      "url_name": "api:admin:reports:merchantmappingmode:export",
      "filter_by": []
    },
    "import": {
      "url_name": "api:admin:estimation:exportimportsession:import",
      "allow_initial_data": false,
      "fields": [],
      "example_format_string": ""
    }
  }
}

export default descriptorOffline