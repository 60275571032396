export default [
  {
    title: 'Line template',
    type: 'SelectQuery',
    data: 'lineTemplates',
    required: true,
    initialValue: undefined,
    note_text: '',
    selectedKey: 'pk',
    // selectedValue: 'label',
    selectedValue: [['subsection', 'identifier'], 'label'],
    separator: ' - ',
    additional: 'edit|add',
    dataIndex: 'line_template',
    dropdownClassName: 'width-auto2',
    // foreignKey: 'subsection',
    filters: [
      {
        title: 'Section',
        dataIndex: 'section',
        urlKey: 'sections-autocomplete',
        // type: 'SelectQuery',
        selectedKey: 'pk',
        selectedValue: 'name',
        relations: ['subsection', 'line_template'], // for clear fields when onChange
      },
      {
        title: 'Subsection',
        dataIndex: 'subsection',
        urlKey: 'subsections-autocomplete',
        // type: 'SelectQuery',
        selectedKey: 'pk',
        selectedValue: 'name',
        foreignKeys: ['section'],
        relations: ['line_template'], // for clear fields when onChange
      },
    ],
  },
  {
    title: 'Subheading',
    type: 'SelectQuery',
    // required: true,
    data: 'subheadings',
    initialValue: undefined,
    selectedKey: 'pk',
    selectedValue: 'label',
    additional: 'edit|add',
    dataIndex: 'subheading',
  },
  {
    title: 'Usage',
    type: 'SelectQuery',
    // required: true,
    data: 'lineUsages',
    initialValue: undefined,
    note_text: '',
    selectedKey: 'pk',
    selectedValue: 'label',
    additional: 'edit|add',
    dataIndex: 'usage',
  },
  {
    title: 'Group',
    type: 'text',
    data: '',
    initialValue: '',
    note_text: '(legacy for imported data, was SH_Set)',
    additional: '',
    dataIndex: 'group',
    order: 2,
  }, {
    title: 'Old subheading id',
    type: 'text',
    data: '',
    initialValue: '',
    note_text: '(legacy for imported data)',
    additional: '',
    dataIndex: 'old_subheading_id',
    order: 3,
  }, {
    title: 'Old sequence',
    type: 'text',
    data: '',
    initialValue: '',
    note_text: '(legacy for imported data)',
    additional: '',
    dataIndex: 'old_sequence',
    order: 4,
  }, {
    title: 'Status',
    type: 'select',
    disableAllowClear: true,
    data: [
      {
        alias: 'active',
        value: 'Active - Anyone can use'
      }, {
        alias: 'inactive',
        value: 'Inactive - No one can use'
      }, {
        alias: 'unpublished',
        value: 'Unpublished - Only certain people can use'
      }
    ],
    initialValue: 'active',
    note_text: '',
    additional: '',
    dataIndex: 'status',
    order: 5,
  }, {
    title: 'import_session',
    type: 'text',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'import_session',
    order: 6,
  },
]