export default [
  {
    title: 'Label',
    type: 'text',
    required: true,
    dataIndex: 'label',
    data: '',
    initialValue: '',
    note_text: 'Usually the pitch angle, in degrees. e.g. \'12.5\'',
    additional: '',
    order: 0,
  }, {
    title: 'Factor',
    type: 'number',
    required: true,
    dataIndex: 'factor',
    data: '',
    initialValue: '',
    note_text: 'The number to be multiplied with the dimensions subtotal',
    additional: '',
    order: 1,
    step: 0.001,
  },
]