import React from "react";
import PropTypes from "prop-types";
import { Row, Spin, Icon } from "antd";
import { get } from "lodash";
import { Lib, CheckPermission } from "App/Utils";
import SubAllList from "App/Component/SubAllList";
import descriptor from "./descriptorOffline";

class PageMerchantGroup extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isGettingPermissions: true,
      permissionItems: [],
    }
  }

  getPermissions = () => {
    const permissionItems = CheckPermission.getPermissionSet({
      userReducer: this.props.userReducer,
      appName: 'jobs',
      modelName: 'merchantgroup',
    }).filter(perm => !perm.includes('.delete_'))
    this.setState({ isGettingPermissions: false, permissionItems })
  }

  onAddMerchantGroup = () => {
    const { mappingMode } = this.props
    const historyState = {
      dataIdentifier: {
        mapping_mode: { pk: mappingMode.pk, name: mappingMode.name }
      }
    }
    Lib.historyAction(this.props.history, '/merchant/group/add', historyState).push()
  }

  onDetailMerchantGroup = (id) => {
    Lib.historyAction(this.props.history, `/merchant/group/${id}`).push()
  }

  componentDidMount() {
    this.getPermissions()
  }

  componentWillUnmount() {
    Lib.windowStop();
  }

  render() {
    const { userReducer, match } = this.props
    const { isGettingPermissions, permissionItems } = this.state

    return isGettingPermissions ? (
      <Row type='flex' justify='center' align='middle' style={{ margin: '30vh 0' }}>
        <Spin
          size='large'
          tip='Loading...'
          indicator={<Icon spin type='loading' style={{ fontSize: '4rem' }} />}
        />
      </Row>
    ) : (
      <SubAllList
        permissions={permissionItems}
        pageTitle="Merchant Group"
        avatar="name"
        descriptor={descriptor}
        masterKey="module-merchant-mappingMode-refGroup"
        args={[get(match, 'params.id')]}
        onAddClick={this.onAddMerchantGroup}
        onDetailClick={this.onDetailMerchantGroup}
        userReducer={userReducer}
        groupByOptions={[]}
      />
    );
  }
}

PageMerchantGroup.propTypes = {
  userReducer: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  mappingMode: PropTypes.object.isRequired, // because this class is for reference, then this line required
}

PageMerchantGroup.defaultProps = {
  
}

export default PageMerchantGroup;
