import React from "react";
import PropTypes from "prop-types";
import { Row, Spin, Icon } from "antd";
import { get } from "lodash";
import { Lib, CheckPermission } from "App/Utils";
import SubAllList from "App/Component/SubAllList";
import descriptor from "./descriptorOffline";

class PageSkuMapping extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isGettingPermissions: true,
      permissionItems: [],
    }
  }

  getPermissions = () => {
    const permissionItems = CheckPermission.getPermissionSet({
      userReducer: this.props.userReducer,
      appName: 'reports',
      modelName: 'skumapping',
    })
    this.setState({ isGettingPermissions: false, permissionItems })
  }

  onAddSkuMapping = () => {
    const merchantGroupId = get(this.props.match, ['params', 'id'])
    Lib.historyAction(this.props.history, `/merchant/merchant/${merchantGroupId}/sku-mapping/add`).push()
  }

  onDetailSkuMapping = (id) => {
    const merchantGroupId = get(this.props.match, ['params', 'id'])
    Lib.historyAction(this.props.history, `/merchant/merchant/${merchantGroupId}/sku-mapping/${id}`).push()
  }

  componentDidMount() {
    this.getPermissions()
  }

  componentWillUnmount() {
    Lib.windowStop();
  }

  render() {
    const { userReducer, match } = this.props
    const { isGettingPermissions, permissionItems } = this.state

    return isGettingPermissions ? (
      <Row type='flex' justify='center' align='middle' style={{ margin: '30vh 0' }}>
        <Spin
          size='large'
          tip='Loading...'
          indicator={<Icon spin type='loading' style={{ fontSize: '4rem' }} />}
        />
      </Row>
    ) : (
      <SubAllList
        permissions={permissionItems}
        pageTitle="SKU Mapping"
        avatar="product_code"
        descriptor={descriptor}
        masterKey="module-merchant-merchant-refSkuMapping"
        args={[get(match, 'params.id')]}
        onAddClick={this.onAddSkuMapping}
        onDetailClick={this.onDetailSkuMapping}
        userReducer={userReducer}
      />
    );
  }
}

PageSkuMapping.propTypes = {
  userReducer: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  merchant: PropTypes.object.isRequired,
}

PageSkuMapping.defaultProps = {
  permissionItems: [],
}

export default PageSkuMapping;
