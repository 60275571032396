import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Row, Col, Result, Button } from 'antd'

const STATUSES = ['403', '404', '500']

export default class PageUnauthorized extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      status: this.getStatus(props.status)
    }
  }

  getStatus = (strStatus) => {
    if (STATUSES.find(status => status === strStatus)) {
      return strStatus
    }
    return '403'
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.status !== nextProps.status) {
      this.setState({ status: this.getStatus(nextProps.status) })
    }
  }

  render() {
    const { title, subTitle, extra, urlTarget, history } = this.props
    const { status } = this.state

    return (
      <Result
        status={status}
        title={title}
        subTitle={subTitle}
        extra={
          extra || (
            <Row type='flex' justify='center' gutter={[12, 12]}>
              {history && (
                <Col>
                  <Button icon='arrow-left' onClick={() => history.goBack()}>
                    Go Back
                  </Button>
                </Col>
              )}
              <Col>
                <Link to={urlTarget}>
                  <Button type='primary'>Back Home</Button>
                </Link>
              </Col>
            </Row>
          )
        }
      />
    )
  }
}

PageUnauthorized.propTypes = {
  status: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  urlTarget: PropTypes.string,
  extra: PropTypes.node,
  history: PropTypes.object,
}

PageUnauthorized.defaultProps = {
  status: '403',
  title: 'UNAUTHORIZED',
  subTitle: 'You cannot access this page, because the admin does not give you permission.',
  urlTarget: '/',
  extra: null,
  history: null,
}