import React from 'react'
import {Drawer, Row, Form, Button, Card, Col, Radio, Select, Checkbox, Switch, Icon} from "antd";
import PropTypes from "prop-types";
import Lib from 'App/Utils/Lib'
import {get, isArray} from 'lodash'
import {FormContext, FormProvider} from "../Context/ContextForm";
import SelectQuery from 'App/Component/SelectQuery';
const FormItem = Form.Item
const { Option } = Select

class AdvancedFilter extends React.Component{
  state = {
    animatedEnd: false
  }

  multipleValidator = (rule, value, callback) => {
    if (value && value.length > 0) {
      const { minSelected, maxSelected } = this.props
      if (minSelected && minSelected > value.length) {
        callback(true)
        return
      }
      if (maxSelected && maxSelected < value.length) {
        callback(true)
        return
      }
    }
    callback()
  }

  handleChange = (e, name, filter) => {
    // make sure foreign key is reloading with new parameter, when relates is changed
    for (let i = 0; i < this.props.filter.length; i += 1) {
      if (this.props.filter[i].relates) {
        for (let j = 0; j < this.props.filter[i].relates.length; j += 1) {
          if (this.props.filter[i].relates[j] === name) {
            if (this[`_${this.props.filter[i].name}`]) {
              this[`_${this.props.filter[i].name}`].reload()
            }
            break
          }
        }
      }
    }
    // end ---
    this.props.handleChange(e, name, filter)
  }

  getDisplay=(data, getFieldDecorator)=>{
    const {handleChange, userReducer, getFilterParams} = this.props
    const filterParams = getFilterParams()
    let param // default is undefined
    const paramProps = { ...data.paramProps }
    if(filterParams) {
      let obj = JSON.parse(filterParams).find(e => e.name === data.name)
      if (obj) param = obj.data
      if (data.relates) {
        for (let i = 0; i < data.relates.length; i += 1) {
          const localRelatedData = JSON.parse(filterParams).find(filterData => filterData.name === data.relates[i])
          const relatedDatas = get(localRelatedData, 'data', [])
          // karena SelectQuery mode=multiple, maka loop
          for (let j = 0; j < relatedDatas.length; j += 1) {
            if (paramProps[data.relates[i]]) paramProps[data.relates[i]] += `|${relatedDatas[j].key}`
            else paramProps[data.relates[i]] = `${relatedDatas[j].key}`
          }
        }
      }
    }
      if(data.api) {
        return (
          <Card className="mt-2">
          <FormItem label={data.label}>
            {getFieldDecorator(Lib.decoratorName(data.name), {
              rules: [{
                required: false,
                message: `Please Input ${data.label}!`
              },{
                validator: this.multipleValidator,
              }],
              initialValue: param || undefined, // must be passing with undefined or object
            })(
            <SelectQuery
              ref={ref => {this[`_${data.name}`] = ref}}
              urlKey={data.api.url_name}
              userReducer={userReducer}
              // mode="tags"
              mode="multiple"
              showSearch
              suffixIcon={<Icon type="caret-down"/>}
              valKey={data.api.key}
              valLabel={data.api.display}
              placeholder='Please type to search'
              style={{maxWidth: '71%', marginRight: '10px'}}
              onChange={(e) => this.handleChange(e, data.name, )}
              paramProps={paramProps}
            />
          )}
          </FormItem>
          </Card>
        )
      }
      else if(data.choices) {
        return (
          <Card className="mt-2">
          <Checkbox.Group
            name={data.name}
            style={{width: '100%'}}
            onChange={(e) => handleChange(e, data.name, data)}
            defaultValue={param}
          >
            <Row>
              <Col span={24} className="mb-2">
                <span style={{fontSize: "15px"}} >{data.label}
                </span>
              </Col>
              {data.choices.map((value, pk) => {
                return (
                  <Col key={pk} span={24}>
                    <Checkbox
                      key={value[0]}
                      value={value[0]}>
                      {get(value, '[1]', value[0])}
                    </Checkbox>
                  </Col>)
              })}
            </Row>
          </Checkbox.Group>
          </Card>
        )
      }else if(data.switch || data.type === 'switch'){
        if (isArray(param) || param === undefined) param = ''
        return(
          <Card className="mt-2">
            <Row>
              <Col span={24} className="mb-2">
                  <span style={{fontSize: "15px"}} >
                    {data.label}
                  </span>
              </Col>
              <Col>
                {/*<Switch*/}
                {/*  onChange={(e) => handleChange(e, data.name)}*/}
                {/*  defaultChecked={param}*/}
                {/*  checkedChildren={<Icon type="check" />}*/}
                {/*  unCheckedChildren={<Icon type="close" />}*/}
                {/*/>*/}
                <Radio.Group onChange={(e)=>handleChange(e.target.value, data.name)} buttonStyle="solid" defaultValue={param}>
                  <Radio.Button value="">All</Radio.Button>
                  <Radio.Button value="true">Yes</Radio.Button>
                  <Radio.Button value="false">No</Radio.Button>
                </Radio.Group>
              </Col>
            </Row>
          </Card>
        )
      } else if (data.singleChoices) {
        // single choice
        return (
          <Card className='mt-2'>
              <Row className='mb-2' style={{ fontSize: '15px' }}>
                {data.label}
              </Row>
              <Select defaultValue={param || get(data.singleChoices, [0, 0])} onChange={val => handleChange(val, data.name)}>
                {data.singleChoices.map(value => (
                  <Option key={value[0]} value={value[0]}>
                    {value[1]}
                  </Option>
                ))}
              </Select>
          </Card>
        )
      } else if (data.type === 'text') {
        return (
          <Card className='mt-2'>
            <Row className='mb-2'>
              {data.label} :
            </Row>
            <Select
              mode='tags'
              tokenSeparators={[',']}
              defaultValue={param}
              onChange={(value) => handleChange(value, data.name)}
            />
          </Card>
        )
      }
  }

  renderConfig(getFieldDecorator){
    const {filter} = this.props
    if(!filter)
      return null
      const config = filter.map((data, key)=>{
      return(
        <div key={key}>
          {this.getDisplay(data, getFieldDecorator)}
      </div>
      )
    })
    return config
  }

  render() {
    const {visibledrawer, onChildrenDrawerClose, setonfilter, onfilter} = this.props
    return (
      <Drawer visible={visibledrawer} title="Advance Filter" width={500} closable={false} onClose={onChildrenDrawerClose} bodyStyle={{ marginBottom: '52px' }}>
        <FormProvider>
          <Form>
          <FormContext.Consumer>
            {form =>
              {
                return this.renderConfig(form.getFieldDecorator)
              }
            }
          </FormContext.Consumer>
        <div
          style={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }}
        >
          <Row type='flex' justify='space-between' gutter={[12, 12]}>
            <Col style={{ display: 'flex', alignItems: 'center' }}>
              <Switch
                unCheckedChildren="Off"
                checkedChildren="On"
                checked={onfilter}
                onChange={setonfilter}
              />
            </Col>
            <Col>
              <Button onClick={onChildrenDrawerClose} type="primary">
                Close
              </Button>
            </Col>
          </Row>
        </div>
          </Form>
        </FormProvider>
      </Drawer>
    )
  }
}
AdvancedFilter.propTypes = {
  visibledrawer: PropTypes.bool,
  onChildrenDrawerClose: PropTypes.func,
  setonfilter: PropTypes.func,
  onfilter: PropTypes.bool
};
AdvancedFilter.defaultProps = {
  visibledrawer: false,
  onfilter: false
}
export default AdvancedFilter;
