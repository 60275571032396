export default {
  "app_label": "estimation",
  "model_name": "prefillset",
  "pagination": true,
  "columns": [
    {
      "name": "pk",
      "label": "ID",
      "help_text": null,
      "hidden": true,
      "identifier": true,
      "data_type": "int",
      "search": false,
      "sort": false
    },
    {
      "name": "name",
      "label": "Name",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "ellipsis": true,
      "search": true,
      "sort": true
    },
    {
      "name": "subsection",
      "label": "Subsection",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": null,
      "child_path": "identifier",
      "search": true,
      "sort": true
    },
    {
      "name": "status",
      "label": "Status",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "search": true,
      "sort": true
    },
    {
      "name": "relation",
      "label": "Relation",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      // "search": true,
      "sort": true
    },
    {
      "name": "order",
      "label": "Order",
      "help_text": null,
      "hidden": true,
      "identifier": false,
      "data_type": "int",
      "search": false,
      "sort": false
    },
    {
      "name": "modified",
      "label": "Modified",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "datetime",
      "search": false,
      "sort": true
    }
  ],
  "filter_by": [
    {
      "label": "Status",
      "name": "status",
      "type": "choice",
      "choices": [
        [
          "active",
          "Active - Anyone can use"
        ],
        [
          "inactive",
          "Inactive - No one can use"
        ],
        [
          "unpublished",
          "Unpublished - Only certain people can use"
        ]
      ]
    },
    {
      "label": "Section",
      "name": "section",
      "type": "api_reference",
      "api": {
        "url_name": "api:admin:estimation:section:autocomplete",
        "key": "pk",
        "display": "name",
        "pagination": true
      }
    },
    {
      "label": "Subsection",
      "name": "subsection",
      "type": "api_reference",
      relates: ['section'],
      "api": {
        "url_name": "api:admin:estimation:subsection:autocomplete",
        "key": "pk",
        "display": "identifier",
        "pagination": true
      }
    },
    {
      "label": "Group",
      "name": "group",
      "type": "api_reference",
      "api": {
        "url_name": "api:admin:estimation:prefillsetgroup:autocomplete",
        "key": "pk",
        "display": "name",
        "pagination": true
      }
    },
    {
      "label": "Lines Product Status",
      "name": "product_status",
      "allowEmpty": false,
      "delimiter": ",",
      "type": "choice",
      "choices": [
        ["active", "Active"],
        ["inactive", "Inactive"],
        ["unpublished", "Unpublished"],
      ],
    },
  ],
  "export_import": null
}