import React from 'react'
import { Button, Col, Dropdown, Icon, Input, message, Switch, Form, Menu, Row } from 'antd'
import { get, isEmpty } from 'lodash'
import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import PromptContainer from 'App/Component/PromptContainer'
import { FormContext, FormProvider } from 'App/Component/Context/ContextForm'
import DynamicField from 'App/Component/DynamicField'
import ReceiverField from './ReceiverField'
import fields from './fields'
import { Lib } from 'App/Utils'
import 'App/Component/Singlepage/SingleStyle.css'

const configEditor = {
  toolbar: ['heading', '|', 'bold', 'italic', 'link', '|', 'bulletedList', 'numberedList', '|', 'blockQuote', '|', 'undo', 'redo'],
  placeholder: 'Write messages here...',
}

class Footer extends React.PureComponent {
  constructor(props) {
    super(props)

    const sentLabel = props.isSent ? 'Resend' : 'Send'
    this.saveOptions = [
      { key: '1', label: `Save and ${sentLabel}`, body: { send: true } },
      { key: '2', label: 'Save' },
    ]
    this.state = {
      selectedSaveOption: this.saveOptions[0],
      isSaving: false,
    }
  }

  saveMenu = () => {
    return (
      <Menu onClick={this.onSaveOptionChange}>
        {this.saveOptions.map(val => (
           <Menu.Item key={val.key}>
             {val.label}
           </Menu.Item>   
        ))}
      </Menu>
    )
  }

  onSaveOptionChange = ({ key }) => {
    const selectedSaveOption = this.saveOptions.find(option => option.key === key)
    this.setState({ selectedSaveOption })
  }

  onSave = () => {
    this.setState({ isSaving: true }, () => {
      this.props.onSave(this.state.selectedSaveOption.body, () => {
        this.setState({ isSaving: false })
      })
    })
  }

  render() {
    const { showDelete, allowSave } = this.props
    const { isSaving, selectedSaveOption } = this.state

    return (
      <div style={{ borderTop: '1px solid #e8e8e8', padding: '10px 16px', backgroundColor: '#fff' }}>
        <Row type='flex' justify='space-between'>
          <Col>
            {
              showDelete && false && ( // belum
                <Button type='danger' icon='delete' onClick={this.handleDelete}>Delete</Button>
              )
            }
          </Col>
          <Col>
            <Row type='flex' gutter={[12, 0]}>
              {!allowSave && (
                <Col>
                  <Row type='flex' align='middle' style={{ height: '100%' }}>
                    <Col style={{ fontWeight: 500, color: 'rgba(255, 0, 0, 0.6)' }}>
                      Cannot edit when status is Sending.
                      Click Refresh button to get current status
                    </Col>
                  </Row>
                </Col>
              )}
              <Col>
                <Button onClick={this.props.onClose}>Close</Button>
              </Col>
              <Col>
                <Dropdown.Button
                  type='primary'
                  disabled={!allowSave || isSaving}
                  placement='topRight'
                  onClick={this.onSave}
                  overlay={this.saveMenu}
                >
                  <Icon type={isSaving ? 'loading' : 'save'} /> {selectedSaveOption.label}
                </Dropdown.Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    )
  }
}

class PageGeneral extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      editorIsBlank: false,
      sizeReceiver: get(props.data, 'receiver_type', 'all') === 'all' ? 24 : 12,
    }
  }

  onRefReceiver = (ref) => {
    this._receiver = ref
  }

  onChangeSizeReceiver = (receiver_type) => {
    const sizeReceiver = receiver_type === 'all' ? 24 : 12
    this.setState({ sizeReceiver })
  }

  onSave = (anotherBody, callback = () => null) => {
    const receiverValues = this._receiver.validateFields()
    if (!receiverValues) { callback(); return; }

    this.form.validateFields((error, values) => {
      if (error) { callback(); return; }
      
      const body = {
        title: values.title,
        force_popup: values.force_popup,
        message: this.activeEditor.getData(),
        ...anotherBody,
        ...receiverValues,
      }
      if (!body.message) {
        callback()
        this.setState({ editorIsBlank: true })
        return
      }

      this.props.onSave(body, (isSuccess, response) => {
        if (isSuccess) {
          message.success('Notification has been saved')
        } else {
          Lib.errorMessageHandler(response)
          this.setFieldsError(response, values)
        }
        callback()
      })
    })
  }

  setFieldsError = (errorResponse, valuesField) => {
    const errorData = get(errorResponse, 'data')
    if (typeof errorData === 'object') {
      const fieldsError = {}
      for (const key in errorData) {
        if (valuesField.hasOwnProperty(key)) {
          fieldsError[key] = {
            value: valuesField[key],
            errors: [new Error(errorData[key])],
          }
        }
      }
      if (!isEmpty(fieldsError)) this.form.setFields(fieldsError)
      if (fieldsError.message) this.setState({ editorIsBlank: true })
    }
  }

  render() {
    const { data, onGoBack } = this.props
    const { editorIsBlank, sizeReceiver } = this.state

    return (
      <div>
        {/* <PromptContainer ref={ref => {this._prompt = ref}} /> */}
        <Row>
          <Col>
            <div>
              <FormProvider>
                <Form layout="horizontal">
                  {
                    fields.map((elem) => {
                      const dataRecord = get(data, elem.dataIndex, elem.initialValue)
                      return (
                        <DynamicField
                          {...elem}
                          key={elem.dataIndex}
                          // {...this.state.operation}
                          dataRecord={dataRecord}
                          onBlur={this.onFieldBlur}
                        />
                      )
                    })
                  }
                  <FormContext.Consumer>
                    {form => {this.form = form}}
                  </FormContext.Consumer>
                </Form>
              </FormProvider>
            </div>
            <div className='my-2 px-4' style={{ display: 'inline-block', width: '100%' }}>
              <Row type='flex' gutter={[12, 12]}>
                <Col xs={24} sm={24} xl={sizeReceiver}>
                  <ReceiverField ref={this.onRefReceiver} data={data} onChangeSizeReceiver={this.onChangeSizeReceiver} />
                </Col>
                <Col className='custom-fixed-ckeditor' xs={24} sm={24} xl={sizeReceiver}>
                  {/* <div className='my-2 px-4' style={{ display: 'inline-block', width: '100%', minHeight: '150px' }}> */}
                    <Row type='flex' align='middle' className='mb-2' style={{ height: '32px', width: '100%' }}>
                      <span style={{ color: '#f5222d', display: 'inline-block', marginRight: '4px', fontFamily: 'SimSun, sans-serif' }}>*</span>
                      Message :
                      {editorIsBlank && (
                        <span className='ml-2' style={{ color: '#f5222d', display: 'inline-block' }}>
                          This field is required!
                        </span>
                      )}
                    </Row>
                    <CKEditor
                      editor={ClassicEditor}
                      config={configEditor}
                      data={get(data, 'message', '')}
                      onInit={editor => {
                        this.activeEditor = editor
                        editor.ui.view.editable.element.spellcheck = false
                        editor.editing.view.change( writer => {
                          writer.setAttribute( 'spellcheck', 'false', editor.editing.view.document.getRoot() );
                        } );
                      }}
                      onChange={(event, editor) => {
                        // if (this._prompt) this._prompt.setTouched(true)
                        let isBlank = !editor.getData()
                        if (this.state.editorIsBlank !== isBlank) {
                          // const height = isBlank ? '100px' : '300px'
                          this.setState({
                            // height,
                            editorIsBlank: isBlank,
                          })
                        }
                      }}
                    />
                  {/* </div> */}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Footer
          allowSave={get(data, 'state') !== 'sending'}
          // showDelete={!isAddMode && this.allowDelete}
          onSave={this.onSave}
          onClose={onGoBack}
          isSent={get(data, 'state') === 'sent'}
          // operation={this.state.operation}
        />
      </div>
    )
  }
}

export default PageGeneral