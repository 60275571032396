import React from 'react'
import { Button, Col, Icon, Row, Select, Spin } from 'antd'
import AnimateHeight from 'react-animate-height'
import { RequestV2 as Request } from 'App/Utils'
import MostUsedProduct from './MostUsedProduct'
import ProductStat from './ProductStat'

const { Option } = Select

class ProductTopUsage extends React.PureComponent {
  constructor(props) {
    super(props)

    this.limit_days = '30d'
    this.paramKeys = ['limit_days']
    this.state = {
      collapsed: false,
      isLoading: true,
      isReloading: false,
      products: [],
    }
  }

  getObjParam = () => {
    const objParam = {}
    for (let i = 0; i < this.paramKeys.length; i += 1) {
      const key = this.paramKeys[i]
      objParam[key] = this[key]
    }
    return objParam
  }

  read = (keyLoading = 'isLoading') => {
    if (typeof keyLoading !== 'string') keyLoading = 'isLoading'

    this.setState({ [keyLoading]: true, selectedProduct: null }, () => {
      Request(
        'get',
        'module-product-mostUsed',
        {},
        this.getObjParam(),
        [],
        this.readSuccess,
        this.readFailed,
        { keyLoading }
      )
    })
  }

  readSuccess = (response, extra) => {
    this.setState({
      [extra.keyLoading]: false,
      products: response.data,
    })
  }

  readFailed = (error, extra) => {
    this.setState({ [extra.keyLoading]: false })
  }

  onDayChange = (limitDays) => {
    this.limit_days = limitDays
    this.read()
  }

  onSelectItem = (productId) => {
    this.setState({ selectedProduct: productId })
  }

  onCollapse = () => {
    this.setState({ collapsed: !this.state.collapsed })
  }

  componentDidMount() {
    this.read()
  }

  render() {
    const { isLoading } = this.state

    return (
      <Spin spinning={isLoading}>
        <Col className='p-2'>
          <Row type='flex' justify='space-between'>
            <Col>
              <h2 style={{ padding: '0 8px' }}>Most Used Product</h2>
            {/* <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}> */}
              {/* <Row type='flex'>
                <Col span={8}>
                  <Button disabled className='group-left' style={{ width: '100%', padding: '0 7px' }}>
                    Show:
                  </Button>
                </Col>
                <Col span={16}>
                  <Select
                    defaultValue={this.limit_days}
                    className='group-right'
                    style={{ width: '100%', marginLeft: '-1px' }}
                    onChange={this.onDayChange}
                  >
                    <Option value='7d'>7 Days</Option>
                    <Option value='30d'>30 Days</Option>
                    <Option value='60d'>60 Days</Option>
                    <Option value='90d'>90 Days</Option>
                  </Select>
                </Col>
              </Row> */}
            </Col>
            <Col>
              <Row type='flex' gutter={[12, 12]}>
                <Col>
                  <Button loading={isLoading} icon='reload' onClick={this.read}>
                    Refresh
                  </Button>
                </Col>
                <Col>
                  <Row type='flex'>
                    <Col>
                      <Button disabled className='group-left' style={{ width: '100px', padding: '0 7px' }}>
                        Show:
                      </Button>
                    </Col>
                    <Col>
                      <Select
                        defaultValue={this.limit_days}
                        className='group-right'
                        style={{ width: '240px', marginLeft: '-1px' }}
                        onChange={this.onDayChange}
                      >
                        <Option value='30d'>30 Days</Option>
                        <Option value='60d'>60 Days</Option>
                        <Option value='90d'>90 Days</Option>
                      </Select>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {/* <Button loading={isLoading} icon='reload' onClick={this.read}>
                Refresh
              </Button> */}
            </Col>
          </Row>
            <AnimateHeight
              className='mt-2 product-stat'
              duration={1000}
              height={this.state.collapsed ? 0 : 'auto'}
            >
              <Row type='flex' gutter={[12, 12]}>
                <Col xs={24} sm={24} xl={10}>
                  <MostUsedProduct
                    products={this.state.products}
                    selectedProduct={this.state.selectedProduct}
                    onSelectItem={this.onSelectItem}
                  />
                </Col>
                <Col xs={24} sm={24} xl={14}>
                  {this.state.products.length > 0 && (
                    <ProductStat
                      products={this.state.products}
                      selectedProduct={this.state.selectedProduct}
                    />
                  )}
                </Col>
              </Row>
            </AnimateHeight>
          <Row type='flex' justify='center' style={{ padding: '6px 12px' }}>
            <Icon
              type='double-right'
              rotate={this.state.collapsed ? 90 : 270}
              className='btn-collapse-chart'
              onClick={this.onCollapse}
            />
          </Row>
        </Col>
      </Spin>
    )
  }
}

export default ProductTopUsage