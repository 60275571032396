import { BaseList, BaseDetail } from "App/Component/Bases";
import fields from "./fields";
import ComponentAutocomplete from "App/Component/Autocomplete";
import descriptor from "./descriptor";
import options from "./options";
import { forcePermissions } from "App/Data";

const title = "Buildings";
const urlData = "buildings";
const bulkName = "name";
const children = {
    name: 'buildings',
    sub: 'plans',
    dataIndex: 'plans',
    identifier: {
      name: 'buildings',
      is_array: false
    },
    bulk_request: false,
    add: true,
    urlSub: 'api:admin:jobs:building:ref-plans-list',
    edit: true,
    data: true,
    permissions: {
      initialrow: 1,
      add: true
    },
    rules: {
      button: {
        type: {
          add: 'default',
          edit: 'default'
        },
        size: 'large'
      },
      input: {
        size: 'large'
      },
      select: {
        size: 'large'
      }
    },
  fields: ComponentAutocomplete['plans']
  }
const availablePermissions = ['.view_']
const filterPermissionItems = (permissionItems) => {
  const allowedPermissionItems = []
  for (let i = 0; i < availablePermissions.length; i += 1) {
    for (let j = 0; j < permissionItems.length; j += 1) {
      if (permissionItems[j].includes(availablePermissions[i])) {
        allowedPermissionItems.push(permissionItems[j])
        break
      }
    }
  }
  return allowedPermissionItems
}

class List extends BaseList {
  constructor(props) {
    super(props)

    this.title = title;
    this.urlData = urlData;
    this.bulkName = bulkName;
    this.filterPermissionItems = filterPermissionItems // second option + override state currentPermissionSet
    this.propsList = {
      descriptor,
      exportImport: options.exportImportOptions,
    }
    this.state = {
      ...this.state,
      currentPermissionSet: this.isSuperuser ? this.filterPermissionItems(forcePermissions) : [],
    };
  }
}

class Detail extends BaseDetail {
  constructor(props) {
    super(props)

    this.title = title;
    this.urlData = urlData;
    this.bulkName = bulkName;
    this.fields = fields;
    this.children = children;
    this.availablePermissions = availablePermissions; // first option + override state currentPermissionSet
    this.state = {
      ...this.state,
      currentPermissionSet: this.isSuperuser ? this.filterPermissionItems(forcePermissions) : [],
    }
  }
}

export default { List, Detail };
