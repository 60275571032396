import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Pagination } from 'antd';

class AppPagination extends Component {

  render() {
    const {
      current,
      limit,
      total,
      onChange,
    } = this.props
    return (
      <Pagination
        current={current}
        pageSize={limit}
        total={total}
        onChange={onChange}
        style={{'marginTop': '1rem'}}
      />
    )
  }
}

AppPagination.propTypes = {
  current: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default AppPagination