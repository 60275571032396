export default {
  "chart": {
    "type": "column"
  },
  "title": {
    "text": "Working Speed Statistics"
  },
  "accessibility": {
    "announceNewData": {
      "enabled": true
    }
  },
  "xAxis": {
    "type": "category"
  },
  "yAxis": {
    "title": {
      "text": "Working Speed Calculation"
    }
  },
  "legend": {
    "enabled": false
  },
  "plotOptions": {
    "series": {
      "borderWidth": 0,
      "dataLabels": {
        "enabled": true,
        "format": "{point.y:.1f}"
      }
    }
  },
  "tooltip": {
    "headerFormat": "",
    "pointFormat": "<span style=\"color:{point.color}\">Grade: </span><b>{point.name}</b><br/><span style=\"color:{point.color}\">Speed: </span><b>{point.speed}</b> m²/hours<br/><span style=\"color:{point.color}\">Duration: </span> <b>{point.duration}</b><br/>"
  },
  credits: {
    enabled: false
  },
}