export default [
  {
    title: 'Code',
    type: 'text',
    required: true,
    initialValue: '',
    dataIndex: 'code',
  }, {
    title: 'Level',
    type: 'text',
    required: true,
    initialValue: '',
    dataIndex: 'level',
  }, {
    title: 'Status',
    type: 'select',
    disableAllowClear: true,
    data: [
      {
        alias: 'active',
        value: 'Active - Anyone can use'
      }, {
        alias: 'inactive',
        value: 'Inactive - No one can use'
      }, {
        alias: 'unpublished',
        value: 'Unpublished - Only certain people can use'
      }
    ],
    initialValue: 'active',
    dataIndex: 'status',
  }, {
    title: 'Rate type',
    type: 'SelectQuery',
    required: true,
    data: 'rateTypes',
    initialValue: '',
    additional: 'edit|add',
    dataIndex: 'rate_type',
    selectedKey: 'pk',
    selectedValue: 'type',
  }, {
    title: 'Rate 1',
    type: 'number',
    initialValue: null,
    note_text: '',
    dataIndex: 'rate_1',
    step: 0.001,
  }, {
    title: 'Rate 2',
    type: 'number',
    initialValue: null,
    dataIndex: 'rate_2',
    step: 0.001,
  }, {
    title: 'Rate 3',
    type: 'number',
    initialValue: null,
    dataIndex: 'rate_3',
    step: 0.001,
  }, {
    title: 'Rate 4',
    type: 'number',
    initialValue: null,
    dataIndex: 'rate_4',
    step: 0.001,
  }, {
    title: 'Rate 5',
    type: 'number',
    initialValue: null,
    dataIndex: 'rate_5',
    step: 0.001,
  }, {
    title: 'Rate 6',
    type: 'number',
    initialValue: null,
    dataIndex: 'rate_6',
    step: 0.001,
  }, {
    title: 'Rate 7',
    type: 'number',
    initialValue: null,
    dataIndex: 'rate_7',
    step: 0.001,
  }, {
    title: 'Rate 8',
    type: 'number',
    initialValue: null,
    dataIndex: 'rate_8',
    step: 0.001,
  }, {
    title: 'Rate 9',
    type: 'number',
    initialValue: null,
    dataIndex: 'rate_9',
    step: 0.001,
  }, {
    title: 'Rate 10',
    type: 'number',
    initialValue: null,
    dataIndex: 'rate_10',
    step: 0.001,
  }, {
    title: 'Rate 1 fee',
    type: 'number',
    initialValue: null,
    dataIndex: 'rate_1_fee',
  }, {
    title: 'Rate 2 fee',
    type: 'number',
    initialValue: null,
    dataIndex: 'rate_2_fee',
  }, {
    title: 'Rate 3 fee',
    type: 'number',
    initialValue: null,
    dataIndex: 'rate_3_fee',
  }, {
    title: 'Rate 4 fee',
    type: 'number',
    initialValue: null,
    dataIndex: 'rate_4_fee',
  }, {
    title: 'Rate 5 fee',
    type: 'number',
    initialValue: null,
    dataIndex: 'rate_5_fee',
  }, {
    title: 'Rate 6 fee',
    type: 'number',
    initialValue: null,
    dataIndex: 'rate_6_fee',
  }, {
    title: 'Rate 7 fee',
    type: 'number',
    initialValue: null,
    dataIndex: 'rate_7_fee',
  }, {
    title: 'Rate 8 fee',
    type: 'number',
    initialValue: null,
    dataIndex: 'rate_8_fee',
  }, {
    title: 'Rate 9 fee',
    type: 'number',
    initialValue: null,
    dataIndex: 'rate_9_fee',
  }, {
    title: 'Rate 10 fee',
    type: 'number',
    initialValue: null,
    dataIndex: 'rate_10_fee',
  }, {
    title: 'QS working hour',
    type: 'number',
    data: '',
    initialValue: null,
    note_text: '',
    additional: '',
    dataIndex: 'qs_working_hour',
  }, {
    title: 'QA1 working hour',
    type: 'number',
    data: '',
    initialValue: null,
    note_text: '',
    additional: '',
    dataIndex: 'qa1_working_hour',
  }, {
    title: 'QA2 working hour',
    type: 'number',
    data: '',
    initialValue: null,
    note_text: '',
    additional: '',
    dataIndex: 'qa2_working_hour',
  }
];