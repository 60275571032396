import React from 'react'
import PropTypes from 'prop-types'
import { Drawer, Row, Col, Card, Typography, Spin, Icon, Empty, Input, Button, Affix, message } from 'antd'
import { get } from 'lodash'
import { RequestV2 as Request } from 'App/Utils'
import PageNotFound from 'App/Component/PageNotFound'

// Class ini dibuat memang bukan untuk penggunaan umum,
class DrawerMerchants extends React.PureComponent {
  constructor(props) {
    super(props)

    this.params = {}
    this.state = {
      isLoading: true,
      isLoadMore: false,
      isSaving: false,
      filteredMerchants: [],
      next: null,
      selectedMerchants: [],
    }
  }

  setThisState = (obj, callback = () => null, force) => {
    if (force) {
      return this.setState(obj, callback)
    } else {
      for (const key in obj) {
        if (obj[key] !== this.state[key]) {
          return this.setState(obj, callback)
        }
      }
    }
    return callback()
  }

  readMerchants = (keyLoading = 'isLoading') => {
    this.setThisState({ [keyLoading]: true }, () => {
      Request(
        'get',
        'module-merchant-merchant-read',
        {},
        { ...this.params },
        [],
        this.readMerchantsSuccess,
        this.readMerchantsFailed,
        { keyLoading }
      )
    })
  }

  readMerchantsSuccess = (response, extra) => {
    let mergeMerchants = response.data.results
    if (extra.keyLoading === 'isLoadMore') {
      mergeMerchants = [...this.state.filteredMerchants, ...response.data.results]
    }
    const filteredMerchants = this.filterMerchants(mergeMerchants)
    this.setState({ [extra.keyLoading]: false, filteredMerchants, next: response.data.next })
  }

  readMerchantsFailed = (error, extra) => {
    message.error('Failed when request merchant list.')
    this.setState({ [extra.keyLoading]: false })
  }

  // return filtered except by merchants and selected users
  filterMerchants = (merchants) => {
    const filteredMerchants = merchants.filter(merchant => !this.props.merchants.find(pMerchant => pMerchant.pk === merchant.pk))
    const filteredMerchantsBySelected = filteredMerchants.filter(merchant => !this.state.selectedMerchants.find(selected => selected.pk === merchant.pk))
    return filteredMerchantsBySelected
  }

  onSearch = (val, event) => {
    this.params.search = val
    this.params.page = 1
    this.readMerchants()
  }

  onSelectMerchant = (newSelectedMerchant) => {
    // Jangan langsung mengganti variable state, seperti push, unshift, dll.
    const { filteredMerchants, selectedMerchants } = this.state
    const remainingUsers = filteredMerchants.filter((val) => val.pk !== get(newSelectedMerchant, 'pk'))
    // selectedMerchants.unshift(...filteredMerchants.splice(0, 1)) // don't do this
    this.setState({ filteredMerchants: remainingUsers, selectedMerchants: [newSelectedMerchant, ...selectedMerchants] })
  }

  onDeselectMerchant = (removedMerchant) => {
    // Jangan langsung mengganti variable state, seperti push, unshift, dll.
    const { filteredMerchants, selectedMerchants } = this.state
    const remainingSelected = selectedMerchants.filter((val) => val.pk !== get(removedMerchant, 'pk'))
    // filteredMerchants.unshift(...selectedMerchants.splice(0, 1)) // don't do this
    this.setState({ filteredMerchants: [removedMerchant, ...filteredMerchants], selectedMerchants: remainingSelected })
  }

  onApply = () => {
    const { selectedMerchants } = this.state
    if (selectedMerchants.length > 0) {
      this.setState({ isSaving: true }, () => {
        setTimeout(() => {
          const ids = this.state.selectedMerchants.map(val => val.pk)
          this.props.onJoinMerchants(ids, this.state.selectedMerchants, (isSuccess) => {
            if (isSuccess) {
              this.setState({ isSaving: false, selectedMerchants: [] })
            } else {
              this.setState({ isSaving: false })
            }
          })
        }, 250)
      })
    } else {
      message.info('Please select at least 1 merchant to join with Mapping Mode')
    }
  }

  onLoadMore = () => {
    const queryString = this.state.next.split('?')[1]
    const params = queryString.split('&')
    for (const key in params) {
      const tempParam = params[key].split('=')
      if (tempParam[0] === 'page') {
        this.params.page = tempParam[1]
        return this.readMerchants('isLoadMore')
      }
    }
    return message.error('Cannot create request, no more data found.')
  }

  onRemoveAllSelected = () => {
    const { filteredMerchants, selectedMerchants } = this.state
    this.setState({ filteredMerchants: [...selectedMerchants, ...filteredMerchants], selectedMerchants: [] })
  }

  componentWillReceiveProps(nextProps) {
    const { filteredMerchants } = this.state
    if (!this.props.visible && nextProps.visible && filteredMerchants.length === 0) {
      this.readMerchants()
    }
  }

  componentDidMount() {
    if (this.props.visible) {
      this.readMerchants()
    }
  }

  render() {
    const { visible, onClose } = this.props
    const { isLoading, filteredMerchants, selectedMerchants, next, isLoadMore, isSaving } = this.state

    return (
      <Drawer
        closable={false}
        width='65%'
        bodyStyle={{ marginTop: 0, paddingTop: 0 }}
        visible={visible}
        onClose={onClose}
      >
        <Spin spinning={isLoading}>
          <Row>
            <Affix offsetTop={0} target={() => document.getElementsByClassName('ant-drawer-wrapper-body')[0]}>
              <div className='header-list-user' style={{ padding: '12px 0', backgroundColor: '#fff' }}>
                <div style={{ marginBotttom: '12px' }}>
                  <Row type='flex' justify='center' style={{ marginBottom: '12px' }}>
                    <Col style={{ fontSize: '24px', fontWeight: 500, lineHeight: 1.35, color: 'rgba(0, 0, 0, 0.85)', marginBottom: '12px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                      Add Merchant
                    </Col>
                  </Row>
                </div>
                <Row type='flex' justify='space-between' gutter={[12, 12]}>
                  <Col>
                    <Input.Search placeholder='Search...' onSearch={this.onSearch} />
                  </Col>
                  <Col>
                    <Button type='primary' icon='save' loading={isSaving} onClick={this.onApply}>
                      Apply
                    </Button>
                  </Col>
                </Row>
              </div>
            </Affix>
            <Row>
              <Col xs={24} sm={24} md={12}>
                <div style={{ padding: '12px', fontSize: '18px', fontWeight: 500, color: 'rgba(0, 0, 0, 0.85)', borderBottom: '1px solid #e8e8e8', borderRight: '1px solid #e8e8e8', textAlign: 'center', backgroundColor: '#f3f3f3' }}>
                  Available Merchant: {filteredMerchants.length}
                </div>
                { filteredMerchants.length === 0 && selectedMerchants.length === 0 && !next && (
                  <Row>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='No user found' />
                    <Row type='flex' justify='center' style={{ marginTop: '12px' }}>
                      <Button onClick={() => this.readMerchants()}>
                        Try again
                      </Button>
                    </Row>
                  </Row>
                )}
                { filteredMerchants.map((merchant) => (
                  <div style={{ borderBottom: '1px solid #e8e8e8', borderRight: '1px solid #e8e8e8' }} key={merchant.pk}>
                    <div className='selection-row-item' onClick={() => this.onSelectMerchant(merchant)}>
                      <div style={{ fontSize: '16px', fontWeight: 500, color: '#030303', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', marginBottom: '2px' }}>
                        {merchant.name}
                      </div>
                      <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                        {get(merchant.merchant_group, 'name', '-')}
                      </div>
                    </div>
                  </div>
                ))}
                { next && (
                  <Row type='flex' justify='center' style={{ marginTop: '12px' }}>
                    <Button loading={isLoadMore} onClick={this.onLoadMore}>
                      Load more
                    </Button>
                  </Row>
                )}
              </Col>
              <Col xs={24} sm={24} md={12}>
                <div style={{ padding: '12px', fontSize: '18px', fontWeight: 500, color: 'rgba(0, 0, 0, 0.85)', borderBottom: '1px solid #e8e8e8', textAlign: 'center', backgroundColor: '#f3f3f3' }}>
                  Chosen Merchant: {selectedMerchants.length}
                  { selectedMerchants.length > 0 && (
                    <span title='Remove all selected' onClick={this.onRemoveAllSelected} style={{ float: 'right', color: '#ff4d4f', cursor: 'pointer' }}>
                      x
                    </span>
                  )}
                </div>
                { selectedMerchants.length === 0 && (
                  <Row type='flex' justify='center'>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='No selected users' />
                  </Row>
                )}
                { selectedMerchants.map((merchant) => (
                  <div style={{ borderBottom: '1px solid #e8e8e8' }} key={merchant.pk}>
                    <div className='selection-row-item' onClick={() => this.onDeselectMerchant(merchant)}>
                      <div style={{ fontSize: '16px', fontWeight: 500, color: '#030303', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', marginBottom: '2px' }}>
                        {merchant.name}
                      </div>
                      <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                        {get(merchant.merchant_group, 'name', '-')}
                      </div>
                    </div>
                  </div>
                ))}
              </Col>
            </Row>
          </Row>
        </Spin>
      </Drawer>
    )
  }
}

DrawerMerchants.propTypes = {
  userReducer: PropTypes.object.isRequired,
  merchants: PropTypes.array,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onJoinMerchants: PropTypes.func,
}

DrawerMerchants.defaultProps = {
  merchants: [],
  visible: false,
  onClose: () => null,
  onJoinMerchants: () => null,
}

export default DrawerMerchants