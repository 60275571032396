import React from 'react'
import { Row, Spin, Icon, message } from 'antd'
import { get } from 'lodash'

import SubAllList from 'App/Component/SubAllList'
import { Lib, RequestV2 as Request, CheckPermission } from 'App/Utils'
import { PermissionName } from 'App/Config'
import { forcePermissions } from 'App/Data'

import ChartJobQueue from './ChartJobQueue/index'
// import { PageNotFound } from 'App/Component' // ini bisa membuat error, tidak tau kenapa
import PageUnauthorized from 'App/Component/PageUnauthorized'
import descriptor from './JobQueue/descriptorOffline'

import 'App/Component/Singlepage/SingleStyle.css'

class JobQueue extends React.Component {
  constructor(props) {
    super(props)

    this.isSuperuser = get(props.userReducer, ['user', 'is_superuser'])
    this.state = {
      isLoading: false,
      // Permission
      isCheckingPermission: !this.isSuperuser,
      isAccessAllowed: this.isSuperuser,
      currentPermissionSet: this.isSuperuser ? forcePermissions : [],
      // ----------
    }
  }

  setThisState = (obj, callback = () => null, force) => {
    if (force === true) {
      return this.setState(obj, callback)
    } else {
      for (const key in obj) {
        if (obj[key] !== this.state[key]) {
          return this.setState(obj, callback)
        }
      }
    }
    callback()
  }

  readSummary = () => {

    // Request
    // {{domain}}/api/job/jobs/job-queues/branch-queue-summaries/?limit_received=30d'
  }

  onDeleteClick = (item) => {
    Request(
      'delete',
      'module-jobQueue-delete',
      {},
      {},
      [item],
      res => this.deleteSuccess(res),
      err => this.deleteFailed(err)
    )
  }

  deleteSuccess = (response) => {
    this.setState({ isLoading: true }, () => {
      this.setState({ isLoading: false }, () => {
        message.success('Delete Success')
      })
    })
  }

  deleteFailed = (error) => {
    this.setState({ isLoading: true }, () => {
      this.setState({ isLoading: false }, () => {
        message.success('Delete Failed')
      })
    })
  }

  detailJobQueue=(val)=>{
    this.props.history.push(`/job-management/job-queues/${val}`)
  }

  handleCheckPermission = (callback = () => null) => {
    this.setThisState({ isCheckingPermission: true }, () => {
      const currentPermissionSet = CheckPermission.getPermissionSet({
        userReducer: this.props.userReducer,
        appName: 'jobs',
        modelName: 'jobqueue',
      })
      const codePart = PermissionName.READ.codePart
      const isAccessAllowed = !!currentPermissionSet.find(val => val.search(codePart) !== -1)
      callback({ isAccessAllowed, currentPermissionSet })
    })
  }

  componentDidMount() {
    const callbackAllowed = () => {
      this.readSummary()
    }

    if (!this.isSuperuser) {
      this.handleCheckPermission(res => {
        this.setState({
          isCheckingPermission: false,
          isAccessAllowed: get(res, 'isAccessAllowed'),
          currentPermissionSet: get(res, 'currentPermissionSet', []),
        }, () => {
          if (get(res, 'isAccessAllowed')) {
            callbackAllowed()
          }
        })
      })
    } else {
      callbackAllowed()
    }
  }

  componentWillUnmount() {
    Lib.windowStop();
  }

  chartBar(chart_data){
    return <ChartJobQueue/>
  }

  render() {
    const { isCheckingPermission, isAccessAllowed, currentPermissionSet } = this.state

    if (isCheckingPermission) {
      return (
        <Row type='flex' justify='center' align='middle' style={{ marginTop: '30vh' }}>
          <Spin
            size='large'
            tip='Checking permission...'
            indicator={<Icon spin type='loading' style={{ fontSize: '4rem' }} />}
          />
        </Row>
      )
    }

    return isAccessAllowed ? (
      <SubAllList
        descriptor={descriptor}
        masterKey='module-jobQueue'
        pageTitle='Job Queues'
        groupByOptions={[
          {
            label: "Branch",
            key: "branch.name",
            dataIndex: "branch.name"
          },
          {
            label: "Role",
            key: "role",
            dataIndex: "role",
          }
        ]}
        userReducer={this.props.userReducer}
        avatar="name"
        renderCountCard={this.chartBar}
        onDeleteClick={item => this.onDeleteClick(item)}
        onDetailClick={this.detailJobQueue}
        permissions={currentPermissionSet}
      />
    ) : (
      <PageUnauthorized />
    )
  }
}

export default JobQueue
