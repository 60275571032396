import { BaseUrl } from 'App/Component/Bases'

const module = 'user-group'
const list = [
  {
    label: 'Group',
    url: BaseUrl.group,
    icon: 'team',
    code: 'account.{}_group',
  },
  {
    label: 'User',
    icon: 'robot',
    menus: [
      {
        label: 'All',
        url: BaseUrl.userAll,
        // icon: 'robot',
        code: 'account.{}_user',
      },
      {
        label: 'IBES',
        url: BaseUrl.userIbes,
        // icon: 'robot',
        code: 'account.{}_user',
      },
      {
        label: 'Tracker',
        url: BaseUrl.userTracker,
        // icon: 'robot',
        code: 'account.{}_user',
      },
    ]
  },
  // {
  //   label: 'Manage',
  //   menus: [
  //     {
  //       label: 'Day Off',
  //       url: `/${module}/manage/day-off`,
  //       icon: 'calendar',
  //       code: 'account.{}_user',
  //     }
  //   ]
  // }
]

export default { key: module, list }