import React from 'react'
import PropTypes from 'prop-types'
import { Bar } from 'ant-design-pro/lib/Charts'
import { Row, Col, Spin, Empty, Radio, message } from 'antd'
import { get } from 'lodash'
import moment from 'moment'
import { Lib, RequestV2 } from 'App/Utils'

const NO_DATA = 'No New Jobs'
const labelReceived = { '7d': '7 Days', '30d': '30 Days' }

class StatusSummary extends React.PureComponent {
  constructor(props) {
    super(props)

    this.limitReceived = '7d'
    this.state = {
      isLoading: true,
      newJobSummary: [],
      totalNewJob: 0,
      months: [],
      noDataDesc: NO_DATA,
    }
  }

  setThisState = (obj, callback = () => null, force) => {
    if (force) {
      return this.setState(obj, callback)
    } else {
      for (const key in obj) {
        if (obj[key] !== this.state[key]) {
          return this.setState(obj, callback)
        }
      }
    }
    callback()
    return false
  }

  readSummary = () => {
    this.setThisState({ isLoading: true }, () => {
      RequestV2(
        'get',
        'module-job-newJobSummaries',
        {},
        {limit_received: this.limitReceived},
        [],
        this.readSummarySuccess,
        this.readSummaryFailed,
      )
    })
  }

  readSummarySuccess = response => {
    const keysConvert = { x: 'label', 'y': 'total' }
    const newJobSummary = []
    const months = []
    for (const index in response.data.summaries) {
      const y = response.data.summaries[index][keysConvert.y]
      let x = response.data.summaries[index][keysConvert.x]
      if (moment(x).isValid()) {
        x = moment(x).format('DD MMM')
        // mencari rentang bulan
        const tempMonth = moment(x).format('MMMM')
        if (tempMonth !== months[months.length - 1]) {
          months.push(tempMonth)
        }
        // ---------------------
      } else if (this.limitReceived === '30d') {
        const x1 = x.split('-')[0].trim()
        const x2 = x.split('-')[1].trim()
        x = moment(x1).format('Do')
        if (x1 !== x2) {
          x += ' - ' + moment(x2).format('Do')
        }
        // mencari rentang bulan
        const tempMonth1 = moment(x1).format('MMMM')
        const tempMonth2 = moment(x2).format('MMMM')
        if (tempMonth1 !== months[months.length - 1]) {
          months.push(tempMonth1)
        }
        if (tempMonth2 !== months[months.length - 1]) {
          months.push(tempMonth2)
        }
        // ---------------------
      }
      newJobSummary.push({ x, y })
    }
    this.setState({ isLoading: false, newJobSummary, totalNewJob: response.data.total, months, noDataDesc: `${NO_DATA} in last ${labelReceived[this.limitReceived]}` })
  }

  readSummaryFailed = error => {
    Lib.errorMessageHandler(get(error, 'response'))
    this.setState({ isLoading: false, newJobSummary: [], totalNewJob: 0, months: [], noDataDesc: 'Request failed' })
  }

  onLimitChange = event => {
    this.limitReceived = event.target.value
    this.setState({ isLoading: true }, () => {
      setTimeout(() => {
        this.readSummary()
      }, 100)
    })
  }

  componentDidMount() {
    this.readSummary()
  }

  render() {
    const { height, title } = this.props
    const { isLoading, newJobSummary, totalNewJob, months, noDataDesc } = this.state

    return (
      <Spin spinning={isLoading}>
        <Col>
          <Row type='flex' justify='space-between' style={{ marginBottom: '12px' }}>
            <Col className='text-truncate' style={{ maxWidth: 'calc(100% - 130px' }}>
              <span style={{ fontSize: '16px', fontWeight: 700 }}>
                {title}:
                <span style={{ fontSize: '12px', marginLeft: '6px', border: '1px solid #c8c8c8', borderRadius: '4px', backgroundColor: '#f0f2f5', padding: '2px 6px' }}>
                  {totalNewJob || 0}
                </span>
                <span style={{ marginLeft: '6px', fontWeight: 500 }}>
                  {
                    months.length > 0 ? (
                      '( ' + months[0] + (months.length > 1 ? ` - ${months[months.length - 1]}` : '') + ' )'
                    ) : ''
                  }
                </span>
              </span>
            </Col>
            <Col>
              <Radio.Group defaultValue='7d' size='small' buttonStyle='solid' onChange={this.onLimitChange}>
                <Radio.Button value='7d'>7 Days</Radio.Button>
                <Radio.Button value='30d'>30 Days</Radio.Button>
              </Radio.Group>
            </Col>
          </Row>
          {
            newJobSummary.length > 0 ? (
              <Bar height={height} data={newJobSummary}  />
            ) : (
              <Row type='flex' justify='center' align='middle' style={{ height }}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={noDataDesc} />
              </Row>
            )
          }
        </Col>
      </Spin>
    )
  }
}

StatusSummary.propTypes = {
  userReducer: PropTypes.object.isRequired,
  title: PropTypes.string,
  height: PropTypes.number,
}

StatusSummary.defaultProps = {
  title: 'Received Jobs',
  height: 200,
}

export default StatusSummary