const descriptor = {
  pagination: true,
  filter_by: [],
  columns: [
    {
      "name": "name",
      "label": "Name",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "ellipsis": true,
      "search": true,
      "sort": true
    }
  ]
}

export default descriptor