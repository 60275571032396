export default [
  {
    title: 'Grade',
    type: 'SelectQuery',
    data: 'jobGrades',
    initialValue: '',
    note_text: '',
    dataIndex: 'grade',
    selectedKey: 'pk',
    required: true,
    disableAllowClear: true,
    selectedValue: 'label',
    // additional: 'edit|add',
    fieldKey: 'pk',
    order: 0,
  }, 
  {
    title: 'Hi-Light',
    type: 'number',
    data: '',
    note_text: '',
    additional: '',
    initialValue: 0,
    dataIndex: 'highlighted_charge',
    order: 1,
  },
  {
    title: 'Rate 1',
    type: 'number',
    dataIndex: 'rate_1',
    data: '',
    initialValue: 0,
  },{
    title: 'Rate 2',
    type: 'number',
    dataIndex: 'rate_2',
    data: '',
    initialValue: 0,
  },{
    title: 'Rate 3',
    type: 'number',
    dataIndex: 'rate_3',
    data: '',
    initialValue: 0,
  },
  {
    title: 'Rate Type',
    type: 'select',
    disableAllowClear: true,
    data: [
      {
        alias: 'fix',
        value: 'Fix'
      }, {
        alias: 'relative',
        value: 'Relative'
      }
    ],
    initialValue: 'relative',
    note_text: '',
    additional: '',
    dataIndex: 'rate_type',
    order: 2,
  },
  {
    title: 'Start Area Range',
    type: 'number',
    data: '',
    // required: true,
    initialValue: 0,
    note_text: '',
    additional: '',
    dataIndex: 'start_area_range',
    order: 1,
  },
  {
    title: 'End Area Range',
    type: 'number',
    data: '',
    // required: true,
    initialValue: 0,
    note_text: '',
    additional: '',
    dataIndex: 'end_area_range',
    order: 1,
  },
  {
    title: 'Min. Charge',
    type: 'number',
    data: '',
    // required: true,
    initialValue: 0,
    note_text: '',
    additional: '',
    dataIndex: 'minimum_charge',
    order: 1,
  },
];
