import React from "react";
import {Button, Form, Icon, Input, InputNumber, Upload} from "antd";
import {EditableContext} from "../../../../Component/Edit/EditableRow";

class EditableCell extends React.Component {
  fileList = []

    getInput = () => {
        if (this.props.inputType === 'number') {
            return <InputNumber />;
        }
        return <Input />;
    };

    normFile = (e) => {
      if (Array.isArray(e)) {
        return e
      }

      this.fileList = e.fileList
      this.fileList = this.fileList.slice(-1)

      return e && this.fileList
    }

    renderCell = ({ getFieldDecorator, getFieldValue }) => {
        const {
            editing,
            dataIndex,
            title,
            inputType,
            record,
            index,
            children,
            ...restProps
        } = this.props;

        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item style={{ margin: 0 }}>
                        {inputType === 'file' ?
                        getFieldDecorator(dataIndex, {
                          valuePropName: 'fileList',
                                // initialValue: record[dataIndex],
                                getValueFromEvent: this.normFile
                            })(
                        <Upload name="logo" action="" listType="picture" beforeUpload={() => false}>
                            <Button>
                            <Icon type="upload" /> Click to change
                            </Button>
                            </Upload>)
                         :
                        getFieldDecorator(dataIndex, {
                            rules: [
                                {
                                    message: `Please Input ${title}!`,
                                },
                            ],
                            initialValue: record[dataIndex],
                        })(this.getInput())}

                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };
    render() {
        return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
    }
}

export default  EditableCell
