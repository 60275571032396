import React from 'react'
import PropTypes from 'prop-types'
import { Collapse, Icon, Spin, Tree } from 'antd'
import { get } from 'lodash'
import { Lib, RequestV2 as Request } from 'App/Utils'
import Section from './Section'

const { Panel } = Collapse
const { TreeNode } = Tree

class Sections extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      sections: [],
    }
  }

  onRefSection = (ref, section) => {
    this[this.getRefKey(section)] = ref
  }

  getRefKey = (section) => {
    // jika ingin mengganti ini, lihat juga di ../index.js apakah ada manual refKey
    return `_section_${get(section, 'pk')}`
  }

  read = () => {
    this.setState({ isLoading: true }, () => {
      Request(
        'get',
        'sections-autocomplete',
        {},
        {limit: 100, status: 'active'},
        [],
        this.readSuccess,
        this.readFailed,
      )
    })
  }

  readSuccess = (response) => {
    this.setState({ isLoading: false, sections: response.data.results })
  }

  readFailed = (error) => {
    Lib.errorMessageHandler(get(error, 'response'))
    this.setState({ isLoading: false })
  }

  componentDidMount() {
    this.read()
  }

  getExpandIcon = (e) => {
    if (e.isActive) return <Icon type='caret-down' className='text-danger' />
    return <Icon type='caret-right' className='text-success' />
  }

  render() {
    const { title } = this.props
    const { isLoading, sections } = this.state

    return (
      <Spin spinning={isLoading}>
        <Collapse
          activeKey='1'
          className='parent-section-collapse'
          expandIcon={() => null}
        >
          <Panel
            key='1'
            header={
              <span className="ant-typography text-success">
                <Icon type='reconciliation' style={{ marginTop: '7px' }} /> {`Subsection -> ${title}`}
              </span>
            }
          >
            <div>
              <div
                className='ant-collapse ant-collapse-borderless ant-collapse-icon-position-left section-collapse default-scroll'
                style={{ height: 'calc(100vh - 309px)', overflowY: 'auto' }}
              >
                {sections.map(section => (
                  <Section
                    ref={ref => {this.onRefSection(ref, section)}}
                    key={section.pk}
                    urlKeyGroupList={this.props.urlKeyGroupList}
                    section={section}
                    defaultExpandedKeys={this.props.defaultExpandedKeys}
                    onAddGroup={this.props.onAddGroup}
                    onSelectGroup={this.props.onSelectGroup}
                    selectedObj={this.props.selectedObj}
                    onDeleteGroup={this.props.onDeleteGroup}
                  />
                ))}
              </div>
            </div>
          </Panel>
        </Collapse>
      </Spin>
    )
  }
}

Sections.propTypes = {
  title: PropTypes.string.isRequired,
  urlKeyGroupList: PropTypes.string.isRequired,
  selectedObj: PropTypes.object,
  defaultExpandedKeys: PropTypes.object,
  onSelect: PropTypes.func,
  onAddGroup: PropTypes.func,
  onSelectGroup: PropTypes.func,
  onDeleteGroup: PropTypes.func,
}

Sections.defaultProps = {
  defaultExpandedKeys: null,
  onSelect: () => null,
  onAddGroup: () => null,
  onSelectGroup: () => null,
  onDeleteGroup: () => null,
}

export default Sections