import { BaseList } from "App/Screens/SubModules/Component";
import descriptor from "./descriptorOffline";

class KeywordGroupList extends BaseList {
  constructor(props) {
    super(props)

    this.appName = 'expert_system'
    this.modelName = 'keywordgroup'
    this.propsList = {
      avatar: 'name',
      pageTitle: 'Keyword Group',
      descriptor,
      masterKey: 'module-expertSystem-keywordGroup',
    }
  }
}

export default KeywordGroupList