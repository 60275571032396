import React from 'react'
import { BasePreDetail } from 'App/Screens/SubModules/Component'
import PageAdd from './Add'
import PageDetail from './Detail'

class TagManagement extends BasePreDetail {
  constructor(props) {
    super(props)

    this.appName = 'jobs'
    this.modelName = 'tag'
  }

  getPageAdd = () => <PageAdd {...this.props} />

  getPageDetail = () => <PageDetail {...this.props} currentPermissionSet={this.state.currentPermissionSet} />
}

export default TagManagement