import React from "react";
import { BrowserRouter } from "react-router-dom";

import { Modules } from "App/Utils";

import "ant-design-pro/dist/ant-design-pro.css";
import "App.css";
import "./App.less";

class App extends React.PureComponent {
  render() {
    return <BrowserRouter>{this.props.routeElement}</BrowserRouter>;
  }
}

export default Modules.setRouter()(App);
