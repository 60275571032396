import React from 'react';
import {Row, Col, Skeleton} from "antd";

function LoadingSkleton(props) {
  const count = props && props.hasOwnProperty('count') ? props.count : 10;
  return (
    <Row gutter={16}>
      {[...Array(count)].map( (val, key) => (
        <Col key={key} span={12}>
          <Skeleton active/>
        </Col>
      ))}
    </Row>
  );
}

export default LoadingSkleton;