import React from "react";
import PropTypes from "prop-types";
import { Row, Spin, Icon } from "antd";
import { get } from "lodash";
import { Lib, CheckPermission } from "App/Utils";
import SubAllList from "App/Component/SubAllList";
import PageError from "App/Component/PageError";
import PageUnauthorized from "App/Component/PageUnauthorized";

const objUnauthorized = {
  status: 403,
  data: { detail: 'You cannot access this page, because the admin does not give you permission.' },
}

class ContentReference extends React.PureComponent {
  constructor(props) {
    super(props)

    this.availablePermissions = get(props, 'availablePermissions', [])
    this.isSuperuser = get(props.userReducer, ['user', 'is_superuser'])
    this.state = {
      errorResponse: null,
      isGettingPermissions: !this.isSuperuser,
      isAccessAllowed: this.isSuperuser,
      permissionItems: [],
    }
  }

  // beri page error jika tidak memiliki permission read? menurut saya sih begitu.

  getPermissions = () => {
    const permissionItems = CheckPermission.getPermissionSet({
      userReducer: this.props.userReducer,
      appName: this.props.appName,
      modelName: this.props.modelName,
    }).filter(val => this.availablePermissions.length === 0 || this.availablePermissions.find(available => val.includes(available)) )

    const isAccessAllowed = permissionItems.some(val => val.includes('.view_'))
    const errorResponse = isAccessAllowed ? null : objUnauthorized
    this.setState({ isAccessAllowed, errorResponse, isGettingPermissions: false, permissionItems })
  }

  getReadParams = () => {
    const { currentSetting, parentData } = this.props
    return currentSetting.manualFilter
      ? {
        [currentSetting.manualFilter.key]: parentData[currentSetting.manualFilter.value],
        exclude_fields: currentSetting.manualFilter.key,
      }
      : null
  }

  getUrlTarget = () => {
    return this.props.urlTarget.replace('{}', this.props.getId())
  }

  onAddClick = () => {
    const { refField, parentData } = this.props
    let historyState = null
    if (refField) {
      if (refField.type === 'object') {
        historyState = {
          dataIdentifier: {
            [refField.name]: {
              [refField.valKey]: get(parentData, refField.valKey),
              [refField.valLabel]: get(parentData, refField.valLabel),
            }
          }
        }
      } else if (refField.type === 'object_arr') {
        historyState = {
          dataIdentifier: {
            [refField.name]: [{
              [refField.valKey]: get(parentData, refField.valKey),
              [refField.valLabel]: get(parentData, refField.valLabel),
            }]
          }
        }
      } else if (refField.type === 'string') {
        historyState = {
          dataIdentifier: {
            [refField.name]: get(parentData, refField.valLabel),
          }
        }
      }
    }
    Lib.historyAction(this.props.history, `${this.getUrlTarget()}/add`, historyState).push()
  }

  onDetailClick = (id) => {
    Lib.historyAction(this.props.history, `${this.getUrlTarget()}/${id}`).push()
  }

  componentDidMount() {
    if (this.state.isGettingPermissions) {
      this.getPermissions()
    }
  }

  componentWillUnmount() {
    Lib.windowStop();
  }

  render() {
    const { currentSetting, propsList, userReducer } = this.props
    const { errorResponse, isAccessAllowed, isGettingPermissions, permissionItems } = this.state

    if (isGettingPermissions) {
      return (
        <Row type='flex' justify='center' align='middle' style={{ margin: '30vh 0' }}>
          <Spin
            size='large'
            tip='Loading...'
            indicator={<Icon spin type='loading' style={{ fontSize: '4rem' }} />}
          />
        </Row>
      )
    }

    return isAccessAllowed ? (
      <SubAllList
        permissions={permissionItems}
        pageTitle={currentSetting.title}
        readParams={this.getReadParams()}
        onAddClick={this.onAddClick}
        onDetailClick={this.onDetailClick}
        userReducer={userReducer}
        {...propsList}
      />
    ) : (
      <PageError
        errorResponse={errorResponse}
      />
    )
  }
}

ContentReference.propTypes = {
  history: PropTypes.object.isRequired,
}

ContentReference.defaultProps = {
  getId: () => null,
}

export default ContentReference;
