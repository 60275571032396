import { BaseList } from "App/Screens/SubModules/Component";
import descriptor from "./descriptorOffline";

class TagList extends BaseList {
  constructor(props) {
    super(props)

    this.appName = 'jobs'
    this.modelName = 'tag'
    this.propsList = {
      avatar: 'excerpt',
      pageTitle: 'Tag',
      masterKey: 'module-merchant-tag',
      descriptor,
    }
  }
}

export default TagList