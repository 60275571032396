import React from 'react'
import { Row, Col, Select, Button, Spin, Empty } from 'antd'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highcharts'
import { options as optsStackedColumn } from './ChartOptions/StackedColumn'
import { RequestV2 } from 'App/Utils'

// import Test from './Test'

import 'App/Component/Singlepage/SingleStyle.css'

const { Option } = Select

class JobQueueSummary extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      limitReceived: '7d',
      isLoading: true,
      // jobQueueSummary: [], // variable belum berguna
      currentOptions: null,
    }
  }

  setThisState = (obj, callback = () => null) => {
    for (const key in obj) {
      if (obj[key] !== this.state[key]) {
        return this.setState(obj, callback)
      }
    }
    callback()
  }

  read = () => {
    this.setThisState({ isLoading: true }, () => {
      const { limitReceived } = this.state
      RequestV2(
        'get', // method
        'api:admin:dashboard:branch:list', // urlKey
        {}, // headers
        { limit_received: limitReceived, status: true }, // data
        [], // args
        this.readSuccess,
        err => this.setState({ isLoading: false, currentOptions: null }),
      )
    })
  }

  readSuccess = response => {
    const data = []
    const categories = []
    const colors = [] // belum
    for (let i = 0; i < response.data.length; i += 1) {
      data.push({ name: response.data[i].name, data: [] })
      colors.push(response.data[i].color) // belum
      for (let j = 0; j < response.data[i].job_summary.summaries.length; j += 1) {
        const { label } = response.data[i].job_summary.summaries[j]
        if (label !== 'Closed') {
          data[i].data.push(response.data[i].job_summary.summaries[j].total)

          if (i === 0) {
            // Set categories, sample in first branch
            categories.push(label)
          }
        }
      }
    }

    this.setState({
      isLoading: false,
      // jobQueueSummary,
      currentOptions: {
        ...optsStackedColumn,
        series: data,
        xAxis: { ...optsStackedColumn.xAxis, categories },
        colors,
      }
    })
  }

  onDayChange = value => {
    this.setState({ isLoading: true, limitReceived: value }, () => {
      this.read()
    })
  }

  componentDidMount() {
    this.read()
  }

  render() {
    const { isLoading, limitReceived, currentOptions } = this.state

    return (
      <Col>
        {/* <Test /> */}
        <Row type='flex' justify='space-between'>
          <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
            <Row type='flex'>
              <Col span={8}>
                <Button disabled className='group-left' style={{ width: '100%', padding: '0 7px' }}>
                  Show:
                </Button>
              </Col>
              <Col span={16}>
                <Select
                  value={limitReceived}
                  className='group-right'
                  style={{ width: '100%', marginLeft: '-1px' }}
                  onChange={this.onDayChange}
                >
                  <Option value='7d'>7 Days</Option>
                  <Option value='30d'>30 Days</Option>
                  <Option value='60d'>60 Days</Option>
                  <Option value='90d'>90 Days</Option>
                </Select>
              </Col>
            </Row>
          </Col>
          <Col>
            <Button loading={isLoading} icon='reload' onClick={this.read}>Refresh</Button>
          </Col>
        </Row>
        <Row className='mt-2'>
          <Spin spinning={isLoading} tip='Loading...' size='large'>
            <div style={{ border: '1px solid #e8e8e8', borderRadius: '4px', padding: '1px' }}>
              {currentOptions ? (
                <HighchartsReact
                  highcharts={Highcharts}
                  constructorType='chart'
                  options={currentOptions}
                />
              ) : (
                <div>
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </div>
              )}
            </div>
          </Spin>
        </Row>
      </Col>
    )
  }
}

export default JobQueueSummary