export default [
  {
    title: 'Name',
    type: 'text',
    data: '',
    required: true,
    initialValue: '',
    note_text: 'A descriptive name, e.g. \'Jakarta Jobs\' or \'Auckland QA\'.',
    additional: '',
    dataIndex: 'name',
    fieldKey: 'name',
    order: 0,
    view: true
  }, {
    title: 'Role',
    type: 'select',
    disableAllowClear: true,
    data: [{
        alias: 'job',
        value: 'Shared Job Queue'
      }, {
        alias: 'qa',
        value: 'Shared QA Queue'
      }
    ],
    initialValue: 'job',
    required: true,
    note_text: 'What this queue is used for.',
    additional: '',
    dataIndex: 'role',
    fieldKey: 'role',
    order: 1,
    view: true
  }, {
    title: 'Branch',
    type: 'SelectQuery',
    data: 'branches',
    required: true,
    initialValue: '',
    note_text: 'Which branch this queue is attached to.',
    additional: 'edit|add',
    selectedKey: 'pk',
    selectedValue: 'name',
    dataIndex: 'branch',
    order: 2,
    // view: true,
    // disableChange:true
  }, {
    title: 'Default for branch',
    type: 'checkbox',
    data: '',
    initialValue: '',
    note_text: 'Default for specified role in branch? If not specified, the queues will be picked randomly.',
    additional: '',
    dataIndex: 'default_for_branch',
    fieldKey: 'default_for_branch',
    order: 3,
    view: true
  }, {
    title: 'Is Commercial',
    type: 'checkbox',
    initialValue: false,
    dataIndex: 'is_commercial',
    fieldKey: 'is_commercial',
    view: true,
  },
];
