export default [
  {
    title: 'Document',
    type: 'file',
    dataIndex: 'file',
    required: true,
    // multipleUpload: true,
    limit: 1,
  },
];
