import React from "react";
import { Col, Form, Input, Row, Icon } from "antd";
import PropTypes from "prop-types";
import { get } from 'lodash'
import { FormContext, FormProvider } from 'App/Component/Context/ContextForm'
import SelectQuery from 'App/Component/SelectQuery'
import Lib2 from 'App/Component/Singlepage/Lib2'
import Lib from 'App/Utils/Lib'

class BasicProfile extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {}
  }

  validateFields = () => {
    // Hati-hati ketika menggunakan isFieldsTouched(), karena jika setelah di setFields, isFieldsTouched kembali menjadil false
    return this.currentForm.validateFieldsAndScroll({scroll: {offsetBottom: 330}}, (err, values) => {
      if (!err) {
        // const convertedValues = Lib2.getValuesWithFile(values)
        // values = convertedValues
        return Lib2.getValuesWithFile(values)
      } else {
        this.props.hasErrors(err)
      }
    })
  }

  // Set error to single field
  setError = (objError) => {
    if (typeof objError === 'object') {
      for (const key in objError) {
        const fieldValue = this.currentForm.getFieldValue(key)
        this.currentForm.setFields({
          [key]: {
            value: fieldValue,
            errors: [new Error(objError[key])],
          }
        })
      }
    }
  }

  render() {
    const { readOnly, user, onFieldTouched } = this.props;

    return (
      <div>
        <h2 className='mb-3'>User Information</h2>
        <FormProvider>
          <FormContext.Consumer>
            {form => {
              this.currentForm = form
              return (
                <Row>
                  {/* Pastikan mengirim rules minimal 1 rule */}
                  <Col>
                    <Form.Item label='Username' style={{ marginBottom: '12px' }}>
                      {form.getFieldDecorator('username', {
                        rules: [{
                          required: true,
                          message: 'Cannot change the username to blank'
                        }],
                        initialValue: get(user, 'username', ''),
                      })(
                        <Input disabled={readOnly} onChange={onFieldTouched} />
                      )}
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item label='First Name' style={{ marginBottom: '12px' }}>
                      {form.getFieldDecorator('first_name', {
                        rules: [{
                          required: true,
                          message: 'Please input First Name'
                        }],
                        initialValue: get(user, 'first_name', ''),
                      })(
                        <Input
                          disabled={readOnly}
                          onChange={onFieldTouched}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item label='Last Name' style={{ marginBottom: '12px' }}>
                      {form.getFieldDecorator('last_name', {
                        rules: [{
                          required: false,
                          message: 'Please input Last Name',
                        }],
                        initialValue: get(user, 'last_name', '')
                      })(
                        <Input disabled={readOnly} onChange={onFieldTouched} />
                      )}
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item label='E-Mail Address' style={{ marginBottom: '12px' }}>
                      {form.getFieldDecorator('email', {
                        rules: [{
                          type: 'email',
                          message: 'The input is not valid E-mail!',
                        }],
                        initialValue: get(user, 'email', ''),
                      })(
                        <Input disabled={readOnly} type='email' onChange={onFieldTouched} />
                      )}
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item label='Home Branch' style={{ marginBottom: '12px' }}>
                      {form.getFieldDecorator('home_branch', {
                        rules: [{
                          required: true,
                          message: 'Please select Home Branch',
                        }],
                        initialValue: Lib.setSelectValue(get(user, 'home_branch'), 'pk', 'name'),
                      })(
                        <SelectQuery
                          disabled={readOnly}
                          urlKey='branches-autocomplete'
                          userReducer={this.props.userReducer}
                          suffixIcon={<Icon type="caret-down"/>}
                          valKey='pk'
                          valLabel='name'
                          style={{ width: '100%' }}
                          onChange={onFieldTouched}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item label='Staff Position' style={{ marginBottom: '12px' }}>
                      {form.getFieldDecorator('staff_position', {
                        rules: [{
                          required: false,
                          message: 'Please input Staff Position',
                        }],
                        initialValue: Lib.setSelectValue(get(user, 'staff_position'), 'pk', 'name'),
                      })(
                        <SelectQuery
                          disabled={readOnly}
                          urlKey='staffPositions-autocomplete'
                          userReducer={this.props.userReducer}
                          suffixIcon={<Icon type="caret-down"/>}
                          valKey='pk'
                          valLabel='name'
                          style={{ width: '100%' }}
                          onChange={onFieldTouched}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item label='Max Jobs' style={{ marginBottom: '12px' }}>
                      {form.getFieldDecorator('max_jobs', {
                        rules: [{
                          required: false,
                          message: 'Please input Max Jobs',
                        }],
                        initialValue: get(user, 'max_jobs'),
                      })(
                        <Input disabled={readOnly} type='number' onChange={onFieldTouched} />
                      )}
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item label='Skill Level' style={{ marginBottom: '12px' }}>
                      {form.getFieldDecorator('skill_level', {
                        rules: [{
                          required: false,
                          message: 'Please input Skill Level',
                        }],
                        initialValue: get(user, 'skill_level'),
                      })(
                        <Input disabled={readOnly} type='number' onChange={onFieldTouched} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              )
            }}
          </FormContext.Consumer>
        </FormProvider>
      </div>
    );
  }
}

BasicProfile.propTypes = {
  readOnly: PropTypes.bool,
  user: PropTypes.object,
  onFieldTouched: PropTypes.func,
  hasErrors: PropTypes.func,
}

BasicProfile.defaultProps = {
  readOnly: false,
  user: {},
  onFieldTouched: () => null,
  hasErrors: () => null,
}

export default BasicProfile;
