import Highcharts from 'highcharts/highcharts'

const options = {
  chart: {
    type: 'column',
    height: (8 / 16 * 100) + '%',
  },
  title: {
    text: '.',
  },
  colors: [
    '#1890ff', // blue (ant design)
    '#9c88ff', // periwinkle (british) like purple
    '#fbc531', // rise-n-shine (british) like yellow
    '#4cd137', // download progress (british) like green light
    '#16a085', // green sea (palette 1) like green
    '#34495e', // wet asphalt (palette 1) like dark
    '#f5222d', // red (ant design)
    '#2980b9', // belize hole (palette 1) like blue dark
    '#f0932b', // quince jelly (aussie) like orange
    '#ff7979', // pink glamour (aussie) like red light --> or use '#c0392b' pomegranate (palette 1) like red dark
  ],
  xAxis: {}, // don't delete this line
  yAxis: {
    min: 0,
    title: {
      text: 'Total Jobs',
    },
    stackLabels: {
      enabled: true,
      verticalAlign: 'top',
      crop: false,
      overflow: 'none',
      //y: 20,
      formatter: function() {
        return this.stack
          // +'<br/>'+ `${this.total}`
      },
      style: {
        color: '#666',
        fontSize: '0.6rem',
        fontWeight: '400',
        fontFamily: '"Open Sans", Arial, sans-serif'
      }
    }
  },
  legend: {
    align: 'right',
    x: -30,
    verticalAlign: 'top',
    y: 0,
    floating: true,
    backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || 'white',
    borderColor: '#ccc',
    borderWidth: 1,
    shadow: false,
    labelFormatter: function() {
      return this.name + ' (' + this.userOptions.stack + ')';
    }
  },
  tooltip: {
    headerFormat: '<b>{point.x}</b><br/>',
    pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
  },

  plotOptions: {
    column: {
      stacking: 'normal',
      dataLabels: {
        enabled: true,
      }
    }
  },
  credits: {
    enabled: false
  },
}

export { options }
