export default [
  {
    title: 'Product code',
    type: 'text',
    data: '',
    required: true,
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'product_code',
  }, {
    title: 'Sku',
    type: 'text',
    data: '',
    required: true,
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'sku',
  }, {
    title: 'Description',
    type: 'text',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'description',
  },
]