import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Typography, Form, Input, Button, message } from 'antd'
import { get } from 'lodash'
import styled from 'styled-components'
import { FormContext, FormProvider } from 'App/Component/Context/ContextForm'
import { RequestV2 as Request } from 'App/Utils'

class ChangePassword extends React.PureComponent {
  constructor(props) {
    super(props)

    this.currentForm = null
    this.state = {
      isChangingPassword: false,
    }
  }

  validatorLength = (rule, value, callback) => {
    if (value) {
      if (value.length < 8) {
        return callback(true)
      }
    }
    return callback()
  }

  matchValidator = (rule, retypePassword, callback) => {
    if (retypePassword) {
      if (this.currentForm) {
        const password = this.currentForm.getFieldValue('new_password')
        if (retypePassword !== password) {
          if (get(this.retypeRef, ['input', 'focus'])) {
            this.retypeRef.input.focus()
          }
          return callback(true)
        }
      }
    }
    callback()
  }
  
  onChangePassword = () => {
    if (this.currentForm) {
      this.currentForm.validateFieldsAndScroll({scroll: {offsetTop: 150}}, (err, values) => {
        if (!err) {
          this.changePassword(values)
        }
      })
    }
  }

  changePassword = (values) => {
    this.setState({ isChangingPassword: true }, () => {
      Request(
        'post',
        'profile-password',
        {},
        values,
        [],
        this.changePasswordSuccess,
        this.changePasswordFailed,
      )
    })
  }

  changePasswordSuccess = (response) => {
    message.success('New password has been saved.')
    this.currentForm.resetFields()
    this.setState({ isChangingPassword: false }, () => {
      this.props.backToGeneral()
    })

  }

  changePasswordFailed = (error) => {
    message.error('New password has not been saved.')
    const errors = get(error.response, ['data'])
    if (typeof errors === 'object') {
      const fieldsError = {}
      for (const key in errors) {
        fieldsError[key] = { value: this.currentForm.getFieldValue(key), errors: [new Error(errors[key][0])] }
      }
      this.currentForm.setFields(fieldsError)
    }
    this.setState({ isChangingPassword: false })
  }

  render() {
    const { isChangingPassword } = this.state

    return (
      <Row type='flex' justify='center'>
        <ColForm>
          <Typography.Title level={2}>
            Password
          </Typography.Title>
          <FormProvider>
            <FormContext.Consumer>
              {form => {
                this.currentForm = form
                return (
                  <Row>
                    <Col>
                      <Form.Item label='Old Password' style={{ marginBottom: '12px' }}>
                        {form.getFieldDecorator('old_password', {
                          rules: [{
                            required: true,
                            message: 'Please input old password'
                          }]
                        })(
                          <Input.Password
                            ref={ref => {ref.input.focus()}}
                            autoComplete='new-password'
                            // placeholder='New password'
                            // onPressEnter={this.onResetPassword}
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item label='New Password' style={{ marginBottom: '12px' }}>
                        {form.getFieldDecorator('new_password', {
                          rules: [{
                            required: true,
                            message: 'Please input new password'
                          }, {
                            validator: this.validatorLength,
                            message: <div>Please choose a longer password.</div>
                          }]
                        })(
                          <Input.Password autoComplete='new-password' onPressEnter={this.onChangePassword} />
                        )}
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item label='Confirm New Password' style={{ marginBottom: '12px' }}>
                        {form.getFieldDecorator('confirm_new_password', {
                          rules: [{
                            required: true,
                            message: 'Please input confirm new password'
                          }, {
                            validator: this.matchValidator,
                            message: <div>The passwords do not match.</div>
                          }]
                        })(
                          <Input.Password ref={ref => {this.retypeRef = ref}} autoComplete='new-password' onPressEnter={this.onChangePassword} />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                )
              }}
            </FormContext.Consumer>
          </FormProvider>
          <Row>
            <Button block type='primary' loading={isChangingPassword} onClick={this.onChangePassword}>
              Change Password
            </Button>
          </Row>
        </ColForm>
      </Row>
    )
  }
}

ChangePassword.propTypes = {
  userReducer: PropTypes.object.isRequired,
  backToGeneral: PropTypes.func,
}

ChangePassword.defaultProps = {
  backToGeneral: () => null,
}

const ColForm = styled(Col)`
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  width: 660px;
  max-width: 100%;
  padding: 24px;
`

export default ChangePassword