export default [
  {
    title: 'Subheading',
    type: 'SelectQuery',
    data: 'subheadings',
    required: true,
    initialValue: '',
    note_text: '',
    additional: 'edit|add',
    dataIndex: 'subheading',
    selectedKey: 'pk',
    selectedValue: 'label',
    order: 0,
  }, {
    title: 'Usage',
    type: 'SelectQuery',
    data: 'lineUsages',
    initialValue: '',
    note_text: '',
    additional: 'edit|add',
    selectedKey: 'pk',
    selectedValue: 'label',
    dataIndex: 'usage',
    order: 1,
  }, {
    title: 'Sort code',
    type: 'text',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'sort_code',
    order: 2,
  }, {
    title: 'Description',
    required: true,
    type: 'text',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'description',
    order: 3,
  },
]