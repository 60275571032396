import React from 'react'
import { Card, Col, Row } from 'antd'

import { RequestV2 as Request } from 'App/Utils'
import ActiveJobs from './ActiveJobs'
import CompletedJobs from './CompletedJobs'
import WorkingSpeedStats from './WorkingSpeedStats'

class UserStatistics extends React.PureComponent {
  render() {
    return (
      <div>
        <Row type='flex' gutter={[24, 24]} style={{ padding: '12px' }}>
          <Col xs={24} sm={24} lg={12}>
            <ActiveJobs userId={this.props.match.params.id} />
          </Col>
          <Col xs={24} sm={24} lg={12}>
            <CompletedJobs userId={this.props.match.params.id} />
          </Col>
          <Col span={24}>
            <WorkingSpeedStats userId={this.props.match.params.id} />
          </Col>
        </Row>
      </div>
    )
  }
}

export default UserStatistics