import { BaseDetail } from 'App/Component/Bases'
import fields from './fields'

class PageAdd extends BaseDetail {
  constructor(props) {
    super(props)

    this.title = 'ERF Mapping'
    this.urlData = 'module-job-erfMapping'
    this.fields = fields
    this.state = {
      isCheckingPermission: false,
      isAccessAllowed: true,
    }
  }
}

export default PageAdd