import { BaseList, BaseDetail } from "App/Component/Bases";
import fields from "./fields";
import descriptor from "./descriptor";
import options from "./options";
import ComponentAutocomplete from 'App/Component/Autocomplete';

const title = "Export Mappings";
const urlData = "exportMappings";
const bulkName = "label";
const availablePermissions = ['.view_', '.add_', '.change_'];
const children = {
  appName: 'estimation',
  modelName: 'exportmappingline',
  name: 'exportMappings',
  sub: 'exportMappingLines',
  dataIndex: 'export_mapping_lines',
  identifier: {
    name: 'export_mapping',
    is_array: false
  },
  bulk_request: false,
  urlSub: 'api:admin:estimation:exportmapping:ref-export-mapping-lines-list',
  add: true,
  edit: true,
  permissions: {
    // bulk_delete: true,
    payload: true,
    // bulk_url: 'api:admin:estimation:prefillline:list',
    initialrow: 1,
    add: true
  },
  rules: {
    button: {
      type: {
        add: 'default',
        edit: 'default'
      },
      size: 'default'
    },
    input: {
      size: 'default'
    },
    select: {
      size: 'default'
    }
  },
  fields: ComponentAutocomplete['exportMappingLines']
};

class List extends BaseList {
  constructor(props) {
    super(props)

    this.title = title;
    this.urlData = urlData;
    this.bulkName = bulkName;
    this.propsList = {
      descriptor,
      exportImport: options.exportImportOptions,
    }
  }
}

class Detail extends BaseDetail {
  constructor(props) {
    super(props)

    this.title = title;
    this.urlData = urlData;
    this.bulkName = bulkName;
    this.fields = fields;
    this.children = children;
    this.propsDetail = {
      urlKeyHistory: 'api:admin:estimation:exportmapping:detail-revision',
      keyLabel: 'label',
    }
  }
}

export default { List, Detail };