import { BaseDetail } from 'App/Screens/SubModules/Component'
import fields from './fields'

class PageDetail extends BaseDetail {
  constructor(props) {
    super(props)

    this.urlKey = 'module-expertSystem-keyword-detail'
    this.title = 'Keyword'
    this.urlList = '/expert-system/keywords'
    this.headerDescriptions = [
      { key: 'name', label: 'Name' },
      // { key: 'keyword', label: 'Keyword' },
      { key: 'skip_keyword', label: 'Skip Keyword' },
      { key: 'ignore_space', label: 'Ignore Space', type: 'bool' },
      { key: 'is_fixed', label: 'Fixed', type: 'bool' },
    ]
    this.fields = fields
  }
}

export default PageDetail