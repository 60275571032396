import Estimation from "App/Screens/Estimation";
import Geo from "App/Screens/Geo";
import Job from "App/Screens/Job";
import Multisite from "App/Screens/Multisite";
import Notification from "App/Screens/Notification";
import Report from "App/Screens/Report";
import Users from "App/Screens/Users";

import SubModules from "App/Screens/SubModules";

export default {
  app: [Estimation, Geo, Job, Multisite, Notification, Report, Users],
  subModules: SubModules.App
};
