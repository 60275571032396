export default {
  "app_label": "estimation",
  "model_name": "exportmapping",
  "pagination": true,
  "columns": [
    {
      "name": "pk",
      "label": "ID",
      "help_text": null,
      "hidden": true,
      "identifier": true,
      "data_type": "int",
      "search": false,
      "sort": false
    },
    {
      "name": "merchant",
      "label": "Merchant",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": null,
      "child_path": "name",
      "search": true,
      "sort": true
    },
    {
      "name": "name",
      "label": "Name",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "ellipsis": true,
      "search": true,
      "sort": true
    },
    {
      "name": "subheading",
      "label": "Subheading",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": null,
      "child_path": "label",
      "search": true,
      "sort": true
    },
    {
      "name": "product",
      "label": "Product",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": null,
      "child_path": "name",
      "search": true,
      "sort": true
    },
    {
      "name": "usage",
      "label": "Usage",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": null,
      "child_path": "label",
      "search": true,
      "sort": true
    },
    {
      "name": "drop",
      "label": "Drop",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "bool",
      "search": false,
      "sort": false
    },
    {
      "name": "is_random_mapping",
      "label": "Is random mapping",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "bool",
      "search": false,
      "sort": false
    },
    {
      "name": "is_random_mapping_roof",
      "label": "Is random mapping roof",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "bool",
      "search": false,
      "sort": false
    },
    {
      "name": "is_random_mapping_frame",
      "label": "Is random mapping frame",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "bool",
      "search": false,
      "sort": false
    },
    {
      "name": "is_carters_random_mapping",
      "label": "Is carters random mapping",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "bool",
      "search": false,
      "sort": false
    },
    {
      "name": "is_bulk_mapping",
      "label": "Is bulk mapping",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "bool",
      "search": false,
      "sort": true
    },
    {
      "name": "modified",
      "label": "Modified",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "datetime",
      "search": false,
      "sort": true
    }
  ],
  "filter_by": [
    {
      "label": "Status",
      "name": "status",
      "type": "choice",
      "choices": [
        [
          "active",
          "Active - Anyone can use"
        ],
        [
          "inactive",
          "Inactive - No one can use"
        ],
        [
          "unpublished",
          "Unpublished - Only certain people can use"
        ]
      ]
    },
    {
      "label": "Drop",
      "name": "drop",
      "type": "switch"
    },
    {
      "label": "Is random mapping",
      "name": "is_random_mapping",
      "type": "switch"
    },
    {
      "label": "Is random mapping roof",
      "name": "is_random_mapping_roof",
      "type": "switch"
    },
    {
      "label": "Is random mapping frame",
      "name": "is_random_mapping_frame",
      "type": "switch"
    },
    {
      "label": "Is carters random mapping",
      "name": "is_carters_random_mapping",
      "type": "switch"
    },
    {
      "label": "Is bulk mapping",
      "name": "is_bulk_mapping",
      "type": "switch"
    },
    {
      "label": "Product",
      "name": "product",
      "type": "api_reference",
      "api": {
        "url_name": "api:admin:estimation:product:autocomplete",
        "key": "pk",
        "display": "name",
        "pagination": true
      }
    },
    {
      "label": "Usage",
      "name": "usage",
      "type": "api_reference",
      "api": {
        "url_name": "api:admin:estimation:lineusage:autocomplete",
        "key": "pk",
        "display": "label",
        "pagination": true
      }
    },
    {
      "label": "Subheading",
      "name": "subheading",
      "type": "api_reference",
      "api": {
        "url_name": "api:admin:estimation:subheading:autocomplete",
        "key": "pk",
        "display": "label",
        "pagination": true
      }
    },
    {
      "label": "Merchant",
      "name": "merchant",
      "type": "api_reference",
      "api": {
        "url_name": "api:admin:jobs:merchant:autocomplete",
        "key": "pk",
        "display": "name",
        "pagination": true
      }
    },
    {
      name: 'product_code',
      label: 'Product Code',
      type: 'text',
    },
    {
      name: 'start_product_code',
      label: 'Start Product Code',
      type: 'text',
    },
    {
      name: 'end_product_code',
      label: 'End Product Code',
      type: 'text',
    },
  ],
  "export_import": null
}