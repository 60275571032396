import React from "react";
import { Button } from "antd";
import { get } from "lodash";
import { BaseList, BaseDetail } from "App/Component/Bases";
import fields from "./fields";
import ComponentAutocomplete from "App/Component/Autocomplete";
import descriptor from "./descriptor";
import options from "./options";
import OrderManagement from "./OrderManagement";
import Management from "./Management";
import { forcePermissions } from "App/Data";

const title = "Override Groups";
const urlData = "overrideSetGroups";
const availablePermissions = ['.view_', '.add_', '.change_'];
const children = {
    appName: 'estimation',
    modelName: 'overrideset',
    name: 'overrideSetGroups',
    sub: 'overrideSets',
    dataIndex: 'override_sets',
    identifier: {
      name: 'override_groups',
      is_array: true
    },
    urlSub: 'api:admin:estimation:overridesetgroup:ref-members-list',
    bulk_request: true,
    add: true,
    edit: true,
    data: false,
    permissions: {
      async_fields: 'override_sets',
      bulk_delete: false,
      bulk_url: '',
      payload: true,
      initialrow: 1,
      add: true
    },
    rules: {
      button: {
        type: {
          add: 'default',
          edit: 'default'
        },
        size: 'default'
      },
      input: {
        size: 'default'
      },
      select: {
        size: 'default'
      }
    },
  fields: ComponentAutocomplete['overrideSets']
  }

class List extends BaseList {
  constructor(props) {
    super(props)
    
    this.title = title;
    this.urlData = urlData;
    this.availablePermissions = availablePermissions;
    this.propsList = {
      descriptor,
      exportImport: options.exportImportOptions,
      getAnotherActions: () => (
        <Button icon='ordered-list' onClick={() => this.props.history.push('/admin/estimation/override-set-groups/order-management')}>
          Order Management
        </Button>
      ),
      onDetailClick: this.onDetailClick,
    }
    this.state = {
      ...this.state,
      currentPermissionSet: this.isSuperuser ? this.filterPermissionItems(forcePermissions) : [],
    }
  }

  onDetailClick = (record) => {
    this.props.history.push(`/admin/estimation/override-set-groups/${record.pk}?section=${get(record.section, 'pk')}&subsection=${get(record.subsection, 'pk')}`)
  }
}

class Detail extends BaseDetail {
  constructor(props) {
    super(props)

    this.title = title
    this.urlData = urlData
    this.bulkName = "name"
    this.fields = fields
    this.children = children
    this.availablePermissions = availablePermissions
    this.propsDetail = {
      urlKeyHistory: 'api:admin:estimation:overridesetgroup:detail-revision',
      keyLabel: 'name'
    }
    this.state = {
      ...this.state,
      currentPermissionSet: this.isSuperuser ? this.filterPermissionItems(forcePermissions) : [],
    }
  }

  getAllowedComponent = () => (
    <Management {...this.getPropsComp()} />
  )
}

export default { List, Detail, OrderManagement };
