export default [
  {
    key: 'status',
    label: 'Status',
  }, {
    key: 'dry_run',
    label: 'Dry Run',
    type: 'bool',
  }, {
    key: 'file_source',
    label: 'File',
    type: 'file',
    display: 'file_name',
    link: 'file',
  }, {
    key: 'created',
    label: 'Created',
    type: 'datetime',
  }, {
    key: 'created_by.full_name',
    label: 'Created by',
  },
]