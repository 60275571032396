import React from 'react'
import PropTypes from 'prop-types'
import { Button, Card, Col, Icon, Row, Tabs } from 'antd'
import { get } from 'lodash'
import { PageGeneral } from './Pages'
import { Lib, RequestV2 as Request } from 'App/Utils'
import PageError from 'App/Component/PageError'
import LoadingSpin from 'App/Component/LoadingSpin'

const { TabPane } = Tabs
const statesTag = {
  new: {
    label: 'New',
    color: '#18e000',
  },
  sending: {
    label: 'Sending',
    color: '#f6652d'
  },
  sent: {
    label: 'Sent',
    color: '#1890ff'
  }
}

class BroadcastDetail extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isRetrieve: props.isRetrieve,
      broadcast: null,
      errorResponse: null,
    }
  }

  onGoBack = () => {
    this.props.history.push('/admin/notifications/broadcast')
  }

  preRetrieve = () => {
    if (this.state.isRetrieve) {
      this.retrieve()
    }
  }

  reload = () => {
    const keyLoading = 'isRetrieve'
    this.setState({ [keyLoading]: true }, () => {
      this.retrieve(keyLoading)
    })
  }

  retrieve = (keyLoading = 'isRetrieve', callback = () => null) => {
    Request(
      'get',
      this.props.urlKeyDetail,
      {},
      {},
      [this.props.match.params.id],
      this.retrieveSuccess,
      this.retrieveFailed,
      { callback, keyLoading }
    )
  }

  retrieveSuccess = (response, extra) => {
    this.setState({ [extra.keyLoading]: false, onSaving: false, broadcast: response.data }, () => {
      extra.callback(true)
    })
  }

  retrieveFailed = (error, extra) => {
    this.setState({ [extra.keyLoading]: false, onSaving: false, broadcast: null, errorResponse: get(error, 'response') }, () => {
      extra.callback()
    })
  }

  onSave = (body, callback) => {
    Request(
      this.props.methodSave,
      this.props.urlKeySave,
      {},
      body,
      [this.props.match.params.id],
      this.onSaveSuccess,
      this.onSaveFailed,
      {
        callback: (isSuccess, response) => {
          if (isSuccess) {
            const id = response.data.pk
            if (this.props.isAddMode) return this.props.history.push(`/admin/notifications/broadcast/${id}`)
            else this.setState({ broadcast: response.data })
          }
          callback(isSuccess, response)
        }
      }
    )
  }

  onSaveSuccess = (response, extra) => {
    extra.callback(true, response)
  }

  onSaveFailed = (error, extra) => {
    // Lib.errorMessageHandler(get(error, 'response'))
    extra.callback(false, get(error, 'response'))
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.isRetrieve !== nextProps.isRetrieve) {
      this.setState({ isRetrieve: nextProps.isRetrieve, broadcast: null }, () => {
        this.preRetrieve()
      })
    }
  }

  componentDidMount() {
    this.preRetrieve()
  }

  render() {
    const { isAddMode, match } = this.props
    const { broadcast, isRetrieve, errorResponse } = this.state
    const stateTag = get(statesTag, get(broadcast, 'state'))

    return (!isAddMode && !isRetrieve && !broadcast) ? (
      <PageError
        errorResponse={errorResponse}
        onGoBack={this.onGoBack}
        onReload={this.retrieve}
      />
    ) : (
      <Card
        title={
          <Row type='flex' justify='space-between' align='middle'>
            <Col>
              <Row type='flex' align='middle'>
                Broadcast
                {!isAddMode && stateTag && (
                  <Col style={{ fontSize: '12px', fontWeight: 500, backgroundColor: stateTag.color, color: '#fff', padding: '0 12px 2px 12px', borderRadius: '14px', marginLeft: '12px' }}>
                    {stateTag.label}
                  </Col>
                )}
              </Row>
            </Col>
            {!isAddMode && (
              <Col>
                <Button icon='reload' onClick={this.reload}>
                  Refresh
                </Button>
              </Col>
            )}
          </Row>
        }
        style={{ borderBottom: '12px', marginBottom: '16px' }}
        bodyStyle={{ padding: '0 0 24px 0' }}
      >
        {isRetrieve ? (
          <LoadingSpin tip='Loading...' height='calc(100vh - 200px)' />
        ) : (
          <Row>
            <Tabs className='custom-margin-tab' tabBarGutter={0}>
              <TabPane key='general' tab={<span><Icon type='setting' />General</span>}>
                <div style={{ padding: '0 24px' }}>
                  <PageGeneral
                    onSave={this.onSave}
                    onGoBack={this.onGoBack}
                    data={broadcast}
                  />
                </div>
              </TabPane>
            </Tabs>
          </Row>
        )}
      </Card>
    )
  }
}

export default BroadcastDetail