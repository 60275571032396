import { RequestV2 as Request } from "App/Utils";

export default [
  {
    title: 'Tag',
    type: 'SelectQuery',
    data: 'tags',
    required: true,
    initialValue: null,
    dataIndex: 'tag',
    selectedKey: 'pk',
    selectedValue: 'label',
    paramProps: { allow_override: true },
    note_text: 'Choose tag with allow override to override tag content of the merchant.',
    // additional: 'edit|add', // error jika ada field yang memiliki key function, history push, state tidak boleh ada function
    specialFunction: function (tag, form, operation) {
      if (tag && operation === 'add') {
        Request(
          'get',
          'tags-detail',
          {},
          {},
          [tag.key || tag.pk],
          response => {
            form.setFieldsValue({ content: response.data.content })
          },
          error => {},
        )
      }
    }
  }, {
    title: 'Merchant',
    type: 'SelectQuery',
    data: 'merchants',
    required: true,
    initialValue: null,
    dataIndex: 'merchant',
    selectedKey: 'pk',
    selectedValue: 'name',
    // additional: 'edit|add', // error jika ada field yang memiliki key function, history push, state tidak boleh ada function
  }, {
    title: '',
    type: 'textarea',
    dataIndex: 'content',
    initialValue: '',
    colProps: { span: 24, md: 24 },
    formProps: {
      labelCol: {span: 0},
      wrapperCol: {span: 24},
    },
    inputProps: {
      placeholder: 'Content',
      rows: 13,
      autoSize: { minRows: 13, maxRows: 21 }
    },
  },
]
