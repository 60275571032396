import { BaseList } from "App/Screens/SubModules/Component";
import descriptor from "App/Screens/ERF/ErfTypes/descriptor";

class ErfTypeList extends BaseList {
  constructor(props) {
    super(props)

    this.appName = 'jobs'
    this.modelName = 'erftype'
    this.propsList = {
      avatar: 'label',
      pageTitle: 'ERF',
      descriptor,
      masterKey: 'module-job-erfType',
    }
    this.availablePermissions = ['.view_', '.change_']
    this.state = {
      ...this.state,
      currentPermissionSet: this.isSuperuser ? this.availablePermissions : [],
    }
  }
}

export default ErfTypeList