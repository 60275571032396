export default {
  "app_label": "estimation",
  "model_name": "linetemplate",
  "pagination": true,
  "columns": [
    {
      "name": "pk",
      "label": "ID",
      "help_text": null,
      "hidden": true,
      "identifier": true,
      "data_type": "int",
      "search": false,
      "sort": false
    },
    {
      "name": "label",
      "label": "Label",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "ellipsis": true,
      "search": true,
      "sort": true
    },
    {
      "name": "order",
      "label": "Order",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "int",
      "search": true,
      "sort": true
    },
    {
      "name": "section",
      "label": "Section",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": null,
      "child_path": "name",
      "search": true,
      "sort": true
    },
    {
      "name": "subsection",
      "label": "Subsection",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": null,
      "child_path": "identifier",
      "search": true,
      "sort": true
    },
    {
      "name": "status",
      "label": "Status",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "search": true,
      "sort": true
    },
    {
      "name": "modified",
      "label": "Modified",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "datetime",
      "search": false,
      "sort": true
    }
  ],
  "filter_by": [
    {
      "label": "Status",
      "name": "status",
      "type": "choice",
      "choices": [
        [
          "active",
          "Active - Anyone can use"
        ],
        [
          "inactive",
          "Inactive - No one can use"
        ],
        [
          "unpublished",
          "Unpublished - Only certain people can use"
        ]
      ]
    },
    {
      "label": "Is highlighted",
      "name": "is_highlighted",
      "type": "switch"
    },
    {
      "label": "Extra1 Widget",
      "name": "extra1_widget",
      "type": "choice",
      "choices": [
        [
          "numeric",
          "Numeric"
        ],
        [
          "text",
          "Text"
        ],
        [
          "choices",
          "Choices"
        ]
      ]
    },
    {
      "label": "Extra2 Widget",
      "name": "extra2_widget",
      "type": "choice",
      "choices": [
        [
          "numeric",
          "Numeric"
        ],
        [
          "text",
          "Text"
        ],
        [
          "choices",
          "Choices"
        ]
      ]
    },
    {
      "label": "Has selected lengths",
      "name": "has_selected_lengths",
      "type": "switch"
    },
    {
      "label": "Automatic",
      "name": "automatic",
      "type": "switch"
    },
    {
      "label": "Hide extra info",
      "name": "hide_extra_info",
      "type": "switch"
    },
    {
      "label": "Zero negative warning",
      "name": "zero_negative_warning",
      "type": "switch"
    },
    {
      "label": "Has labour",
      "name": "has_labour",
      "type": "switch"
    },
    {
      "label": "Section",
      "name": "section",
      "type": "api_reference",
      "api": {
        "url_name": "api:admin:estimation:section:autocomplete",
        "key": "pk",
        "display": "name",
        "pagination": true
      }
    },
    {
      "label": "Subsection",
      "name": "subsection",
      "type": "api_reference",
      relates: ['section'],
      "api": {
        "url_name": "api:admin:estimation:subsection:autocomplete",
        "key": "pk",
        "display": "name",
        "pagination": true
      }
    },
    {
      "label": "Default usage",
      "name": "default_usage",
      "type": "api_reference",
      "api": {
        "url_name": "api:admin:estimation:lineusage:autocomplete",
        "key": "pk",
        "display": "label",
        "pagination": true
      }
    },
    {
      "label": "Default subheading",
      "name": "default_subheading",
      "type": "api_reference",
      "api": {
        "url_name": "api:admin:estimation:subheading:autocomplete",
        "key": "pk",
        "display": "label",
        "pagination": true
      }
    },
    {
      "label": "Unit",
      "name": "unit",
      "type": "api_reference",
      "api": {
        "url_name": "api:admin:estimation:unit:autocomplete",
        "key": "pk",
        "display": "name",
        "pagination": true
      }
    },
    {
      "label": "Formula",
      "name": "formula",
      "type": "api_reference",
      "api": {
        "url_name": "api:admin:estimation:formula:autocomplete",
        "key": "pk",
        "display": "name",
        "pagination": true
      }
    }
  ],
  "export_import": null
}