// Setting for Dynamic editable
// permissions : {
  //   name: 'parent', - parent name
  //   sub: 'children', - children name
  //   urlSub: 'api:admin:jobs:merchantgroup:ref-merchants-list', - url read children data
  //   bulk_request: true, - allow true if using url bulk request
  //   add: true, - allow true if you want show it on add mode
  //   edit: true, - allow true if you want show it on edit mode
  //   data: true, - allow true if parent has data (no need request again)
  //   identifier: 'override_group', - identifier of this children
  //   permissions: {
  //     payload: true, - allow true if using contentType json/application
  //     bulk_delete: true, - allow true if you want allowing bulk request or selection
  //     bulk_url: 'url', - url for bulk_delete
  //     initialrow: 2, - count of row for first load
  //     add: false, - allow true if you want allowing add row or fields
  //     rowfixed: true - allow true if row cannot use add or delete (only reset fields)
  //     duplicate: {
  //         name: 'size', - name of column cannot duplicate
  //         label: 'Size', - Label of column cannot duplicate
  //         dataIndex: 'size.label', - map data of column
  //         message: 'Size cannot duplicate' - message if column has duplicate
  //       }
  //   },
  // form_rules: {
  //   must_sent: [
  //     {
  //       name: 'branch',
  //       default_value: null
  //     },
  //     {
  //       name:'operating_branch',
  //       default_value: null
  //     },
  //     {
  //       name: 'extra_charge',
  //       default_value: null
  //     }],
  // },
  //   rules: {
  //     button: {
  //       type: {
  //         add: 'default', - type button add on row
  //         edit: 'default' - type button edit on row
  //       },
  //       size: 'medium' - size of button on row
  //     },
  //     input: {
  //       size: 'medium' -  size input on row
  //     }
  //   }
  // }

import React, {Component, Fragment} from 'react';
import {
  Button,
  Col,
  Divider,
  message,
  Popconfirm,
  Row,
  Table,
  Modal,
  Card,
  Tooltip, Affix,
} from "antd";
import {Lib, Request} from "App/Utils";
import styled from "styled-components";
import ModalImport from "App/Component/ModalImport";
import DrawerSQ from "App/Component/DrawerSQ";
import {capitalize, get, startCase, lowerCase, find, orderBy, isObject, cloneDeep, isEmpty, snakeCase} from "lodash";
import EditableCell from "../Edit/EditableCellV2";
import Lib2 from "./Lib2";
import {EditableFormRow} from "App/Component/Edit/EditableRowV2";
import validate from "validate.js";
import PropTypes from "prop-types";

const ButtonGroup = Button.Group;
class DynamicEditTable extends Component {
  constructor(props){
    super(props)
    this.state = {
      isLoading: false,
      count_search: 0,
      count: 0,
      current: 0,
      count_row: 0,
      next: null,
      visibleModalImport: false,
      visibleModal: false,
      selectedRowKeys: [],
      visibleBulk: false,
      loadingLoadmore: false,
      selectedRows: [],
      searchTag: [],
      // onSelectAll: false,
      filterTag: [],
      visibleDrawerSQ: false,
      operationSQ: '',
      historySubDrawer: [],
      indexing: -1,
      dataRecord: [],
      editingKey: '',
      cols: [],
      params: []
    };

    this.ordering = '-modified';
    this.page = 1;
    this.msg = '';
    this.limit = 50;
    this.recentKey = [];
}

  componentDidMount() {
    const { changeData, operation, permissions } = this.props;
    const newData = []
    const init = get(permissions, 'permissions.initialrow')
    const rules = get(permissions, 'rules')
    if(init){
      for(let i = 1; i <= init; i++ ){
        newData.push({pk: Lib.uuid()})
      }
    }
    changeData('subheaderData', newData)
    let columns = []
    const cols = cloneDeep(permissions.fields)
    cols.map((col) => {
      if (col.editable || col.view) {
        col.sorter = false
        columns.push({
          ...col,
          onCell: record => ({
            record,
            inputType: col.type,
            params: col.paramProps,
            newRules: rules,
            editable: col.editable,
            dataIndex: col.dataIndex,
            fieldKey: col.fieldKey,
            initialValue: col.initialValue,
            urlKey: col.data,
            static_data: col.static_data,
            inputProps: col.inputProps,
            selectedKey: col.selectedKey,
            selectedValue: col.selectedValue,
            title: col.title,
            additional: col.additional,
            toggleDrawerSQ: this.toggleDrawerSQ,
            required: col.required ? col.required : false,
            userReducer : this.props.userReducer,
            editing: true,
            normalizeFile: this.normalizeFile
          }),
          render: record => (
            <div style={{ whiteSpace: "nowrap" }}>
              {record}
            </div>
          )
        })
      }
    });
    this.setState({
      cols: columns
    }, ()=>{
      operation === 'edit' && this.read(permissions.data)
    })
  }

  queryParam = () =>{
    let objurl = {
      ordering: this.ordering,
      page: this.page,
      _mode: 'full',
      limit: this.limit
    }
    return objurl
  }


  read=(hasdata=undefined, loadmore=false)=>{
    if(!loadmore) this.setState({isLoading: true})
    const p = this.props
    const paramProps = get(p, 'children.paramProps')

    if(hasdata){
      let res = get(p, 'dataRecord')
      if(res) this.readSuccess({data: {results: res[p.permissions.dataIndex]}})
    }else {
      const params = {...this.queryParam()}
      
      if(paramProps) {
        for(const i in paramProps){
          if(paramProps[i] === 'pk') params[i] = get(p, 'dataRecord.pk')
          else params[i] = paramProps[i]
        }
      }

      this.request = Request(
        "get",
        p.urlSub,
        {Authorization: `Bearer ${p.userReducer.token}`},
        params,
        [get(p, 'dataRecord.pk')],
        this.readSuccess,
        (r) => {
          this.setState({isLoading: false, loadingLoadmore: true})
          message.error('get data Failed')
        },
        loadmore,
      );
    }
  }

  readSuccess=(res, loadmore)=>{
    let newData = []
    const {permissions, setThisState, data} = this.props
    let extra;
    if(permissions.permissions.rowfixed)
      for(let i=1; i <= permissions.permissions.initialrow; i++){
        newData.push({pk: Lib.uuid()})
      }
    else
      for(const i of res.data.results){
        if(permissions){
          if(permissions.sub==='plans'){
            extra = Lib2.reFormatFile(i)
          }
        }
        newData.push({pk: Lib.uuid(), ...extra})
      }
    if(loadmore) {
      this.recentKey = newData
      newData = [...data, ...newData]
    }
    setThisState({subheaderData: newData}, ()=>{
      this.setState({dataRecord: res.data.results},()=>{
        const p = this.props
        const s = this.state
        let cloneSD = []
        let index = 0
        if(!loadmore)
          for(const i in p.data){
            if(index === s.dataRecord.length && permissions.permissions.rowfixed)
              break
            index+=1
            this.relationGenerate(p.data[i].pk, res.data.results[i])
            Lib2.setDataForm(p.data[i].form, res.data.results[i], permissions.fields)
            cloneSD.push({pk: s.dataRecord[i].pk, key: p.data[i].pk})
          }
        else {
          let r = this.recentKey
          for(const i in r){
            let key = find(p.data, {pk: r[i].pk})
            if(key) {
              Lib2.setDataForm(key.form, res.data.results[i], permissions.fields)
              cloneSD.push({pk: s.dataRecord[i].pk, key: r[i].pk})
            }
          }
          cloneSD = [...p.cloneSD, ...cloneSD]
          this.setState({loadingLoadmore: false})
          this.recentKey = []
        }
        p.changeData('cloneSD', cloneSD)
      })
    })
    this.setState({isLoading: false, next: res.data.next})
  }

  // getDetail=(s)=>{
  //   const p = this.props
  //   const {dataRecord} = this.state
  //   Request(
  //     "get",
  //     p.urlTab+"-detail",
  //     {Authorization: `Bearer ${p.userReducer.token}`},
  //     {},
  //     [dataRecord[s].pk],
  //     (r)=>{
  //       Lib2.setDataForm(p.data[s].form, r.data)
  //     },
  //     ()=>null
  //   )
  // }

  successDelete=(key, r=null)=>{
    const p = this.props
    if(get(r, 'status')) {
      const async_field = get(p, 'permissions.permissions.async_fields')
      const exclude = p.cloneSD.find(e => e.key === key)
      Lib2.decrementVal(async_field, p.parentForm, exclude)
      this.limit -= 1
      if(this.limit === 0) {
        this.page = 0
        this.limit = 50
      }
    }
    let count = this.state.count-1
    if(get(p.permissions, 'permissions.rowfixed')) {
      const f = p.data.find(e => e.pk === key)
      p.changeData({cloneSD: p.cloneSD.filter(e => e.key !==key)})
      if(f){
        f.form.resetFields()
        count += 1
      }
    }else
      p.setThisState({
        subheaderData: p.data.filter(e => e.pk !== key),
        cloneSD: p.cloneSD.filter(e => e.key !==key),
        count
      })

    this.setState(({
      isLoading: false,
      count: count,
    }), ()=>{
      if (r) message.success('Delete Success');
      else message.success('No data will be deleted, because the data has not been saved.')
    })
  }

  handleDelete = (key) => {
    const p = this.props
    const f = p.cloneSD.find(e => e.key === key)
    if(f){
      this.setState({isLoading: true})
      this.request = Request(
        "delete",
        p.urlTab+"-delete",
        {Authorization: `Bearer ${this.props.userReducer.token}`},
        {},
        [f.pk],
        (r)=>this.successDelete(key, r),
        ()=>message.error('Delete Failed'),
      );
    }else {
      this.successDelete(key)
    }
  };

  handleTableChange = (pagination, filters, ordering) => {
    const p = this.props
    const s = ordering.columnKey
    if(s){
      let data = orderBy(p.data, [s], [ordering.order ? ordering.order.slice(0, -3) : 'asc'])
      p.changeData('subheaderData',data)
    }
  };

  toggleDrawerSQ = (operationSQ, urlSQ = 'overrideLines', idData = null, keyform=null, callback = () => null) => {
    if(keyform)
      this.setState({keyform}, () => callback())
    if (operationSQ === 'back') {
      let popHistory = this.state.historySubDrawer, visibleDrawerSQ = true;
      popHistory.pop();

      if (popHistory.length < 1) {
        visibleDrawerSQ = false
      }

      this.setState((prevState) => ({
        historySubDrawer: popHistory,
        visibleDrawerSQ: visibleDrawerSQ,
        indexing: prevState.indexing - 1,
      }), () => callback());

    } else if (operationSQ === 'close') {
      this.setState((prevState) => ({
        historySubDrawer: [],
        visibleDrawerSQ: false,
        indexing: -1,
      }), () => callback());

    } else {
      let history = {
        idData: idData,
        url: urlSQ,
        historyOperation: operationSQ
      };

      this.setState((prevState) => ({
        visibleDrawerSQ: true,
        operationSQ,
        historySubDrawer: [...prevState.historySubDrawer, history],
        indexing: prevState.indexing + 1
      }), () => callback());
    }
  };

  isEditing = record => {
    return record.pk === this.state.editingKey;
  };

  cancel = (pk) => {
    const data = [...this.props.data];
    const checkTemp = Lib.checkIsTemp(pk);
    this.props.changeData('subheaderData',data.filter(item => item.pk !== pk))
    if(checkTemp) {
      this.setState({
        editingKey: '',
        count: this.state.count -1
      });
    }else{
      this.setState({editingKey: ''});
    }
  };

  handleAdd = () => {
    const { data, changeData } = this.props;
    const static_pk = Lib.uuid();
    const newData = {
      pk: static_pk,
    };
    data.push(newData)
    changeData('subheaderData', data)
    this.setState((state)=>({
      count: state.count + 1
    }));
  };

  formOverrideData =(val)=>{
    const {data, changeData} = this.props
    let r = data.findIndex(e => !e.form)
    if(r >= 0) {
      data[r].form = val
      changeData('subheaderData', data)
    }
    return null
  }

  rowOverride = (p)=>{
    return <EditableFormRow {...p} overrideform={this.formOverrideData}/>
  }

  relationGenerate =(key, data)=>{
    for(const res in data){
      if(isObject(data[res]))
        this.changeSQ(res, { key: data[res].pk }, null, key)
    }
  }

  onFieldBlur = (value, previousValue, dataIndex=null, validate={}) => {
    if ((value || previousValue) && value !== previousValue) {
      this.props.setTouched(true)
    }
    if(dataIndex && !isEmpty(validate.form))
      this.changeSQ(dataIndex, value, validate.form, validate.pk)
  }

  changeSQ = (index, value, form, key) => {
    const { permissions } = this.props
    if (permissions.fields) {
      const obj = {}
      for (const i of permissions.fields) {
       if(i.dataIndex === index){
         if(!isEmpty(i.relations)){
           for(const de of i.relations){
              if(value)
                obj[de] = { [index]: value.key }
              if(form)
                form.setFieldsValue({ [de]: undefined })
           }
         }
       }
      }
      if (!isEmpty(obj)) {
        const { params } = this.state
        let prev = params.find(e => e.key === key)
        if(prev){
          prev.data = {...prev.data, ...obj}
        }else{
          params.push({ key, data: obj })
        }
        this.setState({ params })
      }
    }
};

  renderField = (props) => {
    const { filterSelected } = this.props
    // return null
    let obj = {}
    if(props.record){
      const sw = this.state.params.find(e => e.key === props.record.pk)
      if(sw) obj = sw.data
    }
    const params = {...filterSelected, ...obj}
    return <EditableCell {...props} /*fieldOrder={get(children, 'fieldOrder', [])}*/ filterSelected={params} readOnly={this.props.readOnly} onBlur={this.onFieldBlur} />
  }

  setForm=(val)=>{
    let obj = this.props.data.find(e => e.pk === this.state.keyform)
    if (obj && typeof val === 'object') {
      let keyField = ''
      for (const key in val) { keyField = key; break; }
      const valField = { key: `${val[keyField].key}`, label: val[keyField].label }
      obj.form.setFieldsValue({ [keyField]: valField })
    }
  }

  bulkSuccess=(r, e)=>{
    if(get(r, 'data.detail')) {
      let t = r.data.detail.match(/\d+/)
      if(!isEmpty(t)) {
        this.limit -= t[0]
        if(this.limit === 0){
          this.page = 0
          this.limit = 10
        }
      }
    }
    this.props.setThisState({
      subheaderData: e.data,
      cloneSD: e.cloneSD
    })
    this.setState({selectedRowKeys: [], isLoading: false})
    message.success('delete successfully')
  }

  onFailed=(r)=>{
    message.error('Failed')
  }

  bulkRequest=(init, cloneSD, data)=>{
    const p = this.props
    if(!validate.isEmpty(init)) {
      let obj = {
        ids: init,
        delete_bulk: true
      }
      this.request = Request(
        'put',
        p.permissions.permissions.bulk_url,
        {Authorization: `Bearer ${p.userReducer.token}`},
        obj,
        [],
        this.bulkSuccess,
        this.onFailed,
        {cloneSD: cloneSD, data: data},
        false,
        'bulk-action/'
      )
    }else {
      this.bulkSuccess(null, {cloneSD: cloneSD, data: data})
    }
  }

  bulkDelete=()=>{
    const p = this.props
    const s = this.state
    let u = []
    let a = p.data
    let b = p.cloneSD
    for(const i of s.selectedRowKeys) {
      let y = b.findIndex(e => e.key === i)
      if (y >= 0) {
        u.push(b[y].pk)
        b[y].deleted = true
      }
      let x = a.findIndex(e => e.pk === i)
      if(x >= 0)
        a[x].deleted = true
    }
    this.bulkRequest(u, b.filter(j => !j.deleted), a.filter(j => !j.deleted))
  }

  showConfirm=()=>{
    const {urlTab} = this.props
    const { confirm } = Modal
    confirm({
      title: 'Do you Want to delete these items?',
      content: 'All selected '+lowerCase(urlTab)+' will deleted permanently',
      okText: 'Delete',
      okType: 'danger',
      onOk:()=> {
        this.setState({isLoading: true},()=>this.bulkDelete())
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  // onSelectAll=(e)=>{
  //   this.setState({onSelectAll: e})
  // }

  render() {
    const {cols, isLoading, operationSQ, loadingLoadmore, visibleDrawerSQ, next, indexing, historySubDrawer, dataRecord, selectedRowKeys} = this.state;
    const {readOnly, allowDelete, data, permissions, urlTab} = this.props
    // console.log(this.props)
    let columns = [];
    cols.map((e)=>{
      columns.push(e)
    })

    const operation = {
      key: '1',
      title: 'Delete',
      // fixed: 'right', // BUG if fixed activate on editable model
      width: '60px',
      render: (text, record) => {
        return (
          <Row>
            <Col>
                <Popconfirm
                  title="Sure to delete?"
                  onConfirm={(e) => {
                    this.handleDelete(record.pk)
                    e.stopPropagation()
                }}
                  onCancel={(e)=>e.stopPropagation()}
                >
                  <Button
                    size={get(permissions, 'rules.button.size', 'medium')}
                    type="danger"
                    onClick={(e)=>e.stopPropagation()}
                    icon="delete"
                  />
                </Popconfirm>

            </Col>
          </Row>
        )
      },
    };
    if (!readOnly && allowDelete) columns.push(operation);

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      // onSelectAll: this.onSelectAll,
    }
    let y = {}
    if(permissions.permissions.bulk_delete)
      y.rowSelection = rowSelection

    return (
      <Fragment>
        <Divider dashed/>
        <RoundedCard size="small" title={startCase(urlTab)}>
          {/*<Affix offsetTop={16}>*/}
          <Row style={{marginBottom: '1rem'}}>
            <Col xs={18}>
              {!isEmpty(selectedRowKeys) &&
              <>
                <Button type="danger" onClick={this.showConfirm} icon="delete">
                  Delete selected
                </Button>
                <Divider type="vertical"/>
              </>
              }
              <ButtonGroup>
                <Button
                  icon="plus"
                  type="dashed"
                  disabled={!get(permissions, 'permissions.add', false) || readOnly}
                  onClick={() => {this.handleAdd()}}
                >
                  Add {startCase(urlTab)}
                </Button>
              </ButtonGroup>
              <Divider type="vertical"/>
              {/* <ButtonGroup>
                <Tooltip placement="bottomLeft" title="Import Data">
                  <Button disabled={readOnly} type="primary" icon="vertical-align-top" onClick={() => this.setState({visibleModalImport: true})}/>
                </Tooltip>
                <Tooltip placement="bottomLeft" title="Export Data">
                  <Button disabled={readOnly} type="primary" icon="vertical-align-bottom"/>
                </Tooltip>
              </ButtonGroup> */}
            </Col>
            <Col xs={6}>
            </Col>
          </Row>
          {/*</Affix>*/}
          <Row>
            <Col xs={24}>
              <Table
                scroll={{ x: 'max-content' }}
                components={{
                  body: {
                    row: this.rowOverride,
                    cell: this.renderField,
                  }
                }}
                rowClassName="editable-row"
                size="small"
                rowKey={"pk"}
                loading={isLoading}
                dataSource={data}
                columns={columns}
                onChange={this.handleTableChange}
                pagination={false}
                footer={()=> {
                    if(next)
                      return <Button loading={loadingLoadmore} onClick={()=>{
                        this.page += 1
                        this.read(undefined, true)
                        this.setState({loadingLoadmore: true})
                      }} type="danger"> Load more . . .</Button>
                    else
                      return null
                  }
                }
                {...y}
              />
            </Col>
          </Row>
        </RoundedCard>
        { this.state.visibleModalImport &&
        <ModalImport
          {...this.props}
          visible={this.state.visibleModalImport}
          toggle={() => {
            this.setState((prevState) => ({
              visibleModalImport: !prevState.visibleModalImport
            }))
          }}
          title={"Import "+startCase(permissions.sub)}
        /> }

        <DrawerSQ
          {...this.props}
          visible={visibleDrawerSQ}
          toggleDrawerSQ={this.toggleDrawerSQ}
          oneForm={this.setForm}
          getValuesWithFile={Lib2.getValuesWithFile}
          title={`${capitalize(operationSQ)} Record`}
          operationSQ={operationSQ}
          operation={operationSQ}
          historySubDrawer={historySubDrawer}
          indexing={indexing}
          dataRecord={dataRecord}
          // reloadRead={this.read}
          isTable={true}
        />
      </Fragment>
    );
  }
}

export default DynamicEditTable;

DynamicEditTable.propTypes = {
  readOnly: PropTypes.bool,
  permissions: PropTypes.object,
  setTouched: PropTypes.func,
  allowDelete: PropTypes.bool,
  filterSelected: PropTypes.object,
}

DynamicEditTable.defaultProps = {
  readOnly: false,
  setTouched: () => null,
  allowDelete: true,
  filterSelected: {},
}

const RoundedCard = styled(Card)`
  border-radius: 12px !important;
`;
