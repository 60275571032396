import React,{Fragment} from 'react';
import PropTypes from 'prop-types';
import {Input, Button, InputNumber, Select, Checkbox, Form, Icon, Upload, Col} from 'antd';
import SelectQuery from 'App/Component/SelectQuery';
import {EditableContext} from './EditableRowV2'
import Lib from 'App/Utils/Lib'
import {get, isEmpty} from 'lodash'
const {Option} = Select;

class EditableCell extends React.PureComponent {

  state = {
    fileList : []
  }

  componentDidMount() {
    const p = this.props
    if(p.record){
      if(p.record.file)
        this.setState({fileList: p.record.file})
    }
  }

  multipleValidator = (rule, value, callback) => {
    if (value && value.length > 0) {
      const { minSelected, maxSelected } = this.props
      if (minSelected && minSelected > value.length) {
        callback(true)
        return
      }
      if (maxSelected && maxSelected < value.length) {
        callback(true)
        return
      }
    }
    callback()
  }

  normalizeFile = (info)=>{
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);

    fileList = fileList.map(file => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    this.setState({ fileList });
  }

  validateLink = (rule, value, callback) => {

    if (value) {
      let lowValue = value.toLowerCase()
      if(lowValue.includes("http://") || lowValue.includes("https://")) {
        let pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        if (!pattern.test(value)) {
          callback("The input is not valid url!")
        }else callback()
      }else {
        callback("The input is not valid url!")
      }
    }else callback()
  }

  getInput = (initialValue, paramProps) => {
    const {urlKey, selectedKey, record, inputProps, dataIndex, static_data, title, newRules, selectedValue, userReducer, inputType, onBlur, readOnly} = this.props;
  
    if (inputType === 'SelectQuery') {
      return (
        <SelectQuery
          disabled={readOnly}
          size={newRules.select.size}
          urlKey={`${urlKey}-autocomplete`}
          placeholder=""
          userReducer={userReducer}
          suffixIcon={<Icon type="caret-down"/>}
          valKey={selectedKey}
          valLabel={selectedValue}
          style={{'width': '100%'}}
          onChange={(objVal) => onBlur(objVal, initialValue, dataIndex, record)}
          paramProps={paramProps}
        />
      );
    }
    else if (inputType === 'number') {
      return (
        <InputNumber
          disabled={readOnly}
          size={newRules.input.size}
          min={0}
          onBlur={e => onBlur(e.target.value, initialValue)}
        />
      );
    }
    else if (inputType === 'select') {
      return (
        <Select
          disabled={readOnly}
          style={{ width: 200 }}
          size={newRules.select.size}
          placeholder={'Select a '+ title.toLowerCase()}
          // defaultValue={get(record[fieldKey])}
          onChange={val => onBlur(val, initialValue)}
        >
          {urlKey.map((e)=>{
            return <Option value={e.alias} key={e.alias}>{e.value}</Option>
          })}
        </Select>
      );
    }
    else if (inputType === 'checkbox') {
      return (
        <Checkbox
          disabled={readOnly}
          size={newRules.input.size}
          onChange={e => onBlur(e.target.checked, initialValue)}
          // defaultChecked={record[fieldKey]}
        />
      );
    }
    else if (inputType === 'file') {
      return (
        <Upload fileList={this.state.fileList} onChange={this.normalizeFile} beforeUpload={() => false}>
          <Button disabled={readOnly} size={newRules.button.size}>
            <Icon type="upload"/> Click to Upload
          </Button>
        </Upload>
      );
    }
    else if(inputType === 'select_multiple'){
      return (
          <SelectQuery
            disabled={readOnly}
            urlKey={urlKey + '-autocomplete'}
            mode="multiple"
            size={newRules.select.size}
            showSearch
            placeholder='Please type to search'
            userReducer={userReducer}
            suffixIcon={<Icon type="caret-down"/>}
            onChange={(arrVal) => onBlur(arrVal, initialValue)}
            valKey={selectedKey}
            valLabel={selectedValue}
            style={{marginRight: '10px'}}
            paramProps={paramProps}
          />
      );
    }
    else if(inputType === 'select_multiple_static'){
      
      return (
          <Select {...inputProps} style={{ width: '100%' }}>
            {static_data.map(opt => <Option key={opt.alias} value={opt.alias}>{opt.value}</Option>)}
          </Select>
      )
    }
    return <Input disabled={readOnly} size={newRules.input.size} onBlur={e => onBlur(e.target.value, initialValue)} />;
  };
  

  renderButtonAdditional = (getFieldValue) => {
    const {toggleDrawerSQ, fieldKey, urlKey, inputType, newRules, readOnly, record} = this.props;
    if (inputType !== 'SelectQuery') return null;
    return(
      <Fragment>
        <Button
          icon='plus'
          disabled={readOnly}
          size={newRules.button.size}
          type={newRules.button.type.add}
          style={{marginLeft: '.3rem'}}
          onClick={() => toggleDrawerSQ('add', urlKey, null, record.pk)}
        />

        <Button
          icon='edit'
          type={newRules.button.type.edit}
          size={newRules.button.size}
          style={{marginLeft: '.3rem'}}
          disabled={getFieldValue(fieldKey) === undefined || readOnly}
          onClick={() => toggleDrawerSQ('edit', urlKey, getFieldValue(fieldKey), record.pk)}
        />
      </Fragment>
    )
  };

  renderCell = () => {
    const {
      editing,
      dataIndex,
      fieldKey,
      title,
      inputType,
      record,
      required,
      children,
      selectedKey,
      selectedValue,
      filterSelected,
      // fieldOrder,
      params
    } = this.props;
    
    let initialValue = this.props.initialValue;
    let otd = {}
    let paramProps = {...params}
    if(inputType === 'SelectQuery'){
      initialValue = Lib.setSelectValue(initialValue, selectedKey, selectedValue)
      const catched = filterSelected[dataIndex]
      otd.valuePropName = 'value'
      if(catched){
          paramProps = {...paramProps, ...catched}
      }
    }
    else if(inputType === 'checkbox') otd.valuePropName= 'checked'
    else if(inputType === 'file') initialValue = record[fieldKey]
    let rules = [
      {
        required: required,
        message: `Please Input ${title}!`,
      }
    ]
    if(dataIndex==='link')
      rules.push({validator: this.validateLink})
    else if(inputType === 'select_multiple'){
      initialValue = Lib.setSelectValueMultiple(initialValue, selectedKey, selectedValue)
      rules.push({validator: this.multipleValidator})
      const catched = filterSelected[dataIndex]
      if(catched){
          paramProps = {...paramProps, ...catched}
      }
    }
    return (
      <td className={this.props.className}>
        {editing ? (
          <EditableContext.Consumer>
            {form => (
              <Form.Item style={{ margin: 0}} className='custome-form'>
                {form.getFieldDecorator(fieldKey, {
                  rules: rules,
                  initialValue,
                  ...otd
                })(this.getInput(initialValue, paramProps))}
                {this.renderButtonAdditional(form.getFieldValue)}
              </Form.Item>)}
          </EditableContext.Consumer>
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return this.renderCell()
  }
}

EditableCell.propTypes = {
  readOnly: PropTypes.bool,
  onBlur: PropTypes.func,
}

EditableCell.defaultProps = {
  readOnly: false,
  onBlur: () => null,
}

export default EditableCell
