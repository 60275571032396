import TrackerManagement from './TrackerManagement'

class UserTrackerAdd extends TrackerManagement {
  constructor(props) {
    super(props)

    this.headerDescriptions = [
      { key: 'username', label: 'Username' },
      { key: 'first_name', label: 'First Name' },
      { key: 'last_name', label: 'Last Name' },
      { key: 'email', label: 'E-Mail' },
      { key: 'is_active', label: 'Active', type: 'bool' },
      { key: 'date_joined', label: 'Joined', type: 'datetime' },
    ]
  }
}

export default UserTrackerAdd