export default {
  "app_label": "estimation",
  "model_name": "randommapping",
  "pagination": true,
  "columns": [
    {
      "name": "subsection",
      "label": "Subsection",
      "data_type": null,
      "child_path": "identifier",
      "search": true,
      "sort": true
    },
    {
      "name": "line_template",
      "label": "Line template",
      "child_path": "label",
      "search": true,
      "sort": true
    },
    {
      "name": "usage",
      "label": "Usage",
      "child_path": "label",
      "search": true,
      "sort": true
    },
    {
      "name": "subheading",
      "label": "Subheading",
      "child_path": "label",
      "search": true,
      "sort": true
    },
    {
      "name": "status",
      "label": "Status",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "search": true,
      "sort": true
    },
    {
      "name": "modified",
      "label": "Modified",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "datetime",
      "search": false,
      "sort": true
    }
  ],
  "filter_by": [
    {
      "label": "Status",
      "name": "status",
      "type": "choice",
      "choices": [
        [
          "active",
          "Active - Anyone can use"
        ],
        [
          "inactive",
          "Inactive - No one can use"
        ],
        [
          "unpublished",
          "Unpublished - Only certain people can use"
        ]
      ]
    },
    {
      "label": "Section",
      "name": "section",
      "type": "api_reference",
      "api": {
        "url_name": "api:admin:estimation:section:autocomplete",
        "key": "pk",
        "display": "name",
        "pagination": true
      }
    },
    {
      "label": "Subsection",
      "name": "subsection",
      "type": "api_reference",
      relates: ['section'],
      "api": {
        "url_name": "api:admin:estimation:subsection:autocomplete",
        "key": "pk",
        "display": "name",
        "pagination": true
      }
    },
    {
      "label": "Line template",
      "name": "line_template",
      "type": "api_reference",
      relates: ['subsection'],
      "api": {
        "url_name": "api:admin:estimation:linetemplate:autocomplete",
        "key": "pk",
        "display": "label",
        "pagination": true
      }
    },
    {
      "label": "Usage",
      "name": "usage",
      "type": "api_reference",
      "api": {
        "url_name": "api:admin:estimation:lineusage:autocomplete",
        "key": "pk",
        "display": "label",
        "pagination": true
      }
    },
    {
      "label": "Subheading",
      "name": "subheading",
      "type": "api_reference",
      "api": {
        "url_name": "api:admin:estimation:subheading:autocomplete",
        "key": "pk",
        "display": "label",
        "pagination": true
      }
    },
  ],
  "export_import": null
}