export default [
  {
    title: 'Name',
    type: 'text',
    data: '',
    required: true,
    dataIndex: 'name',
    initialValue: '',
    note_text: 'A descriptive name, e.g. New Zealand Template Set, Australia Template Set.',
    additional: '',
    order: 0,
  },{
    title: 'Code',
    type: 'text',
    data: '',
    required: true,
    dataIndex: 'code',
    initialValue: '',
    maxLength: 4,
    note_text: 'Short code to quickly identify set, e.g. nz for New Zealand Template Set.',
    additional: '',
    order: 1,
  },{
    title: 'Country',
    type: 'SelectQuery',
    data: 'countries',
    required: true,
    dataIndex: 'country',
    selectedKey: 'pk',
    selectedValue: 'name',
    initialValue: '',
    note_text: '',
    additional: 'edit|add',
    order: 2,
  }
];