import { Login, Page404 } from "App/Screens/Pages";
import Main from "App/Screens/Main";

import Dashboard from "App/Screens/Dashboard";
import Estimation from "App/Screens/Estimation/";
import Geo from "App/Screens/Geo/";
import Job from "App/Screens/Job";
import Multisite from "App/Screens/Multisite/";
import Notification from "App/Screens/Notification/";
import Report from "App/Screens/Report/";
import Users from "App/Screens/Users/";
import SubModules from "App/Screens/SubModules";

const setRoutes = routeConfig => {
  const routeObj = [],
    fillRoutes = item =>
      item.routes ? routeObj.push(...item.routes) : routeObj.push(item);
  for (const { routes } of routeConfig) routes.map(fillRoutes);
  return routeObj;
};

const appsRouteConfig = [
  Dashboard,
  Estimation,
  Geo,
  Job,
  Multisite,
  Report,
  Users,
  Notification,
  SubModules.App
];

const loginRouteConfig = [SubModules.Login];

export default {
  loginRoutes: [
    ...setRoutes(loginRouteConfig),
    {
      path: "/login",
      name: "login",
      routeComponent: Login,
      exact: true
    },
    {
      path: "/",
      name: "Main",
      routeComponent: Main,
      private: true,
      to: "/login"
    }
  ],
  appRoutes: [...setRoutes(appsRouteConfig)],
  unknownRoutes: [
    {
      path: "*",
      name: "404",
      routeComponent: Page404
    }
  ]
};
