import React from 'react'
import { Tooltip } from 'antd'
import { get } from 'lodash'
import moment from 'moment'
import JSONView from 'react-json-view'
import { Modules } from 'App/Utils'

const getElemArea = (value, field) => {
  return (
    <div
      style={{
        maxHeight: get(field, 'maxHeight', '256px'),
        maxWidth: get(field, 'maxWidth', '700px'),
        overflowY: 'auto',
        whiteSpace: 'pre-wrap',
      }}
    >
      {value}
    </div>
  )
}

const getJsonView = (value, field) => {
  let json = value
  if (typeof json === 'string') {
    try {
      json = JSON.parse(json)
      if (!json) throw(new Error('src in JSONView cannot be null'))
      // if (field.isHtml) {
      //   if (Array.isArray(json)) {
      //     for (let i = 0; i < json.length; i += 1) {
      //       json[i] = get(json, [i], '').replace(/<\/?[^>]+(>|$)/g, "")
      //     }
      //   }
      // }
    } catch (e) {
      return getElemArea(value, field)
    }
  }
  return (
    <div
      style={{
        maxHeight: get(field, 'maxHeight', '256px'),
        maxWidth: get(field, 'maxWidth', '100%'),
        overflowY: 'auto',
      }}
    >
      <JSONView
        src={json}
        name={field.label}
        iconStyle='triangle'
        enableClipboard={false}
        displayDataTypes={false}
        {...field.inputProps}
      />
    </div>
  )
}

const getHtmlRows = (value, field) => {
  let json = value
  if (typeof json === 'string') {
    try {
      json = JSON.parse(json)
      if (!json) throw(new Error('src in JSONView cannot be null'))
    } catch (e) {
      return getElemArea(value, field)
    }
  }

  return (
    <div
      style={{
        maxHeight: get(field, 'maxHeight', '256px'),
        maxWidth: get(field, 'maxWidth', '100%'),
        overflow: 'auto',
        fontFamily: 'monospace',
      }}
    >
      {Array.isArray(json) ? (
        json.map((val, i) => (
          <div key={i} className='variable-row selection-row-item' style={{ cursor: 'default', fontWeight: 600, padding: '3px 5px 3px 4px' }}>
            <span
              style={{
                display: 'inline-block',
                color: 'rgb(108, 114, 196)',
                letterSpacing: '0.5px',
                verticalAlign: 'top',
                opacity: '0.85'
              }}
            >
              {i}
              <div
                style={{
                  display: 'inline-block',
                  margin: '0 5px',
                  color: 'rgb(0, 43, 54)',
                  verticalAlign: 'top'
                }}
              >:</div>
            </span>
            <span dangerouslySetInnerHTML={{ __html: val }} />
          </div>
        ))
      ) : (
        json
      )}
    </div>
  )
}

const getArrayString = (value, field) => {
  if (!Array.isArray(value)) return '-'

  return (
    <Tooltip
      title={(
        <ol style={{ paddingLeft: '20px' }}>
          {value.map(val => <li key={val}>{val}</li>)}
        </ol>
      )}
    >
      <div className='webkit-box'>
        {value.join(', ')}
      </div>
    </Tooltip>
  )
}

const getValue = (record, field, index) => {
  const value = field.key ? get(record, field.key) : record
  if (typeof field.render === 'function') return field.render(value, index, record)
  if (field.type === 'datetime' && value) return moment(value).format(field.format || 'ddd, DD MMM YYYY hh:mm:ss A')
  if (field.type === 'bool') return Modules.renderStatusAIU(value)
  if (field.type === 'textarea' && value) return getElemArea(value, field)
  if (field.type === 'json' && value) return getJsonView(value, field)
  if (field.type === 'htmlRows' && value) return getHtmlRows(value, field)
  if (field.type === 'arrayString') return getArrayString(value, field)
  if (field.type === 'file' && value) {
    const link = get(value, field.link, '#')
    const name = get(value, field.display, link)
    return (
      <a href={link} target='_blank' rel='noopener noreferrer' title={name}>
        {name}
      </a>
    )
  }
  return (value || value === 0) ? value : get(field, 'strBlank', '-')
}

export default getValue