import React from 'react'
import { Prompt } from 'react-router'
import { Form, Card, Row, Col, Button, message, Modal, Spin, Icon } from 'antd'
import { get, isEmpty } from 'lodash'
import { BaseUrl } from 'App/Component/Bases'
import fields from './fieldsAdd'
import { FormContext, FormProvider } from 'App/Component/Context/ContextForm'
import DynamicField from 'App/Component/DynamicField'
import Lib2 from 'App/Component/Singlepage/Lib2'
import { RequestV2 } from 'App/Utils'
import ActionFooter from './Component/ActionFooter'
import CustomTransfer from './Component/CustomTransfer'
import PageNotFound from 'App/Component/PageNotFound'
import PageError from 'App/Component/PageError'
import ErrorLabel from 'App/Screens/SubModules/UserGroup/Group/Management/Pages/ErrorLabel'

class UserAdd extends React.PureComponent {
  constructor(props) {
    super(props)

    this.groupTitles = ['Available Groups', 'Chosen Groups']
    const urlParams = this.getUrlParams(props.location.search)
    this.initialGroups = this.getGroups(urlParams.groups)
    this.state = {
      isPreparing: true, // for request user display
      errorResponse: null,
      isFieldsTouched: false,
      errorGroup: '',
    }
  }

  getUrlParams = (strSearch) => {
    const urlParams = {}
    if (typeof strSearch === 'string') {
      const params = strSearch.replace(/\?/, '').split('&')
      for (const index in params) {
        const tempParam = params[index].split('=')
        urlParams[tempParam[0]] = tempParam[1]
      }
    }
    return urlParams
  }

  // Convert string to array (number) (split by comma)
  getGroups = (strParam) => {
    const groups = []
    if (typeof strParam === 'string') {
      const tempGroups = decodeURIComponent(strParam).split(',').filter(val => Number(val)).map(val => Number(val))
      groups.push(...tempGroups)
    }
    return groups
  }

  setThisState = (obj, callback = () => null, force) => {
    if (force) {
      return this.setState(obj, callback)
    } else {
      for (const key in obj) {
        if (obj[key] !== this.state[key]) {
          return this.setState(obj, callback)
        }
      }
    }
    return callback()
  }

  readDisplay = (keyLoading = 'isPreparing', callback = () => null) => {
    this.setThisState({ [keyLoading]: true }, () => {
      RequestV2(
        'get',
        'module-helper-user',
        {},
        {},
        [],
        this.readDisplaySuccess,
        this.readDisplayFailed,
        { keyLoading, callback },
      )
    })
  }

  readDisplaySuccess = (response, extra) => {
    this.setState({ [extra.keyLoading]: false, userHelper: response.data })
  }

  readDisplayFailed = (error, extra) => {
    this.setState({ [extra.keyLoading]: false, errorResponse: get(error, 'response') }, () => extra.callback())
  }

  onClose = (event, form, callback = () => null) => {
    callback() // untuk mematikan loadingnya
    this.onGoHome()
  }

  // only for local use, callback(true) if yes, callback() if no.
  confirmation = (callback = () => null, force) => {
    if (force) {
      callback(true)
    } else {
      Modal.confirm({
        title: 'Leave this page?',
        content: 'Changes you made may not be saved.',
        okType: 'danger',
        okText: 'Leave',
        onOk: () => callback(true),
        onCancel: () => callback(),
      })
    }
  }

  // callback tanpa parameter menandakan failed
  onSave = (form, callback = () => null) => {
    form.validateFieldsAndScroll({ scroll: {offsetTop: 150} }, (err, values) => {
      if (!err) {
        this.setState({ isLoading: true }, () => {
          const { userReducer } = this.props
          const convertedValues = Lib2.getValuesWithFile(values)
          const groups = this._group.validateFields()
          RequestV2(
            'post',
            'module-groupUser-user-create',
            {},
            { ...convertedValues, groups },
            [],
            this.saveSuccess,
            this.saveFailed,
            { form, callback },
          )
        })
      } else {
        callback()
      }
    })
  }

  saveSuccess = (response, extra) => {
    this.setState({ isFieldsTouched: false }, () => {
      this.props.history.push(`${BaseUrl.userIbes}/${get(response, 'data.pk')}`)
    })
  }

  saveFailed = (error, extra) => {
    const errorData = get(error, 'response.data', [])
    if (typeof errorData === 'object' && !isEmpty(errorData)) {
      // Warning: Akan blank jika tidak menggunakan if (typeof object), karena bisa jadi response string
      for (const key in errorData) {
        message.error('Error in field ' + key + ' : ' + errorData[key][0], 5)
        if (key === 'groups') {
          this.setErrorGroup(errorData[key][0])
        } else {
          const value = extra.form.getFieldValue(key)
          extra.form.setFields({
            [key]: {
              value,
              errors: [new Error(errorData[key][0])],
            }
          })
        }
      }
    } else {
      const codeError = get(error.response, 'status', '[Code]') + ' ' + get(error.response, 'statusText', 'Unknown')
      message.error('Save failed, code: ' + codeError, 5)
    }
    extra.callback()
  }

  onGoHome = () => {
    this.props.history.push(BaseUrl.userIbes)
  }

  onFieldLeave = (val) => {
    if (val && val !== '0') {
      this.onFieldTouched()
    }
  }

  // Children tidak boleh diberi fungsi ini
  setTouched = (isFieldsTouched, callback) => {
    this.setState({ isFieldsTouched }, callback)
  }

  // Set touched untuk children input
  onFieldTouched = (isFieldsTouched, callback = () => null) => {
    this.setTouched(true, callback)
  }

  onGroupTouched = () => {
    this.setTouched(true, () => {
      this.setErrorGroup()
    })
  }

  setErrorGroup = (errorGroup = '') => {
    this.setState({ errorGroup })
  }

  componentDidMount() {
    this.readDisplay()
  }

  render() {
    const { userReducer } = this.props
    const { isPreparing, userHelper, errorResponse, isFieldsTouched, errorGroup } = this.state

    if (isPreparing) {
      return (
        <Row type='flex' justify='center' align='middle' style={{ marginTop: '30vh' }}>
          <Spin
            size='large'
            tip='Preparing fields...'
            indicator={<Icon spin type='loading' style={{ fontSize: '4rem' }} />}
          />
        </Row>
      )
    }

    return userHelper ? (
      <Card title='Add User' style={{ borderRadius: '4px', marginBottom: '64px' }}>
        <Prompt
          when={isFieldsTouched}
          message='You will lose any unsaved changes. Continue?'
        />
        <Row>
          <FormProvider>
            <Form layout='horizontal'>
              {
                fields.map((val) => (
                  <DynamicField
                    key={val.title}
                    {...val}
                    operation='add'
                    userReducer={userReducer}
                    onBlur={this.onFieldLeave}
                  />
                ))
              }
              <FormContext.Consumer>
                {(form) => (
                  <ActionFooter
                    form={form}
                    onSave={this.onSave}
                    onCancel={(event, callback) => this.onClose(event, form, callback)}
                  />
                )}
              </FormContext.Consumer>
            </Form>
          </FormProvider>
        </Row>
        <Row>
          {errorGroup && <ErrorLabel errorMessage={'(Chosen Groups) ' + errorGroup} />}
          <CustomTransfer
            ref={ref => {this._group = ref}}
            titles={this.groupTitles}
            dataSource={userHelper.group}
            initialValue={this.initialGroups}
            setTouched={this.onGroupTouched}
          />
        </Row>
      </Card>
    ) : (
      <PageError
        errorResponse={errorResponse}
        onGoBack={this.onGoHome}
        onReload={this.readDisplay}
      />
    )
  }
}

export default UserAdd