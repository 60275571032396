export default {
  bulkOptions: [
    // {
    //   key: 'delete_bulk',
    //   field: 'delete_bulk',
    //   value: 'true',
    //   label: 'Delete',
    //   type: 'danger',
    //   permissionCode: 'estimation.delete_product',
    // },
    {
      key: 'set_to_active',
      field: 'status',
      value: 'active',
      label: 'Set to Active',
      permissionCode: 'estimation.change_product',
    }, {
      key: 'set_to_inactive',
      field: 'status',
      value: 'inactive',
      label: 'Set to Inactive',
      permissionCode: 'estimation.change_product',
    }, {
      key: 'set_to_unpublished',
      field: 'status',
      value: 'unpublished',
      label: 'Set to Unpublished',
      permissionCode: 'estimation.change_product',
    }
  ],
  exportImportOptions: {
    export: {
      directly: true,
      url_name: 'api:admin:estimation:product:export',
    },
  },
}